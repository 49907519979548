import React, { useEffect, useState } from "react";

import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Card } from "@material-ui/core";

import { deepOrange, deepPurple } from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";

import Typography from "@material-ui/core/Typography";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import AvatarUpload from "./AvatarUpload";

import Avatar from "@material-ui/core/Avatar";
import { useSelector } from "react-redux";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },

  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    display: "inline-flex",
  },
  title: {
    fontSize: 16,
    margin: 5,
  },
  backdrop: {
    zIndex: 2000,
    color: "#fff",
  },
}));

const LogoPreview = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openPosterDrawer, setOpenPosterDrawer] = useState(false);
  const [type, setType] = useState(null);
  const [preview, setPreview] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const clinicDetails = useSelector((state) => state.clinicDetails);

  const clinicRef = firebase
    .firestore()
    .collection("clinics")
    .doc(clinicDetails.id);

  const handleClickOpen = (logoType) => {
    setType(logoType);
    setPreview(clinicDetails[logoType]);
    setOpen(true);
  };

  const handleClose = () => {
    setType(null);
    setOpen(false);
  };

  const handleClosePosterDrawer = () => {
    setType(null);
    setOpenPosterDrawer(false);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, `${type}-${Date.now()}`);
    fileData.append("user_id", "1gHTSDwzWRPW7KAUeTcscDFxs7z2");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        return res.data.data["file_url"];
      } catch (error) {
        console.error(error, "error in writing file to db");
      }
    } else return "";
  };

  const uploadLogo = async () => {
    if (preview && type) {
      setOpenBackdrop(true);
      var block = preview.split(";");
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      var file = b64toBlob(realData, contentType);
      let downloadURL = await uploadFile(file);
      console.log("File available at", downloadURL);
      if (type) {
        let temp = {};
        temp[type] = downloadURL;
        clinicRef
          .update({
            clinic_details: { ...clinicDetails, ...temp },
          })
          .then(() => {
            console.log(downloadURL);
            setPreview(null);
            setType(null);
            setOpen(false);
            setOpenBackdrop(false);
          });
      }
    }
  };

  const uploadPoster = async (e) => {
    let file = e.target.files[0];
    let downloadURL = await uploadFile(file);
    console.log("File available at", downloadURL);
    clinicRef.update({
      clinic_details: { ...clinicDetails, poster: downloadURL },
    });
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className="shadow-sm bg-dark text-light" variant="outlined">
        <CardContent>
          <Typography variant="h6" component="h2">
            Add {clinicDetails.type === 1 ? "office" : "clinic"} logo and poster
          </Typography>
          <hr />
          <div className="row">
            <div className="col-md-4 text-center">
              <Typography className={classes.title}>Primary Logo</Typography>
              <div>
                <Avatar
                  alt="PrimaryLogo"
                  className={`${classes.orange} ${classes.large}`}
                  src={clinicDetails?.primary_logo}
                />
              </div>
              <Button
                color="primary"
                className="mt-2"
                onClick={() => handleClickOpen("primary_logo")}
              >
                Edit
                <PhotoCamera fontSize="small" className="ml-1 " />
              </Button>
            </div>

            <div className="col-md-4 text-center">
              <Typography className={classes.title}>Secondary Logo</Typography>
              <div>
                <div>
                  <Avatar
                    alt="SecondaryLogo"
                    className={`${classes.purple} ${classes.large}`}
                    src={clinicDetails?.secondary_logo}
                  />
                </div>
                <Button
                  color="secondary"
                  className="mt-2"
                  onClick={() => handleClickOpen("secondary_logo")}
                >
                  Edit
                  <PhotoCamera fontSize="small" className="ml-1 " />
                </Button>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <Typography className={classes.title}>Poster</Typography>
              {clinicDetails.poster ? (
                <img src={clinicDetails.poster} alt="poster" width="200px" />
              ) : (
                <Card className="bg-light py-5 text-muted mx-5">Image</Card>
              )}
              <br />
              <Button
                color="link"
                className="mt-2"
                onClick={() => setOpenPosterDrawer(true)}
              >
                {" "}
                Edit
                <PhotoCamera fontSize="small" className="ml-1 " />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-slide-title">Logo upload</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <AvatarUpload preview={preview} setPreview={setPreview} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Discard
          </Button>
          <Button color="primary" onClick={uploadLogo}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPosterDrawer}
        TransitionComponent={Transition}
        fullWidth="sm"
        keepMounted
        onClose={handleClosePosterDrawer}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-slide-title">Poster upload</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-center"
          >
            {clinicDetails.poster ? (
              <img src={clinicDetails.poster} alt="poster" width="80%" />
            ) : (
              <Card className="bg-light py-5 text-muted mx-5 text-center">
                Image
              </Card>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <input className="w-25 mr-3" type="file" onChange={uploadPoster} />

          <Button onClick={handleClosePosterDrawer} color="secondary">
            Discard
          </Button>
          {/* <Button onClick={handleClosePosterDrawer} color="primary"> */}

          {/* Change
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LogoPreview;
