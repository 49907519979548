import React from "react";
import { Button, Image } from "react-bootstrap";
import { useHistory } from "react-router";

export default function NoMatch() {
    const history = useHistory();
    const backToHome = () => {
        history.push("/");
    }
  return (
    <div className="container-fluid p-md-5 p-3 bg-graphite full-screen">
      <div className="container text-white">
        {/* Desktop view */}
        <div className="d-none d-md-block">
          <div className="row my-3 my-md-5">
            <div className="col-md-4">
              <h1 className="display-4">Looks like you are lost in space.</h1>
              <div className="mt-3 mt-md-5">
                <Button
                  variant="outline-light"
                  className="p-3 px-5"
                  onClick={backToHome}
                >
                  <small className="font-weight-bold small text-uppercase">
                    Back to home
                  </small>
                </Button>
              </div>
            </div>
            <div className="col-md-8">
              <div className="d-flex justify-content-end w-100">
                <div className="col-10">
                  <Image src={process.env.PUBLIC_URL + "/assets/404.svg"} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className="d-block d-md-none">
          <div className="text-center my-3 my-md-5 ">
            <div className="">
              <Image src={process.env.PUBLIC_URL + "/assets/404.svg"} />
            </div>
            <div className="mt-5">
              <h1 className="">Looks like you are lost in space.</h1>
              <div className="mt-3 mt-md-5">
                <Button
                  variant="outline-light"
                  className="p-3 px-5 w-100"
                  onClick={backToHome}
                >
                  <small className="font-weight-bold small text-uppercase">
                    Back to home
                  </small>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
