import React, { useEffect, useState } from "react";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Card, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import firebase from "firebase";

import { loadState, saveState } from "../../../services";
import axios from "axios";
import moment from "moment";
import {
  API_BASE_URL,
  EVENT_PLAN_CREATE,
  EVENT_PLAN_ESTIMATE,
  EVENT_PLAN_CANCEL,
} from "../../../config";

function EventPlans(props) {
  const history = useHistory();
  const {
    country,
    activePlan,
    checkingActivePlan,
    handleShowLoginModal,
    checkPlan,
  } = props;

  //   STATES
  const [cart, setCart] = useState(null);
  const [checkingOut, setCheckingOut] = useState(false);
  const [planEstimate, setPlanEstimate] = useState(0);
  const [estimatingPlan, setEstimatingPlan] = useState(false);
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [cancellingPlan, setCancellingPlan] = useState(false);

  //   FUNCTIONS
  useEffect(() => {
    const cartDetails = loadState("cart");
    if (cartDetails) {
      setCart(JSON.parse(cartDetails));
    }
  }, []);

  useEffect(async () => {
    if (cart) {
      saveState("cart", JSON.stringify(cart));
    }

    // Estimating amount to pay
    try {
      setEstimatingPlan(true);
      const estimatePlanBody = {
        user_id: firebase.auth()?.currentUser?.uid ?? "",
        plan_details: {
          events: {
            quantity: 0,
            participants: 0,
          },
          courses: {
            quantity: 0,
            participants: 0,
          },
          ...cart,
        },
        gateway_id: country === "IN" ? "INR" : "USD",
      };

      const response = await axios.post(
        `https://api.igesia.com${EVENT_PLAN_ESTIMATE}`,
        estimatePlanBody
      );

      if (response.status === 200) {
        const { data } = response.data;
        setPlanEstimate(data);
        setEstimatingPlan(false);
      } else {
        setEstimatingPlan(false);
      }
    } catch (error) {
      console.log("[Error] Estimating amount to pay: ", error);
    }

    // const cartDetails = loadState("cart");
    // if (cartDetails) {
    //   console.log("cartDetails:", JSON.parse(cartDetails));
    // }
  }, [cart]);

  const handleAddToCart = (type = "events", participants = 10) => {
    const planDetails = {
      [type]: {
        quantity: 1,
        participants: participants,
      },
    };
    setCart(planDetails);
  };

  const handleIncreaseQuantity = (type = "events") => {
    let newState = { ...cart };
    newState[type].quantity += 1;

    setCart(newState);
  };

  const handleDecreaseQuantity = (type = "events") => {
    let newState = { ...cart };
    if (newState[type].quantity > 1) {
      newState[type].quantity -= 1;
    } else {
      delete newState[type];
    }

    setCart(newState);
  };

  const handleCheckout = async () => {
    const cartDetails = JSON.parse(loadState("cart"));

    if (firebase.auth().currentUser) {
      if (cartDetails && cartDetails["events"]) {
        setCheckingOut(true);
        try {
          const planData = {
            user_id: firebase.auth().currentUser.uid,
            plan_details: {
              events: {
                quantity: 0,
                participants: 0,
              },
              courses: {
                quantity: 0,
                participants: 0,
              },
              ...cartDetails,
            },
            gateway_id: country === "IN" ? "INR" : "USD",
            payment_success_url: `${window.location.origin}/payment-successful`,
            payment_cancel_url: `${window.location.origin}/payment-failed`,
          };

          const response = await axios.post(
            `https://api.igesia.com${EVENT_PLAN_CREATE}`,
            planData
          );

          if (response.status === 200) {
            setCheckingOut(false);
            saveState(
              "payment_reference_id",
              JSON.stringify(response.data.data.payment_reference_id)
            );

            window.location.href = response.data.data.payment_url;
          } else {
            setCheckingOut(false);
          }
        } catch (error) {
          setCheckingOut(false);
          console.log(error);
        }
      }
    } else {
      handleShowLoginModal();
    }
  };

  const handleCancelPlan = async () => {
    if (firebase.auth().currentUser) {
      try {
        setCancellingPlan(true);

        const response = await axios.post(
          `https://api.igesia.com${EVENT_PLAN_CANCEL}`,
          { user_id: firebase.auth().currentUser.uid }
        );

        if (response.status === 200) {
          checkPlan();
          setCancellingPlan(false);
          setShowCancelPlanModal(false);
        } else {
          setCancellingPlan(false);
        }
      } catch (error) {
        console.log("[Error] Cancelling plan: ", error);
        setCancellingPlan(false);
      }
    }
  };

  const getCurrency = (currency = "INR", value) => {
    const locale =
      currency === "INR" ? "en-IN" : currency === "USD" ? "en-US" : "en-IN";

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  };

  return (
    <div
      className="container-fluid py-5"
      style={{
        backgroundColor: "#17191A",
        overflow: "hidden",
      }}
    >
      <div className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <h2 className="mb-3 text-s-1 text-green">Events</h2>
            <p className="text-s-4 text-light">
              Customize your events to engage your community. Create your unique
              registration pages and sell tickets with different tiers and
              access levels.
            </p>
          </div>
        </div>
        <div className="mt-3 mt-md-4">
          <div className="mb-3">
            <div className="mb-3">
              <h2 className="text-white">Select a preset plan</h2>
            </div>
            <div className="row">
              {/* Plan type: Free */}
              <div className="col-4">
                <Card className="">
                  <Card.Header>
                    <h3 className="mb-0">Free</h3>
                  </Card.Header>
                  <Card.Body>
                    <h3>{country === "IN" ? "Free" : "Free"}</h3>
                    <ul className="px-3">
                      <li className="font-weight-bold">Upto 10 participants</li>
                      <li>Create custom registration pages</li>
                      <li>Integrate your Zoom account</li>
                      <li>Participant dashboard</li>
                      <li>Participant networking</li>
                      <li>Sponsors' booths</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    {checkingActivePlan ? (
                      <Button className="w-100 text-uppercase" disabled>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="mb-0 mr-2"
                        />{" "}
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        className="w-100 text-uppercase"
                        variant="success"
                        onClick={() => {
                          history.push("admin");
                        }}
                        disabled={
                          (activePlan?.planType === 1 &&
                            activePlan?.planDetails?.events) ||
                          (activePlan?.planType === 0 &&
                            activePlan?.planDetails?.events?.available === 0)
                        }
                      >
                        Get Started
                      </Button>
                    )}
                    {activePlan?.planType === 0 &&
                      activePlan?.planDetails?.events?.available === 0 && (
                        <p className="mb-0 small">
                          <b>Note: </b>
                          Free limit exhausted, buy paid plan
                        </p>
                      )}

                    {activePlan?.planType === 1 &&
                      activePlan?.planDetails?.events && (
                        <p className="mb-0 small">
                          <b>Note: </b>
                          {!activePlan?.planCancellationStatus
                            ? "You already have an active plan"
                            : "You already have a cancelled plan"}
                        </p>
                      )}
                  </Card.Footer>
                </Card>
              </div>

              {/* Plan type: Basic */}
              <div className="col-4">
                <Card className="">
                  <Card.Header>
                    <h3 className="mb-0">Basic</h3>
                  </Card.Header>
                  <Card.Body>
                    <h3>{country === "IN" ? "₹10,000/-" : "USD 500"}</h3>
                    <ul className="px-3">
                      <li className="font-weight-bold">
                        Upto 100 participants
                      </li>
                      <li>Create custom registration pages</li>
                      <li>Integrate your Zoom account</li>
                      <li>Participant dashboard</li>
                      <li>Participant networking</li>
                      <li>Sponsors' booths</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    {/* <a
                      className="btn btn-primary w-100 text-uppercase"
                      href="mailto:sales@igesia.co"
                      target="_blank"
                    >
                      Contact Us
                    </a> */}
                    {checkingActivePlan ? (
                      <Button className="w-100 text-uppercase" disabled>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="mb-0 mr-2"
                        />{" "}
                        Loading...
                      </Button>
                    ) : cart &&
                      cart["events"] &&
                      cart["events"].participants === 100 ? (
                      <>
                        <div className="d-flex align-items-center justify-content-center mb-2">
                          <Button
                            variant="light"
                            size="sm"
                            className="border"
                            onClick={() => handleDecreaseQuantity("events")}
                          >
                            <b>-</b>
                          </Button>
                          <p className="mb-0 flex-grow-1 text-center">
                            <b>{cart["events"]?.quantity ?? 0}</b>
                          </p>
                          <Button
                            variant="light"
                            size="sm"
                            className="border"
                            onClick={() => handleIncreaseQuantity("events")}
                          >
                            <b>+</b>
                          </Button>
                        </div>

                        <Button
                          className="w-100"
                          onClick={handleCheckout}
                          disabled={checkingOut}
                        >
                          {checkingOut ? (
                            <span>Checking out...</span>
                          ) : estimatingPlan ? (
                            <span>Estimating...</span>
                          ) : (
                            <span>
                              Pay{" "}
                              {getCurrency(
                                planEstimate.gateway_id,
                                planEstimate.plan_estimate
                              )}
                            </span>
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="w-100 text-uppercase mb-2"
                        variant="primary"
                        onClick={() => handleAddToCart("events", 100)}
                        disabled={
                          (cart &&
                            cart["events"] &&
                            cart["events"].participants === 1000) ||
                          (activePlan?.planType === 1 &&
                            activePlan?.planDetails?.events)
                        }
                      >
                        <FontAwesomeIcon icon={faCartPlus} /> Add to cart
                      </Button>
                    )}
                    {activePlan?.planType === 1 &&
                      activePlan?.planDetails?.events && (
                        <p className="mb-0 small">
                          <b>Note: </b>
                          {!activePlan?.planCancellationStatus
                            ? "You already have an active plan"
                            : "You already have a cancelled plan"}
                        </p>
                      )}
                  </Card.Footer>
                </Card>
              </div>

              {/* Plan type: Pro */}
              <div className="col-4">
                <Card className="">
                  <Card.Header>
                    <h3 className="mb-0">Pro</h3>
                  </Card.Header>
                  <Card.Body>
                    <h3>{country === "IN" ? "₹35,000/-" : "USD 1500"}</h3>
                    <ul className="px-3">
                      <li className="font-weight-bold">
                        Upto 1000 participants
                      </li>
                      <li>Create custom registration pages</li>
                      <li>Integrate your Zoom account</li>
                      <li>Participant dashboard</li>
                      <li>Participant networking</li>
                      <li>Sponsors' booths</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    {checkingActivePlan ? (
                      <Button className="w-100 text-uppercase" disabled>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="mb-0 mr-2"
                        />{" "}
                        Loading...
                      </Button>
                    ) : cart &&
                      cart["events"] &&
                      cart["events"].participants === 1000 ? (
                      <>
                        <div className="d-flex align-items-center justify-content-center mb-2">
                          <Button
                            variant="light"
                            size="sm"
                            className="border"
                            onClick={() => handleDecreaseQuantity("events")}
                          >
                            <b>-</b>
                          </Button>
                          <p className="mb-0 flex-grow-1 text-center">
                            <b>{cart["events"]?.quantity ?? 0}</b>
                          </p>
                          <Button
                            variant="light"
                            size="sm"
                            className="border"
                            onClick={() => handleIncreaseQuantity("events")}
                          >
                            <b>+</b>
                          </Button>
                        </div>

                        <Button
                          className="w-100"
                          onClick={handleCheckout}
                          disabled={checkingOut}
                        >
                          {checkingOut ? (
                            <span>Checking out...</span>
                          ) : estimatingPlan ? (
                            <span>Estimating...</span>
                          ) : (
                            <span>
                              Pay{" "}
                              {getCurrency(
                                planEstimate.gateway_id,
                                planEstimate.plan_estimate
                              )}
                            </span>
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="w-100 text-uppercase mb-2"
                        variant="primary"
                        onClick={() => handleAddToCart("events", 1000)}
                        disabled={
                          (cart &&
                            cart["events"] &&
                            cart["events"].participants === 100) ||
                          (activePlan?.planType === 1 &&
                            activePlan?.planDetails?.events)
                        }
                      >
                        <FontAwesomeIcon icon={faCartPlus} /> Add to cart
                      </Button>
                    )}
                    {activePlan?.planType === 1 &&
                      activePlan?.planDetails?.events && (
                        <p className="mb-0 small">
                          <b>Note: </b>
                          {!activePlan?.planCancellationStatus
                            ? "You already have an active plan"
                            : "You already have a cancelled plan"}
                        </p>
                      )}
                  </Card.Footer>
                </Card>
              </div>
            </div>
          </div>

          {/* Section: Active plan details */}
          {activePlan?.planType === 1 && activePlan?.planDetails?.events && (
            <div>
              <Card>
                <Card.Header>
                  <div className="d-flex align-items-start">
                    <h5 className="mb-0 mr-2">Plan</h5>
                    {activePlan?.planCancellationStatus ? (
                      <Badge variant="secondary">Cancelled</Badge>
                    ) : (
                      <Badge variant="primary">Active</Badge>
                    )}
                  </div>
                </Card.Header>
                <Card.Body>
                  <h5>
                    {activePlan?.planDetails?.events?.participants === 1000
                      ? "Pro"
                      : "Basic"}
                  </h5>
                  <p>
                    Purchase date:{" "}
                    {moment(activePlan?.planPurchaseDate).format("LLL")}
                  </p>
                  <p>Limit: {activePlan?.planDetails?.events?.limit}</p>
                  <p>Usage: {activePlan?.planDetails?.events?.used}</p>
                  <p>Available: {activePlan?.planDetails?.events?.available}</p>
                  {activePlan?.planCancellationStatus ? (
                    <div>
                      Plan cancel date:{" "}
                      {moment(activePlan?.planCancellationDate).format("LLL")}
                    </div>
                  ) : (
                    <div className="d-flex">
                      <p className="mr-2 mb-0">Cancel plan:</p>
                      <Button
                        size="sm"
                        variant="light"
                        className="text-uppercase text-danger"
                        onClick={() => setShowCancelPlanModal(true)}
                        disabled={cancellingPlan}
                      >
                        {cancellingPlan ? (
                          <span>Cancelling...</span>
                        ) : (
                          <span>Cancel plan</span>
                        )}
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>

      {/* Modal: Cancel plan */}
      <Modal show={showCancelPlanModal}>
        <Modal.Header closeButton onHide={() => setShowCancelPlanModal(false)}>
          <Modal.Title>Cancel plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="lead mb-2">
              Are you sure you want to cancel your plan?
            </p>
            <p className="mb-4 text-muted">
              <b>Note: </b>Your current purchase will not be cancelled.
            </p>
            <div className="d-flex flex-row-reverse">
              <Button
                variant="danger"
                size="sm"
                className="text-uppercase"
                onClick={handleCancelPlan}
                disabled={cancellingPlan}
              >
                {cancellingPlan ? (
                  <span>Cancelling...</span>
                ) : (
                  <span>Cancel plan</span>
                )}
              </Button>
              <Button
                variant="light"
                size="sm"
                className="text-uppercase shadow-sm mr-2"
                disabled={cancellingPlan}
                onClick={() => setShowCancelPlanModal(false)}
              >
                Discard
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EventPlans;
