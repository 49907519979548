import React, { memo, useEffect, useState } from "react";
import { Button, Card, Form, Spinner, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faPlusCircle,
  faCheck,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { auth } from "../../../firebase";
import { toast } from "react-toastify";
import {
  ADMIN_SECTION_DATA_UPDATE,
  ADMIN_SECTION_DATA_CREATE,
  ADMIN_SECTION_DATA_DELETE,
  API_BASE_URL,
  USER_SECTION_DATA_GET,
} from "../../../config";

function ListSections(props) {
  // CONSTANTS
  const { name, category, parent_id, parent_type } = props;

  // STATES
  const [editing, setEditing] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [fetchingSectionList, setFetchingSectionList] = useState(false);
  const [creating, setCreating] = useState(false);
  const [newListItem, setNewListItem] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // FUNCTIONS
  const handleStartDelete = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDeleting = () => {
    setShowDeleteModal(false);
    setDeleting(false);
  };

  const handleStartEditing = (data) => {
    setEditing(true);
    setSelectedListItem(data);
  };

  const handleCancelEditing = () => {
    setEditing(false);
    setSelectedListItem(null);
  };

  const handleStartCreating = () => {
    setCreating(true);
    setNewListItem("");
    setSubmitting(false);
  };

  const handleCancelCreating = () => {
    setCreating(false);
    setNewListItem("");
    setSubmitting(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to edit
    </Tooltip>
  );

  const handleCreateListItem = async (e) => {
    e.preventDefault();

    if (!newListItem) {
      toast.dark("List item cannot be empty", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }

    try {
      setSubmitting(true);

      const newListItemDetails = {
        user_id: auth?.currentUser?.uid,
        category: category,
        parent_id: parent_id,
        parent_type: parseInt(parent_type),
        value: newListItem,
      };
      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_SECTION_DATA_CREATE}`,
        newListItemDetails
      );

      if (response.status === 200) {
        setSubmitting(false);
        handleCancelCreating();
        handleFetchSectionList();

        toast.dark("✅ List item added successfully", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        setSubmitting(false);

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(`[Error] Creating list item: ${error.message}`);
      setSubmitting(false);

      toast.dark(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleUpdateListItem = async (e) => {
    e.preventDefault();

    if (!selectedListItem?.id || !selectedListItem?.value) {
      toast.dark("Please refresh and try again", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }

    try {
      setSubmitting(true);

      const editedListItemDetails = {
        section_id: selectedListItem?.id,
        user_id: auth?.currentUser?.uid,
        value: selectedListItem?.value,
      };
      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_SECTION_DATA_UPDATE}`,
        editedListItemDetails
      );

      if (response.status === 200) {
        setSubmitting(false);
        handleCancelEditing();
        handleFetchSectionList();

        toast.dark("✅ List item updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        setSubmitting(false);

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(`[Error] Updating list item: ${error.message}`);
      setSubmitting(false);

      toast.dark(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleDeleteListItem = async () => {
    setDeleting(true);

    if (
      !selectedListItem?.id ||
      typeof selectedListItem?.order_index != "number"
    ) {
      toast.dark("Please refresh and try again", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }

    try {
      setDeleting(true);

      const deletedListItem = {
        user_id: auth?.currentUser?.uid,
        section_id: selectedListItem?.id,
        order_index: selectedListItem?.order_index,
      };

      console.log("deletedListItem: ", deletedListItem);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_SECTION_DATA_DELETE}`,
        deletedListItem
      );

      if (response.status === 200) {
        setDeleting(false);
        handleCancelDeleting();
        handleFetchSectionList();

        toast.dark("List item deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        setDeleting(false);

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(`[Error] Deleting list item: ${error.message}`);
      setDeleting(false);
    }
  };

  const handleFetchSectionList = async () => {
    try {
      setFetchingSectionList(true);
      const response = await axios.get(
        `${API_BASE_URL}${USER_SECTION_DATA_GET}?parent_id=${parent_id}&parent_type=${parent_type}&category=${category}`
      );

      if (response.status === 200) {
        setFetchingSectionList(false);
        setSectionList(response?.data?.data?.section_list);
      } else {
        setFetchingSectionList(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching section list: `, error.message);
      setFetchingSectionList(false);
    }
  };

  useEffect(() => {
    if (category != "" && parent_id != "" && typeof parent_type === "number") {
      handleFetchSectionList();
    }
  }, []);

  return (
    <Card
      style={{ width: "100%" }}
      className="shadow-sm bg-dark  shadow-sm mb-3"
    >
      <Card.Body>
        <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <h5 className="card-title mb-0">{name}</h5>
          </div>
          <div>

          </div>
        </div>

        <ul className="card-text">
          {sectionList?.length === 0 && (
            <p className="text-center">
              <i>
                Click on "
                <a className="small" onClick={handleStartCreating}>
                  <FontAwesomeIcon icon={faPlusCircle} size="1x" />
                </a>
                " to Add list items
              </i>
            </p>
          )}
          {sectionList?.map((val, key) => (
            <li key={key} className="mb-2">
              <div className="d-flex">
                <span className="flex-grow-1 mr-2">{val.value}</span>
                {editing && selectedListItem?.id === val?.id ? null : (
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <a
                      className="small"
                      onClick={() => handleStartEditing(val)}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon className="text-muted" icon={faPencilAlt} />
                    </a>
                  </OverlayTrigger>
                )}
              </div>
              {editing && selectedListItem?.id === val?.id && (
                <Form onSubmit={handleUpdateListItem} className="mb-2">
                  <Form.Group className="mb-1">
                    <Form.Control
                      size="sm"
                      value={selectedListItem?.value}
                      placeholder="Enter value"
                      onChange={(e) =>
                        setSelectedListItem((prev) => ({
                          ...prev,
                          value: e.target.value,
                        }))
                      }
                      as="textarea"
                      rows={2}
                    />
                  </Form.Group>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <Button
                        type="submit"
                        size="sm"
                        className="text-uppercase mr-2 rounded-lg"
                        disabled={submitting}
                      >
                        {submitting ? (
                          <small>
                            <Spinner animation="border" size="sm" />{" "}
                            Submitting...
                          </small>
                        ) : (
                          <small>
                            <FontAwesomeIcon icon={faCheck} /> Save
                          </small>
                        )}
                      </Button>
                      <Button
                        variant="outline-light"
                        size="sm"
                        className="border-0 text-uppercase rounded-lg"
                        onClick={handleCancelEditing}
                        disabled={submitting}
                      >
                        <small>Cancel</small>
                      </Button>
                    </div>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={handleStartDelete}
                      className="text-uppercase rounded-lg"
                      disabled={submitting}
                    >
                      <small>Delete</small>
                    </Button>
                  </div>
                </Form>
              )}
            </li>
          ))}
        </ul>
        <a className="small pointer text-decoration-none" onClick={handleStartCreating}>
          <FontAwesomeIcon icon={faPlusCircle} size="1x" className="ml-3" /> Add
        </a>

        {creating && (
          <Form onSubmit={handleCreateListItem}>
            <Form.Group className="mb-2">
              <Form.Label className="mr-2 text-uppercase small">
                <b>Section item</b>
              </Form.Label>
              <Form.Control
                size="sm"
                value={newListItem}
                placeholder="Enter value"
                onChange={(e) => setNewListItem(e.target.value)}
              />
            </Form.Group>
            <React.Fragment>
              <Button
                type="submit"
                size="sm"
                className="text-uppercase mr-2 rounded-lg"
                disabled={submitting}
              >
                {submitting ? (
                  <small>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </small>
                ) : (
                  <small>
                    <FontAwesomeIcon icon={faCheck} /> Save
                  </small>
                )}
              </Button>
              <Button
                variant="outline-light"
                size="sm"
                className="border-0 text-uppercase rounded-lg"
                onClick={handleCancelCreating}
                disabled={submitting}
              >
                <small>Cancel</small>
              </Button>
            </React.Fragment>
          </Form>
        )}
      </Card.Body>

      {/* Modal: Delete list item */}
      <Modal show={showDeleteModal}>
        <Modal.Header
          closeButton
          onHide={handleCancelDeleting}
          className="lead"
        >
          Confirm deletion
        </Modal.Header>
        <Modal.Body>
          <p className="lead">"{selectedListItem?.value}"</p>
          <p className="mb-0">Are you sure you want to delete?</p>
          <p className="small text-muted">
            <b>Note: </b>Delete action is permanent.
          </p>
          <div className="d-flex flex-row-reverse">
            <Button
              variant="danger"
              size="sm"
              className="text-uppercase rounded-lg"
              onClick={handleDeleteListItem}
              disabled={deleting}
            >
              {deleting ? <span>Deleting...</span> : <span>Delete</span>}
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              className="border-0 text-uppercase rounded-lg mr-2"
              onClick={handleCancelDeleting}
              disabled={deleting}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

export default memo(ListSections);
