import React, { useEffect , useState } from "react";
import { setCourse } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { courseList } from "../data";
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from "firebase";

function CourseRedux({
  setCourse,
  currentCourse,
}) {
  const { id } = useParams();

  const [allCourses, setAllCourses] = useState([])

  const [courseValue, courseLoading, courseError] = useCollection(
    firebase.firestore().collection('courses'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if(courseValue){
      let data = [...courseList]
      courseValue?.docs?.map((e) => {
        data.push({ id: e.id, ...e.data() })
      });
      const thisCourse = data.filter(
        (course) => course?.slug === id
      );
      thisCourse && setCourse(thisCourse[0])
    }
  },[courseValue])

  return null;
}

export default connect(
  (state) => ({
    currentCourse: state.course,
  }),
  { setCourse }
)(CourseRedux);
