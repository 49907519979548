import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import { memo, useState } from "react";
import axios from "axios";
import { API_BASE_URL, POLL_CREATE } from "../../../../config";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import firebase from "firebase";
import { toast } from "react-toastify";
import { connect } from "react-redux";
const PollFields = (props) => {
  const [title, setTitle] = useState([]);
  const [options, setOptions] = useState([]);
  const [saving, setSaving] = useState(false);

  const addPoll = async () => {
    const userDetails = firebase.auth().currentUser;
    try {
      setSaving(true);
      const res = await axios.post(`${API_BASE_URL}${POLL_CREATE}`, {
        user_id: "1gHTSDwzWRPW7KAUeTcscDFxs7z2",
        workspace_id: "LgT2sjzoZQwwKMGPnob2",
        room_id: "nqae8GPum5kRPKe9ukud",
        deadline: false,
        all_participants: true,
        assigned: [],
        title: title,
        options: options,
        images: [],
        folderId: null,
        save_for_later: false,
      });
      const size = (
        await firebase
          .firestore()
          .collection(
            `/courses/${props.courseId}/sections/${props.sectionId}/modules`
          )
          .get()
      ).docs.length;
      await firebase
        .firestore()
        .collection(
          `/courses/${props.courseId}/sections/${props.sectionId}/modules`
        )
        .doc()
        .set(
          {
            content_id: res.data.data.poll_id,
            title: title,
            section: props.sectionId,
            order_index: size,
            type:
              props.addModuleType === "poll"
                ? 3
                : props.addModuleType === "quiz"
                ? 4
                : props.addModuleType === "assignment"
                ? 2
                : props.addModuleType === "lecture_recording"
                ? 1
                : 0,
          },
          {
            merge: true,
          }
        );
      toast.success("Poll added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.error("Error", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }

    // try {
    //   setSaving(true);
    //   const pollData = {
    //     all: true,
    //     assignedTo: [],
    //     createdAt: new Date(),
    //     createdBy: "",
    //     deadline: false,
    //     folderId: null,
    //     isGraded: false,
    //     title: title,
    //     options: options,
    //     timestamp: Date.now(),
    //   };
    //   const size = (
    //     await firebase
    //       .firestore()
    //       .collection(
    //         `/courses/hdCKh1GocgVH6v2zLaGn/sections/${props.sectionId}/modules`
    //       )
    //       .get()
    //   ).docs.length;

    //   await firebase
    //     .firestore()
    //     .collection(
    //       `/courses/hdCKh1GocgVH6v2zLaGn/sections/${props.sectionId}/modules`
    //     )
    //     .doc()
    //     .set(
    //       {
    //         pollData: pollData,
    //         section: props.sectionId,
    //         order_index: size,
    //         type:
    //           props.addModuleType === "poll"
    //             ? 3
    //             : props.addModuleType === "quiz"
    //             ? 4
    //             : props.addModuleType === "assignment"
    //             ? 2
    //             : props.addModuleType === "lecture_recording"
    //             ? 1
    //             : 0,
    //       },
    //       {
    //         merge: true,
    //       }
    //     );
    //   toast.success("Poll added successfully", {
    //     position: toast.POSITION.TOP_CENTER,
    //     hideProgressBar: true,
    //     autoClose: 3000,
    //   });
    // } catch (e) {
    //   console.error("Error : ", e);
    //   toast.error("Error", {
    //     position: toast.POSITION.TOP_CENTER,
    //     hideProgressBar: true,
    //     autoClose: 3000,
    //   });
    // } finally {
    //   setSaving(false);
    //   props.onHide();
    // }
  };

  return (
    <Form>
      <div className="rounded-lg p-2 m-2 bg-card-theme">
        <p className="mb-2 font-weight-bold text-theme small">Poll Title</p>
        <InputGroup className="mb-1">
          <FormControl
            size="sm"
            placeholder="Enter the question for poll"
            name="title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            autoFocus={true}
            required={true}
          />
        </InputGroup>

        {options.length > 0
          ? options.map((option, key) => (
              <div key={key}>
                <div className="d-flex">
                  <p className="mb-0 flex-grow-1 small font-weight-bold">
                    Option {key + 1}.
                  </p>
                  <div
                    className="mb-2 pointer"
                    onClick={() => {
                      setOptions((prev) => {
                        const updatedState = produce(prev, (draft) => {
                          draft.splice(key, 1);
                        });
                        return updatedState;
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div className="d-flex justify-content-center content-align-center mb-4">
                  <InputGroup>
                    <FormControl
                      placeholder="Option"
                      aria-label="option"
                      aria-describedby={"option" + key}
                      name="options[]"
                      onChange={(e) => {
                        setOptions((prev) => {
                          const updatedState = produce(prev, (draft) => {
                            draft[key] = e.target.value;
                          });
                          return updatedState;
                        });
                      }}
                      autoFocus={options.length === key + 1}
                      id={key}
                      required={true}
                      size="sm"
                    />
                    <div
                      className="ml-1 pointer "
                      style={{
                        position: "relative",
                        height: 30,
                        width: 30,
                      }}
                    ></div>
                  </InputGroup>
                </div>
              </div>
            ))
          : null}

        <InputGroup className="w-100 my-2">
          <Button
            size="sm"
            variant="outline-light"
            className="w-100"
            onClick={() => {
              setOptions((prev) => {
                const updatedState = produce(prev, (draft) => {
                  draft.push({});
                });
                return updatedState;
              });
            }}
          >
            + Add Option
          </Button>
        </InputGroup>
      </div>

      <div className="m-2">
        <Button
          variant="primary"
          style={{ width: "100px", marginRight: "5px" }}
          size="sm"
          disabled={saving}
          onClick={() => {
            addPoll();
          }}
        >
          {saving ? (
            <>
              <Spinner size="sm" animation="border" color="white" /> Adding
            </>
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default memo(connect(mapStateToProps)(PollFields));
