import { memo, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
} from "react-feather";
import "./style.css";

function index(props) {
  // CONSTANTS
  const {
    startDateCalendar,
    callbackOpen,
    callbackClose,
    currentValue,
    callbackSet,
  } = props;

  return (
    <div className="Timeline">
      <div className="Calendar">
        <DateTimePicker
          minDetail="year"
          showFixedNumberOfWeeks
          calendarClassName=""
          // className="text-dark"
          // disableClock
          isOpen={startDateCalendar}
          onCalendarOpen={callbackOpen}
          onCalendarClose={callbackClose}
          onClockOpen={callbackOpen}
          value={currentValue}
          onChange={(date) => {
            callbackSet(date);
          }}
          prevLabel={
            <div>
              <ChevronLeft size={18} />
            </div>
          }
          nextLabel={
            <div>
              <ChevronRight size={18} />
            </div>
          }
          prev2Label={
            <div>
              <ChevronsLeft size={18} />
            </div>
          }
          next2Label={
            <div>
              <ChevronsRight size={18} />
            </div>
          }
        />
      </div>
    </div>
  );
}

export default memo(index);
