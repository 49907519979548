import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import EventManagerNavbar from "./EventManagerNavbar";
import EventNavbar from "./Navbar";
import EventManagerLeftbar from "./EventManagerLeftbar";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormFileInput from "react-bootstrap/esm/FormFileInput";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";

toast.configure();

const Stage = (props) => {
  const userDetails = firebase.auth().currentUser;

  const [showAddStageModal, setShowAddStageModal] = useState(false);

  const [stageSnapshot, loading, error] = useCollection(
    firebase.firestore().collection(`/events/${props.eventId}/stages`)
  );

const   [order,setOrder] = useState("");

  useEffect(() => {
    const order_index = stageSnapshot?.docs.length + 1;
    setOrder(order_index);
  },[stageSnapshot]);

  const [stageDetails, setStageDetails] = useState({});

  const [isUpdating, setIsUpdating] = useState(false);

  const saveTextDataToStateHandler = (e) => {
    if (e.target.name === "stream_link") {
      const embedcode = convertYoutubeLinkToEmbedHandler(e.target.value);
      setStageDetails((prevStageData) => {
        return { ...prevStageData, [e.target.name]: embedcode };
      });
    } else {
      setStageDetails((prevStageData) => {
        return { ...prevStageData, [e.target.name]: e.target.value };
      });
    }
  };
  const saveFileToStateHandler = (e) => {
    setStageDetails((prevStageData) => {
      return { ...prevStageData, [e.target.name]: e.target.files[0] };
    });
  };

  const [errors, setErrors] = useState({});

  const validationHandler = (e = true, stageDetails) => {
    if (e.target.name === "stage_name") {
      if (!stageDetails.stage_name || !stageDetails.stage_name.trim().length) {
        setErrors((prev) => {
          const errors = {
            ...prev,
            stage_name: "Stage name can not be empty.",
          };
          return errors;
        });
      }
      if (stageDetails.stage_name && stageDetails.stage_name.trim().length) {
        setErrors((prev) => {
          const errors = { ...prev, stage_name: null };
          return errors;
        });
      }
    }
    if (e.target.name === "stage_type") {
      if (!stageDetails.stage_type || !stageDetails.stage_type.trim().length) {
        setErrors((prev) => {
          const errors = { ...prev, stage_type: "Please select stage type" };
          return errors;
        });
      }
      if (stageDetails.stage_type && stageDetails.stage_type.trim().length) {
        setErrors((prev) => {
          const errors = { ...prev, stage_type: null };
          return errors;
        });
      }
    }

    if (errors.stage_name === null && errors.stage_type === null) {
      setErrors({});
      return 1;
    } else {
      return 0;
    }
  };
  const validationHandlerForm = (e = true, stageDetails) => {
    let error = 0;
    if (!stageDetails.stage_name || !stageDetails.stage_name.trim().length) {
      setErrors((prev) => {
        const errors = {
          ...prev,
          stage_name: "Stage name can not be empty.",
        };
        return errors;
      });
      error++;
    }
    if (stageDetails.stage_name && stageDetails.stage_name.trim().length) {
      setErrors((prev) => {
        const errors = { ...prev, stage_name: null };
        return errors;
      });
      if (error) {
        error--;
      }
    }
    if (!stageDetails.stage_type || !stageDetails.stage_type.trim().length) {
      setErrors((prev) => {
        const errors = { ...prev, stage_type: "Please select stage type" };
        return errors;
      });
      error++;
    }
    if (stageDetails.stage_type && stageDetails.stage_type.trim().length) {
      setErrors((prev) => {
        const errors = { ...prev, stage_type: null };
        return errors;
      });
      if (error) {
        error--;
      }
    }

    if ((errors.stage_name === null && errors.stage_type === null) || !error) {
      setErrors({});
      return 1;
    } else {
      return 0;
    }
  };

  const addStageToDBHandler = async (e, stage) => {
    e.preventDefault();
    const top_banner = stage.top_banner
      ? await uploadFile(stage.top_banner)
      : null;
    const bottom_banner = stage.bottom_banner
      ? await uploadFile(stage.bottom_banner)
      : null;
    const stage_background = stage.stage_background
      ? await uploadFile(stage.stage_background)
      : null;

    const stage_data = {
      stage_name: stage.stage_name ?? "",
      slug: stage.stage_name.toLowerCase().replace(/ /g, ""),
      stage_description: stage.stage_description ?? "",
      stage_type: stage.stage_type ?? "",
      top_banner,
      bottom_banner,
      stage_background,
      stream_link: stage.stream_link ?? "",
    };
    try {
      await firebase
        .firestore()
        .collection(`/events/${props.eventId}/stages`)
        .doc()
        .set(
          {
            ...stage_data,
            order_index: order ?? "",
          },
          { merge: true }
        );
      toast.success("Stage added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setStageDetails({});
    } catch (e) {
      console.error(e);
      toast.error("Error adding stage", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setShowAddStageModal(false);
      setStageDetails({});
    }
  };

  const updateStageToDBHandler = async (e, stage) => {
    e.preventDefault();
    const top_banner = stage.top_banner
      ? await uploadFile(stage.top_banner)
      : null;
    const bottom_banner = stage.bottom_banner
      ? await uploadFile(stage.bottom_banner)
      : null;
    const stage_background = stage.stage_background
      ? await uploadFile(stage.stage_background)
      : null;

    const stage_data = {
      stage_name: stage.stage_name ?? "",
      stage_description: stage.stage_description ?? "",
      stage_type: stage.stage_type ?? "",
      top_banner,
      bottom_banner,
      stage_background,
      stream_link: stage.stream_link ?? "",
    };
    try {
      await firebase
        .firestore()
        .collection(`/events/${props.eventId}/stages`)
        .doc(stage.id)
        .update({
          ...stage_data,
        });
      toast.success("Stage updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setStageDetails({});
    } catch (e) {
      console.error(e);
      toast.error("Error updating stage", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setIsUpdating(false);
      setShowAddStageModal(false);
      setStageDetails({});
    }
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();

      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        userDetails?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        // onUploadProgress: (ProgressEvent) => {
        //   setState({
        //     percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        //   });
        // },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const convertYoutubeLinkToEmbedHandler = (input) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = input.match(regExp);
    if (match && match[2].length === 11) {
      return "https://www.youtube.com/embed/" + match[2];
    } else {
      return input;
    }
  };

  return (
    <>
      <div
        className="h-100 py-4 text-light"
        style={{ overflow: "hidden" }}
      >
        <div
          className="h-100 mt-3 text-light"
          style={{ overflow: "auto" }}
        >
          <h5>Stage Manager</h5>
          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <div></div>
              <div className="d-flex">
                <Button
                  className="btn-sm btn-secondary mr-2"
                  //   disabled
                  onClick={() => setShowAddStageModal(true)}
                >
                  + New stage
                </Button>
              </div>
            </div>
            <div className="mt-3 small">
              <Table borderless responsive className="text-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Stage name</th>
                    <th>Visibility</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {stageSnapshot?.docs.map((stage, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{stage.data().stage_name}</td>
                        <td>{stage.data().stage_type}</td>

                        <td>
                          <Button
                            className="btn-sm btn-light mr-2"
                            onClick={(e) => {
                              setIsUpdating(true);
                              setStageDetails({
                                ...stage.data(),
                                id: stage.id,
                              });
                              setShowAddStageModal(true);
                            }}
                          >
                            Edit
                          </Button>
                          <Button disabled className="btn-sm btn-danger">
                            Remove
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* modal for add stage */}

      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAddStageModal}
        onHide={() => {
          setIsUpdating(false);
          setErrors({});
          setStageDetails({});
          setShowAddStageModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {!isUpdating ? "Create a Stage" : "Update Stage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Stage name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Stage Name"
                name="stage_name"
                onKeyUp={(e) => validationHandler(e, stageDetails)}
                onBlur={(e) => validationHandler(e, stageDetails)}
                value={stageDetails.stage_name}
                onChange={(e) => saveTextDataToStateHandler(e)}
              />
              {errors.stage_name ? (
                <div className="text-danger">{errors.stage_name}</div>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Description"
                name="stage_description"
                value={stageDetails.stage_description}
                onChange={(e) => saveTextDataToStateHandler(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Stream link</Form.Label>
              <Form.Control
                type="url"
                placeholder="Stream Link"
                name="stream_link"
                value={stageDetails.stream_link}
                onChange={(e) => saveTextDataToStateHandler(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Stage type</Form.Label>
              <Form.Control
                as="select"
                placeholder="Stage type"
                name="stage_type"
                value={stageDetails.stage_type}
                onChange={(e) => {
                  saveTextDataToStateHandler(e);
                }}
                onKeyUp={(e) => validationHandler(e, stageDetails)}
                onBlur={(e) => validationHandler(e, stageDetails)}
              >
                <option selected disabled>
                  Stage type
                </option>
                <option value="inperson">In-Person</option>
                <option value="online">Online</option>
              </Form.Control>
              {errors.stage_type ? (
                <div className="text-danger">{errors.stage_type}</div>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>Background</Form.Label>
              <FormFileInput
                name="stage_background"
                onChange={(e) => saveFileToStateHandler(e)}
              ></FormFileInput>
            </Form.Group>
            {!isUpdating ? (
              <Button
                variant="primary"
                onClick={(e) => {
                  validationHandlerForm(e, stageDetails);
                  if (
                    errors.stage_name === null &&
                    errors.stage_type === null
                  ) {
                    addStageToDBHandler(e, stageDetails);
                  }
                }}
              >
                Create stage
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={(e) => {
                  // validationHandlerForm(e, stageDetails);
                  if (validationHandlerForm(e, stageDetails)) {
                    updateStageToDBHandler(e, stageDetails);
                  }
                }}
              >
                Update stage
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => ({
  eventId: state.eventId,
}))(Stage);
