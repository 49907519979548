import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { auth, firestore } from "../../../../firebase";
import { postData } from "../../../../utils";
import {
  ADMIN_ADD_MODULE,
  ADMIN_GET_MODULES,
  API_BASE_URL,
  ADMIN_UPDATE_MODULE,
  ADMIN_DELETE_MODULE,
  ADMIN_UPDATE_SUB_MODULE,
  ADMIN_DELETE_SUB_MODULE,
  ADMIN_REORDERING_MODULES,
} from "../../../../config";
import Module from "./Module";
import SubModulePreview from "./SubModulePreview";
import SubModuleAdd from "./SubModuleAdd";
import axios from "axios";

toast.configure();

function CourseBuilder(props) {
  const { eventId, currentEvent } = props;
  const currentUser = auth.currentUser;

  // STATES
  const [showAddModuleModal, setShowAddModuleModal] = useState(false);
  const [moduleData, setModuleData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [modules, setModules] = useState([]);
  const [fetchingModules, setFetchingModules] = useState(false);
  const [showDeleteModuleModal, setShowDeleteModuleModal] = useState(false);
  const [selectedModuleData, setSelectedModuleData] = useState({});
  const [deletingModule, setDeletingModule] = useState(false);
  const [showEditModuleModal, setShowEditModuleModal] = useState(false);
  const [editingModule, setEditingModule] = useState(false);
  const [subModuleOpen, setSubModuleOpen] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [selectedSubModuleType, setSelectedSubModuleType] = useState(null);
  const [fetchingSubModuleData, setFetchingSubModuleData] = useState(false);
  const [selectedSubModuleData, setSelectedSubModuleData] = useState(null);
  const [showAddSubModuleSection, setShowAddSubModuleSection] = useState(false);
  const [showAddScheduleModal, setShowAddScheduleModule] = useState(false);
  const [showAddAssignmentModal, setShowAddAssignmentModule] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddQuizModal, setShowAddQuizModule] = useState(false);
  const [showAddResource, setShowAddResource] = useState(false);
  const [newSubModuleDetails, setNewSubModuleDetails] = useState({});

  // FUNCTIONS
  const handleShowModuleModal = () => {
    setShowAddModuleModal(true);
  };

  const handleHideModuleModal = () => {
    setShowAddModuleModal(false);
    setModuleData({});
    setSubmitting(false);
  };

  const handleAddModuleFormSubmit = async (e) => {
    e.preventDefault();

    if (moduleData.module_name) {
      try {
        const response = await postData(`${API_BASE_URL}${ADMIN_ADD_MODULE}`, {
          event_id: eventId,
          user_id: currentUser.uid,
          module_name: moduleData.module_name,
          order_index: modules?.length ? modules?.length + 1 : 1,
        });

        if (response.status === 200) {
          setModuleData({});
          setShowAddModuleModal(false);

          toast.success("Module successfully added.", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setSubmitting(false);
        } else {
          setSubmitting(false);
        }
      } catch (error) {
        console.log("[Error] Creating module: ", error);
        toast.warning("[Error] Creating module", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSubmitting(false);
      }
    } else {
      toast.warning("Please enter mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleShowEditModuleModal = (data) => {
    setShowEditModuleModal(true);
    setModuleData(data);
  };

  const handleHideEditModuleModal = () => {
    setShowEditModuleModal(false);
    setModuleData({});
    setEditingModule(false);
  };

  const handleEditModuleFormSubmit = async (e) => {
    e.preventDefault();

    if (moduleData.module_name) {
      try {
        console.log("[Try] Updating module");
        const response = await postData(
          `${API_BASE_URL}${ADMIN_UPDATE_MODULE}`,
          {
            event_id: eventId,
            user_id: currentUser?.uid,
            module_id: moduleData?.id,
            module_name: moduleData.module_name,
            order_index: moduleData?.order_index,
          }
        );

        if (response.status === 200) {
          handleHideEditModuleModal();
          console.log("[Success] Updating module");

          toast.success("Module successfully added.", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          setEditingModule(false);
        }
      } catch (error) {
        console.log("[Error] Updating module: ", error);
        toast.warning("[Error] Updating module", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setEditingModule(false);
      }
    } else {
      toast.warning("Please enter mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleShowDeleteModuleModal = (data) => {
    setShowDeleteModuleModal(true);
    setSelectedModuleData(data);
  };

  const handleHideDeleteModuleModal = () => {
    setShowDeleteModuleModal(false);
    setSelectedModuleData({});
    setDeletingModule(false);
  };

  const handleDeleteModuleSubmit = async (e) => {
    try {
      setDeletingModule(true);
      console.log("[Try] Deleting module");

      const response = await postData(`${API_BASE_URL}${ADMIN_DELETE_MODULE}`, {
        event_id: currentEvent?.id,
        user_id: currentUser?.uid,
        module_id: selectedModuleData?.id,
        order_index: selectedModuleData?.order_index,
      });

      if (response.status === 200) {
        console.log("[Success] Deleting module");

        handleHideDeleteModuleModal();
        toast.dark("Module successfully deleted.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        setDeletingModule(false);
      }
    } catch (error) {
      console.log("[Error] Deleting module: ", error);
      toast.warning("[Error] Deleting module", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setDeletingModule(false);
    }
  };

  const handleFetchModules = async () => {
    setFetchingModules(true);
    try {
      const response = await postData(`${API_BASE_URL}${ADMIN_GET_MODULES}`, {
        event_id: eventId,
        user_id: currentUser.uid,
      });

      if (response.status === 200) {
        const { data } = response;

        setModules(data);
        setSelectedModuleData((prev) => {
          if (prev?.hasOwnProperty("id")) return prev;
          else return data[0];
        });
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  // Function: Close Sub-module open
  const handleCloseSubModule = () => {
    setSubModuleOpen(false);
    setFetchingSubModuleData(false);
    setSelectedSubModuleData(null);
  };

  // Function: Edit Sub-module from Module
  const handleEditSubModule = async (
    data,
    callbackSuccess,
    callbackFailure
  ) => {
    try {
      if (!data?.title) {
        toast.warning("Please add Title for Item.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
        return;
      }

      const response = await postData(
        `${API_BASE_URL}${ADMIN_UPDATE_SUB_MODULE}`,
        {
          event_id: data.ancestor_id,
          user_id: auth.currentUser.uid,
          sub_module_id: data.id,
          title: data?.title,
          description: data?.description,
        }
      );

      if (response.status === 200) {
        toast.dark("Item successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        callbackSuccess();
      }
    } catch (error) {
      console.log("[Error] Updating Item: ", error);
      toast.warning("Error updating Item.", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });
      callbackFailure();
    }
  };

  // Function: Remove Sub-module from Module
  const handleRemoveSubModule = async (data) => {
    try {
      const response = await postData(
        `${API_BASE_URL}${ADMIN_DELETE_SUB_MODULE}`,
        {
          event_id: data.ancestor_id,
          user_id: auth.currentUser.uid,
          sub_module_id: data.id,
          module_id: data.parent_id,
          order_index: data.order_index,
        }
      );

      if (response.status === 200) {
        toast.dark("Item successfully removed.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("[Error] Removing Item: ", error);
      toast.warning("Error removing Item.", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });
    }
  };

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "white" : "white",
    // display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "black" : "",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleReorderingModules = async (e) => {
    const { destination, source } = e;
    const to = destination.index;
    const from = source.index;
    const product_id = destination?.droppableId;

    if (from != to) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}${ADMIN_REORDERING_MODULES}`,
          {
            from,
            to,
            product_id,
            user_id: auth.currentUser.uid,
          }
        );

        if (response.status === 200) {
          toast.success("Reordering of modules completed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log(`[Error] Reordering modules: ${error.message}`);
        toast.danger(`Error reordering modules: ${error.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    }
  };

  useEffect(() => {
    let unsubscribe_modules = firestore
      .collection("modules")
      .where("parent_id", "==", eventId)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    let unsubscribe_submodules = firestore
      .collection("subModules")
      .where("ancestor_id", "==", eventId)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    return () => {
      unsubscribe_modules();
      unsubscribe_submodules();
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row text-light" style={{ height: "100vh" }}>
          {/* Section start: Module list */}
          <div
            className={
              subModuleOpen
                ? "col-md-3 bg-gradient-grey-dark h-100 p-0"
                : "col-md-4 bg-gradient-grey-dark h-100 p-0"
            }
            style={{ overflowY: "scroll" }}
          >
            <div className="border-bottom p-3 border-dark">
              <h4 className="mb-0">Course Modules</h4>
            </div>
            <div className="p-3">
              {modules?.length === 0 && fetchingModules ? (
                <p className="text-center">
                  <Spinner animation="border" size="sm" /> Fetching modules...
                </p>
              ) : null}

              <DragDropContext onDragEnd={handleReorderingModules}>
                <Droppable
                  droppableId={`${currentEvent?.id}`}
                  direction="vertical"
                >
                  {(provided, snapshot) => (
                    <div
                      className={
                        snapshot.isDraggingOver ? "border border-primary" : ""
                      }
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {modules?.length > 0 ? (
                        modules?.map((m, key) => (
                          <Draggable
                            key={`${key}`}
                            draggableId={`${key}`}
                            index={m.order_index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={
                                  snapshot.isDragging
                                    ? "rounded border border-white"
                                    : "rounded"
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Card
                                  className={
                                    selectedModuleData?.id === m?.id
                                      ? "border-light bg-dark mb-2"
                                      : "bg-dark mb-2"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setSelectedModuleData(m)}
                                >
                                  <Card.Body className="p-0">
                                    <div
                                      key={key}
                                      className="p-3 d-flex justify-content-between"
                                    >
                                      <p className="mb-0">
                                        <FontAwesomeIcon
                                          icon={faBars}
                                          className="mr-2 pointer small"
                                        />{" "}
                                        {m.name}
                                      </p>
                                      <div>
                                        <FontAwesomeIcon
                                          onClick={() =>
                                            handleShowEditModuleModal({
                                              ...m,
                                              module_name: m.name,
                                            })
                                          }
                                          icon={faEdit}
                                          className="mr-2 pointer small"
                                        />
                                        <FontAwesomeIcon
                                          onClick={() =>
                                            handleShowDeleteModuleModal(m)
                                          }
                                          icon={faTrash}
                                          className="text-danger pointer small"
                                        />
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <p className="text-center">
                          Start creating modules by clicking on{" "}
                          <b className="text-uppercase">Add</b> button on the
                          top.
                        </p>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {/* {modules?.length > 0 ? (
                modules?.map((m, k) => (
                  <Card
                    className={
                      selectedModuleData?.id === m?.id
                        ? "border-light bg-dark mb-2"
                        : "bg-dark mb-2"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedModuleData(m)}
                  >
                    <Card.Body className="p-0">
                      <div
                        key={k}
                        className="p-3 d-flex justify-content-between"
                      >
                        <p className="mb-0">
                          <FontAwesomeIcon
                            icon={faBars}
                            className="mr-2 pointer small"
                          />{" "}
                          {m.name}
                        </p>
                        <div>
                          <FontAwesomeIcon
                            onClick={() =>
                              handleShowEditModuleModal({
                                ...m,
                                module_name: m.name,
                              })
                            }
                            icon={faEdit}
                            className="mr-2 pointer small"
                          />
                          <FontAwesomeIcon
                            onClick={() => handleShowDeleteModuleModal(m)}
                            icon={faTrash}
                            className="text-danger pointer small"
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <p className="text-center">
                  Start creating modules by clicking on{" "}
                  <b className="text-uppercase">Add</b> button on the top.
                </p>
              )} */}

              <Button
                size="sm"
                className="text-uppercase w-100"
                onClick={handleShowModuleModal}
              >
                Add New Module
              </Button>
            </div>
          </div>
          {/* Section end: Module list */}

          {/* Section start: Sub-module list */}
          {!showAddSubModuleSection && (
            <div
              className={
                subModuleOpen ? "col-md-4 h-100 p-0" : "col-md-8 h-100 p-0"
              }
              style={{ overflowY: "scroll" }}
            >
              {selectedModuleData?.hasOwnProperty("id")
                ? modules
                    ?.filter((e) => e.id === selectedModuleData.id)
                    ?.map((item, key) => (
                      <Module
                        module_data={item}
                        module_idx={key}
                        className="mb-3"
                        setSubModuleOpen={setSubModuleOpen}
                        selectedModuleId={selectedModuleId}
                        setSelectedModuleId={setSelectedModuleId}
                        setSelectedSubModuleType={setSelectedSubModuleType}
                        setFetchingSubModuleData={setFetchingSubModuleData}
                        selectedSubModuleData={selectedSubModuleData}
                        setSelectedSubModuleData={setSelectedSubModuleData}
                        handleCloseSubModule={handleCloseSubModule}
                        handleEditSubModule={handleEditSubModule}
                        handleRemoveSubModule={handleRemoveSubModule}
                        setShowAddSubModuleSection={setShowAddSubModuleSection}
                        setShowAddAssignmentModule={setShowAddAssignmentModule}
                        setShowAddForm={setShowAddForm}
                        setShowAddQuizModule={setShowAddQuizModule}
                        setShowAddScheduleModule={setShowAddScheduleModule}
                        setShowAddResource={setShowAddResource}
                        setNewSubModuleDetails={setNewSubModuleDetails}
                      />
                    ))
                : modules?.length > 0 &&
                  [modules[0]]?.map((item, key) => (
                    <Module
                      module_data={item}
                      module_idx={key}
                      className="mb-3"
                      setSubModuleOpen={setSubModuleOpen}
                      selectedModuleId={selectedModuleId}
                      setSelectedModuleId={setSelectedModuleId}
                      setSelectedSubModuleType={setSelectedSubModuleType}
                      setFetchingSubModuleData={setFetchingSubModuleData}
                      selectedSubModuleData={selectedSubModuleData}
                      setSelectedSubModuleData={setSelectedSubModuleData}
                      handleCloseSubModule={handleCloseSubModule}
                      handleEditSubModule={handleEditSubModule}
                      handleRemoveSubModule={handleRemoveSubModule}
                      setShowAddSubModuleSection={setShowAddSubModuleSection}
                      setShowAddAssignmentModule={setShowAddAssignmentModule}
                      setShowAddForm={setShowAddForm}
                      setShowAddQuizModule={setShowAddQuizModule}
                      setShowAddScheduleModule={setShowAddScheduleModule}
                      setShowAddResource={setShowAddResource}
                      setNewSubModuleDetails={setNewSubModuleDetails}
                    />
                  ))}
            </div>
          )}
          {/* Section end: Sub-module list */}

          {/* Section start: Sub-module */}
          {subModuleOpen && (
            <div className="col-md-5 h-100 p-0 bg-gradient-grey-dark">
              <SubModulePreview
                handleCloseSubModule={handleCloseSubModule}
                fetchingSubModuleData={fetchingSubModuleData}
                selectedSubModuleType={selectedSubModuleType}
                selectedSubModuleData={selectedSubModuleData}
              />
            </div>
          )}
          {/* Section end: Sub-module */}

          {/* Section start: Add Sub-module to a Module */}
          {showAddSubModuleSection && (
            <div className="col-md-8 h-100 p-0">
              <SubModuleAdd
                currentEvent={currentEvent}
                eventId={eventId}
                module_data={
                  selectedModuleData?.hasOwnProperty("id")
                    ? modules?.filter((e) => e.id === selectedModuleData.id)
                        ?.length > 0
                      ? modules?.filter(
                          (e) => e.id === selectedModuleData.id
                        )[0]
                      : {}
                    : modules?.length > 0
                    ? modules[0]
                    : {}
                }
                handleRemoveSubModule={handleRemoveSubModule}
                showAddScheduleModal={showAddScheduleModal}
                setShowAddScheduleModule={setShowAddScheduleModule}
                showAddAssignmentModal={showAddAssignmentModal}
                showAddForm={showAddForm}
                setShowAddAssignmentModule={setShowAddAssignmentModule}
                setShowAddForm={setShowAddForm}
                setShowAddSubModuleSection={setShowAddSubModuleSection}
                showAddQuizModal={showAddQuizModal}
                setShowAddQuizModule={setShowAddQuizModule}
                showAddResource={showAddResource}
                setShowAddResource={setShowAddResource}
                newSubModuleDetails={newSubModuleDetails}
                setNewSubModuleDetails={setNewSubModuleDetails}
              />
            </div>
          )}
          {/* Section end: Add Sub-module to a Module */}
        </div>
      </div>

      {/* Modal: Add module */}
      <Modal show={showAddModuleModal}>
        <Modal.Header closeButton onHide={handleHideModuleModal}>
          <div>
            <h5 className="mb-0">Add Module</h5>
            <p className="mb-0 text-muted small">
              Marked fields (<span className="text-danger">*</span>) are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddModuleFormSubmit}>
            <Form.Group>
              <Form.Label className="text-uppercase text-muted small font-weight-bold">
                Module name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="module_name"
                id="module_name"
                type="text"
                placeholder="Enter module name"
                onChange={(e) => {
                  setModuleData((prev) => {
                    let newState = { ...prev };
                    newState[e.target.name] = e.target.value;

                    return newState;
                  });
                }}
              />
            </Form.Group>

            <div className="d-flex">
              <Button
                size="sm"
                className="text-uppercase mr-2"
                type="submit"
                disabled={submitting}
              >
                {submitting ? <span>Submitting...</span> : <span>Submit</span>}
              </Button>
              <Button
                size="sm"
                className="text-uppercase shadow-sm"
                variant="light"
                disabled={submitting}
                onClick={handleHideModuleModal}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal: Edit module */}
      <Modal show={showEditModuleModal}>
        <Modal.Header closeButton onHide={handleHideEditModuleModal}>
          <div>
            <h5 className="mb-0">Edit Module</h5>
            <p className="mb-0 text-muted small">
              Marked fields (<span className="text-danger">*</span>) are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditModuleFormSubmit}>
            <Form.Group>
              <Form.Label className="text-uppercase text-muted small font-weight-bold">
                Module name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="module_name"
                id="module_name"
                type="text"
                placeholder="Enter module name"
                value={moduleData.module_name}
                onChange={(e) => {
                  setModuleData((prev) => {
                    let newState = { ...prev };
                    newState[e.target.name] = e.target.value;

                    return newState;
                  });
                }}
              />
            </Form.Group>

            <div className="d-flex">
              <Button
                size="sm"
                className="text-uppercase mr-2"
                type="submit"
                disabled={editingModule}
              >
                {editingModule ? (
                  <span>Submitting...</span>
                ) : (
                  <span>Submit</span>
                )}
              </Button>
              <Button
                size="sm"
                className="text-uppercase shadow-sm"
                variant="light"
                disabled={editingModule}
                onClick={handleHideEditModuleModal}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal: Delete module */}
      <Modal show={showDeleteModuleModal}>
        <Modal.Header closeButton onHide={handleHideDeleteModuleModal}>
          <div>
            <h5 className="mb-1">{selectedModuleData?.name}</h5>
            <p className="small mb-0 text-muted text-uppercase">
              Confirm deletion
            </p>
          </div>
        </Modal.Header>
        {selectedModuleData && (
          <Modal.Body>
            <p className="lead">
              Are you sure you want to delete? Delete operation cannot be
              reversed.
            </p>
            <p>
              <b>Note: </b>Any sub-modules linked to module{" "}
              <b>{selectedModuleData?.name}</b> will be unlinked.
            </p>
            <div className="d-flex justify-content-end">
              <Button
                variant="light"
                className="btn-sm mr-2 rounded-lg text-uppercase border border-muted"
                onClick={handleHideDeleteModuleModal}
                disabled={deletingModule}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="btn-sm rounded-lg text-uppercase"
                onClick={handleDeleteModuleSubmit}
                disabled={deletingModule}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(CourseBuilder);
