import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router";
import { Skeleton } from "@material-ui/lab";
import parse from "html-react-parser";
import * as TimelineStyle from "./timeline.module.css";
import moment from "moment";
import { postData } from "../../utils";
import { API_BASE_URL, EVENTS_LIST } from "../../config";

export default function Events() {
  const history = useHistory();

  const [eventList, setEventList] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);

  useEffect(async () => {
    const response = await postData(`${API_BASE_URL}${EVENTS_LIST}`, {
      limit: 3,
      is_published: true,
      type: 0,
    });

    if (response.status === 200) {
      const { data } = response;

      setEventList(data.list);
      setEventsLoading(false);
    }
  }, []);

  const LoadingSkeleton = () => {
    return [1, 2, 3].map((item) => (
      <div className="col-md-4 pl-0" key={item}>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded"
          height={150}
        />
        <Skeleton
          animation="wave"
          className="bg-muted rounded mt-1"
          height={24}
        />
        <Skeleton animation="wave" className="bg-muted rounded" height={24} />
        <Skeleton
          animation="wave"
          className="bg-muted rounded w-50"
          height={20}
        />
      </div>
    ));
  };

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  return (
    <>
      <div className="col-md-12  mb-4">
        <div className="gradient-box bg-gradient-light pb-5">
          <div className="row mb-5">
            <div className="col-md-6">
              <h3 className="mb-3  text-s-7 text-blue">
                Organise hybrid events.
              </h3>
              <p className="text-s-6">
                <span style={{ color: "#333333" }}>
                  Customize your events to engage your community. Create your
                  unique registration pages and sell tickets with different
                  tiers and access levels.{" "}
                </span>
                <br /> <br />
                <span style={{ color: "#888888" }}>
                  Manage payments with Igesia or connect your own Stripe
                  account. Host your speakers on Zoom or Igesia video. Create
                  booths for sponsors.{" "}
                </span>
              </p>
              <a
                // onClick={handleShow}
                href="/events"
                style={{
                  background: "rgb(118, 60, 247)",
                  borderRadius: "12px",
                }}
                className="btn text-white decoration-none font-weight-bold mb-3"
              >
                Explore Events
              </a>
              <a
                // onClick={handleShow}
                href="/courses"
                style={{}}
                className="btn text-purple decoration-none font-weight-bold mb-3"
              >
                Request a Demo
              </a>
            </div>
            <div className="col-md-6">
              <div className="text-center">
                <img
                  style={{ marginRight: "0px" }}
                  src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-event-registration-mockup.png"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <h5 className="font-weight-bold ">Discover events on igesia</h5>
            </div>
            <div className="col-md-12">
              <div className="d-flex" style={{ overflowX: "scroll" }}>
                {eventsLoading && <LoadingSkeleton />}

                {eventList
                  .sort((a, b) => b.timestamp - a.timestamp)
                  .slice(0, 5)
                  .map((event, key) => (
                    <div className="col-md-4 pl-0" key={key}>
                      <div
                        className="card event-card border-0  h-100"
                        onClick={() => history.push(`event/${event.slug}`)}
                        title={`Event: ${
                          event?.event_details
                            ? event?.event_details.EN?.event_name
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="rounded-top"
                          style={{
                            backgroundImage: `url(${event?.event_details?.featured_image})`,
                            height: 150,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            className: "card-img-top bg-gradient-purple",
                          }}
                        />
                        <div className="card-body bg-light">
                          <h3 className="text-s-7 text-dark">
                            {event?.event_details.EN?.event_name}
                          </h3>
                          <p className="font-weight-bold small mb-2 text-deep-purple">
                            {moment(
                              convertSecondstoMilliSeconds(
                                event?.event_details?.start_date?._seconds
                              )
                            ).format("DD MMMM YYYY")}
                          </p>
                          {event?.event_details?.EN?.small_description && (
                            <span className="small mb-0 text-secondary">
                              {parse(
                                event?.event_details?.EN?.small_description
                              )}
                            </span>
                          )}

                          {/* <Link to={process.env.PUBLIC_URL + "/eventpage"} className="text-decoration-none">
                                        <div className="row shadow-lg py-2 mx-1 bg-gradient-grey-dark rounded mt-3 btn-hero">
                                            <div className="col-6">
                                                <span className="mb-0 d-block text-s-7 mb-0 text-light">Mar 16</span>
                                                <span className="mb-0 text-s-9 text-light">11:25PM IST</span>
                                            </div>
                                            <div className="col-6 text-right align-self-center">
                                                <span className="text-s-8 text-purple text-decoration-none">₹0.00 ~ ₹600</span>
                                            </div>
                                        </div>
                                    </Link> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-12 text-center">
              <Button
                className="mt-5"
                variant="none"
                size="sm"
                onClick={() => history.push("events")}
              >
                <span className="text-purple font-weight-bold ">
                  SEE MORE EVENTS
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
