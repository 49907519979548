import React from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router";

import * as TimelineStyle from "./timeline.module.css";
import { consultList } from "../data";

export default function Consultations() {
    const history = useHistory();
    
    return (
        <div className="container pt-3 pt-md-4">
        <Card className={`shadow-sm ${TimelineStyle.cta_card}`}>
          <Card.Header className="border-0 d-flex align-items-center justify-content-between">
            <h4 className="text-white mb-0 font-weight-bold">Consultations</h4>
            <Button
              className=""
              variant="none"
              size="sm"
              onClick={() => history.push("consultations")}
            >
              <span className="text-primary font-weight-bold">See all</span>
            </Button>
          </Card.Header>
          <Card.Body>
            <div className="d-flex" style={{ overflowX: "scroll" }}>
              {consultList.map((course, key) => (
                <div className="col-md-4 pl-0" key={key}>
                  <Card
                    className={`h-100 ${TimelineStyle.services_cards}`}
                    onClick={() => history.push(`consultation/${course.slug}`)}
                    style={{ cursor: "pointer" }}
                    title={`Consultation: ${course.name}`}
                  >
                    <div
                      className="rounded-top"
                      style={{
                        backgroundImage: `url(${course.image})`,
                        height: 150,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    />
                    <Card.Body className="py-2 px-3">
                      <p className="font-weight-bold text-white mb-1">
                        {course.name}
                      </p>
                      <p className="font-weight-bold small mb-0 text-warning">
                        {course.date}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </div>
    )
}
