import React from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function CoursePlans(props) {
  const history = useHistory();
  const { country } = props;

  return (
    <div
      className="container-fluid py-5"
      style={{
        backgroundColor: "#F7F7F7",
        overflow: "hidden",
      }}
    >
      <div className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <h2 className="mb-3 text-s-1 text-danger">Courses</h2>
            <p className="text-s-4 text-black-333">
              Create and offer courses and customized learning experiences.
              Benefit from learning apps – such as quizzes, assignments and
              gradebook delivered with the Igesia platform.
            </p>
          </div>
        </div>

        <div className="mt-3 mt-md-4">
          <div className="">
            <div className="mb-3">
              <h2>Select a preset plan</h2>
            </div>
            <div className="row">
              <div className="col-4">
                <Card className="">
                  <Card.Header>
                    <h3 className="mb-0">Free</h3>
                  </Card.Header>
                  <Card.Body>
                    <h3>{country === "IN" ? "Free" : "Free"}</h3>
                    <ul className="px-3">
                      <li className="font-weight-bold">Upto 10 participants</li>
                      <li>Create custom registration pages</li>
                      <li>Integrate your Zoom account</li>
                      <li>Participant dashboard</li>
                      <li>Participant networking</li>
                      <li>Assessment apps</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    {/* <Button
                      className="w-100 text-uppercase"
                      variant="success"
                      onClick={() => {
                        history.push("admin");
                      }}
                    >
                      Get Started
                    </Button> */}
                    <a
                      className="btn btn-primary w-100 text-uppercase"
                      href="mailto:sales@igesia.co"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </Card.Footer>
                </Card>
              </div>

              <div className="col-4">
                <Card className="">
                  <Card.Header>
                    <h3 className="mb-0">Basic</h3>
                  </Card.Header>
                  <Card.Body>
                    <h3>{country === "IN" ? "₹10,000/-" : "USD 500"}</h3>
                    <ul className="px-3">
                      <li className="font-weight-bold">
                        Upto 100 participants
                      </li>
                      <li>Create custom registration pages</li>
                      <li>Integrate your Zoom account</li>
                      <li>Participant dashboard</li>
                      <li>Participant networking</li>
                      <li>Assessment apps</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    <a
                      className="btn btn-primary w-100 text-uppercase"
                      href="mailto:sales@igesia.co"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </Card.Footer>
                </Card>
              </div>

              <div className="col-4">
                <Card className="">
                  <Card.Header>
                    <h3 className="mb-0">Pro</h3>
                  </Card.Header>
                  <Card.Body>
                    <h3>{country === "IN" ? "₹35,000/-" : "USD 1500"}</h3>
                    <ul className="px-3">
                      <li className="font-weight-bold">
                        Upto 1000 participants
                      </li>
                      <li>Create custom registration pages</li>
                      <li>Integrate your Zoom account</li>
                      <li>Participant dashboard</li>
                      <li>Participant networking</li>
                      <li>Assessment apps</li>
                    </ul>
                  </Card.Body>
                  <Card.Footer>
                    <a
                      className="btn btn-primary w-100 text-uppercase"
                      href="mailto:sales@igesia.co"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </Card.Footer>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursePlans;
