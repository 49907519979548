import React, { useState, useEffect, useMemo } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams, useHistory } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";

import LeftBar from "../LeftBar";
import NavBar from "../NavBar";

import {
  API_BASE_URL,
  USER_GET_SUB_MODULE_DATA,
  USER_GET_MODULES,
  USER_COURSE_PROGRESS,
  USER_SUB_MODULE_PROGRESS,
} from "../../../../config/index";
import { auth, firestore } from "../../../../firebase";

import Module from "./Module";
import File from "./File";
import RecordedLecture from "./RecordedLecture";
import LiveSession from "./LiveSession";
import Assignment from "./Assignment";
import Quiz from "./Quiz";
import { toast } from "react-toastify";
import Form from "./Form";

const SubModulePage = ({ darkTheme, currentEvent }) => {
  // CONSTANTS
  const { id, module_id, sub_module_type, sub_module_id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  // STATES
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [modules, setModules] = useState([]);
  const [fetchingModules, setFetchingModules] = useState(false);
  const [fetchingModulesProgress, setFetchingModulesProgress] = useState(true);
  const [modulesProgress, setModulesProgress] = useState([]);
  const [showCourseContent, setShowCourseContent] = useState(true);
  const [key, setKey] = useState("modules");
  const [nextItem, setNextItem] = useState(null);
  const [prevItem, setPrevItem] = useState(null);

  // FUNCTIONS
  const handleFetchSubModule = async () => {
    if (sub_module_id && auth?.currentUser) {
      setFetchingDetails(true);

      const response = await axios.get(
        `${API_BASE_URL}${USER_GET_SUB_MODULE_DATA}?user_id=${auth?.currentUser?.uid}&sub_module_id=${sub_module_id}`
      );

      if (response.status === 200) {
        setFetchingDetails(false);
        setResponseData(response?.data?.data);
      } else {
        setFetchingDetails(false);
      }
    }
  };

  const handleFetchModules = async () => {
    try {
      setFetchingModules(true);

      const response = await axios.post(`${API_BASE_URL}${USER_GET_MODULES}`, {
        event_id: currentEvent?.id,
      });

      if (response.status === 200) {
        const { data } = response.data;

        setModules(data);
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  const handleFetchProgress = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${USER_COURSE_PROGRESS}?ancestor_id=${currentEvent?.id}&user_id=${auth?.currentUser?.uid}`
      );

      if (response.status === 200) {
        const { data } = response.data;
        setModulesProgress(data);
        setFetchingModulesProgress(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching progress: ${error.message}`);
    }
  };

  useEffect(() => {
    handleFetchSubModule();
  }, [pathname]);

  useEffect(() => {
    if (modules?.length > 0) {
      const currentModule = modules?.filter((e) => e.id === module_id)[0];
      const currentSubModule = currentModule?.sub_modules?.filter(
        (e) => e.id === sub_module_id
      )[0];

      if (modules?.length > 1) {
        // More modules are there
        if (currentModule.order_index === 1) {
          // First module
          if (currentModule.sub_modules.length > 1) {
            if (currentSubModule.order_index === 1) {
              setPrevItem(false);

              const nextSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index + 1
              )[0];

              setNextItem(nextSubModule);
            } else if (
              currentSubModule.order_index < currentModule.sub_modules.length
            ) {
              const prevSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index - 1
              )[0];

              setPrevItem(prevSubModule);

              const nextSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index + 1
              )[0];

              setNextItem(nextSubModule);
            } else {
              const prevSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index - 1
              )[0];

              setPrevItem(prevSubModule);

              const nextModule = modules?.filter(
                (e) => e.order_index === currentModule.order_index + 1
              )[0];
              const nextSubModule = nextModule?.sub_modules[0];

              setNextItem(nextSubModule);
            }
          } else {
            setPrevItem(false);

            const nextModule = modules?.filter(
              (e) => e.order_index === currentModule.order_index + 1
            )[0];
            const nextSubModule = nextModule?.sub_modules[0];

            setNextItem(nextSubModule);
          }
        } else if (currentModule.order_index < modules?.length) {
          // Not last module
          if (currentModule.sub_modules.length > 1) {
            if (currentSubModule.order_index === 1) {
              const prevModule = modules?.filter(
                (e) => e.order_index === currentModule.order_index - 1
              )[0];
              const prevSubModule =
                prevModule?.sub_modules?.[prevModule?.sub_modules?.length - 1];

              setPrevItem(prevSubModule);

              const nextSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index + 1
              )[0];

              setNextItem(nextSubModule);
            } else if (
              currentSubModule.order_index < currentModule.sub_modules.length
            ) {
              const prevSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index - 1
              )[0];

              setPrevItem(prevSubModule);

              const nextSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index + 1
              )[0];

              setNextItem(nextSubModule);
            } else {
              const prevSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index - 1
              )[0];

              setPrevItem(prevSubModule);

              const nextModule = modules?.filter(
                (e) => e.order_index === currentModule.order_index + 1
              )[0];
              const nextSubModule = nextModule?.sub_modules[0];

              setNextItem(nextSubModule);
            }
          } else {
            // Single sub-module
            const prevModule = modules?.filter(
              (e) => e.order_index === currentModule.order_index - 1
            )[0];
            const prevSubModule =
              prevModule?.sub_modules?.[prevModule?.sub_modules?.length - 1];

            setPrevItem(prevSubModule);

            const nextModule = modules?.filter(
              (e) => e.order_index === currentModule.order_index + 1
            )[0];
            const nextSubModule = nextModule?.sub_modules[0];

            setNextItem(nextSubModule);
          }
        } else {
          // Last module
          if (currentModule.sub_modules.length > 1) {
            if (currentSubModule.order_index === 1) {
              const prevModule = modules?.filter(
                (e) => e.order_index === currentModule.order_index - 1
              )[0];
              const prevSubModule =
                prevModule?.sub_modules?.[prevModule?.sub_modules?.length - 1];

              setPrevItem(prevSubModule);

              const nextSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index + 1
              )[0];

              setNextItem(nextSubModule);
            } else if (
              currentSubModule.order_index < currentModule.sub_modules.length
            ) {
              const prevSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index - 1
              )[0];

              setPrevItem(prevSubModule);

              const nextSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index + 1
              )[0];

              setNextItem(nextSubModule);
            } else {
              const prevSubModule = currentModule?.sub_modules?.filter(
                (e) => e.order_index === currentSubModule.order_index - 1
              )[0];

              setPrevItem(prevSubModule);
              setNextItem(false);
            }
          } else {
            const prevModule = modules?.filter(
              (e) => e.order_index === currentModule.order_index - 1
            )[0];
            const prevSubModule =
              prevModule?.sub_modules?.[prevModule?.sub_modules?.length - 1];

            setPrevItem(prevSubModule);
            setNextItem(false);
          }
        }
      } else {
        // Single module
        if (currentModule.sub_modules.length > 1) {
          if (currentSubModule.order_index === 1) {
            setPrevItem(false);

            const nextSubModule = currentModule?.sub_modules?.filter(
              (e) => e.order_index === currentSubModule.order_index + 1
            )[0];

            setNextItem(nextSubModule);
          } else if (
            currentSubModule.order_index < currentModule.sub_modules.length
          ) {
            const prevSubModule = currentModule?.sub_modules?.filter(
              (e) => e.order_index === currentSubModule.order_index - 1
            )[0];

            setPrevItem(prevSubModule);

            const nextSubModule = currentModule?.sub_modules?.filter(
              (e) => e.order_index === currentSubModule.order_index + 1
            )[0];

            setNextItem(nextSubModule);
          } else {
            const prevSubModule = currentModule?.sub_modules?.filter(
              (e) => e.order_index === currentSubModule.order_index - 1
            )[0];

            setPrevItem(prevSubModule);
            setNextItem(false);
          }
        } else {
          setPrevItem(false);
          setNextItem(false);
        }
      }
    }
  }, [modules, module_id, sub_module_id]);

  useEffect(() => {
    let unsubscribe_modules = firestore
      .collection("modules")
      .where("parent_id", "==", currentEvent?.id)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    let unsubscribe_submodules = firestore
      .collection("subModules")
      .where("ancestor_id", "==", currentEvent?.id)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    let unsubscribe_progress = firestore
      .collection("subModulesProgress")
      .where("ancestor_id", "==", currentEvent?.id)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Progress
          handleFetchProgress();
        });
      });
    return () => {
      unsubscribe_modules();
      unsubscribe_submodules();
      unsubscribe_progress();
    };
  }, []);

  const currentProgress = useMemo(() => {
    return modulesProgress?.filter(
      (e) =>
        e.id === responseData?.sub_module_id &&
        e.parent_id === responseData?.sub_module_details?.parent_id
    )[0];
  }, [modulesProgress, responseData]);

  const Loader = () => {
    return (
      <div>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded-sm w-25 mb-2"
          height={20}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded-sm mb-2"
          height={32}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded-sm w-25 mb-3"
          height={32}
        />
        {sub_module_type === "lecture" && (
          <>
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted rounded-sm mb-2 embed-responsive embed-responsive-16by9 h-100"
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted rounded-sm mb-1"
              height={24}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted rounded-sm w-50"
              height={24}
            />
          </>
        )}
        {sub_module_type === "file" && (
          <>
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted rounded-sm"
              height={36}
            />
          </>
        )}
      </div>
    );
  };

  const handleOpenSubModule = (data) => {
    if (data) {
      history.push(
        `/event/${id}/module/${data?.parent_id}/${data?.type}/${data?.id}`
      );
    } else {
      return null;
    }
  };

  const handleSetProgress = async (data) => {
    const progressDetails = {
      ancestor_id: data?.ancestor_id,
      parent_id: data?.parent_id,
      child_id: sub_module_id,
      user_id: auth?.currentUser?.uid,
    };

    let additionalDetails = {};

    if (data.type === "schedule" || data.type === "file") {
      additionalDetails = { state: "complete" };
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}${USER_SUB_MODULE_PROGRESS}`,
        { ...progressDetails, ...additionalDetails }
      );

      if (response.status === 200) {
        const { data } = response.data;
        if (data?.status) {
          toast.dark(
            <div>
              {data?.state === "complete" ? (
                <p className="mb-0">🎊 Completed 🎊</p>
              ) : (
                <p className="mb-0">✅ Marked done</p>
              )}
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: false,
              autoClose: 3000,
            }
          );
        }
      }
    } catch (error) {
      console.log(`[Error] Setting progress: ${error.message}`);
    }
  };

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar progress={modulesProgress} />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className={
              showCourseContent
                ? "col-md-7 h-100 p-md-5 p-3 "
                : "col-md-11 h-100 p-md-5 p-3 "
            }
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            {fetchingDetails && <Loader />}
            {!fetchingDetails && (
              <div>
                <div style={{ minHeight: "35vh" }}>
                  <p className="text-uppercase text-muted small mb-1">
                    <b>{responseData?.resource_type}</b>
                  </p>
                  <h4>{responseData?.sub_module_details?.title}</h4>
                  {/* <p>{responseData?.resource_details?.file_content_type}</p> */}
                  {responseData?.resource_type === "quiz" && (
                    <Quiz
                      fetchingDetails={fetchingDetails}
                      current_progress={currentProgress}
                      resource_details={responseData?.resource_details}
                      sub_module_details={responseData?.sub_module_details}
                      module_id={module_id}
                      sub_module_id={sub_module_id}
                    />
                  )}
                  {responseData?.resource_type === "assignment" && (
                    <Assignment
                      fetchingDetails={fetchingDetails}
                      current_progress={currentProgress}
                      resource_details={responseData?.resource_details}
                      sub_module_details={responseData?.sub_module_details}
                      module_id={module_id}
                      sub_module_id={sub_module_id}
                    />
                  )}
                  {responseData?.resource_type === "schedule" && (
                    <LiveSession
                      id={id}
                      resource_details={responseData?.resource_details}
                      sub_module_details={responseData?.sub_module_details}
                    />
                  )}
                  {responseData?.resource_type === "file" && (
                    <File
                      fetchingDetails={fetchingDetails}
                      id={id}
                      resource_details={responseData?.resource_details}
                      sub_module_details={responseData?.sub_module_details}
                    />
                  )}
                  {responseData?.resource_type === "lecture" && (
                    <RecordedLecture
                      fetching_progress={fetchingModulesProgress}
                      current_progress={currentProgress}
                      resource_details={responseData?.resource_details}
                      sub_module_details={responseData?.sub_module_details}
                      module_id={module_id}
                      sub_module_id={sub_module_id}
                    />
                  )}
                  {responseData?.resource_type === "form" && (
                    <Form
                      // fetching_progress={fetchingModulesProgress}
                      fetchingDetails={fetchingDetails}
                      current_progress={currentProgress}
                      resource_details={responseData?.resource_details}
                      sub_module_details={responseData?.sub_module_details}
                      module_id={module_id}
                      sub_module_id={sub_module_id}
                    />
                  )}

                  {(responseData?.resource_type === "file" ||
                    responseData?.resource_type === "schedule") && (
                    <Button
                      onClick={() =>
                        handleSetProgress(responseData?.sub_module_details)
                      }
                      disabled={
                        currentProgress?.progress_status ||
                        currentProgress?.progress_state === "complete"
                      }
                    >
                      Mark as complete
                    </Button>
                  )}

                  {/* Prev and Next buttons */}
                  <div className="d-flex align-items-center justify-content-between border rounded-0 mt-3">
                    <Button
                      variant="outline-dark"
                      onClick={() => handleOpenSubModule(prevItem)}
                      className="col-md-6 rounded-0"
                      disabled={!prevItem || prevItem === null}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="dark"
                      onClick={() => handleOpenSubModule(nextItem)}
                      className="col-md-6 border-left rounded-0"
                      disabled={!nextItem || nextItem === null}
                    >
                      Next
                    </Button>
                  </div>
                  {/* <p>Prev item: {JSON.stringify(prevItem)}</p>
                  <p>Next item: {JSON.stringify(nextItem)}</p> */}
                </div>
                {!showCourseContent && (
                  <div className="mt-3">
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                      <Tab
                        eventKey="modules"
                        title={
                          <div className="d-flex">
                            <span className="mr-3">Course content</span>
                            <a onClick={() => setShowCourseContent(true)}>
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                          </div>
                        }
                        tabClassName={
                          key === "modules"
                            ? "border-bottom border-top-0 border-left-0 border-right-0 border-dark p-0 pb-2 mr-3 font-weight-bold"
                            : "border-bottom border-top-0 border-left-0 border-right-0 text-muted p-0 pb-2 mr-3 font-weight-bold"
                        }
                      >
                        <div
                          className="mt-3 mx-auto"
                          style={{ maxWidth: "800px" }}
                        >
                          {fetchingModules ? (
                            <p className="text-center">Loading...</p>
                          ) : modules?.length > 0 ? (
                            modules?.map((item, key) => (
                              <Module
                                progress={modulesProgress}
                                id={id}
                                module_id={module_id}
                                sub_module_type={sub_module_type}
                                sub_module_id={sub_module_id}
                                accordion={true}
                                module_data={item}
                                key={key}
                                idx={key}
                              />
                            ))
                          ) : (
                            <p className="text-center">
                              <i>No modules added.</i>
                            </p>
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                )}
              </div>
            )}

            {/* Mobile View: Course module list */}
            <div className="d-md-none py-5">
              <div className="mb-3 border">
                <div className="border-bottom d-flex align-items-center justify-content-between">
                  <p className="mb-0 p-3">
                    <b>Course content</b>
                  </p>
                </div>
                {fetchingModules ? (
                  <p className="text-center">Loading...</p>
                ) : modules?.length > 0 ? (
                  modules?.map((item, key) => (
                    <Module
                      progress={modulesProgress}
                      id={id}
                      module_id={module_id}
                      sub_module_type={sub_module_type}
                      sub_module_id={sub_module_id}
                      accordion={true}
                      module_data={item}
                      key={key}
                      idx={key}
                    />
                  ))
                ) : (
                  <p className="text-center">
                    <i>No modules added.</i>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            data-theme={darkTheme ? "dark" : "light"}
            className={
              showCourseContent
                ? "col-md-4 h-100 px-0 border-left"
                : "col-md-4 h-100 px-0 border-left d-none"
            }
            style={{ overflow: "scroll" }}
          >
            {/* Desktop View: Course module list */}
            <div>
              <div className="border-bottom d-flex align-items-center justify-content-between">
                <p className="mb-0 p-3">
                  <b>Course content</b>
                </p>
                <a
                  className="py-2 px-3 text-decoration-none text-dark"
                  onClick={() => setShowCourseContent(false)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </a>
              </div>
              {fetchingModules ? (
                <p className="text-center">Loading...</p>
              ) : modules?.length > 0 ? (
                modules?.map((item, key) => (
                  <Module
                    progress={modulesProgress}
                    id={id}
                    module_id={module_id}
                    sub_module_type={sub_module_type}
                    sub_module_id={sub_module_id}
                    accordion={true}
                    module_data={item}
                    key={key}
                    idx={key}
                  />
                ))
              ) : (
                <p className="text-center">
                  <i>No modules added.</i>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(SubModulePage);
