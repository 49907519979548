import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Spinner,
  FormCheck,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faClock,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { connect, useSelector } from "react-redux";

import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import EventDetails from "./EventDetails";
import LogoPreview from "./LogoPreview";
import PatientGraph from "./PatientGraph";
import AppointmentGraph from "./AppointmentGraph";
import axios from "axios";
import Switch from "@material-ui/core/Switch";

const Overview = (props) => {
  let officeId = useSelector((state) => state.officeId);
  const [attendeesSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("/clinics/" + officeId + "/users")
      .orderBy("first_name")
  );

  const [clinicSnapshot, loadingClinic, errorClinic] = useDocument(
    firebase.firestore().collection("clinics").doc(officeId)
  );

  const officeDetails = clinicSnapshot?.data().clinic_details;

  const [editingofficeDetails, setEditingofficeDetails] = useState(false);

  const sendSms = () => {
    let url =
      "https://api.textlocal.in/send/?apikey=NTk0MjRjNzA2MTRkNjQzMzYzNjg3MzdhNjIzNjM3NTM=&numbers=[+918802879231]&sender=TXTLCL&message=" +
      encodeURIComponent("hello");
    axios.get(url).then((res) => console.log(res));
  };

  return (
    <>
      {!editingofficeDetails ? (
        <div className="container-fluid  py-4 mb-5 text-light">
          <div className="col-md-12  mt-3">
            <h4>Welcome to {officeDetails?.EN?.clinic_name}</h4>
            {/* <h5 className="mb-4">Your clinic</h5> */}
            <div className="mt-3">
              <Card style={{ width: "100%" }} className="shadow-sm bg-dark">
                <Card.Body>
                  <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                    <div>
                      <img
                        alt={officeDetails && officeDetails["EN"]?.clinic_name}
                        width="110px"
                        src={officeDetails?.featured_image}
                        className="img-fluid rounded d-block d-md-inline mr-3"
                      ></img>
                      <span>
                        {officeDetails && officeDetails["EN"]?.clinic_name}
                      </span>
                    </div>
                    <div>
                      <Button
                        className="btn-sm btn-secondary  mr-2"
                        onClick={() => setEditingofficeDetails(true)}
                      >
                        Edit
                      </Button>
                      {/* <Button onClick={sendSms}>Send sms</Button> */}
                      {/* <Button
                        className="btn-sm btn-primary  mr-2"
                        title="Contact to Igesia"
                      >
                        Turn off payment gateway
                      </Button> */}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          Clients
                        </span>
                        <span>
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            attendeesSnapshot.docs &&
                            attendeesSnapshot.docs.length
                          )}{" "}
                          Clients
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faClock}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          Per Client Time
                        </span>
                        <span>
                          {officeDetails && officeDetails?.per_patient_time
                            ? officeDetails?.per_patient_time
                            : "30"}{" "}
                          mins
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          Payment gateway
                        </span>
                        <span>
                          <Switch
                            title="Contact igesia"
                            checked={false}
                            name="checkedB"
                            color="primary"
                          />
                        </span>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faUsers}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          PEOPLE NETWORKING
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch-people"
                            label={peopleEnabled ? "On" : "Off"}
                            checked={peopleEnabled}
                            onChange={(e) =>
                              firebase
                                .firestore()
                                .collection("clinics")
                                .doc("EKWsp2FDV2aWp1F6P963")
                                .update({
                                  people_enabled: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div> */}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <h5 className="text-white">Stats</h5>
              <Button
                href={`/admin/consultancies/${props.officeId}`}
                variant="link"
                size="sm"
                className="text-uppercase text-decoration-none text-light"
              >
                View All
              </Button>
            </div>
            <div className="mt-3">
              <PatientGraph />
            </div>
            <div className="mt-3">
              <AppointmentGraph />
            </div>
            <div className="mt-3">
              <LogoPreview />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 ml-3">
          <Button
            onClick={() => setEditingofficeDetails(false)}
            className="btn-sm"
          >
            Back to Office overview
          </Button>
          <EventDetails />
        </div>
      )}
    </>
  );
};
export default connect((state) => ({
  currentLanguage: "EN",
  officeId: state.officeId,
}))(Overview);
