import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import firebase from "firebase";
import { setGraphId } from "../../../../store/actions/actions";
import { useCollection } from "react-firebase-hooks/firestore";

function ToBeSettings(props) {
  const { darkTheme, currentEvent, products, graphId } = props;

  const [snapshotOldProducts, fetchingOldProducts, errorOldProducts] =
    useCollection(
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/as_is`)
    );
  const oldProducts = useMemo(() => {
    return snapshotOldProducts?.docs?.map((e) => {
      return e.id;
    });
  }, [snapshotOldProducts]);

  const [snapshotToBeProducts, fetchingToBeProducts, errorToBeProducts] =
    useCollection(
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
    );
  const toBeProducts = useMemo(() => {
    return snapshotToBeProducts?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [snapshotToBeProducts]);

  const finalProducts = useMemo(() => {
    return toBeProducts?.filter((item) => !oldProducts.includes(item.id));
  }, [snapshotToBeProducts]);

  //   Function: Delete product / service
  const deleteProductHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
        .doc(item.id)
        .delete()
        .then(() => {
          toast.dark("Product / Service deleted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((error) => {
          console.error("Error deleting product/ service: ", error);

          toast.warning("Error deleting product/ service " + error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <>
      {/* Product / Service Offerings */}
      <Card
        className={
          darkTheme
            ? "bg-dark shadow-sm border border-dark montserrat-font"
            : "bg-white shadow-sm border border-light montserrat-font"
        }
      >
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 lead">Product / Service (To-Be)</p>
          </div>
        </Card.Header>
        <Card.Body className="p-1">
          {!finalProducts?.length && (
            <div className="d-flex align-items-center justify-content-center p-3">
              <p className="mb-0">
                Click on <b className="text-uppercase">Add your offering</b>{" "}
                above to add a To-be Product.
              </p>
            </div>
          )}
          {finalProducts?.map((item, key) => (
            <div
              className="d-flex align-items-center mb-1 border rounded p-1 pl-2"
              key={key}
            >
              <div
                className="mr-2"
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 4,
                  backgroundColor: item.color,
                }}
              ></div>
              <p className="mb-0 flex-grow-1">{item.name}</p>
              <div>
                <Button
                  onClick={() => {
                    deleteProductHandler(item);
                  }}
                  size="sm"
                  variant="danger"
                >
                  <div>
                    <FontAwesomeIcon size="sm" icon={faTrash} /> Remove
                  </div>
                </Button>
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
  products: state.products,
  graphId: state.graphId,
});

export default connect(mapStateToProps, {
  setGraphId,
})(ToBeSettings);
