import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { TwitterPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faTrash,
  faEllipsisH,
  faFileExcel,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import saveSvgAsPng from "save-svg-as-png";
import ReactExport from "react-export-excel";
import moment from "moment";

function Tier(props) {
  const ref = useRef(null);
  const [showTierUpdateModal, setShowTierUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addedText, setAddedText] = useState("");
  const [currentCords, setCurrentCords] = useState([{ x: 0, y: 0 }]);
  const [selectedCords, setSelectedCords] = useState(null);
  const [showColorPickerForTier, setShowColorPickerForTier] = useState(false);

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("red");

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [cursorPositionContainer, setCursorPositionContainer] = useState({
    x: 0,
    y: 0,
  });
  const [showProTips, setShowProTips] = useState(true);

  const { darkTheme } = props;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [coordinateValue, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bboa/${props.graphId}/three_tiers/`
      )
  );

  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [calculatedWidth, setCalculatedWidth] = useState(0);
  const [loadingSvg, setLoadingSvg] = useState(true);
  useEffect(() => {
    if (ref) {
      if (ref?.current?.clientWidth) {
        setCalculatedHeight((ref.current.clientWidth * 560) / 900);
        setCalculatedWidth(ref.current.clientWidth);
        setLoadingSvg(false);
      }
    }
  }, [ref?.current?.clientWidth, ref?.current?.clientHeight]);

  const coordinateArray = coordinateValue?.docs?.map((item) => {
    return { ...item.data(), id: item.id };
  });

  useEffect(() => {
    if (coordinateValue) {
      let data = coordinateArray;
      setCurrentCords(data);
    }
  }, [JSON.stringify(coordinateValue)]);

  const eventHandler = (e, tier) => {
    let coordinates = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      tier: tier,
    };

    // console.log(e, coordinates, currentCords);
    setSelectedCords(coordinates);
    setShowModal(true);
  };

  const updateValueHandlerForTier = () => {
    //console.log(selectedCords)
    if (selectedCords) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bboa/${props.graphId}/three_tiers`
        )
        .doc(selectedCords.id)
        .update({
          value: selectedCords.value,
          color: selectedCords.color,
        })
        .then(() => {
          setSelectedCords(null);
          setShowTierUpdateModal(false);
        });
    }
  };

  const addValueHandler = (e) => {
    e.preventDefault();
    let user = firebase.auth().currentUser;
    let x = ((selectedCords.x / ref.current.clientHeight) * 100).toFixed(2);
    let y = ((selectedCords.y / ref.current.clientWidth) * 100).toFixed(2);
    let data = {
      x: x,
      y: y,
      tier: selectedCords.tier,
      value: addedText,
      color: selectedColor,
      created_at: Date.now(),
      created_by: {
        name: user.displayName,
        email: user.email,
        id: user.uid,
      },
    };

    setCurrentCords((prev) => {
      let newState = [...prev, data];
      return newState;
    });

    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bboa/${props.graphId}/three_tiers`
      )
      .add(data);

    setShowModal(false);
    setAddedText("");
    setSelectedCords(null);
  };

  const _onMouseMove = (e) => {
    setCursorPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const _onMouseMoveContainer = (e) => {
    setCursorPositionContainer({ x: e.screenX, y: e.screenY });
  };

  const deleteTierHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bboa/${props.graphId}/three_tiers`
        )
        .doc(item.id)
        .delete();
    }
  };

  const handleExportAsPng = () => {
    const imageOptions = {
      scale: 5,
      encoderOptions: 1,
      backgroundColor: "white",
    };
    saveSvgAsPng.saveSvgAsPng(
      document.getElementById("identification-of-the-noncustomers"),
      `identification-of-the-noncustomers.png`,
      imageOptions
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </a>
  ));

  const getTierName = (tier) => {
    switch (tier) {
      case "First Tier":
        return "To-Be";

      case "Second Tier":
        return "Anti";

      case "Third Tier":
        return "Unexplored";

      default:
        return "To-Be";
    }
  };

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header className="d-flex">
        <h5 className="mb-0 text-uppercase flex-grow-1">
          3. IDENTIFICATION OF THE NONCUSTOMERS
        </h5>
        <Button
          size="sm"
          className="text-uppercase"
          onClick={handleExportAsPng}
        >
          <FontAwesomeIcon icon={faImage} className="mr-2" />
          <span>Export</span>
        </Button>
      </Card.Header>
      <Card.Body className="p-0">
        <div onMouseMove={_onMouseMoveContainer}>
          <div
            className="w-100"
            ref={ref}
            style={{
              heigt: calculatedHeight,
            }}
            // style={{ height: 560, width: 900 }}
            onMouseMove={_onMouseMove}
          >
            {!loadingSvg && (
              <svg
                id="identification-of-the-noncustomers"
                // width="900"
                // height="560"
                width={calculatedWidth}
                height={calculatedHeight}
                //onClick={(event) => eventHandler(event)}
              >
                <ellipse
                  onClick={(event) => eventHandler(event, "Third Tier")}
                  cx={
                    calculatedWidth / 2 - ((calculatedWidth * 350) / 900) * 0.2
                  }
                  cy={calculatedHeight / 2}
                  rx={(calculatedWidth * 350) / 900}
                  ry={(calculatedHeight * 200) / 560}
                  // fill="white"
                  fill="#559dd8ff"
                  stroke="black"
                  stroke-width="1px"
                />

                <ellipse
                  onClick={(event) => eventHandler(event, "Second Tier")}
                  cx={
                    calculatedWidth / 2 - ((calculatedWidth * 240) / 900) * 0.75
                  }
                  cy={calculatedHeight / 2}
                  rx={(calculatedWidth * 240) / 900}
                  ry={(calculatedHeight * 120) / 560}
                  // fill="white"
                  fill="#adadffff"
                  stroke="black"
                  stroke-width="1px"
                />

                <ellipse
                  onClick={(event) => eventHandler(event, "First Tier")}
                  style={{ zIndex: "10" }}
                  cx={
                    calculatedWidth / 2 - ((calculatedWidth * 50) / 900) * 7.4
                  }
                  cy={calculatedHeight / 2}
                  rx={(calculatedWidth * 50) / 900}
                  ry={(calculatedWidth * 50) / 900}
                  stroke="black"
                  stroke-width="1px"
                  // fill="white"
                  fill="#f37be5ff"
                />

                <OverlayTrigger
                  placement="right"
                  delay={{ show: 0, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip" {...props}>
                      Current Market Customers
                    </Tooltip>
                  }
                >
                  <ellipse
                    style={{ zIndex: "100" }}
                    cx={
                      calculatedWidth / 2 -
                      ((calculatedWidth * 53.13) / 900) * 7.4
                    }
                    cy={calculatedHeight / 2}
                    rx={(calculatedWidth * 25) / 900}
                    ry={(calculatedWidth * 25) / 900}
                    stroke="black"
                    stroke-width="1px"
                    fill="red"
                    title="Current Market Customers"
                  />
                </OverlayTrigger>

                {currentCords?.map((item, index) => (
                  <text
                    x={`${(item.x / 100) * ref.current?.clientHeight}`}
                    y={`${(item.y / 100) * ref.current?.clientWidth}`}
                    fill={item.color ? item.color : "red"}
                    style={{ fontSize: "14px", cursor: "default" }}
                    data-toggle="tooltip"
                    title={item?.created_by?.name}
                    data-placement="bottom"
                  >
                    ◉ {item.value}
                  </text>
                ))}
              </svg>
            )}
          </div>
          {/* <h1>
          Mouse coordinates: {cursorPosition.x} {cursorPosition.y}
        </h1>
        <h1>
          Mouse coordinates container: {cursorPositionContainer.x}{" "}
          {cursorPositionContainer.y}
        </h1> */}
        </div>
        <>
          <Modal centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header
              closeButton
              className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
            >
              <Modal.Title>Add Value</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
            >
              <p>Select color and add your value</p>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="d-flex">
                    <div
                      className="mr-1"
                      onClick={() =>
                        showColorPicker
                          ? setShowColorPicker(false)
                          : setShowColorPicker(true)
                      }
                      style={{
                        height: 35,
                        width: 35,
                        borderRadius: 4,
                        backgroundColor: selectedColor,
                      }}
                    ></div>
                    {showColorPicker ? (
                      <TwitterPicker
                        onChangeComplete={(color) => {
                          setShowColorPicker(false);
                          setSelectedColor(color.hex);
                        }}
                        colors={[
                          "#FF6900",
                          "#FCB900",
                          "#7BDCB5",
                          "#00D084",
                          "#1adef0",
                          "#0693E3",
                          "#ff26d7",
                          "#EB144C",
                          "#F78DA7",
                          "#7500eb",
                        ]}
                      />
                    ) : null}
                    <Form.Control
                      autoComplete="off"
                      type="text"
                      placeholder="Enter your value"
                      value={addedText}
                      onChange={(e) => setAddedText(e.target.value)}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer
              className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
            >
              <div className="d-flex">
                <Button
                  className="px-3 mr-2"
                  size="sm"
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </Button>
                <Button
                  className="px-3"
                  size="sm"
                  variant="primary"
                  onClick={(e) => addValueHandler(e)}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
        <>
          <Modal
            centered
            show={showTierUpdateModal}
            onHide={() => setShowTierUpdateModal(false)}
          >
            <Modal.Header data-theme={darkTheme ? "dark" : "light"} closeButton>
              <Modal.Title>Add Value</Modal.Title>
            </Modal.Header>
            <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
              <p>{`Enter text to update ${selectedCords?.value}`}</p>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="d-flex">
                    <div
                      className="mr-1"
                      onClick={() =>
                        showColorPickerForTier
                          ? setShowColorPickerForTier(false)
                          : setShowColorPickerForTier(true)
                      }
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 4,
                        backgroundColor: selectedCords?.color
                          ? selectedCords.color
                          : "red",
                      }}
                    ></div>
                    {showColorPickerForTier ? (
                      <TwitterPicker
                        onChangeComplete={(color) => {
                          setShowColorPickerForTier(false);
                          setSelectedCords((prev) => {
                            const newState = {
                              ...prev,
                              color: color.hex,
                            };
                            return newState;
                          });
                        }}
                        colors={[
                          "#FF6900",
                          "#FCB900",
                          "#7BDCB5",
                          "#00D084",
                          "#1adef0",
                          "#0693E3",
                          "#ff26d7",
                          "#EB144C",
                          "#F78DA7",
                          "#7500eb",
                        ]}
                      />
                    ) : null}
                  </div>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Enter Value"
                    value={selectedCords?.value}
                    onChange={(e) =>
                      setSelectedCords((prev) => {
                        const newState = {
                          ...prev,
                          value: e.target.value,
                        };
                        return newState;
                      })
                    }
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer data-theme={darkTheme ? "dark" : "light"}>
              <div className="d-flex">
                <Button
                  className="mr-2"
                  variant="secondary"
                  onClick={() => setShowTierUpdateModal(false)}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => updateValueHandlerForTier(e)}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      </Card.Body>
      <Card.Footer>
        <div className="row mb-1">
          <div className="col-md-4" style={{ color: "#f37be5ff" }}>
            <p className="mb-0">First Tier - "Soon to be" </p>
            <p className="small">
              On the edge of your market ready to jump ship
            </p>
          </div>
          <div className="col-md-4" style={{ color: "#adadffff" }}>
            <p className="mb-0">Second Tier - "Anti" </p>
            <p className="small">Consciously choose against your market</p>
          </div>
          <div className="col-md-4" style={{ color: "#559dd8ff" }}>
            <p className="mb-0">Third Tier - "Unexplored" </p>
            <p className="small">In a market distant from yours</p>
          </div>
        </div>
        <Card
          className={
            darkTheme
              ? "bg-dark shadow-sm border border-dark mb-3"
              : "bg-white shadow-sm border border-light mb-3"
          }
        >
          <Card.Header className="d-flex">
            <div className="flex-grow-1">
              <p className="small text-uppercase font-weight-bold mb-0">
                PROFILES OF THE NONCUSTOMERS
              </p>
            </div>
            <div>
              <ExcelFile
                element={
                  <Button size="sm" className="text-uppercase">
                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                    <span>Export</span>
                  </Button>
                }
              >
                <ExcelSheet
                  data={currentCords}
                  name="Profiles of the Noncustomers"
                >
                  <ExcelColumn
                    label="Tier"
                    value={(item) => getTierName(item.tier)}
                  />
                  <ExcelColumn
                    label="Noncustomer Profile"
                    value={(item) => item.value}
                  />
                  <ExcelColumn label="Contact: Organisation" value="" />
                  <ExcelColumn label="Contact: Name" value="" />
                  <ExcelColumn label="Contact: Title" value="" />
                  <ExcelColumn label="Contact: Email" value="" />
                  <ExcelColumn label="Contact: Phone" value="" />
                  <ExcelColumn
                    label="Interviewer in charge"
                    value={(item) => item.created_by.name}
                  />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </Card.Header>
          <Card.Body className="p-2">
            <div className="">
              <div className=" border border-secondary p-2">
                <p
                  className="text-uppercase small font-weight-bold mb-1"
                  style={{ color: "#f37be5ff" }}
                >
                  Noncustomers: TO-BE
                </p>
                {currentCords?.map((item, key) => {
                  return (
                    <>
                      {item?.tier == "First Tier" && (
                        <div className="d-flex border rounded p-1 pl-2 mb-1">
                          <p
                            onClick={() => {
                              setSelectedCords(item);
                              setShowTierUpdateModal(true);
                            }}
                            className="flex-grow-1 mr-2 mb-0"
                            key={key}
                          >
                            {/* {key + 1}.{" "} */}
                            {`${item.value} (${item?.created_by?.name}) `}
                          </p>
                          <div>
                            <Dropdown className="px-1 py-0 pointer">
                              <Dropdown.Toggle
                                size="sm"
                                variant="white"
                                as={CustomToggle}
                              />

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    deleteTierHandler(item);
                                  }}
                                  className="text-uppercase sm"
                                >
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className=" border border-secondary p-2">
                <p
                  className="text-uppercase small font-weight-bold mb-1"
                  style={{ color: "#adadffff" }}
                >
                  Noncustomers: ANTI
                </p>
                {currentCords?.map((item, key) => {
                  return (
                    <>
                      {item?.tier == "Second Tier" && (
                        <div className="d-flex border rounded p-1 pl-2 mb-1">
                          <p
                            onClick={() => {
                              setSelectedCords(item);
                              setShowTierUpdateModal(true);
                            }}
                            className="flex-grow-1 mr-2 mb-0"
                            key={key}
                          >
                            {/* {key + 1}.{" "} */}
                            {`${item.value} (${item?.created_by?.name}) `}
                          </p>
                          <div>
                            <Dropdown className="px-1 py-0 pointer">
                              <Dropdown.Toggle
                                size="sm"
                                variant="white"
                                as={CustomToggle}
                              />

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    deleteTierHandler(item);
                                  }}
                                  className="text-uppercase sm"
                                >
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className=" border border-secondary p-2">
                <p
                  className="text-uppercase small font-weight-bold mb-1"
                  style={{ color: "#559dd8ff" }}
                >
                  Noncustomers: UNEXPLORED
                </p>
                {currentCords?.map((item, key) => {
                  return (
                    <>
                      {item?.tier == "Third Tier" && (
                        <div className="d-flex border rounded p-1 pl-2 mb-1">
                          <p
                            onClick={() => {
                              setSelectedCords(item);
                              setShowTierUpdateModal(true);
                            }}
                            className="flex-grow-1 mr-2 mb-0"
                            key={key}
                          >
                            {/* {key + 1}.{" "} */}
                            {`${item.value} (${item?.created_by?.name}) `}
                          </p>
                          <div>
                            <Dropdown className="px-1 py-0 pointer">
                              <Dropdown.Toggle
                                size="sm"
                                variant="white"
                                as={CustomToggle}
                              />

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    deleteTierHandler(item);
                                  }}
                                  className="text-uppercase sm"
                                >
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </Card.Body>
        </Card>

        <div className="d-flex align-items-center">
          <p className="mb-0 text-uppercase small">
            <b>Pro tips</b>
          </p>
          <p
            style={{ cursor: "pointer" }}
            className="mb-0 text-uppercase small text-muted ml-2"
            onClick={() => setShowProTips((prev) => !prev)}
            title={
              showProTips ? "Click to hide pro tips" : "Click to show pro tips"
            }
          >
            <b>({showProTips ? "Hide" : "Show"})</b>
          </p>
        </div>
        <ol
          className={showProTips ? "px-3 mb-1 pb-1" : "px-3 mb-1 pb-1 d-none"}
        >
          <li>
            <small>
              Click on any of the 3-tiers to mark your point{" "}
              <b className="text-danger">◉</b> and add a value.
            </small>
          </li>
          <li>
            <small>
              To delete or update value of points go to <b>settings</b> above.
            </small>
          </li>
        </ol>
      </Card.Footer>
    </Card>
  );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  null
)(Tier);
