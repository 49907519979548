import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
  Spinner,
  Alert,
  InputGroup,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faEnvelope,
  faPaperPlane,
  faTrash,
  faSearch,
  faTimes,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { postData, sendEmail } from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { languageText as languageTextToast } from "./language.json";
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import ReactExport from "react-export-excel";

import TextField from "@material-ui/core/TextField";
import ButtonMaterial from "@material-ui/core/Button";

import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { CopyToClipboard } from "react-copy-to-clipboard";

import * as DeviceDetect from "react-device-detect";
import { API_BASE_URL, DRIVE_UPLOAD, USER_EXISTS } from "../../../config";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const Patients = (props) => {
  const { officeId, officeDetails } = useSelector((state) => state);

  const [attendeesSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/clinics/${officeId}/users`)
      .orderBy("first_name")
  );
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [selectedUID, setSelectedUID] = useState(null);
  const [selectedDisplayName, setSelectedDisplayName] = useState("");
  const [typedDisplayName, setTypedDisplayName] = useState("");

  const [showOfflineRegistration, setShowOfflineRegistration] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [formErrors, setFromErrors] = useState({});
  const [writingUserData, setWritingUserData] = useState(false);
  const [signing, setSigning] = useState(false);

  let inviteText =
    "You are invited to join Igesia Clinic. Click this link to join- http://localhost:3000/clinic/template";

  let pass = "igesia123";
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const handleCloseOfflineRegistration = () =>
    setShowOfflineRegistration(false);
  const handleShowOfflineRegistration = () => setShowOfflineRegistration(true);
  const handleFormChanges = (e) => {
    let Name = e.target.name;
    let Value = e.target.value;
    formDetails[Name] = Value;
    setFormDetails({ ...formDetails });
    setFromErrors({});
  };

  const _handleSaveUser = (authUser) => {
    return firebase
      .firestore()
      .collection("users")
      .doc(`${authUser.uid}`)
      .set({
        displayName: authUser.displayName,
        avatar: authUser.photoURL,
        firstName: formDetails.firstName,
        lastName: formDetails.lastName,
        // middleName: middleName,
        email: authUser.email,
        emailVerified: authUser.emailVerified,
        phoneNumber: authUser.phoneNumber,
        providerData: JSON.parse(JSON.stringify(authUser.providerData)),
        uid: authUser.uid,
        isAnonymous: authUser.isAnonymous,
        deviceInfo: DeviceDetect.deviceDetect(),
        deviceType: DeviceDetect.deviceType,
        device: DeviceDetect.isMobile
          ? DeviceDetect.isMobileOnly
            ? "Mobile"
            : "Tablet"
          : "Desktop",
        electron: DeviceDetect.isElectron ? true : false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function (response) {
        addPatientToClinic(authUser.uid);
      })
      .then(() => {
        firebase.auth().signOut();
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const addPatientToClinic = (uid) => {
    console.log(uid, officeId);
    firebase
      .firestore()
      .collection("clinics")
      .doc(officeId)
      .collection("users")
      .doc(uid)
      .set({
        email_address: formDetails.email,
        first_name: formDetails.firstName,
        last_name: formDetails.lastName,
        phone_number: formDetails.phone ? formDetails.phone : null,
        uid: uid,
        timestamp: new Date(),
        user_role: +JSON.parse(window.localStorage.getItem("ticketType"))
          ? +JSON.parse(window.localStorage.getItem("ticketType"))
          : 0,
        session_id: null,
        created_date: new Date().toLocaleDateString(),
        created_at: Date.now(),
      })
      .then(() => {
        setFormDetails({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        });
        handleCloseOfflineRegistration();
        toast.success("Patient added successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setWritingUserData(false);
      });
  };

  const _handleUpdateProfile = () => {
    setWritingUserData(true);

    const authUser = firebase.auth().currentUser;

    authUser
      .updateProfile({
        displayName: `${formDetails.firstName} ${formDetails.lastName}`,
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822",
      })
      .then(() => {
        _handleSaveUser(authUser);
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (writingUserData || signing) return;
    console.log(formDetails);
    const { firstName, lastName, email, phone } = formDetails;
    if (!firstName) {
      formErrors["firstName"] = "Enter you first name!";
      setFromErrors({ ...formErrors });
      return;
    }
    if (!lastName) {
      formErrors["lastName"] = "Enter your last name!";
      setFromErrors({ ...formErrors });
      return;
    }
    if (!email) {
      formErrors["email"] = "Enter your email address";
      setFromErrors({ ...formErrors });
      return;
    }
    if (phone && !phone.match(phoneRegExp)) {
      formErrors["phone"] = "Enter a valid phone number!";
      setFromErrors({ ...formErrors });
      return;
    }

    if (firebase.auth().currentUser) {
      firebase.auth().signOut();
    }

    let patientDoc = await firebase
      .firestore()
      .collection("clinics")
      .doc(officeId)
      .collection("users")
      .where("email_address", "==", email)
      .get();
    console.log("patientDoc", patientDoc);
    if (!patientDoc.empty) {
      toast.warning("Patient already exists", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    try {
      setSigning(true);
      const response = await postData(`${API_BASE_URL}${USER_EXISTS}`, {
        ["email"]: email,
      });
      const { data, message, status } = response;
      console.log("data", data);
      if (status === 200) {
        if (data.exist) {
          let userUid = await firebase
            .firestore()
            .collection("users")
            .where("email", "==", email)
            .get();
          if (!userUid.empty) {
            console.log(userUid.docs[0].id);

            addPatientToClinic(userUid.docs[0].id);
          }
          setSigning(false);
        } else {
          const createdUser = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, pass);
          setWritingUserData(true);
          createdUser.user.sendEmailVerification();
          const response = await sendEmail({
            to: email,
            subject: "Igesia E-Clinic registration successful",
            content: `email: ${email} pass: ${pass}`,
            from: "gyanendra@igesia.co",
            replyTo: "gyanendra@igesia.co",
          });

          console.log(response);
          setSigning(false);
          _handleUpdateProfile();
        }

        setSigning(false);
      }
    } catch (e) {
      console.error(e, "error in signing user");
    } finally {
      setSigning(false);
    }
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleCloseInviteModal = () => setShowInviteModal(false);
  const handleShowInviteModal = () => setShowInviteModal(true);
  const [sendingInvite, setSendingInvite] = useState(false);

  const [inviteEmail, setInviteEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [inviteEmailMessage, setInviteEmailMessage] = useState(inviteText);

  function validateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(inviteEmail).toLowerCase());
  }

  const handleInviteEmail = async () => {
    if (!validateEmail()) {
      setEmailError(true);
      return;
    }
    if (sendingInvite) return;
    setEmailError(false);
    setSendingInvite(true);

    try {
      const response = await sendEmail({
        to: inviteEmail,
        subject: "Igesia E-Clinic Invite",
        content: inviteEmailMessage,
        from: "gyanendra@igesia.co",
        replyTo: "gyanendra@igesia.co",
      });
      console.log(response);
    } catch (e) {
      console.error(e, "error in sending confirm Help request!");
    }
    setSendingInvite(false);
    setInviteEmail("");
    handleCloseInviteModal();

    toast.success(`Invite sent successfully!`, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [boothSnapshot, loadingBooth, errorBooth] = useCollection(
    firebase.firestore().collection(`/clinics/${officeId}/booths`)
  );

  let attendeeDataArray = attendeesSnapshot?.docs.map((doc) => ({
    ...doc.data(),
    display_name: doc.data().first_name + " " + doc.data().last_name,
    id: doc.id,
  }));

  let boothDataArray = boothSnapshot?.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  // const attendeeKeys = _.keyBy(attendeeDataArray, "email_address");

  // const boothKeys = _.keyBy(boothDataArray, (o) => {
  //   return o.email_address_of_owner[0];
  // });

  // attendeeDataArray = _.values(_.merge(attendeeKeys, boothKeys));
  // console.log(attendeeDataArray, "attendeedataarray");

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");
  const allAttendeesEmailArray = !loading
    ? attendeesSnapshot.docs.map((a) => a.data().email_address)
    : [];
  const [emailAllAttendee, setEmailAllAttendee] = useState(false);

  const [currentFilterCriteria, setCurrentFilterCriteria] = useState(null);
  const [currentSortCriteria, setCurrentSortCriteria] = useState(null);

  let filteredDataArray =
    currentFilterCriteria !== null
      ? attendeeDataArray.filter(
          (attendee) => attendee.user_role === currentFilterCriteria
        )
      : attendeeDataArray;

  filteredDataArray = filteredDataArray?.map((item) => {
    return {
      ...item,
      first_name: item.first_name.toLowerCase(),
      last_name: item.last_name.toLowerCase(),
    };
  });

  filteredDataArray =
    currentSortCriteria !== null
      ? _.orderBy(
          filteredDataArray,
          currentSortCriteria.value.split("-")[0],
          currentSortCriteria.sortOrder
        )
      : filteredDataArray;

  const undefinedHandler = (value) => {
    if (value) {
      return value;
    } else {
      return "N/A";
    }
  };
  const { currentLanguage } = props;
  const userRoleHandler = (number) => {
    if (number) {
      return "VIP Ticket";
    } else {
      return "Visitor Ticker";
    }
  };

  const filterOptions = [
    { value: null, label: "All" },
    { value: 0, label: "Visitor" },
    { value: 1, label: "VIP" },
    // { value: 2, label: "Speaker/Booth owner" },
  ];

  const sortOptions = [
    { value: "first_name-asc", label: "First name: A-Z ", sortOrder: "asc" },
    { value: "first_name-desc", label: "First name: Z-A", sortOrder: "desc" },
    { value: "last_name-asc", label: "Last name: A-Z", sortOrder: "asc" },
    { value: "last_name-desc", label: "Last name: Z-A", sortOrder: "desc" },
    {
      value: "timestamp-asc",
      label: "Joining time: Oldest-Latest",
      sortOrder: "asc",
    },
    {
      value: "timestamp-desc",
      label: "Joining time: Latest-Oldest",
      sortOrder: "desc",
    },
  ];

  const deleteUserHandler = (uid) => {
    firebase
      .firestore()
      .collection(`/clinics/${officeId}/users`)
      .doc(uid)
      .delete();
  };
  const blockUserHandler = (uid) => {
    firebase
      .firestore()
      .collection(`/clinics/${officeId}/users`)
      .doc(uid)
      .update({
        isBlocked: true,
      });
  };
  const unblockUserHandler = (uid) => {
    firebase
      .firestore()
      .collection(`/clinics/${officeId}/users`)
      .doc(uid)
      .update({
        isBlocked: false,
      });
  };

  if (loading) return <Spinner size="sm" animation="border" />;
  if (error) return <h5>Something went wrong!</h5>;

  function EmailAttendeeModal(props) {
    const [show, setShow] = useState(false);

    const [subject, setSubject] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const sender = '"igesia Inc." <noreply@igesia.co>';

    const [discardShow, setDiscardShow] = useState(false);

    const isThereAnyContent = useMemo(
      () => subject != "" || messageBody != "",
      [subject, messageBody]
    );

    const [attachment, setAttachment] = useState(null);

    const [isUploading, setIsUploading] = useState("");

    const uploadFile = async (file) => {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);

      fileData.append("user_id", "1gHTSDwzWRPW7KAUeTcscDFxs7z2");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          setIsUploading(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          );
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          // console.log(res);
          return res.data.data["file_url"];
        } catch (error) {
          console.log(error);
          return "";
        }
      } else {
        return "";
      }
    };

    const handleUpload = async (e) => {
      if (isUploading) return;
      let file = e.target.files[0];
      let fileUrl = await uploadFile(file);
      setAttachment(fileUrl);
    };

    const handleClose = () => {
      setIsUploading("");
      setAttachment(null);
      setSubject("");
      setMessageBody("");
      setShow(false);
    };
    const handleShow = () => setShow(true);

    const discardEmail = () => {
      setSubject("");
      setMessageBody("");
      setDiscardShow(false);
      handleClose();
    };

    const { first_name, last_name, email_address, sendEmail } = props;

    const submitEmail = async () => {
      if (isUploading && isUploading < 100) return;

      if (isThereAnyContent) {
        try {
          const response = await sendEmail({
            to: email_address,
            subject: subject,
            content: `<div>${messageBody}<br/>${
              attachment ? `<a href=${attachment}>Open attachment</a>` : ""
            }</div>`,
            from: sender,
            replyTo: "direction@blue-awards.com",
          });
          if (response.status === 200) {
            toast.success("Email sent successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else if (response.status !== 200) {
            toast.error("There was an error in sending email", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        } catch (error) {
          console.log("Email error: ", error);
        } finally {
          handleClose();
        }
      } else {
        toast.error("One or more field(s) are empty", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    };

    return (
      <>
        <Button
          variant="outline"
          className="btn-sm btn-outline-secondary"
          onClick={handleShow}
        >
          <span>Send email</span> <FontAwesomeIcon icon={faEnvelope} />
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title className="h6 font-weight-bold">
              Email Attendee
            </Modal.Title>
          </Modal.Header>
          <p
            className="mb-1 p-2 border-bottom"
            style={{ borderColor: "#515151" }}
          >
            <span className="small text-muted mr-2">To</span> {first_name}{" "}
            {last_name} ({email_address})
          </p>
          <Modal.Body>
            <Form.Group className="mb-1">
              <Form.Label className="small text-muted font-weight-bold">
                Subject
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </Form.Group>

            <Form.Group className="my-1">
              <Form.Label className="small text-muted font-weight-bold">
                Message body
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                data={messageBody ?? ""}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMessageBody(data);
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
              <Form.Group>
                <input
                  type="file"
                  className="my-2 form-console form-control-file"
                  onChange={handleUpload}
                />
                {isUploading === 0 || isUploading ? (
                  <div className="progress my-2">
                    <div
                      className="progress-bar bg-primary progress-bar-striped "
                      role="progressbar"
                      style={{ width: `${isUploading}%` }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {isUploading}%
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </Form.Group>

            <Alert show={discardShow} variant="danger">
              <Alert.Heading className="h6">Discard</Alert.Heading>
              <p className="small">Are you sure you want to discard?</p>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  onClick={discardEmail}
                  variant="outline-danger"
                  className="mr-2"
                >
                  Yes, discard
                </Button>
                <Button
                  size="sm"
                  onClick={() => setDiscardShow(false)}
                  variant="outline-secondary"
                >
                  No, cancel discard
                </Button>
              </div>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            {isThereAnyContent ? (
              <Button
                variant="secondary"
                onClick={() => setDiscardShow(true)}
                disabled={discardShow}
              >
                <span>
                  Discard{" "}
                  <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
                </span>
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                <span>Close</span>
              </Button>
            )}
            <Button variant="primary" onClick={submitEmail}>
              <span>
                Send{" "}
                <FontAwesomeIcon
                  size="sm"
                  icon={faPaperPlane}
                  className="ml-1"
                />
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  function EmailAllAttendeeModal(props) {
    const [subject, setSubject] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const sender = '"igesia Inc." <noreply@igesia.co>';

    const [discardShow, setDiscardShow] = useState(false);

    const isThereAnyContent = useMemo(
      () => subject !== "" || messageBody !== "",
      [subject, messageBody]
    );

    const handleClose = () => props.setShow(false);
    const handleShow = () => props.setShow(true);

    const discardEmail = () => {
      setSubject("");
      setMessageBody("");
      setDiscardShow(false);
      handleClose();
    };

    const { first_name, last_name, email_address, sendEmail } = props;

    const submitEmail = async () => {
      if (isThereAnyContent) {
        try {
          let response = [];
          if (Array.isArray(email_address)) {
            response = await Promise.all(
              email_address.map(async (email) => {
                return await sendEmail({
                  to: email,
                  subject: subject,
                  content: messageBody,
                  from: sender,
                  replyTo: "direction@blue-awards.com",
                });
              })
            );
          }
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } catch (error) {
          console.log("Email error: ", error);
          toast.error("There was an error in sending email", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } finally {
          handleClose();
        }
      } else {
        toast.error("One or more field(s) are empty", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    };

    return (
      <>
        <Modal
          show={props.show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title className="h6 font-weight-bold">
              Email clients
            </Modal.Title>
          </Modal.Header>
          <p
            className="mb-1 p-2 border-bottom"
            style={{ borderColor: "#515151" }}
          >
            <span className="small text-muted mr-2">To</span> all clients
          </p>
          <Modal.Body>
            <Form.Group className="mb-1">
              <Form.Label className="small text-muted font-weight-bold">
                Subject
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </Form.Group>

            <Form.Group className="my-1">
              <Form.Label className="small text-muted font-weight-bold">
                Message body
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                data={messageBody ?? ""}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMessageBody(data);
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </Form.Group>

            <Alert show={discardShow} variant="danger">
              <Alert.Heading className="h6">Discard</Alert.Heading>
              <p className="small">Are you sure you want to discard?</p>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  onClick={discardEmail}
                  variant="outline-danger"
                  className="mr-2"
                >
                  Yes, discard
                </Button>
                <Button
                  size="sm"
                  onClick={() => setDiscardShow(false)}
                  variant="outline-secondary"
                >
                  No, cancel discard
                </Button>
              </div>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            {isThereAnyContent ? (
              <Button
                variant="secondary"
                onClick={() => setDiscardShow(true)}
                disabled={discardShow}
              >
                <span>
                  Discard{" "}
                  <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
                </span>
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                <span>Close</span>
              </Button>
            )}
            <Button variant="primary" onClick={submitEmail}>
              <span>
                Send{" "}
                <FontAwesomeIcon
                  size="sm"
                  icon={faPaperPlane}
                  className="ml-1"
                />
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const ticketTypeHandler = (type) => {
    if (type) {
      return "VIP";
    } else {
      return "Visitor";
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchTerm(e.target.value);
    const reg = _.escapeRegExp(searchTerm);
    // console.log(reg,"Reg")
    const regex = new RegExp(reg, "gi");
    setRegex(regex);
    //this.setState({ regex: regex })
  };

  return (
    <>
      <div className="container-fluid py-4 text-light">
        <div className="mt-3 overflow-auto h-100">
          <div className="col-md-12 h-100">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h5>Clients Management</h5>
                {/* <div className="row">
                  <div className="col-md-6">
                    <p className="small text-secondary">
                      Create profiles for your managers here. Once created,
                      you’ll need to invite your manager. They will then have
                      the option of accepting or editing your suggested profile.
                    </p>
                  </div>
                </div> */}
                <div className="row mb-2 justify-content-between">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center rounded border w-100 search-bar bg-dark text-light">
                      <div className="py-2 pr-2 pl-3">
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="sm"
                          color="#aeaeae"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <Form.Control
                          type="text"
                          id="participantsSearch"
                          className="border-0 px-0  bg-dark text-light"
                          onChange={handleSearch}
                          placeholder={"Search by name, email"}
                          value={searchTerm}
                          style={{ margin: 0 }}
                        />
                      </div>
                      {searchTerm.length > 0 && (
                        <div
                          className="py-2 pl-2 pr-3"
                          onClick={() => setSearchTerm("")}
                        >
                          <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex col-md-5">
                    {/* <Select
                        placeholder="Ticket type"
                        options={filterOptions}
                        onChange={(e) => setCurrentFilterCriteria(e.value)}
                        className="w-100 mx-3"
                      ></Select> */}
                    <Select
                      placeholder="Sort"
                      options={sortOptions}
                      onChange={(e) => setCurrentSortCriteria(e)}
                      className="w-100 mx-3"
                    ></Select>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div></div>
                  <Modal show={showInviteModal} onHide={handleCloseInviteModal}>
                    <Modal.Header closeButton className="bg-dark text-light">
                      <Modal.Title>Invite a client</Modal.Title>
                    </Modal.Header>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Get Shareable Link" />
                      <Tab label="Email Link" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Modal.Body>
                        <Card>
                          <Card.Body>
                            <div className="border p-2 rounded-lg">
                              {inviteText}
                            </div>
                            <div className="my-2">
                              <CopyToClipboard
                                text={inviteText}
                                onCopy={() => {
                                  alert("Link copied!");
                                }}
                              >
                                <Button>Copy text</Button>
                              </CopyToClipboard>
                            </div>
                          </Card.Body>
                        </Card>
                      </Modal.Body>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Modal.Body>
                        <Form.Group className="my-1">
                          <Form.Label className="small text-muted font-weight-bold">
                            Message body
                          </Form.Label>
                          <CKEditor
                            id="editor"
                            editor={ClassicEditor}
                            config={{
                              toolbar: [
                                "heading",
                                "bold",
                                "italic",
                                "link",
                                "numberedList",
                                "bulletedList",
                                "undo",
                                "redo",
                              ],
                            }}
                            data={inviteEmailMessage}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              // console.log( 'Editor is ready to use!', editor );
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setInviteEmailMessage(data);
                            }}
                            onBlur={(event, editor) => {
                              // console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                              // console.log( 'Focus.', editor );
                            }}
                          />
                        </Form.Group>

                        <h6>Enter a email</h6>
                        <TextField
                          label="Enter a email address to invite"
                          onChange={(e) => setInviteEmail(e.target.value)}
                          className="w-100"
                        />
                        {emailError && (
                          <div className="my-2 text-danger font-13">
                            Please enter a valid email address!
                          </div>
                        )}
                        <div className="my-3 text-right">
                          <span
                            className="btn btn-primary"
                            onClick={handleInviteEmail}
                          >
                            Submit{" "}
                            {sendingInvite && (
                              <FontAwesomeIcon
                                icon={faCircleNotch}
                                className="fa-spin"
                              />
                            )}
                          </span>
                        </div>
                      </Modal.Body>
                    </TabPanel>
                  </Modal>
                  <div className="d-flex">
                    <Button
                      onClick={handleShowInviteModal}
                      className="btn-sm btn-dark mr-2"
                    >
                      Invite client
                    </Button>
                    <Button
                      onClick={() => {
                        setEmailAllAttendee(true);
                      }}
                      className="btn-sm btn-primary mr-2"
                    >
                      Email clients
                    </Button>
                    <Button
                      className="btn-sm btn-secondary mr-2"
                      onClick={handleShowOfflineRegistration}
                    >
                      Register Offline
                    </Button>
                    <Modal
                      show={showOfflineRegistration}
                      onHide={handleCloseOfflineRegistration}
                      animation={false}
                    >
                      <Modal.Header
                        className="bg-dark text-light font-overpass"
                        closeButton
                      >
                        <Modal.Title>Register a client</Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        style={{
                          backgroundColor: "#cee5d0",
                        }}
                      >
                        <div className="mx-0 my-2 shadow-sm rounded-lg p-3 bg-info text-light">
                          <h5 className="font-overpass ">
                            Fill all details to register a client
                          </h5>
                          <div className="text-light font-14">
                            <li>Default password will be: igesia123</li>
                          </div>
                        </div>
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div className="row  mx-0">
                              <div className="col-6 my-2 shadow-sm rounded-lg p-2 bg-light">
                                <TextField
                                  className="w-100"
                                  label="First Name"
                                  name="firstName"
                                  onChange={handleFormChanges}
                                  value={formDetails.firstName}
                                />
                                {formErrors.firstName && (
                                  <div className="font-13 my-1 text-danger">
                                    {formErrors.firstName}
                                  </div>
                                )}
                              </div>
                              <div className="col-6 my-2 shadow-sm rounded-lg p-2 bg-light">
                                <TextField
                                  className="w-100"
                                  label="Last Name"
                                  name="lastName"
                                  onChange={handleFormChanges}
                                  value={formDetails.lastName}
                                />
                                {formErrors.lastName && (
                                  <div className="font-13 my-1 text-danger">
                                    {formErrors.lastName}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 my-2 shadow-sm rounded-lg p-2 bg-light">
                                <TextField
                                  name="email"
                                  type="email"
                                  className="w-100"
                                  label="Email"
                                  onChange={handleFormChanges}
                                  value={formDetails.email}
                                />
                                {formErrors.email && (
                                  <div className="font-13 my-1 text-danger">
                                    {formErrors.email}
                                  </div>
                                )}
                              </div>
                              <div className="col-12 my-2 shadow-sm rounded-lg p-2 bg-light">
                                <TextField
                                  type="number"
                                  name="phone"
                                  className="w-100"
                                  label="Mobile Number (optional)"
                                  onChange={handleFormChanges}
                                  value={formDetails.phone}
                                />
                                {formErrors.phone && (
                                  <div className="font-13 my-1 text-danger">
                                    {formErrors.phone}
                                  </div>
                                )}
                              </div>
                              {/* <div className="col-12 my-2 shadow-sm rounded-lg p-2 bg-light">
                                  <label className="text-muted">
                                    Date of Birth
                                  </label>
                                  <TextField
                                    type="date"
                                    className="w-100"
                                    onChange={handleFormChanges}
                                  />
                                </div>
                                <div className="col-12 my-2 shadow-sm rounded-lg p-2 bg-light">
                                  <TextField
                                    select
                                    className="w-100"
                                    label="Gender"
                                    name="gender"
                                    onChange={handleFormChanges}
                                  >
                                    <MenuItem key={0} value={0}>
                                      Male
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                      Female
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                      Other
                                    </MenuItem>
                                  </TextField>
                                </div> */}
                              <div className="col-12 p-0 text-right">
                                <ButtonMaterial
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  Register{" "}
                                  {(signing || writingUserData) && (
                                    <FontAwesomeIcon
                                      icon={faCircleNotch}
                                      className="fa-spin ml-2"
                                    />
                                  )}
                                </ButtonMaterial>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Modal.Body>
                      {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOfflineRegistration}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseOfflineRegistration}>
            Save Changes
          </Button>
        </Modal.Footer> */}
                    </Modal>
                    <ExcelFile
                      element={
                        <button className="mr-2 btn btn-outline-dark btn-sm">
                          Export
                        </button>
                      }
                    >
                      <ExcelSheet data={filteredDataArray} name={"Clients"}>
                        <ExcelColumn
                          label="Name"
                          value={(participant) =>
                            participant.first_name + " " + participant.last_name
                          }
                        />
                        <ExcelColumn
                          label="Email"
                          value={(participant) => participant.email_address}
                        />
                        <ExcelColumn
                          label="Phone"
                          value={(participant) =>
                            participant.phone_number?.number
                          }
                        />
                        <ExcelColumn
                          label="Ticket type"
                          value={(participant) =>
                            ticketTypeHandler(participant.user_role)
                          }
                        />
                        <ExcelColumn
                          label="Registration time"
                          value={(participant) =>
                            moment(
                              participant.timestamp.seconds
                                ? participant.timestamp.toDate()
                                : participant.timestamp
                            ).format("lll")
                          }
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              {searchTerm && searchTerm.trim().length ? (
                <>
                  {filteredDataArray
                    .filter((item) => {
                      return (
                        item?.display_name?.match(regex) ||
                        item?.email_address?.match(regex) ||
                        item?.phone_number?.number?.match(regex)
                      );
                    })
                    .map((attendee, index) => {
                      return (
                        <div className="col-md-4 mb-4 col-12" key={index}>
                          <div className="icon-box bg-dark text-light shadow-sm rounded w-100 h-100 px-4 py-3">
                            <div className="d-flex align-items-center">
                              <div className="mr-2 small">{index + 1}</div>
                              <div>
                                <Avatar
                                  name={
                                    attendee.first_name +
                                    " " +
                                    attendee.last_name
                                  }
                                  round={true}
                                  size="40"
                                  textSizeRatio={1.75}
                                  className="mr-3"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h3 className="text-s-10 mb-0 text-capitalize">
                                  {attendee.first_name +
                                    " " +
                                    attendee.last_name}
                                </h3>
                                <p className="small mb-0">
                                  {attendee.email_address}
                                </p>
                                <p className="small mb-0">
                                  {attendee.phone_number?.number}
                                </p>
                                <p className="small mb-0">
                                  {ticketTypeHandler(attendee.user_role)}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <EmailAttendeeModal
                                email_address={attendee.email_address}
                                phone_number={attendee.phone_number?.number}
                                first_name={attendee.first_name}
                                last_name={attendee.last_name}
                                sendEmail={sendEmail}
                              />
                              <Button
                                variant="outline"
                                className="btn-sm btn-outline-danger ml-1"
                                onClick={() => {
                                  setSelectedDisplayName(
                                    attendee.first_name + attendee.last_name
                                  );
                                  setSelectedUID(attendee.id);
                                  setDeleteWarningModal(true);
                                }}
                              >
                                <span>Delete</span>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {filteredDataArray &&
                    filteredDataArray.map((attendee, index) => {
                      return (
                        <div className="col-md-4 mb-4 col-12" key={index}>
                          <div className="icon-box bg-dark text-light shadow-sm rounded w-100 h-100 px-4 py-3">
                            <div className="d-flex align-items-center">
                              <div className="mr-2 font-weight-bold small">
                                {index + 1}.
                              </div>
                              <div>
                                <Avatar
                                  name={
                                    attendee.first_name +
                                    " " +
                                    attendee.last_name
                                  }
                                  round={true}
                                  size="40"
                                  textSizeRatio={1.75}
                                  className="mr-3"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h3 className="text-s-10 mb-0 text-capitalize">
                                  {attendee.first_name +
                                    " " +
                                    attendee.last_name}
                                </h3>
                                <p className="font-12 mb-0">
                                  {attendee.email_address}
                                </p>
                                <p className="small mb-0">
                                  {attendee.phone_number?.number}
                                </p>
                                <p className="small mb-0">
                                  {/* {ticketTypeHandler(attendee.user_role)} */}
                                  <br />
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <EmailAttendeeModal
                                email_address={attendee.email_address}
                                phone_number={attendee.phone_number?.number}
                                first_name={attendee.first_name}
                                last_name={attendee.last_name}
                                sendEmail={sendEmail}
                              />
                              <Button
                                variant="outline"
                                className="btn-sm btn-outline-danger ml-1"
                                onClick={() => {
                                  setSelectedDisplayName(
                                    attendee.first_name + attendee.last_name
                                  );
                                  setSelectedUID(attendee.id);
                                  setDeleteWarningModal(true);
                                }}
                              >
                                <span>Delete</span>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                              {attendee.isBlocked ? (
                                <span
                                  onClick={() =>
                                    unblockUserHandler(attendee.id)
                                  }
                                  className="btn btn-outline-success mx-1 btn-sm"
                                >
                                  Unblock
                                </span>
                              ) : (
                                <span
                                  onClick={() => blockUserHandler(attendee.id)}
                                  className="btn btn-dark mx-1 btn-sm"
                                >
                                  Block
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <EmailAllAttendeeModal
        email_address={allAttendeesEmailArray}
        sendEmail={sendEmail}
        show={emailAllAttendee}
        setShow={(e) => setEmailAllAttendee(e)}
      />

      <Modal
        show={deleteWarningModal}
        onHide={() => {
          setSelectedUID("");
          setTypedDisplayName("");
          setDeleteWarningModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>Confirm deletion</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete? This action cannot be reversed</p>
          <div className="d-flex justify-content-end flex-column">
            {/* <p>
              Confirm that you want to delete the attendee by typing its name:
              <span className="font-weight-bold">{selectedDisplayName}</span>
            </p>
            <Form.Control
              type="text"
              placeholder={`${selectedDisplayName}`}
              onChange={(e) => {
                setTypedDisplayName(e.target.value);
              }}
            /> */}
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                className="btn-sm mr-3"
                onClick={() => {
                  setSelectedUID("");
                  setTypedDisplayName("");
                  setDeleteWarningModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="btn-sm"
                onClick={() => {
                  // if (selectedDisplayName === typedDisplayName) {
                  deleteUserHandler(selectedUID);
                  setDeleteWarningModal(false);
                  setSelectedUID("");
                  setTypedDisplayName("");
                  toast.success("Attendee deleted", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  // } else {
                  //   toast.error("Confirm deletion by typing attendee's name", {
                  //     position: toast.POSITION.TOP_CENTER,
                  //     hideProgressBar: true,
                  //     autoClose: 3000,
                  //   });
                  // }
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => ({ currentLanguage: "EN" }))(Patients);
