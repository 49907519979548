import React, { useState, useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, Form, ProgressBar } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Viewer from "react-viewer";
import {
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faFileExcel,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import Linkify from "linkifyjs/react";
import * as linkify from "linkifyjs";

export default function Media({ key, data, eventKey }) {
  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const [imageSelected, setImageSelected] = useState("");
  const [visible, setVisible] = useState(false);

  const ImageViewerCaller = (image, content) => {
    setImageSelected([{ src: image, alt: content }]);
    setVisible(true);
  };

  if (eventKey == "images" && data.type == 6) {
    return (
      <div
        key={key}
        className="col-md-4 mb-3 d-flex align-items-center justify-content-center pr-0"
        style={{ height: 150, overflow: "hidden" }}
      >
        <div className="border border-dark ">
          <img
            className="img-fluid"
            src={data.image}
            onClick={() => ImageViewerCaller(data.image, data.content)}
            style={{ height: "auto", width: "100%" }}
          />
        </div>

        <div>
          <Viewer
            visible={visible}
            onClose={() => {
              setVisible(false);
            }}
            images={imageSelected}
          />
        </div>
      </div>
    );
  }

  if (eventKey == "files" && data.type == 7) {
    return (
      <Card key={key} className="bg-dark text-white mb-3">
        {/* <Card.Header className="d-flex align-items-start">
          <div className="d-flex">
            <div
              className="img-fluid rounded-circle shadow-sm"
              style={{
                height: 50,
                width: 50,
                backgroundImage: `url(${data?.user?.avatar})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div className="ml-2">
              <div className="d-flex align-items-center">
                <p className="mb-0 mr-2">{data?.user?.name}</p>
                <p className="mb-0 small text-muted"></p>
              </div>
              <p className="mb-0 small text-muted">
                {moment(data?.timestamp).format("LLL")}
              </p>
            </div>
          </div>
        </Card.Header> */}
        <Card.Body className="py-1">
          <a
            href={data.file}
            className="text-theme w-100 text-decoration-none"
            target="_blank"
            key={data.id}
          >
            {data.metaData ? (
              <div className="col-12 p-2 rounded shadow border-1 bg-theme pointer">
                {word_type.includes(data.metaData.type) && (
                  <FontAwesomeIcon
                    icon={faFileWord}
                    className="text-primary mr-1"
                  />
                )}
                {pdf_type.includes(data.metaData.type) && (
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    className="text-danger mr-1"
                  />
                )}
                {powerpoint_type.includes(data.metaData.type) && (
                  <FontAwesomeIcon
                    icon={faFilePowerpoint}
                    className="text-danger mr-1"
                  />
                )}
                {excel_type.includes(data.metaData.type) && (
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    className="text-success mr-1"
                  />
                )}{" "}
                {data.metaData.name}
              </div>
            ) : (
              <div className="col-12 p-2 rounded shadow border-1 bg-theme pointer">
                <FontAwesomeIcon
                  icon={faQuestion}
                  className="text-success mr-1"
                />{" "}
                Unknown File
              </div>
            )}
          </a>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    );
  }

  if (eventKey == "links" && data.type == 12) {
    return (
      <Card key={key} className=" bg-dark text-white mb-3">
        {/* <Card.Header className="d-flex align-items-start">
          <div className="d-flex">
            <div
              className="img-fluid rounded-circle shadow-sm"
              style={{
                height: 50,
                width: 50,
                backgroundImage: `url(${data?.user?.avatar})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div className="ml-2">
              <div className="d-flex align-items-center">
                <p className="mb-0 mr-2">{data?.user?.name}</p>
              </div>
              <p className="mb-0 small text-muted">
                {moment(data?.timestamp).format("LLL")}
              </p>
            </div>
          </div>
        </Card.Header> */}
        <Card.Body className=" py-1">
          <div className="w-100 pointer">
            <div
              onClick={() => window.open(data.metaTags.og.url, "_blank")}
              className="d-flex align-items-top overflow-hidden text-theme-light rounded bg-theme pointer p-1"
            >
              {data.metaTags.og.image &&
              linkify.find(data.metaTags.og.image).length > 0 &&
              linkify.find(data.metaTags.og.image)[0].type === "url" ? (
                <div
                  className="rounded-left url_message_img"
                  style={{
                    backgroundImage: `url(${data.metaTags.og.image})`,
                  }}
                ></div>
              ) : null}
              <div className="flex-grow-1 d-flex flex-column url_message_text">
                {data.metaTags.meta.title ? (
                  <div
                    style={{ textOverflow: "ellipsis" }}
                    title={data.metaTags.meta.title}
                    className="text-theme-light"
                  >
                    <span className="small text-truncate">
                      {data.metaTags.meta.title}
                    </span>
                  </div>
                ) : null}
                {data.metaTags.meta.description ? (
                  <div
                    style={{ textOverflow: "ellipsis" }}
                    className="flex-grow-1 text-muted overflow-hidden"
                  >
                    <span
                      className="small"
                      title={data.metaTags.meta.description}
                    >
                      {data.metaTags.meta.description?.replace(
                        /(\r\n|\n|\r)/gm,
                        ""
                      )}
                    </span>
                  </div>
                ) : null}
                <div
                  style={{ textOverflow: "ellipsis" }}
                  className="text-theme-light flex-none small"
                >
                  {data.metaTags.og.site_name ? (
                    <span title={data.metaTags.og.site_name}>
                      {data.metaTags.og.site_name}
                    </span>
                  ) : (
                    <span title={data.metaTags.og.site_name}>
                      {data.metaTags.og.url}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
  return null;
}
