import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EventNavbar from "../NavBar";
import EventLeftbar from "../LeftBar";
import { 
    changeLanguage,    
    setProducts,
    setGraphId } from "../../../../store/actions/actions";
import "@culturehq/add-to-calendar/dist/styles.css";
import firebase from "firebase";
import moment from "moment";
import { useHistory , useParams} from "react-router";
import "./apps.css";
import { useCollection } from "react-firebase-hooks/firestore";
import Highcharts from "highcharts/highstock";
import { Form, Button, Dropdown, DropdownButton } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HC_more from "highcharts/highcharts-more";
import _ from "lodash";


HC_more(Highcharts);

require("highcharts/modules/draggable-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts)

var yourLabels = ["Low", "Medium", "High"];

function ToBeTemplate(props) {

  const [tobeProducts, setToBeProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState([]);

  const [factorsCollection, factorsCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.eventId}/bbos/${props.graph.id}/factors`)
  );

  const factorsArray = factorsCollection?.docs
    ?.sort((a, b) => a.position - b.position)
    ?.map((item) => {
      return { ...item.data(), id: item.id };
    });

  useEffect(() => {
    if (factorsArray) {
      let data = factorsArray;
      setSelectedFactors([...data]);
    }
  }, [factorsCollection]);


  const [productCollection, productCollectionLoading, err] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.eventId}/bbos/${props.graph.id}/as_is`)
  );

  const getYDataHandler = async () => {
    try {
      if (productCollection?.docs?.length) {
        // setLoading(true)
        const collectionData = await Promise.all(
          productCollection?.docs?.map(async (item) => {
            let docRef = firebase
              .firestore()
              .collection(
                `events/${props?.eventId}/bbos/${props.graph.id}/as_is/${item.id}/values`
              );
            let value = []
            const data = await Promise.all(
              selectedFactors?.sort((a, b) => a.position - b.position).map(async (factor) => {
                const doc = await docRef.doc(factor.id).get();
                if (doc.exists) {
                  return doc.data().value;
                } else {
                  return factor.defaultValue;
                }
              })
            );
            if (data.length === selectedFactors.length) {
              value.push({
                productName: item.data().name,
                name: props.currentEvent?.bbos_names?.as_is ?? "As Is Strategy",
                color: item.data().color,
                id: item.id,
                data: data,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              })
            }


            const data1 = await Promise.all(
                selectedFactors?.map(async (factor) => {
                return factor.defaultValue;
              })
            );
            if (data.length === selectedFactors.length) {
              value.push({
                name: props.currentEvent?.bbos_names?.to_be ?? "To Be Strategy",
                color: "blue",
                data: data1,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              });
            }
            return value;
          })
        );
        //console.log(collectionData, "Collection data")
        setSelectedProduct(collectionData[0])
      }
    } catch (e) {
      console.error(e, "Something went wrong");
    } finally {
      // setLoading(false)
    }
  };


  let options = {
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      renderTo: "container",
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: selectedProduct[0]?.productName,
    },
    xAxis: {
      categories: selectedFactors?.map(x => x.name),
    },

    yAxis: {
      min: 0,
      max: 2,
      labels: {
        formatter: function () {
          return yourLabels[this.value];
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.series.name;
      },
    },
    series: selectedProduct,
  };



  useEffect(() => {
    if (!productCollectionLoading) {
      getYDataHandler();
    }
  }, [productCollectionLoading, productCollection]);

  

  
  return (
    <>
      <div className="m-4">
        {selectedProduct.length>0 ? 
        <>
        <HighchartsReact
          constructorType={"chart"}
          highcharts={Highcharts}
          options={options}
        />
        </>
        :
        null}
      </div>
    </>
  );
}














function ToBeList(props) {
    const [allGraphs, setAllGraphs] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        firebase.firestore().collection(`events/${props?.currentEvent?.id}/bbos`).onSnapshot((snapshot) => {
            const tempGraphs = [];
            snapshot.forEach((doc) => {
                //if (!doc.data().reset && (doc.data().datasets && doc.data().datasets.length)) {
                tempGraphs.push({ ...doc.data(), id: doc.id })
                //}

            })
            setAllGraphs(tempGraphs)
        })

    }, []);

    let history = useHistory();
    return (
        <div className="h-100 montserrat-font">
            <EventLeftbar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <EventNavbar />
                <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
                    <EventLeftbar />
                    <div
                        className="col-sm h-100 px-5 py-5 "
                        style={{
                            overflow: "scroll",
                            backgroundSize: "cover",
                            backgroundColor: "#EFEFEF",
                            // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/boa-stage-background.jpg)`
                        }}
                    >
                        {/* Networking */}

                        <div className="container py-3">
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="shadow-sm p-3 rounded bg-white">
                                            <h3>
                                                {props.currentEvent?.bbos_names?.to_be ?? "To Be Strategy"} Canvas Gallery
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row">
                                        {allGraphs && allGraphs.map((graph, key) => (
                                            <>
                                                {graph.to_be_created == true &&
                                                    <div
                                                        onClick={() => {
                                                            props.setGraphId(graph.id)
                                                            history.push(`/event/${id}/as_is/view/${graph.id}`)
                                                        }}
                                                        className="col-md-4 mb-4 col-12"
                                                        key={key}
                                                        style={{ overflow: 'hidden' }}
                                                    >
                                                        <div className="card event-card border-0">

                                                            {graph.to_be_created && <ToBeTemplate eventId={props?.currentEvent?.id} graph={graph} />}

                                                            <div className="card-body h-100 bg-white">
                                                                <div className=" mb-3 align-content-center">

                                                                    {graph.title && (
                                                                        <h4 className="text-s-6 text-dark">
                                                                            {graph.title}
                                                                        </h4>
                                                                    )}
                                                                    {graph.createdAt && (
                                                                        <p className=" text-dark">
                                                                            {moment(graph.createdAt).format("LLL")}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </>
                                            // <div
                                            //     onClick={() => {
                                            //         props.setGraphId(graph.id)
                                            //         history.push(`/event/bbos/as_is/view/${graph.id}`)
                                            //     }}
                                            //     className="shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 pointer"
                                            //     key={key}
                                            // >
                                            //     <div className="d-flex ">
                                            //         <div
                                            //             className="pointer"
                                            //         >
                                            //             <small className="text-theme-lighter smaller">
                                            //                 {graph?.createdAt && moment(graph.createdAt).format("LLL")}
                                            //             </small>
                                            //             <p className="m-0 font-weight-bold text-theme small">
                                            //                 {graph.title && <div>{graph.title}</div>}
                                            //             </p>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        ))}
                                    </div>

                                </div>

                            </React.Fragment>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        workspaceId: state.workspaceId,
        roomId: state.roomId,
        currentEvent: state.currentEvent,
    }),
    { changeLanguage, setGraphId, setProducts }
)(ToBeList);
