import React from "react";
import { ProgressBar, Container, Row, Col, Card, Table } from "react-bootstrap";
import Avatar from "react-avatar";
import { memo } from "react";

const ProjectStats = () => {
  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <Card.Body>
                <div className="responsive-tbl">
                  <div className="item">
                    <div className="table-responsive product-list">
                      <Table borderless className="text-white">
                        <thead>
                          <tr>
                            <th> #</th>
                            <th>{"Projects"}</th>
                            <th colSpan="4">{"% Completed"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Eat Fit: Healthy Food Project
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <ProgressBar
                                now={89}
                                label={`${89}% Completed`}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Project C
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <ProgressBar
                                now={60}
                                label={`${60}% Completed`}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(ProjectStats);
