import React, { useState, useEffect, useRef } from "react";
import "./WriteNote.css";

import JoditEditor from "jodit-react";

const WriteNote = ({
  setNoteWriteMode,
  EditorData,
  setEditorData,
  NoteTitle,
  setNoteTitle,
  error,
  setError,
  NoteEdit,
}) => {
  const editor = useRef(null);

  useEffect(() => {
    if (NoteEdit) {
      setEditorData(NoteEdit.content);
      setNoteTitle(NoteEdit.title);
    } else {
      setEditorData("");
      setNoteTitle("");
    }
  }, [NoteEdit]);

  const config = {
    readonly: false, 
    theme: "light",
    height: 360,
    allowResizeX: false,
    allowResizeY: false,
    disablePlugins: "image,video,media,file,class-span,image-properties,image-processor",
    buttons:
    "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,font,fontsize,paragraph,brush,cut,copy,print",
    removeButtons: ["file", "image","copyformat","link","source"],
  };

  const handleNoteTitleChange = (e) => {
    const Value = e.target.value;

    console.log(Value.length);
    if (Value.length > 250) {
      error["note"] = "Should be less than 250 characters!";
      setError({ ...error });
      return;
    } else {
      if (error.note) {
        error["note"] = "";
        setError({ ...error });
      }
    }
    setNoteTitle(Value);
  };

  return (
    <div className="write-note-bg">
      <div className="write-note">
        <input
          type="text"
          placeholder="Title"
          className="write-note-title"
          autoFocus
          name="note-title"
          value={NoteTitle}
          onChange={handleNoteTitleChange}
        />
        {error.note && (
          <div className="text-danger font-13 ml-2">{error.note}</div>
        )}
        {/* <textarea className="mt-2" /> */}
        <div className="write-note-editor ">
          <JoditEditor
            ref={editor}
            value={EditorData}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={(newContent) => setEditorData(newContent)}
          />
        </div>
      </div>
    </div>
  );
};

export default WriteNote;
