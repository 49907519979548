import React, { useState, useMemo, memo, useEffect } from "react";
import firebase from "firebase";
import axios from "axios";
import moment from "moment";
import { useDocument } from "react-firebase-hooks/firestore";
import {
  Card,
  Button,
  Spinner,
  Accordion,
  Modal,
  ProgressBar,
  Image,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import UserDetails from "../UserDetails";
import PollFields from "./PollFields";
import {
  API_BASE_URL,
  POLL_ANALYTICS,
  ADMIN_APP_RESPONSES_GET,
} from "../../../../config";
import { auth } from "../../../../firebase";
import PollLoader from "./PollLoader";
import UserProfile from "../UserProfile";

const Poll = (props) => {
  // CONSTANTS
  const {
    data,
    darkTheme,
    currentEvent,
    handleCloseItem,
    setShowMoreDetailsPanel,
    setSelectedMoreDetails,
    selectedMoreDetails,
    showMoreDetailsPanel,
  } = props;
  const user = auth.currentUser;

  // STATES
  const [show, setShow] = useState(false);
  const [pollId, setPollId] = useState(null);
  const [roomDetails, setRoomDetails] = useState(null);
  const [responseAnalytics, setResponseAnalytics] = useState({});
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [pollData, setPollData] = useState(null);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [count, setCount] = useState(0);
  const [allResponses, setAllResponses] = useState([]);

  // FUNCTIONS
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);

  const getRoomHandler = async () => {
    const roomDoc = firebase
      .firestore()
      .collection(`workspaces/${currentEvent?.workspace_id}/rooms`)
      .doc(`${currentEvent?.room_id}`);
    const roomDocGet = await roomDoc.get();
    setRoomDetails(roomDocGet.data());
  };

  useEffect(() => {
    getRoomHandler();
  }, []);

  const getResponseAnalytics = () => {
    axios
      .post(`${API_BASE_URL}${POLL_ANALYTICS}`, {
        user_id: user.uid,
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        published_poll_id: data.contentId,
      })
      .then((res) => {
        if (res.status === 200) {
          setResponseAnalytics(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleFetchAppResponses = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${ADMIN_APP_RESPONSES_GET}?content_id=${data?.contentId}&type=poll`
      );

      if (response.status === 200) {
        const { data } = response.data;

        setPollData(data);
        setAllResponses(data?.responses);
        setCount(data?.responses?.length);
        setFetchingDetails(false);
      } else {
        setFetchingDetails(false);
      }
    } catch (error) {
      console.log("[Error] Fetching poll details: ", error);
      setFetchingDetails(false);
    }
  };

  useEffect(() => {
    // Fetch: Poll Analytics
    getResponseAnalytics();

    // Fetch: App details
    handleFetchAppResponses();
  }, [data.contentId]);

  const removePoll = async (content_id) => {
    try {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/polls`)
        .doc(`${content_id}`)
        .delete()
        .then(() => {
          if (darkTheme) {
            toast.dark("Poll deleted.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            toast("Poll deleted.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
          handleClose();
        });
    } catch (error) {
      console.log(error);

      toast.warning(`Error: ${error.response.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const foo = (item, arr, responseAnalytics) => {
    return (
      <>
        {item &&
          item["options"].map((option, key) => (
            <div>
              {item.images && item.images.length > 0 && (
                <Image
                  src={item.images[key]}
                  style={{ height: 100, width: "auto" }}
                  // onClick={() => {
                  //     setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
                  //     setActiveIndex(key)
                  // }}
                />
              )}

              <div className="d-flex justify-content-between">
                <p className="mb-1">{option}</p>
                <p className="mb-1"></p>
                <p className="mb-1">
                  {responseAnalytics && responseAnalytics.optionCount ? (
                    <div>{`${responseAnalytics?.optionCount[option]}/${responseAnalytics?.optionCount["total"]} `}</div>
                  ) : null}
                </p>
              </div>
              <ProgressBar
                style={{ height: "1rem" }}
                className="mb-1"
                animated
                now={
                  responseAnalytics
                    ? responseAnalytics.percentage
                      ? responseAnalytics.percentage[option]
                      : 0
                    : 0
                }
                label={
                  responseAnalytics
                    ? responseAnalytics.percentage
                      ? responseAnalytics?.percentage[option] + "%"
                      : null
                    : null
                }
              />
              {/*
                    responseAnalytics && responseAnalytics.optionCount ?
                    <div>{`${responseAnalytics?.optionCount[option]} out of ${responseAnalytics?.optionCount["total"]} participant(s)`}</div>
                    : null
                  */}
            </div>
          ))}
        {/* {item.images && <Viewer
                  visible={imageViewerOpen === item.uid ? true : false}
                  onClose={() => setImageViewerOpen(prev => prev === item.uid ? null : item.uid)}
                  images={item.images.map((image, key) => { return {src: image, alt: item.options[key]}})}
                  activeIndex={activeIndex}
                  downloadable={false}
              />} */}
      </>
    );
  };

  if (fetchingDetails) {
    return <PollLoader handleCloseItem={handleCloseItem} />;
  }

  return (
    <React.Fragment>
      <div className="border border-secondary text-white mr-3">
        <div className="p-3 d-flex align-items-center border-bottom border-secondary">
          <h5 className="flex-grow-1 mb-0">{data?.title}</h5>
          <div>
            <a
              disabled={count > 0}
              title={count > 0 ? "Cannot edit" : "Edit"}
              size="sm"
              onClick={handleShowEditModal}
              className="mr-1 text-white pointer p-2"
            >
              <FontAwesomeIcon icon={faEdit} />
            </a>

            <a
              className="mr-1 text-white pointer p-2"
              onClick={() => {
                handleShow();
                setPollId(data.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </a>

            <a className="text-white pointer p-2" onClick={handleCloseItem}>
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </div>
        </div>
        <div className="p-3">
          <div className="mb-3">
            <p className="text-muted small mb-0">Created at</p>
            <p className="small mb-0">
              {moment(
                convertSecondstoMilliSeconds(
                  pollData?.createdAt._seconds,
                  pollData?.createdAt._nanoseconds
                )
              ).format("LLL")}
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Deadline by</p>
            <p className="small mb-0">
              {moment(pollData?.deadline).format("LLL")} (
              {moment(pollData?.deadline).fromNow()})
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Total number of responses</p>
            <p className="small mb-0">{count}</p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Analytics of responses</p>
            {allResponses?.length > 0 ? (
              <div>
                {!loading &&
                  foo(pollData, pollData.response, responseAnalytics)}
              </div>
            ) : (
              <p className="text-center">
                <i>No responses yet</i>
              </p>
            )}
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Responses</p>
            {allResponses?.length > 0 ? (
              <React.Fragment>
                {allResponses?.map((response, key) => {
                  return (
                    <div
                      key={key}
                      className="border border-secondary mb-2 py-2 px-3"
                      title={`Click to see responses`}
                      onClick={() => {
                        setShowMoreDetailsPanel(true);
                        setSelectedMoreDetails({
                          response,
                        });
                      }}
                      style={
                        selectedMoreDetails?.response?.id === response?.id
                          ? { backgroundColor: "rgba(200, 200, 200, 0.15)" }
                          : {}
                      }
                    >
                      <UserProfile
                        userDetails={response.user_details}
                        imgSize={40}
                        subText={moment(response.responseTime).format("LLL")}
                      />
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <p className="text-center">
                <i>No responses yet</i>
              </p>
            )}
          </div>
        </div>

        {/* Modal: Delete Item */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to "Delete" this Poll?</p>
            <p className="mb-0 small text-muted">
              <b>Note:</b> Polls deleted are still part of your room "
              {roomDetails?.roomName}"
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                setPollId(null);
              }}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={() => removePoll(pollId)}>
              Yes, Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal: Edit Item */}
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Poll</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <PollFields
              onHide={handleCloseEditModal}
              pollData={pollData}
              isEditting={true}
              id={data.id}
            />
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(memo(Poll));
