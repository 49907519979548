import React from "react";
import { Card, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import firebase from "firebase";

const Failed = () => {
  const history = useHistory();
  const userDetails = firebase.auth().currentUser;

  if (!userDetails) {
    return <Redirect to="/pricing" />;
  }

  return (
    <div style={{ backgroundColor: "#efeff6" }}>
      <div className="container ">
        <div className="d-flex justify-content-center align-items-center " style={{ height: '100vh' }}>
          <div className="col-md-5 col-12 mx-auto ">
            <Card>
              <Card.Header className="p-5 text-center" style={{ backgroundColor: "#ffffff" }}>
                <img
                  className="img-fluid shadow-sm mb-2"
                  src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fimages.png?alt=media&token=62770cea-6979-4145-92a1-5f20f792b420`}
                  style={{ borderRadius: "20px" }}
                  title={`Payment Failed`}
                  alt={`Payment Failed`}
                />
                <h5 className="splash-description my-5">Payment Failed.</h5>
                <Button className="my-2" onClick={() => history.push("/my-plan")}>GO TO MY PLAN</Button>
              </Card.Header>

            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Failed;
