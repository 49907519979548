import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Index";
import Overview from "./Overview";
import Publish from "./Publish";
// import Members from "./Members";
// import Posts from "./Posts";
import { useLocation } from "react-router";
import { setBlog } from "../../../store/actions/actions";
import { blogList } from "../../data";
import { connect } from "react-redux";
import BlogManagerNavBar from "./BlogManagerNavBar";

const BlogManager = (props) => {
  const nav_data = [
    {
      id: 1,
      name: "Overview",
      eventKey: "overview",
    },
    {
      id: 2,
      name: "Publish",
      eventKey: "publish",
    },
  ];

  useEffect(() => {
    props.setBlog(blogList[0]);
  }, []);

  const [currentNavKey, setCurrentNavKey] = useState("overview");

  const { hash } = useLocation();
  useEffect(() => {
    nav_data.map((item, key) => {
      if (decodeURIComponent(hash) === `#${item.eventKey}`) {
        setCurrentNavKey(item.eventKey);
      }
      if (hash === "") {
        setCurrentNavKey("overview");
      }
    });
  }, [hash]);

  return (
    <>
      <div className="d-flex vh-100" style={{ background: "#232626" }}>
        {/* <Sidebar /> */}
        <div className="overflow-auto max-vh-100 p-4 w-100" id="style-4">
          {/* <BlogManagerNavBar
            setCurrentNavKey={setCurrentNavKey}
            navData={nav_data}
          /> */}
          {currentNavKey === "overview" && <Overview />}
          {currentNavKey === "publish" && <Publish />}
          {/* {currentNavKey === "members" && <Members />}
          {currentNavKey === "posts" && <Posts />} */}
        </div>
      </div>
    </>
  );
};
export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
  }),
  { setBlog }
)(BlogManager);
