import React, { useState, lazy, useEffect, memo } from "react";
import {
  Button,
  Card,
  Dropdown,
  ButtonGroup,
  Accordion,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import axios from "axios";
import produce from "immer";
import Section from "./Curriculum/Section";
import AddModuleModal from "./Curriculum/AddModuleModal";
import ModuleAccordion from "./Curriculum/ModuleAccordion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Skeleton } from "@material-ui/lab";
import CurriculumCard from "./Curriculum/CurriculumCard";
import { connect } from "react-redux";
toast.configure();

const Stage = (props) => {
  const [currentSection, setCurrentSection] = useState(null);

  const userDetails = firebase.auth().currentUser;

  const [showAddStageModal, setShowAddStageModal] = useState(false);

  const [showAddSection, setShowAddSection] = useState(false);

  const [showAddModuleModal, setShowAddModuleModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");

  const [showAddModuleButton, setShowAddModuleButton] = useState([]);
  const [addModuleType, setAddModuleType] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);

  // code from template

  // category/section manager
  const [sectionSnapshot, loadinSection, errorSnapshot] = useCollection(
    firebase
      .firestore()
      .collection(`/courses/${props.courseId}/sections`)
      .orderBy("order_index", "asc")
  );

  const courseCurriculum =
    sectionSnapshot?.docs?.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    }) ?? [];

  const [errors, setErrors] = useState({});

  const validationHandler = (e = true, courseCurriculum) => {
    if (e.target.name === "stage_name") {
      if (
        !courseCurriculum.stage_name ||
        !courseCurriculum.stage_name.trim().length
      ) {
        setErrors((prev) => {
          const errors = {
            ...prev,
            stage_name: "Stage name can not be empty.",
          };
          return errors;
        });
      }
      if (
        courseCurriculum.stage_name &&
        courseCurriculum.stage_name.trim().length
      ) {
        setErrors((prev) => {
          const errors = { ...prev, stage_name: null };
          return errors;
        });
      }
    }
    if (e.target.name === "stage_type") {
      if (
        !courseCurriculum.stage_type ||
        !courseCurriculum.stage_type.trim().length
      ) {
        setErrors((prev) => {
          const errors = { ...prev, stage_type: "Please select stage type" };
          return errors;
        });
      }
      if (
        courseCurriculum.stage_type &&
        courseCurriculum.stage_type.trim().length
      ) {
        setErrors((prev) => {
          const errors = { ...prev, stage_type: null };
          return errors;
        });
      }
    }

    if (errors.stage_name === null && errors.stage_type === null) {
      setErrors({});
      return 1;
    } else {
      return 0;
    }
  };
  const validationHandlerForm = (e = true, courseCurriculum) => {
    let error = 0;
    if (
      !courseCurriculum.stage_name ||
      !courseCurriculum.stage_name.trim().length
    ) {
      setErrors((prev) => {
        const errors = {
          ...prev,
          stage_name: "Stage name can not be empty.",
        };
        return errors;
      });
      error++;
    }
    if (
      courseCurriculum.stage_name &&
      courseCurriculum.stage_name.trim().length
    ) {
      setErrors((prev) => {
        const errors = { ...prev, stage_name: null };
        return errors;
      });
      if (error) {
        error--;
      }
    }
    if (
      !courseCurriculum.stage_type ||
      !courseCurriculum.stage_type.trim().length
    ) {
      setErrors((prev) => {
        const errors = { ...prev, stage_type: "Please select stage type" };
        return errors;
      });
      error++;
    }
    if (
      courseCurriculum.stage_type &&
      courseCurriculum.stage_type.trim().length
    ) {
      setErrors((prev) => {
        const errors = { ...prev, stage_type: null };
        return errors;
      });
      if (error) {
        error--;
      }
    }
    console.log(error);

    if ((errors.stage_name === null && errors.stage_type === null) || !error) {
      setErrors({});
      return 1;
    } else {
      return 0;
    }
  };

  const addStageToDBHandler = async (e, stage) => {
    e.preventDefault();
    const top_banner = stage.top_banner
      ? await uploadFile(stage.top_banner)
      : null;
    const bottom_banner = stage.bottom_banner
      ? await uploadFile(stage.bottom_banner)
      : null;
    const stage_background = stage.stage_background
      ? await uploadFile(stage.stage_background)
      : null;

    const stage_data = {
      stage_name: stage.stage_name ?? "",
      slug: stage.stage_name.toLowerCase().replace(/ /g, ""),
      stage_description: stage.stage_description ?? "",
      stage_type: stage.stage_type ?? "",
      top_banner,
      bottom_banner,
      stage_background,
      stream_link: stage.stream_link ?? "",
    };
    try {
      await firebase
        .firestore()
        .collection(`/courses/${props.courseId}/stages/`)
        .doc()
        .set(
          {
            ...stage_data,
          },
          { merge: true }
        );
      toast.success("Stage added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error adding stage", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setShowAddStageModal(false);
    }
  };

  const updateStageToDBHandler = async (e, stage) => {
    e.preventDefault();
    const top_banner = stage.top_banner
      ? await uploadFile(stage.top_banner)
      : null;
    const bottom_banner = stage.bottom_banner
      ? await uploadFile(stage.bottom_banner)
      : null;
    const stage_background = stage.stage_background
      ? await uploadFile(stage.stage_background)
      : null;

    const stage_data = {
      stage_name: stage.stage_name ?? "",
      stage_description: stage.stage_description ?? "",
      stage_type: stage.stage_type ?? "",
      top_banner,
      bottom_banner,
      stage_background,
      stream_link: stage.stream_link ?? "",
    };
    try {
      await firebase
        .firestore()
        .collection(`/courses/${props.courseId}/stages/`)
        .doc(stage.id)
        .update({
          ...stage_data,
        });
      toast.success("Stage updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error updating stage", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setIsUpdating(false);
      setShowAddStageModal(false);
    }
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();

      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        userDetails?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        // onUploadProgress: (ProgressEvent) => {
        //   setState({
        //     percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        //   });
        // },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const convertYoutubeLinkToEmbedHandler = (input) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = input.match(regExp);
    if (match && match[2].length === 11) {
      return "https://www.youtube.com/embed/" + match[2];
    } else {
      return input;
    }
  };

  return (
    <>
      <div className="py-4 text-light">
        <div className="col-md-12 h-100 mt-3 text-light">
          <h5>Curriculum Manager</h5>
          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <div></div>
              <div className="d-flex">
                <Button
                  className="btn-sm btn-secondary mr-2"
                  //   disabled
                  onClick={() => setShowAddSection(true)}
                >
                  + New section
                </Button>
              </div>
            </div>
            <div className="mt-3 small">
              {courseCurriculum.map((curriculum, index) => {
                return (
                  <CurriculumCard
                    currentSection={currentSection}
                    setCurrentSection={setCurrentSection}
                    setShowAddModuleButton={setShowAddModuleButton}
                    setSelectedSection={setSelectedSection}
                    showAddModuleButton={showAddModuleButton}
                    setShowAddModuleModal={setShowAddModuleModal}
                    setAddModuleType={setAddModuleType}
                    courseCurriculum={courseCurriculum}
                    index={index}
                    {...curriculum}
                    key={index}
                  />
                );
              })}
              {!courseCurriculum?.length ? (
                <>
                  <h5 className="text-center my-2">No section added yet</h5>
                  <h6 className="text-center">
                    Click on new section to add a section
                  </h6>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {showAddSection ? (
          <Section
            show={showAddSection}
            onHide={() => setShowAddSection(false)}
          />
        ) : null}
      </div>

      <AddModuleModal
        showAddScheduleModal={showAddModuleModal}
        setShowAddScheduleModal={setShowAddModuleModal}
        sectionId={selectedSection}
        addModuleType={addModuleType}
      />

      {/* modal for add stage */}
    </>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default memo(connect(mapStateToProps)(Stage));
