import { memo, useEffect, useMemo } from "react";
import { ProgressBar } from "react-bootstrap";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";

const PollView = (props) => {
  const { pollId } = props;

  const [pollSnapshot, loadingPoll, errorPoll] = useDocument(
    firebase.firestore().collection("polls").doc(pollId)
  );

  const pollData = useMemo(() => {
    return {
      ...pollSnapshot?.data(),
    };
  }, [pollSnapshot]);

  if (loadingPoll) {
    return <></>;
  }

  return (
    <div className="taskBox">
      <div className="poll">
        <div className="d-flex">
          <h5 className="mt-0 flex-grow-1" style={{ minWidth: "300px" }}>
            {pollData?.title}
          </h5>
          <div>
            <span className="text-success small">Active</span>
          </div>
        </div>
        <>
          {pollData &&
            pollData["options"]?.map((option, key) => (
              <div>
                <div className="d-flex justify-content-between">
                  <p className="mb-1">{option}</p>
                </div>
                <ProgressBar
                  style={{ height: "1rem" }}
                  className="mb-1"
                  animated
                  now={0}
                  label={null}
                />
              </div>
            ))}
        </>
      </div>
    </div>
  );
};
export default memo(PollView);
