import React, { useEffect, useRef, useState } from "react";
import { Card, Image, Button } from "react-bootstrap";
import { useHistory } from "react-router";

import {
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faFile,
  faKey,
  faLock,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as TimelineStyle from "../Timeline/timeline.module.css";
import EventImg from "../Timeline/assets/event.png";
import CourseImg from "../Timeline/assets/course.png";
import ConsultationImg from "../Timeline/assets/consultation.png";

import { courseList } from "../data";
import TimelineCover from "../Timeline/TimelineCover";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import moment from "moment";
import Navbar from "../Timeline/Navbar";

export default function Course() {
  const history = useHistory();
  const pageRef = useRef();
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [courseValue, courseLoading, courseError] = useCollection(
    firebase.firestore().collection("courses"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    if (courseValue) {
      let data = [];
      courseValue?.docs?.map((e) => {
        data.push({ id: e.id, ...e.data() });
      });
      setAllCourses([...data]);
    }
  }, [courseValue]);

  const [eventsValue, eventsLoading, eventsError] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("type", "==", 1)
      .where("is_published", "==", true)
      .limit(12),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    if (eventsValue) {
      let data = [];
      eventsValue?.docs?.map((e) => {
        if (e?.data()?.isDemo) {
          console.log(e?.data(), e.id);
          data.push({ id: e.id, ...e.data() });
        }
      });
      setAllEvents([...data]);
    }
  }, [eventsValue]);

  const formatCurrent = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 7,
    }).format(value);
  };

  return (
    <>
      <div
        className="container-fluid pt-3 pb-5 bg-gradient-colourful"
        ref={pageRef}
      >
        <div className="container pb-4">
          <Navbar />
          <div className="row"></div>
          <div className="row my-5">
            <div className="col-md-6 order-2 order-md-1 d-flex flex-column justify-content-center">
              <div className="text-light">
                <h1 className="mb-3 text-s-1">Next-gen education platform.</h1>
                <p className="text-s-6">
                  Create engaging paid synchronous, synchronous and blended
                  courses with assignments, grading and certification.
                </p>
                <a
                  href=""
                  style={{
                    background: "rgb(118, 60, 247)",
                    borderRadius: "12px",
                  }}
                  className="btn text-white decoration-none font-weight-bold mb-3"
                >
                  Request a Demo
                </a>
                <span
                  //href=""
                  style={{ background: "none", borderRadius: "12px" }}
                  className="btn text-white decoration-none font-weight-bold mb-3"
                >
                  Create your course{" "}
                  <sup className="text-warning">COMING SOON</sup>
                </span>
                {/* <a href="#" className="text-decoration-none d-inline-block mt-3" style={{ background: "rgba(0, 0, 70, 0.12)", transition: "background-color 200ms ease 0s", padding: "6px 11px 6px 13px", fontSize: "13px", fontWeight: "500", borderRadius: "50px" }}>
                  <span style={{ color: "#DDDDDD" }}>What's New:</span>
                  <span style={{ color: "#EFEFEF" }}> Igesia is now live! Learn more →</span>
                </a> */}
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2 mb-4">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-course.png"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5  bg-light">
        <div className="container">
          <h5 className="font-weight-bold mb-3">Discover Courses</h5>
          <div className="row">
            {/* Course card */}
            {allEvents?.length > 0 &&
              allEvents
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((event, key) => (
                  <div className="col-md-3 mb-3" key={key}>
                    <Card
                      className={`h-100`}
                      onClick={() => history.push(`event/${event.slug}`)}
                      style={{ cursor: "pointer" }}
                      title={`Event: ${event?.event_details
                          ? event?.event_details.EN?.event_name
                          : ""
                        }`}
                    >
                      <div
                        className="rounded-top"
                        style={{
                          backgroundImage: `url(${event?.event_details?.featured_image})`,
                          height: 150,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      />
                      {/* <Card.Img
                    variant="top"
                    src={event?.event_details?.featured_image}
                  /> */}
                      <Card.Body className="py-2 px-3 d-flex flex-column justify-content-between">
                        {/* <p className="small text-uppercase text-success mb-1">
                          Event
                        </p> */}
                        <p className="font-weight-bold  mb-1 small">
                          {event?.event_details?.EN?.event_name}
                        </p>
                        <p className="small mb-0 text-secondary">
                          {moment(event.start_date).format("DD MMMM, YYYY")}
                        </p>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
          </div>
          <div className="w-100 text-center">
            <a
              href=""
              style={{ background: "rgb(118, 60, 247)", borderRadius: "12px" }}
              className="btn text-white decoration-none font-weight-bold mt-3 "
            >
              Create your course
            </a>
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "rgb(245, 245, 247)",
          overflow: "hidden",
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-10">
              <h2 className="mb-3 text-s-1 text-blue">
                Create an engaging environment for your students that fosters
                better learning.
              </h2>
              <p className="text-s-4 text-black-333">
                With advanced networking features, making valuable connections
                is easier for your key stakeholders.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mb-4">
              <div className="gradient-box bg-gradient-blue  mb-3">
                <div className="row">
                  <div className="col-md-5">
                    <h3 className="mb-3  text-s-5 text-deep-blue">
                      Create and sell your courses without stress.
                    </h3>
                    <ul className="text-s-6 text-white">
                      <li className="mb-2">
                        Create asynchronous, synchronous and blended courses
                      </li>
                      <li className="mb-2">Sell your course online</li>
                      <li className="mb-2">Custom branding opportunities</li>
                      <li className="mb-2">
                        Create groups and cohorts for group work
                      </li>
                      <li className="mb-2">Course Analytics</li>
                      <li className="mb-2">
                        Seamless Zoom integration without loss of analytics
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-7">
                    <div style={{ width: "140%" }}>
                      <img
                        style={{}}
                        src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-course.png"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="gradient-box bg-white  ">
                <h3 className="mb-3  text-s-7 text-blue">
                  Customisable Registration Page
                </h3>
                <p className="text-s-6">
                  <span style={{ color: "#333333" }}>
                    Add your sponsors, speakers, course outline, objectives and
                    more.
                  </span>
                  {/* <span style={{ color: "#555555" }} >When you need to stay focused on a topic, use Structured Channels to require each conversation to be its own thread up front.</span> */}
                </p>

                {/* <img className="mb-4" src={`${process.env.PUBLIC_URL}/assets/igesia-event-registration.png`} width="100%" /> */}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3  text-s-7 text-purple">
                      Welcome your students in a virtual reception.
                    </h3>
                    <p className="text-s-6">
                      <span style={{ color: "#333333" }}>
                        Share information with dynamic course schedule.{" "}
                      </span>
                      <span style={{ color: "#888888" }}>
                        Engage with always-on course chat.
                      </span>
                    </p>
                  </div>

                  {/* <img className="mb-4" src={`${process.env.PUBLIC_URL}/assets/igesia-event-reception.png`} width="100%" /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 ">
              <div className="gradient-box bg-white">
                <h3 className="mb-3 text-s-7 text-blue">
                  Sell courses with different tiers and access.
                </h3>
                <p className="text-s-6 mb-5">
                  <span style={{ color: "#333333" }}>
                    Give your students multiple options to choose from.{" "}
                  </span>
                  <span style={{ color: "#555555" }}>
                    Choose between fully managed payments or add your own stripe
                    account.
                  </span>
                </p>

                <div style={{ width: "100%" }}>
                  {/* <img style={{}} src={`${process.env.PUBLIC_URL}/assets/assignment.png`} width="100%" /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 ">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 text-s-7 text-purple">
                      Engage with custom emails.
                    </h3>
                    <p className="text-s-6 mb-5">
                      <span style={{ color: "#333333" }}>
                        Send custom emails to your students.{" "}
                      </span>
                      <span style={{ color: "#888888" }}>
                        Send reminders, announcements to student groups.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div style={{ width: "100%" }}>
                      {/* <img style={{}} src={`${process.env.PUBLIC_URL}/assets/quiz-image.png`} width="100%" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 ">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 text-s-7 text-purple">
                      Create multiple classrooms for parallel sessions.
                    </h3>
                    <p className="text-s-6 mb-5">
                      <span style={{ color: "#333333" }}>
                        Hosting a large course with multiple sessions
                        simulataneously?{" "}
                      </span>
                      <span style={{ color: "#888888" }}>
                        Create separate classrooms with custom branding and
                        networking.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div style={{ width: "100%" }}>
                      {/* <img style={{}} src={`${process.env.PUBLIC_URL}/assets/poll-results.png`} width="100%" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 ">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 text-s-7 text-purple">
                      Native integration with Zoom.
                    </h3>
                    <p className="text-s-6 mb-5">
                      <span style={{ color: "#333333" }}>
                        You can directly host your course on Zoom or igesia
                        Video.{" "}
                      </span>
                      <span style={{ color: "#888888" }}>
                        Optionally, stream your lectures to YouTube Live or any
                        RTMP stream.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div style={{ width: "100%" }}>
                      {/* <img style={{}} src={`${process.env.PUBLIC_URL}/assets/poll-results.png`} width="100%" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For Attendees */}

      {/* <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "#17191A",
          overflow: "hidden"
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-9">
              <h2 className="mb-3 text-s-1 text-purple">Give a rewarding experience to your sponsors.</h2>
              <p className="text-s-2" style={{ color: "#FFFFFF" }}>
                We understand that creating value for sponsors is a major challenge. That's why we created a platform that creates more value than offline events.</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mb-4">
              <div className="gradient-box bg-gradient-purple mb-3" >
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-3 text-s-5 text-deep-purple">
                      Self serve booths for your sponsors.</h3>
                    <p className="text-s-6 text-white">Sponsor dashboard to customise sponsor booths and network.</p>
                    <ul className="text-s-6 text-white">
                      <li className="mb-2">Showcase corporate video and products/services</li>
                      <li className="mb-2">Networking Lounge with meeting scheduler</li>
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <div style={{ width: "140%" }}>
                      <img style={{}} src={`${process.env.PUBLIC_URL}/assets/igesia-event-booth.png`} width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6  mb-4">
              <div className="gradient-box bg-gradient-grey-dark" >
                <h3 className="mb-3 text-s-7 text-purple">
                  Get real time insights with booth analytics.</h3>
                <p className="text-s-6 mb-5">
                  <span style={{ color: "#FFFFFF" }} >Get real-time visitors contact details. </span>
                  <span style={{ color: "#BBBBBB" }} >Get a summary of booth activity.</span>
                </p>

                

              </div>
            </div>
            <div className="col-md-6  mb-4">
              <div className="gradient-box bg-gradient-light">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3  text-s-7 text-purple">
                      Video meetings with booth visitors.</h3>
                    <p className="text-s-6 mb-5">
                      <span style={{ color: "#333333" }} >Use igesia Connect to network with attendees. </span>
                      <span style={{ color: "#888888" }} >Schedule meetings from the booth.</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div> */}

      <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "#F7F7F7",
          overflow: "hidden",
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-8">
              <h2 className="mb-3 text-s-1 text-danger">
                Features to make your course more effective.
              </h2>
              {/* <p className="text-s-4 text-black-333">Explore all features</p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-blue">
                  <FontAwesomeIcon icon={faChartPie} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Analytics</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-purple">
                  <FontAwesomeIcon icon={faTicketAlt} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Tiered Ticketing</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-black-333">
                  <FontAwesomeIcon icon={faUsersCog} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Networking</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-green">
                  <FontAwesomeIcon icon={faStore} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Booths</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-danger">
                  <FontAwesomeIcon icon={faClone} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Duplicate Events</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-black-333">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Scheduling</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-purple">
                  <FontAwesomeIcon icon={faPoll} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Poll</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-primary">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Quiz</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-green">
                  <FontAwesomeIcon icon={faFile} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">File Sharing</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-blue">
                  <FontAwesomeIcon icon={faBook} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Invoicing</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-warning">
                  <FontAwesomeIcon icon={faStickyNote} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Notes</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-deep-green">
                  <FontAwesomeIcon icon={faKey} />
                </span>
                <h5 className="text-s-8 mt-3 mb-0">Encrypted Invite</h5>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <p className="text-s-4 text-black-888">
                Leading organisations worldwide have switched to igesia to
                supercharge their events.{" "}
                <span className="text-black-333">Join them.</span>
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/special-olympics.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/hci-maputo.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/goa-university.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/cbse.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/gbsn.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/ida.jpg"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
