import React, { useState, useEffect } from "react";
import firebase from "firebase";

import { Form, Dropdown, Button, Spinner } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "flatpickr/dist/themes/material_green.css";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
//import { Font } from "@react-pdf/renderer";
import { languageText } from "../language.json";
import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import * as linkify from "linkifyjs";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";


import { connect } from "react-redux";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactExport from "react-export-excel";

toast.configure();

const ManageBooth = ({ currentLanguage, currentEvent, darkTheme }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [attendeesSnapshot, attendeeLoading, attendeeError] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent.id}/users`)
      .orderBy("first_name")
  );

  let filteredDataArray = attendeesSnapshot?.docs.map((doc) => ({
    ...doc.data(),
    display_name: doc.data().first_name + " " + doc.data().last_name,
    id: doc.id,
  }));

  const history = useHistory();
  const { boothslug, id } = useParams();
  const userDetails = firebase.auth().currentUser;
  const [boothDetails, setBoothDetails] = useState({});
  const [firebaseID, setFirebaseID] = useState(null);
  const [snapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent.id}/booths`)
      .where("email_address_of_owner", "array-contains", userDetails.email)
  );
  let emailsToRemove = [];
  snapshot?.docs?.forEach((doc) => {
    const arr = doc.data().email_address_of_owner.map((email) => email);
    emailsToRemove.push(...arr);
  });

  emailsToRemove = [...new Set(emailsToRemove)];

  for (var i = filteredDataArray?.length - 1; i >= 0; i--) {
    for (var j = 0; j < emailsToRemove?.length; j++) {
      if (
        filteredDataArray[i] &&
        emailsToRemove?.includes(filteredDataArray[i]?.email_address)
      ) {
        filteredDataArray?.splice(i, 1);
      }
    }
  }

  // console.log(filteredDataArray);

  const [slugBoothSnapshot, loadingSlugBooth, errorSlugBooth] = useDocument(
    firebase.firestore().doc(`/events/${currentEvent.id}/booths/${boothslug}`)
  );
  const [fileSizeError, setFileSizeError] = useState(false);

  const [saving, setSaving] = useState(false);
  const [youtubeLinkError, setYoutubeLinkError] = useState(false);

  // const [broadcastLinks, setBroadcastLinks] = useState([]);

  useEffect(() => {
    if (!error && snapshot && snapshot.docs.length === 1 && !boothslug) {
      setBoothDetails(snapshot.docs[0].data());
    } else if (boothslug && !loadingSlugBooth) {
      setBoothDetails(slugBoothSnapshot.data());
    }
  }, [snapshot, error, boothslug, slugBoothSnapshot, loadingSlugBooth]);

  const [selectedBooth, setSelectedBooth] = useState("");

  const showBoothSwitcher = snapshot?.docs.length > 1 ? true : false;

  const saveFileToStateHandler = (e) => {
    if (e.target.name === "brochure") {
      e.target.files[0].size > 10240000
        ? setFileSizeError(true)
        : setFileSizeError(false);
    }

    setBoothDetails((prevBoothData) => {
      return { ...prevBoothData, [e.target.name]: e.target.files[0] };
    });
  };

  const saveTextDataToStateHandler = (e, index = null) => {
    if (e.target.name === "broadcast_link") {
      setYoutubeLinkError(false);
      const embedCode = convertYoutubeLinkToEmbedHandler(e.target.value);
      setBoothDetails((prevBoothData) => {
        if (Array.isArray(prevBoothData["broadcast_link"]) && index) {
          let modifiedArrayLinks = [...prevBoothData["broadcast_link"]];
          modifiedArrayLinks[index] = embedCode;
          return {
            ...prevBoothData,
            [e.target.name]: [...modifiedArrayLinks],
          };
        } else {
          let modifiedArrayLinks = [prevBoothData["broadcast_link"]];
          if (Array.isArray(prevBoothData["broadcast_link"])) {
            modifiedArrayLinks = [...prevBoothData["broadcast_link"]];
          }
          modifiedArrayLinks[index] = embedCode;
          return { ...prevBoothData, [e.target.name]: [...modifiedArrayLinks] };
        }
      });
    } else {
      setBoothDetails((prevBoothData) => {
        return { ...prevBoothData, [e.target.name]: e.target.value };
      });
    }
  };

  useEffect(() => {
    const setFirebaseId = async () => {
      const boothsOfUser = await firebase
        .firestore()
        .collection(`/events/${currentEvent.id}/booths/`)
        .where("email_address_of_owner", "array-contains", userDetails.email)
        .get();
      if (boothsOfUser && boothsOfUser.docs.length && !boothslug) {
        setFirebaseID(boothsOfUser.docs[0].id);
      } else {
        setFirebaseID(boothslug);
      }
    };
    setFirebaseId();
  }, [boothslug]);

  const resetStateHandler = () => {
    setBoothDetails({});
  };

  const saveBoothDataToDBHandler = async (e, boothData, showToast = true) => {
    e.preventDefault();

    console.log("boothData.broadcast_link: ", boothData.broadcast_link);
    if (boothData.broadcast_link && boothData.broadcast_link.indexOf("") >= 0) {
      toast.error(
        "Error Publishing Booths",
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
      setYoutubeLinkError(true);
      return 0;
    }

    const companyLogoURL = boothData.company_logo
      ? await uploadFile(boothData.company_logo)
      : null;
    const featureImageURL = boothData.featured_image
      ? await uploadFile(boothData.featured_image)
      : null;
    const brochureURL = boothData.brochure
      ? await uploadFile(boothData.brochure)
      : null;

    const booth_data = {
      booth_name: boothData.booth_name ?? "",
      small_description: boothData.small_description ?? "",
      description: boothData.description ?? "",
      company_logo: companyLogoURL ?? "",
      featured_image: featureImageURL ?? "",
      brochure: brochureURL ?? "",
      meet_link: boothData.meet_link ?? "",
      broadcast_link: boothData.broadcast_link ?? "",
    };
    try {
      if (showToast) {
        setSaving(true);
      }
      await firebase
        .firestore()
        .collection(`/events/${currentEvent.id}/booths/`)
        .doc(firebaseID)
        .update({
          ...booth_data,
        });
      if (showToast) {
        toast.success(
          "Successfully Saved Booth",
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      }
    } catch (e) {
      console.error(e, "error in saving to db");
      toast.error(
        "Error Publishing Booth",
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
    } finally {
      setSaving(false);
    }
  };

  const publishBoothHandler = async (e, bool = true) => {
    try {
      await firebase
        .firestore()
        .collection(`/events/${currentEvent.id}/booths/`)
        .doc(firebaseID)
        .update({
          is_published: bool,
        });
      toast.success("Booth Published", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in publishing to db");
      toast.error(
        "Error Publishing Booth",
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
    } finally {
      setSaving(false);
    }
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();

      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", userDetails.uid);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        // onUploadProgress: (ProgressEvent) => {
        //   setState({
        //     percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        //   });
        // },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const featured_image_url = boothDetails.featured_image
    ? getWhetherURLOrLocalFile(boothDetails.featured_image)
    : null;
  const company_logo_url = boothDetails.company_logo
    ? getWhetherURLOrLocalFile(boothDetails.company_logo)
    : null;

  const convertYoutubeLinkToEmbedHandler = (input) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = input.match(regExp);
    if (match && match[2].length === 11) {
      return "https://www.youtube.com/embed/" + match[2];
    } else {
      return input;
    }
  };

  return (
    <>
      <div className="h-100 ">
        <LeftBar mobile />

        <div
          className="container-fluid  h-100"
          style={{ overflow: "hidden" }}
        >
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />

            <div
              className="col-md-7 h-100 p-5"
              style={{ overflow: "scroll", /*backgroundColor: "#EFEFEF"*/ }}
              data-theme={darkTheme ? "dark" : "light"}
            >
              <div className="row">
                <div className="col-md-7 mb-2">
                  <ExcelFile
                    element={
                      <Button variant={darkTheme ? "outline-light" : "outline-dark"} className="btn-sm">
                        {languageText[`${currentLanguage}`].exportVisitors}
                      </Button>
                    }
                    filename="Visitors"
                  >
                    <ExcelSheet data={filteredDataArray} name="Visitors">
                      <ExcelColumn
                        label="Name"
                        value={(participant) =>
                          participant.first_name + " " + participant.last_name
                        }
                      />
                      <ExcelColumn
                        label="Email"
                        value={(participant) => participant.email_address}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
                <div className="col-md-12 my-3">
                  <div className="shadow-sm p-3 rounded bg-white text-dark d-flex justify-content-between">
                    <h3>
                      {languageText[`${currentLanguage}`].designYourBooth}
                    </h3>
                    {showBoothSwitcher && (
                      <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          {!selectedBooth.trim().length
                            ? languageText[`${currentLanguage}`]
                                .selectBoothToManage
                            : selectedBooth}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {snapshot &&
                            snapshot.docs.map((booth, index) => {
                              if (booth.data().booth_name) {
                                return (
                                  <Dropdown.Item
                                    eventKey={index}
                                    onClick={() => {
                                      setBoothDetails(
                                        snapshot.docs[index].data()
                                      );
                                      setSelectedBooth(booth.data().booth_name);
                                    }}
                                  >
                                    {booth.data().booth_name}
                                  </Dropdown.Item>
                                );
                              } else {
                                return (
                                  <Dropdown.Item
                                    eventKey={index}
                                    onClick={() => {
                                      setBoothDetails({});
                                      setSelectedBooth("New booth");
                                    }}
                                  >
                                    {
                                      languageText[`${currentLanguage}`]
                                        .newBooth
                                    }
                                  </Dropdown.Item>
                                );
                              }
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mb-3 mt-2">
                  <h5>
                    {languageText[`${currentLanguage}`].boothPage} (
                    {languageText[`${currentLanguage}`].preview})
                  </h5>

                  <div className="card event-card p-3 border-0 h-100">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="d-flex mb-3 align-items-center">
                          {boothDetails.company_logo && (
                            <div
                              style={{
                                background: `url(${company_logo_url})  center center / cover no-repeat`,
                                height: "40px",
                                width: "40px",
                              }}
                              className="rounded-circle mr-3"
                            />
                          )}
                          {boothDetails.booth_name && (
                            <h3 className="text-s-6 text-dark">
                              {boothDetails.booth_name
                                ? boothDetails.booth_name
                                : "Booth name"}
                            </h3>
                          )}
                        </div>
                        {boothDetails.meet_link && (
                          <a
                            href={boothDetails.meet_link}
                            target="_blank"
                            type="button"
                            className="btn btn-outline-primary btn-sm mr-2"
                          >
                            {languageText[`${currentLanguage}`].meetNow}
                          </a>
                        )}
                        {boothDetails.brochure && (
                          <a
                            href={boothDetails.brochure}
                            type="button"
                            className="btn btn-outline-primary btn-sm mr-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {
                              languageText[`${currentLanguage}`]
                                .downloadBrochure
                            }
                          </a>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        {boothDetails.featured_image ? (
                          <img
                            src={featured_image_url}
                            className="img-responsive w-100 rounded-12"
                            alt="featured_image"
                          />
                        ) : null}

                        {boothDetails.description && (
                          <p className="rounded-12 bg-white text-dark px-3 mb-0">
                            {parse(boothDetails.description)}
                          </p>
                        )}
                        {boothDetails.broadcast_link &&
                        Array.isArray(boothDetails.broadcast_link) ? (
                          <>
                            {boothDetails.broadcast_link.map(
                              (link, index) =>
                                link.length > 0 && (
                                  <div className="mb-3 embed-responsive embed-responsive-16by9">
                                    <iframe
                                      title="booth-video"
                                      className="embed-responsive-item"
                                      width="100%"
                                      src={link}
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                    ></iframe>
                                  </div>
                                )
                            )}
                          </>
                        ) : boothDetails.broadcast_link ? (
                          <div className="mb-3 embed-responsive embed-responsive-16by9">
                            <iframe
                              title="booth-video"
                              className="embed-responsive-item"
                              width="100%"
                              src={boothDetails.broadcast_link}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-5">
                  <h5>
                    {languageText[`${currentLanguage}`].boothCard} (
                    {languageText[`${currentLanguage}`].preview})
                  </h5>
                  <div className="card event-card border-0 h-100">
                    {boothDetails.featured_image && (
                      <div
                        style={{
                          background: `url(${featured_image_url}) center center / cover no-repeat`,
                          minHeight: "200px",
                        }}
                        className="card-img-top"
                      />
                    )}
                    <div className="card-body h-100  pb-0 bg-white">
                      <div className="d-flex mb-3 align-content-center">
                        {boothDetails.company_logo && (
                          <div
                            style={{
                              background: `url(${company_logo_url}) 0% 0% / contain no-repeat`,
                              height: "40px",
                              width: "40px",
                            }}
                            className="rounded-circle mr-3"
                          />
                        )}
                        {boothDetails.booth_name && (
                          <h3 className="text-s-6 text-dark">
                            {boothDetails.booth_name
                              ? boothDetails.booth_name
                              : "Booth name"}
                          </h3>
                        )}
                      </div>
                      <p className=" mb-0 text-dark">
                        {boothDetails.small_description
                          ? boothDetails.small_description
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div 
                className="col-md-4 h-100 p-3" 
                style={{ overflow: "scroll" }}
                data-theme={darkTheme ? "dark" : "light"}
            >
              <div className="shadow-sm p-2 bg-light text-secondary mb-3">
                <h5 className="mb-0">
                  {languageText[`${currentLanguage}`].enterBoothDetails}
                </h5>
              </div>
              <Form onSubmit={(e) => saveBoothDataToDBHandler(e, boothDetails)}>
                <Form.Group className>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].boothName}
                    <p className="small text-secondary mb-0">
                      {languageText[`${currentLanguage}`].companyName}
                    </p>
                  </Form.Label>
                  <Form.Control
                    maxLength="200"
                    type="text"
                    className="mb-3"
                    placeholder={languageText[`${currentLanguage}`].name}
                    value={boothDetails.booth_name}
                    name="booth_name"
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].smallDescription}
                    <p className="small text-secondary mb-0">
                      {
                        languageText[`${currentLanguage}`]
                          .smallDescriptionHelper
                      }
                    </p>
                  </Form.Label>
                  <Form.Control
                    maxLength="3000"
                    type="text"
                    className="mb-3"
                    placeholder={
                      languageText[`${currentLanguage}`].smallDescription
                    }
                    value={boothDetails.small_description}
                    name="small_description"
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].description}
                    <p className="small text-secondary mb-0">
                      {languageText[`${currentLanguage}`].descriptionHelper}
                    </p>
                  </Form.Label>
                  {/* <Form.Control 
                                    maxLength= "6000"
                                    type="text" 
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setState({ description: e.target.value})}
                               />*/}
                  <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        "heading",
                        "bold",
                        "italic",
                        "link",
                        "numberedList",
                        "bulletedList",
                        "undo",
                        "redo",
                      ],
                    }}
                    className="mb-3"
                    data={boothDetails.description ?? ""}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setBoothDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          description: data,
                        };
                      });
                    }}
                    onBlur={(event, editor) => {
                      // console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                      // console.log( 'Focus.', editor );
                    }}
                    style={{ height: 400 }}
                  />
                </Form.Group>
                {/* {console.log(data)} */}

                <div className="row">
                  <Form.Group className="col-md-12">
                    <Form.Label>
                      {languageText[`${currentLanguage}`].companyLogo}
                      <p className="small text-secondary mb-0">
                        {languageText[`${currentLanguage}`].companyLogoHelper}
                      </p>
                    </Form.Label>
                    {boothDetails.company_logo && (
                      <div className="p-2 bg-light rounded mb-2">
                        <p className="small mb-0 font-weight-bold text-secondary">
                          {
                            languageText[`${currentLanguage}`]
                              .currentCompanyLogo
                          }
                        </p>

                        <img
                          alt={languageText[`${currentLanguage}`].companyLogo}
                          src={company_logo_url}
                          height="75"
                          className="border border-light shadow-sm"
                        />
                      </div>
                    )}
                    <Form.Control
                      type="file"
                      className="mb-3"
                      accept="image/*"
                      placeholder={
                        languageText[`${currentLanguage}`].companyLogo
                      }
                      name="company_logo"
                      //value={company_logo}
                      onChange={(e) => saveFileToStateHandler(e)}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Form.Label>
                      {languageText[`${currentLanguage}`].featuredImage}
                      <p className="small text-secondary mb-0">
                        {languageText[`${currentLanguage}`].featuredImageHelper}
                      </p>
                    </Form.Label>
                    {boothDetails.featured_image && (
                      <div className="p-2 bg-light rounded mb-2">
                        <p className="small mb-0 font-weight-bold text-secondary">
                          {
                            languageText[`${currentLanguage}`]
                              .currentFeaturedImage
                          }
                        </p>
                        <img
                          alt={languageText[`${currentLanguage}`].featuredImage}
                          src={featured_image_url}
                          className="border border-light rounded shadow-sm img-responsive w-100"
                        />
                      </div>
                    )}

                    <Form.Control
                      type="file"
                      className="mb-3"
                      accept="image/*"
                      placeholder={
                        languageText[`${currentLanguage}`].featuredImage
                      }
                      name="featured_image"
                      //value={featured_image}
                      onChange={(e) => saveFileToStateHandler(e)}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-12">
                    <Form.Label>
                      {languageText[`${currentLanguage}`].brochureUpload}
                      <p className="small text-secondary mb-0">
                        {
                          languageText[`${currentLanguage}`]
                            .brochureUploadHelper
                        }
                      </p>
                    </Form.Label>
                    {boothDetails.brochure && (
                      <div className="p-2 bg-light rounded mb-2">
                        <p className="small mb-0 font-weight-bold text-secondary">
                          {languageText[`${currentLanguage}`].currentBrochure}
                        </p>

                        <a
                          href={boothDetails.brochure}
                          type="button"
                          className="btn btn-outline-primary btn-sm mr-2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {languageText[`${currentLanguage}`].brochure}
                        </a>
                      </div>
                    )}

                    <Form.Control
                      type="file"
                      className="mb-3"
                      placeholder={
                        languageText[`${currentLanguage}`].brochureUpload
                      }
                      name="brochure"
                      //value={brochure}
                      onChange={(e) => saveFileToStateHandler(e)}
                    />
                    {fileSizeError && (
                      <p className="text-danger small mb-0">
                        {languageText[`${currentLanguage}`].brochureError}
                      </p>
                    )}
                  </Form.Group>
                </div>

                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].videoMeetingLink}
                    <p className="small text-secondary mb-0">
                      {
                        languageText[`${currentLanguage}`]
                          .videoMeetingLinkHelper
                      }
                    </p>
                  </Form.Label>

                  <Form.Control
                    maxLength="3000"
                    type="url"
                    className="mb-3"
                    placeholder="Meet now link"
                    value={boothDetails.meet_link}
                    name="meet_link"
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].youtubeEmbedLink}
                    <p className="small text-secondary mb-0">
                      {
                        languageText[`${currentLanguage}`]
                          .youtubeEmbedLinkHelper
                      }
                    </p>
                  </Form.Label>

                  {Array.isArray(boothDetails.broadcast_link) ? (
                    <>
                      <div className="d-flex justify-content-end mt-2">
                        <Button
                          className="btn-sm"
                          onClick={() => {
                            setBoothDetails((prevBoothData) => {
                              return {
                                ...prevBoothData,
                                broadcast_link: [
                                  ...prevBoothData.broadcast_link,
                                  "",
                                ],
                              };
                            });
                          }}
                        >
                          {languageText[`${currentLanguage}`].addBroadcastLink}
                        </Button>
                      </div>
                      {boothDetails.broadcast_link.map((link, index) => (
                        <div className="d-flex align-items-center">
                          <Form.Control
                            maxLength="6000"
                            type="url"
                            placeholder={
                              languageText[`${currentLanguage}`]
                                .youtubeEmbedLink
                            }
                            value={link}
                            name="broadcast_link"
                            className="mt-2"
                            onChange={(e) =>
                              saveTextDataToStateHandler(e, index)
                            }
                          />
                          {index ? (
                            <Button
                              variant="transparent"
                              className="btn-sm"
                              disabled={index === 0}
                              onClick={() => {
                                setBoothDetails((prevBoothData) => {
                                  if (prevBoothData.broadcast_link.length > 1) {
                                    const splicedArray = [
                                      ...prevBoothData.broadcast_link,
                                    ];
                                    splicedArray.splice(index, 1);
                                    return {
                                      ...prevBoothData,
                                      broadcast_link: [...splicedArray],
                                    };
                                  }
                                });
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="text-danger"
                              />
                            </Button>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <Form.Control
                        maxLength="6000"
                        type="url"
                        className="mb-3"
                        placeholder={
                          languageText[`${currentLanguage}`].youtubeEmbedLink
                        }
                        value={boothDetails.broadcast_link}
                        name="broadcast_link"
                        onChange={(e) => saveTextDataToStateHandler(e, 0)}
                      />
                      <div className="d-flex justify-content-around">
                        <Button
                          className="btn-sm"
                          onClick={() => {
                            setBoothDetails((prevBoothData) => {
                              return {
                                ...prevBoothData,
                                broadcast_link: [
                                  prevBoothData.broadcast_link,
                                  "",
                                ],
                              };
                            });
                          }}
                        >
                          {languageText[`${currentLanguage}`].addBroadcastLink}
                        </Button>
                      </div>
                    </>
                  )}
                  {youtubeLinkError && (
                    <small className="text-danger">
                      Error: Please enter valid Youtube link
                    </small>
                  )}
                </Form.Group>
                {/* <div className="d-flex">
                  <Button
                    variant="outline"
                    className="btn btn-outline-primary flex-grow-1"
                    onClick={() =>
                      history.push("/event/blueoceanawards/booth/")
                    }
                  >
                    Preview
                  </Button>
                </div> */}

                <div className="d-flex mt-3">
                  <Button
                    type="submit"
                    className=" flex-grow-1"
                    variant="secondary"
                    disabled={saving || fileSizeError}
                    title={
                      fileSizeError
                        ? "Error: Please select a document whose size is less than or equal to 10 MB"
                        : "Press to save changes"
                    }
                  >
                    {languageText[`${currentLanguage}`].save}{" "}
                    {saving && (
                      <Spinner size="sm" animation="border" color="white" />
                    )}
                  </Button>
                </div>

                <div className="d-flex mt-3 mb-2">
                  <Button
                    className="btn-outline-primary flex-grow-1"
                    variant="outline"
                    disabled={saving || fileSizeError}
                    title={"Preview your booth"}
                    onClick={(e) => {
                      saveBoothDataToDBHandler(e, boothDetails, false).then(
                        () => {
                          window.open(
                            `/event/${id}/previewbooth/${firebaseID}`,
                            "_blank"
                          );
                          // history.push(
                          //   `/event/blueoceanawards/previewbooth/${firebaseID}`
                          // );
                        }
                      );
                    }}
                  >
                    {languageText[`${currentLanguage}`].saveAndPreview}{" "}
                    {/* {saving && (
                      <Spinner size="sm" animation="border" color="white" />
                    )} */}
                  </Button>
                </div>

                <div className="d-flex mt-3  mb-3">
                  <Button
                    className=" flex-grow-1"
                    variant={
                      boothDetails?.is_published ? "outline-success" : "success"
                    }
                    disabled={saving || fileSizeError}
                    title={"Publish"}
                    onClick={(e) => {
                      saveBoothDataToDBHandler(e, boothDetails, false).then(
                        () => {
                          if (
                            boothDetails.booth_name.trim().length &&
                            boothDetails.company_logo
                          ) {
                            publishBoothHandler(e, true);
                          } else {
                            toast.error(
                              "Booth Manadtory",
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              }
                            );
                          }
                        }
                      );
                    }}
                  >
                    {boothDetails?.is_published && (
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    )}
                    {boothDetails?.is_published
                      ? "Published"
                      : languageText[`${currentLanguage}`].publish}{" "}
                    {/* {saving && (
                      <Spinner size="sm" animation="border" color="white" />
                    )} */}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* MOBILE VIEW */}

        <div className="container d-block d-md-none montserrat-font">
          <NavBar />
          <div className="row">
            <div
              className="col-md-12 pt-3"
              style={{ overflow: "scroll", backgroundColor: "#EFEFEF" }}
            >
              <div className="">
                <div className="mb-2">
                  <ExcelFile
                    element={
                      <Button variant="outline-dark" className="btn-sm">
                        {languageText[`${currentLanguage}`].exportVisitors}
                      </Button>
                    }
                    filename="Visitors"
                  >
                    <ExcelSheet data={filteredDataArray} name="Visitors">
                      <ExcelColumn
                        label="Name"
                        value={(participant) =>
                          participant.first_name + " " + participant.last_name
                        }
                      />
                      <ExcelColumn
                        label="Email"
                        value={(participant) => participant.email_address}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
                <div className="shadow-sm p-3 rounded bg-white mb-3">
                  <h4>{languageText[`${currentLanguage}`].designYourBooth}</h4>
                  <Dropdown sel>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      {!selectedBooth.trim().length
                        ? languageText[`${currentLanguage}`].selectBoothToManage
                        : selectedBooth}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {snapshot &&
                        snapshot.docs.map((booth, index) => {
                          if (booth.data().booth_name) {
                            return (
                              <Dropdown.Item
                                eventKey={index}
                                onClick={() => {
                                  setBoothDetails(snapshot.docs[index].data());
                                  setSelectedBooth(booth.data().booth_name);
                                }}
                              >
                                {booth.data().booth_name}
                              </Dropdown.Item>
                            );
                          } else {
                            return (
                              <Dropdown.Item
                                eventKey={index}
                                onClick={() => {
                                  setBoothDetails({});
                                  setSelectedBooth("New booth");
                                }}
                              >
                                {languageText[`${currentLanguage}`].newBooth}
                              </Dropdown.Item>
                            );
                          }
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="mb-3">
                  <h5>
                    {languageText[`${currentLanguage}`].boothCard} (
                    {languageText[`${currentLanguage}`].preview})
                  </h5>
                  <div className="card event-card border-0 h-100">
                    {boothDetails.featured_image && (
                      <div
                        style={{
                          background: `url(${featured_image_url}) center center / cover no-repeat`,
                          minHeight: "200px",
                        }}
                        className="card-img-top"
                      />
                    )}
                    <div className="card-body h-100  pb-0 bg-white">
                      <div className="d-flex mb-3 align-content-center">
                        {boothDetails.company_logo && (
                          <div
                            style={{
                              background: `url(${company_logo_url}) 0% 0% / contain no-repeat`,
                              height: "40px",
                              width: "40px",
                            }}
                            className="rounded-circle mr-3"
                          />
                        )}
                        {boothDetails.booth_name && (
                          <h3 className="text-s-6 text-dark">
                            {boothDetails.booth_name
                              ? boothDetails.booth_name
                              : "Booth name"}
                          </h3>
                        )}
                      </div>
                      <p className=" mb-0 text-dark">
                        {boothDetails.small_description
                          ? boothDetails.small_description
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h5>
                    {languageText[`${currentLanguage}`].boothPage} (
                    {languageText[`${currentLanguage}`].preview})
                  </h5>

                  <div className="card event-card p-3 border-0 h-100">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="d-flex mb-3 align-items-center">
                          {boothDetails.company_logo && (
                            <div
                              style={{
                                background: `url(${company_logo_url})  center center / cover no-repeat`,
                                height: "40px",
                                width: "40px",
                              }}
                              className="rounded-circle mr-3"
                            />
                          )}
                          {boothDetails.booth_name && (
                            <h3 className="text-s-6 text-dark">
                              {boothDetails.booth_name
                                ? boothDetails.booth_name
                                : "Booth name"}
                            </h3>
                          )}
                        </div>
                        {boothDetails.meet_link && (
                          <a
                            href={boothDetails.meet_link}
                            target="_blank"
                            type="button"
                            className="btn btn-outline-primary btn-sm mr-2"
                          >
                            {languageText[`${currentLanguage}`].meetNow}
                          </a>
                        )}
                        {boothDetails.brochure && (
                          <a
                            href={boothDetails.brochure}
                            type="button"
                            className="btn btn-outline-primary btn-sm mr-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {
                              languageText[`${currentLanguage}`]
                                .downloadBrochure
                            }
                          </a>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        {boothDetails.featured_image ? (
                          <img
                            src={featured_image_url}
                            className="img-responsive w-100 rounded-12"
                            alt="featured_image"
                          />
                        ) : null}
                        {boothDetails.description && (
                          <p className="rounded-12 bg-white px-3 mb-0">
                            {parse(boothDetails.description)}
                          </p>
                        )}
                        {boothDetails.broadcast_link &&
                        Array.isArray(boothDetails.broadcast_link) ? (
                          <>
                            {boothDetails.broadcast_link.map(
                              (link, index) =>
                                link.length > 0 && (
                                  <div className="mb-3 embed-responsive embed-responsive-16by9">
                                    <iframe
                                      title="booth-video"
                                      className="embed-responsive-item"
                                      width="100%"
                                      src={link}
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                    ></iframe>
                                  </div>
                                )
                            )}
                          </>
                        ) : boothDetails.broadcast_link ? (
                          <div className="mb-3 embed-responsive embed-responsive-16by9">
                            <iframe
                              title="booth-video"
                              className="embed-responsive-item"
                              width="100%"
                              src={boothDetails.broadcast_link}
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shadow-sm p-2 bg-light border-bottom">
                <h5 className="mb-0">
                  {languageText[`${currentLanguage}`].enterBoothDetails}
                </h5>
              </div>
              <Form
                className="bg-white p-2"
                style={{ marginBottom: "10vh" }}
                onSubmit={(e) => saveBoothDataToDBHandler(e, boothDetails)}
              >
                <Form.Group className>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].boothName}
                    <p className="small text-secondary mb-0">
                      {languageText[`${currentLanguage}`].companyName}
                    </p>
                  </Form.Label>
                  <Form.Control
                    maxLength="200"
                    type="text"
                    className="mb-3"
                    placeholder={languageText[`${currentLanguage}`].name}
                    value={boothDetails.booth_name}
                    name="booth_name"
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].smallDescription}
                    <p className="small text-secondary mb-0">
                      {
                        languageText[`${currentLanguage}`]
                          .smallDescriptionHelper
                      }
                    </p>
                  </Form.Label>
                  <Form.Control
                    maxLength="3000"
                    type="text"
                    className="mb-3"
                    placeholder={
                      languageText[`${currentLanguage}`].smallDescription
                    }
                    value={boothDetails.small_description}
                    name="small_description"
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].description}
                    <p className="small text-secondary mb-0">
                      {languageText[`${currentLanguage}`].descriptionHelper}
                    </p>
                  </Form.Label>
                  {/* <Form.Control 
                                    maxLength= "6000"
                                    type="text" 
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setState({ description: e.target.value})}
                               />*/}
                  <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        "heading",
                        "bold",
                        "italic",
                        "link",
                        "numberedList",
                        "bulletedList",
                        "undo",
                        "redo",
                      ],
                    }}
                    className="mb-3"
                    data={boothDetails.description ?? ""}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setBoothDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          description: data,
                        };
                      });
                    }}
                    onBlur={(event, editor) => {
                      // console.log( 'Blur.', editor );
                    }}
                    onFocus={(event, editor) => {
                      // console.log( 'Focus.', editor );
                    }}
                    style={{ height: 400 }}
                  />
                </Form.Group>

                <div className="row">
                  <Form.Group className="col-md-12">
                    <Form.Label>
                      {languageText[`${currentLanguage}`].companyLogo}
                      <p className="small text-secondary mb-0">
                        {languageText[`${currentLanguage}`].companyLogoHelper}
                      </p>
                    </Form.Label>
                    {boothDetails.company_logo && (
                      <div className="p-2 bg-light rounded mb-2">
                        <p className="small mb-0 font-weight-bold text-secondary">
                          {
                            languageText[`${currentLanguage}`]
                              .currentCompanyLogo
                          }
                        </p>

                        <img
                          alt={languageText[`${currentLanguage}`].companyLogo}
                          src={company_logo_url}
                          height="75"
                          className="border border-light shadow-sm"
                        />
                      </div>
                    )}
                    <Form.Control
                      type="file"
                      className="mb-3"
                      accept="image/*"
                      placeholder={
                        languageText[`${currentLanguage}`].companyLogo
                      }
                      name="company_logo"
                      //value={company_logo}
                      onChange={(e) => saveFileToStateHandler(e)}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                    <Form.Label>
                      {languageText[`${currentLanguage}`].featuredImage}
                      <p className="small text-secondary mb-0">
                        {languageText[`${currentLanguage}`].featuredImageHelper}
                      </p>
                    </Form.Label>
                    {boothDetails.featured_image && (
                      <div className="p-2 bg-light rounded mb-2">
                        <p className="small mb-0 font-weight-bold text-secondary">
                          {
                            languageText[`${currentLanguage}`]
                              .currentFeaturedImage
                          }
                        </p>
                        <img
                          alt={languageText[`${currentLanguage}`].featuredImage}
                          src={featured_image_url}
                          className="border border-light rounded shadow-sm img-responsive w-100"
                        />
                      </div>
                    )}

                    <Form.Control
                      type="file"
                      className="mb-3"
                      accept="image/*"
                      placeholder={
                        languageText[`${currentLanguage}`].featuredImage
                      }
                      name="featured_image"
                      //value={featured_image}
                      onChange={(e) => saveFileToStateHandler(e)}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group className="col-md-12">
                    <Form.Label>
                      {languageText[`${currentLanguage}`].brochureUpload}
                      <p className="small text-secondary mb-0">
                        {
                          languageText[`${currentLanguage}`]
                            .brochureUploadHelper
                        }
                      </p>
                    </Form.Label>
                    {boothDetails.brochure && (
                      <div className="p-2 bg-light rounded mb-2">
                        <p className="small mb-0 font-weight-bold text-secondary">
                          {languageText[`${currentLanguage}`].currentBrochure}
                        </p>

                        <a
                          href={boothDetails.brochure}
                          type="button"
                          className="btn btn-outline-primary btn-sm mr-2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {languageText[`${currentLanguage}`].brochure}
                        </a>
                      </div>
                    )}

                    <Form.Control
                      type="file"
                      className="mb-3"
                      placeholder={
                        languageText[`${currentLanguage}`].brochureUpload
                      }
                      name="brochure"
                      //value={brochure}
                      onChange={(e) => saveFileToStateHandler(e)}
                    />
                    {fileSizeError && (
                      <p className="text-danger small mb-0">
                        {languageText[`${currentLanguage}`].brochureError}
                      </p>
                    )}
                  </Form.Group>
                </div>

                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].videoMeetingLink}
                    <p className="small text-secondary mb-0">
                      {
                        languageText[`${currentLanguage}`]
                          .videoMeetingLinkHelper
                      }
                    </p>
                  </Form.Label>

                  <Form.Control
                    maxLength="3000"
                    type="url"
                    className="mb-3"
                    placeholder="Meet now link"
                    value={boothDetails.meet_link}
                    name="meet_link"
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {languageText[`${currentLanguage}`].youtubeEmbedLink}
                    <p className="small text-secondary mb-0">
                      {
                        languageText[`${currentLanguage}`]
                          .youtubeEmbedLinkHelper
                      }
                    </p>
                  </Form.Label>

                  {Array.isArray(boothDetails.broadcast_link) ? (
                    <>
                      <div className="d-flex justify-content-end mt-2">
                        <Button
                          className="btn-sm"
                          onClick={() => {
                            setBoothDetails((prevBoothData) => {
                              return {
                                ...prevBoothData,
                                broadcast_link: [
                                  ...prevBoothData.broadcast_link,
                                  "",
                                ],
                              };
                            });
                          }}
                        >
                          {languageText[`${currentLanguage}`].addBroadcastLink}
                        </Button>
                      </div>
                      {boothDetails.broadcast_link.map((link, index) => (
                        <div className="d-flex align-items-center">
                          <Form.Control
                            maxLength="6000"
                            type="url"
                            placeholder={
                              languageText[`${currentLanguage}`]
                                .youtubeEmbedLink
                            }
                            value={link}
                            name="broadcast_link"
                            className="mt-2"
                            onChange={(e) =>
                              saveTextDataToStateHandler(e, index)
                            }
                          />
                          {index ? (
                            <Button
                              variant="transparent"
                              className="btn-sm"
                              disabled={index === 0}
                              onClick={() => {
                                setBoothDetails((prevBoothData) => {
                                  if (prevBoothData.broadcast_link.length > 1) {
                                    const splicedArray = [
                                      ...prevBoothData.broadcast_link,
                                    ];
                                    splicedArray.splice(index, 1);
                                    return {
                                      ...prevBoothData,
                                      broadcast_link: [...splicedArray],
                                    };
                                  }
                                });
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="mr-2 text-danger"
                              />
                            </Button>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <Form.Control
                        maxLength="6000"
                        type="url"
                        className="mb-3"
                        placeholder={
                          languageText[`${currentLanguage}`].youtubeEmbedLink
                        }
                        value={boothDetails.broadcast_link}
                        name="broadcast_link"
                        onChange={(e) => saveTextDataToStateHandler(e, 0)}
                      />
                      <div className="d-flex justify-content-around">
                        <Button
                          className="btn-sm"
                          onClick={() => {
                            setBoothDetails((prevBoothData) => {
                              return {
                                ...prevBoothData,
                                broadcast_link: [
                                  prevBoothData.broadcast_link,
                                  "",
                                ],
                              };
                            });
                          }}
                        >
                          {languageText[`${currentLanguage}`].addBroadcastLink}
                        </Button>
                      </div>
                    </>
                  )}
                  {youtubeLinkError && (
                    <small className="text-danger">
                      Error: Please enter valid Youtube link
                    </small>
                  )}
                </Form.Group>

                <div className="d-flex mt-3">
                  <Button
                    type="submit"
                    className=" flex-grow-1"
                    variant="secondary"
                    disabled={saving || fileSizeError}
                    title={
                      fileSizeError
                        ? "Error: Please select a document whose size is less than or equal to 10 MB"
                        : "Press to save changes"
                    }
                  >
                    {languageText[`${currentLanguage}`].save}{" "}
                    {saving && (
                      <Spinner size="sm" animation="border" color="white" />
                    )}
                  </Button>
                </div>

                <div className="d-flex mt-3 mb-2">
                  <Button
                    className="btn-outline-primary flex-grow-1"
                    variant="outline"
                    disabled={saving || fileSizeError}
                    title={"Preview your booth"}
                    onClick={(e) => {
                      saveBoothDataToDBHandler(e, boothDetails, false).then(
                        () => {
                          window.open(
                            `/event/${id}/previewbooth/${firebaseID}`,
                            "_blank"
                          );

                          // history.push(
                          //   `/event/blueoceanawards/previewbooth/${firebaseID}`
                          // );
                        }
                      );
                    }}
                  >
                    {languageText[`${currentLanguage}`].saveAndPreview}{" "}
                    {/* {saving && (
                      <Spinner size="sm" animation="border" color="white" />
                    )} */}
                  </Button>
                </div>

                <div className="d-flex mt-3 mb-3">
                  <Button
                    className=" flex-grow-1"
                    variant={
                      boothDetails?.is_published ? "outline-success" : "success"
                    }
                    disabled={saving || fileSizeError}
                    title={"Publish"}
                    onClick={(e) => {
                      saveBoothDataToDBHandler(e, boothDetails, false).then(
                        () => {
                          if (
                            boothDetails.booth_name.trim().length &&
                            boothDetails.company_logo
                          ) {
                            publishBoothHandler(e, true);
                          } else {
                            toast.error(
                              "Booth Manadtory",
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              }
                            );
                          }
                        }
                      );
                    }}
                  >
                    {boothDetails?.is_published && (
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    )}
                    {boothDetails?.is_published
                      ? "Published"
                      : languageText[`${currentLanguage}`].publish}{" "}
                    {/* {saving && (
                      <Spinner size="sm" animation="border" color="white" />
                    )} */}
                  </Button>
                </div>
                {/* <div className="d-flex w-100 export-excel">
                  <ExcelFile
                    element={
                      <Button
                        variant="outline-dark"
                        className="btn-sm flex-grow-1 w-100"
                      >
                        Download visitors' list
                      </Button>
                    }
                    filename="Visitors"
                  >
                    <ExcelSheet data={filteredDataArray} name="Visitors">
                      <ExcelColumn
                        label="Name"
                        value={(participant) =>
                          participant.first_name + " " + participant.last_name
                        }
                      />
                      <ExcelColumn
                        label="Email"
                        value={(participant) => participant.email_address}
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({ 
    currentLanguage: state.currentLanguage, 
    currentEvent: state.currentEvent, 
    darkTheme: state.darkTheme
}))
(ManageBooth);