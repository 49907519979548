import React, { useState } from "react";
import { Card, Image, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  faTimes,
  faEye,
  faGraduationCap,
  faUser,
  faPodcast,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import TimelineCover from "../Timeline/TimelineCover";

export default function Blogs() {
  const history = useHistory();

  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const peoples = [
    {
      name: "Dr. Reena Kumar",
      email: "reena@gmail.com",
      country: "India",
      img: "https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/drive%2F1gHTSDwzWRPW7KAUeTcscDFxs7z2%2F1623640335613-1623640263211-pic.jpg?alt=media&token=e9307b57-55a8-46a8-a4ec-11604621bb90",
    },
  ];

  return (
    <div className="container-fluid px-0 bg-graphite full-screen">
      {/* Timeline cover starts */}
      <TimelineCover />
      {/* Timeline cover ends */}

      <div className="container text-white py-3 py-md-4">
        <h2 className="font-weight-bold">Consultants</h2>
        <p className="text-white font-weight-bold mb-0">
          {peoples.length > 0
            ? peoples.length > 1
              ? `${peoples.length} consultants`
              : `${peoples.length} consultant`
            : `No member found`}
        </p>

        <InputGroup className="my-2 outerPill">
          <div
            className="d-flex align-items-center rounded-lg flex-grow-1  "
            style={{ backgroundColor: "#3a3b3c" }}
          >
            <div className="py-2 pr-2 pl-3">
              <FontAwesomeIcon icon={faSearch} size="sm" color="#aeaeae" />
            </div>
            <div className="flex-grow-1">
              <Form.Control
                type="text"
                id="participantsSearch"
                className="border-0 px-0 text-white rounded"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  const reg = _.escapeRegExp(searchTerm);
                  // console.log(reg,"Reg")
                  const regex = new RegExp(reg, "gi");
                  setRegex(regex);
                }}
                placeholder={"Search"}
                value={searchTerm}
                style={{ margin: 0, backgroundColor: "#3a3b3c" }}
              />
            </div>
            {searchTerm.length > 0 && (
              <div className="py-2 pl-2 pr-3" onClick={() => setSearchTerm("")}>
                <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
              </div>
            )}
          </div>
        </InputGroup>

        {searchTerm && searchTerm.length > 0 ? (
          <>
            {peoples
              .filter((item) => {
                return item.name?.match(regex);
              })
              .map((item) => {
                return (
                  <Card
                    onClick={() => {
                      history.push(`/bookappointment`);
                      // setSelectedProfile(item);
                      // setShowProfile(true);
                    }}
                    className=" text-white shadow-sm mb-2"
                    style={{ backgroundColor: "#3a3b3c" }}
                  >
                    <Card.Body>
                      <div className="d-flex">
                        <div
                          className="img-fluid rounded-circle shadow-sm border"
                          style={{
                            height: 50,
                            width: 50,
                            backgroundImage: `url(${item.img})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          title={item.name}
                        />
                        <div className="ml-2">
                          <p className="mb-0">{item.name}</p>
                          <span className="small">{item.email}</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
          </>
        ) : (
          <>
            {peoples.map((item) => {
              return (
                <Card
                  onClick={() => {
                    history.push(`bookappointment`);
                    // setSelectedProfile(item);
                    // setShowProfile(true);
                  }}
                  className=" text-white shadow-sm mb-2"
                  style={{ backgroundColor: "#3a3b3c" }}
                >
                  <Card.Body>
                    <div className="d-flex">
                      <div
                        className="img-fluid rounded-circle shadow-sm border"
                        style={{
                          height: 50,
                          width: 50,
                          backgroundImage: `url(${item.img})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        title={item.name}
                      />
                      <div className="ml-2">
                        <p className="mb-0">{item.name}</p>
                        <span className="small">{item.email}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </>
        )}

        <Modal
          centered={true}
          show={showProfile}
          onHide={() => setShowProfile(false)}
          animation={true}
        >
          <Modal.Body className="bg-graphite text-white">
            <div
              title="Close"
              className="pointer d-flex justify-content-end mb-0"
              onClick={() => setShowProfile(false)}
            >
              <FontAwesomeIcon className="text-white" icon={faTimes} />
            </div>
            <div className="d-flex flex-column align-items-center text-center">
              <div
                className="img-fluid rounded-circle shadow-sm"
                style={{
                  height: 120,
                  width: 120,
                  backgroundImage: `url(${selectedProfile?.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                title={selectedProfile?.name}
              />
              <p className="font-weight-bold lead">{selectedProfile?.name}</p>
              <div className="w-100 d-flex align-items-center justify-content-around">
                <p className="border-right border-dark w-100 text-lowercase">{`@${
                  selectedProfile?.name?.split(" ")[0]
                }`}</p>
                <p className="w-100">{`${selectedProfile?.email}`}</p>
              </div>
              <p>{selectedProfile?.country}</p>
              <div className="row w-100">
                <div className="col-sm">
                  <FontAwesomeIcon icon={faGraduationCap} />
                  <p>{selectedProfile?.certification}</p>
                </div>
                <div className="col-sm">
                  <FontAwesomeIcon icon={faUser} />
                  <p>{selectedProfile?.followers}</p>
                </div>
                <div className="col-sm">
                  <FontAwesomeIcon icon={faPodcast} />
                  <p>{selectedProfile?.podcasts}</p>
                </div>
              </div>
              <hr />
              <div>
                <p className="lead font-weight-bold">Specialities</p>
                <p>{selectedProfile?.specialties.replaceAll(",", " •")}</p>
              </div>
              <hr />
              <div>
                <p className="lead font-weight-bold">Qualifications</p>
                <p>{selectedProfile?.qualifications.replaceAll(",", " •")}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
