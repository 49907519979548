import React from "react";
import { Card, Image, Button } from "react-bootstrap";
import { useHistory } from "react-router";

import { faEllipsisH, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as TimelineStyle from "../Timeline/timeline.module.css";
import EventImg from "../Timeline/assets/event.png";
import CourseImg from "../Timeline/assets/course.png";
import ConsultationImg from "../Timeline/assets/consultation.png";

import { projectList } from "../data";
import TimelineCover from "../Timeline/TimelineCover";

export default function Projects() {
  const history = useHistory();

  return (
    <div className="container-fluid px-0 bg-graphite full-screen">
      {/* Timeline cover starts */}
      <TimelineCover />
      {/* Timeline cover ends */}

      <div className="container py-3 py-md-4">
        <h2 className="text-white font-weight-bold">Projects</h2>

        <div className="row mt-3">

          {/* Course card */}
          {projectList.map((project, key) => (
            <div className="col-md-4 mb-2" key={key}>
              <Card
                className={`h-100 ${TimelineStyle.services_cards}`}
                onClick={() => history.push(`project/${project.slug}`)}
                style={{ cursor: "pointer" }}
                title={`Course: ${project.name}`}
              >
                <Card.Img variant="top" src={project.image} />
                <Card.Body className="py-2 px-3">
                  <p className="small text-uppercase text-success mb-1">
                  Project
                  </p>
                  <p className="font-weight-bold text-white mb-1">
                    {project.name}
                  </p>
                  <p className="font-weight-bold small mb-0 text-warning">
                    {project.date}
                  </p>
                </Card.Body>
              </Card>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}
