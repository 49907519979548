import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Form,
  Card,
  FormCheck,
  Modal,
  Dropdown,
  Spinner,
  Row,
  Accordion,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faThLarge,
  faRedo,
  faEye,
  faEyeSlash,
  faEllipsisH,
  faTrash,
  faCopy,
  faClock,
  faEdit,
  faStopwatch,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { useDocument } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import axios from "axios";
import moment from "moment";
import ReactExport from "react-export-excel";
import { useLocation } from "react-router-dom";

import { auth, firestore } from "../../../../firebase";

import IgesiaForm from "./IgesiaForm";
import UserProfile from "../UserProfile";
import { API_BASE_URL } from "../../../../config";

toast.configure();

function Forms(props) {
  // CONSTANTS
  const { eventId } = props;
  const { search } = useLocation();
  const user = auth.currentUser;
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  // STATES
  const [addFormStartDate, setAddFormStartDate] = useState(false);
  const [addFormEndDate, setAddFormEndDate] = useState(false);
  const [addFormModal, setAddFormModal] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [startsAt, setStartsAt] = useState(Date.now());
  const [endsAt, setEndsAt] = useState(Date.now() + 24 * 60 * 60 * 1000);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [fetchingForms, setFetchingForms] = useState(false);
  const [formList, setFormList] = useState([]);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [filter, setFilter] = useState("");
  const [duplicatingForm, setDuplicatingForm] = useState(false);
  const [show, setShow] = useState(false);
  const [publishFormModal, setPublishFormModal] = useState(false);
  const [unPublishFormModal, setUnPublishFormModal] = useState(false);
  const [startNowModal, setStartNowModal] = useState(false);
  const [closeNowModal, setCloseNowModal] = useState(false);
  const [formLive, setFormLive] = useState(false);
  const [formUpdating, setFormUpdating] = useState(false);
  const [allFormFields, setAllFormFields] = useState([]);
  const [updateField, setUpdateField] = useState(false);
  const [updateFieldKey, setUpdateFieldKey] = useState(null);
  const [showModalAnotherField, setShowModalAnotherField] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [formFields, setFormFields] = useState({});
  const [selectValues, setSelectValues] = useState([]);
  const [editing, setEditing] = useState(false);

  // FUNCTIONS
  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firestore.collection("events").doc(eventId)
  );

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const handleOpenItem = (details) => {
    setShowItemDetails(true);
    setSelectedItem(details);
  };

  const handleCloseItem = () => {
    setShowItemDetails(false);
    setSelectedItem(null);
  };

  const handleFetchFormList = async (cbFetching, cbList) => {
    try {
      cbFetching(true);
      const response = await axios.get(
        `${API_BASE_URL}/form?event_id=${eventId}&user_id=${user.uid}`
      );

      if (response.status === 200) {
        cbFetching(false);
        cbList(response?.data?.data?.list);
      } else {
        cbFetching(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching list: `, error.message);
      cbFetching(false);
    }
  };

  const handleFetchFormResponses = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/form/${selectedItem?.id}?event_id=${eventId}&user_id=${user.uid}`
      );

      if (response.status === 200) {
        setSelectedItem((prev) => ({
          ...prev,
          responses: response.data.data.form_responses,
          ...response.data.data.form_data,
        }));
      }
    } catch (error) {
      console.log(`[Error] Fetching responses: `, error.message);
    }
  };

  const handleFormAdd = async (event) => {
    event.preventDefault();
    if (formTitle === "" || formTitle === null) {
      alert("Please enter form title");
      return;
    }

    try {
      setSubmittingForm(true);
      const response = await axios.post(`${API_BASE_URL}/form`, {
        form_title: formTitle,
        starts_at: startsAt ?? false,
        ends_at: endsAt ?? false,
        event_id: eventId,
        user_id: user?.uid,
      });

      if (response.status === 200) {
        setFormTitle("");
        setStartsAt(Date.now());
        setEndsAt(Date.now() + 24 * 60 * 60 * 1000);
        setSubmittingForm(false);
        setAddFormModal(false);
        handleRefresh();

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setSubmittingForm(false);

      console.error("Error creating form: ", err);
      toast.error(`Error creating form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (formTitle === "" || formTitle === null) {
      alert("Please enter form title");
      return;
    }
    if (allFormFields?.length === 0) {
      alert("Please add at least one field to form");
      return;
    }

    try {
      setSubmittingForm(true);
      const response = await axios.put(
        `${API_BASE_URL}/form/${selectedItem?.id}`,
        {
          event_id: props.eventId,
          user_id: user?.uid,
          form_title: formTitle,
          starts_at: startsAt ?? false,
          ends_at: endsAt ?? false,
          form_fields: allFormFields,
        }
      );

      if (response.status === 200) {
        handleEditForm();
        handleCloseItem();
        handleRefresh();
        setSubmittingForm(false);

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setSubmittingForm(false);

      console.error("Error creating form: ", err);
      toast.error(`Error creating form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleEditForm = () => {
    if (selectedItem?.published_at) {
      toast.error(`Cannot Edit already Published Form`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 5000,
      });
    } else {
      if (editing) {
        setEditing(false);
        setFormUpdating(false);
      } else {
        setEditing(true);
        setFormUpdating(true);
      }
    }
  };

  const startNowSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/start/${selectedItem?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      setStartNowModal(false);
      handleFetchFormResponses();
      handleRefresh();
    } catch (err) {
      console.error("Error starting form: ", err);
      toast.error(`Error starting form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const closeNowSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/close/${selectedItem?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      setCloseNowModal(false);
      handleFetchFormResponses();
      handleRefresh();
    } catch (err) {
      console.error("Error closing form: ", err);
      toast.error(`Error closing form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const publishSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/publish/${selectedItem?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      setPublishFormModal(false);
      handleFetchFormResponses();
      handleRefresh();
    } catch (err) {
      console.error("Error publishing form: ", err);
      toast.error(`Error publishing form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const unPublishSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/unpublish/${selectedItem?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      setUnPublishFormModal(false);
      handleFetchFormResponses();
      handleRefresh();
    } catch (err) {
      console.error("Error un-publishing form: ", err);
      toast.error(`Error un-publishing form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteSelectedForm = async () => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/form/${selectedItem?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      handleCloseItem();
      handleRefresh();
      handleClose();
    } catch (err) {
      console.error("Error deleting form: ", err);
      toast.error(`Error deleting form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleDuplicateForm = async () => {
    try {
      setSubmittingForm(true);
      const response = await axios.post(
        `${API_BASE_URL}/form/duplicate/${selectedItem?.id}`,
        {
          event_id: eventId,
          user_id: user?.uid,
        }
      );

      if (response.status === 200) {
        setDuplicatingForm(false);
        handleRefresh();

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setDuplicatingForm(false);

      console.error("Error duplicating form: ", err);
      toast.error(`Error duplicating form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleRefresh = () => {
    // Fetch: Form list
    handleFetchFormList(setFetchingForms, setFormList);
  };

  useEffect(() => {
    if (selectedItem) {
      setFormTitle(selectedItem?.form_title);
      setAllFormFields(
        selectedItem?.form_fields ? [...selectedItem?.form_fields] : []
      );
      setFormLive(selectedItem?.form_required);
      if (selectedItem?.starts_at) {
        setAddFormStartDate(true);
        setStartsAt(selectedItem?.starts_at);
      }
      if (selectedItem?.ends_at) {
        setAddFormEndDate(true);
        setEndsAt(selectedItem?.ends_at);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    const open = new URLSearchParams(search).get("open");

    if (open === "create") {
      setAddFormModal(true);
    }
  }, [search]);

  useEffect(() => {
    handleFetchFormResponses();
  }, [selectedItem?.id]);

  useEffect(() => {
    // Fetch: Form list
    handleFetchFormList(setFetchingForms, setFormList);
  }, []);

  // COMPONENTS
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-light"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </a>
  ));

  return (
    <React.Fragment>
      <div className="p-3" style={{ height: "100vh", overflowY: "auto" }}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <h4 className="text-white">Forms</h4>
          </div>
        </div>
        <div className="row">
          <div className={showItemDetails ? "col-md-6" : "col-md-12"}>
            <div className="text-white border border-secondary">
              <div className="d-flex align-items-center justify-content-between px-3 py-2">
                <div className="d-flex align-items-center">
                  {/* Dropdown: Filter list */}
                  <Dropdown className="mr-3">
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <span className="text-capitalize">
                        {filter === "" ? (
                          <span>
                            <FontAwesomeIcon
                              icon={faThLarge}
                              className="mr-2"
                            />{" "}
                            View all
                          </span>
                        ) : filter === "published" ? (
                          <span>
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            Published
                          </span>
                        ) : (
                          <span>
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              className="mr-2"
                            />
                            Not published
                          </span>
                        )}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setFilter("")}
                        style={
                          filter === ""
                            ? {
                                backgroundColor: "rgba(200, 200, 200, 0.5)",
                              }
                            : {}
                        }
                      >
                        <FontAwesomeIcon icon={faThLarge} className="mr-2" />{" "}
                        View all
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilter("published")}
                        style={
                          filter === "published"
                            ? {
                                backgroundColor: "rgba(200, 200, 200, 0.5)",
                              }
                            : {}
                        }
                      >
                        <FontAwesomeIcon icon={faEye} className="mr-2" />
                        Published
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilter("not-published")}
                        style={
                          filter === "not-published"
                            ? {
                                backgroundColor: "rgba(200, 200, 200, 0.5)",
                              }
                            : {}
                        }
                      >
                        <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                        Not published
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* Button: Activate Forms */}
                  <div className="d-flex flex-column">
                    <span className="small font-weight-bold text-uppercase">
                      Activate Forms
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id="inviteOnly"
                        label={
                          eventSnapshot?.data()?.feedback_form ? "On" : "Off"
                        }
                        //disabled={true}
                        checked={
                          eventSnapshot?.data()?.feedback_form ? true : false
                        }
                        onChange={(e) =>
                          firestore.collection("events").doc(eventId).update({
                            feedback_form: e.target.checked,
                          })
                        }
                      ></FormCheck>
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  {fetchingForms && (
                    <div className="mr-3">
                      <p className="mb-0">Fetching...</p>
                    </div>
                  )}

                  {/* Button: Refresh */}
                  <Button
                    variant="light"
                    size="sm"
                    onClick={handleRefresh}
                    disabled={fetchingForms}
                    className="mr-3"
                  >
                    {fetchingForms ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faRedo} />
                    )}{" "}
                    Refresh
                  </Button>

                  {/* Button: Add New */}
                  <Button
                    size="sm"
                    className="text-uppercase"
                    onClick={() => setAddFormModal(true)}
                  >
                    Add Form
                  </Button>
                </div>
              </div>
              <table class="table table-borderless text-white">
                <thead className="border-top border-secondary">
                  <tr className="">
                    <th className="py-1 font-weight-bold small">#</th>
                    <th className="py-1 font-weight-bold small">Name</th>
                    {!showItemDetails && (
                      <th className="py-1 font-weight-bold small">
                        No. of questions
                      </th>
                    )}
                    {!showItemDetails && (
                      <th className="py-1 font-weight-bold small">Start at</th>
                    )}
                    {!showItemDetails && (
                      <th className="py-1 font-weight-bold small">End by</th>
                    )}
                    <th className="py-1 font-weight-bold small">Published?</th>
                    <th className="py-1 font-weight-bold small">Created</th>
                  </tr>
                </thead>
                <tbody>
                  {formList
                    ?.filter((e) => {
                      if (filter === "") return true;
                      else if (filter === "published") return e.published;
                      else if (filter === "not-published") return !e?.published;
                    })
                    ?.map((item, key) => (
                      <tr
                        className="border-top border-secondary align-items-center pointer"
                        key={key}
                        onClick={() => handleOpenItem(item)}
                        style={
                          selectedItem?.id === item.id
                            ? {
                                fontSize: "90%",
                                backgroundColor: "rgba(200, 200, 200, 0.15)",
                              }
                            : { fontSize: "90%" }
                        }
                      >
                        <td>{key + 1}</td>
                        <td>{item?.form_title}</td>
                        {!showItemDetails && (
                          <td>{item?.form_fields?.length ?? 0}</td>
                        )}
                        {!showItemDetails && (
                          <td>
                            {item?.starts_at
                              ? moment(item?.starts_at).format("LLL")
                              : "NA"}
                          </td>
                        )}
                        {!showItemDetails && (
                          <td>
                            {item?.ends_at
                              ? moment(item?.ends_at).format("LLL")
                              : "NA"}
                          </td>
                        )}
                        <td className="text-uppercase">
                          {item?.published ? "Published" : "Not published"}
                        </td>
                        <td>
                          {moment(
                            convertSecondstoMilliSeconds(
                              item?.created_at?._seconds,
                              item?.created_at?._nanoseconds
                            )
                          ).format("MMM DD, YYYY")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {/* Empty list placeholder */}
              {formList?.filter((e) => {
                if (filter === "") return true;
                else if (filter === "published") return e.published;
                else if (filter === "not-published") return !e?.published;
              })?.length === 0 && (
                <p className="text-center">No items present</p>
              )}
            </div>
          </div>
          {showItemDetails && (
            <div className="col-md-6 p-0 m-0 h-100">
              <div className="border border-secondary text-white mr-3 mb-3">
                <div className="p-3 d-flex align-items-center border-bottom border-secondary">
                  <h5 className="flex-grow-1 mb-0">
                    {selectedItem?.form_title}
                  </h5>
                  <Dropdown className="px-1 py-0 pointer">
                    <Dropdown.Toggle
                      size="sm"
                      variant="white"
                      as={CustomToggle}
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setPublishFormModal(true)}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark border-bottom"
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          size="sm"
                          className="mr-2"
                        />
                        Publish
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setUnPublishFormModal(true)}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark border-bottom"
                      >
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          size="sm"
                          className="mr-2"
                        />
                        Un-publish
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setStartNowModal(true)}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark border-bottom"
                      >
                        <FontAwesomeIcon
                          icon={faClock}
                          size="sm"
                          className="mr-2"
                        />
                        Start Form
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setCloseNowModal(true)}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark border-bottom"
                      >
                        <FontAwesomeIcon
                          icon={faStopwatch}
                          size="sm"
                          className="mr-2"
                        />
                        Close Form
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleEditForm}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark border-bottom"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          size="sm"
                          className="mr-2"
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleShow}
                        className="text-uppercase text-dark border-bottom"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="sm"
                          className="mr-2"
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-uppercase text-dark"
                        onClick={() => handleDuplicateForm(selectedItem)}
                        disabled={duplicatingForm}
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          className="mr-2"
                          size="sm"
                        />
                        {duplicatingForm && (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="mr-2"
                          />
                        )}
                        {duplicatingForm ? "Duplicating" : "Duplicate"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <a
                    className="text-white pointer p-2"
                    onClick={handleCloseItem}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </a>
                </div>
                <div className="p-3">
                  <Row className="mb-3">
                    <div className="col-6">
                      <p className="text-muted small mb-0">Published?</p>
                      <p className="small mb-0 text-uppercase">
                        {selectedItem?.published
                          ? "Published"
                          : "Not published"}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted small mb-0">Mandatory?</p>
                      <p className="small mb-0">
                        {selectedItem?.form_required ? "Mandatory" : "Optional"}
                      </p>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-6">
                      <p className="text-muted small mb-0">Start at</p>
                      <p className="small mb-0">
                        {selectedItem?.starts_at
                          ? moment(selectedItem?.starts_at).format("LLL")
                          : "NA"}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted small mb-0">End by</p>
                      <p className="small mb-0">
                        {selectedItem?.starts_at
                          ? moment(selectedItem?.starts_at).format("LLL")
                          : "NA"}
                      </p>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-6">
                      <p className="text-muted small mb-0">Created at</p>
                      <p className="small mb-0">
                        {moment(
                          convertSecondstoMilliSeconds(
                            selectedItem?.created_at?._seconds,
                            selectedItem?.created_at?._nanoseconds
                          )
                        ).format("LLLL")}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="text-muted small mb-0">No. of questions</p>
                      <p className="small mb-0">
                        {selectedItem?.form_fields?.length ?? 0}
                      </p>
                    </div>
                  </Row>
                </div>
              </div>

              {editing && (
                <div className="border border-secondary text-white mr-3 mb-3">
                  <div className="p-3 d-flex align-items-center border-bottom border-secondary">
                    <h5 className="flex-grow-1 mb-0">Edit Form</h5>
                    <Button
                      variant="secondary"
                      onClick={handleEditForm}
                      size="sm"
                      className="rounder-0 text-uppercase"
                    >
                      <FontAwesomeIcon icon={faEdit} className="mr-2" />
                      <span>Cancel Editing</span>
                    </Button>
                  </div>
                  <div className="p-3">
                    <Form onSubmit={handleFormSubmit}>
                      {formLive ? (
                        formUpdating ? (
                          <Form.Group>
                            <Form.Label>Form Title</Form.Label>
                            <Form.Control
                              type="text"
                              name="form_title"
                              placeholder="Enter form title"
                              onChange={(e) => setFormTitle(e.target.value)}
                              value={formTitle}
                            />
                            <Form.Text className="text-muted">
                              Title will be displayed on top of this form.
                            </Form.Text>
                          </Form.Group>
                        ) : (
                          <div className="mb-3">
                            <p className="text-uppercase text-muted small mb-0">
                              <b>Form Title</b>
                            </p>
                            <p className="h5">{formTitle}</p>
                          </div>
                        )
                      ) : (
                        <Form.Group>
                          <Form.Label>Form Title</Form.Label>
                          <Form.Control
                            type="text"
                            name="form_title"
                            placeholder="Enter form title"
                            onChange={(e) => setFormTitle(e.target.value)}
                            value={formTitle}
                          />
                          <Form.Text className="text-muted">
                            Title will be displayed on top of this form.
                          </Form.Text>
                        </Form.Group>
                      )}
                      <div>
                        <div className="row">
                          <Form.Group
                            controlId="formBasicCheckbox"
                            className="col-md-6"
                          >
                            <Form.Check
                              name="start_date"
                              type="checkbox"
                              label="Add start date"
                              onClick={(e) => {
                                setAddFormStartDate(e.target.checked);
                              }}
                              checked={addFormStartDate}
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formBasicCheckbox"
                            className="col-md-6"
                          >
                            <Form.Check
                              name="end_date"
                              type="checkbox"
                              label="Add end date"
                              onClick={(e) => {
                                setAddFormEndDate(e.target.checked);
                              }}
                              checked={addFormEndDate}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div>
                        <div className="row">
                          {addFormStartDate && (
                            <Form.Group className="col-md-6">
                              <Form.Label>Starts at</Form.Label>
                              <br />
                              <Flatpickr
                                placeholder="Select start date"
                                data-enable-time
                                value={startsAt}
                                name="starts_at"
                                onChange={(selectedDate, dateStr, instance) =>
                                  setStartsAt(selectedDate[0].valueOf())
                                }
                                options={{ minuteIncrement: 5 }}
                                className="w-100"
                              />
                            </Form.Group>
                          )}
                          {addFormEndDate && (
                            <Form.Group className="col-md-6">
                              <Form.Label>Ends at</Form.Label>
                              <br />
                              <Flatpickr
                                placeholder="Select end date"
                                data-enable-time
                                value={endsAt}
                                name="ends_at"
                                onChange={(selectedDate, dateStr, instance) =>
                                  setEndsAt(selectedDate[0].valueOf())
                                }
                                options={{ minuteIncrement: 5 }}
                                className="w-100"
                              />
                            </Form.Group>
                          )}
                        </div>

                        {allFormFields.length > 0 ? (
                          <div>
                            {allFormFields.map((field, idx) => (
                              <div
                                className="border border-secondary rounded-0 px-3 py-2 mb-3"
                                key={idx}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">
                                    <p className="text-uppercase small mb-1">
                                      <b className="mr-2">
                                        {idx + 1}. {field.type}
                                      </b>
                                      <small>
                                        (
                                        {field.required
                                          ? "Mandatory"
                                          : "Not mandatory"}
                                        )
                                      </small>
                                    </p>
                                  </div>

                                  {formLive ? (
                                    formUpdating && (
                                      <div className="d-flex">
                                        <a
                                          className="mr-2 pointer"
                                          onClick={() => {
                                            setUpdateField(true);
                                            setUpdateFieldKey(idx);
                                            setShowModalAnotherField(true);
                                            setFormFields({ ...field });
                                            setFieldType(field.type);
                                            if (field.type === "select") {
                                              setSelectValues(field.values);
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            size="sm"
                                            icon={faEdit}
                                          />
                                        </a>
                                        <a
                                          className="text-danger pointer"
                                          onClick={() => {
                                            setAllFormFields((prev) => {
                                              const oldState = [...prev];
                                              const newState = oldState.filter(
                                                (item) => item.id != idx + 1
                                              );
                                              return newState;
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            size="sm"
                                            icon={faTrash}
                                          />
                                        </a>
                                      </div>
                                    )
                                  ) : (
                                    <div className="d-flex">
                                      <a
                                        className="mr-2 pointer"
                                        onClick={() => {
                                          setUpdateField(true);
                                          setUpdateFieldKey(idx);
                                          setShowModalAnotherField(true);
                                          setFormFields({ ...field });
                                          setFieldType(field.type);
                                          if (field.type === "select") {
                                            setSelectValues(field.values);
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          size="sm"
                                          icon={faEdit}
                                        />
                                      </a>
                                      <a
                                        className="text-danger pointer"
                                        onClick={() => {
                                          setAllFormFields((prev) => {
                                            const oldState = [...prev];
                                            const newState = oldState.filter(
                                              (item) => item.id != idx + 1
                                            );
                                            return newState;
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          size="sm"
                                          icon={faTrash}
                                        />
                                      </a>
                                    </div>
                                  )}
                                </div>

                                <p className="mb-0 small text-uppercase text-muted">
                                  <b>Field label</b>
                                </p>
                                <p
                                  className={
                                    field.type === "select" ? "mb-1" : "mb-0"
                                  }
                                >
                                  {field.label}
                                </p>

                                {field.type === "select" && (
                                  <React.Fragment>
                                    <p className="mb-0 small text-uppercase text-muted">
                                      <b>Values</b>
                                    </p>
                                    <p className="mb-0">
                                      {field.values.join(", ")}
                                    </p>
                                  </React.Fragment>
                                )}

                                {field.type === "rating" && (
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <p className="mb-0 small text-uppercase text-muted">
                                        <b>Start Label</b>
                                      </p>
                                      <p className="mb-0">
                                        {field.start_label}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-0 small text-uppercase text-muted">
                                        <b>End Label</b>
                                      </p>
                                      <p className="mb-0">{field.end_label}</p>
                                    </div>
                                    <div>
                                      <p className="mb-0 small text-uppercase text-muted">
                                        <b>Start Value</b>
                                      </p>
                                      <p className="mb-0">
                                        {field.start_value}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-0 small text-uppercase text-muted">
                                        <b>End Value</b>
                                      </p>
                                      <p className="mb-0">{field.end_value}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center">
                            <p className="">No field added yet</p>
                          </div>
                        )}

                        <div className="d-flex align-items-center mb-3">
                          <Button
                            size="sm"
                            className="text-uppercase"
                            onClick={() => {
                              setShowModalAnotherField(true);
                              setFieldType("text");
                            }}
                          >
                            Add Form Field
                          </Button>
                        </div>
                      </div>

                      <Button
                        size="sm"
                        className="text-uppercase"
                        type="submit"
                        disabled={submittingForm}
                      >
                        {submittingForm && (
                          <span className="mr-2">
                            <Spinner animation="border" size="sm" />
                          </span>
                        )}
                        Update Form
                      </Button>
                    </Form>
                  </div>
                </div>
              )}

              {/* Section: Questions */}
              {!editing && (
                <div className="border border-secondary text-white mr-3 mb-3">
                  <div className="px-3 py-2 border-bottom border-secondary">
                    <p className="text-muted mb-0">
                      <span className="mr-2 text-uppercase">Questions</span>
                      <span className="text-light">
                        (
                        {selectedItem?.form_fields?.length > 0
                          ? selectedItem?.form_fields?.length > 1
                            ? `${selectedItem?.form_fields?.length} questions`
                            : `${selectedItem?.form_fields?.length} question`
                          : "No questions yet"}
                        )
                      </span>
                    </p>
                  </div>
                  <div className="p-3">
                    {selectedItem?.form_fields?.map((field, idx) => (
                      <div
                        className="border border-secondary px-3 py-2 mb-2"
                        key={idx}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <p className="text-uppercase small mb-1">
                              <b className="mr-2">
                                {idx + 1}. {field.type}
                              </b>
                              <small>
                                (
                                {field.required ? "Mandatory" : "Not mandatory"}
                                )
                              </small>
                            </p>
                          </div>
                        </div>

                        <p className="mb-0 small text-uppercase text-muted">
                          <b>Field label</b>
                        </p>
                        <p
                          className={field.type === "select" ? "mb-1" : "mb-0"}
                        >
                          {field.label}
                        </p>

                        {field.type === "select" && (
                          <React.Fragment>
                            <p className="mb-0 small text-uppercase text-muted">
                              <b>Values</b>
                            </p>
                            <p className="mb-0">{field.values.join(", ")}</p>
                          </React.Fragment>
                        )}

                        {field.type === "rating" && (
                          <div className="d-flex justify-content-between">
                            <div>
                              <p className="mb-0 small text-uppercase text-muted">
                                <b>Start Label</b>
                              </p>
                              <p className="mb-0">{field.start_label}</p>
                            </div>
                            <div>
                              <p className="mb-0 small text-uppercase text-muted">
                                <b>End Label</b>
                              </p>
                              <p className="mb-0">{field.end_label}</p>
                            </div>
                            <div>
                              <p className="mb-0 small text-uppercase text-muted">
                                <b>Start Value</b>
                              </p>
                              <p className="mb-0">{field.start_value}</p>
                            </div>
                            <div>
                              <p className="mb-0 small text-uppercase text-muted">
                                <b>End Value</b>
                              </p>
                              <p className="mb-0">{field.end_value}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    {!selectedItem?.form_fields?.length && (
                      <Button
                        size="sm"
                        className="text-uppercase mr-2"
                        onClick={() => {
                          setShowModalAnotherField(true);
                          setEditing(true);
                        }}
                      >
                        Add Form Field
                      </Button>
                    )}

                    {formUpdating && (
                      <Button
                        size="sm"
                        className="text-uppercase"
                        onClick={() => setShowModalAnotherField(true)}
                      >
                        Add Form Field
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {/* Section: Responses */}
              {!editing && (
                <div className="border border-secondary text-white mr-3">
                  <div className="px-3 py-2 border-bottom border-secondary">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="text-muted mb-0">
                        <span className="mr-2 text-uppercase">Responses</span>
                        <span className="text-light">
                          (
                          {selectedItem?.responses?.length > 0
                            ? selectedItem?.responses?.length > 1
                              ? `${selectedItem?.responses?.length} responses`
                              : `${selectedItem?.responses?.length} response`
                            : "No responses yet"}
                          )
                        </span>
                      </p>
                      <ExcelFile
                        filename={selectedItem?.form_title}
                        element={
                          <button className="mr-2 btn btn-outline-light btn-sm text-uppercase">
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              className="mr-2"
                            />
                            Export
                          </button>
                        }
                      >
                        <ExcelSheet
                          data={selectedItem?.responses}
                          name="Responses"
                        >
                          <ExcelColumn
                            label="Email"
                            value={(res) =>
                              res.user ? res.user.email : res.uid
                            }
                          />
                          <ExcelColumn
                            label="Name"
                            value={(res) =>
                              res.user ? res.user.display_name : res.uid
                            }
                          />

                          {selectedItem?.form_fields?.map(
                            ({ field_name, label }, key) => (
                              <ExcelColumn
                                key={key}
                                label={label}
                                value={(res) => res?.form_responses[field_name]}
                              />
                            )
                          )}
                          <ExcelColumn
                            label={"Submission Time"}
                            value={(res) =>
                              moment(
                                convertSecondstoMilliSeconds(
                                  res?.created_at?._seconds,
                                  res?.created_at?._nanoseconds
                                )
                              ).format("LLL")
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                  <div className="p-3">
                    {selectedItem?.responses?.map((response, key) => (
                      <Accordion key={key} className="mb-2">
                        <Card
                          className="rounded-0 border-secondary"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className="m-0 py-2 px-3"
                          >
                            <UserProfile
                              userDetails={{
                                avatarUrl: response.user.avatar,
                                firstName: response.user.first_name,
                                lastName: response.user.last_name,
                              }}
                              imgSize={40}
                              subText={moment(
                                convertSecondstoMilliSeconds(
                                  response.created_at._seconds,
                                  response.created_at._nanoseconds
                                )
                              ).format("LLL")}
                            />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {selectedItem?.form_fields?.map((item, index) => (
                                <div key={index}>
                                  <p className="font-weight-bold mb-0">
                                    Que {index + 1}. {item?.label}
                                  </p>
                                  <p className="mb-0">
                                    Ans:{" "}
                                    {response.form_responses[
                                      item.field_name
                                    ] ? (
                                      response.form_responses[item.field_name]
                                    ) : (
                                      <i>Skipped</i>
                                    )}
                                  </p>
                                </div>
                              ))}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modal: Add Form */}
      <Modal show={addFormModal} onHide={() => setAddFormModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="mb-0">Create form</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormAdd}>
            <Form.Group>
              <Form.Label>Form Title</Form.Label>
              <Form.Control
                disabled={submittingForm}
                type="text"
                name="form_title"
                placeholder="Enter form title"
                onChange={(e) => setFormTitle(e.target.value)}
                value={formTitle}
              />
              <Form.Text className="text-muted">
                Title will be displayed on top of this form.
              </Form.Text>
            </Form.Group>
            <div>
              <div className="row">
                <Form.Group controlId="start_date" className="col-md-6">
                  <Form.Check
                    disabled={submittingForm}
                    name="start_date"
                    type="checkbox"
                    label="Add start date"
                    onClick={(e) => {
                      setAddFormStartDate(e.target.checked);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="end_date" className="col-md-6">
                  <Form.Check
                    disabled={submittingForm}
                    name="end_date"
                    type="checkbox"
                    label="Add end date"
                    onClick={(e) => {
                      setAddFormEndDate(e.target.checked);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div>
              <div className="row">
                {addFormStartDate && (
                  <Form.Group className="col-md-6">
                    <Form.Label>Starts at</Form.Label>
                    <br />
                    <Flatpickr
                      disabled={submittingForm}
                      placeholder="Select start date"
                      data-enable-time
                      value={startsAt}
                      name="starts_at"
                      onChange={(selectedDate, dateStr, instance) =>
                        setStartsAt(selectedDate[0].valueOf())
                      }
                      options={{ minuteIncrement: 5 }}
                      className="w-100"
                    />
                  </Form.Group>
                )}
                {addFormEndDate && (
                  <Form.Group className="col-md-6">
                    <Form.Label>Ends at</Form.Label>
                    <br />
                    <Flatpickr
                      disabled={submittingForm}
                      placeholder="Select end date"
                      data-enable-time
                      value={endsAt}
                      name="ends_at"
                      onChange={(selectedDate, dateStr, instance) =>
                        setEndsAt(selectedDate[0].valueOf())
                      }
                      options={{ minuteIncrement: 5 }}
                      className="w-100"
                    />
                  </Form.Group>
                )}
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-6">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={submittingForm}
                  >
                    {submittingForm ? (
                      <span>Submitting...</span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    variant="secondary"
                    onClick={() => setAddFormModal(false)}
                    className="w-100"
                    disabled={submittingForm}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal: Publish Form */}
      <Modal show={publishFormModal} onHide={() => setPublishFormModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Publish Form</Modal.Title>
        </Modal.Header>
        {selectedItem?.form_fields?.length > 0 ? (
          <Modal.Body>
            Are you sure you want to <b>Publish this form</b>? After this
            participants will be able to see this form.
          </Modal.Body>
        ) : (
          <Modal.Body>
            Please add atleast 1 form field in form to publish it. Click on{" "}
            <b>Add Form Field</b> to add form fields.
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setPublishFormModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            disabled={!selectedItem?.form_fields?.length}
            onClick={() => publishSelectedForm()}
          >
            Publish
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal: Un-publish Form */}
      <Modal
        show={unPublishFormModal}
        onHide={() => setUnPublishFormModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unpublish Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Unpublish this form</b>? Participants will
          no longer be able to see it in their dashboard.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setUnPublishFormModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={() => unPublishSelectedForm()}
          >
            Unpublish
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal: Start Form */}
      <Modal show={startNowModal} onHide={() => setStartNowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Start now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Start form now</b>? Participants will be
          able to respond to form.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setStartNowModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={() => startNowSelectedForm()}
          >
            Start now
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal: Close Form */}
      <Modal show={closeNowModal} onHide={() => setCloseNowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Close now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Close form now</b>? Participants will no
          longer be able to respond to the form.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setCloseNowModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={() => closeNowSelectedForm()}
          >
            Close form
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal: Add/ Edit Form field */}
      <Modal
        show={showModalAnotherField}
        onHide={() => {
          setFormFields({});
          setFieldType("");
          setSelectValues([]);

          setShowModalAnotherField(false);
        }}
      >
        <Modal.Header closeButton>
          <div>
            <h4 className="mb-0">
              {updateField ? formFields?.label : "Add Field"}
            </h4>
            {updateField && (
              <p className="text-muted text-uppercase mb-0">Update Field</p>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="text-uppercase small text-muted">
              <b>Field Type</b>
            </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setFieldType(e.target.value);
                if (e.target.value === "rating") {
                  setFormFields((prev) => {
                    return {
                      ...prev,
                      start_value: 1,
                      end_value: 5,
                      start_label: "Not good",
                      end_label: "Perfect",
                    };
                  });
                }
              }}
              value={fieldType}
            >
              <option value="">Choose field type</option>
              <option value="text">Text</option>
              <option value="select">Select</option>
              <option value="number">Number</option>
              <option value="rating">Rating</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label className="text-uppercase small text-muted">
              <b>Field Label</b>
            </Form.Label>
            <Form.Control
              type="text"
              name="label"
              placeholder="Enter field label"
              value={formFields?.label}
              onChange={(e) => {
                setFormFields((prev) => {
                  return updateField
                    ? {
                        ...prev,
                        [e.target.name]: e.target.value,
                      }
                    : {
                        ...prev,
                        [e.target.name]: e.target.value,
                        field_name: e.target.value
                          .toLowerCase()
                          .replace(/[^a-zA-Z0-9]/g, "_"),
                      };
                });
              }}
            />
            <Form.Text className="text-muted">
              Label will be displayed as name of this form field.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Control
              type="hidden"
              name="field_name"
              value={formFields?.field_name}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="text-uppercase small text-muted mb-0">
              <b>Mandatory Field</b>
            </Form.Label>
            <Form.Text className="text-muted mb-2">
              (If this field is <b>mandatory</b> to be filled by user or not)
            </Form.Text>
            <FormCheck
              type="switch"
              id="field-required"
              label={formFields?.required ? "Yes" : "No"}
              checked={formFields?.required}
              onChange={(e) =>
                setFormFields((prev) => {
                  return {
                    ...prev,
                    required: e.target.checked,
                  };
                })
              }
            ></FormCheck>
          </Form.Group>

          {fieldType === "select" && (
            <React.Fragment>
              <Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="text-uppercase small text-muted mb-0">
                    <b>Values for select</b>
                  </Form.Label>
                  <Button
                    size="sm"
                    onClick={() => setSelectValues((prev) => [...prev, ""])}
                    className="text-uppercase"
                  >
                    Add Value
                  </Button>
                </div>
                {selectValues.map((item, key) => {
                  return (
                    <div key={key}>
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <Form.Label className="small text-muted mb-0">
                          Value {key + 1}
                        </Form.Label>
                        <a
                          title="Remove"
                          onClick={() => {
                            setSelectValues((prev) => {
                              const oldState = [...prev];
                              const newState = [
                                ...oldState.slice(0, key),
                                ...oldState.slice(key + 1),
                              ];
                              return newState;
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size="sm" />
                        </a>
                      </div>
                      <Form.Control
                        type="text"
                        name={`values-${key}`}
                        value={selectValues[key]}
                        onChange={(e) => {
                          setSelectValues((prev) => {
                            const oldValues = [...prev];
                            oldValues[key] = e.target.value;
                            return oldValues;
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </Form.Group>
            </React.Fragment>
          )}

          {fieldType === "rating" && (
            <React.Fragment>
              <Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="text-uppercase small text-muted mb-0">
                    <b>Labels for Rating</b>
                  </Form.Label>
                </div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-6">
                      <Form.Group>
                        <Form.Label className="text-uppercase small text-muted">
                          Starting label
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="start_label"
                          placeholder="Enter starting label"
                          value={formFields?.start_label ?? "Not good"}
                          onChange={(e) => {
                            setFormFields((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      <Form.Group>
                        <Form.Label className="text-uppercase small text-muted">
                          Ending label
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="end_label"
                          placeholder="Enter ending label"
                          value={formFields?.end_label ?? "Perfect"}
                          onChange={(e) => {
                            setFormFields((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Form.Group>

              <Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="text-uppercase small text-muted mb-0">
                    <b>Range of values</b>
                  </Form.Label>
                </div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-6">
                      <Form.Group>
                        <Form.Label className="text-uppercase small text-muted">
                          Start value
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min={1}
                          max={
                            formFields?.end_value
                              ? formFields?.end_value - 1
                              : false
                          }
                          name="start_value"
                          placeholder="Enter start value"
                          value={formFields?.start_value ?? 1}
                          onChange={(e) => {
                            setFormFields((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: parseInt(e.target.value),
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      <Form.Group>
                        <Form.Label className="text-uppercase small text-muted">
                          End value
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min={2}
                          name="end_value"
                          placeholder="Enter end value"
                          value={formFields?.end_value ?? 5}
                          onChange={(e) => {
                            setFormFields((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: parseInt(e.target.value),
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          {updateField ? (
            <Button
              size="sm"
              variant="primary"
              className="text-uppercase mr-2"
              onClick={() => {
                setAllFormFields((prev) => {
                  const oldState = [...prev];

                  if (fieldType === "select") {
                    if (selectValues.length > 0) {
                      return [
                        ...oldState.slice(0, updateFieldKey),
                        {
                          ...formFields,
                          type: fieldType,
                          values: selectValues,
                        },
                        ...oldState.slice(updateFieldKey + 1),
                      ];
                    } else {
                      toast.error("Please add some values", {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      });
                    }
                  } else
                    return [
                      ...oldState.slice(0, updateFieldKey),
                      {
                        ...formFields,
                        type: fieldType,
                      },
                      ...oldState.slice(updateFieldKey + 1),
                    ];
                });
                setShowModalAnotherField(false);

                setFormFields({});
                setFieldType("");
                setSelectValues([]);
                setUpdateField(false);
                setUpdateFieldKey(null);
              }}
            >
              Save Updates
            </Button>
          ) : (
            <Button
              size="sm"
              variant="success"
              className="text-uppercase mr-2"
              onClick={() => {
                setAllFormFields((prev) => {
                  if (fieldType === "select") {
                    if (selectValues.length > 0) {
                      return [
                        ...prev,
                        {
                          ...formFields,
                          type: fieldType,
                          id: prev.length + 1,
                          values: selectValues,
                        },
                      ];
                    } else {
                      toast.error("Please add some values", {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      });
                    }
                  } else {
                    if (prev?.length > 0) {
                      return [
                        ...prev,
                        {
                          ...formFields,
                          type: fieldType,
                          id: prev.length + 1,
                        },
                      ];
                    } else {
                      return [
                        {
                          ...formFields,
                          type: fieldType,
                          id: 1,
                        },
                      ];
                    }
                  }
                });
                setShowModalAnotherField(false);

                setFormFields({});
                setFieldType("");
                setSelectValues([]);
              }}
            >
              Save Changes
            </Button>
          )}

          <Button
            size="sm"
            variant="none"
            className="text-uppercase"
            onClick={() => {
              setFormFields({});
              setFieldType("");
              setSelectValues([]);
              setUpdateField(false);
              setUpdateFieldKey(null);

              setShowModalAnotherField(false);
            }}
          >
            Discard changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal: Delete Form */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Delete this form</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="danger" onClick={() => deleteSelectedForm()}>
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
});

export default connect(mapStateToProps)(Forms);
