import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button, Modal, Form } from "react-bootstrap";
import {
  setBuyerRows,
  setBuyerHeader,
} from "../../../../store/actions/actions";


function TableCell(props) {
  const { rowData, headData } = props;
  let column = headData.id,
    row = rowData.id;

  const [cellValuesArray, setCellValuesArray] = useState([]);

  const [valuesCollection, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `/events/${props?.currentEvent?.id}/bbos/${props.graph.id}/buyer_exp_audit/0/columns/${column}/values/${row}/values`
      )
  );

  useEffect(() => {
    if (valuesCollection) {
      const valuesArray = valuesCollection?.docs?.map((item) => {
        // console.log(item.data())
        return { ...item.data(), id: item.id };
      });
      setCellValuesArray(valuesArray);
      // console.log(valuesArray,"value array")
    }
  }, [valuesCollection]);

  if (headData.id == 0) return <td>{rowData.name}</td>;
  else
    return (
      <>
        <td>
          {loading ? (
            "Loading..."
          ) : (
            <>
            {cellValuesArray.map((item) => {
                return <p>X</p>
                // return <p>{`X${item?.created_by.name}`}</p>;
              })}
             
              
            </>
          )}
        </td>

        
      </>
    );
}












function BuyerExpTemplate(props) {
    const [header, setHeader] = useState([]);
    const [row, setRow] = useState([]);

  const [stepCollection, stepCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graph.id}/buyer_exp_audit/0/columns`
      )
  );

  const stepArray = stepCollection?.docs?.map((item) => {
    return { ...item.data(), id: item.id };
  });

  useEffect(() => {
    if (stepArray) {
      let data = stepArray;
      setHeader([...data]);
    }
  }, [stepCollection]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graph.id}/buyer_exp_audit/`
      )
      .doc("0")
      .onSnapshot((snapshot) => {
        if (snapshot.data()?.rows) {
          setRow(snapshot.data().rows);
        }
      });
  }, []);

  const head = [
    {
      id: 0,
      name: "#",
    },
    ...header,
  ];

  const rows = [...row];


  return (
    <>
    <div className="p-4">
    <div style={{overflow: 'hidden'}}>
      <table class="table table-striped responsive">
        <thead className="thead-dark">
          <tr>
            {head.map((item) => (
              <th scope="col" key={item.id}>
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowData) => {
            return (
              <tr>
                {head.map((headData) => {
                  return (
                    <TableCell
                      rowData={rowData}
                      headData={headData}
                      graph={props.graph}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table> 
      </div>
      </div>  
    </>
  );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    buyerRows: state.buyerRows,
    buyerHeaders: state.buyerHeaders,
    currentEvent: state.currentEvent,
  }),
  {
    setBuyerRows,
    setBuyerHeader,
  }
)(BuyerExpTemplate);
