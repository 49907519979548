import React, { useState, useEffect } from "react";
import { Card, Form, ButtonGroup, Spinner, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faLock, faCamera, faCheckCircle, faCommentAlt, faStar, faImages, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from "react-router";




const ThanksPage = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [error, setError] = useState("");
    const [starValue, setStarValue] = useState(0);
    const [imagesLoading, setImagesLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const fileSelectedHandler = (e) => {
        const loadedFile = e.target.files;
        setFiles(prev => [...prev, ...loadedFile])
    }

    const removeImage = (key) => {
        let temp = [...files]
        temp.splice(key, 1)
        setFiles(temp)
    }

    const CreateStars = ({ n, setStarValue, starValue }) => {
        let group = []
        for (let i = 1; i <= n; i++) {
            group.push(
                <Button
                    id={i}
                    key={i}
                    variant="transparent"
                    value={i}
                    onClick={() => setStarValue(i)}
                    className="feedbackstar">
                    {(starValue >= i) ? <FontAwesomeIcon icon={faStar} color="#f2b600" /> : <FontAwesomeIcon className="text-theme" icon={faStar} />}
                </Button>
            )
        }
        return group
    }

    const handleSubmit = () => {
        console.log("Feedback Submitted")
        // if (pathname.split("/")[2] !== "" && pathname.split("/")[2] === "class") {
        //   history.push(`dashboard`);
        // }
    };


    return (
        <div className="container-fluid px-0">

            <div className="container my-3 my-md-5">
                <div className="row">
                    <div className="col-md-6 col-12 mx-auto">
                        <Card className="shadow-lg border-0 rounded-sm bg-dark text-white text-center mb-5">
                            <Card.Body>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="col-md-9">
                                        <h3 className="mb-3">
                                            Thank you for attending the session.
                                        </h3>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="bg-dark text-white p-4">
                            <Card.Header><h4 className="text-center">We would love to get your feedback.</h4></Card.Header>
                            <Form className="text-white">
                                <p className="mb-0">How will you rate your session from 1-5 where 1-not good and<br /> 5-wonderful.</p>
                                <div className="d-flex align-items-center">
                                    <div className="mb-3 feedbackdirection flex-grow-1 text-theme">
                                        <ButtonGroup aria-label="Feedback" >
                                            <CreateStars n={5} setStarValue={setStarValue} starValue={starValue} />
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <Form.Group>
                                    <Form.Label className="">Any Feedback for us?</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        aria-label=""
                                        placeholder=""
                                        rows="3"
                                    //   onChange={e => {
                                    //     setError("")
                                    //     setFeedback(e.target.value)
                                    //   }} 
                                    />

                                    {error != "" ? <small className="text-danger">{error}</small> : null}
                                </Form.Group>



                                {/* <div>
                                <p className=" mb-0">Attach screenshots (optional)</p>
                                <p className="small text-muted">Click on image to remove</p>
                                <div className="row m-1">

                                    {files.map((file, key) => (
                                        <>
                                            <ShowImage key={key} blob={file} removeImage={() => removeImage(key)} />
                                            {(!images[key] && imagesLoading)
                                                ? <Spinner animation="border" size="sm" />
                                                : (images.length > 0 || imagesLoading) && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 12 }} className="text-success" />}
                                        </>
                                    ))}

                                    <div className="m-1 pointer" style={{ position: "relative", height: 60, width: 60 }}>
                                        <div
                                            className="bg-light d-flex align-items-center justify-content-center"
                                            style={{ borderRadius: 10, borderStyle: "dashed", position: "absolute", cursor: "pointer", height: 60, width: 60, borderWidth: 0.1 }}>
                                            <FontAwesomeIcon icon={faImages} className="text-secondary" />
                                        </div>
                                        <input
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            onChange={e => fileSelectedHandler(e)}
                                            style={{ position: "absolute", opacity: 0, height: 60, width: 60 }} />
                                    </div>

                                </div>
                            </div> */}
                                <Button onClick={() => handleSubmit()}>Submit</Button>
                            </Form>
                        </Card>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ThanksPage;