import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from "@material-ui/lab";

import File from "./File";
import RecordedLecture from "./RecordedLecture";
import Assignment from "./Assignment";
import Quiz from "./Quiz";
import Schedule from "./Schedule";

function ModulePreview(props) {
  // CONSTANTS
  const {
    fetchingSubModuleData,
    handleCloseSubModule,
    selectedSubModuleType,
    selectedSubModuleData,
  } = props;

  // FUNCTIONAL COMPONENTS
  const Loader = () => {
    return (
      <div>
        <div className="border-bottom border-dark">
          <div className="d-flex align-items-center">
            <a
              onClick={handleCloseSubModule}
              style={{ cursor: "pointer" }}
              className="text-white p-3 m-0"
              title="Back"
            >
              <FontAwesomeIcon icon={faTimes} />
            </a>
            <div className="flex-grow-1 p-3">
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded-sm mb-2"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={32}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded-sm w-25"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={20}
              />
            </div>
          </div>
        </div>
        <div className="p-3">
          {selectedSubModuleType === "lecture" && (
            <>
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded-sm mb-2 embed-responsive embed-responsive-16by9 h-100"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded-sm mb-1"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={24}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded-sm w-50"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={24}
              />
            </>
          )}
          {selectedSubModuleType === "file" && (
            <>
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded-sm"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={36}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {fetchingSubModuleData ? (
        <Loader />
      ) : (
        <div style={{ height: "100%", overflowY: "scroll" }}>
          <div className="border-bottom border-dark">
            <div className="d-flex align-items-center">
              <a
                onClick={handleCloseSubModule}
                style={{ cursor: "pointer" }}
                className="text-white p-3 m-0"
                title="Close"
              >
                <FontAwesomeIcon icon={faTimes} />
              </a>
              <div className="p-3">
                <h4 className="text-white mb-2">
                  {selectedSubModuleData?.sub_module_details?.title}
                </h4>
                <p className="text-uppercase text-light small m-0">
                  <b>{selectedSubModuleData?.resource_type}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="p-3">
            {/* Schedule type */}
            {selectedSubModuleData?.resource_type === "schedule" && (
              <Schedule
                resource_details={selectedSubModuleData?.resource_details}
                sub_module_details={selectedSubModuleData?.sub_module_details}
              />
            )}

            {/* Quiz type */}
            {selectedSubModuleData?.resource_type === "quiz" && (
              <Quiz
                resource_details={selectedSubModuleData?.resource_details}
                sub_module_details={selectedSubModuleData?.sub_module_details}
              />
            )}

            {/* Assignment type */}
            {selectedSubModuleData?.resource_type === "assignment" && (
              <Assignment
                resource_details={selectedSubModuleData?.resource_details}
                sub_module_details={selectedSubModuleData?.sub_module_details}
              />
            )}

            {/* File type */}
            {selectedSubModuleData?.resource_type === "file" && (
              <File
                resource_details={selectedSubModuleData?.resource_details}
                sub_module_details={selectedSubModuleData?.sub_module_details}
              />
            )}

            {/* Lecture type */}
            {selectedSubModuleData?.resource_type === "lecture" && (
              <RecordedLecture
                resource_details={selectedSubModuleData?.resource_details}
                sub_module_details={selectedSubModuleData?.sub_module_details}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ModulePreview;
