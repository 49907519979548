import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Accordion,
  Card,
  Image,
  Button,
  Tabs,
  Tab,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faVideo,
  faFile,
  faInfinity,
  faMobile,
  faCertificate,
  faQuestionCircle,
  faClipboardList,
  faGraduationCap,
  faUser,
  faPodcast,
  faUserPlus,
  faEnvelope,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { changeLanguage, setCourseSection } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import * as CourseData from "./course.json";
import * as CourseStyle from "./course.module.css";
import ReadMore from "../ReadMore";
import CourseRedux from "./CourseRedux";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import HTMLReactParser from "html-react-parser";

function Course({
  currentLanguage,
  changeLanguage,
  currentCourse,
  setCourseSection,
}) {
  const history = useHistory();
  const { hash, pathname } = useLocation();

  const [modules, setModules] = useState([]);
  const [follow, setFollow] = useState(false);

  const [faculty, facultyLoading, facultyError] = useCollection(
    firebase.firestore().collection(`courses/${currentCourse?.id}/speakers`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const facultyData = faculty?.docs?.map((e) => ({ ...e.data(), id: e.id }));

  const [sections, sectionsLoading, sectionsError] = useCollection(
    firebase.firestore().collection(`courses/${currentCourse?.id}/sections`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const sectionsData = sections?.docs?.map((e) => ({ ...e.data(), id: e.id }));

  useEffect(() => {
    if (sections) {
      let sectionWithModules = sectionsData?.map((section) => {
        let data = [];
        firebase
          .firestore()
          .collection(
            `courses/${currentCourse?.id}/sections/${section.id}/modules`
          )
          .get()
          .then((res) => {
            res.forEach((item) => data.push({ ...item.data(), id: item.id }));
          });
        return { ...section, subModules: data };
      });
      setModules([...sectionWithModules]);
      setCourseSection([...sectionWithModules]);
    }
  }, [sections, sectionsLoading]);

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [courseContentKey, setCourseContentKey] = useState(0);

  const [navigationTo, setNavigationTo] = useState("overview");
  const overviewRef = useRef(null);
  const outcomesRef = useRef(null);
  const instructorsRef = useRef(null);
  const courseContentRef = useRef(null);
  const certificationRef = useRef(null);
  const benefitsRef = useRef(null);

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#outcomes`) {
      outcomesRef && outcomesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#instructors`) {
      instructorsRef &&
        instructorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#course-content`) {
      courseContentRef &&
        courseContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#certification`) {
      certificationRef &&
        certificationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#benefits`) {
      benefitsRef && benefitsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const handleEnrollNow = () => {
    if (pathname.split("/")[2] !== "" && pathname.split("/").length < 4) {
      history.push(`${pathname.split("/")[2]}/register`);
    }
  };

  const [selectedInstructor, setSelectedInstructor] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      <CourseRedux />
      {/* Top navigation start */}
      <Navbar
        className="container-fluid py-3 py-md-3 bg-graphite shadow-sm"
        variant="dark"
        sticky="top"
      >
        <div className="container d-flex align-items-center justify-content-between px-3">
          <Tabs
            activeKey={navigationTo}
            onSelect={(k) => {
              setNavigationTo(k);
              history.push({
                hash: `#${k}`,
              });
            }}
            id="cta"
            variant="pills"
          >
            <Tab
              eventKey="overview"
              title={`Overview`}
              tabClassName={CourseStyle.cta_tabs}
            />
            <Tab
              eventKey="outcomes"
              title={`Outcomes`}
              tabClassName={CourseStyle.cta_tabs}
            />
            <Tab
              eventKey="instructors"
              title={`Instructors`}
              tabClassName={CourseStyle.cta_tabs}
            />
            <Tab
              eventKey="course-content"
              title={`Course content`}
              tabClassName={CourseStyle.cta_tabs}
            />
            <Tab
              eventKey="certification"
              title={`Certification`}
              tabClassName={CourseStyle.cta_tabs}
            />
            <Tab
              eventKey="benefits"
              title={`Benefits`}
              tabClassName={CourseStyle.cta_tabs}
            />
          </Tabs>
          <Dropdown className="mr-3 small text-white">
            <Dropdown.Toggle
              as="div"
              id="dropdown-basic"
              style={{ cursor: "pointer" }}
            >
              {currentLanguage === "EN" ? "EN" : "FR"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "EN"}
                onClick={() => changeLanguage("EN")}
              >
                {/* {languageText[`${currentLanguage}`].english} */}
                English
              </Dropdown.Item>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "FR"}
                onClick={() => changeLanguage("FR")}
              >
                {/* {languageText[`${currentLanguage}`].french} */}
                French
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      {/* Top navigation end */}

      <div className="container">
        <div className="row my-3 my-md-4">
          <div className="col-md-4 w-100 order-2">
            <Card
              className={`shadow-sm ${CourseStyle.course_cover_card}`}
              style={{ position: "sticky", top: 70 }}
            >
              <Card.Body className={`text-white rounded-bottom`}>
                <p className="lead font-weight-bold">
                  {currentCourse ? currentCourse.name : CourseData?.programName}
                  {currentCourse?.course_details
                    ? currentCourse.course_details.EN.course_title
                    : null}
                </p>
                <p className="font-weight-bold small mb-1 text-warning">
                  {currentCourse?.date}
                </p>
                <p className="h5 font-weight-bold">{currentCourse?.fee}</p>
                <Button
                  className={`w-100 ${CourseStyle.enroll_btn}`}
                  onClick={handleEnrollNow}
                >
                  <span className="d-flex align-items-center justify-content-center">
                    <p className="small font-weight-bold mb-0 text-uppercase">
                      Enroll now
                    </p>
                  </span>
                </Button>
                <div className="mt-3">
                  <p className="font-weight-bold">This course includes:</p>
                  <p className="mb-1">
                    <FontAwesomeIcon
                      icon={faVideo}
                      className="mr-2"
                      size="sm"
                      style={{ width: 20 }}
                    />{" "}
                    8 hours live video
                  </p>
                  <p className="mb-1">
                    <FontAwesomeIcon
                      icon={faFile}
                      className="mr-2"
                      size="sm"
                      style={{ width: 20 }}
                    />{" "}
                    2 articles
                  </p>
                  <p className="mb-1">
                    <FontAwesomeIcon
                      icon={faVideo}
                      className="mr-2"
                      size="sm"
                      style={{ width: 20 }}
                    />{" "}
                    4 hours on-demand video
                  </p>
                  <p className="mb-1">
                    <FontAwesomeIcon
                      icon={faInfinity}
                      className="mr-2"
                      size="sm"
                      style={{ width: 20 }}
                    />{" "}
                    Full lifetime access
                  </p>
                  <p className="mb-1">
                    <FontAwesomeIcon
                      icon={faMobile}
                      className="mr-2"
                      size="sm"
                      style={{ width: 20 }}
                    />{" "}
                    Access on mobile and TV
                  </p>
                  <p className="mb-1">
                    <FontAwesomeIcon
                      icon={faCertificate}
                      className="mr-2"
                      size="sm"
                      style={{ width: 20 }}
                    />{" "}
                    Certificate of completion
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-8 order-1">
            {/* Event cover starts */}
            <div id="#overview" ref={overviewRef}>
              <Card className={`shadow-sm border-0 ${CourseStyle.dark_bg}`}>
                {currentCourse?.course_details ? (
                  <Card.Img
                    variant="top"
                    src={
                      currentCourse?.course_details
                        ? currentCourse.course_details.course_image
                        : null
                    }
                  />
                ) : null}
                <Card.Body>
                  <h2 className="font-weight-bold text-white mb-1">
                    {currentCourse
                      ? currentCourse.name
                      : CourseData?.programName}
                    {currentCourse?.course_details
                      ? currentCourse.course_details.EN.course_title
                      : null}
                  </h2>
                  <div className="text-white">
                    {HTMLReactParser(
                      currentCourse
                        ? currentCourse?.course_details?.EN?.description
                        : CourseData.aboutProgram
                    )}
                    {/* <ReadMore
                      full_text={
                        currentCourse
                          ? currentCourse?.course_details?.EN?.description?.replace(
                              /<[^>]+>/g,
                              ""
                            )
                          : CourseData.aboutProgram
                      }
                      max_length={260}
                    /> */}
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* Event cover ends */}

            <div className="py-3 py-md-5">
              {/* What you will learn */}
              {currentCourse?.course_details?.outcomes?.title && (
                <div className="mb-3 mb-md-5" id="#outcomes" ref={outcomesRef}>
                  <Card
                    className={`${CourseStyle.dark_bg} shadow-sm text-white`}
                  >
                    <Card.Header>
                      <h3>{currentCourse?.course_details?.outcomes?.title}</h3>
                    </Card.Header>
                    <Card.Body>
                      <ul className={CourseStyle.tick_list}>
                        {currentCourse?.course_details?.outcomes?.outcomes_array?.map(
                          (learningPoint) => (
                            <li>
                              <p className="text-uppercase mb-0">
                                {learningPoint}
                              </p>
                            </li>
                          )
                        )}
                      </ul>
                    </Card.Body>
                  </Card>
                </div>
              )}

              {/* Instructors */}
              {facultyData?.length > 0 && (
                <div
                  className="mb-3 mb-md-5"
                  id="#instructors"
                  ref={instructorsRef}
                >
                  <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                    <Card.Header>
                      <h3 className="text-white">Instructors</h3>
                    </Card.Header>

                    <Card.Body className="row text-white">
                      {facultyData
                        ?.sort((a, b) => a.order_index - b.order_index)
                        ?.map((professor) => (
                          <div
                            className="col-md-6 d-flex align-items-center mb-3"
                            onClick={() => {
                              setSelectedInstructor(professor);
                              handleShow();
                            }}
                            style={{ cursor: "pointer" }}
                            title={`${professor?.first_name} ${professor?.last_name}`}
                          >
                            <img
                              className={`img-fluid rounded-circle ${CourseStyle.instructor_img}`}
                              src={professor.img_src}
                            />

                            <div className="flex-grow-1 p-2">
                              <h6 className="mb-0 font-weight-bold">
                                {`${professor?.first_name} ${professor?.last_name}`}
                              </h6>
                              <p className="mb-0">{professor?.designation}</p>
                            </div>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </div>
              )}

              {/* Course content */}
              <div
                className="mb-3 mb-md-5"
                id="#course-content"
                ref={courseContentRef}
              >
                {modules?.length && (
                  <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                    <Card.Header>
                      <h3 className="text-white">Course content</h3>
                    </Card.Header>
                    <Card.Body>
                      <p className="text-white">
                        {CourseData &&
                          CourseData.learningJourney?.modules?.filter(
                            (item) => item.moduleType !== ""
                          ).length}{" "}
                        sections • 12 lectures • 14h total length
                      </p>

                      <Accordion
                        activeKey={courseContentKey}
                        onSelect={setCourseContentKey}
                      >
                        {modules
                          ?.sort((a, b) => a.order_index - b.order_index)
                          ?.map((item, index) => (
                            <Card key={index}>
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={index + "ac"}
                                style={{ cursor: "pointer" }}
                                className={`${CourseStyle.dark_bg} text-white`}
                              >
                                <div className="d-flex">
                                  <div>
                                    <FontAwesomeIcon
                                      icon={
                                        courseContentKey === index
                                          ? faAngleUp
                                          : faAngleDown
                                      }
                                      className="mr-2"
                                    />
                                  </div>
                                  <div className="flex-grow-1">
                                    <span className="font-weight-bold text-uppercase small">
                                      {item.title}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="small">
                                      {item.details}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={index + "ac"}>
                                <Card.Body className={`bg-graphite`}>
                                  {item?.subModules?.map((content, key) => (
                                    <div
                                      className="row mb-2"
                                      key={key}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span className="col-8 small text-white">
                                        {content.title}
                                      </span>
                                      <span className="col-4 text-right text-muted small">
                                        {content.length}
                                        {content.isQuiz && (
                                          <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                          />
                                        )}
                                        {content.isAssignment && (
                                          <FontAwesomeIcon
                                            icon={faClipboardList}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  ))}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          ))}
                      </Accordion>
                    </Card.Body>
                  </Card>
                )}
              </div>

              <div className="mb-3 mb-md-5" id="#outcomes" ref={outcomesRef}>
                <Card className={`${CourseStyle.dark_bg} shadow-sm text-white`}>
                  <Card.Header>
                    <h3>{`Requirements`}</h3>
                  </Card.Header>
                  <Card.Body>
                    <ul className={CourseStyle.tick_list}>
                      <li>
                        <p className="text-uppercase mb-0">{`A device with active internet connection`}</p>
                      </li>
                      <li>
                        <p className="text-uppercase mb-0">{`High school maths`}</p>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </div>

              {/* Record of Participation */}
              <div
                className="mb-3 mb-md-5"
                id="#certification"
                ref={certificationRef}
              >
                <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                  <Card.Header>
                    <h3 className="text-white">Record of Participation</h3>
                  </Card.Header>
                  <Card.Body>
                    <p className="text-white">
                      Upon successful completion of the course, you will be
                      awarded a record of participation from the University of
                      California, Los Angeles (UCLA) Extension
                    </p>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Funex_certificate.png?alt=media&token=e273510f-6596-4ffc-9565-94149eee018d"
                      width="100%"
                    />
                  </Card.Body>
                </Card>
              </div>

              {/* Who should take this course? */}
              <div className="mb-3 mb-md-5" id="#benefits" ref={benefitsRef}>
                <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                  <Card.Header>
                    <h3 className="text-white">Who should take this course?</h3>
                  </Card.Header>
                  <Card.Body>
                    <p className="text-white">
                      {CourseData && CourseData.who.intro}
                    </p>

                    <h5 className="text-white">
                      This program will benefit you if
                    </h5>
                    <p className="text-white">
                      <ul>
                        {CourseData &&
                          CourseData.who.questions.map((question) => (
                            <li>
                              <p>{question}</p>
                            </li>
                          ))}
                      </ul>
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="bg-graphite border-0 p-3 p-md-4" closeButton>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <img
              className="img-fluid rounded-circle mb-3"
              style={{ height: 150, width: 150 }}
              src={selectedInstructor?.img_src}
            />
            <h4 className="text-white font-weight-bold">
              {selectedInstructor?.first_name +
                " " +
                selectedInstructor?.last_name}
            </h4>
            <p className="text-white text-center mb-0">
              {selectedInstructor?.designation}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="text-white text-center bg-graphite border-0 p-3 p-md-4">
          <div className="row">
            <div className="col-sm">
              <FontAwesomeIcon icon={faGraduationCap} />
              <p className="mb-0">{`14`}</p>
            </div>
            <div className="col-sm">
              <FontAwesomeIcon icon={faUser} />
              <p className="mb-0">{`1251`}</p>
            </div>
            <div className="col-sm">
              <FontAwesomeIcon icon={faPodcast} />
              <p className="mb-0">{`43`}</p>
            </div>
          </div>
          <hr />
          <ReadMore
            className="text-white text-left"
            full_text={selectedInstructor?.description}
            max_length={250}
          />
          {/*<hr />
           <div className="d-flex align-items-center justify-content-center">
            <div className="w-50 border-right border-dark">
              <Button
                variant="none"
                className="text-primary font-weight-bold w-100"
                onClick={() => {
                  if (follow) {
                    setFollow(false);
                  } else {
                    setFollow(true);
                  }
                }}
              >
                {follow ?
                  <span className="d-flex align-items-center justify-content-center text-success">
                    <FontAwesomeIcon icon={faUserCheck} flip="horizontal" />
                    <p className="mb-0 ml-2">Following</p>
                  </span>
                  :
                  <span className="d-flex align-items-center justify-content-center text-primary">
                    <FontAwesomeIcon icon={faUserPlus} flip="horizontal" />
                    <p className="mb-0 ml-2">Follow</p>
                  </span>
                }

              </Button>
            </div>
            <div className="w-50">
              <Button
                variant="none"
                className="text-primary font-weight-bold w-100"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faEnvelope} flip="horizontal" />
                  <p className="mb-0 ml-2">Message</p>
                </span>
              </Button>
            </div>
          </div> 
          <hr />*/}
          {/* <div>
            <p className="font-weight-bold lead">Specifications</p>
            <p>{selectedInstructor?.specialties?.replaceAll(",", " •")}</p>
          </div> 
          <hr />*/}
          <div>
            <p className="font-weight-bold lead">Specialities</p>
            <p>{`Innovation • Artificial Intelligence • Services Operations Management • Technology Strategy`}</p>
          </div>
          <hr />
          <div>
            <p className="font-weight-bold lead">Qualifications</p>
            <p>{`Phd • MS • Btech`}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentCourse: state.course,
  }),
  { changeLanguage, setCourseSection }
)(Course);
