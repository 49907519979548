import React, { useState, useMemo, useEffect, memo } from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import { auth, firestore, FIRESTORE_SERVER_TIME } from "../../../../firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { API_BASE_URL } from "../../../../config";

const FormComponent = (props) => {
  // CONSTANTS
  const {
    sub_module_details,
    resource_details,
    fetchingDetails,
    darkTheme,
    currentEvent,
    sub_module_id,
    module_id,
  } = props;
  const user = auth.currentUser;

  // STATES
  const [formState, setFormState] = useState({});
  const [valid, setValid] = useState(false);
  const [sendingForm, setSendingForm] = useState(false);

  // FUNCTIONS
  const [formResponseDoc, formResponseLoading, formResponseError] = useDocument(
    firestore.doc(
      `events/${currentEvent?.id}/forms/${sub_module_details?.content_id}/responses/${user.uid}`
    )
  );

  useEffect(() => {
    if (formResponseDoc?.exists) {
      setFormState({
        ...formResponseDoc.data().form_responses,
        created_at: formResponseDoc.data().created_at,
      });
    }
  }, [formResponseDoc, formResponseLoading]);

  useEffect(() => {
    validateUserInput();
  }, [formState]);

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const generateArray = (start, end) => {
    const fn = (x) => x + 1;
    let out = Array.from(Array(end - start + 1), (_, x) => fn(x));
    return out;
  };

  const handleChange = (e) => {
    setFormState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleChangeRating = (e, val) => {
    setFormState((prev) => {
      return {
        ...prev,
        [e.target.name]: val,
      };
    });
  };

  const resetForm = () => {
    setFormState({});
  };

  const validateUserInput = () => {
    const inputValidation = resource_details?.form_fields?.map((item, idx) => {
      switch (item.type) {
        case "select":
          if (
            item.values.includes(formState[item.field_name]) ||
            !item.required
          ) {
            return true;
          } else {
            return false;
          }

        case "number":
          if (formState[item.field_name] || !item.required) {
            return true;
          } else {
            return false;
          }

        case "text":
          if (formState[item.field_name] != "" || !item.required) {
            return true;
          } else {
            return false;
          }

        case "rating":
          if (
            (formState[item.field_name] >= 1 &&
              formState[item.field_name] <= 5) ||
            !item.required
          ) {
            return true;
          } else {
            return false;
          }

        default:
          return true;
      }
    });

    setValid(
      inputValidation?.filter((item) => item)?.length ===
        resource_details?.form_fields?.length
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateUserInput();
    setSendingForm(true);

    if (valid) {
      const responseData = {
        event_id: currentEvent?.id,
        form_id: sub_module_details?.content_id,
        user_id: user?.uid,
        form_responses: {
          ...formState,
        },
        module_id: module_id,
        sub_module_id: sub_module_id,
      };

      axios
        .post(`${API_BASE_URL}/form/response`, responseData)
        .then((response) => {
          toast.dark(
            response?.data?.data?.state === "complete"
              ? `🎊 Completed 🎊`
              : "Responses submitted successfully",
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: false,
              autoClose: 3000,
            }
          );
          resetForm();
          setSendingForm(false);
        });
    } else {
      toast.info("Please fill all the mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setSendingForm(false);
    }
  };

  // HOOKS
  const formToBeStarted = useMemo(() => {
    if (resource_details?.starts_at) {
      return resource_details?.starts_at > Date.now();
    } else {
      return false;
    }
  }, [sub_module_details?.content_id, resource_details?.starts_at]);

  const formAlreadyEnded = useMemo(() => {
    if (resource_details?.ends_at) {
      return resource_details?.ends_at < Date.now();
    } else {
      return false;
    }
  }, [resource_details?.ends_at]);

  const submitButtonIsBlocked = useMemo(() => {
    let isBlocked = false;

    if (resource_details?.starts_at) {
      isBlocked = isBlocked || resource_details?.starts_at > Date.now();
    }

    if (resource_details?.ends_at) {
      isBlocked = isBlocked || resource_details?.ends_at < Date.now();
    }
    return sendingForm || isBlocked;
  }, [
    sub_module_details?.content_id,
    sendingForm,
    resource_details?.starts_at,
    resource_details?.ends_at,
  ]);

  // COMPONENTS
  if (fetchingDetails || formResponseLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  if (!fetchingDetails && !resource_details) {
    return (
      <div className="w-100 text-center">
        <p>Error loading form, please reload.</p>
      </div>
    );
  }

  if (formToBeStarted) {
    return (
      <Card
        className={
          darkTheme ? "shadow-sm rounded bg-dark" : "shadow-sm rounded bg-white"
        }
      >
        <Card.Header className="d-flex align-items-start">
          <div className="flex-grow-1">
            <p className="mb-0">
              <b>Starts: </b>
              <span>
                {moment(resource_details?.starts_at).fromNow()} (
                {moment(resource_details?.starts_at).format("LLLL")})
              </span>
            </p>
            <p className="small mb-0">
              <b>Created at: </b>
              {moment(
                convertSecondstoMilliSeconds(
                  resource_details?.created_at._seconds,
                  resource_details?.created_at._nanoseconds
                )
              ).format("LLL")}
            </p>
          </div>
          <div>
            {resource_details?.form_required ? (
              <p className="mb-0 text-danger">*Mandatory</p>
            ) : null}
          </div>
        </Card.Header>
        <Card.Body>{resource_details?.form_fields?.length}</Card.Body>
        <Card.Footer>
          <p className="mb-0 small">
            Deadline:{" "}
            {resource_details?.ends_at ? (
              <b>{moment(resource_details?.ends_at).format("LLL")}</b>
            ) : (
              <b>Active forever</b>
            )}
          </p>
        </Card.Footer>
      </Card>
    );
  }

  if (formAlreadyEnded && !formResponseDoc?.exists) {
    return (
      <Card
        className={
          darkTheme ? "shadow-sm rounded bg-dark" : "shadow-sm rounded bg-white"
        }
      >
        <Card.Header className="d-flex align-items-start">
          <div className="flex-grow-1">
            <p className="mb-0">
              {resource_details?.starts_at ? (
                <>
                  <b>Started: </b>
                  <span>
                    {moment(resource_details?.starts_at).fromNow()} (
                    {moment(resource_details?.starts_at).format("LLLL")})
                  </span>
                </>
              ) : (
                <>
                  <b>Starts: </b>
                  <span>Always active</span>
                </>
              )}
            </p>
            <p className="small mb-0">
              <b>Created at: </b>
              {moment(
                convertSecondstoMilliSeconds(
                  resource_details?.created_at._seconds,
                  resource_details?.created_at._nanoseconds
                )
              ).format("LLL")}
            </p>
          </div>
          <div>
            {resource_details?.form_required ? (
              <p className="mb-0 text-danger">*Mandatory</p>
            ) : null}
          </div>
        </Card.Header>
        <Card.Body>
          <h3>Form Expired!</h3>
          <p className="mb-0">
            Deadline: <b>{moment(resource_details?.ends_at).format("LLL")}</b>
          </p>
          <p className="mb-0">
            Number of questions: {resource_details?.form_fields?.length}
          </p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card
      className={
        darkTheme ? "shadow-sm rounded bg-dark" : "shadow-sm rounded bg-white"
      }
    >
      <Card.Header className="d-flex align-items-start">
        <div className="flex-grow-1">
          <p className="mb-0">
            {resource_details?.starts_at ? (
              resource_details?.starts_at > Date.now() ? (
                <>
                  <b>Starts: </b>
                  <span>
                    {moment(resource_details?.starts_at).fromNow()} (
                    {moment(resource_details?.starts_at).format("LLLL")})
                  </span>
                </>
              ) : (
                <>
                  <b>Started: </b>
                  <span>
                    {moment(resource_details?.starts_at).fromNow()} (
                    {moment(resource_details?.starts_at).format("LLLL")})
                  </span>
                </>
              )
            ) : (
              <>
                <b>Starts: </b>
                <span>Always active</span>
              </>
            )}
          </p>
          <p className="small mb-0">
            <b>Created at: </b>
            {moment(
              convertSecondstoMilliSeconds(
                resource_details?.created_at._seconds,
                resource_details?.created_at._nanoseconds
              )
            ).format("LLL")}
          </p>
        </div>
        <div>
          {resource_details?.form_required ? (
            <p className="mb-0 text-danger">*Mandatory</p>
          ) : null}
        </div>
      </Card.Header>
      <Card.Body className="p-3">
        <p>
          Marked fields (<span className="text-danger">*</span>) are mandatory.
        </p>

        <Form onSubmit={handleSubmit}>
          {resource_details?.form_fields?.map((item, idx) => {
            switch (item.type) {
              case "select":
                return (
                  <Form.Group key={idx}>
                    <Form.Label>
                      {idx + 1}. {item.label}{" "}
                      {item.required && <span className="text-danger">*</span>}
                      {formResponseDoc?.exists &&
                        (formState[item.field_name] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success ml-2"
                          />
                        ) : (
                          <span className="text-muted ml-2 small">
                            (skipped)
                          </span>
                        ))}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      required={item.required}
                      name={item.field_name}
                      id={item.field_name}
                      onChange={handleChange}
                      value={formState[item.field_name]}
                      disabled={formResponseDoc?.exists}
                    >
                      <option value={null} selected disabled={true}>
                        Select {item.label}
                      </option>
                      {item.values.map((option, key) => (
                        <option value={option} key={key}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                );

              case "number":
                return (
                  <Form.Group key={idx}>
                    <Form.Label>
                      {idx + 1}. {item.label}{" "}
                      {item.required && <span className="text-danger">*</span>}
                      {formResponseDoc?.exists &&
                        (formState[item.field_name] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success ml-2"
                          />
                        ) : (
                          <span className="text-muted ml-2 small">
                            (skipped)
                          </span>
                        ))}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={`Enter ${item.label}`}
                      required={item.required}
                      name={item.field_name}
                      id={item.field_name}
                      onChange={handleChange}
                      value={formState[item.field_name]}
                      readOnly={formResponseDoc?.exists}
                    />
                  </Form.Group>
                );

              case "text":
                return (
                  <Form.Group key={idx}>
                    <Form.Label>
                      {idx + 1}. {item.label}{" "}
                      {item.required && <span className="text-danger">*</span>}
                      {formResponseDoc?.exists &&
                        (formState[item.field_name] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success ml-2"
                          />
                        ) : (
                          <span className="text-muted ml-2 small">
                            (skipped)
                          </span>
                        ))}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={`Enter ${item.label}`}
                      required={item.required}
                      name={item.field_name}
                      id={item.field_name}
                      onChange={handleChange}
                      value={formState[item.field_name]}
                      readOnly={formResponseDoc?.exists}
                    />
                  </Form.Group>
                );

              case "rating":
                return (
                  <Form.Group key={idx}>
                    <Form.Label>
                      {idx + 1}. {item.label}{" "}
                      {item.required && <span className="text-danger">*</span>}
                      {formResponseDoc?.exists &&
                        (formState[item.field_name] ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success ml-2"
                          />
                        ) : (
                          <span className="text-muted ml-2 small">
                            (skipped)
                          </span>
                        ))}
                    </Form.Label>
                    <div
                      key={`inline-radio`}
                      className="d-flex align-items-center mb-3 mb-md-4 mt-2"
                    >
                      <p className="mb-0 mr-2 small text-uppercase">
                        {item.start_label}
                      </p>
                      <React.Fragment>
                        {generateArray(1, 5)?.map((arr, key) => {
                          return (
                            <Form.Check
                              key={key}
                              inline
                              label={arr}
                              name={`${item.field_name}`}
                              type="radio"
                              id={`inline-radio-${arr}`}
                              onChange={(e) => handleChangeRating(e, arr)}
                              checked={formState[item.field_name] === arr}
                              disabled={formResponseDoc?.exists}
                            />
                          );
                        })}
                      </React.Fragment>
                      <p className="mb-0 ml-2 small text-uppercase">
                        {item.end_label}
                      </p>
                    </div>
                  </Form.Group>
                );

              default:
                return null;
            }
          })}
          {formResponseDoc?.exists ? (
            <div className="border rounded bg-light p-2">
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                </div>
                <div className="flex-grow-1">
                  <p className="text-success mb-0">
                    Responses submitted successfully
                  </p>
                  <p className="text-success small mb-0">
                    {moment(
                      convertSecondstoMilliSeconds(
                        formResponseDoc?.data()?.created_at?.seconds,
                        formResponseDoc?.data()?.created_at?.nanoseconds
                      )
                    ).format("LLL")}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Button
                className="px-3 px-md-4 rounded-sm text-uppercase"
                type="submit"
                disabled={submitButtonIsBlocked}
              >
                {sendingForm && (
                  <Spinner size="sm" animation="border" className="mr-1" />
                )}
                <small>{sendingForm ? "Sending..." : "Submit"}</small>
              </Button>
              {resource_details?.starts_at > Date.now() && (
                <p className="mb-0 small">
                  Starts at{" "}
                  <b>{moment(resource_details?.starts_at).format("LLL")}</b>
                </p>
              )}
            </>
          )}
        </Form>
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 small">
          Deadline:{" "}
          {resource_details?.ends_at ? (
            <b>{moment(resource_details?.ends_at).format("LLL")}</b>
          ) : (
            <b>Active forever</b>
          )}
        </p>
      </Card.Footer>
    </Card>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(memo(FormComponent));
