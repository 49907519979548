import React, { useState, useMemo, memo, useEffect } from "react";
import {
  Alert,
  Button,
  Spinner,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faEdit,
  faTrash,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ReactExport from "react-export-excel";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";

// import QuizFields from "./QuizFields";
import QuizFields from "../CourseBuilder/SubModuleAdd/Quiz/Create";
import {
  API_BASE_URL,
  ADMIN_CHECK_LINKING,
  ADMIN_DELETE_LINKING,
  ADMIN_APP_RESPONSES_GET,
  GRADEBOOK_SCORE_PUBLISH,
} from "../../../../config";
import { auth, firestore } from "../../../../firebase";
import QuizLoader from "./QuizLoader";
import UserProfile from "../UserProfile";
import { useCollection } from "react-firebase-hooks/firestore";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Quiz = (props) => {
  // CONSTANTS
  const {
    data,
    darkTheme,
    currentEvent,
    eventId,
    handleCloseItem,
    setShowMoreDetailsPanel,
    setSelectedMoreDetails,
    selectedMoreDetails,
    showMoreDetailsPanel,
    handleRefresh,
  } = props;
  const currentUser = auth.currentUser;
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  // STATES
  const [count, setCount] = useState(0);
  const [allResponses, setAllResponses] = useState([]);
  const [show, setShow] = useState(false);
  const [quizId, setQuizId] = useState(null);
  const [quizDetails, setQuizDetails] = useState("");
  const [roomDetails, setRoomDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLinkedWith, setIsLinkedWith] = useState(false);
  const [linkedWith, setLinkedWith] = useState([]);
  const [checkingLinking, setCheckingLinking] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [quizData, setQuizData] = useState(null);
  const [loadingShowResponses, setLoadingShowResponses] = useState(false);
  const [loadingQuestionsMandatory, setLoadingQuestionsMandatory] =
    useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [loadingRows, setLoadingRows] = useState(true);
  const [showGradingModal, setShowGradingModal] = useState(false);
  const [points, setPoints] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentResponse, setCurrentResponse] = useState({});

  // FUNCTIONS
  const handleReset = () => {
    setLoading(false);
    setShowGradingModal(false);
    setPoints(null);
    setRemarks(null);
  };

  const handlePublishGrade = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (points.length != currentResponse?.quizData?.quizQuestions?.length) {
      toast.warning("ERROR: Please add grade points for all questions.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setLoading(false);
      return;
    }

    const api_response = await axios.post(
      `${API_BASE_URL}${GRADEBOOK_SCORE_PUBLISH}`,
      {
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        publisher_id: currentUser?.uid,
        user_id: currentResponse?.response?.id,
        graded_content_id: data.contentId,
        graded_content_type: 4,
        scores: points,
        remarks: remarks,
        for_event: true,
      }
    );
    if (api_response.status === 200) {
      toast.success("Assignment score published.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      handleReset();
    } else {
      toast.warning("ERROR: Publishing Assignment score.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);

  const [eventQuizDocs, eventQuizDocsLoading, eventQuizDocsError] =
    useCollection(
      firestore
        .collection(`events/${currentEvent?.id}/quizzes`)
        .where("content_id", "==", data?.contentId)
    );

  const eventQuizDetails = useMemo(() => {
    if (!eventQuizDocsLoading && eventQuizDocs?.docs[0]?.exists) {
      return {
        ...eventQuizDocs?.docs[0]?.data(),
        id: eventQuizDocs?.docs[0]?.id,
      };
    } else return {};
  }, [eventQuizDocs, eventQuizDocsLoading]);

  const handleFetchAppResponses = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${ADMIN_APP_RESPONSES_GET}?content_id=${data?.contentId}&type=quiz`
      );

      if (response.status === 200) {
        const { data } = response.data;

        setQuizData(data);
        setAllResponses(data?.responses);
        setCount(data?.responses?.length);
        setFetchingDetails(false);
      } else {
        setFetchingDetails(false);
      }
    } catch (error) {
      console.log("[Error] Fetching quiz details: ", error);
      setFetchingDetails(false);
    }
  };

  useEffect(() => {
    // Fetch: App details
    handleFetchAppResponses();
  }, [data?.contentId]);

  useEffect(() => {
    if (quizData) {
      let newState = ["S.no.", "Name", "Created"];

      Object.entries(quizData?.quizQuestions)
        ?.sort((a, b) => a[1].sequenceNo - b[1].sequenceNo)
        ?.map((e) =>
          newState.push(
            e[1]?.weight
              ? `${e[1]?.question} (${e[1]?.weight} points)`
              : `${e[1]?.question}`
          )
        );

      if (quizData?.isGraded) newState.push("Total");

      setColumns(newState);
    }

    if (allResponses) {
      const rowData = allResponses?.map((item, key) => {
        let newState = [
          key + 1,
          `${item?.user_details?.firstName} ${item?.user_details?.lastName}`,
          moment(item?.responseTimestamp).format("LLL"),
        ];

        Object.entries(quizData?.quizQuestions)
          ?.sort((a, b) => a[1].sequenceNo - b[1].sequenceNo)
          ?.map((q) => {
            if (item?.answers[q[0]]) {
              if (Object.entries(item?.answers[q[0]]?.options)?.length > 1) {
                newState.push(
                  `${Object.entries(item?.answers[q[0]]?.options)
                    ?.map(
                      (opts) =>
                        quizData?.quizQuestions[q[0]]?.options[opts[0]]
                          ?.optionValue
                    )
                    ?.join(", ")} ${
                    quizData?.isGraded
                      ? item?.answers[q[0]]?.correct
                        ? `(✅ Correct) (${item?.answers[q[0]]?.score} points)`
                        : `(❌ Incorrect) (${
                            item?.answers[q[0]]?.score
                          } points)`
                      : item?.answers[q[0]]?.correct
                      ? "(✅ Correct)"
                      : "(❌ Incorrect)"
                  }`
                );
              } else {
                newState.push(
                  `${
                    quizData?.quizQuestions[q[0]]?.options[
                      Object.entries(item?.answers[q[0]]?.options)[0][0]
                    ]?.optionValue
                  } ${
                    quizData?.isGraded
                      ? item?.answers[q[0]]?.correct
                        ? `(✅ Correct) (${item?.answers[q[0]]?.score} points)`
                        : `(❌ Incorrect) (${
                            item?.answers[q[0]]?.score
                          } points)`
                      : item?.answers[q[0]]?.correct
                      ? "(✅ Correct)"
                      : "(❌ Incorrect)"
                  }`
                );
              }
            } else {
              newState.push("Skipped");
            }
          });

        if (quizData?.isGraded) {
          if (item?.isScorePublished)
            newState.push(`${item?.totalScore} points`);
          else newState.push("Not published");
        }

        return newState;
      });

      setRows(rowData);
      setLoadingRows(false);
    }
  }, [allResponses]);

  useEffect(async () => {
    const roomDoc = firestore
      .collection(`workspaces/${currentEvent?.workspace_id}/rooms`)
      .doc(`${currentEvent?.room_id}`);
    const roomDocGet = await roomDoc.get();

    setRoomDetails(roomDocGet.data());
  }, []);

  const removeQuiz = async () => {
    try {
      // let res = await axios
      //   .post(`${API_BASE_URL}${ASSIGNMENT_REMOVE}`, {
      //     user_id: currentUser.uid,
      //     workspace_id: currentEvent.workspace_id,
      //     room_id: currentEvent.room_id,
      //     //   saved_assignment_id: savedAssignmentId,
      //   })
      firestore
        .collection(`events/${currentEvent?.id}/quizzes`)
        .doc(`${data.id}`)
        .delete()
        .then(() => {
          if (darkTheme) {
            toast.dark("Quiz deleted.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            toast("Quiz deleted.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }

          if (isLinkedWith) {
            handleDeleteLinking({
              event_id: eventId,
              user_id: currentUser.uid,
              content_id: quizDetails.id,
              type: "quiz",
            });
          }

          handleClose();
        });
    } catch (error) {
      console.log(error);

      toast.warning(`Error: ${error.response.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const handleDeleteLinking = async (data) => {
    try {
      console.log("[Try] Deleting linking: ", data);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_DELETE_LINKING}`,
        data
      );

      if (response.status === 200) {
        console.log("[Success] Deleting linking: ", response.data);
      }
    } catch (error) {
      console.log("[Error] Deleting linking: ", error);
    }
  };

  const handleCheckLinking = async (data) => {
    try {
      console.log("[Try] Cheking link: ", data);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_CHECK_LINKING}`,
        data
      );

      if (response.status === 200) {
        const { data } = response.data;
        console.log("[Success] Checking link: ", data);

        if (data?.length > 0) {
          setIsLinkedWith(true);
          setLinkedWith(data);
        } else {
          setIsLinkedWith(false);
        }
        setCheckingLinking(false);
      }
    } catch (error) {
      console.log("[Error] Checking link: ", error);
      setCheckingLinking(false);
    }
  };

  const updateShowResponses = (val) => {
    setLoadingShowResponses(true);

    firestore
      .collection(`events/${currentEvent?.id}/quizzes`)
      .doc(`${data.id}`)
      .update(
        {
          show_responses: val,
        },
        { merge: true }
      )
      .finally(() => setLoadingShowResponses(false));
  };

  const updateQuestionsMandatory = (val) => {
    setLoadingQuestionsMandatory(true);

    firestore
      .collection(`events/${currentEvent?.id}/quizzes`)
      .doc(`${data.id}`)
      .update(
        {
          questions_mandatory: val,
        },
        { merge: true }
      )
      .finally(() => setLoadingQuestionsMandatory(false));
  };

  if (fetchingDetails) {
    return <QuizLoader handleCloseItem={handleCloseItem} />;
  }

  return (
    <React.Fragment>
      <div className="border border-secondary text-white mr-3">
        <div className="p-3 d-flex align-items-center border-bottom border-secondary">
          <h5 className="flex-grow-1 mb-0">{data?.title}</h5>
          <div>
            <a
              disabled={count > 0}
              title={count > 0 ? "Cannot edit" : "Edit"}
              size="sm"
              onClick={handleShowEditModal}
              className="mr-1 text-white pointer p-2"
            >
              <FontAwesomeIcon icon={faEdit} />
            </a>

            <a
              className="mr-1 text-white pointer p-2"
              onClick={() => {
                handleShow();
                setQuizId(data.id);
                setQuizDetails(quizData);
                handleCheckLinking({
                  event_id: eventId,
                  user_id: currentUser.uid,
                  content_id: quizData?.id,
                  type: "quiz",
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </a>

            <a className="text-white pointer p-2" onClick={handleCloseItem}>
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </div>
        </div>
        <div className="p-3">
          <div className="mb-3">
            <p className="text-muted small mb-0">Questions</p>
            {Object.keys(quizData?.quizQuestions).length > 0 &&
              Object.keys(quizData?.quizQuestions)
                .sort(
                  (a, b) =>
                    quizData?.quizQuestions[a].sequenceNo -
                    quizData?.quizQuestions[b].sequenceNo
                )
                .map((item, key) => (
                  <>
                    <p className="mb-1" key={key}>
                      {key + 1}. {quizData?.quizQuestions[item].question}{" "}
                      {quizData?.isGraded && (
                        <>({quizData?.quizQuestions[item].weight} points)</>
                      )}
                    </p>
                    {quizData?.quizQuestions[item].questionImage && (
                      <figure>
                        <Zoom>
                          <img
                            alt={quizData?.quizQuestions[item].question}
                            src={quizData?.quizQuestions[item].questionImage}
                            className="img-fluid w-100 rounded-sm"
                            style={{ height: 120 }}
                          />
                        </Zoom>
                      </figure>
                    )}
                  </>
                ))}
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Created at</p>
            <p className="small mb-0">
              {moment(
                convertSecondstoMilliSeconds(
                  quizData?.createdAt._seconds,
                  quizData?.createdAt._nanoseconds
                )
              ).format("LLL")}
            </p>
          </div>
          {data?.is_graded && (
            <div className="mb-3">
              <p className="text-muted small mb-0">Total grade points</p>
              <p className="small mb-0">{data?.total_points}</p>
            </div>
          )}
          <div className="mb-3">
            <p className="text-muted small mb-0">Deadline by</p>
            <p className="small mb-0">
              {moment(quizData?.deadline).format("LLL")} (
              {moment(quizData?.deadline).fromNow()})
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Total number of responses</p>
            <p className="small mb-0">{count}</p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">
              Show correct/ incorrect responses to participants
            </p>
            <Form.Check
              type="switch"
              id="show-responses-switch"
              label="Show responses to participants"
              value="show_responses"
              checked={eventQuizDetails?.show_responses}
              disabled={loadingShowResponses}
              onChange={(e) => {
                if (e.target.checked) updateShowResponses(true);
                else updateShowResponses(false);
              }}
            />
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">All Questions Mandatory</p>
            <Form.Check
              type="switch"
              id="questions-mandatory-switch"
              label="All questions mandatory"
              value="questions_mandatory"
              checked={eventQuizDetails?.questions_mandatory}
              disabled={loadingQuestionsMandatory}
              onChange={(e) => {
                if (e.target.checked) updateQuestionsMandatory(true);
                else updateQuestionsMandatory(false);
              }}
            />
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <p className="text-muted small mb-0">Responses</p>
              {allResponses?.length > 0 && rows?.length > 0 && (
                <ExcelFile
                  filename={data?.title}
                  element={
                    <button
                      className="btn btn-outline-light btn-sm"
                      disabled={loadingRows}
                    >
                      <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                      Responses
                    </button>
                  }
                >
                  <ExcelSheet
                    dataSet={[{ columns, data: rows }]}
                    name={`${data?.title}`}
                  />
                </ExcelFile>
              )}
            </div>
            {allResponses?.length > 0 ? (
              <React.Fragment>
                {allResponses?.map((response, key) => {
                  return (
                    <div
                      className="border border-secondary mb-2 d-flex align-items-start justify-content-between"
                      style={
                        selectedMoreDetails?.response?.id === response?.id
                          ? { backgroundColor: "rgba(200, 200, 200, 0.15)" }
                          : {}
                      }
                    >
                      <div
                        key={key}
                        className="py-2 px-3"
                        onClick={() => {
                          setShowMoreDetailsPanel(true);
                          setSelectedMoreDetails({
                            response,
                            quizData,
                          });
                        }}
                      >
                        <UserProfile
                          userDetails={response.user_details}
                          imgSize={40}
                          subText={moment(response.responseTimestamp).format(
                            "LLL"
                          )}
                        />
                        {response?.isScorePublished && !showMoreDetailsPanel ? (
                          <>
                            <p className="mt-2 mb-0">
                              Total grade points scored:{" "}
                              <span className="text-success">
                                {response.totalScore} out of{" "}
                                {data?.total_points} points
                              </span>
                            </p>
                            <p className="text-success mb-1">
                              <FontAwesomeIcon icon={faCheckDouble} /> Graded at{" "}
                              {moment(response?.publishTimestamp).format("LLL")}
                            </p>
                          </>
                        ) : null}
                      </div>

                      {quizData?.isGraded && (
                        <div>
                          <Button
                            className="m-2"
                            variant="success"
                            size="sm"
                            onClick={() => {
                              setShowGradingModal(true);
                              setCurrentResponse({
                                response,
                                quizData,
                              });

                              Object.entries(response?.answers)?.map((resp) => {
                                setPoints((prev) => {
                                  let newState = { ...prev };

                                  newState[resp[0]] = {
                                    score: resp[1]?.score,
                                  };

                                  return newState;
                                });
                              });
                            }}
                          >
                            Grade
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <p className="text-center mt-1">
                <i>No responses yet</i>
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Modal: Confir Deletion */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div>
            <h5 className="mb-1">{quizDetails?.quizName}</h5>
            <p className="small mb-0 text-muted text-uppercase">
              Confirm deletion
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">
            Are you sure you want to delete? Delete operation cannot be
            reversed.
          </p>
          {!checkingLinking && isLinkedWith && (
            <p>
              Looks like <b>{quizDetails?.quizName}</b> is added to{" "}
              <b>Modules</b>, if you delete it will also remove them from
              modules.
            </p>
          )}
          <p className="text-muted">
            <b>Note:</b> Quizzes deleted are still part of your room "
            {roomDetails?.roomName}"
          </p>
          <div className="d-flex justify-content-end">
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                handleClose();
                setQuizId(null);
              }}
              className="rounded-lg text-uppercase border border-secondary mr-2"
              disabled={checkingLinking}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={removeQuiz}
              className="rounded-lg text-uppercase"
              disabled={checkingLinking}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal: Edit Quiz */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <QuizFields
            onHide={handleCloseEditModal}
            handleRefresh={handleRefresh}
            handleCloseItem={handleCloseItem}
            quizData={quizData}
            isEditting={true}
            id={data.id}
          />
        </Modal.Body>
      </Modal>

      {/* Modal: Grade Quiz */}
      <Modal size="lg" show={showGradingModal} onHide={handleReset}>
        {showGradingModal && (
          <>
            <Modal.Header closeButton>
              <UserProfile
                userDetails={currentResponse?.response?.user_details}
                imgSize={40}
                subText={moment(
                  currentResponse?.response?.responseTimestamp
                ).format("LLL")}
              />
            </Modal.Header>
            <Modal.Body>
              <div>
                <p className="mb-0 lead">Quiz: {data?.title}</p>
                {currentResponse?.isScorePublished ? (
                  <p className="">
                    Total grade points scored: {currentResponse.totalScore} out
                    of {data?.total_points}
                    <span className="text-success ml-2">
                      <FontAwesomeIcon icon={faCheckDouble} /> Graded at{" "}
                      {moment(currentResponse?.publishTimestamp).format("LLL")}
                    </span>
                  </p>
                ) : (
                  <p className="">Total grade points: {data?.total_points}</p>
                )}

                <Form onSubmit={handlePublishGrade} className="p-3">
                  {Object.keys(currentResponse?.quizData?.quizQuestions)
                    .length > 0 &&
                    Object.keys(currentResponse?.quizData?.quizQuestions)
                      .sort(
                        (a, b) =>
                          currentResponse?.quizData?.quizQuestions[a]
                            .sequenceNo -
                          currentResponse?.quizData?.quizQuestions[b].sequenceNo
                      )
                      .map((item, key) => (
                        <>
                          <p className="mb-2" key={key}>
                            <span className="font-weight-bold">
                              Q{key + 1}.
                            </span>{" "}
                            {
                              currentResponse?.quizData?.quizQuestions[item]
                                .question
                            }{" "}
                            {currentResponse?.quizData?.isGraded && (
                              <>
                                (
                                {
                                  currentResponse?.quizData?.quizQuestions[item]
                                    .weight
                                }{" "}
                                points)
                              </>
                            )}
                          </p>
                          {Object.entries(
                            currentResponse?.quizData?.quizQuestions[item]
                              .options
                          )
                            .sort((a, b) => a[1].sequenceNo - b[1].sequenceNo)
                            ?.map((v, k) => (
                              <div key={k}>
                                <Form.Group>
                                  <Form.Check
                                    type="checkbox"
                                    label={
                                      currentResponse?.response?.answers[item]
                                        ?.options[v[0]]
                                        ? currentResponse?.response?.answers[
                                            item
                                          ]?.options[v[0]]?.correct
                                          ? `${v[1].optionValue} (Correct)`
                                          : `${v[1].optionValue} (Incorrect)`
                                        : v[1].correctAnswer
                                        ? `${v[1].optionValue} (Correct)`
                                        : v[1].optionValue
                                    }
                                    checked={
                                      currentResponse?.response?.answers[item]
                                        ?.options[v[0]]
                                        ? true
                                        : false
                                    }
                                    disabled
                                    readOnly
                                    className={
                                      currentResponse?.response?.answers[item]
                                        ?.options[v[0]]
                                        ? currentResponse?.response?.answers[
                                            item
                                          ]?.options[v[0]]?.correct
                                          ? "text-success"
                                          : "text-danger"
                                        : ""
                                    }
                                  />
                                </Form.Group>
                              </div>
                            ))}
                          {currentResponse?.response?.answers[item]?.correct ? (
                            <p className="text-success">
                              <FontAwesomeIcon icon={faCheck} /> Correct answer
                            </p>
                          ) : (
                            <p className="text-danger">
                              <FontAwesomeIcon icon={faTimes} /> Incorrect
                              answer
                            </p>
                          )}

                          {currentResponse?.response?.isScorePublished &&
                            currentResponse?.response?.remarks[item]
                              ?.remark && (
                              <Alert variant="info">
                                <p className="mb-0">
                                  <b>Remarks</b>
                                </p>
                                <p className="mb-0">
                                  {
                                    currentResponse?.response?.remarks[item]
                                      ?.remark
                                  }{" "}
                                  (
                                  {currentResponse?.response?.answers[item]
                                    ? `${currentResponse?.response?.answers[item]?.score} points`
                                    : "0 points"}
                                  )
                                </p>
                              </Alert>
                            )}

                          {currentResponse?.response
                            ?.isScorePublished ? null : (
                            <>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  name={`score-${item}`}
                                  placeholder="Enter grade points"
                                  type="number"
                                  min="0"
                                  max={
                                    currentResponse?.quizData?.quizQuestions[
                                      item
                                    ].weight
                                  }
                                  disabled
                                  value={
                                    currentResponse?.response?.answers[item]
                                      ?.correct
                                      ? currentResponse?.quizData
                                          ?.quizQuestions[item].weight
                                      : 0
                                  }
                                  onChange={(e) =>
                                    setPoints((prev) => {
                                      let newState = { ...prev };

                                      if (
                                        e.target.value <=
                                        parseInt(
                                          currentResponse?.quizData
                                            ?.quizQuestions[item].weight
                                        )
                                      ) {
                                        newState[item] = {
                                          score: e.target.value,
                                        };
                                      }

                                      return newState;
                                    })
                                  }
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    out of{" "}
                                    {
                                      currentResponse?.quizData?.quizQuestions[
                                        item
                                      ].weight
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>

                              <Form.Control
                                className="mb-3"
                                name={`remark-${item}`}
                                placeholder="Enter remarks"
                                as="textarea"
                                rows={2}
                                value={remarks ? remarks[item]?.remark : null}
                                onChange={(e) =>
                                  setRemarks((prev) => {
                                    let newState = { ...prev };
                                    newState[item] = {
                                      remark: e.target.value,
                                    };
                                    return newState;
                                  })
                                }
                              />
                            </>
                          )}
                        </>
                      ))}
                  <div>
                    <Button
                      size="sm"
                      className="text-uppercase mr-2"
                      type="submit"
                      disabled={
                        loading || currentResponse?.response?.isScorePublished
                      }
                      title={
                        currentResponse?.response?.isScorePublished &&
                        "Scores already published"
                      }
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="mr-1"
                        />
                      )}
                      <span>{loading ? "Publishing..." : "Publish"}</span>
                    </Button>
                    <Button
                      size="sm"
                      variant="light"
                      className="text-uppercase shadow-sm"
                      onClick={handleReset}
                    >
                      {currentResponse?.response?.isScorePublished
                        ? "Close"
                        : "Discard"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
  eventId: state.eventId,
});

export default connect(mapStateToProps)(memo(Quiz));
