import React, { useMemo, memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
//import { projectList, blogList } from "../../data";
import { connect } from "react-redux";
import { setProject, setBlog } from "../../store/actions/actions";
import AddConsultancyModal from "./Sidebar/AddConsultancyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "./Navbar";
import moment from "moment";
import {
    faEdit, faUserEdit, faShapes, faVideo, faPoll, faCalendarAlt, faClipboardCheck, faClinicMedical, faNotesMedical, faHdd, faFile, faBook, faBookReader, faCheckSquare, faGripHorizontal, faGreaterThanEqual, faListAlt
} from "@fortawesome/free-solid-svg-icons";
import {
    faYoutube
} from "@fortawesome/free-brands-svg-icons";

const Sidebar = (props) => {
    const history = useHistory();

    const { events, blogs } = props;

    const userDetails = firebase.auth().currentUser;
    const [showAddConsultancyModal, setShowAddConsultancyModal] = useState(false);

    const [
        consultanciesSnapshot,
        loadingConsultancies,
        errorConsultancies,
    ] = useCollection(
        firebase.firestore().collection("clinics").where("isDemo", "==", true)
    );

    const [coursesSnapshot, loadingCourses, errorCourses] = useCollection(
        firebase.firestore().collection("courses").where("isDemo", "==", true)
    );

    const [projectsSnapshot, loadingProjects, errorProjects] = useCollection(
        firebase.firestore().collection("projects")
    );

    const convertSecondstoMilliSeconds = (seconds) => {
        return Math.floor(seconds * 1000);
    };

    const [
        organizationSnapshot,
        loadingOrganization,
        errorOrganization,
    ] = useCollection(firebase.firestore().collection("organizations"));

    // derived states

    // const consultancies = useMemo(() => {
    //   return consultanciesSnapshot?.docs?.map((e) => {
    //     return { id: e.id, ...e.data() };
    //   });
    // }, [consultanciesSnapshot]);

    const courses = useMemo(() => {
        return coursesSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [coursesSnapshot]);

    const projects = useMemo(() => {
        return projectsSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [projectsSnapshot]);

    const organizations = useMemo(() => {
        return organizationSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [organizationSnapshot]);

    return (
        <>
            <NavBar />
            <div className="row mt-3">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    <div className="rounded py-4 px-3 w-100"
                                        style={{
                                            backgroundColor: "lightblue",
                                            backgroundImage: "url(https://assets.codepen.io/1729459/download.svg)",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom right"
                                        }}>
                                        <div className="col-md-8">
                                            <span className="font-weight-bold text-uppercase text-dark small">GET STARTED</span>
                                            <h5 className="text-dark mb-3">Discover how you can use igesia to build and engage your community</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col-md-2 mb-2">
                            <ul class="list-group small  text-light">
                                <li class="list-group-item bg-dark">Introduction</li>
                                <li class="list-group-item bg-dark">Events FAQ <br /><span className="text-warning small">COMING SOON</span></li>
                                <li class="list-group-item bg-dark">Rooms FAQ <br /><span className="text-warning small">COMING SOON</span></li>
                                <li class="list-group-item bg-dark">Course FAQ <br /><span className="text-warning small">COMING SOON</span></li>
                            </ul>
                        </div>

                        <div className="col-md-8 mb-2">
                            <span className="font-weight-bold text-uppercase text-light small">INTRODUCTION</span>
                            <p className="text-light">
                                igesia is a super app for knowledge business to create, engage and monetise a knowledge community.
                            </p>
                            <span className="font-weight-bold text-uppercase text-light small">WHY IGESIA?</span>
                            <p className="text-light">
                                The COVID-19 crisis has accelerated the move of organisations across sectors into operating from a remote working environment. Almost overnight organisations around the world adopted digital systems to solve their remote work requirements. However, most of these systems solve a specific problem very well but are disconnected. Disconnected solutions lead to digital fatigue and poor productivity.
                            </p>
                            <p className="text-light">
                                Remote work is here to stay and thrive. A Gartner study  revealed that 82% of company leaders plan to allow employees to work remotely some of the time and 47% said they intend to allow employees to work remotely full time going forward. This has created a need for a platform that integrates multiple products and solutions to enable seamless remote work.
                            </p>
                            <p className="text-light">
                                Igesia solves the following problems:
                            </p>
                            <ul className="text-light">
                                <li>An integrated digital platform for a knowledge community</li>
                                <li>Connect multiple products and solutions</li>
                                <li>An easy way to monetize interactions on a knowledge community</li>
                                <li>Measuring and stimulating engagement of members of the knowledge community</li>
                                <li>Marketing and sales of courses, events and other knowledge services</li>
                                <li>Improve repeat engagements with multiple touchpoints</li>
                            </ul>
                        </div>
                        <div className="col-md-10 mb-3  text-light ">

                        </div>


                    </div>

                </div>
            </div >
        </>
    );
};
const sideBar = memo(Sidebar);

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        project: state.project,
        events: state.myEvents,
        blogs: state.myBlogs,
    }),
    { setProject, setBlog }
)(Sidebar);
