import React, { useState, useEffect, memo } from "react";
import { Button, Form, Spinner, Tab, Tabs, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faFile,
  faTimes,
  faEye,
  faUnlink,
  faCheck,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { connect } from "react-redux";

import { auth } from "../../../../../firebase";
import {
  ADMIN_ADD_ASYNCHRONOUS_CONTENT,
  ADMIN_ASYNCHRONOUS_CONTENT_GET,
  API_BASE_URL,
} from "../../../../../config";
import RecordedLecture from "../SubModulePreview/RecordedLecture";
import File from "../SubModulePreview/File";
import ListLoader from "./ListLoader";

toast.configure();

function AsyncResource(props) {
  // CONSTANTS
  const {
    eventId,
    currentEvent,
    module_data,
    setShowAddResource,
    newSubModuleDetails,
    setShowAddSubModuleSection,
    handleAddSubModule,
    handleRemoveSubModule,
  } = props;

  // STATES
  const [key, setKey] = useState("select");
  const [resourceFile, setResourceFile] = useState(null);
  const [resourceTitle, setResourceTitle] = useState("");
  const [resourceDescription, setResourceDescription] = useState("");
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState("start");
  const [response, setResponse] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [fetchingResourceList, setFetchingResourceList] = useState(false);
  const [resourceList, setResourceList] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  // FUNCTIONS
  const handleRecordedLectureForm = async (e) => {
    e.preventDefault();

    if (!resourceFile) {
      alert("Please select a file");
    }

    const fileData = new FormData();
    fileData.append("file", resourceFile, `${resourceFile?.name}`);
    fileData.append("user_id", auth.currentUser.uid);
    fileData.append("parent_id", currentEvent?.id);
    fileData.append("parent_type", currentEvent?.type);
    fileData.append("title", resourceTitle);
    fileData.append("description", resourceDescription);

    if (Object.entries(newSubModuleDetails)?.length > 0) {
      Object.entries(newSubModuleDetails).forEach((e) => {
        fileData.append(`${e[0]}`, e[1]);
      });
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        setProgress(
          Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
        );
        if ((ProgressEvent.loaded * 100) / ProgressEvent.total === 100) {
          setStatus("await");
        }
      },
    };

    try {
      setUploading(true);
      let response = await axios.post(
        `${API_BASE_URL}${ADMIN_ADD_ASYNCHRONOUS_CONTENT}`,
        fileData,
        config
      );
      const { status, data, message } = response;
      console.log("CORS Response ==> ",response)

      if (status === 200) {
        console.log("[Success] Uploading Resource: ", data.data);
        setStatus("success");
        setResponse(data.data);
      } else {
        setStatus("failure");
        setErrorMsg(message);
      }
      setUploading(false);
    } catch (error) {
      console.log(`[Error] Uploading Resource: ${error}`);
      setUploading(false);
      setStatus("failure");
      setErrorMsg(error.message);
    }
  };

  const handleCloseAsyncContentModal = () => {
    setShowAddResource(false);
    setShowAddSubModuleSection(false);
    setResourceFile(null);
    setResourceTitle("");
    setUploading(false);
    setProgress(0);
    setStatus("start");
    setResponse(null);
    setErrorMsg("");
  };

  const handleShowPreview = (resource) => {
    setShowPreviewModal(true);
    setSelectedResource(resource);
  };

  const handleHidePreview = () => {
    setShowPreviewModal(false);
    setSelectedResource(null);
  };

  const handleFetchResourceList = async (cbFetching, cbList) => {
    try {
      cbFetching(true);
      const response = await axios.get(
        `${API_BASE_URL}${ADMIN_ASYNCHRONOUS_CONTENT_GET}?user_id=${auth?.currentUser?.uid}&parent_id=${currentEvent?.id}&parent_type=${currentEvent?.type}&type=${newSubModuleDetails?.type}`
      );

      if (response.status === 200) {
        cbFetching(false);
        cbList(response?.data?.data?.resource_list);
      } else {
        cbFetching(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching resource list: `, error.message);
      cbFetching(false);
    }
  };
  const handleRefresh = () => {
    // Fetch: Resource list
    handleFetchResourceList(setFetchingResourceList, setResourceList);
  };

  useEffect(() => {
    // Fetch: Resource list
    handleFetchResourceList(setFetchingResourceList, setResourceList);
  }, [key]);

  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <div className="d-flex align-items-center">
        <a
          onClick={handleCloseAsyncContentModal}
          style={{ cursor: "pointer" }}
          className="text-white p-3 m-0"
          title="Close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <div className="p-3">
          <h4 className="text-white mb-2">
            Add a{" "}
            {newSubModuleDetails?.type === "lecture"
              ? "Recorded Lecture"
              : "Resource"}
          </h4>
          <p className="text-uppercase text-light small m-0">
            <strong>MODULE</strong> {module_data?.name}
          </p>
        </div>
      </div>

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="w-100"
      >
        <Tab
          tabClassName={
            key === "select"
              ? "border-top-0 border-left-0 border-right-0 border-bottom border-primary w-50 bg-transparent text-primary font-weight-bold"
              : "border-top-0 border-left-0 border-right-0 border-bottom border-secondary w-50 bg-transparent text-secondary font-weight-bold"
          }
          eventKey="select"
          title="Select from existing"
        >
          <div className="p-3">
            {fetchingResourceList && <ListLoader />}
            {resourceList?.map((resource, key) => (
              <div
                key={key}
                className="d-flex justify-content-between align-items-center border rounded p-2 px-3 mb-2"
              >
                <div>
                  <p className="mb-0">
                    {/* {key + 1}.   */}
                    {currentEvent && currentEvent?.slug && (
                      <a
                        onClick={() => handleShowPreview(resource)}
                        className="text-secondary mr-2 pointer"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </a>
                    )}
                    <span>{resource?.file_name}</span>
                  </p>
                </div>
                <div>
                  {module_data?.sub_modules?.filter(
                    (item) => item.content_id === resource?.id
                  )?.length > 0 ? (
                    <div
                      className="pointer"
                      onClick={() =>
                        handleRemoveSubModule(
                          module_data?.sub_modules?.filter(
                            (item) => item.content_id === resource?.id
                          )[0]
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faUnlink}
                        className="my-0 mr-2 pointer text-danger"
                      />
                      <small>REMOVE</small>
                    </div>
                  ) : (
                    <div
                      className="pointer"
                      onClick={() =>
                        handleAddSubModule({
                          reference_id: resource?.id,
                          content_id: resource?.id,
                          title: resource?.file_name,
                          type: newSubModuleDetails?.type,
                          user_id: auth.currentUser.uid,
                          order_index: module_data?.sub_modules?.length
                            ? module_data?.sub_modules?.length + 1
                            : 1,
                          event_id: eventId,
                          module_id: module_data?.id,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="my-0 mr-2 pointer text-primary"
                      />
                      <small>ADD</small>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Tab>
        <Tab
          tabClassName={
            key === "upload"
              ? "border-top-0 border-left-0 border-right-0 border-bottom border-primary w-50 bg-transparent text-primary font-weight-bold"
              : "border-top-0 border-left-0 border-right-0 border-bottom border-secondary w-50 bg-transparent text-secondary font-weight-bold"
          }
          eventKey="upload"
          title="Upload"
        >
          <div className="p-3">
            {status === "success" && response?.resource_type === "lecture" && (
              <div>
                <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer
                    controls
                    stopOnUnmount
                    width="100%"
                    height="auto"
                    playing
                    config={{
                      file: {
                        attributes: {
                          disablePictureInPicture: true,
                          noDownload: true,
                          controlsList: "nodownload noremoteplayback",
                        },
                      },
                    }}
                    url={response?.resource_file_url}
                  />
                </div>
                <p className="lead">{response?.resource_title}</p>
              </div>
            )}

            {status === "success" && response?.resource_type === "file" && (
              <a
                className="border rounded d-flex p-1 mb-3"
                href={response?.resource_file_url}
                target="_blank"
              >
                <div className="mr-2">
                  <FontAwesomeIcon icon={faFile} size="3x" />
                </div>
                <div className="flex-grow-1">
                  <p className="mb-0">{response?.resource_title}</p>
                </div>
              </a>
            )}

            {status != "success" && (
              <Form onSubmit={handleRecordedLectureForm}>
                <Form.Group>
                  <Form.Label className="text-uppercase small text-light">
                    <b>
                      {newSubModuleDetails?.type === "lecture"
                        ? "Recorded Lecture"
                        : "Resource"}{" "}
                      File
                    </b>
                  </Form.Label>
                  <Form.Control
                    name="resource_file"
                    id="resource_file"
                    type="file"
                    accept={
                      newSubModuleDetails?.type === "lecture" ? "video/*" : ""
                    }
                    onChange={(e) => {
                      setResourceFile(e.target.files[0]);
                      setResourceTitle((prev) => {
                        return prev === "" ? e.target.files[0]?.name : prev;
                      });
                    }}
                    disabled={uploading}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="text-uppercase small text-light">
                    <b>
                      {newSubModuleDetails?.type === "lecture"
                        ? "Recorded Lecture"
                        : "Resource"}{" "}
                      Title
                    </b>
                  </Form.Label>
                  <Form.Control
                    name="resource_title"
                    id="resource_title"
                    placeholder="Enter Resource title"
                    onChange={(e) => setResourceTitle(e.target.value)}
                    value={resourceTitle}
                    type="text"
                    disabled={uploading}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Description
                    <p className="small text-light mb-0">
                      Recommdended: Min. 50 words (Max. 1500 characters)
                    </p>
                  </Form.Label>
                  <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "link",
                          "|",
                          "bulletedList",
                          "numberedList",
                          // "-", // break point
                          // "insertTable",
                          // "|",
                          // "outdent",
                          // "indent",
                          // "|",
                          // "uploadImage",
                          "blockQuote",
                          "|",
                          "undo",
                          "redo",
                        ],
                        shouldNotGroupWhenFull: true,
                      },
                    }}
                    className="mb-3"
                    data={resourceDescription ?? ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setResourceDescription(data);
                    }}
                    style={{ height: 400 }}
                  />
                </Form.Group>

                {status === "start" && (
                  <Button
                    className="w-100"
                    type="submit"
                    disabled={uploading || progress === 100}
                  >
                    {progress === 100
                      ? "Almost done"
                      : uploading
                      ? `Uploading ${progress}%`
                      : "Upload"}
                  </Button>
                )}

                {status === "await" && (
                  <div className="w-100 border rounded d-flex align-items-center justify-content-center">
                    <div className="d-flex p-1 align-items-center justify-content-center text-light">
                      <Spinner
                        size="sm"
                        animation="border"
                        className="m-0 p-0 mr-2"
                      />
                      <p className="mb-0">Processing</p>
                    </div>
                  </div>
                )}
              </Form>
            )}

            {status === "success" && (
              <>
                <div className="w-100 border rounded d-flex align-items-center justify-content-center mb-2">
                  <div className="d-flex p-1 align-items-center justify-content-center text-success">
                    <FontAwesomeIcon icon={faCheck} className="m-0 p-0 mr-2" />
                    <p className="mb-0">Success</p>
                  </div>
                </div>
                <Button onClick={handleCloseAsyncContentModal} size="sm">
                  <div className="d-flex p-1 align-items-center justify-content-center text-uppercase">
                    <FontAwesomeIcon icon={faCheck} className="m-0 p-0 mr-2" />
                    <p className="mb-0">Close</p>
                  </div>
                </Button>
              </>
            )}

            {status === "failure" && (
              <>
                <div className="w-100 border rounded d-flex align-items-center justify-content-center mb-2">
                  <div className="d-flex p-1 align-items-center justify-content-center text-danger">
                    <FontAwesomeIcon icon={faTimes} className="m-0 p-0 mr-2" />
                    <p className="mb-0">Failed</p>
                  </div>
                </div>
                <p className="small text-danger">
                  <b>Error: </b>
                  {errorMsg}
                </p>
                <Button
                  onClick={handleCloseAsyncContentModal}
                  variant="secondary"
                  size="sm"
                >
                  <div className="d-flex p-1 align-items-center justify-content-center text-uppercase">
                    <FontAwesomeIcon icon={faTimes} className="m-0 p-0 mr-2" />
                    <p className="mb-0">Close</p>
                  </div>
                </Button>
              </>
            )}
          </div>
        </Tab>
      </Tabs>

      {/* Modal: Resource preview */}
      <Modal show={showPreviewModal} onHide={handleHidePreview}>
        <Modal.Header closeButton>
          <h5 className="mb-0">{selectedResource?.file_name}</h5>
        </Modal.Header>
        <Modal.Body>
          {showPreviewModal &&
            (selectedResource?.type === "lecture" ? (
              <RecordedLecture
                resource_details={selectedResource}
                sub_module_details={null}
              />
            ) : (
              <File
                resource_details={selectedResource}
                sub_module_details={null}
              />
            ))}
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

export default memo(connect(mapStateToProps)(AsyncResource));
