import React, { useMemo, useState } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPaperPlane,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sendEmail } from "../../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EmailAttendeeModal(props) {
  const [show, setShow] = useState(false);

  const [subject, setSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const sender = '"igesia Inc." <noreply@igesia.co>';

  const [discardShow, setDiscardShow] = useState(false);

  const isThereAnyContent = useMemo(
    () => subject !== "" || messageBody !== "",
    [subject, messageBody]
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const discardEmail = () => {
    setSubject("");
    setMessageBody("");
    setDiscardShow(false);
    handleClose();
  };

  const { first_name, last_name, email_address } = props;

  const submitEmail = async () => {
    if (isThereAnyContent) {
      try {
        const response = await sendEmail({
          to: email_address,
          subject: subject,
          content: messageBody,
          from: sender,
          replyTo: "direction@blue-awards.com",
        });
        if (response.status === 200) {
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else if (response.status !== 200) {
          toast.error("There was an error in sending email", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log("Email error: ", error);
      } finally {
        handleClose();
      }
    } else {
      toast.error("One or more field(s) are empty", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Button
        variant="outline"
        className="btn-sm btn-outline-light mr-2"
        onClick={handleShow}
      >
        <span>Send email</span> <FontAwesomeIcon icon={faEnvelope} />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-dark text-white">
          <Modal.Title className="h6 font-weight-bold">
            Email Attendee
          </Modal.Title>
        </Modal.Header>
        <p
          className="mb-1 p-2 border-bottom"
          style={{ borderColor: "#515151" }}
        >
          <span className="small text-muted mr-2">To</span> {first_name}{" "}
          {last_name} ({email_address})
        </p>
        <Modal.Body>
          <Form.Group className="mb-1">
            <Form.Label className="small text-muted font-weight-bold">
              Subject
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </Form.Group>

          <Form.Group className="my-1">
            <Form.Label className="small text-muted font-weight-bold">
              Message body
            </Form.Label>
            <CKEditor
              id="editor"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "bold",
                  "italic",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "undo",
                  "redo",
                ],
              }}
              data={messageBody ?? ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMessageBody(data);
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            />
          </Form.Group>

          <Alert show={discardShow} variant="danger">
            <Alert.Heading className="h6">Discard</Alert.Heading>
            <p className="small">Are you sure you want to discard?</p>
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                onClick={discardEmail}
                variant="outline-danger"
                className="mr-2"
              >
                Yes, discard
              </Button>
              <Button
                size="sm"
                onClick={() => setDiscardShow(false)}
                variant="outline-light"
              >
                No, cancel discard
              </Button>
            </div>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          {isThereAnyContent ? (
            <Button
              variant="light"
              onClick={() => setDiscardShow(true)}
              disabled={discardShow}
            >
              <span>
                Discard{" "}
                <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
              </span>
            </Button>
          ) : (
            <Button variant="light" onClick={handleClose}>
              <span>Close</span>
            </Button>
          )}
          <Button variant="primary" onClick={submitEmail}>
            <span>
              Send{" "}
              <FontAwesomeIcon size="sm" icon={faPaperPlane} className="ml-1" />
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
