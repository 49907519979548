import React, { useRef, useState, useEffect, useMemo } from "react";
import { Card, Image, Button, Dropdown, Form, Nav } from "react-bootstrap";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import * as BlogStyle from "./blog.module.css";
import { courseList } from "../data";
import { useHistory, useParams } from "react-router";
import firebase from "firebase";
import moment from "moment";
import BlogNavbar from "./BlogNavbar";
import ReactHtmlParser from "react-html-parser";
import Navbar from "../Timeline/Navbar";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";

import { API_BASE_URL, BLOG_GET_BY_ID } from "../../config";

function Blog({ currentLanguage }) {
  // CONSTANTS
  const { id } = useParams();
  const history = useHistory();

  // REFERENCES
  const pageRef = useRef(null);

  // STATES
  const [blogData, setBlogData] = useState(null);
  const [fetchingBlogData, setFetchingBlogData] = useState(false);
  const [readMoreBlogs, setReadMoreBlogs] = useState([]);

  // FUNCTIONS
  const fetchBlogData = async () => {
    try {
      console.log(`[Try] Fetching Blog data`);
      setFetchingBlogData(true);

      const response = await axios.get(
        `${API_BASE_URL}${BLOG_GET_BY_ID}?blog_id=${id}`
      );

      if (response.status === 200) {
        console.log(`[Success] Fetching Blog data`);
        setBlogData(response.data.data.item);
        setReadMoreBlogs(response.data.data.read_more_list);
        setFetchingBlogData(false);
      } else {
        setFetchingBlogData(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching Blog data: `, error);
    }
  };

  useEffect(() => {
    // Scroll to Top
    pageRef && pageRef.current.scrollIntoView();

    // Fetch: Blog data
    fetchBlogData();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom border-dark">
        <div className="container">
          {/* <a className="navbar-brand text-s-6" href={`/public-profile/${userDetails?.id}`}>{userDetails?.firstName} {userDetails?.lastName}</a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto w-100">
              <form className="form-inline my-2 my-lg-0 w-100">
                {/* <input className="form-control mr-sm-2 w-100 border-dark" type="search" placeholder={`Search ${userDetails?.firstName} ${userDetails?.lastName}'s workspace`} aria-label="Search" /> */}
              </form>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                {/* <a className="nav-link text-s-8" href="#">Contact</a> */}
                <a
                  href="mailto:{userDetails?.bio}"
                  className="btn btn-outline-dark my-2 my-sm-0 text-decoration-none"
                  type="submit"
                >
                  <span className="text-s-11 font-weight-bold pb-4">
                    Contact
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid py-2 bg-light" ref={pageRef}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-2 pl-0">
              {/* Author details */}
              {/* <div style={{ position: "sticky", top: 70 }}>
                <img
                  src={blogData?.author?.picture}
                  className="img-fluid pr-3 pr-md-5 mb-3 rounded"
                />
                <p className="font-weight-bold mb-2">
                  {blogData?.author?.name}
                </p>
                <p className="text-secondary small">
                  {blogData?.author?.designation}
                </p>
                
              </div> */}
            </div>

            {fetchingBlogData && (
              <div className="col-md-7">
                <Skeleton
                  animation="wave"
                  variant="rect"
                  className="bg-muted w-100 rounded-sm mb-1"
                  height={48}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  className="bg-muted w-50 rounded-sm mb-3"
                  height={48}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  className="bg-muted w-25 rounded-sm mb-4"
                  height={24}
                />
                <div class="embed-responsive embed-responsive-16by9 mb-3 mb-md-5">
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    className="bg-muted w-100 h-100 embed-responsive-item rounded-lg"
                  />
                </div>
                <div className="mb-3">
                  {[1, 2, 3, 4, 5, 6]?.map((e, idx) => (
                    <Skeleton
                      key={idx}
                      animation="wave"
                      variant="rect"
                      className={
                        e === 6
                          ? "bg-muted w-25 rounded-sm mb-1"
                          : "bg-muted w-100 rounded-sm mb-1"
                      }
                      height={20}
                    />
                  ))}
                </div>
              </div>
            )}

            {!fetchingBlogData && (
              <div className="col-md-7">
                <h1 className="font-weight-bold ">{blogData?.heading}</h1>
                <p className="text-muted mb-4">
                  {moment(blogData?.timestamp).format("Do MMM YY")}
                </p>

                <img
                  src={blogData?.img_src}
                  className="img-fluid mb-3 mb-md-5 rounded"
                />
                {/* <p className={`text-white ${BlogStyle.blog_description}`}>
              {blogData?.content}
            </p> */}
                <div className={` ${BlogStyle.blog_description}`}>
                  {ReactHtmlParser(blogData?.content)}
                </div>
                <hr className="bg-light" />
                <p className="small font-weight-bold text-uppercase">
                  Read more
                </p>
                <div className="row">
                  {readMoreBlogs &&
                    readMoreBlogs?.map((item, key) => (
                      <>
                        {item?.is_published ? (
                          <div
                            onClick={() => history.push(`${item.id}`)}
                            key={key}
                            className={`col-md-6 rounded mb-3`}
                            title={`Blog: ${item.heading}`}
                          >
                            <img
                              className="img-fluid rounded-top"
                              src={item.img_src}
                              alt="blog_image"
                            />
                            <div className="py-2">
                              <p className="text-muted small mb-0">
                                {moment(item.timestamp).format("Do MMM YY")}
                              </p>
                              <p className="small mb-0">{item.heading}</p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                </div>
                {/* <hr className="bg-light" />
              <p className="small font-weight-bold text-uppercase">
                Recommended for you
              </p>
              <div className="row">
                {courseList.map((course, key) => (
                  <div
                    onClick={() =>
                      window.open(
                        `${process.env.PUBLIC_URL}/course/${course.slug}`
                      )
                    }
                    className={` col-md-6 rounded mb-3`}
                    key={key}
                    title={`Course: ${course.name}`}
                  >
                    <img className="img-fluid rounded-top" src={course.image} />
                    <div className="py-2">
                      <p className="text-warning font-weight-bold small mb-0">
                        {course.date}
                      </p>
                      <p className=" mb-0">{course.name}</p>
                    </div>
                  </div>
                ))}
              </div> */}
              </div>
            )}

            <div className="col-md-3">
              {/* <p className=" small font-weight-bold text-uppercase">
                RECOMMENDED
              </p>
              <div className={BlogStyle.related_course_list}>
                {courseList.map((course, key) => (
                  <div
                    onClick={() =>
                      window.open(
                        `${process.env.PUBLIC_URL}/course/${course.slug}`
                      )
                    }
                    className={`${BlogStyle.related_course} rounded mb-3`}
                    key={key}
                    title={`Course: ${course.name}`}
                  >
                    <img className="img-fluid rounded-top" src={course.image} />
                    <div className="py-2">
                      <p className="text-warning font-weight-bold small mb-0">
                        {course.date}
                      </p>
                      <p className=" mb-0">{course.name}</p>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    blog: state.blog,
  }),
  { changeLanguage }
)(Blog);
