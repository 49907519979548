import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faImage,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import HTMLReactParser from "html-react-parser";

export default function File(props) {
  // CONSTANTS
  const { resource_details, sub_module_details } = props;
  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];
  const pdf_type = ["application/pdf"];
  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  // REFERENCES
  const fileRef = useRef(null);

  // FUNCTIONS
  const FileIcon = ({ file_content_type }) => {
    if (image_type?.indexOf(file_content_type) >= 0) {
      return <FontAwesomeIcon icon={faImage} className="mr-2" size="2x" />;
    }

    if (pdf_type?.indexOf(file_content_type) >= 0) {
      return <FontAwesomeIcon icon={faFilePdf} className="mr-2" size="2x" />;
    }

    if (word_type?.indexOf(file_content_type) >= 0) {
      return <FontAwesomeIcon icon={faFileWord} className="mr-2" size="2x" />;
    }

    if (excel_type?.indexOf(file_content_type) >= 0) {
      return <FontAwesomeIcon icon={faFileExcel} className="mr-2" size="2x" />;
    }

    if (powerpoint_type?.indexOf(file_content_type) >= 0) {
      return (
        <FontAwesomeIcon icon={faFilePowerpoint} className="mr-2" size="2x" />
      );
    }

    return <FontAwesomeIcon icon={faFile} className="mr-2" size="2x" />;
  };

  if (image_type?.indexOf(resource_details?.file_content_type) >= 0) {
    return (
      <div>
        {/* Preview: Image files */}
        <figure>
          <Zoom>
            <img
              alt={resource_details?.file_name}
              src={resource_details?.file_url}
              className="img-fluid w-100"
            />
          </Zoom>
        </figure>
        {sub_module_details?.description && (
          <div>{HTMLReactParser(sub_module_details?.description)}</div>
        )}
      </div>
    );
  }

  if (pdf_type?.indexOf(resource_details?.file_content_type) >= 0) {
    return (
      <div className="bg-secondary w-100 embed-responsive embed-responsive-1by1">
        {/* Preview: PDF files */}
        <embed
          className="w-100 embed-responsive-item"
          ref={fileRef}
          src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${resource_details?.file_url}`}
          // height="628"
        ></embed>
        {sub_module_details?.description && (
          <div>{HTMLReactParser(sub_module_details?.description)}</div>
        )}
      </div>
    );
  }

  return (
    <div>
      <a
        href={resource_details?.file_url}
        className="d-flex align-items-center border rounded-sm p-1"
        target="_blank"
      >
        <FileIcon file_content_type={resource_details?.file_content_type} />
        <span>{resource_details?.file_name}</span>
      </a>
      {sub_module_details?.description && (
        <div>{HTMLReactParser(sub_module_details?.description)}</div>
      )}
    </div>
  );
}
