import React, { useState } from "react";
import { Button, Card, Dropdown, Form, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import firebase from "firebase";
import { toast } from "react-toastify";
import { useCollection } from "react-firebase-hooks/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEllipsisH,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ReactExport from "react-export-excel";

function KeyNeedOfNonCustomers(props) {
  const { darkTheme, event_id, project_id } = props;
  const user = firebase.auth().currentUser;

  const [showKeyNeedForm, setShowKeyNeedForm] = useState(false);
  const [keyNeed, setKeyNeed] = useState({ title: "", description: "" });
  const [sending, setSending] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const resetKeyNeed = () => {
    setKeyNeed({ id: "", title: "", description: "" });
  };

  const [keyNeedsSnapshot, keyNeedsLoading, keyNeedsError] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${event_id}/bboa/${project_id}/key_needs_non_customers`
      )
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    if (editing) {
      if (keyNeed?.id === "") {
        toast.warning("Error editing Key need, please refresh.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        return;
      }
    }

    if (keyNeed?.title === "" || keyNeed?.description === "") {
      setError(true);
      setSending(false);
      return;
    }

    const asIsKeyNeedRef = firebase
      .firestore()
      .collection(
        `events/${event_id}/bboa/${project_id}/key_needs_non_customers`
      );

    if (editing) {
      asIsKeyNeedRef
        .doc(`${keyNeed?.id}`)
        .update(
          {
            title: keyNeed?.title ?? "",
            description: keyNeed?.description ?? "",
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Key need updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((e) => {
          console.error("Key need error: ", e);
          setSending(false);

          toast.warning("Error adding Key need: " + e, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setSending(false);
          resetKeyNeed();
          setShowKeyNeedForm(false);
          setEditing(false);
        });
    } else {
      asIsKeyNeedRef
        .add({
          title: keyNeed?.title ?? "",
          description: keyNeed?.description ?? "",
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          created_by: {
            name: user.displayName,
            email: user.email,
            id: user.uid,
          },
        })
        .then(() => {
          toast.success("Key need added successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((e) => {
          console.error("Key need error: ", e);
          setSending(false);

          toast.warning("Error adding Key need: " + e, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setSending(false);
          resetKeyNeed();
          setShowKeyNeedForm(false);
        });
    }
  };

  const handleDeleteKeyNeed = () => {
    if (!!keyNeed && keyNeed?.id != "") {
      // do nothing
    } else {
      toast.warning("Error deleting Key need, please refresh.", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    setDeleting(true);

    const asIsKeyNeedRef = firebase
      .firestore()
      .collection(
        `events/${event_id}/bboa/${project_id}/key_needs_non_customers`
      );

    asIsKeyNeedRef
      .doc(`${keyNeed?.id}`)
      .delete()
      .then(() => {
        toast.dark("Key need deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((e) => {
        console.error("Key need error: ", e);
        setDeleting(false);

        toast.warning("Error deleting Key need: " + e, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setDeleting(false);
        resetKeyNeed();
        setShowDeleteModal(false);
      });
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </a>
  ));

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header>
        <div className="d-flex mb-2">
          <h5 className="mb-0 text-uppercase flex-grow-1">
            5. KEY NEEDS OF THE NONCUSTOMERS
          </h5>
          <div>
            <ExcelFile
              element={
                <Button size="sm" className="text-uppercase mr-2">
                  <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                  <span>Export</span>
                </Button>
              }
            >
              <ExcelSheet
                data={keyNeedsSnapshot?.docs ?? []}
                name="Key needs of the Noncustomers"
              >
                <ExcelColumn
                  label="Creation time"
                  value={(item) =>
                    moment(
                      convertSecondstoMilliSeconds(
                        item?.data()?.created_at?.seconds,
                        item?.data()?.created_at?.nanoseconds
                      )
                    ).format("LLL")
                  }
                />
                <ExcelColumn
                  label="Name"
                  value={(item) => item.data().created_by?.name}
                />
                <ExcelColumn
                  label="Email"
                  value={(item) => item.data().created_by?.email}
                />
                <ExcelColumn
                  label="Title"
                  value={(item) => item.data().title}
                />
                <ExcelColumn
                  label="Description"
                  value={(item) => item.data().description}
                />
              </ExcelSheet>
            </ExcelFile>
            <Button
              onClick={() => {
                setShowKeyNeedForm(true);
                resetKeyNeed();
              }}
              className="text-uppercase"
              size="sm"
            >
              Add Key Need
            </Button>
          </div>
        </div>
        <div>
          <p className="mb-0">
            "Summarize here the key learnings your group got from your
            exploration regarding the key needs of the Noncustomers"
          </p>
        </div>
      </Card.Header>

      <Card.Body>
        {keyNeedsError && (
          <p className="text-center">
            Error fetching key needs, please reload.
          </p>
        )}

        {keyNeedsLoading && (
          <p className="text-center">
            <Spinner animation="border" size="sm" /> Fetching key needs...
          </p>
        )}

        {keyNeedsSnapshot?.docs?.length > 0 ? (
          keyNeedsSnapshot?.docs?.map((item, index) => (
            <div key={index} className="border rounded p-2 mb-2">
              <div className="d-flex justify-content-between">
                <p className="mb-0">
                  <b>{index + 1}.</b> {item.data().title}
                </p>
                <div className="d-flex">
                  <Dropdown className="px-1 py-0 pointer">
                    <Dropdown.Toggle
                      size="sm"
                      variant="white"
                      as={CustomToggle}
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setEditing(true);
                          setKeyNeed({
                            id: item.id,
                            title: item.data().title,
                            description: item.data().description,
                          });
                          setShowKeyNeedForm(true);
                        }}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark"
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setShowDeleteModal(true);
                          setKeyNeed({
                            id: item.id,
                            title: item.data().title,
                            description: item.data().description,
                          });
                        }}
                        className="text-uppercase"
                      >
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <p className="small mb-0">{item.data().description}</p>
              {/* <p className="mb-0 small">
                {moment(
                  convertSecondstoMilliSeconds(
                    item?.data()?.created_at?.seconds,
                    item?.data()?.created_at?.nanoseconds
                  )
                ).format("LLL")}{" "}
                <b className="text-muted">
                  ({item?.data()?.created_by?.email})
                </b>
              </p> */}
            </div>
          ))
        ) : (
          <p className="text-center">
            <i>No key needs added yet.</i>
          </p>
        )}
      </Card.Body>

      {/* Add and Edit key need modal */}
      <Modal
        show={showKeyNeedForm}
        onHide={() => {
          setShowKeyNeedForm(false);
          resetKeyNeed();
        }}
      >
        <Modal.Header closeButton>
          <div>
            <h5>{editing ? "Edit" : "Add"} key need</h5>
            <p className="mb-0 small text-muted">
              Marked (<span className="text-danger">*</span>) fields are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Label>
                Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Key need title"
                value={keyNeed?.title}
                onChange={(e) => {
                  setKeyNeed((prev) => {
                    let newValues = { ...prev };
                    newValues["title"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter Key need description"
                value={keyNeed?.description}
                onChange={(e) => {
                  setKeyNeed((prev) => {
                    let newValues = { ...prev };
                    newValues["description"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            {error && (
              <p className="text-danger small">
                <b>Note:</b> Title, description cannot be empty
              </p>
            )}

            <div className="d-flex">
              <Button
                variant="primary"
                type="submit"
                className="mr-2 text-uppercase"
                size="sm"
                disabled={sending}
              >
                {sending ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Sending...
                  </span>
                ) : (
                  <span>{editing ? "Update" : "Add"}</span>
                )}
              </Button>
              <Button
                variant="none"
                className="text-uppercase shadow-sm"
                size="sm"
                onClick={() => {
                  setShowKeyNeedForm(false);
                  resetKeyNeed();
                }}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Key need modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          resetKeyNeed();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Are you sure you want to delete Key need,{" "}
              <b>"{keyNeed?.title}"</b>?
            </p>
            <p className="small text-muted">
              <b>Note:</b> Once deleted you cannot retrive.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              variant="danger"
              size="sm"
              className="mr-2"
              onClick={handleDeleteKeyNeed}
              disabled={deleting}
            >
              {deleting ? (
                <span>
                  <Spinner animation="border" size="sm" /> Deleting...
                </span>
              ) : (
                <span>Yes, Delete</span>
              )}
            </Button>
            <Button
              variant="none"
              size="sm"
              className="shadow-sm"
              onClick={() => {
                setShowDeleteModal(false);
                resetKeyNeed();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
});

export default connect(mapStateToProps)(KeyNeedOfNonCustomers);
