import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
  Spinner,
  Alert,
  InputGroup,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faEnvelope,
  faPaperPlane,
  faTrash,
  faSearch,
  faTimes,
  faUser,
  faEllipsisV,
  faCrown,
  faEdit,
  faUsers,
  faEllipsisH,
  faFileExcel,
  faUsersSlash,
} from "@fortawesome/free-solid-svg-icons";
import InviteTemplate from "./InviteTemplate";
import Avatar from "react-avatar";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sendEmail } from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import ReactExport from "react-export-excel";
import { postData } from "../../../utils/index";

const Attendees = (props) => {
  // CONSTANTS
  const { eventId, currentEvent } = props;

  const [attendeesSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventId}/users`)
      .orderBy("firstName")
  );

  const admin_email = currentEvent?.event_details?.admin_email ?? "";

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${eventId}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        value: e.id,
        label: e.data().name,
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [selectedUID, setSelectedUID] = useState(null);
  const [selectedDisplayName, setSelectedDisplayName] = useState("");
  const [typedDisplayName, setTypedDisplayName] = useState("");
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [sendingCategoryName, setSendingCategoryName] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [showAssignAttendeeCategoryModal, setShowAssignAttendeeCategoryModal] =
    useState(false);
  const [selectedAttendeeCategory, setSelectedAttendeeCategory] = useState("");

  const [showInviteAttendeeModal, setShowInviteAttendeeModal] = useState(false);
  const [currentAttendeeDetails, setCurrentAttendeeDetails] = useState({});
  const [attendeeDetails, setAttendeeDetails] = useState({});
  const [saving, setSaving] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let attendeeDataArray = attendeesSnapshot?.docs.map((doc) => ({
    ...doc.data(),
    display_name: doc.data().firstName + " " + doc.data().lastName,
    id: doc.id,
  }));

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");
  const allAttendeesEmailArray = !loading
    ? attendeesSnapshot.docs.map((a) => a.data().email)
    : [];
  const [emailAllAttendee, setEmailAllAttendee] = useState(false);

  const [currentSortCriteria, setCurrentSortCriteria] = useState(null);

  let filteredDataArray = attendeeDataArray;
  filteredDataArray = filteredDataArray?.map((item) => {
    return {
      ...item,
      firstName: item.firstName.toLowerCase(),
      lastName: item.lastName.toLowerCase(),
    };
  });

  filteredDataArray =
    currentSortCriteria !== null
      ? _.orderBy(
          filteredDataArray,
          currentSortCriteria.value.split("-")[0],
          currentSortCriteria.sortOrder
        )
      : filteredDataArray;

  const { currentLanguage, darkTheme } = props;

  const changeUserStatus = (Attendee) => {
    if (Attendee.user_role == 0) {
      firebase
        .firestore()
        .collection(`/events/${eventId}/users`)
        .doc(Attendee.uid)
        .update({ user_role: 1 });
      console.log("userID to mod", Attendee.uid);
    } else {
      firebase
        .firestore()
        .collection(`/events/${eventId}/users`)
        .doc(Attendee.uid)
        .update({ user_role: 0 });
      console.log("userID to participant", Attendee.uid);
    }
  };

  const sortOptions = [
    { value: "firstName-asc", label: "First name: A-Z ", sortOrder: "asc" },
    { value: "firstName-desc", label: "First name: Z-A", sortOrder: "desc" },
    { value: "lastName-asc", label: "Last name: A-Z", sortOrder: "asc" },
    { value: "lastName-desc", label: "Last name: Z-A", sortOrder: "desc" },
    {
      value: "timestamp-asc",
      label: "Joining time: Oldest-Latest",
      sortOrder: "asc",
    },
    {
      value: "timestamp-desc",
      label: "Joining time: Latest-Oldest",
      sortOrder: "desc",
    },
  ];

  const deleteUserHandler = (uid) => {
    firebase
      .firestore()
      .collection(`/events/${eventId}/users`)
      .doc(uid)
      .delete();
  };

  function EmailAllAttendeeModal(props) {
    const [subjectAll, setSubjectAll] = useState("");
    const [messageBodyAll, setMessageBodyAll] = useState("");
    const sender = '"igesia Inc." <noreply@igesia.co>';

    const [discardShow, setDiscardShow] = useState(false);

    const isThereAnyContent = useMemo(
      () => subjectAll !== "" || messageBodyAll !== "",
      [subjectAll, messageBodyAll]
    );

    const handleClose = () => props.setShow(false);
    const handleShow = () => props.setShow(true);

    const discardEmail = () => {
      setSubjectAll("");
      setMessageBodyAll("");
      setDiscardShow(false);
      handleClose();
    };

    const { firstName, lastName, email, sendEmail } = props;

    const submitEmail = async () => {
      if (isThereAnyContent) {
        try {
          let response = [];
          if (Array.isArray(email)) {
            response = await Promise.all(
              email.map(async (email) => {
                return await sendEmail({
                  to: email,
                  subject: subjectAll,
                  content: messageBodyAll,
                  from: sender,
                  replyTo: `${admin_email}`,
                });
              })
            );
          }
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } catch (error) {
          console.log("Email error: ", error);
          toast.error("There was an error in sending email", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } finally {
          handleClose();
        }
      } else {
        toast.error("One or more field(s) are empty", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    };

    return (
      <>
        <Modal
          show={props.show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title className="h6 font-weight-bold">
              Email Participants
            </Modal.Title>
          </Modal.Header>
          <p
            className="mb-1 p-2 border-bottom"
            style={{ borderColor: "#515151" }}
          >
            <span className="small text-muted mr-2">To</span> all participants
          </p>
          <Modal.Body>
            <Form.Group className="mb-1">
              <Form.Label className="small text-muted font-weight-bold">
                Subject
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubjectAll(e.target.value)}
                value={subjectAll}
              />
            </Form.Group>

            <Form.Group className="my-1">
              <Form.Label className="small text-muted font-weight-bold">
                Message body
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                data={messageBodyAll ?? ""}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMessageBodyAll(data);
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </Form.Group>

            <Alert show={discardShow} variant="danger">
              <Alert.Heading className="h6">Discard</Alert.Heading>
              <p className="small">Are you sure you want to discard?</p>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  onClick={discardEmail}
                  variant="outline-danger"
                  className="mr-2"
                >
                  Yes, discard
                </Button>
                <Button
                  size="sm"
                  onClick={() => setDiscardShow(false)}
                  variant="outline-secondary"
                >
                  No, cancel discard
                </Button>
              </div>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            {isThereAnyContent ? (
              <Button
                variant="secondary"
                onClick={() => setDiscardShow(true)}
                disabled={discardShow}
              >
                <span>
                  Discard{" "}
                  <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
                </span>
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                <span>Close</span>
              </Button>
            )}
            <Button variant="primary" onClick={submitEmail}>
              <span>
                Send{" "}
                <FontAwesomeIcon
                  size="sm"
                  icon={faPaperPlane}
                  className="ml-1"
                />
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const participantTypeHandler = (type) => {
    if (type) {
      return "Moderator";
    } else {
      return "Participant";
    }
  };

  const saveTextDataToStateHandler = (e) => {
    setAttendeeDetails((prevSpeakersdata) => {
      return { ...prevSpeakersdata, [e.target.name]: e.target.value };
    });
  };

  const inviteAttendeeHandler = async (e, attendeesData) => {
    e.preventDefault();
    // if (attendeesData.email_address.trim().length) {
    const emailAlreadyExists = attendeesData.email_address?.trim().length
      ? (
          await firebase
            .firestore()
            .collection(`/events/${eventId}/invitees`)
            .where(
              "email_address",
              "==",
              attendeesData.email_address.toLowerCase()
            )
            .get()
        ).docs.length ||
        (
          await firebase
            .firestore()
            .collection(`/events/${eventId}/users`)
            .where(
              "email_address",
              "==",
              attendeesData.email_address.toLowerCase()
            )
            .get()
        ).docs.length
      : false;
    if (!emailAlreadyExists) {
      try {
        setSaving(true);
        await firebase
          .firestore()
          .collection(`/events/${eventId}/invitees`)
          .doc()
          .set({
            first_name: attendeesData.first_name ?? "",
            last_name: attendeesData.last_name ?? "",
            email_address: attendeesData.email_address ?? "",
            user_role: 0,
            category: attendeesData.category_id ?? "",
            timestamp_of_assigning: new Date().valueOf(),
          });
        await sendEmail({
          to: attendeesData.email_address,
          subject: `${currentEvent?.event_details?.EN?.event_name}`,
          content: InviteTemplate(
            attendeesData.first_name,
            attendeesData.last_name,
            attendeesData.email_address,
            currentEvent
          ),
          from: '"igesia" <noreply@igesia.co>',
        });
        await postData("https://dev.igesia.com/data/push", {
          point_type: "invite.new_attendee",
          data_properties: {
            attendee_email: attendeesData.email_address,
            attendee_first_name: attendeesData.first_name,
            attendee_last_name: attendeesData.last_name,
            event_id: eventId,
            category_id: attendeesData.category_id || "",
          },
        });
        toast.success("Invite Send", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } catch (e) {
        console.error(e, "Unable to write to db");
        toast.error(e, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } finally {
        setSaving(false);
        setAttendeeDetails({});
        setShowInviteAttendeeModal(false);
      }
    } else {
      toast.error(
        `${attendeesData.email_address.toLowerCase()} has already been invited`,
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
      setSaving(false);
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchTerm(e.target.value);
    const reg = _.escapeRegExp(searchTerm);
    // console.log(reg,"Reg")
    const regex = new RegExp(reg, "gi");
    setRegex(regex);
    //this.setState({ regex: regex })
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </a>
  ));

  const handleAddAttendeeCategory = (event) => {
    event.preventDefault();
    if (categoryName != "") {
      setSendingCategoryName(true);

      if (selectedCategory) {
        firebase
          .firestore()
          .collection(`events/${eventId}/attendee_category`)
          .doc(`${selectedCategory?.id}`)
          .update({
            name: categoryName,
          })
          .then(() => {
            toast.success("Participant group updated", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setShowAddCategoryModal(false);
            setCategoryName("");
            setSelectedCategory(null);
          })
          .catch((err) => {
            console.error("Error updating group data to database: ", err);
            toast.error("Fail to update Participant group", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .then(() => {
            setSendingCategoryName(false);
            setCategoryName("");
            setSelectedCategory(null);
          });
      } else {
        firebase
          .firestore()
          .collection(`events/${eventId}/attendee_category`)
          .add({
            name: categoryName,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            toast.success("Participant group added", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setShowAddCategoryModal(false);
            setCategoryName("");
          })
          .catch((err) => {
            console.error("Error adding group data to database: ", err);
            toast.error("Fail to add Participant group", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .then(() => {
            setSendingCategoryName(false);
            setCategoryName("");
          });
      }
    } else {
      alert("Please add some group name");
    }
  };

  const handleDeleteAttendeeCategory = (id) => {
    firebase
      .firestore()
      .collection(`events/${eventId}/attendee_category`)
      .doc(`${id}`)
      .delete()
      .then(() => {
        toast.success("Participant group deleted", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error("Error deleting Participant group", err);
        toast.error("Fail to delete Participant group", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const handleUpdateAttendeeCategory = (category) => {
    setSelectedCategory(category);

    setShowAddCategoryModal(true);
    setCategoryName(category.name);
  };

  const handleAssignAttendeeCategory = (event) => {
    event.preventDefault();

    if (selectedAttendeeCategory != "") {
      firebase
        .firestore()
        .collection(`events/${eventId}/users`)
        .doc(`${selectedAttendee?.id}`)
        .update({
          category: selectedAttendeeCategory,
        })
        .then(() => {
          toast.success("Participant group updated", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });

          setSelectedAttendee(null);
          setSelectedAttendeeCategory("");
          setShowAssignAttendeeCategoryModal(false);
        })
        .catch((err) => {
          console.error("Error updating group data to database: ", err);
          toast.error("Fail to update Participant group", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .then(() => {
          setSelectedAttendee(null);
          setSelectedAttendeeCategory("");
          setShowAssignAttendeeCategoryModal(false);
        });
    } else {
      toast.error("Please select some Participant group", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
  };

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  const unAssignCategoryHandler = (attendeeId) => {
    console.log();
    if (attendeeId) {
      firebase
        .firestore()
        .collection(`events/${eventId}/users`)
        .doc(`${attendeeId}`)
        .update({
          category: "",
        })
        .then(() => {
          toast.success("Unassigned Participant group", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((err) => {
          toast.error("Fail to unassign Participant group", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const AttendeeCard = (props) => {
    const { attendee, index } = props;

    return (
      <div className="col-md-4 mb-3 col-12" key={index}>
        <div className="icon-box bg-dark rounded shadow-sm text-light w-100 h-100 p-2">
          <div className="d-flex">
            <div className="mr-2 small">{index + 1}</div>
            <div>
              <Avatar
                name={attendee.firstName + " " + attendee.lastName}
                round={true}
                size="40"
                textSizeRatio={1.75}
                className="mr-3"
              />
            </div>
            <div className="flex-grow-1">
              <h5 className="mb-0 text-capitalize">
                {attendee.firstName + " " + attendee.lastName}
              </h5>
              <p className="small mb-0">{attendee.email}</p>
              <p className="small mb-0">{attendee.phone_number?.number}</p>
              <p className="small mb-0">
                {participantTypeHandler(attendee.user_role)}
              </p>
              <p className="small mb-0">
                <FontAwesomeIcon icon={faUsers} className="mr-2" size="sm" />
                {attendee?.category
                  ? getAttendeeCategoryName(attendee?.category)
                  : "No group assigned"}
              </p>
            </div>
            <div>
              <Dropdown className="px-1 py-0 pointer">
                <Dropdown.Toggle size="sm" variant="white" as={CustomToggle} />

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setShowEmailAttendeeModal(true);
                      setCurrentAttendeeDetails(attendee);
                    }}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    className="text-uppercase text-dark"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="sm"
                      className="mr-2"
                    />
                    Send email
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedDisplayName(
                        attendee.firstName + attendee.lastName
                      );
                      setSelectedUID(attendee.id);
                      setDeleteWarningModal(true);
                    }}
                    className="text-uppercase"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="sm"
                      className="mr-2"
                    />
                    Delete
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      changeUserStatus(attendee);
                    }}
                    className="text-uppercase"
                  >
                    <FontAwesomeIcon
                      icon={attendee.user_role ? faUser : faCrown}
                      className="mr-2"
                      size="sm"
                    />
                    {attendee.user_role ? (
                      <span>Revoke Moderator</span>
                    ) : (
                      <span>Make Moderator</span>
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-uppercase"
                    onClick={() => {
                      setSelectedAttendee(attendee);
                      setShowAssignAttendeeCategoryModal(true);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUsers}
                      className="mr-2"
                      size="sm"
                    />
                    Assign Group
                  </Dropdown.Item>
                  {attendee?.category ? (
                    <Dropdown.Item
                      onClick={() => {
                        unAssignCategoryHandler(attendee?.uid);
                      }}
                      className="text-uppercase"
                    >
                      <FontAwesomeIcon
                        icon={faUsersSlash}
                        size="sm"
                        className="mr-2"
                      />
                      Unassign Group
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [showEmailAttendeeModal, setShowEmailAttendeeModal] = useState(false);

  const [subject, setSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const sender = '"igesia Inc." <noreply@igesia.co>';

  const [discardShow, setDiscardShow] = useState(false);

  const isThereAnyContent = useMemo(
    () => subject != "" || messageBody != "",
    [subject, messageBody]
  );

  const discardEmail = () => {
    setSubject("");
    setMessageBody("");
    setDiscardShow(false);
    setShowEmailAttendeeModal(false);
  };

  const submitEmail = async () => {
    if (isThereAnyContent) {
      try {
        const response = await sendEmail({
          to: currentAttendeeDetails.email,
          subject: subject,
          content: messageBody,
          from: sender,
          replyTo: `${admin_email}`,
        });
        if (response.status === 200) {
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else if (response.status !== 200) {
          toast.error("There was an error in sending email", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log("Email error: ", error);
      } finally {
        discardEmail();
      }
    } else {
      toast.error("One or more field(s) are empty", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  if (loading) return <Spinner size="sm" animation="border" />;
  if (error) return <h5>Something went wrong!</h5>;
  return (
    <div className="container-fluid vh-100 overflow-auto">
      <div className="" data-theme={darkTheme ? "dark" : "light"}>
        <div className="mt-3 overflow-auto h-100">
          <div className="h-100">
            <div className="row">
              <div className="col-md-12 mb-3 text-light">
                <div>
                  <h5>Participants</h5>
                  <div className="row mb-2 justify-content-between">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center rounded border w-100 search-bar">
                        <div className="py-2 pr-2 pl-3 bg-dark">
                          <FontAwesomeIcon
                            icon={faSearch}
                            size="sm"
                            color="#aeaeae"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <Form.Control
                            type="text"
                            id="participantsSearch"
                            className="border-0 px-0 rounded bg-dark"
                            onChange={handleSearch}
                            placeholder={"Search by name, email"}
                            value={searchTerm}
                            style={{ margin: 0 }}
                          />
                        </div>
                        {searchTerm.length > 0 && (
                          <div
                            className="py-2 pl-2 pr-3 bg-dark"
                            onClick={() => setSearchTerm("")}
                          >
                            <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex col-md-5">
                      <Select
                        placeholder="Sort"
                        options={sortOptions}
                        onChange={(e) => setCurrentSortCriteria(e)}
                        className="w-100 mx-3 small text-dark"
                      ></Select>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Button
                        size="sm"
                        className="mr-2"
                        onClick={() => setShowAddCategoryModal(true)}
                      >
                        Add Group
                      </Button>

                      <Button
                        onClick={() => {
                          setEmailAllAttendee(true);
                        }}
                        className="btn-sm btn-primary mr-2"
                      >
                        Email Participants
                      </Button>

                      <Button
                        className="btn-sm btn-secondary mr-2"
                        onClick={() => setShowInviteAttendeeModal(true)}
                      >
                        Invite Participant
                      </Button>

                      {/* <Button className="btn-sm btn-secondary disabled mr-2">
                        Register Offline
                      </Button> */}
                    </div>
                    <div>
                      <ExcelFile
                        element={
                          <button className="mr-2 btn btn-outline-light btn-sm">
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              className="mr-2"
                            />
                            Participants
                          </button>
                        }
                      >
                        <ExcelSheet data={filteredDataArray} name="Attendees">
                          <ExcelColumn
                            label="Name"
                            value={(participant) =>
                              participant.firstName + " " + participant.lastName
                            }
                          />
                          <ExcelColumn
                            label="Email"
                            value={(participant) => participant.email}
                          />
                          <ExcelColumn
                            label="Phone"
                            value={(participant) => participant.phone}
                          />
                          <ExcelColumn
                            label="Participant type"
                            value={(participant) =>
                              participantTypeHandler(participant.user_role ?? 0)
                            }
                          />
                          <ExcelColumn
                            label="Registration time"
                            value={(participant) =>
                              moment(participant.timestamp.toDate()).format(
                                "lll"
                              )
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>

                      {currentEvent?.owner === "FSP" && (
                        <ExcelFile
                          filename={`Enrollment_${moment().format()}`}
                          element={
                            <button className="mr-2 btn btn-outline-light btn-sm">
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                className="mr-2"
                              />
                              Enrollment
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={filteredDataArray}
                            name="Enrollment"
                          >
                            <ExcelColumn
                              label="Content Source Id"
                              value={(participant) => "FSP_ID"}
                            />
                            <ExcelColumn
                              label="Course External Id"
                              value={(participant) => `${eventId}`}
                            />
                            <ExcelColumn
                              label="email"
                              value={(participant) => participant.email}
                            />
                            <ExcelColumn
                              label="date_enrolled"
                              value={(participant) =>
                                `${moment(
                                  participant.timestamp.toDate()
                                ).format()}`
                              }
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      )}

                      {currentEvent?.owner === "FSP" && (
                        <ExcelFile
                          filename={`Completion_${moment().format()}`}
                          element={
                            <button className="mr-2 btn btn-outline-light btn-sm">
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                className="mr-2"
                              />
                              Completion
                            </button>
                          }
                        >
                          <ExcelSheet
                            data={filteredDataArray}
                            name="Completion"
                          >
                            <ExcelColumn
                              label="Content Source Id"
                              value={(participant) => "FSP_ID"}
                            />
                            <ExcelColumn
                              label="Course External Id"
                              value={(participant) => `${eventId}`}
                            />
                            <ExcelColumn
                              label="email"
                              value={(participant) => participant.email}
                            />
                            <ExcelColumn
                              label="date_completed"
                              value={(participant) =>
                                `${moment(
                                  new Date(
                                    participant.timestamp.seconds * 1000 +
                                      participant.timestamp.nanoseconds +
                                      10 * 24 * 60 * 60 * 1000
                                  )
                                ).format()}`
                              }
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      )}

                      {currentEvent?.owner === "FSP" && (
                        <ExcelFile
                          filename={`Payment_${moment().format()}`}
                          element={
                            <button className="mr-2 btn btn-outline-light btn-sm">
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                className="mr-2"
                              />
                              Payment
                            </button>
                          }
                        >
                          <ExcelSheet data={filteredDataArray} name="Payment">
                            <ExcelColumn
                              label="Content Source Id"
                              value={(participant) => "FSP_ID"}
                            />
                            <ExcelColumn
                              label="Course External Id"
                              value={(participant) => `${eventId}`}
                            />
                            <ExcelColumn
                              label="email"
                              value={(participant) => participant.email}
                            />
                            <ExcelColumn
                              label="fee_paid"
                              value={(participant) => 0}
                            />
                            <ExcelColumn
                              label="date_fee_paid"
                              value={(participant) =>
                                `${moment(
                                  participant.timestamp.toDate()
                                ).format()}`
                              }
                            />
                            <ExcelColumn
                              label="receipt_number"
                              value={(participant) => `${participant.id}`}
                            />
                            <ExcelColumn
                              label="receipt_url"
                              value={(participant) =>
                                `igesia.co/invoice/${participant.id}`
                              }
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              <div className="mt-3 mb-3 mb-md-4">
                <h6 className="text-uppercase font-weight-bold">Groups</h6>
                {attendeeCategoryLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : null}

                {attendeeCategoryList?.length === 0 && (
                  <p className="text-center">No group exists</p>
                )}

                {attendeeCategoryList?.map((item, idx) => (
                  <div
                    className={
                      darkTheme
                        ? "d-flex align-items-center mb-1 border border-secondary shadow-sm rounded-sm"
                        : "d-flex align-items-center mb-1 border shadow-sm rounded-sm"
                    }
                    key={idx}
                  >
                    <p className="mb-0 px-2 py-1">{idx + 1}.</p>
                    <p className="flex-grow-1 mb-0 mr-2 py-1">{item.name}</p>
                    <div>
                      <a
                        className="btn btn-sm pointer px-2 py-1"
                        onClick={() => handleUpdateAttendeeCategory(item)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className={darkTheme ? "text-white" : "text-dark"}
                        />
                      </a>
                      <a
                        className="btn btn-sm pointer px-2 py-1"
                        onClick={() => handleDeleteAttendeeCategory(item.id)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={darkTheme ? "text-white" : "text-dark"}
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            }

            <div className="mt-3">
              <h5>Moderators</h5>
              <div className="row">
                {searchTerm && searchTerm.trim().length ? (
                  <>
                    {filteredDataArray
                      .filter((item) => {
                        return (
                          item?.display_name?.match(regex) ||
                          item?.email?.match(regex) ||
                          (item?.phone_number?.number?.match(regex) &&
                            item.user_role === 1)
                        );
                      })
                      .map((attendee, index) => {
                        return (
                          <AttendeeCard
                            attendee={attendee}
                            index={index}
                            key={index}
                          />
                        );
                      })}
                  </>
                ) : (
                  <>
                    {filteredDataArray &&
                      filteredDataArray
                        .filter((item) => item.user_role === 1)
                        .map((attendee, index) => {
                          return (
                            <AttendeeCard
                              attendee={attendee}
                              index={index}
                              key={index}
                            />
                          );
                        })}
                  </>
                )}
              </div>
            </div>

            <div className="mt-3">
              <h5>Participants</h5>
              <div className="row">
                {searchTerm && searchTerm.trim().length ? (
                  <>
                    {filteredDataArray
                      .filter((item) => {
                        return (
                          item?.display_name?.match(regex) ||
                          item?.email?.match(regex) ||
                          (item?.phone_number?.number?.match(regex) &&
                            item.user_role != 1)
                        );
                      })
                      .map((attendee, index) => {
                        return (
                          <AttendeeCard
                            attendee={attendee}
                            index={index}
                            key={index}
                          />
                        );
                      })}
                  </>
                ) : (
                  <>
                    {filteredDataArray &&
                      filteredDataArray
                        .filter((item) => item.user_role != 1)
                        .map((attendee, index) => {
                          return (
                            <AttendeeCard
                              attendee={attendee}
                              index={index}
                              key={index}
                            />
                          );
                        })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailAllAttendeeModal
        email={allAttendeesEmailArray}
        sendEmail={sendEmail}
        show={emailAllAttendee}
        setShow={(e) => setEmailAllAttendee(e)}
      />

      <Modal
        show={showAssignAttendeeCategoryModal}
        onHide={() => {
          setSelectedAttendee(null);
          setShowAssignAttendeeCategoryModal(false);
        }}
      >
        <Modal.Header
          closeButton
          data-theme={darkTheme ? "dark" : "light"}
          className={darkTheme ? "border-dark" : ""}
        >
          <Modal.Title>Assign patricipant group</Modal.Title>
        </Modal.Header>
        <Modal.Body
          data-theme={darkTheme ? "dark" : "light"}
          className={darkTheme ? "border-dark" : ""}
        >
          <div className="d-flex align-items-center mb-2">
            <Avatar
              name={
                selectedAttendee?.firstName + " " + selectedAttendee?.lastName
              }
              round={true}
              size="40"
              textSizeRatio={1.75}
              className="mr-3"
              maxInitials={2}
            />
            <div className="flex-grow-1">
              <h5 className="mb-0 text-capitalize">
                {selectedAttendee?.firstName + " " + selectedAttendee?.lastName}
              </h5>
              <p className="mb-0 small">
                {selectedAttendee?.category
                  ? getAttendeeCategoryName(selectedAttendee?.category)
                  : "No group assigned"}
              </p>
            </div>
          </div>
          <Select
            placeholder="Participant Group"
            options={attendeeCategoryList?.map((item) => {
              return {
                label: `${item.name}`,
                value: `${item.id}`,
              };
            })}
            onChange={(e) => setSelectedAttendeeCategory(e.value)}
            className="w-100 my-3 text-dark"
          ></Select>
          <Button
            size="sm"
            className="text-uppercase"
            disabled={selectedAttendee?.category === selectedAttendeeCategory}
            onClick={handleAssignAttendeeCategory}
          >
            Save changes
          </Button>
          {selectedAttendee?.category === selectedAttendeeCategory && (
            <p className="small mb-0 text-muted">
              "{selectedAttendee?.firstName}" is already linked with{" "}
              <b>{getAttendeeCategoryName(selectedAttendee?.category)}</b>
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddCategoryModal}
        onHide={() => {
          setCategoryName("");
          setShowAddCategoryModal(false);
        }}
      >
        <Modal.Header
          closeButton
          data-theme={darkTheme ? "dark" : "light"}
          className={darkTheme ? "border-dark" : ""}
        >
          <Modal.Title>
            {selectedCategory ? "Update Group" : "Add Group"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          data-theme={darkTheme ? "dark" : "light"}
          className={darkTheme ? "border-dark" : ""}
        >
          <Form onSubmit={handleAddAttendeeCategory}>
            <Form.Group>
              <Form.Label>Group name</Form.Label>
              <Form.Control
                type="text"
                name="category"
                placeholder="Enter Group name"
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
                required={true}
              />
            </Form.Group>

            {selectedCategory ? (
              <Button
                size="sm"
                disabled={sendingCategoryName}
                type="submit"
                className="text-uppercase mr-2"
              >
                {sendingCategoryName ? (
                  <Spinner animation="border" size="sm" />
                ) : null}{" "}
                Save Changes
              </Button>
            ) : (
              <Button
                size="sm"
                disabled={sendingCategoryName}
                type="submit"
                className="text-uppercase mr-2"
              >
                {sendingCategoryName ? (
                  <Spinner animation="border" size="sm" />
                ) : null}{" "}
                Submit
              </Button>
            )}
            <Button
              onClick={() => {
                setCategoryName("");
                setShowAddCategoryModal(false);
                setSelectedCategory(null);
              }}
              variant="none"
              size="sm"
              className={
                darkTheme
                  ? "text-uppercase text-white"
                  : "text-uppercase text-secondary"
              }
            >
              Discard
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteWarningModal}
        onHide={() => {
          setSelectedUID("");
          setTypedDisplayName("");
          setDeleteWarningModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>Confirm deletion</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete? This action cannot be reversed</p>
          <div className="d-flex justify-content-end flex-column">
            {/* <p>
              Confirm that you want to delete the Participant by typing its name:
              <span className="font-weight-bold">{selectedDisplayName}</span>
            </p>
            <Form.Control
              type="text"
              placeholder={`${selectedDisplayName}`}
              onChange={(e) => {
                setTypedDisplayName(e.target.value);
              }}
            /> */}
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                className="btn-sm mr-3"
                onClick={() => {
                  setSelectedUID("");
                  setTypedDisplayName("");
                  setDeleteWarningModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="btn-sm"
                onClick={() => {
                  // if (selectedDisplayName === typedDisplayName) {
                  deleteUserHandler(selectedUID);
                  setDeleteWarningModal(false);
                  setSelectedUID("");
                  setTypedDisplayName("");
                  toast.success("Participant deleted", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  // } else {
                  //   toast.error("Confirm deletion by typing attendee's name", {
                  //     position: toast.POSITION.TOP_CENTER,
                  //     hideProgressBar: true,
                  //     autoClose: 3000,
                  //   });
                  // }
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*--------------------Invite Participant Modal Start --------------------*/}
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showInviteAttendeeModal}
        onHide={() => {
          setAttendeeDetails({});
          setShowInviteAttendeeModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Participant information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={attendeeDetails.first_name}
                  placeholder="First Name"
                  name="first_name"
                  onChange={(e) => saveTextDataToStateHandler(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  value={attendeeDetails.last_name}
                  placeholder="Last name"
                  name="last_name"
                  onChange={(e) => saveTextDataToStateHandler(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={attendeeDetails.email_address}
                  name="email_address"
                  onChange={(e) => saveTextDataToStateHandler(e)}
                />
              </Form.Group>
              {attendeeCategoryList?.length > 0 && (
                <Form.Group className="col-md-12">
                  <Form.Label>Participant Group</Form.Label>
                  <Select
                    placeholder="Select Group"
                    options={attendeeCategoryList}
                    onChange={(e) => {
                      setAttendeeDetails((prevSpeakersdata) => {
                        return { ...prevSpeakersdata, category_id: e.value };
                      });
                    }}
                    className="w-100 small text-dark"
                  ></Select>
                </Form.Group>
              )}
            </div>
            <Button
              variant="primary"
              className="w-100"
              onClick={(e) => inviteAttendeeHandler(e, attendeeDetails)}
              disabled={saving}
            >
              {saving ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/*--------------------Invite Participant Modal Ends --------------------*/}

      {/* --------------------Email Participant Modal Starts-------------------- */}
      <Modal
        show={showEmailAttendeeModal}
        onHide={() => setShowEmailAttendeeModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-dark text-white">
          <Modal.Title className="h6 font-weight-bold">
            Email Participant
          </Modal.Title>
        </Modal.Header>
        <p
          className="mb-1 p-2 border-bottom"
          style={{ borderColor: "#515151" }}
        >
          <span className="small text-muted mr-2">To</span>{" "}
          {currentAttendeeDetails.firstName} {currentAttendeeDetails.lastName} (
          {currentAttendeeDetails.email})
        </p>
        <Modal.Body>
          <Form.Group className="mb-1">
            <Form.Label className="small text-muted font-weight-bold">
              Subject
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </Form.Group>

          <Form.Group className="my-1">
            <Form.Label className="small text-muted font-weight-bold">
              Message body
            </Form.Label>
            <CKEditor
              id="editor"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "bold",
                  "italic",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "undo",
                  "redo",
                ],
              }}
              data={messageBody ?? ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMessageBody(data);
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            />
          </Form.Group>

          <Alert show={discardShow} variant="danger">
            <Alert.Heading className="h6">Discard</Alert.Heading>
            <p className="small">Are you sure you want to discard?</p>
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                onClick={discardEmail}
                variant="outline-danger"
                className="mr-2"
              >
                Yes, discard
              </Button>
              <Button
                size="sm"
                onClick={() => setDiscardShow(false)}
                variant="outline-secondary"
              >
                No, cancel discard
              </Button>
            </div>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          {isThereAnyContent ? (
            <Button
              variant="secondary"
              onClick={() => setDiscardShow(true)}
              disabled={discardShow}
            >
              <span>
                Discard{" "}
                <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
              </span>
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={() => setShowEmailAttendeeModal(false)}
            >
              <span>Close</span>
            </Button>
          )}
          <Button variant="primary" onClick={submitEmail}>
            <span>
              Send{" "}
              <FontAwesomeIcon size="sm" icon={faPaperPlane} className="ml-1" />
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
      {/* --------------------Email Participant Modal Ends-------------------- */}
    </div>
  );
};
export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  currentEvent: state.currentEvent,
  eventId: state.eventId,
  darkTheme: true,
}))(Attendees);
