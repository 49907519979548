import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  ShoppingBag,
  MessageCircle,
  UserPlus,
  Layers,
  ShoppingCart,
  DollarSign,
  ArrowDown,
  ArrowUp,
} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faComments,
  faPen,
  faPodcast,
  faEye,
  faUserPlus,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { memo } from "react";

const BlogStats = () => {
  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faThumbsUp} size="3x" />
                  <h5 className="my-3">Likes</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={12} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={45} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={145} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faEye} size="3x" />
                  <h5 className="my-3">Visitors</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={8} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={74} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={189} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faUserPlus} size="3x" />
                  <h5 className="my-3">Followers</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={11} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={45} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={202} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faShare} size="3x" />
                  <h5 className="my-3">Shares</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={16} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={32} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={121} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="d-flex">
          <Button
            variant="link"
            className=" my-auto ml-auto"
            href="/allengagement"
          >
            View All
          </Button>
        </div>
      </Container>
    </>
  );
};

export default memo(BlogStats);
