import React from "react";
import { useSelector } from "react-redux";
import PrescriptionApp from "./Prescription/PrescriptionModal/PrescriptionApp";

const LetterHead = () => {
  const state = useSelector((state) => state);

  return (
    <div className="d-flex justify-content-center">
      <PrescriptionApp
        appointmentId={state.clinicId}
        clinicId={state.clinicId}
        clinicManager
        title="Letter Head"
      />
    </div>
  );
};

export default LetterHead;
