import React from "react";
import { connect } from "react-redux";
import EventNavbar from "./NavBar";
import EventLeftbar from "./LeftBar";
import { changeLanguage } from "../../../store/actions/actions";
import EventChat from "./EventChat";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";

function BBOSApps(props) {
    const { currentEvent } = props;
    const { id } = useParams();

    return (
        <div className="h-100 montserrat-font">
            <EventLeftbar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <EventNavbar />
                <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
                    <EventLeftbar />
                    <div
                        className="col-md-7 h-100 px-5 py-5"
                        style={{
                            overflow: "scroll",
                            backgroundSize: "cover",
                            backgroundColor: "#EFEFEF",
                            // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/boa-stage-background.jpg)`
                        }}
                    >
                        {/* Networking */}
                        <div className="container py-3">
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="shadow-sm p-3 rounded bg-white">
                                            <h3>
                                                BBOS
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/projects/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fpoll.png?alt=media&token=243777f5-70e5-489c-a826-bd6ffea857d4`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            Blue Ocean
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/as-is/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fas_is.png?alt=media&token=1f2dcaf7-3464-46d2-aaec-2c97a70f2e95`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            {props.currentEvent?.bbos_names?.as_is ?? "As Is Strategy"} Canvas
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/buyer-exp-audit/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fbuyer-exp.png?alt=media&token=d2ceda44-1b8c-490e-8a4e-9b554cd6bf92`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            {props.currentEvent?.bbos_names?.buyer_experience ?? "Buyer Experience Audit"}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/three-tiers/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fthree-tiers.png?alt=media&token=42ae5a56-3ba9-4f00-b113-91d652e34100`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            {props.currentEvent?.bbos_names?.three_tier ?? "Three Tiers of Non-Customers"}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/six-path/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fsix-path.png?alt=media&token=b5b50d3e-85dd-4346-a374-b262245a2a19`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            {props.currentEvent?.bbos_names?.six_path ?? "Six Path"} Template
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/errc-grid/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Ferrc-grid.png?alt=media&token=59ea1f08-be79-46b1-ae4e-70b3d39bd5c7`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            {props.currentEvent?.bbos_names?.errc ?? "Errc Grid"}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 mb-4 col-12" >
                                                <Link to={process.env.PUBLIC_URL + `/event/${id}/to-be/`}>
                                                    <div className="d-flex align-items-center border border-muted rounded">
                                                        <img
                                                            alt=""
                                                            src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fas_is.png?alt=media&token=1f2dcaf7-3464-46d2-aaec-2c97a70f2e95`}
                                                            width="60"
                                                            height="60"
                                                            className="mr-2 rounded"
                                                        />
                                                        <p className="mb-0">
                                                            {props.currentEvent?.bbos_names?.to_be ?? "To Be Strategy"} Canvas
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                            {/* <div className="col-md-6 mb-4 col-12" >
                                                <Link to ={process.env.PUBLIC_URL + "/event/bbos/errc_grid/"}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <img
                                                                alt=""
                                                                src={`${process.env.PUBLIC_URL}/assets/quiz.png`}
                                                                width="70"
                                                                height="70"
                                                                className="mr-4"
                                                            />
                                                        </div>
                                                        <div>
                                                            <h3 className="text-s-10 mb-0">
                                                                Errc Grid
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                    <div
                        className="col-md-4 h-100 px-0"
                        style={{ overflow: "scroll" }}
                    >
                        <EventChat event_id={currentEvent?.id} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        workspaceId: state.workspaceId,
        roomId: state.roomId,
        currentEvent: state.currentEvent,
    }),
    { changeLanguage }
)(BBOSApps);
