import React, { useEffect, useMemo, useState } from "react";
import firebase from "firebase";
import { Col, Container, Row, Nav } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faCircleNotch,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faKey,
  faLanguage,
  faLock,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUsers,
  faUsersCog,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import EventManagerNavbar from "./EventManagerNavbar";
// import EventNavbar from "../Navbar/Navbar";
import EventManagerLeftbar from "./EventManagerLeftbar";
import Overview from "./Overview";
import Tickets from "./Tickets";
import Speakers from "./Speakers";
import Schedule from "./Schedule";
import Sponsors from "./Sponsors";
import Booths from "./Booths";
import Attendees from "./Attendees";
import Stage from "./Stage";
import { useLocation } from "react-router";
import { connect, useSelector } from "react-redux";
import { setClinicDetails, setClinicID } from "../../../store/actions/actions";
import LetterHead from "./LetterHead";
import Documents from "./Documents";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import Sidebar from "../Sidebar/Index";

const ClinicManager = (props) => {
  const { clinicDetails } = props;
  const { clinicid } = useParams();
  const nav_data = useMemo(() => {
    return [
      {
        id: 1,
        name: "Overview",
        eventKey: "overview",
      },
      {
        id: 2,
        name: "Doctors",
        eventKey: "doctors",
      },
      {
        id: 3,
        name: "Patients",
        eventKey: "patients",
      },
      {
        id: 4,
        name: "Letter Head",
        eventKey: "letterHead",
      },
      {
        id: 5,
        name: "Documents",
        eventKey: "documents",
      },
    ];
  }, []);

  const [currentNavKey, setCurrentNavKey] = useState("overview");

  const { hash } = useLocation();
  useEffect(() => {
    nav_data.map((item, key) => {
      if (decodeURIComponent(hash) === `#${item.eventKey}`) {
        setCurrentNavKey(item.eventKey);
      }
      if (hash === "") {
        setCurrentNavKey("overview");
      }
    });
  }, [hash, nav_data]);

  const [clinicSnapshot, loadingClinic, errorClinic] = useDocument(
    firebase.firestore().collection("clinics").doc(clinicid)
  );

  const clinicData = clinicSnapshot?.data() ?? {};

  useEffect(() => {
    if (!loadingClinic) {
      props.setClinicDetails({ clinicData, id: clinicSnapshot?.id });
      props.setClinicID(clinicid);
    }
  }, [clinicSnapshot, loadingClinic]);

  return (
    <>
      <div className="d-flex vh-100" style={{ background: "#232626" }}>
        <Sidebar />
        <div className="overflow-auto max-vh-100 p-4 w-100" id="style-4">
          <EventManagerNavbar
            setCurrentNavKey={setCurrentNavKey}
            navData={nav_data}
          />
          {currentNavKey === "overview" && <Overview />}
          {currentNavKey === "doctors" && <Speakers />}
          {currentNavKey === "patients" && <Attendees />}
          {currentNavKey === "letterHead" && <LetterHead  clinicManager />}
          {currentNavKey === "documents" && <Documents />}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  clinicId: state.clinicId,
  clinicDetails: state.clinicDetails,
});

export default connect(mapStateToProps, { setClinicDetails, setClinicID })(
  ClinicManager
);
