import React, { useEffect, useState } from "react";
import { Button, Card, Spinner, FormCheck } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import {
  faCalendarAlt,
  faClipboardList,
  faComments,
  faAddressCard,
  faUsers,
  faEdit,
  faArrowLeft,
  faLink,
  faTasks,
  faCheckCircle,
  faAngleRight,
  faVideo,
  faFile,
  faEye,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import EventDetails from "./EventDetails";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth, firestore } from "../../../firebase";
import { postData } from "../../../utils";
import {
  ADMIN_GET_MODULES,
  API_BASE_URL,
  EVENT_SPEAKERS,
} from "../../../config";
import ListSections from "./ListSections";
import {PROD_HOSTED_BASE_URL} from "../../../config"
toast.configure();

const Overview = (props) => {
  // CONSTANTS
  const { eventId, currentEvent } = props;

  const [attendeesSnapshot, loading, error] = useCollection(
    firestore.collection(`/events/${eventId}/users`).orderBy("firstName")
  );

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firestore.collection("events").doc(eventId)
  );

  const eventDetails = eventSnapshot?.data() ?? {};
  const chatOn = eventSnapshot?.data().chat_enabled ?? false;
  const peopleEnabled = eventSnapshot?.data()?.people_enabled ?? false;

  // STATES
  const [ingEventDetails, setingEventDetails] = useState(false);
  const [fetchingModules, setFetchingModules] = useState(false);
  const [modules, setModules] = useState([]);
  const [allSpeakers, setAllSpeakers] = useState(null);
  const [publishedSpeakers, setPublishedSpeakers] = useState(null);
  const [fetchingSpeakers, setFetchingSpeakers] = useState(false);

  // FUNCTIONS
  const handleFetchModules = async () => {
    setFetchingModules(true);
    try {
      const response = await postData(`${API_BASE_URL}${ADMIN_GET_MODULES}`, {
        event_id: eventId,
        user_id: auth?.currentUser?.uid,
      });

      if (response.status === 200) {
        const { data } = response;

        setModules(data);
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  const handleFetchSpeakers = async () => {
    setFetchingSpeakers(true);

    // Fetch: Speakers
    const response_speakers = await postData(
      `${API_BASE_URL}${EVENT_SPEAKERS}`,
      { event_id: eventId }
    );

    if (response_speakers.status == 200) {
      const { data } = response_speakers;
      const published_speakers = data.participantList?.filter(
        (speaker) => speaker.is_published
      );

      setAllSpeakers(data.participantList);
      setPublishedSpeakers(published_speakers);
      setFetchingSpeakers(false);
    }
  };

  useEffect(() => {
    let unsubscribe_modules = firestore
      .collection("modules")
      .where("parent_id", "==", eventId)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    let unsubscribe_submodules = firestore
      .collection("subModules")
      .where("ancestor_id", "==", eventId)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    return () => {
      unsubscribe_modules();
      unsubscribe_submodules();
    };
  }, []);

  useEffect(() => {
    if (!loadingEvent) {
      firestore.collection("events").doc(eventId).update({
        chat_enabled: chatOn,
      });
    }
  }, [loadingEvent]);

  useEffect(() => {
    if (eventId) {
      handleFetchSpeakers();
    }
  }, []);

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  const SubModuleIcon = ({ type, className }) => {
    const icon =
      type === "schedule"
        ? faCalendarAlt
        : type === "assignment"
        ? faClipboardList
        : type === "quiz"
        ? faTasks
        : type === "lecture"
        ? faVideo
        : faFile;
    return <FontAwesomeIcon icon={icon} className={className} />;
  };

  return (
    <div className="container-fluid vh-100" style={{ overflow: "scroll" }}>
      {!ingEventDetails ? (
        <div className="text-light mt-3">
          <div className="row">
            <div className="col-md-5 offset-md-2">
              {/* Overview Card */}

              <h3 className="card-title">
                {eventDetails?.event_details?.EN?.event_name}
              </h3>
              <div className="card-text">
                {parse(`${eventDetails?.event_details?.EN?.small_description}`)}
              </div>

              <Card
                style={{ width: "100%" }}
                className="shadow-sm bg-dark shadow-sm mb-3"
              >
                <img
                  src={eventDetails?.event_details?.featured_image}
                  className="card-img-bottom"
                  alt="..."
                />
              </Card>

              <div className="mb-3">
                <a
                  className="btn btn-sm btn-primary mr-2"
                  href={`https://${PROD_HOSTED_BASE_URL}/event/${eventDetails?.slug}`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faEye} className="mr-1" />
                  <span>Preview Registration Page</span>
                </a>
                <CopyToClipboard
                  text={`https://${PROD_HOSTED_BASE_URL}/event/${eventDetails?.slug}`}
                  onCopy={() => {
                    toast.dark("Registration link copied!", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }}
                >
                  <Button className="mr-2" size="sm">
                    <FontAwesomeIcon icon={faLink} className="mr-1" />
                    <span>Copy Registration Link</span>
                  </Button>
                </CopyToClipboard>
              </div>

              {/* What you'll learn: Course Only */}

              {eventDetails?.type === 1 && (
                <ListSections
                  name="What you'll learn"
                  category="learnings"
                  parent_id={eventId}
                  parent_type={eventDetails?.type}
                />
              )}

              {/* Requirements: Course Only */}

              {eventDetails?.type === 1 && (
                <ListSections
                  name="Requirements"
                  category="requirements"
                  parent_id={eventId}
                  parent_type={eventDetails?.type}
                />
              )}

              {/* Who this course is for: Course Only */}

              {eventDetails?.type === 1 && (
                <ListSections
                  name="Who this course is for"
                  category="audience"
                  parent_id={eventId}
                  parent_type={eventDetails?.type}
                />
              )}

              {/* Description */}

              <Card
                style={{ width: "100%" }}
                className="shadow-sm bg-dark  shadow-sm mb-3"
              >
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <h5 className="card-title">Description</h5>
                    <FontAwesomeIcon
                      className="pointer text-muted"
                      icon={faPencilAlt}
                      onClick={() => setingEventDetails(true)}
                    />
                  </div>
                  <div className="card-text">
                    {parse(`${eventDetails?.event_details?.EN?.description}`)}
                  </div>
                </Card.Body>
              </Card>

              {/* Section: Course Modules (For Courses only)  */}
              {eventDetails?.type === 1 && (
                <div className="">
                  <h5 className="mb-3">Course Content</h5>
                  <Card
                    style={{ width: "100%" }}
                    className="shadow-sm bg-dark shadow-sm mb-3"
                  >
                    {fetchingModules ? (
                      <p>Loading modules...</p>
                    ) : (
                      modules?.map((module, key) => (
                        <div key={key}>
                          <Card.Header>
                            {/* {key + 1}.  */}
                            <p className="mb-0 font-weight-bold">
                              {module.name}
                            </p>
                          </Card.Header>
                          <Card.Body>
                            {module?.sub_modules?.map((sub_module, idx) => (
                              <p className="small" key={idx}>
                                {/* {key + 1}.{idx + 1}.{" "} */}
                                <SubModuleIcon
                                  type={sub_module?.type}
                                  className="mx-2"
                                />{" "}
                                {sub_module?.title}
                              </p>
                            ))}
                          </Card.Body>
                        </div>
                      ))
                    )}
                  </Card>
                </div>
              )}

              {/* Instructors */}

              {eventDetails?.type === 1 && (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-3">Instructors</h5>
                    </div>
                  </div>
                  <div>
                    {fetchingSpeakers ? (
                      <p className="text-center">Fetching Instructors...</p>
                    ) : (
                      publishedSpeakers?.map((speaker, idx) => {
                        const item = speaker;
                        return (
                          <div
                            className="d-flex bg-dark mb-3 p-3 rounded"
                            key={idx}
                          >
                            <div>
                              <img
                                src={`${item.img_src}`}
                                width="50"
                                height="50"
                                className="mr-3 rounded-circle"
                              />
                            </div>
                            <div>
                              <h6 className="mb-0">
                                {item.first_name} {item.last_name}
                              </h6>
                              <p className="small mb-0 text-light">
                                {item.designation}
                              </p>
                              <p className="small mb-0 font-weight-bold">
                                {item.organisation}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-3 offset-md-1">
              {/* Section: Course setup checklist (Course only) */}
              {eventDetails?.type === 1 && (
                <React.Fragment>
                  <h5>Course setup checklist</h5>
                  <ul className="list-group mb-3">
                    <a
                      className="list-group-item bg-dark d-flex justify-content-between pointer text-decoration-none text-light "
                      onClick={() => setingEventDetails(true)}
                    >
                      Manage your course description
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="align-items-center"
                      />
                    </a>
                    <a
                      className="list-group-item bg-dark d-flex justify-content-between text-decoration-none text-light pointer"
                      href={`/admin/eventmanager/${currentEvent?.id}/course-builder`}
                    >
                      Add course content
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="align-items-center"
                      />
                    </a>
                    <a
                      className="list-group-item bg-dark d-flex justify-content-between text-decoration-none text-light pointer"
                      href={`/admin/eventmanager/${currentEvent?.id}/schedules`}
                    >
                      Add live sessions
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="align-items-center"
                      />
                    </a>
                    <a
                      className="list-group-item bg-dark d-flex justify-content-between text-decoration-none text-light pointer"
                      href={`/admin/eventmanager/${currentEvent?.id}/registration`}
                    >
                      How do students register?
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="align-items-center"
                      />
                    </a>
                    <a
                      className="list-group-item bg-dark d-flex justify-content-between text-decoration-none text-light pointer"
                      href={`/admin/eventmanager/${currentEvent?.id}/pages`}
                    >
                      Add bonus content
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="align-items-center"
                      />
                    </a>
                  </ul>
                </React.Fragment>
              )}

              <Card
                style={{ width: "100%" }}
                className="shadow-sm bg-dark shadow-sm mb-3"
              >
                <Card.Body>
                  <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                    {/* <div>
                      <img
                        alt="event-thumbnail"
                        width="110px"
                        src={eventDetails?.event_details?.featured_image}
                        className="img-fluid rounded d-block d-md-inline mr-3"
                      ></img>
                      <span>{eventDetails?.event_details?.EN?.event_name}</span>
                    </div> */}
                    <h5>Settings</h5>
                  </div>
                  <hr />
                  <div className="row">
                    {eventDetails?.event_details?.start_date && (
                      <div className="d-flex align-items-center mb-3 col-md-12">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="mx-4 btn-hero"
                        />
                        <div className="d-flex flex-column">
                          <span className="small font-weight-bold">DATE</span>
                          <span>
                            {moment(
                              convertSecondstoMilliSeconds(
                                eventDetails?.event_details?.start_date?.seconds
                              )
                            ).format("DD MMMM YYYY")}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="d-flex align-items-center mb-3 col-md-12">
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mx-4  btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="small font-weight-bold">
                          PARTICIPANTS
                        </span>
                        <span>
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            attendeesSnapshot.docs &&
                            attendeesSnapshot.docs.length
                          )}{" "}
                          registrations
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-3 col-md-12">
                      <FontAwesomeIcon
                        icon={faComments}
                        className="mx-4  btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="small font-weight-bold">
                          GROUP CHAT
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch"
                            label={chatOn ? "On" : "Off"}
                            checked={chatOn}
                            onChange={(e) =>
                              firestore
                                .collection("events")
                                .doc(eventId)
                                .update({
                                  chat_enabled: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-3 col-md-12">
                      <FontAwesomeIcon
                        icon={faUsers}
                        className="mx-4  btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="small font-weight-bold">
                          NETWORKING
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch-people"
                            label={peopleEnabled ? "On" : "Off"}
                            checked={peopleEnabled}
                            onChange={(e) =>
                              firestore
                                .collection("events")
                                .doc(eventId)
                                .update({
                                  people_enabled: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-3 col-md-12">
                      <FontAwesomeIcon
                        icon={faAddressCard}
                        className="mx-4  btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="small font-weight-bold text-uppercase">
                          Published
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="isPublished"
                            label={
                              eventSnapshot?.data()?.is_published ? "On" : "Off"
                            }
                            //disabled={true}
                            checked={
                              eventSnapshot?.data()?.is_published ? true : false
                            }
                            onChange={(e) =>
                              firestore
                                .collection("events")
                                .doc(eventId)
                                .update({
                                  is_published: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {/* <h5 className="mb-3">Students</h5>

              <h5 className="mb-3">Live Sessions</h5> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3">
          <Button onClick={() => setingEventDetails(false)} className="btn-sm">
            <FontAwesomeIcon icon={faArrowLeft} /> Overview
          </Button>
          <EventDetails />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});
export default connect(mapStateToProps)(Overview);
