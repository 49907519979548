import { memo, useMemo, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
const QuizView = (props) => {
  const { admin, quizId } = props;

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [quizSnapshot, loading, error] = useDocument(
    firebase.firestore().collection("quiz").doc(quizId)
  );

  const quizData = useMemo(() => {
    return {
      ...quizSnapshot?.data(),
    };
  }, [quizSnapshot]);

  if (loading) {
    return <></>;
  }
  return (
    <>
      <Form className="quiz" style={{ minWidth: "300px" }}>
        <div className="d-flex">
          <h5 className="mt-0 flex-grow-1">{quizData.quizName}</h5>
          <div>
            <span className="text-success p">Active</span>
          </div>
        </div>
        <p className="mt-0 mb-1">
          Total questions: {Object.keys(quizData.quizQuestions).length}
        </p>
        {admin &&
          Object.keys(quizData.quizQuestions).length > 0 &&
          Object.keys(quizData.quizQuestions)
            .sort(
              (a, b) =>
                quizData.quizQuestions[a].sequenceNo -
                quizData.quizQuestions[b].sequenceNo
            )
            .map((item, key) => (
              <>
                <p className="my-2" key={key}>
                  <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                  {quizData.quizQuestions[item].question}
                </p>
              </>
            ))}

        <>
          {quizData &&
            Object.keys(quizData.quizQuestions)
              .sort(
                (a, b) =>
                  quizData.quizQuestions[a].sequenceNo -
                  quizData.quizQuestions[b].sequenceNo
              )
              .map(
                (q, k) =>
                  currentQuestion === k && (
                    <Card className=" border-0 rounded shadow-sm" key={k}>
                      <Card.Header className="bg-dark d-flex">
                        <p className="text-light flex-grow-1">
                          Question {k + 1} <br />
                          {quizData.quizQuestions[q].weight
                            ? quizData.quizQuestions[q].weight > 1
                              ? `${quizData.quizQuestions[q].weight} points`
                              : `${quizData.quizQuestions[q].weight} point`
                            : ""}
                        </p>
                        <div className="d-flex">
                          {currentQuestion !== 0 && (
                            <Button
                              size="sm"
                              variant="light"
                              style={{
                                width: "100px",
                                marginRight: "5px",
                              }}
                              onClick={() =>
                                setCurrentQuestion((prev) => prev - 1)
                              }
                            >
                              <p>Previous</p>
                            </Button>
                          )}

                          {currentQuestion !==
                            Object.keys(quizData.quizQuestions).length - 1 && (
                            <Button
                              size="sm"
                              variant="dark"
                              style={{
                                width: "100px",
                                marginLeft: "5px",
                              }}
                              onClick={() =>
                                setCurrentQuestion((prev) => prev + 1)
                              }
                            >
                              <p>Next</p>
                            </Button>
                          )}
                        </div>
                      </Card.Header>
                      <Card.Body className="bg-dark text-light">
                        <p className="text-light">
                          {quizData.quizQuestions[q].question}
                        </p>
                        <div>
                          {quizData.quizQuestions[q].questionImage && (
                            <>
                              <img
                                className="img-fluid mb-2"
                                alt=""
                                style={{ height: 100, width: "auto" }}
                                src={quizData.quizQuestions[q].questionImage}
                              />
                            </>
                          )}
                        </div>
                        {Object.keys(quizData.quizQuestions[q].options)
                          .sort(
                            (a, b) =>
                              quizData.quizQuestions[q].options[a].sequenceNo -
                              quizData.quizQuestions[q].options[b].sequenceNo
                          )
                          .map((o, key) => (
                            <>
                              {quizData.quizQuestions[q].options[o]
                                .optionImage == null ? null : (
                                <img
                                  src={
                                    quizData.quizQuestions[q].options[o]
                                      .optionImage
                                  }
                                  style={{ width: "100px" }}
                                  alt=""
                                />
                              )}
                              <div
                                className={
                                  "form-check rounded mb-2 border-dark"
                                }
                                key={key}
                              >
                                <input
                                  className="form-check-input"
                                  type={
                                    quizData.quizQuestions[q].type === 0
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  value={
                                    quizData.quizQuestions[q].options[o]
                                      .optionValue
                                  }
                                  name={
                                    quizData.createdBy +
                                    "_" +
                                    quizData.quizQuestions[q].options[o]
                                      .optionValue +
                                    k +
                                    key
                                  }
                                  id={
                                    quizData.createdBy +
                                    "_" +
                                    k +
                                    "_" +
                                    key +
                                    "_" +
                                    q
                                  }
                                />

                                <label
                                  className="form-check-label"
                                  htmlFor={
                                    quizData.createdBy +
                                    "_" +
                                    k +
                                    "_" +
                                    key +
                                    "_" +
                                    q
                                  }
                                >
                                  {
                                    quizData.quizQuestions[q].options[o]
                                      .optionValue
                                  }
                                </label>
                              </div>
                            </>
                          ))}
                      </Card.Body>
                    </Card>
                  )
              )}
        </>
      </Form>
    </>
  );
};
export default memo(QuizView);
