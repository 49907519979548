import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import EventChat from "./chat";
import { useParams } from "react-router";
import { connect } from "react-redux";
import parse from "html-react-parser";

function BoothDescription({currentEvent}) {

  const { booth_id, } = useParams();

  console.log(booth_id, currentEvent.id)

  const [booth, loading, error] = useDocument(
    firebase
      .firestore()
      .collection(`events/${currentEvent.id}/booths`)
      .doc(`${booth_id}`)
  );

  console.log(booth?.data())

  return (
    <div className="h-100">
      <LeftBar mobile />

      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5"
            style={{ overflow: "scroll", backgroundColor: "#F7F7F7" }}
          >
            {/* BoothName */}
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="d-flex mb-3">
                  <img
                    src={booth?.data()?.company_logo}
                    height="40"
                    className="rounded-circle mr-3"
                    alt="company_logo"
                  />
                  <h3>{booth?.data()?.booth_name}</h3>
                </div>
                <a
                  href={booth?.data()?.meet_link}
                  target="_blank"
                  type="button"
                  class="btn btn-outline-primary btn-sm mr-2"
                >
                  Meet Now
                </a>
                <a
                  href={booth?.data()?.brochure}
                  type="button"
                  class="btn btn-outline-primary btn-sm mr-2"
                >
                  Download Brochure
                </a>
              </div>
              <div className="col-md-12 mb-3">
                <img
                  className="mb-3"
                  width="100%"
                  height="320"
                  src={booth?.data()?.featured_image}
                  alt="featured_image"
                />
                <p className="rounded-12 bg-white p-3 mb-3">
                  {booth?.data()?.small_description}
                </p>
                <p className="rounded-12 bg-white p-3 mb-3">
                  {booth?.data()?.description ? parse(booth?.data()?.description) : ''}
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 h-100 pt-3 px-0 d-flex flex-column justify-content-between"
            style={{ overflow: "scroll" }}
          >
            
            
            <EventChat/>
          </div>
        </div>
      </div>
    </div>
  );
}


export default connect(
  (state) => ({ currentEvent: state.currentEvent }),
  null
)(BoothDescription);