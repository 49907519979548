import React, { memo, useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  ShoppingBag,
  MessageCircle,
  UserPlus,
  Layers,
  Clipboard,
  DollarSign,
  ArrowDown,
  ArrowUp,
  Home,
  Book,
} from "react-feather";
import CountUp from "react-countup";
import Mdata from "./Data/MonthlyData.json";

const QuickUpdateCards = (props) => {
  const [data, setData] = useState(props.date);

  useEffect(() => {
    setData(props.date);
  }, [props]);

  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        {/* <h5>This month's stats</h5> */}
        <Row>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden  ">
              <Card.Body className="changecol b-r-4 card-body ">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <DollarSign />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Earnings</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.revenue ?? 2000 } />
                      {".56"}
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <div className="changecol b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <ShoppingBag />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Events</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.events ?? 20 } />
                    </h4>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <div className="changecol b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Book />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Courses</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.courses ?? 10} />
                    </h4>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <div className="changecol b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Clipboard />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Consultants</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.consultants ?? 12} />
                    </h4>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <div className="changecol b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Home />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Rooms</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.rooms ?? 30} />
                    </h4>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <Card.Body className="changecol b-r-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <MessageCircle />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Messages</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.messages ?? 20} />
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <Card.Body className="changecol b-r-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserPlus />
                  </div>
                  <div className="media-body">
                    <span className="m-0">New Users</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.newuser ?? 40} />
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="my-3" sm="6" xl="3" lg="6">
            <Card className="o-hidden">
              <Card.Body className="changecol b-r-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserPlus />
                  </div>
                  <div className="media-body">
                    <span className="m-0">Engagements</span>
                    <h4 className="mb-0 counter">
                      <CountUp end={Mdata[`${data}`]?.overview?.networking ?? 12} />
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(QuickUpdateCards);
