import React, { Component } from "react";
import { SketchPicker } from "react-color";
import OutsideAlerter from "./OutsideAlerter";
import GraffitiBoard from "./graffitBoard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTimes,
  faPencilAlt,
  faImage,
  faEraser,
  faUndo,
  faRedo,
  faFont,
  faImages,
  faSquareFull,
  faLayerGroup,
  faFolderOpen,
  faUpload,
  faEllipsisV,
  faTrash,
  faEdit,
  faFolderPlus,
  faFolder,
  faArrowRight,
  faPaintBrush,
  faEye,
  faEyeSlash,
  faCog,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare, faCircle } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import {
  saveWhiteboardLayers,
  saveWhiteboardBackground,
  setShowWhiteboard,
} from "../../../store/actions/actions";
import firebase from "firebase";
import mime from "mime-types";
import { Form, Modal, Spinner } from "react-bootstrap";
import canvasToImage from "canvas-to-image";
import html2canvas from "html2canvas";

import "./artboard.css";
import swal from "sweetalert";

const styles = {
  artBoardWrapper: {
    height: 595,
    width: 842,
    margin: "0 auto",
    marginTop: 0,
    marginBottom: 0,
    // border: 'solid 1px rgb(106, 106, 106)',
    borderRadius: "5px",
    backgroundColor: "#fff",
    position: "absolute",
    zIndex: "550",
  },
  controlPanelWrapper: {
    height: 50,
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ddd",
    borderRadius: "5px 5px 0 0",
    border: "none",
    padding: "5px 0",
    boxSizing: "border-box",

    controlItem: {
      position: "relative",
      height: "32px",
      width: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgb(50, 50, 50)",
      backgroundColor: "transparent",
      border: "1px outset",
      cursor: "pointer",
      outline: "none",
      margin: 5,
      borderRadius: 5,
    },
    controlItemSelected: {
      color: "rgb(244, 244, 244)",
      backgroundColor: "rgb(62, 62, 62)",
      stroke: "#fff",
    },
  },
};

class ArtBoard extends Component {
  constructor(props) {
    super();
    this.state = {
      roomID: props.roomID ? props.roomID : "roomID",
      workspaceID: props.currentWorkspace
        ? props.currentWorkspace
        : "workspaceID",
      resultBoard: null,
      boardWidth: 842,
      boardHeight: 545,
      selectedTool: "PENCILE",
      layers: [],
      showPicker: false,
      redoLayers: [],
      backgroundImage: {},
      authorized: ["image/jpeg", "image/png", "image/svg+xml", "image/gif"],
      uploadTask: null,
      uploadState: "",
      percentUploaded: 0,
      errors: "",
      selectedLayer: "",
      IsMoving: false,
      ShowFileManager: false,
      ImageCollection: [],
      folderCollection: [],
      selectedFolder: "",
      loadingCollection: true,
      showLayers: false,
      reversLayers: [],
      showSettingModal: false,
      settingDisplay: "Pen",
      showQuickColorPicker: {},
      QuickColors: ["#00416d", "#fddb3a"],
      penColor: "#dc3545",
      defaultPenColor: "#dc3545",
      lineWidth: 1,
      defaultLineWidth: 1,
      textFont: "Arial",
      isLoading: true,
    };
  }

  addFile = (event) => {
    if (!event.target.files) return;
    const backgroundImage = event.target.files[0];
    if (backgroundImage) {
      this.setState(
        {
          backgroundImage,
        },
        () => {
          this.sendFile();
        }
      );
    }
  };

  sendFile = () => {
    const { backgroundImage } = this.state;

    console.log("file recieved to send");
    if (backgroundImage !== null) {
      console.log("file checked");
      if (this.isAuthorized(backgroundImage.name)) {
        const metadata = { contentType: mime.lookup(backgroundImage.name) };
        this.uploadFile(backgroundImage, metadata);
        this.clearFile();
      }
    }
  };

  isAuthorized = (filename) =>
    this.state.authorized.includes(mime.lookup(filename));

  clearFile = () => this.setState({ backgroundImage: null });

  uploadFile = (file, metadata) => {
    const { roomID, workspaceID } = this.state;
    const filePath = `${roomID}/${file.name}`;

    this.setState(
      {
        uploadState: "uploading",
        uploadTask: firebase
          .storage()
          .ref()
          .child("whiteboard")
          .child(filePath)
          .put(file, metadata),
      },
      () => {
        this.state.uploadTask.on(
          "state_changed",
          (snap) => {
            const percentUploaded = Math.round(
              (snap.bytesTransferred / snap.totalBytes) * 100
            );
            console.log(percentUploaded);
            this.setState({ percentUploaded });
          },
          (err) => {
            console.error(err);
            this.setState({
              errors: this.state.errors.concat(err),
              uploadState: "error",
              uploadTask: null,
            });
          },
          () => {
            this.state.uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadUrl) => {
                console.log(downloadUrl);
                firebase
                  .firestore()
                  .collection(`workspaces`)
                  .doc(workspaceID)
                  .collection("rooms")
                  .doc(roomID).collection("whiteboards").doc("0")
                  .collection("whiteboardSetting")
                  .doc("background")
                  .set({
                    background: downloadUrl,
                  })
                  .catch((err) => console.log(err));
              })
              .then(() =>
                this.setState({ uploadState: "", percentUploaded: "0" })
              )
              .catch((err) => {
                console.error(err);
                this.setState({
                  errors: this.state.errors.concat(err),
                  uploadState: "error",
                  uploadTask: null,
                });
              });
          }
        );
      }
    );
  };

  createObjectURL = (file) => {
    if (window.webkitURL) {
      return window.webkitURL.createObjectURL(file);
    } else if (window.URL && window.URL.createObjectURL) {
      return window.URL.createObjectURL(file);
    } else {
      return null;
    }
  };

  componentDidMount() {
    if(!this.props.currentUser) {
      this.props.setShowWhiteboard(false);
      return;
    }
    const { roomID, workspaceID, resultBoard } = this.state;
    this.setState({ resultBoard: document.querySelector("#resultBoard") });

    // -------------------------------------- User collection data (Images and folders)-----------------------
    firebase
      .firestore()
      .collection("Whiteboard")
      .doc(this.props.currentUser.uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          this.setState(
            {
              ImageCollection: data.images
                .reverse()
                .filter(
                  (image) => image.folderName === this.state.selectedFolder
                ),
              folderCollection: data.Folders.reverse(),
            },
            () => this.setState({ loadingCollection: false })
          );
        } else {
          this.setState({ loadingCollection: false });
        }
      });

    // ---------------------------- user's whiteboard setting -----------------------------
    firebase
      .firestore()
      .collection("Whiteboard")
      .doc(this.props.currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ ...data.whiteboardSetting }, () =>
            this.setState({ isLoading: false })
          );
          if (data.whiteboardSetting.defaultLineWidth) {
            this.setState({
              lineWidth: data.whiteboardSetting.defaultLineWidth,
            });
          }
          if (data.whiteboardSetting.defaultPenColor) {
            this.setState({ penColor: data.whiteboardSetting.defaultPenColor });
          }
        } else {
          this.setState({ isLoading: false });
        }
      });

    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboardSetting")
      .doc("background")
      .onSnapshot((doc) => {
        console.log("Background image", doc);
        if (doc.exists) {
          const backgroundImage = doc.data().background;
          this.props.saveWhiteboardBackground(backgroundImage);
        }
      });

    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard")
      .orderBy("created_at", "asc")
      .onSnapshot((allLayersData) => {
        // console.log("Whiteboard room", allLayersData.docChanges());
        if (!allLayersData.empty) {
          let layers = this.state.layers;
					let reversLayers = []
          allLayersData.docChanges().map((layer) => {
            if (layer.type === "added") {
              const newLayer = { ...layer.doc.data(), id: layer.doc.id };
              console.log(newLayer);
              console.log(layers);
							layers.push(newLayer);
              this.drawLayer(newLayer);
            } else if (layer.type === "removed") {
							let filteredLayers = layers.filter((arr) => arr.id !== layer.doc.id);
							layers = [...filteredLayers]
							console.log('filtered layers', filteredLayers);
              this.setState({ layers: filteredLayers }, () => {
                this.clearBoard();
                this.drawResult();
              });
            }
          });
					// console.log("Stored reverse layer array",reversLayers)
					reversLayers = [...layers]
          this.props.saveWhiteboardLayers(layers);
          this.setState(
            { layers: layers, reversLayers: reversLayers.reverse() },
            () => console.log(layers)
          );
          // this.props.saveWhiteboardBackground(data.background);
          // console.log(document.querySelector('#resultBoard'))
        } else {
          this.setState(
            { resultBoard: document.querySelector("#resultBoard") },
            () => {
              this.props.saveWhiteboardLayers([]);
              this.setState({ layers: [], reversLayers: [] }, () => {
                //  console.log('ALL layers in state', this.state.layers)
                this.clearBoard();
                this.drawResult();
              });
            }
          );
        }
      });
  }

  settingUpdate = () => {
    const {
      QuickColors,
      defaultPenColor,
      defaultLineWidth,
      textFont,
    } = this.state;
    firebase
      .firestore()
      .collection("Whiteboard")
      .doc(this.props.currentUser.uid)
      .update({
        whiteboardSetting: {
          QuickColors,
          defaultPenColor,
          defaultLineWidth,
          textFont,
        },
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState === this.state) return;
    const {
      QuickColors,
      defaultPenColor,
      defaultLineWidth,
      textFont,
    } = this.state;

    if (QuickColors !== prevState.QuickColors) {
      this.settingUpdate();
    }

    if (defaultPenColor !== prevState.defaultPenColor) {
      this.settingUpdate();
    }
    if (defaultLineWidth !== prevState.defaultLineWidth) {
      this.settingUpdate();
    }
    if (textFont !== prevState.textFont) {
      this.settingUpdate();
    }

    if (prevState.selectedFolder !== this.state.selectedFolder) {
      this.setState({ loadingCollection: true });
      firebase
        .firestore()
        .collection("Whiteboard")
        .doc(this.props.currentUser.uid)
        .onSnapshot((doc) => {
          this.setState(
            {
              ImageCollection: doc
                .data()
                .images.reverse()
                .filter(
                  (image) => image.folderName === this.state.selectedFolder
                ),
              folderCollection: doc.data().Folders.reverse(),
            },
            () => this.setState({ loadingCollection: false })
          );
        });
    }
  }

  setTool = (tool) => (e) => {
    e.preventDefault();
    this.setState({
      selectedTool: tool,
    });
  };

  saveLayer = (layer) => {
    this.setState({ redoLayers: [] });
    const { roomID, workspaceID } = this.state;
    const updateRef = firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard");

    // console.log(updateRef);

    updateRef
      .add({
        ...layer,
        created_at: new Date(),
      })
      .then(() => this.drawResult());
  };

  deleteLayer = (layerId) => {
    console.log("delete ID", layerId);
    const { roomID, workspaceID } = this.state;
    // console.log("undo layer",undoLayer)
    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard")
      .doc(layerId)
      .delete();
  };

  undoLayer = (e) => {
    e.preventDefault();
    var layers = this.state.layers;
    var { redoLayers, roomID } = this.state;

    if (layers.length) {
      const undoLayer = layers.pop();
      console.log(undoLayer);
      redoLayers.push(undoLayer);
      this.setState({ redoLayers: redoLayers });
      this.deleteLayer(undoLayer.id);
    }
  };

  redoLayer = (e) => {
    e.preventDefault();
    var redoLayers = this.state.redoLayers;
    if (redoLayers.length > 0) {
      this.saveLayer(redoLayers[redoLayers.length - 1]);
      redoLayers.pop(redoLayers.length - 1);
      this.setState({ redoLayers: redoLayers });
    }
  };

  clearBoard = () => {
    const { resultBoard } = this.state;
    if (!resultBoard) return;
    resultBoard
      .getContext("2d")
      .clearRect(0, 0, resultBoard.width, resultBoard.height);

    document
      .querySelector("#imageBoard")
      .getContext("2d")
      .clearRect(0, 0, resultBoard.width, resultBoard.height);
  };

  clearResult = () => {
    const { roomID, workspaceID, layers } = this.state;

    this.clearBackground();
    const Ref = firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard");
    // console.log(layers);

    layers.forEach((layer, idx) => {
      console.log(layer);
      Ref.doc(layer.id).delete();
    });
    this.setState({ redoLayers: [], layers: [] });
    this.clearBoard();
  };

  drawPath = ({
    eraser,
    paths = [],
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    paths.forEach((point, idx) => {
      stroke.lineCap = "round";
      if (idx === 0) {
        stroke.beginPath();
        // stroke.lineWidth = 20;
        stroke.moveTo(point.x, point.y);
      } else {
        // stroke.lineWidth = 20;
        stroke.lineTo(point.x, point.y);
        stroke.stroke();
      }
    });
  };
  drawBrush = ({
    color,
    lineWidth,
    paths = [],
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    paths.forEach((point, idx) => {
      stroke.lineCap = "round";
      if (idx === 0) {
        stroke.lineJoin = stroke.lineCap = "round";
        stroke.shadowBlur = lineWidth * 2;
        stroke.shadowColor = color;

        stroke.beginPath();
        stroke.moveTo(point.x, point.y);
      } else {
        stroke.shadowBlur = lineWidth * 2;
        stroke.lineTo(point.x, point.y);
        stroke.stroke();
        stroke.shadowBlur = 0;
      }
    });
  };

  drawLine = ({
    path,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    stroke.lineCap = "round";
    stroke.beginPath();
    stroke.moveTo(path.p, path.q);
    stroke.lineTo(path.x, path.y);
    stroke.stroke();
  };

  drwaArrow = ({
    path,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    var angle = Math.atan2(path.y - path.q, path.x - path.p);
    stroke.beginPath();
    stroke.moveTo(path.p, path.q);
    stroke.lineTo(path.x, path.y);
    stroke.moveTo(path.x, path.y);
    stroke.lineTo(
      path.x - path.headlen * Math.cos(angle - Math.PI / 6),
      path.y - path.headlen * Math.sin(angle - Math.PI / 6)
    );
    stroke.moveTo(path.x, path.y);
    stroke.lineTo(
      path.x - path.headlen * Math.cos(angle + Math.PI / 6),
      path.y - path.headlen * Math.sin(angle + Math.PI / 6)
    );
    stroke.lineCap = "round";
    stroke.stroke();
  };

  drawText = ({
    text,
    font,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    if (text && text.x && text.y && text.text && text.fontSize && text.color) {
      stroke.beginPath();
      stroke.fillStyle = text.color;
      stroke.font = `${(text.fontSize + 1) * 6}px ${font?font: "Arial"}`;
      stroke.fillText(text.text, text.x, text.y);
      stroke.stroke();
    }
  };

  drawRect = ({
    rect = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    if (rect && "x" in rect && "y" in rect && "w" in rect && "h" in rect) {
      stroke.strokeRect(rect.x, rect.y, rect.w, rect.h);
    }
  };
  drawFillRect = ({
    path,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    if (
      path &&
      "x" in path &&
      "y" in path &&
      "w" in path &&
      "h" in path &&
      "color" in path
    ) {
      stroke.fillStyle = path.color;
      stroke.fillRect(path.x, path.y, path.w, path.h);
    }
  };

  drawCircle = ({
    circle,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    if (circle && "x" in circle && "y" in circle && "r" in circle) {
      stroke.beginPath();
      stroke.arc(circle.x, circle.y, circle.r, 0, 2 * Math.PI);
      stroke.stroke();
    }
  };

  drawCurve = ({
    path,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    if (path && "x" in path && "y" in path && "p" in path && "q" in path) {
      stroke.beginPath();
      stroke.moveTo(path.p, path.q);
      stroke.bezierCurveTo(
        path.p + (path.x - path.p) / 3,
        path.q + (path.x - path.p) / 2.5,
        path.p + ((path.x - path.p) * 2) / 3,
        path.q + (path.x - path.p) / 2.5,
        path.x,
        path.y
      );
      stroke.stroke();
    }
  };

  drawTriangle = ({
    path,
    stroke = document.querySelector("#resultBoard").getContext("2d"),
  }) => {
    if (
      path &&
      "x" in path &&
      "y" in path &&
      "p" in path &&
      "q" in path &&
      "z" in path
    ) {
      stroke.beginPath();
      stroke.moveTo(path.x, path.q);
      stroke.lineTo(path.z, path.y);
      stroke.lineTo(path.p, path.q);
      stroke.closePath();
      stroke.stroke();
    }
  };

  DrawImage = ({
    path,
    stroke = document.querySelector("#imageBoard").getContext("2d"),
    url = "",
  }) => {
    const image = new Image(); // Using optional size for image
    image.onload = drawImageActualSize; // Draw when image has loaded

    image.src = url;

    function drawImageActualSize() {
      stroke.shadowBlur = 0;
      stroke.drawImage(this, path.x, path.y, path.w, path.h);
    }
  };

  drawResult = () => {
    const { resultBoard, layers } = this.state;
    if (!resultBoard) return;

    // console.log("ALL Layers", layers);
    if (!layers.length) {
      this.setState({ selectedLayer: "" });
      return;
    }
    layers.forEach((layer) => {
      this.drawLayer(layer);
    });
  };

  drawLayer = (layer) => {
    this.setState({ selectedLayer: layer });
    const stroke = this.state.resultBoard.getContext("2d");
    stroke.strokeStyle = layer.color;
    stroke.lineWidth = layer.strokeWidth;
    if (layer.hidden) return;
    if (layer.type === "IMAGE") {
      this.DrawImage({ url: layer.url, path: layer.path });
    }
    if (layer.type === "PENCILE") {
      this.drawPath({ paths: layer.path, stroke, eraser: layer.isEraser });
    }
    if (layer.type === "BRUSH") {
      this.drawBrush({
        paths: layer.path,
        stroke,
        color: layer.color,
        lineWidth: layer.strokeWidth,
      });
    }
    if (layer.type === "RECTANGLE") {
      this.drawRect({ rect: layer.path, stroke });
    }
    if (layer.type === "CIRCLE") {
      this.drawCircle({ circle: layer.path, stroke });
    }
    if (layer.type === "TEXT") {
      this.drawText({ text: layer.path, stroke, font: layer.font });
    }

    if (layer.type === "LINE") {
      this.drawLine({ path: layer.path, stroke });
    }
    if (layer.type === "ARROW") {
      this.drwaArrow({ path: layer.path, stroke });
    }
    if (layer.type === "CURVE") {
      this.drawCurve({ path: layer.path, stroke });
    }
    if (layer.type === "FILLRECTANGLE") {
      this.drawFillRect({ path: layer.path, stroke });
    }
    if (layer.type === "TRIANGLE") {
      this.drawTriangle({ path: layer.path, stroke });
    }
  };

  // undo = () => {
  //   const { roomID } = this.state;

  //   this.props.layers.length &&
  //     firebase
  //       .database()
  //       .ref("whiteboards")
  //       .child(`${roomID}`)
  //       .child("layers")
  //       .child(this.props.layers.length - 1)
  //       .remove();
  // };

  ImageSelect = (e) => {
    console.log(e.target);
    if (!e.target.files) return;
    console.log("called");
    var file = e.target.files[0];
    const { roomID } = this.state;
    const filePath = `${roomID}/images/${file.name}`;
    const Task = firebase
      .storage()
      .ref()
      .child("whiteboard")
      .child(filePath)
      .put(file);

    Task.on(
      "state_changed",
      (snap) => {
        const percentUploaded = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        console.log(percentUploaded);
        this.setState({ percentUploaded: percentUploaded });
      },
      (err) => {
        console.error(err);
      },
      () => {
        Task.snapshot.ref
          .getDownloadURL()
          .then((downloadUrl) => {
            console.log("downloadUrl", downloadUrl);
            console.log(downloadUrl);
            this.saveLayer({
              type: "IMAGE",
              url: downloadUrl,
              path: {
                x: 10,
                y: 10,
                h: 100,
                w: 100,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    );
  };

  handlePositionChange = (e) => {
    const {
      selectedLayer,
      roomID,
      boardWidth,
      boardHeight,
      workspaceID,
    } = this.state;
    if (selectedLayer.type !== "IMAGE") return;

    const Name = e.target.name;
    const Value = e.target.value;
    selectedLayer.path[Name] = Value;
    this.setState({ selectedLayer: selectedLayer });
    this.setState({ IsMoving: true }, () => {
      const moving = document.querySelector("#Moving").getContext("2d");
      moving.clearRect(0, 0, boardWidth, boardHeight);
      moving.strokeStyle = "black";
      moving.strokeRect(
        selectedLayer.path.x,
        selectedLayer.path.y,
        selectedLayer.path.w,
        selectedLayer.path.h
      );
      const image = new Image(); // Using optional size for image
      image.onload = drawImageActualSize; // Draw when image has loaded
      image.src = selectedLayer.url;

      function drawImageActualSize() {
        moving.drawImage(
          this,
          selectedLayer.path.x,
          selectedLayer.path.y,
          selectedLayer.path.w,
          selectedLayer.path.h
        );
      }
    });
  };

  savePositionChange = (e) => {
    console.log("Handle position change called");
    this.setState({ IsMoving: false });
    const { selectedLayer, roomID, layers } = this.state;
    this.deleteLayer(selectedLayer.id);
    this.saveLayer(selectedLayer);
  };

  handleFileUpload = (e) => {
    if (this.state.uploadState === "uploading") return;

    const file = e.target.files[0];

    if (!file) return;

    if (!this.isAuthorized(file.name)) {
      swal("This file type is not allowed");
      return;
    }
    if (file.size > 1500000) {
      swal("Image size is too large");
      return;
    }
    const date = new Date();
    const filePath = `UserId/CollectionImages/${date.toString()}`;
    this.setState({ uploadState: "uploading" });
    const uploadTask = firebase
      .storage()
      .ref()
      .child("whiteboard")
      .child(filePath)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snap) => {
        const percentUploaded = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        console.log(percentUploaded);
        this.setState({ percentUploaded: percentUploaded });
      },
      (err) => {
        console.error(err);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
          const Image = {
            fileName: "New file",
            created_at: new Date(),
            edited_at: new Date(),
            url: downloadUrl,
            path: filePath,
            folderName: this.state.selectedFolder,
          };
          firebase
            .firestore()
            .collection("Whiteboard")
            .doc(this.props.currentUser.uid)
            .update({
              images: firebase.firestore.FieldValue.arrayUnion(Image),
            })
            .then(() => this.setState({ uploadState: "", percentUploaded: 0 }))
            .catch((err) => {
              console.error(err);
            });
        });
      }
    );
  };
  f;

  handleCollecttionImageClick = (url) => {
    const { roomID, workspaceID } = this.state;
    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard")
      .doc("layers")
      .update({
        background: url,
      });
  };

  handleCollectionImageDelete = (image) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (this.props.background === image.url) {
          this.clearBackground();
        }
        firebase
          .firestore()
          .collection("Whiteboard")
          .doc(this.props.currentUser.uid)
          .update({
            images: firebase.firestore.FieldValue.arrayRemove(image),
          })
          .then(() => {
            firebase
              .storage()
              .ref()
              .child("whiteboard")
              .child(image.path)
              .delete()
              .then(() => {
                swal("Success! Your file is deleted!", {
                  icon: "success",
                });
              })
              .catch((err) => {
                console.log(err);
                swal("Error! Something went wrong!", {
                  icon: "error",
                });
              });
          })
          .catch((err) => {
            console.log(err);
            swal("Error! Something went wrong!", {
              icon: "error",
            });
          });
      }
    });
  };

  addNewFolder = () => {
    swal({
      text: "Please enter folder name",
      content: "input",
      button: {
        text: "Add",
      },
    }).then((name) => {
      if (!name) throw null;
      var isExists = false;
      this.state.folderCollection.forEach((folder) => {
        if (folder.folderName === name) {
          return (isExists = true);
        }
      });
      if (isExists) {
        swal("Error", "No duplicate folder name allowed", "error");
        return;
      }
      firebase
        .firestore()
        .collection("Whiteboard")
        .doc(this.props.currentUser.uid)
        .update({
          Folders: firebase.firestore.FieldValue.arrayUnion({
            folderName: name,
            created_at: new Date(),
          }),
        });
    });
  };

  handleFolderDelete = (folder) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this folder!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        firebase
          .firestore()
          .collection("Whiteboard")
          .doc(this.props.currentUser.uid)
          .update({
            Folders: firebase.firestore.FieldValue.arrayRemove(folder),
          })
          .then(() => {
            firebase
              .firestore()
              .collection("Whiteboard")
              .doc(this.props.currentUser.uid)
              .get()
              .then((doc) => {
                doc
                  .data()
                  .images.filter(
                    (image) => image.folderName === folder.folderName
                  )
                  .forEach((image) => {
                    if (this.props.background === image.url) {
                      this.clearBackground();
                    }
                    firebase
                      .firestore()
                      .collection("Whiteboard")
                      .doc(this.props.currentUser.uid)
                      .update({
                        images: firebase.firestore.FieldValue.arrayRemove(
                          image
                        ),
                      });
                    firebase
                      .storage()
                      .ref()
                      .child("whiteboard")
                      .child(image.path)
                      .delete();
                  });
              });

            swal("Success! Your folder is deleted!", {
              icon: "success",
            });
            console.log("removed");
          })
          .catch((err) => {
            console.log(err);
            swal("Error! Something went wrong!", {
              icon: "error",
            });
          });
      }
    });
  };

  clearBackground = () => {
    const { roomID, workspaceID } = this.state;

    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboardSetting")
      .doc("background")
      .set({
        background: "",
      });
  };

  handleShowHideLayer = (layer, reverseIndex) => {
    const { roomID, workspaceID, layers } = this.state;

    layer["hidden"] = !layer.hidden;
    layers[layers.length - reverseIndex - 1] = layer;
    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard")
      .doc("layers")
      .update({
        layers: layers,
      });
  };

  handleDeleteLayer = (reverseIndex) => {
    const { roomID, workspaceID, layers } = this.state;
    var updateLayers = [];
    updateLayers = layers.filter(
      (item, idx) => idx !== layers.length - reverseIndex - 1
    );
    firebase
      .firestore()
      .collection(`workspaces`)
      .doc(workspaceID)
      .collection("rooms")
      .doc(roomID).collection("whiteboards").doc("0")
      .collection("whiteboard")
      .doc("layers")
      .update({
        layers: updateLayers,
      });
  };

  render() {
    const {
      layers,
      selectedTool,
      boardWidth,
      boardHeight,
      penColor,
      showPicker,
      lineWidth,
      redoLayers,
      selectedLayer,
      IsMoving,
      QuickColors,
      ShowFileManager,
      ImageCollection,
      folderCollection,
      selectedFolder,
      loadingCollection,
      showLayers,
      reversLayers,
      showSettingModal,
      settingDisplay,
      showQuickColorPicker,
      textFont,
      isLoading,
      defaultPenColor,
    } = this.state;

    const { setShowWhiteboard, showWhiteboard } = this.props;

    const itemSelectedStyle = (item) =>
      item === selectedTool
        ? styles.controlPanelWrapper.controlItemSelected
        : {};

    return (
      <div>
        {isLoading && (
          <div className="centered-spinner bg-light">
            <Spinner animation="border" variant="dark" className="" />
          </div>
        )}
        <div
          style={{
            ...styles.artBoardWrapper,
          }}
        >
          <div style={styles.controlPanelWrapper}>

          {/* ----------------------------------------------- UNDO Button -------------------------------------- */}
            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Undo"
              onClick={this.undoLayer}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("UNDO"),
              }}
            >
              <FontAwesomeIcon
                icon={faUndo}
                style={{ opacity: `${layers.length ? 1 : 0.5}` }}
              />
            </button>


          {/* ----------------------------------------------- REDO Button -------------------------------------- */}

            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Redo"
              onClick={this.redoLayer}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("REDO"),
              }}
            >
              <FontAwesomeIcon
                icon={faRedo}
                style={{ opacity: `${redoLayers.length ? 1 : 0.5}` }}
              />
            </button>



          {/* ----------------------------------------------- Pencil select Button -------------------------------------- */}

            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Pencile"
              onClick={this.setTool("PENCILE")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("PENCILE"),
              }}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>

            {/* <button
							data-toggle="tooltip"
							data-placement="bottom"
							title="Pencile"
							onClick={this.setTool('BRUSH')}
							style={{
								...styles.controlPanelWrapper.controlItem,
								...itemSelectedStyle('BRUSH')
							}}
						>
							<FontAwesomeIcon icon={faPaintBrush} />
						</button> */}


          {/* ----------------------------------------------- Eraser select Button -------------------------------------- */}

            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Eraser"
              onClick={this.setTool("ERASER")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("ERASER"),
              }}
            >
              <FontAwesomeIcon icon={faEraser} />
            </button>


          {/* ----------------------------------------------- TEXT select Button -------------------------------------- */}

            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Text"
              onClick={this.setTool("TEXT")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("TEXT"),
              }}
            >
              <FontAwesomeIcon icon={faFont} />
            </button>


          {/* ----------------------------------------------- Line select Button -------------------------------------- */}

            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Line"
              onClick={this.setTool("LINE")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("LINE"),
              }}
            >
              <svg height="auto" width="auto">
                <line
                  x1="2"
                  y1="8"
                  x2="18"
                  y2="22"
                  style={{ stroke: `${this.state.penColor}`, strokeWidth: 2 }}
                />
              </svg>
            </button>

          {/* ----------------------------------------------- Arrow select Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Arrow"
              onClick={this.setTool("ARROW")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("ARROW"),
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>

          {/* ----------------------------------------------- Background image select Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Background Image"
              // onClick={this.setTool('ADD_IMAGE')}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("ADD_IMAGE"),
                position: "relative",
              }}
            >
              <FontAwesomeIcon icon={faImage} />
              <input
                type="file"
                id="backgroundImage"
                style={{
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
                onChange={(e) => this.addFile(e)}
              />
            </button>

          {/* ----------------------------------------------- Image layer select Button -------------------------------------- */}


            {/* <button
              // onClick={this.setTool('ADD_IMAGE')}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Image layer"
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("ADD_IMAGE"),
                position: "relative",
              }}
            >
              <FontAwesomeIcon icon={faImages} />
              <input
                type="file"
                id="ImageLayer"
                style={{
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
                onChange={(e) => {
                  this.setTool("DRAWIMAGE");
                  this.ImageSelect(e);
                }}
              />
            </button> */}

          {/* ----------------------------------------------- Rectangle select Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Rectangle"
              onClick={this.setTool("RECTANGLE")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("RECTANGLE"),
              }}
            >
              <FontAwesomeIcon icon={faSquare} />
            </button>

          {/* ----------------------------------------------- Fill Rectangle select Button -------------------------------------- */}


            {/* <button
							data-toggle="tooltip"
							data-placement="bottom"
							title="Fill rectangle"
							onClick={this.setTool('FILLRECTANGLE')}
							style={{
								...styles.controlPanelWrapper.controlItem,
								...itemSelectedStyle('FILLRECTANGLE')
							}}
						>
							<FontAwesomeIcon icon={faSquareFull} style={{ color: `${this.state.penColor}` }} />
						</button> */}

          {/* ----------------------------------------------- Circle select Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Circle"
              onClick={this.setTool("CIRCLE")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("CIRCLE"),
              }}
            >
              <FontAwesomeIcon icon={faCircle} />
            </button>

          {/* ----------------------------------------------- Curve select Button -------------------------------------- */}


            {/* <button
            data-toggle="tooltip"
            data-placement="bottom"
            title="Curve"
            onClick={this.setTool("CURVE")}
            style={{
              ...styles.controlPanelWrapper.controlItem,
              ...itemSelectedStyle("CURVE"),
            }}
          >
            <FontAwesomeIcon icon={faCopyright} />
          </button> */}

          {/* ----------------------------------------------- Triangle select Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Triangle"
              onClick={this.setTool("TRIANGLE")}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                ...itemSelectedStyle("TRIANGLE"),
              }}
            >
              <svg height="auto" width="auto">
                <polygon
                  points="8,8, 1,20 ,15,20"
                  style={{
                    fill: "transparent",
                    stroke: "black",
                    strokeWidth: 1.5,
                    ...itemSelectedStyle("TRIANGLE"),
                  }}
                />
              </svg>
            </button>

          {/* ----------------------------------------------- Clear all Button -------------------------------------- */}
           

            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Clear All"
              onClick={this.clearResult}
              style={{
                ...styles.controlPanelWrapper.controlItem,
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>

          {/* ----------------------------------------------- Color picker Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Color picker"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  showPicker: true,
                });
              }}
              style={{
                ...styles.controlPanelWrapper.controlItem,
                height: 32,
                width: 32,
                backgroundColor: `${this.state.penColor}`,
                zIndex: 1000,
                marginLeft: '80px'
              }}
            >
              {showPicker && (
                <OutsideAlerter
                  onClickOutside={() => {
                    this.setState({
                      showPicker: false,
                    });
                  }}
                >
                  <SketchPicker
                    color={this.state.penColor}
                    onChangeComplete={(color) => {
                      this.setState({
                        penColor: color.hex,
                      });
                    }}
                  />
                </OutsideAlerter>
              )}
            </button>

          {/* ----------------------------------------------- Line width select Button -------------------------------------- */}
          

            <span className="px-2 pb-1 d-inline-flex flex-column text-center">
              <div className="text-center">
                <small>
                  {selectedTool === "TEXT" ? "Text size" : "Line width"}
                </small>
              </div>
              <Form.Control
                type="range"
                min="1"
                max="10"
                value={this.state.lineWidth}
                data-toggle="tooltip"
                data-placement="bottom"
                title={`${this.state.lineWidth} pts`}
                onChange={(e) =>
                  this.setState({ lineWidth: parseInt(e.target.value) })
                }
              />
            </span>

          {/* ----------------------------------------------- Save whhiteboard as image Button -------------------------------------- */}


            <button
              data-toggle="tooltip"
              data-placement="bottom"
              title="Save image"
              id="saveImage"
              className="btn btn-sm btn-dark mx-1 mr-2"
              onClick={(e) => {
                e.preventDefault();
                const resultBoard = document.querySelector("#resultBoard");
                html2canvas(resultBoard, {
                  useCORS: true,
                  allowTaint: true,
                  logging: true,
                  removeContainer: true,
                }).then(function (canvas) {
                  canvas.id = "savedImage";
                  resultBoard.append(canvas);

                  canvasToImage(canvas, {
                    name: `whiteboard-${Date.now()}`,
                    type: "jpg",
                    quality: 1,
                  });
                });
              }}
            >
              Save
            </button>

            {/* <-------------------------------------------- Toggle whiteboard --------------------------- */}

           
            {/* <span
              className="btn btn-sm btn-danger ml-auto mr-2"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Close Whiteboard"
              onClick={() => setShowWhiteboard(!showWhiteboard)}
            >
              <b>
                <FontAwesomeIcon icon={faTimes} className="mx-1" />
              </b>
            </span> */}
          </div>

          <canvas
            id="imageBoard"
            width={boardWidth}
            height={boardHeight}
            style={{
              top: 50,
              left: 0,
              position: "absolute",
              backgroundImage: `url("${this.props.background}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          />
          <canvas
            id="resultBoard"
            width={boardWidth}
            height={boardHeight}
            style={{
              top: 50,
              left: 0,
              zIndex: 1,
              position: "absolute",
            }}
          />

          {!~[
            "RECTANGLE",
            "PENCILE",
            "CIRCLE",
            "TEXT",
            "LINE",
            "CURVE",
            "FILLRECTANGLE",
            "TRIANGLE",
            "ERASER",
            "ARROW",
            "BRUSH",
          ].indexOf(selectedTool) ? (
            <div
              style={{
                zIndex: 100,
                position: "relative",
                backgroundColor: "transparent",
                cursor: `${selectedTool === "TEXT" ? "text" : "crosshair"}`,
                width: `${boardWidth}px`,
                height: `${boardHeight}px`,
              }}
            />
          ) : (
            <GraffitiBoard
              textFont={textFont}
              penColor={penColor}
              selectedTool={selectedTool}
              boardWidth={boardWidth}
              boardHeight={boardHeight}
              lineWidth={lineWidth}
              save={this.saveLayer}
            />
          )}

          {IsMoving && (
            <canvas
              id="Moving"
              width={boardWidth}
              height={boardHeight}
              style={{
                top: 50,
                left: 0,
                position: "absolute",
                zIndex: 200,
                backgroundColor: "transparent",
              }}
            />
          )}

          {/* ----------------------------------------------- File Manager ----------------------------------------------- */}
          {ShowFileManager && (
            <div
              className="bg-dark position-absolute rounded-lg border text-white"
              style={{
                width: "220px",
                height: "500px",
                zIndex: "300",
                top: 55,
                left: 3,
              }}
            >
              <div className="py-2  px-3 border-bottom">
                <span>
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <small className="mx-2">
                    <b>Collection</b>
                  </small>
                </span>
                <span
                  className="float-right cursor-pointer"
                  onClick={() => this.setState({ ShowFileManager: false })}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Close"
                >
                  x
                </span>
              </div>
              <div
                className="border bg-secondary scrollBar"
                style={{ height: "400px", overflowY: "auto" }}
              >
                {loadingCollection ? (
                  <div className="mt-5 text-center">
                    <Spinner animation="border" variant="light" />
                  </div>
                ) : (
                  <div>
                    {!selectedFolder && folderCollection.length
                      ? folderCollection.map((folder, idx) => (
                          <div
                            className="row m-1 p-1  bg-light text-dark rounded-lg"
                            key={idx}
                          >
                            <div
                              className="col-3  pl-2  text-warning  "
                              style={{ fontSize: "22px", cursor: "pointer" }}
                            >
                              <FontAwesomeIcon icon={faFolder} />
                            </div>
                            <div
                              className="col-7 px-1 "
                              style={{ marginTop: "-3px", cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  selectedFolder: folder.folderName,
                                })
                              }
                            >
                              <div>
                                <small>
                                  {folder.folderName.length > 12
                                    ? folder.folderName.slice(0, 12) + "..."
                                    : folder.folderName}
                                </small>
                              </div>
                              <div
                                className="text-muted"
                                style={{ fontSize: "10px" }}
                              >
                                {folder.created_at.toDate().toDateString()}
                              </div>
                            </div>

                            <div className="col-2 px-1 ">
                              <span
                                className="float-right p-1 btn "
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right text-dark p-2">
                                <div className=" btn ">
                                  <FontAwesomeIcon icon={faEdit} />
                                  <small className="pl-2">
                                    <b>Rename</b>
                                  </small>
                                </div>
                                <div
                                  className="text-danger btn"
                                  onClick={() =>
                                    this.handleFolderDelete(folder)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                  <small className="pl-2">
                                    <b>Delete</b>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}

                    {selectedFolder && (
                      <div className="row m-1 bg-white text-dark py-1 rounded-lg">
                        <div className="col-8  text-left">
                          {" "}
                          <small>
                            <b>
                              /
                              {selectedFolder.length > 12
                                ? selectedFolder.slice(0, 12) + "..."
                                : selectedFolder}
                            </b>
                          </small>
                        </div>
                        <div
                          className="col-4 btn btn-sm btn-primary"
                          onClick={() => this.setState({ selectedFolder: "" })}
                        >
                          {"<-"}
                          <b>Back</b>
                        </div>
                      </div>
                    )}

                    {ImageCollection.length
                      ? ImageCollection.map((image, idx) => (
                          <div
                            className="row m-1 p-1  bg-dark text-white rounded-lg"
                            key={idx}
                          >
                            <div className="col-3  px-1">
                              <img
                                src={image.url}
                                className="rounded-lg"
                                alt="collection"
                                height="35px"
                                width="35px"
                              />
                            </div>
                            <div
                              className="col-7 px-1 "
                              style={{ marginTop: "-3px", cursor: "pointer" }}
                              onClick={() =>
                                this.handleCollecttionImageClick(image.url)
                              }
                            >
                              <div>
                                <small>
                                  {image.fileName.length > 12
                                    ? `${image.fileName.slice(0, 12)}...`
                                    : image.fileName}
                                </small>
                              </div>
                              <div
                                className="text-muted"
                                style={{ fontSize: "10px" }}
                              >
                                {image.created_at.toDate().toDateString()}
                              </div>
                            </div>
                            <div className="col-2 px-1 ">
                              <span
                                className="float-right p-1 btn text-white"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right text-dark p-2">
                                <div className=" btn ">
                                  <FontAwesomeIcon icon={faEdit} />
                                  <small className="pl-2">
                                    <b>Rename</b>
                                  </small>
                                </div>
                                <div className=" btn ">
                                  <FontAwesomeIcon icon={faFolderPlus} />
                                  <small className="pl-2">
                                    <b>Move to folder</b>
                                  </small>
                                </div>
                                <div
                                  className="text-danger btn"
                                  onClick={() =>
                                    this.handleCollectionImageDelete(image)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                  <small className="pl-2">
                                    <b>Delete</b>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                )}
              </div>
              <div className="position-absolute w-100 " style={{ bottom: 12 }}>
                <div
                  className="ml-3  cursor-pointer d-inline-block py-1  rounded-lg text-center bg-primary"
                  style={{ width: "62%", fontSize: "14px" }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Upload a new image"
                >
                  <input
                    type="file"
                    className="position-absolute"
                    accept="image/*"
                    style={{
                      opacity: 0,
                      zIndex: 5,
                      left: "10px",
                      top: "0px",
                      width: "60%",
                      cursor: "pointer",
                    }}
                    onChange={this.handleFileUpload}
                  />
                  <b>
                    {this.state.uploadState === "uploading" ? (
                      `Uploading ${this.state.percentUploaded}%`
                    ) : (
                      <div className="mr-1">
                        <FontAwesomeIcon icon={faUpload} className="mx-2" />{" "}
                        Upload
                      </div>
                    )}
                  </b>
                </div>
                <button
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Create new folder"
                  className="btn d-inline-block float-right mr-3 p-0 btn-light px-2 btn-sm"
                  disabled={selectedFolder ? true : false}
                  style={{
                    fontSize: "19px",
                    zIndex: 6,
                    position: "absolute",
                    right: "-5px",
                  }}
                  onClick={this.addNewFolder}
                >
                  <FontAwesomeIcon icon={faFolderPlus} />
                </button>
              </div>
            </div>
          )}

          {/* ----------------------------------------- *** End File Manager *** ----------------------------------------- */}

          {/* ----------------------------------------------- All Layers ------------------------------------------------- */}

          {showLayers && (
            <div
              className="bg-secondary text-white shadow border position-absolute rounded-lg border  "
              style={{
                width: "220px",
                height: "500px",
                zIndex: "300",
                top: 55,
                right: 3,
                letterSpacing: ".5px",
              }}
            >
              <div className="bg-primary text-white p-2 rounded border-bottom ">
                <FontAwesomeIcon icon={faFolderPlus} className="mx-1" />
                <span className="mx-1 ">
                  <small>
                    <b className="text-white">All layers</b>
                  </small>
                </span>
                <span
                  className="float-right cursor-pointer text-white"
                  onClick={() => this.setState({ showLayers: false })}
                >
                  <b>
                    <FontAwesomeIcon icon={faTimes} className="mx-1" />
                  </b>
                </span>
              </div>
              <div>
                {layers.length ? (
                  <div>
                    <div
                      className="p-1 scrollBar"
                      style={{ height: "450px", overflowX: "auto" }}
                    >
                      {reversLayers.map((layer, idx) => (
                        <div
                          className={`bg-dark text-white p-2 my-1 rounded-lg `}
                        >
                          <span
                            className="cursor-pointer p-1 text-warning"
                            onClick={() => this.handleShowHideLayer(layer, idx)}
                          >
                            {layer.hidden ? (
                              <FontAwesomeIcon icon={faEyeSlash} size="xs" />
                            ) : (
                              <FontAwesomeIcon icon={faEye} size="xs" />
                            )}
                          </span>
                          <span>
                            <span
                              className="float-right p-1 cursor-pointer text-white"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </span>
                            <div className="dropdown-menu dropdown-menu-right text-dark p-2">
                              <div
                                className="text-danger btn"
                                onClick={() => this.deleteLayer(layer.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                                <small className="pl-2">
                                  <b>Delete</b>
                                </small>
                              </div>
                            </div>
                          </span>
                          <span className="mx-1">
                            <small>
                              {layer.isEraser ? (
                                <span>
                                  Eraser{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faEraser} />
                                  </span>
                                </span>
                              ) : layer.type === "PENCILE" ? (
                                <span>
                                  Pencil{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </span>
                                </span>
                              ) : layer.type === "BRUSH" ? (
                                <span>
                                  Brush{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faPaintBrush} />
                                  </span>
                                </span>
                              ) : layer.type === "TEXT" ? (
                                <span>
                                  Text{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faFont} />
                                  </span>
                                </span>
                              ) : layer.type === "LINE" ? (
                                <span>
                                  Line{" "}
                                  <span className="float-right  mr-1">
                                    <svg height="20" width="20">
                                      <line
                                        x1="2"
                                        y1="5"
                                        x2="18"
                                        y2="22"
                                        style={{
                                          stroke: "white",
                                          strokeWidth: 2,
                                        }}
                                      />
                                    </svg>
                                  </span>
                                </span>
                              ) : layer.type === "ARROW" ? (
                                <span>
                                  Arrow{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faArrowRight} />
                                  </span>
                                </span>
                              ) : layer.type === "IMAGE" ? (
                                <span>
                                  Image{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faImages} />
                                  </span>
                                </span>
                              ) : layer.type === "RECTANGLE" ? (
                                <span>
                                  Rectangle{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faSquare} />
                                  </span>
                                </span>
                              ) : layer.type === "FILLRECTANGLE" ? (
                                <span>
                                  Filled Rectangle{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faSquareFull} />
                                  </span>
                                </span>
                              ) : layer.type === "CIRCLE" ? (
                                <span>
                                  Circle{" "}
                                  <span className="float-right p-1 mr-1">
                                    <FontAwesomeIcon icon={faCircle} />
                                  </span>
                                </span>
                              ) : layer.type === "TRIANGLE" ? (
                                <span>
                                  Triangle{" "}
                                  <span className="float-right mr-1">
                                    <svg height="20" width="20">
                                      <polygon
                                        points="8,8, 1,20 ,15,20"
                                        style={{
                                          fill: "transparent",
                                          stroke: "white",
                                          strokeWidth: 1.5,
                                          ...itemSelectedStyle("TRIANGLE"),
                                        }}
                                      />
                                    </svg>
                                  </span>
                                </span>
                              ) : (
                                layer.type
                              )}
                            </small>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className=" text-center mt-5 pt-5 align-content-center font-weight-bold">
                    <div>
                      <i>No Layers Found!</i>
                    </div>
                    <div>
                      <small>Start drawing...</small>
                    </div>
                    <div>☺</div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* ----------------------------------------- *** End All Layers *** ------------------------------------------- */}

          {/* <div
            className=" p-2 rounded-lg d-flex  border-top row m-0"
            style={{ backgroundColor: "#ddd" }}
          >
            <div className="col-2 px-1 mt-1">
              
              <button
								data-toggle="tooltip"
								data-placement="top"
								title="Open collection"
								className="rounded-lg"
								onClick={() => this.setState({ ShowFileManager: !ShowFileManager })}
								style={{
									cursor: 'pointer',
									border: '1px outset',
									height: '35px',
									width: '35px'
								}}
							>
								<FontAwesomeIcon icon={faFolderOpen} />
							</button>

              <button
								data-toggle="tooltip"
								data-placement="top"
								title="Settings"
								className="rounded-lg py-1 px-2 m-1"
								style={{ border: '1px outset' }}
								onClick={() => this.setState({ showSettingModal: true })}
							>
								<FontAwesomeIcon icon={faCog} />
							</button>

              <Modal
                show={showSettingModal}
                onHide={() => this.setState({ showSettingModal: false })}
                keyboard={false}
                centered
              >
                <Modal.Header closeButton className="bg-dark text-white">
                  <Modal.Title>
                    <h5>
                      <FontAwesomeIcon
                        icon={faCog}
                        className="mr-2 text-danger"
                      />
                      Settings
                    </h5>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className="bg-secondary scrollBar"
                  style={{
                    height: "300px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <div className="row m-0 font-14 ">
                    <div
                      className={`col  rounded-lg  py-1 mx-1 my-1 cursor-pointer rounded-pill ${
                        settingDisplay === "Pen"
                          ? "bg-dark shadow text-white"
                          : "bg-white"
                      }`}
                      onClick={() => this.setState({ settingDisplay: "Pen" })}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />{" "}
                      Pen & Text
                    </div>

                    <div
                      className={`col  rounded-lg  py-1 mx-1 my-1 cursor-pointer rounded-pill ${
                        settingDisplay === "Other"
                          ? "bg-dark shadow text-white"
                          : "bg-white"
                      }`}
                      onClick={() => this.setState({ settingDisplay: "Other" })}
                    >
                      Other
                    </div>
                  </div>

                  {settingDisplay === "Pen" && (
                    <div className="mx-1 my-2 text-white ">
                      <div className="border-bottom font-14 py-1 px-2  rounded-lg bg-light text-primary">
                        <span className="mr-1">
                          <svg height="25px" width="25px">
                            <line
                              x1="2"
                              y1="4"
                              x2="20"
                              y2="22"
                              style={{ stroke: "#007bff", strokeWidth: 2 }}
                            />
                          </svg>
                        </span>
                        Line
                      </div>
                      <div className="  my-1 p-2 d-flex font-weight-bolder ">
                        <span>Width:</span>
                        <span className="w-100 mx-2">
                          <Form.Control
                            type="range"
                            min="1"
                            max="10"
                            value={this.state.defaultLineWidth}
                            className="mx-2 mt-1"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={`${this.state.defaultLineWidth} pts`}
                            onChange={(e) =>
                              this.setState({
                                defaultLineWidth: parseInt(e.target.value),
                              })
                            }
                          />
                        </span>
                      </div>
                      <div className="border-bottom font-14 py-1 px-2 shadow rounded-lg bg-light text-primary">
                        <FontAwesomeIcon icon={faPalette} className="mr-2" />
                        Color
                      </div>
                      <div className=" mt-1 p-2 d-flex font-weight-bolder">
                        <span>Primary:</span>
                        <div className="ml-2">
                          {[
                            "#C0C0C0",
                            "#808080",
                            "#000000",
                            "#FF0000",
                            "#800000",
                            "#FFFF00",
                            "#808000",
                            "#00FF00",
                            "#008000",
                            "#00FFFF",
                            "#008080",
                            "#0000FF",
                            "#000080",
                            "#FF00FF",
                            "#800080",
                          ].map((color) => (
                            <span
                              className="rounded-lg m-1 d-inline-block cursor-pointer border "
                              onClick={() =>
                                this.setState({ defaultPenColor: color })
                              }
                              style={{
                                backgroundColor: color,
                                height: "28px",
                                width: "28px",
                                transform: `scale(${
                                  defaultPenColor && color === defaultPenColor
                                    ? 1.2
                                    : 0.9
                                })`,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="  mb-1 p-2 d-flex font-weight-bolder">
                        <span className="mt-1">Quick Colors:</span>
                        <span className="ml-2">
                          {[0, 1].map((item) => (
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                showQuickColorPicker[item] = true;
                                this.setState({
                                  showQuickColorPicker: showQuickColorPicker,
                                });
                              }}
                              className="d-inline-block rounded-circle border"
                              style={{
                                ...styles.controlPanelWrapper.controlItem,
                                height: 30,
                                width: 30,
                                backgroundColor: `${this.state.QuickColors[item]}`,
                              }}
                            >
                              {showQuickColorPicker[item] && (
                                <OutsideAlerter
                                  onClickOutside={() => {
                                    showQuickColorPicker[item] = false;
                                    this.setState({
                                      showQuickColorPicker: showQuickColorPicker,
                                    });
                                  }}
                                >
                                  <SketchPicker
                                    color={this.state.QuickColors[item]}
                                    onChangeComplete={(color) => {
                                      QuickColors[item] = color.hex;
                                      console.log(color);
                                      this.setState({
                                        QuickColors: QuickColors,
                                      });
                                    }}
                                  />
                                </OutsideAlerter>
                              )}
                            </span>
                          ))}
                        </span>
                      </div>
                      <div className="border-bottom font-14 py-1 px-2 shadow rounded-lg bg-light text-primary">
                        <FontAwesomeIcon icon={faFont} className="mr-2" />
                        Font
                      </div>

                      <div className="  my-1 p-2 d-flex font-weight-bolder ">
                        Font-family:
                        <span className="ml-2">
                          <Form.Control
                            size="sm"
                            as="select"
                            className="bg-dark text-white"
                            value={textFont}
                            onChange={(e) =>
                              this.setState({ textFont: e.target.value })
                            }
                          >
                            {[
                              "Arial",
                              "Courier New",
                              "sans-serif",
                              "serif",
                              "Times New Roman",
                              "Verdana",
                            ].map((item) => (
                              <option value={item} style={{ fontFamily: item }}>
                                {item}
                              </option>
                            ))}
                          </Form.Control>
                        </span>
                      </div>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </div>
            <div
              className="col-2 px-1 rounded-lg pt-1 px-1 bg-light"
              style={{ border: "1px outset" }}
            >
              <Form>
                <Form.Group className="d-flex m-0" controlId="formBasicRange">
                  <Form.Label className="mr-2 ">
                    <small>X:</small>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={
                      selectedLayer && selectedLayer.path.w
                        ? 840 - selectedLayer.path.w
                        : 840
                    }
                    value={selectedLayer ? parseInt(selectedLayer.path.x) : 420}
                    name="x"
                    onChange={this.handlePositionChange}
                    onMouseUp={this.savePositionChange}
                    disabled={selectedLayer.type === "IMAGE" ? false : true}
                  />
                </Form.Group>
              </Form>
            </div>

            <div
              className="col-2 px-1 rounded-lg pt-1 px-1 bg-light"
              style={{ border: "1px outset" }}
            >
              <Form>
                <Form.Group className="d-flex m-0" controlId="formBasicRange">
                  <Form.Label className="mr-2 ">
                    <small>Y:</small>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={
                      selectedLayer && selectedLayer.path.h
                        ? 510 - selectedLayer.path.h
                        : 510
                    }
                    name="y"
                    value={selectedLayer ? parseInt(selectedLayer.path.y) : 255}
                    onChange={this.handlePositionChange}
                    onMouseUp={this.savePositionChange}
                    disabled={selectedLayer.type === "IMAGE" ? false : true}
                  />
                </Form.Group>
              </Form>
            </div>
            <div
              className="col-2 px-1 rounded-lg pt-1 px-1 bg-light"
              style={{ border: "1px outset" }}
            >
              <Form>
                <Form.Group className="d-flex m-0" controlId="formBasicRange">
                  <Form.Label className="mr-2 ">
                    <small>H:</small>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={
                      selectedLayer && selectedLayer.path.y
                        ? 510 - selectedLayer.path.y
                        : 510
                    }
                    name="h"
                    value={selectedLayer ? parseInt(selectedLayer.path.h) : 255}
                    onMouseUp={this.savePositionChange}
                    onChange={this.handlePositionChange}
                    disabled={selectedLayer.type === "IMAGE" ? false : true}
                  />
                </Form.Group>
              </Form>
            </div>
            <div
              className="col-2 px-1 rounded-lg pt-1 bg-light"
              style={{ border: "1px outset" }}
            >
              <Form className="mx-1 ">
                <Form.Group className="d-flex m-0" controlId="formBasicRange">
                  <Form.Label className="mr-2 ">
                    <small>W:</small>
                  </Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={
                      selectedLayer && selectedLayer.path.x
                        ? 840 - selectedLayer.path.x
                        : 840
                    }
                    name="w"
                    value={selectedLayer ? parseInt(selectedLayer.path.w) : 420}
                    onMouseUp={this.savePositionChange}
                    onChange={this.handlePositionChange}
                    disabled={selectedLayer.type === "IMAGE" ? false : true}
                  />
                </Form.Group>
              </Form>
            </div>

            <div className="col-2 px-1 ">
              {[0, 1].map((item) => (
                <span
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Quick Colors"
                  className="btn rounded-circle px-1 mt-1"
                  onClick={() =>
                    this.setState({
                      penColor: QuickColors[item],
                    })
                  }
                  style={{
                    height: "25px",
                    width: "25px",
                    border: "1px outset",
                    backgroundColor: QuickColors[item],
                  }}
                />
              ))}

              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Clear Background"
                onClick={this.clearBackground}
                className="btn  mx-1 px-0   mt-1"
                style={{
                  height: "30px",
                  width: "30px",
                  border: "1px outset",
                  paddingTop: "2px",
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <button
                data-toggle="tooltip"
                data-placement="top"
                title="All layers"
                onClick={() => this.setState({ showLayers: !showLayers })}
                className="rounded-lg mt-1  float-right "
                style={{
                  cursor: "pointer",
                  border: "1px outset",
                  height: "30px",
                  width: "30px",
                }}
              >
                <FontAwesomeIcon icon={faLayerGroup} />
              </button>
            </div>
          </div>
       
        */}
          {/* {layers.length ? (
          <div
            style={{
              width: "100%",
              padding: 5,
              boxSizing: "border-box",
            }}
          >
            <h2>Steps</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Layer type</th>
                  <th>Color</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {layers.map((layer, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{layer.type}</td>
                    <td>
                      <div
                        style={{
                          backgroundColor: `${layer.color}`,
                          width: 20,
                          height: 20,
                        }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={this.deleteLayer(layer.id)}
                      >
                        <i className="material-icons" style={{ fontSize: 12 }}>
                          close
                        </i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  
  return {
  layers: state.layers,
  background: state.background,
  currentWorkspace: "LgT2sjzoZQwwKMGPnob2",
 // currentChannel: state.channel.currentChannel,
  roomID: "I5ZwggPkyQpeCdRpcl1M",
  showWhiteboard: state.showWhiteboard,
  currentUser: firebase.auth().currentUser,
}
};

export default connect(mapStateToProps, {
  saveWhiteboardLayers,
  saveWhiteboardBackground,
  setShowWhiteboard,
})(ArtBoard);
