import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Accordion,
  Card,
  Image,
  InputGroup,
  Form,
  Button,
  Tabs,
  Tab,
  Dropdown,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faPaperPlane,
  faPodcast,
  faTimes,
  faUser,
  faSearch,
  faUserPlus,
  faUserCheck,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import * as CourseData from "../course.json";
import * as CourseStyle from "../course.module.css";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import Avatar from "react-avatar";
import ReadMore from "../../ReadMore";
import Chat from "./Chat";
import CourseRedux from "../CourseRedux";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";

function Instructors({ currentLanguage, changeLanguage, course, currentCourse }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const [follow, setFollow] = useState(false);

  const [faculty, facultyLoading, facultyError] = useCollection(
    firebase.firestore().collection(`courses/${currentCourse?.id}/speakers`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const facultyData = faculty?.docs?.map((e) => ({ ...e.data(), id: e.id }));

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [selectedInstructor, setSelectedInstructor] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <CourseRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <h2 className="font-weight-bold text-white">Instructors</h2>

                  <div className="mt-3 mt-md-4 ">
                    <div className="">
                      {/* Upcoming Live Sessions */}


                      <InputGroup className="my-2 outerPill">
                        <div className="d-flex align-items-center rounded-lg flex-grow-1  bg-dark">
                          <div className="py-2 pr-2 pl-3">
                            <FontAwesomeIcon
                              icon={faSearch}
                              size="sm"
                              color="#aeaeae"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <Form.Control
                              type="text"
                              id="participantsSearch"
                              className="border-0 px-0 bg-dark text-white rounded"
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                                const reg = _.escapeRegExp(searchTerm);
                                // console.log(reg,"Reg")
                                const regex = new RegExp(reg, "gi");
                                setRegex(regex);
                              }}
                              placeholder={"Search"}
                              value={searchTerm}
                              style={{ margin: 0 }}
                            />
                          </div>
                          {searchTerm.length > 0 && (
                            <div
                              className="py-2 pl-2 pr-3"
                              onClick={() => setSearchTerm("")}
                            >
                              <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                            </div>
                          )}
                        </div>
                      </InputGroup>

                      <div
                        className="mb-3 mb-md-5"
                      //id="#outcomes"
                      //ref={outcomesRef}
                      >
                        {facultyData?.length && facultyData?.map((professor) => {
                          let fullName = professor?.first_name + ' ' + professor?.last_name;

                          return (
                            (searchTerm && searchTerm.length > 0
                              ? fullName?.match(regex)
                              : true) && (
                              <Card
                                className="text-white bg-dark mb-2"
                                onClick={() => {
                                  setSelectedInstructor(professor);
                                  handleShow();
                                }}
                                style={{ cursor: "pointer" }}
                                title={professor.first_name}
                              >
                                <Card.Body>
                                  <div className="d-flex">
                                    <div
                                      className="img-fluid rounded-circle shadow-sm border"
                                      style={{
                                        height: 50,
                                        width: 50,
                                        backgroundImage: `url(${professor.img_src})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                      }}
                                    />
                                    <div className="ml-2">
                                      <p className="mb-0">{fullName}</p>
                                      <span className="small">
                                        {professor.designation}
                                      </span>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="bg-graphite border-0 p-3 p-md-4" closeButton>
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <img
              className="img-fluid rounded-circle mb-3"
              style={{ height: 150, width: 150 }}
              src={selectedInstructor?.img_src}
            />
            <h4 className="text-white font-weight-bold">
              {selectedInstructor?.first_name + ' ' + selectedInstructor?.last_name}
            </h4>
            <p className="text-white text-center mb-0">
              {selectedInstructor?.designation}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="text-white text-center bg-graphite border-0 p-3 p-md-4">
          <div className="row">
            <div className="col-sm">
              <FontAwesomeIcon icon={faGraduationCap} />
              <p className="mb-0">{`14`}</p>
            </div>
            <div className="col-sm">
              <FontAwesomeIcon icon={faUser} />
              <p className="mb-0">{`1251`}</p>
            </div>
            <div className="col-sm">
              <FontAwesomeIcon icon={faPodcast} />
              <p className="mb-0">{`43`}</p>
            </div>
          </div>
          <hr />
          <ReadMore
            className="text-white text-left"
            full_text={selectedInstructor?.description}
            max_length={250}
          />
          <hr />
          <div className="d-flex align-items-center justify-content-center">
            <div className="w-50 border-right border-dark">
              <Button
                variant="none"
                className="text-primary font-weight-bold w-100"
                onClick={() => {
                  if (follow) {
                    setFollow(false);
                  } else {
                    setFollow(true);
                  }
                }}
              >
                {follow ?
                  <span className="d-flex align-items-center justify-content-center text-success">
                    <FontAwesomeIcon icon={faUserCheck} flip="horizontal" />
                    <p className="mb-0 ml-2">Following</p>
                  </span>
                  :
                  <span className="d-flex align-items-center justify-content-center text-primary">
                    <FontAwesomeIcon icon={faUserPlus} flip="horizontal" />
                    <p className="mb-0 ml-2">Follow</p>
                  </span>
                }

              </Button>
            </div>
            <div className="w-50">
              <Button
                variant="none"
                className="text-primary font-weight-bold w-100"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faEnvelope} flip="horizontal" />
                  <p className="mb-0 ml-2">Message</p>
                </span>
              </Button>
            </div>
          </div>
          <hr />
          <div>
            <p className="font-weight-bold lead">Specialities</p>
            <p>{`Innovation • Artificial Intelligence • Services Operations Management • Technology Strategy`}</p>
          </div>
          <hr />
          <div>
            <p className="font-weight-bold lead">Qualifications</p>
            <p>{`Phd • MS • Btech`}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    course: state.course,
    currentCourse: state.course,
  }),
  { changeLanguage }
)(Instructors);
