import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Tabs,
  Tab,
  Image,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import * as ConsultationStyle from "./consultation.module.css";
import { languageText } from "../Event/language.json";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faTooth, faXRay } from "@fortawesome/free-solid-svg-icons";

import Gallery from "react-grid-gallery";
import { images } from "./images.json";

function Consultation({ currentLanguage, changeLanguage }) {
  const { hash, pathname } = useLocation();
  const {id} = useParams();
  const history = useHistory();

  const handleRegister = () => {
    if (pathname.split("/")[2] !== "" && pathname.split("/").length < 4) {
      history.push(`${pathname.split("/")[2]}/register`);
    }
  };

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [navigationTo, setNavigationTo] = useState("overview");
  const overviewRef = useRef(null);
  const doctorsRef = useRef(null);
  const servicesRef = useRef(null);
  const galleryRef = useRef(null);

  const [clinicDoc, loadingClinicDoc, errorClinicDoc] = useDocument(
    firebase.firestore().collection("clinics").doc(`${id}`)
  );

  // Doctors data
  const [doctorsSnap, loadingDoctorsSnap, errorDoctorsSnap] = useCollection(
    firebase
      .firestore()
      .collection("/clinics/EKWsp2FDV2aWp1F6P963/doctors")
      .orderBy("order_index", "asc")
  );

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#doctors`) {
      doctorsRef && doctorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#services`) {
      servicesRef && servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#gallery`) {
      galleryRef && galleryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Top navigation start */}
      <Navbar
        className=" py-3 py-md-3 bg-graphite shadow-sm"
        variant="dark"
        sticky="top"
      >
        <div className="container d-flex align-items-center justify-content-between px-3">
          <Tabs
            activeKey={navigationTo}
            onSelect={(k) => {
              setNavigationTo(k);
              history.push({
                hash: `#${k}`,
              });
            }}
            id="cta"
            variant="pills"
          >
            <Tab
              eventKey="overview"
              title={languageText[`${currentLanguage}`].overview}
              tabClassName={ConsultationStyle.cta_tabs}
            />
            <Tab
              eventKey="doctors"
              title={`Providers`}
              tabClassName={ConsultationStyle.cta_tabs}
            />
            <Tab
              eventKey="services"
              title={`Services`}
              tabClassName={ConsultationStyle.cta_tabs}
            />
            {/* <Tab
              eventKey="gallery"
              title={`Gallery`}
              tabClassName={ConsultationStyle.cta_tabs}
            /> */}
          </Tabs>
          <Dropdown className="mr-3 small text-white">
            <Dropdown.Toggle
              as="div"
              id="dropdown-basic"
              style={{ cursor: "pointer" }}
            >
              {currentLanguage === "EN" ? "EN" : "FR"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "EN"}
                onClick={() => changeLanguage("EN")}
              >
                {languageText[`${currentLanguage}`].english}
              </Dropdown.Item>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "FR"}
                onClick={() => changeLanguage("FR")}
              >
                {languageText[`${currentLanguage}`].french}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      {/* Top navigation end */}

      <div className="container">
        <div className="row">
          {/* Description panel starts */}
          
          <div className="col-md-8">
            {/* Event cover starts */}
            {/* <div className="container mb-5 mt-3" id="#overview" ref={overviewRef}>
              <div className="row">
                <div className="col-md-12">

                  <Card
                    className={`shadow-sm ${ConsultationStyle.consultation_cover_card}`}
                  >
                    <div
                      className={`rounded-top ${ConsultationStyle.consultation_cover_img}`}
                      style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/dr-reena.jpeg"
                          })`,
                      }}
                    />
                    <Card.Body
                      className={`rounded-bottom py-2 ${ConsultationStyle.consultation_cover_card_body}`}
                    >
                      <Image
                        className={`rounded ${ConsultationStyle.consultation_cover_logo}`}
                        src={`https://events.igesia.co/assets/igesia-logo.png`}
                      />
                      <div
                        className={`text-white ${ConsultationStyle.consultation_cover_content}`}
                      >
                        <p className="h5 mb-0 font-weight-bold">Igesia Health</p>
                        <p className="mb-0">@igesiahealth</p>
                      </div>
                      <div className="">
                        <Button
                          className={ConsultationStyle.register_btn}
                          onClick={handleRegister}
                        >
                          <span className="d-flex align-items-center">
                            <p className="small font-weight-bold mb-0">
                              Book Appointment
                            </p>
                          </span>
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div> */}
            {/* Event cover ends */}

            <div className=" mb-5" ref={overviewRef}>
              <h5 className="text-white mb-3 font-weight-bold">
                 {!loadingClinicDoc && clinicDoc?.data()?.clinic_details["EN"]?.clinic_name}
              </h5>
              <div className={` p-3 rounded shadow-sm ${ConsultationStyle.consultation_cover_card_body}`}>
                <p className="text-white font-weight-light mb-0">
                  {!loadingClinicDoc &&
                    HTMLReactParser(
                      clinicDoc?.data()?.clinic_details["EN"]?.small_description
                    )}
                </p>
              </div>
            </div>

            {/* Doctors start */}
            <div className=" mb-5" id="#doctors" ref={doctorsRef}>
              <h5 className="text-white mb-3 font-weight-bold">
                {/* {languageText[`${currentLanguage}`].speakers} */}
                Providers
              </h5>
              {!loadingDoctorsSnap &&
                doctorsSnap?.docs.map((doctorDoc) => (
                  <div className={` p-3 rounded shadow-sm ${ConsultationStyle.consultation_cover_card_body}`}>
                    {/* <p className="text-white">{JSON.stringify(doctorDoc.data())}</p> */}
                    <div className="d-flex">
                      <img src={doctorDoc.data()?.img_src} className="rounded-circle mr-3" height="80" width="80" />
                      <div>
                        <h5 className="text-white mb-0">
                          {doctorDoc.data()?.first_name}{" "}
                          {doctorDoc.data()?.last_name}
                        </h5>
                        {doctorDoc.data()?.specialties?.map((item) => (
                          <p className="text-white mb-0">{item}</p>
                        ))}
                        <p className="text-white">
                          {HTMLReactParser(doctorDoc.data()?.bio)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* Doctors end */}

            {/* Services start */}
            <div className=" mb-5" id="#services" ref={servicesRef}>
              <h5 className="text-white mb-3 font-weight-bold">
                {/* {languageText[`${currentLanguage}`].speakers} */}
                Services
              </h5>
              <div className={`d-flex text-white mb-3 p-3 rounded shadow-sm ${ConsultationStyle.consultation_cover_card_body}`}>
                <div className="mr-3 " style={{ width: "80px" }} >
                  <FontAwesomeIcon style={{ width: "80px" }} icon={faTooth} size="2x" className="mr-3 mt-1" />
                </div>
                <div>
                  <p className="mb-0 font-weight-bold">Dentistry</p>
                  <p className="mb-0">
                    A professional with 39 years in Education, Administration, Health Care Delivery & adept at leading programs & initiative involving multi-functional & multi-cultural teams with an unwavering passion for delivering service with excellence
                  </p>
                </div>
              </div>
              <div className={`d-flex text-white mb-3 p-3 rounded shadow-sm ${ConsultationStyle.consultation_cover_card_body}`}>
                <div className="mr-3 " style={{ width: "80px" }} >
                  <FontAwesomeIcon style={{ width: "80px" }} icon={faXRay} size="2x" className="mr-3 mt-1" />
                </div>
                <div>
                  <p className="mb-0 font-weight-bold">CT Scan &amp; X-Ray</p>
                  <p className="mb-0">
                    A professional with 39 years in Education, Administration, Health Care Delivery & adept at leading programs & initiative involving multi-functional & multi-cultural teams with an unwavering passion for delivering service with excellence
                  </p>
                </div>

              </div>
              <div className={`d-flex text-white mb-3 p-3 rounded shadow-sm ${ConsultationStyle.consultation_cover_card_body}`}>
                <div className="mr-3 " style={{ width: "80px" }} >
                  <FontAwesomeIcon style={{ width: "80px" }} icon={faBrain} size="2x" className="mr-3 mt-1" />
                </div>
                <div>
                  <p className="mb-0 font-weight-bold">Neurological Treatment</p>
                  <p className="mb-0">
                    A professional with 39 years in Education, Administration, Health Care Delivery & adept at leading programs & initiative involving multi-functional & multi-cultural teams with an unwavering passion for delivering service with excellence
                  </p>
                </div>
              </div>
            </div>
            {/* Services end */}

            {/* Publication start */}
            <div className=" mb-5" id="#services" ref={servicesRef}>
              <h5 className="text-white mb-3 font-weight-bold">
                {/* {languageText[`${currentLanguage}`].speakers} */}
                Publications
              </h5>
              <div className="d-flex" style={{ overflow: "scroll" }}>
                <a target="_blank" href="https://www.amazon.com/gp/product/B093FR41BY/">
                  <img src="https://m.media-amazon.com/images/I/41tviNvueKS._SY346_.jpg" height="200" className="rounded shadow-sm mr-3" />
                </a>
                <a target="_blank" href="https://www.amazon.com/gp/product/B00I0GI6SY/">
                  <img src="https://m.media-amazon.com/images/I/41yhuaVxgsL.jpg" height="200" className="rounded shadow-sm mr-3" />
                </a>
                <a target="_blank" href="https://www.amazon.com/gp/product/B00391L88K/">
                  <img src="https://m.media-amazon.com/images/I/419kD27SjWL.jpg" height="200" className="rounded shadow-sm mr-3" />
                </a>
                <a target="_blank" href="https://www.amazon.com/gp/product/B001U5VJY0/">
                  <img src="https://m.media-amazon.com/images/I/41if3XHoH-L.jpg" height="200" className="rounded shadow-sm mr-3" />
                </a>
                <a target="_blank" href="https://www.amazon.com/gp/product/B01DJDHNCC/">
                  <img src="https://m.media-amazon.com/images/I/51MT1Zw599L.jpg" height="200" className="rounded shadow-sm mr-3" />
                </a>
              </div>


            </div>
            {/* Publication end */}

          </div>
          {/* Description panel Ends */}
          {/* Booking panel starts */}
          <div className="col-md-4">
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-12">
                  <Card className={`d-flex text-white mb-3  rounded shadow-sm ${ConsultationStyle.consultation_cover_card}`}>

           
                    <div
              style={{
                backgroundImage: `url(${clinicDoc?.data()?.clinic_details?.featured_image})`,//`url(${process.env.PUBLIC_URL + "/assets/dr-reena.jpeg"})`,
                height: 150,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
                    <Card.Body>
                      <div className="">
                        <p className="font-weight-bold small mb-1 text-warning">
                          Monday-Friday (10:00 AM to 4:00 PM)
                        </p>
                        <p className="h5 font-weight-bold">₹4,999</p>
                        <Button
                          className={`w-100 ${ConsultationStyle.register_btn}`}
                          onClick={handleRegister}
                        >
                          <span className="d-flex align-items-center justify-content-center">
                            <p className="small font-weight-bold mb-0">
                              Book Appointment
                            </p>
                          </span>
                        </Button>
                      

                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          {/* Booking panel ends */}
        </div>
      </div>


      {/* Gallery start */}
      {/* <div className="container pt-3 pt-md-4" id="#gallery" ref={galleryRef}>
        <h3 className="text-white">
          Gallery
        </h3>
        <Gallery className=" bg-graphite" images={images}/>
      </div> */}
      {/* Gallery end */}
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(Consultation);
