import React from "react";
import Avatar from "react-avatar-edit";

import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob",
      200,
      200
    );
  });

class AvatarUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: props.preview,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
  }

  onClose() {
    this.setState({ preview: null });
    this.props.setPreview(null);
  }

  onCrop(preview) {
    this.setState({ preview });
    this.props.setPreview(preview);
  }

  onBeforeFileLoad = async (elem) => {
    const image = await resizeFile(elem.target.files[0]);
    console.log(image);
  };

  render() {
    return (
      <div className="mx-auto my-4" style={{ width: "310px" }}>
        <Avatar
          width={300}
          height={300}
          cropRadius={0}
          onCrop={this.onCrop}
          onClose={this.onClose}
          onBeforeFileLoad={this.onBeforeFileLoad}
          exportAsSquare={true}
        />
      </div>
    );
  }
}

export default AvatarUpload;
