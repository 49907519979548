import React, { useRef, useEffect, useState } from "react";
import { Card, Accordion } from "react-bootstrap";
import { changeLanguage, setCourseModule } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useCollection } from "react-firebase-hooks/firestore";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import * as CourseStyle from "../course.module.css";
import * as CourseData from "../course.json";
import CourseRedux from "../CourseRedux";
import Chat from "./Chat";
import firebase from "firebase";


function CourseSubContent(props) {
  const { index, section, currentCourseId, setCourseModule } = props;
  const history = useHistory();
  const [modulesData, modulesDataLoading, modulesDataError] = useCollection(
    firebase
      .firestore()
      .collection(`courses/${currentCourseId}/sections/${section.id}/modules`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const modules = modulesData?.docs?.sort((a, b) => a.order_index - b.order_index)?.map((mod) => {
    return { ...mod.data(), id: mod.id };
  });


  return (
    <>
      {modules?.map((content, key) => (
        <div
          className={`${CourseStyle.sub_module_element} row mb-1 rounded`}
          key={key}
          onClick={() => {
            history.push(`module/${section.id}/${content.id}`)
            setCourseModule(modules)
          }}
        >
          {content?.title &&
            <>
              <span className="col-8 small text-white">
                {content.title}
              </span>
            </>
          }
         
        </div>
      ))}     
    </>
  );
}

function CourseContent({ currentLanguage, changeLanguage, currentCourse, setCourseModule }) {
  const pageRef = useRef(null);
  const [sectionsData, sectionsDataLoading, sectionsDataError] = useCollection(
    firebase.firestore().collection(`courses/${currentCourse?.id}/sections`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const sections = sectionsData?.docs?.map((section) => {
    return { ...section.data(), id: section.id };
  });

  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const history = useHistory();

  return (
    <>
      <CourseRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <h2 className="font-weight-bold text-white">
                    Course content
                  </h2>
                  <p className="text-white">
                    {CourseData &&
                      CourseData.learningJourney?.modules?.filter(
                        (item) => item.moduleType !== ""
                      ).length}{" "}
                    sections • 12 lectures • 14h total length
                  </p>
                  {/* Course content */}
                  <div className="mb-3 mb-md-5">
                    {sections?.sort((a, b) => a.order_index - b.order_index).map((item, index) => (
                      <Card key={index} className="mb-3">
                        <Card.Header
                          style={{ cursor: "pointer" }}
                          className={`${CourseStyle.dark_bg} text-white`}
                        >
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <span className="font-weight-bold text-uppercase small">
                                {item.title}
                              </span>
                            </div>
                            <div>
                              {/* <span className="small">{item.details}</span> */}
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body className={`bg-graphite rounded-bottom`}>
                          <CourseSubContent
                            currentCourseId={currentCourse.id}
                            index={index}
                            section={item}
                            setCourseModule={setCourseModule}
                          />
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentCourse: state.course,
  }),
  { changeLanguage, setCourseModule }
)(CourseContent);
