import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Tabs,
  Tab,
  Image,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import * as ProjectStyle from "./project.module.css";

import { projectList, projectTimeline } from "../data";
import ProjectRedux from "./ProjectRedux";
import ProjectNavbar from "./ProjectNavbar";

function Project(props) {
  const history = useHistory();
  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  return (
    <div className="container-fluid px-0 bg-graphite" ref={pageRef}>
      <ProjectRedux />
      {/* Top navigation start */}
      <ProjectNavbar />
      {/* Top navigation end */}

      <div className="container">
        <div className="row py-3 py-md-4">
          <div className="col-md-4 w-100 order-2">
            <Card
              className={`shadow-sm ${ProjectStyle.project_cover_card}`}
              style={{ position: "sticky", top: 70 }}
            >
              <Card.Img src={props?.project?.image} />
              <Card.Body className={`text-white rounded-bottom`}>
                <p className="lead font-weight-bold">{props?.project?.name}</p>
                <p className="font-weight-bold small mb-1 text-warning">
                  {props?.project?.date}
                </p>

                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-white small font-weight-bold mb-1">
                    {props?.project?.peopleList?.length > 0
                      ? props?.project?.peopleList?.length > 1
                        ? `${props?.project?.peopleList?.length} members`
                        : `${props?.project?.peopleList?.length} member`
                      : `No member found`}
                  </p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  style={{ height: 50 }}
                >
                  <div className="d-flex align-items-center">
                    {props?.project?.peopleList?.map(
                      (member, key) =>
                        key < 5 && (
                          <div
                            className="img-fluid rounded-circle shadow-sm border"
                            style={{
                              height: 40,
                              width: 40,
                              backgroundImage: `url(${member.avatar})`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              position: "relative",
                              left: -10 * key,
                            }}
                            title={member.name}
                          />
                        )
                    )}
                  </div>
                  {props?.project?.peopleList?.length > 5 && (
                    <div>
                      <p className="font-weight-bold small mb-0">
                        + {props?.project?.peopleList?.length - 5} more
                      </p>
                    </div>
                  )}
                </div>
                <Button
                  className={`w-100 ${ProjectStyle.enroll_btn}`}
                  onClick={() =>
                    history.push(`/project/${props.match.params.id}/register`)
                  }
                >
                  <span className="d-flex align-items-center justify-content-center">
                    <p className="small font-weight-bold mb-0 text-uppercase">
                      Request to Join
                    </p>
                  </span>
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-8 order-1">
            <div id="#overview">
              <Card className={`shadow-sm border-0 ${ProjectStyle.dark_bg}`}>
                <Card.Body>
                  <h2 className="font-weight-bold text-white">
                    {props?.project?.name}
                  </h2>
                  <p className="text-white ">{props?.project?.date}</p>
                  <p className="text-white">{props?.project?.description}</p>
                </Card.Body>
              </Card>
            </div>
            <div className="mt-3 mt-md-4">
              {projectTimeline.map((item) => {
                return (
                  <Card
                    className={`${ProjectStyle.dark_bg} text-white shadow-sm mb-3 mb-md-4`}
                  >
                    <Card.Header>
                      <div className="d-flex">
                        <div
                          className="img-fluid rounded-circle shadow-sm"
                          style={{
                            height: 50,
                            width: 50,
                            backgroundImage: `url(${item.user.avatar})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          //title={selectedProfile?.name}
                        />
                        <div className="ml-2">
                          <div className="d-flex align-items-center">
                            <p className="mb-0 mr-2">{item.user.name}</p>
                            <p className="mb-0 small text-muted">{`6:34 PM`}</p>
                          </div>
                          <p className="mb-0 small text-muted">
                            {item.user.designation}
                          </p>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <p className="">{item.content}</p>
                      <Card.Img
                        src={item.photoURL}
                        className="rounded img-fluid"
                        variant="bottom"
                      />
                      {/* <div
                        style={{
                          backgroundImage: `url(${item.photoURL})`,
                          height: 250,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      /> */}
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
  }),
  { changeLanguage }
)(Project);
