import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Accordion,
  Card,
  Image,
  Button,
  Tabs,
  Tab,
  Dropdown,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faPaperPlane,
  faQuestionCircle,
  faClipboardList,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import * as CourseData from "../course.json";
import * as CourseStyle from "../course.module.css";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import { courseList, SAMPLE_ROOMS } from "../../data";
import CourseRedux from "../CourseRedux";
import Chat from "./Chat";

function Dashboard({
  currentLanguage,
  changeLanguage,
  currentCourse,
  courseSections,
}) {
  const history = useHistory();
  const { hash, pathname } = useLocation();

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [courseContentKey, setCourseContentKey] = useState(0 + "ac");

  const [navigationTo, setNavigationTo] = useState("dashboard");
  const overviewRef = useRef(null);
  const outcomesRef = useRef(null);
  const instructorsRef = useRef(null);
  const courseContentRef = useRef(null);
  const certificationRef = useRef(null);
  const benefitsRef = useRef(null);

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#outcomes`) {
      outcomesRef && outcomesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#instructors`) {
      instructorsRef &&
        instructorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#course-content`) {
      courseContentRef &&
        courseContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#certification`) {
      certificationRef &&
        certificationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#benefits`) {
      benefitsRef && benefitsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <>
      <CourseRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <h2 className="font-weight-bold text-white">
                {currentCourse?.name}
              </h2>

              {/* Dashboard starts */}
              <div
                className="row"
                //id="#overview"
                //ref={overviewRef}
              >
                <div className="col-md-12 mb-4">
                  <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <h5 className="text-white font-weight-bold">
                          Progress
                        </h5>
                        <div className="flex-grow-1 text-muted text-right">
                          <p className="mb-0 small">15 hours remaining</p>
                        </div>
                      </div>
                      <div>
                        <ProgressBar now={23} label={`23%`} />
                      </div>
                      <div className="mt-2">
                        <Button
                          variant="success"
                          size="sm"
                          className="text-uppercase"
                        >
                          Resume
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-md-12">
                  {/* Upcoming Live Sessions */}
                  <div
                    className=""
                    //id="#outcomes"
                    //ref={outcomesRef}
                  >
                    <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                      <Card.Header>
                        <h3 className="text-white">Upcoming Live Sessions</h3>
                      </Card.Header>
                      <Card.Body>
                        {/* SESSION 1 */}
                        <div className="d-flex align-items-center mb-2">
                          <div className="col-3">
                            <p className="text-white font-weight-bold">
                              21 July
                            </p>
                          </div>
                          <div className="col-5">
                            <p className="text-white">What is AI?</p>
                          </div>
                          <div className="col-4 d-flex align-items-center">
                            <div className="px-2">
                              <p className="text-muted mb-0">30 min</p>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 justify-content-center">
                              <Button
                                size="sm"
                                onClick={() => window.open("class/live", "_blank")}
                              >
                                Join Now
                              </Button>
                            </div>
                          </div>
                        </div>

                        {/* SESSION 2 */}
                        <div className="d-flex align-items-center mb-2">
                          <div className="col-3">
                            <p className="text-white font-weight-bold">
                              24 July
                            </p>
                          </div>
                          <div className="col-5">
                            <p className="text-white">Importance of AI</p>
                          </div>
                          <div className="col-4 d-flex align-items-center">
                            <div className="px-2">
                              <p className="text-muted mb-0">30 min</p>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 justify-content-center">
                              <Button size="sm">Join in 25 hrs</Button>
                            </div>
                          </div>
                        </div>

                        {/* SESSION 3 */}
                        <div className="d-flex align-items-center">
                          <div className="col-3">
                            <p className="text-white font-weight-bold">
                              30 July
                            </p>
                          </div>
                          <div className="col-5">
                            <p className="text-white">QnA Session</p>
                          </div>
                          <div className="col-4 d-flex align-items-center">
                            <div className="px-2">
                              <p className="text-muted mb-0">30 min</p>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 justify-content-center">
                              <Button size="sm">Join in 2 days</Button>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              {/* Dashboard ends */}

              <div className="py-3 py-md-5">
                {/* Rooms start */}
                {/* <div className="mb-3 mb-md-5">
                  <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                    <Card.Header>
                      <h3 className="text-white">Rooms</h3>
                    </Card.Header>
                    <Card.Body>
                      {SAMPLE_ROOMS.map((room, key) => (
                        <a>
                          <div className="d-flex align-items-center">
                            <div className="mr-2">
                              <div
                                className="shadow-sm rounded-circle"
                                style={{
                                  backgroundImage: `url(${room.roomPic})`,
                                  height: 50,
                                  width: 50,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-white mb-0 font-weight-bold">
                                {room.roomType === 1 && (
                                  <FontAwesomeIcon
                                    icon={faLock}
                                    className="text-success"
                                    size="sm"
                                  />
                                )}{" "}
                                {room.roomName}
                              </p>
                              <p className="text-light mb-0 small">
                                <b>{room.lastMessage?.user?.name}: </b>
                                {room.lastMessage?.content}
                              </p>
                            </div>
                            <div>
                              <a
                                href={`https://console.igesia.co/${room.inviteCode}`}
                                target="_blank"
                                className="rounded-sm btn btn-sm btn-outline-light"
                              >
                                Join room
                              </a>
                            </div>
                          </div>
                          {((SAMPLE_ROOMS.length - 1) != key) && <hr/>}
                        </a>
                      ))}
                    </Card.Body>
                  </Card>
                </div> */}
                {/* Rooms end */}

                {/* Course content */}
                <div id="#course-content" ref={courseContentRef}>
                  <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                    <Card.Header>
                      <h3 className="text-white">Course content</h3>
                    </Card.Header>
                    <Card.Body>
                      <p className="text-white">
                        {CourseData &&
                          CourseData.learningJourney?.modules?.filter(
                            (item) => item.moduleType !== ""
                          ).length}{" "}
                        sections • 12 lectures • 14h total length
                      </p>

                      <Accordion
                        activeKey={courseContentKey}
                        onSelect={setCourseContentKey}
                      >
                        {courseSections
                          ?.sort((a, b) => a.order_index - b.order_index)
                          ?.map((item, index) => (
                            <Card key={index}>
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={index + "ac"}
                                style={{ cursor: "pointer" }}
                                className={`${CourseStyle.dark_bg} text-white`}
                              >
                                <div className="d-flex">
                                  <div>
                                    <FontAwesomeIcon
                                      icon={
                                        courseContentKey === index
                                          ? faAngleUp
                                          : faAngleDown
                                      }
                                      className="mr-2"
                                    />
                                  </div>
                                  <div className="flex-grow-1">
                                    <span className="font-weight-bold text-uppercase small">
                                      {item.title}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="small">
                                      {item.details}
                                    </span>
                                  </div>
                                </div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={index + "ac"}>
                                <Card.Body className={`bg-graphite`}>
                                  {item?.subModules.map((content, key) => (
                                    <div
                                      className="row mb-2"
                                      key={key}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        history.push(
                                          `module/${index + 1}/${key + 1}`
                                        )
                                      }
                                    >
                                      <span className="col-8 small text-white">
                                        {content.title}
                                      </span>
                                      <span className="col-4 text-right text-muted small">
                                        {content.length}
                                        {content.isQuiz && (
                                          <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                          />
                                        )}
                                        {content.isAssignment && (
                                          <FontAwesomeIcon
                                            icon={faClipboardList}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  ))}
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          ))}
                      </Accordion>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>

            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentCourse: state.course,
    courseSections: state.courseSection,
  }),
  { changeLanguage }
)(Dashboard);
