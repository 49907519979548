import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Accordion, Card, Tabs, Tab, Button } from "react-bootstrap";

// import * as EventStyle from "../event.module.css";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import EventChat from "./EventChat";
import { removeTags } from "../../../utils";

function Event({ currentLanguage, changeLanguage, darkTheme, currentEvent }) {
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const [showChat, setShowChat] = useState(false);
  const user = firebase.auth().currentUser;

  const { id } = useParams();

  const [key, setKey] = useState(0);

  const schedule_category_enabled =
    currentEvent?.schedule_category_enabled ?? "";

  const schedule_category_layout = currentEvent?.schedule_category_layout ?? "";

  const [categorySnapshot, loadingCategory, errorCategory] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/categories`)
      .orderBy("order_index", "asc")
  );

  const categoryOptions = categorySnapshot?.docs.map((category) => {
    return {
      value: category.id,
      label:
        category?.data()[currentLanguage]?.title ?? category?.data()?.title,
    };
  });

  // Agenda data
  const [scheduleSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/schedule`)
      .orderBy("starts_at", "asc")
  );

  const [stageSnapshot, loadingStage, errorStage] = useCollection(
    firebase.firestore().collection(`/events/${currentEvent?.id}/stages`)
  );

  const [currentUserDoc, currentUserLoading, currentUserError] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/users`)
      .doc(`${user.uid}`)
  );
  const currentUserCategory = currentUserDoc?.data()?.category ?? "";
  const currentUserRole = currentUserDoc?.data()?.user_role ?? 0;

  const stageOptions =
    stageSnapshot?.docs.map((stage) => ({
      value: stage.id,
      label: stage.data().stage_name,
    })) ?? [];

  // speakers, category, stage name handler
  const nameHandler = (type, id) => {
    const arrayToTraverse = type === "stage" ? stageOptions : [];
    if (arrayToTraverse?.length) {
      return arrayToTraverse.find((element) => element.value === id);
    }
  };

  const agenda = useMemo(() => {
    return scheduleSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [scheduleSnapshot]);

  const agendaUpcoming = useMemo(() => {
    return agenda
      ?.sort((a, b) => a.starts_at - b.starts_at)
      ?.filter((item) => item.ends_at > Date.now());
  }, [agenda]);

  //   Speakers data
  const [speakerSnapshot, loadingSnapshot, errorSnapshot] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/speakers`)
      .orderBy("order_index", "asc")
  );

  const allSpeakers = useMemo(() => {
    return speakerSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        ...fac.data(),
      };
    });
  }, [speakerSnapshot]);

  const showAgenda = (agenda, role, category) => {
    let show = false;

    if (!agenda?.assignedTo || !agenda?.assignedToAll) {
      show = true;
    }

    if (agenda.assignedToAll || role === 1) {
      show = true;
    } else if (
      !agenda?.assignedTo ||
      !agenda?.assignedToAll ||
      !agenda.assignedToCategoryMultiple
    ) {
      show = true;
    } else {
      if (agenda.assignedToCategoryMultiple) {
        if (agenda.assignedToCategory.includes(category)) {
          show = true;
        } else show = false;
      } else {
        if (agenda.assignedToCategory === category) show = true;
        else show = false;
      }
    }
    return show;
  };

  const AgendaCard = ({
    item,
    currentUserRole,
    currentUserCategory,
    scheduleLangData,
  }) => {
    return (
      <>
        {showAgenda(item, currentUserRole, currentUserCategory) && (
          <div className="col-md-12 mb-3">
            <Card
              className={
                darkTheme
                  ? item?.ends_at < Date.now()
                    ? agendaUpcoming && agendaUpcoming[0]?.id === item.id
                      ? `bg-dark shadow-sm border border-primary`
                      : `bg-dark shadow-sm border border-dark`
                    : agendaUpcoming && agendaUpcoming[0]?.id === item.id
                    ? `bg-dark shadow-sm border border-primary`
                    : `bg-dark shadow-sm border border-dark`
                  : item?.ends_at < Date.now()
                  ? agendaUpcoming && agendaUpcoming[0]?.id === item.id
                    ? `bg-light shadow-sm border border-primary`
                    : `bg-light shadow-sm border border-light`
                  : agendaUpcoming && agendaUpcoming[0]?.id === item.id
                  ? `bg-white shadow-sm border border-primary`
                  : `bg-white shadow-sm border border-light`
              }
            >
              <Card.Header
                className={
                  darkTheme
                    ? item?.ends_at < Date.now()
                      ? "border-0 bg-dark mb-0 pb-0"
                      : "border-0 bg-dark mb-0 pb-0"
                    : item?.ends_at < Date.now()
                    ? "border-0 bg-light mb-0 pb-0"
                    : "border-0 bg-white mb-0 pb-0"
                }
              >
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    className={
                      item?.ends_at < Date.now()
                        ? "small mb-0 text-muted"
                        : "small mb-0"
                    }
                  >
                    {moment(item?.starts_at).format("MMMM Do HH:mm")} -{" "}
                    {moment(item?.ends_at).format("HH:mm")} ·{" "}
                  </p>
                  <div>
                    <AddToCalendar
                      style={{ zIndex: 10 }}
                      event={{
                        name: item["EN"].title,
                        details: item?.stage
                          ? `${item["EN"].description}  <p><a href=${item.stage.stage_link}>${item.stage.stage_link}</a></p>`
                          : `${item["EN"].description}`,
                        startsAt: item.starts_at,
                        endsAt: item.ends_at,
                        location:
                          currentUserRole >= 1
                            ? item.start_url ?? item.meeting_url
                            : item.meeting_url,
                      }}
                    >
                      Add to calendar
                    </AddToCalendar>
                  </div>
                </div>
              </Card.Header>
              <Card.Body
                onClick={() => history.push(`/event/${id}/agenda/${item.id}`)}
                style={{ cursor: "pointer" }}
                className={
                  item?.ends_at < Date.now()
                    ? `mt-0 pt-0 text-muted`
                    : `mt-0 pt-0`
                }
              >
                <h4 className="text-s-10 mb-1">{scheduleLangData.title}</h4>
                <p className="small">
                  {scheduleLangData.description.length > 250
                    ? parse(
                        removeTags(scheduleLangData.description, " ").substring(
                          0,
                          250
                        ) +
                          "..." +
                          "<a href=''>Read more</a>"
                      )
                    : parse(scheduleLangData.description)}
                </p>
                <div className="row">
                  {item?.speakers?.map((speaker, key) => {
                    const facultyData = allSpeakers?.filter(
                      (item) => item.uid === speaker
                    )[0];

                    return (
                      <div
                        key={key}
                        className="col-md-6 d-flex align-items-center justify-content-center"
                        title={facultyData?.first_name}
                        style={{ cursor: "pointer" }}
                        // onClick={() => {
                        //   setShowProfile(true);
                        //   setSelectedProfile(facultyData);
                        // }}
                      >
                        <img
                          className="rounded-circle"
                          height="50"
                          width="50"
                          src={facultyData?.img_src}
                          title={facultyData?.first_name}
                          alt=""
                        />
                        <div className="flex-grow-1 ml-2">
                          <h6 className="mb-1">
                            {facultyData?.first_name} {facultyData?.last_name}
                          </h6>
                          <p className="small mb-0">
                            {facultyData?.designation}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </>
    );
  };

  const AgendaCardModular = ({
    key,
    item,
    currentUserRole,
    currentUserCategory,
    scheduleLangData,
  }) => {
    return (
      <>
        {showAgenda(item, currentUserRole, currentUserCategory) && (
          <div key={key} className="mb-2">
            <Card
              className={
                darkTheme
                  ? item?.ends_at < Date.now()
                    ? agendaUpcoming && agendaUpcoming[0]?.id === item.id
                      ? `bg-dark border-primary`
                      : `bg-dark border-dark`
                    : agendaUpcoming && agendaUpcoming[0]?.id === item.id
                    ? `bg-dark border-primary`
                    : `bg-dark border-dark`
                  : item?.ends_at < Date.now()
                  ? agendaUpcoming && agendaUpcoming[0]?.id === item.id
                    ? `bg-light border-primary`
                    : `bg-light border-light`
                  : agendaUpcoming && agendaUpcoming[0]?.id === item.id
                  ? `bg-white border-primary`
                  : `bg-white border-secondary`
              }
            >
              <Card.Body
                className={
                  item?.ends_at < Date.now() ? `p-2  text-muted` : `p-2`
                }
                onClick={() => history.push(`/event/${id}/agenda/${item.id}`)}
                style={{ cursor: "pointer" }}
              >
                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      className={
                        item?.ends_at < Date.now()
                          ? "small mb-0 text-muted"
                          : "small mb-0"
                      }
                    >
                      {moment(item?.starts_at).format("MMMM Do HH:mm")} -{" "}
                      {moment(item?.ends_at).format("HH:mm")} ·{" "}
                    </p>
                  </div>
                  <h4 className="text-s-10 mb-0">{scheduleLangData.title}</h4>
                </div>
                <div className="row small">
                  {item?.speakers?.map((speaker, key) => {
                    const facultyData = allSpeakers?.filter(
                      (item) => item.uid === speaker
                    )[0];

                    return (
                      <div
                        key={key}
                        className="col-md-6 d-flex align-items-center justify-content-center"
                        title={facultyData?.first_name}
                        style={{ cursor: "pointer" }}
                        // onClick={() => {
                        //   setShowProfile(true);
                        //   setSelectedProfile(facultyData);
                        // }}
                      >
                        <img
                          className="rounded-circle"
                          height="50"
                          width="50"
                          src={facultyData?.img_src}
                          title={facultyData?.first_name}
                          alt=""
                        />
                        <div className="flex-grow-1 ml-2">
                          <h6 className="mb-1">
                            {facultyData?.first_name} {facultyData?.last_name}
                          </h6>
                          <p className="small mb-0">
                            {facultyData?.designation}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            <div className="container-fluid px-0 ">
              {/* Agenda start */}
              <div className="container" id="#agenda">
                <h3 className="mb-3">
                  {currentEvent?.type === 1 ? "Live Sessions" : "Agenda"}
                </h3>

                {schedule_category_layout ? (
                  <div>
                    {categoryOptions?.map((category, index) => {
                      return (
                        <Card key={index} className="mb-3">
                          <Card.Header>
                            <h5 className="m-0">{category.label}</h5>
                          </Card.Header>
                          <Card.Body className="">
                            <div className="w-100">
                              {agenda?.map((item, idx) => {
                                const scheduleLangData = {
                                  ...item[currentLanguage],
                                  id: item.id,
                                };
                                if (category.value === item.category)
                                  return (
                                    <AgendaCardModular
                                      key={idx}
                                      item={item}
                                      currentUserRole={currentUserRole}
                                      currentUserCategory={currentUserCategory}
                                      scheduleLangData={scheduleLangData}
                                    />
                                  );
                              })}
                            </div>
                          </Card.Body>
                        </Card>
                      );
                    })}
                  </div>
                ) : schedule_category_enabled ? (
                  <div>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(+k)}
                    >
                      {categoryOptions?.map((category, index) => {
                        return (
                          <Tab
                            eventKey={index}
                            title={category.label}
                            tabClassName={
                              key === index ? "text-primary" : "text-dark"
                            }
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row mt-3">
                                  {agenda?.map((item, idx) => {
                                    const scheduleLangData = {
                                      ...item[currentLanguage],
                                      id: item.id,
                                    };
                                    if (category.value === item.category)
                                      return (
                                        <AgendaCard
                                          item={item}
                                          currentUserRole={currentUserRole}
                                          currentUserCategory={
                                            currentUserCategory
                                          }
                                          scheduleLangData={scheduleLangData}
                                        />
                                      );
                                  })}
                                </div>
                              </div>
                            </div>
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </div>
                ) : (
                  <div>
                    <div className="row mt-3">
                      {agenda?.length > 0 ? (
                        <>
                          {agenda?.map((item, key) => {
                            const scheduleLangData = {
                              ...item[currentLanguage],
                              id: item.id,
                            };

                            return (
                              <AgendaCard
                                item={item}
                                currentUserRole={currentUserRole}
                                currentUserCategory={currentUserCategory}
                                scheduleLangData={scheduleLangData}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <p className="small m-2 text-dark">
                          No Agenda available
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Agenda end */}
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  { changeLanguage }
)(Event);
