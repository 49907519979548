import React, { useRef, useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  setCourseModule,
  setCourseSubModule,
  changeLanguage,
} from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import firebase from "firebase";
import * as CourseStyle from "../course.module.css";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import CourseRedux from "../CourseRedux";
import ReadMore from "../../ReadMore";
import Quiz from "../../Apps/Quiz";
import Poll from "../../Apps/Poll";
import Assignment from "../../Apps/Assignment";
import Chat from "./Chat";
import ReactPlayer from "react-player";
import { useDocument } from "react-firebase-hooks/firestore";
import parse from "html-react-parser";

function CourseModule({
  currentLanguage,
  changeLanguage,
  currentCourse,
  currentModule,
  currentSubModule,
  setCourseModule,
  setCourseSubModule,
}) {
  const history = useHistory();

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const { module_id, sub_module_id } = useParams();
  const { pathname } = useLocation();

  const [value, loading, error] = useDocument(
    firebase
      .firestore()
      .doc(
        `courses/${currentCourse?.id}/sections/${module_id}/modules/${sub_module_id}`
      ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (value) {
      setCourseSubModule({ ...value.data(), id: value.id });
    }
  }, [module_id, sub_module_id, pathname, currentCourse]);

  return (
    <>
      <CourseRedux />

      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className="">
                <div className="mb-3">
                  <Button
                    variant="none"
                    className="text-white border rounded"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                      Back
                    </span>
                  </Button>
                </div>
                {/* Course content */}
                <div className="mb-3" id="#course-content">
                  <Card className={`${CourseStyle.dark_bg} shadow-sm`}>
                    <Card.Header>
                      <h3 className="text-white">{currentSubModule?.name}</h3>
                      <p className="text-white mb-0">
                        {currentSubModule?.details}
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center text-white">
                        {currentSubModule?.title && (
                          <>
                            <p className="lead">{currentSubModule.title}</p>
                          </>
                        )}
                      </div>

                      {currentSubModule?.type === 4 && (
                        <Quiz
                          className="text-white"
                          data={{ contentId: currentSubModule.content_id }}
                        />
                      )}

                      {currentSubModule?.type === 2 && (
                        <Assignment
                          className=""
                          data={{ contentId: currentSubModule.content_id }}
                        />
                      )}

                      {currentSubModule?.type === 3 && (
                        <Poll
                          className=""
                          data={{ contentId: currentSubModule.content_id }}
                        />
                      )}

                      <hr />

                      {currentSubModule?.type === 1 && (
                        <>
                          <div className="embed-responsive embed-responsive-16by9 w-100 player-wrapper shadow-sm rounded">
                            <ReactPlayer
                              className="react-player"
                              url={currentSubModule?.video_lecture}
                              controls={true}
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <hr />
                        </>
                      )}

                      {currentSubModule?.description && (
                        <>
                          <p className="font-weight-bold text-white mb-1">
                            Details
                          </p>
                          {currentSubModule?.description ? (
                            
                            <ReadMore
                              className="text-white"
                              full_text={currentSubModule.description}
                              max_length={150}
                            />
                          ) : (
                            <p className="mb-0 small text-white font-italic">
                              No details
                            </p>
                          )}
                          <hr />
                        </>
                      )}

                      {currentSubModule?.extra_reference_material && (
                        <>
                          <p className="font-weight-bold text-white mb-1">
                            Documents
                          </p>
                          {currentSubModule?.extra_reference_material?.length >
                          0 ? (
                            currentSubModule?.extra_reference_material?.map(
                              (doc, key) => (
                                <>
                                  <a
                                    key={key}
                                    href={doc.link}
                                    target="_blank"
                                    className="text-white"
                                  >
                                    <div className="bg-graphite rounded p-2 d-flex align-items-center">
                                      <FontAwesomeIcon
                                        icon={faFileAlt}
                                        className="mr-2"
                                      />
                                      <span>{doc.title}</span>
                                    </div>
                                  </a>
                                  <div className="mb-2"></div>
                                </>
                              )
                            )
                          ) : (
                            <p className="mb-0 small text-white font-italic">
                              No documents
                            </p>
                          )}
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mb-3">
                    <Button
                      variant="none"
                      className="text-white border rounded"
                      onClick={() => {
                        if (currentSubModule?.order_index > 1)
                          // history.push(`${currentModule[currentSubModule?.order_index -1]?.id}`)
                          console.log(
                            currentModule[currentSubModule?.order_index - 1]
                          );
                        //setCourseSubModule()
                      }}
                      disabled={true/*currentSubModule?.order_index < 1*/}
                      size="sm"
                    >
                      <span className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Prev lesson
                      </span>
                    </Button>
                  </div>

                  <div className="mb-3">
                    <Button
                      variant="none"
                      className="text-white border rounded"
                      onClick={() => {
                        if (
                          currentSubModule?.order_index < currentModule.length
                        ) {
                          setCourseSubModule(
                            currentModule[currentSubModule?.order_index]
                          );
                          // history.push(
                          //   `${currentModule[currentSubModule?.order_index].id}`
                          // );
                        }
                      }}
                      disabled={true
                       // parseInt(sub_module_id) ===
                       // currentModule?.subContent?.length
                      }
                      size="sm"
                    >
                      <span className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                        Next lesson (Skip)
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentCourse: state.course,
    currentModule: state.course_module,
    currentSubModule: state.course_sub_module,
  }),
  {
    setCourseModule,
    setCourseSubModule,
    changeLanguage,
  }
)(CourseModule);
