import React, { useState, useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, Form, ProgressBar } from "react-bootstrap";
import moment from "moment";

export default function Poll({ data }) {
  const [scheduleData, scheduleDataLoading, scheduleDataError] = useDocument(
    firebase.firestore().collection(`schedule`).doc(`${data.contentId}`)
  );

  const colors = ["primary", "warning", "danger", "success", "info"];
  const options = ["Yes", "No", "Maybe"];

  const [showResult, setShowResult] = useState(false);
  const responses = useMemo(() => {
    let res = {};
    scheduleData?.data()?.response?.map((item) => {
      console.log(item.optionValue)
      if (res[`${item.optionValue}`]) res[`${item.optionValue}`] += 1;
      else res[`${item.optionValue}`] = 1;
    });
    return res;
  }, [scheduleData]);

  return (
    <div className="">
      {scheduleDataLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Card className="bg-dark text-white mb-3">
            <Card.Header className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="font-weight-bold mb-0">
                  {scheduleData?.data()?.scheduleTitle}
                </h4>
              </div>
              <p className="small mb-0">
                {moment(data?.timestamp).format("LLL")}
              </p>
            </Card.Header>
            <Card.Body className="py-1">
              <Form onSubmit={(e) => e.preventDefault()}>
                <p className="mb-0">
                  <b>Agenda:</b> {scheduleData?.data()?.agenda}
                </p>
                <p className="">
                  <b>Place:</b> {scheduleData?.data()?.place}
                </p>
                {scheduleData?.data()?.options.map((option, key) => {
                  if (
                    scheduleData?.data()?.images &&
                    scheduleData?.data()?.images[key]
                  ) {
                    return (
                      <div>
                        <img
                          src={scheduleData?.data()?.images[key]}
                          height={100}
                          width="auto"
                        />
                        <Form.Check
                          label={option}
                          name={data.contentId}
                          type={"radio"}
                          id={key}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Form.Check
                          label={option}
                          name={data.contentId}
                          type={"radio"}
                          id={key}
                        />
                      </div>
                    );
                  }
                })}
                <div className="d-flex my-3">
                  <Button className="w-100 mr-2" variant="success">
                    <small>Submit</small>
                  </Button>
                  <Button
                    className="w-100 ml-2"
                    variant="secondary"
                    onClick={() => setShowResult((prev) => !prev)}
                  >
                    <small>{showResult ? "Hide" : "Show"} Results</small>
                  </Button>
                </div>
              </Form>
              {showResult && (
                <div>
                  <p className="lead font-weight-bold">
                    Total responses: {scheduleData?.data()?.response?.length}
                  </p>
                  {scheduleData?.data()?.options.map((option, key) => {
                    return (
                      <div>
                        <p>{JSON.stringify(responses)}</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="mb-1" key={key}>
                            {option}
                          </p>
                          <p className="mb-1 small">
                            {responses[`${option}`]
                              ? (responses[`${option}`] /
                                  scheduleData?.data()?.response?.length) *
                                100
                              : 0}
                            % (
                            {responses[`${option}`]
                              ? responses[`${option}`]
                              : 0}{" "}
                            responses)
                          </p>
                        </div>
                        <ProgressBar
                          animated
                          now={
                            responses[`${option}`]
                              ? (responses[`${option}`] /
                                  scheduleData?.data()?.response?.length) *
                                100
                              : 0
                          }
                          className="mb-2"
                          variant={colors[key % 5]}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </Card.Body>
            <Card.Footer>
              <p className="mb-0 small">
                Created by <b>{data?.user?.name}</b>
              </p>
              <p className="mb-0 small">
                Deadline by{" "}
                <b>{moment(scheduleData?.data()?.deadline).format("LLL")}</b>
              </p>
            </Card.Footer>
          </Card>
        </div>
      )}
    </div>
  );
}
