import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";
// import "../../Academy/igesia-styles.scss";
// import "../../Academy/event-console.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useParams } from "react-router";
import {
  faClinicMedical,
  faUsers,
  faTh,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Avatar from "react-avatar";
import firebase from "../../../firebase";
import { useCollectionOnce } from "react-firebase-hooks/firestore";

import { loadState, saveState } from "../../../services/index";

function LeftBar(props) {
  const userDetails = firebase.auth().currentUser;
  const {id} = useParams();

  const [language, setLanguage] = useState("EN");

  useEffect(() => {
    if (loadState("language")) {
      setLanguage(loadState("language"));
    } else {
      saveState("language", "EN");
    }
  }, [loadState("language")]);

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("eventconsole");
  const eventSlug = `/consultation/${id}/`;

  const locations_data = [
    {
        name: "Overview",
        slug: "dashboard",
        icon: faClinicMedical,
        disabled: false,
    },
    {
        name: "Provider",
        slug: "provider",
        icon: faUsers,
        disabled: false,
    }, 
    {
        name: "Services",
        slug: "services",
        icon: faTh,
        disabled: false,
    },
    {
      name: "Calender",
      slug: "calendar",
      icon: faCalendarAlt,
      disabled: false,
  },   
  ];

  useEffect(() => {
    setCurrentLocation(
      location.pathname.replace(eventSlug, "").replace("/", "")
    );
  }, [location]);

  return (
    <>
      {props.mobile ? (
        <div
          className="container-fluid d-block d-md-none"
          style={{
            backgroundColor: "#17191A",
            position: "fixed",
            zIndex: "10",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div className="d-flex justify-content-around">
            {locations_data.map((item, key) => (
              <Link
                key={key}
                className={
                  item.disabled
                    ? "inline-block text-secondary py-3 px-2"
                    : currentLocation === item.slug
                      ? "inline-block text-primary border-bottom border-primary py-3 px-2"
                      : "inline-block text-light py-3 px-2"
                }
                to={!item.disabled && `${process.env.PUBLIC_URL}${eventSlug}${item.slug}`}
              >
                {item.icon && (
                  <FontAwesomeIcon className="mb-0 small" icon={item.icon} size="sm" />
                )}
                <span className="smaller d-none small">{item.name}</span>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="col-md-1 h-100 pt-5 text-center d-none d-sm-block"
          style={{ borderRight: "#CCCCCC", backgroundColor: "#17191A" }}
        >
          {locations_data.map((item, key) => (
            <Link
              key={key}
              className={
                item.disabled
                  ? "mb-5 text-secondary text-decoration-none"
                  : currentLocation === item.slug
                    ? "mb-5 text-primary text-decoration-none"
                    : "mb-5 text-light text-decoration-none"
              }
              to={!item.disabled && `${process.env.PUBLIC_URL}${eventSlug}${item.slug}`}
              style={item.disabled ? { cursor: "no-drop" } : {}}
            >
              {item.logo_image ? (
                <img
                  alt=""
                  className="mb-2 shadow-lg rounded-12"
                  src={item.logo_image}
                  style={{ width: "75%" }}
                />
              ) : (
                item.icon && <FontAwesomeIcon icon={item.icon} style={{ fontSize: 20 }}  />
              )}
              <p className=" mb-5 small">{item.name}</p>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(LeftBar);