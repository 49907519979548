import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import EventManagerNavbar from "./EventManagerNavbar";
import EventNavbar from "./Navbar";
import EventManagerLeftbar from "./EventManagerLeftbar";

const Sponsors = (props) => {
  const [showAddSponsorModal, setShowAddSponsorModal] = useState(false);

  return (
    <>
      <div
        className="container-fluid h-100 py-4"
        style={{ overflow: "hidden" }}
      >
        <h2>Sponsors Management</h2>
        <div className="mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>Selected event</div>
            <div className="d-flex">
              <Button
                className="btn-sm btn-primary mr-2"
                onClick={() => setShowAddSponsorModal(true)}
              >
                Add sponsor
              </Button>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Select event
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Event1</Dropdown.Item>
                  <Dropdown.Item>Event 2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="mt-3">
            <Table borderless responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Website</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <img
                      width="50px"
                      src="https://i.pinimg.com/originals/66/98/d2/6698d28bf4693ddcdf4cf335e41a6c81.png"
                    ></img>
                  </td>
                  <td>Michellin</td>
                  <td>
                    <a href="https://www.michelin.com/en/">www.michelin.com</a>
                  </td>
                  <td>
                    <Button className="btn-sm btn-light mr-2">Edit</Button>
                    <Button className="btn-sm btn-danger">Remove</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* modal for add ticket */}

      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAddSponsorModal}
        onHide={() => setShowAddSponsorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add sponsor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Name" />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Website link</Form.Label>
                <Form.Control type="text" placeholder="Website link" />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Logo</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </div>
            <Button variant="primary" className="w-100" type="submit">
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Sponsors;
