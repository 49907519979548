import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Modal, Form } from "react-bootstrap";
import { API_BASE_URL, PLAN_STATUS, PLAN_CANCEL, USER_WORKSPACES } from "../../config";
import axios from "axios";
import firebase from "firebase";
import Navbar from "./Navbar";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const YourPlan = () => {
  const userDetails = firebase.auth().currentUser;

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [userCurrentPlan, setUserCurrentPlan] = useState({});
  const [cancelPlanModal, setCancelPlanModal] = useState(false);

  const [userWorkSpaces, setUserWorkSpaces] = useState([]);
  const [defaultWorkSpace, setDefaultWorkSpace] = useState({});

  const getWorkspaces = async () => {
    try {
      const res = await axios.post(`${API_BASE_URL}${USER_WORKSPACES}`, {
        user_id: userDetails?.uid,
      });
      if (res.data.status === 200) {
        console.log(res.data.data.user_workspaces);
        let workspaces = res.data.data.user_workspaces.map((item) => {
          return { label: item.workspaceName, value: item.workspaceId };
        });
        setUserWorkSpaces(workspaces);
        setDefaultWorkSpace(workspaces[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userDetails && userWorkSpaces.length == 0) {
      getWorkspaces();
    }
  }, [userDetails]);

  const getCurrentUserPlan = async () => {
    try {
      const res = await axios.post(`${API_BASE_URL}${PLAN_STATUS}`, {
        user_id: userDetails.uid,
        workspace_id: defaultWorkSpace?.value
      });
      if (res.data.status === 200) {
        setUserCurrentPlan(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCurrentUserPlan();
  }, [cancelPlanModal, defaultWorkSpace]);

  const cancelPlanHandler = async () => {
    try {
      const res = await axios.post(`${API_BASE_URL}${PLAN_CANCEL}`, {
        workspace_id: defaultWorkSpace?.value,
        user_id: userDetails.uid,
      });
      if (res.data.status === 200) {
        toast.success("Plan cancelled.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        getCurrentUserPlan();
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  }


  if (!userDetails) {
    return <Redirect to="/pricing" />;
  }


  return (
    <>
      <div className="overflow-hidden" ref={pageRef}>
        <div className="container-fluid pt-3 pb-5 bg-gradient-colourful">
          <div className="container pb-4">
            <Navbar />
            <div className="text-white mb-5 mt-2">
              <h2 className="mb-3 text-s-1">Pricing plans for everyone.</h2>
            </div>
            {userDetails && (<div>
              <h5>Select workspace</h5>
              <Form.Group>
                <Select
                  value={defaultWorkSpace}
                  options={userWorkSpaces}
                  onChange={(e) => {
                    setDefaultWorkSpace(e)
                  }}
                />
              </Form.Group>
            </div>)}
            <div className="row mt-5">
              <div className="col-md-6 mb-4">
                <Card className="">
                  <Card.Header style={{ backgroundColor: "#fff" }}>
                    <h2>Your  Current Plan</h2>
                  </Card.Header>
                  <Card.Body style={{ backgroundColor: "#fff" }}>
                    <div className="">
                      <h3>{`Hi, ${userDetails?.displayName}`}</h3>
                      <h5>{`Plan type: `}{userCurrentPlan.planType === 0 ? "No Plan Active" : "Subscribed"}</h5>
                      <h5><u>{`Plan Details`}</u></h5>
                      <p className="blockquote m-0">{`Rooms Limit: ${userCurrentPlan?.planDetails?.rooms?.limit}`}</p>
                      <p className="blockquote m-0">{`Rooms Used: ${userCurrentPlan?.planDetails?.rooms?.used}`}</p>
                      <p className="blockquote m-0">{`Rooms Available: ${userCurrentPlan?.planDetails?.rooms?.available}`}</p>
                      {console.log(userCurrentPlan)}
                      {!userCurrentPlan?.planCancellationStatus &&
                        <p className="mt-2">{`Expiring on ${moment(userCurrentPlan?.planStartDate).add(1, "M").format("DD MMM YYYY")}`}</p>
                      }
                      {userCurrentPlan?.planCancellationStatus &&
                        <p className="mt-2">{`This plan is cancelled on ${moment(userCurrentPlan?.planCancellationDate).format("DD MMM YYYY")}, you can enjoy the plan benefits till ${moment(userCurrentPlan?.planStartDate).add(1, "M").format("DD MMM YYYY")}`}</p>
                      }
                      <Button
                        className="mb-2"
                        variant="danger"
                        disabled={userCurrentPlan?.planCancellationStatus}
                        onClick={() => setCancelPlanModal(true)}
                      >
                        Cancel Plan
                      </Button>
                      {userDetails && <div className="mt-3 d-flex">
                        <p className="align-items-center mb-0">
                          Not{" "}
                          <b>
                            {userDetails?.displayName} ({userDetails?.email})
                          </b>
                          ?{" "}
                          <a
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await firebase.auth().signOut();

                              toast.dark("Logged out", {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                            }}
                          >
                            Logout
                          </a>
                        </p>
                      </div>}
                    </div>

                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid py-5"
          style={{
            backgroundColor: "#17191A",
            overflow: "hidden"
          }}
        >
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8">
                <h2 className="mb-3 text-s-1 text-green">
                  How others are using igesia.
                </h2>
                <p className="text-s-4 text-light">
                  30+ organisaions are using igesia to run live events, offer
                  synchronous and asynchronous courses, consult and more.
                  Discover some of their stories.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-3 mb-4">
                <div className="gradient-box bg-white">
                  <img
                    src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/cbse.jpg"
                    width="150"
                    className="mb-3"
                    alt="cbse-logo"
                  />
                  <h3 className="mb-5 text-s-7 text-dark">
                    CBSE and Indian Orthodontic Society is using igesia for
                    preventive health teachers' training course
                  </h3>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="gradient-box bg-white">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/goa-university.jpg"
                        width="150"
                        className="mb-3"
                        alt="goa-university-logo"
                      />
                      <h3 className="mb-5 text-s-7 text-dark">
                        Goa University is using igesia for its graduate courses
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="gradient-box bg-white">
                  <img
                    src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/boa.jpg"
                    width="150"
                    className="mb-3"
                    alt="boa-logo"
                  />
                  <h3 className="mb-5 text-s-7 text-dark">
                    Blue Ocean Awards is using igesia for its international
                    awards event
                  </h3>
                </div>
              </div>

              <div className="col-md-3 mb-4">
                <div className="gradient-box bg-white">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/gbsn.jpg"
                        width="150"
                        className="mb-3"
                        alt="gbsn-logo"
                      />
                      <h3 className="mb-5 text-s-7 text-dark">
                        Global Business School Network is using igesia for its
                        teachers' training course
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal centered show={cancelPlanModal} onHide={() => setCancelPlanModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel plan?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCancelPlanModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              cancelPlanHandler();
              setCancelPlanModal(false)
            }
            }>
            Cancel Plan
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default YourPlan;
