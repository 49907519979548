import React, { useState, useEffect } from "react";
import { Card, InputGroup, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as ProjectStyle from "../../Project/project.module.css";
import firebase from "firebase";
function Dashboard() {
  const history = useHistory();
  const userDetails = firebase.auth().currentUser;
  const [post, setPost] = useState({ heading: "", content: "" });
  const [allPost, setAllPost] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    handleDisabled();
  }, [post]);

  const handleDisabled = () => {
    if (post?.content === "") {
      setIsDisabled(true);
    } else setIsDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await firebase
        .firestore()
        .collection("/projects")
        .doc()
        .set({
          heading: post?.heading ?? "",
          content: post?.content ?? "",
          timestamp: new Date().valueOf(),
          isProject: true,
          isDemo: true,
          author: {
            name: userDetails?.displayName ?? "",
            picture: userDetails?.photoURL ?? "",
            designation: userDetails?.designation ?? "",
          },
        });
      toast.success("Project added", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "unable to write to db");
    } finally {
      setPost({ heading: "", content: "" });
    }
  };

  return (
    <>
      <div className=" bg-graphite ">
        <div className="">
          <h2 className="font-weight-bold text-white">Posts</h2>
          <div className="mt-3 mt-md-4 ">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  className="bg-dark text-white"
                  type="text"
                  placeholder="Type your Heading"
                  value={post?.heading}
                  onChange={(e) => {
                    setPost((prev) => {
                      const newState = { ...prev, heading: e.target.value };
                      return newState;
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <p className="small text-secondary mb-0">
                    Recommdended: Min. 50 words (Max. 400 characters)
                  </p>
                </Form.Label>
                <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "bold",
                      "italic",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  className="mb-3 text-dark"
                  data={post?.content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPost((prev) => {
                      const newState = { ...prev, content: data };
                      return newState;
                    });
                  }}
                  style={{ height: 400 }}
                />
              </Form.Group>

              <div className="d-flex align-items-center justify-content-between">
                <Button
                  className="my-2 px-3 px-md-4"
                  variant="light"
                  size="sm"
                  type="submit"
                  disabled={isDisabled}
                >
                  <span className="text-uppercase small font-weight-bold">
                    Submit
                  </span>
                </Button>
                {isSubmitted && (
                  <p className="text-success text-uppercase mb-0">
                    <FontAwesomeIcon icon={faCheck} className="mr-2" /> Posted
                  </p>
                )}
              </div>
            </Form>
            {allPost?.map((item, index) => {
              return (
                <Card
                  className={`${ProjectStyle.dark_bg} text-white shadow-sm mb-3 mb-md-4`}
                >
                  <Card.Header>
                    <div className="d-flex">
                      <div className="ml-2">
                        <h4 className="mb-0 text-white">{item.heading}</h4>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <p className="">{item.content.replace(/<[^>]*>/g, "")}</p>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
  }),
  { changeLanguage }
)(Dashboard);
