import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
  Spinner,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import Schedules from "./schedules.json";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import produce from "immer";

toast.configure();

const BoothCategory = (props) => {
  const { show } = props;
  const [saving, setSaving] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const saveTextDataToStateHandler = (e, lang) => {
    setCategoryData((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [e.target.name]: e.target.value,
        };
      });
      return updatedState;
    });
  };
  const addScheduleToDBHandler = async (category) => {
    try {
      let size = await firebase
        .firestore()
        .collection(`/events/${props.eventId}/boothCategories`)
        .get();

      size = size.size ?? 0;

      let docref = firebase
        .firestore()
        .collection(`/events/${props.eventId}/boothCategories`)
        .doc();

      await docref.set({
        ...category,
        order_index: +size + 1,
      });

      toast.success("Category added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setCategoryData({});
    } catch (e) {
      console.error(e);
      toast.error("Error adding category", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New Category details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                name="title"
                value={categoryData["EN"]?.title}
                onChange={(e) => saveTextDataToStateHandler(e, "EN")}
              />
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titre"
                name="title"
                value={categoryData["FR"]?.title}
                onChange={(e) => saveTextDataToStateHandler(e, "FR")}
              />
            </Form.Group>
          </div>
          <Button
            variant="primary"
            className="w-100"
            onClick={() => addScheduleToDBHandler(categoryData)}
            disabled={saving}
          >
            Save
            {saving ? <Spinner /> : null}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default BoothCategory;
