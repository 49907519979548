import React, { useRef, useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { changeLanguage, setCurrentUser } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import moment from "moment";
import parse from "html-react-parser";
import EventChat from "./EventChat";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Styles.css";
import { Button } from "react-bootstrap";
import Certificate from "./Certificate";
import UpcomingSchedule from "./UpcomingSchedule";
import { API_BASE_URL, EVENT_USER_DETAILS } from "../../../config";
import { postData } from "../../../utils";
toast.configure();

function Event({
  currentLanguage,
  changeLanguage,
  darkTheme,
  setCurrentUser,
  currentUser,
  currentEvent,
}) {
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const current_user = firebase.auth().currentUser;
  const [showChat, setShowChat] = useState(false);

  const overviewRef = useRef(null);
  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  useEffect(async () => {
    // Fetch: User details
    if (current_user && currentEvent?.id != null) {
      const response_user_details = await postData(
        `${API_BASE_URL}${EVENT_USER_DETAILS}`,
        {
          event_id: currentEvent?.id,
          user_id: current_user?.uid,
        }
      );

      if (response_user_details.status == 200) {
        const { data } = response_user_details;

        setCurrentUser(data.userData);

        if (data.custom_form_required && !data.custom_form_filled) {
          history.push("./fill-details");
        }
      }

      if (response_user_details.status == 400) {
        toast.info(
          `Hello ${current_user.email}, looks like you are not registered for this event`,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        history.push("./complete-registration");
      }
    }
  }, [current_user, currentEvent]);

  useEffect(() => {
    if (current_user) {
      // Publish: Email to igesians email list
      var myHeaders = new Headers();
      myHeaders.append("Accept-Charset", "utf-8");

      var formdata = new FormData();
      formdata.append("name", current_user.displayName);
      formdata.append("email", current_user.email);
      formdata.append("list", "892ARa6Qrr5Ba81PPYfP65FQ");
      formdata.append("api_key", "nKAxSMspzFluFgktWFP5");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://sendy.iarani.com/subscribe", requestOptions)
        .then((response) => {
          // response.text()
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => console.log("error", error));
    }
  }, [current_user]);

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  const checkUserIsAdmin = () => {
    if (
      currentUser?.uid === currentEvent?.created_by ||
      currentEvent?.event_manager?.includes(currentUser?.email)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="h-100" ref={pageRef}>
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3"
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            <div className="px-0 pb-md-3 pb-5">
              {/* Event cover starts */}
              <div className="" id="#overview" ref={overviewRef}>
                <h2 className="font-weight-bold">
                  {currentEvent?.event_details?.EN?.event_name}
                </h2>
                <div className="d-flex">
                  {currentEvent?.event_details?.start_date && (
                    <p className="flex-grow-1">
                      {moment(
                        convertSecondstoMilliSeconds(
                          currentEvent?.event_details?.start_date?.seconds
                        )
                      ).format("DD MMMM YYYY")}
                    </p>
                  )}
                  {currentEvent?.certificate_available && <Certificate />}
                </div>

                {currentLanguage === "EN" ? (
                  <p className="mb-1">
                    {currentEvent?.event_details?.EN?.small_description &&
                      parse(currentEvent?.event_details?.EN?.small_description)}
                  </p>
                ) : (
                  <p className="mb-1">
                    {currentEvent?.event_details?.FR?.small_description &&
                      parse(currentEvent?.event_details?.FR?.small_description)}
                  </p>
                )}
                <UpcomingSchedule
                  eventId={currentEvent?.id}
                  eventSlug={id}
                  startsAt={Date.now()}
                />

                <img
                  src={currentEvent?.event_details?.featured_image}
                  className="img-fluid rounded-lg border border-secondary mb-3 w-100"
                />
              </div>
              {/* Event cover ends */}
              {currentUser?.uid === currentEvent?.created_by ||
              currentEvent?.event_manager?.includes(currentUser?.email) ? (
                <>
                  {attendeeCategoryList?.length > 0 && (
                    <div className="mb-3">
                      <p className="mb-1 text-uppercase small">
                        <b>Groups</b>
                      </p>
                      {attendeeCategoryList?.map((item) => (
                        <div className="border rounded shadow-sm p-2 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                              <h5 className="m-0 font-weight-bold">
                                {item.name}
                              </h5>
                              <p className="small mb-0">
                                Chat with your team members here.
                              </p>
                            </div>
                            <Button
                              size="sm"
                              className="text-uppercase"
                              onClick={() => {
                                if (pathname.split("/").length === 4)
                                  history.push(`group/${item.id}`);

                                if (pathname.split("/").length === 5)
                                  history.push(`../group/${item.id}`);
                              }}
                            >
                              Open
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {currentUser && currentUser?.category && (
                    <div className="mb-3">
                      <p className="mb-1 text-uppercase small">
                        <b>Your Group</b>
                      </p>
                      <div className="border rounded shadow-sm p-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="m-0 font-weight-bold">
                              {getAttendeeCategoryName(currentUser?.category)}
                            </h5>
                            <p className="small mb-0">
                              Chat with your team members here.
                            </p>
                          </div>
                          <Button
                            size="sm"
                            className="text-uppercase"
                            onClick={() => {
                              if (pathname.split("/").length === 4)
                                history.push(`group/${currentUser?.category}`);

                              if (pathname.split("/").length === 5)
                                history.push(
                                  `../group/${currentUser?.category}`
                                );
                            }}
                          >
                            Open
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {currentLanguage === "EN" ? (
                <p className="">
                  {currentEvent?.event_details?.EN?.description &&
                    parse(currentEvent?.event_details?.EN?.description)}
                </p>
              ) : (
                <p className="">
                  {currentEvent?.event_details?.FR?.description &&
                    parse(currentEvent?.event_details?.FR?.description)}
                </p>
              )}
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>

      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentUser: state.currentUser,
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  { changeLanguage, setCurrentUser }
)(Event);
