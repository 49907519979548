import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';


// const strip = value => {
//     const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
//     const match = value.match(regExp);
//     return (match && match[2].length === 11)
//         ? <> <span className="d-block mb-2" style={{width: '400px'}}> {value} </span> <iframe style={{ borderRadius: '4px', maxWidth: '400px' }} width="100%" height="225" src={"//www.youtube.com/embed/" + match[2]} frameborder="0" allowfullscreen></iframe> </>
//         : value;
// }


const strip = value => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const regExp_video = /https:\/\/[\S]+?\.mp4/g;
    const match = value.match(regExp);
    const match_video = value.match(regExp_video);
    return (match && match[2].length === 11)
      ? <> <span className="d-block mb-2" style={{width: '400px'}}> {value} </span> <iframe style={{ borderRadius: '4px', maxWidth: '400px' }} width="100%" height="225" src={"//www.youtube.com/embed/" + match[2]} frameborder="0" allowfullscreen></iframe> </>
      : match_video
        ? <>{console.log("Video is ", match_video[0], value)}
          <video width="320" height="240" controls>
            <source src={match_video[0]} type="video/mp4" />
                Your browser does not support the video tag.
          </video>
          </>
          : value;
  }

export default class ReadMore extends Component {
    constructor(props) {
        const MAX_LENGTH = props.max_length ? props.max_length : 100;
        super(props);
        this.state = {
            full_text: props.full_text,
            short_text: props.full_text.slice(0, MAX_LENGTH),
            show_full: false,
            show_needed: props.full_text.length > MAX_LENGTH ? true : false
        };
    }
    componentDidUpdate(prevProps, prevState) {
        const MAX_LENGTH = this.props.max_length ? this.props.max_length : 100;
        if (prevProps.full_text !== this.props.full_text)
            this.setState({
                full_text: this.props.full_text,
                short_text: this.props.full_text.slice(0, MAX_LENGTH),
                show_full: false,
                show_needed: this.props.full_text.length > MAX_LENGTH ? true : false
            });
    }


    render() {
        const { full_text, short_text, show_full, show_needed } = this.state;
        return (
            <>
                {show_needed
                    ? show_full
                        ? full_text.split('\n')
                            .map(item =>
                                <span>
                                    <Linkify className="small">
                                        {strip(item)}
                                    </Linkify>
                                    <br />
                                </span>)
                        : <> <Linkify>{strip(short_text)}</Linkify> ... </>
                    : full_text.split('\n').
                        map(item =>
                            <span>
                            <Linkify>
                                    {strip(item)}
                                </Linkify>
                                <br />
                            </span>)}

                {show_needed
                    ? show_full 
                        ? <a className="text-primary small d-block" style={{cursor: 'pointer'}} onClick={() => this.setState({ show_full: !show_full })}> Less</a> 
                        : <a className="text-primary small d-block" style={{cursor: 'pointer'}} onClick={() => this.setState({ show_full: !show_full })}> More</a>
                    : null}
            </>
        )
    }
}