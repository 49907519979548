import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faCircleNotch, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "./SlotSelection.css";
import axios from "axios";
import moment from "moment";
import firebase from "firebase";
import { Button } from "react-bootstrap";
import Calendar from "react-calendar";
import { connect, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();



const SlotSelection = ({
  currentUser,
  appointmentId,
  setAppointmentId,
  ...props
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [slotSelected, setSlotSelected] = useState(null);
  const [date, setDate] = useState(new Date());
  const [showSlots, setShowSlots] = useState(false);

  const [slots, setSlots] = useState([
    "10:00-10:30",
    "11:00-11:30",
    "11:30-12:00",
    "12:00-12:30",
    "12:30-13:00",
    "13:00-13:30",
    "13:30-14:00",
    "14:00-14:30",
    "14:30-15:00",
    "15:00-15:30",
    "15:30-16:00",
    "16:00-16:30",
    "16:30-17:00",
  ]);
  const [fetchingSlots, setFetchingSlots] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [showShowConfirmBtn, setShowConfirmBtn] = useState("");
  const [showPatientInformationForm, setShowPatientInformation] =
    useState(false);

  const clinicId = useSelector((state) => state.clinicId);

  const clinicDetails = useSelector((state) => state.clinicDetails);

  const [isLoading, setIsLoading] = useState(true);

  const [patientInformation, setPatientInformation] = useState(null);

  const [bookAppointmentData, setBookAppointmentData] = useState({});

  const [doctorId, setDoctorId] = useState("");

  useEffect(() => {
    if (patientInformation) {
      setIsLoading(true);
    }
  }, [patientInformation]);

  const dateChangeHandler = (date) => {
    setDate(date);
    setShowSlots(true);
  };

  const [isSlotSelected, setIsSlotSelected] = useState(false);
  const handleSlotSelect = () => {
    setIsSlotSelected((prev) => !prev);
  };

  const handleRegister = async () => {
    // if (
    //   pathname.split("/")[2] !== "" &&
    //   pathname.split("/")[3] === "register"
    // ) {
    //   history.push(`fill-details`);
    // }
    const keyID = "rzp_test_MUBBgyLeFbvEU8"; //Test mode
    const baseURL = "https://dev.igesia.com"; //Dev mode

    // postData

    let options = {
      key: keyID,
      amount: 499900,//course ? course?.feeValue * 100 : 1000000,
      currency: "INR",
      name: "Igesia",
      description: "Igesia Clinic",//course ? course?.name : "Igesia product",
      image:
        "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6",
      // order_id: orderGeneratedData.rzp_order_id,
      handler: async (response) => {
        // console.log("Handler Response: ", response)
        try {
          let processDetails = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          console.log("processDetails: ", processDetails);

          if (processDetails?.razorpay_payment_id != null) {
            const payment_success = window.localStorage.getItem(
              "payment_success"
            )
              ? JSON.parse(window.localStorage.getItem("payment_success"))
              : [];

            console.log("payment_success: ", payment_success);

            const new_payment_success = {
              id: "1",//course?.id,
              razorpay_payment_id: processDetails?.razorpay_payment_id,
              success: true,
            };

            window.localStorage.setItem(
              "payment_success",
              JSON.stringify([...payment_success, new_payment_success])
            );

            toast.dark(`✅ Payment successful`, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: false,
              autoClose: 3000,
            });
            // if (
            //   pathname.split("/")[2] !== "" &&
            //   pathname.split("/")[3] === "bookappointment"
            // ) {
              history.push(`dashboard`);
           // }
          }
        } catch (error) {
          console.log(error);
        }
      },
      prefill: {
        name: ``,//`${userDetails?.first_name} ${userDetails?.last_name}`,
        email: ``,//`${userDetails?.email_address}`,
        contact: ``//`${userDetails?.phone_number}`,
      },
      notes: {
        address: "Igesia all in one",
        website: window.location.href,
      },
      theme: {
        color: "#191C25",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return false ? (
    <div className="vh-100 bg-graphite text-success d-flex align-items-center justify-content-center flex-column">
      <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-4x" />
      <h6>Loading</h6>
    </div>
  ) : (
    <div className=" bg-graphite">
      <div className="container">
        <div
          //  className="row w-100 mx-0 px-4"
          style={{ overflow: "auto", height: "100vh" }}
          className={` w-100 mx-0 px-4`}
          id="Homepage-patient"
        >
          <div className=" text-center pt-4">
            <div
              className="p-3 rounded-lg "
              style={{ backgroundColor: "#3a3b3c" }}
            >
              <div className=" ">
                <img
                  src={`${
                    clinicDetails?.primary_logo
                      ? clinicDetails.primary_logo
                      : "https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fffd.png?alt=media&token=3e26098e-ed8e-40f5-b0a2-425c43bca42b"
                  }`}
                  height="80px"
                  width="80px"
                  alt="clinic-logo"
                  className="rounded-lg shadow-sm"
                />
              </div>
              <div className="text-dark">
                <h3 className=" text-center my-3 ">
                  {clinicDetails && clinicDetails["EN"]?.clinic_name}
                </h3>
              </div>
              {/* <img
            src={clinicDetails.featured_image}
            alt="logo"
            width={isMobile ? "40%" : "82%"}
            className="rounded-lg border shadow  mt-2"
          /> */}
              <div className="text-dark text-center p-2 "></div>
            </div>
          </div>

          {slotSelected && slotSelected.date && slotSelected.time ? (
            <div
              className=" py-4 px-md-2 px-0"
              style={{ height: "calc(100vh - 50px)", overflowY: "auto" }}
            ></div>
          ) : (
            <div className={` bg-graphite text-white mt-4 pt-4`}>
              <h4 className="">Select a Date & Time </h4>
              <div className="row">
                <div
                  className={
                    !isMobile ? "col-5" : date ? "d-none" : "col-12 text-center"
                  }
                >
                  <Calendar
                    onChange={dateChangeHandler}
                    value={date}
                    minDate={new Date()}
                    className="mx-auto"
                  />
                </div>

                <div
                  className={
                    !isMobile
                      ? "col-7 border-left"
                      : !date
                      ? "d-none"
                      : "col-12 "
                  }
                >
                  {date ? (
                    <div className="position-relative text-center">
                      {isMobile && (
                        <span
                          className="btn btn-primary btn-sm float-right"
                          onClick={() => setDate(null)}
                        >
                          Change date
                        </span>
                      )}
                      <h5 className="font-14 text-muted ">
                        <i>Available time slots on</i>
                      </h5>
                      <h4>{moment(date).format("LL")}</h4>
                      {fetchingSlots ? (
                        <div className="pt-5 text-center">
                          <Spinner animation="border" size="md" />
                        </div>
                      ) : (
                        <div>
                          {slots?.length ? (
                            <div className="m-3 text-center">
                              <div
                                className="mt-4 px-2  "
                                style={{
                                  maxHeight: "40vh",
                                  overflowY: "auto",
                                }}
                              >
                                {slots.map((item) => (
                                  <div className="d-flex my-3">
                                    <label
                                      class="form-check-label"
                                      className="btn btn-outline-primary btn-block "
                                    >
                                      <p className="text-white mb-0">
                                        {item.split("-")[0]}
                                      </p>
                                      <input
                                        class=" "
                                        type="radio"
                                        hidden
                                        name="slots"
                                        value={item.split("-")[0]}
                                        date={date.toDateString()}
                                        onClick={(e) => {
                                          console.log(
                                            e.target.value,
                                            moment(date).format("YYYY-MM-DD")
                                          );

                                          setShowConfirmBtn(item);
                                          setBookAppointmentData({
                                            clinic_id: clinicId,
                                            doctor_id: doctorId,
                                            appointment_date:
                                              moment(date).format("YYYY-MM-DD"),
                                            appointment_start_time:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </label>
                                    {showShowConfirmBtn === item &&
                                      (isSlotSelected ? (
                                        <label
                                          className="btn btn-success btn-block btn-lg mt-0 ml-2"
                                          onClick={handleSlotSelect}
                                        >
                                          Confirmed
                                        </label>
                                      ) : (
                                        <label
                                          className="btn btn-primary btn-block btn-lg mt-0 ml-2"
                                          onClick={handleSlotSelect}
                                        >
                                          Confirm
                                        </label>
                                      ))}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <div className="text-danger text-center py-5">
                              No slots founds!
                              <div className="text-muted font-14">
                                <i>Please select another date...</i>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-danger text-center mt-5">
                      No date selected!
                      <div className="text-muted font-14">
                        <>Please select a date...</>
                      </div>
                    </div>
                  )}
                </div>
                <Button
                  className="form-control m-5"
                  onClick={() => handleRegister()}//history.push(`dashboard`)}
                  disabled={!isSlotSelected}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {/* <IgesiaWaterMark /> */}
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => ({
  currentUser: firebase.auth().currentUser,
  //appointmentData: state.appointmentData,
  //appointmentId: state.appointmentId,
});

export default connect(mapStatesToProps, null)(SlotSelection);
