import {
  faAngleDown,
  faAngleUp,
  faImages,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import { memo, useState } from "react";
import {
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  Accordion,
  Spinner,
} from "react-bootstrap";
import firebase from "firebase";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL, QUIZ_CREATE } from "../../../../config";
import { connect } from "react-redux";

const QuizFields = (props) => {
  const [quizTitle, setQuizTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [types, setTypes] = useState([]);
  const [isMultiple, setIsMultiple] = useState(false);
  const [correctOptionsIndex, setCorrectOptionsIndex] = useState([0]);
  const [saving, setSaving] = useState(false);

  const [currentEventKey, setCurrentEventKey] = useState(1);

  const addQuestion = () => {
    let prevQuestion = [...questions];
    prevQuestion[prevQuestion.length] = "";
    setQuestions(prevQuestion);
    setCurrentEventKey(`${prevQuestion.length}`);

    let prevOptions = [...options];
    prevOptions[prevOptions.length] = [""];
    setOptions(prevOptions);

    let prevCorrectAnswer = [...correctAnswers];
    prevCorrectAnswer[prevCorrectAnswer.length] = 0;
    setCorrectAnswers(prevCorrectAnswer);

    let prevTypes = [...types];
    prevTypes[prevTypes.length] = "single";
    setTypes(prevTypes);
  };

  const makeOptionsHandler = () => {
    let optionsModified = {};
    options.forEach((option, index) => {
      optionsModified = {
        ...optionsModified,
        [Math.floor(100000 + Math.random() * 900000)]: {
          optionImage: null,
          correctAnwser: correctOptionsIndex.includes(index),
          optionValue: option.optionValue,
          sequenceNo: index + 1,
        },
      };
    });
    return optionsModified;
  };

  const handleOptions = (e, key, k) => {
    let prevOptions = [...options];
    let thatOption = [...prevOptions[key]];

    thatOption[k] = e.target.value;
    prevOptions[key] = thatOption;
    // console.log(prevOptions);
    setOptions(prevOptions);
  };

  const handleAnswer = (val, key, optionValue) => {
    let prev = [...correctAnswers];

    if (types[key] === "single") {
      prev[key] = optionValue ? optionValue : options[key][val];
    } else {
      let temp = prev[key] ? prev[key] : [];
      prev[key] = temp.includes(optionValue)
        ? temp.filter((item) => item !== optionValue)
        : [...temp, optionValue];
    }
    setCorrectAnswers(prev);
  };

  const addOption = (i) => {
    console.log(options[i], options, i);
    if (options[i].length < 8) {
      let prevOptions = [...options];
      let thatOption = prevOptions[i];
      prevOptions[i] = [...thatOption, ""];
      setOptions(prevOptions);
    } else {
      console.log("Max options length(8) exceeded");
      return;
    }
  };

  const handleTypes = (val, key) => {
    let prev = [...types];
    prev[key] = val ? "multiple" : "single";
    setTypes(prev);
  };

  const removeOption = (key, k) => {
    let prevOptions = [...options];
    let thatOption = prevOptions[key];
    let prevAnswers = [...correctAnswers];
    if (thatOption[k] === prevAnswers[key]) {
      prevAnswers[key] = thatOption[k + 1] ? thatOption[k + 1] : 0;
      thatOption.splice(k, 1);
      setCorrectAnswers(prevAnswers);
    } else {
      thatOption.splice(k, 1);
    }
    prevOptions[key] = thatOption;
    setOptions(prevOptions);
  };
  const generateQuestions = (questions) => {
    let quiz_questions = [];
    let newOptions = [];
    newOptions = options.map((option, key) =>
      option.map((opt, k) => {
        if (correctAnswers[key] === opt || correctAnswers[key].includes(opt)) {
          return {
            option_value: opt,
            correct_answer: true,
            option_image: null,
            sequence_no: k + 1,
          };
        } else {
          return {
            option_value: opt,
            correct_answer: false,
            option_image: null,
            sequence_no: k + 1,
          };
        }
      })
    );

    questions.map((question, key) =>
      quiz_questions.push({
        sequence_no: key + 1,
        question,
        questionImage: "",
        type: types[key] === "multiple" ? 1 : 0,
        options: newOptions[key],
        //correctAnswer: correct_answers[key],
        weight: null,
      })
    );
    return quiz_questions;
  };

  const addQuiz = async () => {
    try {
      setSaving(true);

      const res = await axios.post(`${API_BASE_URL}${QUIZ_CREATE}`, {
        user_id: "1gHTSDwzWRPW7KAUeTcscDFxs7z2",
        workspace_id: "LgT2sjzoZQwwKMGPnob2",
        room_id: "nqae8GPum5kRPKe9ukud",
        deadline: false,
        quiz_name: quizTitle,
        questions: generateQuestions(questions),
        all_participants: true,
        assigned: [],
        folderId: "",
        save_for_later: false,
        is_graded: false,
      });

      const size = (
        await firebase
          .firestore()
          .collection(
            `/courses/${props.courseId}/sections/${props.sectionId}/modules`
          )
          .get()
      ).docs.length;

      await firebase
        .firestore()
        .collection(
          `/courses/${props.courseId}/sections/${props.sectionId}/modules`
        )
        .doc()
        .set(
          {
            content_id: res.data.data.quiz_id,
            title: quizTitle,
            section: props.sectionId,
            order_index: size,
            type:
              props.addModuleType === "poll"
                ? 3
                : props.addModuleType === "quiz"
                ? 4
                : props.addModuleType === "assignment"
                ? 2
                : props.addModuleType === "lecture_recording"
                ? 1
                : 0,
          },
          {
            merge: true,
          }
        );
      toast.success("Quiz added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error("Error : ", e);
      toast.error("Error", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };

  return (
    <Form>
      <div className="rounded-lg p-2 m-2 bg-dark">
        <p className="mb-2 font-weight-bold text-theme small">Quiz Title</p>
        <InputGroup className="mb-3">
          <FormControl
            size="sm"
            placeholder="Quiz Title"
            aria-label="Quiz Title"
            aria-describedby="quiz-title"
            name="quizTitle"
            autoFocus={true}
            onChange={(e) => setQuizTitle(e.target.value)}
          />
        </InputGroup>
        <Accordion activeKey={currentEventKey} onSelect={setCurrentEventKey}>
          {questions.length > 0 &&
            questions?.map((question, key) => (
              <>
                <Card className="bg-dark my-1 border-0">
                  <div className="d-flex">
                    <Accordion.Toggle
                      eventKey={`${key + 1}`}
                      as={Card.Header}
                      variant="link"
                      className="flex-grow-1 p-2 px-4 pointer border-0 bg-secondary rounded my-2"
                    >
                      <div className="d-flex justify-content-between">
                        <p className="mb-0 flex-grow-1 small font-weight-bold">
                          Question {key + 1}
                        </p>
                        <div>
                          <FontAwesomeIcon
                            icon={
                              currentEventKey === `${key + 1}`
                                ? faAngleUp
                                : faAngleDown
                            }
                            className="text-light"
                          />
                        </div>
                      </div>
                    </Accordion.Toggle>
                    {/* <div
                      className="pointer mt-1 mr-1"
                      title="Delete"
                      onClick={() =>
                        setQuestions((prev) => {
                          const updatedState = produce(prev, (draft) => {
                            draft.splice(key, 1);
                          });
                          return updatedState;
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faTrashAlt} title="Delete" />
                    </div> */}
                  </div>
                  <>
                    <Accordion.Collapse eventKey={`${key + 1}`}>
                      <Card.Body className="pt-1 px-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            placeholder="Question"
                            aria-label="Question"
                            aria-describedby={"question" + key}
                            as="textarea"
                            name="questions"
                            id={key}
                            required={true}
                            onChange={(e) =>
                              setQuestions((prev) => {
                                const updatedState = produce(prev, (draft) => {
                                  draft[key] = e.target.value;
                                });
                                return updatedState;
                              })
                            }
                          />
                        </InputGroup>

                        <Form.Group className="mb-1">
                          <Form.Check
                            label="Multiple answers"
                            value={types[key] === "multiple" ? true : false}
                            checked={types[key] === "multiple" ? true : false}
                            onChange={(e) => {
                              handleTypes(
                                types[key] === "multiple" ? false : true,
                                key
                              );
                            }}
                          />
                        </Form.Group>
                        {options[key]?.map((option, k) => (
                          <>
                            <InputGroup className="mb-1" key={k}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {options[key][k] && (
                                    <Form.Check
                                      onChange={() =>
                                        handleAnswer(k, key, option)
                                      }
                                      type={
                                        types[key] === "single"
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      checked={
                                        types[key] === "single"
                                          ? correctAnswers[key] === option
                                            ? true
                                            : false
                                          : correctAnswers[key] &&
                                            (correctAnswers[key].includes(
                                              option
                                            ) ||
                                              correctAnswers[key].includes(k))
                                          ? true
                                          : false
                                      }
                                      name={"options_" + key}
                                    />
                                  )}
                                </InputGroup.Text>
                              </InputGroup.Prepend>

                              <FormControl
                                placeholder="Option"
                                name={options[key]}
                                value={options[key][k]}
                                id={k}
                                onChange={(e) => handleOptions(e, key, k)}
                                required={true}
                              />
                              <InputGroup.Append
                                onClick={() => removeOption(key, k)}
                                className="pointer"
                              >
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faTimes} />
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </>
                        ))}
                        {/* {options.map((option, k) => (
                          <>
                            <InputGroup className="mb-1" key={k}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {options[k] && (
                                    <Form.Check
                                      name={"options_" + key}
                                      type={!isMultiple ? "radio" : "checkbox"}
                                      onChange={(e) => {
                                        setCorrectOptionsIndex((prev) => {
                                          const updatedState = produce(
                                            prev,
                                            (draft) => {
                                              if (isMultiple) {
                                                if (prev.includes(k)) {
                                                  const index = draft.findIndex(
                                                    (el) => el === k
                                                  );
                                                  draft.splice(index, 1);
                                                } else {
                                                  draft.push(k);
                                                }
                                              } else {
                                                draft.splice(0, draft.length);
                                                draft.push(k);
                                              }
                                            }
                                          );
                                          return updatedState;
                                        });
                                      }}
                                      checked={correctOptionsIndex.includes(k)}
                                    />
                                  )}
                                </InputGroup.Text>
                              </InputGroup.Prepend>

                              <FormControl
                                placeholder="Option"
                                id={k}
                                required
                                onChange={(e) => {
                                  setOptions((prev) => {
                                    const updatedState = produce(
                                      prev,
                                      (draft) => {
                                        draft[k][
                                          Math.floor(
                                            100000 + Math.random() * 900000
                                          )
                                        ] = {
                                          optionImage: null,
                                          // correctAnswer: correctOptionsIndex.includes(
                                          //   index
                                          // ),
                                          optionValue: e.target.value,
                                          // sequenceNo: index + 1,
                                        };
                                      }
                                    );
                                    return updatedState;
                                  });
                                }}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  className="pointer"
                                  onClick={() =>
                                    setOptions((prev) => {
                                      const updatedState = produce(
                                        prev,
                                        (draft) => {
                                          draft.splice(key, 1);
                                        }
                                      );
                                      return updatedState;
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </>
                        ))} */}
                        <InputGroup className="w-100 mb-3">
                          <Button
                            size="sm"
                            className="w-100"
                            onClick={() => {
                              addOption(key);
                            }}
                          >
                            + <small>Add Option</small>
                          </Button>
                        </InputGroup>
                      </Card.Body>
                    </Accordion.Collapse>
                  </>
                </Card>
              </>
            ))}
        </Accordion>

        <InputGroup className="w-100 mb-2">
          <Button
            size="sm"
            variant="outline-light"
            className="w-100"
            onClick={() => {
              addQuestion();
            }}
          >
            + Add Question
          </Button>
        </InputGroup>
      </div>

      <div className="m-2">
        <Button
          variant="primary"
          style={{ width: "100px", marginRight: "5px" }}
          size="sm"
          disabled={saving}
          onClick={() => {
            addQuiz();
          }}
        >
          {saving ? (
            <>
              <Spinner size="sm" animation="border" color="white" /> Adding
            </>
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default memo(connect(mapStateToProps)(QuizFields));
