import React, { useEffect } from "react";
//import Data from "./Data/connectData.json";
//import "./igesia-styles.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faCalendarAlt, faChartPie, faClone, faFile, faKey, faLock, faPoll, faQuestionCircle, faReply, faStickyNote, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import Navbar from "../Timeline/Navbar";

//import Navbar from "./igesiaNav";
//import Footer from "./igesiaFooter";
import { Link } from "react-router-dom";

function Connect() {
  // useEffect(() => {
  //   console.log(Data);
  // }, []);
  return (
    <div className="overflow-hidden">
      <div className="container-fluid pt-3 pb-5 bg-gradient-colourful">
        <div className="container pb-4">
          <Navbar />
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/Igesia-Logo.svg`}
                width="100%"  
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="text-center text-light">
                <h1 className="mb-3" style={{ fontSize: "40px", fontWeight: 700 }}>Supercharge your remote work with igesia rooms.</h1>
                <a
                  href="https://console.igesia.co/"
                  style={{ background: "rgb(118, 60, 247)", borderRadius: "12px" }}
                  className="btn text-white decoration-none font-weight-bold mb-3"
                >
                  Try Rooms
                </a>
                <p className="small">Available for web, macOS, Windows, Linux, iOS and Android</p>
                <Link to={process.env.PUBLIC_URL + "/updates"}  className="text-decoration-none" style={{ background: "rgba(0, 0, 70, 0.12)", transition: "background-color 200ms ease 0s", padding: "6px 11px 6px 13px", fontSize: "13px", fontWeight: "500", borderRadius: "50px" }}>
                  <span style={{ color: "#DDDDDD" }}>What's New:</span>
                  <span style={{ color: "#EFEFEF" }}> Igesia is now live! Learn more →</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "#17191A",
          overflow: "hidden"
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-9">
              <h2 className="mb-3 text-s-1 text-purple">igesia makes remote work <span style={{ color: "#FFFFFF" }}>work</span> for you.</h2>
              <p className="text-s-2" style={{ color: "#FFFFFF" }}>
                We love working remotely. That's why we created a platform that integrates the essential systems required to make remote work more efficient and fun.</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mb-4">
              <div className="gradient-box bg-gradient-purple mb-3" >
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-3 text-s-5 text-deep-purple">
                      Create permanent meeting rooms.</h3>
                    <p className="text-s-6 text-white">Rooms keep your conversations discoverable and organized. When you need to meet repeatedly, create a room to avoid sending invites.</p>
                  </div>
                  <div className="col-md-8">
                    <div style={{ width: "140%" }}>
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/Igesia-Full-App.png" width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6  mb-4">
              <div className="gradient-box bg-gradient-grey-dark" >
                <h3 className="mb-3 text-s-7 text-purple">
                  Keep all your conversations at one place.</h3>
                <p className="text-s-6">
                  <span style={{ color: "#FFFFFF" }} >No need to send links repeatedly. Create rooms once, use forever. </span>
                  <span style={{ color: "#BBBBBB" }} >Every room has fully integrated video meeting, apps, analytics and more.</span>
                </p>

                <div className="text-center">
                  <img src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-mobile-rooms.png" width="50%" />
                </div>

              </div>
            </div>
            <div className="col-md-6  mb-4">
              <div className="gradient-box bg-gradient-light">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3  text-s-7 text-purple">
                      Apps designed for modern work needs.</h3>
                    <p className="text-s-6">
                      <span style={{ color: "#333333" }} >We have designed industry-specific apps to make remote work more efficient. </span>
                      <span style={{ color: "#888888" }} >With our open API architecture, you can design custom apps as well.</span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="text-center">
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-mobile-apps.png" width="50%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "rgb(245, 245, 247)",
          overflow: "hidden"
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-7">
              <h2 className="mb-3 text-s-1 text-blue">igesia is built for <span style={{ color: "#333333" }}>educators</span>.</h2>
              <p className="text-s-4 text-black-333">
                We have built a suite of learning management apps to make remote learning more efficient.</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mb-4">
              <div className="gradient-box bg-gradient-blue  mb-3">
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-3  text-s-5 text-deep-blue">
                      Your lectures, assignments, grades, all at one place.</h3>
                    <p className="text-s-6 text-white">Create permanent classrooms and study groups with embedded Learning Management Apps</p>
                  </div>
                  <div className="col-md-8">
                    <div style={{ width: "140%" }}>
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/Igesia-Teaching-video.png" width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-8 mb-4">
              <div className="gradient-box bg-white  ">
                <h3 className="mb-3  text-s-7 text-blue">
                  Grade your students right inside the platform.</h3>
                <p className="text-s-6">
                  <span style={{ color: "#333333" }} >Automate and simplify assessment with advanced assessment tools within igesia</span>
                  {/* <span style={{ color: "#555555" }} >When you need to stay focused on a topic, use Structured Channels to require each conversation to be its own thread up front.</span> */}
                </p>

                <div style={{ width: "100%" }}>
                  <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/Gradebook.png" width="100%" />
                </div>

              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3  text-s-7 text-purple">
                      Keep your teaching organised with folders.</h3>
                    <p className="text-s-6">
                      <span style={{ color: "#333333" }} >Create folders for each module or chapter. </span>
                      <span style={{ color: "#888888" }} >Folders help you to keep your classroom organised.</span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/folders.png" width="100%" />
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="gradient-box bg-white">
                <h3 className="mb-3 text-s-7 text-blue">
                  Share assignments with your students.</h3>
                <p className="text-s-6">
                  <span style={{ color: "#333333" }} >Prepare poll, quiz and assignment in adavance and launch them while teaching seamlessly. </span>
                  <span style={{ color: "#555555" }} >Grade your students based on response.</span>
                </p>

                <div style={{ width: "100%" }}>
                  <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/assignment.png" width="100%" />
                </div>

              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 text-s-7 text-purple">
                      Use images as quiz options.</h3>
                    <p className="text-s-6">
                      <span style={{ color: "#333333" }} >Use images as options, designed for STEM courses. </span>
                      <span style={{ color: "#888888" }} >Set a deadline to close submissions automatically. Extend deadline, if required.</span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div style={{ width: "100%" }}>
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/quiz-image.png" width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="gradient-box bg-white">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 text-s-7 text-purple">
                      Create and view poll results in real time.</h3>
                    <p className="text-s-6">
                      <span style={{ color: "#333333" }} >Prepare poll, quiz and assignment in adavance and launch them while teaching seamlessly. </span>
                      <span style={{ color: "#888888" }} >Get a summary of results in real time.</span>
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div style={{ width: "100%" }}>
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/poll-results.png" width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "#17191A",
          overflow: "hidden"
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-8">
              <h2 className="mb-3 text-s-1 text-green">igesia is built for health and wellness experts.</h2>
              <p className="text-s-4 text-light">
                We collaborated with doctors to understand their requirements for remote consultations and then built apps to support them.</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mb-4">
              <div className="gradient-box bg-gradient-green" >
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-3 text-s-5 text-deep-green">
                      Your patient data, appointments, conferences, all at one place </h3>
                    <p className="text-s-6 text-white">With igesia, focus on patient care, we take care of the rest.</p>
                  </div>
                  <div className="col-md-8">
                    <div style={{ width: "140%" }}>
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/Igesia-Full-App.png" width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="gradient-box bg-gradient-grey-dark">
                <h3 className="mb-3 text-s-7 text-green">
                  Powerful appointment system to keep your day organised.</h3>
                <p className="text-s-6">
                  <span style={{ color: "#FFFFFF" }} >Supercharge your your schedule with igesia. </span>
                  <span style={{ color: "#BBBBBB" }} >Connect with your existing systems - Practo, Google Calendar and others.</span>
                </p>

                <div style={{ width: "100%" }}>
                  <img className=" mb-3" style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-doctor.png" width="100%" />
                </div>

              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="gradient-box bg-gradient-light">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 text-s-7 text-purple">
                      Create and share prescriptions with patients during consultation.</h3>
                    <p className="text-s-6">
                      <span style={{ color: "#333333" }} >Digital prescriptions discoverable and organized. </span>
                      <span style={{ color: "#888888" }} >Write them while consulting and share with the patient immediately. </span>
                    </p>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div style={{ width: "100%" }}>
                      <img style={{}} src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-prescription.png" width="100%" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid py-5"
        style={{
          backgroundColor: "#F7F7F7",
          overflow: "hidden"
        }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-8">
              <h2 className="mb-3 text-s-1 text-danger">And so much more.</h2>
              <p className="text-s-4 text-black-333">
                igesia is packed with features, but your conversations always come first.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-blue"><FontAwesomeIcon icon={faChartPie} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Analytics</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-purple"><FontAwesomeIcon icon={faLock} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Private Rooms</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-black-333"><FontAwesomeIcon icon={faUsersCog} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Co-Admin</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-green"><FontAwesomeIcon icon={faReply} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Reply</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-danger"><FontAwesomeIcon icon={faClone} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Duplicate Room</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-black-333"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Scheduling</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-purple"><FontAwesomeIcon icon={faPoll} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Poll</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-primary"><FontAwesomeIcon icon={faQuestionCircle} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Quiz</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-green"><FontAwesomeIcon icon={faFile} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Assignment</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-blue"><FontAwesomeIcon icon={faBook} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Gradebook</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-warning"><FontAwesomeIcon icon={faStickyNote} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Notes</h5>
              </div>
            </div>
            <div className="col-md-3 mb-4 col-6">
              <div className="icon-box bg-white text-center w-100 h-100 py-4">
                <span className="text-s-2 text-deep-green"><FontAwesomeIcon icon={faKey} /></span>
                <h5 className="text-s-8 mt-3 mb-0">Encrypted Invite</h5>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <p className="text-s-4 text-black-888">
                Leading organisations worldwide have switched to igesia to supercharge their remote work environment. <span className="text-black-333">Join them.</span>
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/special-olympics.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/hci-maputo.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/goa-university.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/cbse.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/gbsn.jpg"
                width="100%"
              />
            </div>
            <div className="col-md-2 col-6 mb-3">
              <img
                src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/ida.jpg"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Connect;
