import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Accordion,
  Card,
  Image,
  InputGroup,
  Form,
  Button,
  Tabs,
  Tab,
  Dropdown,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faPaperPlane, faPodcast, faTimes, faUser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import ProjectRedux from "../ProjectRedux";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import Avatar from "react-avatar";
import Chat from "./Chat";

function Dashboard({project}) {
  const history = useHistory();
  const { hash, pathname } = useLocation();

  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [courseContentKey, setCourseContentKey] = useState(0 + "ac");

  const [navigationTo, setNavigationTo] = useState("dashboard");
  const overviewRef = useRef(null);
  const outcomesRef = useRef(null);
  const instructorsRef = useRef(null);
  const courseContentRef = useRef(null);
  const certificationRef = useRef(null);
  const benefitsRef = useRef(null);

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#outcomes`) {
      outcomesRef && outcomesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#instructors`) {
      instructorsRef &&
        instructorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#course-content`) {
      courseContentRef &&
        courseContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#certification`) {
      certificationRef &&
        certificationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#benefits`) {
      benefitsRef && benefitsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const [selectedInstructor, setSelectedInstructor] = useState({});
  const [show, setShow] = useState(false);

  
  return (
    <>
      <div className="h-100" ref={pageRef}>
        <ProjectRedux />
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <h2 className="font-weight-bold text-white">Members</h2>

                  <div className="mt-3 mt-md-4 ">
                    <div className="">
                      {/* Upcoming Live Sessions */}


                <InputGroup className="my-2 outerPill">
                  <div className="d-flex align-items-center rounded-lg flex-grow-1  bg-dark">
                    <div className="py-2 pr-2 pl-3">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        id="participantsSearch"
                        className="border-0 px-0 bg-dark text-white rounded"
                        onChange={(e) => {
                          setSearchTerm(e.target.value)
                            const reg = _.escapeRegExp(searchTerm);
                            // console.log(reg,"Reg")
                            const regex = new RegExp(reg, "gi");
                            setRegex(regex)
                          
                          
                        }}
                        placeholder={"Search"}
                        value={searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => setSearchTerm("")}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                </InputGroup>
    


                      <div
                        className="mb-3 mb-md-5"
                      //id="#outcomes"
                      //ref={outcomesRef}
                      >

                    {searchTerm && searchTerm.length > 0 ? (<>
                        {project?.peopleList.filter((item) => {
                      return item.name?.match(regex);
                    }).map((item) => {
                          return (
                            <Card
                            onClick={() => {
                              setSelectedProfile(item);
                              setShowProfile(true);
                            }}
                            className="bg-dark text-white shadow-sm mb-2"
                          >
                            <Card.Body>
                              <div className="d-flex">
                                <div
                                  className="img-fluid rounded-circle shadow-sm border"
                                  style={{
                                    height: 50,
                                    width: 50,
                                    backgroundImage: `url(${item.avatar})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                  title={item.name}
                                />
                                <div className="ml-2">
                                  <p className="mb-0">{item.name}</p>
                                  <span className="small">
                                    {item.email}
                                  </span>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                          );
                        })}
                        </>) :(
                        <>
                        {project?.peopleList.map((item) => {
                          return (
                            <Card
                                  onClick={() => {
                                    setSelectedProfile(item);
                                    setShowProfile(true);
                                  }}
                                  className="bg-dark text-white shadow-sm mb-2"
                                >
                                  <Card.Body>
                                    <div className="d-flex">
                                      <div
                                        className="img-fluid rounded-circle shadow-sm border"
                                        style={{
                                          height: 50,
                                          width: 50,
                                          backgroundImage: `url(${item.avatar})`,
                                          backgroundPosition: "center",
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                        }}
                                        title={item.name}
                                      />
                                      <div className="ml-2">
                                        <p className="mb-0">{item.name}</p>
                                        <span className="small">
                                          {item.email}
                                        </span>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                          );
                        })}
                        </>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal centered={true} show={showProfile} onHide={() => setShowProfile(false)} animation={true}>
              <Modal.Body className="bg-graphite text-white">
                <div
                  title="Close"
                  className="pointer d-flex justify-content-end mb-0"
                  onClick={() => setShowProfile(false)}
                >
                  <FontAwesomeIcon className="text-white" icon={faTimes} />
                </div>
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="img-fluid rounded-circle shadow-sm"
                    style={{
                      height: 120,
                      width: 120,
                      backgroundImage: `url(${selectedProfile?.avatar})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    title={selectedProfile?.name}
                  />
                  <p className="font-weight-bold lead">
                    {selectedProfile?.name}
                  </p>
                  <div className="w-100 d-flex align-items-center justify-content-around">
                    <p className="border-right border-dark w-100 text-lowercase">{`@${selectedProfile?.name?.split(" ")[0]}`}</p>
                    <p className="w-100">{`${selectedProfile?.email}`}</p>
                  </div>
                  <p>{`USA`}</p>
                  <div className="row w-100">
                    <div className="col-sm">
                      <FontAwesomeIcon icon={faGraduationCap} />
                      <p>{`14`}</p>
                    </div>
                    <div className="col-sm">
                      <FontAwesomeIcon icon={faUser} />
                      <p>{`1251`}</p>
                    </div>
                    <div className="col-sm">
                      <FontAwesomeIcon icon={faPodcast} />
                      <p>{`43`}</p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p className="lead font-weight-bold">Specialities</p>
                    <p>{`Innovation • Artificial Intelligence • Services Operations Management • Technology Strategy`}</p>
                  </div>
                  <hr />
                  <div>
                    <p className="lead font-weight-bold">Qualifications</p>
                    <p>{`PhD • MS • Btech`}</p>
                  </div>
                </div>
              </Modal.Body>

            </Modal>

            {/* Chat module */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module ends */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({ 
    currentLanguage: state.currentLanguage, 
    project: state.project
   }),
  { changeLanguage }
)(Dashboard);
