import React, { useState, useMemo, useEffect } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import {
  Card,
  Button,
  Spinner,
  ButtonToolbar,
  Image,
  Badge,
  ProgressBar,
} from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import LeftBar from "../LeftBar";
import NavBar from "../NavBar";
import EventChat from "../EventChat";
import { connect } from "react-redux";
import { API_BASE_URL, POLL_SUBMIT } from "../../../../config/index";
import axios from "axios";
import { toast } from "react-toastify";

const Poll = ({ darkTheme, currentEvent }) => {
  const { poll_id } = useParams();
  const user = firebase.auth().currentUser;

  const [showChat, setShowChat] = useState(false);
  const [response, setResponse] = useState({});
  const [alreadyResponded, setAlreadyResponsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isClosed, setIsClosed] = useState(false);

  const [pollSnapshot, pollDataLoading, pollDataError] = useDocument(
    firebase.firestore().doc(`polls/${poll_id}`)
  );

  const pollData = useMemo(() => {
    if (!pollDataLoading)
      return { ...pollSnapshot?.data(), id: pollSnapshot.id };
    else return {};
  }, [pollSnapshot, pollDataLoading]);

  useEffect(() => {
    if (pollData.response) {
      let myResponse = pollData.response.filter(
        (response) => response.userId === user.uid
      );
      if (myResponse.length === 1) {
        setResponse(myResponse[0]);
        setAlreadyResponsed(true);
        setLoading(false);
      }
    }
    if (pollData.previousDeadline || pollData.deadline < Date.now().valueOf()) {
      setIsClosed(true);
    }
  }, [pollData]);

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("MMM DD YYYY");
  };

  const fooUser = (item, arr) => {
    var a = [],
      b = [],
      prev = "";

    const arrValues = arr.map((item) => item.optionValue);
    arrValues.sort();

    for (var i = 0; i < arrValues.length; i++) {
      if (arrValues[i] !== prev) {
        a.push(arrValues[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arrValues[i];
    }
    var c = [];
    for (var i = 0; i < a.length; i++) {
      c[i] = (b[i] * 100) / arrValues.length;
    }

    //console.log(item, arr, "in foo")

    return (
      <>
        {item &&
          item["options"].map((option, key) => (
            <div>
              {item.images && item.images.length > 0 && (
                <Image
                  src={item.images[key]}
                  style={{ height: 100, width: "auto" }}
                  // onClick={() => {
                  //     setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
                  //     setActiveIndex(key)
                  // }}
                />
              )}
              <p className="mb-1">{option}</p>
              <ProgressBar
                style={{ height: "1rem" }}
                className="mb-1"
                animated
                now={a.indexOf(option) >= 0 ? c[a.indexOf(option)] : "0"}
                label={`${
                  a.indexOf(option) >= 0 ? c[a.indexOf(option)].toFixed(2) : "0"
                } %`}
              />
            </div>
          ))}
        {/* {item.images && <Viewer
                  visible={imageViewerOpen === item.uid ? true : false}
                  onClose={() => setImageViewerOpen(prev => prev === item.uid ? null : item.uid)}
                  images={item.images.map((image, key) => { return {src: image, alt: item.options[key]}})}
                  activeIndex={activeIndex}
                  downloadable={false}
              />} */}
      </>
    );
  };

  const taskResponse = async (option) => {
    let response = await axios.post(`${API_BASE_URL}${POLL_SUBMIT}`, {
      user_id: user.uid,
      room_id: currentEvent?.room_id,
      workspace_id: currentEvent?.workspace_id,
      poll_id: poll_id,
      option_value: option,
    });
    if (response.status === 200) {
      if (darkTheme) {
        toast.dark(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        toast(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    }
  };

  if (pollDataLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            {pollData.createdBy !== user.uid ? (
              <div>
                <Card
                  className={
                    darkTheme
                      ? "shadow-sm rounded bg-dark"
                      : "shadow-sm rounded bg-white"
                  }
                >
                  <Card.Header className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <h4 className="font-weight-bold mb-0">
                        {pollData?.quizName}
                      </h4>
                      <p className="small mb-0">
                        {moment(
                          convertSecondstoMilliSeconds(
                            pollData?.createdAt?.seconds,
                            pollData?.createdAt?.nanoseconds
                          )
                        ).format("LLL")}
                      </p>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-3">
                    <Card className="task">
                      <Card.Header className="bg-white d-flex">
                        <h5> {pollData.title}</h5>
                      </Card.Header>

                      <Card.Body>
                        {alreadyResponded ? (
                          <div className="w-100">
                            {!loading && fooUser(pollData, pollData.response)}

                            <Badge variant="secondary">Your answer</Badge>
                            <p className="mt-0 mb-0">{response.optionValue}</p>
                            <p className="mt-0 mb-0 small">
                              {timeFromNow(response.responseTime)}
                            </p>
                          </div>
                        ) : (
                          <ButtonToolbar
                            aria-label="Toolbar with button groups"
                            className="w-100"
                          >
                            {pollData.options &&
                              pollData.options.map((option, key) => (
                                <div className="col-12 ">
                                  <Button
                                    disabled={pollData.deadline < Date.now()}
                                    className={
                                      darkTheme
                                        ? "w-100 mb-2 border-primary text-white rounded"
                                        : "w-100 mb-2 border-primary text-dark rounded"
                                    }
                                    variant=""
                                    size="sm"
                                    value={option}
                                    onClick={() => taskResponse(option)}
                                    size="sm"
                                  >
                                    {option}
                                  </Button>
                                </div>
                              ))}
                          </ButtonToolbar>
                        )}
                      </Card.Body>
                    </Card>
                  </Card.Body>
                  <Card.Footer>
                    <p className="mb-0 small">
                      Deadline by{" "}
                      <b>{moment(pollData?.deadline).format("LLL")}</b>
                    </p>
                  </Card.Footer>
                </Card>
              </div>
            ) : null}
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(Poll);
