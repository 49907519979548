import React, { useMemo, memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
import { projectList, blogList } from "../../data";
import { connect } from "react-redux";
import { setProject, setBlog } from "../../../store/actions/actions";
import AddConsultancyModal from "./AddConsultancyModal";

const Sidebar = (props) => {
  const history = useHistory();

  const { events, blogs } = props;

  const userDetails = firebase.auth().currentUser;
  const [showAddConsultancyModal, setShowAddConsultancyModal] = useState(false);

  const [consultanciesSnapshot, loadingConsultancies, errorConsultancies] =
    useCollection(
      firebase.firestore().collection("clinics").where("isDemo", "==", true)
    );

  const [coursesSnapshot, loadingCourses, errorCourses] = useCollection(
    firebase.firestore().collection("courses").where("isDemo", "==", true)
  );

  const [projectsSnapshot, loadingProjects, errorProjects] = useCollection(
    firebase.firestore().collection("projects")
  );

  const [organizationSnapshot, loadingOrganization, errorOrganization] =
    useCollection(firebase.firestore().collection("organizations"));

  // derived states

  // const consultancies = useMemo(() => {
  //   return consultanciesSnapshot?.docs?.map((e) => {
  //     return { id: e.id, ...e.data() };
  //   });
  // }, [consultanciesSnapshot]);

  const courses = useMemo(() => {
    return coursesSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [coursesSnapshot]);

  const projects = useMemo(() => {
    return projectsSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [projectsSnapshot]);

  const organizations = useMemo(() => {
    return organizationSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [organizationSnapshot]);

  return (
    <div
      className="text-white h-100 p-3 overflow-auto w-35"
      style={{ background: "#333333" }}
      id="style-4"
    >
      <span className="font-weight-bold text-uppercase text-light small">
        YOUR PROFILE
      </span>

      <Link to="/admin" style={{ color: "inherit", textDecoration: "inherit" }}>
        <div
          className="d-flex my-2 p-2 rounded "
          style={{
            cursor: "pointer",
            border: "1px solid rgba(255, 255, 255, .3)",
          }}
        >
          <Avatar
            className="mr-2"
            textSizeRatio={2}
            size="2.5rem"
            title="Igesia"
            maxInitials={2}
            round
            name={userDetails?.displayName}
          ></Avatar>
          <div className=" pt-2">
            <span className="font-weight-bold ">
              {userDetails?.displayName}
            </span>
          </div>
        </div>
      </Link>
      {/* <div className="mt-4 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          Your Organizations
        </span>
        <Link
          to="/admin/addorganisation"
          className="text-primary ml-auto text-decoration-none"
        >
          <Button
            size="sm"
            variant="link"
            className="text-primary ml-auto text-decoration-none"
          >
            <span className="small" style={{ color: "#89cff0" }}>
              + NEW
            </span>
          </Button>
        </Link>
      </div>
      {organizations?.map((e, idx) => (
        <div className="my-2" key={idx}>
          <div
            className="d-flex my-2 py-2 px-2 rounded"
            style={{
              cursor: "pointer",
              border: "1px solid rgba(255, 255, 255, .3)",
              background: "#212529",
            }}
          >
            <Avatar
              className="mr-2"
              textSizeRatio={2}
              size="2.5rem"
              title={e.organisation_name}
              maxInitials={2}
              src={e.avatar}
              round
              name={e.organisation_name}
            ></Avatar>
            <div className="">
              <p className="my-0 font-weight-bold">{e.organisation_name}</p>
              <p className="my-0 small text-light">Premium plan</p>
            </div>
          </div>
        </div>
      ))} */}

      {/* <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/courses"
            className="text-light ml-auto text-decoration-none"
          >
            Courses
          </Link>
        </span>
        <Link
          to="/admin/add/course"
          className="text-light ml-auto text-decoration-none"
        >
          <Button
            size="sm"
            variant="link"
            className="text-light ml-auto text-decoration-none"
          >
            <span className="small" style={{ color: "#89cff0" }}>
              + NEW
            </span>
          </Button>
        </Link>
      </div>
      {courses?.map((course) => {
        return (
          <a
            href={`/admin/coursemanager/${course.id}`}
            // className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            <div className="my-2">
              <div
                className="d-flex my-2 py-2 px-2 rounded"
                style={{
                  cursor: "pointer",
                  background: "#474773",
                  border: "1px solid rgba(255, 255, 255, .3)",
                }}
              >
                <div className="">
                  <p className="my-0 small">
                    {course.course_details.EN.course_title}
                  </p>
                  <p className="my-0 small text-light">$1200</p>
                </div>
              </div>
            </div>
          </a>
        );
      })} */}

      <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/events"
            className="text-light ml-auto text-decoration-none"
          >
            Events
          </Link>
        </span>
        <Link
          to="/admin/add/event"
          className="text-light ml-auto text-decoration-none"
        >
          <Button
            size="sm"
            variant="link"
            className="text-light ml-auto text-decoration-none"
          >
            <span className="small" style={{ color: "#89cff0" }}>
              + NEW
            </span>
          </Button>
        </Link>
      </div>
      {events?.length ? (
        events?.map((e) => {
          return (
            <a
              href={`/admin/eventmanager/${e.id}/overview`}
              // className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <div className="my-2">
                <div
                  className="d-flex my-2 py-2 px-2 rounded"
                  style={{
                    cursor: "pointer",
                    background: "rgb(103 58 78)",
                    border: "1px solid rgba(255, 255, 255, .3)",
                  }}
                >
                  <div className="">
                    <p className="my-0 small">
                      {e?.event_details?.EN?.event_name}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          );
        })
      ) : (
        <Skeleton
          className="bg-dark"
          animation="wave"
          variant="rect"
          height={50}
          classes={{
            wave: {
              color: "#ffffff",
              background: "#ffffff",
            },
          }}
        />
      )}

      {/* <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/consultancies"
            className="text-light ml-auto text-decoration-none"
          >
            Consultancies
          </Link>
        </span>
        <Button
          size="sm"
          variant="link"
          className="text-light ml-auto text-decoration-none"
          onClick={() => {
            setShowAddConsultancyModal(true);
          }}
        >
          <span className="small" style={{ color: "#89cff0" }}>
            + NEW
          </span>
        </Button>
      </div>
      {!loadingConsultancies ? (
        consultancies?.map((e) => {
          return (
            <a
              href={
                e.isOffice
                  ? `/admin/officemanager/${e.id}`
                  : `/admin/clinicmanager/${e.id}`
              }
              style={{ color: "inherit" }}
            >
              <div className="my-2">
                <div
                  className="d-flex my-2 py-2 px-2 rounded"
                  style={{
                    cursor: "pointer",
                    background: "rgb(116 56 110)",
                    border: "1px solid rgba(255, 255, 255, .3)",
                  }}
                >
                  <div className="">
                    <p className="my-0 small">
                      {e?.clinic_details?.EN?.clinic_name}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          );
        })
      ) : (
        <Skeleton
          className="bg-dark"
          animation="wave"
          variant="rect"
          height={50}
          classes={{
            wave: {
              color: "#ffffff",
              background: "#ffffff",
            },
          }}
        />
      )} */}

      {/* <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/projects"
            className="text-light ml-auto text-decoration-none"
          >
            Projects
          </Link>
        </span>
        <Link
          to="/admin/add/project"
          className="text-light ml-auto text-decoration-none"
        >
          <Button
            size="sm"
            variant="link"
            className="text-light ml-auto text-decoration-none"
          >
            <span className="small" style={{ color: "#89cff0" }}>
              + NEW
            </span>
          </Button>
        </Link>
      </div>
      {projects ? (
        projects?.map((e) => {
          return (
            <a
              href={`/admin/projectmanager/${e.id}`}
              style={{ color: "inherit" }}
            >
              <div className="my-2">
                <div
                  className="d-flex my-2 py-2 px-2 rounded"
                  style={{
                    cursor: "pointer",
                    background: "rgb(103 58 78)",
                    border: "1px solid rgba(255, 255, 255, .3)",
                  }}
                >
                  <div className="">
                    <p className="my-0 small text-capitalize">
                      {e?.heading ?? "Sample Project"}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          );
        })
      ) : (
        <>
          <Skeleton
            className="bg-dark"
            animation="wave"
            variant="rect"
            height={50}
            classes={{
              wave: {
                color: "#ffffff",
                background: "#ffffff",
              },
            }}
          />
        </>
      )} */}

      <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/blogs"
            className="text-light ml-auto text-decoration-none"
          >
            Blogs
          </Link>
        </span>
        <Link
          to="/admin/add/blog"
          className="text-light ml-auto text-decoration-none"
        >
          <Button
            size="sm"
            variant="link"
            className="text-light ml-auto text-decoration-none"
          >
            <span className="small" style={{ color: "#89cff0" }}>
              + NEW
            </span>
          </Button>
        </Link>
      </div>
      {blogs ? (
        blogs?.map((e) => {
          return (
            <div
              onClick={() => {
                history.push(`/admin/blogmanager/${e.id}`);
                props.setBlog(e);
              }}
              // className="text-decoration-none"
              style={{ color: "inherit" }}
            >
              <div className="my-2">
                <div
                  className="d-flex my-2 py-2 px-2 rounded"
                  style={{
                    cursor: "pointer",
                    background: "rgb(58 103 99)",
                    border: "1px solid rgba(255, 255, 255, .3)",
                  }}
                >
                  <div className="">
                    <p className="my-0 small">{e?.heading}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <Skeleton
            className="bg-dark"
            animation="wave"
            variant="rect"
            height={50}
            classes={{
              wave: {
                color: "#ffffff",
                background: "#ffffff",
              },
            }}
          />
        </>
      )}
      <AddConsultancyModal
        show={showAddConsultancyModal}
        onHide={setShowAddConsultancyModal}
      />
    </div>
  );
};
const sideBar = memo(Sidebar);

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
    events: state.myEvents,
    blogs: state.myBlogs,
  }),
  { setProject, setBlog }
)(Sidebar);
