import React from "react";
import { Card } from "react-bootstrap";

import * as TimelineStyle from "./timeline.module.css";

export default function AboutCard() {
  return (
    <Card className={`shadow-sm ${TimelineStyle.about_card} mb-3 mb-md-4`}>
      <Card.Body className="p-3">
        <p className="text-white font-weight-bold mb-1">About</p>
        <p>
          Igesia is a digital learning and commerce platform that lets you
          manage, grow and monetize a knowledge community
        </p>
      </Card.Body>
    </Card>
  );
}
