import React, { useMemo, memo, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
//import { projectList, blogList } from "../../data";
import { connect } from "react-redux";
import { setProject, setBlog } from "../../store/actions/actions";
import AddConsultancyModal from "./Sidebar/AddConsultancyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "./Navbar";
import moment from "moment";
import {
    faEdit, faUserEdit, faShapes, faVideo, faPoll, faCalendarAlt, faClipboardCheck, faClinicMedical, faNotesMedical, faHdd, faFile, faBook, faBookReader, faCheckSquare, faGripHorizontal, faGreaterThanEqual, faListAlt
} from "@fortawesome/free-solid-svg-icons";
import {
    faYoutube
} from "@fortawesome/free-brands-svg-icons";

const Sidebar = (props) => {
    const history = useHistory();

    const { events, blogs } = props;

    const userDetails = firebase.auth().currentUser;
    const [showAddConsultancyModal, setShowAddConsultancyModal] = useState(false);

    const [
        consultanciesSnapshot,
        loadingConsultancies,
        errorConsultancies,
    ] = useCollection(
        firebase.firestore().collection("clinics").where("isDemo", "==", true)
    );

    const [coursesSnapshot, loadingCourses, errorCourses] = useCollection(
        firebase.firestore().collection("courses").where("isDemo", "==", true)
    );

    const [projectsSnapshot, loadingProjects, errorProjects] = useCollection(
        firebase.firestore().collection("projects")
    );

    const convertSecondstoMilliSeconds = (seconds) => {
        return Math.floor(seconds * 1000);
    };

    const [
        organizationSnapshot,
        loadingOrganization,
        errorOrganization,
    ] = useCollection(firebase.firestore().collection("organizations"));

    // derived states

    // const consultancies = useMemo(() => {
    //   return consultanciesSnapshot?.docs?.map((e) => {
    //     return { id: e.id, ...e.data() };
    //   });
    // }, [consultanciesSnapshot]);

    const courses = useMemo(() => {
        return coursesSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [coursesSnapshot]);

    const projects = useMemo(() => {
        return projectsSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [projectsSnapshot]);

    const organizations = useMemo(() => {
        return organizationSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [organizationSnapshot]);

    return (
        <>
            <NavBar />
            <div className="row mt-3">
                <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="rounded py-4 px-3 w-100" style={{
                                        backgroundColor: "lightgoldenrodyellow",
                                        backgroundImage: "url(https://assets.codepen.io/1729459/download-2.png)",
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "bottom right"
                                    }}>
                                        <div className="col-md-8">
                                            <span className="font-weight-bold text-uppercase text-dark small">GET INSPIRED</span>
                                            <h5 className="text-dark mb-3">igesia is transforming knowledge businesses around the world. Here are handpicked stories to inspire you. </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-3">
                            <div className="rounded py-4 px-3 w-100 bg-dark" >
                                <div className="d-flex">
                                    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/9/95/CBSE_new_logo.svg/1200px-CBSE_new_logo.svg.png"
                                        className="mr-3"
                                        height="80px" />
                                    <div>
                                        <span className="font-weight-bold text-uppercase text-light small">Central Board of Secondary Education</span>
                                        <p className="text-light small">
                                            The Central Board of Secondary Education is a national level board of education in India for public and private schools, controlled and managed by Union Government of India. There are approximately 21,271 schools in India and 220 schools in 28 foreign countries affiliated to the CBSE.
                                        </p>
                                    </div>
                                </div>
                                <p className="text-light small font-weight-bold">
                                    CBSE leverages igesia for teacher training.
                                </p>
                                <p className="text-light small mb-0">
                                    Every CBSE school will be provided a virtual Oral Health & Sports Safety room on the digital platform Igesia Connect. 2.	Each digital virtual room will have standardised education material created by IDA for school children and teachers. Indian Dental Association educated the students and teachers on good oral health and hygiene practices and sports safety by live virtual mode by using resource persons (doctors) and resource material available in each school’s own digital room. Schools have access to the virtual digital room on Igesia Connect digital platform to conduct their own health education by the teachers using the material available in the virtual room.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-3">
                        <div className="rounded py-4 px-3 w-100 bg-dark" >
                            <div className="d-flex ">
                                <img src="https://media-exp1.licdn.com/dms/image/C4E0BAQEtF4GY0d23MA/company-logo_200_200/0/1615051586112?e=2159024400&v=beta&t=Pu3ugwNNnVWyGjAH35CEf-eGxiKSPKiQu8PQZ4b8v3w"
                                    className="mr-3"
                                    height="80px" />
                                <div>
                                    <span className="font-weight-bold text-uppercase text-light small">Blue Ocean Awards</span>
                                    <p className="text-light small">
                                        The Blue Ocean Awards are inspired by the Blue Ocean Strategy concept, which stems from the research work of W. Chan Kim and Renée Mauborgne on innovation through value. Over 7 years, 91% of the Blue Ocean Awards winners have been funded within 6 months. 3 years following their victory, their valuation is multiplied by 10 on average, up to more than 40 times.
                                    </p>
                                </div>
                            </div>
                            <p className="text-light small font-weight-bold">
                                Supported by the French Embassy in South Africa, HEC Paris, Michelin, Veolia and INPI, participants joined the 7 th Blue Ocean Awards day on igesia, where they networked with over 450 participants, with partners and the 50 finalists on their virtual stands, as well as follow the event live from HEC Paris, Bordeaux Technowest, EFAB Lyon and the French Embassy in South Africa.
                            </p>
                            <p className="text-light small mb-0">
                                The participants also had the option to subscribe a VIP pack, paid within the platform that gave them access to 12 hours of “learning by doing” training-workshop at Blue Ocean Strategy and a speaking position.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-3">
                        <div className="rounded py-4 px-3 w-100 bg-dark" >
                            <div className="d-flex ">
                                <img src="https://media-exp1.licdn.com/dms/image/C4E0BAQEeSZwBRZo7YA/company-logo_200_200/0/1527061404520?e=2159024400&v=beta&t=uvx052oX9gRPqWkDQbTOcbOSRjStvExZtw_C0z60wXI"
                                    className="mr-3"
                                    height="80px" />
                                <div>
                                    <span className="font-weight-bold text-uppercase text-light small">Johannesburg Business School</span>
                                    <p className="text-light small">
                                    The Johannesburg Business School (JBS), established in 2017 as one of Africa’s largest business schools, forms part of the College of Business and Economics at the University of Johannesburg.
                                    </p>
                                </div>
                            </div>
                            <p className="text-light small font-weight-bold">
                            JBS leverages igesia for its International Elective Curriculum held across Johannesburg, Shanghai, Mumbai, Nairobi and Buenos Aires
                            </p>
                            <p className="text-light small mb-0">
                            The three week program introduces students to global business in a post-pandemic age virtually via igesia. Students worked with a local company in Shanghai, Mumbai, Nairobi, Buenos Aires on the platform to apply the new communication, innovation and strategy tools to create a series of strategic options.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-3">
                        <div className="rounded py-4 px-3 w-100 bg-dark" >
                            <div className="d-flex ">
                                <img src="https://lh3.googleusercontent.com/proxy/R2x2LhP9eD12EMpJsbLv5abqPbWfBfHj4DCr1ZAtGGplG692d1SXqHEfyq6yGkIRzw1F8hvps4p2XknojyrMourX4hR7d7RNosNL7EU0AgKcRC0_lecWP9uQk60Q-Bug"
                                    className="mr-3"
                                    height="80px" />
                                <div>
                                    <span className="font-weight-bold text-uppercase text-light small">Special Olympics</span>
                                    <p className="text-light small">
                                    Special Olympics is the world's largest sports organization for children and adults with intellectual disabilities and physical disabilities, providing year-round training and activities to 5 million participants and Unified Sports partners in 172 countries
                                    </p>
                                </div>
                            </div>
                            <p className="text-light small font-weight-bold">
                            Special Olympics leverages igesia to screen its athletes via custom app built for the organisation, Virtual Smiles app that runs exclusively on igesia
                            </p>
                            <p className="text-light small mb-0">
                            Healthcare workers use the Virtual Smiles app on igesia to offer virtual health screening and preventive education for athletes of Special Olympics.
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};
const sideBar = memo(Sidebar);

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        project: state.project,
        events: state.myEvents,
        blogs: state.myBlogs,
    }),
    { setProject, setBlog }
)(Sidebar);
