import React, { memo, useState, useEffect } from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import axios from "axios";
import {
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import firebase from "firebase";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { auth } from "../../../../../../firebase";
import {
  API_BASE_URL,
  ADMIN_ASSIGNMENT_CREATE,
  ASSIGNMENT_EDIT,
} from "../../../../../../config";
import DateTimePicker from "../../../../../DateTimePicker";

const Create = (props) => {
  const { eventId, currentEvent, module_id } = props;
  const [questions, setQuestions] = useState([]);
  const [questionDescription, setQuestionDescription] = useState([]);
  const [assignmentSubject, setAssignmentSubject] = useState("");
  const [assignmentDescription, setAssignmentDescription] = useState("");
  const [saving, setSaving] = useState(false);
  const [currentActiveKey, setCurrentActiveKey] = useState("1");
  const userDetails = auth.currentUser;
  const [graded, setGraded] = useState(false);
  const [assignmentGradepoints, setAssignmentGradepoints] = useState(0);
  const [questionsGradepoints, setQuestionsGradepoints] = useState([]);
  const [addDeadline, setAddDeadline] = useState(false);
  const [deadline, setDeadline] = useState(new Date(Date.now() + 86400000));
  const [startDateCalendar, setStartDateCalendar] = useState(false);

  useEffect(() => {
    if (props?.assignmentData) {
      const { assignmentData } = props;
      setAssignmentSubject(assignmentData?.assignmentSubject);
      setAssignmentDescription(assignmentData?.assignmentDescription);
      setQuestions(assignmentData?.questions.map((item) => item.question));
      setQuestionDescription(
        assignmentData?.questions.map((item) => item.description ?? "")
      );
      setGraded(assignmentData?.isGraded);
      setAssignmentGradepoints(
        assignmentData?.questions?.reduce(
          (a, b) => parseInt(a.weight) + parseInt(b.weight)
        )
      );
      setQuestionsGradepoints(
        assignmentData?.questions?.map((item) => parseInt(item.weight))
      );
    }
  }, [props]);

  const addAssignment = async () => {
    let questionsArrayWithObjects = [];
    for (let i = 0; i < questions.length; i++) {
      let json = {
        question: questions[i],
        description: questionDescription[i],
        questionId: i + 1,
        weight: questionsGradepoints[i],
      };
      questionsArrayWithObjects.push(json);
    }

    try {
      setSaving(true);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_ASSIGNMENT_CREATE}`,
        {
          user_id: `${userDetails?.uid}`,
          workspace_id: currentEvent?.workspace_id,
          room_id: currentEvent?.room_id,
          deadline: addDeadline
            ? moment(deadline).valueOf()
            : Date.now() + 2 * 365 * 24 * 60 * 60 * 1000, //false,
          assignment_subject: assignmentSubject,
          assignment_description: assignmentDescription,
          questions: questionsArrayWithObjects,
          all_participants: true,
          assigned: [],
          folderId: null,
          save_for_later: false,
          is_graded: graded || false,
          product_id: eventId,
          parent_id: module_id,
        }
      );

      if (response.status === 200) {
        toast.success("Assignment created successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });

        setQuestions([]);
        setQuestionDescription([]);
        setAssignmentDescription("");
        setAssignmentSubject("");
        setGraded(false);
        setAssignmentGradepoints(0);
        setQuestionsGradepoints([]);
        setAddDeadline(false);
        setDeadline(new Date(Date.now() + 86400000));
        setStartDateCalendar(false);
      }

      setSaving(false);
    } catch (error) {
      console.log("[Error] Creating Assignment: ", error);
      toast.error(`Error creating Assignment: ${error.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setSaving(false);
    }
  };

  const editAssignment = async () => {
    let questionsArrayWithObjects = [];
    for (let i = 0; i < questions.length; i++) {
      let json = {
        question: questions[i],
        description: questionDescription[i],
        questionId: i + 1,
        weight: questionsGradepoints[i],
      };
      questionsArrayWithObjects.push(json);
    }

    try {
      let res = await axios.post(`${API_BASE_URL}${ASSIGNMENT_EDIT}`, {
        user_id: `${userDetails?.uid}`,
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        deadline: Date.now() + 2 * 365 * 24 * 60 * 60 * 1000,
        assignment_subject: assignmentSubject,
        assignment_description: assignmentDescription,
        questions: questionsArrayWithObjects,
        published_assignment_id: props?.assignmentId,
        all_participants: true,
        assigned: [],
        folderId: null,
        is_graded: graded || false,
      });

      await firebase
        .firestore()
        .collection(`/events/${eventId}/assignments/`)
        .doc(`${props?.assignmentId}`)
        .set(
          {
            title: assignmentSubject,
            // deadline: Date.now() + 2 * 365 * 24 * 60 * 60 * 1000,
            updated_at: Date.now(),
            type: 2,
            is_graded: graded || false,
            total_points: assignmentGradepoints,
          },
          {
            merge: true,
          }
        );
      toast.success("Assignment editted successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error("Error : ", e);
      toast.error(`Error: ${e.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      setQuestions([]);
      setQuestionDescription([]);
      setAssignmentDescription("");
      setAssignmentSubject("");
    }
  };

  // HANDLES QUESTIONS ADDED
  const handleQuestions = (e, key) => {
    let prev = [...questions];
    prev[key] = e.target.value;
    setQuestions(prev);
  };

  // HANDLES DESCRIPTION ADDED
  const handleDescriptions = (e, key) => {
    let prev = [...questionDescription];
    prev[key] = e.target.value;
    setQuestionDescription(prev);
  };

  // HANDLES GRADE POINTS ADDED
  const handleGradepoints = (e, key) => {
    let prev = [...questionsGradepoints];
    prev[key] = parseInt(e.target.value);
    setQuestionsGradepoints(prev);
  };

  const addQuestion = () => {
    let prev = [...questions];
    prev[prev.length] = "";
    setQuestions(prev);
    setCurrentActiveKey(`${prev.length}`);

    let prevDescriptions = [...questionDescription];
    prevDescriptions[prevDescriptions.length] = "";
    setQuestionDescription(questionDescription);

    let prevGradepoints = [...questionsGradepoints];
    prevGradepoints[prevGradepoints.length] = 0;
    setAssignmentGradepoints(prevGradepoints);
  };

  useEffect(() => {
    if (questionsGradepoints?.length > 0) {
      setAssignmentGradepoints(
        questionsGradepoints?.reduce((a, b) => {
          return parseInt(a) + parseInt(b);
        })
      );
    }
  }, [questionsGradepoints]);

  return (
    <Form>
      <div className="rounded-lg p-2 m-2 bg-card-theme">
        <div className="mb-3">
          <InputGroup className="mb-1">
            <FormControl
              placeholder="Subject"
              aria-label="Subject"
              aria-describedby={"Subject"}
              name="AssignmentSubject"
              autoFocus={true}
              required={true}
              value={assignmentSubject}
              onChange={(e) => {
                setAssignmentSubject(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup className="mb-2">
            <FormControl
              placeholder="Description"
              aria-label="Description"
              aria-describedby={"Description"}
              name="AssignmentDescription"
              required={true}
              value={assignmentDescription}
              onChange={(e) => {
                setAssignmentDescription(e.target.value);
              }}
            />
          </InputGroup>

          <div className="mb-2">
            <Form.Check
              type="switch"
              id="gradebook-switch"
              label="Add to Gradebook"
              value="graded"
              checked={graded}
              onChange={(e) => {
                if (e.target.checked) setGraded(true);
                else setGraded(false);
              }}
            />
            {graded && (
              <>
                <Form.Text className="small text-muted mb-2">
                  <b>Note:</b> Provide grade points for each question to add
                  them to Gradebook.
                </Form.Text>
                <InputGroup>
                  <FormControl
                    placeholder="Gradepoints"
                    name="assignmentGradepoints"
                    type="number"
                    min="0"
                    disabled={true}
                    // onChange={(e) =>
                    //   setAssignmentGradepoints(parseInt(e.target.value))
                    // }
                    value={assignmentGradepoints}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>Total points</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </>
            )}
          </div>

          <div className="mb-1">
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Add deadline"
                onChange={(e) => {
                  setAddDeadline(e.target.checked);
                  setDeadline(new Date(Date.now() + 86400000));
                }}
                checked={addDeadline}
              />
            </Form.Group>
            {addDeadline &&
              (deadline && !startDateCalendar ? (
                <a
                  className="border border-secondary rounded p-2 text-white pointer"
                  onClick={() => setStartDateCalendar(true)}
                  style={{ textDecoration: "none" }}
                >
                  Deadline: {moment(deadline).format("MM/DD/YYYY hh:mm A")}
                </a>
              ) : (
                <DateTimePicker
                  startDateCalendar={startDateCalendar}
                  callbackOpen={() => setStartDateCalendar(true)}
                  callbackClose={() => setStartDateCalendar(false)}
                  currentValue={deadline}
                  callbackSet={setDeadline}
                />
              ))}
          </div>
        </div>

        {questions.length > 0
          ? questions.map((question, key) => (
              <Card className="mb-3 bg-dark">
                <Card.Header className="d-flex align-items-center">
                  <div className="flex-grow-1 ">
                    <p className="mb-0 small font-weight-bold">
                      Question {key + 1}
                    </p>
                    {currentActiveKey != key + 1 && (
                      <p className="mb-0 mt-1">{questions[key]}</p>
                    )}
                  </div>
                  <div
                    className="mt-1 mr-1 pointer"
                    title="Delete"
                    onClick={() => {
                      setQuestions((prev) => {
                        const updatedState = produce(prev, (draft) => {
                          draft.splice(key, 1);
                        });
                        return updatedState;
                      });

                      setQuestionsGradepoints((prev) => {
                        const updatedState = produce(prev, (draft) => {
                          draft.splice(key, 1);
                        });
                        return updatedState;
                      });

                      setQuestionDescription((prev) => {
                        const updatedState = produce(prev, (draft) => {
                          draft.splice(key, 1);
                        });
                        return updatedState;
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} title="Delete" />
                  </div>
                </Card.Header>
                <Card.Body className="py-1 px-2">
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="Question"
                      aria-label="Question"
                      aria-describedby={"question" + key}
                      as="textarea"
                      name="questions[]"
                      autoFocus={questions.length === key + 1}
                      id={key}
                      value={questions[key]}
                      onChange={(e) => handleQuestions(e, key)}
                      required={true}
                    />
                  </InputGroup>

                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="Description (optional)"
                      aria-label="Description"
                      aria-describedby={"Description" + key}
                      as="textarea"
                      name="descriptions[]"
                      id={key}
                      value={questionDescription[key]}
                      onChange={(e) => handleDescriptions(e, key)}
                      required={true}
                    />
                  </InputGroup>

                  {graded && (
                    <InputGroup>
                      <FormControl
                        placeholder="Grade points"
                        name="gradepoints[]"
                        type="number"
                        min="1"
                        id={key}
                        onChange={(e) => handleGradepoints(e, key)}
                        value={questionsGradepoints[key]}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>points</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  )}
                </Card.Body>
              </Card>
            ))
          : null}
        <InputGroup className="w-100 mt-3">
          <Button
            size="sm"
            variant="outline-primary"
            className="w-100"
            onClick={() => addQuestion()}
          >
            + <small>Add Question</small>
          </Button>
        </InputGroup>

        {props?.isEditting ? (
          <Button
            variant="primary"
            className="mt-2 w-100"
            size="sm"
            disabled={saving}
            onClick={() => {
              editAssignment();
            }}
          >
            {saving ? (
              <>
                <Spinner size="sm" animation="border" color="white" /> Saving
                Changes
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        ) : (
          <Button
            variant="primary"
            className="mt-2 w-100"
            size="sm"
            disabled={saving}
            onClick={() => {
              addAssignment();
            }}
          >
            {saving ? (
              <>
                <Spinner size="sm" animation="border" color="white" />{" "}
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        )}
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});
export default memo(connect(mapStateToProps)(Create));
