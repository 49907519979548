import React from "react";
import Avatar from "react-avatar";

export default function UserProfile(props) {
  // CONSTANTS
  const { userDetails, subText, imgSize, className } = props;
  let img_size = parseInt(imgSize) ?? 25;
  let class_name = className ?? "";

  return userDetails?.avatarUrl.indexOf("solo-icon.svg") > -1 ? (
    <div className={`d-flex align-items-center ${class_name}`}>
      <Avatar
        name={userDetails?.firstName + " " + userDetails?.lastName}
        round={true}
        size={img_size}
        textSizeRatio={1.75}
        className="mr-3"
        style={{ cursor: "pointer" }}
      />
      <div>
        <p className="mb-0">
          {userDetails?.firstName + " " + userDetails?.lastName}
        </p>
        {subText && <p className="small mb-0">{subText}</p>}
      </div>
    </div>
  ) : (
    <div className={`d-flex align-items-center ${class_name}`}>
      <img
        title={userDetails?.firstName + " " + userDetails?.lastName}
        src={userDetails?.avatarUrl}
        className="img-fluid rounded-circle mr-3"
        style={{ height: img_size, width: img_size, cursor: "pointer" }}
      />
      <div>
        <p className="mb-0">
          {userDetails?.firstName + " " + userDetails?.lastName}
        </p>
        {subText && <p className="small mb-0">{subText}</p>}
      </div>
    </div>
  );
}
