import React, { useState, useEffect, useRef } from "react";
import { Card, Image, Button, Dropdown, Form, Nav } from "react-bootstrap";

import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { languageText } from "../Event/language.json";
import { ticketTiers } from "../Event/ticketTiers.json";
import { useHistory, useLocation } from "react-router-dom";

import * as CourseData from "./course.json";
import * as CourseStyle from "./course.module.css";
import ReadMore from "../ReadMore";
import { loadState, saveState } from "../../services";
import CourseRedux from "./CourseRedux";
import { postData } from "../../utils";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function CourseAuthentication({ currentLanguage, changeLanguage, course }) {
  const [route, setRoute] = useState("");
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email_address: "",
    password_value: "",
  });

  const { pathname } = useLocation();
  const history = useHistory();

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (pathname.split("/")[3] && pathname.split("/")[3] === "register") {
      setRoute("register");
    }
    if (pathname.split("/")[3] && pathname.split("/")[3] === "login") {
      setRoute("login");
    }
  }, [pathname]);

  const handleFormInput = (e) => {
    // console.log(e.target.name, e.target.value);
    setUserDetails((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleRegister = async () => {
    // if (
    //   pathname.split("/")[2] !== "" &&
    //   pathname.split("/")[3] === "register"
    // ) {
    //   history.push(`fill-details`);
    // }
    const keyID = "rzp_test_MUBBgyLeFbvEU8"; //Test mode
    const baseURL = "https://dev.igesia.com"; //Dev mode

    // postData

    let options = {
      key: keyID,
      amount: course ? course?.feeValue * 100 : 1000000,
      currency: "INR",
      name: "Igesia",
      description: course ? course?.name : "Igesia product",
      image:
        "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6",
      // order_id: orderGeneratedData.rzp_order_id,
      handler: async (response) => {
        // console.log("Handler Response: ", response)
        try {
          let processDetails = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          console.log("processDetails: ", processDetails);

          if (processDetails?.razorpay_payment_id != null) {
            const payment_success = window.localStorage.getItem(
              "payment_success"
            )
              ? JSON.parse(window.localStorage.getItem("payment_success"))
              : [];

            console.log("payment_success: ", payment_success);

            const new_payment_success = {
              id: course?.id,
              razorpay_payment_id: processDetails?.razorpay_payment_id,
              success: true,
            };

            window.localStorage.setItem(
              "payment_success",
              JSON.stringify([...payment_success, new_payment_success])
            );

            toast.dark(`✅ Payment successful`, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: false,
              autoClose: 3000,
            });
            if (
              pathname.split("/")[2] !== "" &&
              pathname.split("/")[3] === "register"
            ) {
              history.push(`fill-details`);
            }
          }
        } catch (error) {
          console.log(error);
        }
      },
      prefill: {
        name: `${userDetails?.first_name} ${userDetails?.last_name}`,
        email: `${userDetails?.email_address}`,
        contact: `${userDetails?.phone_number}`,
      },
      notes: {
        address: "Igesia all in one",
        website: window.location.href,
      },
      theme: {
        color: "#191C25",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleLogin = () => {
    if (pathname.split("/")[2] !== "" && pathname.split("/")[3] === "login") {
      history.push(`dashboard`);
    }
  };

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      <CourseRedux />
      {/* Top navigation start */}
      <div className="container pt-3 pt-md-4 d-flex align-items-center justify-content-between">
        <div onClick={() => history.push("/")}>
          <Image
            className={`rounded ${CourseStyle.course_cover_logo}`}
            src={
              course?.logo_image
                ? course?.logo_image
                : process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"
            }
          />
        </div>
        <Dropdown className="mr-3 small text-white">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {languageText[`${currentLanguage}`].english}
            </Dropdown.Item>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "FR"}
              onClick={() => changeLanguage("FR")}
            >
              {languageText[`${currentLanguage}`].french}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-md-8 mb-3 mb-md-4 col-12 mx-auto">
            <h2 className="font-weight-bold text-white text-center">
              {course?.name}
              {course?.course_details
                ? course.course_details.EN.course_title
                : null}
            </h2>
            {/* <p className="text-white">May 11 7:30 AM to May 11 6:00 PM CET</p> */}
          </div>
        </div>
        <div className=" pb-5">
          <div className="col-md-8 col-12 mx-auto bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              style={{ position: "sticky", top: 60 }}
            >
              <Card.Body>
                <p className="text-center h5 mb-3">
                  {route === "register" ? "Register" : "Log in"} for an igesia
                  account
                  <br />
                  to join
                  {/* for
                        <br/>{programName} */}
                </p>
                <div className="mb-3">
                  <Nav
                    variant="pills"
                    onSelect={(e) => {
                      history.push(e);
                    }}
                    activeKey={route}
                    className="w-100 border rounded-pill m-0"
                  >
                    <Nav.Item className="w-50">
                      <Nav.Link
                        className="py-1 text-center rounded-pill"
                        eventKey="register"
                      >
                        Register
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="w-50">
                      <Nav.Link
                        className="py-1 text-center rounded-pill"
                        eventKey="login"
                      >
                        Log in
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  {route === "register" ? (
                    <Form
                      className="mt-3"
                      onSubmit={(e) => {
                        e.preventDefault();
                        //console.log(e)
                        handleRegister();
                      }}
                    >
                      <Form.Group>
                        <Form.Label className="small">First Name</Form.Label>
                        <Form.Control
                          type="text"
                          required={true}
                          placeholder="Enter first name"
                          className="border rounded-lg px-2"
                          id="first_name"
                          name="first_name"
                          onChange={handleFormInput}
                          value={userDetails?.first_name}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="small">Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          required={true}
                          placeholder="Enter last name"
                          className="border rounded-lg px-2"
                          id="last_name"
                          name="last_name"
                          onChange={handleFormInput}
                          value={userDetails?.last_name}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="small">
                          Phone number (optional)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          className="border rounded-lg px-2"
                          id="phone_number"
                          name="phone_number"
                          onChange={handleFormInput}
                          value={userDetails?.phone_number}
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="small">Email address</Form.Label>
                        <Form.Control
                          type="email"
                          required={true}
                          placeholder="Enter email"
                          className="border rounded-lg px-2"
                          id="email_address"
                          name="email_address"
                          onChange={handleFormInput}
                          value={userDetails?.email_address}
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="small">Password</Form.Label>
                        <Form.Control
                          type="password"
                          required={true}
                          placeholder="Password"
                          className="border rounded-lg px-2"
                          id="password_value"
                          name="password_value"
                          onChange={handleFormInput}
                          value={userDetails?.password_value}
                        />
                      </Form.Group>

                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="I agree to igesia's Terms & Conditions and Privacy Policy"
                          className="small"
                          required={true}
                          id="t_n_c"
                          name="t_n_c"
                          onChange={handleFormInput}
                        />
                      </Form.Group>

                      <Button variant="primary" type="submit" className="w-100">
                        Register
                      </Button>

                      <p className="text-center small mt-2 mb-0 font-weight-bold">
                        Already have an account?{" "}
                        <a
                          className="text-primary"
                          onClick={() => {
                            history.push("login");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Log in here
                        </a>
                      </p>
                    </Form>
                  ) : (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                      }}
                    >
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="small">Email address</Form.Label>
                        <Form.Control
                          type="email"
                          required={true}
                          placeholder="Enter email"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="small">Password</Form.Label>
                        <Form.Control
                          type="password"
                          required={true}
                          placeholder="Password"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        // onClick={handleLogin}
                      >
                        Log in
                      </Button>

                      <p className="text-center small mt-2 mb-0 font-weight-bold">
                        Don't have an account?{" "}
                        <a
                          className="text-primary"
                          onClick={() => {
                            history.push("register");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Register here
                        </a>
                      </p>
                    </Form>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {/* Event cover ends */}
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    course: state.course,
  }),
  { changeLanguage }
)(CourseAuthentication);
