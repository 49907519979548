import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faArrowLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useHistory } from "react-router-dom";
import EventManagerNavbar from "./EventManagerNavbar";
// import EventNavbar from "./Navbar";
import Sidebar from "../Sidebar/Index";
import Overview from "./Overview";
import Tickets from "./Tickets";
import Speakers from "./Speakers";
import Schedule from "./Schedule";
import Sponsors from "./Sponsors";
import Booths from "./Booths";
import Attendees from "./Attendees";
import Pages from "./Pages";
import Stage from "./Stage";
import Assignments from "./Assignments/index";
import Quizzes from "./Quizzes/index";
import Polls from "./Poll/index";
import BBOS from "./BBOS/index";
import AsIs from "./BBOS/As-is";
import { useLocation } from "react-router";
import { setEventID } from "../../../store/actions/actions";
import { connect } from "react-redux";
import "./admin.css";
import RegistrationSettings from "./RegistrationSettings";
import IgesiaForm from "./IgesiaForm/index";
import CourseBuilder from "./CourseBuilder";
import Settings from "./Settings";
import Pricing from "./Pricing";
import Messages from "./Messages";
import Resources from "./Resources";
import Report from "./Report";
import Report2 from "./Report/Report2";

const EventManager = (props) => {
  // CONSTANTS
  const { eventid, page } = useParams();
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const { currentEvent, eventId } = props;
  const nav_data = [
    {
      id: 1,
      name: currentEvent?.type === 1 ? "Course Home" : "Overview",
      eventKey: "overview",
      show: true,
    },
    {
      id: 2,
      name: "Course Content",
      eventKey: "course-builder",
      show: currentEvent?.type === 1,
    },

    {
      id: 3,
      name: "Files & Videos",
      eventKey: "resources",
      show: currentEvent?.type === 1,
    },
    {
      id: 4,
      name: currentEvent?.type === 1 ? "Live Sessions" : "Schedule",
      eventKey: "schedules",
      show: true,
    },
    {
      id: 5,
      name: "Assignments",
      eventKey: "assignments",
      show: true,
    },
    {
      id: 6,
      name: "Quizzes",
      eventKey: "quizzes",
      show: true,
    },
    {
      id: 7,
      name: "Polls",
      eventKey: "polls",
      show: true,
    },
    {
      id: 8,
      name: currentEvent?.type === 1 ? "Class List" : "Participants",
      eventKey: "attendees",
      show: true,
    },
    {
      id: 18,
      name: currentEvent?.type === 1 ? "Class Report" : "Report",
      eventKey: "report",
      show: currentEvent?.type === 1,
    },
    {
      id: 9,
      name: "Registration",
      eventKey: "registration",
      show: true,
    },
    {
      id: 10,
      name: currentEvent?.type === 1 ? "Instructors" : "Speakers",
      eventKey: "speakers",
      show: true,
    },
    {
      id: 11,
      name: currentEvent?.type === 1 ? "Pricing" : "Pricing",
      eventKey: "pricing",
      show: true,
    },
    {
      id: 12,
      name: currentEvent?.type === 1 ? "Messages" : "Messages",
      eventKey: "messages",
      show: true,
    },
    {
      id: 13,
      name: currentEvent?.type === 1 ? "Extra Content" : "Pages",
      eventKey: "pages",
      show: true,
    },
    {
      id: 14,
      name: currentEvent?.bbos_names?.title ?? "BBOS",
      eventKey: "bbos",
      show: currentEvent?.is_bbos_apps ? true : false,
    },
    {
      id: 15,
      name: "Forms",
      eventKey: "forms",
      show: true,
    },
    {
      id: 16,
      name: "Booths",
      eventKey: "booths",
      show: currentEvent?.type != 1,
    },
    {
      id: 17,
      name: currentEvent?.type === 1 ? "Course Settings" : "Settings",
      eventKey: "settings",
      show: true,
    },
  ];

  // STATES
  const [currentNavKey, setCurrentNavKey] = useState("overview");
  const [bbos, setBbos] = useState("");
  const [isLeftSidePanelOpen, setIsLeftSidePanelOpen] = useState(false);

  // FUNCTIONS
  const openLeftSide = () => setIsLeftSidePanelOpen(true);
  const closeLeftSide = () => setIsLeftSidePanelOpen(false);

  useEffect(() => {
    if (page) {
      setCurrentNavKey(page);
      setBbos("");
    } else {
      if (pathname?.split("/")?.length === 5) {
        history.push(`./overview`);
      } else {
        history.push(`./${currentEvent?.id}/overview`);
      }

      setCurrentNavKey("overview");
      setBbos("");
    }

    // if (hash === "") {
    //   setCurrentNavKey("overview");
    //   setBbos("");
    // }

    // nav_data.map((item, key) => {
    //   if (decodeURIComponent(hash) === `#${item.eventKey}`) {
    //     setCurrentNavKey(item.eventKey);
    //     setBbos("");
    //   }
    // });

    if (
      decodeURIComponent(hash).includes("#bbos") &&
      decodeURIComponent(hash).includes("project=")
    ) {
      setBbos(decodeURIComponent(hash)?.replace("#bbos?project=", ""));
    }
  }, [hash, page]);

  useEffect(() => {
    props.setEventID(eventid);
  }, [eventid]);

  return (
    <>
      <div
        className="container-fluid vh-100 overflow-hidden"
        style={{ background: "#232626" }}
      >
        <div className="row h-100 overflow-scroll">
          <div className="d-block d-md-none">
            <div style={{ width: "100vw" }}>
              <button
                className="open_btn shadow d-flex align-items-center"
                onClick={openLeftSide}
              >
                <span className="mr-2">☰</span>
                <span className="small">
                  {currentEvent?.event_details?.EN?.event_name}
                </span>
              </button>
            </div>
            <div
              className="mobile_sidebar_left"
              style={isLeftSidePanelOpen ? { width: "100%" } : { width: 0 }}
            >
              <div className="d-flex">
                <a className="pointer" onClick={closeLeftSide}>
                  <FontAwesomeIcon icon={faTimes} className="mx-2 my-0 p-0" />
                </a>
                <a
                  className="d-flex align-items-center flex-grow-1 text-white text-decoration-none text-uppercase"
                  href="/admin"
                ></a>
                <a
                  className="small pointer mt-1 text-decoration-none"
                  href="/admin"
                >
                  Back to dashboard
                </a>
              </div>

              <div className="overflow-scroll">
                <EventManagerNavbar
                  currentNavKey={currentNavKey}
                  setCurrentNavKey={setCurrentNavKey}
                  setBbos={setBbos}
                  navData={nav_data}
                  closeLeftSide={closeLeftSide}
                />
              </div>
            </div>
          </div>

          <div
            className="col-md-2 h-100 bg-dark w-100 pt-3 d-none d-md-block px-0"
            style={{ overflowY: "scroll" }}
          >
            <div className="d-flex flex-column justify-content-between">
              <div className="shadow-sm">
                <a
                  className=" px-3 text-white text-decoration-none text-uppercase"
                  // href="/admin"
                  onClick={() => history.push("/admin")}
                >
                  {/* <FontAwesomeIcon icon={faArrowLeft} />  */}
                  <span className="small">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="lg"
                      className="mt-0 mb-2 p-0"
                    />
                  </span>
                </a>
                <div className="shadow-sm pb-1">
                  <p className="text-light px-3">
                    {currentEvent?.event_details?.EN?.event_name}
                  </p>
                </div>
                <EventManagerNavbar
                  currentNavKey={currentNavKey}
                  setCurrentNavKey={setCurrentNavKey}
                  setBbos={setBbos}
                  navData={nav_data}
                  closeLeftSide={closeLeftSide}
                />
              </div>
              <div>{/* User data */}</div>
            </div>
          </div>

          <div className="col-md-10 p-0">
            {bbos != "" ? (
              <div className="overflow-auto max-vh-100 w-100" id="style-4">
                <AsIs />
              </div>
            ) : (
              <div className="overflow-auto max-vh-100 w-100">
                {currentNavKey === "overview" && <Overview />}
                {currentNavKey === "settings" && <Settings />}
                {currentNavKey === "tickets" && <Tickets />}
                {currentNavKey === "registration" && <RegistrationSettings />}
                {currentNavKey === "stages" && <Stage />}
                {currentNavKey === "schedules" && <Schedule />}
                {currentNavKey === "speakers" && <Speakers />}
                {/* {currentNavKey === "sponsors" && <Sponsors />} */}
                {currentNavKey === "booths" && <Booths />}
                {currentNavKey === "attendees" && <Attendees />}
                {currentNavKey === "pages" && <Pages />}
                {currentNavKey === "assignments" && <Assignments />}
                {currentNavKey === "pricing" && <Pricing />}
                {currentNavKey === "messages" && <Messages />}
                {currentNavKey === "quizzes" && <Quizzes />}
                {currentNavKey === "bbos" && <BBOS />}
                {currentNavKey === "polls" && <Polls />}
                {currentNavKey === "forms" && <IgesiaForm />}
                {currentNavKey === "course-builder" && <CourseBuilder />}
                {currentNavKey === "resources" && <Resources />}
                {currentNavKey === "report" && <Report2 />}
                {currentNavKey === "report2" && <Report />}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  currentEvent: state.currentEvent,
  eventId: state.eventId,
});

export default connect(mapStateToProps, { setEventID })(EventManager);
