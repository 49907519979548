import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Avatar from "react-avatar";
import { memo } from "react";

const TopUsers = () => {
  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <Card.Body>
                <div className="best-seller-table responsive-tbl">
                  <div className="item">
                    <div className="table-responsive product-list">
                      <Table borderless className="text-white">
                        <thead>
                          <tr>
                            <th className="f-22">Top Users</th>
                            <th>{"Messages"}</th>
                            <th>{"Polls"}</th>
                            <th>{"Quizzes"}</th>
                            <th>{"Assignments"}</th>
                            <th>{"Files"}</th>
                            <th>{"Courses Subscribed"}</th>
                            <th>{"Events Participated in"}</th>
                            <th>{"Money Spent"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="">
                                <div className="d-flex flex-column align-items-center">
                                  <Avatar
                                    round
                                    size="30px"
                                    name="John Keter"
                                    className="mb-1"
                                  />
                                  <span className="small">John Keter</span>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>456</td>
                            <td style={{ verticalAlign: "middle" }}>22</td>
                            <td style={{ verticalAlign: "middle" }}>13</td>
                            <td style={{ verticalAlign: "middle" }}>8</td>
                            <td style={{ verticalAlign: "middle" }}>14</td>
                            <td style={{ verticalAlign: "middle" }}>5</td>
                            <td style={{ verticalAlign: "middle" }}>6</td>
                            <td style={{ verticalAlign: "middle" }}>$652</td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="">
                                <div className="d-flex flex-column align-items-center">
                                  <Avatar
                                    round
                                    size="30px"
                                    name="John Doe"
                                    className="mb-1"
                                  />
                                  <span className="small">John Doe</span>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>410</td>
                            <td style={{ verticalAlign: "middle" }}>14</td>
                            <td style={{ verticalAlign: "middle" }}>8</td>
                            <td style={{ verticalAlign: "middle" }}>81</td>
                            <td style={{ verticalAlign: "middle" }}>11</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>$452</td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="">
                                <div className="d-flex flex-column align-items-center">
                                  <Avatar
                                    round
                                    size="30px"
                                    name="Raj Singh"
                                    className="mb-1"
                                  />
                                  <span className="small">Raj Singh</span>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>246</td>
                            <td style={{ verticalAlign: "middle" }}>14</td>
                            <td style={{ verticalAlign: "middle" }}>7</td>
                            <td style={{ verticalAlign: "middle" }}>18</td>
                            <td style={{ verticalAlign: "middle" }}>13</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td style={{ verticalAlign: "middle" }}>$358</td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>
                              <div className="">
                                <div className="d-flex flex-column align-items-center">
                                  <Avatar
                                    round
                                    size="30px"
                                    name="Janice Mcgill"
                                    className="mb-1"
                                  />
                                  <span className="small">Janice Mcgill</span>
                                </div>
                              </div>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>126</td>
                            <td style={{ verticalAlign: "middle" }}>14</td>
                            <td style={{ verticalAlign: "middle" }}>5</td>
                            <td style={{ verticalAlign: "middle" }}>8</td>
                            <td style={{ verticalAlign: "middle" }}>18</td>
                            <td style={{ verticalAlign: "middle" }}>5</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>$202</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(TopUsers);
