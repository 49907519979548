import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  Accordion,
  Button,
  Card,
  Spinner,
  Form,
  Modal,
  FormCheck,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTimes,
  faTrash,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import FormResponses from "./FormResponses";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_BASE_URL } from "../../../../config";
import { auth } from "../../../../firebase";
toast.configure();

function IgesiaForm(props) {
  const { eventSnapshot, loadingEvent, formDetails, idx, eventId } = props;
  const user = auth.currentUser;

  const [showModalAnotherField, setShowModalAnotherField] = useState(false);
  const [formLive, setFormLive] = useState(false);
  const [formUpdating, setFormUpdating] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formTitle, setFormTitle] = useState("");
  const [startsAt, setStartsAt] = useState(Date.now());
  const [addFormStartDate, setAddFormStartDate] = useState(false);
  const [endsAt, setEndsAt] = useState(Date.now() + 24 * 60 * 60 * 1000);
  const [addFormEndDate, setAddFormEndDate] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [formFields, setFormFields] = useState({});
  const [selectValues, setSelectValues] = useState([]);
  const [allFormFields, setAllFormFields] = useState([]);
  const [updateField, setUpdateField] = useState(false);
  const [updateFieldKey, setUpdateFieldKey] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleteFormDetails, setDeleteFormDetails] = useState({});

  const [publishFormModal, setPublishFormModal] = useState(false);
  const [publishingFormDetails, setPublishingFormDetails] = useState({});
  const [unPublishFormModal, setUnPublishFormModal] = useState(false);
  const [startNowModal, setStartNowModal] = useState(false);
  const [closeNowModal, setCloseNowModal] = useState(false);
  const [duplicatingForm, setDuplicatingForm] = useState(false);

  useEffect(() => {
    if (formDetails) {
      setFormTitle(formDetails?.form_title);
      setAllFormFields(
        formDetails?.form_fields ? [...formDetails?.form_fields] : []
      );
      setFormLive(formDetails?.form_required);
      if (formDetails?.starts_at) {
        setAddFormStartDate(true);
        setStartsAt(formDetails?.starts_at);
      }
      if (formDetails?.ends_at) {
        setAddFormEndDate(true);
        setEndsAt(formDetails?.ends_at);
      }
    }
  }, [formDetails]);

  const handleResetForm = () => {
    setFormFields({});
    setFieldType("");
    setSelectValues([]);
    setUpdateField(false);
    setUpdateFieldKey(null);
    setFormUpdating(false);
    setAllFormFields({});
    setFormTitle("");
    setStartsAt(Date.now());
    setEndsAt(Date.now() + 30 * 24 * 60 * 60 * 1000);
    setEditing(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (formTitle === "" || formTitle === null) {
      alert("Please enter form title");
      return;
    }
    if (allFormFields?.length === 0) {
      alert("Please add at least one field to form");
      return;
    }

    try {
      setSubmittingForm(true);
      const response = await axios.put(
        `${API_BASE_URL}/form/${formDetails?.id}`,
        {
          event_id: props.eventId,
          user_id: user?.uid,
          form_title: formTitle,
          starts_at: startsAt ?? false,
          ends_at: endsAt ?? false,
          form_fields: allFormFields,
        }
      );

      if (response.status === 200) {
        handleResetForm();
        setSubmittingForm(false);

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setSubmittingForm(false);

      console.error("Error creating form: ", err);
      toast.error(`Error creating form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const handleEditForm = () => {
    if (editing) {
      setEditing(false);
      setFormUpdating(false);
    } else {
      setEditing(true);
      setFormUpdating(true);
    }
  };

  const deleteSelectedForm = async () => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/form/${deleteFormDetails?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      handleClose();
    } catch (err) {
      console.error("Error deleting form: ", err);
      toast.error(`Error deleting form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleHidePublishModal = () => {
    setPublishingFormDetails({});
    setPublishFormModal(false);
  };

  const publishSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/publish/${publishingFormDetails?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      handleHidePublishModal();
    } catch (err) {
      console.error("Error publishing form: ", err);
      toast.error(`Error publishing form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleHideUnpublishModal = () => {
    setPublishingFormDetails({});
    setUnPublishFormModal(false);
  };

  const unPublishSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/unpublish/${publishingFormDetails?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      handleHideUnpublishModal();
    } catch (err) {
      console.error("Error un-publishing form: ", err);
      toast.error(`Error un-publishing form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleHideStartModal = () => {
    setPublishingFormDetails({});
    setPublishFormModal(false);
  };

  const startNowSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/start/${publishingFormDetails?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      handleHideStartModal();
    } catch (err) {
      console.error("Error starting form: ", err);
      toast.error(`Error starting form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const closeNowSelectedForm = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/form/close/${publishingFormDetails?.id}?event_id=${eventId}&user_id=${user?.uid}`
      );

      toast.dark(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 3000,
      });

      handleHideStartModal();
    } catch (err) {
      console.error("Error closing form: ", err);
      toast.error(`Error closing form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleDuplicateForm = async (formDetails) => {
    try {
      setSubmittingForm(true);
      const response = await axios.post(
        `${API_BASE_URL}/form/duplicate/${formDetails?.id}`,
        {
          event_id: props.eventId,
          user_id: user?.uid,
        }
      );

      if (response.status === 200) {
        setDuplicatingForm(false);

        toast.dark(`${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 3000,
        });
      }
    } catch (err) {
      setDuplicatingForm(false);

      console.error("Error duplicating form: ", err);
      toast.error(`Error duplicating form: ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  if (loadingEvent) {
    return (
      <div className="w-100 text-center">
        <Spinner size="sm" animation="border" /> Loading...
      </div>
    );
  }

  return (
    <div key={idx} className="border border-light rounded mb-3 p-3">
      <div className="d-flex mb-0">
        <p className="flex-grow-1 mb-0 small">
          {moment(
            convertSecondstoMilliSeconds(
              formDetails?.created_at?.seconds,
              formDetails?.created_at?.nanoseconds
            )
          ).format("LLL")}
        </p>

        {formDetails?.form_required ? (
          <p className="mb-0 small text-danger">*Mandatory</p>
        ) : (
          <p className="mb-0 small">Optional</p>
        )}
      </div>
      <p className="h4 mb-2">{formDetails?.form_title}</p>

      <p className="small mb-0">
        <b>No. of questions:</b> {formDetails?.form_fields?.length ?? 0}
      </p>

      {formDetails?.starts_at && (
        <p className="small mb-0">
          <b>Starting at:</b>{" "}
          {formDetails?.starts_at &&
            moment(formDetails?.starts_at).format("LLL")}
        </p>
      )}

      {formDetails?.ends_at && (
        <p className="small">
          <b>Ending at:</b>{" "}
          {formDetails?.ends_at && moment(formDetails?.ends_at).format("LLL")}
        </p>
      )}

      <div className="mb-3">
        {!formDetails?.published_at && (
          <>
            {formDetails?.form_fields?.length > 0 && (
              <Button
                variant="secondary"
                size="sm"
                className="mr-2 text-uppercase"
                onClick={handleEditForm}
              >
                {editing ? (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faTimes} /> Cancel Editing
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </React.Fragment>
                )}
              </Button>
            )}
            {!formDetails?.form_fields?.length && (
              <Button
                size="sm"
                className="text-uppercase mr-2"
                onClick={() => {
                  setShowModalAnotherField(true);
                  setEditing(true);
                }}
              >
                Add Form Field
              </Button>
            )}
          </>
        )}
        {formDetails?.published ? (
          <Button
            variant="secondary"
            size="sm"
            className="mr-2 text-uppercase"
            onClick={() => {
              setPublishingFormDetails(formDetails);
              setUnPublishFormModal(true);
            }}
          >
            Unpublish
          </Button>
        ) : (
          <Button
            size="sm"
            className="mr-2 text-uppercase"
            onClick={() => {
              setPublishingFormDetails(formDetails);
              setPublishFormModal(true);
            }}
          >
            {formDetails?.published_at ? "Republish" : "Publish"}
          </Button>
        )}
        {formDetails?.starts_at && formDetails?.starts_at > Date.now() && (
          <Button
            variant="secondary"
            size="sm"
            className="mr-2 text-uppercase"
            onClick={() => {
              setPublishingFormDetails(formDetails);
              setStartNowModal(true);
            }}
          >
            Start form
          </Button>
        )}
        {formDetails?.ends_at && formDetails?.ends_at < Date.now() && (
          <Button
            variant="secondary"
            size="sm"
            className="mr-2 text-uppercase"
            onClick={() => {
              setPublishingFormDetails(formDetails);
              setCloseNowModal(true);
            }}
          >
            Close form
          </Button>
        )}
        <Button
          variant="danger"
          className="mr-2 text-uppercase"
          size="sm"
          onClick={() => {
            setDeleteFormDetails(formDetails);
            handleShow();
          }}
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>

        <Button
          className="mr-2 text-uppercase"
          size="sm"
          onClick={() => handleDuplicateForm(formDetails)}
          disabled={duplicatingForm}
        >
          {duplicatingForm && (
            <Spinner animation="border" size="sm" className="mr-2" />
          )}
          {duplicatingForm ? "Duplicating" : "Duplicate Form"}
        </Button>
      </div>
      {/* <FormResponses formDetails={formDetails} eventId={eventId} /> */}

      {editing ? (
        <div style={{ width: "100%" }}>
          <Form onSubmit={handleFormSubmit}>
            {formLive ? (
              formUpdating ? (
                <Form.Group>
                  <Form.Label>Form Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="form_title"
                    placeholder="Enter form title"
                    onChange={(e) => setFormTitle(e.target.value)}
                    value={formTitle}
                  />
                  <Form.Text className="text-muted">
                    Title will be displayed on top of this form.
                  </Form.Text>
                </Form.Group>
              ) : (
                <div className="mb-3">
                  <p className="text-uppercase text-muted small mb-0">
                    <b>Form Title</b>
                  </p>
                  <p className="h5">{formTitle}</p>
                </div>
              )
            ) : (
              <Form.Group>
                <Form.Label>Form Title</Form.Label>
                <Form.Control
                  type="text"
                  name="form_title"
                  placeholder="Enter form title"
                  onChange={(e) => setFormTitle(e.target.value)}
                  value={formTitle}
                />
                <Form.Text className="text-muted">
                  Title will be displayed on top of this form.
                </Form.Text>
              </Form.Group>
            )}
            <div>
              <div className="row">
                <Form.Group controlId="formBasicCheckbox" className="col-md-6">
                  <Form.Check
                    name="start_date"
                    type="checkbox"
                    label="Add start date"
                    onClick={(e) => {
                      setAddFormStartDate(e.target.checked);
                    }}
                    checked={addFormStartDate}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox" className="col-md-6">
                  <Form.Check
                    name="end_date"
                    type="checkbox"
                    label="Add end date"
                    onClick={(e) => {
                      setAddFormEndDate(e.target.checked);
                    }}
                    checked={addFormEndDate}
                  />
                </Form.Group>
              </div>
            </div>
            <div>
              <div className="row">
                {addFormStartDate && (
                  <Form.Group className="col-md-6">
                    <Form.Label>Starts at</Form.Label>
                    <br />
                    <Flatpickr
                      placeholder="Select start date"
                      data-enable-time
                      value={startsAt}
                      name="starts_at"
                      onChange={(selectedDate, dateStr, instance) =>
                        setStartsAt(selectedDate[0].valueOf())
                      }
                      options={{ minuteIncrement: 5 }}
                      className="w-100"
                    />
                  </Form.Group>
                )}
                {addFormEndDate && (
                  <Form.Group className="col-md-6">
                    <Form.Label>Ends at</Form.Label>
                    <br />
                    <Flatpickr
                      placeholder="Select end date"
                      data-enable-time
                      value={endsAt}
                      name="ends_at"
                      onChange={(selectedDate, dateStr, instance) =>
                        setEndsAt(selectedDate[0].valueOf())
                      }
                      options={{ minuteIncrement: 5 }}
                      className="w-100"
                    />
                  </Form.Group>
                )}
              </div>

              {allFormFields.length > 0 ? (
                <div>
                  {allFormFields.map((field, idx) => (
                    <div className="border rounded p-3 mb-3" key={idx}>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <p className="text-uppercase small mb-1">
                            <b className="mr-2">
                              {idx + 1}. {field.type}
                            </b>
                            <small>
                              ({field.required ? "Mandatory" : "Not mandatory"})
                            </small>
                          </p>
                        </div>

                        {formLive ? (
                          formUpdating && (
                            <div className="d-flex">
                              <a
                                className="mr-2 pointer"
                                onClick={() => {
                                  setUpdateField(true);
                                  setUpdateFieldKey(idx);
                                  setShowModalAnotherField(true);
                                  setFormFields({ ...field });
                                  setFieldType(field.type);
                                  if (field.type === "select") {
                                    setSelectValues(field.values);
                                  }
                                }}
                              >
                                <FontAwesomeIcon size="sm" icon={faEdit} />
                              </a>
                              <a
                                className="text-danger pointer"
                                onClick={() => {
                                  setAllFormFields((prev) => {
                                    const oldState = [...prev];
                                    const newState = oldState.filter(
                                      (item) => item.id != idx + 1
                                    );
                                    return newState;
                                  });
                                }}
                              >
                                <FontAwesomeIcon size="sm" icon={faTrash} />
                              </a>
                            </div>
                          )
                        ) : (
                          <div className="d-flex">
                            <a
                              className="mr-2 pointer"
                              onClick={() => {
                                setUpdateField(true);
                                setUpdateFieldKey(idx);
                                setShowModalAnotherField(true);
                                setFormFields({ ...field });
                                setFieldType(field.type);
                                if (field.type === "select") {
                                  setSelectValues(field.values);
                                }
                              }}
                            >
                              <FontAwesomeIcon size="sm" icon={faEdit} />
                            </a>
                            <a
                              className="text-danger pointer"
                              onClick={() => {
                                setAllFormFields((prev) => {
                                  const oldState = [...prev];
                                  const newState = oldState.filter(
                                    (item) => item.id != idx + 1
                                  );
                                  return newState;
                                });
                              }}
                            >
                              <FontAwesomeIcon size="sm" icon={faTrash} />
                            </a>
                          </div>
                        )}
                      </div>

                      <p className="mb-0 small text-uppercase text-muted">
                        <b>Field label</b>
                      </p>
                      <p className={field.type === "select" ? "mb-1" : "mb-0"}>
                        {field.label}
                      </p>

                      {field.type === "select" && (
                        <React.Fragment>
                          <p className="mb-0 small text-uppercase text-muted">
                            <b>Values</b>
                          </p>
                          <p className="mb-0">{field.values.join(", ")}</p>
                        </React.Fragment>
                      )}

                      {field.type === "rating" && (
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="mb-0 small text-uppercase text-muted">
                              <b>Start Label</b>
                            </p>
                            <p className="mb-0">{field.start_label}</p>
                          </div>
                          <div>
                            <p className="mb-0 small text-uppercase text-muted">
                              <b>End Label</b>
                            </p>
                            <p className="mb-0">{field.end_label}</p>
                          </div>
                          <div>
                            <p className="mb-0 small text-uppercase text-muted">
                              <b>Start Value</b>
                            </p>
                            <p className="mb-0">{field.start_value}</p>
                          </div>
                          <div>
                            <p className="mb-0 small text-uppercase text-muted">
                              <b>End Value</b>
                            </p>
                            <p className="mb-0">{field.end_value}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <p className="">No field added yet</p>
                </div>
              )}

              <div className="d-flex align-items-center mb-3">
                <Button
                  size="sm"
                  className="text-uppercase"
                  onClick={() => setShowModalAnotherField(true)}
                >
                  Add Form Field
                </Button>
              </div>
            </div>

            <Modal
              show={showModalAnotherField}
              onHide={() => {
                setFormFields({});
                setFieldType("");
                setSelectValues([]);

                setShowModalAnotherField(false);
              }}
            >
              <Modal.Header closeButton>
                <div>
                  <h4 className="mb-0">
                    {updateField ? formFields?.label : "Add Field"}
                  </h4>
                  {updateField && (
                    <p className="text-muted text-uppercase mb-0">
                      Update Field
                    </p>
                  )}
                </div>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label className="text-uppercase small text-muted">
                    <b>Field Type</b>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setFieldType(e.target.value);
                      if (e.target.value === "rating") {
                        setFormFields((prev) => {
                          return {
                            ...prev,
                            start_value: 1,
                            end_value: 5,
                            start_label: "Not good",
                            end_label: "Perfect",
                          };
                        });
                      }
                    }}
                    value={fieldType}
                  >
                    <option value="">Choose field type</option>
                    <option value="text">Text</option>
                    <option value="select">Select</option>
                    <option value="number">Number</option>
                    <option value="rating">Rating</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="text-uppercase small text-muted">
                    <b>Field Label</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="label"
                    placeholder="Enter field label"
                    value={formFields?.label}
                    onChange={(e) => {
                      setFormFields((prev) => {
                        return updateField
                          ? {
                              ...prev,
                              [e.target.name]: e.target.value,
                            }
                          : {
                              ...prev,
                              [e.target.name]: e.target.value,
                              field_name: e.target.value
                                .toLowerCase()
                                .replace(/[^a-zA-Z0-9]/g, "_"),
                            };
                      });
                    }}
                  />
                  <Form.Text className="text-muted">
                    Label will be displayed as name of this form field.
                  </Form.Text>
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    type="hidden"
                    name="field_name"
                    value={formFields?.field_name}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="text-uppercase small text-muted mb-0">
                    <b>Mandatory Field</b>
                  </Form.Label>
                  <Form.Text className="text-muted mb-2">
                    (If this field is <b>mandatory</b> to be filled by user or
                    not)
                  </Form.Text>
                  <FormCheck
                    type="switch"
                    id="field-required"
                    label={formFields?.required ? "Yes" : "No"}
                    checked={formFields?.required}
                    onChange={(e) =>
                      setFormFields((prev) => {
                        return {
                          ...prev,
                          required: e.target.checked,
                        };
                      })
                    }
                  ></FormCheck>
                </Form.Group>

                {fieldType === "select" && (
                  <React.Fragment>
                    <Form.Group>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="text-uppercase small text-muted mb-0">
                          <b>Values for select</b>
                        </Form.Label>
                        <Button
                          size="sm"
                          onClick={() =>
                            setSelectValues((prev) => [...prev, ""])
                          }
                          className="text-uppercase"
                        >
                          Add Value
                        </Button>
                      </div>
                      {selectValues.map((item, key) => {
                        return (
                          <div key={key}>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <Form.Label className="small text-muted mb-0">
                                Value {key + 1}
                              </Form.Label>
                              <a
                                title="Remove"
                                onClick={() => {
                                  setSelectValues((prev) => {
                                    const oldState = [...prev];
                                    const newState = [
                                      ...oldState.slice(0, key),
                                      ...oldState.slice(key + 1),
                                    ];
                                    return newState;
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} size="sm" />
                              </a>
                            </div>
                            <Form.Control
                              type="text"
                              name={`values-${key}`}
                              value={selectValues[key]}
                              onChange={(e) => {
                                setSelectValues((prev) => {
                                  const oldValues = [...prev];
                                  oldValues[key] = e.target.value;
                                  return oldValues;
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </Form.Group>
                  </React.Fragment>
                )}

                {fieldType === "rating" && (
                  <React.Fragment>
                    <Form.Group>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="text-uppercase small text-muted mb-0">
                          <b>Labels for Rating</b>
                        </Form.Label>
                      </div>
                      <div className="w-100">
                        <div className="row">
                          <div className="col-6">
                            <Form.Group>
                              <Form.Label className="text-uppercase small text-muted">
                                Starting label
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="start_label"
                                placeholder="Enter starting label"
                                value={formFields?.start_label ?? "Not good"}
                                onChange={(e) => {
                                  setFormFields((prev) => {
                                    return {
                                      ...prev,
                                      [e.target.name]: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-6">
                            <Form.Group>
                              <Form.Label className="text-uppercase small text-muted">
                                Ending label
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="end_label"
                                placeholder="Enter ending label"
                                value={formFields?.end_label ?? "Perfect"}
                                onChange={(e) => {
                                  setFormFields((prev) => {
                                    return {
                                      ...prev,
                                      [e.target.name]: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label className="text-uppercase small text-muted mb-0">
                          <b>Range of values</b>
                        </Form.Label>
                      </div>
                      <div className="w-100">
                        <div className="row">
                          <div className="col-6">
                            <Form.Group>
                              <Form.Label className="text-uppercase small text-muted">
                                Start value
                              </Form.Label>
                              <Form.Control
                                type="number"
                                min={1}
                                max={
                                  formFields?.end_value
                                    ? formFields?.end_value - 1
                                    : false
                                }
                                name="start_value"
                                placeholder="Enter start value"
                                value={formFields?.start_value ?? 1}
                                onChange={(e) => {
                                  setFormFields((prev) => {
                                    return {
                                      ...prev,
                                      [e.target.name]: parseInt(e.target.value),
                                    };
                                  });
                                }}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-6">
                            <Form.Group>
                              <Form.Label className="text-uppercase small text-muted">
                                End value
                              </Form.Label>
                              <Form.Control
                                type="number"
                                min={2}
                                name="end_value"
                                placeholder="Enter end value"
                                value={formFields?.end_value ?? 5}
                                onChange={(e) => {
                                  setFormFields((prev) => {
                                    return {
                                      ...prev,
                                      [e.target.name]: parseInt(e.target.value),
                                    };
                                  });
                                }}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </React.Fragment>
                )}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-start">
                {updateField ? (
                  <Button
                    size="sm"
                    variant="primary"
                    className="text-uppercase mr-2"
                    onClick={() => {
                      setAllFormFields((prev) => {
                        const oldState = [...prev];

                        if (fieldType === "select") {
                          if (selectValues.length > 0) {
                            return [
                              ...oldState.slice(0, updateFieldKey),
                              {
                                ...formFields,
                                type: fieldType,
                                values: selectValues,
                              },
                              ...oldState.slice(updateFieldKey + 1),
                            ];
                          } else {
                            toast.error("Please add some values", {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                            });
                          }
                        } else
                          return [
                            ...oldState.slice(0, updateFieldKey),
                            {
                              ...formFields,
                              type: fieldType,
                            },
                            ...oldState.slice(updateFieldKey + 1),
                          ];
                      });
                      setShowModalAnotherField(false);

                      setFormFields({});
                      setFieldType("");
                      setSelectValues([]);
                      setUpdateField(false);
                      setUpdateFieldKey(null);
                    }}
                  >
                    Save Updates
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    variant="success"
                    className="text-uppercase mr-2"
                    onClick={() => {
                      setAllFormFields((prev) => {
                        if (fieldType === "select") {
                          if (selectValues.length > 0) {
                            return [
                              ...prev,
                              {
                                ...formFields,
                                type: fieldType,
                                id: prev.length + 1,
                                values: selectValues,
                              },
                            ];
                          } else {
                            toast.error("Please add some values", {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                            });
                          }
                        } else {
                          if (prev?.length > 0) {
                            return [
                              ...prev,
                              {
                                ...formFields,
                                type: fieldType,
                                id: prev.length + 1,
                              },
                            ];
                          } else {
                            return [
                              {
                                ...formFields,
                                type: fieldType,
                                id: 1,
                              },
                            ];
                          }
                        }
                      });
                      setShowModalAnotherField(false);

                      setFormFields({});
                      setFieldType("");
                      setSelectValues([]);
                    }}
                  >
                    Save Changes
                  </Button>
                )}

                <Button
                  size="sm"
                  variant="none"
                  className="text-uppercase"
                  onClick={() => {
                    setFormFields({});
                    setFieldType("");
                    setSelectValues([]);
                    setUpdateField(false);
                    setUpdateFieldKey(null);

                    setShowModalAnotherField(false);
                  }}
                >
                  Discard changes
                </Button>
              </Modal.Footer>
            </Modal>

            <Button
              size="sm"
              className="text-uppercase"
              type="submit"
              disabled={submittingForm}
            >
              {submittingForm && (
                <span className="mr-2">
                  <Spinner animation="border" size="sm" />
                </span>
              )}
              Update Form
            </Button>
          </Form>
        </div>
      ) : null}

      {/* <Accordion
                  defaultActiveKey={
                    formDetails?.form_fields?.length ? null : "0"
                  }
                >
                  {formDetails?.form_fields?.length && (
                    <div className="d-flex align-items-center mb-2">
                      <p className="mb-0 mr-1">
                        {formDetails?.form_fields?.length} fields,
                      </p>
                      <Accordion.Toggle
                        className="btn btn-link p-0 m-0"
                        eventKey="0"
                      >
                        show full details
                      </Accordion.Toggle>
                    </div>
                  )}
                  <Accordion.Collapse eventKey="0">
                    <div>
                      {formDetails?.form_fields?.length > 0 ? (
                        <div>
                          {formDetails?.form_fields.map((field, idx) => (
                            <div className="border rounded p-3 mb-3" key={idx}>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                  <p className="text-uppercase small mb-1">
                                    <b className="mr-2">
                                      {idx + 1}. {field.type}
                                    </b>
                                    <small>
                                      (
                                      {field.required
                                        ? "Mandatory"
                                        : "Not mandatory"}
                                      )
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <p className="mb-0 small text-uppercase text-muted">
                                <b>Field label</b>
                              </p>
                              <p
                                className={
                                  field.type === "select" ? "mb-1" : "mb-0"
                                }
                              >
                                {field.label}
                              </p>

                              {field.type === "select" && (
                                <React.Fragment>
                                  <p className="mb-0 small text-uppercase text-muted">
                                    <b>Values</b>
                                  </p>
                                  <p className="mb-0">
                                    {field.values.join(", ")}
                                  </p>
                                </React.Fragment>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center">
                          <p className="">No field added yet</p>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion> */}
      {/*----------------Delete Form Modal---------------------------------------- */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Delete this form</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Discard
          </Button>
          <Button variant="danger" onClick={() => deleteSelectedForm()}>
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/*----------------Publish Form Modal---------------------------------------- */}
      <Modal show={publishFormModal} onHide={() => setPublishFormModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Publish Form</Modal.Title>
        </Modal.Header>
        {publishingFormDetails?.form_fields?.length > 0 ? (
          <Modal.Body>
            Are you sure you want to <b>Publish this form</b>? After this
            participants will be able to see this form.
          </Modal.Body>
        ) : (
          <Modal.Body>
            Please add atleast 1 form field in form to publish it. Click on{" "}
            <b>Add Form Field</b> to add form fields.
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setPublishFormModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            disabled={!publishingFormDetails?.form_fields?.length}
            onClick={() => publishSelectedForm()}
          >
            Publish
          </Button>
        </Modal.Footer>
      </Modal>

      {/*----------------unPublish Form Modal---------------------------------------- */}
      <Modal
        show={unPublishFormModal}
        onHide={() => setUnPublishFormModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unpublish Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Unpublish this form</b>? Participants will
          no longer be able to see it in their dashboard.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setUnPublishFormModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={() => unPublishSelectedForm()}
          >
            Unpublish
          </Button>
        </Modal.Footer>
      </Modal>

      {/*----------------Start Now Form Modal---------------------------------------- */}
      <Modal show={startNowModal} onHide={() => setStartNowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Start now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Start form now</b>? Participants will be
          able to respond to form.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setStartNowModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={() => startNowSelectedForm()}
          >
            Start now
          </Button>
        </Modal.Footer>
      </Modal>

      {/*----------------Close Now Form Modal---------------------------------------- */}
      <Modal show={closeNowModal} onHide={() => setCloseNowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Close now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to <b>Close form now</b>? Participants will no
          longer be able to respond to the form.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-uppercase"
            onClick={() => setCloseNowModal(false)}
          >
            Discard
          </Button>
          <Button
            variant="primary"
            className="text-uppercase"
            onClick={() => closeNowSelectedForm()}
          >
            Close form
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
});

export default connect(mapStateToProps)(IgesiaForm);
