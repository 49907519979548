import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { Button, Modal, Form, Card } from "react-bootstrap";
import {
  setSixPathHeader,
  setSixPathRows,
} from "../../../../store/actions/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function SixPathTable(props) {
  const {
    setSixPathHeader,
    setSixPathRows,
    sixPathRows,
    sixPathHeaders,
    graphId,
    currentEvent,
    darkTheme,
  } = props;

  const [path, setPath] = useState(0);
  const [pathName, setPathName] = useState("");
  const [addRowModal, setAddRowModal] = useState(false);
  const [newRowValue, setNewRowValue] = useState({});
  const [editSelectedValue, setEditSelectedValue] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);

  const [headDoc, headDocLoading, headDocError] = useDocument(
    firebase
      .firestore()
      .doc(
        `events/${currentEvent?.id}/bbos/${graphId}/six_path/${path.toString()}`
      )
  );

  useEffect(() => {
    if (headDoc?.data()) {
      setSixPathHeader(headDoc?.data().head);
      setPathName(headDoc?.data().name);
    }
  }, [headDoc]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(
        `events/${currentEvent?.id
        }/bbos/${graphId}/six_path/${path.toString()}/rows`
      )
      .onSnapshot((snapshot) => {
        let arr = [];
        snapshot.forEach((item) => {
          arr.push({ ...item.data(), id: item.id });
        });
        setSixPathRows(arr);
      });
  }, [path, pathName]);

  const addNewRowValuesHandler = (e) => {
    e.preventDefault();
    // console.log(newRowValue, path, sixPathRows.length, "values")
    if (path >= 0 && sixPathRows.length >= 0) {
      firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id
          }/bbos/${graphId}/six_path/${path.toString()}/rows`
        )
        .doc(`${sixPathRows.length}`)
        .set({
          ...newRowValue,
        });
      setAddRowModal(false);
      setNewRowValue({});
    }
  };

  const updateValueHandler = (e) => {
    e.preventDefault();
    console.log(editSelectedValue);
    if (editSelectedValue) {
      firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id
          }/bbos/${graphId}/six_path/${path.toString()}/rows`
        )
        .doc(`${editSelectedValue.rowId}`)
        .set(
          {
            [editSelectedValue.headId]: editSelectedValue.value,
          },
          {
            merge: true,
          }
        );
      setShowEditModal(false);
      setEditSelectedValue({});
    }
  };

  const deleteCurrentRow = (value) => {
    console.log(value)
    if (value) {
      firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id
          }/bbos/${graphId}/six_path/${path.toString()}/rows`
        )
        .doc(`${value.rowId}`)
        .delete()
        .then(() => {
          toast.success("You have successfully deleted row", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });

        }).catch((error) => {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
        setShowEditModal(false)
    }
  }

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header>
        <h5 className="mb-0 text-uppercase">{props.currentEvent?.bbos_names?.six_path ?? "Six Path"} Template</h5>
      </Card.Header>
      <Card.Body>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <h6 className="text-uppercase">{pathName && pathName}</h6>
          </div>
          <div>
            <div className="d-flex my-2">
              <Button
                variant="light"
                size="sm"
                className="mr-2 text-uppercase"
                onClick={() => {
                  if (path > 0) {
                    setPath(path - 1);
                    setSixPathRows([]);
                    setSixPathHeader([]);
                  } else {
                    return;
                  }
                }}
                disabled={path > 0 ? false : true}
                title={path > 0 ? `Go to previous path` : `No previous path`}
              >
                {`<< Previous`}
              </Button>

              <Button
                size="sm"
                className="mr-2 button-muted text-uppercase"
                onClick={() => {
                  if (path < 5) {
                    setPath(path + 1);
                    setSixPathRows([]);
                    setSixPathHeader([]);
                  } else {
                    return;
                  }
                }}
                disabled={path < 5 ? false : true}
                title={path < 5 ? `Go to next path` : `No next path`}
              >
                {"Next >>"}
              </Button>
            </div>
          </div>
        </div>
        <table class="table table-striped">
          <thead className={darkTheme ? "thead-light" : "thead-dark"}>
            <tr>
              {sixPathHeaders?.map((item) => (
                <th scope="col" key={item.id}>
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-100">
            {sixPathRows?.length > 0 ? (
              sixPathRows?.map((rowData) => {
                return (
                  <tr>
                    {sixPathHeaders?.map((headData, index) => {
                      return (
                        <td
                          className={darkTheme ? " border border-light" : " border border-dark"}
                          data-theme={darkTheme ? "dark" : "light"}
                          onClick={(e) => {
                            // console.log( headData.id, rowData.id, rowData[headData.id])
                            setEditSelectedValue({
                              rowId: rowData.id,
                              headId: headData.id.toString(),
                              value: rowData[headData.id],
                            });
                            setShowEditModal(true);
                          }}
                        >
                          {rowData[headData.id]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <div className="py-2">
                <p className={darkTheme ? "mb-0 text-light" : "mb-0 text-dark"}>
                  No rows added yet
                </p>
              </div>
            )}
          </tbody>
        </table>
        <div className="w-100">
          <Button
            className="text-uppercase w-100"
            size="sm"
            onClick={() => setAddRowModal(true)}
          >
            Add Row
          </Button>
        </div>
        <>
          <Modal
            centered
            show={addRowModal}
            onHide={() => setAddRowModal(false)}
          >
            <Modal.Header data-theme={darkTheme ? "dark" : "light"} closeButton>
              <Modal.Title >{pathName && pathName}</Modal.Title>
            </Modal.Header>
            <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
              <Form onSubmit={(e) => addNewRowValuesHandler(e)}>
                {sixPathHeaders?.map((item, index) => {
                  return (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{item.name}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder={item.name}
                        value={newRowValue[item.id]}
                        onChange={(e) =>
                          setNewRowValue((prev) => {
                            const newState = {
                              ...prev,
                              [item.id]: e.target.value,
                            };
                            return newState;
                          })
                        }
                      />
                    </Form.Group>
                  );
                })}
              </Form>
            </Modal.Body>
            <Modal.Footer data-theme={darkTheme ? "dark" : "light"}>
              {Object.keys(newRowValue).length === 0 ? (
                <Button
                  variant="secondary"
                  onClick={() => setAddRowModal(false)}
                >
                  Close
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setNewRowValue({});
                    setAddRowModal(false);
                  }}
                >
                  Discard
                </Button>
              )}

              <Button
                variant="primary"
                onClick={(e) => addNewRowValuesHandler(e)}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <>
          <Modal
            centered
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          >
            <Modal.Header data-theme={darkTheme ? "dark" : "light"} closeButton>
              <Modal.Title>{`Edit Clicked Value`}</Modal.Title>
            </Modal.Header>
            <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
              <Form onSubmit={(e) => updateValueHandler(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="Enter value"
                    value={editSelectedValue?.value}
                    onChange={(e) =>
                      setEditSelectedValue((prev) => {
                        const newState = { ...prev, value: e.target.value };
                        return newState;
                      })
                    }
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer data-theme={darkTheme ? "dark" : "light"}>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Close
              </Button>
              <Button size="sm" variant="primary" onClick={(e) => updateValueHandler(e)}>
                Save
              </Button>
              <Button
                onClick={() => {
                  deleteCurrentRow(editSelectedValue);
                }}
                size="sm"
                variant="danger"
              >
                <div>
                  <FontAwesomeIcon
                    size="sm"
                    icon={faTrash}
                  />{" "}
                  Delete row
                </div>
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 text-uppercase small">
          <b>Pro tips</b>
        </p>
        <ol className="px-3 mb-1 pb-1">
          <li>
            <small>
              Add a new row by clicking on <b>Add Row</b> button under the
              table.
            </small>
          </li>
          <li>
            <small>
              Navigate the 6 paths using the <b>Next</b> and <b>Previous</b>{" "}
              buttons on top right.
            </small>
          </li>
        </ol>
      </Card.Footer>
    </Card>
  );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    sixPathRows: state.sixPathRows,
    sixPathHeaders: state.sixPathHeaders,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  {
    setSixPathHeader,
    setSixPathRows,
  }
)(SixPathTable);
