import React, { memo, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import { API_BASE_URL, USER_SECTION_DATA_GET } from "../../config";

function ListSections(props) {
  // CONSTANTS
  const { name, category, parent_id, parent_type } = props;

  // STATES
  const [sectionList, setSectionList] = useState([]);
  const [fetchingSectionList, setFetchingSectionList] = useState(false);

  // FUNCTIONS
  const handleFetchSectionList = async () => {
    try {
      setFetchingSectionList(true);
      const response = await axios.get(
        `${API_BASE_URL}${USER_SECTION_DATA_GET}?parent_id=${parent_id}&parent_type=${parent_type}&category=${category}`
      );

      if (response.status === 200) {
        setFetchingSectionList(false);
        setSectionList(response?.data?.data?.section_list);
      } else {
        setFetchingSectionList(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching section list: `, error.message);
      setFetchingSectionList(false);
    }
  };

  useEffect(() => {
    if (category != "" && parent_id != "" && typeof parent_type === "number") {
      handleFetchSectionList();
    }
  }, []);

  return (
    <>

  {sectionList?.length > 0 ? (
      <Card
        style={{ width: "100%" }}
        className="border mb-3"
      >
        <Card.Body>
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0 text-s-6">{name}</h5>
            </div>
          </div>

          <ul className="checkmark-list card-text">
            {sectionList?.length === 0 && (
              <p className="text-center">
                <i>No items added yet</i>
              </p>
            )}
            {sectionList?.map((val, key) => (
              <li key={key} className="checkmark-list  mb-2">
                {val.value}
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card>
      ) : null }
    </>

  );
}

export default memo(ListSections);
