import React,{useState} from 'react';


import {Toast} from "react-bootstrap"

const Notification= (props)=> {

  return (
        <Toast onClose={props.onHide} show={props.show} delay={3000} autohide>
          <Toast.Header>
            
            <strong className="mr-auto">{props.header}</strong>
            
          </Toast.Header>
          <Toast.Body>{props.body}</Toast.Body>
        </Toast>
  );
}

export default Notification;