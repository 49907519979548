import React, { useState, useEffect, useRef } from "react";
import { Card, Image, Button, Dropdown, Form } from "react-bootstrap";

import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { languageText } from "../Event/language.json";
import { useHistory, useLocation } from "react-router-dom";

import * as CourseData from "./course.json";
import * as CourseStyle from "./course.module.css";
import CourseRedux from "./CourseRedux";

function CourseAuthentication({ currentLanguage, changeLanguage, course }) {
  const { pathname } = useLocation();
  const history = useHistory();

  const pageRef = useRef(null);

  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();

    const payment_history = window.localStorage.getItem("payment_success")
      ? JSON.parse(window.localStorage.getItem("payment_success"))
      : [];
    const current_payment =
      payment_history?.length > 0
        ? payment_history?.filter((item) => item.id == course?.id)
        : [];
    if (current_payment?.length == 0 || current_payment == null) {
      history.goBack();
    }
  }, []);

  const handleRegister = () => {
    if (
      pathname.split("/")[2] !== "" &&
      pathname.split("/")[3] === "fill-details"
    ) {
      history.push(`dashboard`);
    }
  };

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      <CourseRedux />
      {/* Top navigation start */}
      <div className="container pt-3 pt-md-4 d-flex align-items-center justify-content-between">
        <div onClick={() => history.push(`/`)}>
          <Image
            className={`rounded ${CourseStyle.course_cover_logo}`}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
        </div>
        <Dropdown className="mr-3 small text-white">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {languageText[`${currentLanguage}`].english}
            </Dropdown.Item>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "FR"}
              onClick={() => changeLanguage("FR")}
            >
              {languageText[`${currentLanguage}`].french}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-md-8 mb-3 mb-md-4  col-12 mx-auto">
            <h2 className="font-weight-bold text-white text-center">
              {course?.name}
              {course?.course_details
                ? course.course_details.EN.course_title
                : null}
            </h2>
            {/* <p className="text-white">May 11 7:30 AM to May 11 6:00 PM CET</p> */}
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-8 col-12 mx-auto bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              style={{ position: "sticky", top: 60 }}
            >
              <Card.Body>
                <p className="text-center h5 mb-3">
                  Please fill below information
                  <br />
                  to register for
                  <br />
                  {CourseData?.programName}
                </p>
                <div className="mb-3">
                  <Form
                    className="mt-3"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleRegister();
                    }}
                  >
                    <Form.Group>
                      <Form.Label className="small">Organisation</Form.Label>
                      <Form.Control
                        type="text"
                        required={true}
                        placeholder="Enter organisation"
                        className="border rounded-lg px-2"
                      />
                    </Form.Group>

                    <Form.Group controlId="formGroupEmail">
                      <Form.Label className="small">Address</Form.Label>
                      <Form.Control
                        type="text"
                        required={true}
                        placeholder="Enter address"
                        className="border rounded-lg px-2"
                      />
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      //onClick={handleRegister}
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {/* Event cover ends */}
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage, course: state.course }),
  { changeLanguage }
)(CourseAuthentication);
