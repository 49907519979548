import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

const EventManagerNavbar = (props) => {
  const {
    eventId,
    navData,
    currentNavKey,
    setCurrentNavKey,
    closeLeftSide,
    setBbos,
  } = props;

  const history = useHistory();
  const { pathname, hash } = useLocation();

  // useEffect(() => {
  //   navData.forEach((item, key) => {
  //     if (decodeURIComponent(hash) === `#${item.eventKey}`) {
  //       setCurrentNavKey(item.eventKey);
  //     }
  //     if (hash === "") {
  //       setCurrentNavKey("overview");
  //     }
  //   });
  // }, [hash]);

  return (
    <Nav
      variant="navs"
      justify
      defaultActiveKey={currentNavKey}
      activeKey={currentNavKey}
      className=" lead text-primary bg-dark rounded flex-column"
    >
      {navData.map((item, key) => (
        <>
          {item?.show ? (
            <Nav.Item key={key}>
              <Nav.Link
                className={
                  currentNavKey === item.eventKey
                    ? "text-primary text-left "
                    : "text-light text-left"
                }
                eventKey={item.eventKey}
                onSelect={(e) => {
                  setCurrentNavKey(e);

                  if (pathname?.split("/")?.length === 5) {
                    history.push(`./${e}`);
                  } else {
                    history.push(`./${eventId}/${e}`);
                  }

                  setBbos("");
                  closeLeftSide();
                }}
              >
                {item.name}
              </Nav.Link>
            </Nav.Item>
          ) : null}
        </>
      ))}
    </Nav>
  );
};

const mapStateToProps = (state) => ({
  eventId: state.eventId,
});
export default connect(mapStateToProps)(EventManagerNavbar);
