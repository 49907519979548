import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faKey,
  faLanguage,
  faLock,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUsers,
  faUsersCog,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import EventManagerNavbar from "./EventManagerNavbar";
// import EventNavbar from "./Navbar";
import Sidebar from "../Sidebar/Index";
import Overview from "./Overview";
import Tickets from "./Tickets";
import Speakers from "./Speakers";
import Schedule from "./Schedule";
import Sponsors from "./Sponsors";
import Booths from "./Booths";
import Attendees from "./Attendees";
import Stage from "./Stage";
import { useLocation } from "react-router";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { connect } from "react-redux";
import { setCourseDetails, setCourseID } from "../../../store/actions/actions";

const CourseManager = (props) => {
  const { courseid } = useParams();
  const nav_data = useMemo(() => {
    return [
      {
        id: 1,
        name: "Overview",
        eventKey: "overview",
      },
      // {
      //   id: 2,
      //   name: "Tickets",
      //   eventKey: "tickets",
      // },
      {
        id: 3,
        name: "Curriculum",
        eventKey: "curriculum",
      },
      // {
      //   id: 4,
      //   name: "Schedule",
      //   eventKey: "schedules",
      // },
      {
        id: 5,
        name: "Instructors",
        eventKey: "instructors",
      },
      {
        id: 7,
        name: "Students",
        eventKey: "students",
      },
    ];
  }, []);

  const [currentNavKey, setCurrentNavKey] = useState("overview");

  const { hash } = useLocation();
  useEffect(() => {
    nav_data.forEach((item, key) => {
      if (decodeURIComponent(hash) === `#${item.eventKey}`) {
        setCurrentNavKey(item.eventKey);
      }
      if (hash === "") {
        setCurrentNavKey("overview");
      }
    });
  }, [hash, nav_data]);

  const [courseSnapshot, loadingCourse, errorCourse] = useDocument(
    firebase.firestore().collection("courses").doc(courseid)
  );

  const courseData = courseSnapshot?.data() ?? {};

  useEffect(() => {
    if (!loadingCourse) {
      props.setCourseID(courseid);
      props.setCourseDetails({ courseData, id: courseSnapshot?.id });
    }
  }, [courseSnapshot, loadingCourse]);

  return (
    <>
      <div className="d-flex vh-100" style={{ background: "#232626" }}>
        {/* <EventNavbar /> */}
        <Sidebar />
        <div className="overflow-auto max-vh-100 p-4 w-100" id="style-4">
          <EventManagerNavbar
            setCurrentNavKey={setCurrentNavKey}
            navData={nav_data}
          />
          {currentNavKey === "overview" && <Overview />}
          {/* {currentNavKey === "tickets" && <Tickets />} */}
          {currentNavKey === "curriculum" && <Stage />}
          {/* {currentNavKey === "schedules" && <Schedule />} */}
          {currentNavKey === "instructors" && <Speakers />}
          {currentNavKey === "students" && <Attendees />}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, { setCourseDetails, setCourseID })(
  CourseManager
);
