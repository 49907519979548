import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Card } from "react-bootstrap";

import * as EventStyle from "../event.module.css";

export default function CoverLoader(props) {
  const { mobile } = props;
  if (mobile) {
    return (
      <>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded mb-2"
          height={40}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded w-50 mb-3"
          height={40}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded w-25 mb-3"
          height={18}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded mb-0"
          height={320}
        />
        <Card.Body className={` rounded ${EventStyle.border_light}`}>
          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-100 mb-2"
            height={18}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-100 mb-2"
            height={18}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-25 mb-3"
            height={18}
          />

          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-100 mb-2"
            height={14}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-100 mb-2"
            height={14}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-100 mb-2"
            height={14}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            className="bg-muted rounded w-50 mb-0"
            height={14}
          />
        </Card.Body>
      </>
    );
  } else {
    return (
      <div>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded mb-2"
          height={28}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded w-75 mb-3"
          height={28}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded w-50 mb-2"
          height={12}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded"
          height={28}
        />
      </div>
    );
  }
}
