import React, { useState } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import * as TimelineStyle from "./timeline.module.css";
import { useHistory } from "react-router";
import { mySpaceData } from "../data";

export default function MySpace() {
    const history = useHistory();

    const [cta, setCta] = useState(100);

    return (
        <Card className={`shadow-sm ${TimelineStyle.cta_card}`}>
          <Card.Header className="border-0">
            <h4 className="text-white font-weight-bold">My Space</h4>
          </Card.Header>
          <Card.Body className="pt-0">
            <div className="d-flex mx-2 mb-3">
              <Tabs
                activeKey={cta}
                onSelect={(k) => setCta(parseInt(k))}
                id="cta"
                variant="pills"
                className="flex-grow-1"
              >
                <Tab
                  eventKey={100}
                  title="All"
                  tabClassName={TimelineStyle.cta_tabs}
                />
                <Tab
                  eventKey={0}
                  title="Event"
                  tabClassName={TimelineStyle.cta_tabs}
                />
                <Tab
                  eventKey={1}
                  title="Course"
                  tabClassName={TimelineStyle.cta_tabs}
                />
                <Tab
                  eventKey={2}
                  title="Consultation"
                  tabClassName={TimelineStyle.cta_tabs}
                />
                <Tab
                  eventKey={3}
                  title="Project"
                  tabClassName={TimelineStyle.cta_tabs}
                />
              </Tabs>
            </div>

            <div className="d-flex" style={{ overflowX: "scroll" }}>
              {mySpaceData.map(
                (item, key) =>
                  (item.type === cta || cta == 100) && (
                    <div className="col-md-4 pl-0" key={key}>
                      <Card
                        className={`h-100 ${TimelineStyle.services_cards}`}
                        onClick={() => {
                          switch (item.type) {
                            case 0:
                              history.push(`event/${item.slug}`);
                              break;
                            case 1:
                              history.push(`course/${item.slug}`);
                              break;
                            case 2:
                              history.push(`consultation/${item.slug}`);
                              break;
                            case 3:
                              history.push(`project/${item.slug}`);
                              break;
                            default:
                              history.push("404");
                              break;
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        title={`${item.name}`}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${item.image_url})`,
                            height: 150,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        />
                        <Card.Body className="py-2 px-3">
                          {item.type === 0 && (
                            <p className="small text-uppercase text-danger mb-1">
                              Event
                            </p>
                          )}
                          {item.type === 1 && (
                            <p className="small text-uppercase text-success mb-1">
                              Course
                            </p>
                          )}
                          {item.type === 2 && (
                            <p className="small text-uppercase text-info mb-1">
                              Consultation
                            </p>
                          )}
                          {item.type === 3 && (
                            <p className="small text-uppercase text-warning mb-1">
                              Project
                            </p>
                          )}
                          <p className="font-weight-bold text-white mb-1">
                            {item.name}
                          </p>
                          <p className="font-weight-bold small mb-0 text-warning">
                            {item.start_date}
                          </p>
                        </Card.Body>
                      </Card>
                    </div>
                  )
              )}
            </div>
          </Card.Body>
        </Card>
    )
}
