import React, { useState, useMemo, useEffect } from "react";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faCheckCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import LeftBar from "../LeftBar";
import NavBar from "../NavBar";
import EventChat from "../EventChat";
import { connect } from "react-redux";
import { API_BASE_URL, QUIZ_SUBMIT } from "../../../../config/index";
import axios from "axios";
import { toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Quiz = ({ darkTheme, currentEvent }) => {
  const { quiz_id } = useParams();
  const user = firebase.auth().currentUser;

  const [showChat, setShowChat] = useState(false);

  const [eventQuizDocs, eventQuizDocsLoading, eventQuizDocsError] =
    useCollection(
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/quizzes`)
        .where("content_id", "==", quiz_id)
    );

  const eventQuizDetails = useMemo(() => {
    if (!eventQuizDocsLoading && eventQuizDocs?.docs[0]?.exists) {
      return {
        ...eventQuizDocs?.docs[0]?.data(),
        id: eventQuizDocs?.docs[0]?.id,
      };
    } else return {};
  }, [eventQuizDocs, eventQuizDocsLoading]);

  const [quizSnapshot, quizDataLoading, quizDataError] = useDocument(
    firebase.firestore().doc(`quiz/${quiz_id}`)
  );

  const quizData = useMemo(() => {
    if (!quizDataLoading)
      return { ...quizSnapshot?.data(), id: quizSnapshot.id };
    else return {};
  }, [quizSnapshot, quizDataLoading]);

  console.log("ML QUIZ Data ==> ",quizData)

  const [responseAnswers, setResponseAnswers] = useState([]);
  const [imageSelected, setImageSelected] = useState(null);
  const [visible, setVisible] = useState(false);
  const [all, setAll] = useState([]);

  const [current_question, set_current_question] = useState(
    quizData && responseAnswers?.id
      ? Object.keys(quizData?.quizQuestions ?? {})?.length - 1
      : 0
  );

  const [submit, setSubmit] = useState(quizData && responseAnswers?.answers);
  const [answers, setAnswers] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const getResponses = () => {
    let docRef = firebase
      .firestore()
      .collection(`/quiz/${quiz_id}/responses`)
      .doc(`${user.uid}`);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setResponseAnswers({ ...doc.data(), id: doc.id });
          setSubmit(true);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    getResponses();
  }, []);

  useEffect(() => {
    if (quizData && responseAnswers?.answers) {
      let responsedAnswers = { ...responseAnswers.answers };

      let answers = [];
      Object.keys(quizData?.quizQuestions ?? {})
        .sort(
          (a, b) =>
            quizData?.quizQuestions[a]?.sequenceNo -
            quizData?.quizQuestions[b]?.sequenceNo
        )
        .map((q, k) => {
          let mulAnswers = [];
          let sinAnswer = "";
          Object.keys(quizData?.quizQuestions[q]?.options ?? {})
            .sort(
              (a, b) =>
                quizData?.quizQuestions[q]?.options[a]?.sequenceNo -
                quizData?.quizQuestions[q]?.options[b]?.sequenceNo
            )
            .map((o, key) => {
              if (quizData.quizQuestions[q].type === 1) {
                if (
                  responsedAnswers[q] &&
                  Object.keys(responsedAnswers[q].options).includes(o)
                ) {
                  mulAnswers.push(
                    quizData.quizQuestions[q].options[o].optionValue
                  );
                }
              } else {
                if (
                  responsedAnswers[q] &&
                  Object.keys(responsedAnswers[q].options).includes(o)
                ) {
                  sinAnswer = quizData.quizQuestions[q].options[o].optionValue;
                }
              }
            });
          if (mulAnswers.length > 0) {
            answers.push(mulAnswers);
          }
          if (sinAnswer) {
            answers.push(sinAnswer);
          }
          if (sinAnswer === "" && mulAnswers.length === 0) {
            answers.push("");
          }
        });
      setAnswers(answers);
      setSubmit(true);
    }
  }, [responseAnswers, quizData]);

  const addAnswer = (q_no, ans, type = 0, question_id, option_id) => {
    // console.log(q_no, ans, type, "q_no,ans,type");
    // console.log(question_id, option_id, "q_id,o_id");
    if (type === 0) {
      let allAnswers = answers;
      let resAnswers = [...responseAnswers];
      let prev = allAnswers[q_no] ? allAnswers[q_no] : "";
      let prevAns = resAnswers[q_no] ? resAnswers[q_no] : {};
      allAnswers[q_no] = prev === ans ? "" : ans;

      let json = { question_id: question_id, options: option_id };
      if (
        prevAns.question_id === question_id &&
        prevAns.option_id === option_id
      ) {
        resAnswers.push({});
      } else {
        resAnswers.splice(q_no, 1, json);
      }

      //resAnswers.push(json);
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    } else {
      let allAnswers = answers;
      let resAnswers = responseAnswers;
      let prev = allAnswers[q_no] ? allAnswers[q_no] : [];

      allAnswers[q_no] = prev.includes(ans)
        ? prev.filter((item) => item !== ans)
        : [...prev, ans];
      let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );
      let index = resAnswers.findIndex(
        (res) => res.question_id === question_id
      );
      PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};
      let prevAnswers =
        PrevAnswerObj && PrevAnswerObj.options ? PrevAnswerObj.options : [];
      prevAnswers = prevAnswers.includes(option_id)
        ? prevAnswers.filter((item) => item !== option_id)
        : [...prevAnswers, option_id];
      PrevAnswerObj.question_id = question_id;
      PrevAnswerObj.options = [...prevAnswers];
      if (index === -1) {
        resAnswers.push(PrevAnswerObj);
      } else {
        resAnswers.splice(index, 1, PrevAnswerObj);
      }
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    }
  };

  const handleSubmit = async () => {
    if (eventQuizDetails.questions_mandatory) {
      if (
        Object.entries(quizData.quizQuestions).length !== responseAnswers.length
      ) {
        toast.error("Please answer all the questions", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 5000,
        });

        return;
      }
    }

    axios
      .post(`${API_BASE_URL}${QUIZ_SUBMIT}`, {
        user_id: user.uid,
        room_id: currentEvent?.room_id,
        workspace_id: currentEvent?.workspace_id,
        quiz_id: quizData.id,
        answers: [...responseAnswers],
      })
      .then(() => {
        toast.success("Successfully submitted", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        getResponses();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (quizData && quizData?.isGraded) {
      let questionGradepointsArray = Object.values(quizData.quizQuestions)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
        .map((item) => parseInt(item.weight));

      setTotalPoints(
        questionGradepointsArray?.reduce((a, b) => parseInt(a) + parseInt(b))
      );
    }
  }, [quizData]);

  if (quizDataLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            {quizData.createdBy !== user.uid ? (
              <div>
                <Card
                  className={
                    darkTheme
                      ? "shadow-sm rounded bg-dark"
                      : "shadow-sm rounded bg-white"
                  }
                >
                  <Card.Header className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <h4 className="font-weight-bold mb-0">
                        {quizData?.quizName}
                      </h4>
                      <p className="small mb-0">
                        {moment(
                          convertSecondstoMilliSeconds(
                            quizData?.createdAt?.seconds,
                            quizData?.createdAt?.nanoseconds
                          )
                        ).format("LLL")}
                      </p>
                      {quizData?.isGraded && (
                        <p className="small mt-2 mb-0">
                          <b>Total grade points: </b>
                          {totalPoints}
                        </p>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="p-3">
                    <>
                      {quizData &&
                        Object.keys(quizData.quizQuestions)
                          .sort(
                            (a, b) =>
                              quizData.quizQuestions[a].sequenceNo -
                              quizData.quizQuestions[b].sequenceNo
                          )
                          .map(
                            (q, k) =>
                              current_question === k && (
                                <Card className="task" key={k}>
                                  <Card.Header className="bg-white d-flex">
                                    <small className="text-dark flex-grow-1">
                                      Question {k + 1} <br />
                                      {quizData.quizQuestions[q].weight
                                        ? quizData.quizQuestions[q].weight > 1
                                          ? `${quizData.quizQuestions[q].weight} points`
                                          : `${quizData.quizQuestions[q].weight} point`
                                        : ""}
                                    </small>
                                    <div className="d-flex">
                                      {current_question !== 0 && (
                                        <Button
                                          size="sm"
                                          variant="light"
                                          style={{
                                            width: "100px",
                                            marginRight: "5px",
                                          }}
                                          onClick={() =>
                                            set_current_question(
                                              (prev) => prev - 1
                                            )
                                          }
                                        >
                                          <small>Previous</small>
                                        </Button>
                                      )}

                                      {current_question !==
                                        Object.keys(quizData.quizQuestions)
                                          .length -
                                          1 && (
                                        <Button
                                          size="sm"
                                          variant="dark"
                                          style={{
                                            width: "100px",
                                            marginLeft: "5px",
                                          }}
                                          onClick={() =>
                                            set_current_question(
                                              (prev) => prev + 1
                                            )
                                          }
                                        >
                                          <small>Next</small>
                                        </Button>
                                      )}

                                      {current_question ===
                                        Object.keys(quizData.quizQuestions)
                                          .length -
                                          1 &&
                                        (submit ? (
                                          <Button
                                            size="sm"
                                            variant="primary"
                                            style={{
                                              width: "100px",
                                              marginLeft: "5px",
                                            }}
                                            disabled
                                          >
                                            <small>Completed</small>
                                          </Button>
                                        ) : (
                                          <Button
                                            size="sm"
                                            variant="success"
                                            style={{
                                              width: "100px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => handleSubmit()}
                                            disabled={
                                              responseAnswers.length === 0
                                            }
                                          >
                                            <small>Submit</small>
                                          </Button>
                                        ))}
                                    </div>
                                  </Card.Header>

                                  <Card.Body>
                                    <small className="font-weight-bold text-dark">
                                      {
                                        answers.filter((item) => item !== null)
                                          .length
                                      }{" "}
                                      of{" "}
                                      {
                                        Object.keys(quizData.quizQuestions)
                                          .length
                                      }{" "}
                                      answered{" "}
                                      {eventQuizDetails.questions_mandatory && (
                                        <span className="text-danger">
                                          (All questions are mandatory)
                                        </span>
                                      )}
                                    </small>

                                    <p className="text-dark">
                                      {quizData.quizQuestions[q].question}
                                    </p>
                                    <div>
                                      {quizData.quizQuestions[q]
                                        .questionImage && (
                                        <figure>
                                          <Zoom>
                                            <img
                                              alt={
                                                quizData.quizQuestions[q]
                                                  .question
                                              }
                                              src={
                                                quizData.quizQuestions[q]
                                                  .questionImage
                                              }
                                              className="img-fluid w-100"
                                            />
                                          </Zoom>
                                        </figure>
                                      )}
                                    </div>

                                    {Object.keys(
                                      quizData.quizQuestions[q].options
                                    )
                                      .sort(
                                        (a, b) =>
                                          quizData.quizQuestions[q].options[a]
                                            .sequenceNo -
                                          quizData.quizQuestions[q].options[b]
                                            .sequenceNo
                                      )
                                      .map((o, key) => (
                                        <>
                                          {quizData.quizQuestions[q].options[o]
                                            .optionImage == null ? null : (
                                            <img
                                              src={
                                                quizData.quizQuestions[q]
                                                  .options[o].optionImage
                                              }
                                              style={{ width: "100px" }}
                                            />
                                          )}
                                          <div
                                            className={
                                              quizData.quizQuestions[q].type ===
                                              0
                                                ? quizData.quizQuestions[q]
                                                    .options[o].optionValue ===
                                                    answers[k] ||
                                                  (responseAnswers[k] &&
                                                    q ===
                                                      responseAnswers[k][
                                                        "question_id"
                                                      ] &&
                                                    responseAnswers[k] &&
                                                    o ===
                                                      responseAnswers[k][
                                                        "options"
                                                      ])
                                                  ? "form-check rounded mb-2 border-dark d-flex"
                                                  : "form-check rounded mb-2 d-flex"
                                                : answers[k] &&
                                                  answers[k].includes(
                                                    quizData.quizQuestions[q]
                                                      .options[o].optionValue
                                                  )
                                                ? "form-check rounded mb-2 border-dark d-flex"
                                                : "form-check rounded mb-2 d-flex"
                                            }
                                            key={key}
                                          >
                                            <input
                                              className="form-check-input"
                                              type={
                                                quizData.quizQuestions[q]
                                                  .type === 0
                                                  ? "radio"
                                                  : "checkbox"
                                              }
                                              value={
                                                quizData.quizQuestions[q]
                                                  .options[o].optionValue
                                              }
                                              name={
                                                quizData.createdBy +
                                                "_" +
                                                quizData.quizQuestions[q]
                                                  .options[o].optionValue +
                                                k +
                                                key
                                              }
                                              id={
                                                quizData.createdBy +
                                                "_" +
                                                k +
                                                "_" +
                                                key +
                                                "_" +
                                                q
                                              }
                                              onChange={(e) => {
                                                addAnswer(
                                                  k,
                                                  e.target.value,
                                                  quizData.quizQuestions[q]
                                                    .type,
                                                  q,
                                                  o
                                                );
                                                setAll(e.target.value);
                                              }}
                                              checked={
                                                quizData.quizQuestions[q]
                                                  .type === 0
                                                  ? quizData.quizQuestions[q]
                                                      .options[o]
                                                      .optionValue ===
                                                    answers[k]
                                                    ? true
                                                    : false
                                                  : answers[k] &&
                                                    answers[k].includes(
                                                      quizData.quizQuestions[q]
                                                        .options[o].optionValue
                                                    )
                                              }
                                              disabled={submit}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={
                                                quizData.createdBy +
                                                "_" +
                                                k +
                                                "_" +
                                                key +
                                                "_" +
                                                q
                                              }
                                            >
                                              {
                                                quizData.quizQuestions[q]
                                                  .options[o].optionValue
                                              }
                                            </label>
                                            {submit &&
                                              eventQuizDetails.show_responses &&
                                              Object.entries(
                                                quizData.quizQuestions[q]
                                                  .options
                                              )
                                                ?.filter(
                                                  (e) => e[1].correctAnswer
                                                )
                                                ?.map((e) => e[0])
                                                ?.includes(o) && (
                                                <p className="ml-2 mb-0 text-success">
                                                  (Correct)
                                                </p>
                                              )}
                                          </div>
                                        </>
                                      ))}

                                    {submit && eventQuizDetails.show_responses && (
                                      <>
                                        {responseAnswers.answers[q].correct ? (
                                          <p className="text-success mb-0 mt-2">
                                            <FontAwesomeIcon icon={faCheck} />{" "}
                                            Correct Answer{" "}
                                            {responseAnswers.answers[q]
                                              ?.score &&
                                              `(${responseAnswers.answers[q]?.score} points)`}
                                          </p>
                                        ) : (
                                          <p className="text-danger mb-0 mt-2">
                                            <FontAwesomeIcon icon={faTimes} />{" "}
                                            Incorrect Answer
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </Card.Body>
                                </Card>
                              )
                          )}
                    </>
                    {submit && (
                      <div
                        className="d-flex mt-2 pt-2 pb-1"
                        style={{
                          borderTop: 2,
                          borderTopStyle: "dashed",
                          borderTopColor: "#dbdbdb",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                        <p
                          className="small pb-0 mb-0 flex-grow-1 px-2"
                          style={{ color: "#777B8E" }}
                        >
                          Response recorded{" "}
                          {moment(
                            quizData.response &&
                              quizData.response[user?.id] &&
                              quizData.response[user?.id].responseTime
                          ).format("MMM DD, YYYY hh:mm A")}
                        </p>
                      </div>
                    )}
                  </Card.Body>
                  <Card.Footer>
                    <p className="mb-0 small">
                      Deadline by{" "}
                      <b>{moment(quizData?.deadline).format("LLL")}</b>
                    </p>
                  </Card.Footer>
                </Card>
              </div>
            ) : null}
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(Quiz);
