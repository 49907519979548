import TopUsers from "./TopUsers";
import DatePicker from "react-datepicker";
import Dashboard from "./Dashboard";
import ImgCards from "./ImgCards";
import Projections from "./Projections";
import QuickUpdateCards from "./QuickUpdateCards";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useState } from "react";
const SelectedDashboard = (props) => {
  const { slug } = props;
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  let servicesData = [
    {
      id: 1,
      type: 0, // 0 - events, 1 - Course, 2 - Consultation
      name: "Orthodontic webinar series for MDS examinees",
      start_date: "3 SEPTEMBER 2021, 8:30 PM",
      slug: "orthodontic-webinar-series-for-mds-examinees",
    },
    {
      id: 2,
      type: 0, // 0 - events, 1 - Course, 2 - Consultation
      name: "Blue Ocean Awards: 7th Edition",
      start_date: "11 MAY 2021, 7:30 AM",
      slug: "EKWsp2FDV2aWp1F6P963",
    },
    {
      id: 3,
      type: 1, // 0 - events, 1 - Course, 2 - Consultation
      name: "Certificate Course in Social Work",
      start_date: "3 SEPTEMBER 2021, 8:30 PM",
      slug: "certificate-course-in-social-work",
    },
    {
      id: 4,
      type: 1, // 0 - events, 1 - Course, 2 - Consultation
      name: "Innovation Management: Strategy and Execution",
      start_date: "3 SEPTEMBER 2021, 8:30 PM",
      slug: "innovation-management-strategy-and-execution",
    },
    {
      id: 5,
      type: 2, // 0 - events, 1 - Course, 2 - Consultation
      name: "Good Mind Clinic",
      start_date: "3 SEPTEMBER 2021, 8:30 PM",
      slug: "good-mind-clinic",
    },
    {
      id: 6,
      type: 2, // 0 - events, 1 - Course, 2 - Consultation
      name: "Igesia Clinic",
      start_date: "3 SEPTEMBER 2021, 8:30 PM",
      slug: "igesia-clinic",
    },
  ];

  const selectedService = servicesData.find((element) => element.slug === slug);

  return (
    <div className="overflow-auto max-vh-100 p-4 w-100" id="style-4">
      <div className="text-center w-100">
        {/* <h5 className="text-light">{selectedService.name}'s stats</h5> */}
      </div>
      <div className="d-flex justify-content-between">
        <h5 className="text-white">
          {moment(selectedMonth).format("MMMM")}
          's stats
        </h5>
        <div>
          <DatePicker
            customInput={
              <Button variant="link" size="sm">
                Select month
              </Button>
            }
            showMonthYearPicker
            selected={selectedMonth}
            onChange={(e) => {
              setSelectedMonth(e);
            }}
          />
        </div>
      </div>

      <QuickUpdateCards />
      <Projections />
      <Dashboard />
      <h5 className="text-white ml-3">Engagement</h5>
      <ImgCards />
      <TopUsers />
    </div>
  );
};
export default SelectedDashboard;
