import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";
import Dashboard from "./Posts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faKey,
  faLanguage,
  faLock,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUsers,
  faUsersCog,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
// import EventNavbar from "./Navbar";
import Sidebar from ".././Sidebar/Index";
import { useLocation } from "react-router";
import NavBar from "../Navbar";
const AddProjectManager = (props) => {
  return (
    <>
      <div className="d-flex vh-100" style={{ background: "#232626" }}>
        {/* <EventNavbar /> */}
        <Sidebar />
        <div className="overflow-auto max-vh-100 p-4 w-100" id="style-4">
          <Dashboard />
        </div>
      </div>
    </>
  );
};
export default AddProjectManager;
