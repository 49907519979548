import React, { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import {
  faPlusCircle,
  faEye,
  faUnlink,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import { firestore, auth } from "../../../../../firebase";
import { postData } from "../../../../../utils";
import { API_BASE_URL, ADMIN_EVENT_SCHEDULES } from "../../../../../config";

function Schedule(props) {
  // CONSTANTS
  const {
    eventId,
    currentEvent,
    module_data,
    handleAddSubModule,
    handleRemoveSubModule,
    handleCloseScheduleView,
  } = props;

  // STATES
  const [schedules, setSchedules] = useState(null);
  const [scheduleCategories, setScheduleCategories] = useState(null);
  const [fetchingSchedules, setFetchingSchedules] = useState(true);

  // FUNCTIONS
  const handleFetchSchedules = async () => {
    const response_schedules = await postData(
      `${API_BASE_URL}${ADMIN_EVENT_SCHEDULES}`,
      {
        event_id: eventId,
        user_id: auth.currentUser.uid,
      }
    );

    if (response_schedules.status == 200) {
      const { data } = response_schedules;
      const schedule_category_list = data.scheduleCategories?.map(
        (category) => {
          return {
            ...category,
            value: category.categoryId,
            label: category.title,
          };
        }
      );

      setSchedules(data.scheduleList);
      setScheduleCategories(schedule_category_list);
      setFetchingSchedules(false);
    }
  };

  useEffect(async () => {
    let unsubscribe_schedules = firestore
      .collection(`/events/${eventId}/schedule`)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          if (auth.currentUser) {
            // Fetch: Agenda / Schedule
            handleFetchSchedules();
          } else {
            setFetchingSchedules(false);
          }
        });
      });

    return () => {
      unsubscribe_schedules();
    };
  }, []);

  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <div className="d-flex align-items-center">
        <a
          onClick={handleCloseScheduleView}
          style={{ cursor: "pointer" }}
          className="text-white p-3 m-0"
          title="Close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <div className="p-3">
          <h4 className="text-white mb-2">Select a live session or add new</h4>
          <p className="text-uppercase text-light small m-0">
            <strong>MODULE</strong> {module_data?.name}
          </p>
        </div>
      </div>
      <div className="p-3">
        <div className="mb-3">
          {schedules?.map((schedule, key) => (
            <div className="d-flex justify-content-between align-items-center border rounded p-2 px-3 mb-2">
              <div>
                <p className="mb-0">
                  {/* {key + 1}.  */}
                  {currentEvent && currentEvent?.slug && (
                    <a
                      href={`${window.location.origin}/event/${currentEvent?.slug}/agenda/${schedule.scheduleId}`}
                      target="_blank"
                      className="text-secondary mr-2 pointer"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </a>
                  )}
                  {schedule.EN.title}
                </p>
              </div>
              <div>
                {module_data?.sub_modules?.filter(
                  (item) => item.content_id === schedule.scheduleId
                )?.length > 0 ? (
                  <div
                    onClick={() =>
                      handleRemoveSubModule(
                        module_data?.sub_modules?.filter(
                          (item) => item.content_id === schedule.scheduleId
                        )[0]
                      )
                    }
                    className="pointer"
                  >
                    <FontAwesomeIcon
                      icon={faUnlink}
                      className="my-0 mr-2 pointer text-danger"
                    />
                    <small>REMOVE</small>
                  </div>
                ) : (
                  <div
                    className="pointer"
                    onClick={() =>
                      handleAddSubModule({
                        reference_id: schedule.id,
                        content_id: schedule.scheduleId,
                        title: schedule.EN.title,
                        type: "schedule",
                        user_id: auth.currentUser.uid,
                        order_index: module_data?.sub_modules?.length
                          ? module_data?.sub_modules?.length + 1
                          : 1,
                        event_id: eventId,
                        module_id: module_data?.id,
                      })
                    }
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="my-0 mr-2 pointer text-primary"
                    />
                    <small>ADD</small>
                  </div>
                )}
              </div>
            </div>
          ))}
          <Link to={`/admin/eventmanager/${currentEvent?.id}/schedules`}>
            {" "}
            <button className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faPlusCircle} /> Add new Live Session
            </button>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

export default memo(connect(mapStateToProps)(Schedule));
