import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { connect } from "react-redux";

function Certificate(props) {
  const { currentEvent } = props;
  const userDetails = firebase.auth().currentUser;
  const CertificateFile =
    process.env.PUBLIC_URL + `/assets/${currentEvent.id}.png`;

  const [loading, setLoading] = useState(false);

  const loadCanvas = function () {
    var canvas = document.getElementById("myCanvas");
    canvas.width = currentEvent?.certificate_details?.canvas_width ?? 2000;
    canvas.height = currentEvent?.certificate_details?.canvas_height ?? 1500;
    var context = canvas.getContext("2d");

    var imageObj = new Image();
    imageObj.onload = function () {
      context.drawImage(imageObj, 0, 0);
      context.font = "30pt Calibri";
      context.fillText(
        userDetails.displayName.toUpperCase(),
        currentEvent?.certificate_details?.name_position_x ?? 650,
        currentEvent?.certificate_details?.name_position_y ?? 850
      );
    };
    imageObj.src = CertificateFile;
  };

  useEffect(() => {
    window.addEventListener("load", loadCanvas());
  }, []);

  const downloadCertificate = () => {
    setLoading(true);
    var download = document.getElementById("download");
    var image = document
      .getElementById("myCanvas")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    download.setAttribute("href", image);

    firebase
      .firestore()
      .collection(`events/${currentEvent.id}/users`)
      .doc(userDetails.uid)
      .update({
        certificateDownloaded: {
          downloaded: true,
          timestamp: new Date(),
        },
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <canvas id="myCanvas" className="d-none"></canvas>
      <a
        id="download"
        download={
          currentEvent?.slug
            ? `${currentEvent?.slug}_certificate.png`
            : `certificate.png`
        }
      >
        <button
          className="btn btn-primary btn-sm"
          onClick={downloadCertificate}
          disabled={loading}
        >
          {loading ? "Downloading..." : "Download Certificate"}
        </button>
      </a>
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  {}
)(Certificate);
