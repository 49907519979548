import React, { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { loadState } from "../../services";

const Success = () => {
  const history = useHistory();
  const userDetails = firebase.auth().currentUser;

  useEffect(() => {
    const cartDetails = loadState("cart");

    if (cartDetails) {
      window.localStorage.removeItem("cart");
    }
  }, []);

  if (!userDetails) {
    return <Redirect to="/pricing" />;
  }

  return (
    <div style={{ backgroundColor: "#efeff6" }}>
      <div className="container ">
        <div
          className="d-flex justify-content-center align-items-center "
          style={{ height: "100vh" }}
        >
          <div className="col-md-5 col-12 mx-auto ">
            <Card>
              <Card.Header
                className="p-5 text-center"
                style={{ backgroundColor: "#ffffff" }}
              >
                <img
                  className="img-fluid shadow-sm mb-2"
                  src={`https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/images%2Fsuccessful_payment_388054.png?alt=media&token=fc0a2c48-b9f2-40b4-8316-c7337c70a9b1`}
                  style={{ borderRadius: "20px" }}
                  width="100"
                  title={`Payment Successfull`}
                  alt={`Payment Successfull`}
                />
                <h5 className="splash-description my-5">
                  Payment Successfull.
                </h5>
                <p>
                  Your Payment Reference Id:{" "}
                  {localStorage.getItem("payment_reference_id")}
                </p>
                <Button
                  className="my-2"
                  onClick={() => history.push("/my-plan")}
                >
                  GO TO MY PLAN
                </Button>
              </Card.Header>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
