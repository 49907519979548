import React, { useMemo, memo, useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
//import { projectList, blogList } from "../../data";
import { connect } from "react-redux";
import { setProject, setBlog, setMyBlogs } from "../../store/actions/actions";
import AddConsultancyModal from "./Sidebar/AddConsultancyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faEdit,
  faUserEdit,
  faShapes,
  faVideo,
  faPoll,
  faCalendarAlt,
  faClipboardCheck,
  faClinicMedical,
  faNotesMedical,
  faHdd,
  faFile,
  faBook,
  faBookReader,
  faCheckSquare,
  faGripHorizontal,
  faGreaterThanEqual,
  faListAlt,
  faChartPie,
  faLock,
  faUsersCog,
  faReply,
  faClone,
  faQuestionCircle,
  faStickyNote,
  faKey,
  faSearch,
  faCog,
  faLink,
  faCode,
  faChartLine,
  faCaretDown,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { auth } from "../../firebase";
import { API_BASE_URL, BLOG_GET_BY_USER,PROD_HOSTED_BASE_URL } from "../../config";
import axios from "axios";

const Sidebar = (props) => {
  // CONSTANTS
  const history = useHistory();
  let { events, blogs, setMyBlogs } = props;
  const userDetails = auth.currentUser;
  const tabs = [
    { id: 1, name: "All", value: "all" },
    { id: 2, name: "Events", value: "events" },
    { id: 3, name: "Courses", value: "courses" },
    // { id: 4, name: "Articles", value: "articles" },
    // { id: 5, name: "Rooms", value: "rooms" },
  ];

  // console.log("Events Before ML ==> ",events)

  events = events?.reduce((accumulator, current) => {
    if (!accumulator?.find(event => event?.id === current?.id)) {
        accumulator.push(current);
    }
    return accumulator;
  }, []);

  events = events?.filter(event => event?.event_manager?.includes(userDetails?.email));
  // console.log("Events After ML ==> ",events)


  // STATES
  const [showAddConsultancyModal, setShowAddConsultancyModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const [fetchingBlogs, setFetchingBlogs] = useState(false);

  // FUNCTIONS
  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  const handleCopyTextToClipboard = (id) => {
    const body = document.querySelector("body");
    const selector = document.getElementById(`${id}`);
    const area = document.createElement("textarea");
    body.appendChild(area);

    area.value = selector.innerText;
    area.select();
    document.execCommand("copy");

    body.removeChild(area);
    toast.dark("Public profile url copied!", {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };

  const fetchBlogs = async (cbSetLoading, cbSetData) => {
    try {
      console.log(`[Try] Fetching Blogs`);
      cbSetLoading(true);

      const response = await axios.get(
        `${API_BASE_URL}${BLOG_GET_BY_USER}?user_id=${userDetails.uid}`
      );

      if (response.status === 200) {
        console.log(`[Success] Fetching Blogs`);
        cbSetData(response.data.data.list);
        cbSetLoading(false);
      } else {
        cbSetLoading(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching Blogs: `, error);
    }
  };

  useEffect(() => {
    // Fetch: Blogs data
    fetchBlogs(setFetchingBlogs, setMyBlogs);
  }, [userDetails]);

  return (
    <>
      <div className="row mb-3 mt-5">
        <div className="col-md-10 offset-md-1 text-light mb-2">
          <div className="d-flex mb-2">
            <span>
              <h4 className="text-light mb-0">
                <Link
                  to="/admin/all/events"
                  className="text-light ml-auto text-decoration-none"
                >
                  My Workspace{" "}
                  <FontAwesomeIcon icon={faAngleDown} className="mr-2" />
                </Link>
              </h4>
              <a
                href={`https://${PROD_HOSTED_BASE_URL}/public-profile/${userDetails.uid}`}
                target="_blank"
                className="small text-primary"
                id="public-profile-url"
              >
                {PROD_HOSTED_BASE_URL}/public-profile/{userDetails.uid}
              </a>
            </span>
            <span className="d-flex">
              <Dropdown className="pointer no-dropdown-caret mr-2">
                <Dropdown.Toggle
                  as="div"
                  id="dropdown-basic"
                  className="text-light ml-5"
                >
                  <FontAwesomeIcon icon={faCog} className="mr-2" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      className="text-decoration-none text-dark small"
                      onClick={() =>
                        handleCopyTextToClipboard(`public-profile-url`)
                      }
                    >
                      <FontAwesomeIcon icon={faLink} className="mr-2" />
                      Copy Public Link
                    </Link>
                  </Dropdown.Item>
                  {/* <Dropdown.Item>
                    <Link
                      to=""
                      className="text-decoration-none text-dark small"
                    >
                      <FontAwesomeIcon icon={faCode} className="mr-2" />
                      Add to website
                    </Link>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <Link
                to="/admin/workspaceanalytics"
                className="text-decoration-none ml-auto text-light"
              >
                <FontAwesomeIcon icon={faChartLine} className="mr-2" />
              </Link>
            </span>
            <Dropdown className="pointer no-dropdown-caret ml-auto">
              <Dropdown.Toggle
                as="div"
                id="dropdown-basic"
                className="btn btn-primary btn-md text-light mr-2"
              >
                {" "}
                + Create
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="border-bottom">
                  <Link
                    to="/admin/add/event"
                    className="text-decoration-none text-dark"
                  >
                    <div className="d-flex">
                      <img
                        src="https://static.xx.fbcdn.net/rsrc.php/v3/yx/r/N7UOh8REweU.png"
                        width="30px"
                        height="30px"
                        className="mr-2"
                      />
                      <div>
                        <span className="font-weight-bold text-uppercase  small">
                          EVENT
                        </span>
                        <p className="small my-0 ">
                          Set up an event with custom registration page. <br />
                          Create meaningful networking opportunities for your
                          attendees.
                        </p>
                      </div>
                    </div>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="border-bottom">
                  <Link
                    to="/admin/course/add"
                    className="text-decoration-none text-dark"
                  >
                    <div className="d-flex">
                      <img
                        src="https://static.xx.fbcdn.net/rsrc.php/v3/yo/r/hLkEFzsCyXC.png"
                        width="30px"
                        height="30px"
                        className="mr-2"
                      />
                      <div>
                        <span className="font-weight-bold text-uppercase small">
                          COURSE
                        </span>
                        <p className="small my-0">
                          Create a course with live lectures and self-paced{" "}
                          <br />
                          content, quizzes, assignment, divide your class in
                          cohorts.
                        </p>
                      </div>
                    </div>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="border-bottom">
                  <Link
                    to="/admin/add/blog"
                    className="text-decoration-none text-dark"
                  >
                    <div className="d-flex">
                      <img
                        src="https://static.xx.fbcdn.net/rsrc.php/v3/yu/r/DxwxddVlL2T.png"
                        width="30px"
                        height="30px"
                        className="mr-2"
                      />
                      <div>
                        <span className="font-weight-bold text-uppercase small">
                          ARTICLE
                        </span>
                        <p className="small my-0">
                          Keep you community engaged with blogs and updates.{" "}
                          <br />
                          Upsell your courses and events.
                        </p>
                      </div>
                    </div>
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="">
                  <a
                    href="https://console.igesia.co"
                    target="_blank"
                    className="text-decoration-none text-dark"
                  >
                    <div className="d-flex">
                      <img
                        src="https://www.facebook.com/rsrc.php/v3/y4/r/MN44Sm-CTHN.png"
                        width="30px"
                        height="30px"
                        className="mr-2"
                      />
                      <div>
                        <span className="font-weight-bold text-uppercase small">
                          MEETING ROOM
                        </span>
                        <p className="small my-0 ">
                          Create discussion rooms with native productivity apps{" "}
                          <br />
                          including in-built video to improve quality of
                          engagement.
                        </p>
                      </div>
                    </div>
                  </a>
                </Dropdown.Item>

                {/* <Dropdown.Item className="border-bottom">
                      <div className="d-flex">
                        <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yF/r/VoY5lgHtw8x.png" width="30px" height="30px" className="mr-2" />
                        <div>
                          <span className="font-weight-bold text-uppercase  small">COACHING  <span className="text-warning small">COMING SOON</span></span>
                          <p className="small my-0 ">Create a virtual office to schedule meetings and <br />offer paid consultations for coaching and expert advice.</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="d-flex">
                        <img src="https://www.facebook.com/rsrc.php/v3/ye/r/vEc1FLp5rSK.png" width="30px" height="30px" className="mr-2" />
                        <div>
                          <span className="font-weight-bold text-uppercase small">COMMUNITY PROJECT <span className="text-warning small">COMING SOON</span></span>
                          <p className="small my-0">Create custom projects to enhance collaboration across <br />community members with project and task management apps.</p>
                        </div>
                      </div>
                    </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="row border-bottom border-dark">
        <div className="col-md-10 offset-md-1 text-light mb-2">
          {tabs?.map((e, idx) => (
            <a
              key={idx}
              className={
                selectedTab === e.value
                  ? "mr-3 text-light text-decoration-none font-weight-bold"
                  : "mr-3 text-light text-decoration-none"
              }
              onClick={() => setSelectedTab(e.value)}
              style={{ cursor: "pointer" }}
            >
              {e.name}
            </a>
          ))}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-10 offset-md-1">
          <div className="row text-white">
            <div className="col-md-12">
              <div className="">
                <div className="row">
                  {events?.length ? (
                    events
                      ?.filter((item) => {
                        if (selectedTab === "all") return true;
                        else if (selectedTab === "events")
                          return item.type === 0;
                        else return item.type === 1;
                      })
                      ?.map((e) => {
                        return (
                          <div className="col-md-3 mb-3">
                            <a
                              className="text-decoration-none"
                              onClick={() =>
                                history.push(
                                  `/admin/eventmanager/${e.id}/overview`
                                )
                              }
                              style={{ color: "inherit" }}
                            >
                              <div
                                className="card w-100 h-100 shadow-sm"
                                style={{
                                  background: "rgb(35 35 35)",
                                  border: "1px solid rgba(255,255,255,0.25",
                                }}
                              >
                                <div
                                  className="rounded"
                                  style={{
                                    backgroundImage: `url(${e?.event_details?.featured_image})`,
                                    height: "120px",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "top center",
                                  }}
                                ></div>
                                <div className="card-body">
                                  <p className="card-text mb-0">
                                    {e?.event_details?.EN?.event_name}
                                  </p>
                                  <p className="small text-uppercase mb-0 text-warning">
                                    {e?.type === 0 ? "Event" : "Course"}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                  ) : (
                    <Skeleton
                      className="bg-dark"
                      animation="wave"
                      variant="rect"
                      height={50}
                      classes={{
                        wave: {
                          color: "#ffffff",
                          background: "#ffffff",
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-5 text-white">
            <div className="col-md-12">
              <div className="px-3 py-2 rounded  bg-dark">
                <div className="d-flex align-items-center mb-2">
                  <span className="text-uppercase text-light small font-weight-bold">
                    <Link
                      to="/admin/all/blogs"
                      className="text-light ml-auto text-decoration-none"
                    >
                      Blogs
                    </Link>
                  </span>
                  <Link
                    to="/admin/add/blog"
                    className="text-light ml-auto text-decoration-none"
                  >
                    <Button
                      size="sm"
                      variant="link"
                      className="text-light ml-auto text-decoration-none"
                    >
                      <span className="small" style={{ color: "#89cff0" }}>
                        + NEW
                      </span>
                    </Button>
                  </Link>
                </div>

                {/* Section: Blogs */}
                {!fetchingBlogs ? (
                  blogs?.length > 0 ? (
                    <div className="row">
                      {blogs?.map((e) => {
                        return (
                          <div
                            className="col-md-3 mb-3"
                            onClick={() => {
                              history.push(`/admin/blogmanager/${e.id}`);
                              props.setBlog(e);
                            }}
                            // className="text-decoration-none"
                            style={{ color: "inherit" }}
                          >
                            <div
                              className="card w-100 h-100 shadow-sm"
                              style={{
                                background: "rgb(35 35 35)",
                                border: "1px solid rgba(255,255,255,0.25",
                              }}
                            >
                              <div
                                className="rounded"
                                style={{
                                  backgroundImage: `url(${e?.img_src})`,
                                  height: "120px",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "top center",
                                }}
                              ></div>
                              <div className="card-body">
                                <p className="card-text mb-0">{e?.heading}</p>
                                <p className="small text-uppercase mb-0 text-warning">
                                  {moment(e?.timestamp).format("Do MMM YYYY")}
                                </p>
                                {e?.is_published ? (
                                  <p className="smaller mb-0 text-muted">
                                    PUBLISHED
                                  </p>
                                ) : (
                                  <p className="smaller mb-0 text-muted">
                                    DRAFT
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <p className="text-center">
                        Click on{" "}
                        <a onClick={() => history.push("/admin/add/blog")}>
                          <span className="small" style={{ color: "#89cff0" }}>
                            + NEW
                          </span>
                        </a>{" "}
                        to add a blog
                      </p>
                    </div>
                  )
                ) : (
                  <>
                    <Skeleton
                      className="bg-dark"
                      animation="wave"
                      variant="rect"
                      height={50}
                      classes={{
                        wave: {
                          color: "#ffffff",
                          background: "#ffffff",
                        },
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="rounded py-4 px-3 w-100"
                    style={{
                      backgroundColor: "lightblue",
                      backgroundImage:
                        "url(https://assets.codepen.io/1729459/download.svg)",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "bottom right",
                    }}
                  >
                    <div className="col-md-8">
                      <span className="font-weight-bold text-uppercase text-dark small">
                        GET STARTED NOW
                      </span>
                      <h5 className="text-dark mb-3">
                        Discover how you can use igesia to build and engage your
                        community
                      </h5>
                      <a
                        href="/admin/gettingstarted"
                        className="btn btn-md btn-primary"
                      >
                        <small className="small font-weight-bold text-uppercase">
                          See Next Steps
                        </small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="rounded py-4 px-3 w-100"
                    style={{
                      backgroundColor: "lightgoldenrodyellow",
                      backgroundImage:
                        "url(https://assets.codepen.io/1729459/download-2.png)",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "bottom right",
                    }}
                  >
                    <div className="col-md-8">
                      <span className="font-weight-bold text-uppercase text-dark small">
                        GET INSPIRED
                      </span>
                      <h5 className="text-dark mb-3">
                        Read success stories from our happy customers
                      </h5>
                      <a
                        href="/admin/customerstories"
                        className="btn btn-md btn-primary"
                      >
                        <small className="small font-weight-bold text-uppercase">
                          DISCOVER
                        </small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="row">
          <div className="col-md-12 mb-2">
            <span className="font-weight-bold text-uppercase text-light small">CREATE</span>
          </div>
          <div className="col-md-4 mb-3 ">
            <Link to="/admin/add/event" className="text-decoration-none w-100 h-100">
              <div className="d-flex bg-dark rounded p-2 h-100">
                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yx/r/N7UOh8REweU.png" width="30px" height="30px" className="mr-2" />
                <div>
                  <span className="font-weight-bold text-uppercase text-light small">EVENT</span>
                  <p className="small my-0 text-light">Set up an event with custom registration page. Create meaningful networking opportunities for your attendees.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mb-3 d-flex">
            <Link to="/admin/course/add" className="text-decoration-none w-100 h-100">
              <div className="d-flex bg-dark rounded p-2 w-100 h-100">
                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yo/r/hLkEFzsCyXC.png" width="30px" height="30px" className="mr-2" />
                <div>
                  <span className="font-weight-bold text-uppercase text-light small">COURSE</span>
                  <p className="small my-0 text-light">Create a course with live lectures and self-paced content, quizzes, assignment, divide your class in cohorts.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mb-3 d-flex">
            <a href="https://console.igesia.co" target="_blank" className="text-decoration-none w-100 h-100">
              <div className="d-flex bg-dark rounded p-2 w-100 h-100">
                <img src="https://www.facebook.com/rsrc.php/v3/y4/r/MN44Sm-CTHN.png" width="30px" height="30px" className="mr-2" />
                <div>
                  <span className="font-weight-bold text-uppercase text-light small">MEETING ROOM</span>
                  <p className="small my-0 text-light">Create discussion rooms with native productivity apps including in-built video to improve quality of engagement.</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 mb-3 d-flex">
            <Link to="/admin/add/blog" className="text-decoration-none w-100 h-100">
              <div className="d-flex bg-dark rounded p-2 h-100">
                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yu/r/DxwxddVlL2T.png" width="30px" height="30px" className="mr-2" />
                <div>
                  <span className="font-weight-bold text-uppercase text-light small">ARTICLE</span>
                  <p className="small my-0 text-light">Keep you community engaged with blogs and updates. Upsell your courses and events.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 mb-3 d-flex">
            <div className="d-flex bg-dark rounded p-2 w-100">
              <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yF/r/VoY5lgHtw8x.png" width="30px" height="30px" className="mr-2" />
              <div>
                <span className="font-weight-bold text-uppercase text-light small">COACHING  <span className="text-warning small">GET IN TOUCH</span></span>
                <p className="small my-0 text-light">Create a virtual office to schedule meetings and offer paid consultations for coaching and expert advice.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 d-flex">
            <div className="d-flex bg-dark rounded p-2 w-100">
              <img src="https://www.facebook.com/rsrc.php/v3/ye/r/vEc1FLp5rSK.png" width="30px" height="30px" className="mr-2" />
              <div>
                <span className="font-weight-bold text-uppercase text-light small">COMMUNITY PROJECT <span className="text-warning small">GET IN TOUCH</span></span>
                <p className="small my-0 text-light">Create custom projects to enhance collaboration across community members with project and task management apps.</p>
              </div>
            </div>
          </div>

        </div> */}

          {/* <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/consultancies"
            className="text-light ml-auto text-decoration-none"
          >
            Consultancies
          </Link>
        </span>
        <Button
          size="sm"
          variant="link"
          className="text-light ml-auto text-decoration-none"
          onClick={() => {
            setShowAddConsultancyModal(true);
          }}
        >
          <span className="small" style={{ color: "#89cff0" }}>
            + NEW
          </span>
        </Button>
      </div>
      {!loadingConsultancies ? (
        consultancies?.map((e) => {
          return (
            <a
              href={
                e.isOffice
                  ? `/admin/officemanager/${e.id}`
                  : `/admin/clinicmanager/${e.id}`
              }
              style={{ color: "inherit" }}
            >
              <div className="my-2">
                <div
                  className="d-flex my-2 py-2 px-2 rounded"
                  style={{
                    cursor: "pointer",
                    background: "rgb(116 56 110)",
                    border: "1px solid rgba(255, 255, 255, .3)",
                  }}
                >
                  <div className="">
                    <p className="my-0 small">
                      {e?.clinic_details?.EN?.clinic_name}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          );
        })
      ) : (
        <Skeleton
          className="bg-dark"
          animation="wave"
          variant="rect"
          height={50}
          classes={{
            wave: {
              color: "#ffffff",
              background: "#ffffff",
            },
          }}
        />
      )} */}

          {/* <div className="mt-2 rounded d-flex align-items-center">
        <span className="text-uppercase text-light small font-weight-bold">
          <Link
            to="/admin/all/projects"
            className="text-light ml-auto text-decoration-none"
          >
            Projects
          </Link>
        </span>
        <Link
          to="/admin/add/project"
          className="text-light ml-auto text-decoration-none"
        >
          <Button
            size="sm"
            variant="link"
            className="text-light ml-auto text-decoration-none"
          >
            <span className="small" style={{ color: "#89cff0" }}>
              + NEW
            </span>
          </Button>
        </Link>
      </div>
      {projects ? (
        projects?.map((e) => {
          return (
            <a
              href={`/admin/projectmanager/${e.id}`}
              style={{ color: "inherit" }}
            >
              <div className="my-2">
                <div
                  className="d-flex my-2 py-2 px-2 rounded"
                  style={{
                    cursor: "pointer",
                    background: "rgb(103 58 78)",
                    border: "1px solid rgba(255, 255, 255, .3)",
                  }}
                >
                  <div className="">
                    <p className="my-0 small text-capitalize">
                      {e?.heading ?? "Sample Project"}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          );
        })
      ) : (
        <>
          <Skeleton
            className="bg-dark"
            animation="wave"
            variant="rect"
            height={50}
            classes={{
              wave: {
                color: "#ffffff",
                background: "#ffffff",
              },
            }}
          />
        </>
      )} */}
          <div>
            <AddConsultancyModal
              show={showAddConsultancyModal}
              onHide={setShowAddConsultancyModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const sideBar = memo(Sidebar);

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
    events: state.myEvents,
    blogs: state.myBlogs,
  }),
  { setProject, setBlog, setMyBlogs }
)(Sidebar);
