import React, { memo, useState, useEffect } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import firebase from "firebase";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL, POLL_CREATE, POLL_EDIT } from "../../../../config";
import { connect } from "react-redux";

const PollFields = (props) => {
  const { currentEvent, id, pollData } = props;
  const userDetails = firebase.auth().currentUser;

  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState("");
  const [errorObj, setErrorObj] = useState({ title: "", options: [] });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (props?.pollData) {
      setQuestion(pollData?.title);
      setOptions(pollData?.options);
    }
  }, [props]);

  const addOption = () => {
    if (options.length < 8) {
      let prev = [...options];
      prev[prev.length] = "";
      setOptions(prev);
    } else {
      console.log("Options can't be more than 8.");
      setError("Options can't be more than 8.");
      return;
    }
  };

  const removeOption = (key) => {
    let err = { ...errorObj };
    let prev = [...options];
    prev.splice(key, 1);
    setOptions(prev);
    err.options[key + 1] = "";
    setErrorObj(err);
  };

  const handleOptions = (e, key) => {
    let prev = [...options];
    prev[key] = e.target.value;
    setOptions(prev);
  };

  const validateDataOfField = (e) => {
    let err = { ...errorObj };
    if (e.target.name === "options[]") {
      if (e.target.value.trim().length < 1) {
        err.options[+e.target.id + 1] = "Option cannot be empty.";
      } else {
        err.options[+e.target.id + 1] = "";
      }
    }
    if (e.target.name === "title") {
      if (e.target.value.trim().length < 1) {
        err.title = "Title cannot be empty.";
      } else {
        err.title = "";
      }
    }
    setErrorObj(err);
  };

  const addPoll = async () => {
    try {
      setSaving(true);
      const poll_data = {
        user_id: `${userDetails?.uid}`,
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        deadline: Date.now() + 2 * 365 * 24 * 60 * 60 * 1000, //false,
        all_participants: true,
        assigned: [],
        title: question,
        options: options,
        folderId: "",
        save_for_later: false,
      };

      const res = await axios.post(`${API_BASE_URL}${POLL_CREATE}`, poll_data);

      const size = (
        await firebase
          .firestore()
          .collection(`/events/${props.eventId}/polls`)
          .get()
      ).docs.length;

      await firebase
        .firestore()
        .collection(`/events/${props.eventId}/polls`)
        .doc()
        .set(
          {
            content_id: res.data.data.poll_id,
            title: question,
            created_by: `${userDetails?.uid}`,
            deadline: Date.now() + 2 * 365 * 24 * 60 * 60 * 1000,
            created_at: Date.now(),
            order_index: size,
            type: 3,
          },
          {
            merge: true,
          }
        );
      toast.success("Poll added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error("Error : ", e);
      toast.error("Error", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };

  const editPoll = async () => {
    try {
      setSaving(true);
      const poll_data = {
        user_id: `${userDetails?.uid}`,
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        deadline: Date.now() + 2 * 365 * 24 * 60 * 60 * 1000, //false,
        published_poll_id: pollData?.id,
        all_participants: true,
        assigned: [],
        title: question,
        options: options,
        folderId: "",
        images: [],
      };

      await axios.post(`${API_BASE_URL}${POLL_EDIT}`, poll_data);

      await firebase
        .firestore()
        .collection(`/events/${props.eventId}/polls`)
        .doc(`${id}`)
        .update(
          {
            title: question,
          },
          {
            merge: true,
          }
        );
      toast.success("Poll updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error("Error : ", e);
      toast.error(`Error`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };

  return (
    <React.Fragment>
      <div className="text-theme-lighter">
        <Form>
          <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
            <p className="mb-2 font-weight-bold text-theme small">Poll Title</p>
            <InputGroup className="mb-1">
              <FormControl
                size="sm"
                placeholder="Enter the question for poll"
                name="title"
                autoFocus={true}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyUp={(e) => validateDataOfField(e)}
                onBlur={(e) => validateDataOfField(e)}
                required={true}
                value={question}
              />
            </InputGroup>
            {errorObj.title ? (
              <p className="small text-danger">{errorObj.title}</p>
            ) : (
              ""
            )}

            {options.length > 0
              ? options.map((option, key) => (
                  <div key={key}>
                    <div className="d-flex">
                      <p className="mb-0 flex-grow-1 small font-weight-bold">
                        Option {key + 1}.
                      </p>
                      <div className="mb-2">
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => removeOption(key)}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center content-align-center mb-4">
                      <InputGroup>
                        <FormControl
                          placeholder="Option"
                          aria-label="option"
                          aria-describedby={"option" + key}
                          name="options[]"
                          autoFocus={options.length === key + 1}
                          value={options[key]}
                          id={key}
                          onChange={(e) => handleOptions(e, key)}
                          onKeyUp={(e) => validateDataOfField(e)}
                          onBlur={(e) => validateDataOfField(e)}
                          required={true}
                          size="sm"
                        />
                      </InputGroup>
                    </div>
                    {errorObj.options[key + 1] ? (
                      <p className="small text-danger">
                        {errorObj.options[key + 1]}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              : null}

            <InputGroup className="w-100 my-2">
              <Button
                size="sm"
                variant="outline-primary"
                className="w-100"
                onClick={() => addOption()}
              >
                + <small>Add Option</small>
              </Button>
            </InputGroup>
            <div className="text-danger mb-2 small">{error ? error : null}</div>
          </div>
          <div className="m-2">
            {props?.isEditting ? (
              <Button
                variant="primary"
                className="mt-2 w-100"
                size="sm"
                disabled={saving}
                onClick={() => {
                  editPoll();
                }}
              >
                {saving ? (
                  <>
                    <Spinner size="sm" animation="border" color="white" />{" "}
                    Saving Changes
                  </>
                ) : (
                  "Save Changes"
                )}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="mt-2 w-100"
                size="sm"
                disabled={saving}
                onClick={() => {
                  addPoll();
                }}
              >
                {saving ? (
                  <>
                    <Spinner size="sm" animation="border" color="white" />{" "}
                    Adding
                  </>
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});
export default memo(connect(mapStateToProps)(PollFields));
