import * as actionTypes from "../actions/actionTypes";
import { saveState, loadState } from "../../services/index";

const initialState = {
  currentLanguage:
    loadState("language") === "FR" ? loadState("language") : "EN",
  clinicDetails: {},
  workspaceId: "LgT2sjzoZQwwKMGPnob2",
  roomId: "9HG26Wq7BXCiJMuCS8Ac",
  clinicId: "HNsDJvAkNawGk0FPitbv",
  officeId: "uWuJrdPudu3sIjrA2K5j",
  eventId: "EKWsp2FDV2aWp1F6P963",
  courseId: "hdCKh1GocgVH6v2zLaGn",
  officeDetails: {},
  courseDetails: {},
  course: null,
  course_module: null,
  course_sub_module: null,
  project: null,
  app: null,
  blog: null,
  showNoteApp: false,
  showWhiteboard: true,
  layers: [],
  background: "",
  courseSection: [],
  darkTheme: false,
  graphId: null,
  errcId: null,
  factors: [],
  products: [],
  asisFactors: [],
  buyerHeaders: [],
  buyerRows: [],
  sixPath: [],
  sixPathHeaders: [],
  sixPathRows: [],
  currentEvent: null,
  myEvents: [],
  myBlogs: [],
};

const reducer = (state = initialState, action) => {
  if (action.type === actionTypes.SET_LANGUAGE) {
    saveState("language", `${action.payload.currentLanguage}`);
    return {
      ...state,
      currentLanguage: action.payload.currentLanguage,
    };
  }
  if (action.type === actionTypes.SET_SHOW_NOTEAPP) {
    saveState("showNoteApp", `${action.payload.showNoteApp}`);
    return {
      ...state,
      showNoteApp: action.payload.showNoteApp,
    };
  }
  if (action.type === actionTypes.SET_MY_EVENTS) {
    return {
      ...state,
      myEvents: action.payload.myEvents,
    };
  }
  if (action.type === actionTypes.SET_MY_BLOGS) {
    return {
      ...state,
      myBlogs: action.payload.myBlogs,
    };
  }
  if (action.type === actionTypes.SET_OFFICE_DETAILS) {
    return {
      ...state,
      officeDetails: action.payload.officeDetails,
    };
  }
  if (action.type === actionTypes.SET_CURRENT_EVENT) {
    return {
      ...state,
      currentEvent: action.payload.currentEvent,
    };
  }
  if (action.type === actionTypes.SET_CURRENT_USER) {
    return {
      ...state,
      currentUser: action.payload.currentUser,
    };
  }
  if (action.type === actionTypes.SET_COURSE_SECTION) {
    return {
      ...state,
      courseSection: action.payload.courseSection,
    };
  }
  if (action.type === actionTypes.SET_OFFICE_ID) {
    return {
      ...state,
      officeId: action.payload.officeId,
    };
  }
  if (action.type === actionTypes.SET_CLINIC_ID) {
    return {
      ...state,
      clinicId: action.payload.clinicId,
    };
  }
  if (action.type === actionTypes.SET_COURSE_ID) {
    return {
      ...state,
      courseId: action.payload.courseId,
    };
  }
  if (action.type === actionTypes.SET_EVENT_ID) {
    return {
      ...state,
      eventId: action.payload.eventId,
    };
  }
  if (action.type === actionTypes.SET_COURSE_DETAILS) {
    return {
      ...state,
      courseDetails: action.payload.courseDetails,
    };
  }

  if (action.type === actionTypes.SET_COURSE) {
    return {
      ...state,
      course: action.payload.course,
    };
  }

  if (action.type === actionTypes.SET_COURSE_MODULE) {
    return {
      ...state,
      course_module: action.payload.course_module,
    };
  }

  if (action.type === actionTypes.SET_COURSE_SUB_MODULE) {
    return {
      ...state,
      course_sub_module: action.payload.course_sub_module,
    };
  }

  if (action.type === actionTypes.SET_PROJECT) {
    return {
      ...state,
      project: action.payload.project,
    };
  }

  if (action.type === actionTypes.SET_APP_PAGE) {
    return {
      ...state,
      app: action.payload.app,
    };
  }

  if (action.type === actionTypes.SET_BLOG) {
    return {
      ...state,
      blog: action.payload.blog,
    };
  }

  if (action.type === actionTypes.SET_SHOW_WHITEBOARD) {
    return {
      ...state,
      showWhiteboard: action.payload.showWhiteboard,
    };
  }
  if (action.type === actionTypes.SAVE_WHITEBOARD_LAYERS) {
    return {
      ...state,
      layers: action.payload.layers,
    };
  }

  if (action.type === actionTypes.SAVE_WHITEBOARD_BACKGROUND) {
    return {
      ...state,
      background: action.payload.background,
    };
  }
  if (action.type === actionTypes.SET_DARK_THEME) {
    return {
      ...state,
      darkTheme: action.payload.darkTheme,
    };
  } else if (action.type === actionTypes.SET_GRAPH_ID) {
    console.log("Reducers GraphID ML ===> ",action.payload.graphId)
    saveState("graphId", `${action.payload.graphId}`);
    return {
      ...state,
      graphId: action.payload.graphId,
    };
  } else if (action.type === actionTypes.SET_ERRC_ID) {
    saveState("errcId", `${action.payload.errcId}`);
    return {
      ...state,
      errcId: action.payload.errcId,
    };
  } else if (action.type === actionTypes.SET_FACTORS) {
    return {
      ...state,
      factors: action.payload.factors,
    };
  } else if (action.type === actionTypes.SET_PRODUCTS) {
    return {
      ...state,
      products: action.payload.products,
    };
  } else if (action.type === actionTypes.SET_PRODUCTS_TO_BE) {
    return {
      ...state,
      productsToBe: action.payload.productsToBe,
    };
  } else if (action.type === actionTypes.SET_ASIS_FACTORS) {
    return {
      ...state,
      asisFactors: action.payload.asisFactors,
    };
  } else if (action.type === actionTypes.SET_BUYER_HEADER) {
    return {
      ...state,
      buyerHeaders: action.payload.buyerHeaders,
    };
  } else if (action.type === actionTypes.SET_BUYER_ROWS) {
    return {
      ...state,
      buyerRows: action.payload.buyerRows,
    };
  } else if (action.type === actionTypes.SET_SIX_PATH_HEADER) {
    return {
      ...state,
      sixPathHeaders: action.payload.sixPathHeaders,
    };
  } else if (action.type === actionTypes.SET_SIX_PATH_ROWS) {
    return {
      ...state,
      sixPathRows: action.payload.sixPathRows?.sort((a, b) => parseInt(a.id) - parseInt(b.id)),
      // sixPathRows: action.payload.sixPathRows,
    };
  }

  return state;
};

export default reducer;
