import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const CategoriesDragListMultiLang = (props) => {
  const { items, i, currentLanguage } = props;
  return (
    <>
      <div className="bg-light h-100 d-flex align-items-center px-1">
        <FontAwesomeIcon
          icon={faGripVertical}
          className="text-secondary mr-1"
        />
      </div>
      <div className="flex-grow-1 py-3">
        <p className="text-s-10 mb-0 text-capitalize">
          {items[i][currentLanguage].title}
        </p>
      </div>
    </>
  );
};
export default connect((state) => ({ currentLanguage: state.currentLanguage }))(
  CategoriesDragListMultiLang
);
