import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Spinner,
  FormCheck,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faComments,
  faStore,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { connect } from "react-redux";

import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
// import EventDetails from "./EventDetails";
// import BriefStats from "./BriefStats";

const Overview = (props) => {
  const [attendeesSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${props.eventId}/users`)
      .orderBy("first_name")
  );

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc("EKWsp2FDV2aWp1F6P963")
  );

  const chatOn = eventSnapshot?.data().chat_enabled ?? false;

  const peopleEnabled = eventSnapshot?.data()?.people_enabled ?? false;
  useEffect(() => {
    if (!loadingEvent) {
      firebase
        .firestore()
        .collection("events")
        .doc("EKWsp2FDV2aWp1F6P963")
        .update({
          chat_enabled: chatOn,
        });
    }
  }, [loadingEvent]);

  //   useEffect(() => {
  //     if (!loadingEvent && eventSnapshot.data().chat_enabled) {
  //       setChatOn(true);
  //     } else {
  //       setChatOn(false);
  //     }
  //   }, [loadingEvent]);
  const [ingEventDetails, setingEventDetails] = useState(false);

  return (
    <>
      {!ingEventDetails ? (
        <div
          className="container-fluid h-100 py-4 text-light"
          style={{ overflow: "hidden" }}
        >
          <div className="col-md-12 h-100 mt-3" style={{ overflow: "auto" }}>
            <h4>Welcome to igesia</h4>
            <h5 className="mb-4">Your Projects</h5>
            <div className="mt-3">
              <Card
                style={{ width: "100%" }}
                className="shadow-sm bg-dark shadow-sm"
              >
                <Card.Body>
                  <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                    <div>
                      <img
                        alt="event-thumbnail"
                        width="110px"
                        src={props?.project?.image}
                        className="img-fluid rounded d-block d-md-inline mr-3"
                      ></img>
                      <span>{props?.project?.name} </span>
                    </div>
                    <div>
                      {/* <Button
                        className="btn-sm btn-primary mr-2"
                        onClick={() => setingEventDetails(true)}
                      >
                        Edit
                      </Button> */}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faVideo}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          PROJECT STATUS
                        </span>
                        <span>Live</span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          DATE
                        </span>
                        <span>May 11 7:30 AM {"to"} May 11 6:00 PM CET</span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          MEMBERS
                        </span>
                        <span>
                          {/* {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            attendeesSnapshot.docs &&
                            attendeesSnapshot.docs.length
                          )}{" "} */}
                          {"5 "}
                          members
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faComments}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          CHAT
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch"
                            label={chatOn ? "On" : "Off"}
                            checked={chatOn}
                            onChange={(e) => {
                              console.log(e.target.value);
                              //   firebase
                              //     .firestore()
                              //     .collection("events")
                              //     .doc("EKWsp2FDV2aWp1F6P963")
                              //     .update({
                              //       chat_enabled: e.target.checked,
                              //     })
                            }}
                          ></FormCheck>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faUsers}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          PEOPLE NETWORKING
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch-people"
                            label={peopleEnabled ? "On" : "Off"}
                            checked={peopleEnabled}
                            onChange={(e) => {
                              console.log(e.target.value);
                              //   firebase
                              //     .firestore()
                              //     .collection("events")
                              //     .doc("EKWsp2FDV2aWp1F6P963")
                              //     .update({
                              //       people_enabled: e.target.checked,
                              //     })
                            }}
                          ></FormCheck>
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <BriefStats /> */}
          </div>
        </div>
      ) : (
        <div className="mt-3 ml-3">
          <Button onClick={() => setingEventDetails(false)} className="btn-sm">
            Back to event manager
          </Button>
          {/* <EventDetails /> */}
        </div>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    project: state.project,
  }),
  null
)(Overview);
