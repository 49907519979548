import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

export default function igesiaNav() {
  return (
    <>
      <div className="d-none d-sm-block">
        <div className="row pb-5 mb-4">
          <div className="col-md-6">
            <a
              href="/"
              className="text-decoration-none text-light mr-3 text-uppercase text-s-9"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/Igesia-Logo.svg`}
                height="60"
              />
            </a>
            {/* <Dropdown style={{display: "inline"}} >
              <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-decoration-none  pt-0" >
                <span className="text-decoration-none text-black-333 mr-1 text-uppercase text-s-9">Solutions</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/connect"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Connect</Dropdown.Item>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/events"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Events</Dropdown.Item>
                 <Dropdown.Item href={process.env.PUBLIC_URL + "/course"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Courses</Dropdown.Item>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/consultation"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Consultation</Dropdown.Item> 
              </Dropdown.Menu> 
            </Dropdown> */}

            <a
              target="_blank"
              href="https://academy.igesia.co/"
              className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9"
            >
              Academy
            </a>
          </div>
          <div className="col-md-6 text-right pt-3">
            {/* <a
              target="_blank"
              href="https://academy.igesia.co/updates"
              className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9"
            >
              Updates
            </a> */}
            <Link to={process.env.PUBLIC_URL + "/pricing"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9">Pricing</Link>
            {/* <a href="https://console.igesia.co/" className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9"
              style={{ background: "rgba(0, 0, 70, 0.12)", padding: "6px 11px 6px 13px", borderRadius: "50px" }}>Try it free</a> */}
          </div>
        </div>
      </div>
      <div className="d-block d-sm-none">
        <div className="row pb-5 mb-4">
          <div className="col-md-12 text-center mb-3">
            {/*  <Dropdown style={{display: "inline"}} >
              <Dropdown.Toggle variant="link" id="dropdown-basic" className="text-decoration-none" >
                <span className="text-decoration-none text-black-333 mr-1 text-uppercase text-s-9">Solutions</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/connect"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Connect</Dropdown.Item>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/events"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Events</Dropdown.Item>
                 <Dropdown.Item href={process.env.PUBLIC_URL + "/course"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Courses</Dropdown.Item>
                <Dropdown.Item href={process.env.PUBLIC_URL + "/consultation"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9 font-weight-bold">Consultation</Dropdown.Item> 
              </Dropdown.Menu>
            </Dropdown> */}
            <a
              target="_blank"
              href="https://academy.igesia.co/"
              className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9"
            >
              Academy
            </a>
          </div>
          <div className="col-md-12 text-center">
            {/* <a
              target="_blank"
              href="https://academy.igesia.co/updates"
              className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9"
            >
              Updates
            </a> */}
            <Link to={process.env.PUBLIC_URL + "/pricing"} className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9">Pricing</Link>
            {/* <a href="" className="text-decoration-none text-black-333 mr-3 text-uppercase text-s-9"
              style={{ background: "rgba(0, 0, 70, 0.12)", padding: "6px 11px 6px 13px", borderRadius: "50px" }}>Try it free</a> */}
          </div>
        </div>
      </div>
    </>
  );
}
