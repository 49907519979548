import React, { useEffect, useState } from "react";
import TableCell from "./TableCell";
import { connect } from "react-redux";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button, Modal, Form, Card } from "react-bootstrap";
import {
  setBuyerRows,
  setBuyerHeader,
} from "../../../../store/actions/actions";

function Table(props) {
  const [showAddStepModal, setShowAddStepModal] = useState(false);
  const [newStep, setNewStep] = useState("");

  const [editStageModal, setEditStageModal] = useState(false);
  const [editStageModalValue, setEditStageModalValue] = useState(null);
  const { darkTheme } = props;

  const [stepCollection, stepCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns`
      )
  );

  const stepArray = stepCollection?.docs?.map((item) => {
    return { ...item.data(), id: item.id };
  });

  useEffect(() => {
    if (stepArray) {
      let data = stepArray;
      props.setBuyerHeader([...data]);
    }
  }, [JSON.stringify(stepCollection)]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/`
      )
      .doc("0")
      .onSnapshot((snapshot) => {
        if (snapshot.data()?.rows) {
          props.setBuyerRows(snapshot.data().rows);
        }
      });
  }, []);

  const head = [
    {
      id: 0,
      name: " ",
    },
    ...props.buyerHeaders,
  ];

  const rows = [...props.buyerRows];

  const addStepHandler = (e) => {
    e.preventDefault();
    let user = firebase.auth().currentUser;
    let data = {
      name: newStep,
      created_at: new Date(),
      created_by: {
        name: user.displayName,
        email: user.email,
        id: user.uid,
      },
    };
    // console.log(data, props.buyerHeaders.length + 1);
    if (newStep) {
      let docRef = firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns`
        );
      docRef.doc(`${props.buyerHeaders.length + 1}`).set(data);

      setNewStep("");
      setShowAddStepModal(false);
    }
  };

  const editStageNameHandler = (e) => {
    e.preventDefault();
    // console.log(editStageModalValue)
    let user = firebase.auth().currentUser;
    let data = {
      name: editStageModalValue.name,
      created_at: new Date(),
      created_by: {
        name: user.displayName,
        email: user.email,
        id: user.uid,
      },
    };
    if (editStageModalValue) {
      let docRef = firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns`
        );
      docRef.doc(`${editStageModalValue.id}`).set(data);

      setEditStageModalValue(null);
      setEditStageModal(false);
    }
  };

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="mb-0 text-uppercase">{props.currentEvent?.bbos_names?.buyer_experience ?? "Buyer Experience Audit"}</h5>

        <Button
          size="sm"
          variant="primary"
          onClick={() => setShowAddStepModal(true)}
        >
          <span className="text-uppercase">Add Stage</span>
        </Button>
      </Card.Header>
      {/* <Form className="row" >
        <Form.Group className="m-3" controlId="names">
          <Form.Check
            type="radio"
            label="Show Names"
            value="names"
            name="view"
            onClick={(e) => setSelectedView(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="m-3" controlId="cross">
          <Form.Check
            type="radio"
            label="Show Crosses"
            value="cross"
            name="view"
            onClick={(e) => setSelectedView(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="m-3" controlId="notes">
          <Form.Check
            type="radio"
            label="Notes"
            value="notes"
            name="view"
            onClick={(e) => setSelectedView(e.target.value)}
          />
        </Form.Group>
      </Form> */}
      <Card.Body className="p-1">
        <table class="table table-bordered">
          <thead className={darkTheme ? "thead-light" : "thead-dark"}>
            <tr>
              {head.map((item, index) => (
                <th
                  scope="col"
                  key={item.id}
                  onClick={() => {
                    if (index > 0) {
                      setEditStageModal(true);
                      setEditStageModalValue(item);
                    }
                  }}
                >
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={darkTheme ? "text-light" : "text-dark"}>
            {rows.map((rowData) => {
              return (
                <tr>
                  {head.map((headData) => {
                    // console.log(rowData, headData, "my data")
                    return (
                      <TableCell
                        rowData={rowData}
                        headData={headData}
                        //selectedView={selectedView}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <>
          <Modal
            centered
            show={showAddStepModal}
            onHide={() => setShowAddStepModal(false)}
          >
            <Modal.Header
              closeButton
              className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
            >
              <Modal.Title>Add Stage</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
            >
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Stage name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter stage name"
                    value={newStep}
                    onChange={(e) => setNewStep(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer
              className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
            >
              <div className="d-flex">
                <Button
                  size="sm"
                  className="px-3 mr-2"
                  variant="secondary"
                  onClick={() => setShowAddStepModal(false)}
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  className="px-3"
                  variant="primary"
                  onClick={(e) => addStepHandler(e)}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>

        <>
          <Modal
            centered
            show={editStageModal}
            onHide={() => setEditStageModal(false)}
          >
            <Modal.Header data-theme={darkTheme ? "dark" : "light"} closeButton>
              <Modal.Title>Edit Stage name</Modal.Title>
            </Modal.Header>
            <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Edit stage</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Stage to edit"
                    value={editStageModalValue?.name}
                    onChange={(e) => {
                      setEditStageModalValue((prev) => {
                        const newState = { ...prev, name: e.target.value };
                        return newState;
                      });
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer data-theme={darkTheme ? "dark" : "light"}>
              <div className="d-flex">
              <Button
                className="col-sm mr-2"
                variant="secondary"
                onClick={() => setEditStageModal(false)}
              >
                Close
              </Button>
              <Button
                className="col-sm"
                variant="primary"
                onClick={(e) => editStageNameHandler(e)}
              >
                Save
              </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 text-uppercase small">
          <b>Pro tips</b>
        </p>
        <ol className="px-3 mb-1 pb-1">
          <li>
            <small>
              Click on any cell to mark your <b className="text-danger">X</b>{" "}
              and add Note.
            </small>
          </li>
          <li>
            <small>
              Click on the cell to see Notes added by you and others.
            </small>
          </li>
          <li>
            <small>
              To add more <b>Stages</b> click on <b>Add Stage</b> button on top
              right of this card.
            </small>
          </li>
        </ol>
      </Card.Footer>
    </Card>
  );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    buyerRows: state.buyerRows,
    buyerHeaders: state.buyerHeaders,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  {
    setBuyerRows,
    setBuyerHeader,
  }
)(Table);
