import React, { useState, memo, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import { faYoutube, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import {
  Alert,
  Badge,
  Button,
  Card,
  ResponsiveEmbed,
  Modal,
} from "react-bootstrap";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";

import { postData } from "../../../utils";
import EventNavbar from "./NavBar";
import EventLeftbar from "./LeftBar";
import EventChat from "./EventChat";
import IgesiaVideo from "./VideoConference/IgesiaVideo";

toast.configure();

function Lecture(props) {
  const { session_id } = useParams();
  const { currentLanguage, darkTheme, currentEvent } = props;

  const currentUser = firebase.auth().currentUser;

  // STATES
  const [showChat, setShowChat] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const api_url =
    currentEvent?.api_type === "prod" ? `api.igesia.com` : `dev.igesia.com`;

  const [snapshotSpeakers, loadingSpeakers, errorSpeakers] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/speakers`)
      .where("email_address", "==", currentUser?.email)
  );
  const isUserSpeaker = snapshotSpeakers?.docs?.length > 0 ? true : false;

  const [snapshotUsers, loadingUsers, errorUsers] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/users`)
      .where("email", "==", currentUser?.email)
  );
  const isUserModerator =
    !loadingUsers && snapshotUsers?.docs[0]?.data()?.user_role == 1
      ? true
      : false;

  const [courseSessionDoc, loadingCourseSession, errorCourseSession] =
    useDocument(
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/schedule`)
        .doc(`${session_id}`)
    );
  const courseSession = useMemo(() => courseSessionDoc?.data());
  const meetingID =
    courseSession?.meeting_type === 1
      ? null
      : courseSession?.meeting_id ??
        courseSession?.meeting_url?.replace("https://zoom.us/j/", "");

  const [streamSnapshot, streamLoading, streamError] = useDocument(
    firebase
      .firestore()
      .collection(`sessions/events/streamingSessions/`)
      .doc(`${meetingID}`)
  );
  const streamLive = streamSnapshot?.data()?.status;

  const [meetingSnapshot, loadingMeeting, errorMeeting] = useDocument(
    firebase
      .firestore()
      .collection("sessions/events/zoomSessions")
      .doc(`${meetingID}`)
  );

  const handleStreaming = (meetingID) => {
    const MEETING_LIVESTREAM_STATUS =
      api_url === "dev.igesia.com"
        ? `https://dev.igesia.com/integration/zoom/meetings/${meetingID}/livestream/status`
        : `https://${api_url}/events/meetings/${meetingID}/livestream/status`;

    postData(MEETING_LIVESTREAM_STATUS, {
      action: streamLive ? "stop" : "start",
      state: courseSession?.state ?? "",
    })
      .then((response) => {
        console.log("Response: ", response.data);
        setShowModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const stage_bg_color = currentEvent?.stage_bg_color ?? "#ffffff";

  return (
    <div className="h-100">
      <EventLeftbar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <EventNavbar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <EventLeftbar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 pb-5"
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "auto", backgroundColor: `${stage_bg_color}` }}
          >
            <div className="row">
              {currentEvent?.top_branding_image ? (
                <img
                  className="img-fluid"
                  src={currentEvent?.top_branding_image}
                />
              ) : (
                <div className="col-md-12 mb-2 text-center">
                  <h3 className="font-weight-bold">
                    {currentEvent &&
                      currentEvent?.event_details?.EN?.event_name}
                  </h3>
                </div>
              )}
              <p className="mt-2 w-100 font-weight-bold h5 text-center">
                {courseSession?.EN?.title}
              </p>

              <div className="w-100">
                {/* Session Recording */}
                {courseSession?.is_ended && courseSession?.recording_link && (
                  <div className="embed-responsive embed-responsive-16by9">
                    <ReactPlayer
                      controls
                      stopOnUnmount
                      // height="400px"
                      width="100%"
                      height="auto"
                      // className="embed-responsive-item"
                      playing
                      config={{
                        file: {
                          attributes: {
                            disablePictureInPicture: true,
                            noDownload: true,
                            controlsList: "nodownload noremoteplayback",
                          },
                        },
                      }}
                      url={courseSession?.recording_link}
                    />
                  </div>
                )}
                {/* Session Live */}
                {!courseSession?.is_ended && (
                  <>
                    {!loadingCourseSession &&
                      !loadingSpeakers &&
                      !loadingUsers &&
                      courseSession?.meeting_type === 0 && (
                        <>
                          {isUserSpeaker || isUserModerator ? (
                            <React.Fragment>
                              <div className="d-md-block d-none mt-3">
                                <Card className="border-0">
                                  <Card.Header>
                                    <div className="d-flex justify-content-between">
                                      {/* {meetingSnapshot?.data()?.status == 1 ? ( */}
                                      <div id="header">
                                        {courseSessionDoc.data()
                                          ?.livestream && (
                                          <div className="youtube-btn">
                                            {streamLive ? (
                                              <Badge
                                                style={{
                                                  marginBottom: "0",
                                                  fontSize: "14px",
                                                  padding: "5px",
                                                }}
                                                variant="danger"
                                                className="mr-2"
                                              >
                                                Live
                                              </Badge>
                                            ) : null}

                                            <a
                                              className="btn btn-sm btn-outline-danger mr-2"
                                              href={
                                                courseSessionDoc.data()?.link
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faYoutubeSquare}
                                                  size="lg"
                                                />{" "}
                                                &nbsp;Watch stream
                                              </span>
                                            </a>
                                            <button
                                              className="btn btn-sm btn-danger"
                                              onClick={() => {
                                                if (!streamLive) {
                                                  setModalType("streamStart");
                                                } else {
                                                  setModalType("streamEnd");
                                                }
                                                setShowModal(true);
                                              }}
                                              disabled={streamLive === 0}
                                              title={
                                                streamLive === 0 &&
                                                meetingSnapshot?.data()
                                                  ?.status === 0
                                                  ? "Streaming already completed"
                                                  : ""
                                              }
                                              style={
                                                streamLive === 0 &&
                                                meetingSnapshot?.data()
                                                  ?.status === 0
                                                  ? { cursor: "not-allowed" }
                                                  : {}
                                              }
                                            >
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faYoutube}
                                                  size="lg"
                                                />{" "}
                                                <b>
                                                  {streamLive ? "End" : "Start"}{" "}
                                                  streaming
                                                </b>
                                              </span>
                                            </button>
                                          </div>
                                        )}

                                        <Modal
                                          show={showModal}
                                          onHide={() => {
                                            setShowModal(false);
                                          }}
                                          style={{
                                            opacity: "1 !important",
                                          }}
                                          className="modal-dialog-centered"
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Title>
                                              {modalType === "streamStart"
                                                ? "Start streaming"
                                                : "Stop streaming"}
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            {modalType === "streamStart" ? (
                                              <>
                                                <p>
                                                  You're about to start
                                                  streaming. Once streaming
                                                  starts, it can not be paused
                                                  and can only be stopped!{" "}
                                                  <br></br> You can watch the
                                                  stream at{" "}
                                                  <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={
                                                      courseSessionDoc?.data()
                                                        ?.link
                                                    }
                                                    style={{
                                                      marginLeft: "3px",
                                                    }}
                                                  >
                                                    {
                                                      courseSessionDoc?.data()
                                                        ?.link
                                                    }
                                                  </a>
                                                </p>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <Button
                                                    className="btn-sm"
                                                    style={{
                                                      marginLeft: "auto",
                                                      marginTop: "10px",
                                                    }}
                                                    variant="primary"
                                                    onClick={() => {
                                                      handleStreaming(
                                                        meetingID
                                                      );
                                                    }}
                                                  >
                                                    Start streaming
                                                  </Button>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <p>
                                                  You're about to stop
                                                  streaming. Once streaming
                                                  stops, it can not be restarted
                                                  again!
                                                </p>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <Button
                                                    className="btn-sm"
                                                    style={{
                                                      marginLeft: "auto",
                                                      marginTop: "10px",
                                                    }}
                                                    variant="primary"
                                                    onClick={() => {
                                                      handleStreaming(
                                                        meetingID
                                                      );
                                                    }}
                                                  >
                                                    Stop streaming
                                                  </Button>
                                                </div>
                                              </>
                                            )}
                                          </Modal.Body>
                                        </Modal>
                                      </div>
                                      {/* ) : null} */}
                                      <Button
                                        variant="outline-dark"
                                        size="sm"
                                        onClick={() => {
                                          window.open(
                                            courseSession?.start_url ??
                                              courseSession?.meeting_url,
                                            "_blank"
                                          );
                                        }}
                                        className="ml-auto"
                                      >
                                        Open in Zoom app
                                      </Button>
                                    </div>
                                    {streamLive === 0 &&
                                    meetingSnapshot?.data()?.status === 0 ? (
                                      <p className="small text-muted mt-1 mb-0">
                                        <b>Note: </b>Streaming already
                                        completed, click on Watch stream to see
                                        video.
                                      </p>
                                    ) : null}
                                  </Card.Header>
                                  <Card.Body className="p-0">
                                    <ResponsiveEmbed
                                      className="d-md-block d-none"
                                      aspectRatio="16by9"
                                    >
                                      {currentEvent?.event_details?.EN
                                        ?.event_name && (
                                        <iframe
                                          title={
                                            currentEvent?.event_details?.EN
                                              ?.event_name
                                          }
                                          className="shadow-lg embed-responsive-item"
                                          width="100%"
                                          frameborder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;camera; microphone;"
                                          allowFullScreen
                                          src={`https://igesia-zoom.web.app/events/${currentEvent?.id}/${session_id}/${currentUser.uid}`}
                                        ></iframe>
                                      )}
                                    </ResponsiveEmbed>
                                  </Card.Body>
                                </Card>
                              </div>
                              <div className="d-md-none d-flex justify-content-center w-100 h-100 align-items-center">
                                <Button
                                  variant="outline-dark"
                                  size="sm"
                                  onClick={() => {
                                    window.open(
                                      courseSession?.meeting_url,
                                      "_blank"
                                    );
                                  }}
                                >
                                  Join with Zoom
                                </Button>
                              </div>
                            </React.Fragment>
                          ) : courseSessionDoc.data()?.livestream ? (
                            <React.Fragment>
                              {/* <p>{courseSessionDoc?.data()?.link.replace("/watch?v=", "/embed/")}</p> */}

                              {courseSessionDoc?.data()?.link ? (
                                <ResponsiveEmbed
                                  className="col-md-12 h-md-100 d-flex align-items-xs-start-md-center "
                                  aspectRatio="16by9"
                                >
                                  <>
                                    <iframe
                                      width="560"
                                      height="315"
                                      src={courseSessionDoc
                                        ?.data()
                                        ?.link.replace("/watch?v=", "/embed/")}
                                      title={courseSession?.EN?.title}
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                  </>
                                </ResponsiveEmbed>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="d-md-block d-none mt-3">
                                <Card className="border-0">
                                  <Card.Header>
                                    <div className="d-flex justify-content-between">
                                      {meetingSnapshot?.data()?.status == 1 ? (
                                        <div id="header">
                                          {courseSessionDoc.data()
                                            ?.livestream && (
                                            <div className="youtube-btn">
                                              {streamLive ? (
                                                <Badge
                                                  style={{
                                                    marginBottom: "0",
                                                    fontSize: "14px",
                                                    padding: "5px",
                                                  }}
                                                  variant="danger"
                                                  className="mr-2"
                                                >
                                                  Live
                                                </Badge>
                                              ) : null}

                                              <a
                                                className="btn btn-sm btn-outline-danger mr-2"
                                                href={
                                                  courseSessionDoc.data()?.link
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <span>
                                                  <FontAwesomeIcon
                                                    icon={faYoutubeSquare}
                                                    size="lg"
                                                  />{" "}
                                                  &nbsp;Watch stream
                                                </span>
                                              </a>
                                              <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() => {
                                                  if (!streamLive) {
                                                    setModalType("streamStart");
                                                  } else {
                                                    setModalType("streamEnd");
                                                  }
                                                  setShowModal(true);
                                                }}
                                                disabled={streamLive === 0}
                                              >
                                                <span>
                                                  <FontAwesomeIcon
                                                    icon={faYoutube}
                                                    size="lg"
                                                  />{" "}
                                                  <b>
                                                    {streamLive
                                                      ? "End"
                                                      : "Start"}{" "}
                                                    streaming
                                                  </b>
                                                </span>
                                              </button>
                                            </div>
                                          )}

                                          <Modal
                                            show={showModal}
                                            onHide={() => {
                                              setShowModal(false);
                                            }}
                                            style={{
                                              opacity: "1 !important",
                                            }}
                                            className="modal-dialog-centered"
                                          >
                                            <Modal.Header closeButton>
                                              <Modal.Title>
                                                {modalType === "streamStart"
                                                  ? "Start streaming"
                                                  : "Stop streaming"}
                                              </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                              {modalType === "streamStart" ? (
                                                <>
                                                  <p>
                                                    You're about to start
                                                    streaming. Once streaming
                                                    starts, it can not be paused
                                                    and can only be stopped!{" "}
                                                    <br></br> You can watch the
                                                    stream at{" "}
                                                    <a
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      href={
                                                        courseSessionDoc?.data()
                                                          ?.link
                                                      }
                                                      style={{
                                                        marginLeft: "3px",
                                                      }}
                                                    >
                                                      {
                                                        courseSessionDoc?.data()
                                                          ?.link
                                                      }
                                                    </a>
                                                  </p>
                                                  <div
                                                    style={{ display: "flex" }}
                                                  >
                                                    <Button
                                                      className="btn-sm"
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginTop: "10px",
                                                      }}
                                                      variant="primary"
                                                      onClick={() => {
                                                        handleStreaming(
                                                          meetingID
                                                        );
                                                      }}
                                                    >
                                                      Start streaming
                                                    </Button>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <p>
                                                    You're about to stop
                                                    streaming. Once streaming
                                                    stops, it can not be
                                                    restarted again!
                                                  </p>
                                                  <div
                                                    style={{ display: "flex" }}
                                                  >
                                                    <Button
                                                      className="btn-sm"
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginTop: "10px",
                                                      }}
                                                      variant="primary"
                                                      onClick={() => {
                                                        handleStreaming(
                                                          meetingID
                                                        );
                                                      }}
                                                    >
                                                      Stop streaming
                                                    </Button>
                                                  </div>
                                                </>
                                              )}
                                            </Modal.Body>
                                          </Modal>
                                        </div>
                                      ) : null}
                                      <Button
                                        variant="outline-dark"
                                        size="sm"
                                        onClick={() => {
                                          window.open(
                                            courseSession?.meeting_url,
                                            "_blank"
                                          );
                                        }}
                                        className="ml-auto"
                                      >
                                        Open in Zoom app
                                      </Button>
                                    </div>
                                  </Card.Header>
                                  <Card.Body className="p-0">
                                    <ResponsiveEmbed
                                      className="d-md-block d-none"
                                      aspectRatio="16by9"
                                    >
                                      {currentEvent?.event_details?.EN
                                        ?.event_name && (
                                        <iframe
                                          title={
                                            currentEvent?.event_details?.EN
                                              ?.event_name
                                          }
                                          className="shadow-lg embed-responsive-item"
                                          width="100%"
                                          frameborder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;camera; microphone;"
                                          allowFullScreen
                                          src={`https://igesia-zoom.web.app/events/${currentEvent?.id}/${session_id}/${currentUser.uid}`}
                                        ></iframe>
                                      )}
                                    </ResponsiveEmbed>
                                  </Card.Body>
                                </Card>
                              </div>
                              <div className="d-md-none d-flex justify-content-center w-100 h-100 align-items-center">
                                <Button
                                  variant="outline-dark"
                                  size="sm"
                                  onClick={() => {
                                    window.open(
                                      courseSession?.meeting_url,
                                      "_blank"
                                    );
                                  }}
                                >
                                  Join with Zoom
                                </Button>
                              </div>
                            </React.Fragment>
                          )}
                        </>
                      )}
                    {/* Meeting type: Custom */}
                    {courseSession?.meeting_type === 1 &&
                      courseSession?.live_streaming && (
                        <React.Fragment>
                          {courseSession?.streaming_link ? (
                            <div className="embed-responsive embed-responsive-16by9">
                              <ReactPlayer
                                controls
                                stopOnUnmount
                                // height="400px"
                                width="100%"
                                height="auto"
                                // className="embed-responsive-item"
                                playing
                                config={{
                                  file: {
                                    attributes: {
                                      disablePictureInPicture: true,
                                      noDownload: true,
                                      controlsList:
                                        "nodownload noremoteplayback",
                                    },
                                  },
                                }}
                                url={courseSession?.streaming_link}
                              />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}

                    {/* Meeting type: Igesia */}
                    {courseSession?.meeting_type === 2 &&
                      (courseSession?.live_streaming ? (
                        isUserSpeaker || isUserModerator ? (
                          <IgesiaVideo
                            session_id={session_id}
                            sessionDetails={courseSession}
                            isUserSpeaker={isUserSpeaker}
                            isUserModerator={isUserModerator}
                          />
                        ) : (
                          <React.Fragment>
                            {courseSession?.streaming_link ? (
                              <div className="embed-responsive embed-responsive-16by9">
                                <ReactPlayer
                                  controls
                                  stopOnUnmount
                                  // height="400px"
                                  width="100%"
                                  height="auto"
                                  // className="embed-responsive-item"
                                  playing
                                  config={{
                                    file: {
                                      attributes: {
                                        disablePictureInPicture: true,
                                        noDownload: true,
                                        controlsList:
                                          "nodownload noremoteplayback",
                                      },
                                    },
                                  }}
                                  url={courseSession?.streaming_link}
                                />
                              </div>
                            ) : null}
                          </React.Fragment>
                        )
                      ) : (
                        <IgesiaVideo
                          session_id={session_id}
                          sessionDetails={courseSession}
                          isUserSpeaker={isUserSpeaker}
                          isUserModerator={isUserModerator}
                        />
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "auto" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={[
                {
                  name: "Chat",
                  type: "chat",
                  chatroute: `/events/${currentEvent?.id}/schedule/${session_id}/questionsAsked/`,
                },
              ]}
            />
          </div>
        </div>
      </div>

      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat
            event_id={currentEvent?.id}
            tabs={[
              {
                name: "Chat",
                type: "chat",
                chatroute: `/events/${currentEvent?.id}/schedule/${session_id}/questionsAsked/`,
              },
            ]}
          />
        </div>
      )}

      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
}

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  currentEvent: state.currentEvent,
  darkTheme: state.darkTheme,
}))(memo(Lecture));
