import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Tabs,
  Tab,
  Image,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import * as ProjectStyle from "../project.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { projectTimeline } from "../../data";
import ProjectRedux from "../ProjectRedux";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import Chat from "./Chat";

function Project(props) {
  const { hash, pathname } = useLocation();
  const history = useHistory();


  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);


  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc("X341jPkQegTcM4QTT8p0")
  );



  return (


    <div className="h-100" ref={pageRef}>
      <ProjectRedux />
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div className="col-md-7 h-100 p-md-5 p-3 bg-graphite" style={{ overflow: "scroll" }}>
            <div className="px-0 bg-graphite" >
              {/* Event cover starts */}
              <div className="" id="#overview" >
                <h2 className="font-weight-bold text-white">
                  {props?.project?.name && props?.project.name}
                </h2>



                {/* <img src={} className="img-fluid rounded-lg border border-secondary" /> */}
                <div
                  className={`rounded-top ${ProjectStyle.project_cover_img1}`}
                  style={{
                    backgroundImage: `url(${props?.project?.image})`,
                  }}
                />
              </div>
              {/* Event cover ends */}

              <div className=" pt-3 pt-md-4">
                <div className="text-white">
                  <p>
                    {props?.project?.description && props?.project.description}
                  </p>
                </div>

                {projectTimeline.map((item) => {
                  return (
                    <Card
                    className={`${ProjectStyle.dark_bg} text-white shadow-sm mb-3 mb-md-4`}
                  >
                    <Card.Header>
                      <div className="d-flex">
                        <div
                          className="img-fluid rounded-circle shadow-sm"
                          style={{
                            height: 50,
                            width: 50,
                            backgroundImage: `url(${item.user.avatar})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                          //title={selectedProfile?.name}
                        />
                        <div className="ml-2">
                          <div className="d-flex align-items-center">
                            <p className="mb-0 mr-2">{item.user.name}</p>
                            <p className="mb-0 small text-muted">{`6:34 PM`}</p>
                          </div>
                          <p className="mb-0 small text-muted">
                            {item.user.designation}
                          </p>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <p className="">{item.content}</p>
                      <Card.Img
                        src={item.photoURL}
                        className="rounded img-fluid"
                        variant="bottom"
                      />
                      {/* <div
                        style={{
                          backgroundImage: `url(${item.photoURL})`,
                          height: 250,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      /> */}
                    </Card.Body>
                  </Card>
                  );
                })}
              </div>
            </div>

          </div>
          <div className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
            style={{ overflow: "scroll" }} >
            <Chat />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project
  }),
  { changeLanguage }
)(Project);
