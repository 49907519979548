import { Card, CardContent, CardHeader } from "@material-ui/core";
import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import firebase from "firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const data = [{name: 'Page A', uv: 400, amt: 2400}];

const AppointmentGraph = () => {
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(true);

  const clinicId = useSelector((state) => state.clinicId);

  let AppointmentRef = firebase
    .firestore()
    .collection("clinics")
    .doc(clinicId)
    .collection("appointments");

  let date = new Date();

  // AppointmentRef.get().then((doc)=>{
  //   doc.docs.map(item=>{
  //     console.log(item.data())
  //   })
  // })

  const getAppointmentNumber = async (d) => {
    let response = await AppointmentRef.where(
      "appointmentDate",
      "==",
      moment(d).format("DD-MM-YYYY")
    )
      .get()
      .then((doc) => {
        // appointmentData.shift()

        return {
          date: moment(date).format("DD-MM-YYYY"),
          appointments: doc.docs.length,
        };
      });
    return response;
  };
  const [appointmentDataDays, setAppointmentDataDays] = useState(7);

  const fetchData = async () => {
    setLoading(true);
    let tempAppointmentData = [];
    tempAppointmentData.push(await getAppointmentNumber(date));
    for (let i = 1; i < appointmentDataDays; i++) {
      const newDate = new Date(date.setDate(date.getDate() - 1));

      // console.log(newDate);
      tempAppointmentData.unshift(await getAppointmentNumber(newDate));
    }
    // console.log(new Date().toLocaleDateString());
    // console.log(tempAppointmentData);

    setAppointmentData(tempAppointmentData);
    setLoading(false);
  };

  useEffect(() => {
    // AppointmentRef.get().then(doc=>{
    //   doc.docs.map(appointment=>{
    //     AppointmentRef.doc(appointment.id).update({
    //       created_date: new Date(appointment.data().timestamp?.seconds *1000).toLocaleDateString()
    //     })
    //   })
    // })
    fetchData();
    console.log(appointmentData);
  }, [appointmentDataDays]);

  return (
    <Card>
      <CardHeader
        className="bg-dark text-white"
        title={
          <>
            Appointment Graph
            <select
              className="from-control float-right small"
              defaultValue={7}
              onChange={(e) => setAppointmentDataDays(e.target.value)}
            >
              <option value={7}>Last 7 days</option>
              <option value={15}>Last 15 days</option>
              <option value={30}>Last 30 days</option>
            </select>
          </>
        }
      />

      {
        <CardContent className="text-center overflow-auto bg-dark">
          {loading ? (
            <div className="text-center py-5 my-5">
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </div>
          ) : (
            <div className="d-flex justify-content-center ">
              <LineChart
                width={900}
                height={300}
                data={appointmentData}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              >
                <Line
                  type="monotone"
                  dataKey="appointments"
                  stroke="#99154e  "
                />
                <CartesianGrid stroke="#fff" strokeDasharray="5 5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
              </LineChart>
            </div>
          )}
        </CardContent>
      }
    </Card>
  );
};

export default AppointmentGraph;
