import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Spinner,
  ResponsiveEmbed,
  Card,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  faChevronDown,
  faDownload,
  faPaperPlane,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import Avatar from "react-avatar";
import moment from "moment";
import Linkify from "linkifyjs/react";
import { loadState, saveState } from "../../../../services/index";
import ReadMore from "./ReadMore";
import "./AskQuestion.scss";
import "../Styles.css";

function EventChat(props) {
  const userDetails = firebase.auth().currentUser;

  const {darkTheme} = props;

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView(
      { behavior: "smooth" }
    );
  };

  const [questionsAsked, setQuestionsAsked] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionError] = useState("");

  const isStringEmptyOrUndefined = (str) => {
    return str === "" || str === undefined;
  };

  const emptyQuestion = () => setQuestion("");

  useEffect(() => {
    firebase
      .firestore()
      .collection(`${props.chatroute}`)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        let temp_questionsAsked = [];
        docs.forEach((doc) => {
          temp_questionsAsked.push(doc.data());
        });
        setQuestionsAsked(temp_questionsAsked);
      });
  }, [props.chatroute]);

  useEffect(() => {
    scrollToBottom();

    return scrollToBottom();
  }, [questionsAsked]);

  const submitQuestion = () => {

    
    if (!isStringEmptyOrUndefined(question)) {
      let questionObj = {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        name: userDetails.displayName,
        uid: userDetails.uid,
        question,
        timestamp: Date.now(),
      };
      console.log(props.chatroute, questionObj)
      firebase
        .firestore()
        .collection(`${props.chatroute}`)
        .add(questionObj)
        .then((docRef) => {
          emptyQuestion();
          console.log("Done: ", docRef);
        })
        .catch((err) => console.error(err));
    } else {
      setQuestionError("Please enter some text!");
    }
  };

  const strip = (value) => {
    if (value) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = value.match(regExp);
      return match && match[2].length === 11 ? (
        <iframe
          style={{ borderRadius: "4px" }}
          width="100%"
          height="170"
          src={"//www.youtube.com/embed/" + match[2]}
          frameborder="0"
          allowfullscreen
        ></iframe>
      ) : // : <ReadMore full_text={value} max_length="250" />;
      value.length > 350 ? (
        value.slice(0, 350) + "…"
      ) : (
        value
      );
    }
    // : value.length > 50 ? <ReadMore full_text={value} max_length="250" /> + '…' : value;
  };

  const isOwnMessage = (message, user) => {
    return message.uid === user.uid ? "message__self" : "message__others";
  };

  const timeFromNow = (timestamp) => {
    //if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).format("h:mm A");
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return `Yesterday, ${moment(timestamp).format("h:mm A")}`;
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return `${moment(timestamp).format("dddd")}, ${moment(timestamp).format(
        "h:mm A"
      )}`;
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
      return `${moment(timestamp).format("MMM DD, YYYY")}, ${moment(
        timestamp
      ).format("h:mm A")}`;
    else return moment(timestamp).format("MMM YY");
  };

  const MessageTemplate = (message) => {
    if (message.message.question) {
      return (
        <Linkify
          options={{
            target: { email: null, target: "_blank" },
            format: { url: strip(message.question) },
            events: {
              mouseover: function (e) {
                console.log("Link clicked!");
              },
            },
          }}
        >
          <ReadMore full_text={message.message.question} max_length="250" />
        </Linkify>
      );
    }
  };

  return (
    <React.Fragment>
      <Card 
      className={props.darkTheme ? "bg-dark text-muted h-100 border-0 rounded-0" : "bg-light text-muted h-100 border-0 rounded-0"} style={{ minHeight: "100%" }}
       >
        {props.headerComponent ? (
          <Card.Header className="p-0">{props.headerComponent}</Card.Header>
        ) : (
          <Card.Header>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ">
                <p className="mb-0 font-weight-bold small">Chat</p>
              </div>
            </div>
          </Card.Header>
        )}
        <Card.Body className="m-0 p-0" style={{ overflowY: "auto" }}>
          <div className="p-2 content-messages">
            {props.chatroute ? <>
            {questionsAsked.map((item) => (
              <div className="message m-0 p-0">
                <div
                  className={`${isOwnMessage(item, userDetails)} p-2 `}
                  style={{ marginBottom: 10 }}
                >
                  <div className="d-flex">
                    <span
                      style={{ fontWeight: "bold" }}
                      className="author"
                      as="a"
                    >
                      {item.name}&nbsp;
                    </span>
                    <span className="small text-theme-light timestamp">
                      {timeFromNow(item.timestamp)}
                    </span>
                  </div>
                  <div className="mt-1">
                    <MessageTemplate message={item} />
                  </div>
                  {/* <p className="mb-0">
                                            <Linkify
                                                className="mb-0"
                                            >
                                                {strip(item.question)}
                                            </Linkify>
                                        </p> */}

                  {/* <p className="mb-0 small">{item.question}</p> */}
                  {/* <div className="d-flex flex-row-reverse">
                                            <small className="text-white smaller">{moment(item.timestamp).format("LLL")}</small>
                                        </div> */}
                </div>
              </div>
            ))}
            </>:
            <div className={props.darkTheme ? "bg-dark text-muted h-100 border-0 rounded-0" : "bg-light text-muted h-100 border-0 rounded-0"}>
            
            <div className="text-center">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
                width="50%"
              />
              <p className="text-muted small mt-3">Chat will be available soon</p>
            </div>
            {/* <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
              <div>Chat is currently disabled</div>
              <div>
                <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
              </div>
            </div> */}
          </div> }
            <div ref={messagesEndRef} />
          </div>
        </Card.Body>
        <Card.Footer>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              // submitQuestion()
            }}
          >
            {props.chatroute ? <div className="d-flex align-items-center">
              <Form.Control
                as="textarea"
                rows="1"
                placeholder="Type a message"
                className="border py-2 bg-white mr-3"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                  setQuestionError("");
                }}
                style={{ fontSize: 16, borderRadius: "40px" }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    submitQuestion();
                  }
                }}
              />
              <Button
                variant="primary rounded-circle"
                type="submit"
                onClick={submitQuestion}
              >
                <p className="small mb-0">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </p>
              </Button>
            </div> : <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
            <div>Chat is currently disabled</div>
            <div>
              <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
            </div>
          </div>}

            {questionError != "" && (
              <p className="small mt-1 mb-0 text-danger">{questionError}</p>
            )}
          </Form>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default connect(
  (state) => ({  darkTheme: state.darkTheme}),
  null
)(EventChat);