import React, { useRef, useState, useEffect } from "react";
import {
  Navbar,
  Tabs,
  Tab,
  Image,
  Card,
  Button,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import * as EventStyle from "../event.module.css";
import { languageText } from "../language.json";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import EventChat from "./chat";

function Event({ currentLanguage, changeLanguage, currentEvent }) {
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [navigationTo, setNavigationTo] = useState("overview");
  const overviewRef = useRef(null);
  const speakersRef = useRef(null);
  const agendaRef = useRef(null);
  const boothsRef = useRef(null);

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#speakers`) {
      speakersRef && speakersRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#agenda`) {
      agendaRef && agendaRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#booths`) {
      boothsRef && boothsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const { id } = useParams();

  const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
    firebase.firestore().collection("events").where("isDemo", "==", true).where("slug", "==", `${id}`)
  );

  const userDetails = firebase.auth().currentUser;

  const [snapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent.id}/booths`)
      .where("email_address_of_owner", "array-contains", userDetails.email)
  ); //listens to onsnapshot changes

  //   Booths data
  const [boothCategorySnapshot, loadingBoothCategory, errorBoothCategory] =
    useCollection(
      firebase
        .firestore()
        .collection(`/events/${eventSnapshot?.docs[0]?.id}/boothCategories`)
        .orderBy("order_index", "asc")
    );
  const sortedBoothCategories = boothCategorySnapshot?.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));

  const showFullCardBooths =
    eventSnapshot?.docs[0]?.data()?.show_full_card_booths ?? false;

  const [boothsSnapshot, loadingBooths, errorBooths] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventSnapshot?.docs[0]?.id}/booths`)
      .orderBy("order_index", "asc")
  );

  let sortedBooths = boothsSnapshot?.docs.map((booth) => {
    return {
      ...booth.data(),
      id: booth.id,
    };
  });

  sortedBooths = sortedBooths?.filter((category) => category);
  sortedBooths = _.orderBy(sortedBooths, "order_index", "asc");
  const BoothsPerCategoryHandler = (props) => {
    const { boothCategoryID } = props;
    return sortedBooths.map((booth, idx) => {
      if (
        booth.booth_categories &&
        booth.booth_categories.length &&
        booth.booth_categories.includes(boothCategoryID) &&
        showFullCardBooths
      ) {
        const item = booth;
        return (
          <div
            className="col-md-6 mb-4 col-12"
            key={idx}
            onClick={() =>
              history.push(`/event/${id}/booth/${item.id}`)
            }
            style={{ cursor: "pointer" }}
          >
            <div className="card event-card border-0 h-100">
              {item.featured_image && (
                <div
                  style={{
                    background: `url(${item.featured_image}) center center / cover no-repeat`,
                    minHeight: "200px",
                  }}
                  className="card-img-top"
                />
              )}
              <div className="card-body h-100 bg-white">
                <div className="d-flex mb-3 align-items-center">
                  {item.company_logo && (
                    <div
                      style={{
                        background: `url(${item.company_logo}) 0% 0% / contain no-repeat`,
                        height: "40px",
                        width: "40px",
                      }}
                      className="rounded-circle mr-3"
                    />
                  )}
                  {item.booth_name && (
                    <h5 className="text-s-6 text-dark">
                      {item.booth_name ? item.booth_name : "Booth name"}
                    </h5>
                  )}
                </div>
                <p className=" mb-0 text-dark">
                  {item.small_description ? item.small_description : null}
                </p>
              </div>
            </div>
          </div>
        );
      } else if (
        booth.booth_categories &&
        booth.booth_categories.length &&
        booth.booth_categories.includes(boothCategoryID)
      ) {
        const item = booth;
        return (
          <div
            className="col-md-6 mb-4 col-12"
            key={idx}
            onClick={() =>
              history.push(`/event/${id}/booth/${item.id}`)
            }
            style={{ cursor: "pointer" }}
          >
            <div
              className="icon-box bg-white w-100 px-4 py-3"
              style={{ borderRadius: "12px" }}
            >
              <div className="d-flex">
                <div className="d-flex mb-3 align-items-center">
                  {item.company_logo && (
                    <img
                      src={item.company_logo}
                      alt="..."
                      width="70"
                      className="mr-4"
                    />
                  )}
                  {item.booth_name && (
                    <h5 className="text-s-10 mb-0">
                      {item.booth_name ? item.booth_name : "Booth name"}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="h-100" ref={pageRef}>
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
            style={{ overflow: "scroll" }}
          >
            <div className="container-fluid px-0 bg-graphite">
              {/* Booths start */}
              <div
                className={`container card shadow-sm border-0 ${EventStyle.dark_bg} pt-3 pt-md-4`}
                id="#booths"
                ref={boothsRef}
              >
                <h3 className="text-dark">
                  {languageText[`${currentLanguage}`].booths}

                </h3>
                {snapshot?.docs && snapshot?.docs.length ? (
                    <div className="shadow-smrounded bg-white small">
                      <Button
                        variant="outline"
                        className="btn-sm btn-outline-primary"
                        onClick={() =>
                          history.push(`/event/${id}/managebooth`)
                        }
                      >
                        {languageText[`${currentLanguage}`].manageBooth}
                      </Button>
                    </div>
                  ) : null}
                <div>
                  {sortedBoothCategories?.map((category) => {
                    return (
                      <div className="row w-100">
                        <div className="col-md-12">
                          <h5 className="mb-4 mt-3 text-dark">
                            {category[currentLanguage].title}
                          </h5>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <BoothsPerCategoryHandler
                                  boothCategoryID={category.id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* Booths end */}
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
            style={{ overflow: "scroll" }}
          >
            <EventChat/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage, currentEvent: state.currentEvent}),
  { changeLanguage }
)(Event);
