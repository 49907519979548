import { memo, useEffect, useMemo, useState } from "react";
import { Button, Card, Form, Modal, Spinner, Accordion } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import Select from "react-select";
import { toast } from "react-toastify";
import produce from "immer";
import firebase from "firebase";
import PollFields from "./PollFields";
import AssignmentFields from "./AssignmentFields";
import QuizFields from "./QuizFields";
import { useCollection } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { isArray } from "lodash";
import axios from "axios";
toast.configure();

const AddModuleModal = (props) => {
  const [facultySnapshot, facultyLoading, facultyError] = useCollection(
    firebase.firestore().collection(`/courses/${props.courseId}/faculty`)
  );

  const facultyOptions = useMemo(() => {
    return facultySnapshot?.docs?.map((fa) => ({
      label: fa.data().fullName.firstName + " " + fa.data().fullName.lastName,
      value: fa.id,
    }));
  }, [facultySnapshot]);

  const [scheduleData, setScheduleData] = useState({});
  const [saving, setSaving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [instructors, setInstructors] = useState([]);

  const [preReadingKey, setPreReadingKey] = useState(0 + "ac");
  const [extraMaterialKey, setExtraMaterialKey] = useState(0 + "ex");

  const saveTextDataToArrayHandler = (e, type, index) => {
    setScheduleData((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[type][index][e.target.name] = e.target.value;
      });
      return updatedState;
    });
  };

  const saveTextDataToStateHandler = (e) => {
    setScheduleData((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[e.target.name] = e.target.value;
      });
      return updatedState;
    });
  };

  const saveDateToStateHandler = (selectedDate, dateStr, instance) => {
    setScheduleData((prev) => {
      return { ...prev, [instance.input.name]: selectedDate[0].valueOf() };
    });
  };

  const timeValidation = (minMilliseconds) => {
    if (
      scheduleData.end_time - scheduleData.start_time < 0 ||
      scheduleData.end_time - scheduleData.start_time < minMilliseconds
    ) {
      return 0;
    } else {
      return 1;
    }
  };

  const createZoomMeetingHandler = async (meetingData) => {
    const response = await axios.post(
      "https://dev.igesia.com/events/meetings/schedule ",
      {
        event_id: "MFTda9uuVF0tVJ4lgxo4",
        user_id: "1gHTSDwzWRPW7KAUeTcscDFxs7z2",
        meeting_topic: meetingData.topic,
        meeting_agenda: meetingData.short_description ?? " ",
        meeting_timezone: "Asia/Calcutta",
        meeting_start_time: meetingData.start_time,
        meeting_duration: Math.floor(
          ((meetingData.end_time - meetingData.start_time) / 1000 / 60) << 0
        ),
      }
    );
    return response;
  };

  const addScheduleToDBHandler = async (schedule) => {
    try {
      setSaving(true);
      let zoomResponse;
      if (props.addModuleType === 0) {
        zoomResponse = await createZoomMeetingHandler(scheduleData);
      }
      let live = {};

      if (zoomResponse?.status === 200) {
        const data = zoomResponse.data.data;
        live = {
          meeting_no: data.meeting_id,
          meeting_pass: data.meeting_password ?? "",
          meeting_url: data.start_url,
          join_url: data.join_url,
          type: 0, // zoom
        };
      }
      const size = (
        await firebase
          .firestore()
          .collection(
            `/courses/${props.courseId}/sections/${props.sectionId}/modules`
          )
          .get()
      ).docs.length;
      let docref = firebase
        .firestore()
        .collection(
          `/courses/${props.courseId}/sections/${props.sectionId}/modules`
        )
        .doc();
      await docref.set({
        ...scheduleData,
        section: props.sectionId,
        order_index: size,
        type:
          props.addModuleType === "poll"
            ? 3
            : props.addModuleType === "quiz"
            ? 4
            : props.addModuleType === "assignment"
            ? 2
            : props.addModuleType === "lecture_recording"
            ? 1
            : 0,
        live: props.addModuleType === 0 ? live : null,
      });
      toast.success("Module added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error adding session", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.setShowAddScheduleModal(false);
      setScheduleData({});
    }
  };

  const updateScheduleToDBHandler = async (schedule) => {
    try {
      setSaving(true);
      const moduleCount = (
        await firebase
          .firestore()
          .collection(
            `/courses/${props.courseId}/sections/${props.sectionId}/modules`
          )
          .get()
      ).docs.length;
      let docref = firebase
        .firestore()
        .collection(
          `/courses/${props.courseId}/sections/${props.sectionId}/modules`
        )
        .doc(schedule.id);

      await docref.set({
        ...scheduleData,
        instructors: instructors ?? [],
        timestamp: Date.now(),
        section: props.sectionId,
        order_index: moduleCount,
      });

      toast.success("Module updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e);
      toast.error("Error adding session", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setIsUpdating(false);
      setSaving(false);
      props.setShowAddScheduleModal(false);
      setScheduleData({});
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showAddScheduleModal}
      onHide={() => {
        setIsUpdating(false);
        props.setShowAddScheduleModal(false);
        setScheduleData({});
      }}
      size="lg"
    >
      <Modal.Header closeButton className="bg-dark text-light border-0">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-capitalize"
        >
          New{" "}
          {props.addModuleType === "lecture_recording"
            ? "Lecture Recording"
            : props.addModuleType}{" "}
          Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light">
        {props.addModuleType === "poll" ? (
          <PollFields
            onHide={() => {
              setIsUpdating(false);
              props.setShowAddScheduleModal(false);
              setScheduleData({});
            }}
            sectionId={props.sectionId}
            addModuleType={props.addModuleType}
          />
        ) : props.addModuleType === "assignment" ? (
          <AssignmentFields
            onHide={() => {
              setIsUpdating(false);
              props.setShowAddScheduleModal(false);
              setScheduleData({});
            }}
            sectionId={props.sectionId}
            addModuleType={props.addModuleType}
          />
        ) : props.addModuleType === "quiz" ? (
          <QuizFields
            onHide={() => {
              setIsUpdating(false);
              props.setShowAddScheduleModal(false);
              setScheduleData({});
            }}
            sectionId={props.sectionId}
            addModuleType={props.addModuleType}
          />
        ) : (
          <Form>
            <div className="row">
              <Form.Group className="col-md-12">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={scheduleData.title}
                  onChange={(e) => saveTextDataToStateHandler(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Short Description"
                  name="short_description"
                  value={scheduleData.short_description}
                  onChange={(e) => saveTextDataToStateHandler(e)}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "bold",
                      "italic",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  className="mb-3"
                  data={scheduleData.description ?? ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setScheduleData((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft.description = data;
                      });
                      return updatedState;
                    });
                  }}
                  style={{ height: 400 }}
                />
              </Form.Group>

              <Form.Group className="col-md-12 mb-0">
                <Form.Label>Pre-lecture readings</Form.Label>
              </Form.Group>
              <Accordion
                activeKey={preReadingKey}
                onSelect={setPreReadingKey}
                flush
                className="col-md-12"
              >
                {scheduleData?.pre_lecture_readings?.map((pre, idx) => {
                  return (
                    <Card key={idx} className="text-dark">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={idx + "ac"}
                        style={{ cursor: "pointer" }}
                        className={`text-white`}
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <span className="font-weight-bold text-uppercase small text-dark">
                              Pre-lecture reading #{idx + 1}
                            </span>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon={
                                preReadingKey === idx + "ac"
                                  ? faAngleUp
                                  : faAngleDown
                              }
                              className="mr-4 text-dark"
                            />
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => {
                                setScheduleData((prev) => {
                                  const updatedState = produce(
                                    prev,
                                    (draft) => {
                                      draft.pre_lecture_readings.splice(idx, 1);
                                    }
                                  );
                                  return updatedState;
                                });
                              }}
                            >
                              X
                            </Button>
                          </div>
                          <div>
                            <span className="small"></span>
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={idx + "ac"}>
                        <Card.Body className={`bg-graphite text-light`}>
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={scheduleData.pre_lecture_readings[idx].title}
                            onChange={(e) =>
                              saveTextDataToArrayHandler(
                                e,
                                "pre_lecture_readings",
                                idx
                              )
                            }
                            className="mb-2"
                          />
                          <Form.Label>Link</Form.Label>
                          <Form.Control
                            type="url"
                            placeholder="Link"
                            name="link"
                            value={scheduleData.pre_lecture_readings[idx].link}
                            onChange={(e) =>
                              saveTextDataToArrayHandler(
                                e,
                                "pre_lecture_readings",
                                idx
                              )
                            }
                            className="mb-2"
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
              <div className="text-center my-2 w-100">
                <Button
                  variant="outline-light"
                  size="sm"
                  onClick={() => {
                    setScheduleData((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft["pre_lecture_readings"] = Array.isArray(
                          draft.pre_lecture_readings
                        )
                          ? [...draft.pre_lecture_readings]
                          : [];
                        draft.pre_lecture_readings.push({});
                      });
                      return updatedState;
                    });
                  }}
                >
                  Add pre-lecture reading
                </Button>
              </div>

              <Form.Group className="col-md-12 mb-0">
                <Form.Label>Extra reading material</Form.Label>
              </Form.Group>
              <Accordion
                activeKey={extraMaterialKey}
                onSelect={setExtraMaterialKey}
                flush
                className="col-md-12"
              >
                {scheduleData?.extra_reference_material?.map((pre, idx) => {
                  return (
                    <Card key={idx} className="text-dark">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={idx + "ex"}
                        style={{ cursor: "pointer" }}
                        className={`text-white`}
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <span className="font-weight-bold text-uppercase small text-dark">
                              Extra reading material #{idx + 1}
                            </span>
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon={
                                extraMaterialKey === idx + "ex"
                                  ? faAngleUp
                                  : faAngleDown
                              }
                              className="mr-4 text-dark"
                            />
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => {
                                setScheduleData((prev) => {
                                  const updatedState = produce(
                                    prev,
                                    (draft) => {
                                      draft.extra_reference_material.splice(
                                        idx,
                                        1
                                      );
                                    }
                                  );
                                  return updatedState;
                                });
                              }}
                            >
                              X
                            </Button>
                          </div>
                          <div>
                            <span className="small"></span>
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={idx + "ex"}>
                        <Card.Body className={`bg-graphite text-white`}>
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={
                              scheduleData.extra_reference_material[idx].title
                            }
                            onChange={(e) =>
                              saveTextDataToArrayHandler(
                                e,
                                "extra_reference_material",
                                idx
                              )
                            }
                            className="mb-2"
                          />
                          <Form.Label>Link</Form.Label>
                          <Form.Control
                            type="url"
                            placeholder="Link"
                            name="link"
                            value={
                              scheduleData.extra_reference_material[idx].link
                            }
                            onChange={(e) =>
                              saveTextDataToArrayHandler(
                                e,
                                "extra_reference_material",
                                idx
                              )
                            }
                            className="mb-2"
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
              <div className="text-center my-2 w-100">
                <Button
                  variant="outline-light"
                  size="sm"
                  onClick={() => {
                    setScheduleData((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft["extra_reference_material"] = Array.isArray(
                          draft.extra_reference_material
                        )
                          ? [...draft.extra_reference_material]
                          : [];
                        draft.extra_reference_material.push({});
                      });
                      return updatedState;
                    });
                  }}
                >
                  Add Extra reference material
                </Button>
              </div>

              {props.addModuleType === "lecture" ? (
                <>
                  <Form.Group className="col-md-6">
                    <Form.Label>Starts at</Form.Label>
                    <Flatpickr
                      placeholder="Select date"
                      data-enable-time
                      value={scheduleData.start_time}
                      name="start_time"
                      onChange={(selectedDate, dateStr, instance) =>
                        saveDateToStateHandler(selectedDate, dateStr, instance)
                      }
                      options={{ minDate: "today", minuteIncrement: 1 }}
                      className="form-control bg-light"
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Ends at</Form.Label>
                    <Flatpickr
                      placeholder="Select date"
                      data-enable-time
                      value={scheduleData.end_time}
                      name="end_time"
                      onChange={(selectedDate, dateStr, instance) =>
                        saveDateToStateHandler(selectedDate, dateStr, instance)
                      }
                      options={{ minDate: "today", minuteIncrement: 1 }}
                      className="form-control bg-light"
                    />
                  </Form.Group>
                </>
              ) : null}

              {props.addModuleType === "lecture_recording" ? (
                <div className="col-12">
                  <Form.Label>Video Lecture</Form.Label>
                  <div class="input-group mb-3">
                    <div class="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        disabled
                      />
                      <label
                        className="custom-file-label custom-file-label-dark"
                        htmlFor="inputGroupFile01"
                      >
                        Choose file
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              {props.addModuleType === "lecture_recording" ? (
                <Form.Group className="col-md-12">
                  <Form.Label>Video lecture URL</Form.Label>
                  <Form.Control
                    type="url"
                    placeholder="Video lecture URL"
                    name="video_lecture"
                    value={scheduleData.video_lecture}
                    onChange={(e) => saveTextDataToStateHandler(e)}
                  />
                </Form.Group>
              ) : null}
              {props.addModuleType === "lecture_recording" ? (
                <Form.Group className="col-md-12">
                  <Form.Label>Lecture Recording Duration</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Lecture Recording Duration (mins)"
                    name="lecture_recording_duration"
                    value={scheduleData.lecture_recording_duration}
                    onChange={(e) => saveTextDataToStateHandler(e)}
                    className="mb-2"
                  />
                </Form.Group>
              ) : null}

              <Form.Group className="col-md-12">
                <Form.Label>Instructor</Form.Label>
                <Select
                  placeholder="Select Instructor"
                  isMulti
                  options={facultyOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#007bff",
                      neutral0: "#343a40;",
                      neutral10: "#6c757d",
                      neutral80: "#ffffff",
                    },
                  })}
                  // value={selectedFacultyList}
                  onChange={(e) => {
                    setInstructors((prev) => {
                      return e.map((fac) => fac.value);
                    });
                  }}
                ></Select>
              </Form.Group>
              {/* {isUpdating ? (
              <Form.Group className="col-md-12">
                <Form.Label>Lecture Recording</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Lecture Recording"
                  name="lecture_recording"
                  value={scheduleData?.lecture_recording}
                  onChange={(e) => {
                    setScheduleData((prev) => {
                      const updatedState = produce(prev, (draft) => {
                        draft[e.target.name] = e.target.value;
                      });
                      return updatedState;
                    });
                  }}
                />
              </Form.Group>
            ) : null} */}
            </div>

            {!isUpdating ? (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  if (timeValidation(5000)) {
                    addScheduleToDBHandler(scheduleData);
                  } else {
                    toast.error(
                      "Module end time should be at least 5 minutes from the start time",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      }
                    );
                  }
                }}
                disabled={saving}
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  if (timeValidation(5000)) {
                    updateScheduleToDBHandler(scheduleData);
                  } else {
                    toast.error(
                      "Module end time should be at least 5 minutes from the start time",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      }
                    );
                  }
                }}
                disabled={saving}
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Update"}
              </Button>
            )}
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default memo(connect(mapStateToProps)(AddModuleModal));
