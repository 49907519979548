import {
  SET_LANGUAGE,
  SET_CLINIC_DETAILS,
  SET_OFFICE_DETAILS,
  SET_COURSE,
  SET_COURSE_MODULE,
  SET_COURSE_SUB_MODULE,
  SET_PROJECT,
  SET_BLOG,
  SET_SHOW_NOTEAPP,
  SET_APP_PAGE,
  SET_SHOW_WHITEBOARD,
  SAVE_WHITEBOARD_LAYERS,
  SAVE_WHITEBOARD_BACKGROUND,
  SET_OFFICE_ID,
  SET_CLINIC_ID,
  SET_COURSE_ID,
  SET_COURSE_DETAILS,
  SET_EVENT_ID,
  SET_COURSE_SECTION,
  SET_DARK_THEME,
  SET_GRAPH_ID,
  SET_ERRC_ID,
  SET_FACTORS,
  SET_PRODUCTS,
  SET_PRODUCTS_TO_BE,
  SET_ASIS_FACTORS,
  SET_BUYER_ROWS,
  SET_BUYER_HEADER,
  SET_SIX_PATH_HEADER,
  SET_SIX_PATH_ROWS,
  SET_CURRENT_EVENT,
  SET_CURRENT_USER,
  SET_MY_EVENTS,
  SET_MY_BLOGS,
} from "./actionTypes";

export const setMyEvents = (value) => {
  return {
    type: SET_MY_EVENTS,
    payload: {
      myEvents: value,
    },
  };
};

export const setMyBlogs = (value) => {
  return {
    type: SET_MY_BLOGS,
    payload: {
      myBlogs: value,
    },
  };
};

export const setCurrentEvent = (value) => {
  return {
    type: SET_CURRENT_EVENT,
    payload: {
      currentEvent: value,
    },
  };
};

export const setCurrentUser = (value) => {
  return {
    type: SET_CURRENT_USER,
    payload: {
      currentUser: value,
    },
  };
};

export const setShowNoteApp = (value) => {
  return {
    type: SET_SHOW_NOTEAPP,
    payload: {
      showNoteApp: value,
    },
  };
};

export const setDarkTheme = (value) => {
  return {
    type: SET_DARK_THEME,
    payload: {
      darkTheme: value,
    },
  };
};

export const changeLanguage = (languageNow = "EN") => ({
  type: SET_LANGUAGE,
  payload: {
    currentLanguage: languageNow,
  },
});

export const setClinicDetails = (clinicDetails) => ({
  type: SET_CLINIC_DETAILS,
  payload: {
    clinicDetails: clinicDetails,
  },
});

export const setCourse = (course_data) => ({
  type: SET_COURSE,
  payload: {
    course: course_data,
  },
});

export const setCourseSection = (course_section) => ({
  type: SET_COURSE_SECTION,
  payload: {
    courseSection: course_section,
  },
});

export const setCourseModule = (course_module_data) => ({
  type: SET_COURSE_MODULE,
  payload: {
    course_module: course_module_data,
  },
});

export const setCourseSubModule = (course_sub_module_data) => ({
  type: SET_COURSE_SUB_MODULE,
  payload: {
    course_sub_module: course_sub_module_data,
  },
});

export const setOfficeDetails = (officeDetails) => ({
  type: SET_OFFICE_DETAILS,
  payload: {
    officeDetails: officeDetails,
  },
});

export const setCourseDetails = (courseDetails) => ({
  type: SET_COURSE_DETAILS,
  payload: {
    courseDetails: courseDetails,
  },
});

export const setProject = (project_data) => ({
  type: SET_PROJECT,
  payload: {
    project: project_data,
  },
});

export const setAppPage = (app_data) => ({
  type: SET_APP_PAGE,
  payload: {
    app: app_data,
  },
});

export const setBlog = (blog_data) => ({
  type: SET_BLOG,
  payload: {
    blog: blog_data,
  },
});

export const setShowWhiteboard = (value) => {
  return {
    type: SET_SHOW_WHITEBOARD,
    payload: {
      showWhiteboard: value,
    },
  };
};

export const saveWhiteboardLayers = (value) => {
  return {
    type: SAVE_WHITEBOARD_LAYERS,
    payload: {
      layers: value,
    },
  };
};

export const saveWhiteboardBackground = (value) => {
  return {
    type: SAVE_WHITEBOARD_BACKGROUND,
    payload: {
      background: value,
    },
  };
};

export const setOfficeID = (value) => {
  return {
    type: SET_OFFICE_ID,
    payload: {
      officeId: value,
    },
  };
};

export const setClinicID = (value) => {
  return {
    type: SET_CLINIC_ID,
    payload: {
      clinicId: value,
    },
  };
};

export const setCourseID = (value) => {
  return {
    type: SET_COURSE_ID,
    payload: {
      courseId: value,
    },
  };
};
export const setEventID = (value) => {
  return {
    type: SET_EVENT_ID,
    payload: {
      eventId: value,
    },
  };
};

export const setGraphId = (id) => ({
  type: SET_GRAPH_ID,
  payload: {
    graphId: id,
  },
});

export const setErrcId = (id) => ({
  type: SET_ERRC_ID,
  payload: {
    errcId: id,
  },
});

export const setFactors = (data) => ({
  type: SET_FACTORS,
  payload: {
    factors: data,
  },
});

export const setProducts = (data) => ({
  type: SET_PRODUCTS,
  payload: {
    products: data,
  },
});

export const setProductsToBe = (data) => ({
  type: SET_PRODUCTS_TO_BE,
  payload: {
    productsToBe: data,
  },
});

export const setAsIsFactors = (data) => ({
  type: SET_ASIS_FACTORS,
  payload: {
    asisFactors: data,
  },
});

export const setBuyerHeader = (data) => ({
  type: SET_BUYER_HEADER,
  payload: {
    buyerHeaders: data,
  },
});

export const setBuyerRows = (data) => ({
  type: SET_BUYER_ROWS,
  payload: {
    buyerRows: data,
  },
});

export const setSixPathHeader = (data) => ({
  type: SET_SIX_PATH_HEADER,
  payload: {
    sixPathHeaders: data,
  },
});

export const setSixPathRows = (data) => ({
  type: SET_SIX_PATH_ROWS,
  payload: {
    sixPathRows: data,
  },
});
