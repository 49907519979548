import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCs7Q7b4gb27vrCFw-ZHziu5s2t1xuIpKo",
//   authDomain: "igesia-v1.firebaseapp.com",
//   databaseURL: "https://igesia-v1.firebaseio.com",
//   projectId: "igesia-v1",
//   storageBucket: "igesia-v1.appspot.com",
//   messagingSenderId: "971790760240",
//   appId: "1:971790760240:web:038d94c462e78096c1b0f5",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDmaKKLmqVg8uyjUx_OfaOttZwDRU6XBl0",
  authDomain: "igesia-94de2.firebaseapp.com",
  databaseURL: "https://igesia-94de2.firebaseio.com",
  projectId: "igesia-94de2",
  storageBucket: "igesia-94de2.appspot.com",
  messagingSenderId: "415922997581",
  appId: "1:415922997581:web:47bdf5fd3fa266f5134f04"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//igesia-v1
//npm run build;  firebase deploy --project igesia-v1

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const FIRESTORE_SERVER_TIME =
  firebase.firestore.FieldValue.serverTimestamp();
export default firebase;
