import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import { memo, useState } from "react";
import axios from "axios";
import { API_BASE_URL, ASSIGNMENT_CREATE } from "../../../../config";
import {
  Accordion,
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import firebase from "firebase";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const AssignmentFields = (props) => {
  const [questions, setQuestions] = useState([]);
  const [questionDescription, setQuestionDescription] = useState([]);
  const [assignmentSubject, setAssignmentSubject] = useState("");
  const [assignmentDescription, setAssignmentDescription] = useState("");
  const [saving, setSaving] = useState(false);
  const [currentActiveKey, setCurrentActiveKey] = useState("1");

  const addAssignment = async () => {
    // console.log(questions, questionDescription, assignmentSubject, assignmentDescription)

    let questionsArrayWithObjects = [];
    for (let i = 0; i < questions.length; i++) {
      let json = {
        question: questions[i],
        description: questionDescription[i],
        questionId: i + 1,
      };
      questionsArrayWithObjects.push(json);
    }

    try {
      let res = await axios.post(`${API_BASE_URL}${ASSIGNMENT_CREATE}`, {
        user_id: "1gHTSDwzWRPW7KAUeTcscDFxs7z2",
        workspace_id: "LgT2sjzoZQwwKMGPnob2",
        room_id: "nqae8GPum5kRPKe9ukud",
        deadline: false,
        assignment_subject: assignmentSubject,
        assignment_description: assignmentDescription,
        questions: questionsArrayWithObjects,
        all_participants: true,
        assigned: [],
        folderId: null,
        save_for_later: false,
        is_graded: false,
      });
      const size = (
        await firebase
          .firestore()
          .collection(
            `/courses/${props.courseId}/sections/${props.sectionId}/modules`
          )
          .get()
      ).docs.length;

      await firebase
        .firestore()
        .collection(
          `/courses/${props.courseId}/sections/${props.sectionId}/modules`
        )
        .doc()
        .set(
          {
            title: assignmentSubject,
            content_id: res.data.data.assignment_id,
            section: props.sectionId,
            order_index: size,
            type:
              props.addModuleType === "poll"
                ? 3
                : props.addModuleType === "quiz"
                ? 4
                : props.addModuleType === "assignment"
                ? 2
                : props.addModuleType === "lecture_recording"
                ? 1
                : 0,
          },
          {
            merge: true,
          }
        );
      toast.success("Assignment added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error("Error : ", e);
      toast.error("Error", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };

  // try {
  //   setSaving(true);
  //   const assignmentData = {
  //     assignmentSubject,
  //     assignmentDescription,
  //     questions,
  //     all: true,
  //     assignedTo: [],
  //     createdAt: new Date(),
  //     createdBy: "",
  //     deadline: false,
  //     folderId: null,
  //     isGraded: false,
  //     timestamp: Date.now(),
  //   };
  //   const size = (
  //     await firebase
  //       .firestore()
  //       .collection(
  //         `/courses/hdCKh1GocgVH6v2zLaGn/sections/${props.sectionId}/modules`
  //       )
  //       .get()
  //   ).docs.length;

  // await firebase
  //   .firestore()
  //   .collection(
  //     `/courses/hdCKh1GocgVH6v2zLaGn/sections/${props.sectionId}/modules`
  //   )
  //   .doc()
  //   .set(
  //     {
  //       assignmentData: assignmentData,
  //       section: props.sectionId,
  //       order_index: size,
  //       type:
  //         props.addModuleType === "poll"
  //           ? 3
  //           : props.addModuleType === "quiz"
  //             ? 4
  //             : props.addModuleType === "assignment"
  //               ? 2
  //               : props.addModuleType === "lecture_recording"
  //                 ? 1
  //                 : 0,
  //     },
  //     {
  //       merge: true,
  //     }
  //   );
  //   toast.success("Assignment added successfully", {
  //     position: toast.POSITION.TOP_CENTER,
  //     hideProgressBar: true,
  //     autoClose: 3000,
  //   });
  // } catch (e) {
  //   console.error("Error : ", e);
  //   toast.error("Error", {
  //     position: toast.POSITION.TOP_CENTER,
  //     hideProgressBar: true,
  //     autoClose: 3000,
  //   });
  // } finally {
  //   setSaving(false);
  //   props.onHide();
  // }

  // HANDLES QUESTIONS ADDED
  const handleQuestions = (e, key) => {
    let prev = [...questions];
    prev[key] = e.target.value;
    setQuestions(prev);
  };

  // HANDLES DESCRIPTION ADDED
  const handleDescriptions = (e, key) => {
    let prev = [...questionDescription];
    prev[key] = e.target.value;
    setQuestionDescription(prev);
  };

  const addQuestion = () => {
    let prev = [...questions];
    prev[prev.length] = "";
    setQuestions(prev);
    setCurrentActiveKey(prev.length + 1);

    let prevDescriptions = [...questionDescription];
    prevDescriptions[prevDescriptions.length] = "";
    setQuestionDescription(questionDescription);

    // let prevWeights = [...this.props.weights];
    // prevWeights[prevWeights.length] = 0;
    // this.props.setAssignmentWeights(prevWeights);
  };
  return (
    <Form className="bg-dark">
      <div className="rounded-lg p-2 m-2 bg-card-theme">
        <p className="mb-2 font-weight-bold">Assignment</p>
        <div className="mb-3">
          <InputGroup className="mb-1">
            <FormControl
              placeholder="Assignment Subject"
              aria-label="Assignment Subject"
              aria-describedby={"Subject"}
              name="AssignmentSubject"
              autoFocus={true}
              required={true}
              value={assignmentSubject}
              onChange={(e) => {
                setAssignmentSubject(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup className="mb-1">
            <FormControl
              placeholder="Assignment Description"
              aria-label="Assignment Description"
              aria-describedby={"Description"}
              name="AssignmentDescription"
              required={true}
              value={assignmentDescription}
              onChange={(e) => {
                setAssignmentDescription(e.target.value);
              }}
            />
          </InputGroup>
        </div>
        <Accordion
          activeKey={`${currentActiveKey}`}
          defaultActiveKey="1"
          className="bg-dark"
        >
          {questions.length > 0
            ? questions.map((question, key) => (
                <>
                  <Card className="bg-dark my-1">
                    <div className="d-flex ">
                      <Accordion.Toggle
                        eventKey={`${key + 1}`}
                        as={Card.Header}
                        variant="link"
                        className="flex-grow-1 p-2 pointer border-bottom-0 bg-dark"
                        onClick={() => {
                          if (currentActiveKey === key + 1)
                            setCurrentActiveKey(null);
                          else setCurrentActiveKey(key + 1);
                        }}
                      >
                        <p className="mb-0 flex-grow-1 small font-weight-bold">
                          Question {key + 1}
                        </p>
                      </Accordion.Toggle>
                      {/* <div
                        className="mt-1 mr-1 pointer"
                        title="Delete"
                        onClick={() =>
                          setQuestions((prev) => {
                            const updatedState = produce(prev, (draft) => {
                              draft.splice(key, 1);
                            });
                            return updatedState;
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} title="Delete" />
                      </div> */}
                    </div>
                    <>
                      <Accordion.Collapse eventKey={`${key + 1}`}>
                        <Card.Body className="pt-1 px-2">
                          <InputGroup className="mb-1">
                            <FormControl
                              placeholder="Question"
                              aria-label="Question"
                              aria-describedby={"question" + key}
                              as="textarea"
                              name="questions[]"
                              autoFocus={questions.length === key + 1}
                              id={key}
                              value={questions[key]}
                              onChange={(e) => handleQuestions(e, key)}
                              required={true}
                            />
                          </InputGroup>
                          <InputGroup className="mb-1">
                            <FormControl
                              placeholder="Description (optional)"
                              aria-label="Description"
                              aria-describedby={"Description" + key}
                              as="textarea"
                              name="descriptions[]"
                              id={key}
                              value={questionDescription[key]}
                              onChange={(e) => handleDescriptions(e, key)}
                              required={true}
                            />
                          </InputGroup>
                        </Card.Body>
                      </Accordion.Collapse>
                    </>
                  </Card>
                </>
              ))
            : null}
        </Accordion>
        <InputGroup className="w-100 mt-3">
          <Button
            size="sm"
            variant="outline-primary"
            className="w-100"
            onClick={() => addQuestion()}
          >
            + <small>Add Question</small>
          </Button>
        </InputGroup>
        {/* 
        <InputGroup className="w-100 mt-3">
          <Button
            size="sm"
            variant="outline-light"
            className="w-100"
            onClick={() => {
              setQuestions((prev) => {
                const updatedState = produce(prev, (draft) => {
                  draft.push({});
                });
                return updatedState;
              });
            }}
          >
            + Add Question
          </Button>
        </InputGroup> */}
      </div>

      <div className="m-2">
        <Button
          variant="primary"
          style={{ width: "100px", marginRight: "5px" }}
          size="sm"
          disabled={saving}
          onClick={() => {
            addAssignment();
          }}
        >
          {saving ? (
            <>
              <Spinner size="sm" animation="border" color="white" /> Adding
            </>
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default memo(connect(mapStateToProps)(AssignmentFields));
