import React, { memo } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function Schedule(props) {
  // CONSTANTS
  const { resource_details, sub_module_details } = props;

  // STATES
  // FUNCTIONS

  return (
    <div>
      <h5 className="font-weight-bold">{resource_details?.EN?.title}</h5>
      <div className="d-flex justify-content-between">
        <p>{moment(resource_details?.starts_at).format("LLLL")}</p>
        <a className="btn btn-sm btn-light mb-2" target="_blank" href={resource_details?.meeting_url}>Join</a>
      </div>
      <span className="d-block mb-3">{resource_details?.EN?.description}</span>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <div className="input-group-text"><FontAwesomeIcon icon={faCopy} /></div>
        </div>
        <input type="text" className="form-control" id="inlineFormInputGroup" placeholder={resource_details?.meeting_url} />
      </div>

      {/* <p>3. meeting_url: {resource_details?.meeting_url}</p>

      <p>1. starts_at: {moment(resource_details?.starts_at).format("LLLL")}</p>
      <p>2. ends_at: {moment(resource_details?.ends_at).format("LLLL")}</p>
      <p>3. meeting_url: {resource_details?.meeting_url}</p>
      <p>4. created_at: {resource_details?.created_at}</p>
      <p>5. category: {resource_details?.category}</p>
      <p>
        6. assignedToAll: {resource_details?.assignedToAll ? "True" : "False"}
      </p>
      <p>7. description: {resource_details?.EN?.description}</p>
      <p>8. title: {resource_details?.EN?.title}</p> */}
    </div>
  );
}

export default memo(Schedule);
