import {
  faEdit,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";
import { memo, useEffect, useState } from "react";
import { Accordion, Button, ButtonGroup, Card, Form } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import ModuleAccordion from "./ModuleAccordion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Skeleton } from "@material-ui/lab";
import { connect } from "react-redux";

const CurriculumCard = (props) => {
  // const [hoverable, hovered] = useHover(CurriculumCard);
  // const ref = useRef(null);
  // const isHovering = useHoverDirty(ref);
  // modules
  const {
    currentSection,
    setCurrentSection,
    courseCurriculum,
    setShowAddModuleButton,
    setSelectedSection,
    showAddModuleButton,
    setShowAddModuleModal,
    setAddModuleType,
  } = props;
  const [modulesSnapshot, modulesLoading, errorModules] = useCollection(
    firebase
      .firestore()
      .collection(`/courses/${props.courseId}/sections/${props.id}/modules`)
  );

  const [modulesArray, setModulesArray] = useState([]);

  useEffect(() => {
    setModulesArray(() => {
      let newState = modulesSnapshot?.docs?.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      newState = newState?.sort((a, b) => a.order_index - b.order_index);
      return newState;
    });
  }, [modulesSnapshot]);

  // const updateModule = (modules = []) => {
  //   modules.forEach((mod) => {
  //     firebase
  //       .firestore()
  //       .collection(`/courses/${props.courseId}/sections/${props.id}/modules`)
  //       .doc(mod.id)
  //       .update({
  //         title:
  //           mod.topic ??
  //           mod.assignment_subject ??
  //           mod.quiz_name ??
  //           mod.poll_title ??
  //           null,
  //         content_id: mod.quiz_id ?? mod.poll_id ?? mod.assignment_id ?? null,
  //       });
  //   });
  // };

  // useEffect(() => {
  //   if (modulesArray?.length) {
  //     console.log("func called");
  //     updateModule(modulesArray);
  //   }
  // }, [modulesArray]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let items = reorder(
      modulesArray,
      result.source.index,
      result.destination.index
    );
    items = items
      .map((item, idx) => ({ ...item, order_index: idx }))
      .sort((a, b) => a.order_index - b.order_index);
    updateOrderInDB(items);
    setModulesArray(items);
  };

  const updateOrderInDB = async (items) => {
    try {
      items.map(async (item, idx) => {
        firebase
          .firestore()
          .collection(`/courses/${props.courseId}/sections/${props.id}/modules`)
          .doc(item.id)
          .update({
            order_index: idx,
          });
      });
    } catch (e) {
    } finally {
    }
  };

  return (
    <Accordion
      flush
      className="col-md-12 my-3"
      activeKey={currentSection}
      onSelect={setCurrentSection}
    >
      <Card className="bg-dark text-light rounded shadow-sm my-4">
        <Accordion.Toggle
          as={Card.Header}
          eventKey={props.index + "section"}
          style={{ cursor: "pointer" }}
          className={`text-white bg-dark border-0`}
        >
          <div className="d-flex justify-content-between align-items-center">
            {!courseCurriculum[props.index].editing ? (
              <p>{courseCurriculum[props.index]?.title}</p>
            ) : null}
            <div className="d-flex">
              <Button
                variant="link"
                size="sm"
                className="text-light"
                onClick={() => {}}
              >
                <FontAwesomeIcon
                  className="mx-3"
                  icon={
                    courseCurriculum[props.index]["editing"] ? faTimes : faEdit
                  }
                ></FontAwesomeIcon>
              </Button>
              <Button
                variant="link"
                size="sm"
                className="text-light"
                onClick={() => {}}
              >
                <FontAwesomeIcon
                  className="mx-3"
                  icon={faTrash}
                  onClick={() => {}}
                ></FontAwesomeIcon>
              </Button>
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={props.index + "section"}>
          <Card.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Accordion flush className="col-md-12 my-3">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {modulesArray
                          ?.filter((module) => module.section === props.id)
                          ?.sort((a, b) => a.order_index - b.order_index)
                          ?.map((mod, idx) => {
                            return (
                              <Draggable
                                key={idx}
                                draggableId={idx + "module"}
                                index={idx}
                              >
                                {(provided, snapshot) => (
                                  <ModuleAccordion
                                    ref={provided.innerRef}
                                    propsRef={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={idx}
                                    idx={idx}
                                    module={mod}
                                  />
                                )}
                              </Draggable>
                            );
                          })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Accordion>

              <Button
                variant="outline-light"
                className="rounded-circle"
                style={{ height: 30, width: 30 }}
                size="sm"
                onClick={() => {
                  setShowAddModuleButton((prev) => {
                    const updatedState = produce(prev, (draft) => {
                      draft[props.index] = !draft[props.index];
                    });
                    return updatedState;
                  });
                  setSelectedSection(props.id);
                }}
                title="Add module"
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={showAddModuleButton[props.index] ? faTimes : faPlus}
                ></FontAwesomeIcon>
              </Button>
              {showAddModuleButton[props.index] ? (
                <ButtonGroup aria-label="Select module" className="my-3">
                  <Button
                    variant="outline-light"
                    size="sm"
                    name="lecture"
                    onClick={(e) => {
                      setShowAddModuleModal(true);
                      setAddModuleType(e.target.name);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                    Lecture
                  </Button>
                  <Button
                    variant="outline-light"
                    size="sm"
                    name="lecture_recording"
                    onClick={(e) => {
                      setShowAddModuleModal(true);
                      setAddModuleType(e.target.name);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                    Lecture Recording
                  </Button>
                  <Button
                    variant="outline-light"
                    size="sm"
                    name="assignment"
                    onClick={(e) => {
                      setShowAddModuleModal(true);
                      setAddModuleType(e.target.name);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                    Assignment
                  </Button>
                  <Button
                    variant="outline-light"
                    size="sm"
                    name="poll"
                    onClick={(e) => {
                      setShowAddModuleModal(true);
                      setAddModuleType(e.target.name);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                    Poll
                  </Button>
                  <Button
                    variant="outline-light"
                    size="sm"
                    name="quiz"
                    onClick={(e) => {
                      setShowAddModuleModal(true);
                      setAddModuleType(e.target.name);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                    Quiz
                  </Button>
                </ButtonGroup>
              ) : null}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default memo(connect(mapStateToProps)(CurriculumCard));
