import React, { memo } from "react";
import moment from "moment";

function Assignment(props) {
  // CONSTANTS
  const { resource_details, sub_module_details } = props;

  // STATES
  // FUNCTIONS

  return (
    <div>
      <div>
        <p>1. isGraded: {resource_details?.isGraded ? "True" : "False"}</p>
        <p>2. createdBy: {resource_details?.createdBy}</p>
        <p>
          3. all:{" "}
          {resource_details?.all ? "Assigned to all" : "Not assigned to all"}
        </p>
        <p>4. assignedTo: {JSON.stringify(resource_details?.assignedTo)}</p>
        <p>
          5. timestamp: {moment(resource_details?.timestamp).format("LLLL")}
        </p>
        <p>6. deadline: {moment(resource_details?.deadline).format("LLLL")}</p>
        <p>7. assignmentSubject: {resource_details?.assignmentSubject}</p>
        <p>
          8. assignmentDescription: {resource_details?.assignmentDescription}
        </p>
        <p>9. questions: </p>
        <div className="ml-3">
          {resource_details?.questions
            ?.sort((a, b) => a.questionId - b.questionId)
            ?.map((val, key) => (
              <div>
                <p key={key}>
                  Q{val.questionId}. {val.question} ({val?.weight} marks)
                  (Description: {val.description})
                </p>
              </div>
            ))}
        </div>
      </div>
      {/* <div>
              {Object.entries(
                resource_details
              )?.map((item, idx) => (
                <p>
                  {idx + 1}. {item[0]}: {JSON.stringify(item[1])}
                </p>
              ))}
              <br />
              {Object.entries(
                sub_module_details
              )?.map((item, idx) => (
                <p>
                  {idx + 1}. {item[0]}: {JSON.stringify(item[1])}
                </p>
              ))}
            </div> */}
    </div>
  );
}

export default memo(Assignment);
