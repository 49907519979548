import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import firebase from "firebase";

import { API_BASE_URL, JITSI_MEETING } from "../../../config";
import { postData } from "../../../utils/index";
import { Spinner } from "react-bootstrap";

function Video(props) {
  const {
    darkTheme,
    categoryId,
    event_id,
    audioMute,
    videoMute,
    setOpenMeeting,
    openMeeting,
    isAdmin,
    currentEvent,
  } = props;

  const [starting, setStarting] = useState(true);
  const [jitsiLoading, setJitsiLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);

  const jitsiDoc = useRef(null);
  const jitsiContainerStyle = {
    display: jitsiLoading ? "none" : "block",
    width: "100%",
    height: "400px",
    // height: true ? "97%" : "100%",
  };

  const getAuthToken = async () => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      return idToken;
    } catch (e) {
      console.error(e, "error in getting auth token");
      return 0;
    }
  };

  const getHeaders = async () => {
    return {
      "content-type": "application/json",
      authorization: await getAuthToken(),
    };
  };

  async function startConference() {
    try {
      const headers = await getHeaders();

      await postData("https://dev.igesia.com/data/push", {
        point_type: "conference.start_request",
        data_properties: {
          event_id: event_id,
          category_id: categoryId,
          email: firebase.auth().currentUser.email,
          is_admin: isAdmin,
        },
      });

      let roomConfigData = {
        clinic_id: event_id,
        appointment_id: `${categoryId}`,
        is_admin: isAdmin,
      };

      const response = await axios.post(
        `${API_BASE_URL}${JITSI_MEETING}`,
        roomConfigData,
        {
          headers: headers,
        }
      );

      const {
        serverURL,
        roomName,
        height,
        parentNodeRef,
        jwt,
        noSsl,
        noticeMessage,
        enableUserRolesBasedOnToken,
        interfaceConfigOverwrite,
        configOverwrite,
        userInfo,
      } = response.data.data;

      let domain = currentEvent?.domain ?? serverURL.replace("https://", "");

      let OLD_TOOLBAR_BUTTONS = interfaceConfigOverwrite.TOOLBAR_BUTTONS;

      let NEW_TOOLBAR_BUTTONS =
        // isAdmin
        //   ? ["hangup", "fullscreen", "livestreaming", ...OLD_TOOLBAR_BUTTONS]
        //   :
        ["hangup", "fullscreen", ...OLD_TOOLBAR_BUTTONS];

      let newInterfaceConfigOverwrite = {
        ...interfaceConfigOverwrite,
        TOOLBAR_BUTTONS: NEW_TOOLBAR_BUTTONS,
      };

      let options = {
        roomName,
        height,
        parentNode: document.getElementById(`${parentNodeRef}`),
        jwt,
        noSsl,
        noticeMessage,
        enableUserRolesBasedOnToken,
        interfaceConfigOverwrite: true
          ? newInterfaceConfigOverwrite
          : interfaceConfigOverwrite,
        configOverwrite,
        userInfo,
      };

      if (jwt) {
        setWaiting(false);
      } else {
        setStarting(false);
        setWaiting(true);
      }

      const api = new window.JitsiMeetExternalAPI(domain, options);

      api.executeCommand("subject", `${props?.group_name}`);

      if (audioMute) {
        api.executeCommand("toggleAudio");
      }
      if (videoMute) {
        setTimeout(() => api.executeCommand("toggleVideo"), 500);
      }

      api.executeCommand("toggleTileView");

      api.addEventListener("videoConferenceJoined", (data) => {
        console.log(data);

        setStarting(false);
        setWaiting(false);
        setJitsiLoading(false);

        // Event- Suspend Detected
        api.on("suspendDetected", (data) => {
          console.log("Suspend Detected Event:", data);
        });
        api.on("readyToClose", (data) => {
          console.log("hangup");
          setOpenMeeting(!openMeeting);
        });
      });
    } catch (error) {
      setStarting(false);
      setWaiting(false);
      console.error("Failed to load Video API", error);
    }
  }

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      startConference();
    }
  }, []);

  return (
    <React.Fragment>
      {waiting && (
        <div
          style={{ width: "100%", height: "400px" }}
          className={
            darkTheme
              ? "border shadow-sm bg-dark text-white d-flex align-items-center justify-content-center rounded"
              : "border shadow-sm bg-white text-black d-flex align-items-center justify-content-center rounded"
          }
        >
          <div className="text-center">
            <Spinner animation="border" size="sm" />
            <p className="lead">
              <b>Waiting for admin to start meeting</b>
            </p>
          </div>
        </div>
      )}
      {starting && (
        <div
          style={{ width: "100%", height: "400px" }}
          className={
            darkTheme
              ? "border shadow-sm bg-dark text-white d-flex align-items-center justify-content-center rounded"
              : "border shadow-sm bg-white text-black d-flex align-items-center justify-content-center rounded"
          }
        >
          <div className="text-center">
            <Spinner animation="border" size="sm" />
            <p className="lead">
              <b>Fetching session details</b>
            </p>
          </div>
        </div>
      )}
      <div ref={jitsiDoc} id="video-screen" style={jitsiContainerStyle}>
        <div id="jitsi-container" style={jitsiContainerStyle}>
          {jitsiLoading && <p className="text-center">Connecting...</p>}
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  currentUser: state.currentUser,
  currentEvent: state.currentEvent,
  darkTheme: state.darkTheme,
}))(Video);
