import React from "react";
import firebase from "firebase";
import { Spinner } from "react-bootstrap";
import "flatpickr/dist/themes/material_green.css";
import parse from "html-react-parser";
import { useDocument } from "react-firebase-hooks/firestore";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import { languageText } from "../language.json";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import EventChat from "./chat";

const PreviewBooth = (props) => {
  const { currentLanguage } = props;
  const { boothslug } = useParams();
  const [boothSnapshot, loading, error] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${props.currentEvent.id}/booths`)
      .doc(boothslug)
  );
  const booth = boothSnapshot?.data();
  console.log(booth, "booth");
  if (loading) return <Spinner />;
  return (
    <div className="h-100  montserrat-font">
      <LeftBar mobile />

      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 pb-5"
            style={{ overflow: "scroll", backgroundColor: "#F7F7F7" }}
          >
            {/* BoothName */}
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="d-flex mb-3 align-items-center">
                  {booth?.company_logo && (
                    <div
                      style={{
                        background: `url(${booth?.company_logo})  center center / cover no-repeat`,
                        height: "40px",
                        width: "40px",
                      }}
                      className="rounded-circle mr-3"
                    />
                  )}
                  {booth?.booth_name && (
                    <h3 className="text-s-6 text-dark mb-0">
                      {booth?.booth_name ? booth?.booth_name : "Booth name"}
                    </h3>
                  )}
                </div>
                {booth?.meet_link && (
                  <a
                    href={booth.meet_link}
                    target="_blank"
                    type="button"
                    className="btn btn-outline-primary btn-sm mr-2"
                  >
                    {languageText[`${currentLanguage}`].meetNow}
                  </a>
                )}
                {booth?.brochure && (
                  <a
                    href={booth.brochure}
                    type="button"
                    className="btn btn-outline-primary btn-sm mr-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {languageText[`${currentLanguage}`].downloadBrochure}
                  </a>
                )}
              </div>
              <div className="col-md-12 mb-3 bg-white">
                {booth?.featured_image ? (
                  <img
                    className="img-responsive rounded-12 w-100"
                    src={booth.featured_image}
                    alt="featured_image"
                  />
                ) : null}
                <p className="rounded-12 bg-white p-3 mb-3 mb-0 text-dark mt-2">
                  {booth.small_description ? booth.small_description : null}
                </p>
                <p className="rounded-12 bg-white text-dark p-3 mb-3">
                  {parse(booth.description)}
                </p>
                {booth?.broadcast_link && Array.isArray(booth.broadcast_link) ? (
                  <>
                    {booth?.broadcast_link.map((link, index) => (
                      <div className="mb-3 embed-responsive embed-responsive-16by9">
                        <iframe
                          title="booth-video"
                          key={index}
                          title="broadcast"
                          className="embed-responsive-item"
                          width="100%"
                          src={link}
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ))}
                  </>
                ) : booth?.broadcast_link ? (
                  <div className="mb-3 embed-responsive embed-responsive-16by9">
                    <iframe
                      title="booth-video"
                      className="embed-responsive-item"
                      width="100%"
                      src={booth.broadcast_link}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
            style={{ overflow: "scroll" }}
          >
            <EventChat />
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect((state) => ({ 
    currentLanguage: state.currentLanguage, 
    currentEvent: state.currentEvent
}))(
  PreviewBooth
);