import React, { useRef, useState, useEffect } from "react";
import { Card, InputGroup, Form } from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch, faLock } from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";

import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import Avatar from "react-avatar";
import { SAMPLE_ROOMS } from "../../data";
import Chat from "./Chat";
import CourseRedux from "../CourseRedux";

function Rooms({ currentLanguage, changeLanguage, course }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [listOfRooms, setListOfRooms] = useState([]);
  useEffect(() => {
    if (course?.roomList) {
      setListOfRooms(course?.roomList);
    } else {
      setListOfRooms(SAMPLE_ROOMS);
    }
  }, [course]);

  return (
    <>
      <CourseRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <h2 className="font-weight-bold text-white">Rooms</h2>

                  <div className="mt-3 mt-md-4 ">
                    <div className="">
                      <InputGroup className="my-2 outerPill">
                        <div className="d-flex align-items-center rounded-lg flex-grow-1  bg-dark">
                          <div className="py-2 pr-2 pl-3">
                            <FontAwesomeIcon
                              icon={faSearch}
                              size="sm"
                              color="#aeaeae"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <Form.Control
                              type="text"
                              id="participantsSearch"
                              className="border-0 px-0 bg-dark text-white rounded"
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                                const reg = _.escapeRegExp(searchTerm);
                                const regex = new RegExp(reg, "gi");
                                setRegex(regex);
                              }}
                              placeholder={"Search"}
                              value={searchTerm}
                              style={{ margin: 0 }}
                            />
                          </div>
                          {searchTerm.length > 0 && (
                            <div
                              className="py-2 pl-2 pr-3"
                              onClick={() => setSearchTerm("")}
                            >
                              <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                            </div>
                          )}
                        </div>
                      </InputGroup>

                      <div className="mb-3 mb-md-5">
                        {/* Rooms start */}
                        <div className="mb-3 mb-md-5">
                          {searchTerm && searchTerm.length > 0
                            ? listOfRooms
                                .filter((item) => {
                                  return item.roomName?.match(regex);
                                })
                                .map((room, key) => (
                                  <Card
                                    className="bg-dark text-white shadow-sm mb-2"
                                    key={key}
                                  >
                                    <Card.Body className="d-flex align-items-center">
                                      <div className="mr-2">
                                        <div
                                          className="shadow-sm rounded-circle"
                                          style={{
                                            backgroundImage: `url(${room.roomPic})`,
                                            height: 50,
                                            width: 50,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        />
                                      </div>
                                      <div className="flex-grow-1">
                                        <p className="text-white mb-0 font-weight-bold">
                                          {room.roomType === 1 && (
                                            <FontAwesomeIcon
                                              icon={faLock}
                                              className="text-success"
                                              size="sm"
                                            />
                                          )}{" "}
                                          {room.roomName}
                                        </p>
                                        <p className="text-light mb-0 small">
                                          <b>
                                            {room.lastMessage?.user?.name}:{" "}
                                          </b>
                                          {room.lastMessage?.content}
                                        </p>
                                      </div>
                                      <div>
                                        <a
                                          href={`https://console.igesia.co/${room.inviteCode}`}
                                          target="_blank"
                                          className="rounded-sm btn btn-sm btn-outline-light"
                                        >
                                          Join room
                                        </a>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                ))
                            : listOfRooms.map((room, key) => (
                                <Card
                                  className="bg-dark text-white shadow-sm mb-2"
                                  key={key}
                                >
                                  <Card.Body className="d-flex align-items-center">
                                    <div className="mr-2">
                                      <div
                                        className="shadow-sm rounded-circle"
                                        style={{
                                          backgroundImage: `url(${room.roomPic})`,
                                          height: 50,
                                          width: 50,
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                          backgroundRepeat: "no-repeat",
                                        }}
                                      />
                                    </div>
                                    <div className="flex-grow-1">
                                      <p className="text-white mb-0 font-weight-bold">
                                        {room.roomType === 1 && (
                                          <FontAwesomeIcon
                                            icon={faLock}
                                            className="text-success"
                                            size="sm"
                                          />
                                        )}{" "}
                                        {room.roomName}
                                      </p>
                                      <p className="text-light mb-0 small">
                                        <b>{room.lastMessage?.user?.name}: </b>
                                        {room.lastMessage?.content}
                                      </p>
                                    </div>
                                    <div>
                                      <a
                                        href={`https://console.igesia.co/${room.inviteCode}`}
                                        target="_blank"
                                        className="rounded-sm btn btn-sm btn-outline-light"
                                      >
                                        Join room
                                      </a>
                                    </div>
                                  </Card.Body>
                                </Card>
                              ))}
                        </div>
                        {/* Rooms end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    course: state.course,
  }),
  { changeLanguage }
)(Rooms);
