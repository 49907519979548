import { React, useState, useRef } from "react";
import { Dropdown, Image, Form, Button, Overlay, Tooltip } from "react-bootstrap";
import Avatar from "react-avatar";
import firebase from "firebase";
import { useHistory, useLocation } from "react-router-dom";

import { changeLanguage, setDarkTheme } from "../../../store/actions/actions";
import { connect } from "react-redux";
import * as EventStyle from "../event.module.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveState } from "../../../services";
import "./Styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faShare, faSun } from "@fortawesome/free-solid-svg-icons";

toast.configure();

function NavBar(props) {
  const { darkTheme, setDarkTheme, currentEvent, progress } = props;
  const { pathname } = useLocation();
  const userDetails = firebase.auth().currentUser;

  const history = useHistory();

  const { changeLanguage, currentLanguage } = props;

  return (
    <div
      className="row px-3 d-flex justify-content-between  bg-light border-bottom border-dark"
      // data-theme-navbar={darkTheme ? "dark" : "light"}

      style={{ height: "7vh" }}
    >
      <div className="d-flex align-items-center">
        <Image
          // onClick={() => history.push("/events")}
          // className={`rounded pointer ${EventStyle.event_cover_logo}`}
          className={`rounded ${EventStyle.event_cover_logo}`}
          src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          title="igesia logo"
        />
        {currentEvent?.event_details?.EN?.event_name && (
          <p className="mb-0 border-secondary mx-2 text-s-8">
            <span className="">
              {currentEvent?.event_details?.EN?.event_name}
            </span>
          </p>
        )}
      </div>

      <div className="d-none d-md-flex align-items-center"> {/* Not shown on Mobile - Need to fix - 3 Feb 2022 */}
        {/* Light/ Dark Mode button */}
        {/* <div className="mr-3 pointer">
          <a
            title={darkTheme ? "Light theme" : "Dark theme"}
            onClick={() => {
              setDarkTheme(!darkTheme);

              if (document.body.getAttribute("data-theme") === "dark") {
                document.body.setAttribute("data-theme", "light");
                saveState("theme", "light");
              } else {
                document.body.setAttribute("data-theme", "dark");
                saveState("theme", "dark");
              }
            }}
          >
            <FontAwesomeIcon icon={darkTheme ? faSun : faMoon} />
          </a>
        </div> */}

        {progress?.length > 0 && (
          <p className="text-dark mr-3 mb-0">
            <button type="button" class="btn btn-transparent btn-sm" data-container="body" data-toggle="popover" data-placement="bottom"
              data-content="popover">
              {`${progress?.filter((e) => e.progress_status)?.length} of ${progress?.length} complete`}
              {/* {Math.round(progress?.filter((e) => e.progress_status)?.length * 100 / progress?.length)}% */}
            </button>

          </p>
        )}

        {/* Share Button - to be added */}

        {/* <a className="btn btn-sm btn-outline-dark mr-3">
          Share &nbsp;
          <FontAwesomeIcon icon={faShare} />
        </a> */}

        {/* Language Button - to be added */}

        {/* <Dropdown className="mr-3 small btn btn-sm btn-outline-dark">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "English" : "French"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {`English`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown className="mr-4 pointer">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Avatar
              name={userDetails.displayName}
              round={true}
              size="25"
              textSizeRatio={1.75}
              maxInitials={2}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={async () => {
                console.log(pathname.split("/")[3]);
                if (pathname.split("/")[3] === "page") {
                  history.push("../profile");
                } else {
                  history.push(`/event/${currentEvent?.slug}/notes`);
                }
              }}
            >
              {`Notes`}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                console.log(pathname.split("/")[3]);
                if (pathname.split("/")[3] === "page") {
                  history.push("../profile");
                } else {
                  history.push(`/event/${currentEvent?.slug}/profile`);
                }
              }}
            >
              {`Profile`}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                await firebase.auth().signOut();

                toast.dark("Logged out", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }}
            >
              {`Logout`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  { changeLanguage, setDarkTheme }
)(NavBar);
