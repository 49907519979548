import React, { useState, useEffect } from "react";
import { Button, Spinner, Modal, Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import { API_BASE_URL, GRADEBOOK_SCORE_PUBLISH } from "../../../../config";
import UserProfile from "../UserProfile";

function AssignmentResponse(props) {
  // CONSTANTS
  const {
    currentEvent,
    response,
    assignmentSnapshot,
    questionData,
    data,
    currentUser,
    setShowMoreDetailsPanel,
    setSelectedMoreDetails,
    selectedMoreDetails,
    showMoreDetailsPanel,
  } = props;

  // STATES
  const [showGradingModal, setShowGradingModal] = useState(false);
  const [points, setPoints] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [loading, setLoading] = useState(false);

  // FUNCTIONS
  const handleReset = () => {
    setLoading(false);
    setShowGradingModal(false);
    setPoints(null);
    setRemarks(null);
  };

  const handlePublishGrade = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (points.length != questionData.length) {
      toast.warning("ERROR: Please add grade points for all questions.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setLoading(false);
      return;
    }

    const api_response = await axios.post(
      `${API_BASE_URL}${GRADEBOOK_SCORE_PUBLISH}`,
      {
        workspace_id: currentEvent?.workspace_id,
        room_id: currentEvent?.room_id,
        publisher_id: currentUser?.uid,
        user_id: response.id,
        graded_content_id: data.contentId,
        graded_content_type: 5,
        scores: points,
        remarks: remarks,
        for_event: true,
      }
    );
    if (api_response.status === 200) {
      toast.success("Assignment score published.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      handleReset();
    } else {
      toast.warning("ERROR: Publishing Assignment score.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="border border-secondary mb-2">
        <div
          style={
            selectedMoreDetails?.response?.id === response?.id
              ? { backgroundColor: "rgba(200, 200, 200, 0.15)" }
              : {}
          }
        >
          <div className="d-flex align-items-start">
            <div
              className="m-0 py-2 px-3 border-0 flex-grow-1 pointer"
              title={`Click to see responses`}
              onClick={() => {
                setShowMoreDetailsPanel(true);
                setSelectedMoreDetails({
                  response,
                  assignmentSnapshot,
                  questionData,
                  data,
                });
              }}
            >
              <UserProfile
                userDetails={response.user_details}
                imgSize={40}
                subText={moment(response.responseTime).format("LLL")}
              />
              {response?.isScorePublished && !showMoreDetailsPanel ? (
                <>
                  <p className="mt-2 mb-0">
                    Total grade points scored:{" "}
                    <span className="text-success">
                      {response.totalScore} out of {data?.total_points} points
                    </span>
                  </p>
                  <p className="text-success mb-1">
                    <FontAwesomeIcon icon={faCheckDouble} /> Graded at{" "}
                    {moment(response?.publishTimestamp).format("LLL")}
                  </p>
                </>
              ) : null}
            </div>
            {assignmentSnapshot?.isGraded && (
              <div>
                <Button
                  className="m-2"
                  variant="success"
                  size="sm"
                  onClick={() => {
                    setShowGradingModal(true);
                  }}
                >
                  Grade
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal: Grade assignment */}
      <Modal size="lg" show={showGradingModal} onHide={handleReset}>
        {showGradingModal && (
          <>
            <Modal.Header closeButton>
              <UserProfile
                userDetails={response?.user_details}
                imgSize={40}
                subText={moment(response?.responseTime).format("LLL")}
              />
            </Modal.Header>
            <Modal.Body>
              <div>
                <p className="mb-0 lead">
                  Assignment: {assignmentSnapshot?.assignmentSubject}
                </p>
                {response?.isScorePublished ? (
                  <p className="">
                    Total grade points scored: {response.totalScore} out of{" "}
                    {data?.total_points}
                    <span className="text-success ml-2">
                      <FontAwesomeIcon icon={faCheckDouble} /> Graded at{" "}
                      {moment(response?.publishTimestamp).format("LLL")}
                    </span>
                  </p>
                ) : (
                  <p className="">
                    Total grade points: {response?.total_points}
                  </p>
                )}
              </div>
              <div>
                <Form onSubmit={handlePublishGrade}>
                  {response?.answers?.map((ans, idx) => (
                    <div key={idx}>
                      <p className="font-weight-bold mb-0">
                        Que {ans.questionId}.{" "}
                        {questionData[ans.questionId].question}
                      </p>
                      {response?.isScorePublished ? (
                        <div>
                          {assignmentSnapshot?.isGraded && (
                            <>
                              <p className="mb-0">
                                (Grade points scored: {ans.score} /{" "}
                                {questionData[ans.questionId]?.weight} points)
                              </p>
                              {ans.remark && <p>(Remarks: {ans.remark})</p>}
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          {assignmentSnapshot?.isGraded && (
                            <>({questionData[ans.questionId]?.weight} points)</>
                          )}
                        </div>
                      )}
                      <p className="mb-0">Ans: {ans.answer}</p>
                      <p>
                        Attachment:{" "}
                        {ans.attachment ? (
                          <a href={ans.attachment} target="_blank">
                            {ans.attachment}
                          </a>
                        ) : (
                          <i>"No Attachment"</i>
                        )}
                      </p>
                      {response?.isScorePublished ? null : (
                        <>
                          <InputGroup className="mb-3">
                            <Form.Control
                              name={`score-${ans.questionId}`}
                              placeholder="Enter grade points"
                              type="number"
                              min="0"
                              max={questionData[ans.questionId]?.weight}
                              value={
                                points ? points[ans.questionId]?.score : null
                              }
                              onChange={(e) =>
                                setPoints((prev) => {
                                  let newState = { ...prev };

                                  if (
                                    e.target.value <=
                                    parseInt(
                                      questionData[ans.questionId]?.weight
                                    )
                                  ) {
                                    newState[ans.questionId] = {
                                      score: e.target.value,
                                    };
                                  }

                                  return newState;
                                })
                              }
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>
                                out of {questionData[ans.questionId]?.weight}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>

                          <Form.Control
                            className="mb-3"
                            name={`remark-${ans.questionId}`}
                            placeholder="Enter remarks"
                            as="textarea"
                            rows={2}
                            value={
                              remarks ? remarks[ans.questionId]?.remark : null
                            }
                            onChange={(e) =>
                              setRemarks((prev) => {
                                let newState = { ...prev };
                                newState[ans.questionId] = {
                                  remark: e.target.value,
                                };
                                return newState;
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  ))}

                  <div>
                    <Button
                      size="sm"
                      className="text-uppercase mr-2"
                      type="submit"
                      disabled={loading || response?.isScorePublished}
                      title={
                        response?.isScorePublished && "Scores already published"
                      }
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="mr-1"
                        />
                      )}
                      <span>{loading ? "Publishing..." : "Publish"}</span>
                    </Button>
                    <Button
                      size="sm"
                      variant="light"
                      className="text-uppercase shadow-sm"
                      onClick={handleReset}
                    >
                      {response?.isScorePublished ? "Close" : "Discard"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(AssignmentResponse);
