import TopUsers from "./TopUsers";
import UpdateCards from "./UpdateCards";
import DatePicker from "react-datepicker";
import Dashboard from "./Dashboard";
import ImgCards from "./ImgCards";
import Projections from "./Projections";
import QuickUpdateCards from "./QuickUpdateCards";
import Timeline from "./Timeline";
import BlogStats from "./BlogsStats";
import CourseStats from "./CourseStats";
import ProjectStats from "./ProjectStats";
import EventStats from "./EventStats";
import PodcastStats from "./PodcastStats";
import { Button } from "react-bootstrap";
import Revenue from "./revenue";
import moment from "moment";
import { useEffect, useState } from "react";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Home = (props) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  //const [isNull, setisNull] = useState(false);

  //useEffect(() => {
  //  if (selectedMonth.getMonth() == 1 || selectedMonth.getMonth() == 0 ){
  //  setisNull(true);
  //  }else{
  //  setisNull(false);
  //  }
  //  },[selectedMonth]);

  // const script = async () => {
  //   try {
  //     const details = (
  //       await firebase
  //         .firestore()
  //         .doc("/clinics/uWuJrdPudu3sIjrA2K5j/users/mJOUxqFZcTXRVSUkJYtVtIzZYse2")
  //         .get()
  //     ).data();

  //     firebase
  //       .firestore()
  //       .doc("/clinics/uWuJrdPudu3sIjrA2K5j/users/mJOUxqFZcTXRVSUkJYtVtIzZYse2")
  //       .set({
  //         ...details,
  //       });
  //   } catch (e) {
  //     console.error(e, "error in writing");
  //   }
  // };

  // useEffect(() => {
  //   script();
  // }, []);
  if (selectedMonth.getMonth() == 1 || selectedMonth.getMonth() == 0) {
    return (
      <div className="max-vh-100 p-4 w-100 overflow-auto" id="style-4">
        <div className="d-flex justify-content-between">
          <div >
            <a href="/admin"><FontAwesomeIcon icon={faArrowLeft} size="lg" className="text-white mr-3" /></a>
            <h5 className="text-white d-inline-block font-weight-bold"> Workspace Analytics</h5>
          </div>
          <div>
            <DatePicker
              customInput={
                <Button
                  variant="secondary"
                  size="sm p-2"
                  style={{
                    cursor: "pointer",
                    border: "1px solid rgba(255, 255, 255, .3)",
                    background: "rgb(58, 59, 61)",
                  }}
                >
                  {moment(selectedMonth).format("MMMM") + ` `}
                </Button>
              }
              showMonthYearPicker
              selected={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e);
              }}
            />
          </div>
        </div>
        <hr
          style={{
            backgroundColor: "rgba(255, 255, 255, .3)",
            marginLeft: "-23px",
            marginRight: "-23px",
          }}
        />
        <h1 className="nodatatop font-weight-bold">Oops !</h1>
        <h1 className="font-weight-bold nodata">No Data Found</h1>
        <h1 className="font-weight-bold nodata my-3" style={{ fontSize: "20px" }}>Try selecting a <u>different month</u></h1>
      </div>
    );
  } else {
    return (
      <div className="max-vh-100 p-4 w-100 overflow-auto" id="style-4">
        <div className="d-flex justify-content-between">
          <div >
            <a href="/admin"><FontAwesomeIcon icon={faArrowLeft} size="lg" className="text-white mr-3" /></a>
            <h5 className="text-white d-inline-block font-weight-bold"> Workspace Analytics</h5>
          </div>
          <div>
            <DatePicker
              customInput={
                <Button
                  variant="secondary"
                  size="sm p-2"
                  style={{
                    cursor: "pointer",
                    border: "1px solid rgba(255, 255, 255, .3)",
                    background: "rgb(58, 59, 61)",
                  }}
                >
                  {moment(selectedMonth).format("MMMM") + ` `}
                  <FontAwesomeIcon icon={faCaretDown} />
                </Button>
              }
              showMonthYearPicker
              selected={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e);
              }}
            />
          </div>
        </div>
        <hr
          style={{
            backgroundColor: "rgba(255, 255, 255, .3)",
            marginLeft: "-23px",
            marginRight: "-23px",
          }}
        />

        <QuickUpdateCards date={moment(`${selectedMonth}`).format("MMMM")} />
        <Projections date={moment(`${selectedMonth}`).format("MMMM")} />
        <Revenue date={moment(`${selectedMonth}`).format("MMMM")} />
        <Dashboard />

        <h5 className="text-white ml-3">Engagement</h5>
        <ImgCards />
        <h5 className="text-white ml-3">Timeline</h5>
        <Timeline />
        <h5 className="text-white ml-3">Blogs</h5>
        <BlogStats />
        <h5 className="text-white ml-3 my-4">Courses</h5>
        <CourseStats />
        <h5 className="text-white ml-3 my-4">Projects</h5>
        <ProjectStats />
        <h5 className="text-white ml-3 my-4">Event</h5>
        <EventStats />
        <h5 className="text-white ml-3 my-4">Podcasts</h5>
        <PodcastStats />

        <br />
        <br />
        <TopUsers />
      </div>
    );
  }
};
export default Home;
