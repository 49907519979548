import {
  Spinner,
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Dropdown
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "firebase";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NavBar = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const userDetails = firebase.auth().currentUser;
  return (
    <div className="container-fluid" style={{ backgroundColor: "#3a3b3d" }}>
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <Navbar
            className="text-white d-flex justify-content-between"
          >
            <Navbar.Brand onClick={() => history.push("/admin")} className="pointer text-white font-weight-bold">
              igesia
            </Navbar.Brand>
            <Nav className="d-flex align-items-center">
              <Nav.Link className="small text-light" href="/admin">Home</Nav.Link>
              <Nav.Link className="small text-light" href="/admin/integrations">Integrations</Nav.Link>
              <Dropdown className="pointer no-dropdown-caret">
                <Dropdown.Toggle as="div" id="dropdown-basic" className="small text-light mr-2">Help
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    {`Ask a question`}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {`Help Center`}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="pointer no-dropdown-caret">
                <Dropdown.Toggle as="div" id="dropdown-basic">
                  <Avatar
                    name={userDetails.displayName}
                    round={true}
                    size="25"
                    textSizeRatio={1.75}
                    maxInitials={2}
                  />
                  <span className="small text-light ml-1">Account</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="small"
                    onClick={async () => {
                      history.push("/admin/profile");
                    }}
                  >
                    {`Profile`}
                  </Dropdown.Item>
                  <Dropdown.Item
                  className="small"
                    onClick={async () => {
                      await firebase.auth().signOut();
                      history.push("/admin/login")

                      toast.dark("Logged out", {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      });
                    }}
                  >
                    {`Logout`}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
