import React, { lazy, Suspense, useEffect } from "react";
import Sidebar from "./Sidebar/Index";
import "react-datepicker/dist/react-datepicker.css";
import "./style/Calendar.css";
import { useParams } from "react-router";
import Home from "./Home";
import NavBar from "./Navbar";
import { Spinner } from "react-bootstrap";
import SelectedDashboard from "./SelectedDashboard";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
const ViewAll = lazy(() => import("./ViewAll"));

const AdminDashboard = (props) => {
  const params = useParams();
  const currentUser = firebase.auth().currentUser;

  useEffect(() => {
    if (currentUser) {
      var myHeaders = new Headers();
      myHeaders.append("Accept-Charset", "utf-8");

      var formdata = new FormData();
      formdata.append("name", currentUser.displayName);
      formdata.append("email", currentUser.email);
      formdata.append("list", "Obhqn4o98rT9XweQvR05fQ");
      formdata.append("api_key", "nKAxSMspzFluFgktWFP5");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://sendy.iarani.com/subscribe", requestOptions)
        .then((response) => {
          // response.text()
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => console.log("error", error));
    }
  }, [currentUser]);

  if (!currentUser) {
    return <Redirect to="/admin/login" />;
  }
  return (
    <div className="">
      <NavBar />
      <div
        className="container-fluid "
        style={{
          height: "calc(100vh - 56px)",
        }}
      >
        {/* <div className="container"> */}
        {/* <Sidebar /> */}
        {params?.type?.trim()?.length && !params?.slug?.trim()?.length ? (
          <Suspense
            fallback={<Spinner animation="border" size="sm" color="white" />}
          >
            <ViewAll {...params} />
          </Suspense>
        ) : params?.slug?.trim()?.length ? (
          <Suspense
            fallback={<Spinner animation="border" size="sm" color="white" />}
          >
            <SelectedDashboard {...params} />
          </Suspense>
        ) : (
          <Home />
        )}
        {/* </div> */}
      </div>
    </div>
  );
};
export default AdminDashboard;
