import React, { useEffect, useMemo, useState } from "react";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import firebase from "../../../firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
// import { loadState, saveState } from "../../../services/index";
import FontAwesomeExports from "./FontAwesomeExports";

function EventLeftbar(props) {
  const { currentEvent } = props;
  const { id } = useParams();

  // const [language, setLanguage] = useState("EN");

  // useEffect(() => {
  //   if (loadState("language")) {
  //     setLanguage(loadState("language"));
  //   } else {
  //     saveState("language", "EN");
  //   }
  // }, [loadState("language")]);

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("eventconsole");
  const eventSlug = `/event/${id}/`;

  const [snapshotPages, loadingPages, errorPages] = useCollection(
    firebase.firestore().collection(`/events/${currentEvent?.id}/pages`)
  );

  const [snapshotSchedules, loadingSchedules, errorSchedules] = useCollection(
    firebase.firestore().collection(`/events/${currentEvent?.id}/schedule`)
  );

  const [snapshotBooths, loadingBooths, errorBooths] = useCollection(
    firebase.firestore().collection(`/events/${currentEvent?.id}/booths`)
  );

  const [snapshotQuizzes, loadingQuizzes, errorQuizzes] = useCollection(
    firebase.firestore().collection(`/events/${currentEvent?.id}/quizzes`)
  );

  const [snapshotAssignments, loadingAssignments, errorAssignments] =
    useCollection(
      firebase.firestore().collection(`/events/${currentEvent?.id}/assignments`)
    );

  const [snapshotPolls, loadingPolls, errorPolls] = useCollection(
    firebase.firestore().collection(`/events/${currentEvent?.id}/polls`)
  );

  const [customPages, setCustomPages] = useState([]);
  const custom_pages = useMemo(() => {
    return snapshotPages?.docs?.map((e) => {
      return { id: e.id, ...e.data(), slug: `page/${e.data().slug}` };
    });
  }, [snapshotPages]);
  useEffect(() => {
    setCustomPages(custom_pages?.sort((a, b) => a.order_index - b.order_index));
  }, [custom_pages]);

  const locations_data = [
    {
      name: `Dashboard`,
      slug: "dashboard",
      icon: "faHome",
      disabled: false,
      show: true,
    },
    {
      name: currentEvent?.type === 1 ? "Instructors" : "Speakers",
      slug: "speakers",
      icon: "faUserGraduate",
      disabled: false,
      show: true,
    },
    {
      name: currentEvent?.type === 1 ? "Live Sessions" : "Agenda",
      slug: "agenda",
      icon: "faCalendarAlt",
      disabled: false,
      show: snapshotSchedules?.docs?.length ? true : false,
    },
    {
      name: "Booths",
      slug: "booths",
      icon: "faStore",
      disabled: false,
      show: snapshotBooths?.docs?.length ? true : false,
    },
    {
      name: "Networking",
      slug: "networking",
      icon: "faUsers",
      disabled: false,
      show: currentEvent?.people_enabled ? true : false,
    },
    {
      name: props.currentEvent?.bbos_names?.title ?? "BBOS",
      slug: "projects",
      icon: "faTh",
      disabled: false,
      show: currentEvent?.is_bbos_apps ? true : false,
    },
    {
      name: "BBOA",
      slug: "bboa",
      icon: "faTh",
      disabled: false,
      show: currentEvent?.bboa_access ? true : false,
    },
    // {
    //   name: "Notes",
    //   slug: "notes",
    //   icon: "faStickyNote",
    //   disabled: false,
    //   show: true,
    // },
    {
      name: "Assignments",
      slug: "assignments",
      icon: "faClipboardList",
      disabled: false,
      show: currentEvent?.assignment_enabled ? true : false,
    },
    {
      name: "Quizzes",
      slug: "quizzes",
      icon: "faTasks",
      disabled: false,
      show: currentEvent?.quiz_enabled ? true : false,
    },
    {
      name: "Polls",
      slug: "polls",
      icon: "faPoll",
      disabled: false,
      show: currentEvent?.poll_enabled ? true : false,
    },
    {
      name: "Forms",
      slug: "forms",
      icon: "faAddressCard",
      disabled: false,
      show: currentEvent?.feedback_form ?? false,
    },
  ];

  const [routesData, setRoutesData] = useState([]);
  // console.log("Routes data ML ===> ",routesData)

  useEffect(() => {
    return () => {
      setRoutesData([]); // This worked for me
    };
  }, []);

  useEffect(() => {
    if (customPages)
      setRoutesData(() => [
        ...locations_data?.filter((e) => e.show),
        ...customPages,
      ]);
  }, [customPages, loadingSchedules, loadingBooths]);

  useEffect(() => {
    if (!loadingAssignments) {
      if (snapshotAssignments?.docs?.length) {
        setRoutesData((prev) => {
          return prev?.map((item) => {
            if (item.slug === "assignments") {
              return {
                ...item,
                show: true,
              };
            } else {
              return { ...item };
            }
          });
        });
      }
    }
  }, [loadingAssignments]);

  useEffect(() => {
    if (!loadingPolls) {
      if (snapshotPolls?.docs?.length) {
        setRoutesData((prev) => {
          return prev?.map((item) => {
            if (item.slug === "polls") {
              return {
                ...item,
                show: true,
              };
            } else {
              return { ...item };
            }
          });
        });
      }
    }
  }, [loadingPolls]);

  useEffect(() => {
    if (!loadingQuizzes) {
      if (snapshotQuizzes?.docs?.length) {
        setRoutesData((prev) => {
          return prev?.map((item) => {
            if (item.slug === "quizzes") {
              return {
                ...item,
                show: true,
              };
            } else {
              return { ...item };
            }
          });
        });
      }
    }
  }, [loadingQuizzes]);

  useEffect(() => {
    setCurrentLocation(
      // location.pathname.replace(eventSlug, "").replace("/", "")
      location.pathname.split(eventSlug)[1]
    );
  }, [location]);

  return (
    <>
      {props.mobile ? (
        <div
          className="container-fluid d-block d-md-none"
          style={{
            backgroundColor: "#17191A",
            position: "fixed",
            zIndex: "10",
            bottom: 0,
            left: 0,
            right: 0,
            overflowX: "scroll",
          }}
        >
          <div className="d-flex justify-content-around">
            {routesData.map((item, key) => (
              <>
                {item.show ? (
                  <Link
                    key={key}
                    className={
                      item.disabled
                        ? "inline-block text-secondary py-3 px-2"
                        : currentLocation === item.slug
                        ? "inline-block text-primary border-bottom border-primary py-3 px-2"
                        : "inline-block text-light py-3 px-2"
                    }
                    to={
                      !item.disabled &&
                      `${process.env.PUBLIC_URL}${eventSlug}${item.slug}`
                    }
                  >
                    {item.icon && (
                      <FontAwesomeExports className="mb-0" icon={item.icon} />
                    )}
                    <span className="smaller d-none">{item.name}</span>
                  </Link>
                ) : null}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="col-md-1 h-100 pt-5 text-center d-none d-sm-block border-right border-dark"
          style={{
            //borderRight: "1px solid #AAAAAA",
            backgroundColor: "#FFFFFF",
            overflowY: "scroll",
          }}
        >
          {routesData.map((item, key) => (
            <>
              {item.show ? (
                <Link
                  key={key}
                  className={
                    item.disabled
                      ? "mb-5 text-secondary text-decoration-none"
                      : currentLocation === item.slug
                      ? "mb-5 text-primary text-decoration-none"
                      : "mb-5 text-dark text-decoration-none"
                  }
                  to={
                    !item.disabled &&
                    `${process.env.PUBLIC_URL}${eventSlug}${item.slug}`
                  }
                  style={item.disabled ? { cursor: "no-drop" } : {}}
                >
                  {item.logo_image ? (
                    <img
                      alt=""
                      className="mb-2 shadow-lg rounded-12"
                      src={item.logo_image}
                      style={{ width: "75%" }}
                    />
                  ) : (
                    item.icon && (
                      <FontAwesomeExports
                        icon={item.icon}
                        style={{ fontSize: 24 }}
                      />
                    )
                  )}
                  <p className="smaller mb-5 small">{item.name}</p>
                </Link>
              ) : null}
            </>
          ))}
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentEvent: state.currentEvent,
  }),
  { changeLanguage }
)(EventLeftbar);
