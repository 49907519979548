import React, { useState, useEffect, useMemo } from "react";
import { Button, Card, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";

import { API_BASE_URL, EVENT_DETAILS } from "../config";
import { firestore } from "../firebase";
import { postData } from "../utils";

function ExportBBOAMatrix(props) {
  // CONSTANTS
  const { darkTheme } = props;
  const { product_id, project_id } = useParams();

  // STATES
  const [productDetails, setProductDetails] = useState(null);
  const [fetchingProductDetails, setFetchingProductDetails] = useState(null);
  const [matrixData, setMatrixData] = useState(null);
  const [eliminate, setEliminate] = useState([]);
  const [raise, setRaise] = useState([]);
  const [reduce, setReduce] = useState([]);
  const [create, setCreate] = useState([]);
  const [main, setMain] = useState([]);

  // FUNCTIONS
  // Fetch: Matrix details
  const [matrixDetailsDoc, matrixDetailsLoading, matrixDetailsError] =
    useDocument(
      firestore
        .collection(`events/${productDetails?.id}/bboa`)
        .doc(`${project_id}`)
    );

  // Fetch: Factors
  const [factorsCollection, factorsCollectionLoading, factorsError] =
    useCollection(
      firestore.collection(
        `events/${productDetails?.id}/bboa/${project_id}/factors`
      )
    );

  const factors = useMemo(() => {
    return factorsCollection?.docs?.map((e) => ({ ...e.data(), id: e.id }));
  }, [factorsCollection]);

  // Fetch: Products
  const [productsCollection, productsCollectionLoading, productsError] =
    useCollection(
      firestore.collection(
        `events/${productDetails?.id}/bboa/${project_id}/as_is`
      )
    );

  const products = useMemo(() => {
    return productsCollection?.docs?.map((e) => ({ ...e.data(), id: e.id }));
  }, [productsCollection]);

  // Fetch: ERRC grid data
  const [errcCollection, errcCollectionLoading, error] = useCollection(
    firestore.collection(
      `events/${productDetails?.id}/bboa/${project_id}/errc_grid`
    )
  );

  const getErrcHandler = () => {
    if (project_id) {
      errcCollection?.docs?.map((snapshot) => {
        const filteredMain = factors?.filter((item1) => {
          return snapshot.data()?.main?.includes(item1.id);
        });
        //console.log(filteredMain);
        setMain(filteredMain);

        const filteredEliminate = factors?.filter((item1) => {
          return snapshot.data()?.eliminate?.includes(item1.id);
        });
        //console.log(filteredEliminate);
        setEliminate(filteredEliminate);

        const filteredReduce = factors?.filter((item1) => {
          return snapshot.data()?.reduce?.includes(item1.id);
        });
        //console.log(filteredReduce);
        setReduce(filteredReduce);

        const filteredRaise = factors?.filter((item1) => {
          return snapshot.data()?.raise?.includes(item1.id);
        });
        //console.log(filteredRaise);
        setRaise(filteredRaise);

        const filteredCreate = factors?.filter((item1) => {
          return snapshot.data()?.create?.includes(item1.id);
        });
        //console.log(filteredCreate);
        setCreate(filteredCreate);
      });
    }
  };

  const populateMatrixDetails = async () => {
    if (!matrixDetailsLoading) {
      let newData = { ...matrixData };

      if (matrixDetailsDoc?.data()?.industry)
        newData["industry"] = matrixDetailsDoc?.data()?.industry;
      if (matrixDetailsDoc?.data()?.standardOfferings)
        newData["standardOfferings"] =
          matrixDetailsDoc?.data()?.standardOfferings;
      if (matrixDetailsDoc?.data()?.logoImage)
        newData["logoImage"] = matrixDetailsDoc?.data()?.logoImage;
      if (matrixDetailsDoc?.data()?.tagline)
        newData["tagline"] = matrixDetailsDoc?.data()?.tagline;
      if (matrixDetailsDoc?.data()?.valueProposition)
        newData["valueProposition"] =
          matrixDetailsDoc?.data()?.valueProposition;
      if (matrixDetailsDoc?.data()?.market)
        newData["market"] = matrixDetailsDoc?.data()?.market;
      if (matrixDetailsDoc?.data()?.nonCustomersReached)
        newData["nonCustomersReached"] =
          matrixDetailsDoc?.data()?.nonCustomersReached;

      setMatrixData(newData);
    }
  };

  const handleFetchProductDetails = async () => {
    const response = await postData(`${API_BASE_URL}${EVENT_DETAILS}`, {
      event_slug: `${product_id}`,
    });

    if (response.status == 200) {
      const { data } = response;

      setProductDetails({
        ...data,
        id: data?.event_id,
      });

      setFetchingProductDetails(false);
    }
  };

  useEffect(() => {
    if (!errcCollectionLoading) {
      getErrcHandler();
    }
  }, [errcCollection, factors]);

  useEffect(() => {
    if (matrixDetailsDoc) {
      // Fetch: Martix data
      populateMatrixDetails();
    }
  }, [matrixDetailsDoc]);

  useEffect(() => {
    // Fetch: Product details
    handleFetchProductDetails();
  }, []);

  // FUNCTIONAL COMPONENTS
  const Quadrants = ({ type, quadName, values }) => {
    return (
      <div
        className={
          type == "main"
            ? "col-md-12 border border-secondary p-0 m-0"
            : "col-md-6 border border-secondary p-0 m-0"
        }
      >
        <div className="p-2 w-100 bd-highlight text-center text-white bg-primary mb-3">
          {quadName?.toUpperCase()}
        </div>
        <ol>
          {values
            ?.sort((a, b) => a.position - b.position)
            ?.map((e, idx) => (
              <li key={idx}>
                <p>{e.name}</p>
                {/* <p className={e?.description ? "mb-0" : ""}>{e.name}</p>
                {e?.description && (
                  <p className="small text-muted">
                    <b>Description: </b>
                    {e?.description}
                  </p>
                )} */}
              </li>
            ))}
        </ol>
      </div>
    );
  };

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3 border-0 rounded-0"
          : "bg-white shadow-sm border border-light mb-3 border-0 rounded-0"
      }
    >
      <Card.Header>
        <div className="d-flex justify-content-between">
          <h5 className="mb-0 text-uppercase">Blue Ocean Matrix</h5>
          {/* <Button
              className="text-uppercase"
              size="sm"
            >
              Export
            </Button> */}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-end mb-3">
          <div className="d-flex flex-column">
            {/* Logo */}
            {matrixData?.logoImage ? (
              <div className="mb-2">
                <Image
                  src={matrixData?.logoImage}
                  width="120"
                  className="img-fluid shadow-sm p-1 bg-white rounded"
                />
              </div>
            ) : (
              <p className="text-uppercase lead">Logo</p>
            )}
            {/* Tagline */}
            <p className="mb-0 font-weight-bold" style={{ color: "#2f2a60" }}>
              {matrixData?.tagline ? matrixData?.tagline : "TAGLINE"}
            </p>
          </div>
          <div className="d-flex align-items-end flex-column">
            <p className="mb-2" style={{ color: "#2f2a60" }}>
              <b>VALUE PROPOSITION</b>
            </p>
            <p className="lead text-info mb-0">
              {matrixData?.valueProposition
                ? matrixData?.valueProposition
                : "________________"}
            </p>
          </div>
        </div>

        <div
          className="mb-3 rounded p-2"
          style={{ backgroundColor: "#ff5050ff" }}
        >
          <p className="text-white mb-0">
            <b className="mr-2">INDUSTRY [Red Ocean]:</b>
            <span>
              {matrixData?.industry ? matrixData?.industry : "________________"}
            </span>
          </p>

          <p className="text-white mb-0">
            <b className="mr-2">Standard offerings:</b>
            <span>
              {products
                ? products?.map((item) => item.name)?.join(", ")
                : "________________"}
            </span>
          </p>
        </div>

        <div className="mb-3">
          <div className="row justify-content-between mb-3">
            <div className="col" style={{ color: "#2f2a60" }}>
              <p className="lead font-weight-bold mb-0">MARKET POTENTIAL</p>
              <p className="font-weight-light mb-0">
                {matrixData?.market?.potential ?? "COUNTRY / WORLD"}
              </p>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <div className="border rounded w-100 p-2">
                <p className="mb-0 font-weight-bold small text-danger">
                  Market Size: {matrixData?.market?.size ?? "____________"}
                </p>
                <p className="mb-0 font-weight-bold small text-info">
                  Created Market:{" "}
                  {matrixData?.market?.sizeCreated ?? "____________"}
                </p>
              </div>
            </div>
          </div>
          <div style={{ color: "#2f2a60" }}>
            <p className="mb-1">
              <span className="mr-2">Market size in 5 years</span>
              <b>
                {matrixData?.market?.sizeIn5Years ?? "____________ Clients"}
              </b>
            </p>
            <p className="mb-1">
              <span className="mr-2">Market share in 5 years</span>
              <b>{matrixData?.market?.shareIn5Years ?? "____________"} %</b>
            </p>
            <p className="mb-1">
              <span className="mr-2">Revenues in 5 years</span>
              <b>{matrixData?.market?.revenueIn5Years ?? "____________"}</b>
            </p>
          </div>
        </div>

        <div className="mb-3">
          <div className="mb-2" style={{ color: "#2f2a60" }}>
            <p className="lead font-weight-bold mb-0">
              NONCUSTOMERS REACHED BY THE OFFERING
            </p>
          </div>
          <div style={{ color: "#2f2a60" }}>
            <ul className="pl-3">
              <li className="mb-1">
                <b className="mr-2">To-Be:</b>
                <span>
                  {matrixData?.nonCustomersReached?.toBe ??
                    "________________________"}
                </span>
              </li>
              <li className="mb-1">
                <b className="mr-2">Anti:</b>
                <span>
                  {matrixData?.nonCustomersReached?.anti ??
                    "________________________"}
                </span>
              </li>
              <li className="mb-1">
                <b className="mr-2">Unexplored:</b>
                <span>
                  {matrixData?.nonCustomersReached?.unexplored ??
                    "________________________"}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <Quadrants type="reduce" quadName="REDUCED" values={reduce} />
          <Quadrants
            type="eliminate"
            quadName="ELIMINATED"
            values={eliminate}
          />
          <Quadrants type="raise" quadName="RAISED" values={raise} />
          <Quadrants type="create" quadName="CREATED" values={create} />
          {/* <Quadrants type="main" quadName="MAIN" values={main} /> */}
        </div>
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
});

export default connect(mapStateToProps)(ExportBBOAMatrix);
