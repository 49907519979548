import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import HtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import { removeTags } from "../../utils";

function Template2(props) {
  // CONSTANTS
  const {
    userDetails,
    courses,
    events,
    blogs,
    fetchingUserDetails,
    fetchingCourses,
    fetchingEvents,
    fetchingBlogs,
  } = props;

  // FUNCTIONS COMPONENTS
  const ItemSkeleton = () => {
    return (
      <Card className="border-muted mb-3">
        <Card.Body className="row">
          <div className="col-md-3 mb-md-0 mb-2">
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted w-100"
              height={120}
            />
          </div>
          <div className="col-md-7 px-3 border-md-right mb-3">
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted w-75 mb-1"
              height={26}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted w-50 mb-3"
              height={12}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted mb-1"
              height={12}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted mb-1"
              height={12}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted mb-1"
              height={12}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted w-50 mb-1"
              height={12}
            />
          </div>
          <div className="col">
            <Skeleton
              animation="wave"
              variant="rect"
              className="bg-muted w-100"
              height={32}
            />
          </div>
        </Card.Body>
      </Card>
    );
  };

  const ItemCard = ({ e }) => {
    return (
      <div className="col-md-4">
        <a
          className="text-decoration-none text-dark d-block mb-5"
          href={`${window.location.origin}/event/${e.slug}`}
        >
          {/* <img
                    src={e?.event_details?.featured_image}
                    className="img-fluid w-100 mb-2"
                    style={{ cursor: "default", height: "auto" }}
                    title={e?.event_details?.EN?.event_name}
                /> */}
          <div
            className="mb-2 rounded-top"
            style={{
              backgroundImage: `url(${e?.event_details?.featured_image})`,
              height: "125px",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
          <h5 className="mb-0 text-s-6" style={{ fontSize: "100%" }}>
            {e?.event_details?.EN?.event_name}
          </h5>
          {/* <p className="small text-secondary">
                    <React.Fragment>
                        {HtmlParser(e?.event_details?.EN?.small_description)}
                    </React.Fragment>
                </p> */}
          {/* <p className="small text-uppercase text-warning">
                                {moment(
                                    convertSecondstoMilliSeconds(
                                    e?.event_details?.start_date?._seconds
                                    )
                                ).format("Do MMMM YYYY")}
                                </p> */}
        </a>
      </div>
    );
  };

  const BlogListItem = ({ e, idx }) => {
    return (
      <div className="col-md-4" key={idx}>
        <a
          className="text-decoration-none text-dark d-block mb-4"
          href={`${window.location.origin}/blog/${e?.id}`}
          target="_blank"
        >
          <img
            src={e?.img_src}
            className="img-fluid w-100 mb-2"
            style={{ cursor: "default", height: "auto" }}
            title={e?.heading}
          />
          <h5 className="card-text mb-md-2 mb-2 text-s-6">{e?.heading}</h5>
          <p className="small text-secondary">
            <React.Fragment>
              {HtmlParser(
                removeTags(e?.content, " ").substring(0, 250) +
                  "..." +
                  `<a href='${window.location.origin}/blog/${e?.id}' target="_blank">Read more</a>`
              )}
            </React.Fragment>
          </p>
          <p className="small text-muted">
            <b>Published on</b>: {moment(e?.timestamp).format("Do MMMM YYYY")}
          </p>
        </a>
      </div>
    );
  };

  return (
    <div
      className="container-fluid p-0 m-0 bg-white"
      style={{ minHeight: "100vh" }}
    >
      <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom border-dark">
        <div className="container">
          <a
            className="navbar-brand text-s-6"
            href={`/public-profile/${userDetails?.id}`}
          >
            {userDetails?.firstName} {userDetails?.lastName}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto w-100">
              {courses?.length > 0 ? (
                <li className="nav-item">
                  <a
                    class="nav-link"
                    href={`/public-profile/${userDetails?.id}#courses`}
                  >
                    Courses
                  </a>
                </li>
              ) : null}
              {events?.length > 0 ? (
                <li className="nav-item">
                  <a
                    class="nav-link"
                    href={`/public-profile/${userDetails?.id}#events`}
                  >
                    Events
                  </a>
                </li>
              ) : null}
              {blogs?.filter((e) => e?.is_published)?.length > 0 ? (
                <li className="nav-item">
                  <a
                    class="nav-link"
                    href={`/public-profile/${userDetails?.id}#blogs`}
                  >
                    Blogs
                  </a>
                </li>
              ) : null}
              {/* <li className="nav-item "><a class="nav-link" href="#">Articles</a></li> */}
              <form className="form-inline my-2 my-lg-0 w-100">
                {/* <input className="form-control mr-sm-2 w-100 border-dark" type="search" placeholder={`Search ${userDetails?.firstName} ${userDetails?.lastName}'s workspace`} aria-label="Search" /> */}
              </form>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                {/* <a className="nav-link text-s-8" href="#">Contact</a> */}
                <a
                  href="mailto:{userDetails?.bio}"
                  className="btn btn-outline-dark my-2 my-sm-0 text-decoration-none"
                  type="submit"
                >
                  <span className="text-s-11 font-weight-bold pb-4">
                    Contact
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid pt-5 bg-white">
        {" "}
        {/*  bg-gradient-colourful */}
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="border rounded mb-3">
                <div
                  className="w-100 rounded-top"
                  style={{
                    height: "250px",
                    backgroundImage: `url('https://images.unsplash.com/photo-1644351032803-9c6f42458ea7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3471&q=80')`,
                  }}
                />
                <div className="d-flex justify-content-between">
                  <img
                    src={userDetails?.avatar}
                    width="100px"
                    className="ml-3 rounded-circle border"
                    style={{ marginTop: "-50px" }}
                  />
                  <div>
                    <span class="badge badge-pill badge-primary mr-3">Pro</span>
                  </div>
                </div>
                <div className="p-3">
                  {fetchingUserDetails ? (
                    <h1 className="mb-5 text-s-1">Loading...</h1>
                  ) : userDetails ? (
                    <h1 className="mb-2 text-s-5">
                      {userDetails?.firstName} {userDetails?.lastName}
                    </h1>
                  ) : (
                    <h1 className="mb-3 text-s-1">
                      This profile does not exist
                    </h1>
                  )}
                  <p>
                    Acme University is devoted to excellence in teaching,
                    learning, and research
                  </p>
                  {/* <p className="text-s-6">About</p> */}
                </div>
              </div>

              <div className="mb-5">
                <h3 className="text-s-6">About</h3>
                <p className="mb-0 text-s-12">{userDetails?.bio}</p>
              </div>

              {courses?.length > 0 && (
                <div className="pb-3 " id="courses">
                  <h3 className="mb-3 text-s-6">
                    Course{courses?.length > 1 ? "s" : null} ({courses?.length})
                  </h3>
                  <div>
                    <div className="row">
                      {!fetchingCourses ? (
                        courses?.length > 0 ? (
                          courses?.map((e) => {
                            return <ItemCard e={e} />;
                          })
                        ) : (
                          <div>
                            <p className="text-center">No courses</p>
                          </div>
                        )
                      ) : (
                        <ItemSkeleton />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {events?.length > 0 && (
                <div className="pb-3" id="events">
                  <h3 className="mb-3 text-s-6">
                    Event{events?.length > 1 ? "s" : null} ({events?.length})
                  </h3>
                  <div>
                    <div className="row">
                      {!fetchingEvents ? (
                        events?.length > 0 ? (
                          events?.map((e) => {
                            return <ItemCard e={e} />;
                          })
                        ) : (
                          <div>
                            <p className="text-center">No events</p>
                          </div>
                        )
                      ) : (
                        <ItemSkeleton />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {blogs?.filter((e) => e?.is_published)?.length > 0 && (
                <div className="pb-3" id="blogs">
                  <h3 className="mb-3 text-s-6">
                    Blog
                    {blogs?.filter((e) => e?.is_published)?.length > 1
                      ? "s"
                      : null}{" "}
                    ({blogs?.filter((e) => e?.is_published)?.length})
                  </h3>
                  <div>
                    <div className="row">
                      {!fetchingBlogs ? (
                        blogs?.filter((e) => e?.is_published)?.length > 0 ? (
                          blogs
                            ?.filter((e) => e?.is_published)
                            ?.map((e, idx) => {
                              return <BlogListItem e={e} idx={idx} />;
                            })
                        ) : (
                          <div>
                            <p className="text-center">No blogs</p>
                          </div>
                        )
                      ) : (
                        <ItemSkeleton />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-2 offset-md-1">
              <img
                src={userDetails?.avatar}
                width="100%"
                className="mb-5 rounded-circle"
              />
              <a
                href={userDetails?.websiteURL}
                target="_blank"
                className="btn btn-md btn-outline-dark w-100 mb-2  pointer"
              >
                <span className="text-s-8">
                  <FontAwesomeIcon icon={faLink} className="mr-2" />
                  Website
                </span>
              </a>
              <a
                href={userDetails?.linkedinURL}
                target="_blank"
                className="btn btn-md btn-outline-dark w-100 mb-2  pointer"
              >
                <span className="text-s-8">
                  <FontAwesomeIcon icon={faLink} className="mr-2" />
                  LinkedIn
                </span>
              </a>
              <a
                href={userDetails?.twitterURL}
                target="_blank"
                className="btn btn-md btn-outline-dark w-100  pointer"
              >
                <span className="text-s-8">
                  <FontAwesomeIcon icon={faLink} className="mr-2" />
                  Twitter
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white" style={{ height: "100%" }}>
        <div className="container">
          {/* Section: Courses */}

          {/* Section: Events */}
        </div>
      </div>
    </div>
  );
}

Template2.defaultProps = {
  courses: [],
  events: [],
  blogs: [],
  fetchingUserDetails: true,
  fetchingBlogs: true,
  fetchingCourses: true,
  fetchingEvents: true,
};

Template2.propTypes = {
  userDetails: PropTypes.object.isRequired,
  courses: PropTypes.array,
  events: PropTypes.array,
  blogs: PropTypes.array,
  fetchingUserDetails: PropTypes.bool,
  fetchingCourses: PropTypes.bool,
  fetchingEvents: PropTypes.bool,
  fetchingBlogs: PropTypes.bool,
};

export default Template2;
