import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faKey,
  faLanguage,
  faLock,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUsers,
  faUsersCog,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";

const EventManagerNavbar = (props) => {
  const clinicDetails = useSelector((state) => state.clinicDetails);
  const [currentNavKey, setCurrentNavKey] = useState("overview");

  const history = useHistory();
  const { hash } = useLocation();

  useEffect(() => {
    props.navData.map((item, key) => {
      if (decodeURIComponent(hash) === `#${item.eventKey}`) {
        setCurrentNavKey(item.eventKey);
      }
      if (hash === "") {
        setCurrentNavKey("overview");
      }
    });
  }, [hash]);

  return (
    <Nav
      variant="navs"
      justify
      defaultActiveKey={currentNavKey}
      activeKey={currentNavKey}
      className="small text-uppercase font-weight-bold text-primary"
    >
      {props.navData.map((item, key) => (
        <Nav.Item key={key}>
          <Nav.Link
            className={
              currentNavKey === item.eventKey
                ? "text-primary pb-3"
                : "text-light pb-3"
            }
            eventKey={item.eventKey}
            onSelect={(e) => {
              props.setCurrentNavKey(e);
              history.push({
                hash: `#${item.eventKey}`,
              });
            }}
          >
            {item.name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};
export default EventManagerNavbar;
