import React from "react";
import {
  faCheckCircle,
  faEllipsisH,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Image } from "react-bootstrap";
import * as TimelineStyle from "./timeline.module.css";

export default function TimelineCover() {
  return (
    <div className="container pt-0 pt-md-4">
      <Card
        className={`shadow-sm ${TimelineStyle.timeline_cover_card} d-none d-md-block`}
      >
        <div
          className={`rounded-top ${TimelineStyle.timeline_cover_img}`}
          style={{
            backgroundImage: `url(${`${process.env.PUBLIC_URL}/assets/igesia-cover.jpg`})`,
          }}
        />
        <Card.Body
          className={`rounded-bottom py-2 ${TimelineStyle.timeline_cover_card_body}`}
        >
          <Image
            className={TimelineStyle.timeline_cover_logo}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
          <div className={`text-white ${TimelineStyle.timeline_cover_content}`}>
            <p className="h5 mb-0 font-weight-bold">Igesia</p>
            <p className="mb-0">@igesia</p>
          </div>
          <div className="">
            {true ? (
              <Button
                className={`${TimelineStyle.follow_btn} text-white border`}
                variant="none"
              >
                <span className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="mr-2 small"
                  />
                  <p className="small font-weight-bold mb-0">Joined</p>
                </span>
              </Button>
            ) : (
              <Button className={TimelineStyle.follow_btn}>
                <span className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faEye} className="mr-2 small" />
                  <p className="small font-weight-bold mb-0">Request to Join</p>
                </span>
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>

      <div className={`shadow-sm border-0 d-block d-md-none`}>
        <div className={`p-2 bg-graphite d-flex justify-content-end`}>
          <div className="rounded-pill d-flex align-items-center border border-secondary p-1 px-2 bg-dark">
            <Image
              src={`${process.env.PUBLIC_URL}/assets/Igesia-Logo.svg`}
              style={{ height: 35 }}
              className="rounded-circle"
            />
            <div className={`text-white`}>
              <p className="mb-0 font-weight-bold small">Igesia</p>
            </div>
          </div>
        </div>
        <Image
          src={`${process.env.PUBLIC_URL}/assets/igesia-cover.jpg`}
          className="img-fluid"
        />
      </div>
    </div>
  );
}
