import React from "react";
import HtmlParser from "react-html-parser";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function SinglePage({ featured_image, content }) {
  return (
    <React.Fragment>
      {featured_image && (
        <figure>
          <Zoom>
            <img src={featured_image} className="img-fluid w-100 rounded-sm" />
          </Zoom>
        </figure>
      )}
      {HtmlParser(content)}
    </React.Fragment>
  );
}
