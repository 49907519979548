import React, { useRef, useState, useEffect } from "react";
import {
    Navbar,
    Tabs,
    Tab,
    Image,
    Card,
    Button,
    Dropdown,
} from "react-bootstrap";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import * as ConsultationStyle from "../consultation.module.css";
import { languageText } from "../../Event/language.json";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import Gallery from "react-grid-gallery";
import { images } from "../images.json";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";

function Consultation({ currentLanguage, changeLanguage }) {
    const { hash, pathname } = useLocation();
    const {id} = useParams();
    const history = useHistory();

    const handleRegister = () => {
        if (pathname.split("/")[2] !== "" && pathname.split("/").length < 4) {
            history.push(`${pathname.split("/")[2]}/register`);
        }
    };

    const [clinicDoc, loadingClinicDoc, errorClinicDoc] = useDocument(
        firebase.firestore().collection("clinics").doc(`${id}`)
    );

    return (

        <div className="h-100">
            <LeftBar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <NavBar />
                <div className="row" style={{ height: "95vh", overflow: "hidden" }}>
                    <LeftBar />
                    <div className="col-md-7 h-100 p-5 bg-graphite" style={{ overflow: "scroll" }}>
                        <div className=" px-0 bg-graphite" >
                            <div className="">
                                <div className="">
                                    {/* Description panel starts */}
                                    <div className="col-md-12">
                                        <div className="">
                                            <h2 className="font-weight-bold text-white">
                                                {!loadingClinicDoc && clinicDoc.data()?.clinic_details["EN"]?.clinic_name}
                                            </h2>
                                            <p className="text-white">Mon - Sat: 9:30 AM to 5:00 PM IST</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {/* Event cover starts */}
                                        <div className="" id="#overview">
                                            <div className="row">
                                                <div className="col-md-12">

                                                    <Card
                                                        className={`shadow-sm ${ConsultationStyle.consultation_cover_card}`}
                                                    >
                                                        <div
                                                            className={`rounded-top ${ConsultationStyle.consultation_cover_img}`}
                                                            style={{
                                                                backgroundImage: `url(${clinicDoc?.data()?.clinic_details?.featured_image
                                                                    })`,
                                                            }}
                                                        />
                                                        {/* <Card.Body
                                                            className={`rounded-bottom py-2 ${ConsultationStyle.consultation_cover_card_body}`}
                                                        >
                                                            <Image
                                                                className={`rounded ${ConsultationStyle.consultation_cover_logo}`}
                                                                src={`https://events.igesia.co/assets/igesia-logo.png`}
                                                            />
                                                            <div
                                                                className={`text-white ${ConsultationStyle.consultation_cover_content}`}
                                                            >
                                                                <p className="h5 mb-0 font-weight-bold">Igesia Health</p>
                                                                <p className="mb-0">@igesiahealth</p>
                                                            </div>
                                                            <div className="">
                                                                <Button
                                                                    className={ConsultationStyle.register_btn}
                                                                    onClick={handleRegister}
                                                                >
                                                                    <span className="d-flex align-items-center">
                                                                        <p className="small font-weight-bold mb-0">
                                                                            Book Appointment
                                                                        </p>
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        </Card.Body> */}
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Event cover ends */}

                                        {/* Booking panel starts */}
                                        <div className=" my-3">


                                            <Card className="bg-dark">
                                                <Card.Body>
                                                    <div className="text-white">
                                                        {clinicDoc?.data()?.clinic_details["EN"]?.small_description &&
                                                            HTMLReactParser(
                                                                clinicDoc?.data()?.clinic_details["EN"]?.small_description
                                                            )}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        {/* Booking panel ends */}
                                        {clinicDoc?.data()?.clinic_details["EN"]?.description && <div className=" mb-5">
                                            <h5 className="text-white mb-3 font-weight-bold">
                                                {/* {languageText[`${currentLanguage}`].speakers} */}
                                                Overview
                                            </h5>
                                            <div className="bg-dark p-3 rounded shadow-sm">
                                                <p className="text-white font-weight-light mb-0">
                                                    {
                                                        HTMLReactParser(
                                                            clinicDoc?.data()?.clinic_details["EN"]?.description
                                                        )}
                                                </p>
                                            </div>
                                        </div>}
                                    </div>
                                    {/* Description panel Ends */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
                        style={{ overflow: "scroll" }} >
                        <div className="d-flex flex-column h-100 justify-content-between bg-dark">
                            <p className="my-2 font-weight-bold text-muted ml-3">
                                Chat
                            </p>
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
                                    width="50%"
                                />
                                <p className="text-muted small mt-3">
                                    Chat will be enabled soon
                                </p>
                            </div>
                            <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
                                <div>
                                    Chat is currently disabled
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({ currentLanguage: state.currentLanguage }),
    { changeLanguage }
)(Consultation);
