import React, { useState, useMemo, memo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, InputGroup } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import {
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faTimes,
  faCloudUploadAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const Assignment = (props) => {
  const { data } = props;

  const image_type = [
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/gif",
  ];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const [
    assignmentSnapshot,
    assignmentDataLoading,
    assignmentDataError,
  ] = useDocument(firebase.firestore().doc(`assignments/${data.contentId}`));

  const assignmentData = useMemo(() => {
    if (assignmentDataLoading) return assignmentSnapshot?.data();
    else return {};
  }, [assignmentSnapshot, assignmentDataLoading]);

  const [files, setFiles] = useState([]);
  const [answers, setAnswers] = useState([]);


  const [authorized, setAuthorized] = useState([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/gif",

    "application/pdf",

    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ])

  const handleAnswers = (e, key) => {
    let prev = [...answers];
    prev[key] = e.target.value;
    setAnswers(prev)
  };

  const addFile = (event, key) => {
    let prev = [...files];
    let file = event.target.files[0];

    if (event.target.files[0]) {
      prev[key] = file;
      setFiles(prev)
    }
  };

  const removeFile = (key) => {
    let prev = [...files];
    prev.splice(key, 1);
    prev.splice(key, 0, "");
    setFiles(prev)
  };

  if (assignmentDataLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  return (
    <div className="">
      <div>
        <Card className="bg-dark text-white mb-3">
          <Card.Header className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h4 className="font-weight-bold mb-0">
                {assignmentSnapshot?.data()?.assignmentSubject}
              </h4>
            </div>
            <p className="small mb-0">
              {moment(data?.timestamp).format("LLL")}
            </p>
          </Card.Header>
          <Card.Body className="py-1">
            <>
               {assignmentSnapshot?.data()?.questions?.map((que, key) => (
                <div className="mb-3">
                  <p className="font-weight-bold mb-0">Que: {que.question}</p>
                  <p className="mt-0 mb-1">Desc: {que.description}</p>
                  {assignmentSnapshot?.data()?.deadline > Date.now() ? null:(
                      <InputGroup className="mb-1">
                        <TextareaAutosize
                          type="text"
                          className="w-100 p-2 rounded"
                          style={{
                            borderColor: "#CDCDCD",
                            minWidth: "300px",
                          }}
                          name="answers[]"
                          value={answers[key]}
                          id={key}
                          onChange={(e) => handleAnswers(e, key)}
                          minRows={3}
                          maxRows={20}
                          placeholder="Your response"
                        />
                      </InputGroup>
                    ) }
                    {files[key] && (
                    <div>
                      <div className="d-flex rounded bg-light mt-2">
                        <div
                          className="py-2 px-3"
                          style={{ backgroundColor: "#FEF2E4" }}
                        >
                          {!authorized.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              className="text-danger"
                            />
                          )}
                          {image_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faImage}
                              className="text-secondary"
                            />
                          )}
                          {word_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFileWord}
                              className="text-primary"
                            />
                          )}
                          {pdf_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              className="text-danger"
                            />
                          )}
                          {powerpoint_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFilePowerpoint}
                              className="text-danger"
                            />
                          )}
                          {excel_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              className="text-success"
                            />
                          )}
                        </div>
                        <div className="p-2 flex-grow-1">
                          <small className="font-weight-bold text-secondary">
                            {files[key].name}
                          </small>
                        </div>
                        <div className="p-2 flex-grow-1 d-flex justify-content-end align-items-center">
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => removeFile(key)}
                            className="text-dark"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}  
                  {assignmentSnapshot?.data()?.deadline > Date.now() ? null : 
                      files[key] && files[key].name ? null : (
                    <InputGroup className="mb-1">
                      <div class="file-input">
                        <input
                          type="file"
                          name={key}
                          onChange={(e) => {
                            addFile(e, key);
                          }}
                        />
                        <span class="btn btn-sm btn-primary">Attach</span>
                        <span
                          class="label ml-2"
                          className="text-dark"
                          data-js-label
                        >
                          {files[key]
                            ? " " + files[key].name
                            : " No file selected"}
                        </span>
                      </div>
                    </InputGroup>
                  )}  
              </div>
               ))} 
              <Button
                 disabled={
                   assignmentSnapshot?.data()?.deadline > Date.now()
                 }
                //    ||
                //   !(
                //     assignmentSnapshot?.data()?.questions?.length ===
                //     Object.keys(assignmentSnapshot?.data()?.response)?.filter((a) =>
                //       Object.keys(
                //         answers.filter(
                //           (an) => an.answer == ""
                //         )
                //       )
                //         .concat(
                //           Object.keys(
                //             assignmentSnapshot?.data()?.links?.filter((link) => link !== "")
                //           )
                //         )
                //         .includes(a)
                //     )?.length
                //   )
                // }
                variant="primary"
                size="sm"
                // onClick={() => taskResponse(uid)}
              >
                Submit
              </Button>
              
              {!(
                assignmentSnapshot?.data()?.questions?.length ===
                assignmentSnapshot?.data()?.response?.length &&
                assignmentSnapshot?.data()?.questions?.length ===
                assignmentSnapshot?.data()?.links?.length || 
                assignmentSnapshot?.data()?.deadline > Date.now()
              ) && (
                <p className="small mt-2 mb-0 pb-0">
                  <span className="font-weight-bold">Note: </span>Please
                  complete your Assignment to unlock submit.
                </p>
              )}
            </>
             {assignmentSnapshot?.data()?.response?.length ? (
                <>
                  {assignmentSnapshot?.data()?.response?.map((ans, key) => {
                    return (<div>
                      {ans.answers.map((item, index) => {
                        return (
                          <>
                            <p className="font-weight-bold mb-0">
                              Q{key + 1}. {assignmentSnapshot?.data()?.questions[key].question}
                            </p>
                            {item.answer ? (
                              <p className="mt-0 mb-0">
                                <span className="font-weight-bold">A{key + 1}.</span>{" "}
                                {item?.answer}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            {item.attachment ? (
                              <a className="mt-0 mb-0" href={item.attachment} target="_blank">
                                Attachment
                              </a>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      })}
                    </div>
                    )
                  })}
                </>
              ) : (
                null
                // <div>
                //   {assignmentSnapshot?.data()?.questions?.map((que, key) => (
                //     <>
                //       <p className="mb-0">
                //         <span className="font-weight-bold">
                //           Q{que.questionId}.
                //         </span>{" "}
                //         {que.question}
                //       </p>
                //       <p className="mt-0 mb-1 text-secondary">
                //         {que.description}
                //       </p>

                //     </>
                //   ))}
                // </div>
              )} 
          </Card.Body>
          <Card.Footer>
            <p className="mb-0 small">
              Created by <b>{data?.user?.name}</b>
            </p>
            <p className="mb-0 small">
              Deadline by{" "}
              <b>{moment(assignmentData?.deadline).format("LLL")}</b>
            </p>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};
export default memo(Assignment);
