import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Image, Dropdown, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";

import * as EventStyle from "./event.module.css";
import { languageText } from "./language.json";
import { changeLanguage } from "../../store/actions/actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function EventCustomForm({ currentLanguage, changeLanguage }) {
  const history = useHistory();
  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const { id } = useParams();
  const current_user = firebase.auth().currentUser;
  const [currentEvent, setCurrentEvent] = useState({});
  const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("slug", "==", `${id}`)
  );

  useEffect(() => {
    if (eventSnapshot) {
      setCurrentEvent({
        ...eventSnapshot?.docs[0]?.data(),
        id: eventSnapshot?.docs[0]?.id,
      });
    }
  }, [eventSnapshot, loadingEvent]);

  const [formState, setFormState] = useState({});
  const [sendingForm, setSendingForm] = useState(false);
  const handleChange = (e) => {
    setFormState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const resetForm = () => {
    setFormState({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    const validate = currentEvent?.custom_form?.map((item, idx) => {
      if (item.type == "select") {
        if (item.values.includes(formState[item.field_name])) return true;
        else {
          isValid = false;
          return false;
        }
      }
    });
    console.log(currentEvent?.id, current_user?.email, formState, validate);

    if (isValid) {
      setSendingForm(true);
      firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/users/`)
        .doc(current_user.uid)
        .update({
          ...formState,
          custom_form_filled: true,
        })
        .then(() => {
          toast.success("Details submitted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          resetForm();
          history.push("dashboard");
          setSendingForm(false);
        });
    } else {
      toast.info("Please fill in all details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  useEffect(async () => {
    if (current_user && !loadingEvent && currentEvent?.id != null) {
      const querySnapshot = await firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/users`)
        .where("email", "==", current_user.email)
        .get();

      if (querySnapshot?.docs?.length > 0 && querySnapshot?.docs[0]?.data()) {
        if (currentEvent.custom_form_required) {
          const { custom_form_filled } = querySnapshot?.docs[0]?.data();

          if (!!custom_form_filled && custom_form_filled) {
            history.push("./dashboard");
          }
        }
      }
    }
  }, [current_user, currentEvent, loadingEvent]);
  
  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  }

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Top navigation start */}
      <div className="container pt-3 pt-md-4 d-flex align-items-center justify-content-between">
        <div 
        // onClick={() => history.push(`/`)}
        >
          <Image
            className={`rounded ${EventStyle.event_cover_logo}`}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
        </div>
        <Dropdown className="mr-3 small text-dark">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {languageText[`${currentLanguage}`].english}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-md-8 col-12 mx-auto text-center">
            <h2 className="font-weight-bold text-dark">
              {currentEvent?.event_details?.EN?.event_name}
            </h2>
            <p className="text-dark mb-1">
              {moment(convertSecondstoMilliSeconds(currentEvent?.event_details?.start_date?.seconds)).format(
                "DD MMMM YYYY"
              )}
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-8 col-12 mx-auto bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              style={{ position: "sticky", top: 60 }}
            >
              <Card.Body>
                <p className="text-center h4 mb-3">
                  {currentEvent?.custom_form_title}
                </p>
                <Form onSubmit={handleSubmit}>
                  {currentEvent?.custom_form?.map((item, idx) => {
                    switch (item.type) {
                      case "select":
                        return (
                          <Form.Group key={idx}>
                            <Form.Label>{item.label}</Form.Label>
                            <Form.Control
                              as="select"
                              custom
                              required={item.required}
                              name={item.field_name}
                              id={item.field_name}
                              onChange={handleChange}
                            >
                              <option value={null} selected disabled={true}>
                                Select {item.label}
                              </option>
                              {item.values.map((option, key) => (
                                <option value={option} key={key}>
                                  {option}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        );

                      case "number":
                        return (
                          <Form.Group key={idx}>
                            <Form.Label>{item.label}</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder={`Enter ${item.label}`}
                              required={item.required}
                              name={item.field_name}
                              id={item.field_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        );

                      case "text":
                        return (
                          <Form.Group key={idx}>
                            <Form.Label>{item.label}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={`Enter ${item.label}`}
                              required={item.required}
                              name={item.field_name}
                              id={item.field_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        );

                      default:
                        return null;
                    }
                  })}
                  <Button
                    className="px-3 px-md-4 rounded-sm text-uppercase"
                    type="submit"
                  >
                    <small>Submit</small>
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(EventCustomForm);
