export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_CLINIC_DETAILS = "SET_CLINIC_DETAILS";
export const SET_CLINIC_ID = "SET_CLINIC_ID";

export const SET_EVENT_ID = "SET_EVENT_ID";

export const SET_OFFICE_DETAILS = "SET_OFFICE_DETAILS";
export const SET_OFFICE_ID = "SET_OFFICE_ID";

export const SET_COURSE_ID = "SET_COURSE_ID";
export const SET_COURSE_DETAILS = "SET_COURSE_DETAILS";

export const SET_COURSE = "SET_COURSE";
export const SET_COURSE_MODULE = "SET_COURSE_MODULE";
export const SET_COURSE_SUB_MODULE = "SET_COURSE_SUB_MODULE";

export const SET_PROJECT = "SET_PROJECT";
export const SET_APP_PAGE = "SET_APP_PAGE";

export const SET_BLOG = "SET_BLOG";

export const SET_SHOW_NOTEAPP = "SET_SHOW_NOTEAPP";

export const SET_SHOW_WHITEBOARD = "SET_SHOW_WHITEBOARD";
export const SAVE_WHITEBOARD_LAYERS = "SAVE_WHITEBOARD_LAYERS";
export const SAVE_WHITEBOARD_BACKGROUND = "SAVE_WHITEBOARD_BACKGROUND";

export const SET_COURSE_SECTION = "SET_COURSE_SECTION";

export const SET_DARK_THEME = "SET_DARK_THEME";

export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_MY_EVENTS = "SET_MY_EVENTS";

export const SET_MY_BLOGS = "SET_MY_BLOGS";

//Apps Actions

export const SET_GRAPH_ID = "SET_GRAPH_ID";
export const SET_ERRC_ID = "SET_ERRC_ID";
export const SET_FACTORS = "SET_FACTORS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCTS_TO_BE = "SET_PRODUCTS_TO_BE";
export const SET_ASIS_FACTORS = "SET_ASIS_FACTORS";
export const SET_BUYER_ROWS = "SET_BUYER_ROWS";
export const SET_BUYER_HEADER = "SET_BUYER_HEADER";

export const SET_SIX_PATH_HEADER = "SET_SIX_PATH_HEADER";
export const SET_SIX_PATH_ROWS = "SET_SIX_PATH_ROWS";
