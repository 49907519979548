import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav } from "react-bootstrap";
// import "./igesia-styles.scss";
// import "./event-console.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faKey,
  faLanguage,
  faLock,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUserGraduate,
  faUsers,
  faUsersCog,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
//import { languageText } from "./language.json";
import { connect } from "react-redux";
import { useCollection } from "react-firebase-hooks/firestore";
import AskQuestion from "./EventChat/AskQuestion";

function Chat(props) {

    const { id } = useParams();
  
    const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
      firebase.firestore().collection("events").where("isDemo", "==", true).where("slug", "==", `${id}`)
    );

  const chatOn = eventSnapshot?.docs[0]?.data()?.chat_enabled ?? false;

  const { currentLanguage, darkTheme } = props;

  return (
    <div className="d-flex flex-column justify-content-between h-100 ">
      <div className="border-bottom  px-3"></div>
      {!chatOn ? (
        <div 
          className="d-flex flex-column h-100 justify-content-between"
          data-theme-navbar={darkTheme ? "dark" : "light"}
        >
          <p 
          className="my-2 font-weight-bold text-muted ml-3">
            {`Event Chat`}
          </p>
          <div className="text-center">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
              width="50%"
            />
            <p className="text-muted small mt-3">
              {`Event Chat Will Be Enabled Soon`}
            </p>
          </div>
          <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
            <div>
              {`Chat Is Currently Disabled`}
            </div>
            <div>
              <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="col-md-12 h-100  d-none d-md-flex px-0 flex-column justify-content-between"
          style={{ overflow: "scroll" }}
        >
          <AskQuestion chatroute={`/events/${eventSnapshot?.docs[0]?.id}/questionsAsked/`} />
        </div>
      )}
    </div>
  );
}
export default connect((state) => ({ currentLanguage: state.currentLanguage, darkTheme: state.darkTheme }))(
  Chat
);