import React, { useRef, useState, useEffect, useMemo } from "react";
import {
  Navbar,
  Tabs,
  Tab,
  Image,
  Card,
  Button,
  Dropdown,
  Modal,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faGraduationCap,
  faTimes,
  faPodcast,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Skeleton } from "@material-ui/lab";

import * as EventStyle from "./event.module.css";

import { postData, removeTags } from "../../utils";
import {
  API_BASE_URL,
  EVENT_BOOTHS,
  EVENT_DETAILS,
  EVENT_PAGES,
  EVENT_SCHEDULES,
  EVENT_SPEAKERS,
  EVENT_USER_DETAILS,
  USER_DETAILS,
  USER_GET_MODULES,
} from "../../config";
import ListSections from "./ListSections";
import CoverLoader from "./LandingPage/CoverLoader";
import Pages from "./LandingPage/Pages";
import Module from "./Dashboard/Modules/Module";

function Event({ currentLanguage, changeLanguage, darkTheme }) {
  const currentUser = firebase.auth().currentUser;

  const { hash, pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();

  // States
  const [key, setKey] = useState(0);

  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [currentEvent, setCurrentEvent] = useState({});
  const [fetchingEventDetails, setFetchingEventDetails] = useState(true);

  const [userDetails, setUserDetails] = useState(null);
  const [currentUserCategory, setCurrentUserCategory] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(0);
  const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
  const [currentUserIsPart, setCurrentUserIsPart] = useState(false);

  const [allSpeakers, setAllSpeakers] = useState(null);
  const [publishedSpeakers, setPublishedSpeakers] = useState(null);
  const [fetchingSpeakers, setFetchingSpeakers] = useState(true);

  const [schedules, setSchedules] = useState(null);
  const [scheduleCategories, setScheduleCategories] = useState(null);
  const [fetchingSchedules, setFetchingSchedules] = useState(true);

  const [boothCategories, setBoothCategories] = useState(null);
  const [boothList, setBoothList] = useState(null);
  const [fetchingBooths, setFetchingBooths] = useState(true);

  const [pages, setPages] = useState(null);
  const [fetchingPages, setFetchingPages] = useState(true);
  const [modules, setModules] = useState([]);
  const [fetchingModules, setFetchingModules] = useState(false);
  const [creatorDetails, setCreatorDetails] = useState(null);
  const [fetchingCreatorDetails, setFetchingCreatorDetails] = useState(false);

  // References
  const pageRef = useRef(null);
  const overviewRef = useRef(null);
  const speakersRef = useRef(null);
  const agendaRef = useRef(null);
  const boothsRef = useRef(null);
  const pagesRef = useRef(null);
  const modulesRef = useRef(null);

  // FUNCTIONS
  const handleFetchCustomPages = async () => {
    try {
      const { event_id } = currentEvent;
      const response_pages = await postData(`${API_BASE_URL}${EVENT_PAGES}`, {
        event_id: event_id,
      });

      if (response_pages.status == 200) {
        const { data } = response_pages;

        setPages(data.pages);
        setFetchingPages(false);
      } else {
        setFetchingPages(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching custom pages: ${error.message}`);
      setFetchingPages(false);
    }
  };

  const handleFetchModules = async () => {
    try {
      setFetchingModules(true);

      const response = await postData(`${API_BASE_URL}${USER_GET_MODULES}`, {
        event_id: currentEvent?.event_id,
      });

      if (response.status === 200) {
        const { data } = response;

        setModules(data);
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  useEffect(async () => {
    pageRef && pageRef.current.scrollIntoView();

    // Fetch: Event details
    const response = await postData(`${API_BASE_URL}${EVENT_DETAILS}`, {
      event_slug: `${id}`,
    });

    if (response.status == 200) {
      const { data } = response;

      setCurrentEvent({
        ...data,
        id: data?.event_id,
      });

      setFetchingEventDetails(false);
    }
  }, []);

  useEffect(async () => {
    if (currentEvent && !fetchingEventDetails) {
      const { event_id } = currentEvent;

      // Fetch: User details
      if (currentUser) {
        const response_user_details = await postData(
          `${API_BASE_URL}${EVENT_USER_DETAILS}`,
          {
            event_id: event_id,
            user_id: currentUser?.uid,
          }
        );

        if (response_user_details.status == 200) {
          const { data } = response_user_details;

          setUserDetails(data.userData);
          setCurrentUserCategory(data.userData?.category ?? "");
          setCurrentUserRole(data.userData?.user_role ?? 0);
          setCurrentUserIsPart(data.is_registered);
          setFetchingUserDetails(false);

          if (data.custom_form_required && !data.custom_form_filled) {
            history.push("./fill-details");
          }
        }

        if (response_user_details.status == 400) {
          setCurrentUserIsPart(false);
          setFetchingUserDetails(false);
        }
      } else {
        setFetchingUserDetails(false);
      }

      // Fetch: Speakers
      const response_speakers = await postData(
        `${API_BASE_URL}${EVENT_SPEAKERS}`,
        { event_id: event_id }
      );

      if (response_speakers.status == 200) {
        const { data } = response_speakers;
        const published_speakers = data.participantList?.filter(
          (speaker) => speaker.is_published
        );

        setAllSpeakers(data.participantList);
        setPublishedSpeakers(published_speakers);
        setFetchingSpeakers(false);
      }

      // Fetch: Agenda / Schedule
      if (currentUser) {
        const response_schedules = await postData(
          `${API_BASE_URL}${EVENT_SCHEDULES}`,
          {
            event_id: event_id,
            user_id: currentUser?.uid,
          }
        );

        if (response_schedules.status == 200) {
          const { data } = response_schedules;
          const schedule_category_list = data.scheduleCategories?.map(
            (category) => {
              return {
                ...category,
                value: category.categoryId,
                label: category.title,
              };
            }
          );

          setSchedules(data?.scheduleList);
          setScheduleCategories(schedule_category_list);
          setFetchingSchedules(false);
        }
      } else {
        setFetchingSchedules(false);
      }

      // Fetch: Booths
      const response_booths = await postData(`${API_BASE_URL}${EVENT_BOOTHS}`, {
        event_id: event_id,
      });

      if (response_booths.status == 200) {
        const { data } = response_booths;

        setBoothCategories(data.boothCategoryList);
        setBoothList(data.boothList);
        setFetchingBooths(false);
      }

      // Fetch: Pages (custom)
      handleFetchCustomPages();

      // Fetch: Modules
      if (currentEvent?.type === 1) {
        handleFetchModules();
      }
    }
  }, [fetchingEventDetails]);

  const showFullCardBooths = currentEvent?.show_full_card_booths ?? false;

  const schedule_category_enabled =
    currentEvent?.schedule_category_enabled ?? "";

  const handleRegister = () => {
    if (pathname.split("/").length == 3) {
      history.push(`./${id}/register`);
    }

    if (pathname.split("/").length == 4) {
      history.push("register");
    }
  };

  const handleCompleteRegister = () => {
    if (pathname.split("/").length == 3) {
      history.push(`./${id}/complete-registration`);
    }

    if (pathname.split("/").length == 4) {
      history.push("complete-registration");
    }
  };

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef &&
        overviewRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#modules`) {
      modulesRef && modulesRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#speakers`) {
      speakersRef &&
        speakersRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#agenda`) {
      agendaRef && agendaRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#booths`) {
      boothsRef && boothsRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  // boothList = boothList?.filter((category) => category);

  const BoothsPerCategoryHandler = (props) => {
    const { boothCategoryID } = props;

    return boothList?.map((booth, idx) => {
      if (
        booth.booth_categories &&
        booth.booth_categories.length &&
        booth.booth_categories.includes(boothCategoryID) &&
        showFullCardBooths
      ) {
        const item = booth;
        return (
          <div
            className="col-md-6 mb-4 col-12"
            key={idx}
            onClick={() =>
              history.push(`/event/${currentEvent?.slug}/booth/${item.boothId}`)
            }
            style={{ cursor: "pointer" }}
          >
            <div className="card event-card border-0 h-100">
              {item.featured_image && (
                <div
                  style={{
                    background: `url(${item.featured_image}) center center / cover no-repeat`,
                    minHeight: "200px",
                  }}
                  className="card-img-top"
                />
              )}
              <div className="card-body h-100 bg-white">
                <div className="d-flex mb-3 align-items-center">
                  {item.company_logo && (
                    <div
                      style={{
                        background: `url(${item.company_logo}) 0% 0% / contain no-repeat`,
                        height: "40px",
                        width: "40px",
                      }}
                      className="rounded-circle mr-3"
                    />
                  )}
                  {item.booth_name && (
                    <h3 className="text-s-6 text-dark">
                      {item.booth_name ? item.booth_name : "Booth name"}
                    </h3>
                  )}
                </div>
                <p className=" mb-0 text-dark">
                  {item.small_description ? item.small_description : null}
                </p>
              </div>
            </div>
          </div>
        );
      } else if (
        booth.booth_categories &&
        booth.booth_categories.length &&
        booth.booth_categories.includes(boothCategoryID)
      ) {
        const item = booth;
        return (
          <div
            className="col-md-6 mb-4 col-12"
            key={idx}
            onClick={() =>
              history.push(`/event/${currentEvent?.slug}/booth/${item.boothId}`)
            }
            style={{ cursor: "pointer" }}
          >
            <div
              className="icon-box bg-white w-100 px-4 py-3"
              style={{ borderRadius: "12px" }}
            >
              <div className="d-flex">
                <div className="d-flex mb-3 align-items-center">
                  {item.company_logo && (
                    <img
                      src={item.company_logo}
                      alt="..."
                      width="70"
                      className="mr-4"
                    />
                  )}
                  {item.booth_name && (
                    <h3 className="text-s-10 mb-0">
                      {item.booth_name ? item.booth_name : "Booth name"}
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const handleVisitReception = () => {
    if (pathname.split("/").length == 3) {
      history.push(`./${id}/dashboard`);
    }

    if (pathname.split("/").length == 4) {
      history.push("dashboard");
    }
  };

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  const showAgenda = (agenda, role, category) => {
    let show = false;

    if (!agenda?.assignedTo || !agenda?.assignedToAll) {
      show = true;
    }

    if (agenda.assignedToAll || role === 1) {
      show = true;
    } else if (
      !agenda?.assignedTo ||
      !agenda?.assignedToAll ||
      !agenda.assignedToCategoryMultiple
    ) {
      show = true;
    } else {
      if (agenda.assignedToCategoryMultiple) {
        if (agenda.assignedToCategory.includes(category)) {
          show = true;
        } else show = false;
      } else {
        if (agenda.assignedToCategory === category) show = true;
        else show = false;
      }
    }
    return show;
  };

  const fetchUserDetails = async (cbSetLoading, cbSetData) => {
    try {
      console.log("[Try] Fetching user details");
      cbSetLoading(true);

      const response = await postData(`${API_BASE_URL}${USER_DETAILS}`, {
        user_id: currentEvent?.created_by,
      });

      if (response.status === 200) {
        console.log("[Success] Feteching user details");
        cbSetData(response.data);
        cbSetLoading(false);
      } else {
        cbSetLoading(false);
      }
    } catch (error) {
      console.log("[Error] Fetching user details: ", error);
    }
  };

  useEffect(() => {
    // Fetching user details
    fetchUserDetails(setFetchingCreatorDetails, setCreatorDetails);
  }, [currentEvent?.created_by]);

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Top navigation start */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom border-dark">
        <div className="container">
          <a
            className="navbar-brand text-s-6"
            href={`/public-profile/${currentEvent?.created_by}`}
            target="_blank"
          >
            {creatorDetails?.firstName} {creatorDetails?.lastName}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto w-100">
              <form className="form-inline my-2 my-lg-0 w-100">
                {/* <input className="form-control mr-sm-2 w-100 border-dark" type="search" placeholder={`Search ${userDetails?.firstName} ${userDetails?.lastName}'s workspace`} aria-label="Search" /> */}
              </form>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                {/* <a className="nav-link text-s-8" href="#">Contact</a> */}
                <a
                  href="mailto:{userDetails?.bio}"
                  className="btn btn-outline-dark my-2 my-sm-0 text-decoration-none"
                  type="submit"
                >
                  <span className="text-s-11 font-weight-bold pb-4">
                    Contact
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Navbar
        className="container-fluid pb-0 bg-graphite border-bottom"
        variant={darkTheme ? "dark" : "light"}
        sticky="top"
      >
        <div className="container">
          <div
            className={`${EventStyle.nav_tabs} collapse navbar-collapse`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li
                className={
                  hash === "#overview" || hash === ""
                    ? `nav-item active`
                    : `nav-item`
                }
              >
                <a className="nav-link small" href="#overview">
                  Overview
                </a>
              </li>
              {currentEvent?.type === 1 && modules?.length > 0 && (
                <li
                  className={
                    hash === "#modules" ? `nav-item active` : `nav-item`
                  }
                >
                  <a className="nav-link small" href="#modules">
                    Course content
                  </a>
                </li>
              )}
              {publishedSpeakers?.length > 0 && (
                <li
                  className={
                    hash === "#speakers" ? `nav-item active` : `nav-item`
                  }
                >
                  <a className="nav-link small" href="#speakers">
                    {currentEvent?.type === 1 ? "Instructors" : "Speakers"}
                  </a>
                </li>
              )}
              {schedules?.length > 0 && currentUser && (
                <li
                  className={
                    hash === "#agenda" ? `nav-item active` : `nav-item`
                  }
                >
                  <a className="nav-link small" href="#agenda">
                    {currentEvent?.type === 1 ? "Live Sessions" : "Agenda"}
                  </a>
                </li>
              )}
              {boothCategories && boothCategories.length > 0 && (
                <li
                  className={
                    hash === "#booths" ? `nav-item active` : `nav-item`
                  }
                >
                  <a className="nav-link small" href="#booths">
                    Booths
                  </a>
                </li>
              )}

              {pages &&
                pages.length > 0 &&
                pages
                  ?.sort((a, b) => a.order_index - b.order_index)
                  .map((item) => (
                    <li
                      className={
                        hash === `#${item.slug}`
                          ? `nav-item active`
                          : `nav-item`
                      }
                    >
                      <a className="nav-link small" href={`#${item.slug}`}>
                        {item.name}
                      </a>
                    </li>
                  ))}
            </ul>
          </div>
          <Dropdown className="mr-3 small text-dark">
            <Dropdown.Toggle
              as="div"
              id="dropdown-basic"
              style={{ cursor: "pointer" }}
            >
              {currentLanguage === "EN" ? "EN" : "FR"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "EN"}
                onClick={() => changeLanguage("EN")}
              >
                English
              </Dropdown.Item>
              {/* <Dropdown.Item
                className="small"
                active={currentLanguage === "FR"}
                onClick={() => changeLanguage("FR")}
              >
                French
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container">
        <div className="row py-3 py-md-4">
          <div className="col-md-4 w-100 order-2 text-dark">
            <div style={{ position: "sticky", top: 70 }}>
              <Card
                className={` ${EventStyle.event_cover_card} d-none d-md-block`}
              >
                {fetchingEventDetails ? (
                  <Card.Body className={`text-dark rounded-bottom`}>
                    <CoverLoader mobile={false} />
                  </Card.Body>
                ) : (
                  <Card.Body className={`text-dark rounded-bottom`}>
                    <p className="lead font-weight-bold">
                      {currentEvent?.event_details?.EN?.event_name}
                    </p>
                    {currentEvent?.event_details?.start_date && (
                      <p className="font-weight-bold small mb-1">
                        {moment(
                          convertSecondstoMilliSeconds(
                            currentEvent?.event_details?.start_date?._seconds
                          )
                        ).format("DD MMMM YYYY")}
                      </p>
                    )}
                    <p className="h5 font-weight-bold">{}</p>

                    {fetchingUserDetails ? (
                      <React.Fragment>
                        <Button className={`w-100 `} disabled={true}>
                          <span className="d-flex align-items-center justify-content-center">
                            <p className="small font-weight-bold mb-0 text-uppercase">
                              <Spinner
                                size="sm"
                                animation="border"
                                className="mr-2"
                              />
                              <span>Loading...</span>
                            </p>
                          </span>
                        </Button>
                      </React.Fragment>
                    ) : !!currentUser ? (
                      currentUserIsPart ? (
                        <React.Fragment>
                          <Button
                            className={`w-100 `}
                            onClick={handleVisitReception}
                          >
                            <span className="d-flex align-items-center justify-content-center">
                              <p className="small font-weight-bold mb-0 text-uppercase">
                                Visit Reception
                              </p>
                            </span>
                          </Button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Button
                            className={`w-100 `}
                            onClick={handleCompleteRegister}
                          >
                            <span className="d-flex align-items-center justify-content-center">
                              <p className="small font-weight-bold mb-0 text-uppercase">
                                Enroll now
                              </p>
                            </span>
                          </Button>
                        </React.Fragment>
                      )
                    ) : (
                      <Button className={`w-100 `} onClick={handleRegister}>
                        <span className="d-flex align-items-center justify-content-center">
                          <p className="small font-weight-bold mb-0 text-uppercase">
                            Enroll now
                          </p>
                        </span>
                      </Button>
                    )}
                  </Card.Body>
                )}
              </Card>

              {/* Organised by */}
              {/* <Card className={`mt-3 py-0 ${EventStyle.event_cover_card} `}>
                <Card.Body className={`text-dark rounded-bottom  py-3`}>
                  <p className="small mb-0">Organised by</p>
                  <div className="row d-flex align-items-center">

                    <div className="col-md-12 d-flex align-items-center">
                      <img className="mt-3 mr-3 shadow-sm p-1 border rounded "
                        src="https://www.coca-colaindia.com/content/dam/journey/in/en/private/stories/history/the_logo_story_01122014_596x334.jpg" width="80" height="100%" />
                      <p className="mt-3 mb-0">Coca Cola</p>
                    </div>

                    

                    <div className="col-md-4">
                      <img className="mt-3 shadow-sm p-1 border rounded"
                        src="https://www.coca-colaindia.com/content/dam/journey/in/en/private/stories/history/the_logo_story_01122014_596x334.jpg" width="80" height="100%" />
                    </div>

                     <div className="col-md-4">
                      <img className="mt-3 shadow-sm p-1 border rounded"
                        src="https://1000logos.net/wp-content/uploads/2016/10/canon-symbol.jpg" width="80" height="100%" />
                    </div>
                    <div className="col-md-4">
                      <img className="mt-3 shadow-sm p-1 border rounded"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Oxford-University-Circlet.svg/1636px-Oxford-University-Circlet.svg.png" width="80" height="100%" />
                    </div>
                    <div className="col-md-4">
                      <img className="mt-3 shadow-sm p-1 border rounded"
                        src="https://1000logos.net/wp-content/uploads/2016/10/canon-symbol.jpg" width="80" height="100%" />
                    </div>
                  </div>
                </Card.Body>
              </Card> */}
              <p className="small mt-2 text-right">
                Powered by{" "}
                <img
                  src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/Igesia-Logo.svg"
                  width="50"
                  height="50"
                  alt="igesia"
                />
              </p>
            </div>
          </div>
          <div className="col-md-8 order-1">
            <Card
              className={`card text-dark mb-3 border-0 ${EventStyle.dark_bg} `}
              id="#overview"
              ref={overviewRef}
            >
              {fetchingEventDetails ? (
                <CoverLoader mobile={true} />
              ) : (
                <>
                  <h2 className="font-weight-bold ">
                    {currentEvent?.event_details?.EN?.event_name}
                  </h2>
                  {currentEvent?.event_details?.start_date && (
                    <p className="text-dark small">
                      <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                      {moment(
                        convertSecondstoMilliSeconds(
                          currentEvent?.event_details?.start_date?._seconds
                        )
                      ).format("DD MMMM YYYY")}
                    </p>
                  )}
                  <Card.Img
                    variant="top"
                    src={`${currentEvent?.event_details?.featured_image}`}
                  />
                  <Card.Body className={` rounded-bottom border-top-0 border `}>
                    {" "}
                    {/* ${EventStyle.border_light} */}
                    <div className="mb-3 d-block d-md-none">
                      {fetchingUserDetails ? (
                        <React.Fragment>
                          <Button className={`w-100 `} disabled={true}>
                            <span className="d-flex align-items-center justify-content-center">
                              <p className="small font-weight-bold mb-0 text-uppercase">
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  className="mr-2"
                                />
                                <span>Loading...</span>
                              </p>
                            </span>
                          </Button>
                        </React.Fragment>
                      ) : !!currentUser ? (
                        currentUserIsPart ? (
                          <React.Fragment>
                            <Button
                              className={`w-100 `}
                              onClick={handleVisitReception}
                            >
                              <span className="d-flex align-items-center justify-content-center">
                                <p className="small font-weight-bold mb-0 text-uppercase">
                                  Visit Reception
                                </p>
                              </span>
                            </Button>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Button
                              className={`w-100 `}
                              onClick={handleCompleteRegister}
                            >
                              <span className="d-flex align-items-center justify-content-center">
                                <p className="small font-weight-bold mb-0 text-uppercase">
                                  Enroll now
                                </p>
                              </span>
                            </Button>
                          </React.Fragment>
                        )
                      ) : (
                        <Button className={`w-100 `} onClick={handleRegister}>
                          <span className="d-flex align-items-center justify-content-center">
                            <p className="small font-weight-bold mb-0 text-uppercase">
                              Enroll now
                            </p>
                          </span>
                        </Button>
                      )}
                    </div>
                    <span className="text-dark font-weight-bold">
                      {currentEvent?.event_details?.EN?.small_description && (
                        <p>
                          {parse(
                            currentEvent?.event_details?.EN?.small_description
                          )}
                        </p>
                      )}
                    </span>
                    {currentEvent?.event_details?.EN?.description && (
                      <p className="mb-0">
                        {parse(currentEvent?.event_details?.EN?.description)}
                      </p>
                    )}
                  </Card.Body>
                </>
              )}
            </Card>

            {/* Section ends: Speakers */}
            {/* Section starts: What you'll learn (Only for course) */}
            {currentEvent?.type === 1 && (
              <ListSections
                name="What you'll learn"
                category="learnings"
                parent_id={currentEvent?.id}
                parent_type={currentEvent?.type}
              />
            )}

            {/* Section starts: Modules */}
            {currentEvent?.type === 1 && modules?.length > 0 && (
              <div ref={modulesRef} id="#modules">
                <h5 className="text-dark mb-3 text-s-6">Course content</h5>
                <div className="border rounded">
                  {fetchingModules ? (
                    <p className="text-center">Loading...</p>
                  ) : modules?.length > 0 ? (
                    modules?.map((item, key) => (
                      <Module
                        is_public={true}
                        accordion={true}
                        module_data={item}
                        key={key}
                        idx={key}
                      />
                    ))
                  ) : (
                    <p className="text-center">
                      <i>No modules added.</i>
                    </p>
                  )}
                </div>
              </div>
            )}
            {/* Section ends: Modules */}

            {/* Section starts: Speakers */}
            {publishedSpeakers?.length > 0 && (
              <div
                className={`mt-5 ${EventStyle.dark_bg}`}
                id="#speakers"
                ref={speakersRef}
              >
                <h5 className="text-dark mb-4 text-s-6">
                  {currentEvent?.type === 1 ? "Instructors" : "Speakers"}
                </h5>

                {fetchingSpeakers ? (
                  <div className="row my-3">
                    {[1, 2].map((item) => (
                      <div className="col-md-6 col-12" key={item}>
                        <div className="d-flex align-items-center">
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            className="bg-muted rounded-circle mb-0 mr-4"
                            height={60}
                            width={60}
                          />
                          <div className="flex-grow-1">
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              className="bg-muted w-50 rounded-sm mb-2"
                              height={14}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              className="bg-muted rounded-sm mb-1"
                              height={10}
                            />
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              className="bg-muted w-75 rounded-sm mb-0"
                              height={10}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="row mt-3">
                    {publishedSpeakers?.map((speaker, idx) => {
                      const item = speaker;
                      return (
                        <div
                          className="col-md-6 mb-4 col-12"
                          key={idx}
                          onClick={() => {
                            setShowProfile(true);
                            setSelectedProfile(speaker);
                          }}
                        >
                          <div className="d-flex">
                            <div>
                              <div
                                className="mr-4 img-fluid rounded-circle"
                                style={{
                                  height: 70,
                                  width: 70,
                                  backgroundImage: `url(${process.env.PUBLIC_URL}${item.img_src})`,
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                }}
                                title={item?.name}
                              />
                            </div>
                            <div>
                              <p className="text-dark my-0 font-weight-bold">
                                {item.first_name} {item.last_name}
                              </p>
                              <p className="small mb-0 text-muted">
                                {item.designation}
                              </p>
                              <p className="small mb-0 text-dark">
                                {item.organisation}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {/* Section ends: What you'll learn (Only for course) */}
            {/* Section starts: Requirements (Only for course) */}
            {currentEvent?.type === 1 && (
              <ListSections
                name="Requirements"
                category="requirements"
                parent_id={currentEvent?.id}
                parent_type={currentEvent?.type}
              />
            )}
            {/* Section ends: Requirements (Only for course) */}
            {/* Section starts: Who this course is for (Only for course) */}
            {currentEvent?.type === 1 && (
              <ListSections
                name="Who this course is for"
                category="audience"
                parent_id={currentEvent?.id}
                parent_type={currentEvent?.type}
              />
            )}
            {/* Section ends: Who this course is for (Only for course) */}
            {/* Section starts: Agenda */}
            {currentUser && (
              <div ref={agendaRef}>
                {schedule_category_enabled ? (
                  <div>
                    <h5 className="text-s-6 mb-3">
                      {currentEvent?.type === 1 ? "Live Sessions" : "Agenda"}
                    </h5>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(+k)}
                    >
                      {scheduleCategories?.map((category, index) => {
                        return (
                          <Tab
                            eventKey={index}
                            title={category.label}
                            tabClassName={
                              key === index ? "text-primary" : "text-dark"
                            }
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row mt-3">
                                  {schedules?.map((item, idx) => {
                                    const scheduleLangData = {
                                      ...item[currentLanguage],
                                      id: item.scheduleId,
                                    };
                                    if (category.value === item.category)
                                      return (
                                        <>
                                          {showAgenda(
                                            item,
                                            currentUserRole,
                                            currentUserCategory
                                          ) && (
                                            <div className="col-md-12 mb-3">
                                              <Card
                                                className={
                                                  darkTheme
                                                    ? "bg-dark border"
                                                    : "bg-white border"
                                                }
                                              >
                                                <Card.Header
                                                  className={
                                                    darkTheme
                                                      ? "border-0 bg-dark mb-0 pb-0"
                                                      : "border-0 bg-white mb-0 pb-0"
                                                  }
                                                >
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    <p className="small mb-0">
                                                      {moment(
                                                        item?.starts_at
                                                      ).format(
                                                        "MMMM Do HH:mm"
                                                      )}{" "}
                                                      -{" "}
                                                      {moment(
                                                        item?.ends_at
                                                      ).format("HH:mm")}{" "}
                                                      ·{" "}
                                                    </p>
                                                    <div>
                                                      <AddToCalendar
                                                        style={{ zIndex: 10 }}
                                                        event={{
                                                          name: item["EN"]
                                                            .title,
                                                          details: `${item["EN"].description}  <p><a href=${item?.stage?.stage_link}>${item?.stage?.stage_link}</a></p>`,
                                                          startsAt:
                                                            item.starts_at,
                                                          endsAt: item.ends_at,
                                                          location:
                                                            currentUserRole >= 1
                                                              ? item.start_url ??
                                                                item.meeting_url
                                                              : item.meeting_url,
                                                        }}
                                                      >
                                                        Add to calendar
                                                      </AddToCalendar>
                                                    </div>
                                                  </div>
                                                </Card.Header>
                                                <Card.Body
                                                  onClick={() =>
                                                    history.push(
                                                      `/event/${id}/agenda/${item.id}`
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  className="mt-0 pt-0"
                                                >
                                                  <h4 className="text-s-10 mb-1">
                                                    {scheduleLangData.title}
                                                  </h4>
                                                  <p className="small">
                                                    {scheduleLangData
                                                      .description.length > 250
                                                      ? parse(
                                                          removeTags(
                                                            scheduleLangData.description,
                                                            " "
                                                          ).substring(0, 250) +
                                                            "..." +
                                                            "<a href=''>Read more</a>"
                                                        )
                                                      : parse(
                                                          scheduleLangData.description
                                                        )}
                                                  </p>
                                                  <div className="row">
                                                    {item?.speakers?.map(
                                                      (speaker, key) => {
                                                        const facultyData =
                                                          allSpeakers?.filter(
                                                            (item) =>
                                                              item.uid ===
                                                              speaker
                                                          )[0];

                                                        return (
                                                          <div
                                                            key={key}
                                                            className="col-md-6 d-flex align-items-center justify-content-center"
                                                            title={
                                                              facultyData?.first_name
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            // onClick={() => {
                                                            //   setShowProfile(true);
                                                            //   setSelectedProfile(facultyData);
                                                            // }}
                                                          >
                                                            <img
                                                              className="rounded-circle"
                                                              height="50"
                                                              width="50"
                                                              src={
                                                                facultyData?.img_src
                                                              }
                                                              title={
                                                                facultyData?.first_name
                                                              }
                                                              alt=""
                                                            />
                                                            <div className="flex-grow-1 ml-2">
                                                              <h6 className="mb-1">
                                                                {
                                                                  facultyData?.first_name
                                                                }{" "}
                                                                {
                                                                  facultyData?.last_name
                                                                }
                                                              </h6>
                                                              <p className="small mb-0">
                                                                {
                                                                  facultyData?.designation
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Card.Body>
                                              </Card>
                                            </div>
                                          )}
                                        </>
                                      );
                                  })}
                                </div>
                              </div>
                            </div>
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </div>
                ) : (
                  schedules?.length > 0 && (
                    <div id="#agenda">
                      <h5 className="text-s-6">
                        {currentEvent?.type === 1 ? "Live Sessions" : "Agenda"}
                      </h5>
                      <div className="row mt-3">
                        {schedules?.length > 0 ? (
                          <>
                            {schedules?.map((item, key) => {
                              const scheduleLangData = {
                                ...item[currentLanguage],
                                id: item.scheduleId,
                              };

                              return (
                                <>
                                  {showAgenda(
                                    item,
                                    currentUserRole,
                                    currentUserCategory
                                  ) && (
                                    <div className="col-md-12 mb-3">
                                      <Card
                                        className={
                                          darkTheme
                                            ? "bg-dark border"
                                            : "bg-white border"
                                        }
                                      >
                                        <Card.Header
                                          className={
                                            darkTheme
                                              ? "border-0 bg-dark mb-0 pb-0"
                                              : "border-0 bg-white mb-0 pb-0"
                                          }
                                        >
                                          <div className="d-flex justify-content-between align-items-center">
                                            <p className="small mb-0">
                                              {moment(item?.starts_at).format(
                                                "MMMM Do HH:mm"
                                              )}{" "}
                                              -{" "}
                                              {moment(item?.ends_at).format(
                                                "HH:mm"
                                              )}{" "}
                                              ·{" "}
                                            </p>
                                            {/* <div>
                                              <AddToCalendar
                                                style={{ zIndex: 10 }}
                                                event={{
                                                  name: item["EN"].title,
                                                  details: item?.stage
                                                    ? `${item["EN"].description}  <p><a href=${item.stage.stage_link}>${item.stage.stage_link}</a></p>`
                                                    : `${item["EN"].description}`,
                                                  startsAt: item.starts_at,
                                                  endsAt: item.ends_at,
                                                  location:
                                                    currentUserRole >= 1
                                                      ? item.start_url ??
                                                        item.meeting_url
                                                      : item.meeting_url,
                                                }}
                                              >
                                                Add to calendar
                                              </AddToCalendar>
                                            </div> */}
                                          </div>
                                        </Card.Header>
                                        <Card.Body
                                          onClick={() =>
                                            history.push(
                                              `/event/${id}/agenda/${item.id}`
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="mt-0 pt-0"
                                        >
                                          <h4 className="text-s-10 mb-1">
                                            {scheduleLangData.title}
                                          </h4>
                                          <p className="small">
                                            {scheduleLangData.description
                                              .length > 250
                                              ? parse(
                                                  removeTags(
                                                    scheduleLangData.description,
                                                    " "
                                                  ).substring(0, 250) +
                                                    "..." +
                                                    "<a href=''>Read more</a>"
                                                )
                                              : parse(
                                                  scheduleLangData.description
                                                )}
                                          </p>
                                          <div className="row">
                                            {item?.speakers?.map(
                                              (speaker, key) => {
                                                const facultyData =
                                                  allSpeakers?.filter(
                                                    (item) =>
                                                      item.uid === speaker
                                                  )[0];

                                                return (
                                                  <div
                                                    key={key}
                                                    className="col-md-6 d-flex align-items-center justify-content-center"
                                                    title={
                                                      facultyData?.first_name
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    // onClick={() => {
                                                    //   setShowProfile(true);
                                                    //   setSelectedProfile(facultyData);
                                                    // }}
                                                  >
                                                    <img
                                                      className="rounded-circle"
                                                      height="50"
                                                      width="50"
                                                      src={facultyData?.img_src}
                                                      title={
                                                        facultyData?.first_name
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="flex-grow-1 ml-2">
                                                      <h6 className="mb-1">
                                                        {
                                                          facultyData?.first_name
                                                        }{" "}
                                                        {facultyData?.last_name}
                                                      </h6>
                                                      <p className="small mb-0">
                                                        {
                                                          facultyData?.designation
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <p className="small m-2 text-dark">
                            No Agenda available
                          </p>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            {/* Section ends: Agenda */}
            {/* Section starts: Booths */}
            {boothCategories && boothCategories.length > 0 && (
              <div
                className={`container card border ${EventStyle.dark_bg} pt-3 pt-md-4`}
                id="#booths"
                ref={boothsRef}
              >
                <h3 className="text-dark text-s-6">Booths</h3>
                <div>
                  {boothCategories?.map((category) => {
                    return (
                      <div className={`row`}>
                        <div className="col-md-12">
                          <h5 className="mb-4 mt-3 text-dark">
                            {category[currentLanguage].title}
                          </h5>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                {fetchingBooths ? (
                                  <p className="text-center">
                                    <i>Loading booths</i>
                                  </p>
                                ) : (
                                  <BoothsPerCategoryHandler
                                    boothCategoryID={category.boothCategoryId}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Section ends: Booths */}
            {/* Section starts: Custom pages */}
            <Pages
              currentEvent={currentEvent}
              pagesRef={pagesRef}
              pages={pages}
              fetchingPages={fetchingPages}
            />
            {/* Section ends: Custom pages */}
          </div>
        </div>

        {/* Modal starts: Speaker profile */}
        <Modal
          centered={true}
          show={showProfile}
          onHide={() => setShowProfile(false)}
          animation={true}
        >
          <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
            <div
              title="Close"
              className="pointer d-flex justify-content-end mb-0"
              onClick={() => setShowProfile(false)}
            >
              <FontAwesomeIcon className="" icon={faTimes} />
            </div>
            <div className="">
              <div className="d-flex mb-3">
                <div className="mr-3" style={{ width: 70 }}>
                  <div
                    className="img-fluid rounded-circle shadow-sm"
                    style={{
                      height: 70,
                      width: 70,
                      backgroundImage: `url(${selectedProfile?.img_src})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    title={selectedProfile?.name}
                  />
                </div>
                <div className="flex-grow-1">
                  <p className="ftext-dark my-0 font-weight-bold">
                    {selectedProfile?.first_name} {selectedProfile?.last_name}
                  </p>
                  <p className="small mb-0 text-muted">
                    {selectedProfile?.designation}
                  </p>
                  <p className="small mb-0 text-dark">
                    {selectedProfile?.organisation}
                  </p>
                </div>
              </div>
              {selectedProfile?.bio && (
                <div>
                  <p className="small">{parse(selectedProfile?.bio)}</p>
                </div>
              )}
              <div></div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Modal ends: Speaker profile */}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
  }),
  { changeLanguage }
)(Event);
