import React, { useState, useEffect, useRef } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { SketchPicker } from "react-color";

import { connect, useSelector } from "react-redux";

import "./PrescriptionApp.css";
import PrescriptionAppCardLogo from "../Assets/PrescriptionApp-card-logo.png";
import DentalIcon from "../Assets/dental-icon.png";
import IgesiaLogo from "../Assets/igesia-logo.png";
import * as htmlToImage from "html-to-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import JoditEditor from "jodit-react";

import ReactHtmlParser from "react-html-parser";
import firebase from "firebase";
import {
  faArrowRight,
  faCircleNotch,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faPaperPlane,
  faPhoneAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import Resizer from "react-image-file-resizer";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../../../config/index";
import axios from "axios";
import swal from "sweetalert";
import { sendEmail } from "../../../../../utils";

const PrescriptionApp = ({
  prescriptionEditId,
  setPrescriptionEditId,
  currentUser,
  patientId,
  title,
  clinicDetails,
  clinicManager,
  clinicId,
  showEmailSend,
  ...props
}) => {
  let userId = !clinicManager ? props.userId : clinicId;
  const [patientName, setPatientName] = useState("");
  const [showPatientNameInput, setshowPatientNameInput] = useState(true);
  const [patientNameError, setPatientNameError] = useState(false);
  const [PatientDetailsEditMode, setPatientDetailsEditMode] = useState(false);
  const [PrescriptionHeaderDetails, setPrescriptionHeaderDetails] = useState(
    {}
  );
  const [
    SavedPrescriptionHeaderDetails,
    setSavedPrescriptionHeaderDetails,
  ] = useState({});
  const [prescriptionData, setPrescriptionData] = useState("");
  const [ShowEditor, setShowEditor] = useState(true);
  const [Saving, setSaving] = useState(false);
  const [PrescriptionId, setPrescriptionId] = useState(null);
  const [SavingPrescriptionHeader, setSavingPrescriptionHeader] = useState(
    false
  );
  const [sign, setSign] = useState("");
  const [isSending, setIsSending] = useState(false);
  const editor = useRef(null);

  let doctorEmail = firebase.auth().currentUser?.email;
  let doctorName = firebase.auth().currentUser?.displayName;

  const [patientDetails, setPatientDetails] = useState({});

  useEffect(() => {
    firebase
      .firestore()
      .collection("clinics")
      .doc(clinicId)
      .collection("users")
      .doc(patientId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setPatientDetails({ ...doc.data(), id: doc.id });
        }
      });
  }, []);

  useEffect(() => {
    if (!prescriptionEditId) {
      firebase
        .firestore()
        .collection("prescriptionHeaders")
        .where("doctor_id", "==", clinicManager ? clinicId : userId)
        .get()
        .then((doc) => {
          if (!doc.empty) {
            setPrescriptionHeaderDetails({ ...doc.docs[0].data() });
            setSavedPrescriptionHeaderDetails({ ...doc.docs[0].data() });
          }
        });
    } else {
      firebase
        .firestore()
        .collection("prescriptions")
        .doc(prescriptionEditId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            console.log(doc.data());
            setPrescriptionHeaderDetails({
              ...data.prescription_header_details,
            });
            setSavedPrescriptionHeaderDetails({
              ...data.prescription_header_details,
            });
            setSign(data.doctor_signature);
            setPrescriptionData(data.prescription_data);
            setPatientName(data.patient_name);
            setPrescriptionId(prescriptionEditId);
          }
        });
    }
  }, []);

  const SavePrescription = () => {
    if (Saving) return;
    if (!patientName) {
      setPatientNameError(true);
      return;
    }
    setSaving(true);
    if (!PrescriptionId) {
      firebase
        .firestore()
        .collection("prescriptions")
        .add({
          prescription_header_details: PrescriptionHeaderDetails,
          patient_name: patientName,
          prescription_data: prescriptionData,
          created_by: userId,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          timestamp: Date.now(),
          doctor_signature: sign,
          // room_id: roomId,
          // workspace_id: workspaceId,
        })
        .then((doc) => {
          console.log(doc);
          setSaving(false);
          setPrescriptionId(doc.id);
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    } else {
      firebase
        .firestore()
        .collection("prescriptions")
        .doc(PrescriptionId)
        .update({
          prescription_header_details: PrescriptionHeaderDetails,
          patient_name: patientName,
          prescription_data: prescriptionData,
          doctor_signature: sign,
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((doc) => {
          console.log(doc);
          setSaving(false);
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
    }
  };

  const PrescriptionHeaderDetailsChanges = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    PrescriptionHeaderDetails[name] = value;
    setPrescriptionHeaderDetails({ ...PrescriptionHeaderDetails });
  };

  const handleHeaderDetailsSubmit = (e) => {
    e.preventDefault();
    setSavingPrescriptionHeader(true);
    setSavedPrescriptionHeaderDetails({ ...PrescriptionHeaderDetails });
    firebase
      .firestore()
      .collection("prescriptionHeaders")
      .where("doctor_id", "==", userId)
      .get()
      .then((doc) => {
        console.log(doc);
        if (!doc.empty) {
          firebase
            .firestore()
            .collection("prescriptionHeaders")
            .doc(doc.docs[0].id)
            .update({
              ...PrescriptionHeaderDetails,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => setSavingPrescriptionHeader(false));
        } else {
          firebase
            .firestore()
            .collection("prescriptionHeaders")
            .add({
              ...PrescriptionHeaderDetails,
              doctor_id: userId,
              created_at: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => setSavingPrescriptionHeader(false));
        }
      });
  };

  const uploadFile = async (file) => {
    setIsSending(true);
    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);

        setIsSending(false);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
        setIsSending(false);
        return "";
      }
    } else {
      return "";
    }
  };

  const logoUpload = async (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const image = await resizeFile(file);

    // let file_url = await uploadFile(image);
    // console.log("file_url", file_url);
    // if (file_url) {
    //   PrescriptionHeaderDetails[name] = file_url;
    //   setPrescriptionHeaderDetails({ ...PrescriptionHeaderDetails });
    // }

    PrescriptionHeaderDetails[name] = image;
    setPrescriptionHeaderDetails({ ...PrescriptionHeaderDetails });
  };

  const uploadSign = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    // let file_url = await uploadFile(image);
    // console.log("file_url", file_url);
    // if (file_url) {
    //   setSign(file_url);
    // }

    setSign(image);
  };

  // const PatientDetailsChangeHandler = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   PatientDetails[name] = value;
  //   setPatientDetails(PatientDetails);
  // };

  const DownloadCard = () => {
    htmlToImage
      .toJpeg(document.getElementById("doctor-card"), { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "e-card_visiting_card.jpeg";
        link.href = dataUrl;
        link.click();
      })
      .then(() =>
        toast.success("E-card downloaded successfully!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
  };

  const DownloadPrescription = () => {
    if (!patientName) {
      setPatientNameError(true);
      return;
    }
    setshowPatientNameInput(false);
    setShowEditor(false);
    setIsSending(true);

    setTimeout(() => {
      htmlToImage
        .toJpeg(document.getElementById("DownloadPrescription"), {
          quality: 0.99,
        })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "prescription.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          setshowPatientNameInput(true);
          setShowEditor(true);
          setIsSending(false);
          toast.success("Prescription downloaded successfully!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }, [500]);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const sendImageTochat = (type) => {
    if (type === "DownloadPrescription" && !patientName) {
      setPatientNameError(true);
      swal("Error", "Please enter patient name!", "error");
      return;
    }
    if (type === "DownloadPrescription" && !prescriptionData) {
      swal("Error", "Prescription should not be empty!", "error");
      return;
    }
    setIsSending(true);
    setshowPatientNameInput(false);
    setShowEditor(false);
    setTimeout(() => {
      htmlToImage
        .toJpeg(document.getElementById(`${type}`), {
          quality: 1,
        })
        .then(function (dataUrl) {
          var ImageURL = dataUrl;
          setshowPatientNameInput(true);
          setShowEditor(true);
          // Split the base64 string in data and contentType
          var block = ImageURL.split(";");
          // Get the content type of the image
          var contentType = block[0].split(":")[1]; // In this case "image/gif"
          // get the real base64 content of the file
          var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

          // Convert it to a blob to upload
          var file = b64toBlob(realData, contentType);
          console.log(file);
          let fileUrl;
          let Path = userId + Date.now();
          var storageRef = firebase.storage().ref("prescriptions/" + Path);
          var uploadTask = storageRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + Math.round(progress) + "% done");
            },
            function (error) {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  console.log("File available at", downloadURL);

                  firebase
                    .firestore()
                    .collection("clinics")
                    .doc(clinicId)
                    .collection("documents")
                    .add({
                      senderId: userId,
                      receiverId: patientId,
                      title: "Prescription",
                      url: downloadURL,
                      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                      timestamp: Date.now(),
                    })
                    .then(() => {
                      setIsSending(false);
                      setshowPatientNameInput(true);
                      setShowEditor(true);
                    })
                    .then(() => {
                      toast.success("Prescription sent successfully!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    });
                });
            }
          );
        });
    }, [500]);
  };

  const sendByEmail = () => {
    if (!patientName) {
      setPatientNameError(true);
      swal("Error", "Please enter patient name!", "error");
      return;
    }
    if (!prescriptionData) {
      swal("Error", "Prescription should not be empty!", "error");
      return;
    }
    setIsSending(true);
    setshowPatientNameInput(false);
    setShowEditor(false);

    setTimeout(() => {
      htmlToImage
        .toJpeg(document.getElementById(`DownloadPrescription`), {
          quality: 1,
        })
        .then(function (dataUrl) {
          var ImageURL = dataUrl;
          setshowPatientNameInput(true);
          setShowEditor(true);
          // Split the base64 string in data and contentType
          var block = ImageURL.split(";");
          // Get the content type of the image
          var contentType = block[0].split(":")[1]; // In this case "image/gif"
          // get the real base64 content of the file
          var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

          // Convert it to a blob to upload
          var file = b64toBlob(realData, contentType);
          console.log(file);
          let Path = userId + Date.now();
          var storageRef = firebase.storage().ref("prescriptions/" + Path);
          var uploadTask = storageRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + Math.round(progress) + "% done");
            },
            function (error) {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  console.log("File available at", downloadURL);
                  console.log(doctorEmail);
                  try {
                    sendEmail({
                      to: patientDetails.email_address,
                      subject: `Prescription -Dr. ${doctorName}`,
                      content: `<div><p>Please download your prescription</p><br/><img src=${downloadURL}></img></div><div><a href=${downloadURL}>Open in new tab</a></div>`,
                      from: '"Prescription" <noreply@igesia.co>',
                      replyTo: doctorEmail,
                    }).then((response) => {
                      // co
                      if (response.status === 200) {
                        toast.success("Email sent successfully", {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                        });
                      } else if (response.status !== 200) {
                        toast.error("There was an error in sending email", {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                        });
                      }
                    });
                  } catch (error) {
                    console.log("Email error: ", error);
                  } finally {
                    // handleClose();
                    setIsSending(false);
                  }
                });
            }
          );
        });
    }, [500]);
  };

  const config = {
    readonly: false,
    disablePlugins:
      "image,video,media,file,class-span,image-properties,image-processor",
    buttons:
      "bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,font,fontsize,paragraph,brush,cut,copy,print",
    removeButtons: ["file", "image", "copyformat", "link", "source"],
  };

  return (
    <div className="PrescriptionApp">
      {isSending && (
        <div className="loading">
          <FontAwesomeIcon
            icon={faCircleNotch}
            size="4x"
            className="text-success fa-spin"
          />{" "}
        </div>
      )}
      <div className="PrescriptionApp-header">
        <span className="font-14 p-2 pt-3 text-light font-weight-bold">
          {title ? title : "Prescription"}
        </span>
        <span className="  right-buttons">
          {!clinicManager && (
            <span
              className="btn btn-light btn-sm mr-2 mt-1 py-1"
              onClick={() => sendImageTochat("DownloadPrescription")}
            >
              <small>Send to documents</small>
            </span>
          )}
          {showEmailSend ? (
            <span
              className="btn btn-info btn-sm mx-1 mt-1"
              onClick={sendByEmail}
            >
              Send in email
            </span>
          ) : null}
          <span
            className="btn btn-primary btn-sm mr-2 mt-1 py-1"
            onClick={DownloadPrescription}
          >
            <small>Export</small>
          </span>
          {/* <span
            className="btn btn-primary btn-sm mr-2 mt-1 py-1"
            onClick={SavePrescription}
          >
            <small>{Saving ? "Saving" : "Save"}</small>
          </span> */}
          {/* <span
            className="cp text-danger p-1"
            onClick={() => {
              setShowPrescription(false);
              setPrescriptionEditId(null);
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="font-20"
              style={{ margin: "-3px" }}
            />
          </span> */}
        </span>
      </div>
      {PatientDetailsEditMode && (
        <div className="PrescriptionApp-header-edit-modal  ">
          <div className="p-2 border-bottom">
            <span className="lead font-weight-bold">Edit Details</span>
            <span
              className="float-right cp text-danger p-1 mr-2 "
              onClick={() => setPatientDetailsEditMode(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          <form onSubmit={handleHeaderDetailsSubmit}>
            <div className="PrescriptionApp-header-edit-modal-body  p-3">
              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">
                  {clinicDetails?.type === 1 ? "Office" : "clinic"} Name:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="hospital_name"
                    value={PrescriptionHeaderDetails.hospital_name}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">Tagline:</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="hospital_tagline"
                    value={PrescriptionHeaderDetails.hospital_tagline}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">
                  {clinicDetails?.type === 1 ? "Manager" : "Doctor"} Name:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="doctor_name"
                    value={PrescriptionHeaderDetails.doctor_name}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">
                  {clinicDetails?.type === 1 ? "Qualification" : "Specialty"}:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="speciality"
                    value={PrescriptionHeaderDetails.speciality}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row ">
                <label className=" col-sm-2 py-1  font-14">
                  Mobile Number:
                </label>
                <div className="col-sm-10">
                  <PhoneInput
                    country={"in"}
                    className="form-control form-control-sm"
                    value={PrescriptionHeaderDetails.mobile_number}
                    onChange={(phone) => {
                      PrescriptionHeaderDetails["mobile_number"] = phone;
                      setPrescriptionHeaderDetails({
                        ...PrescriptionHeaderDetails,
                      });
                    }}
                  />
                </div>
              </div>

              <div className=" row mb-2">
                <label className=" col-sm-2 py-1 pb-2  font-14"></label>
                <div className="col-sm-10">
                  <PhoneInput
                    country={"in"}
                    className="form-control form-control-sm"
                    value={PrescriptionHeaderDetails.mobile_number2}
                    onChange={(phone) => {
                      PrescriptionHeaderDetails["mobile_number2"] = phone;
                      setPrescriptionHeaderDetails({
                        ...PrescriptionHeaderDetails,
                      });
                    }}
                  />
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">Email:</label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    name="email"
                    value={PrescriptionHeaderDetails.email}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row mb-2">
                <label className=" col-sm-2 py-1  font-14"></label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    name="email2"
                    value={PrescriptionHeaderDetails.email2}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">Website:</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="hospital_website"
                    value={PrescriptionHeaderDetails.hospital_website}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">Address:</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="hospital_address"
                    value={PrescriptionHeaderDetails.hospital_address}
                    onChange={PrescriptionHeaderDetailsChanges}
                  ></input>
                </div>
              </div>

              <div className=" row">
                <label className=" col-sm-2 py-1  font-14">Logo:</label>
                <div className="col-sm-6">
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    name="hospital_logo"
                    accept="image/x-png,image/jpg,image/jpeg"
                    onChange={logoUpload}
                  ></input>
                  {PrescriptionHeaderDetails.hospital_logo && (
                    <div className="text-center">
                      <img
                        src={PrescriptionHeaderDetails.hospital_logo}
                        alt="logo"
                        style={{ objectFit: "contain" }}
                        height="100px"
                        width="100px"
                        className="mt-2"
                      />
                    </div>
                  )}
                </div>
                <div className="col-sm-4">
                  <div className="font-13 py-2">Background Color</div>
                  <SketchPicker
                    color={
                      PrescriptionHeaderDetails.header_bg_color
                        ? PrescriptionHeaderDetails.header_bg_color
                        : "#000"
                    }
                    onChangeComplete={(color) => {
                      PrescriptionHeaderDetails["header_bg_color"] = color.hex;
                      setPrescriptionHeaderDetails({
                        ...PrescriptionHeaderDetails,
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="PrescriptionApp-header-edit-modal-footer bg-light">
              <span className="float-right m-2">
                <button className="btn btn-primary px-3 " type="submit">
                  {SavingPrescriptionHeader ? (
                    <>
                      Saving{" "}
                      <FontAwesomeIcon
                        icon={faCircleNotch}
                        className="fa-spin"
                      />{" "}
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
                <span
                  className="btn btn-secondary ml-2 px-3"
                  onClick={() => setPatientDetailsEditMode(false)}
                >
                  Close
                </span>
              </span>
            </div>
          </form>
        </div>
      )}

      <div className=" dropleft">
        <span
          className="PrescriptionApp-header-edit-btn"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </span>
        <div
          className="dropdown-menu px-2"
          aria-labelledby="dropdownMenuButton"
        >
          <div
            className="font-13 cp my-1 py-1"
            onClick={() => setPatientDetailsEditMode(true)}
          >
            <b>
              <FontAwesomeIcon icon={faEdit} className="mx-2" />
              <span>Edit</span>
            </b>
          </div>
          <div className="font-13 cp  my-1 py-1" onClick={DownloadCard}>
            <b>
              <FontAwesomeIcon icon={faDownload} className="mx-2" />
              Download
            </b>
          </div>
          {/* <div
            className="font-13 cp  my-1 py-1"
            onClick={() => sendImageTochat("doctor-card")}
          >
            <b>
              <FontAwesomeIcon icon={faPaperPlane} className="mx-2" />
              Send e-card in chat
            </b>
          </div> */}
        </div>
      </div>
      <div className="PrescriptionApp-body" id="DownloadPrescription">
        <div
          className="PrescriptionApp-card "
          style={{
            backgroundColor: `${
              SavedPrescriptionHeaderDetails.header_bg_color
                ? SavedPrescriptionHeaderDetails.header_bg_color
                : "#1f1f22"
            }`,
          }}
          id="doctor-card"
        >
          <img
            src={
              SavedPrescriptionHeaderDetails.hospital_logo
                ? SavedPrescriptionHeaderDetails.hospital_logo
                : PrescriptionAppCardLogo
            }
            alt="PrescriptionAppCardLogo"
            height="60px"
            width="60px"
            className="PrescriptionAppCardLogo rounded-lg"
          />
          <span className="hospital-details">
            <span className="hospital-details-title">
              {SavedPrescriptionHeaderDetails.hospital_name}
            </span>
            <span className="hospital-details-subtitle">
              {SavedPrescriptionHeaderDetails.hospital_tagline}
            </span>
            <span className="hospital-details-address">
              {SavedPrescriptionHeaderDetails.hospital_address}
            </span>
          </span>
          <span className="PrescriptionApp-card-doctor-details">
            {/* <img
                src={DentalIcon}
                alt="DentalIcon"
                height="70px"
                className="DentalIcon"
              /> */}
            {!SavedPrescriptionHeaderDetails.doctor_name && ShowEditor && (
              <div className="add-header-details-text">
                Add header details <FontAwesomeIcon icon={faArrowRight} />
              </div>
            )}
            <img
              src={IgesiaLogo}
              alt="IgesiaLogo"
              className="PrescriptionApp-IgesiaLogo"
            />
            <div className="lead text-info">
              {SavedPrescriptionHeaderDetails.doctor_name}
            </div>
            <div className="font-13 ">
              {" "}
              {SavedPrescriptionHeaderDetails.speciality}
            </div>
            <div className="doctor-details font-12">
              <div>
                {SavedPrescriptionHeaderDetails.mobile_number && (
                  <div>
                    <span>
                      <FontAwesomeIcon
                        icon={faPhoneAlt}
                        className="text-info mr-1"
                      />
                    </span>
                    :{" "}
                    <span style={{ display: "inline-table" }}>
                      {SavedPrescriptionHeaderDetails.mobile_number}{" "}
                      {SavedPrescriptionHeaderDetails.mobile_number2 && (
                        <span>
                          <br />
                          {SavedPrescriptionHeaderDetails.mobile_number2}
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div>
                {SavedPrescriptionHeaderDetails.email && (
                  <div>
                    <span>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="text-info mr-1"
                      />
                    </span>
                    :{" "}
                    <span style={{ display: "inline-table" }}>
                      {SavedPrescriptionHeaderDetails.email}{" "}
                      {SavedPrescriptionHeaderDetails.email2 && (
                        <span>
                          <br />
                          {SavedPrescriptionHeaderDetails.email2}
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div>
                {SavedPrescriptionHeaderDetails.hospital_website && (
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-info mr-1"
                    />
                    : {SavedPrescriptionHeaderDetails.hospital_website}
                  </span>
                )}
              </div>
            </div>
          </span>
        </div>
        <div className="row mx-0 bg-white text-dark mt-2">
          <div className="col-2 ">
            <label>
              <b>Name:</b>
            </label>
          </div>
          <div className="col-10">
            {showPatientNameInput ? (
              <input
                type="text"
                placeholder="Please enter a name"
                className="form-control"
                value={patientName}
                onChange={(e) => {
                  setPatientName(e.target.value);
                  setPatientNameError(false);
                }}
              />
            ) : (
              <span onClick={() => setshowPatientNameInput(true)}>
                {patientName}
              </span>
            )}
          </div>
          {patientNameError && (
            <div className="col-12 text-danger font-13 font-weight-bold">
              Please enter a Name!
            </div>
          )}
        </div>
        <div className="PrescriptionApp-editor p-3 bg-white">
          {ShowEditor && (
            <div>
              <JoditEditor
                ref={editor}
                value={prescriptionData}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setPrescriptionData(newContent)}
              />
            </div>
          )}

          {!ShowEditor && <div>{ReactHtmlParser(prescriptionData)}</div>}
        </div>
        <div className="p-3 bg-white ">
          {!sign ? (
            ShowEditor ? (
              <div>
                Upload your signature:{" "}
                <input
                  type="file"
                  name="signature"
                  accept="image/x-png,image/jpg,image/jpeg"
                  onChange={uploadSign}
                />
              </div>
            ) : (
              <div></div>
            )
          ) : (
            <div>
              <img src={sign} alt="sing" height="60px" />{" "}
              {ShowEditor && (
                <span
                  className="btn btn-info btn-sm"
                  onClick={() => setSign("")}
                >
                  <small>Change</small>
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.currentUser.uid,
  currentUser: state.currentUser,
  clinicDetails: state.clinicDetails,
});

export default connect(mapStateToProps)(PrescriptionApp);
