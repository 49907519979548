import React from 'react';
import "./BookLogo.css"

const BookLogo=({bgColor})=>{
  return <div>
    <span className="BookLogo" >
      <span className="BookLogo-color" style={{backgroundColor: `${bgColor?bgColor: "#ff4c50"}`}} ></span>
      <span className="BookLogo-line first" >
      </span>
      <span className="BookLogo-line second" >
      </span>
      <span className="BookLogo-line third" >
      </span>
    </span>
    
  </div>
}

export default BookLogo;