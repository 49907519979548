import React, { memo } from "react";

import SinglePage from "../Dashboard/Templates/SinglePage";
import MultiplePages from "../Dashboard/Templates/MultiplePages";

import * as EventStyle from "../event.module.css";

function Pages(props) {
  // CONSTANTS
  const { pages, fetchingPages, currentEvent, pagesRef } = props;

  return (
    <div ref={pagesRef}>
      {pages?.map((item) => (
        <div
          className={`container card border mt-4 ${EventStyle.dark_bg} pt-3 pt-md-4`}
          id={item.slug}
        >
          <h5 className="text-s-6 mb-3">{item.name}</h5>
          <div className="">
            {item && parseInt(item?.type) === 0 ? (
              <SinglePage content={item?.content} />
            ) : parseInt(item?.type) === 1 ? (
              <MultiplePages
                content={item?.content}
                pageId={item?.pageId}
                eventId={currentEvent?.event_id}
              />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

export default memo(Pages);
