import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import firebase from "firebase";
//import EventManagerNavbar from "./EventManagerNavbar";
import { Form, Dropdown, Button, Spinner, InputGroup } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Flatpickr from "react-flatpickr";
import { Redirect } from "react-router-dom";
import "flatpickr/dist/themes/material_green.css";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
//import { Font } from "@react-pdf/renderer";
// import "../console/igesia-styles.scss";
// import "../console/event-console.scss";
import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../config/index";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import * as linkify from "linkifyjs";
import EventNavbar from "./Navbar";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { languageText } from "../console/language.json";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
//import { languageText as languageTextToast } from "../console/language.json";
import { faLink, faVideo } from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle,
  faCircle,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import { auth, firestore } from "../../firebase";

toast.configure();

const UserProfileUpdate = (props) => {
  const { darkTheme } = props;
  const history = useHistory();
  const userDetails = auth.currentUser;
  const [userData, setUserData] = useState({});
  const [selectedProfileTemplate, setSelectedProfileTemplate] =
    useState("Template1");
  const [updatingProfileTemplate, setUpdatingProfileTemplate] = useState(false);

  const [userSnapshot, loading, error] = useDocument(
    firestore.doc(`/users/${userDetails?.uid}`)
  );

  const [saving, setSaving] = useState(false);
  const { currentLanguage } = props;

  useEffect(() => {
    if (!loading) {
      setUserData(userSnapshot.data());
    }
  }, [loading]);

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();

      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", userDetails.uid);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };
  const saveEventDataToDBHandler = async (e, updatedUserData) => {
    e.preventDefault();
    const avatarURL = updatedUserData.avatar
      ? await uploadFile(updatedUserData.avatar)
      : null;
    const user_data = {
      firstName: updatedUserData.firstName ?? "",
      lastName: updatedUserData.lastName ?? "",
      bio: updatedUserData.bio ?? "",
      email: updatedUserData.email ?? "",
      avatar: avatarURL ?? "",
      providerData: [
        {
          ...userData.providerData[0],
          photoURL: avatarURL ?? "",
          phoneNumber: updatedUserData.phoneNumber ?? null,
        },
      ],
      phoneNumber: updatedUserData.phoneNumber ?? null,
      linkedinURL: updatedUserData.linkedinURL ?? "",
      twitterURL: updatedUserData.twitterURL ?? "",
      videoMeetingLink: updatedUserData.videoMeetingLink ?? "",
      websiteURL: updatedUserData.websiteURL ?? "",
      organisation: updatedUserData.organisation ?? "",
      designation: updatedUserData.designation ?? "",
    };
    try {
      setSaving(true);

      await firestore.doc(`/users/${userDetails.uid}`).update({
        ...user_data,
      });

      firebase
        .auth()
        .currentUser.updateProfile({
          photoURL: avatarURL,
        })
        .then(function () {
          // Update successful.
          // alert("Photo URL updated successfully!");
        })
        .catch(function (error) {
          // An error happened.
          // alert("Error updating Photo URL!");
        });

      toast.success("Successfully Updated Profile", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in saving to db");
      toast.error("Error Updating Profile", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };
  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const handleChangePublicProfileTemplate = async (val) => {
    try {
      setUpdatingProfileTemplate(true);
      setSelectedProfileTemplate(val);

      await firestore.doc(`/users/${userDetails.uid}`).update({
        template: val,
      });

      console.log(`[Success] Updating Profile`);
      toast.dark(`Profile updated successfully`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setUpdatingProfileTemplate(false);
    } catch (error) {
      console.log(`[Error] Updating Profile: ${error.message}`);
      toast.error(`[Error] Updating Profile: ${error.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setUpdatingProfileTemplate(false);
    }
  };

  if (!userDetails) {
    return <Redirect to="/admin/login" />;
  }

  return (
    <>
      <EventNavbar />
      <div className="container-fluid">
        <div className="row mt-3 border-bottom border-dark mb-3">
          <div className="col-md-11 offset-md-1 text-light">
            <h4 className="ml-3 mb-3">Account Settings</h4>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-10 offset-md-1">
            <div className="row ">
              <div className="col-md-2 mb-2">
                <ul class="list-group small  text-light">
                  <li class="list-group-item bg-dark">Profile Settings</li>
                  <li class="list-group-item bg-dark">My Plan</li>
                  <li class="list-group-item bg-dark">My Items</li>
                </ul>
              </div>

              <div className="col-md-10 mb-2 row">
                {/* <div className="row col-md-10 ">
                                <div className="col-md-6 mb-3">
                                    <div className="card event-card border-0 h-100 p-4">
                                        <div className="d-flex flex-column align-items-center">
                                            {userData.avatar && (
                                                <div
                                                    style={{
                                                        background: `url(${getWhetherURLOrLocalFile(
                                                            userData.avatar
                                                        )}) 0% 0% / cover no-repeat`,
                                                        height: "150px",
                                                        width: "150px",
                                                    }}
                                                    className="rounded-circle mx-5"
                                                />
                                            )}
                                            {userData.firstName && (
                                                <h3 className="text-s-6 text-dark mt-3">
                                                    {userData.firstName ? (
                                                        <>
                                                            {userData.firstName}{" "}
                                                            <>
                                                                {userData.lastName && (
                                                                    <span>{userData.lastName}</span>
                                                                )}
                                                            </>
                                                        </>
                                                    ) : (
                                                        "Your name"
                                                    )}
                                                </h3>
                                            )}
                                            <p className="mb-3 text-dark">
                                                {userData.email ? userData.email : null}
                                            </p>
                                            <div className="d-flex mb-2">
                                                {userData.linkedinURL ? (
                                                    <>
                                                        <a
                                                            className="mr-3"
                                                            rel="noreferrer noopener"
                                                            target="_blank"
                                                            href={userData.linkedinURL}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faLinkedin}
                                                                className="text-primary"
                                                                style={{ fontSize: 20 }}
                                                            />
                                                        </a>
                                                    </>
                                                ) : null}

                                                {userData.twitterURL ? (
                                                    <>
                                                        <a
                                                            className="mr-3"
                                                            rel="noreferrer noopener"
                                                            target="_blank"
                                                            href={userData.twitterURL}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTwitterSquare}
                                                                className="text-primary"
                                                                style={{ fontSize: 20 }}
                                                            />
                                                        </a>
                                                    </>
                                                ) : null}

                                                {userData.videoMeetingLink ? (
                                                    <a
                                                        className="mr-3"
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                        href={userData.videoMeetingLink}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faVideo}
                                                            className="text-primary"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                    </a>
                                                ) : null}

                                                {userData.websiteURL ? (
                                                    <a
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                        href={userData.websiteURL}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                            className="text-primary"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                    </a>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="card-body h-100 p-0 bg-white">
                                            <p className="mb-3 text-dark text-center">
                                                {userData.bio ? parse(userData.bio) : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                <div className="col-md-6 mb-3">
                  <Form
                    className="text-light"
                    onSubmit={(e) => saveEventDataToDBHandler(e, userData)}
                  >
                    <div className="d-flex mb-3 align-items-center">
                      {userData.avatar && (
                        <div
                          style={{
                            background: `url(${getWhetherURLOrLocalFile(
                              userData.avatar
                            )}) 0% 0% / cover no-repeat`,
                            height: "80px",
                            width: "80px",
                          }}
                          className="rounded-circle mr-3"
                        />
                      )}
                      <Form.Group className="col-md-6">
                        <Form.Label>{`Update Profile Picture`}</Form.Label>
                        <Form.Control
                          type="file"
                          className="mb-3"
                          accept="image/*"
                          placeholder="Profile Picture"
                          name="avatar"
                          onChange={(event) => {
                            setUserData((prevDetails) => {
                              return {
                                ...prevDetails,
                                avatar: event.target.files[0],
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="row">
                      <Form.Group className="col-md-6">
                        <Form.Label>{`First Name`}</Form.Label>
                        <Form.Control
                          maxLength="150"
                          type="text"
                          className="mb-3"
                          placeholder={`First Name`}
                          value={userData.firstName}
                          name="firstName"
                          onChange={(event) => {
                            setUserData((prevDetails) => {
                              return {
                                ...prevDetails,
                                firstName: event.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6">
                        <Form.Label>{`Last Name`}</Form.Label>
                        <Form.Control
                          maxLength="150"
                          type="text"
                          className="mb-3"
                          placeholder={`Last Name`}
                          value={userData.lastName}
                          name="lastName"
                          onChange={(event) => {
                            setUserData((prevDetails) => {
                              return {
                                ...prevDetails,
                                lastName: event.target.value,
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <Form.Group className>
                      <Form.Label>{`Phone Number`}</Form.Label>
                      <PhoneInput
                        inputClass="w-100"
                        enableSearch
                        placeholder={`Phone Number`}
                        name="phoneNumber"
                        value={userData.phoneNumber}
                        onChange={(phone) => {
                          setUserData((prevDetails) => {
                            return {
                              ...prevDetails,
                              phoneNumber: phone,
                            };
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        maxLength="200"
                        type="email"
                        className="mb-3"
                        placeholder="E-mail"
                        value={userData.email}
                        name="email"
                        disabled
                        style={{ cursor: "no-drop" }}
                      />
                    </Form.Group>

                    <Form.Group className>
                      <Form.Label>{`About you`}</Form.Label>
                      <Form.Control
                        maxLength="400"
                        type="text"
                        as="textarea"
                        rows={4}
                        className="mb-3"
                        placeholder={`About you`}
                        value={userData.bio}
                        name="bio"
                        onChange={(event) => {
                          setUserData((prevDetails) => {
                            return {
                              ...prevDetails,
                              bio: event.target.value,
                            };
                          });
                        }}
                      />
                    </Form.Group>

                    <Form.Group className>
                      <Form.Label>LinkedIn</Form.Label>
                      <InputGroup>
                        {" "}
                        <span
                          class="input-group-text mb-3 border-0"
                          id="inputGroupPrepend3"
                        >
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className="text-dark"
                          />
                        </span>
                        <Form.Control
                          maxLength="500"
                          type="text"
                          className="mb-3"
                          placeholder="LinkedIn"
                          value={userData.linkedinURL}
                          name="linkedinURL"
                          onChange={(event) => {
                            setUserData((prevDetails) => {
                              return {
                                ...prevDetails,
                                linkedinURL: event.target.value,
                              };
                            });
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className>
                      <Form.Label>Twitter</Form.Label>
                      <InputGroup>
                        {" "}
                        <span
                          class="input-group-text mb-3 border-0"
                          id="inputGroupPrepend3"
                        >
                          <FontAwesomeIcon
                            icon={faTwitterSquare}
                            className="text-dark"
                          />
                        </span>
                        <Form.Control
                          maxLength="500"
                          type="text"
                          className="mb-3"
                          placeholder="Twitter"
                          value={userData.twitterURL}
                          name="twitterURL"
                          onChange={(event) => {
                            setUserData((prevDetails) => {
                              return {
                                ...prevDetails,
                                twitterURL: event.target.value,
                              };
                            });
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className>
                      <Form.Label>
                        {`Video Meeting Link`}
                        <p className="small text-secondary mb-0">
                          {`Video Meeting Link Helper`}
                        </p>
                      </Form.Label>
                      <Form.Control
                        maxLength="500"
                        type="url"
                        className="mb-3"
                        placeholder={`Video Meeting Link`}
                        value={userData.videoMeetingLink}
                        name="videoMeetingLink"
                        onChange={(event) => {
                          setUserData((prevDetails) => {
                            return {
                              ...prevDetails,
                              videoMeetingLink: event.target.value,
                            };
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className>
                      <Form.Label>{`Website`}</Form.Label>
                      <Form.Control
                        maxLength="500"
                        type="url"
                        className="mb-3"
                        placeholder={`Website`}
                        value={userData.websiteURL}
                        name="websiteURL"
                        onChange={(event) => {
                          setUserData((prevDetails) => {
                            return {
                              ...prevDetails,
                              websiteURL: event.target.value,
                            };
                          });
                        }}
                      />
                    </Form.Group>
                    <Button
                      className="form-control"
                      variant="primary"
                      type="submit"
                    >
                      {`Submit`}
                    </Button>
                  </Form>
                </div>
                <div className="col-md-6">
                  <div>
                    <p className="text-light">
                      Public Profile Template{" "}
                      <a
                        href={`${window.location.origin}/public-profile/${auth.currentUser.uid}`}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </a>
                    </p>
                  </div>
                  <div className="row mb-1">
                    {[
                      { name: "Legacy", value: "Template1" },
                      { name: "Latest", value: "Template2" },
                    ]?.map((e, idx) => (
                      <div key={idx} className="col-md-6">
                        <div>
                          <div
                            className={
                              selectedProfileTemplate === e.value
                                ? "border border-primary p-3 mb-2 w-100 pointer text-primary rounded-lg"
                                : "border p-3 mb-2 w-100 pointer text-white rounded-lg"
                            }
                            onClick={() =>
                              handleChangePublicProfileTemplate(e.value)
                            }
                            disabled={updatingProfileTemplate}
                          >
                            <p className="mb-0 lead d-flex align-items-center">
                              <span className="mr-2">
                                <FontAwesomeIcon
                                  icon={
                                    selectedProfileTemplate === e.value
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                  className="small"
                                />
                              </span>
                              <span>{e.name}</span>
                            </p>
                          </div>
                          <div>
                            <a
                              href={`${window.location.origin}/public-profile/${auth.currentUser.uid}?previewTemplate=${e.value}`}
                              target={"_blank"}
                              className="btn btn-sm btn-light w-100"
                            >
                              <FontAwesomeIcon icon={faEye} /> Preview
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="small text-light">
                    <b>Note:</b> Click on "Preview" button to see template's
                    preview of your "Profile".
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-100">

                <div className="container-fluid  h-100" style={{ overflow: "hidden" }}>

                    <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
                        <div
                            data-theme={darkTheme ? "dark" : "light"}
                            className="col-md-8 h-100 p-md-5 p-3 bg-graphite"
                            style={{ overflow: "scroll", backgroundColor: "#EFEFEF" }}
                        >
                            <div className="row col-md-10 mx-auto">
                                <div className="col-md-12 mb-3">
                                    <div className="card event-card border-0 h-100 p-4">
                                        <div className="d-flex flex-column align-items-center">
                                            {userData.avatar && (
                                                <div
                                                    style={{
                                                        background: `url(${getWhetherURLOrLocalFile(
                                                            userData.avatar
                                                        )}) 0% 0% / cover no-repeat`,
                                                        height: "150px",
                                                        width: "150px",
                                                    }}
                                                    className="rounded-circle mx-5"
                                                />
                                            )}
                                            {userData.firstName && (
                                                <h3 className="text-s-6 text-dark mt-3">
                                                    {userData.firstName ? (
                                                        <>
                                                            {userData.firstName}{" "}
                                                            <>
                                                                {userData.lastName && (
                                                                    <span>{userData.lastName}</span>
                                                                )}
                                                            </>
                                                        </>
                                                    ) : (
                                                        "Your name"
                                                    )}
                                                </h3>
                                            )}
                                            <p className="mb-3 text-dark">
                                                {userData.email ? userData.email : null}
                                            </p>
                                            <div className="d-flex mb-2">
                                                {userData.linkedinURL ? (
                                                    <>
                                                        <a
                                                            className="mr-3"
                                                            rel="noreferrer noopener"
                                                            target="_blank"
                                                            href={userData.linkedinURL}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faLinkedin}
                                                                className="text-primary"
                                                                style={{ fontSize: 20 }}
                                                            />
                                                        </a>
                                                    </>
                                                ) : null}

                                                {userData.twitterURL ? (
                                                    <>
                                                        <a
                                                            className="mr-3"
                                                            rel="noreferrer noopener"
                                                            target="_blank"
                                                            href={userData.twitterURL}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTwitterSquare}
                                                                className="text-primary"
                                                                style={{ fontSize: 20 }}
                                                            />
                                                        </a>
                                                    </>
                                                ) : null}

                                                {userData.videoMeetingLink ? (
                                                    <a
                                                        className="mr-3"
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                        href={userData.videoMeetingLink}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faVideo}
                                                            className="text-primary"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                    </a>
                                                ) : null}

                                                {userData.websiteURL ? (
                                                    <a
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                        href={userData.websiteURL}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                            className="text-primary"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                    </a>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="card-body h-100 p-0 bg-white">
                                            <p className="mb-3 text-dark text-center">
                                                {userData.bio ? parse(userData.bio) : null}
                                            </p>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-4 h-100 p-3"
                            style={{ overflow: "scroll" }}
                            data-theme={darkTheme ? "dark" : "light"}
                        >
                            <div className="shadow-sm p-2  mb-3">
                                <h5 className="mb-0">Enter Your Details</h5>
                            </div>
                            <Form onSubmit={(e) => saveEventDataToDBHandler(e, userData)}>
                                <Form.Group className="col-md-12">
                                    <Form.Label>{`Update Profile Picture`}</Form.Label>
                                    <Form.Control
                                        type="file"
                                        className="mb-3"
                                        accept="image/*"
                                        placeholder="Profile Picture"
                                        name="avatar"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    avatar: event.target.files[0],
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`First Name`}</Form.Label>
                                    <Form.Control
                                        maxLength="150"
                                        type="text"
                                        className="mb-3"
                                        placeholder={`First Name`}
                                        value={userData.firstName}
                                        name="firstName"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    firstName: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`Last Name`}</Form.Label>
                                    <Form.Control
                                        maxLength="150"
                                        type="text"
                                        className="mb-3"
                                        placeholder={`Last Name`}
                                        value={userData.lastName}
                                        name="lastName"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    lastName: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`Phone Number`}</Form.Label>
                                    <PhoneInput
                                        inputClass="w-100"
                                        enableSearch
                                        placeholder={`Phone Number`}
                                        name="phoneNumber"
                                        value={userData.phoneNumber}
                                        onChange={(phone) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    phoneNumber: phone,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className>
                                    <Form.Label>{`About you`}</Form.Label>
                                    <Form.Control
                                        maxLength="400"
                                        type="text"
                                        as="textarea"
                                        rows={4}
                                        className="mb-3"
                                        placeholder={`About you`}
                                        value={userData.bio}
                                        name="bio"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    bio: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className>
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        maxLength="200"
                                        type="email"
                                        className="mb-3"
                                        placeholder="E-mail"
                                        value={userData.email}
                                        name="email"
                                        disabled
                                        style={{ cursor: "no-drop" }}
                                    />
                                </Form.Group>

                                <Form.Group className>
                                    <Form.Label>LinkedIn</Form.Label>
                                    <InputGroup>
                                        {" "}
                                        <span
                                            class="input-group-text mb-3 border-0"
                                            id="inputGroupPrepend3"
                                        >
                                            <FontAwesomeIcon
                                                icon={faLinkedin}
                                                className="text-dark"
                                            />
                                        </span>
                                        <Form.Control
                                            maxLength="500"
                                            type="text"
                                            className="mb-3"
                                            placeholder="LinkedIn"
                                            value={userData.linkedinURL}
                                            name="linkedinURL"
                                            onChange={(event) => {
                                                setUserData((prevDetails) => {
                                                    return {
                                                        ...prevDetails,
                                                        linkedinURL: event.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>Twitter</Form.Label>
                                    <InputGroup>
                                        {" "}
                                        <span
                                            class="input-group-text mb-3 border-0"
                                            id="inputGroupPrepend3"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTwitterSquare}
                                                className="text-dark"
                                            />
                                        </span>
                                        <Form.Control
                                            maxLength="500"
                                            type="text"
                                            className="mb-3"
                                            placeholder="Twitter"
                                            value={userData.twitterURL}
                                            name="twitterURL"
                                            onChange={(event) => {
                                                setUserData((prevDetails) => {
                                                    return {
                                                        ...prevDetails,
                                                        twitterURL: event.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>
                                        {`Video Meeting Link`}
                                        <p className="small text-secondary mb-0">
                                            {`Video Meeting Link Helper`}
                                        </p>
                                    </Form.Label>
                                    <Form.Control
                                        maxLength="500"
                                        type="url"
                                        className="mb-3"
                                        placeholder={`Video Meeting Link`}
                                        value={userData.videoMeetingLink}
                                        name="videoMeetingLink"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    videoMeetingLink: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`Website`}</Form.Label>
                                    <Form.Control
                                        maxLength="500"
                                        type="url"
                                        className="mb-3"
                                        placeholder={`Website`}
                                        value={userData.websiteURL}
                                        name="websiteURL"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    websiteURL: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Button
                                    className="form-control"
                                    variant="primary"
                                    type="submit"
                                >
                                    {`Submit`}
                                </Button>

                            </Form>
                        </div>
                    </div>
                </div>

                

                <div className="container d-block d-md-none">
                    <EventNavbar />
                    <div className="row">
                        <div
                            className="col-md-12"
                            style={{ overflow: "scroll", backgroundColor: "#EFEFEF" }}
                        >
                            <div className="mt-3">
                                <div className="mb-3">
                                    
                                    <div className="card event-card border-0 h-100 p-4">
                                        <div className="d-flex flex-column align-items-center">
                                            {userData.avatar && (
                                                <div
                                                    style={{
                                                        background: `url(${getWhetherURLOrLocalFile(
                                                            userData.avatar
                                                        )}) 0% 0% / cover no-repeat`,
                                                        height: "150px",
                                                        width: "150px",
                                                    }}
                                                    className="rounded-circle mx-5"
                                                />
                                            )}
                                            {userData.firstName && (
                                                <h3 className="text-s-6 text-dark mt-3">
                                                    {userData.firstName ? (
                                                        <>
                                                            {userData.firstName}{" "}
                                                            <>
                                                                {userData.lastName && (
                                                                    <span>{userData.lastName}</span>
                                                                )}
                                                            </>
                                                        </>
                                                    ) : (
                                                        "Your name"
                                                    )}
                                                </h3>
                                            )}
                                            <p className="mb-3 text-dark">
                                                {userData.email ? userData.email : null}
                                            </p>
                                            <div className="d-flex mb-2">
                                                {userData.linkedinURL ? (
                                                    <>
                                                        <a
                                                            className="mr-3"
                                                            rel="noreferrer noopener"
                                                            target="_blank"
                                                            href={userData.linkedinURL}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faLinkedin}
                                                                className="text-primary"
                                                                style={{ fontSize: 20 }}
                                                            />
                                                        </a>
                                                    </>
                                                ) : null}

                                                {userData.twitterURL ? (
                                                    <>
                                                        <a
                                                            className="mr-3"
                                                            rel="noreferrer noopener"
                                                            target="_blank"
                                                            href={userData.twitterURL}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTwitterSquare}
                                                                className="text-primary"
                                                                style={{ fontSize: 20 }}
                                                            />
                                                        </a>
                                                    </>
                                                ) : null}

                                                {userData.videoMeetingLink ? (
                                                    <a
                                                        className="mr-3"
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                        href={userData.videoMeetingLink}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faVideo}
                                                            className="text-primary"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                    </a>
                                                ) : null}

                                                {userData.websiteURL ? (
                                                    <a
                                                        rel="noreferrer noopener"
                                                        target="_blank"
                                                        href={userData.websiteURL}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLink}
                                                            className="text-primary"
                                                            style={{ fontSize: 20 }}
                                                        />
                                                    </a>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="card-body h-100 p-0 bg-white">
                                            <p className="mb-3 text-dark text-center">
                                                {userData.bio ? parse(userData.bio) : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shadow-sm p-2 bg-light border-bottom">
                                <h5 className="mb-0">{`Enter Your Details`}</h5>
                            </div>
                            <Form
                                className="bg-white p-2"
                                style={{ marginBottom: "10vh" }}
                                onSubmit={(e) => saveEventDataToDBHandler(e, userData)}
                            >
                                <Form.Group className="">
                                    <Form.Label>{`Upload Profile Picture`}</Form.Label>
                                    <Form.Control
                                        type="file"
                                        className="mb-3"
                                        accept="image/*"
                                        placeholder="Profile Picture"
                                        name="avatar"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    avatar: event.target.files[0],
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`First Name`}</Form.Label>
                                    <Form.Control
                                        maxLength="150"
                                        type="text"
                                        className="mb-3"
                                        placeholder={`First Name`}
                                        value={userData.firstName}
                                        name="firstName"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    firstName: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`lastName`}</Form.Label>
                                    <Form.Control
                                        maxLength="150"
                                        type="text"
                                        className="mb-3"
                                        placeholder={`lastName`}
                                        value={userData.lastName}
                                        name="lastName"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    lastName: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`Phone Number`}</Form.Label>
                                    <PhoneInput
                                        inputClass="w-100"
                                        enableSearch
                                        placeholder={`Phone Number`}
                                        name="phoneNumber"
                                        value={userData.phoneNumber}
                                        onChange={(phone) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    phoneNumber: phone,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className>
                                    <Form.Label>{`About You`}</Form.Label>
                                    <Form.Control
                                        maxLength="400"
                                        type="text"
                                        as="textarea"
                                        rows={4}
                                        className="mb-3"
                                        placeholder={`About You`}
                                        value={userData.bio}
                                        name="bio"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    bio: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className>
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        maxLength="200"
                                        type="email"
                                        className="mb-3"
                                        placeholder="E-mail"
                                        value={userData.email}
                                        name="email"
                                        disabled
                                        style={{ cursor: "no-drop" }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>LinkedIn</Form.Label>
                                    <InputGroup>
                                        {" "}
                                        <span
                                            class="input-group-text mb-3 border-0"
                                            id="inputGroupPrepend3"
                                        >
                                            <FontAwesomeIcon
                                                icon={faLinkedin}
                                                className="text-dark"
                                            />
                                        </span>
                                        <Form.Control
                                            maxLength="500"
                                            type="text"
                                            className="mb-3"
                                            placeholder="LinkedIn"
                                            value={userData.linkedinURL}
                                            name="linkedinURL"
                                            onChange={(event) => {
                                                setUserData((prevDetails) => {
                                                    return {
                                                        ...prevDetails,
                                                        linkedinURL: event.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>Twitter</Form.Label>
                                    <InputGroup>
                                        {" "}
                                        <span
                                            class="input-group-text mb-3 border-0"
                                            id="inputGroupPrepend3"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTwitterSquare}
                                                className="text-dark"
                                            />
                                        </span>
                                        <Form.Control
                                            maxLength="500"
                                            type="text"
                                            className="mb-3"
                                            placeholder="Twitter"
                                            value={userData.twitterURL}
                                            name="twitterURL"
                                            onChange={(event) => {
                                                setUserData((prevDetails) => {
                                                    return {
                                                        ...prevDetails,
                                                        twitterURL: event.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>
                                        {`Video Meeting Link`}
                                        <p className="small text-secondary mb-0">
                                            {`Video Meeting Link Helper`}
                                        </p>
                                    </Form.Label>
                                    <Form.Control
                                        maxLength="500"
                                        type="url"
                                        className="mb-3"
                                        placeholder={`Video Meeting Link`}
                                        value={userData.videoMeetingLink}
                                        name="videoMeetingLink"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    videoMeetingLink: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className>
                                    <Form.Label>{`website`}</Form.Label>
                                    <Form.Control
                                        maxLength="500"
                                        type="url"
                                        className="mb-3"
                                        placeholder={`website`}
                                        value={userData.websiteURL}
                                        name="websiteURL"
                                        onChange={(event) => {
                                            setUserData((prevDetails) => {
                                                return {
                                                    ...prevDetails,
                                                    websiteURL: event.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Form.Group>
                                <Button
                                    className="form-control"
                                    variant="primary"
                                    type="submit"
                                >
                                    {`Submit`}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default connect(
  (state) => (
    { currentLanguage: state.currentLanguage }, { darkTheme: state.darkTheme }
  )
)(UserProfileUpdate);
