import HTMLReactParser from "html-react-parser";
import React from "react";
import ReactPlayer from "react-player";

export default function RecordedLecture(props) {
  // CONSTANTS
  const { resource_details, sub_module_details } = props;

  return (
    <div>
      <div className="embed-responsive embed-responsive-16by9">
        <ReactPlayer
          controls
          stopOnUnmount
          width="100%"
          height="auto"
          playing={false}
          muted
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                noDownload: true,
                controlsList: "nodownload noremoteplayback",
              },
            },
          }}
          url={resource_details?.file_url}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <p className="lead">{resource_details?.file_name}</p>
      {sub_module_details?.description && (
        <div>{HTMLReactParser(sub_module_details?.description)}</div>
      )}
    </div>
  );
}
