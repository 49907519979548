import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  Card,
  Dropdown,
  Form,
  FormCheck,
  Modal,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faThLarge,
  faRedo,
  faPen,
  faCheck,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useDocument } from "react-firebase-hooks/firestore";

import QuizFields from "./QuizFields";
import Quiz from "./Quiz";
import QuizCreate from "../CourseBuilder/SubModuleAdd/Quiz/Create";
import {
  API_BASE_URL,
  INVITE_PROCESS,
  ADMIN_GET_APP_LIST,
} from "../../../../config";
import { postData } from "../../../../utils";
import { auth, firestore } from "../../../../firebase";
import UserProfile from "../UserProfile";

function Quizzes(props) {
  // CONSTANTS
  const user = auth.currentUser;
  const { eventId, currentEvent } = props;
  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firestore.collection("events").doc(eventId)
  );

  // STATES
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomLinked, setRoomLinked] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const [fetchingQuizzes, setFetchingQuizzes] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [filter, setFilter] = useState("");
  const [showMoreDetailsPanel, setShowMoreDetailsPanel] = useState(false);
  const [selectedMoreDetails, setSelectedMoreDetails] = useState(null);

  // FUNCTIONS
  const processRoomInvite = (inviteProcessData) => {
    axios
      .post(`https://api.igesia.com${INVITE_PROCESS}`, inviteProcessData)
      .then((response) => {
        console.log("Invite processing: ", response?.response?.data?.message);
      })
      .catch((error) => {
        console.error(
          "Error processing invite: ",
          error?.response?.data?.message
        );
      });
  };

  const handleFetchQuizzes = async () => {
    try {
      const response = await postData(`${API_BASE_URL}${ADMIN_GET_APP_LIST}`, {
        event_id: eventId,
        user_id: auth.currentUser.uid,
        types: ["quiz"],
      });

      if (response.status === 200) {
        const { data } = response;

        setQuizzes(data.quizList);
        setFetchingQuizzes(false);
      } else {
        setFetchingQuizzes(false);
      }
    } catch (error) {
      console.log("[Error] Fetching quizzes: ", error);
      setFetchingQuizzes(false);
    }
  };

  const handleOpenItem = (resource) => {
    setShowItemDetails(true);
    setSelectedItem(resource);
    handleCloseAddItem();
    setShowMoreDetailsPanel(false);
    setSelectedMoreDetails(null);
  };

  const handleCloseItem = () => {
    setShowItemDetails(false);
    setSelectedItem(null);
    setShowMoreDetailsPanel(false);
    setSelectedMoreDetails(null);
  };

  const handleCloseMoreDetails = () => {
    setShowMoreDetailsPanel(false);
    setSelectedMoreDetails(null);
  };

  const handleOpenAddItem = () => {
    setShow(true);
    handleCloseItem();
  };

  const handleCloseAddItem = () => {
    setShow(false);
  };

  useEffect(() => {
    if (
      currentEvent?.workspace_id &&
      currentEvent?.room_id &&
      currentEvent?.room_invite_code
    ) {
      setRoomLinked(true);
      setLoading(false);

      const inviteProcessData = {
        invite_code: currentEvent?.room_invite_code,
        user_id: user?.uid,
      };
      processRoomInvite(inviteProcessData);
    } else {
      setLoading(false);
    }

    // Fetch: Quizzes
    handleFetchQuizzes();
  }, []);

  const handleRefresh = () => {
    // Fetch: Quizzes
    handleFetchQuizzes();
  };

  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center text-white p-3">
        <Spinner animation="border" size="sm" className="mr-2" /> Loading...
      </div>
    );

  if (roomLinked)
    return (
      <div className="p-3" style={{ height: "100vh", overflowY: "auto" }}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <h4 className="text-white">Quizzes</h4>
          </div>

          <div className="col-md-12 mb-3">
            <Card
              style={{ width: "100%" }}
              className="shadow-sm bg-dark shadow-sm mb-3 text-white"
            >
              <Card.Header>
                <Card.Title className="mb-0">Settings</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <FontAwesomeIcon icon={faTasks} className="mx-4 btn-hero" />
                  <div className="d-flex flex-column">
                    <span className="text-white small font-weight-bold text-uppercase">
                      Show Quizzes button
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id="showQuizzes"
                        label={
                          eventSnapshot?.data()?.quiz_enabled ? "On" : "Off"
                        }
                        checked={
                          eventSnapshot?.data()?.quiz_enabled ? true : false
                        }
                        onChange={(e) =>
                          firestore.collection("events").doc(eventId).update({
                            quiz_enabled: e.target.checked,
                          })
                        }
                      ></FormCheck>
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div
            className={
              showItemDetails || show
                ? showMoreDetailsPanel
                  ? "col-md-4"
                  : "col-md-6"
                : "col-md-12"
            }
          >
            <div className="text-white border border-secondary">
              <div className="d-flex align-items-end justify-content-between px-3 py-2">
                <div className="d-flex flex-column justify-content-start">
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <span className="text-capitalize">
                        {filter === "" ? (
                          <span>
                            <FontAwesomeIcon
                              icon={faThLarge}
                              className="mr-2"
                            />
                            View all
                          </span>
                        ) : filter === "graded" ? (
                          <span>
                            <FontAwesomeIcon icon={faPen} className="mr-2" />
                            Graded
                          </span>
                        ) : (
                          <span>
                            <span
                              className="mr-2"
                              style={{ position: "relative" }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                size="sm"
                                style={{ position: "absolute" }}
                              />
                              <FontAwesomeIcon icon={faPen} />
                            </span>
                            Not Graded
                          </span>
                        )}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        style={
                          filter === ""
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("")}
                      >
                        <FontAwesomeIcon icon={faThLarge} className="mr-2" />
                        View all
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={
                          filter === "graded"
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("graded")}
                      >
                        <FontAwesomeIcon icon={faPen} className="mr-2" />
                        Graded
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={
                          filter === "not-graded"
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("not-graded")}
                      >
                        <span className="mr-2" style={{ position: "relative" }}>
                          <FontAwesomeIcon
                            icon={faTimes}
                            size="sm"
                            style={{ position: "absolute" }}
                          />
                          <FontAwesomeIcon icon={faPen} />
                        </span>
                        Not Graded
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex align-items-end">
                  {fetchingQuizzes && (
                    <div className="mr-3">
                      <p className="mb-0">Fetching...</p>
                    </div>
                  )}
                  {!showMoreDetailsPanel && (
                    <Button
                      variant="light"
                      size="sm"
                      onClick={handleRefresh}
                      disabled={fetchingQuizzes}
                      className="mr-3"
                    >
                      {fetchingQuizzes ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <FontAwesomeIcon icon={faRedo} />
                      )}{" "}
                      Refresh
                    </Button>
                  )}
                  <Button
                    size="sm"
                    className="text-uppercase"
                    onClick={handleOpenAddItem}
                  >
                    Add Quiz
                  </Button>
                </div>
              </div>
              <table class="table table-borderless text-white">
                <thead className="border-top border-secondary">
                  <tr className="">
                    <th className="py-1 font-weight-bold small">#</th>
                    <th className="py-1 font-weight-bold small">Name</th>
                    {!showMoreDetailsPanel && (
                      <th className="py-1 font-weight-bold small">Deadline</th>
                    )}
                    {!showMoreDetailsPanel && (
                      <th className="py-1 font-weight-bold small text-center">
                        Grade points
                      </th>
                    )}
                    {!showItemDetails && (
                      <th className="py-1 font-weight-bold small text-center">
                        Created
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {quizzes
                    ?.filter((e) => {
                      if (filter === "") return true;
                      else if (filter === "graded") {
                        return e.is_graded;
                      } else {
                        return !e?.is_graded;
                      }
                    })
                    ?.map((item, key) => (
                      <tr
                        className="border-top border-secondary align-items-center pointer"
                        key={key}
                        onClick={() => handleOpenItem(item)}
                        style={
                          selectedItem?.id === item.id
                            ? {
                                fontSize: "90%",
                                backgroundColor: "rgba(200, 200, 200, 0.15)",
                              }
                            : { fontSize: "90%" }
                        }
                      >
                        <td>{key + 1}</td>
                        <td>{item?.title}</td>
                        {!showMoreDetailsPanel && (
                          <td>{moment(item?.deadline).fromNow()}</td>
                        )}
                        {!showMoreDetailsPanel && (
                          <td className="text-center">
                            {item?.is_graded
                              ? item?.total_points
                                ? `${item?.total_points} points`
                                : "-"
                              : "NA"}
                          </td>
                        )}
                        {!showItemDetails && (
                          <td className="text-center">
                            {moment(item?.created_at).format("LLL")}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Section: Show selected Item */}
          {showItemDetails && selectedItem && (
            <div
              className={
                showMoreDetailsPanel
                  ? "col-md-4 p-0 m-0 h-100"
                  : "col-md-6 p-0 m-0 h-100"
              }
            >
              <Quiz
                handleCloseItem={handleCloseItem}
                handleRefresh={handleRefresh}
                setShowMoreDetailsPanel={setShowMoreDetailsPanel}
                showMoreDetailsPanel={showMoreDetailsPanel}
                setSelectedMoreDetails={setSelectedMoreDetails}
                selectedMoreDetails={selectedMoreDetails}
                className="text-white"
                data={{
                  contentId: selectedItem.content_id,
                  ...selectedItem,
                }}
              />
            </div>
          )}

          {/* Section: Show More Details */}
          {showItemDetails &&
            selectedItem &&
            showMoreDetailsPanel &&
            selectedMoreDetails && (
              <div className="col-md-4 p-0 m-0 h-100">
                <div className="border border-secondary text-white mr-3">
                  <div className="p-3 d-flex align-items-center border-bottom border-secondary">
                    <UserProfile
                      userDetails={selectedMoreDetails?.response?.user_details}
                      imgSize={40}
                      subText={moment(
                        selectedMoreDetails?.response?.responseTimestamp
                      ).format("LLL")}
                      className="flex-grow-1"
                    />
                    <div>
                      <a
                        className="text-white pointer p-2"
                        onClick={handleCloseMoreDetails}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </a>
                    </div>
                  </div>
                  <div className="p-3">
                    {Object.keys(selectedMoreDetails?.quizData?.quizQuestions)
                      .length > 0 &&
                      Object.keys(selectedMoreDetails?.quizData?.quizQuestions)
                        .sort(
                          (a, b) =>
                            selectedMoreDetails?.quizData?.quizQuestions[a]
                              .sequenceNo -
                            selectedMoreDetails?.quizData?.quizQuestions[b]
                              .sequenceNo
                        )
                        .map((item, key) => (
                          <>
                            <p className="mb-2" key={key}>
                              <span className="font-weight-bold">
                                Q{key + 1}.
                              </span>{" "}
                              {
                                selectedMoreDetails?.quizData?.quizQuestions[
                                  item
                                ].question
                              }{" "}
                              {selectedMoreDetails?.quizData?.isGraded && (
                                <>
                                  (
                                  {
                                    selectedMoreDetails?.quizData
                                      ?.quizQuestions[item].weight
                                  }{" "}
                                  points)
                                </>
                              )}
                            </p>
                            {Object.entries(
                              selectedMoreDetails?.quizData?.quizQuestions[item]
                                .options
                            )
                              .sort((a, b) => a[1].sequenceNo - b[1].sequenceNo)
                              ?.map((v, k) => (
                                <div key={k}>
                                  <Form.Group>
                                    <Form.Check
                                      type="checkbox"
                                      label={
                                        selectedMoreDetails?.response?.answers[
                                          item
                                        ]?.options[v[0]]
                                          ? selectedMoreDetails?.response
                                              ?.answers[item]?.options[v[0]]
                                              ?.correct
                                            ? `${v[1].optionValue} (Correct)`
                                            : `${v[1].optionValue} (Incorrect)`
                                          : v[1].correctAnswer
                                          ? `${v[1].optionValue} (Correct)`
                                          : v[1].optionValue
                                      }
                                      checked={
                                        selectedMoreDetails?.response?.answers[
                                          item
                                        ]?.options[v[0]]
                                          ? true
                                          : false
                                      }
                                      readOnly
                                      className={
                                        selectedMoreDetails?.response?.answers[
                                          item
                                        ]?.options[v[0]]
                                          ? selectedMoreDetails?.response
                                              ?.answers[item]?.options[v[0]]
                                              ?.correct
                                            ? "text-success"
                                            : "text-danger"
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              ))}
                            {selectedMoreDetails?.response?.answers[item]
                              ?.correct ? (
                              <p className="text-success">
                                <FontAwesomeIcon icon={faCheck} /> Correct
                                answer
                              </p>
                            ) : (
                              <p className="text-danger">
                                <FontAwesomeIcon icon={faTimes} /> Incorrect
                                answer
                              </p>
                            )}
                          </>
                        ))}
                  </div>
                </div>
              </div>
            )}

          {/* Section: Add Item */}
          {show && (
            <div className="col-md-6 p-0 m-0 h-100">
              <div className="border border-secondary text-white mr-3">
                <div className="p-3 d-flex align-items-center border-bottom border-secondary">
                  <h5 className="flex-grow-1 mb-0">Add Quiz</h5>
                  <a
                    className="text-white pointer p-2"
                    onClick={handleCloseAddItem}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </a>
                </div>
                <div className="p-1">
                  <QuizCreate module_id={null} handleRefresh={handleRefresh} />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Quiz</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 m-0">
            <QuizFields onHide={handleClose} />
          </Modal.Body>
        </Modal> */}
      </div>
    );
  else {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <p className="text-white p-4">
          To request access of Quiz app, please email us at{" "}
          <a href="mailto:hello@igesia.co" target="_blank">
            hello@igesia.co
          </a>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(Quizzes);
