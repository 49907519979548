// import React from 'react';
// import { useCanvas } from './useCanvas';
// // import './App.css';
// function Tier() {
//   const [ coordinates, setCoordinates, canvasRef, canvasWidth, canvasHeight ] = useCanvas();
//   const handleCanvasClick=(event)=>{
//     // on each click get current mouse location
//     console.log(event)
//     const currentCoord = { x: event.clientX, y: event.clientY };
//     // add the newest mouse location to an array in state
//     console.log(currentCoord)
//     setCoordinates([...coordinates, currentCoord]);
//   };
//   const handleClearCanvas=(event)=>{
//     setCoordinates([]);
//   };
//   return (
//     <main className="" >
//         <h1 className="p-5">Tiers of Customers</h1>
//       <canvas
//         className=" border border-secondary p-5"
//         ref={canvasRef}
//         width={canvasWidth}
//         height={canvasHeight}
//         onClick={handleCanvasClick} />
//       <div className="button" >
//         <button onClick={handleClearCanvas} > CLEAR </button>
//       </div>
//     </main>
//   );
// };
// export default Tier;





// import { values } from 'lodash';
// import React from 'react';
// class Tier extends React.Component {
//     state ={
//         coordinates:[]
//     }
//     componentDidMount() {
//         this.updateCanvas();
//     }
//     draw(ctx, location){
//         console.log("attempting to draw")
//         ctx.font = "15px Arial";
//         ctx.strokeText(`${JSON.stringify(location)}`, location.x, location.y);
//     };
//     updateCanvas() {
//         const ctx = this.refs.canvas.getContext('2d');
//         ctx.beginPath();
//         ctx.ellipse(300, 300, 270, 135, Math.PI / 1, 0, 2 * Math.PI);
//         ctx.ellipse(210, 300, 180, 90, Math.PI / 1, 0, 2 * Math.PI);
//         ctx.ellipse(120, 300, 90, 45, Math.PI / 1, 0, 2 * Math.PI);
//         ctx.stroke();
//         this.state.coordinates.forEach((coordinate)=>{this.draw(ctx, coordinate)});
//     }
//     handleCanvasClick=(event)=>{
//         // on each click get current mouse location
//         const currentCoord = { x: event.clientX, y: event.clientY };
//         // add the newest mouse location to an array in state
//         // setCoordinates([...coordinates, currentCoord]);
//         console.log(JSON.stringify(currentCoord));
//         this.setState({ coordinates: [...this.state.coordinates, currentCoord] });
//         //const ctx = this.refs.canvas.getContext('2d');
//         //coordinates.forEach((coordinate)=>{draw(ctx, coordinate)}
//       };
//     render() {
//         return (
//             <div >
//                 {console.log(this.state.coordinates)}
//                 <h1 classname="mb-3">Tiers of customers</h1>
//                 <canvas className="border border-secondary m-5" ref="canvas" width={600} height={600} onClick={(e) => this.handleCanvasClick(e)}/>
//             </div>
//         );
//     }
// }
// export default Tier;



import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from 'firebase';
import { TwitterPicker } from "react-color";

function Tier(props) {
  const ref = useRef(null)
  const [currentCords, setCurrentCords] = useState([{ x: 0, y: 0 }]);

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [cursorPositionContainer, setCursorPositionContainer] = useState({
    x: 0,
    y: 0,
  });

  const [coordinateValue, loading, error] = useCollection(
    firebase.firestore().collection(`events/${props?.currentEvent?.id}/bbos/${props.graph.id}/three_tiers/`),
  );

  const [calculatedHeight, setCalculatedHeight] = useState(0)
  const [calculatedWidth, setCalculatedWidth] = useState(0)
  const [loadingSvg, setLoadingSvg] = useState(true)
  useEffect(() => {
    if (ref) {
      if (ref?.current?.clientWidth) {
        setCalculatedHeight(ref.current.clientWidth * 560 / 900);
        setCalculatedWidth(ref.current.clientWidth);
        setLoadingSvg(false)
      }
    }
  }, [ref?.current?.clientWidth, ref?.current?.clientHeight])

  const coordinateArray = coordinateValue?.docs
    ?.map((item) => {
      return { ...item.data(), id: item.id };
    });

  useEffect(() => {
    if (coordinateValue) {
      let data = coordinateArray;
      setCurrentCords(data)
    }
  }, [JSON.stringify(coordinateValue)])




  const _onMouseMove = (e) => {
    setCursorPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const _onMouseMoveContainer = (e) => {
    setCursorPositionContainer({ x: e.screenX, y: e.screenY });
  };

  return (
    <>
      <div className="container" onMouseMove={_onMouseMoveContainer}>
        <div
          className="bg-light shadow-sm border rounded w-100"
          ref={ref}
          style={{
            heigt: calculatedHeight
          }}
          // style={{ height: 560, width: 900 }}
          onMouseMove={_onMouseMove}
        >
          {!loadingSvg && <svg
            // width="900"
            // height="560"
            width={calculatedWidth}
            height={calculatedHeight}
            //onClick={(event) => eventHandler(event)}
          >
            <ellipse
             //onClick={(event) => eventHandler(event, "Third Tier")}
              cx={calculatedWidth / 2 - ((calculatedWidth * 350) / 900) * 0.2}
              cy={calculatedHeight / 2}
              rx={(calculatedWidth * 350) / 900}
              ry={(calculatedHeight * 200) / 560}
              fill="white"
              stroke="black"
              stroke-width="1px"
            />
            
            <ellipse
            // onClick={(event) => eventHandler(event, "Second Tier")}
              cx={calculatedWidth / 2 - ((calculatedWidth * 240) / 900) * 0.75}
              cy={calculatedHeight / 2}
              rx={(calculatedWidth * 240) / 900}
              ry={(calculatedHeight * 120) / 560}
              fill="white"
              stroke="black"
              stroke-width="1px"
            />

            <ellipse
            //   onClick={(event) => eventHandler(event, "First Tier")}
              style={{zIndex:"10"}}
              cx={calculatedWidth / 2 - ((calculatedWidth * 50) / 900) * 7.4}
              cy={calculatedHeight / 2}
              rx={(calculatedWidth * 50) / 900}
              ry={(calculatedWidth * 50) / 900}
              stroke="black"
              stroke-width="1px"
              fill="white"
            />
            

            {currentCords?.map((item, index) => (
              <text
                x={`${(item.x / 100) * ref.current?.clientHeight}`}
                y={`${(item.y / 100) * ref.current?.clientWidth}`}
                fill={item.color ? item.color : "red"}
                style={{ fontSize: "14px", cursor: "default" }}
                data-toggle="tooltip" title={item?.created_by?.name} data-placement="bottom"
              >
                ◉ {item.value}
              </text>
            ))}
          </svg>}
        </div>
       
      </div>
     
    </>
  );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    currentEvent: state.currentEvent,
  }),
  null
)(Tier);
