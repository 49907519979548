import React from "react";
import { useParams } from "react-router";
import Chat from "./Chat";
import ClassNavbar from "./ClassNavbar";

import * as CourseStyle from "../course.module.css";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Live from "./Live";

function Class() {
  const { class_type } = useParams();
  const class_details = { name: "What is AI?" };

  if (class_type === "live") return <Live class_type={class_type} />;

  return (
    <div className="h-100">
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <ClassNavbar
          class_type={class_type}
          class_details={{ name: "What is AI?" }}
        />
        <div
          className={`bg-graphite row`}
          style={{ height: "93vh", overflow: "hidden" }}
        >
          <div className="col-md-8 h-100 w-100">
            <div className="d-flex align-items-center justify-content-center h-100 w-100">
              <Card className="rounded-lg">
                <Card.Body className="px-5 py-4">
                  <h5 className="font-weight-bold text-center mb-4">
                    Welcome Jatin Ahuja
                  </h5>
                  <p className="text-muted">
                    Ensure following for a smoother experience during the
                    session:
                  </p>
                  <p className="text-muted">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                    />{" "}
                    A stable network connection with{" "}
                    <b className="text-dark">&gt; 500kbps</b> download speed.
                  </p>
                  <p className="text-muted">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                    />{" "}
                    <b className="text-dark">Disable any VPN</b> or proxy
                    setting you might be.
                  </p>
                  <p className="text-dark font-weight-bold text-center">
                    {class_details?.name} (
                    {class_type === "live" ? "Live" : "Archive"})
                  </p>
                  <Button variant="dark" className="w-100">
                    <span className="text-uppercase font-weight-bold small">
                      Watch Recording
                    </span>
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>

          {/* Chat module start */}
          <div
            className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
            style={{ overflow: "scroll" }}
          >
            <Chat />
          </div>
          {/* Chat module end */}
        </div>
      </div>
    </div>
  );
}

export default Class;
