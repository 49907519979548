import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
  Spinner,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import Schedules from "./schedules.json";
import firebase from "firebase";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { useHistory, useLocation } from "react-router";
import produce from "immer";
import { connect } from "react-redux";
import { changeLanguage } from "../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import "@culturehq/add-to-calendar/dist/styles.css";
import Category from "./Category";
import DraggableList from "./DraggableList";

toast.configure();

const Schedule = (props) => {
  const { currentLanguage, changeLanguage } = props;

  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("clinics").doc("EKWsp2FDV2aWp1F6P963")
  );

  const [scheduleSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("/clinics/EKWsp2FDV2aWp1F6P963/schedule")
      .orderBy("starts_at", "asc")
  );
  const [speakersSnapshot, loadingSpeakers, errorSpeakers] = useCollection(
    firebase.firestore().collection("/clinics/EKWsp2FDV2aWp1F6P963/speakers")
  );
  const [stageSnapshot, loadingStage, errorStage] = useCollection(
    firebase.firestore().collection("/clinics/EKWsp2FDV2aWp1F6P963/stages")
  );
  const [categorySnapshot, loadingCategory, errorCategory] = useCollection(
    firebase
      .firestore()
      .collection("/clinics/EKWsp2FDV2aWp1F6P963/categories")
      .orderBy("order_index", "asc")
  );

  const sortedCategories = categorySnapshot?.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));

  const [saving, setSaving] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showReorderCategory, setShowReorderCategory] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [selectedUID, setSelectedUID] = useState(null);
  const [selectedMeetingID, setSelectedMeetingID] = useState("");

  const stageOptions =
    stageSnapshot?.docs.map((stage) => ({
      value: stage.id,
      label: stage.data().stage_name,
      slug: stage.data().slug,
    })) ?? [];

  const categoryOptions = categorySnapshot?.docs.map((category) => ({
    value: category.id,
    label: category.data().title,
  }));

  const [scheduleData, setScheduleData] = useState({});

  const saveTextDataToStateHandler = (e, lang) => {
    setScheduleData((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [e.target.name]: e.target.value
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, ""),
        };
      });
      return updatedState;
    });
  };

  const saveDateToStateHandler = (selectedDate, dateStr, instance) => {
    setScheduleData((prev) => {
      return { ...prev, [instance.input.name]: selectedDate[0].valueOf() };
    });
  };

  const saveCheckBoxToStateHandler = (e) => {
    setScheduleData((prev) => {
      return { ...prev, [e.target.name]: e.target.checked ? true : false };
    });
  };

  let getRoundedDate = (minutes, d = new Date()) => {
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

    return roundedDate;
  };

  const validateScheduleData = (schedule) => {
    if (!schedule.title.trim().length) {
    }
  };

  const createZoomMeetingHandler = async (meetingData) => {
    // console.log("Meeting Data ML ==> ",meetingData)

    const response = await axios.post(
      "https://dev.igesia.com/events/meetings/schedule ",
      {
        event_id: "EKWsp2FDV2aWp1F6P963",
        user_id: "1gHTSDwzWRPW7KAUeTcscDFxs7z2",
        meeting_topic: meetingData["EN"].title,
        meeting_agenda: meetingData["EN"].description ?? "",
        meeting_timezone: "Asia/Calcutta",
        meeting_start_time: meetingData.starts_at,
        meeting_duration: Math.floor(
          ((meetingData.ends_at - meetingData.starts_at) / 1000 / 60) << 0
        ),
      }
    );
    // console.log("Response ML ==> ",response)
    return response;
  };

  const addScheduleToDBHandler = async (schedule) => {
    try {
      console.log('Inside addScheduleToDBHandler ML')
      setSaving(true);
      const response = await createZoomMeetingHandler(schedule);
      // console.log('Inside addScheduleToDBHandler Response ML =>',response)
      let docref = firebase
        .firestore()
        .collection("/clinics/EKWsp2FDV2aWp1F6P963/schedule")
        .doc();

      // console.log('Inside addScheduleToDBHandler docref ML =>',docref)
      
      const slug = eventSnapshot.data().slug;

      const stage_link = `https://${window.location.host}/clinic/${slug}/meeting#${schedule.stage.slug}`;

      if (response.status === 200) {
        const meetingURL = "https://zoom.us/j/" + response.data.data.meeting_id;
        const scheduleWithoutTranslations = { ...schedule };
        delete scheduleWithoutTranslations.EN;
        delete scheduleWithoutTranslations.FR;

        await docref.set(
          {
            EN: {
              ...schedule["EN"],
              description: `<p>${schedule["EN"].description}</p>`,
            },
            FR: {
              ...schedule["FR"],
              description: `<p>${schedule["FR"].description}</p>`,
            },
            ...scheduleWithoutTranslations,
            stage: {
              stage_id: scheduleWithoutTranslations.stage.value,
              stage_link: stage_link ?? "",
            },
            location: stage_link ?? "",
            created_at: new Date().valueOf(),
            meeting_url: meetingURL,
          },
          { merge: true }
        );
      }

      if (schedule.livestream) {
        const responseOfStreamCreation = await axios.post(
          "https://api2.igesia.com/streams",
          {
            stream_title: schedule["EN"].title,
            stream_description: schedule["EN"].description,
            stream_start_time: new Date(schedule.starts_at).toISOString(),
            stream_privacy_status: "unlisted",
          }
        );
        if (responseOfStreamCreation.status === 200) {
          const meetingURL =
            "https://zoom.us/j/" + response.data.data.meeting_id;
          const responseOfBind = await axios.post(
            "https://api2.igesia.com/streams/bind",
            {
              stream_title: schedule["EN"].title,
              stream_description: schedule["EN"].description,
              broadcast_id: responseOfStreamCreation.data.data.broadcast_id,
            }
          );
          docref.update({
            ...responseOfBind.data.data,
          });
          await axios.post(
            `https://dev.igesia.com/events/meetings/${response.data.data.meeting_id}/livestream `,
            {
              stream_url:
                responseOfBind.data.data.streaming_server_config
                  .rtmp_ingestion_address,
              stream_key: responseOfBind.data.data.stream_key,
              video_link: responseOfBind.data.data.link,
            }
          );
        }
      }
      toast.success("Schedule added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setScheduleData({});
    } catch (e) {
      console.error(e);
      toast.error("Error adding schedule", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      setShowAddScheduleModal(false);
      setScheduleData({});
    }
  };

  const updateScheduleToDBHandler = async (schedule) => {
    try {
      console.log(schedule.id);
      setSaving(true);
      let docref = firebase
        .firestore()
        .collection("/clinics/EKWsp2FDV2aWp1F6P963/schedule")
        .doc(schedule.id);

      const slug = eventSnapshot.data().slug;

      const selectedStage = stageOptions[getScheduleValueHandler()];

      const stage_link = `https://${window.location.host}/clinic/${slug}/meeting#${selectedStage.slug}`;

      const scheduleWithoutTranslations = { ...schedule };
      delete scheduleWithoutTranslations.EN;
      delete scheduleWithoutTranslations.FR;

      await docref.update({
        EN: {
          ...schedule["EN"],
          description: `<p>${schedule["EN"].description}</p>`,
        },
        FR: {
          ...schedule["FR"],
          description: `<p>${schedule["FR"].description}</p>`,
        },
        ...scheduleWithoutTranslations,
        stage: {
          stage_id: selectedStage.value,
          stage_link: stage_link ?? "",
        },
        location: stage_link ?? "",
        updated_at: new Date().valueOf(),
      });

      toast.success("Schedule updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setScheduleData({});
    } catch (e) {
      console.error(e);
      toast.error("Error adding schedule", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setIsUpdating(false);
      setSaving(false);
      setShowAddScheduleModal(false);
      setScheduleData({});
    }
  };

  const speakerOptions = speakersSnapshot?.docs.map((speaker) => {
    if (speaker.data().email_address) {
      return {
        value: speaker.id,
        label: `${speaker.data().first_name} ${speaker.data().last_name}`,
      };
    } else {
      return { value: speaker.id, label: speaker.data().name };
    }
  });

  const removeScheduleHandler = async (uid) => {
    try {
      await firebase
        .firestore()
        .collection("/clinics/EKWsp2FDV2aWp1F6P963/schedule/")
        .doc(uid)
        .delete();
      if (selectedMeetingID) {
        await axios.delete(
          `https://dev.igesia.com/events/meetings/${selectedMeetingID}`
        );
      }

      toast.success("Schedule Deleted", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in deleting");
    }
  };

  // reoder category
  // throttling
  const listReorderHandler = (newOrder) => {
    // new order will be an array of indices
    newOrder.forEach((order, index) => {
      // [1,0,3,2]
      if ((order !== undefined || order !== null) && sortedCategories.length) {
        sortedCategories[order].order_index = index;
      }
    });
  };
  const saveOrderIndexToDBHandler = async () => {
    try {
      sortedCategories.forEach(async (category) => {
        await firebase
          .firestore()
          .collection("/clinics/EKWsp2FDV2aWp1F6P963/categories/")
          .doc(category.id)
          .update({
            order_index: category.order_index,
          });
      });
    } catch (e) {
      console.error(e, "error in changing the order");
    }
  };

  const throttledReorder = _.throttle(listReorderHandler, 1000);

  // category navbar
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();

  // speakers, category, stage name handler
  const nameHandler = (type, id) => {
    const arrayToTraverse =
      type === "speakers"
        ? speakerOptions
        : type === "category"
        ? categoryOptions
        : type === "stage"
        ? stageOptions
        : [];
    if (arrayToTraverse?.length) {
      return arrayToTraverse.find((element) => element.value === id);
    }
  };

  const getScheduleValueHandler = () => {
    if (scheduleData?.stage?.value) {
      return stageOptions.findIndex(
        (stage) => stage.value === scheduleData.stage.value
      );
    } else if (scheduleData?.stage?.stage_id) {
      return stageOptions.findIndex(
        (stage) => stage.value === scheduleData.stage.stage_id
      );
    }
  };

  const getCategoryHandler = () => {
    if (scheduleData?.category?.trim().length) {
      return categoryOptions.findIndex(
        (category) => category.value === scheduleData.category
      );
    }
  };

  const timeValidation = (minMilliseconds) => {
    if (
      scheduleData.ends_at - scheduleData.starts_at < 0 ||
      scheduleData.ends_at - scheduleData.starts_at < minMilliseconds
    ) {
      return 0;
    } else {
      return 1;
    }
  };

  return (
    <>
      <div className="container-fluid py-4" style={{ height: "85vh" }}>
        <div className="mt-3 overflow-auto h-100">
          <div className="col-md-12 h-100" style={{ overflow: "scroll" }}>
            <h5>Schedule Management</h5>
            <div className="mt-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      All events
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Event1</Dropdown.Item>
                      <Dropdown.Item>Event 2</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex">
                  <Button
                    className="btn-sm btn-primary mr-2"
                    onClick={() => setShowAddScheduleModal(true)}
                    // disabled={true}
                  >
                    + New schedule
                  </Button>
                  <Button
                    className="btn-sm btn-primary mr-2"
                    onClick={() => setShowAddCategory(true)}
                    // disabled={true}
                  >
                    + New category
                  </Button>
                  <Button
                    className="btn-sm btn-secondary mr-2"
                    onClick={() => setShowReorderCategory(true)}
                    // disabled={true}
                  >
                    Reorder category
                  </Button>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-between bg-light my-3 border-bottom px-3">
                <Nav
                  className="w-100"
                  defaultActiveKey={
                    categorySnapshot?.docs[currentIndex].data().title
                  }
                  activeKey={categorySnapshot?.docs[currentIndex].data().title}
                >
                  {categorySnapshot?.docs.map((category, index) => {
                    return (
                      <Nav.Item>
                        <Nav.Link
                          eventKey={category.data().title}
                          onSelect={(e) => {
                            setCurrentIndex(index);
                            history.push({
                              hash: `#${category.data().title}`,
                            });
                          }}
                        >
                          {category.data().title}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </div>
              <div className="mt-3">
                <Table
                  borderless
                  responsive
                  className="small"
                  style={{ minHeight: "80vh" }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Stage</th>
                      <th>Speakers</th>
                      <th>Category</th>
                      <th>Duration</th>
                      <th>Date</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleSnapshot?.docs.map((item, key) => {
                      const scheduleLangData = {
                        ...item.data()[currentLanguage],
                        id: item.id,
                      };
                      return (
                        <tr>
                          <td>{key + 1}</td>
                          <td>{scheduleLangData.title}</td>
                          <td>
                            {
                              nameHandler("stage", item.data()?.stage?.stage_id)
                                ?.label
                            }
                          </td>
                          <td>
                            {item.data()?.speakers?.map((speaker) => {
                              return (
                                <p className="mb-0">
                                  {nameHandler("speakers", speaker)?.label}
                                </p>
                              );
                            })}
                          </td>
                          <td>
                            {
                              nameHandler("category", item.data()?.category)
                                ?.label
                            }
                          </td>
                          <td>
                            {moment(item.data().starts_at).format("LT")} -{" "}
                            {moment(item.data().ends_at).format("LT")}
                          </td>
                          <td>{moment(item.data().starts_at).format("LL")}</td>
                          <td>{parse(scheduleLangData.description)}</td>

                          <td>
                            <div style={{ minWidth: "130px" }}>
                              <AddToCalendar
                                event={{
                                  name: scheduleLangData.title,
                                  details: `${
                                    scheduleLangData.description
                                  }  <p><a href=${
                                    item.data().stage.stage_link
                                  }>${item.data().stage.stage_link}</a></p>`,
                                  startsAt: item.data().starts_at,
                                  endsAt: item.data().ends_at,
                                  location: item.data().location,
                                }}
                              ></AddToCalendar>
                            </div>

                            <Button
                              className="btn-sm btn-light mr-2"
                              onClick={() => {
                                setIsUpdating(true);
                                setScheduleData({
                                  ...item.data(),
                                  id: item.id,
                                });
                                setShowAddScheduleModal(true);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setSelectedMeetingID(
                                  item
                                    .data()
                                    ?.meeting_url?.replace(
                                      "https://zoom.us/j/",
                                      ""
                                    )
                                );
                                setSelectedUID(scheduleLangData.id);
                                setDeleteWarningModal(true);
                              }}
                              className="btn-sm btn-danger"
                              // disabled
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddCategory ? (
        <Category
          show={showAddCategory}
          onHide={() => setShowAddCategory(false)}
        />
      ) : null}

      {/* modal for add ticket */}

      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAddScheduleModal}
        onHide={() => {
          setIsUpdating(false);
          setShowAddScheduleModal(false);
          setScheduleData({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Schedule Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group className="col-md-12">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={scheduleData["EN"]?.title}
                  onChange={(e) => saveTextDataToStateHandler(e, "EN")}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  row={1}
                  placeholder="Description"
                  value={scheduleData["EN"]?.description
                    ?.replace(/<p>/g, "")
                    ?.replace(/<\/p>/g, "")}
                  name="description"
                  onChange={(e) => saveTextDataToStateHandler(e, "EN")}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Titre"
                  name="title"
                  value={scheduleData["FR"]?.title}
                  onChange={(e) => saveTextDataToStateHandler(e, "FR")}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>La description</Form.Label>
                <Form.Control
                  as="textarea"
                  row={1}
                  placeholder="La description"
                  value={scheduleData["FR"]?.description
                    ?.replace(/<p>/g, "")
                    ?.replace(/<\/p>/g, "")}
                  name="description"
                  onChange={(e) => saveTextDataToStateHandler(e, "FR")}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Stage</Form.Label>
                <Select
                  isSearchable
                  value={stageOptions[getScheduleValueHandler()]}
                  options={stageOptions}
                  onChange={(e) => {
                    setScheduleData((prev) => {
                      const prevScheduleData = {
                        ...prev,
                        stage: e,
                      };
                      return prevScheduleData;
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Category</Form.Label>
                <Select
                  isSearchable
                  value={
                    categoryOptions?.length
                      ? categoryOptions[getCategoryHandler()]
                      : null
                  }
                  options={categoryOptions}
                  onChange={(e) => {
                    setScheduleData((prev) => {
                      const prevScheduleData = { ...prev, category: e.value };
                      return prevScheduleData;
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Starts at</Form.Label>
                <Flatpickr
                  placeholder="Select date"
                  data-enable-time
                  value={scheduleData.starts_at}
                  name="starts_at"
                  onChange={(selectedDate, dateStr, instance) =>
                    saveDateToStateHandler(selectedDate, dateStr, instance)
                  }
                  options={{ minDate: "today", minuteIncrement: 1 }}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Ends at</Form.Label>
                <Flatpickr
                  placeholder="Select date"
                  data-enable-time
                  value={scheduleData.ends_at}
                  name="ends_at"
                  onChange={(selectedDate, dateStr, instance) =>
                    saveDateToStateHandler(selectedDate, dateStr, instance)
                  }
                  options={{ minDate: "today", minuteIncrement: 1 }}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label>Speakers</Form.Label>
                <Select
                  isMulti
                  isSearchable
                  options={speakerOptions}
                  onChange={(e) => {
                    console.log(e);
                    setScheduleData((prev) => {
                      const prevScheduleData = {
                        ...prev,
                        speakers: e.map((speaker) => speaker.value),
                      };
                      return prevScheduleData;
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-md-12 d-flex">
                <Form.Check
                  id="livestream"
                  type="checkbox"
                  placeholder="Live stream the session"
                  name="livestream"
                  value={scheduleData.livestream}
                  onChange={(e) => saveCheckBoxToStateHandler(e)}
                />
                <Form.Label htmlFor="livestream">
                  Live stream the session
                </Form.Label>
              </Form.Group>
            </div>
            {!isUpdating ? (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  if (timeValidation(5000)) {
                    addScheduleToDBHandler(scheduleData);
                  } else {
                    toast.error(
                      "Session end time should be at least 5 minutes from the start time",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      }
                    );
                  }
                }}
                disabled={saving}
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Save"}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="w-100"
                onClick={() => {
                  if (timeValidation(5000)) {
                    updateScheduleToDBHandler(scheduleData);
                  } else {
                    toast.error(
                      "Session end time should be at least 5 minutes from the start time",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000,
                      }
                    );
                  }
                }}
                disabled={saving}
              >
                {saving ? <Spinner animation="border" size="sm" /> : "Update"}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {/* reorder category */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showReorderCategory}
        onHide={() => setShowReorderCategory(false)}
      >
        <Modal.Header closeButton>Reorder categories</Modal.Header>
        <Modal.Body>
          <DraggableList
            orderFunction={throttledReorder}
            items={sortedCategories}
            variant="categories"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={() => setShowReorderCategory(false)}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className="btn-sm"
            onClick={() => {
              saveOrderIndexToDBHandler();
              setShowReorderCategory(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete warning */}
      <Modal
        show={deleteWarningModal}
        onHide={() => {
          setSelectedUID("");
          setDeleteWarningModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>Confirm deletion</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete? This action cannot be reversed</p>
          {/* <p>Contine ?</p> */}
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              className="btn-sm mr-3"
              onClick={() => setDeleteWarningModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="btn-sm"
              onClick={() => {
                removeScheduleHandler(selectedUID);
                setDeleteWarningModal(false);
              }}
            >
              Yes, delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => ({ currentLanguage: "EN" }), {
  changeLanguage,
})(Schedule);
