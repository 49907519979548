import React, { useState, useEffect } from "react";
import Chat from "./Chat";
import ClassNavbar from "./ClassNavbar";

import * as CourseStyle from "../course.module.css";
import * as ClassStyle from "./class.module.css";
import { Button, Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import {
  faCheckCircle,
  faComment,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export default function Live({ class_type }) {
  const history = useHistory();
  const class_details = {
    name: "What is AI?",
    uid: "what-is-artificial-intelligence-1",
  };

  const [startLive, setStartLive] = useState(false);

  const [heightOfSpace, setHeightOfSpace] = useState(500);
  const [heightOfRightBar, setHeightOfRightBar] = useState(10);
  useEffect(() => {
    if (startLive) {
      const widthOfSpace = document.getElementById("space").clientWidth;
      widthOfSpace && setHeightOfSpace((widthOfSpace / 16) * 9);

      const widthOfRightBar = document.getElementById("right-bar").clientWidth;
      widthOfRightBar && setHeightOfRightBar(widthOfRightBar - 28);
    }
  }, [startLive]);

  const [showChat, setShowChat] = useState(true);

  const [isLive, setIsLive] = useState(false);
  const [API, setAPI] = useState(null);
  const [loading, setLoading] = useState(false);

  const containerStyle = {
    width: "100%",
    height: heightOfSpace,
  };
  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  function startConference() {
    try {
      const domain = "meet.jit.si";
      const options = {
        roomName: class_details?.uid,
        height: heightOfSpace,
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
          DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
          SHARING_FEATURES: [],
          SHOW_CHROME_EXTENSION_BANNER: false,
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            // "closedcaptions",
            "desktop",
            // "embedmeeting",
            "fullscreen",
            "fodeviceselection",
            // "hangup",
            "profile",
            // "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            // "invite",
            // "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "select-background",
            // "download",
            // "help",
            "mute-everyone",
            "mute-video-everyone",
            "security",
          ],
          SETTINGS_SECTIONS: ["devices", "language", "moderator", "profile"],
          DEFAULT_BACKGROUND: "#232626",
          DEFAULT_LOCAL_DISPLAY_NAME: "me",
          REMOTE_THUMBNAIL_RATIO: 16 / 9,
          DEFAULT_REMOTE_DISPLAY_NAME: "Fellow Participant",
        },
        configOverwrite: {
          disableSimulcast: false,
          requireDisplayName: false,
          prejoinPageEnabled: false,
          startWithAudioMuted: true,
          disableDeepLinking: true
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      api && setAPI(api);
      api.addEventListener("videoConferenceJoined", () => {
        console.log("Local User Joined");
        setLoading(false);
        setIsLive(true);
        api.executeCommand("displayName", "Jatin Ahuja");
      });
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) {
      startLive && startConference();
    } else alert("Jitsi Meet API script not loaded");
  }, [startLive]);

  return (
    <div className="h-100">
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <ClassNavbar class_type={"live"} class_details={class_details} />
        <div
          className={`bg-graphite row`}
          style={{ height: "93vh", overflow: "hidden" }}
        >
          {!startLive ? (
            <div className="col-md-12 h-100 w-100">
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                <Card className="rounded-lg">
                  <Card.Body className="px-5 py-4">
                    <h5 className="font-weight-bold text-center mb-4">
                      Welcome Jatin Ahuja
                    </h5>
                    <p className="text-muted">
                      Ensure following for a smoother experience during the
                      session:
                    </p>
                    <p className="text-muted">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success"
                      />{" "}
                      A stable network connection with{" "}
                      <b className="text-dark">&gt; 500kbps</b> download speed.
                    </p>
                    <p className="text-muted">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success"
                      />{" "}
                      <b className="text-dark">Disable any VPN</b> or proxy
                      setting you might be.
                    </p>
                    <p className="text-dark font-weight-bold text-center">
                      {class_details?.name} (
                      {class_type === "live" ? "Live" : "Archive"})
                    </p>
                    <Button
                      variant="success"
                      className="w-100"
                      onClick={() => setStartLive(true)}
                    >
                      <span className="text-uppercase font-weight-bold small">
                        Join
                      </span>
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="col-md-8 h-100 w-100" id="space">
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <div style={containerStyle} className="rounded">
                    {loading && <Spinner animation="border" size="lg" />}
                    <div id="jitsi-container" style={jitsiContainerStyle} />
                  </div>
                </div>
              </div>

              {/* Chat module start */}
              <div
                className="col-md-4 h-100 px-0 d-flex justify-content-end"
                style={{ overflow: "scroll" }}
              >
                {showChat && <Chat classes="col-10" />}
                <div
                  className="bg-graphite d-flex flex-column col-2 justify-content-between align-items-center my-3"
                  id="right-bar"
                >
                  <div>
                    <Button
                      variant="none"
                      className={showChat ? "text-warning" : "text-white"}
                      onClick={() => {
                        setShowChat((prev) => !prev);
                      }}
                      style={
                        showChat
                          ? {
                              height: heightOfRightBar,
                              width: heightOfRightBar,
                              backgroundColor: "rgba(200,200,200,0.1)"
                            }
                          : {
                              height: heightOfRightBar,
                              width: heightOfRightBar,
                            }
                      }
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <FontAwesomeIcon
                          icon={faComment}
                          style={{ fontSize: heightOfRightBar / 3 }}
                        />
                        <p className="mb-0 small">
                          Chat
                        </p>
                      </div>
                    </Button>
                  </div>
                  <div>
                    {
                      <small className="text-danger text-center font-weight-bold mb-2">
                        End call
                      </small>
                    }
                    <Button
                      variant="danger"
                      //   className="mb-3"
                      disabled={!isLive}
                      onClick={() => {
                        API.executeCommand("hangup");
                        history.push(`end`)
                        setStartLive(false);
                        setIsLive(false);
                        setAPI(null);
                      }}
                      style={{
                        height: heightOfRightBar,
                        width: heightOfRightBar,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        className={`${ClassStyle.hangup_btn_icon}`}
                        style={{ fontSize: heightOfRightBar / 3 }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
              {/* Chat module end */}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
