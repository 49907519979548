import React, { useState } from "react";
import { Badge, Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { TwitterPicker } from "react-color";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import firebase from "firebase";
import {
  setFactors,
  setProducts,
  setGraphId,
  setAsIsFactors,
} from "../../../../store/actions/actions";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { API_BASE_URL, BBOA_DELETE_FACTOR } from "../../../../config";

function ProjectSettings(props) {
  const {
    darkTheme,
    currentEvent,
    products,
    graphId,
    asisFactors,
    factors,
    setProducts,
    title,
    setTitle,
    errcCreated,
    currentCustomers,
    setCurrentCustomers,
  } = props;
  const currentUser = firebase.auth().currentUser;

  const [newProductName, setNewProductName] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [showProductForm, setShowProductForm] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("red");
  const [showFactorForm, setShowFactorForm] = useState(false);
  const [newFactorName, setNewFactorName] = useState("");
  const [newFactorDescription, setNewFactorDescription] = useState("");
  const [newFactorValue, setNewFactorValue] = useState({
    value: 0,
    name: "Low",
  });
  const [showDescription, setShowDescription] = useState([]);

  const [editCurrentCustomers, setEditCurrentCustomers] = useState(false);
  const [sending, setSending] = useState(false);

  const [keyElementDetails, setKeyElementDetails] = useState(null);
  const [showEditKeyElementModal, setShowEditKeyElementModal] = useState(false);
  const [editing, setEditing] = useState(false);

  const titleHandler = (e) => {
    e.preventDefault();

    if (newTitle) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa`)
        .add({
          title: newTitle,
          errc_created: false,
          to_be_created: false,
          buyerExpAudit_created: false,
          six_path: false,
        })
        .then((doc) => {
          setGraphId(doc.id);
          setTitle(newTitle);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const discardAddProjectForm = () => {
    setShowProductForm(false);
    setNewProductName("");
  };

  //   Function: Add new product / service
  const addProductHandler = (e) => {
    e.preventDefault();

    if (newProductName && selectedColor) {
      let data = {
        name: newProductName,
        color: selectedColor,
      };
      if (graphId) {
        firebase
          .firestore()
          .collection(`events/${currentEvent?.id}/bboa/${graphId}/as_is`)
          .add(data)
          .then((response) => {
            firebase
              .firestore()
              .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
              .doc(response.id)
              .set(data);
          })
          .catch((error) => {
            console.error("Error adding product: ", error);

            toast.warning("Error adding Product / Service: " + error, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .finally(() => {
            discardAddProjectForm();
            toast.success("Product / Service added successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
    }
  };

  //   Function: Delete product / service
  const deleteProductHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/as_is`)
        .doc(item.id)
        .delete()
        .then(() => {
          firebase
            .firestore()
            .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
            .doc(item.id)
            .delete();

          if (products.length == 1) {
            setProducts([]);
          }
        })
        .catch((error) => {
          console.error("Error deleting product/ service: ", error);

          toast.warning("Error deleting product/ service " + error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          toast.dark("Product / Service deleted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const discardAddKeyElementModal = () => {
    setShowFactorForm(false);
    setNewFactorName("");
    setNewFactorValue({
      value: 0,
      name: "Low",
    });
  };

  const discardEditKeyElementModal = () => {
    setKeyElementDetails(null);
    setShowEditKeyElementModal(false);
    setEditing(false);
  };

  //   Function: Add new key element
  const addKeyElementHandler = (e) => {
    e.preventDefault();

    if (newFactorName && newFactorValue) {
      let data = {
        defaultValue: newFactorValue.value,
        name: newFactorName,
        description: newFactorDescription,
        position: factors?.filter((e) => !e?.isNew)?.length,
        positionToBe: factors?.length,
      };
      if (graphId) {
        firebase
          .firestore()
          .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
          .add(data)
          .then((doc) => {
            if (errcCreated) {
              firebase
                .firestore()
                .collection(
                  `events/${currentEvent?.id}/bboa/${graphId}/errc_grid`
                )
                .doc("0")
                .set(
                  {
                    main: firebase.firestore.FieldValue.arrayUnion(doc.id),
                  },
                  {
                    merge: true,
                  }
                );
            }
          });
        factors.push(data);
        asisFactors.push(data);
        products.map((product) => {
          product.data.push(newFactorValue.value);
        });

        discardAddKeyElementModal();

        toast.success("Key element added successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    }
  };

  // Function: Edit key element
  const editKeyElementHandler = (e) => {
    e.preventDefault();
    setEditing(true);

    if (keyElementDetails?.name && keyElementDetails?.defaultValue >= 0) {
      let data = {
        defaultValue: parseInt(keyElementDetails?.defaultValue),
        name: keyElementDetails?.name,
        description: keyElementDetails?.description ?? "",
        position: parseInt(keyElementDetails?.position),
      };
      if (graphId && keyElementDetails?.id) {
        firebase
          .firestore()
          .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
          .doc(`${keyElementDetails?.id}`)
          .update(data, {
            merge: true,
          })
          .then((doc) => {
            // factors.push(data);
            // asisFactors.push(data);
            // products.map((product) => {
            //   product.data.push(newFactorValue.value);
            // });

            discardEditKeyElementModal();

            toast.success("Key element updated successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .catch((error) => {
            console.error("Error updating key element: ", error);
            toast.warning("Error updating key element", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
    } else {
      toast.warning("Please enter mandatory fields!", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  //   Function: Delete key element
  const deleteKeyElementHandler = async (item) => {
    var index = _.findIndex(asisFactors, { id: item.id });

    if (item) {
      let val = products.map((item, key) => {
        let product = item;
        product.data.splice(index, 1);
        return product;
      });

      setProducts(val);

      try {
        const response = await axios.post(
          `${API_BASE_URL}${BBOA_DELETE_FACTOR}`,
          {
            product_id: currentEvent?.id,
            product_type: currentEvent?.type,
            project_id: graphId,
            factor_id: item.id,
          }
        );

        if (response.status === 200) {
          toast.dark("Factor deleted successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.dark("Error deleting factors", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log(`[Error] Deleting factors: ${error.message}`);
      }
    }
  };

  //   Function: Upvote / Downvote a key element
  const voteKeyFactorHandler = (product_id) => {
    let factorRef = firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
      .doc(`${product_id}`);

    factorRef.get().then((doc) => {
      if (doc.exists) {
        if (doc.data()?.voters?.includes(`${currentUser?.uid}`)) {
          factorRef.update({
            voters: firebase.firestore.FieldValue.arrayRemove(
              `${currentUser?.uid}`
            ),
          });
        } else {
          factorRef.update({
            voters: firebase.firestore.FieldValue.arrayUnion(
              `${currentUser?.uid}`
            ),
          });
        }
      }
    });
  };

  const handleCurrentCustomer = (e) => {
    e.preventDefault();
    setSending(true);

    const docRef = firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa`)
      .doc(`${graphId}`);

    docRef
      .update({ current_customers: currentCustomers }, { merge: true })
      .then(() => {})
      .catch((e) => {
        console.error("Error updating current customers", e);
        toast.error("Error updating current customers", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSending(false);
      })
      .finally(() => {
        toast.success("Current customers successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSending(false);
        setEditCurrentCustomers(false);
      });
  };

  return (
    <>
      <Card
        className={
          darkTheme
            ? "bg-dark shadow-sm border border-dark mb-3"
            : "bg-white shadow-sm border border-light mb-3"
        }
      >
        <Card.Header>
          <h5 className="mb-0 text-uppercase">VISUAL AWAKENING</h5>
        </Card.Header>
        <Card.Body className="p-1">
          <div>
            {!title && (
              <div className="card montserrat-font m-2">
                <div className="p-3">
                  <Form>
                    <Form.Group>
                      <Form.Label>Add Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        value={newTitle}
                        onChange={(e) => {
                          setNewTitle(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={titleHandler}
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            )}

            <div className="p-2">
              {editCurrentCustomers ? (
                <Form onSubmit={handleCurrentCustomer}>
                  <Form.Group>
                    <Form.Label className="text-muted text-uppercase small">
                      <b>Current customers of the industry</b>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter value"
                      value={currentCustomers}
                      onChange={(e) => {
                        setCurrentCustomers(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <div className="d-flex">
                    <Button
                      size="sm"
                      className="text-uppercase mr-2"
                      type="submit"
                      disabled={sending}
                    >
                      {sending ? "Updating..." : "Update"}
                    </Button>
                    <Button
                      size="sm"
                      variant="light"
                      className="text-uppercase shadow-sm"
                      onClick={() => {
                        setEditCurrentCustomers(false);
                      }}
                    >
                      Discard
                    </Button>
                  </div>
                </Form>
              ) : (
                <div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="mb-0 small mr-2">
                      <b className="text-muted text-uppercase">
                        Current customers of the industry
                      </b>
                    </p>
                    <Button
                      size="sm"
                      variant="light"
                      className="text-uppercase shadow-sm"
                      onClick={() => setEditCurrentCustomers(true)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Update
                    </Button>
                  </div>
                  {currentCustomers != "" ? (
                    <p className="lead">{currentCustomers}</p>
                  ) : (
                    <p>
                      <i className="text-center">Not added yet</i>
                    </p>
                  )}
                </div>
              )}
            </div>

            {/* Key elements */}
            <Card
              className={
                darkTheme
                  ? "bg-dark shadow-sm border border-dark montserrat-font m-2"
                  : "bg-white shadow-sm border border-light montserrat-font m-2"
              }
            >
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-0 lead">Key elements</p>
                    <p className="mb-0 small">
                      Criteria considered as key by the current customers to
                      make their choice between the existing offerings
                    </p>
                  </div>
                  <Button
                    size="sm"
                    className="text-uppercase"
                    onClick={() => setShowFactorForm(true)}
                  >
                    New
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="p-1">
                {asisFactors
                  ?.sort((a, b) => a.position - b.position)
                  ?.map((item, key) => (
                    <div className="mb-1 border p-1 pl-2 rounded">
                      <div className="d-flex align-items-center ">
                        <div className="mr-2 flex-grow-1">
                          <span>
                            {key + 1}. {item.name}
                          </span>
                          {item.description && (
                            <a
                              className="pointer"
                              onClick={() =>
                                setShowDescription((prev) =>
                                  [...prev]?.includes(item.id)
                                    ? [...prev]?.filter((i) => i != item.id)
                                    : [...prev, item.id]
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faQuestionCircle}
                                className="ml-2"
                              />
                            </a>
                          )}
                        </div>
                        <div>
                          <Button
                            size="sm"
                            variant={
                              item?.voters?.includes(`${currentUser.uid}`)
                                ? "secondary"
                                : "primary"
                            }
                            className="mr-2"
                            onClick={() => voteKeyFactorHandler(item.id)}
                          >
                            {item?.voters?.includes(`${currentUser.uid}`)
                              ? "Downvote"
                              : "Upvote"}{" "}
                            {item?.voters?.length > 0 ? (
                              <Badge variant="light">
                                {item.voters.length}
                              </Badge>
                            ) : (
                              <Badge variant="light">0</Badge>
                            )}
                            <span className="sr-only">upvotes</span>
                          </Button>
                          <Button
                            onClick={() => {
                              setKeyElementDetails(item);
                              setShowEditKeyElementModal(true);
                            }}
                            size="sm"
                            variant="secondary"
                            className="mr-2"
                          >
                            <div>
                              <FontAwesomeIcon size="sm" icon={faEdit} /> Edit
                            </div>
                          </Button>
                          <Button
                            onClick={() => deleteKeyElementHandler(item)}
                            size="sm"
                            variant="danger"
                          >
                            <div>
                              <FontAwesomeIcon size="sm" icon={faTrash} />{" "}
                              Remove
                            </div>
                          </Button>
                        </div>
                      </div>
                      {showDescription?.includes(item.id) && (
                        <p className="small mb-0">{item.description}</p>
                      )}
                    </div>
                  ))}
              </Card.Body>
            </Card>

            {/* Product / Service Offerings */}
            <Card
              className={
                darkTheme
                  ? "bg-dark shadow-sm border border-dark montserrat-font m-2"
                  : "bg-white shadow-sm border border-light montserrat-font m-2"
              }
            >
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-0 lead">Product / Service Offerings</p>
                    <p className="mb-0 small">
                      Main service or product offerings currently proposed on
                      your industry
                    </p>
                  </div>
                  <Button
                    size="sm"
                    className="text-uppercase"
                    onClick={() => setShowProductForm(true)}
                  >
                    New
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="p-1">
                {!products?.length && (
                  <div className="d-flex align-items-center justify-content-center p-3">
                    <p className="mb-0">
                      Click on <b>New</b> above to add a Product.
                    </p>
                  </div>
                )}
                {products?.map((item, key) => (
                  <div
                    className="d-flex align-items-center mb-1 border rounded p-1 pl-2"
                    key={key}
                  >
                    <div
                      className="mr-2"
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 4,
                        backgroundColor: item.color,
                      }}
                    ></div>
                    <p className="mb-0 flex-grow-1">{item.name}</p>
                    <div>
                      <Button
                        onClick={() => {
                          deleteProductHandler(item);
                        }}
                        size="sm"
                        variant="danger"
                      >
                        <div>
                          <FontAwesomeIcon size="sm" icon={faTrash} /> Remove
                        </div>
                      </Button>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>

      {/* Modal: Add product / service */}
      <Modal centered show={showProductForm} onHide={discardAddProjectForm}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>Add Product / Service</Modal.Title>
            <p className="mb-0 small text-muted">
              Marked (<span className="text-danger">*</span>) fields are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form className="mb-0">
            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Product / Service name <span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex align-items-center">
                <div
                  className="mr-1"
                  onClick={() =>
                    showColorPicker
                      ? setShowColorPicker(false)
                      : setShowColorPicker(true)
                  }
                  style={{
                    height: 35,
                    width: 35,
                    borderRadius: 4,
                    backgroundColor: selectedColor,
                  }}
                ></div>
                <Form.Control
                  type="text"
                  placeholder="Enter name of product / service"
                  value={newProductName}
                  autoComplete="off"
                  onChange={(e) => setNewProductName(e.target.value)}
                />
              </div>
            </Form.Group>
            {showColorPicker ? (
              <div style={{ position: "absolute", zIndex: 1001 }}>
                <TwitterPicker
                  onChangeComplete={(color) => {
                    setShowColorPicker(false);
                    setSelectedColor(color.hex);
                  }}
                  colors={[
                    "#FF6900",
                    "#FCB900",
                    "#7BDCB5",
                    "#00D084",
                    "#1adef0",
                    "#0693E3",
                    "#ff26d7",
                    "#EB144C",
                    "#F78DA7",
                    "#7500eb",
                  ]}
                />
              </div>
            ) : null}
            <div className="d-flex">
              <Button
                variant="primary"
                size="sm"
                className="mr-2 text-uppercase"
                type="submit"
                onClick={addProductHandler}
              >
                Add
              </Button>
              <Button
                variant="light"
                size="sm"
                className="text-uppercase shadow-sm"
                onClick={discardAddProjectForm}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal: Add key element */}
      <Modal centered show={showFactorForm} onHide={discardAddKeyElementModal}>
        <Modal.Header closeButton>
          <div>
            <Modal.Title>Add Key Element</Modal.Title>
            <p className="mb-0 small text-muted">
              Marked (<span className="text-danger">*</span>) fields are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addKeyElementHandler}>
            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Key element name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter key element name"
                autoComplete="off"
                value={newFactorName}
                onChange={(e) => {
                  setNewFactorName(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Select default value <span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex">
                {["Low", "Medium", "High"].map((item, idx) => (
                  <Form.Check
                    key={`default-${idx}`}
                    id={`default-${idx}`}
                    type="radio"
                    label={item}
                    className="mr-3"
                    value={item}
                    checked={parseInt(newFactorValue.value) === parseInt(idx)}
                    name="default"
                    onClick={(e) =>
                      setNewFactorValue({
                        value: parseInt(idx),
                        name: item,
                      })
                    }
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Description <span className="small text-muted">(optional)</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Write a description"
                value={newFactorDescription}
                onChange={(e) => {
                  setNewFactorDescription(e.target.value);
                }}
              />
            </Form.Group>

            <div className="d-flex">
              <Button
                variant="primary"
                type="submit"
                size="sm"
                className="mr-2 text-uppercase"
              >
                Add
              </Button>
              <Button
                size="sm"
                variant="light"
                className="text-uppercase shadow-sm"
                onClick={discardAddKeyElementModal}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal: Edit key element */}
      <Modal
        centered
        show={showEditKeyElementModal}
        onHide={discardEditKeyElementModal}
      >
        <Modal.Header closeButton>
          <div>
            <Modal.Title>Edit Key Element</Modal.Title>
            <p className="mb-0 small text-muted">
              Marked (<span className="text-danger">*</span>) fields are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editKeyElementHandler}>
            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Key element name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter key element name"
                autoComplete="off"
                value={keyElementDetails?.name}
                onChange={(e) => {
                  setKeyElementDetails((prev) => {
                    let newState = { ...prev };
                    newState["name"] = e.target.value;

                    return newState;
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Select default value <span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex">
                {["Low", "Medium", "High"].map((item, idx) => (
                  <Form.Check
                    key={`default-${idx}`}
                    id={`default-${idx}`}
                    type="radio"
                    label={item}
                    className="mr-3"
                    value={item}
                    checked={
                      parseInt(keyElementDetails?.defaultValue) ===
                      parseInt(idx)
                    }
                    name="default"
                    onChange={(e) => {
                      setKeyElementDetails((prev) => {
                        let newState = { ...prev };
                        newState["defaultValue"] = parseInt(idx);

                        return newState;
                      });
                    }}
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-uppercase font-weight-bold text-muted">
                Description <span className="small text-muted">(optional)</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Write a description"
                value={keyElementDetails?.description}
                onChange={(e) => {
                  setKeyElementDetails((prev) => {
                    let newState = { ...prev };
                    newState["description"] = e.target.value;

                    return newState;
                  });
                }}
              />
            </Form.Group>

            <div className="d-flex">
              <Button
                variant="primary"
                type="submit"
                size="sm"
                className="mr-2 text-uppercase"
                disabled={editing}
              >
                {editing ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Saving...
                  </span>
                ) : (
                  <span>Save Changes</span>
                )}
              </Button>
              <Button
                size="sm"
                variant="light"
                className="text-uppercase shadow-sm"
                onClick={discardEditKeyElementModal}
                disabled={editing}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
  products: state.products,
  graphId: state.graphId,
  asisFactors: state.asisFactors,
  factors: state.factors,
});

export default connect(mapStateToProps, {
  setFactors,
  setProducts,
  setGraphId,
  setAsIsFactors,
})(ProjectSettings);
