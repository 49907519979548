import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import { connect } from "react-redux";

import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import EventChat from "./EventChat";
// import * as EventStyle from "../event.module.css";

import { changeLanguage } from "../../../store/actions/actions";
import { API_BASE_URL, EVENT_SPEAKERS } from "../../../config";
import { postData } from "../../../utils";

function Event({ currentLanguage, changeLanguage, darkTheme, currentEvent }) {
  // STATES
  const [showChat, setShowChat] = useState(false);
  const [allSpeakers, setAllSpeakers] = useState(null);
  const [publishedSpeakers, setPublishedSpeakers] = useState(null);
  const [fetchingSpeakers, setFetchingSpeakers] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  // REFS
  const pageRef = useRef(null);

  // FUNCTIONS
  useEffect(async () => {
    setFetchingSpeakers(true);
    pageRef && pageRef.current.scrollIntoView();

    // Fetch: Speakers
    const response_speakers = await postData(
      `${API_BASE_URL}${EVENT_SPEAKERS}`,
      { event_id: currentEvent?.id }
    );

    if (response_speakers.status == 200) {
      const { data } = response_speakers;
      const published_speakers = data.participantList?.filter(
        (speaker) => speaker.is_published
      );

      setAllSpeakers(data.participantList);
      setPublishedSpeakers(published_speakers);
      setFetchingSpeakers(false);
    }
  }, []);

  return (
    <div className="h-100" ref={pageRef}>
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3"
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            <div className="container-fluid px-0 ">
              {/* Speakers start */}
              <div className="container">
                <h3 className="">
                  {currentEvent?.type === 1 ? "Instructors" : "Speakers"}
                </h3>
                <div className="row mt-3">
                  {fetchingSpeakers ? (
                    <p className="text-center">Loading...</p>
                  ) : (
                    publishedSpeakers?.map((speaker, idx) => {
                      const item = speaker;
                      return (
                        <div
                          className="col-md-6 mb-4 col-12"
                          key={idx}
                          onClick={() => {
                            setShowProfile(true);
                            setSelectedProfile(speaker);
                          }}
                        >
                          <div className=" ">
                            <div className="d-flex">
                              <div>
                                <img
                                  src={`${item.img_src}`}
                                  width="70"
                                  height="70"
                                  className="mr-4 rounded-circle"
                                />
                              </div>
                              <div>
                                <h5 className=" mb-0">
                                  {item.first_name} {item.last_name}
                                </h5>
                                <p className="small mb-0 text-muted w-75">
                                  {item.designation}
                                </p>
                                <p className="small mb-0 font-weight-bold w-75">
                                  {item.organisation}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              {/* Speakers end */}
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
        {/* Chat Mobile view */}
        {showChat && (
          <div
            className="shadow-lg border border-muted"
            style={{
              height: "55vh",
              position: "fixed",
              bottom: "17vh",
              left: 10,
            }}
          >
            <EventChat event_id={currentEvent?.id} />
          </div>
        )}
        <div
          style={{
            position: "fixed",
            height: 45,
            width: 45,
            bottom: "10.5vh",
            left: 5,
          }}
          className="bg-primary rounded-circle d-block d-md-none"
        >
          <a
            className="d-flex align-items-center justify-content-center"
            onClick={() => setShowChat((prev) => !prev)}
            style={{
              height: 45,
              width: 45,
            }}
          >
            <FontAwesomeIcon
              className="text-white"
              icon={showChat ? faTimes : faComment}
              style={{ fontSize: 20 }}
            />
          </a>
        </div>
      </div>
      {/*Speaker Modal Start*/}
      <Modal
        centered={true}
        show={showProfile}
        onHide={() => setShowProfile(false)}
        animation={true}
      >
        <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
          <div
            title="Close"
            className="pointer d-flex justify-content-end mb-0"
            onClick={() => setShowProfile(false)}
          >
            <FontAwesomeIcon className="" icon={faTimes} />
          </div>
          <div className="d-flex flex-column align-items-center text-center">
            <div
              className="img-fluid rounded-circle shadow-sm"
              style={{
                height: 120,
                width: 120,
                backgroundImage: `url(${selectedProfile?.img_src})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              title={selectedProfile?.name}
            />
            <div className="mb-3">
              <p className="font-weight-bold lead mb-0">
                {selectedProfile?.first_name} {selectedProfile?.last_name}
              </p>
              <p>
                {selectedProfile?.designation}
                <br />
                <b>{selectedProfile?.organisation}</b>
              </p>
            </div>

            {selectedProfile?.bio && (
              <div className="text-left">
                {/* <p className="lead font-weight-bold">Bio</p> */}
                {parse(selectedProfile?.bio)}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {/*Speaker Modal Ended */}
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  { changeLanguage }
)(Event);
