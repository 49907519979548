import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { connect } from "react-redux";

const Messages = (props) => {
    const currentUser = firebase.auth().currentUser;
    const { eventId, currentEvent } = props;
    const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
        firebase.firestore().collection("events").doc(eventId)
    );



    return (
        <div className="container-fluid vh-100 overflow-auto">
            <div className="h-100 my-3 text-light" style={{ overflow: "scroll" }}>
                <div className="h-100" style={{ overflow: "auto" }}>
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Messages</h5>
                            <p className="mb-5">Write messages to your participants (optional) that will be sent automatically when they register to encourage
                                participants to engage with content. If you do not wish to send a welcome
                                message, leave the text box blank.</p>
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1" className="font-weight-bold">Welcome message</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary mb-2">Save</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    eventId: state.eventId,
    currentEvent: state.currentEvent,
});
export default connect(mapStateToProps)(Messages);