import React, { useState, useMemo, useEffect } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import LeftBar from "../LeftBar";
import NavBar from "../NavBar";
import EventChat from "../EventChat";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const IgesiaForm = ({ darkTheme, currentEvent }) => {
  const { form_id } = useParams();
  const user = firebase.auth().currentUser;
  const [showChat, setShowChat] = useState(false);

  const [formDoc, formLoading, formError] = useDocument(
    firebase.firestore().doc(`events/${currentEvent?.id}/forms/${form_id}`)
  );

  const [formResponseDoc, formResponseLoading, formResponseError] = useDocument(
    firebase
      .firestore()
      .doc(`events/${currentEvent?.id}/forms/${form_id}/responses/${user.uid}`)
  );

  const [formState, setFormState] = useState({});
  const [valid, setValid] = useState(false);
  const [sendingForm, setSendingForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (formResponseDoc?.exists) {
      setFormSubmitted(true);
      setFormState({
        ...formResponseDoc?.data()?.form_responses,
      });
    }
  }, [formResponseDoc]);

  useEffect(() => {
    validateUserInput();
  }, [formState]);

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const generateArray = (start, end) => {
    const fn = (x) => x + 1;
    let out = Array.from(Array(end - start + 1), (_, x) => fn(x));
    return out;
  };

  const handleChange = (e) => {
    setFormState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleChangeRating = (e, val) => {
    setFormState((prev) => {
      return {
        ...prev,
        [e.target.name]: val,
      };
    });
  };

  const resetForm = () => {
    setFormState({});
  };

  const validateUserInput = () => {
    const inputValidation = formDoc?.data()?.form_fields?.map((item, idx) => {
      switch (item.type) {
        case "select":
          if (
            item.values.includes(formState[item.field_name]) ||
            !item.required
          ) {
            return true;
          } else {
            return false;
          }

        case "number":
          if (formState[item.field_name] || !item.required) {
            return true;
          } else {
            return false;
          }

        case "text":
          if (formState[item.field_name] != "" || !item.required) {
            return true;
          } else {
            return false;
          }

        case "rating":
          if (
            (formState[item.field_name] >= 1 &&
              formState[item.field_name] <= 5) ||
            !item.required
          ) {
            return true;
          } else {
            return false;
          }

        default:
          return true;
      }
    });

    setValid(
      inputValidation?.filter((item) => item)?.length ===
        formDoc?.data()?.form_fields?.length
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateUserInput();
    setSendingForm(true);

    if (valid) {
      const responseData = {
        uid: user?.uid,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        form_responses: {
          ...formState,
        },
      };

      firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/forms/${form_id}/responses`)
        .doc(user?.uid)
        .set(responseData, { merge: true })
        .then(() => {
          toast.success("Responses submitted successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          resetForm();
          setSendingForm(false);
        });
    } else {
      toast.info("Please fill all the mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setSendingForm(false);
    }
  };

  if (formLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  if (formError) {
    return (
      <div className="w-100 text-center">
        <p>Error loading form, please reload.</p>
      </div>
    );
  }

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            <div>
              <Card
                className={
                  darkTheme
                    ? "shadow-sm rounded bg-dark"
                    : "shadow-sm rounded bg-white"
                }
              >
                <Card.Header className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h4 className="font-weight-bold mb-0">
                      {formDoc?.data()?.form_title}
                    </h4>
                    <p className="small mb-0">
                      {moment(
                        convertSecondstoMilliSeconds(
                          formDoc?.data()?.created_at.seconds,
                          formDoc?.data()?.created_at.nanoseconds
                        )
                      ).format("LLL")}
                    </p>
                  </div>
                  <div>
                    {formDoc?.data()?.form_required ? (
                      <p className="mb-0 text-danger">*Mandatory</p>
                    ) : null}
                  </div>
                </Card.Header>
                <Card.Body className="p-3">
                  <p>
                    Marked fields (<span className="text-danger">*</span>) are
                    mandatory.
                  </p>

                  <Form onSubmit={handleSubmit}>
                    {formDoc?.data()?.form_fields?.map((item, idx) => {
                      switch (item.type) {
                        case "select":
                          return (
                            <Form.Group key={idx}>
                              <Form.Label>
                                {idx + 1}. {item.label}{" "}
                                {item.required && (
                                  <span className="text-danger">*</span>
                                )}
                                {formSubmitted &&
                                  (formState[item.field_name] ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-success ml-2"
                                    />
                                  ) : (
                                    <span className="text-muted ml-2 small">
                                      (skipped)
                                    </span>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                required={item.required}
                                name={item.field_name}
                                id={item.field_name}
                                onChange={handleChange}
                                value={formState[item.field_name]}
                                disabled={formSubmitted}
                              >
                                <option value={null} selected disabled={true}>
                                  Select {item.label}
                                </option>
                                {item.values.map((option, key) => (
                                  <option value={option} key={key}>
                                    {option}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          );

                        case "number":
                          return (
                            <Form.Group key={idx}>
                              <Form.Label>
                                {idx + 1}. {item.label}{" "}
                                {item.required && (
                                  <span className="text-danger">*</span>
                                )}
                                {formSubmitted &&
                                  (formState[item.field_name] ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-success ml-2"
                                    />
                                  ) : (
                                    <span className="text-muted ml-2 small">
                                      (skipped)
                                    </span>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder={`Enter ${item.label}`}
                                required={item.required}
                                name={item.field_name}
                                id={item.field_name}
                                onChange={handleChange}
                                value={formState[item.field_name]}
                                readOnly={formSubmitted}
                              />
                            </Form.Group>
                          );

                        case "text":
                          return (
                            <Form.Group key={idx}>
                              <Form.Label>
                                {idx + 1}. {item.label}{" "}
                                {item.required && (
                                  <span className="text-danger">*</span>
                                )}
                                {formSubmitted &&
                                  (formState[item.field_name] ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-success ml-2"
                                    />
                                  ) : (
                                    <span className="text-muted ml-2 small">
                                      (skipped)
                                    </span>
                                  ))}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={`Enter ${item.label}`}
                                required={item.required}
                                name={item.field_name}
                                id={item.field_name}
                                onChange={handleChange}
                                value={formState[item.field_name]}
                                readOnly={formSubmitted}
                              />
                            </Form.Group>
                          );

                        case "rating":
                          return (
                            <Form.Group key={idx}>
                              <Form.Label>
                                {idx + 1}. {item.label}{" "}
                                {item.required && (
                                  <span className="text-danger">*</span>
                                )}
                                {formSubmitted &&
                                  (formState[item.field_name] ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-success ml-2"
                                    />
                                  ) : (
                                    <span className="text-muted ml-2 small">
                                      (skipped)
                                    </span>
                                  ))}
                              </Form.Label>
                              <div
                                key={`inline-radio`}
                                className="d-flex align-items-center mb-3 mb-md-4 mt-2"
                              >
                                <p className="mb-0 mr-2 small text-uppercase">
                                  {item.start_label}
                                </p>
                                <React.Fragment>
                                  {generateArray(1, 5)?.map((arr, key) => {
                                    return (
                                      <Form.Check
                                        key={key}
                                        inline
                                        label={arr}
                                        name={`${item.field_name}`}
                                        type="radio"
                                        id={`inline-radio-${arr}`}
                                        onChange={(e) =>
                                          handleChangeRating(e, arr)
                                        }
                                        checked={
                                          formState[item.field_name] === arr
                                        }
                                        disabled={formSubmitted}
                                      />
                                    );
                                  })}
                                </React.Fragment>
                                <p className="mb-0 ml-2 small text-uppercase">
                                  {item.end_label}
                                </p>
                              </div>
                            </Form.Group>
                          );

                        default:
                          return null;
                      }
                    })}
                    {formSubmitted ? (
                      <div className="border rounded bg-light p-2">
                        <div className="d-flex align-items-center">
                          <div className="mr-2">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-success"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-success mb-0">
                              Responses submitted successfully
                            </p>
                            <p className="text-success small mb-0">
                              {moment(
                                convertSecondstoMilliSeconds(
                                  formResponseDoc?.data()?.created_at?.seconds,
                                  formResponseDoc?.data()?.created_at
                                    ?.nanoseconds
                                )
                              ).format("LLL")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Button
                          className="px-3 px-md-4 rounded-sm text-uppercase"
                          type="submit"
                          disabled={
                            sendingForm ||
                            formDoc?.data()?.ends_at < Date.now() ||
                            formDoc?.data()?.starts_at > Date.now()
                          }
                        >
                          {sendingForm && (
                            <Spinner
                              size="sm"
                              animation="border"
                              className="mr-1"
                            />
                          )}
                          <small>{sendingForm ? "Sending..." : "Submit"}</small>
                        </Button>
                        {formDoc?.data()?.starts_at > Date.now() && (
                          <p className="mb-0 small">
                            Starts at{" "}
                            <b>
                              {moment(formDoc?.data()?.starts_at).format("LLL")}
                            </b>
                          </p>
                        )}
                      </>
                    )}
                  </Form>
                </Card.Body>
                <Card.Footer>
                  {}
                  <p className="mb-0 small">
                    Deadline by{" "}
                    <b>{moment(formDoc?.data()?.ends_at).format("LLL")}</b>
                  </p>
                </Card.Footer>
              </Card>
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(IgesiaForm);
