import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faIdCard,
  faKey,
  faLanguage,
  faLock,
  faMemory,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUserGraduate,
  faUsers,
  faUsersCog,
  faVideo,
  faUniversity,
  faTh,
  faTasks,
  faClipboardList,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function FontAwesomeExports({ icon, style, className, size }) {
  if (`${icon}` == "faClipboardList") return <FontAwesomeIcon icon={faClipboardList} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faAddressCard") return <FontAwesomeIcon icon={faAddressCard} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faTasks") return <FontAwesomeIcon icon={faTasks} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faBell") return <FontAwesomeIcon icon={faBell} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faUniversity") return <FontAwesomeIcon icon={faUniversity} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faHome") return <FontAwesomeIcon icon={faHome} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faVideo") return <FontAwesomeIcon icon={faVideo} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faUserGraduate") return <FontAwesomeIcon icon={faUserGraduate} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faCalendarAlt") return <FontAwesomeIcon icon={faCalendarAlt} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faStore") return <FontAwesomeIcon icon={faStore} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faBook") return <FontAwesomeIcon icon={faBook} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faChartPie") return <FontAwesomeIcon icon={faChartPie} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faClone") return <FontAwesomeIcon icon={faClone} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faComment") return <FontAwesomeIcon icon={faComment} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faComments") return <FontAwesomeIcon icon={faComments} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faFile") return <FontAwesomeIcon icon={faFile} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faIdCard") return <FontAwesomeIcon icon={faIdCard} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faKey") return <FontAwesomeIcon icon={faKey} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faLanguage") return <FontAwesomeIcon icon={faLanguage} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faLock") return <FontAwesomeIcon icon={faLock} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faMemory") return <FontAwesomeIcon icon={faMemory} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faPaperPlane") return <FontAwesomeIcon icon={faPaperPlane} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faPoll") return <FontAwesomeIcon icon={faPoll} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faQuestionCircle") return <FontAwesomeIcon icon={faQuestionCircle} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faReply") return <FontAwesomeIcon icon={faReply} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faStickyNote") return <FontAwesomeIcon icon={faStickyNote} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faTicketAlt") return <FontAwesomeIcon icon={faTicketAlt} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faUser") return <FontAwesomeIcon icon={faUser} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faUsers") return <FontAwesomeIcon icon={faUsers} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faUsersCog") return <FontAwesomeIcon icon={faUsersCog} className={className} style={style} size={size ?? "1x"} />;
  if (`${icon}` == "faTh") return <FontAwesomeIcon icon={faTh} className={className} style={style} size={size ?? "1x"} />;
  return <FontAwesomeIcon icon={faHome} className={className} style={style} size={size ?? "1x"} />;
}
