import React, { useState, useMemo, useEffect } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faCheckCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import {
  API_BASE_URL,
  QUIZ_RESPONSE_GET,
  USER_QUIZ_SUBMIT,
} from "../../../../config/index";
import { auth, firestore } from "../../../../firebase";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useCollection } from "react-firebase-hooks/firestore";

const Quiz = (props) => {
  // CONSTANTS
  const {
    darkTheme,
    currentEvent,
    fetchingDetails,
    current_progress,
    resource_details,
    sub_module_details,
    module_id,
    sub_module_id,
  } = props;
  const user = auth.currentUser;

  // STATES
  const [responseAnswers, setResponseAnswers] = useState([]);
  const [imageSelected, setImageSelected] = useState(null);
  const [visible, setVisible] = useState(false);
  const [all, setAll] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [loading, setLoading] = useState(true);

  // FUNCTIONS
  const [current_question, set_current_question] = useState(
    resource_details && responseAnswers?.id
      ? Object.keys(resource_details?.quizQuestions ?? {})?.length - 1
      : 0
  );
  const [submit, setSubmit] = useState(
    resource_details && responseAnswers?.answers
  );

  const [eventQuizDocs, eventQuizDocsLoading, eventQuizDocsError] =
    useCollection(
      firestore
        .collection(`events/${currentEvent?.id}/quizzes`)
        .where("content_id", "==", sub_module_details?.content_id)
    );

  const eventQuizDetails = useMemo(() => {
    if (!eventQuizDocsLoading && eventQuizDocs?.docs[0]?.exists) {
      return {
        ...eventQuizDocs?.docs[0]?.data(),
        id: eventQuizDocs?.docs[0]?.id,
      };
    } else return {};
  }, [eventQuizDocs, eventQuizDocsLoading]);

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const getResponses = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${QUIZ_RESPONSE_GET}?content_id=${sub_module_details?.content_id}&user_id=${auth?.currentUser?.uid}`
      );

      if (response.status === 200) {
        const { data } = response.data;

        setResponseAnswers({ ...data });
        setSubmit(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("[Error] Fetching Quiz details: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getResponses();
  }, []);

  useEffect(() => {
    if (resource_details && responseAnswers?.answers) {
      let responsedAnswers = { ...responseAnswers.answers };

      let answers = [];
      Object.keys(resource_details?.quizQuestions ?? {})
        .sort(
          (a, b) =>
            resource_details?.quizQuestions[a]?.sequenceNo -
            resource_details?.quizQuestions[b]?.sequenceNo
        )
        .map((q, k) => {
          let mulAnswers = [];
          let sinAnswer = "";
          Object.keys(resource_details?.quizQuestions[q]?.options ?? {})
            .sort(
              (a, b) =>
                resource_details?.quizQuestions[q]?.options[a]?.sequenceNo -
                resource_details?.quizQuestions[q]?.options[b]?.sequenceNo
            )
            .map((o, key) => {
              if (resource_details?.quizQuestions[q].type === 1) {
                if (
                  responsedAnswers[q] &&
                  Object.keys(responsedAnswers[q].options).includes(o)
                ) {
                  mulAnswers.push(
                    resource_details?.quizQuestions[q].options[o].optionValue
                  );
                }
              } else {
                if (
                  responsedAnswers[q] &&
                  Object.keys(responsedAnswers[q].options).includes(o)
                ) {
                  sinAnswer =
                    resource_details?.quizQuestions[q].options[o].optionValue;
                }
              }
            });
          if (mulAnswers.length > 0) {
            answers.push(mulAnswers);
          }
          if (sinAnswer) {
            answers.push(sinAnswer);
          }
          if (sinAnswer === "" && mulAnswers.length === 0) {
            answers.push("");
          }
        });
      setAnswers(answers);
      setSubmit(true);
    }
  }, [responseAnswers, resource_details]);

  const addAnswer = (q_no, ans, type = 0, question_id, option_id) => {
    // console.log(q_no, ans, type, "q_no,ans,type");
    // console.log(question_id, option_id, "q_id,o_id");
    if (type === 0) {
      let allAnswers = answers;
      let resAnswers = [...responseAnswers];
      let prev = allAnswers[q_no] ? allAnswers[q_no] : "";
      let prevAns = resAnswers[q_no] ? resAnswers[q_no] : {};
      allAnswers[q_no] = prev === ans ? "" : ans;

      let json = { question_id: question_id, options: option_id };
      if (
        prevAns.question_id === question_id &&
        prevAns.option_id === option_id
      ) {
        resAnswers.push({});
      } else {
        resAnswers.splice(q_no, 1, json);
      }

      //resAnswers.push(json);
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    } else {
      let allAnswers = answers;
      let resAnswers = responseAnswers;
      let prev = allAnswers[q_no] ? allAnswers[q_no] : [];

      allAnswers[q_no] = prev.includes(ans)
        ? prev.filter((item) => item !== ans)
        : [...prev, ans];
      let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );
      let index = resAnswers.findIndex(
        (res) => res.question_id === question_id
      );
      PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};
      let prevAnswers =
        PrevAnswerObj && PrevAnswerObj.options ? PrevAnswerObj.options : [];
      prevAnswers = prevAnswers.includes(option_id)
        ? prevAnswers.filter((item) => item !== option_id)
        : [...prevAnswers, option_id];
      PrevAnswerObj.question_id = question_id;
      PrevAnswerObj.options = [...prevAnswers];
      if (index === -1) {
        resAnswers.push(PrevAnswerObj);
      } else {
        resAnswers.splice(index, 1, PrevAnswerObj);
      }
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    }
  };

  const handleSubmit = async () => {
    if (eventQuizDetails?.questions_mandatory) {
      if (
        Object.entries(resource_details?.quizQuestions).length !==
        responseAnswers.length
      ) {
        toast.error("Please answer all the questions", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          autoClose: 5000,
        });

        return;
      }
    }

    axios
      .post(`${API_BASE_URL}${USER_QUIZ_SUBMIT}`, {
        user_id: user.uid,
        room_id: currentEvent?.room_id,
        workspace_id: currentEvent?.workspace_id,
        quiz_id: sub_module_details?.content_id,
        answers: [...responseAnswers],
        child_id: sub_module_id,
        parent_id: module_id,
        ancestor_id: sub_module_details?.ancestor_id,
      })
      .then(() => {
        toast.dark("🎊 Submitted successfully 🎊", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        getResponses();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (resource_details && resource_details?.isGraded) {
      let questionGradepointsArray = Object.values(
        resource_details?.quizQuestions
      )
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
        .map((item) => parseInt(item.weight));

      setTotalPoints(
        questionGradepointsArray?.reduce((a, b) => parseInt(a) + parseInt(b))
      );
    }
  }, [resource_details]);

  if (fetchingDetails || loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Card
      className={
        darkTheme ? "shadow-sm rounded bg-dark" : "shadow-sm rounded bg-white"
      }
    >
      <Card.Header className="d-flex align-items-start">
        <div className="flex-grow-1">
          {/* <h4 className="font-weight-bold mb-0">
                {resource_details?.quizName}
              </h4> */}
          <p className="small mb-0">
            {moment(
              convertSecondstoMilliSeconds(
                resource_details?.createdAt?._seconds,
                resource_details?.createdAt?._nanoseconds
              )
            ).format("LLL")}
          </p>
          {resource_details?.isGraded && (
            <p className="small mt-2 mb-0">
              <b>Total grade points: </b>
              {totalPoints}
            </p>
          )}
        </div>
      </Card.Header>
      <Card.Body className="p-3">
        <>
          {resource_details &&
            Object.keys(resource_details?.quizQuestions)
              .sort(
                (a, b) =>
                  resource_details?.quizQuestions[a].sequenceNo -
                  resource_details?.quizQuestions[b].sequenceNo
              )
              .map(
                (q, k) =>
                  current_question === k && (
                    <Card className="task" key={k}>
                      <Card.Header className="bg-white d-flex">
                        <small className="text-dark flex-grow-1">
                          Question {k + 1} <br />
                          {resource_details?.quizQuestions[q].weight
                            ? resource_details?.quizQuestions[q].weight > 1
                              ? `${resource_details?.quizQuestions[q].weight} points`
                              : `${resource_details?.quizQuestions[q].weight} point`
                            : ""}
                        </small>
                        <div className="d-flex">
                          {current_question !== 0 && (
                            <Button
                              size="sm"
                              variant="light"
                              style={{
                                width: "100px",
                                marginRight: "5px",
                              }}
                              onClick={() =>
                                set_current_question((prev) => prev - 1)
                              }
                            >
                              <small>Previous</small>
                            </Button>
                          )}

                          {current_question !==
                            Object.keys(resource_details?.quizQuestions)
                              .length -
                              1 && (
                            <Button
                              size="sm"
                              variant="dark"
                              style={{
                                width: "100px",
                                marginLeft: "5px",
                              }}
                              onClick={() =>
                                set_current_question((prev) => prev + 1)
                              }
                            >
                              <small>Next</small>
                            </Button>
                          )}

                          {current_question ===
                            Object.keys(resource_details?.quizQuestions)
                              .length -
                              1 &&
                            (submit ? (
                              <Button
                                size="sm"
                                variant="primary"
                                style={{
                                  width: "100px",
                                  marginLeft: "5px",
                                }}
                                disabled
                              >
                                <small>Completed</small>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                variant="success"
                                style={{
                                  width: "100px",
                                  marginLeft: "5px",
                                }}
                                onClick={() => handleSubmit()}
                                disabled={responseAnswers.length === 0}
                              >
                                <small>Submit</small>
                              </Button>
                            ))}
                        </div>
                      </Card.Header>

                      <Card.Body>
                        <small className="font-weight-bold text-dark">
                          {answers.filter((item) => item !== null).length} of{" "}
                          {Object.keys(resource_details?.quizQuestions).length}{" "}
                          answered{" "}
                          {eventQuizDetails?.questions_mandatory && (
                            <span className="text-danger">
                              (All questions are mandatory)
                            </span>
                          )}
                        </small>

                        <p className="text-dark">
                          {resource_details?.quizQuestions[q].question}
                        </p>
                        <div>
                          {resource_details?.quizQuestions[q].questionImage && (
                            <figure>
                              <Zoom>
                                <img
                                  alt={
                                    resource_details?.quizQuestions[q].question
                                  }
                                  src={
                                    resource_details?.quizQuestions[q]
                                      .questionImage
                                  }
                                  className="img-fluid w-100 rounded-sm"
                                  style={{ height: 120 }}
                                />
                              </Zoom>
                            </figure>
                          )}
                        </div>

                        {Object.keys(resource_details?.quizQuestions[q].options)
                          .sort(
                            (a, b) =>
                              resource_details?.quizQuestions[q].options[a]
                                .sequenceNo -
                              resource_details?.quizQuestions[q].options[b]
                                .sequenceNo
                          )
                          .map((o, key) => (
                            <>
                              {resource_details?.quizQuestions[q].options[o]
                                .optionImage == null ? null : (
                                <img
                                  src={
                                    resource_details?.quizQuestions[q].options[
                                      o
                                    ].optionImage
                                  }
                                  style={{ width: "100px" }}
                                />
                              )}
                              <div
                                className={
                                  resource_details?.quizQuestions[q].type === 0
                                    ? resource_details?.quizQuestions[q]
                                        .options[o].optionValue ===
                                        answers[k] ||
                                      (responseAnswers[k] &&
                                        q ===
                                          responseAnswers[k]["question_id"] &&
                                        responseAnswers[k] &&
                                        o === responseAnswers[k]["options"])
                                      ? "form-check rounded mb-2 border-dark d-flex"
                                      : "form-check rounded mb-2 d-flex"
                                    : answers[k] &&
                                      answers[k].includes(
                                        resource_details?.quizQuestions[q]
                                          .options[o].optionValue
                                      )
                                    ? "form-check rounded mb-2 border-dark d-flex"
                                    : "form-check rounded mb-2 d-flex"
                                }
                                key={key}
                              >
                                <input
                                  className="form-check-input"
                                  type={
                                    resource_details?.quizQuestions[q].type ===
                                    0
                                      ? "radio"
                                      : "checkbox"
                                  }
                                  value={
                                    resource_details?.quizQuestions[q].options[
                                      o
                                    ].optionValue
                                  }
                                  name={
                                    resource_details?.createdBy +
                                    "_" +
                                    resource_details?.quizQuestions[q].options[
                                      o
                                    ].optionValue +
                                    k +
                                    key
                                  }
                                  id={
                                    resource_details?.createdBy +
                                    "_" +
                                    k +
                                    "_" +
                                    key +
                                    "_" +
                                    q
                                  }
                                  onChange={(e) => {
                                    addAnswer(
                                      k,
                                      e.target.value,
                                      resource_details?.quizQuestions[q].type,
                                      q,
                                      o
                                    );
                                    setAll(e.target.value);
                                  }}
                                  checked={
                                    resource_details?.quizQuestions[q].type ===
                                    0
                                      ? resource_details?.quizQuestions[q]
                                          .options[o].optionValue === answers[k]
                                        ? true
                                        : false
                                      : answers[k] &&
                                        answers[k].includes(
                                          resource_details?.quizQuestions[q]
                                            .options[o].optionValue
                                        )
                                  }
                                  disabled={submit}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={
                                    resource_details?.createdBy +
                                    "_" +
                                    k +
                                    "_" +
                                    key +
                                    "_" +
                                    q
                                  }
                                >
                                  {
                                    resource_details?.quizQuestions[q].options[
                                      o
                                    ].optionValue
                                  }
                                </label>
                                {submit &&
                                  eventQuizDetails?.show_responses &&
                                  Object.entries(
                                    resource_details?.quizQuestions[q].options
                                  )
                                    ?.filter((e) => e[1].correctAnswer)
                                    ?.map((e) => e[0])
                                    ?.includes(o) && (
                                    <p className="ml-2 mb-0 text-success">
                                      (Correct)
                                    </p>
                                  )}
                              </div>
                            </>
                          ))}

                        {submit && eventQuizDetails?.show_responses && (
                          <>
                            {responseAnswers.answers[q].correct ? (
                              <p className="text-success mb-0 mt-2">
                                <FontAwesomeIcon icon={faCheck} /> Correct
                                Answer{" "}
                                {responseAnswers.answers[q]?.score &&
                                  `(${responseAnswers.answers[q]?.score} points)`}
                              </p>
                            ) : (
                              <p className="text-danger mb-0 mt-2">
                                <FontAwesomeIcon icon={faTimes} /> Incorrect
                                Answer
                              </p>
                            )}
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  )
              )}
        </>
        {submit && (
          <div
            className="d-flex mt-2 pt-2 pb-1"
            style={{
              borderTop: 2,
              borderTopStyle: "dashed",
              borderTopColor: "#dbdbdb",
            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
            <p
              className="small pb-0 mb-0 flex-grow-1 px-2"
              style={{ color: "#777B8E" }}
            >
              Response recorded{" "}
              {moment(
                resource_details?.response &&
                  resource_details?.response[user?.id] &&
                  resource_details?.response[user?.id].responseTime
              ).format("MMM DD, YYYY hh:mm A")}
            </p>
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 small">
          Deadline by <b>{moment(resource_details?.deadline).format("LLL")}</b>
        </p>
      </Card.Footer>
    </Card>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(Quiz);
