import React, { useEffect, useMemo, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import HtmlParser from "react-html-parser";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function MultiplePages({ content, eventId, pageId }) {
  const [key, setKey] = useState("1");
  const [pageLoading, setPageLoading] = useState(true);

  const [contentSnapshot, contentLoading, contentError] = useCollection(
    firebase.firestore().collection(`events/${eventId}/pages/${pageId}/content`)
  );
  const contentData = useMemo(() => {
    return contentSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [contentSnapshot]);

  useEffect(() => {
    if (!contentLoading) {
      setPageLoading(false);
    }
  }, [contentLoading]);

  const getContent = (contentId) => {
    const thisContent = contentData?.filter((item) => item.id === contentId);
    if (!!thisContent) {
      return thisContent[0]?.content;
    } else {
      return `<p><i>No content added</i></p>`;
    }
  };

  const getFeaturedImage = (contentId) => {
    const thisContent = contentData?.filter((item) => item.id === contentId);
    if (!!thisContent) {
      return thisContent[0]?.featured_image;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {pageLoading && (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" size="sm" /> Loading...
        </div>
      )}
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        style={{ borderBottom: 0 }}
      >
        {content?.map((item) => (
          <Tab
            tabClassName={
              key === `${item.order_index}`
                ? "col bg-primary text-white font-weight-bold text-center border-0 rounded-sm"
                : "col bg-light text-secondary font-weight-bold text-center border-0 rounded-sm"
            }
            eventKey={`${item.order_index}`}
            title={`${item.tab_name}`}
          >
            {getFeaturedImage(item.content_id) && (
              <div className="my-3">
                <figure>
                  <Zoom>
                    <img
                      src={getFeaturedImage(item.content_id)}
                      className="img-fluid w-100 rounded-sm"
                    />
                  </Zoom>
                </figure>
              </div>
            )}
            {!!item.content_id ? (
              <div className="p-3">
                {HtmlParser(getContent(item.content_id))}
              </div>
            ) : (
              <div className="p-3">
                {HtmlParser(`<p><i>No content added</i></p>`)}
              </div>
            )}
          </Tab>
        ))}
      </Tabs>
    </React.Fragment>
  );
}
