import React, { useMemo, memo, useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router";
//import { projectList, blogList } from "../../data";
import { connect } from "react-redux";
import { setProject, setBlog } from "../../store/actions/actions";
import AddConsultancyModal from "./Sidebar/AddConsultancyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "./Navbar";
import moment from "moment";
import {
    faEdit, faUserEdit, faShapes, faVideo, faPoll, faCalendarAlt, faClipboardCheck, faClinicMedical, faNotesMedical, faHdd, faFile, faBook, faBookReader, faCheckSquare, faGripHorizontal, faGreaterThanEqual, faListAlt, faChartPie, faLock, faUsersCog, faReply, faClone, faQuestionCircle, faStickyNote, faKey, faSearch, faCog, faLink, faCode, faChartLine, faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import {
    faYoutube
} from "@fortawesome/free-brands-svg-icons";

const Sidebar = (props) => {
    const history = useHistory();

    const { events, blogs } = props;

    const userDetails = firebase.auth().currentUser;
    const [showAddConsultancyModal, setShowAddConsultancyModal] = useState(false);

    const [
        consultanciesSnapshot,
        loadingConsultancies,
        errorConsultancies,
    ] = useCollection(
        firebase.firestore().collection("clinics").where("isDemo", "==", true)
    );

    const [coursesSnapshot, loadingCourses, errorCourses] = useCollection(
        firebase.firestore().collection("courses").where("isDemo", "==", true)
    );

    const [projectsSnapshot, loadingProjects, errorProjects] = useCollection(
        firebase.firestore().collection("projects")
    );

    const convertSecondstoMilliSeconds = (seconds) => {
        return Math.floor(seconds * 1000);
    };

    const [
        organizationSnapshot,
        loadingOrganization,
        errorOrganization,
    ] = useCollection(firebase.firestore().collection("organizations"));

    // derived states

    // const consultancies = useMemo(() => {
    //   return consultanciesSnapshot?.docs?.map((e) => {
    //     return { id: e.id, ...e.data() };
    //   });
    // }, [consultanciesSnapshot]);

    const courses = useMemo(() => {
        return coursesSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [coursesSnapshot]);

    const projects = useMemo(() => {
        return projectsSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [projectsSnapshot]);

    const organizations = useMemo(() => {
        return organizationSnapshot?.docs?.map((e) => {
            return { id: e.id, ...e.data() };
        });
    }, [organizationSnapshot]);

    return (
        <>
            <NavBar />
            <div className="container-fluid">
                <div className="row mt-3 border-bottom border-dark mb-3">
                    <div className="col-md-11 offset-md-1 text-light pb-3">
                        <Link to="/admin/integrations" className="text-decoration-none"><a className="btn btn-md btn-outline-primary"> <FontAwesomeIcon icon={faAngleLeft} /> Integrations</a></Link>
                        <h4 className="ml-3 mb-3 d-inline">Zoom Setup</h4>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-10 offset-md-1">
                    <div className="row text-white mt-3">
                        <div className="col-md-12">
                            <div className="mt-2 px-3 pt-4 pb-2 rounded mb-3" style={{ border: "1px solid rgba(255, 255, 255, .3)" }} >
                                <div className="row">
                                    <div className="col-md-12 mb-4 col-6">
                                            <h5 className="text-s-8 mt-3 mb-0">Fill in these details to link your Zoom account.</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div >
        </>
    );
};
const sideBar = memo(Sidebar);

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        project: state.project,
        events: state.myEvents,
        blogs: state.myBlogs,
    }),
    { setProject, setBlog }
)(Sidebar);
