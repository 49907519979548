import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function TableCell(props) {
  const { rowData, headData, darkTheme } = props;
  let column = headData.id,
    row = rowData.id;

  const [showAddValueModal, setShowAddValueModal] = useState(false);
  const [newCellValue, setNewCellValue] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [isCellValueEdit, setIsCellValueEdit] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [addNote, setAddNote] = useState(false);
  const [cellValuesArray, setCellValuesArray] = useState([]);
  const [valuesCollection, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `/events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns/${column}/values/${row}/values`
      )
  );

  useEffect(() => {
    if (valuesCollection) {
      const valuesArray = valuesCollection?.docs?.map((item) => {
        return { ...item.data(), id: item.id };
      });
      setCellValuesArray(valuesArray);
    }
  }, [valuesCollection]);

  const deleteCellValueHandler = (e, item) => {
    e.preventDefault();
    if (item) {
      firebase
        .firestore()
        .collection(
          `/events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns/${selectedColumn.id}/values/${selectedRow.id}/values`
        )
        .doc(`${item.id}`)
        .delete()
        .then(() => {
          toast.success("Deleted successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const editCellValueHandler = (e) => {
    e.preventDefault();
    if (editValue) {
      firebase
        .firestore()
        .collection(
          `/events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns/${selectedColumn.id}/values/${selectedRow.id}/values`
        )
        .doc(`${editValue.id}`)
        .set(
          {
            note: editValue.note ?? "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          setIsCellValueEdit(false);
        });
    }
  };

  const addCellValueHandler = (e) => {
    e.preventDefault();
    // console.log(selectedRow, selectedColumn, newCellValue)
    let user = firebase.auth().currentUser;
    let data = {
      note: newCellValue,
      created_at: new Date(),
      created_by: {
        name: user.displayName,
        email: user.email,
        id: user.uid,
      },
    };
    if (newCellValue) {
      firebase
        .firestore()
        .collection(
          `/events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns/${selectedColumn.id}/values/${selectedRow.id}/values`
        )
        .add(data)
        .then(() => {
          setSelectedRow(null);
          setSelectedColumn(null);
          setNewCellValue("");
          setShowAddValueModal(false);
        });
    }
  };

  if (headData.id == 0)
    return (
      <td
        className={
          darkTheme ? " border border-light col" : " border border-dark col"
        }
      >
        {rowData.name}
      </td>
    );
  else
    return (
      <>
        <td
          className={
            darkTheme ? " border border-light col" : " border border-dark col"
          }
          onClick={() => {
            // console.log( rowData, headData )
            setSelectedRow(rowData);
            setSelectedColumn(headData);
            setShowAddValueModal(true);
          }}
        >
          {loading ? (
            "Loading..."
          ) : (
            <div className="row px-2">
              {cellValuesArray.map((item) => {
                return (
                  <p className="m-1" style={{ color: "red" }}>
                    {`X`}
                  </p>
                );
              })}
            </div>
          )}
        </td>
        <>
          <Modal
            centered
            show={showAddValueModal}
            onHide={() => {
              setShowAddValueModal(false);
              setAddNote(false);
            }}
          >
            <Modal.Header data-theme={darkTheme ? "dark" : "light"} closeButton>
              <Modal.Title>
                {selectedRow && selectedRow.name}&nbsp;
                {selectedColumn && selectedColumn.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
              <div>
                {cellValuesArray.map((item, index) => {
                  return (
                    <div className="d-flex">
                      {!isCellValueEdit ? (
                        <>
                          <p className="m-1 flex-grow-1">
                            {`${index + 1}. ${item.note} (${
                              item.created_by.name
                            })`}
                          </p>
                          <Button
                            onClick={() => {
                              setIsCellValueEdit(true);
                              setEditValue(item);
                            }}
                            size="sm"
                            variant="primary"
                            className="mr-1"
                          >
                            <div>
                              <FontAwesomeIcon size="sm" icon={faEdit} /> Edit
                            </div>
                          </Button>
                          <Button
                            onClick={(e) => {
                              deleteCellValueHandler(e, item);
                            }}
                            size="sm"
                            variant="danger"
                          >
                            <div>
                              <FontAwesomeIcon size="sm" icon={faTrash} />{" "}
                              Delete
                            </div>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Form.Control
                            type="text"
                            placeholder="Edit Value"
                            value={editValue?.note}
                            onChange={(e) => {
                              setEditValue((prev) => {
                                const newState = {
                                  ...prev,
                                  note: e.target.value,
                                };
                                return newState;
                              });
                            }}
                          />
                          <Button
                            onClick={(e) => {
                              editCellValueHandler(e);
                            }}
                            size="sm"
                            variant="primary"
                            className="ml-2"
                          >
                            <div>Save</div>
                          </Button>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {addNote && (
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Add Note</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Note to add"
                      value={newCellValue}
                      onChange={(e) => setNewCellValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer data-theme={darkTheme ? "dark" : "light"}>
              <div className="d-flex">
                <Button
                  className="col-sm mr-2"
                  variant="secondary"
                  onClick={() => {
                    setShowAddValueModal(false);
                    setAddNote(false);
                  }}
                >
                  Close
                </Button>
                {addNote && (
                  <Button
                    className="col-sm"
                    variant="primary"
                    onClick={(e) => addCellValueHandler(e)}
                  >
                    Save
                  </Button>
                )}
                {!addNote && (
                  <Button
                    className="col-sm"
                    variant="primary"
                    onClick={() => setAddNote(true)}
                  >
                    Add Note
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </>
      </>
    );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  null
)(TableCell);
