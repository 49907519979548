import React, { useState, useEffect } from "react";
import EventLeftbar from "../LeftBar";
import EventNavbar from "../NavBar";
import Highcharts from "highcharts/highstock";
import { connect } from "react-redux";
import { Form, Button, Dropdown, Modal } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HC_more from "highcharts/highcharts-more";
import firebase from "firebase";
import { TwitterPicker } from "react-color";
import Tier from "./Tier"
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  setFactors,
  setProducts,
  setGraphId,
  setAsIsFactors,
} from "../../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import ErrcGrid from "./ErrcGrid";
import ToBe from "./To-be";
import Table from "./Table";
import SixPath from "./Sixpath";

HC_more(Highcharts);

require("highcharts/modules/draggable-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts)

var yourLabels = ["Low", "Medium", "High"];

function ASISTemplate(props) {
  const [title, setTitle] = useState("");
  const [factors, setFactors] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (props.graph.id) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`)
        .doc(props.graph.id)
        .onSnapshot((snapshot) => {
          if (snapshot.data().title) {
            setTitle(snapshot.data().title);
          }
        });
    }
  }, [props]);

  const [factorsCollection, factorsCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos/${props.graph.id}/factors`)
  );

  const factorsArray = factorsCollection?.docs
    ?.sort((a, b) => a.position - b.position)
    ?.map((item) => {
      return { ...item.data(), id: item.id };
    });

  useEffect(() => {
    if (factorsArray) {
      let data = factorsArray.filter(f => !f.isNew);
      setFactors([...data]);
    }
  }, [factorsCollection]);

  const [productCollection, productCollectionLoading, err] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos/${props.graph.id}/as_is`)
  );

  const getYDataHandler = async () => {
    try {
      if (productCollection?.docs?.length) {
        // setLoading(true)
        const collectionData = await Promise.all(
          productCollection?.docs?.map(async (item) => {
            let docRef = firebase
              .firestore()
              .collection(
                `events/${props?.currentEvent?.id}/bbos/${props.graph.id}/as_is/${item.id}/values`
              );

            const data = await Promise.all(
              factors?.map(async (factor) => {
                const doc = await docRef.doc(factor.id).get();
                if (doc.exists) {
                  return doc.data().value;
                } else {
                  return factor.defaultValue;
                }
              })
            );
            if (data.length === factors.length) {
              return {
                ...item.data(),
                id: item.id,
                data: data,
              };
            }
          })
        );
        setProducts(collectionData);
      }
    } catch (e) {
      console.error(e, "Something went wrong");
    } finally {
      // setLoading(false)
    }
  };



  useEffect(() => {
    if (!productCollectionLoading) {
      getYDataHandler();
    }
  }, [productCollectionLoading, productCollection]);

  let options = {
    chart: {
      renderTo: "container",
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: factors.map(x => x.name),
    },

    yAxis: {
      min: 0,
      max: 2,
      labels: {
        formatter: function () {
          return yourLabels[this.value];
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.series.name;
      },
    },
    series: products,
  };




  return (
    <>
    
       
         
              <React.Fragment>
               
                    <HighchartsReact
                      constructorType={"chart"}
                      highcharts={Highcharts}
                      options={options}
                    />
              </React.Fragment>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    workspaceId: state.workspaceId,
    currentEvent: state.currentEvent,
    roomId: state.roomId,
    graphId: state.graphId,
    factors: state.factors,
    products: state.products,
    series: state.series,
    asisFactors: state.asisFactors,
  }),
  {
    setFactors,
    setProducts,
    setGraphId,
    setAsIsFactors,
  }
)(ASISTemplate);
