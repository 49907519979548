import React, { useState, useEffect, useMemo } from "react";
import Highcharts from "highcharts/highstock";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Card,
  Modal,
} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HC_more from "highcharts/highcharts-more";
import firebase from "firebase";
import { TwitterPicker } from "react-color";
import _ from "lodash";
import {
  setFactors,
  setProducts,
  setProductsToBe,
  setGraphId,
  setAsIsFactors,
} from "../../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { toast } from "react-toastify";
import ToBeSettings from "./ToBeSettings";
import { API_BASE_URL, BBOA_REORDER_FACTORS } from "../../../../config";
import axios from "axios";
import { handleExportJpeg, handleExportPng } from "../../../../utils";
import { XAxis } from "recharts";

HC_more(Highcharts);

require("highcharts/modules/draggable-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

var yourLabels = ["Low", "Medium", "High"];

function TOBE(props) {
  const {
    darkTheme,
    currentEvent,
    graphId,
    factors,
    products,
    productsToBe,
    setProducts,
    setProductsToBe,
    title,
  } = props;

  const [tobeProducts, setToBeProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState([]);

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("red");
  const [newProductName, setNewProductName] = useState("");
  const [showProTips, setShowProTips] = useState(true);
  const [reorderingFactors, setReorderingFactors] = useState(false);

  const [dynamicOption, setDynamicOption] = useState({
    xAxis: [],
    series: [],
  });

  const [productCollection, productCollectionLoading, err] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
  );

  const [errcDoc, errcDocLoading, errcDocError] = useDocument(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/errc_grid`)
      .doc("0")
  );

  const splicePositions = (data, positions) => {
    const pos = positions?.map((item) => item.positionToBe);
    // console.log("pos: ", pos);

    const newData = data?.map((val, key) => {
      if (!pos?.includes(key)) return val;
    });
    // console.log("newData: ", newData);

    return newData?.filter((item) => item >= 0);
  };

  useEffect(async () => {
    if (productCollection?.docs?.length) {
      const productValues = await Promise.all(
        productCollection?.docs?.map(async (p) => {
          const factorValues = await Promise.all(
            factors
              ?.sort((a, b) => a.positionToBe - b.positionToBe)
              ?.map(async (f) => {
                const docRef = await firebase
                  .firestore()
                  .collection(
                    `events/${currentEvent?.id}/bboa/${graphId}/to_be/${p.id}/values`
                  )
                  .doc(f.id)
                  .get();

                if (docRef.exists) {
                  return docRef.data().value;
                } else {
                  return f.defaultValue;
                }
              })
          );

          return {
            ...p.data(),
            id: p.id,
            dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
            data: factorValues,
          };
        })
      );

      console.log("productValues: ", productValues);
      setProductsToBe(productValues);
    }

    setDynamicOption((prev) => {
      const factors_in_main = factors?.filter((item) =>
        errcDoc?.data()?.main?.includes(item.id)
      );

      // const data1 = factors
      //   ?.filter((item) => !errcDoc?.data()?.main?.includes(item.id))
      //   ?.map((factor) => {
      //     return factor.defaultValue;
      //   });

      let updated_products = productsToBe
        ? productsToBe?.map((item) => {
            return {
              ...item,
              data:
                factors_in_main?.length > 0
                  ? splicePositions(item.data, factors_in_main)
                  : item.data,
            };
          })
        : [];

      // console.log(
      //   "To be: ",
      //   factors?.filter((item) => !errcDoc?.data()?.main?.includes(item.id)),
      //   products,
      //   factors_in_main,
      //   updated_products
      // );

      let totalFactors = factors?.filter((item) => (!errcDoc?.data()?.main?.includes(item.id)) && ((errcDoc?.data()?.create?.includes(item.id)) || (errcDoc?.data()?.eliminate?.includes(item.id)) || (errcDoc?.data()?.raise?.includes(item.id)) || (errcDoc?.data()?.reduce?.includes(item.id)) ))

      const additionalFactors = updated_products.length > 0 && updated_products[0]?.data?.length - totalFactors?.length

      // console.log("ML totalFactors ==> ",totalFactors)
      // console.log("ML updated_products ==> ",updated_products)

      if(additionalFactors > 0){
        updated_products = updated_products?.map(item => {
          const newData = item?.data?.slice(0, -additionalFactors);
          return { ...item, data: newData };
        });
      }
      
      const newState = {
        ...prev,
        xAxis: factors
          ?.filter((item) => (!errcDoc?.data()?.main?.includes(item.id)) && ((errcDoc?.data()?.create?.includes(item.id)) || (errcDoc?.data()?.eliminate?.includes(item.id)) || (errcDoc?.data()?.raise?.includes(item.id)) || (errcDoc?.data()?.reduce?.includes(item.id)) ))
          ?.map((x) => x.name),
        series: [
          ...updated_products,
          // {
          //   id: "to-be",
          //   name: "To Be Strategy",
          //   color: "blue",
          //   data: data1,
          //   dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
          // },
        ],
      };

      return newState;
    });
  }, [
    JSON.stringify(productsToBe),
    JSON.stringify(factors),
    errcDoc,
    productCollection,
  ]);

  const getYDataHandler = async () => {
    try {
      if (productCollection?.docs?.length) {
        // setLoading(true)
        const collectionData = await Promise.all(
          productCollection?.docs?.map(async (item) => {
            let docRef = firebase
              .firestore()
              .collection(
                `events/${currentEvent?.id}/bboa/${graphId}/to_be/${item.id}/values`
              );
            let value = [];
            const data = await Promise.all(
              factors
                ?.sort((a, b) => a.positionToBe - b.positionToBe)
                .map(async (factor) => {
                  const doc = await docRef.doc(factor.id).get();
                  if (doc.exists) {
                    return doc.data().value;
                  } else {
                    return null;
                  }
                })
            );
            if (data.length === factors.length) {
              value.push({
                productName: item.data().name,
                name: "As Is Strategy",
                color: item.data().color,
                id: item.id,
                data: data,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              });
            }

            const data1 = await Promise.all(
              factors?.map(async (factor) => {
                return factor.defaultValue;
              })
            );
            if (data.length === factors.length) {
              value.push({
                name: "To Be Strategy",
                color: "blue",
                data: data1,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              });
            }
            return value;
          })
        );
        // console.log("collectionData: ", collectionData);
        setToBeProducts(collectionData);
        collectionData?.length > 0 && setSelectedProduct(collectionData[0]);

        const data1 = await Promise.all(
          factors?.map(async (factor) => {
            return factor.defaultValue;
          })
        );
        setDynamicOption((prev) => {
          const newState = {
            ...prev,
            xAxis: factors
          ?.filter((item) => (!errcDoc?.data()?.main?.includes(item.id)) && ((errcDoc?.data()?.create?.includes(item.id)) || (errcDoc?.data()?.eliminate?.includes(item.id)) || (errcDoc?.data()?.raise?.includes(item.id)) || (errcDoc?.data()?.reduce?.includes(item.id)) ))
          ?.map((x) => x.name),
            series: [
              ...products,
              {
                name: "To Be Strategy",
                color: "blue",
                data: data1,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              },
            ],
          };
          return newState;
        });

        collectionData?.map((item) => {
          if (item[0].id === selectedProduct[0].id) {
            setSelectedProduct(item);
          }
        });
      }
    } catch (e) {
      console.error(e, "Something went wrong");
    } finally {
      // setLoading(false)
    }
  };

  const updateProductValues = async (product, product_id, factor_id, value) => {
    const productDoc = firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
      .doc(product_id);

    const productDocData = await productDoc.get();

    if (!productDocData.exists) {
      productDoc.set({
        color: product.color,
        name: product.name,
      });
    }

    let document = productDoc.collection(`values`).doc(factor_id);

    if (document && document.exists) {
      document.update(
        {
          value: value,
        },
        { merge: true }
      );
    } else {
      document.set(
        {
          value: value,
        },
        { merge: true }
      );
    }
  };

  let options = {
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      renderTo: "container",
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: dynamicOption?.xAxis,
      title: {
        text: "Key Elements",
      },
    },
    scrollbar: { enabled: true },
    yAxis: {
      min: 0,
      max: 2,
      labels: {
        formatter: function () {
          return yourLabels[this.value];
        },
      },
      title: {
        text: "Offering Level",
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.series.name;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            drag: function (e) {
              // console.log(e)
            },
            drop: function (e) {
              // console.log(
              //   e.target,
              //   e.target.category,
              //   e.target.y,
              //   e.target.series.userOptions.id,
              //   factors
              // );
              let key = e.target.series.userOptions.id;
              let value = factors.filter((x) => x.name === e.target.category);

              updateProductValues(
                e.target.series.userOptions,
                key,
                value[0].id,
                e.target.y
              );
            },
          },
        },
        stickyTracking: false,
      },
      column: {
        stacking: "normal",
      },
      line: {
        cursor: "ns-resize",
      },
    },
    series: dynamicOption?.series,
  };

  // console.log("SEP ML Options: ", options);

  useEffect(() => {
    if (!productCollectionLoading) {
      // getYDataHandler();
    }
  }, [productCollectionLoading, productCollection, factors, errcDoc]);

  useEffect(() => {
    setSelectedFactors(
      factors
        ?.filter((item) => (!errcDoc?.data()?.main?.includes(item.id)) && ((errcDoc?.data()?.create?.includes(item.id)) || (errcDoc?.data()?.eliminate?.includes(item.id)) || (errcDoc?.data()?.raise?.includes(item.id)) || (errcDoc?.data()?.reduce?.includes(item.id)) ))
        ?.sort((a, b) => a.positionToBe - b.positionToBe)
        // ?.filter((item) => !errcDoc?.data()?.main?.includes(item.id))
    );
  }, [JSON.stringify(factors), errcDoc]);

  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  function handleOnDragEnd({ destination, source }) {
    // console.log(destination, source);
    let sourceIndex = factors[source.index];
    let destinationIndex = factors[destination.index];
    // console.log(sourceIndex, "source");
    // console.log(destinationIndex, "destination");

    if (destination) {
      // alert(
      //   `Destination: ${destinationIndex.positionToBe}, Source: ${sourceIndex.positionToBe}`
      // );
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
        .doc(sourceIndex.id)
        .set(
          {
            positionToBe: destinationIndex.positionToBe,
          },
          {
            merge: true,
          }
        );
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
        .doc(destinationIndex.id)
        .set(
          {
            positionToBe: sourceIndex.positionToBe,
          },
          {
            merge: true,
          }
        );
    }

    // const item = Array.from(
    //   selectedProduct?.map((x, key) => {
    //     const itemData = x;
    //     const [reorderedData] = itemData.data.splice(source.index, 1);
    //     itemData.data.splice(destination.index, 0, reorderedData);
    //     return itemData;
    //   })
    // );
  }

  const addNewProduct = () => {
    if (newProductName && selectedColor) {
      let data = {
        name: newProductName,
        color: selectedColor,
      };
      if (graphId) {
        firebase
          .firestore()
          .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
          .add(data)
          .then(() => {
            toast.success("Product/ Service added successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setNewProductName("");
            setShowAddProductModal(false);
          });
      }
    }
  };

  const deleteProductHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/to_be`)
        .doc(item.id)
        .delete()
        .then(() => {
          if (products.length == 1) {
            setProducts([]);
          }
          //
        });
    }
  };

  const handleReorderFactors = async () => {
    try {
      setReorderingFactors(true);

      const response = await axios.post(
        `${API_BASE_URL}${BBOA_REORDER_FACTORS}`,
        {
          product_id: currentEvent?.id,
          product_type: currentEvent?.type,
          project_id: graphId,
        }
      );

      if (response.status === 200) {
        toast.success("Reordering factors successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        toast.dark("Error reordering factors", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
      setReorderingFactors(false);
    } catch (error) {
      console.log(`[Error] Reordering factors: ${error.message}`);
      setReorderingFactors(false);
    }
  };

  return (
    <>
      <Card
        className={
          darkTheme
            ? "bg-dark shadow-sm border border-dark mb-3"
            : "bg-white shadow-sm border border-light mb-3"
        }
      >
        <Card.Header className="d-flex justify-content-between align-items-start">
          <div>
            <h5 className="mb-0 text-uppercase">9. TO-BE STRATEGY CANVAS</h5>
          </div>
          <div className="d-flex">
            <Dropdown className="pointer no-dropdown-caret mr-2">
              <Dropdown.Toggle
                as="div"
                id="dropdown-basic"
                className="btn btn-secondary btn-sm text-uppercase"
              >
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    handleExportJpeg("to-be-canvas", `${title}_to-be`)
                  }
                >
                  Download JPEG Image
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleExportPng("to-be-canvas", `${title}_to-be`)
                  }
                >
                  Download PNG Image
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="secondary"
              size="sm"
              className="text-uppercase mr-2"
              disabled={reorderingFactors}
              onClick={handleReorderFactors}
            >
              {reorderingFactors ? (
                <span>Reordering...</span>
              ) : (
                <span>Reorder key elements</span>
              )}
            </Button>
            <Button
              size="sm"
              className="text-uppercase mr-2"
              disabled={showAddProductModal}
              onClick={() => setShowAddProductModal(true)}
            >
              ADD YOUR OFFERING
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-0" id="to-be-canvas">
          {/*----------------------------ADD YOUR OFFERING Modal Start--------------------------- */}
          <Modal
            centered
            show={showAddProductModal}
            onHide={() => setShowAddProductModal(false)}
          >
            <Modal.Header
              className={
                darkTheme
                  ? "bg-dark shadow-sm border border-dark montserrat-font"
                  : "bg-white shadow-sm border border-light montserrat-font"
              }
              closeButton
            >
              <Modal.Title>ADD YOUR OFFERING</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className={
                darkTheme
                  ? "bg-dark shadow-sm border border-dark montserrat-font"
                  : "bg-white shadow-sm border border-light montserrat-font"
              }
            >
              <Form className="mb-0">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Product name</Form.Label>
                  <div className="d-flex align-items-center">
                    <div
                      className="mr-1"
                      onClick={() =>
                        showColorPicker
                          ? setShowColorPicker(false)
                          : setShowColorPicker(true)
                      }
                      style={{
                        height: 35,
                        width: 35,
                        borderRadius: 4,
                        backgroundColor: selectedColor,
                      }}
                    ></div>
                    {showColorPicker ? (
                      <TwitterPicker
                        onChangeComplete={(color) => {
                          setShowColorPicker(false);
                          setSelectedColor(color.hex);
                        }}
                        colors={[
                          "#FF6900",
                          "#FCB900",
                          "#7BDCB5",
                          "#00D084",
                          "#1adef0",
                          "#0693E3",
                          "#ff26d7",
                          "#EB144C",
                          "#F78DA7",
                          "#7500eb",
                        ]}
                      />
                    ) : null}
                    <Form.Control
                      type="text"
                      placeholder="Enter name of product"
                      value={newProductName}
                      autoComplete="off"
                      onChange={(e) => setNewProductName(e.target.value)}
                    />
                  </div>
                </Form.Group>
                <div className="d-flex">
                  <Button
                    variant="primary"
                    size="sm"
                    className="mr-2 text-uppercase col-6"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      addNewProduct();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    className="text-uppercase col-6"
                    onClick={() => setShowAddProductModal(false)}
                  >
                    Discard
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          {/*----------------------------ADD YOUR OFFERING Modal Ends--------------------------- */}

          {
            // selectedProduct.length > 0
            true ? (
              <>
                <HighchartsReact
                  constructorType={"chart"}
                  highcharts={Highcharts}
                  options={options}
                />
                <div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {selectedFactors?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={item.positionToBe}
                              // isDragDisabled={item.name === "Price"}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={
                                    index == 0
                                      ? "btn btn-light"
                                      : "btn btn-light "
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={getItemStyle(
                                  //   snapshot.isDragging,
                                  //   provided.draggableProps.style
                                  // )}
                                >
                                  {item.name}
                                  {/* {item.name} ({item.positionToBe}) */}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <p className="p-3 p-md-5 mb-0">
                  Select a <b>Product</b> on top right to view To-Be Strategy
                  Canvas.
                </p>
              </div>
            )
          }
        </Card.Body>
        <Card.Footer>
          <div className="d-flex align-items-center">
            <p className="mb-0 text-uppercase small">
              <b>Pro tips</b>
            </p>
            <p
              style={{ cursor: "pointer" }}
              className="mb-0 text-uppercase small text-muted ml-2"
              onClick={() => setShowProTips((prev) => !prev)}
              title={
                showProTips
                  ? "Click to hide pro tips"
                  : "Click to show pro tips"
              }
            >
              <b>({showProTips ? "Hide" : "Show"})</b>
            </p>
          </div>
          <ol
            className={showProTips ? "px-3 mb-1 pb-1" : "px-3 mb-1 pb-1 d-none"}
          >
            <li>
              <small>Drag factors to change their position on x-axis.</small>
            </li>
            <li>
              <small>Drag points on y-axis to change their value.</small>
            </li>
            <li>
              <small>
                To add more <b>Products</b> and <b>Factors</b> go to project
                settings.
              </small>
            </li>
            <li>
              <small>
                To hide or show <b>To-Be</b> or <b>As-Is</b> graph click on the
                Legend below.
              </small>
            </li>
          </ol>
        </Card.Footer>
      </Card>

      <ToBeSettings />
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    graphId: state.graphId,
    factors: state.factors,
    products: state.products,
    productsToBe: state.productsToBe,
    series: state.series,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  {
    setFactors,
    setProducts,
    setProductsToBe,
    setGraphId,
    setAsIsFactors,
  }
)(TOBE);
