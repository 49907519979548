import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
    ViewState,
    EditingState,
    IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import {
    Scheduler,
    DayView,
    MonthView,
    WeekView,
    Appointments,
    ViewSwitcher,
    Toolbar,
    EditRecurrenceMenu,
    DragDropProvider,
    DateNavigator,
    TodayButton,
    AppointmentTooltip,
    AppointmentForm,
    ConfirmationDialog,
} from "@devexpress/dx-react-scheduler-material-ui";
import firebase from "firebase";
import moment from "moment";
import axios from "axios";
// import useFetch from "../../../hooks/useFetch";
// import { useCollection } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPaperPlane,
  } from "@fortawesome/free-solid-svg-icons";

const Calendar = (props) => {
    const clinicId = useSelector((state) => state.clinicId);
    const [data, setData] = useState(
        JSON.parse(window.localStorage.getItem("scheduler")) ?? []
    );

    const [scheduleData, setScheduleData] = useState([]);

    const [calenderData, setCalenderData] = useState(null);

    const getAuthToken = async () => {
        try {
            const idToken = await firebase.auth().currentUser.getIdToken(true);
            return idToken;
        } catch (e) {
            console.error(e, "error in getting auth token");
            return 0;
        }
    };

    const getHeaders = async () => {
        return {
            "content-type": "application/json",
            authorization: await getAuthToken(),
        };
    };

    const [updatingData, setUpdatingData] = useState(false);

    const commitChanges = async ({ added, changed, deleted }) => {
        let schedule = data;
        if (added) {
            const startingAddedId =
                schedule.length > 0 ? schedule[schedule.length - 1].id + 1 : 0;
            schedule = [...schedule, { id: startingAddedId, ...added }];
            const start_timestamp = moment(added.startDate).format("HH:mm");
            const end_timestamp = moment(added.endDate).format("HH:mm");
            const title = added.title;
            const date = moment(added.startDate).format("YYYY-MM-DD");

            const isScheduleExists = scheduleData.filter(
                (item) => moment(item.startDate).format("YYYY-MM-DD") === date
            );

            if (isScheduleExists.length) {
                let sessionData = [];

                isScheduleExists.map(async (item) => {
                    if (item.status !== "cancelled") {
                        sessionData.push({
                            session_start_time: moment(item.startDate).format("HH:mm"),
                            session_end_time: moment(item.endDate).format("HH:mm"),
                        });
                    }
                });

                setUpdatingData(true);
                axios
                    .patch(
                        `https://dev.igesia.com/clinic/doctors/schedule`,
                        {
                            clinic_id: clinicId,
                            schedule_id: isScheduleExists[0].schedule_id,
                            date,
                            session_data: [
                                ...sessionData,
                                {
                                    session_start_time: start_timestamp,
                                    session_end_time: end_timestamp,
                                    status: "active",
                                },
                            ],
                        },
                        {
                            headers: await getHeaders(),
                        }
                    )
                    .then(() => {
                        setUpdatingData(false);
                        AllSchedule();
                    });
            } else {
                axios
                    .post(
                        `https://dev.igesia.com/clinic/doctors/schedule`,
                        {
                            clinic_id: clinicId,
                            schedules: [
                                {
                                    date,
                                    sessions: [
                                        {
                                            session_start_time: start_timestamp, // format=HH:mm
                                            session_end_time: end_timestamp,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            headers: await getHeaders(),
                        }
                    )
                    .then(() => AllSchedule());
            }
        }
        if (changed) {
            scheduleData.map(async (appointment) => {
                console.log(appointment);
                if (changed[appointment.id]) {
                    let updatedSchedule = { ...changed[appointment.id] };

                    const start_timestamp = updatedSchedule.startDate
                        ? moment(updatedSchedule.startDate).format("HH:mm")
                        : moment(appointment.startDate).format("HH:mm");
                    const end_timestamp = updatedSchedule.endDate
                        ? moment(updatedSchedule.endDate).format("HH:mm")
                        : moment(appointment.endDate).format("HH:mm");
                    // const title = added.title;
                    const date = updatedSchedule.startDate
                        ? moment(updatedSchedule.startDate).format("YYYY-MM-DD")
                        : moment(appointment.startDate).format("YYYY-MM-DD");


                    const isScheduleExists = scheduleData.filter(
                        (item) => moment(item.startDate).format("YYYY-MM-DD") === date
                    );

                    if (isScheduleExists.length) {
                        let sessionData = [];

                        isScheduleExists.map(async (item) => {
                            if (item.status !== "cancelled" && item.id !== appointment.id) {
                                sessionData.push({
                                    session_start_time: moment(item.startDate).format("HH:mm"),
                                    session_end_time: moment(item.endDate).format("HH:mm"),
                                });
                            }
                        });

                        axios
                            .patch(
                                `https://dev.igesia.com/clinic/doctors/schedule`,
                                {
                                    clinic_id: clinicId,
                                    schedule_id: appointment.schedule_id,
                                    date,
                                    session_data: [...sessionData,
                                    {
                                        session_start_time: start_timestamp,
                                        session_end_time: end_timestamp,
                                    },
                                    ],
                                },
                                {
                                    headers: await getHeaders(),
                                }
                            )
                            .then(() => {
                                setUpdatingData(false);
                                AllSchedule();
                            });


                    } else {
                        axios
                            .patch(
                                `https://dev.igesia.com/clinic/doctors/schedule`,
                                {
                                    clinic_id: clinicId,
                                    schedule_id: appointment.schedule_id,
                                    date,
                                    session_data: [
                                        {
                                            session_start_time: start_timestamp,
                                            session_end_time: end_timestamp,
                                        },
                                    ],
                                },
                                {
                                    headers: await getHeaders(),
                                }
                            )
                        .then(() => {
                            setUpdatingData(false);
                            AllSchedule();
                        });
                    }
                }
            });
        }
        if (deleted !== undefined) {
            scheduleData.map(async (appointment) => {
                console.log(appointment);
                if (appointment.id === deleted) {

                    const isScheduleExists = scheduleData.filter(
                        (item) => moment(item.startDate).format("YYYY-MM-DD") === moment(appointment.startDate).format("YYYY-MM-DD")
                    );

                    if (isScheduleExists.length) {
                        let sessionData = [];

                        isScheduleExists.map(async (item) => {
                            if (item.id !== appointment.id) {
                                sessionData.push({
                                    session_start_time: moment(item.startDate).format("HH:mm"),
                                    session_end_time: moment(item.endDate).format("HH:mm"),
                                    status: item.status
                                });
                            }
                        });

                        axios
                            .patch(
                                `https://dev.igesia.com/clinic/doctors/schedule`,
                                {
                                    clinic_id: clinicId,
                                    schedule_id: appointment.schedule_id,
                                    date: moment(appointment.startDate).format("YYYY-MM-DD"),
                                    session_data: [...sessionData,
                                    {
                                        session_start_time: moment(appointment.startDate).format(
                                            "HH:mm"
                                        ),
                                        session_end_time: moment(appointment.endDate).format(
                                            "HH:mm"
                                        ),
                                        status: "cancelled",
                                    },
                                    ],
                                },
                                {
                                    headers: await getHeaders(),
                                }
                            )
                            .then(() => AllSchedule());

                    } else {
                        axios
                            .patch(
                                `https://dev.igesia.com/clinic/doctors/schedule`,
                                {
                                    clinic_id: clinicId,
                                    schedule_id: appointment.schedule_id,
                                    date: moment(appointment.startDate).format("YYYY-MM-DD"),
                                    session_data: [
                                        {
                                            session_start_time: moment(appointment.startDate).format(
                                                "HH:mm"
                                            ),
                                            session_end_time: moment(appointment.endDate).format(
                                                "HH:mm"
                                            ),
                                            status: "cancelled",
                                        },
                                    ],
                                },
                                {
                                    headers: await getHeaders(),
                                }
                            )
                        .then(() => AllSchedule());
                    }
                }
            });
        }
        setData((prev) => [...schedule]);
        // window.localStorage.setItem("scheduler", JSON.stringify(schedule));
    };

    const testUpdate = async () => {
        axios.patch(
            `https://dev.igesia.com/clinic/doctors/schedule`,
            {
                clinic_id: "EKWsp2FDV2aWp1F6P963",
                schedule_id: 47,
                date: "2021-07-05",
                session_data: [
                    {
                        session_start_time: "09:00",
                        session_end_time: "14:00",
                    },
                ],
            },
            {
                headers: await getHeaders(),
            }
        );
    };

    let AllSchedule = async () => {
        console.log(await getHeaders());
        let response = await axios.post(
            `https://dev.igesia.com/clinic/doctors/fetch-schedule`,
            {
                clinic_id: clinicId,
                default: true,
                start_date: moment(new Date()).format("YYYY-MM-DD"),
                end_date: moment().add(15, "days").format("YYYY-MM-DD"),
            },
            {
                headers: await getHeaders(),
            }
        );

        if (response?.data?.data?.schedules?.schedules) {
            let tempScheduleData = [];
            response.data.data.schedules.schedules.map((dataCalender) => {
                dataCalender?.sessions.map((item) => {
                    // if(item.status !== "cancelled"){
                    tempScheduleData.push({
                        ...item,
                        startDate: moment(`${dataCalender.date} ${item.session_start_time}`)
                            ._d,
                        endDate: moment(`${dataCalender.date} ${item.session_end_time}`)._d,
                        title: `${item.status === "active"
                                ? `${dataCalender.date} 's appointments`
                                : "Cancelled"
                            }`,
                    });
                    // }
                });
            });
            setScheduleData([...tempScheduleData]);

        }
    };

    useEffect(() => {
        AllSchedule()
    }, []);

    const Appointment = ({ children, style, ...restProps }) => {
        return (
            <Appointments.Appointment
                {...restProps}
                style={{
                    ...style,
                    backgroundColor:
                        restProps.data.status === "cancelled" ? "#CD113B" : "#185ADB",
                    fontSize: "15px",
                    borderRadius: "5px",
                }}
            >
                {children}
            </Appointments.Appointment>
        );
    };

    return (
        <div className="h-100">
            <LeftBar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <NavBar />
                <div className="row" style={{ height: "95vh", overflow: "hidden" }}>
                    <LeftBar />
                    <div className="col-md-7 h-100 p-5 bg-graphite" style={{ overflow: "scroll" }}>
                        <Paper className="mt-3" id="calender">
                            <Scheduler data={scheduleData} height={props.height ?? null}>
                                <ViewState />
                                <EditingState onCommitChanges={commitChanges} />
                                <IntegratedEditing />

                                {props.defaultView === "day" ? <DayView /> : null}
                                {props.defaultView === "week" ? <WeekView /> : null}

                                <MonthView />
                                <WeekView />
                                <DayView />
                                {/* <EditRecurrenceMenu /> */}
                                <ConfirmationDialog />

                                <Appointments appointmentComponent={Appointment} />
                                <AppointmentTooltip showCloseButton showOpenButton />
                                <AppointmentForm />
                                <Toolbar />
                                <DateNavigator />
                                <TodayButton />
                                <ViewSwitcher />
                                {/* <DragDropProvider /> */}
                            </Scheduler>
                        </Paper>
                    </div>
                    <div className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
                        style={{ overflow: "scroll" }} >
                        <div className="d-flex flex-column h-100 justify-content-between bg-dark">
                            <p className="my-2 font-weight-bold text-muted ml-3">
                                Chat
                            </p>
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
                                    width="50%"
                                />
                                <p className="text-muted small mt-3">
                                    Chat will be enabled soon
                                </p>
                            </div>
                            <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
                                <div>
                                    Chat is currently disabled
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Calendar;
