import React from "react";
import { Container } from "react-bootstrap";

export default function DigitalDownloadsCreate() {
  return (
    <Container>
      <h3 className="text-white">DigitalDownloadsCreate</h3>
    </Container>
  );
}
