import React from "react";
import { Skeleton } from "@material-ui/lab";

export default function ListLoader() {
  return [1, 2, 3].map((e) => (
    <Skeleton
      key={e}
      animation="wave"
      variant="rect"
      className="rounded w-100 mb-2"
      style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
      height={36}
    />
  ));
}
