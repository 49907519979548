import React, { memo, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { connect } from "react-redux";
import axios from "axios";

import { postData } from "../../utils";
import {
  ADMIN_EVENTS_LIST,
  API_BASE_URL,
  BLOG_GET_BY_USER,
  USER_DETAILS,
} from "../../config";
import ProfileTemplate from "./ProfileTemplate";

const PublicProfile = () => {
  // CONSTANTS
  const { user_id } = useParams();
  const { search } = useLocation();

  // STATES
  const [events, setEvents] = useState([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [courses, setCourses] = useState([]);
  const [fetchingCourses, setFetchingCourses] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [fetchingUserDetails, setFetchingUserDetails] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [fetchingBlogs, setFetchingBlogs] = useState(false);

  // FUNCTIONS
  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const getTemplate = (val = "Template1") => {
    const previewTemplate = new URLSearchParams(search).get("previewTemplate");

    if (previewTemplate) {
      switch (previewTemplate) {
        case "Template1":
          return "Template1";
        case "Template2":
          return "Template2";
        default:
          return "Template1";
      }
    } else {
      switch (val) {
        case "Template1":
          return "Template1";
        case "Template2":
          return "Template2";
        default:
          return "Template1";
      }
    }
  };

  const fetchUserDetails = async (cbSetLoading, cbSetData) => {
    try {
      console.log("[Try] Fetching user details");
      cbSetLoading(true);

      const response = await postData(`${API_BASE_URL}${USER_DETAILS}`, {
        user_id: user_id,
      });

      if (response.status === 200) {
        console.log("[Success] Feteching user details");
        cbSetData({
          ...response.data,
          template: getTemplate(response.data.template ?? ""),
        });
        cbSetLoading(false);
      } else {
        cbSetLoading(false);
      }
    } catch (error) {
      console.log("[Error] Fetching user details: ", error);
    }
  };

  const fetchItems = async (cbSetLoading, cbSetData, type) => {
    const item_name = type ? "courses" : "events";

    try {
      console.log(`[Try] Fetching ${item_name}`);
      cbSetLoading(true);

      const response = await postData(`${API_BASE_URL}${ADMIN_EVENTS_LIST}`, {
        user_id: user_id,
        type: type,
      });

      if (response.status === 200) {
        console.log(`[Success] Fetching ${item_name}`);
        cbSetData(response.data.list?.filter((e) => e.is_published));
        cbSetLoading(false);
      } else {
        cbSetLoading(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching ${item_name}: `, error);
    }
  };

  const fetchBlogs = async (cbSetLoading, cbSetData) => {
    try {
      console.log(`[Try] Fetching Blogs`);
      cbSetLoading(true);

      const response = await axios.get(
        `${API_BASE_URL}${BLOG_GET_BY_USER}?user_id=${user_id}`
      );

      if (response.status === 200) {
        console.log(`[Success] Fetching Blogs`);
        cbSetData(response.data.data.list);
        cbSetLoading(false);
      } else {
        cbSetLoading(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching Blogs: `, error);
    }
  };

  useEffect(() => {
    // Fetch: User details
    fetchUserDetails(setFetchingUserDetails, setUserDetails);

    // Fetch: Blogs data
    fetchBlogs(setFetchingBlogs, setBlogs);

    // Fetch: Events data
    fetchItems(setFetchingEvents, setEvents, 0);

    // Fetch: Courses data
    fetchItems(setFetchingCourses, setCourses, 1);
  }, [user_id]);

  return (
    <ProfileTemplate
      userDetails={userDetails}
      courses={courses}
      events={events}
      blogs={blogs}
      fetchingUserDetails={fetchingUserDetails}
      fetchingCourses={fetchingCourses}
      fetchingEvents={fetchingEvents}
      fetchingBlogs={fetchingBlogs}
    />
  );
};

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
}))(memo(PublicProfile));
