import Avatar from "react-avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

const SpeakerDragList = (props) => {
  const { items, i } = props;
  return (
    <>
      <div className=" h-100 d-flex align-items-center px-1">
        <FontAwesomeIcon
          icon={faGripVertical}
          className="text-secondary mr-1"
        />
        {/* <span className="small font-weight-bold">#{i+1}</span> */}
      </div>
      <div className="p-3">
        <Avatar
          name={items[i].first_name + items[i].last_name}
          src={items[i].img_src}
          round={true}
          size="50"
          textSizeRatio={1}
          className=""
        />
      </div>
      <div className="flex-grow-1 py-3">
        <p className="text-s-10 mb-0 text-capitalize">
          {items[i].first_name + " " + items[i].last_name}
        </p>
        <p className="small mb-1">
          {items[i].designation} ({items[i].organisation})
        </p>
        <p className="small mb-0">{items[i].email_address}</p>
      </div>
    </>
  );
};
export default SpeakerDragList;
