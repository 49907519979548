import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import {
  faBell,
  faComments,
  faHome,
  faList,
  faUserGraduate,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useParams } from "react-router-dom";
import firebase from "../../../firebase";

import { loadState, saveState } from "../../../services/index";

function LeftBar(props) {
  const userDetails = firebase.auth().currentUser;

  const [language, setLanguage] = useState("EN");

  useEffect(() => {
    if (loadState("language")) {
      setLanguage(loadState("language"));
    } else {
      saveState("language", "EN");
    }
  }, [loadState("language")]);

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("eventconsole");
  const { id } = useParams();
  const courseSlug = `/course/${id}/`;

  const locations_data = [
    {
      name: "Dashboard",
      slug: "dashboard",
      icon: faHome,
      disabled: false,
    },
    {
      name: "Course content",
      slug: "module",
      icon: faList,
      disabled: false,
    },
    {
      name: "Rooms",
      slug: "rooms",
      icon: faComments,
      disabled: false,
    },
    {
      name: "Participants",
      slug: "participants",
      icon: faUsers,
      disabled: false,
    },
    {
      name: "Instructors",
      slug: "instructors",
      icon: faUserGraduate,
      disabled: false,
    },
    {
      name: "Notifications",
      slug: "notifications",
      icon: faBell,
      disabled: false,
    },
  ];

  useEffect(() => {
    setCurrentLocation(
      location.pathname.split("/")[3]
        ? location.pathname.split("/")[3]
        : location.pathname.replace(courseSlug, "").replace("/", "")
    );
  }, [location]);

  return (
    <>
      {props.mobile ? (
        <div
          className="container-fluid d-block d-md-none"
          style={{
            backgroundColor: "#17191A",
            position: "fixed",
            zIndex: "10",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div className="d-flex justify-content-around">
            {locations_data.map((item, key) => (
              <Link
                key={key}
                className={
                  item.disabled
                    ? "inline-block text-secondary py-3 px-2"
                    : currentLocation === item.slug
                    ? "inline-block text-primary border-bottom border-primary py-3 px-2"
                    : "inline-block text-light py-3 px-2"
                }
                to={
                  !item.disabled &&
                  `${process.env.PUBLIC_URL}${courseSlug}${item.slug}`
                }
              >
                {item.icon && (
                  <FontAwesomeIcon className="mb-0" icon={item.icon} />
                )}
                <span className="smaller d-none">{item.name}</span>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="col-md-1 h-100 pt-5 text-center d-none d-sm-block"
          style={{ borderRight: "#CCCCCC", backgroundColor: "#17191A" }}
        >
          {locations_data.map((item, key) => (
            <Link
              key={key}
              className={
                item.disabled
                  ? "mb-5 text-secondary text-decoration-none"
                  : currentLocation === item.slug
                  ? "mb-5 text-primary text-decoration-none"
                  : "mb-5 text-light text-decoration-none"
              }
              to={
                !item.disabled &&
                `${process.env.PUBLIC_URL}${courseSlug}${item.slug}`
              }
              style={item.disabled ? { cursor: "no-drop" } : {}}
            >
              {item.logo_image ? (
                <img
                  alt=""
                  className="mb-2 shadow-lg rounded-12"
                  src={item.logo_image}
                  style={{ width: "75%" }}
                />
              ) : (
                item.icon && (
                  <FontAwesomeIcon icon={item.icon} style={{ fontSize: 24 }} />
                )
              )}
              <p className="smaller mb-5 small">{item.name}</p>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(LeftBar);
