import React, { useRef, useState, useEffect } from "react";
import {
    Navbar,
    Tabs,
    Tab,
    Image,
    Card,
    Button,
    Dropdown,
} from "react-bootstrap";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import * as ConsultationStyle from "../consultation.module.css";
import { languageText } from "../../Event/language.json";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import Gallery from "react-grid-gallery";
import { images } from "../images.json";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";

function Consultation({ currentLanguage, changeLanguage }) {
    const { hash, pathname } = useLocation();
    const history = useHistory();

    const [clinicDoc, loadingClinicDoc, errorClinicDoc] = useDocument(
        firebase.firestore().collection("clinics").doc("EKWsp2FDV2aWp1F6P963")
    );

    // Doctors data
    const [doctorsSnap, loadingDoctorsSnap, errorDoctorsSnap] = useCollection(
        firebase
            .firestore()
            .collection("/clinics/EKWsp2FDV2aWp1F6P963/doctors")
            .orderBy("order_index", "asc")
    );

    return (

        <div className="h-100">
            <LeftBar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <NavBar />
                <div className="row" style={{ height: "95vh", overflow: "hidden" }}>
                    <LeftBar />
                    <div className="col-md-7 h-100 p-5 bg-graphite" style={{ overflow: "scroll" }}>
                        <div className="container-fluid px-0 bg-graphite" >
                            {/* Doctors start */}
                            <div className="container mb-5" id="#doctors">
                                <h5 className="text-white mb-3 font-weight-bold">
                                    {/* {languageText[`${currentLanguage}`].speakers} */}
                                    Providers
                                </h5>
                                {!loadingDoctorsSnap &&
                                    doctorsSnap?.docs.map((doctorDoc) => (
                                        <div className="bg-dark p-3 rounded shadow-sm">
                                            {/* <p className="text-white">{JSON.stringify(doctorDoc.data())}</p> */}
                                            <div className="d-flex">
                                                <img src={doctorDoc.data()?.img_src} className="rounded-circle mr-3" height="80" width="80" />
                                                <div>
                                                    <h5 className="text-white mb-0">
                                                        {doctorDoc.data()?.first_name}{" "}
                                                        {doctorDoc.data()?.last_name}
                                                    </h5>
                                                    {doctorDoc.data()?.specialties?.map((item) => (
                                                        <p className="text-white mb-0">{item}</p>
                                                    ))}
                                                    <p className="text-white">
                                                        {HTMLReactParser(doctorDoc.data()?.bio)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {/* Doctors end */}
                        </div>
                    </div>
                    <div className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
                        style={{ overflow: "scroll" }} >
                        <div className="d-flex flex-column h-100 justify-content-between bg-dark">
                            <p className="my-2 font-weight-bold text-muted ml-3">
                                Chat
                            </p>
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
                                    width="50%"
                                />
                                <p className="text-muted small mt-3">
                                    Chat will be enabled soon
                                </p>
                            </div>
                            <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
                                <div>
                                    Chat is currently disabled
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({ currentLanguage: state.currentLanguage }),
    { changeLanguage }
)(Consultation);
