import React from "react";
import { Navbar, Image, Dropdown } from "react-bootstrap";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import * as ProjectStyle from "./project.module.css";
import { useHistory } from "react-router-dom";

function ProjectNavbar({ currentLanguage }) {
  const history = useHistory();
  return (
    <Navbar
      className="container-fluid py-3 py-md-3 bg-graphite shadow-sm"
      variant="dark"
      sticky="top"
    >
      <div className="container d-flex align-items-center justify-content-between px-3">
        <div onClick={() => history.push(`/`)} className="text-white">
          <Image
            className={`rounded ${ProjectStyle.project_cover_logo}`}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
        </div>
        <Dropdown className="mr-3 small text-white">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {/* {languageText[`${currentLanguage}`].english} */}
              English
            </Dropdown.Item>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "FR"}
              onClick={() => changeLanguage("FR")}
            >
              {/* {languageText[`${currentLanguage}`].french} */}
              French
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Navbar>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
  }),
  { changeLanguage }
)(ProjectNavbar);
