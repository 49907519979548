import React, { useState, useEffect } from "react";
import { Card, InputGroup, Form, Button } from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as ProjectStyle from "../../Project/project.module.css";
import firebase from "firebase";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import axios from "axios";
import { useDocument } from "react-firebase-hooks/firestore";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Publish() {
  const history = useHistory();
  const { id } = useParams();
  const userDetails = firebase.auth().currentUser;

  const [post, setPost] = useState({ heading: "", content: "" });
  const [allPost, setAllPost] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [blogSnapshot, loadingBlog, errorBlog] = useDocument(
    firebase
      .firestore()
      .collection("blogs")
      .doc(`${id}`)
  );

  useEffect(() => {
    if (blogSnapshot?.data()) {
      setPost({ ...blogSnapshot?.data(), id: blogSnapshot?.id })
      setIsUpdating(true);
    }
  }, [blogSnapshot])

  useEffect(() => {
    handleDisabled();
  }, [post]);


  const handleDisabled = () => {
    if (post?.content === "") {
      setIsDisabled(true);
    } else setIsDisabled(false);
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();

      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        userDetails?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imgSrcURL = post?.img_src ? await uploadFile(post.img_src) : null;
    try {
      await firebase
        .firestore()
        .collection("/blogs/")
        .doc()
        .set({
          heading: post?.heading ?? "",
          content: post?.content ?? "",
          img_src: imgSrcURL ?? "",
          timestamp: new Date().valueOf(),
          isBlog: true,
          created_by: userDetails?.uid ?? "",
          author: {
            name: userDetails?.displayName ?? "",
            picture: userDetails?.photoURL ?? "",
            designation: userDetails?.designation ?? "",
          },
        });
      toast.success("Blog added", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "unable to write to db");
    } finally {
      setPost({ heading: "", content: "" });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const imgSrcURL = post?.img_src ? await uploadFile(post.img_src) : null;
    try {
      await firebase
        .firestore()
        .collection("/blogs/")
        .doc(`${post.id}`)
        .set({
          heading: post?.heading ?? "",
          content: post?.content ?? "",
          img_src: imgSrcURL ?? "",
          timestamp: new Date().valueOf(),
          isBlog: true,
          created_by: userDetails?.uid ?? "",
          author: {
            name: userDetails?.displayName ?? "",
            picture: userDetails?.photoURL ?? "",
            designation: userDetails?.designation ?? "",
          },
        });
      toast.success("Blog Updated", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "unable to write to db");
    }
  };

  const saveFileToStateHandler = (e) => {
    setPost((prevSpeakersdata) => {
      return { ...prevSpeakersdata, [e.target.name]: e.target.files[0] };
    });
  };

  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const blog_image_url = post?.img_src
    ? getWhetherURLOrLocalFile(post?.img_src)
    : null;

  return (
    <div className="container-fluid">
      <div className="row text-white justify-content-md-center">
        <div className="col-12 d-flex justify-content-between border-bottom border-dark px-4 px-md-5 py-3 shadow-sm">
          <h5 className="my-0">{isUpdating ? "Update Blog" : "Add new blog"}</h5>
          <Link to="/admin" className="text-decoration-none text-white my-0">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </Link>
        </div>

        <div
          className="col-md-6 px-4 px-md-5"
        // style={{ maxHeight: "85vh", overflow: "auto" }}
        >
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group>
              {/* <Form.Label className="mt-2">Heading</Form.Label> */}
              <Form.Control
                className="bg-dark text-white mt-3 h3"
                type="text"
                placeholder="Heading"
                value={post?.heading}
                onChange={(e) => {
                  setPost((prev) => {
                    const newState = { ...prev, heading: e.target.value };
                    return newState;
                  });
                }}
              />
            </Form.Group>
            {blog_image_url && (
              <img
                alt="event-thumbnail"
                width="100%"
                src={blog_image_url}
                className="img-fluid rounded d-block d-md-inline mr-3 mb-2"
              ></img>
            )}
            <Form.Control
              type="file"
              accept="image/*"
              name="img_src"
              onChange={(e) => saveFileToStateHandler(e)}
              className="border p-1 rounded"
              style={{ cursor: "pointer" }}
            />
            <Form.Group>
              <Form.Label>
                <p className="small text-secondary mb-0">
                  Recommdended: Min. 50 words (Max. 400 characters)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3 text-dark"
                data={post?.content}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPost((prev) => {
                    const newState = { ...prev, content: data };
                    return newState;
                  });
                }}
                style={{ height: 400 }}
              />
            </Form.Group>

            <div className="d-flex align-items-center justify-content-between mb-4">
              {isUpdating ?
                <Button
                  className="my-2 px-3 px-md-4 col-12"
                  variant="light"
                  size="sm"
                  onClick={(e) => handleUpdate(e)}
                  disabled={isDisabled}
                >
                  <span className="text-uppercase small font-weight-bold">
                    Update
                  </span>
                </Button> :
                <Button
                  className="my-2 px-3 px-md-4 col-12"
                  variant="light"
                  size="sm"
                  onClick={(e) => handleSubmit(e)}
                  disabled={isDisabled}
                >
                  <span className="text-uppercase small font-weight-bold">
                    Submit
                  </span>
                </Button>}
              {isSubmitted && (
                <p className="text-success text-uppercase mb-0">
                  <FontAwesomeIcon icon={faCheck} className="mr-2" /> Posted
                </p>
              )}
            </div>
          </Form>
        </div>
        {/* <div
          className="col-md-6 px-4 px-md-5 py-3 bg-dark h-100"
        >
          <h5 className="small">PREVIEW</h5>
          <div className="text-dark">


            {post && blog_image_url && (
              <img
                src={blog_image_url}
                className="w-100 mb-3 rounded-12"
                alt="..."
              />
            )}

            {post && post?.heading && (
              <h4 className="text-s-2-bold text-white">
                {post?.heading
                  ? post?.heading
                  : "Blog Heading"}
              </h4>
            )}

            {post && post?.content && (
              <p className="text-white">
                {parse(post?.content)}
              </p>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
  }),
  { changeLanguage }
)(Publish);
