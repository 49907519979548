import React, { useRef, useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";
import { useHistory, useLocation } from "react-router";
import { Accordion, Button, Card, Tab, Tabs } from "react-bootstrap";
import axios from "axios";

import { postData } from "../../../../utils";
import { auth, firestore } from "../../../../firebase";

import LeftBar from "../LeftBar";
import NavBar from "../NavBar";
import "../Styles.css";
import Module from "./Module";
import {
  API_BASE_URL,
  EVENT_GROUPS_LIST,
  USER_COURSE_PROGRESS,
  USER_GET_MODULES,
} from "../../../../config";

toast.configure();

function Modules(props) {
  // CONSTANTS
  const { pathname } = useLocation();
  const history = useHistory();
  const { darkTheme, currentEvent } = props;
  const currentUser = auth.currentUser;

  // STATES
  const [modules, setModules] = useState([]);
  const [fetchingModules, setFetchingModules] = useState(true);
  const [modulesProgress, setModulesProgress] = useState([]);
  const [groups, setGroups] = useState([]);
  const [fetchingGroups, setFetchingGroups] = useState(false);
  const [key, setKey] = useState("description");
  const [accordionKey, setAccordionKey] = useState("0");

  // REFS
  const pageRef = useRef(null);

  // FUNCTIONS
  const handleFetchGroups = async () => {
    try {
      setFetchingGroups(true);

      const response = await postData(`${API_BASE_URL}${EVENT_GROUPS_LIST}`, {
        event_id: currentEvent?.id,
        user_id: currentUser?.uid,
      });

      if (response.status === 200) {
        const { data } = response;

        setGroups(data?.groupList);
        setFetchingGroups(false);
      } else {
        setFetchingGroups(false);
      }
    } catch (error) {
      console.log("[Error] Fetching groups: ", error);
      setFetchingGroups(false);
    }
  };

  const handleFetchModules = async () => {
    try {
      setFetchingModules(true);

      const response = await postData(`${API_BASE_URL}${USER_GET_MODULES}`, {
        event_id: currentEvent?.id,
      });

      if (response.status === 200) {
        const { data } = response;

        setModules(data);
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  const handleFetchProgress = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${USER_COURSE_PROGRESS}?ancestor_id=${currentEvent?.id}&user_id=${auth?.currentUser?.uid}`
      );

      if (response.status === 200) {
        const { data } = response.data;
        setModulesProgress(data);
      }
    } catch (error) {
      console.log(`[Error] Fetching progress: ${error.message}`);
    }
  };

  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();

    let unsubscribe_modules = firestore
      .collection("modules")
      .where("parent_id", "==", currentEvent?.id)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    let unsubscribe_submodules = firestore
      .collection("subModules")
      .where("ancestor_id", "==", currentEvent?.id)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Modules
          handleFetchModules();
        });
      });

    let unsubscribe_progress = firestore
      .collection("subModulesProgress")
      .where("ancestor_id", "==", currentEvent?.id)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Progress
          handleFetchProgress();
        });
      });

    return () => {
      unsubscribe_modules();
      unsubscribe_submodules();
      unsubscribe_progress();
    };
  }, []);

  useEffect(() => {
    if (currentUser?.uid) {
      // Fetch: Groups
      handleFetchGroups();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      // Publish: Email to igesians email list
      var myHeaders = new Headers();
      myHeaders.append("Accept-Charset", "utf-8");

      var formdata = new FormData();
      formdata.append("name", currentUser.displayName);
      formdata.append("email", currentUser.email);
      formdata.append("list", "892ARa6Qrr5Ba81PPYfP65FQ");
      formdata.append("api_key", "nKAxSMspzFluFgktWFP5");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://sendy.iarani.com/subscribe", requestOptions)
        .then((response) => {
          // response.text()
        })
        .then((result) => {
          // console.log(result)
        })
        .catch((error) => console.log("error", error));
    }
  }, [currentUser]);

  return (
    <div className="h-100" ref={pageRef}>
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar progress={modulesProgress} />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 d-none d-md-block"
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            <div className="px-0 pb-md-3 pb-5">
              <h3 className="mb-3 mb-md-4">
                {currentEvent?.event_details?.EN?.event_name}
              </h3>
              <div>
                <p className="mb-1">
                  {currentEvent?.event_details?.EN?.small_description &&
                    parse(currentEvent?.event_details?.EN?.small_description)}
                </p>

                <img
                  src={currentEvent?.event_details?.featured_image}
                  className="img-fluid rounded-lg border border-secondary mb-3 w-100"
                />

                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  // style={{ borderBottomWidth: "2px" }}
                >
                  <Tab
                    eventKey="description"
                    title="Description"
                    tabClassName={
                      key === "description"
                        ? "border-bottom border-top-0 border-left-0 border-right-0 border-dark p-0 pb-2 mr-3 font-weight-bold"
                        : "border-bottom border-top-0 border-left-0 border-right-0 text-muted p-0 pb-2 mr-3 font-weight-bold"
                    }
                  >
                    <div className="my-3">
                      {currentEvent?.event_details?.EN?.description &&
                        parse(currentEvent?.event_details?.EN?.description)}
                    </div>
                  </Tab>
                  {groups?.length > 0 && (
                    <Tab
                      eventKey="groups"
                      title="Groups"
                      tabClassName={
                        key === "groups"
                          ? "border-bottom border-top-0 border-left-0 border-right-0 border-dark p-0 pb-2 mr-3 font-weight-bold"
                          : "border-bottom border-top-0 border-left-0 border-right-0 text-muted p-0 pb-2 mr-3 font-weight-bold"
                      }
                    >
                      <div className="my-3">
                        {groups?.map((item) => (
                          <div className="border rounded shadow-sm p-2 mb-2">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h5 className="m-0 font-weight-bold">
                                  {item.name}
                                </h5>
                                <p className="small mb-0">
                                  Chat with your team members here.
                                </p>
                              </div>
                              <Button
                                size="sm"
                                className="text-uppercase"
                                onClick={() => {
                                  if (pathname.split("/").length === 4)
                                    history.push(`group/${item.groupId}`);

                                  if (pathname.split("/").length === 5)
                                    history.push(`../group/${item.groupId}`);
                                }}
                              >
                                Open
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
          <div
            data-theme={darkTheme ? "dark" : "light"}
            className="col-md-4 h-100 px-0 border-left"
            style={{ overflow: "scroll" }}
          >
            <Accordion
              onSelect={(e) => setAccordionKey(e)}
              defaultActiveKey="0"
            >
              <Card className="border-top-0 border-left-0 border-right-0 rounded-0">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  className="border-bottom p-0 m-0 bg-white"
                >
                  <div className="p-3 d-flex align-items-center justify-content-between">
                    <p className="mb-0">
                      <b>About the Course</b>
                    </p>
                    <FontAwesomeIcon
                      icon={accordionKey === "0" ? faAngleUp : faAngleDown}
                    />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p-0 m-0">
                    <div className="p-3">
                      <p className="mb-1">
                        {currentEvent?.event_details?.EN?.small_description &&
                          parse(
                            currentEvent?.event_details?.EN?.small_description
                          )}
                      </p>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            {/* Desktop View: Course module list */}
            <div className="d-none d-md-block">
              <div className="border-bottom">
                <p className="mb-0 p-3">
                  <b>Course content</b>
                </p>
              </div>
              {fetchingModules ? (
                <p className="text-center">Loading...</p>
              ) : modules?.length > 0 ? (
                modules?.map((item, key) => (
                  <Module
                    progress={modulesProgress}
                    accordion={true}
                    module_data={item}
                    key={key}
                    idx={key}
                  />
                ))
              ) : (
                <p className="text-center">
                  <i>No modules added.</i>
                </p>
              )}
            </div>

            {/* Mobile View: Course module list */}
            <div className="d-block d-md-none pb-5">
              <div className="mb-3">
                <div className="border-bottom">
                  <p className="mb-0 p-3">
                    <b>Course content</b>
                  </p>
                </div>
                {fetchingModules ? (
                  <p className="text-center">Loading...</p>
                ) : modules?.length > 0 ? (
                  modules?.map((item, key) => (
                    <Module
                      progress={modulesProgress}
                      accordion={true}
                      module_data={item}
                      key={key}
                      idx={key}
                    />
                  ))
                ) : (
                  <p className="text-center">
                    <i>No modules added.</i>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentLanguage: state.currentLanguage,
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(Modules);
