import React from "react";
import { Skeleton } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

export default function AssignmentLoader(props) {
  // CONSTANTS
  const { handleCloseItem } = props;

  return (
    <React.Fragment>
      <div className="border border-secondary text-white mr-3">
        <div className="p-3 d-flex align-items-center border-bottom border-secondary">
          <div className="flex-grow-1 mr-2">
            <Skeleton
              animation="wave"
              variant="rect"
              className="rounded w-100"
              style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
              height={20}
            />
          </div>
          <div>
            <a className="text-white pointer mr-1 p-2" disabled={true}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
            <a className="text-white pointer mr-1 p-2" disabled={true}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
            <a className="text-white pointer p-2" onClick={handleCloseItem}>
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </div>
        </div>
        <div className="p-3">
          <div className="mb-3">
            <p className="text-muted small mb-0">Questions</p>
            {[1, 2, 3]?.map((e) => (
              <Skeleton
                key={e}
                animation="wave"
                variant="rect"
                className="rounded w-100 mb-2"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={20}
              />
            ))}
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Created at</p>
            <p className="small mb-0">
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded w-50"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={20}
              />
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Deadline by</p>
            <p className="small mb-0">
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded w-50"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={20}
              />
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Total number of responses</p>
            <p className="small mb-0">
              <Skeleton
                animation="wave"
                variant="rect"
                className="rounded w-25"
                style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
                height={20}
              />
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Responses</p>
            <Skeleton
              animation="wave"
              variant="rect"
              className="rounded w-100 mb-2"
              style={{ backgroundColor: "rgba(51,51,51,0.75)" }}
              height={100}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
