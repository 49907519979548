import React, { lazy, Suspense, useEffect, useState } from "react";
import Sidebar from "./Sidebar/Index";
import "react-datepicker/dist/react-datepicker.css";
import "./style/Calendar.css";
import { postData } from "../../utils/index";
import { useParams } from "react-router";
import Home from "./Home_old";
import NavBar from "./Navbar";
import { Spinner } from "react-bootstrap";
import SelectedDashboard from "./SelectedDashboard";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
const ViewAll = lazy(() => import("./ViewAll"));

const AdminNewAnalytics = (props) => {
  const [dataPoints, setDataPoints] = useState([]);

  useEffect(async () => {
    const response = await postData('https://dev.igesia.com/data/networking-requests', {});

    setDataPoints(response.data);
  }, []);

  const params = useParams();
  const user = firebase.auth().currentUser;
  if (!user) {
    return <Redirect to="/admin/login" />;
  }
  return (
    <>
      <NavBar />

      <table style={{ border: "solid 1px purple" }}>
            <tr style={{ border: "solid 1px purple" }}>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>Event Id</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>From Email</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>To Email</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>From User Id</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>To User Id</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>Message</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>Created At</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
            </tr>
        {dataPoints.map(( listValue, index ) => {
          return (
            <tr key={index}>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.event_id}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.from_email}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.to_email}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.from_user_id}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.to_user_id}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.message}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
              <td className="mb-0" style={{ border: "solid 1px purple" }}><span style={{ color: "white" }}>{listValue.createdAt}</span></td>
              <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
            </tr>
          );
        })}
      </table>
    </>
  );
};
export default AdminNewAnalytics;
