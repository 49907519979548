import React, { useMemo } from "react";
import { Card, Image, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import firebase from "firebase";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCollection } from "react-firebase-hooks/firestore";
import * as TimelineStyle from "../Timeline/timeline.module.css";
import moment from "moment";
import { blogList } from "../data";
import TimelineCover from "../Timeline/TimelineCover";

export default function Blogs() {
  const history = useHistory();

  const [blogsSnapshot, loadingBlogs, errorBlogs] = useCollection(
    firebase.firestore().collection("blogs")
  );

  const blogs = useMemo(() => {
    return blogsSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [blogsSnapshot]);

  return (
    <div className="container-fluid px-0 bg-graphite full-screen">
      {/* Timeline cover starts */}
      <TimelineCover />
      {/* Timeline cover ends */}

      <div className="container py-3 py-md-4">
        <h2 className="text-white font-weight-bold">Blogs</h2>

        <div className="row mt-3">

          {/* Course card */}
          {blogs && blogs.map((blog, key) => (
            <>
              {blog?.is_published ?
                <div className="col-md-4 mb-2" key={key}>
                  <Card
                    className={`h-100 ${TimelineStyle.services_cards}`}
                    onClick={() => history.push(`blog/${blog.id}`)}
                    style={{ cursor: "pointer" }}
                    title={`Course: ${blog.heading}`}
                  >
                    <Card.Img variant="top" src={blog.img_src} />
                    <Card.Body className="py-2 px-3">
                      <p className="small text-uppercase text-success mb-1">
                        Blog
                      </p>
                      <p className="font-weight-bold text-white mb-1">
                        {blog.heading}
                      </p>
                      <p className="font-weight-bold small mb-0 text-warning">
                        {moment(blog.timestamp).format("Do MMM YY")}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
                : <></>
              }
            </>
          ))}

        </div>
      </div>
    </div>
  );
}
