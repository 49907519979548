import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

const BoothsDragList = (props) => {
  const { items, i } = props;
  return (
    <>
      <div className="bg-light h-100 d-flex align-items-center px-1">
        <FontAwesomeIcon
          icon={faGripVertical}
          className="text-secondary mr-1"
        />
      </div>
      <div className="flex-grow-1 py-3">
        <p className="text-s-10 mb-0">
          {items[i].email_address_of_owner &&
            items[i].email_address_of_owner.map((email, key) => (
              <p className="mb-0" key={key}>
                {email}
              </p>
            ))}
        </p>
      </div>
    </>
  );
};
export default BoothsDragList;
