import { useMemo, memo } from "react";
import { Form, InputGroup } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";

const AssignmentView = (props) => {
  const { currAssignment, assignmentId } = props;
  const [assignmentSnapshot, loadingAssignment, errorAssignment] = useDocument(
    firebase.firestore().collection("assignments").doc(assignmentId)
  );

  const assignmentData = useMemo(() => {
    return {
      ...assignmentSnapshot?.data(),
    };
  }, [assignmentSnapshot]);
  return (
    <div>
      <Form style={{ minWidth: "300px" }}>
        <div className="d-flex">
          <h5 className="mt-0 flex-grow-1">
            {assignmentData.assignmentSubject}
          </h5>
          <div>
            <span className="text-success small">Active</span>
          </div>
        </div>
        <div className="m-0">
          <p>{assignmentData.assignmentDescription}</p>
        </div>
        <>
          {assignmentData &&
            assignmentData?.questions?.map((que, key) => (
              <div className="mb-3">
                <p className="font-weight-bold mb-0">Que: {que.question}</p>
                <p className="mt-0 mb-1">Desc: {que.description}</p>
                {assignmentData.deadline < Date.now() ? null : (
                  <InputGroup className="mb-1">
                    <TextareaAutosize
                      className="w-100 p-2 rounded"
                      style={{
                        borderColor: "#CDCDCD",
                        minWidth: "300px",
                      }}
                      name="answers[]"
                      id={key}
                      minRows={3}
                      maxRows={20}
                      placeholder="Your response"
                    />
                  </InputGroup>
                )}
              </div>
            ))}
        </>
      </Form>
    </div>
  );
};
export default memo(AssignmentView);
