import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { lineChart1, lineChart2, lineChart3 } from "../Data/charts-data";
import Chart from "react-apexcharts";
import { memo } from "react";
import { connect } from "react-redux";

const BriefStats = (props) => {
  return (
    <div className="my-3">
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5 className="text-white">Stats</h5>
        <Button
          href={`/admin/courses/${props.eventId}`}
          variant="link"
          size="sm"
          className="text-uppercase text-decoration-none text-light"
        >
          View All
        </Button>
      </div>
      <Card className="bg-dark rounded shadow-sm">
        <Row className="dark-only container-fluid my-3">
          <Col xl="4" md="12" className="box-col-12">
            <Card className="o-hidden">
              <div className="chart-widget-top">
                <Row className="card-body">
                  <Col xs="8">
                    <h6 className="f-w-600 font-primary">Tickets sold</h6>
                    {/* <span className="num">
                  <span className="counter">{"90"}</span>
                  {"%"}
                  <i className="icon-angle-up f-12 ml-1"></i>
                </span> */}
                  </Col>
                  <Col xs="4" className="text-right">
                    <h4 className="num total-value">
                      <span className="counter">{"364"}</span>
                    </h4>
                  </Col>
                </Row>
                <div>
                  <div id="chart-widget1">
                    <Chart
                      options={lineChart1.options}
                      series={lineChart1.series}
                      height="170"
                      type="area"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xl="4" md="12" className="box-col-12">
            <Card className="o-hidden">
              <div className="chart-widget-top">
                <Row className="card-body">
                  <Col xs="8">
                    <h6 className="f-w-600 font-success">Attendees</h6>
                    {/* <span className="num">
                  <span className="counter">{"30"}</span>
                  {"%"}
                  <i className="icon-angle-up f-12 ml-1"></i>
                </span> */}
                  </Col>
                  <Col xs="4" className="text-right">
                    <h4 className="num total-value">
                      <span className="counter">{"169"}</span>
                    </h4>
                  </Col>
                </Row>
                <div>
                  <div id="chart-widget2">
                    <Chart
                      className="flot-chart-placeholder"
                      options={lineChart2.options}
                      series={lineChart2.series}
                      height="170"
                      type="area"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col xl="4" md="12" className="box-col-12">
            <Card className="o-hidden">
              <div className="chart-widget-top">
                <Row className="card-body">
                  <Col xs="8">
                    <h6 className="f-w-600 font-success">
                      Networking requests
                    </h6>
                    {/* <span className="num">
                  <span className="counter">{"68"}</span>
                  {"%"}
                  <i className="icon-angle-up f-12 ml-1"></i>
                </span> */}
                  </Col>
                  <Col xs="4" className="text-right">
                    <h4 className="num total-value">
                      <span className="counter">{"93"}</span>
                    </h4>
                  </Col>
                </Row>
                <div>
                  <div id="chart-widget3">
                    <Chart
                      className="flot-chart-placeholder"
                      options={lineChart3.options}
                      series={lineChart3.series}
                      height="170"
                      type="area"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    eventId: state.eventId,
  };
};
export default memo(connect(mapStateToProps)(BriefStats));
