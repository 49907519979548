import React, { useEffect, useState, useMemo } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import EventNavbar from "../NavBar";
import EventLeftbar from "../LeftBar";
import { setGraphId, setProducts } from "../../../../store/actions/actions";
import "@culturehq/add-to-calendar/dist/styles.css";
import firebase from "firebase";
import EventChat from "../EventChat";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { Button, ButtonGroup, Modal, Form } from "react-bootstrap";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./apps.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faShare,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function ProjectList(props) {
  const user = firebase.auth().currentUser;
  const { id } = useParams();

  const [allGraphs, setAllGraphs] = useState(null);
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAttendeeCategory, setSelectedAttendeeCategory] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [editProjectName, setEditProjectName] = useState(false);
  const [sorting, setSorting] = useState(0);
  const { darkTheme } = props;

  useEffect(() => {
    props.setProducts([]);
  }, []);

  const [usersSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${props?.currentEvent?.id}/users`)
      .where("uid", "!=", user.uid)
  );

  const allUsers = useMemo(() => {
    return usersSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        label: fac?.data()?.firstName,
        value: fac?.data()?.firstName,
        ...fac.data(),
      };
    });
  }, [usersSnapshot]);

  const [currentUserDoc, currentUserLoading, currentUserError] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${props?.currentEvent?.id}/users`)
      .doc(`${user.uid}`)
  );
  const currentUserCategory = currentUserDoc?.data()?.category ?? "";
  const currentUserRole = currentUserDoc?.data()?.user_role ?? "";

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos`)
      .orderBy("title", "asc")
      .onSnapshot((snapshot) => {
        const tempGraphs = [];
        snapshot.forEach((doc) => {
          //if (!doc.data().reset && (doc.data().datasets && doc.data().datasets.length)) {
          tempGraphs.push({ ...doc.data(), id: doc.id });
          //}
        });
        setAllGraphs(tempGraphs);
      });
  }, []);

  const addProjectHandler = () => {
    if (projectName) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .add({
          title: projectName,
          createdAt: Date.now(),
          createdBy: user.uid,
          assignedToCategory: "",
          errc_created: false,
          to_be_created: false,
          buyerExpAudit_created: false,
          six_path: false,
          three_tiers: false,
        })
        .then((doc) => {
          props.setGraphId(doc.id);
          firebase
            .firestore()
            .collection(
              `events/${props?.currentEvent?.id}/bbos/${doc.id}/factors`
            )
            .add({
              defaultValue: 1,
              name: "Price",
              position: 0,
            });
          history.push(
            `/event/${props?.currentEvent?.slug}/as_is/view/${doc.id}`
          );
          props.setProducts([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteProjectHandler = () => {
    if (selectedProject) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${selectedProject.id}`)
        .delete()
        .then(() => {
          setShowDeleteModal(false);
          toast.success("Project deleted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const calculatePercent = (item) => {
    let count = 0;
    for (let key in item) {
      if (typeof item[key] === "boolean" && item[key] === true) {
        count++;
      }
    }
    return Math.floor((count / 5) * 100);
  };

  const handleSelectedShare = (graphId) => {
    if (selectedUsers.length > 0) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${graphId}`)
        .update(
          {
            assignedTo: firebase.firestore.FieldValue.arrayUnion(
              ...selectedUsers
            ),
            assignedToAll: false,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }

    if (selectedAttendeeCategory != "") {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${graphId}`)
        .update({
          assignedToCategory: selectedAttendeeCategory,
          assignedToAll: false,
        })
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedAttendeeCategory("");
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }
  };

  let history = useHistory();

  useEffect(() => {
    if (!!selectedProject) {
      const thisProject = allGraphs?.filter(
        (item) => item.id === selectedProject.id
      );

      if (thisProject[0]) {
        setSelectedProject(thisProject[0]);
      }
    }
  }, [allGraphs]);

  const revokeSharingAccess = (project_id) => {
    const projectRef = firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos/`)
      .doc(`${project_id}`);

    projectRef
      .update({
        assignedToCategory: "",
      })
      .then(() => {
        setShowRevokeModal(false);
        toast.dark("Access revoked", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((error) => console.error("Revoke sharing access: ", error));
  };

  const shareToAllHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .set(
          {
            assignedToAll: true,
            assignedToCategory: "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared to all successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const editProjectNameHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          title: projectName,
        })
        .then(() => {
          setEditProjectName(false);
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const revokeAccessFromAll = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          assignedToAll: false,
          assignedToCategory: "",
        })
        .then(() => {
          toast.success("Revoked Access successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const UserDetails = ({ uid, idx, project_id }) => {
    const userDetails = allUsers?.filter((item) => item.uid === uid);
    return (
      <div className="d-flex align-items-center mb-1" key={idx}>
        <div className="mr-2">
          <Button
            size="sm"
            variant="danger"
            onClick={() => revokeSharingAccess(uid, project_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="flex-grow-1">
          <p className="mb-0">
            {idx + 1}. {userDetails[0]?.label} ({userDetails[0]?.email})
          </p>
        </div>
      </div>
    );
  };

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  const compare = (a, b) => {
    let title_a = a.title,
      title_b = b.title;

    if (sorting === 1) {
      return title_b.localeCompare(title_a);
    } else {
      return title_a.localeCompare(title_b);
    }
  };

  return (
    <div className="h-100 montserrat-font">
      <EventLeftbar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <EventNavbar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <EventLeftbar />
          <div
            className="col-md-7 h-100 p-md-5 p-3"
            data-theme={darkTheme ? "dark" : "light"}
            style={{
              overflow: "scroll",
            }}
          >
            {/* Networking */}

            <React.Fragment>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div
                    className={
                      darkTheme
                        ? "shadow-sm p-3 rounded bg-dark"
                        : "shadow-sm p-3 rounded bg-white"
                    }
                  >
                    <h3>
                      {props.currentEvent?.bbos_names?.title ?? "BBOS"} Projects
                    </h3>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-end mb-2">
                  <Button size="sm" onClick={() => setShow(true)}>
                    <p className="mb-0">Add Project</p>
                  </Button>

                  <div>
                    <p className="small mb-0 font-weight-bold">Sort by name</p>
                    <ButtonGroup size="sm">
                      {["A - Z", "Z - A"]?.map((element, index) => (
                        <Button
                          variant={
                            sorting === index
                              ? "secondary"
                              : "outline-secondary"
                          }
                          onClick={() => setSorting(index)}
                        >
                          {element}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </div>

                {allGraphs &&
                  allGraphs?.sort(compare)?.map((graph, key) => (
                    <>
                      {(graph.createdBy === user.uid ||
                        currentUserRole === 1 ||
                        graph.assignedToAll ||
                        graph.assignedToCategory === currentUserCategory) && (
                        <div
                          className={
                            darkTheme
                              ? "shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 bg-dark"
                              : "shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 bg-white"
                          }
                          // className="shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 "
                          key={key}
                        >
                          <div className="d-flex">
                            <div
                              className="pointer flex-grow-1"
                              onClick={() => {
                                props.setGraphId(graph.id);
                                history.push(
                                  `/event/${id}/as_is/view/${graph.id}`
                                );
                              }}
                            >
                              <small className="text-theme-lighter smaller">
                                {graph?.createdAt &&
                                  moment(graph.createdAt).format("LLL")}
                              </small>
                              <p className="m-0 font-weight-bold text-theme small">
                                {graph.title && <div>{graph.title}</div>}
                              </p>
                            </div>

                            {(graph.createdBy != user.uid ||
                              // graph.assignedTo.includes(user.uid) ||
                              graph.assignedToCategory ===
                                currentUserCategory) && (
                              <div className="p-2">
                                <>
                                  {allUsers?.map((item) => (
                                    <>
                                      {item.uid === graph.createdBy && (
                                        <p className=" small">{`Created By ${item.firstName}`}</p>
                                      )}
                                    </>
                                  ))}
                                </>
                              </div>
                            )}

                            {graph.createdBy === user.uid && (
                              <>
                                <div className="pt-2 mr-2">
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setEditProjectName(true);
                                      setProjectName(graph.title);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                                    Project Name
                                  </Button>
                                </div>
                                <div className="pt-2 mr-2">
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setShowShare(true);
                                      setSelectedProject(graph);
                                    }}
                                    className="text-uppercase font-weight-bold"
                                  >
                                    <FontAwesomeIcon icon={faShare} size="sm" />{" "}
                                    <small>Share</small>
                                  </Button>
                                  <Modal
                                    show={showShare}
                                    onHide={() => {
                                      setShowShare(false);
                                      setSelectedUsers([]);
                                    }}
                                  >
                                    <Modal.Header
                                      closeButton
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <div className="d-flex flex-column">
                                        <p className="h4 mb-0">
                                          Share your project
                                        </p>
                                        {/* {selectedProject?.assignedTo?.length >
                                      0 ? (
                                        <p className="mb-0">
                                          Shared with{" "}
                                          {selectedProject?.assignedTo?.length}{" "}
                                          member
                                          {selectedProject?.assignedTo?.length >
                                          1
                                            ? "s"
                                            : ""}
                                        </p>
                                      ) : (
                                        <p className="mb-0">Not yet shared</p>
                                      )} */}
                                        {selectedProject?.assignedToCategory ||
                                        selectedProject?.assignedToAll ? (
                                          <p className="mb-0">
                                            Shared with{" "}
                                            {selectedProject?.assignedToAll ? (
                                              <b>All</b>
                                            ) : (
                                              <b>
                                                {getAttendeeCategoryName(
                                                  selectedProject?.assignedToCategory
                                                )}
                                              </b>
                                            )}{" "}
                                            attendee(s)
                                          </p>
                                        ) : (
                                          <p className="mb-0">Not yet shared</p>
                                        )}
                                      </div>
                                    </Modal.Header>
                                    <Modal.Body className="text-dark">
                                      {/* {selectedProject?.assignedTo?.length >
                                      0 && (
                                      <div className="mb-3">
                                        <p className="mb-2 text-uppercase small">
                                          Already shared with:
                                        </p>
                                        {selectedProject?.assignedTo?.map(
                                          (item, idx) => (
                                            <UserDetails
                                              uid={item}
                                              idx={idx}
                                              project_id={graph.id}
                                            />
                                          )
                                        )}
                                      </div>
                                    )} */}

                                      <p className="mb-1">
                                        Select category to share your project
                                      </p>
                                      <Select
                                        placeholder="Attendee Category"
                                        options={attendeeCategoryList?.map(
                                          (item) => {
                                            return {
                                              label: `${item.name}`,
                                              value: `${item.id}`,
                                            };
                                          }
                                        )}
                                        onChange={(e) =>
                                          setSelectedAttendeeCategory(e.value)
                                        }
                                        className="w-100 mb-2 text-dark"
                                      ></Select>

                                      {/* <Select
                                      isMulti
                                      placeholder="Select people"
                                      className="w-100 mb-2"
                                      options={allUsers?.filter(
                                        (item) =>
                                          !selectedProject?.assignedTo?.includes(
                                            item.uid
                                          )
                                      )}
                                      onChange={(e) =>
                                        setSelectedUsers(
                                          e?.map((item) => item.uid)
                                        )
                                      }
                                    /> */}

                                      <Button
                                        className="w-100 mb-2"
                                        onClick={() =>
                                          handleSelectedShare(graph.id)
                                        }
                                        disabled={
                                          selectedProject?.assignedToCategory ===
                                          selectedAttendeeCategory
                                        }
                                      >
                                        Share
                                      </Button>
                                      {selectedProject?.assignedToCategory && (
                                        <Button
                                          variant="none"
                                          className="w-100 text-danger shadow-sm border-danger"
                                          onClick={(e) => {
                                            setShowRevokeModal(true);
                                          }}
                                        >
                                          Revoke Access from{" "}
                                          <b>
                                            {" "}
                                            {getAttendeeCategoryName(
                                              selectedProject?.assignedToCategory
                                            )}
                                          </b>
                                        </Button>
                                      )}
                                      {selectedProject?.assignedToCategory && (
                                        <p className="small mb-0 text-muted">
                                          "{selectedProject?.title}" is already
                                          being shared with{" "}
                                          <b>
                                            {getAttendeeCategoryName(
                                              selectedProject?.assignedToCategory
                                            )}
                                          </b>
                                        </p>
                                      )}

                                      <p className="my-2 text-center">OR</p>

                                      {!selectedProject?.assignedToAll && (
                                        <Button
                                          className="w-100 mb-2"
                                          onClick={() =>
                                            shareToAllHandler(graph.id)
                                          }
                                        >
                                          Share with Everyone
                                        </Button>
                                      )}
                                      {selectedProject?.assignedToAll && (
                                        <Button
                                          variant="none"
                                          className="w-100 text-danger shadow-sm border-danger"
                                          onClick={(e) => {
                                            revokeAccessFromAll(graph.id);
                                          }}
                                        >
                                          Revoke Access from <b>all</b>
                                        </Button>
                                      )}

                                      <Modal
                                        show={showRevokeModal}
                                        onHide={() => setShowRevokeModal(false)}
                                      >
                                        <Modal.Header
                                          className={
                                            darkTheme
                                              ? "bg-dark border-secondary pb-1"
                                              : "bg-white border-light pb-1"
                                          }
                                          closeButton
                                        >
                                          <Modal.Title>
                                            Revoke Access
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                          className={
                                            darkTheme
                                              ? "bg-dark border-secondary pb-1"
                                              : "bg-white border-light pb-1"
                                          }
                                        >
                                          Are you sure you want to Revoke
                                          Access?
                                        </Modal.Body>
                                        <Modal.Footer
                                          className={
                                            darkTheme
                                              ? "bg-dark border-secondary pb-1"
                                              : "bg-white border-light pb-1"
                                          }
                                        >
                                          <Button
                                            variant="secondary"
                                            onClick={() =>
                                              setShowRevokeModal(false)
                                            }
                                          >
                                            Close
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={(e) =>
                                              revokeSharingAccess(
                                                selectedProject.id
                                              )
                                            }
                                          >
                                            Revoke Access
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                              </>
                            )}
                            {graph.createdBy === user.uid && (
                              <>
                                <div className="pt-2">
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setSelectedProject(graph);
                                    }}
                                    className="text-uppercase font-weight-bold"
                                  >
                                    <FontAwesomeIcon icon={faTrash} size="sm" />{" "}
                                    <small>Delete</small>
                                  </Button>
                                </div>
                                <>
                                  <Modal
                                    centered
                                    show={showDeleteModal}
                                    onHide={() => setShowDeleteModal(false)}
                                  >
                                    <Modal.Header
                                      data-theme={darkTheme ? "dark" : "light"}
                                      className={darkTheme ? "border-dark" : ""}
                                      closeButton
                                    >
                                      <Modal.Title>Delete Project</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                      data-theme={darkTheme ? "dark" : "light"}
                                    >
                                      Are you sure you want to delete this
                                      project?
                                    </Modal.Body>
                                    <Modal.Footer
                                      data-theme={darkTheme ? "dark" : "light"}
                                      className={darkTheme ? "border-dark" : ""}
                                    >
                                      <Button
                                        size="sm"
                                        variant="secondary"
                                        onClick={() => {
                                          setShowDeleteModal(false);
                                          setSelectedProject(null);
                                        }}
                                        className="text-uppercase"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => deleteProjectHandler()}
                                        className="text-uppercase"
                                      >
                                        <FontAwesomeIcon icon={faTrash} />{" "}
                                        <span>Delete</span>
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                  {/*------------Edit Project Name Modal Start----------------*/}
                                  <Modal
                                    show={editProjectName}
                                    onHide={() => setEditProjectName(false)}
                                  >
                                    <Modal.Header
                                      closeButton
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <Modal.Title>
                                        Edit Project Name
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label>
                                            Edit Project Name
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Project Name"
                                            autoComplete="off"
                                            value={projectName}
                                            onChange={(e) =>
                                              setProjectName(e.target.value)
                                            }
                                          />
                                        </Form.Group>
                                      </Form>
                                    </Modal.Body>
                                    <Modal.Footer
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <Button
                                        variant="secondary"
                                        onClick={() =>
                                          setEditProjectName(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          editProjectNameHandler(graph.id)
                                        }
                                      >
                                        Update
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                  {/*------------Edit Project Name Modal Ends----------------*/}
                                </>
                              </>
                            )}
                          </div>

                          <div
                            className="pointer p-3"
                            onClick={() => {
                              props.setGraphId(graph.id);
                              history.push(
                                `/event/${id}/as_is/view/${graph.id}`
                              );
                            }}
                          >
                            <ProgressBar
                              height={2}
                              filledBackground="green"
                              percent={calculatePercent(graph)}
                            >
                              <Step>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      accomplished ? "accomplished" : null
                                    }`}
                                  >
                                    {/* {index + 1} */}
                                  </div>
                                )}
                              </Step>
                              <Step>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      accomplished
                                        ? graph.buyerExpAudit_created
                                          ? "accomplished"
                                          : "accomplished_warn"
                                        : null
                                    }`}
                                  >
                                    {accomplished &&
                                      !graph.buyerExpAudit_created &&
                                      "!"}
                                  </div>
                                )}
                              </Step>
                              <Step>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      accomplished
                                        ? graph.three_tiers
                                          ? "accomplished"
                                          : "accomplished_warn"
                                        : null
                                    }`}
                                  >
                                    {accomplished && !graph.three_tiers && "!"}
                                  </div>
                                )}
                              </Step>
                              <Step>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      accomplished
                                        ? graph.six_path
                                          ? "accomplished"
                                          : "accomplished_warn"
                                        : null
                                    }`}
                                  >
                                    {accomplished && !graph.six_path && "!"}
                                  </div>
                                )}
                              </Step>
                              <Step>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      accomplished
                                        ? graph.errc_created
                                          ? "accomplished"
                                          : "accomplished_warn"
                                        : null
                                    }`}
                                  >
                                    {accomplished && !graph.errc_created && "!"}
                                  </div>
                                )}
                              </Step>
                              <Step>
                                {({ accomplished, index }) => (
                                  <div
                                    className={`indexedStep ${
                                      accomplished
                                        ? graph.to_be_created
                                          ? "accomplished"
                                          : "accomplished_warn"
                                        : null
                                    }`}
                                  >
                                    {accomplished &&
                                      !graph.to_be_created &&
                                      "!"}
                                  </div>
                                )}
                              </Step>
                            </ProgressBar>
                          </div>
                          <div
                            className="row px-3 pointer"
                            onClick={() => {
                              props.setGraphId(graph.id);
                              history.push(
                                `/event/${id}/as_is/view/${graph.id}`
                              );
                            }}
                          >
                            <p className="small mx-3">
                              {props.currentEvent?.bbos_names?.as_is ??
                                "As Is Strategy"}
                            </p>
                            <p className="small mx-3">
                              {props.currentEvent?.bbos_names
                                ?.buyer_experience ?? "Buyer Experience Audit"}
                            </p>
                            <p className="small mx-3">
                              {props.currentEvent?.bbos_names?.three_tier ??
                                "Three Tiers of Non-Customers"}
                            </p>
                            <p className="small mx-3">
                              {props.currentEvent?.bbos_names?.six_path ??
                                "Six Path"}
                            </p>
                            <p className="small mx-3">
                              {props.currentEvent?.bbos_names?.errc ??
                                "Errc Grid"}
                            </p>
                            <p className="small mx-3">
                              {props.currentEvent?.bbos_names?.to_be ??
                                "To Be Strategy"}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
              <Modal centered show={show} onHide={() => setShow(false)}>
                <Modal.Header
                  className={
                    darkTheme
                      ? "bg-dark border-secondary pb-1"
                      : "bg-white border-light pb-1"
                  }
                  closeButton
                >
                  <Modal.Title>Add Project</Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={
                    darkTheme
                      ? "bg-dark border-secondary pb-1"
                      : "bg-white border-light pb-1"
                  }
                >
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Project Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Name"
                        autoComplete="off"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer
                  className={
                    darkTheme
                      ? "bg-dark border-secondary pb-1"
                      : "bg-white border-light pb-1"
                  }
                >
                  <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={() => addProjectHandler()}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block  "
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={props?.currentEvent?.id}
              tabs={
                props?.currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${props?.currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${props?.currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
          {/* Chat Mobile view */}
          {showChat && (
            <div
              className="shadow-lg border border-muted"
              style={{
                height: "55vh",
                position: "fixed",
                bottom: "17vh",
                left: 10,
              }}
            >
              <EventChat event_id={props?.currentEvent?.id} />
            </div>
          )}
          <div
            style={{
              position: "fixed",
              height: 45,
              width: 45,
              bottom: "10.5vh",
              left: 5,
            }}
            className="bg-primary rounded-circle d-block d-md-none"
          >
            <a
              className="d-flex align-items-center justify-content-center"
              onClick={() => setShowChat((prev) => !prev)}
              style={{
                height: 45,
                width: 45,
              }}
            >
              <FontAwesomeIcon
                className="text-white"
                icon={showChat ? faTimes : faComment}
                style={{ fontSize: 20 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    workspaceId: state.workspaceId,
    roomId: state.roomId,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  { setGraphId, setProducts }
)(ProjectList);
