import React from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router";

import * as TimelineStyle from "./timeline.module.css";
import { memberList } from "../data";

export default function MembersCard() {
  const history = useHistory();

  return (
    <Card className={`shadow-sm ${TimelineStyle.custom_content_card}`}>
      <Card.Body className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <p className="text-white font-weight-bold mb-0 mr-2">Members</p>
            <small className="text-white mb-0">
              (
              {memberList.length > 0
                ? memberList.length > 1
                  ? `${memberList.length} members`
                  : `${memberList.length} member`
                : `No member found`}
              )
            </small>
          </div>
          <Button
            size="sm"
            variant="none"
            className="text-primary"
            onClick={() => history.push("members")}
          >
            See all
          </Button>
        </div>
        <div className="d-flex align-items-end" style={{ height: 60 }}>
          {memberList.map((member, key) => (
            <div
              className="img-fluid rounded-circle shadow-sm border"
              style={{
                height: 50,
                width: 50,
                backgroundImage: `url(${member.avatar})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "relative",
                left: -10 * key,
              }}
              title={member.name}
            />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}
