import { faBuilding, faHospitalAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { Modal, Button } from "react-bootstrap";

const AddConsultancyModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-dark text-light border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Select type of consultancy to add
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-light p-3">
        <div className="d-flex align-items-center justify-content-around">
          <Button
            variant="outline-light"
            className="p-5"
            href="/admin/add/office"
          >
            <FontAwesomeIcon icon={faBuilding} size="6x" className="mb-3" />
            <p className="mb-0">Office</p>
          </Button>
          <Button
            variant="outline-light"
            className="p-5"
            href="/admin/add/clinic"
          >
            <FontAwesomeIcon icon={faHospitalAlt} size="6x" className="mb-3" />
            <p className="mb-0">Clinic</p>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(AddConsultancyModal);
