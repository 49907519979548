import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router";
import { Skeleton } from "@material-ui/lab";
import parse from "html-react-parser";
import * as TimelineStyle from "./timeline.module.css";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import moment from "moment";

export default function Courses() {
  const history = useHistory();

  const [eventList, setEventList] = useState([]);

  const [allCourses, setAllCourses] = useState([]);

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  const [courseValue, courseLoading, courseError] = useCollection(
    firebase.firestore().collection("courses"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [eventsValue, eventsLoading, eventsError] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("type", "==", 1)
      .where("is_published", "==", true)
      .limit(4),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (eventsValue) {
      let data = [];
      eventsValue?.docs?.map((e) => {
        data.push({ id: e.id, ...e.data() });
      });
      setEventList([...data]);
    }
  }, [eventsValue]);

  useEffect(() => {
    if (courseValue) {
      let data = [];
      courseValue?.docs?.map((e) => {
        data.push({ id: e.id, ...e.data() });
      });
      setAllCourses([...data]);
    }
  }, [courseValue]);

  const formatCurrent = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 7,
    }).format(value);
  };

  const LoadingSkeleton = () => {
    return [1, 2, 3].map((item) => (
      <div className="col-md-4 pl-0" key={item}>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded"
          height={150}
        />
        <Skeleton
          animation="wave"
          className="bg-muted rounded mt-1"
          height={24}
        />
        <Skeleton animation="wave" className="bg-muted rounded" height={24} />
        <Skeleton
          animation="wave"
          className="bg-muted rounded w-50"
          height={20}
        />
      </div>
    ));
  };

  return (
    <div className="">
      <div className="col-md-12  mb-4">
        <div className="gradient-box bg-gradient-light pb-5">
          <div className="row mb-5">
            <div className="col-md-6">
              <h3 className="mb-3  text-s-7 text-blue">
                Offer blended courses.
              </h3>
              <p className="text-s-6">
                <span style={{ color: "#333333" }}>
                  Create and offer courses and customized learning experiences.
                  Benefit from learning apps – such as quizzes, assignments and
                  gradebook delivered with the Igesia platform.
                </span>
                <br /> <br />
                <span style={{ color: "#888888" }}>
                  Build or link your own custom learning apps. Manage course
                  enrollments and payments.
                </span>
              </p>
              <a
                // onClick={handleShow}
                href="/courses"
                style={{
                  background: "rgb(118, 60, 247)",
                  borderRadius: "12px",
                }}
                className="btn text-white decoration-none font-weight-bold mb-3"
              >
                Explore Course Platform
              </a>
              <a
                // onClick={handleShow}
                href="/courses"
                style={{
                  
                }}
                className="btn text-purple decoration-none font-weight-bold mb-3"
              >
                Request a Demo
              </a>
            </div>
            <div className="col-md-6">
              <div className="text-center">
                <img
                  style={{ marginRight: "0px" }}
                  src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/igesia-course.png"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <h5 className="font-weight-bold ">Discover courses on igesia</h5>
            </div>
            <div className="col-md-12">
              <div className="d-flex" style={{ overflowX: "scroll" }}>
                {eventsLoading && <LoadingSkeleton />}

                {eventList
                  .sort((a, b) => b.timestamp - a.timestamp)
                  .slice(0, 5)
                  .map((event, key) => (
                    <div className="col-md-4 pl-0" key={key}>
                      <div
                        className="card event-card border-0  h-100"
                        onClick={() => history.push(`event/${event.slug}`)}
                        title={`Event: ${
                          event?.event_details
                            ? event?.event_details.EN?.event_name
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className="rounded-top"
                          style={{
                            backgroundImage: `url(${event?.event_details?.featured_image})`,
                            height: 150,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            className: "card-img-top bg-gradient-purple",
                          }}
                        />
                        <div className="card-body bg-light">
                          <h3 className="text-s-7 text-dark">
                            {event?.event_details.EN?.event_name}
                          </h3>
                          <p className="font-weight-bold small mb-2 text-deep-purple">
                            {moment(
                              convertSecondstoMilliSeconds(
                                event?.event_details?.start_date?.seconds
                              )
                            ).format("DD MMMM YYYY")}
                          </p>
                          {event?.event_details?.EN?.small_description && (
                            <span className="small mb-0 text-secondary">
                              {parse(
                                event?.event_details?.EN?.small_description
                              )}
                            </span>
                          )}

                          {/* <Link to={process.env.PUBLIC_URL + "/eventpage"} className="text-decoration-none">
                                        <div className="row shadow-lg py-2 mx-1 bg-gradient-grey-dark rounded mt-3 btn-hero">
                                            <div className="col-6">
                                                <span className="mb-0 d-block text-s-7 mb-0 text-light">Mar 16</span>
                                                <span className="mb-0 text-s-9 text-light">11:25PM IST</span>
                                            </div>
                                            <div className="col-6 text-right align-self-center">
                                                <span className="text-s-8 text-purple text-decoration-none">₹0.00 ~ ₹600</span>
                                            </div>
                                        </div>
                                    </Link> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-12 text-center">
              <Button
                className="mt-5"
                variant="none"
                size="sm"
                onClick={() => history.push("courses")}
              >
                <span className="text-purple font-weight-bold ">
                  SEE MORE COURSES
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
