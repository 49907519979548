import React, { useState, useMemo, memo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, InputGroup } from "react-bootstrap";
import moment from "moment";

const Assignment = (props) => {
  const { data } = props;

  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const [quizSnapshot, quizDataLoading, quizDataError] = useDocument(
    firebase.firestore().doc(`quiz/${data.contentId}`)
  );

  const quizData = useMemo(() => {
    if (quizDataLoading) return quizSnapshot?.data();
    else return {};
  }, [quizSnapshot, quizDataLoading]);

  const user_id = firebase.auth().currentUser.uid;

  const [answers, setAnswers] = useState([]);

  const [responses, setResponses] = useState([]);

  const getResult = (response, user_id) => {
    return response && response.find((user) => user.id === user_id)
      ? true
      : false;
  };

  const [submit, setSubmit] = useState(
    quizData && getResult(responses, user_id)
  );

  const [all, setAll] = useState([]);
  const [responseAnswers, setResponseAnswers] = useState([]);

  const [current_question, set_current_question] = useState(
    quizSnapshot?.data() && getResult(responses, user_id)
      ? Object.keys(quizSnapshot?.data()?.quizQuestions).length - 1
      : 0
  );

  const addAnswer = (q_no, ans, type = 0, question_id, option_id) => {
    console.log(q_no, ans, type, "q_no,ans,type");
    console.log(question_id, option_id, "q_id,o_id");
    if (type === 0) {
      let allAnswers = answers;
      let resAnswers = [...responseAnswers];
      let prev = allAnswers[q_no] ? allAnswers[q_no] : "";
      let prevAns = resAnswers[q_no] ? resAnswers[q_no] : {};
      allAnswers[q_no] = prev === ans ? "" : ans;

      let json = { question_id: question_id, options: option_id };

      if (
        prevAns.question_id === question_id &&
        prevAns.option_id === option_id
      ) {
        resAnswers.push({});
      } else {
        resAnswers.splice(q_no, 1, json);
      }
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    } else {
      let allAnswers = answers;
      let resAnswers = responseAnswers;
      let prev = allAnswers[q_no] ? allAnswers[q_no] : [];

      allAnswers[q_no] = prev.includes(ans)
        ? prev.filter((item) => item !== ans)
        : [...prev, ans];
      let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );
      let index = resAnswers.findIndex(
        (res) => res.question_id === question_id
      );
      PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};
      let prevAnswers =
        PrevAnswerObj && PrevAnswerObj.options ? PrevAnswerObj.options : [];
      prevAnswers = prevAnswers.includes(option_id)
        ? prevAnswers.filter((item) => item !== option_id)
        : [...prevAnswers, option_id];
      PrevAnswerObj.question_id = question_id;
      PrevAnswerObj.options = [...prevAnswers];
      if (index === -1) {
        resAnswers.push(PrevAnswerObj);
      } else {
        resAnswers.splice(index, 1, PrevAnswerObj);
      }
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    }
  };

  if (quizDataLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  return (
    <div className="">
      <div>
        <Card className="bg-dark text-white mb-3">
          <Card.Header className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h4 className="font-weight-bold mb-0">
                {quizSnapshot?.data()?.quizName}
              </h4>
            </div>
            <p className="small mb-0">
              {moment(data?.timestamp).format("LLL")}
            </p>
          </Card.Header>
          <Card.Body className="py-1">
            <>
              {quizSnapshot &&
                Object.keys(quizSnapshot?.data()?.quizQuestions)
                  .sort(
                    (a, b) =>
                      quizSnapshot?.data()?.quizQuestions[a].sequenceNo -
                      quizSnapshot?.data()?.quizQuestions[b].sequenceNo
                  )
                  .map(
                    (q, k) =>
                      current_question === k && (
                        <Card className="task" key={k}>
                          <Card.Header className="bg-white d-flex">
                            <small className="text-dark flex-grow-1">
                              Question {k + 1} <br />
                              {quizSnapshot?.data()?.quizQuestions[q].weight
                                ? quizSnapshot?.data()?.quizQuestions[q]
                                    .weight > 1
                                  ? `${
                                      quizSnapshot?.data()?.quizQuestions[q]
                                        .weight
                                    } points`
                                  : `${
                                      quizSnapshot?.data()?.quizQuestions[q]
                                        .weight
                                    } point`
                                : ""}
                            </small>
                            <div className="d-flex">
                              {current_question !== 0 && (
                                <Button
                                  size="sm"
                                  variant="light"
                                  style={{
                                    width: "100px",
                                    marginRight: "5px",
                                  }}
                                  onClick={() =>
                                    set_current_question((prev) => prev - 1)
                                  }
                                >
                                  <small>Previous</small>
                                </Button>
                              )}

                              {current_question !==
                                Object.keys(quizSnapshot?.data()?.quizQuestions)
                                  .length -
                                  1 && (
                                <Button
                                  size="sm"
                                  variant="dark"
                                  style={{
                                    width: "100px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() =>
                                    set_current_question((prev) => prev + 1)
                                  }
                                >
                                  <small>Next</small>
                                </Button>
                              )}

                              {current_question ===
                                Object.keys(quizSnapshot?.data()?.quizQuestions)
                                  .length -
                                  1 &&
                                (submit ? (
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    style={{
                                      width: "100px",
                                      marginLeft: "5px",
                                    }}
                                    disabled
                                  >
                                    <small>Completed</small>
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    variant="success"
                                    style={{
                                      width: "100px",
                                      marginLeft: "5px",
                                    }}
                                    //onClick={() => handleSubmit()}
                                    disabled={responseAnswers.length === 0}
                                  >
                                    <small>Submit</small>
                                  </Button>
                                ))}
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <small className="font-weight-bold text-dark">
                              {answers.filter((item) => item !== null).length}{" "}
                              of{" "}
                              {
                                Object.keys(quizSnapshot?.data()?.quizQuestions)
                                  .length
                              }{" "}
                              answered
                            </small>

                            <p className="text-dark">
                              {quizSnapshot?.data()?.quizQuestions[q].question}
                            </p>
                            <div>
                              {quizSnapshot?.data()?.quizQuestions[q]
                                .questionImage && (
                                <>
                                  <img
                                    className="img-fluid mb-2"
                                    alt=""
                                    style={{ height: 100, width: "auto" }}
                                    src={
                                      quizSnapshot?.data()?.quizQuestions[q]
                                        .questionImage
                                    }
                                    // onClick={() => {
                                    //   setImageSelected(quizSnapshot?.data()?.quizQuestions[q].questionImage)
                                    //   setVisible(true)
                                    // }}
                                  />
                                </>
                              )}
                            </div>
                            {Object.keys(
                              quizSnapshot?.data()?.quizQuestions[q].options
                            )
                              .sort(
                                (a, b) =>
                                  quizSnapshot?.data()?.quizQuestions[q]
                                    .options[a].sequenceNo -
                                  quizSnapshot?.data()?.quizQuestions[q]
                                    .options[b].sequenceNo
                              )
                              .map((o, key) => (
                                <>
                                  {quizSnapshot?.data()?.quizQuestions[q]
                                    .options[o].optionImage == null ? null : (
                                    <img
                                      src={
                                        quizSnapshot?.data()?.quizQuestions[q]
                                          .options[o].optionImage
                                      }
                                      style={{ width: "100px" }}
                                    />
                                  )}
                                  <div
                                    className={
                                      quizSnapshot?.data()?.quizQuestions[q]
                                        .type === 0
                                        ? quizSnapshot?.data()?.quizQuestions[q]
                                            .options[o].optionValue ===
                                            answers[k] ||
                                          (responseAnswers[k] &&
                                            q ===
                                              responseAnswers[k][
                                                "question_id"
                                              ] &&
                                            responseAnswers[k] &&
                                            o === responseAnswers[k]["options"])
                                          ? "form-check rounded mb-2 border-dark"
                                          : "form-check rounded mb-2"
                                        : answers[k] &&
                                          answers[k].includes(
                                            quizSnapshot?.data()?.quizQuestions[
                                              q
                                            ].options[o].optionValue
                                          )
                                        ? "form-check rounded mb-2 border-dark"
                                        : "form-check rounded mb-2"
                                    }
                                    key={key}
                                  >
                                    <input
                                      className="form-check-input"
                                      type={
                                        quizSnapshot?.data()?.quizQuestions[q]
                                          .type === 0
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      value={
                                        quizSnapshot?.data()?.quizQuestions[q]
                                          .options[o].optionValue
                                      }
                                      name={
                                        quizSnapshot?.data()?.createdBy +
                                        "_" +
                                        quizSnapshot?.data()?.quizQuestions[q]
                                          .options[o].optionValue +
                                        k +
                                        key
                                      }
                                      id={
                                        quizSnapshot?.data()?.createdBy +
                                        "_" +
                                        k +
                                        "_" +
                                        key +
                                        "_" +
                                        q
                                      }
                                      onChange={(e) => {
                                        /* console.log(
                                                                              e.target.name,
                                                                              e.target.value
                                                                            );
                                                                            console.log(
                                                                              quizData.quizQuestions[q].options[o]
                                                                                .optionValue
                                                                            );*/
                                        addAnswer(
                                          k,
                                          e.target.value,
                                          quizSnapshot?.data()?.quizQuestions[q]
                                            .type,
                                          q,
                                          o
                                        );
                                        setAll(e.target.value);
                                      }}
                                      checked={
                                        quizSnapshot?.data()?.quizQuestions[q]
                                          .type === 0
                                          ? quizSnapshot?.data()?.quizQuestions[
                                              q
                                            ].options[o].optionValue ===
                                            answers[k]
                                            ? true
                                            : false
                                          : answers[k] &&
                                            answers[k].includes(
                                              quizSnapshot?.data()
                                                ?.quizQuestions[q].options[o]
                                                .optionValue
                                            )
                                      }
                                      disabled={submit}
                                    />

                                    <label
                                      className="form-check-label text-dark"
                                      htmlFor={
                                        quizSnapshot?.data()?.createdBy +
                                        "_" +
                                        k +
                                        "_" +
                                        key +
                                        "_" +
                                        q
                                      }
                                    >
                                      {
                                        quizSnapshot?.data()?.quizQuestions[q]
                                          .options[o].optionValue
                                      }
                                    </label>
                                  </div>
                                </>
                              ))}
                          </Card.Body>
                        </Card>
                      )
                  )}
            </>
          </Card.Body>
          <Card.Footer>
            <p className="mb-0 small">
              Created by <b>{data?.user?.name}</b>
            </p>
            <p className="mb-0 small">
              Deadline by <b>{moment(quizSnapshot?.deadline).format("LLL")}</b>
            </p>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};
export default memo(Assignment);
