import React, { useRef, useState, useEffect } from "react";
import { Card, Image, Tabs, Tab } from "react-bootstrap";
import { changeLanguage, setAppPage } from "../../../store/actions/actions";
import { connect } from "react-redux";

import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import ProjectRedux from "../ProjectRedux";
import Chat from "./Chat";
import * as ProjectStyle from "../project.module.css";
import { useHistory, useParams } from "react-router";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { useMemo } from "react";
import Poll from "../../Apps/Poll";
import Schedule from "../../Apps/Schedule";
import Reading from "../../Apps/Reading";
import Assignment from "../../Apps/Assignment";
import Media from "../../Apps/Media";
import Quiz from "../../Apps/Quiz";
import { appsAvailable } from "../../data";

function Apps({ project, setAppPage, app }) {
  const { app_type } = useParams();

  const thisApp = appsAvailable.filter(
    (apps) => apps.route === app_type
  )[0];

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const query =
    thisApp && Array.isArray(thisApp?.type)
      ? firebase
        .firestore()
        .collection(
          "/workspaces/LgT2sjzoZQwwKMGPnob2/rooms/I5ZwggPkyQpeCdRpcl1M/messages"
        )
        .where("type", "in", thisApp?.type)
      : firebase
        .firestore()
        .collection(
          "/workspaces/LgT2sjzoZQwwKMGPnob2/rooms/I5ZwggPkyQpeCdRpcl1M/messages"
        )
        .where("type", "==", thisApp?.type);

  const [appDataSnapshot, appDataLoading, appDataError] = useCollection(
    query.orderBy("timestamp", "desc")
  );

  const appData = useMemo(() => {
    return appDataSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [appDataSnapshot]);

  const [mediaKey, setMediaKey] = useState("images");

  const mediaTypes = [6, 7, 12];

  return (
    <>
      <ProjectRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "95vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <div className="d-flex align-items-center rounded border border-dark">
                    <img
                      src={thisApp?.icon_image}
                      className={`bg-dark p-1 ${ProjectStyle.app_icon_image}`}
                      alt=""
                    />
                    <h3 className="font-weight-bold text-white mx-2 mb-0">
                      {thisApp?.name}
                    </h3>
                  </div>
                  {thisApp?.name === "Media" ? (
                    <div className="my-3">
                      <Tabs
                        className="d-flex justify-content-around border-0"
                        defaultActiveKey="images"
                        id="uncontrolled-tab-example"
                        onSelect={(e) => setMediaKey(e)}
                      >
                        <Tab
                          tabClassName={
                            mediaKey == "images"
                              ? "col text-center border-dark rounded text-primary font-weight-bold"
                              : "col text-center border-secondary rounded text-white font-weight-bold"
                          }
                          eventKey="images"
                          title="Images"
                        ></Tab>
                        <Tab
                          tabClassName={
                            mediaKey == "files"
                              ? "col text-center border-dark rounded text-primary font-weight-bold"
                              : "col text-center border-secondary rounded text-white font-weight-bold"
                          }
                          eventKey="files"
                          title="Files"
                        ></Tab>
                        <Tab
                          tabClassName={
                            mediaKey == "links"
                              ? "col text-center border-dark rounded text-primary font-weight-bold"
                              : "col text-center border-secondary rounded text-white font-weight-bold"
                          }
                          eventKey="links"
                          title="Links"
                        ></Tab>
                      </Tabs>
                    </div>
                  ) : null}

                  <div className="mt-3 mt-md-4">
                    <div className="mb-3 mb-md-5">
                      <div className={mediaTypes.includes(parseInt(thisApp?.type)) && (mediaKey === "images" ? "row" : "")}>
                        {appData?.map((data, key) => {
                          switch (data?.type) {
                            case 1:
                              return (
                                <div key={key}>
                                  <Schedule data={data} />
                                </div>
                              );
                              break;

                            case 2:
                              return (
                                <div key={key}>
                                  <Reading data={data} />
                                </div>
                              );
                              break;

                            case 3:
                              return (
                                <div key={key}>
                                  <Poll data={data} />
                                </div>
                              );
                              break;

                            case 5:
                              return (
                                <div key={key}>
                                  <Assignment data={data} />
                                </div>
                              );
                              break;

                            case 4:
                              return (
                                <div key={key}>
                                  <Quiz data={data} />
                                </div>
                              );
                            case 6:
                            case 7:
                            case 12:
                              return (
                                <Media
                                  key={key}
                                  data={data}
                                  eventKey={mediaKey}
                                />
                              );
                              break;

                            default:
                              return (
                                <div key={key}>
                                  <p className="text-white">{data.type}</p>
                                </div>
                              );
                              break;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Chat module */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module ends */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
    app: state.app,
  }),
  { changeLanguage, setAppPage }
)(Apps);
