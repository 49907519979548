import React, { useRef } from "react";
import clamp from "lodash-es/clamp";
import swap from "lodash-move";
import { useGesture } from "react-with-gesture";
import { useSprings, animated, interpolate } from "react-spring";
import SpeakerDragList from "./Templates/SpeakerDragList";
import CategoriesDragList from "./Templates/CategoriesDragList";
import CategoriesDragListMultiLang from "./Templates/CategoriesDragListMultiLang";
import BoothsDragList from "./Templates/BoothsDragList";

const fn = (order, down, originalIndex, curIndex, y) => (index) =>
  down && index === originalIndex
    ? {
        y: curIndex * 100 + y,
        scale: 1,
        zIndex: "1",
        shadow: 5,
        immediate: (n) => n === "y" || n === "zIndex",
      }
    : {
        y: order.indexOf(index) * 100,
        scale: 1,
        zIndex: "0",
        shadow: 1,
        immediate: false,
      };

export default function DraggableList(props) {
  const { items, variant } = props;
  const order = useRef(items.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(items.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const bind = useGesture((event) => {
    const {
      args: [originalIndex],
      down,
      delta: [, y],
    } = event;
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(
      Math.round((curIndex * 100 + y) / 100),
      0,
      items.length - 1
    );
    const newOrder = swap(order.current, curIndex, curRow);
    props.orderFunction(newOrder);
    setSprings(fn(newOrder, down, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
    if (!down) order.current = newOrder;
  });
  return (
    <div style={{ height: items.length * 100 }}>
      {springs.map(({ zIndex, shadow, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          style={{
            zIndex,
            boxShadow: shadow.interpolate(
              (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
            ),
            transform: interpolate(
              [y, scale],
              (y, s) => `translate3d(0,${y}px,0) scale(${s})`
            ),
            cursor: "grabbing",
          }}
          className="user-select-none bg-dark text-white drag rounded border d-flex align-items-center justify-content-center"
          // children={items[i].first_name}
        >
          {variant === "speakers" ? (
            <SpeakerDragList items={items} i={i} />
          ) : variant === "categories" ? (
            <CategoriesDragList items={items} i={i} />
          ) : variant === "categoriesMultiLang" ? (
            <CategoriesDragListMultiLang items={items} i={i} />
          ) : variant === "booths" ? (
            <BoothsDragList items={items} i={i} />
          ) : null}
        </animated.div>
      ))}
    </div>
  );
}
