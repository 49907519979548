import React, { useState, useMemo } from "react";
import { Button, Card, Dropdown, Form, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import firebase from "firebase";
import { toast } from "react-toastify";
import { useCollection } from "react-firebase-hooks/firestore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faGripVertical,
  faTrash,
  faEllipsisH,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Select from "react-select";
import ReactExport from "react-export-excel";

function IdeationTable(props) {
  const { darkTheme, event_id, project_id } = props;
  const user = firebase.auth().currentUser;

  const [showIdeaForm, setShowIdeaForm] = useState(false);
  const [showIdeaDetailsModal, setShowIdeaDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reorderIdeaModal, setReorderIdeaModal] = useState(false);
  const [idea, setIdea] = useState({
    title: "",
    path_name: "",
    noncustomers_addressed: "",
    value_proposition: "",
    eliminated: "",
    created: "",
  });
  const [sending, setSending] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const paths = [
    {
      label: "Time Trends",
      value: "time-trends",
    },
    {
      label: "Alternative Industries",
      value: "alternative-industries",
    },
    {
      label: "Strategic Groups",
      value: "strategic-groups",
    },
    {
      label: "Decision Chain",
      value: "decision-chain",
    },
    {
      label: "Complementary Products & Services",
      value: "complementary-products-services",
    },
    {
      label: "Emotional & Functional Appeal",
      value: "emotional-functional-appeal",
    },
  ];

  const resetIdea = () => {
    setIdea({
      id: "",
      title: "",
      path_name: "",
      noncustomers_addressed: "",
      value_proposition: "",
      eliminated: "",
      created: "",
    });
  };

  const [ideasSnapshot, ideasLoading, ideasError] = useCollection(
    firebase
      .firestore()
      .collection(`events/${event_id}/bboa/${project_id}/ideas`)
  );

  const ideasList = useMemo(() => {
    return ideasSnapshot?.docs
      ?.sort((a, b) => a?.data()?.order_index - b?.data()?.order_index)
      ?.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }, [ideasSnapshot]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return;
    }

    let sourceIndex = ideasList[result.source.index];
    let destinationIndex = ideasList[result.destination.index];
    if (result.destination) {
      firebase
        .firestore()
        .collection(`events/${event_id}/bboa/${project_id}/ideas`)
        .doc(sourceIndex.id)
        .set(
          {
            order_index: destinationIndex.order_index,
          },
          {
            merge: true,
          }
        );
      firebase
        .firestore()
        .collection(`events/${event_id}/bboa/${project_id}/ideas`)
        .doc(destinationIndex.id)
        .set(
          {
            order_index: sourceIndex.order_index,
          },
          {
            merge: true,
          }
        );
    }
  };

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#efefef" : "white",
    padding: grid,
    width: "100%",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#efefef" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // Function: Idea form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    if (editing) {
      if (idea?.id === "") {
        toast.warning("Error editing idea, please refresh.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        return;
      }
    }

    if (idea?.title === "") {
      setError(true);
      setSending(false);
      return;
    }

    const ideaRef = firebase
      .firestore()
      .collection(`events/${event_id}/bboa/${project_id}/ideas`);

    if (editing) {
      ideaRef
        .doc(`${idea?.id}`)
        .update(
          {
            title: idea?.title ?? "",
            path_name: idea?.path_name ?? "",
            noncustomers_addressed: idea?.noncustomers_addressed ?? "",
            value_proposition: idea?.value_proposition ?? "",
            eliminated: idea?.eliminated ?? "",
            created: idea?.created ?? "",
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Idea updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((e) => {
          console.error("Idea error: ", e);
          setSending(false);

          toast.warning("Error adding idea: " + e, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setSending(false);
          resetIdea();
          setShowIdeaForm(false);
          setEditing(false);
        });
    } else {
      ideaRef
        .add({
          title: idea?.title ?? "",
          path_name: idea?.path_name ?? "",
          noncustomers_addressed: idea?.noncustomers_addressed ?? "",
          value_proposition: idea?.value_proposition ?? "",
          eliminated: idea?.eliminated ?? "",
          created: idea?.created ?? "",
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          created_by: {
            name: user.displayName,
            email: user.email,
            id: user.uid,
          },
          order_index: ideasList?.length ?? 1,
        })
        .then(() => {
          toast.success("Idea added successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((e) => {
          console.error("Idea error: ", e);
          setSending(false);

          toast.warning("Error adding idea: " + e, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setSending(false);
          resetIdea();
          setShowIdeaForm(false);
        });
    }
  };

  // Function: Delete idea
  const handleDeleteIdea = () => {
    if (!!idea && idea?.id != "") {
      // do nothing
    } else {
      toast.warning("Error deleting idea, please refresh.", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    setDeleting(true);

    const ideaRef = firebase
      .firestore()
      .collection(`events/${event_id}/bboa/${project_id}/ideas`);

    ideaRef
      .doc(`${idea?.id}`)
      .delete()
      .then(() => {
        toast.dark("Idea deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((e) => {
        console.error("Idea error: ", e);
        setDeleting(false);

        toast.warning("Error deleting idea: " + e, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setDeleting(false);
        resetIdea();
        setShowDeleteModal(false);
      });
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </a>
  ));

  const getPathName = (path_id) => {
    const pathName = paths.filter((item) => item.value === path_id)[0]
      ? paths.filter((item) => item.value === path_id)[0]["label"]
      : "None";

    return pathName;
  };

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header>
        <div className="d-flex mb-2">
          <h5 className="mb-0 text-uppercase flex-grow-1">7. IDEATION</h5>
          <div>
            <ExcelFile
              element={
                <Button size="sm" className="text-uppercase mr-2">
                  <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                  <span>Export</span>
                </Button>
              }
            >
              <ExcelSheet data={ideasList ?? []} name="Ideation">
                <ExcelColumn
                  label="Creation time"
                  value={(item) =>
                    moment(
                      convertSecondstoMilliSeconds(
                        item?.created_at?.seconds,
                        item?.created_at?.nanoseconds
                      )
                    ).format("LLL")
                  }
                />
                <ExcelColumn
                  label="Name"
                  value={(item) => item.created_by?.name}
                />
                <ExcelColumn
                  label="Email"
                  value={(item) => item.created_by?.email}
                />
                <ExcelColumn label="Title" value={(item) => item.title} />
                <ExcelColumn
                  label="Select Path"
                  value={(item) => getPathName(item.path_name)}
                />
                <ExcelColumn
                  label="Noncustomers Addressed"
                  value={(item) => item.noncustomers_addressed}
                />
                <ExcelColumn
                  label="Value Proposition"
                  value={(item) => item.value_proposition}
                />
                <ExcelColumn
                  label="Eliminated"
                  value={(item) => item.eliminated}
                />
                <ExcelColumn label="Created" value={(item) => item.created} />
              </ExcelSheet>
            </ExcelFile>
            <Button
              size="sm"
              className="text-uppercase mr-2"
              onClick={() => setReorderIdeaModal(true)}
            >
              Reorder Ideas
            </Button>
            <Button
              onClick={() => {
                setShowIdeaForm(true);
                setEditing(false);
                resetIdea();
              }}
              className="text-uppercase"
              size="sm"
            >
              Add Idea
            </Button>
          </div>
        </div>
        {/* <div>
          <p className="mb-0">
            "Is your industry perimeter a Red Ocean? Why?", "Define the standard
            offering on your industry" and "Is there a potential to
            differentiate from the standard offering?Some ideas of new value
            elements to be added in the offering?"
          </p>
        </div> */}
      </Card.Header>

      <Card.Body>
        {ideasError && (
          <p className="text-center">Error fetching ideas, please reload.</p>
        )}

        {ideasLoading && (
          <p className="text-center">
            <Spinner animation="border" size="sm" /> Fetching ideas...
          </p>
        )}

        {ideasList?.length > 0 ? (
          ideasList?.map((item, index) => (
            <div key={index} className="border rounded p-2 mb-2">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="d-flex align-items-center">
                  <b className="mr-2">{index + 1}.</b>
                  {item.path_name && (
                    <div className="mr-2">
                      <img
                        className="img-fluid pb-1"
                        src={`https://igesia-static-1bt.s3.amazonaws.com/images/bboa/${item.path_name}.png`}
                        style={{
                          height: 40,
                          width: 40,
                        }}
                      />
                    </div>
                  )}
                  <div>
                    <p className="mb-0">{item.title}</p>
                    <p className="mb-0">{item.value_proposition}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <Dropdown className="px-1 py-0 pointer">
                    <Dropdown.Toggle
                      size="sm"
                      variant="white"
                      as={CustomToggle}
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setEditing(true);
                          setIdea({
                            id: item.id,
                            title: item.title,
                            path_name: item.path_name ?? "",
                            noncustomers_addressed:
                              item?.noncustomers_addressed ?? "",
                            value_proposition: item?.value_proposition ?? "",
                            eliminated: item?.eliminated ?? "",
                            created: item?.created ?? "",
                          });
                          setShowIdeaForm(true);
                        }}
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        className="text-uppercase text-dark"
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setShowDeleteModal(true);
                          setIdea({
                            id: item.id,
                            title: item.title,
                            path_name: item.path_name ?? "",
                            noncustomers_addressed:
                              item?.noncustomers_addressed ?? "",
                            value_proposition: item?.value_proposition ?? "",
                            eliminated: item?.eliminated ?? "",
                            created: item?.created ?? "",
                          });
                        }}
                        className="text-uppercase"
                      >
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <p className="text-muted mb-0">{item.noncustomers_addressed}</p>
              <Button
                onClick={() => {
                  setIdea({
                    id: item.id,
                    ...item,
                  });
                  setShowIdeaDetailsModal(true);
                }}
                variant="link"
                size="sm"
                className="px-0"
              >
                Show more details
              </Button>
            </div>
          ))
        ) : (
          <p className="text-center">
            <i>No ideas added yet.</i>
          </p>
        )}
      </Card.Body>

      {/* Modal: Show idea details */}
      <Modal
        show={showIdeaDetailsModal}
        onHide={() => {
          setShowIdeaDetailsModal(false);
          resetIdea();
        }}
      >
        <Modal.Header closeButton>
          <div>
            <div className="d-flex align-items-center mb-2">
              {idea?.path_name && (
                <div className="mr-2">
                  <img
                    className="img-fluid pb-1"
                    src={`https://igesia-static-1bt.s3.amazonaws.com/images/bboa/${idea?.path_name}.png`}
                    style={{
                      height: 40,
                      width: 40,
                    }}
                  />
                </div>
              )}
              <div>
                <h5 className="mb-0">{idea?.title}</h5>
                <h5 className="mb-0">{idea?.value_proposition}</h5>
              </div>
            </div>

            <p className="mb-1 small">
              {moment(
                convertSecondstoMilliSeconds(
                  idea?.created_at?.seconds,
                  idea?.created_at?.nanoseconds
                )
              ).format("LLL")}{" "}
              <b className="text-muted">({idea?.created_by?.email})</b>
            </p>
            <div className="d-flex">
              <Button
                size="sm"
                className="text-uppercase mr-2"
                onClick={() => {
                  setShowIdeaDetailsModal(false);
                  setEditing(true);
                  setIdea({
                    ...idea,
                  });
                  setShowIdeaForm(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit
              </Button>
              <Button
                variant="danger"
                size="sm"
                className="text-uppercase"
                onClick={() => {
                  setShowIdeaDetailsModal(false);
                  setShowDeleteModal(true);
                  setIdea({
                    ...idea,
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="small text-muted text-uppercase mb-0">
              Noncustomers Addressed
            </p>
            <p>
              {idea?.noncustomers_addressed != "" ? (
                idea?.noncustomers_addressed
              ) : (
                <i className="text-center small">No value added</i>
              )}
            </p>
          </div>
          <div>
            <p className="small text-muted text-uppercase mb-0">Eliminated</p>
            <p>
              {idea?.eliminated != "" ? (
                idea?.eliminated
              ) : (
                <i className="text-center small">No value added</i>
              )}
            </p>
          </div>
          <div>
            <p className="small text-muted text-uppercase mb-0">Created</p>
            <p>
              {idea?.created != "" ? (
                idea?.created
              ) : (
                <i className="text-center small">No value added</i>
              )}
            </p>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal: Add and Edit ideas */}
      <Modal
        show={showIdeaForm}
        onHide={() => {
          setShowIdeaForm(false);
          resetIdea();
        }}
      >
        <Modal.Header closeButton>
          <div>
            <h5>{editing ? "Edit" : "Add"} idea</h5>
            <p className="mb-0 small text-muted">
              Marked (<span className="text-danger">*</span>) fields are
              mandatory.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group>
              <Form.Label>
                Title <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter idea title"
                value={idea?.title}
                onChange={(e) => {
                  setIdea((prev) => {
                    let newValues = { ...prev };
                    newValues["title"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Select Path</Form.Label>
              <div className="d-flex align-items-center justify-content-center">
                {idea?.path_name && (
                  <div className="mr-2">
                    <img
                      className="img-fluid"
                      src={`https://igesia-static-1bt.s3.amazonaws.com/images/bboa/${idea?.path_name}.png`}
                      style={{
                        height: 40,
                        width: 40,
                      }}
                    />
                  </div>
                )}
                <Select
                  placeholder="Select Path"
                  options={paths}
                  defaultValue={
                    idea?.path_name
                      ? paths.filter(
                          (item) => `${item.value}` === `${idea?.path_name}`
                        )
                      : null
                  }
                  name="path_name"
                  onChange={(e) => {
                    setIdea((prev) => {
                      let newValues = { ...prev };
                      newValues["path_name"] = e.value;
                      return newValues;
                    });
                    setError(false);
                  }}
                  className="w-100"
                ></Select>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Noncustomers Addressed</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter noncustomers addressed"
                value={idea?.noncustomers_addressed}
                onChange={(e) => {
                  setIdea((prev) => {
                    let newValues = { ...prev };
                    newValues["noncustomers_addressed"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Value Proposition</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter value proposition"
                value={idea?.value_proposition}
                onChange={(e) => {
                  setIdea((prev) => {
                    let newValues = { ...prev };
                    newValues["value_proposition"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Eliminated</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter eliminated"
                value={idea?.eliminated}
                onChange={(e) => {
                  setIdea((prev) => {
                    let newValues = { ...prev };
                    newValues["eliminated"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Created</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter created"
                value={idea?.created}
                onChange={(e) => {
                  setIdea((prev) => {
                    let newValues = { ...prev };
                    newValues["created"] = e.target.value;
                    return newValues;
                  });
                  setError(false);
                }}
              />
            </Form.Group>

            {error && (
              <p className="text-danger small">
                <b>Note:</b> Title cannot be empty
              </p>
            )}

            <div className="d-flex">
              <Button
                variant="primary"
                type="submit"
                className="mr-2 text-uppercase"
                size="sm"
                disabled={sending}
              >
                {sending ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Sending...
                  </span>
                ) : (
                  <span>{editing ? "Update" : "Add"}</span>
                )}
              </Button>
              <Button
                variant="none"
                className="text-uppercase shadow-sm"
                size="sm"
                onClick={() => {
                  setShowIdeaForm(false);
                  resetIdea();
                }}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal: Delete idea */}
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          resetIdea();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Are you sure you want to delete idea, <b>"{idea?.title}"</b>?
            </p>
            <p className="small text-muted">
              <b>Note:</b> Once deleted you cannot retrive.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              variant="danger"
              size="sm"
              className="mr-2"
              onClick={handleDeleteIdea}
              disabled={deleting}
            >
              {deleting ? (
                <span>
                  <Spinner animation="border" size="sm" /> Deleting...
                </span>
              ) : (
                <span>Yes, Delete</span>
              )}
            </Button>
            <Button
              variant="none"
              size="sm"
              className="shadow-sm"
              onClick={() => {
                setShowDeleteModal(false);
                resetIdea();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal: Reorder ideas */}
      <Modal
        centered
        show={reorderIdeaModal}
        onHide={() => setReorderIdeaModal(false)}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="mb-0">Reorder Ideas</h5>
            <p className="mb-0 text-muted">
              <b>Hint:</b> Drag list elements in desired order
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {ideasList?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={
                            snapshot.isDragging
                              ? "shadow border rounded"
                              : "border rounded"
                          }
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="d-flex align-items-center">
                            <a
                              className="py-2 px-1"
                              style={{
                                cursor: "grab",
                                textDecoration: "none",
                              }}
                            >
                              <FontAwesomeIcon icon={faGripVertical} />
                            </a>
                            <p className="mb-0 py-2 px-1">
                              {item.order_index}. {item.title}
                            </p>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Body>
      </Modal>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
});

export default connect(mapStateToProps)(IdeationTable);
