import React from "react";
import Publish from "./Publish";
import Sidebar from ".././Sidebar/Index";

const AddBlogManager = (props) => {
  return (
    <Publish />
  );
};
export default AddBlogManager;
