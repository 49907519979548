import React, { useMemo } from "react";
import { Button, Card } from "react-bootstrap";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router";
import { Skeleton } from "@material-ui/lab";

import * as TimelineStyle from "./timeline.module.css";
import moment from "moment";

export default function Blogs() {
  const history = useHistory();

  const [blogsSnapshot, loadingBlogs, errorBlogs] = useCollection(
    firebase.firestore().collection("blogs")
  );

  const blogs = useMemo(() => {
    return blogsSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [blogsSnapshot]);

  const LoadingSkeleton = () => {
    return [1, 2, 3].map((item) => (
      <div className="col-md-4 pl-0" key={item}>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded"
          height={150}
        />
        <Skeleton
          animation="wave"
          className="bg-muted rounded mt-1"
          height={24}
        />
        <Skeleton animation="wave" className="bg-muted rounded" height={24} />
        <Skeleton
          animation="wave"
          className="bg-muted rounded w-50"
          height={20}
        />
      </div>
    ));
  };

  return (
    <>
      <div className="col-md-12  mb-4">
        <div className="gradient-box bg-gradient-light pb-5">
          <div className="row mb-5">
            <div className="col-md-6">
              <h3 className="mb-3  text-s-7 text-blue">
                Share your latest updates.
              </h3>
              <p className="text-s-6">
                <span style={{ color: "#333333" }}>
                  Keep you community engaged with blogs and updates. Upsell your courses and events.{" "}
                </span>
                <br /> <br />
                {/* <span style={{ color: "#888888" }}>
                  {" "}
                </span> */}
              </p>
              <a
                // onClick={handleShow}
                href="/blogs"
                style={{
                  background: "rgb(118, 60, 247)",
                  borderRadius: "12px",
                }}
                className="btn text-white decoration-none font-weight-bold mb-3"
              >
                Explore Blogs
              </a>
              <a
                // onClick={handleShow}
                href=""
                style={{

                }}
                className="btn text-purple decoration-none font-weight-bold mb-3"
              >
                Request a Demo
              </a>
            </div>
            <div className="col-md-6">
              <div className="text-center">
                {/* <img
                  style={{ marginRight: "0px" }}
                  src="https://igesia.co/assets/igesia-event-registration-mockup.png"
                  width="100%"
                /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <h5 className="font-weight-bold ">Discover blogs on igesia</h5>
            </div>
            <div className="col-md-12">
              <div className="d-flex" style={{ overflowX: "scroll" }}>
                {loadingBlogs && <LoadingSkeleton />}

                {blogs && blogs
                  .sort((a, b) => b.timestamp - a.timestamp)
                  .slice(0, 5)
                  .map((blog, key) => (
                    <>
                      {blog?.is_published ?
                        <div className="col-md-4 pl-0" key={key}>
                          <div
                            className="card event-card border-0  h-100"
                            onClick={() => history.push(`blog/${blog.id}`)}
                            title={`Project: ${blog.heading}`}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="rounded-top"
                              style={{
                                backgroundImage: `url(${blog.img_src})`,
                                height: 150,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                className: "card-img-top bg-gradient-purple",
                              }}
                            />
                            <div className="card-body bg-light">
                              <h3 className="text-s-7 text-dark">
                                {blog.heading}
                              </h3>
                              <p className="font-weight-bold small mb-2 text-deep-purple">
                                {moment(blog.timestamp).format("Do MMM YY")}
                              </p>
                            </div>
                          </div>
                        </div> : <></>}
                    </>
                  ))}
              </div>
            </div>
            <div className="col-md-12 text-center">
              <Button
                className="mt-5"
                variant="none"
                size="sm"
                onClick={() => history.push("events")}
              >
                <span className="text-purple font-weight-bold ">
                  SEE MORE BLOGS
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
