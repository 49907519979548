import React from "react";
import { Container, Row, Col, Card, Button, Table } from "react-bootstrap";
import {
  ShoppingBag,
  MessageCircle,
  UserPlus,
  Layers,
  ShoppingCart,
  DollarSign,
  ArrowDown,
  ArrowUp,
} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faComments,
  faPen,
  faPodcast,
  faHeadphonesAlt,
  faUsers,
  faClock,
  faShare,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { memo } from "react";

const PodcastStats = () => {
  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faHeadphonesAlt} size="3x" />
                  <h5 className="my-3">Listeners</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={12} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={45} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={145} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faUsers} size="3x" />
                  <h5 className="my-3">Subscription</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={8} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={74} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={189} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faClock} size="3x" />
                  <h5 className="my-1">Time Played(Minutes)</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={11} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={45} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={202} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="text-white my-3" md="6" sm="12">
            <Card className="browser-widget">
              <Card.Body className="media card-body d-flex align-items-center justify-content-around ">
                <div className="d-flex flex-column align-items-center col-4">
                  <FontAwesomeIcon icon={faShare} size="3x" />
                  <h5 className="my-3">Shares</h5>
                </div>
                <div className="media-body align-self-center col-8">
                  <Col>
                    <p>Today</p>
                    <h4 className="text-white">
                      <span>
                        <CountUp end={16} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Week</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={32} />
                      </span>
                    </h4>
                  </Col>
                  <Col>
                    <p>This Month</p>
                    <h4 className="text-white">
                      <span className="counter text-white">
                        <CountUp end={121} />
                      </span>
                    </h4>
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="d-flex">
          <Button
            variant="link"
            className=" my-auto ml-auto"
            href="/allengagement"
          >
            View All
          </Button>
        </div>
      </Container>
      #table below podcast
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <Card.Body>
                <div className="responsive-tbl">
                  <div className="item">
                    <div className="table-responsive product-list">
                      <Table borderless className="text-white">
                        <thead>
                          <tr>
                            <th className="f-22">#</th>
                            <th>{"Name"}</th>
                            <th>{"Duration(Minutes)"}</th>
                            <th>{"Daily Listeners"}</th>
                            <th>{"Listen"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>1</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Gastropod
                            </td>
                            <td style={{ verticalAlign: "middle" }}>232</td>
                            <td style={{ verticalAlign: "middle" }}>13</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button variant="outline-success" size="sm">
                                Listen
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Overhead: Nat Geo
                            </td>
                            <td style={{ verticalAlign: "middle" }}>4</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button variant="outline-success" size="sm">
                                Listen
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Blue Ocean
                            </td>
                            <td style={{ verticalAlign: "middle" }}>13</td>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button variant="outline-success" size="sm">
                                Listen
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>4</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Code Switch
                            </td>
                            <td style={{ verticalAlign: "middle" }}>5</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button variant="outline-success" size="sm">
                                Listen
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(PodcastStats);
