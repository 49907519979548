import React from "react";

import "./NoteView.css";
import ReactHtmlParser from "react-html-parser";
import Swal from "sweetalert2";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const NoteView = ({
  SelectedNote,
  setNoteEdit,
  setNoteWriteMode,
  setSelectedNote,
  currentUser,
}) => {
  const DeleteNote = (id) => {
    Swal.fire({
      title: "Delete Note?",
      showDenyButton: true,
      confirmButtonText: `Delete`,
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedNote(null);
        firebase
        .firestore()
        .collection("notesApp")
        .doc(currentUser.uid)
        .collection("notes")
          .doc(id)
          .delete();
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <div className="text-left  mx-5 mb-2 mt-2">
      <div className="my-3 text-center text-light font-13">
        {SelectedNote.created_at?.toDate().toDateString()}
        <span className="ml-2">
          {SelectedNote.created_at?.toDate().toLocaleTimeString()}
        </span>
      </div>
      <div>
        <div className="font-18 my-2 mb-3 text-white border-bottom">
          {SelectedNote.title}
        </div>
        <div className="NoteView-data text-light blackScrollBar ">
          {ReactHtmlParser(SelectedNote.content)}
        </div>
      </div>
      <div className="NoteView-action">
        <span
          className="btn btn-sm btn-primary mx-2 "
          onClick={() => {
            setNoteEdit(SelectedNote);
            setNoteWriteMode(true);
            setSelectedNote(null);
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit
        </span>
        <span
          className="btn btn-sm btn-danger mx-2"
          onClick={() => DeleteNote(SelectedNote.id)}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          Delete
        </span>
      </div>
    </div>
  );
};

export default NoteView;
