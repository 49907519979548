import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useHistory } from "react-router";
import { Skeleton } from "@material-ui/lab";
import parse from "html-react-parser";
import * as TimelineStyle from "./timeline.module.css";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import moment from "moment";

export default function Events() {
  const history = useHistory();

  const [eventList, setEventList] = useState([]);

  const [eventsValue, eventsLoading, eventsError] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("is_published", "==", true),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (eventsValue) {
      let data = [];
      eventsValue?.docs?.map((e) => {
        if (e.data()?.type === 1) {
        } else data.push({ id: e.id, ...e.data() });
      });
      setEventList([...data]);
    }
  }, [eventsValue]);

  const LoadingSkeleton = () => {
    return [1, 2, 3].map((item) => (
      <div className="col-md-4 pl-0" key={item}>
        <Skeleton
          animation="wave"
          variant="rect"
          className="bg-muted rounded"
          height={150}
        />
        <Skeleton
          animation="wave"
          className="bg-muted rounded mt-1"
          height={24}
        />
        <Skeleton animation="wave" className="bg-muted rounded" height={24} />
        <Skeleton
          animation="wave"
          className="bg-muted rounded w-50"
          height={20}
        />
      </div>
    ));
  };

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  return (
    <>
      <div className="col-md-12  mb-4">
        <div className="gradient-box bg-gradient-light pb-5">
          <div className="row mb-5">
            <div className="col-md-6">
              <h3 className="mb-3  text-s-7 text-blue">
                Interact and engage in discussion rooms.
              </h3>
              <p className="text-s-6">
                <span style={{ color: "#333333" }}>
                Create discussion rooms and offer rich and meaningful networking opportunities.{" "}
                </span>
                <br /> <br />
                <span style={{ color: "#888888" }}>
                Rooms can be connected to events and course platforms and assigned to all your participants or cohorts. 
                Native productivity apps including in-built video to improve quality of engagement.{" "}
                </span>
              </p>
              <a
                // onClick={handleShow}
                href="/rooms"
                style={{
                  background: "rgb(118, 60, 247)",
                  borderRadius: "12px",
                }}
                className="btn text-white decoration-none font-weight-bold mb-3"
              >
                Explore Rooms
              </a>
              <a
                // onClick={handleShow}
                href="https://console.igesia.co"
                style={{
                  
                }}
                className="btn text-purple decoration-none font-weight-bold mb-3"
              >
                Try Rooms
              </a>
            </div>
            <div className="col-md-6">
              <div className="text-center">
                <img
                  style={{ marginRight: "0px" }}
                  src="https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/Igesia-Full-App.png"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
