import React, { useEffect, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClipboardList,
  faTasks,
  faVideo,
  faFile,
  faRedo,
  faSortAlphaDownAlt,
  faSortAlphaUpAlt,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";

import {
  ADMIN_GET_MODULES,
  ADMIN_PROGRESS_REPORT_GET,
  API_BASE_URL,
} from "../../../../config";
import { auth } from "../../../../firebase";

function Report2(props) {
  // CONSTANTS
  const { currentEvent } = props;

  // STATES
  const [fetchingReportList, setFetchingReportList] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [filter, setFilter] = useState("firstNameAsc");
  const [fetchingModules, setFetchingModules] = useState(false);
  const [modules, setModules] = useState([]);
  const [progressListFilter, setProgressListFilter] = useState("");

  // FUNCTIONS
  const SubModuleIcon = ({ type, className }) => {
    const icon =
      type === "schedule"
        ? faCalendarAlt
        : type === "assignment"
        ? faClipboardList
        : type === "quiz"
        ? faTasks
        : type === "lecture"
        ? faVideo
        : faFile;
    return <FontAwesomeIcon icon={icon} className={className} />;
  };

  const getItemDetails = (parent_id, id) => {
    const moduleData = modules?.filter((e) => e.id === parent_id)[0];
    const subModuleData = moduleData?.sub_modules?.filter(
      (e) => e.id === id
    )[0];

    return (
      <span>
        <SubModuleIcon type={subModuleData?.type} /> {subModuleData?.title}
      </span>
    );
  };

  const handleFetchModules = async () => {
    setFetchingModules(true);
    try {
      const response = await axios.post(`${API_BASE_URL}${ADMIN_GET_MODULES}`, {
        event_id: currentEvent?.id,
        user_id: auth?.currentUser?.uid,
      });

      if (response.status === 200) {
        const { data } = response.data;

        setModules(data);
        console.log("reportList: ", reportList);
        console.log("Modules: ", data);
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  const handleFetchReportList = async (cbFetching, cbList, type) => {
    try {
      cbFetching(true);
      const response = await axios.get(
        `${API_BASE_URL}${ADMIN_PROGRESS_REPORT_GET}?user_id=${auth?.currentUser?.uid}&product_id=${currentEvent?.id}`
      );

      if (response.status === 200) {
        cbFetching(false);
        cbList(response?.data?.data);
      } else {
        cbFetching(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching report list: `, error.message);
      cbFetching(false);
    }
  };

  const handleRefresh = () => {
    // Fetch: Report list
    handleFetchReportList(setFetchingReportList, setReportList);
  };

  function ExportToExcel(type, fn, dl) {
    if (!window.XLSX) {
      alert("Please try again later!");
      return;
    }

    var elt = document.getElementById("report_table");
    var wb = window.XLSX.utils.table_to_book(elt, { sheet: "Report" });
    return dl
      ? window.XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : window.XLSX.writeFile(
          wb,
          fn || `Report-${new Date().toJSON()}.` + (type || "xlsx")
        );
  }

  useEffect(() => {
    // Fetch: Report list
    handleFetchReportList(setFetchingReportList, setReportList);

    // Fetch: Modules list
    handleFetchModules();
  }, [currentEvent]);

  return (
    <React.Fragment>
      <div className="py-3" style={{ height: "100vh", overflowY: "auto" }}>
        <div className="px-3 mb-3">
          <h4 className="text-white">
            {currentEvent?.type === 1 ? "Class Report" : "Report"}
          </h4>
        </div>
        <div className="text-white">
          <div className="d-flex align-items-end justify-content-between px-3 pt-2 pb-3">
            <div className="d-flex flex-column justify-content-start">
              <Dropdown className="w-100">
                <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                  <span>
                    {filter === "" ? (
                      <span>
                        <FontAwesomeIcon icon={faFilter} className="mr-2" />
                        Order by
                      </span>
                    ) : filter === "firstNameDesc" ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faSortAlphaDownAlt}
                          className="mr-2"
                        />
                        First name (Z - A)
                      </span>
                    ) : filter === "firstNameAsc" ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faSortAlphaUpAlt}
                          className="mr-2"
                        />
                        First name (A - Z)
                      </span>
                    ) : filter === "lastNameDesc" ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faSortAlphaDownAlt}
                          className="mr-2"
                        />
                        Last name (Z - A)
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faSortAlphaUpAlt}
                          className="mr-2"
                        />
                        Last name (A - Z)
                      </span>
                    )}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    style={
                      filter === "firstNameAsc"
                        ? { backgroundColor: "rgba(51,51,51,0.25)" }
                        : {}
                    }
                    onClick={() => setFilter("firstNameAsc")}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} className="mr-2" />
                    First name (A - Z)
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={
                      filter === "firstNameDesc"
                        ? { backgroundColor: "rgba(51,51,51,0.25)" }
                        : {}
                    }
                    onClick={() => setFilter("firstNameDesc")}
                  >
                    <FontAwesomeIcon
                      icon={faSortAlphaDownAlt}
                      className="mr-2"
                    />
                    First name (Z - A)
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={
                      filter === "lastNameDesc"
                        ? { backgroundColor: "rgba(51,51,51,0.25)" }
                        : {}
                    }
                    onClick={() => setFilter("lastNameDesc")}
                  >
                    <FontAwesomeIcon
                      icon={faSortAlphaDownAlt}
                      className="mr-2"
                    />
                    Last name (A - Z)
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={
                      filter === "lastNameAsc"
                        ? { backgroundColor: "rgba(51,51,51,0.25)" }
                        : {}
                    }
                    onClick={() => setFilter("lastNameAsc")}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} className="mr-2" />
                    Last name (Z - A)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-end">
              {fetchingReportList && (
                <div className="mr-3">
                  <p className="mb-0">Fetching...</p>
                </div>
              )}

              <Button
                size="sm"
                variant="outline-light"
                onClick={() => ExportToExcel("xlsx")}
                className="mr-2"
                disabled={fetchingReportList}
              >
                <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                Report
              </Button>

              <Button
                variant="light"
                size="sm"
                onClick={handleRefresh}
                disabled={fetchingReportList}
              >
                {fetchingReportList ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <FontAwesomeIcon icon={faRedo} />
                )}{" "}
                Refresh
              </Button>
              <div className="d-flex flex-column justify-content-end"></div>
            </div>
          </div>

          {fetchingReportList && (
            <p className="text-center p-3 mb-0">
              <Spinner animation="border" size="sm" /> Kindly wait as we
              generate your report. Please do not navigate away from this page.
            </p>
          )}

          <div className="w-100 px-3" style={{ overflowX: "scroll" }}>
            <table
              className="table table-sm text-white border-bottom border-right"
              id="report_table"
            >
              <tr className="small">
                {[
                  "#",
                  "Name",
                  "Email",
                  "Total items",
                  "Completed items",
                  "Progress %",
                ]?.map((element) => (
                  <td
                    key={element}
                    rowspan="2"
                    className="text-center border-left"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {element}
                  </td>
                ))}
                {currentEvent?.custom_form?.map((element) => (
                  <td
                    key={element.id}
                    rowspan="2"
                    className="text-center border-left"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {element.label}
                  </td>
                ))}
                {modules
                  ?.sort((a, b) => a.order_index - b.order_index)
                  ?.map((element) => (
                    <th
                      colspan={String(element?.sub_modules?.length)}
                      scope="colgroup"
                      className="text-center border-left"
                    >
                      {element.name}
                    </th>
                  ))}
              </tr>
              <tr className="small">
                {modules
                  ?.sort((a, b) => a.order_index - b.order_index)
                  ?.map((element) =>
                    element?.sub_modules?.map((subModule) => (
                      <th
                        scope="col"
                        className="text-center border-left"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <SubModuleIcon type={subModule?.type} />{" "}
                        {`${subModule?.title} (${
                          subModule?.type?.slice(0, 1).toUpperCase() +
                          subModule?.type?.slice(1)
                        })`.toString()}
                      </th>
                    ))
                  )}
              </tr>
              {reportList
                ?.sort((a, b) => {
                  if (filter === "") {
                    return true;
                  } else if (filter === "firstNameAsc") {
                    return a.firstName.localeCompare(b.firstName);
                  } else if (filter === "firstNameDesc") {
                    return b.firstName.localeCompare(a.firstName);
                  } else if (filter === "lastNameAsc") {
                    return a.lastName.localeCompare(b.lastName);
                  } else if (filter === "lastNameDesc") {
                    return b.lastName.localeCompare(a.lastName);
                  } else {
                    return false;
                  }
                })
                ?.map((item, key) => (
                  <tr
                    className="border-top border-secondary align-items-center pointer small"
                    key={key}
                  >
                    <td
                      className="border-left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {key + 1}
                    </td>
                    <td
                      className="border-left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td
                      className="border-left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {item?.email}
                    </td>
                    <td
                      className="border-left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {item?.total_items}
                    </td>
                    <td
                      className="border-left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {item?.completed_items}
                    </td>
                    <td
                      className="border-left"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {item?.progress_percentage}
                    </td>
                    {currentEvent?.custom_form?.map((element) => (
                      <td
                        key={element.id}
                        className="border-left"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {item[element.field_name] ?? " - "}
                      </td>
                    ))}
                    {modules
                      ?.sort((a, b) => a.order_index - b.order_index)
                      ?.map((element) =>
                        element?.sub_modules?.map((subModule) => (
                          <td
                            className="border-left"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {item?.progress?.filter(
                              (ele) =>
                                ele.id === subModule.id &&
                                ele.parent_id === subModule.parent_id
                            )?.length
                              ? item?.progress?.filter(
                                  (ele) =>
                                    ele.id === subModule.id &&
                                    ele.parent_id === subModule.parent_id
                                )[0]?.progress_status
                                ? "✅ Done"
                                : "Not done"
                              : "Not done"}
                          </td>
                        ))
                      )}
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(Report2);
