import React,{ useState, useEffect} from "react";
import { Card, Image, Button } from "react-bootstrap";
import { useHistory } from "react-router";

import { faEllipsisH, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as TimelineStyle from "../Timeline/timeline.module.css";
import EventImg from "../Timeline/assets/event.png";
import CourseImg from "../Timeline/assets/course.png";
import ConsultationImg from "../Timeline/assets/consultation.png";

import { consultList } from "../data";
import TimelineCover from "../Timeline/TimelineCover";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";

export default function Consultations() {
  const history = useHistory();

  const [clinics, clinicsLoading, clinicsError] = useCollection(
    firebase.firestore().collection("clinics"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [allClinics, setAllClinics] = useState([]);

  useEffect(() => {
    if (clinics) {
      let data = [];
      clinics?.docs?.filter(item => !item?.data()?.isDemo)?.map((e) => {
        data.push({ id: e.id, ...e.data() });
      });
      setAllClinics([...data]);
    }
  }, [clinics]);

  return (
    <div className="container-fluid px-0 bg-graphite full-screen">
      {/* Timeline cover starts */}
      <TimelineCover />
      {/* Timeline cover ends */}
      <div className="container py-3 py-md-4">
        <h2 className="text-white font-weight-bold">Consultations</h2>

        <div className="row mt-3">
        {console.log(allClinics)}
          {/* Course card */}
          {allClinics.map((consult, key) => (
            <div className="col-md-4 mb-2" key={key}>
              <Card
                className={`h-100 ${TimelineStyle.services_cards}`}
                onClick={() => history.push(`consultation/${consult.id}`)}
                style={{ cursor: "pointer" }}
                title={`Course: ${consult?.clinic_details?.EN?.clinic_name}`}
              >
                <div
                  className="img-fluid  shadow-sm"
                  style={{
                    height: 200,
                    backgroundImage: `url(${consult?.clinic_details?.featured_image})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                //title={selectedProfile?.name}
                />
                <Card.Body className="py-2 px-3">
                  <p className="small text-uppercase text-success mb-1">
                    Consult
                  </p>
                  <p className="font-weight-bold text-white mb-1">
                    {consult?.clinic_details?.EN?.clinic_name}
                  </p>
                  <p className="font-weight-bold small mb-0 text-warning">
                    {consult.date}
                  </p>
                </Card.Body>
              </Card>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}
