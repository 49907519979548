import React, { useRef, useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import EventChat from "./EventChat";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SinglePage from "./Templates/SinglePage";
import MultiplePages from "./Templates/MultiplePages";
toast.configure();

function Event({ currentLanguage, changeLanguage, darkTheme }) {
  const { hash, pathname } = useLocation();
  const history = useHistory();
  const { id, page_slug } = useParams();
  const [currentEvent, setCurrentEvent] = useState({});
  const current_user = firebase.auth().currentUser;
  const [showChat, setShowChat] = useState(false);

  const overviewRef = useRef(null);
  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("slug", "==", `${id}`)
  );

  useEffect(() => {
    if (eventSnapshot) {
      setCurrentEvent({
        ...eventSnapshot?.docs[0]?.data(),
        id: eventSnapshot?.docs[0]?.id,
      });
    }
  }, [eventSnapshot, loadingEvent]);

  const [snapshotPage, loadingPage, errorPage] = useCollection(
    firebase
      .firestore()
      .collection(`events/${eventSnapshot?.docs[0]?.id}/pages`)
      .where("slug", "==", page_slug)
  );
  const page_details = useMemo(() => {
    return { ...snapshotPage?.docs[0]?.data(), id: snapshotPage?.docs[0]?.id };
  }, [snapshotPage]);

  useEffect(async () => {
    if (current_user && !loadingEvent && currentEvent?.id != null) {
      const querySnapshot = await firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/users`)
        .where("email", "==", current_user.email)
        .get();

      if (querySnapshot?.empty) {
        toast.info(
          `Hello ${current_user.email}, looks like you are not registered for this event`,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        history.push("./complete-registration");
      }
    }
  }, [current_user, currentEvent, loadingEvent]);

  return (
    <div className="h-100" ref={pageRef}>
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            style={{ overflow: "scroll" }}
            data-theme={darkTheme ? "dark" : "light"}
          >
            <div className="px-0">
              <div className="" id="#overview" ref={overviewRef}>
                <div className="">
                  {!loadingPage &&
                  page_details &&
                  parseInt(page_details?.type) === 0 ? (
                    <SinglePage
                      featured_image={page_details?.featured_image}
                      content={page_details?.content}
                    />
                  ) : parseInt(page_details?.type) === 1 ? (
                    <MultiplePages
                      content={page_details?.content}
                      pageId={page_details?.id}
                      eventId={currentEvent?.id}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat event_id={currentEvent?.id} />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
  }),
  { changeLanguage }
)(Event);
