import { Card, CardContent, CardHeader } from "@material-ui/core";
import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import firebase from "firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const data = [{name: 'Page A', uv: 400, amt: 2400}];

const PatientGraph = () => {
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(true);

  const clinicId = useSelector((state) => state.clinicId);
  const clinicDetails = useSelector((state) => state.clinicDetails);

  let PatientRef = firebase
    .firestore()
    .collection("clinics")
    .doc(clinicId)
    .collection("users");

  let date = new Date();

  const getPatientNumber = async (d) => {
    let response = await PatientRef.where(
      "created_date",
      "==",
      d.toLocaleDateString()
    )
      .get()
      .then((doc) => {
        let data = {};
        // patientData.shift()
        if (clinicDetails.type === 1) {
          data = {
            date: moment(d).format("DD MMM, YY"),
            clients: doc.docs.length,
          };
        } else {
          data = {
            date: moment(d).format("DD MMM, YY"),
            patients: doc.docs.length,
          };
        }
        return data;
      });
    return response;
  };
  const [patientDataDays, setPatientDataDays] = useState(7);

  const fetchData = async () => {
    setLoading(true);
    let tempPatientData = [];
    tempPatientData.push(await getPatientNumber(date));
    for (let i = 1; i < patientDataDays; i++) {
      const newDate = new Date(date.setDate(date.getDate() - 1));

      console.log(newDate);
      tempPatientData.unshift(await getPatientNumber(newDate));
    }
    console.log(new Date().toLocaleDateString());
    console.log(tempPatientData);

    setPatientData(tempPatientData);
    setLoading(false);
  };

  useEffect(() => {
    // PatientRef.get().then(doc=>{
    //   doc.docs.map(patient=>{
    //     PatientRef.doc(patient.id).update({
    //       created_date: new Date(patient.data().timestamp?.seconds *1000).toLocaleDateString()
    //     })
    //   })
    // })
    fetchData();
  }, [patientDataDays]);

  return (
    <Card>
      <CardHeader
        className="bg-dark text-white"
        title={
          <>
            {clinicDetails.type === 1 ? "Clients" : "Patients"} Graph
            <select
              className="from-control float-right small"
              defaultValue={7}
              onChange={(e) => setPatientDataDays(e.target.value)}
            >
              <option value={7}>Last 7 days</option>
              <option value={15}>Last 15 days</option>
              <option value={30}>Last 30 days</option>
            </select>
          </>
        }
      />

      {
        <CardContent className="text-center overflow-auto bg-dark text-light">
          {loading ? (
            <div className="text-center py-5 my-5">
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </div>
          ) : (
            <div className="d-flex justify-content-center ">
              <LineChart
                width={900}
                height={300}
                data={patientData}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              >
                <Line
                  type="monotone"
                  dataKey={clinicDetails.type === 1 ? "clients" : "patients"}
                  stroke="#BA135D"
                />
                <CartesianGrid stroke="#fff" strokeDasharray="5 5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
              </LineChart>
            </div>
          )}
        </CardContent>
      }
    </Card>
  );
};

export default PatientGraph;
