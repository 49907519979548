import React, { useEffect, useState, useMemo } from "react";
import TableCell from "./TableCell";
import { connect } from "react-redux";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  Button,
  Modal,
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  setBuyerRows,
  setBuyerHeader,
} from "../../../../store/actions/actions";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ReactExport from "react-export-excel";

function Table(props) {
  const {
    darkTheme,
    currentEvent,
    graphId,
    buyerHeaders,
    buyerRows,
    setBuyerHeader,
    setBuyerRows,
  } = props;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [showAddStepModal, setShowAddStepModal] = useState(false);
  const [newStep, setNewStep] = useState("");
  const [newStepDescription, setNewStepDescription] = useState("");

  const [editStageModal, setEditStageModal] = useState(false);
  const [editStageModalValue, setEditStageModalValue] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [reorderStagesModal, setReorderStagesModal] = useState(false);
  const [showProTips, setShowProTips] = useState(true);

  const [stepCollection, stepCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
      )
  );

  const stagesList = useMemo(() => {
    return stepCollection?.docs
      ?.sort((a, b) => a?.data()?.order_index - b?.data()?.order_index)
      ?.map((e, key) => {
        return {
          ...e.data(),
          id: e.id,
          key: key,
        };
      });
  }, [stepCollection]);

  useEffect(() => {
    if (stagesList) {
      setBuyerHeader([...stagesList]);
    }
  }, [stagesList]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit`)
      .doc("0")
      .onSnapshot((snapshot) => {
        if (snapshot.data()?.rows) {
          setBuyerRows(snapshot.data().rows);
        }
      });
  }, []);

  const head = [
    {
      id: 0,
      name: " ",
      order_index: 0,
    },
    ...buyerHeaders,
  ];

  const rows = [...buyerRows];

  // Function: Update order when drag ends
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return;
    }

    let sourceIndex = stagesList[result.source.index];
    let destinationIndex = stagesList[result.destination.index];
    if (result.destination) {
      let destination_order = destinationIndex.order_index;
      let source_order = sourceIndex.order_index;
      let destination_key = destinationIndex.key;
      let source_key = sourceIndex.key;
      let total_length = stagesList?.length;

      if (source_order === destination_order) {
        if (source_key < destination_key) {
          destination_order--;
        }
        if (source_key > destination_key) {
          destination_order--;
        }
      }

      if (destination_order > total_length) {
        destination_order = total_length;
      }
      if (source_order > total_length) {
        source_order = total_length;
      }

      firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
        )
        .doc(sourceIndex.id)
        .set(
          {
            order_index: destination_order,
          },
          {
            merge: true,
          }
        );
      firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
        )
        .doc(destinationIndex.id)
        .set(
          {
            order_index: source_order,
          },
          {
            merge: true,
          }
        );
    }
  };

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#efefef" : "white",
    padding: grid,
    width: "100%",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#efefef" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // Function: Add Stage
  const addStepHandler = (e) => {
    e.preventDefault();

    let user = firebase.auth().currentUser;
    let data = {
      name: newStep,
      description: newStepDescription,
      created_at: new Date(),
      created_by: {
        name: user.displayName,
        email: user.email,
        id: user.uid,
      },
      order_index: buyerHeaders.length + 1,
    };

    if (newStep) {
      let docRef = firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
        );

      docRef.add(data);

      setNewStep("");
      setNewStepDescription("");
      setShowAddStepModal(false);
    }
  };

  // Function: Edit stage details
  const editStageNameHandler = (e) => {
    e.preventDefault();

    let data = {
      name: editStageModalValue.name,
      description: editStageModalValue.description,
      updated_at: new Date(),
    };
    if (editStageModalValue) {
      let docRef = firebase
        .firestore()
        .collection(
          `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
        );
      docRef.doc(`${editStageModalValue.id}`).update(data, { merge: true });

      setEditStageModalValue(null);
      setEditStageModal(false);
    }
  };

  // Function: Delete stage
  const deleteStageHandler = (stage_id) => {
    setDeleting(true);

    let docRef = firebase
      .firestore()
      .collection(
        `events/${currentEvent?.id}/bboa/${graphId}/buyer_exp_audit/0/columns`
      );

    docRef
      .doc(`${stage_id}`)
      .delete()
      .then(() => {
        setEditStageModal(false);
        setEditStageModalValue(null);
        setDeleting(false);

        toast.dark("Stage deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.error("Error deleting stage: ", error);

        toast.warning("Error deleting stage " + error, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <Card
        className={
          darkTheme
            ? "bg-dark shadow-sm border border-dark mb-3"
            : "bg-white shadow-sm border border-light mb-3"
        }
      >
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0 text-uppercase">
            4. EXPLORATION: BUYER-USER UTILITY MAP
          </h5>

          <div>
            <Button
              size="sm"
              className="text-uppercase mr-2"
              onClick={() => setReorderStagesModal(true)}
            >
              Reorder stages
            </Button>
            <Button
              size="sm"
              variant="primary"
              onClick={() => setShowAddStepModal(true)}
            >
              <span className="text-uppercase">Add Stage</span>
            </Button>

            <ExcelFile
              element={
                <Button size="sm" className="text-uppercase ml-2">
                  <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                  <span>Export</span>
                </Button>
              }
            >
              <ExcelSheet
                data={rows ?? []}
                name="Exploration: Buyer-User Utility Map"
              >
                {[
                  "Utility levers Stages",
                  ...head.filter((data) => data?.name != " "),
                ]?.map((a, i) => (
                  <ExcelColumn
                    label={i === 0 ? a : a.name}
                    value={(item) => (i === 0 ? `${item.name} (${item.description})` : "")}
                  />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </div>
        </Card.Header>
        <Card.Body
          className="p-1"
          id="buyer-utility-map"
          style={{ overflowX: "auto", width: "100%" }}
        >
          <table className="table table-bordered">
            <thead className={darkTheme ? "thead-light" : "thead-dark"}>
              <tr className="d-flex">
                {head.map((item, index) => (
                  <th
                    scope="col"
                    key={item.id}
                    onClick={() => {
                      if (index > 0) {
                        setEditStageModal(true);
                        setEditStageModalValue(item);
                      }
                    }}
                    style={index === 0 ? { zIndex: 100 } : {}}
                    className={
                      index === 0
                        ? "col-5 p-0"
                        : head.length > 4
                        ? "col-2 p-0"
                        : "col p-0"
                    }
                  >
                    {index === 0 ? (
                      <div className="d-flex justify-content-center align-items-center h-100 w-100 bg-dark">
                        <span>Utility levers \ Stages</span>
                      </div>
                    ) : (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={item.name}>
                            {item.description
                              ? item.description
                              : "No description added"}
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="none"
                          className="d-flex justify-content-center align-items-center text-white h-100 w-100"
                        >
                          <span className="mr-1">{item.name}</span>
                        </Button>
                      </OverlayTrigger>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={darkTheme ? "text-light" : "text-dark"}>
              {rows.map((rowData, key) => {
                return (
                  <tr key={key} className="d-flex">
                    {head.map((headData, idx) => {
                      return (
                        <TableCell
                          headLength={head.length}
                          rowData={rowData}
                          headData={headData}
                          key={idx}
                        />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <>
            <Modal
              centered
              show={showAddStepModal}
              onHide={() => setShowAddStepModal(false)}
            >
              <Modal.Header
                closeButton
                className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
              >
                <Modal.Title>Add Stage</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className={darkTheme ? "bg-dark border-secondary" : "bg-light"}
              >
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Stage name</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter stage name"
                      value={newStep}
                      onChange={(e) => setNewStep(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter description"
                      value={newStepDescription}
                      onChange={(e) => setNewStepDescription(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <div className="d-flex">
                  <Button
                    size="sm"
                    className="text-uppercase mr-2"
                    variant="primary"
                    onClick={addStepHandler}
                  >
                    Add
                  </Button>
                  <Button
                    size="sm"
                    className="text-uppercase shadow-sm"
                    variant="light"
                    onClick={() => setShowAddStepModal(false)}
                  >
                    Discard
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </>

          <>
            <Modal
              centered
              show={editStageModal}
              onHide={() => setEditStageModal(false)}
            >
              <Modal.Header
                data-theme={darkTheme ? "dark" : "light"}
                closeButton
              >
                <Modal.Title>Edit Stage</Modal.Title>
              </Modal.Header>
              <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Stage name</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter stage name"
                      value={editStageModalValue?.name}
                      onChange={(e) => {
                        setEditStageModalValue((prev) => {
                          const newState = { ...prev, name: e.target.value };
                          return newState;
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter description"
                      value={editStageModalValue?.description}
                      onChange={(e) => {
                        setEditStageModalValue((prev) => {
                          const newState = {
                            ...prev,
                            description: e.target.value,
                          };
                          return newState;
                        });
                      }}
                    />
                  </Form.Group>
                </Form>

                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Button
                      className="mr-2 text-uppercase"
                      size="sm"
                      variant="primary"
                      onClick={editStageNameHandler}
                    >
                      Update
                    </Button>
                    <Button
                      className="mr-2 text-uppercase shadow-sm"
                      size="sm"
                      variant="light"
                      onClick={() => setEditStageModal(false)}
                    >
                      Discard
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="text-uppercase"
                      variant="danger"
                      size="sm"
                      onClick={() => deleteStageHandler(editStageModalValue.id)}
                      disabled={
                        deleting
                        //  ||
                        // editStageModalValue?.id != buyerHeaders?.length
                      }
                    >
                      {deleting ? (
                        <span>Deleting...</span>
                      ) : (
                        <span>Delete</span>
                      )}
                    </Button>
                  </div>
                </div>
                {/* {editStageModalValue?.id != buyerHeaders?.length && (
                  <p className="small mb-0 mt-1 text-muted">
                    <b>Note:</b> Deletion is only allowed for last added stage.
                  </p>
                )} */}
              </Modal.Body>
            </Modal>
          </>
        </Card.Body>
        <Card.Footer>
          <div className="d-flex align-items-center">
            <p className="mb-0 text-uppercase small">
              <b>Pro tips</b>
            </p>
            <p
              style={{ cursor: "pointer" }}
              className="mb-0 text-uppercase small text-muted ml-2"
              onClick={() => setShowProTips((prev) => !prev)}
              title={
                showProTips
                  ? "Click to hide pro tips"
                  : "Click to show pro tips"
              }
            >
              <b>({showProTips ? "Hide" : "Show"})</b>
            </p>
          </div>
          <ol
            className={showProTips ? "px-3 mb-1 pb-1" : "px-3 mb-1 pb-1 d-none"}
          >
            <li>
              <small>Add more stages and describe them</small>
            </li>
            <li>
              <small>Click on the cell to add weights and notes</small>
            </li>
            <li>
              <small>
                Click on the cell to see Notes added by you and others.
              </small>
            </li>
            <li>
              <small>
                Write the key needs of the Noncustomers you have interviewed
              </small>
            </li>
          </ol>
        </Card.Footer>
      </Card>

      {/* Modal: Reorder stages */}
      <Modal
        centered
        show={reorderStagesModal}
        onHide={() => setReorderStagesModal(false)}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="mb-0">Reorder Stages</h5>
            <p className="mb-0 text-muted">
              <b>Hint:</b> Drag list elements in desired order
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {stagesList?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={
                            snapshot.isDragging
                              ? "shadow border rounded"
                              : "border rounded"
                          }
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="d-flex align-items-center">
                            <a
                              className="py-2 px-1"
                              style={{
                                cursor: "grab",
                                textDecoration: "none",
                              }}
                            >
                              <FontAwesomeIcon icon={faGripVertical} />
                            </a>
                            <p className="mb-0 py-2 px-1">rows
                              {index + 1}. {item.name}
                            </p>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default connect(
  (state) => ({
    graphId: state.graphId,
    buyerRows: state.buyerRows,
    buyerHeaders: state.buyerHeaders,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  {
    setBuyerRows,
    setBuyerHeader,
  }
)(Table);
