import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import firebase from "firebase";
import EventManagerNavbar from "./EventManagerNavbar";
import { Form, Dropdown, Button, Spinner } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import * as linkify from "linkifyjs";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import produce from "immer";
import { connect, useSelector } from "react-redux";
import { useDocument } from "react-firebase-hooks/firestore";

toast.configure();

const AddClinic = (props) => {
  const { currentLanguage } = props;

  const { eventid } = props;
  const history = useHistory();
  const userDetails = firebase.auth().currentUser;
  const [clinicDetails, setClinicDetails] = useState({});

  const [firebaseID, setFirebaseID] = useState(null);

  const [fileSizeError, setFileSizeError] = useState(false);

  const [saving, setSaving] = useState(false);

  const saveTextDataToStateHandler = (e, lang) => {
    setClinicDetails((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [e.target.name]: e.target.value,
        };
      });
      return updatedState;
    });
  };

  const saveNumberToStateHandler = (e) => {
    setClinicDetails((prev) => {
      const per_patient_time = +e.target.value;
      return { ...prev, per_patient_time };
    });
  };

  const saveHTMLDataToStateHandler = (name, data, lang) => {
    setClinicDetails((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [name]: data,
        };
      });
      return updatedState;
    });
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        userDetails?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const saveEventDataToDBHandler = async (e, clinicData) => {
    e.preventDefault();
    const feature_image_url = clinicData.featured_image
      ? await uploadFile(clinicData.featured_image)
      : null;

    const clinic_data = {
      ...clinicData,
      featured_image: feature_image_url,
    };
    try {
      setSaving(true);
      const docRef = firebase.firestore().collection(`clinics`).doc();

      await docRef.set({
        clinic_details: {
          ...clinic_data,
        },
        isDemo: true,
        isOffice: false,
        slug: clinic_data.EN.clinic_name
          ?.trim()
          .replace(/ /g, "_")
          .toLowerCase(),
        timestamp: Date.now(),
      });
      toast.success(
        clinicDetails?.type === 1
          ? "You have successfully updated office details"
          : "You have successfully updated clinic details",
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
      history.push(`/admin/clinicmanager/${docRef.id}`);
    } catch (e) {
      console.error(e, "error in saving to db");
      toast.error("Error in updatingevent details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };
  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const featured_image_url = clinicDetails?.featured_image
    ? getWhetherURLOrLocalFile(clinicDetails?.featured_image)
    : null;

  return (
    <>
      <div className="row w-100 h-100 montserrat-font p-3">
        <div
          className="col-md-7 p-3"
          //   style={{ maxHeight: "85vh", overflow: "auto" }}
        >
          <div className="text-light">
            {clinicDetails && clinicDetails[currentLanguage]?.clinic_name && (
              <h1 className="text-white mb-3 text-s-2-bold">
                {clinicDetails[currentLanguage]?.clinic_name
                  ? clinicDetails[currentLanguage]?.clinic_name
                  : "Event name"}
              </h1>
            )}

            {clinicDetails && clinicDetails?.featured_image && (
              <img
                src={featured_image_url}
                className="w-100 mb-3 rounded-12"
                alt="..."
              />
            )}

            {clinicDetails &&
              clinicDetails[currentLanguage]?.small_description && (
                <p className="text-white">
                  {parse(clinicDetails[currentLanguage]?.small_description)}
                </p>
              )}

            <div className="p-3  rounded-12 mb-3">
              {clinicDetails && clinicDetails[currentLanguage]?.description && (
                <p className="mb-3">
                  {parse(clinicDetails[currentLanguage]?.description)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-5"
          //   style={{ maxHeight: "85vh", overflow: "auto" }}
        >
          <div className="shadow-sm p-2 bg-dark rounded text-light mb-3">
            <h5 className="mb-0">
              Enter {clinicDetails?.type === 1 ? "Office" : "Clinic"} details
            </h5>
          </div>
          <Form onSubmit={(e) => saveEventDataToDBHandler(e, clinicDetails)}>
            <Form.Group>
              <Form.Label>
                {clinicDetails?.type === 1 ? "Office" : "Clinic"} name
              </Form.Label>
              <Form.Control
                maxLength="200"
                type="text"
                className="mb-3"
                placeholder="Name"
                value={clinicDetails?.EN?.clinic_name}
                name="clinic_name"
                onChange={(event) => {
                  saveTextDataToStateHandler(event, "EN");
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Per {clinicDetails?.type === 1 ? "appointment" : "patient"} time
                <p className="small text-light mb-0">In minutes</p>
              </Form.Label>
              <Form.Control
                type="number"
                className="mb-3"
                placeholder="Per patient time"
                value={clinicDetails?.per_patient_time}
                name="per_patient_time"
                step="5"
                min="5"
                max="60"
                onChange={(event) => {
                  saveNumberToStateHandler(event);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Small Description
                <p className="small text-light mb-0">
                  Recommdended: Min. 50 words (Max. 400 characters)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3"
                data={clinicDetails?.EN?.small_description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  saveHTMLDataToStateHandler("small_description", data, "EN");
                }}
                style={{ height: 400 }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Description
                <p className="small text-light mb-0">
                  Recommdended: Min. 100 words (No Limit)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3"
                data={clinicDetails?.EN?.description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  saveHTMLDataToStateHandler("description", data, "EN");
                }}
                style={{ height: 400 }}
              />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>
                Featured Image
                <p className="small text-light mb-0">
                  Recommdended: 1200px X 628px
                </p>
              </Form.Label>
              <Form.Control
                type="file"
                className="mb-3"
                accept="image/*"
                placeholder="Featured Image"
                name="featured_image"
                onChange={(event) => {
                  setClinicDetails((prevDetails) => {
                    return {
                      ...prevDetails,
                      featured_image: event.target.files[0],
                    };
                  });
                }}
              />
            </Form.Group>
            <div className="pb-5">
              <Button className="form-control" variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({ currentLanguage: "EN" }))(AddClinic);
