import React, { useRef, useState, useEffect } from "react";
import { Nav, Form, Image, Card, Button, Dropdown } from "react-bootstrap";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import * as ConsultationStyle from "./consultation.module.css";
import { languageText } from "../Event/language.json";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faTooth, faXRay } from "@fortawesome/free-solid-svg-icons";

import Gallery from "react-grid-gallery";
import { images } from "./images.json";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function ConsultationAuthentication({ currentLanguage, changeLanguage }) {
  const { hash, pathname } = useLocation();
  const {id} = useParams();
  const history = useHistory();
  const [route, setRoute] = useState("");

  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email_address: "",
    password_value: "",
  });

  useEffect(() => {
    if (pathname.split("/")[3] && pathname.split("/")[3] === "register") {
      setRoute("register");
    }
    if (pathname.split("/")[3] && pathname.split("/")[3] === "login") {
      setRoute("login");
    }
  }, [pathname]);

  const handleRegister = () => {
    if (pathname.split("/")[2] !== "" && pathname.split("/")[3] === "register") {
      history.push(`doctors`);
    }
    //if (pathname.split("/")[2] !== "" && pathname.split("/").length < 4) {
      //history.push(`${pathname.split("/")[2]}/register`);
    //}
  };

  


  const handleLogin = () => {
    if (pathname.split("/")[2] !== "" && pathname.split("/")[3] === "login") {
      history.push(`doctors`);
    }
  };

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [navigationTo, setNavigationTo] = useState("overview");
  const overviewRef = useRef(null);
  const doctorsRef = useRef(null);
  const servicesRef = useRef(null);
  const galleryRef = useRef(null);

  const [clinicDoc, loadingClinicDoc, errorClinicDoc] = useDocument(
    firebase.firestore().collection("clinics").doc(`${id}`)
  );

  // Doctors data
  const [doctorsSnap, loadingDoctorsSnap, errorDoctorsSnap] = useCollection(
    firebase
      .firestore()
      .collection("/clinics/EKWsp2FDV2aWp1F6P963/doctors")
      .orderBy("order_index", "asc")
  );

  useEffect(() => {
    if (hash && hash === `#overview`) {
      overviewRef && overviewRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#doctors`) {
      doctorsRef && doctorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#services`) {
      servicesRef && servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (hash && hash === `#gallery`) {
      galleryRef && galleryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const handleFormInput = (e) => {
    // console.log(e.target.name, e.target.value);
    setUserDetails((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Top navigation start */}
      <div className="container pt-3 pt-md-4 d-flex align-items-center justify-content-between">
        <div onClick={() => history.push(`/`)}>
          <Image
            className={`rounded ${ConsultationStyle.consultation_cover_logo}`}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
        </div>
        <Dropdown className="mr-3 small text-white">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {languageText[`${currentLanguage}`].english}
            </Dropdown.Item>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "FR"}
              onClick={() => changeLanguage("FR")}
            >
              {languageText[`${currentLanguage}`].french}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Top navigation end */}

      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-md-8 col-12 mx-auto text-center">
            <h2 className="font-weight-bold text-white">
              {!loadingClinicDoc &&
                clinicDoc.data()?.clinic_details["EN"]?.clinic_name}
            </h2>
            <p className="text-white">Mon - Sat: 9:30 AM to 5:00 PM IST</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-12 mx-auto bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              style={{ position: "sticky", top: 60 }}
            >
              <Card.Body>
                <p className="text-center h5 mb-3">
                  {route === "register" ? "Register" : "Log in"} for an igesia
                  account
                  <br />
                  to join
                  {/* for
                        <br/>{programName} */}
                </p>
                <div className="mb-3">
                  <Nav
                    variant="pills"
                    onSelect={(e) => {
                      history.push(e);
                    }}
                    activeKey={route}
                    className="w-100 border rounded-pill m-0"
                  >
                    <Nav.Item className="w-50">
                      <Nav.Link
                        className="py-1 text-center rounded-pill"
                        eventKey="register"
                      >
                        Register
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="w-50">
                      <Nav.Link
                        className="py-1 text-center rounded-pill"
                        eventKey="login"
                      >
                        Log in
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  {route === "register" ? (
                    <Form
                      className="mt-3"
                      onSubmit={(e) => {
                        e.preventDefault()
                        handleRegister();
                      }}
                    >
                      <Form.Group>
                        <Form.Label className="small">First Name</Form.Label>
                        <Form.Control
                          type="text"
                          required={true}
                          placeholder="Enter first name"
                          className="border rounded-lg px-2"
                          id="first_name"
                          name="first_name"
                          onChange={handleFormInput}
                          value={userDetails?.first_name}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="small">Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          required={true}
                          placeholder="Enter last name"
                          className="border rounded-lg px-2"
                          id="last_name"
                          name="last_name"
                          onChange={handleFormInput}
                          value={userDetails?.last_name}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="small">
                          Phone number (optional)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          className="border rounded-lg px-2"
                          id="phone_number"
                          name="phone_number"
                          onChange={handleFormInput}
                          value={userDetails?.phone_number}
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="small">Email address</Form.Label>
                        <Form.Control
                          required={true}
                          type="email"
                          placeholder="Enter email"
                          className="border rounded-lg px-2"
                          id="email_address"
                          name="email_address"
                          onChange={handleFormInput}
                          value={userDetails?.email_address}
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="small">Password</Form.Label>
                        <Form.Control
                          type="password"
                          required={true}
                          placeholder="Password"
                          className="border rounded-lg px-2"
                          id="password_value"
                          name="password_value"
                          onChange={handleFormInput}
                          value={userDetails?.password_value}
                        />
                      </Form.Group>

                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          required={true}
                          label="I agree to igesia's Terms & Conditions and Privacy Policy"
                          className="small"
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"

                      >
                        Register
                      </Button>

                      <p className="text-center small mt-2 mb-0 font-weight-bold">
                        Already have an account?{" "}
                        <a
                          className="text-primary"
                          onClick={() => {
                            history.push("login");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Log in here
                        </a>
                      </p>
                    </Form>
                  ) : (
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="small">Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="small">Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        onClick={() => {
                          // history.push(`${pathname.split("/")[2]}/overview`);
                          handleLogin()
                        }}
                      >
                        Log in
                      </Button>

                      <p className="text-center small mt-2 mb-0 font-weight-bold">
                        Don't have an account?{" "}
                        <a
                          className="text-primary"
                          onClick={() => {
                            history.push("register");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Register here
                        </a>
                      </p>
                    </Form>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
          {/* Booking panel ends */}
        </div>
      </div>

      {/* Gallery start */}
      {/* <div className="container pt-3 pt-md-4" id="#gallery" ref={galleryRef}>
        <h3 className="text-white">
          Gallery
        </h3>
        <Gallery className=" bg-graphite" images={images}/>
      </div> */}
      {/* Gallery end */}
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(ConsultationAuthentication);
