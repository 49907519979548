import React, { useState, useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, Form, ProgressBar } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLink
  } from "@fortawesome/free-solid-svg-icons";

export default function Reading({ data }) {
  const [readingData, readingDataLoading, readingDataError] = useDocument(
    firebase.firestore().collection(`readings`).doc(`${data.contentId}`)
  );

  const [showResult, setShowResult] = useState(false);
  const responses = useMemo(() => {
    let res = {};
    return res;
  }, [readingData]);

  const colors = ["primary", "warning", "danger", "success", "info"];

  return (
    <div className="">
      {readingDataLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Card className="bg-dark text-white mb-3">
            <Card.Header className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="font-weight-bold mb-0">
                  {readingData?.data()?.readingSubject}
                </h4>
              </div>
              <p className="small mb-0">
                {moment(data?.timestamp).format("LLL")}
              </p>
            </Card.Header>
            <Card.Body className="py-1">
            <p>{readingData?.data()?.readingDescription}</p>
            <div>
                {readingData?.data()?.readings?.map((reading, key) => {
                    return (
                        <div className="mb-2">
                          <p className="mb-1">
                            {reading.link ? (
                              <span>
                                {key + 1}.{" "}
                                <a
                                  href={
                                    reading.link.indexOf("http://") == 0 ||
                                    reading.link.indexOf("https://") == 0
                                      ? reading.link
                                      : `https://${reading.link}`
                                  }
                                  target="_blank"
                                >
                                  {reading.title} <FontAwesomeIcon icon={faLink} />
                                </a>
                              </span>
                            ) : (
                              <span>
                                {key + 1}. {reading.title}
                              </span>
                            )}
                          </p>
                        </div>
                    )
                })}
            </div>
              
            </Card.Body>
            <Card.Footer>
              <p className="mb-0 small">
                Created by <b>{data?.user?.name}</b>
              </p>
              <p className="mb-0 small">
                Deadline by{" "}
                <b>{moment(readingData?.data()?.deadline).format("LLL")}</b>
              </p>
            </Card.Footer>
          </Card>
        </div>
      )}
    </div>
  );
}
