import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Spinner,
  FormCheck,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faComments,
  faStore,
  faUsers,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
// import EventDetails from "./EventDetails";
// import BriefStats from "./BriefStats";

const Overview = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const [blogSnapshot, loadingBlog, errorBlog] = useDocument(
    firebase
      .firestore()
      .collection("blogs")
      .doc(`${id}`)
  );
  const blog = blogSnapshot?.data();

  const [ingEventDetails, setingEventDetails] = useState(false);

  return (
    <>
      {!ingEventDetails ? (
        <div
          className="container-fluid h-100 py-4 text-light"
          style={{ overflow: "hidden" }}
        >
          <div className="col-md-12 h-100 mt-3" style={{ overflow: "auto" }}>
            <div className="mt-3">
              <Card
                style={{ width: "100%" }}
                className="shadow-sm bg-dark shadow-sm"
              >
                <Card.Body>
                  <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                    <div>
                      {blog?.img_src && (
                        <img
                          alt="event-thumbnail"
                          width="110px"
                          src={blog?.img_src}
                          className="img-fluid rounded d-block d-md-inline mr-3"
                        ></img>
                      )}
                      <span>{blog?.heading} </span>
                    </div>
                    <div>
                      {/* <Button
                        className="btn-sm btn-primary mr-2"
                        onClick={() => setingEventDetails(true)}
                      >
                        Edit
                      </Button> */}
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faUpload}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold text-uppercase">
                          Published
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="isPublished"
                            label={blog?.is_published ? "On" : "Off"}
                            //disabled={true}
                            checked={blog?.is_published ? true : false}
                            onChange={(e) =>
                              firebase
                                .firestore()
                                .collection("blogs")
                                .doc(`${id}`)
                                .update({
                                  is_published: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          DATE
                        </span>
                        <span>{moment(blog?.timestamp).format("Do MMM YY")}</span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <Button
                          className="mr-2"
                          size="sm"
                          onClick={() => {
                            history.push(`/blog/${id}`)
                          }}
                        >
                          View Blog Page
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <Button
                          className="mr-2"
                          size="sm"
                          onClick={() => {
                            history.push(`/admin/blogmanager/${id}#publish`)
                          }}
                        >
                          Edit Blog
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <BriefStats /> */}
          </div>
        </div>
      ) : (
        <div className="mt-3 ml-3">
          <Button onClick={() => setingEventDetails(false)} className="btn-sm">
            Back to event manager
          </Button>
          {/* <EventDetails /> */}
        </div>
      )
      }
    </>
  );
};

export default connect(
  (state) => ({
    blog: state.blog,
  }),
  null
)(Overview);
