import React, { useState, useMemo, memo, useEffect } from "react";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import {
  API_BASE_URL,
  ASSIGNMENT_REMOVE,
  APP_CLOSE,
  ADMIN_CHECK_LINKING,
  ADMIN_DELETE_LINKING,
  ADMIN_APP_RESPONSES_GET,
} from "../../../../config";
import AssignmentFields from "./AssignmentFields";
import AssignmentResponse from "./AssignmentResponse";
import AssignmentLoader from "./AssignmentLoader";

const Assignment = (props) => {
  // CONSTANTS
  const {
    data,
    darkTheme,
    currentEvent,
    eventId,
    roomId,
    handleCloseItem,
    setShowMoreDetailsPanel,
    setSelectedMoreDetails,
    selectedMoreDetails,
    showMoreDetailsPanel,
  } = props;
  const currentUser = firebase.auth().currentUser;

  // STATES
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const [assignmentId, setAssignmentId] = useState(null);
  const [assignmentTitle, setAssignmentTitle] = useState("");
  const [roomDetails, setRoomDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLinkedWith, setIsLinkedWith] = useState(false);
  const [linkedWith, setLinkedWith] = useState([]);
  const [checkingLinking, setCheckingLinking] = useState(true);
  const [questionData, setQuestionData] = useState({});
  const [allResponses, setAllResponses] = useState([]);
  const [assignmentData, setAssignmentData] = useState(null);
  const [fetchingDetails, setFetchingDetails] = useState(true);

  // FUNCTIONS
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);

  const handleFetchAppResponses = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${ADMIN_APP_RESPONSES_GET}?content_id=${data?.contentId}&type=assignment`
      );

      if (response.status === 200) {
        const { data } = response.data;

        const temp = {};
        data?.questions?.map((item) => (temp[item.questionId] = { ...item }));

        setQuestionData(temp);
        setAssignmentData(data);
        setAllResponses(data?.responses);
        setCount(data?.responses?.length);
        setFetchingDetails(false);
      } else {
        setFetchingDetails(false);
      }
    } catch (error) {
      console.log("[Error] Fetching quiz details: ", error);
      setFetchingDetails(false);
    }
  };

  useEffect(() => {
    // Fetch: App details
    handleFetchAppResponses();
  }, [data?.contentId]);

  useEffect(async () => {
    const roomDoc = firebase
      .firestore()
      .collection(`workspaces/${currentEvent?.workspace_id}/rooms`)
      .doc(`${currentEvent?.room_id}`);
    const roomDocGet = await roomDoc.get();

    setRoomDetails(roomDocGet.data());
  }, []);

  const removeAssignment = async (content_id) => {
    try {
      await firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/assignments`)
        .doc(`${content_id}`)
        .delete()
        .then(() => {
          if (darkTheme) {
            toast.dark("Assignment deleted.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            toast("Assignment deleted.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }

          if (isLinkedWith) {
            handleDeleteLinking({
              event_id: eventId,
              user_id: currentUser.uid,
              content_id: content_id,
              type: "assignment",
            });
          }

          handleClose();
        });
    } catch (error) {
      console.log(error);

      toast.warning(`Error: ${error.response.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const closeAppResponses = async (assignment_id) => {
    console.log(data.contentId, assignment_id, "my ass id");
    // YNswUgibAjuYm9DlCnLF
    // let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
    //   workspace_id: eventId,
    //   room_id: roomId,
    //   user_id: user.uid,
    //   message_id: data.contentId,
    // });
    // if (res.status === 200) {
    //   if (darkTheme) {
    //     toast.dark("Closed", {
    //       position: toast.POSITION.TOP_CENTER,
    //       hideProgressBar: true,
    //       autoClose: 3000,
    //     });
    //   } else {
    //     toast("Closed", {
    //       position: toast.POSITION.TOP_CENTER,
    //       hideProgressBar: true,
    //       autoClose: 3000,
    //     });
    //   }
    // } else {
    //   console.log(res);
    // }
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const handleDeleteLinking = async (data) => {
    try {
      console.log("[Try] Deleting linking: ", data);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_DELETE_LINKING}`,
        data
      );

      if (response.status === 200) {
        console.log("[Success] Deleting linking: ", response.data);
      }
    } catch (error) {
      console.log("[Error] Deleting linking: ", error);
    }
  };

  const handleCheckLinking = async (data) => {
    try {
      console.log("[Try] Cheking link: ", data);

      const response = await axios.post(
        `${API_BASE_URL}${ADMIN_CHECK_LINKING}`,
        data
      );

      if (response.status === 200) {
        const { data } = response.data;
        console.log("[Success] Checking link: ", data);

        if (data?.length > 0) {
          setIsLinkedWith(true);
          setLinkedWith(data);
        } else {
          setIsLinkedWith(false);
        }
        setCheckingLinking(false);
      }
    } catch (error) {
      console.log("[Error] Checking link: ", error);
      setCheckingLinking(false);
    }
  };

  if (fetchingDetails) {
    return <AssignmentLoader handleCloseItem={handleCloseItem} />;
  }

  return (
    <React.Fragment>
      <div className="border border-secondary text-white mr-3">
        <div className="p-3 d-flex align-items-center border-bottom border-secondary">
          <h5 className="flex-grow-1 mb-0">{data?.title}</h5>
          <div>
            <a
              disabled={count > 0}
              title={count > 0 ? "Cannot edit" : "Edit"}
              size="sm"
              onClick={handleShowEditModal}
              className="mr-1 text-white pointer p-2"
            >
              <FontAwesomeIcon icon={faEdit} />
            </a>

            {/* <Button
              variant="warning"
              className="mr-2"
              size="sm"
              onClick={() => {
                closeAppResponses(assignmentData?.id);
              }}
              disabled={true}
            >
              Close
            </Button> */}

            <a
              className="mr-1 text-white pointer p-2"
              onClick={() => {
                handleShow();
                setAssignmentId(assignmentData?.id);
                setAssignmentTitle(assignmentData?.assignmentSubject);
                handleCheckLinking({
                  event_id: eventId,
                  user_id: currentUser.uid,
                  content_id: assignmentData?.id,
                  type: "assignment",
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </a>

            <a className="text-white pointer p-2" onClick={handleCloseItem}>
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </div>
        </div>
        <div className="p-3">
          <div className="mb-3">
            <p className="text-muted small mb-0">Questions</p>
            {assignmentData?.questions?.map((item, key) => (
              <div key={key}>
                <p className="mb-1">
                  {key + 1}. {item.question}{" "}
                  {assignmentData?.isGraded && <>({item.weight} points)</>}
                </p>
                {item.description && <p>({item.description})</p>}
              </div>
            ))}
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Created at</p>
            <p className="small mb-0">
              {moment(
                convertSecondstoMilliSeconds(
                  assignmentData?.createdAt._seconds,
                  assignmentData?.createdAt._nanoseconds
                )
              ).format("LLL")}
            </p>
          </div>
          {data?.is_graded && (
            <div className="mb-3">
              <p className="text-muted small mb-0">Total grade points</p>
              <p className="small mb-0">{data?.total_points}</p>
            </div>
          )}
          <div className="mb-3">
            <p className="text-muted small mb-0">Deadline by</p>
            <p className="small mb-0">
              {moment(assignmentData?.deadline).format("LLL")} (
              {moment(assignmentData?.deadline).fromNow()})
            </p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Total number of responses</p>
            <p className="small mb-0">{count}</p>
          </div>
          <div className="mb-3">
            <p className="text-muted small mb-0">Responses</p>
            {allResponses?.length > 0 ? (
              <React.Fragment>
                {allResponses?.map((response, key) => {
                  return (
                    <AssignmentResponse
                      key={key}
                      response={response}
                      assignmentSnapshot={assignmentData}
                      questionData={questionData}
                      data={data}
                      currentUser={currentUser}
                      setShowMoreDetailsPanel={setShowMoreDetailsPanel}
                      showMoreDetailsPanel={showMoreDetailsPanel}
                      setSelectedMoreDetails={setSelectedMoreDetails}
                      selectedMoreDetails={selectedMoreDetails}
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              <p className="text-center mt-1">
                <i>No responses yet</i>
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Modal: Delete assignment */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div>
            <h5 className="mb-1">{assignmentTitle}</h5>
            <p className="small mb-0 text-muted text-uppercase">
              Confirm deletion
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="lead">
            Are you sure you want to delete? Delete operation cannot be
            reversed.
          </p>
          {!checkingLinking && isLinkedWith && (
            <p>
              Looks like <b>{assignmentTitle}</b> is added to <b>Modules</b>, if
              you delete it will also remove them from modules.
            </p>
          )}
          <p className="text-muted">
            <b>Note:</b> Assignments deleted are still part of your room "
            {roomDetails?.roomName}"
          </p>
          <div className="d-flex justify-content-end">
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                handleClose();
                setAssignmentId(null);
              }}
              className="rounded-lg text-uppercase border border-secondary mr-2"
              disabled={checkingLinking}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => removeAssignment(assignmentId)}
              disabled={checkingLinking}
              className="rounded-lg text-uppercase"
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <AssignmentFields
            onHide={handleCloseEditModal}
            isEditting={true}
            assignmentId={assignmentData?.id}
            assignmentData={assignmentData}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
  eventId: state.eventId,
  roomId: state.roomId,
});

export default connect(mapStateToProps)(memo(Assignment));
