import React, { useEffect, useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faEyeSlash,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { Badge, Button } from "react-bootstrap";
import firebase from "firebase";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";

import Video from "../Video";

toast.configure();

function IgesiaVideo(props) {
  const {
    darkTheme,
    currentEvent,
    session_id,
    sessionDetails,
    isUserSpeaker,
    isUserModerator,
  } = props;

  // STATES
  const [openMeeting, setOpenMeeting] = useState(false);
  const [videoMute, setVideoMute] = useState(false);
  const [audioMute, setAudioMute] = useState(false);
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection(`sessions/${currentEvent?.id?.toLowerCase()}/rooms`)
      .doc(`${session_id?.toLowerCase()}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (doc?.data()?.currentSession != "") {
            setIsLive(true);
          }
        }
      });
  }, [currentEvent?.id, session_id]);

  return (
    <React.Fragment>
      <div className="px-0 pb-md-3 pb-5">
        <div className="mb-3">
          <div
            className={
              darkTheme
                ? "border bg-dark border-dark rounded shadow-sm p-2"
                : "border bg-white rounded shadow-sm p-2"
            }
          >
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 d-flex align-items-start">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-start">
                    <h5 className="m-0 font-weight-bold">
                      {sessionDetails?.EN?.title}
                    </h5>
                    <div className="ml-2 small">
                      {isLive ? (
                        <Badge variant="danger">
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faDotCircle}
                              className="mr-1"
                            />
                            <span>Live</span>
                          </div>
                        </Badge>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                size="sm"
                variant={openMeeting ? "danger" : "primary"}
                className="text-uppercase"
                onClick={() => setOpenMeeting((prev) => !prev)}
                disabled={currentEvent?.id == "" && session_id == ""}
              >
                <FontAwesomeIcon icon={faVideo} className="mr-2" />
                {openMeeting ? "End" : "Join"} Meeting
              </Button>
            </div>
          </div>
        </div>

        {!openMeeting && (
          <div
            className={
              darkTheme
                ? "p-3 rounded-lg mb-3 shadow-sm border bg-dark border-dark d-flex flex-column align-items-center justify-content-center"
                : "p-3 rounded-lg mb-3 shadow-sm border bg-white d-flex flex-column align-items-center justify-content-center"
            }
            style={{ height: "400px" }}
          >
            <h4 className="mb-3 text-center">
              Select your video and audio preferences
              <br /> before joining.
            </h4>
            <div
              className="mb-3 pointer mx-auto"
              style={{ position: "relative", width: "300px" }}
              onClick={() => {
                setVideoMute(!videoMute);
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "48%",
                  left: "48%",
                  zIndex: "1000",
                }}
              >
                {videoMute ? (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    size="lg"
                    style={{ opacity: "75%" }}
                  />
                ) : null}
              </div>
              <Webcam
                audio={false}
                videoConstraints={{ width: 300, height: 160 }}
                height={160}
                width={300}
                className="rounded"
                style={
                  videoMute
                    ? {
                        filter: "blur(12px)",
                        transform: "scaleX(-1)",
                      }
                    : { transform: "scaleX(-1)" }
                }
              />
            </div>
            <div className="d-flex justify-content-center">
              <div className="text-center mr-5">
                <button
                  className={
                    videoMute
                      ? "btn btn-light rounded-circle mb-1"
                      : "btn btn-success rounded-circle mb-1"
                  }
                  style={{ width: "45px", height: "45px" }}
                  onClick={() => {
                    setVideoMute(!videoMute);
                  }}
                >
                  {videoMute ? (
                    <FontAwesomeIcon icon={faVideoSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faVideo} />
                  )}
                </button>
                <p className="small text-theme">
                  {videoMute ? <>Video Off</> : <>Video On</>}
                </p>
              </div>
              <div className="text-center">
                <button
                  className={
                    audioMute
                      ? "btn btn-light rounded-circle mb-1"
                      : "btn btn-success rounded-circle mb-1"
                  }
                  style={{ width: "45px", height: "45px" }}
                  onClick={() => {
                    setAudioMute(!audioMute);
                  }}
                >
                  {audioMute ? (
                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faMicrophone} />
                  )}
                </button>
                <br />
                <p className="small text-theme">
                  {audioMute ? <>Audio Off</> : <>Audio On</>}
                </p>
              </div>
            </div>
          </div>
        )}

        {openMeeting && (
          <div>
            <Video
              categoryId={session_id}
              event_id={currentEvent?.id}
              group_name={sessionDetails?.EN?.title}
              audioMute={audioMute}
              videoMute={videoMute}
              setOpenMeeting={setOpenMeeting}
              openMeeting={openMeeting}
              isAdmin={isUserSpeaker || isUserModerator}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  currentEvent: state.currentEvent,
  darkTheme: state.darkTheme,
}))(memo(IgesiaVideo));
