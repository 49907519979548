import React, { useState, useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, Form, ProgressBar } from "react-bootstrap";
import moment from "moment";

export default function Poll({ data }) {
  const [pollData, pollDataLoading, pollDataError] = useDocument(
    firebase.firestore().collection(`polls`).doc(`${data.contentId}`)
  );

  const [showResult, setShowResult] = useState(false);
  const responses = useMemo(() => {
    let res = {};
    pollData?.data()?.response?.map((item) => {
      if (res[`${item.optionValue}`]) res[`${item.optionValue}`] += 1;
      else res[`${item.optionValue}`] = 1;
    });
    return res;
  }, [pollData]);

  const colors = ["primary", "warning", "danger", "success", "info"];

  return (
    <div className="">
      {pollDataLoading ? (
        <Spinner animation="border" />
      ) : (
        <div>
          <Card className="bg-dark text-white mb-3">
            <Card.Header className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h4 className="font-weight-bold mb-0">
                  {pollData?.data()?.title}
                </h4>
              </div>
              <p className="small mb-0">
                {moment(data?.timestamp).format("LLL")}
              </p>
            </Card.Header>
            <Card.Body className="py-1">
              <Form onSubmit={(e) => e.preventDefault()}>
                <p className="small">Choose one answer:</p>
                {pollData?.data()?.options.map((option, key) => {
                  if (
                    pollData?.data()?.images &&
                    pollData?.data()?.images[key]
                  ) {
                    return (
                      <div>
                        <img
                          src={pollData?.data()?.images[key]}
                          height={100}
                          width="auto"
                        />
                        <Form.Check
                          label={option}
                          name={data.contentId}
                          type={"radio"}
                          id={key}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Form.Check
                          label={option}
                          name={data.contentId}
                          type={"radio"}
                          id={key}
                        />
                      </div>
                    );
                  }
                })}
                <div className="d-flex my-3">
                  <Button className="w-100 mr-2" variant="success">
                    <small>Submit</small>
                  </Button>
                  <Button
                    className="w-100 ml-2"
                    variant="secondary"
                    onClick={() => setShowResult((prev) => !prev)}
                  >
                    <small>{showResult ? "Hide" : "Show"} Results</small>
                  </Button>
                </div>
              </Form>
              {showResult && (
                <div>
                  <p className="lead font-weight-bold">
                    Total responses: {pollData?.data()?.response?.length}
                  </p>
                  {pollData?.data()?.options.map((option, key) => {
                    if (
                      pollData?.data()?.images &&
                      pollData?.data()?.images[key]
                    ) {
                      return (
                        <div>
                          <img
                            src={pollData?.data()?.images[key]}
                            height={100}
                            width="auto"
                          />
                          <div className="d-flex align-items-center justify-content-between">
                            <p key={key}>{option}</p>
                            <p className="mb-1 small">
                              {responses[`${option}`]
                                ? (responses[`${option}`] /
                                    pollData?.data()?.response?.length) *
                                  100
                                : 0}
                              % (
                              {responses[`${option}`]
                                ? responses[`${option}`]
                                : 0}{" "}
                              responses)
                            </p>
                          </div>
                          <ProgressBar
                            animated
                            now={
                              responses[`${option}`]
                                ? (responses[`${option}`] /
                                    pollData?.data()?.response?.length) *
                                  100
                                : 0
                            }
                            className="mb-2"
                            variant={colors[key % 5]}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-1" key={key}>
                              {option}
                            </p>
                            <p className="mb-1 small">
                              {responses[`${option}`]
                                ? (responses[`${option}`] /
                                    pollData?.data()?.response?.length) *
                                  100
                                : 0}
                              % (
                              {responses[`${option}`]
                                ? responses[`${option}`]
                                : 0}{" "}
                              responses)
                            </p>
                          </div>
                          <ProgressBar
                            animated
                            now={
                              responses[`${option}`]
                                ? (responses[`${option}`] /
                                    pollData?.data()?.response?.length) *
                                  100
                                : 0
                            }
                            className="mb-2"
                            variant={colors[key % 5]}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </Card.Body>
            <Card.Footer>
              <p className="mb-0 small">
                Created by <b>{data?.user?.name}</b>
              </p>
              <p className="mb-0 small">
                Deadline by{" "}
                <b>{moment(pollData?.data()?.deadline).format("LLL")}</b>
              </p>
            </Card.Footer>
          </Card>
        </div>
      )}
    </div>
  );
}
