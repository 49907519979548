import { memo } from "react";
import parse from "react-html-parser";
import ReactPlayer from "react-player";

const VideoLectureView = (props) => {
  const { module } = props;
  return (
    <div>
      <h6>{module.topic}</h6>
      <p>{parse(module.description)}</p>
      <ReactPlayer
        controls
        stopOnUnmount
        height="400px"
        width="100%"
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              noDownload: true,
              controlsList: "nodownload noremoteplayback",
            },
          },
        }}
        url={module?.video_lecture}
      />
    </div>
  );
};
export default memo(VideoLectureView);
