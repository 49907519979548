import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Card,
  Modal,
} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HC_more from "highcharts/highcharts-more";
import firebase from "firebase";
import { TwitterPicker } from "react-color";
import _ from "lodash";
import {
  setFactors,
  setProducts,
  setGraphId,
  setAsIsFactors,
} from "../../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import ErrcGrid from "./ErrcGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

HC_more(Highcharts);

require("highcharts/modules/draggable-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts)

var yourLabels = ["Low", "Medium", "High"];

function TOBE(props) {
  const [graphData, graphLoading, graphError] = useDocument(
    firebase.firestore().doc(`events/${props?.currentEvent?.id}/bbos/${props.graphId}`)
  );

  const [tobeProducts, setToBeProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState([]);

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("red");
  const [newProductName, setNewProductName] = useState("");

  const [showAddTaglineModal, setShowAddTaglineModal] = useState(false);
  const [tagline, setTagline] = useState("");

  const { darkTheme } = props;

  useEffect(() => {
    setTagline(graphData?.data()?.tagline);
},[graphData]);

  const [productCollection, productCollectionLoading, err] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is`
      )
  );

  const getYDataHandler = async () => {
    try {
      if (productCollection?.docs?.length) {
        // setLoading(true)
        const collectionData = await Promise.all(
          productCollection?.docs?.map(async (item) => {
            let docRef = firebase
              .firestore()
              .collection(
                `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is/${item.id}/values`
              );
            let value = [];
            const data = await Promise.all(
              props.factors
                ?.sort((a, b) => a.position - b.position)
                .map(async (factor) => {
                  const doc = await docRef.doc(factor.id).get();
                  if (doc.exists) {
                    return doc.data().value;
                  } else {
                    return factor.defaultValue;
                  }
                })
            );
            if (data.length === props.factors.length) {
              value.push({
                productName: item.data().name,
                name: props.currentEvent?.bbos_names?.as_is ?? "As Is Strategy",
                color: item.data().color,
                id: item.id,
                data: data,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              });
            }

            const data1 = await Promise.all(
              props.factors?.map(async (factor) => {
                return factor.defaultValue;
              })
            );
            if (data.length === props.factors.length) {
              value.push({
                name: props.currentEvent?.bbos_names?.to_be ?? "To Be Strategy",
                color: "blue",
                data: data1,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              });
            }
            return value;
          })
        );
        //console.log(collectionData, "Collection data")
        setToBeProducts(collectionData);
      }
    } catch (e) {
      console.error(e, "Something went wrong");
    } finally {
      // setLoading(false)
    }
  };

  // console.log(selectedFactors, selectedProduct)

  let options = {
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      renderTo: "container",
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: selectedProduct[0]?.productName,
    },
    xAxis: {
      categories: selectedFactors?.map((x) => x.name),
    },

    yAxis: {
      min: 0,
      max: 2,
      labels: {
        formatter: function () {
          return yourLabels[this.value];
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.series.name;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            drag: function (e) {
              // console.log(e)
            },
            drop: function (e) {
              console.log(
                e.target,
                e.target.category,
                e.target.y,
                e.target.series.userOptions.id,
                props.factors
              );
              let key = e.target.series.userOptions.id;
              let value = props.factors.filter(
                (x) => x.name === e.target.category
              );
              console.log(value, key, props.graphId);
              let document = firebase
                .firestore()
                .collection(
                  `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is/${key}/values`
                )
               .doc(value[0].id);
              if (document && document.exists) {
                document.update({
                  value: e.target.y,
                });
              } else {
                document.set({
                  value: e.target.y,
                });
              }
            },
          },
        },
        stickyTracking: false,
      },
      column: {
        stacking: "normal",
      },
      line: {
        cursor: "ns-resize",
      },
    },
    series: selectedProduct,
  };

  useEffect(() => {
    if (!productCollectionLoading) {
      getYDataHandler();
    }
  }, [productCollectionLoading, productCollection]);

  useEffect(() => {
    setSelectedFactors(props?.factors?.sort((a, b) => a.position - b.position));
  }, [JSON.stringify(props?.factors)]);

  const grid = 8;
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  function handleOnDragEnd({ destination, source }) {
    //console.log(destination, source);
    let sourceIndex = props.factors[source.index];
    let destinationIndex = props.factors[destination.index];
    // console.log(sourceIndex , "source");
    // console.log(destinationIndex , "destination");

    if (destination) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .doc(sourceIndex.id)
        .set(
          {
            position: destinationIndex.position,
          },
          {
            merge: true,
          }
        );
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .doc(destinationIndex.id)
        .set(
          {
            position: sourceIndex.position,
          },
          {
            merge: true,
          }
        );
    }

    const item = Array.from(
      selectedProduct?.map((x, key) => {
        const itemData = x;
        const [reorderedData] = itemData.data.splice(source.index, 1);
        itemData.data.splice(destination.index, 0, reorderedData);
        return itemData;
      })
    );
  }

  const addTaglineHandler = () => {
    if (props?.graphId) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`)
        .doc(`${props?.graphId}`)
        .set(
          {
            tagline: tagline,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Tagline Added successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowAddTaglineModal(false);
        });
    }
  };

  const addNewProduct = () => {
    if (newProductName && selectedColor) {
      let data = {
        name: newProductName,
        color: selectedColor,
      };
      if (props.graphId) {
        firebase
          .firestore()
          .collection(
            `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is`
          )
          .add(data)
          .then(() => {
            toast.success("Product Added successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setNewProductName("");
            setShowAddProductModal(false);
          });
      }
    }
  };

  const deleteProductHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is`
        )
        .doc(item.id)
        .delete()
        .then(() => {
          if (props.products.length == 1) {
            props.setProducts([]);
          }
          //
        });
    }
  };

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header className="d-flex justify-content-between align-items-start">
        <div>
          <h5 className="mb-0 text-uppercase">To-Be Strategy Canvas</h5>
          <p className="mb-0">Tagline: {tagline}</p>
        </div>
        <div className="d-flex">
          <div className="mr-2">
            <Dropdown id="dropdown-basic-button">
              <Dropdown.Toggle size="sm" className="text-uppercase">
                Select Product
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {tobeProducts?.map((item, index) => {
                  return (
                    <Dropdown.Item
                      className="pointer"
                      onClick={() => {
                        setSelectedProduct(item);
                      }}
                    >
                      {item[0].productName}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Button
            size="sm"
            className="text-uppercase mr-2"
            disabled={showAddProductModal}
            onClick={() => setShowAddProductModal(true)}
          >
            Add Product
          </Button>
          <Button
            size="sm"
            className="text-uppercase"
            disabled={showAddTaglineModal}
            onClick={() => setShowAddTaglineModal(true)}
          >
            {tagline ? "Edit Tagline" : "Add Tagline"}
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        {/*----------------------------Add Tagline Modal Start--------------------------- */}
        <Modal
          centered
          show={showAddTaglineModal}
          onHide={() => setShowAddTaglineModal(false)}
        >
          <Modal.Header
            closeButton
            className={
              darkTheme
                ? "bg-dark shadow-sm border border-dark montserrat-font"
                : "bg-white shadow-sm border border-light montserrat-font"
            }
          >
            <Modal.Title>Add Tagline</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={
              darkTheme
                ? "bg-dark shadow-sm border border-dark montserrat-font"
                : "bg-white shadow-sm border border-light montserrat-font"
            }
          >
            <Form className="mb-0">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Tagline</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name of product"
                  value={tagline}
                  autoComplete="off"
                  onChange={(e) => setTagline(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex">
                <Button
                  variant="primary"
                  size="sm"
                  className="mr-2 text-uppercase col-6"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    addTaglineHandler();
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  className="text-uppercase col-6"
                  onClick={() => setShowAddTaglineModal(false)}
                >
                  Discard
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/*----------------------------Add Tagline Modal Endss--------------------------- */}

        {/*----------------------------Add Product Modal Start--------------------------- */}
        <Modal
          centered
          show={showAddProductModal}
          onHide={() => setShowAddProductModal(false)}
        >
          <Modal.Header
            className={
              darkTheme
                ? "bg-dark shadow-sm border border-dark montserrat-font"
                : "bg-white shadow-sm border border-light montserrat-font"
            }
            closeButton
          >
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={
              darkTheme
                ? "bg-dark shadow-sm border border-dark montserrat-font"
                : "bg-white shadow-sm border border-light montserrat-font"
            }
          >
            <Form className="mb-0">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Product name</Form.Label>
                <div className="d-flex align-items-center">
                  <div
                    className="mr-1"
                    onClick={() =>
                      showColorPicker
                        ? setShowColorPicker(false)
                        : setShowColorPicker(true)
                    }
                    style={{
                      height: 35,
                      width: 35,
                      borderRadius: 4,
                      backgroundColor: selectedColor,
                    }}
                  ></div>
                  {showColorPicker ? (
                    <TwitterPicker
                      onChangeComplete={(color) => {
                        setShowColorPicker(false);
                        setSelectedColor(color.hex);
                      }}
                      colors={[
                        "#FF6900",
                        "#FCB900",
                        "#7BDCB5",
                        "#00D084",
                        "#1adef0",
                        "#0693E3",
                        "#ff26d7",
                        "#EB144C",
                        "#F78DA7",
                        "#7500eb",
                      ]}
                    />
                  ) : null}
                  <Form.Control
                    type="text"
                    placeholder="Enter name of product"
                    value={newProductName}
                    autoComplete="off"
                    onChange={(e) => setNewProductName(e.target.value)}
                  />
                </div>
              </Form.Group>
              <div className="d-flex">
                <Button
                  variant="primary"
                  size="sm"
                  className="mr-2 text-uppercase col-6"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    addNewProduct();
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  className="text-uppercase col-6"
                  onClick={() => setShowAddProductModal(false)}
                >
                  Discard
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/*----------------------------Add Product Modal Ends--------------------------- */}

        {selectedProduct.length > 0 ? (
          <>
            <HighchartsReact
              constructorType={"chart"}
              highcharts={Highcharts}
              options={options}
            />
            <div>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <div className="row-reverse"></div>
                      {selectedFactors?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={
                                index == 0 ? "btn btn-light" : "btn btn-light "
                              }
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              // style={getItemStyle(
                              //   snapshot.isDragging,
                              //   provided.draggableProps.style
                              // )}
                            >
                              {item.name}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <p className="p-3 p-md-5 mb-0">
              Select a <b>Product</b> on top right to view To-Be Strategy
              Canvas.
            </p>
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 text-uppercase small">
          <b>Pro tips</b>
        </p>
        <ol className="px-3 mb-1 pb-1">
          <li>
            <small>
              Select a <b>Product</b> on top right to view To-Be Strategy
              Canvas.
            </small>
          </li>
          <li>
            <small>Drag factors to change their position on x-axis.</small>
          </li>
          <li>
            <small>Drag points on y-axis to change their value.</small>
          </li>
          <li>
            <small>
              To add more <b>Products</b> and <b>Factors</b> go to project
              settings.
            </small>
          </li>
          <li>
            <small>
              To hide or show <b>To-Be</b> or <b>As-Is</b> graph click on the
              Legend below.
            </small>
          </li>
        </ol>
      </Card.Footer>
    </Card>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    graphId: state.graphId,
    factors: state.factors,
    products: state.products,
    series: state.series,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  {
    setFactors,
    setProducts,
    setGraphId,
    setAsIsFactors,
  }
)(TOBE);
