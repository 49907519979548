import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Card, Spinner, Modal, Form } from "react-bootstrap";
import EventNavbar from "./NavBar";
import EventLeftbar from "./LeftBar";
import EventChat from "./EventChat";
import firebase from "firebase";
import { useHistory, useParams } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import {
  API_BASE_URL,
  EVENT_SPEAKERS,
  EVENT_USER_DETAILS,
} from "../../../config";
import { postData } from "../../../utils";
import { setCurrentUser } from "../../../store/actions/actions";

toast.configure();

function Session(props) {
  const { setCurrentUser, darkTheme, currentEvent } = props;
  const { id, session_id } = useParams();
  const history = useHistory();

  const currentUser = firebase.auth().currentUser;

  // STATES
  const [showChat, setShowChat] = useState(false);
  const [allSpeakers, setAllSpeakers] = useState(null);
  const [publishedSpeakers, setPublishedSpeakers] = useState(null);
  const [fetchingSpeakers, setFetchingSpeakers] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [currentUserCategory, setCurrentUserCategory] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(0);
  const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
  const [currentUserIsPart, setCurrentUserIsPart] = useState(false);
  const [liveStreaming, setLiveStreaming] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [streamingLink, setStreamingLink] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [show, setShow] = useState(false);

  // FUNCTIONS
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(async () => {
    if (currentEvent?.id) {
      // Fetch: Speakers
      const response_speakers = await postData(
        `${API_BASE_URL}${EVENT_SPEAKERS}`,
        { event_id: currentEvent?.id }
      );

      if (response_speakers.status == 200) {
        const { data } = response_speakers;
        const published_speakers = data.participantList?.filter(
          (speaker) => speaker.is_published
        );

        setAllSpeakers(data.participantList);
        setPublishedSpeakers(published_speakers);
        setFetchingSpeakers(false);
      }
    }

    if (currentEvent?.id && currentUser) {
      // Fetch: User details
      const response_user_details = await postData(
        `${API_BASE_URL}${EVENT_USER_DETAILS}`,
        {
          event_id: currentEvent?.id,
          user_id: currentUser?.uid,
        }
      );

      if (response_user_details.status == 200) {
        const { data } = response_user_details;

        setUserDetails(data.userData);
        setCurrentUser(data.userData);
        setCurrentUserCategory(data.userData?.category ?? "");
        setCurrentUserRole(data.userData?.user_role ?? 0);
        setCurrentUserIsPart(data.is_registered);
        setFetchingUserDetails(false);

        if (data.custom_form_required && !data.custom_form_filled) {
          history.push("../fill-details");
        }
      }
    }
  }, []);

  const [sessionDoc, loadingSession, errorSession] = useDocument(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/schedule`)
      .doc(`${session_id}`)
  );
  const session = useMemo(() => sessionDoc?.data());

  useEffect(() => {
    if (!loadingSession) {
      if (
        sessionDoc.data()?.meeting_type === 1 ||
        sessionDoc.data()?.meeting_type === 2
      ) {
        setLiveStreaming(sessionDoc.data()?.live_streaming);
        setStreamingLink(sessionDoc.data()?.streaming_link);
      }
    }
  }, [sessionDoc]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdating(true);

    if (liveStreaming && streamingLink) {
      firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/schedule`)
        .doc(`${session_id}`)
        .update(
          {
            live_streaming: liveStreaming,
            streaming_link: liveStreaming ? streamingLink : null,
          },
          { merge: true }
        )
        .then((response) => {
          toast.success("Streaming settings updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((error) => {
          console.error("Error updating streaming settings: ", error);
          toast.error("Error updating streaming settings", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setUpdating(false);
        });
    } else if (!liveStreaming) {
      firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/schedule`)
        .doc(`${session_id}`)
        .update(
          {
            live_streaming: liveStreaming,
            streaming_link: null,
          },
          { merge: true }
        )
        .then((response) => {
          toast.success("Streaming settings updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((error) => {
          console.error("Error updating streaming settings: ", error);
          toast.error("Error updating streaming settings", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setUpdating(false);
        });
    } else {
      toast.warning("Please enter mandatory fields!", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setUpdating(false);
    }
  };

  return (
    <div className="h-100">
      <EventLeftbar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <EventNavbar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <EventLeftbar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 pb-5"
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            {loadingSession ? (
              <Spinner animation="border" />
            ) : (
              <div>
                {/* Section: Session details */}
                <React.Fragment>
                  {session?.starts_at && (
                    <Card
                      // className="border-muted w-100 mb-3"
                      className={
                        darkTheme
                          ? "bg-dark shadow-sm border border-dark w-100 mb-3"
                          : "bg-white shadow-sm border border-light w-100 mb-3"
                      }
                    >
                      <Card.Header
                        style={{
                          backgroundColor: "rgba(231, 244, 255, 1)",
                        }}
                        className={
                          darkTheme
                            ? "mb-0 font-weight-bold text-dark"
                            : "mb-0 font-weight-bold"
                        }
                      >
                        <h6 className="mb-0 font-weight-bold d-flex align-items-start">
                          <small>
                            {session?.is_ended ? (
                              <Badge variant="secondary">Ended</Badge>
                            ) : moment(session?.starts_at).isSame(
                                Date.now(),
                                "day"
                              ) ? (
                              <Badge variant="danger">Live</Badge>
                            ) : moment(session?.starts_at).isAfter(
                                Date.now(),
                                "day"
                              ) ? (
                              <Badge variant="primary">Starting soon</Badge>
                            ) : (
                              <Badge variant="secondary">Ended</Badge>
                            )}
                          </small>
                        </h6>
                      </Card.Header>
                      <Card.Body>
                        <h4 className="mb-1">
                          <b>{session?.EN?.title}</b>
                        </h4>
                        <p className="text-secondary font-weight-bold">
                          {/* {moment(session?.starts_at).format("Do MMM")},{" "} */}
                          {moment(session?.starts_at).format("MMMM Do HH:mm")} -{" "}
                          {moment(session?.ends_at).format("HH:mm")}
                        </p>

                        {session?.EN?.description && (
                          <div>
                            <p className="mb-0">
                              <b>Description</b>
                            </p>
                            {HTMLReactParser(session?.EN?.description)}
                          </div>
                        )}

                        {session?.is_ended ? (
                          // Session: Ended
                          session?.recording_link ? (
                            // Recording: Available
                            <div>
                              <Button
                                size="sm"
                                className="rounded-sm px-3 px-md-5"
                                onClick={() =>
                                  history.push(
                                    `/event/${id}/agenda/${session_id}/live`
                                  )
                                }
                              >
                                <b>Watch Session Recording</b>
                              </Button>
                            </div>
                          ) : (
                            // Recording: Not Available
                            <div>
                              <p className="mb-1 text-danger">
                                <b>Session Ended</b>
                              </p>
                              <Button
                                size="sm"
                                className="rounded-sm px-3 px-md-5"
                                variant="secondary"
                                disabled={true}
                              >
                                <b>Watch Session Recording</b>
                              </Button>
                              <p className="text-dark small my-2">
                                <b>Note: </b>Recording of the session will be
                                uploaded soon
                              </p>
                            </div>
                          )
                        ) : moment(session?.starts_at).isSame(
                            Date.now(),
                            "day"
                          ) ? (
                          // Session: Today
                          <div>
                            <Button
                              size="sm"
                              className="rounded-sm px-3 px-md-5"
                              href={
                                session?.meeting_type === 1
                                  ? currentUserRole >= 1
                                    ? `${session?.start_url}`
                                    : session?.live_streaming &&
                                      session?.streaming_link
                                    ? `/event/${id}/agenda/${session_id}/live`
                                    : `${session?.meeting_url}`
                                  : `/event/${id}/agenda/${session_id}/live`
                              }
                              target={
                                session?.meeting_type === 1 &&
                                !session?.live_streaming
                                  ? "_blank"
                                  : ""
                              }
                            >
                              {session?.meeting_type === 1 ? (
                                <b>Join Session</b>
                              ) : (
                                <b>Join Live Session</b>
                              )}
                            </Button>
                            {session?.meeting_type === 1 &&
                              !session?.live_streaming && (
                                <p className="mt-2 mb-0">
                                  or, you can click on{" "}
                                  <a
                                    href={
                                      currentUserRole >= 1
                                        ? session?.start_url
                                        : session?.meeting_url
                                    }
                                    target="_blank"
                                  >
                                    {currentUserRole >= 1
                                      ? session?.start_url
                                      : session?.meeting_url}
                                  </a>
                                </p>
                              )}
                          </div>
                        ) : moment(session?.starts_at).isAfter(
                            Date.now(),
                            "day"
                          ) ? (
                          // Session: Day after Today
                          <div>
                            <p className="mb-1 text-primary">
                              <b>Starting soon</b>
                            </p>
                            <Button
                              size="sm"
                              className="rounded-sm px-3 px-md-5"
                              variant="secondary"
                              title="Starting soon"
                              disabled={true}
                              href={`/event/${id}/agenda/${session_id}`}
                            >
                              <b>Join Live Session</b>
                            </Button>
                          </div>
                        ) : null}

                        {/* {moment(session?.starts_at).isSame(
                          Date.now(),
                          "day"
                        ) ? (
                          <div>
                            <Button
                              size="sm"
                              className="rounded-sm px-3 px-md-5"
                              href={`/event/${id}/agenda/${session_id}/live`}
                            >
                              <b>Join Live</b>
                            </Button>
                          </div>
                        ) : moment(session?.starts_at).isAfter(
                            Date.now(),
                            "day"
                          ) ? (
                          <div style={{ cursor: "not-allowed" }}>
                            <Button
                              size="sm"
                              className="rounded-sm px-3 px-md-5"
                              variant="secondary"
                              title="Starting soon"
                              disabled={true}
                              href={`/event/${id}/agenda/${session_id}`}
                            >
                              <b>Join Live</b>
                            </Button>
                          </div>
                        ) : null} */}
                      </Card.Body>
                    </Card>
                  )}
                </React.Fragment>

                {/* Section: Streaming settings (only visible for Custom and Igesia meeting type) */}
                {(session?.meeting_type === 1 || session?.meeting_type === 2) &&
                  currentUserRole >= 1 && (
                    <React.Fragment>
                      <Card
                        className={
                          darkTheme
                            ? "bg-dark shadow-sm border border-dark w-100 mb-3"
                            : "bg-white shadow-sm border border-light w-100 mb-3"
                        }
                      >
                        <Card.Header
                          style={{
                            backgroundColor: "rgba(231, 244, 255, 1)",
                          }}
                        >
                          <h6
                            className={
                              darkTheme
                                ? "mb-0 font-weight-bold text-dark"
                                : "mb-0 font-weight-bold"
                            }
                          >
                            Streaming settings
                          </h6>
                        </Card.Header>
                        <Card.Body>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                              <Form.Check
                                id="liveStreaming"
                                type="checkbox"
                                label="Live streaming for the session"
                                name="liveStreaming"
                                value={liveStreaming}
                                checked={liveStreaming}
                                onChange={(e) =>
                                  setLiveStreaming(e.target.checked)
                                }
                              />
                              {session?.meeting_type === 2 && (
                                <Form.Text className="small text-muted">
                                  <b>Note:</b> To start Live streaming
                                  moderator/ speaker have to add "Streaming key"
                                  within session itself.
                                </Form.Text>
                              )}
                            </Form.Group>

                            {liveStreaming && (
                              <Form.Group className="mb-3">
                                <Form.Label className="text-muted text-uppercase font-weight-bold small">
                                  Streaming link{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  name="streaming_link"
                                  type="text"
                                  placeholder="https://www.streaming-url.com"
                                  onChange={(e) =>
                                    setStreamingLink(e.target.value)
                                  }
                                  value={streamingLink}
                                />
                                <Form.Text className="small text-muted">
                                  <b>Note:</b> Streaming will be displayed on
                                  Participant dashboard.
                                </Form.Text>
                              </Form.Group>
                            )}

                            <Button
                              size="sm"
                              type="submit"
                              className="text-uppercase"
                              disabled={updating}
                            >
                              {updating ? (
                                <span>Updating...</span>
                              ) : (
                                <span>Update</span>
                              )}
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </React.Fragment>
                  )}

                {/* Section: Speakers details */}
                <React.Fragment>
                  {session?.speakers && session?.speakers?.length > 0 && (
                    <Card
                      // className="border-muted w-100 mb-3"
                      className={
                        darkTheme
                          ? "bg-dark shadow-sm border border-dark w-100 mb-3"
                          : "bg-white shadow-sm border border-light w-100 mb-3"
                      }
                    >
                      <Card.Header
                        style={{
                          backgroundColor: "rgba(231, 244, 255, 1)",
                        }}
                      >
                        <h6
                          className={
                            darkTheme
                              ? "mb-0 font-weight-bold text-dark"
                              : "mb-0 font-weight-bold"
                          }
                        >
                          Speakers
                        </h6>
                      </Card.Header>
                      <Card.Body className="row">
                        {session?.speakers?.map((speaker, key) => {
                          const facultyData = allSpeakers?.filter(
                            (item) => item.userId === speaker
                          )[0];

                          return (
                            <div
                              key={key}
                              className="col-md-6 d-flex align-items-start p-2 mb-2"
                              title={facultyData?.first_name}
                              style={{ cursor: "pointer" }}
                              // onClick={() => {
                              //   handleShow();
                              //   setSelectedFaculty(facultyData);
                              // }}
                            >
                              <img
                                className="rounded-circle"
                                height="90"
                                width="90"
                                src={facultyData?.img_src}
                                title={facultyData?.first_name}
                                alt=""
                              />
                              <div className="flex-grow-1 ml-2">
                                <h6>
                                  {facultyData?.first_name}{" "}
                                  {facultyData?.last_name}
                                </h6>
                                <p className="small">
                                  {facultyData?.bio
                                    ? HTMLReactParser(facultyData?.bio)
                                    : ""}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  )}
                  <Modal
                    size="lg"
                    // aria-labelledby="contained-modal-title-vcenter"
                    // centered
                    show={show}
                    onHide={() => {
                      handleClose();
                      setSelectedFaculty(null);
                    }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        {selectedFaculty?.name}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ whiteSpace: "pre-line" }}>
                      <div className="d-flex flex-column justify-content-between align-items-center">
                        <img
                          src={selectedFaculty?.img_src}
                          width="150"
                          height="150"
                          className="mr-4 rounded-circle"
                          alt=""
                        ></img>
                        {selectedFaculty?.description
                          .split("\n")
                          .map((para, i) => {
                            return <p key={i}>{para}</p>;
                          })}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          handleClose();
                          setSelectedFaculty(null);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </React.Fragment>
              </div>
            )}
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={[
                {
                  name: "Chat",
                  type: "chat",
                  chatroute: `/events/${currentEvent?.id}/schedule/${session_id}/questionsAsked/`,
                },
              ]}
            />
          </div>
        </div>
      </div>

      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat
            event_id={currentEvent?.id}
            tabs={[
              {
                name: "Chat",
                type: "chat",
                chatroute: `/events/${currentEvent?.id}/schedule/${session_id}/questionsAsked/`,
              },
            ]}
          />
        </div>
      )}

      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  {
    setCurrentUser,
  }
)(Session);
