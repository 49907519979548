import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import {
  faEnvelope,
  faPen,
  faTrash,
  faEllipsisV,
  faCircle as faCircleS,
  faEnvelopeOpen,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import EmailAttendeeModal from "./EmailUserModal";
import {
  faCircle as faCircleR,
  faDotCircle,
} from "@fortawesome/free-regular-svg-icons";

const SpeakersList = (props) => {
  const { speaker, index } = props;
  const [isClicked, setIsClicked] = useState(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </a>
  ));

  return (
    <div className="col-md-6 mb-3 col-12" key={index}>
      <div className="icon-box bg-dark text-light shadow-sm rounded w-100 h-100 p-2">
        <div className="d-flex ">
          <div>
            <Avatar
              name={speaker.first_name + speaker.last_name}
              src={speaker.img_src}
              // style={speaker.is_published ? {border: "4px solid green"} : {border: "4px solid red"} }
              round={true}
              size="40"
              textSizeRatio={1.75}
              className="mr-3"
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="text-s-10 mb-0 text-capitalize align-items-center">
              {speaker.is_published ? (
                <FontAwesomeIcon
                style={{fontSize: "10px"}}
                  icon={faCircleS}
                  className="text-success mr-1 mb-1"
                />
              ) : (
                <FontAwesomeIcon
                style={{fontSize: "10px"}}
                  icon={faDotCircle}
                  className="text-danger mr-1 mb-1"
                />
              )}
              {speaker.first_name + " " + speaker.last_name}
            </h5>
            <p className="small mb-0">{speaker.designation}</p>
            <p className="small text-lighter mb-0">
              {speaker.organisation}
            </p>
          </div>

          <div className="d-flex flex-column justify-content-between">
            <div>
              <Dropdown className="px-1 py-0 pointer">
              <Dropdown.Toggle size="sm" variant="white" as={CustomToggle} />

              <Dropdown.Menu>
                {speaker.email_address ? (
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();
                      props.sendReminder(speaker.email_address);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelopeOpen}
                      size="sm"
                      className="mr-2"
                    />
                    <span>Send Reminder</span>
                  </Dropdown.Item>
                ) : null}
                {speaker.email_address ? (
                  <Dropdown.Item>
                    <EmailAttendeeModal
                      first_name={speaker.first_name}
                      last_name={speaker.last_name}
                      email_address={speaker.email_address}
                    />
                  </Dropdown.Item>
                ) : null}
                <Dropdown.Item
                  onClick={() => props.speakerOnClickHandler(speaker)}
                >
                  <FontAwesomeIcon icon={faPen} size="sm" className="mr-2" />
                  <span>Edit Profile</span>
                </Dropdown.Item>
                {speaker.is_published ? (
                  <Dropdown.Item
                    variant="outline"
                    className="mr-2"
                    onClick={() => props.unPublishSpeakerHandler(speaker.id)}
                  >
                    <FontAwesomeIcon
                      icon={faDotCircle}
                      className="text-danger mr-2"
                    />
                    Unpublish now
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    variant="outline"
                    className=" mr-2"
                    onClick={() => props.publishSpeakerHandler(speaker.id)}
                  >
                    <FontAwesomeIcon
                      icon={faCircleS}
                      className="text-success mr-2"
                    />
                    Publish now
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    props.setSelectedUID(speaker.id);
                    props.setDeleteWarningModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" className="mr-2" />
                  <span>Delete</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </div>
            <div className="text-right">
              <small>{index + 1}</small>
            </div>
          </div>
          {/* <div className="d-flex w-100 ">
            {!speaker.is_published && speaker.email_address ? (
              <Button
                variant="outline"
                className="btn-sm btn-outline-light mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  props.sendReminder(speaker.email_address);
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="sm" />{" "}
                <span>Send Reminder</span>{" "}
              </Button>
            ) : null}
            {speaker.email_address ? (
              <EmailAttendeeModal
                first_name={speaker.first_name}
                last_name={speaker.last_name}
                email_address={speaker.email_address}
              />
            ) : null}
            <Button
              onClick={() => props.speakerOnClickHandler(speaker)}
              variant="outline"
              className="btn-sm btn-outline-primary mr-2"
            >
              {" "}
              <FontAwesomeIcon icon={faPen} size="sm" />{" "}
              <span>Edit Profile</span>
            </Button>
            {speaker.is_published ? (
              <div>
                <Button
                  variant="outline"
                  className="btn-sm btn-outline-warning mr-2"
                  onClick={() => props.unPublishSpeakerHandler(speaker.id)}
                >
                  Unpublish
                </Button>
              </div>
            ) : (
              <Button
                variant="outline"
                className="btn-sm btn-outline-warning mr-2"
                onClick={() => props.publishSpeakerHandler(speaker.id)}
              >
                Publish
              </Button>
            )}
            <Button
              variant="outline"
              className="btn-sm btn-outline-danger mr-2"
              onClick={() => {
                props.setSelectedUID(speaker.id);
                props.setDeleteWarningModal(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="sm" /> <span>Delete</span>
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default SpeakersList;
