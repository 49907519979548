import {
  faEllipsisH,
  faThumbsUp,
  faComments,
  faImage,
  faTimes,
  faVideo,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Tabs, Tab, Image, Form, Modal } from "react-bootstrap";
import Header from "./Header";
import CustomContentImg from "./assets/custom-content.png";
import * as TimelineStyle from "./timeline.module.css";
import { timeFromNow } from "../../utils";

import ReadMore from "../ReadMore";
import TimelineCover from "./TimelineCover";
import MySpace from "./MySpace";
import Courses from "./Courses";
import Consultations from "./Consultations";
import Events from "./Events";
import Projects from "./Projects";
import Blogs from "./Blogs";
import Spotlight from "./Spotlight";
import MembersCard from "./MembersCard";
import AboutCard from "./AboutCard";

export default function Timeline() {
  //const user = firebase?.auth()?.currentUser;

  const [filter, setFilter] = useState(100);
  const [show, setShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const postRef = useRef(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    postRef && postRef?.current?.focus();
  }, [show, postRef]);

  const handlePostSubmit = (e) => {
    e.preventDefault();

    if (selectedImg != null) {
      setTimeLineData([
        { ...newPost, image_url: selectedImg },
        ...timeLineData,
      ]);
    } else {
      setTimeLineData([newPost, ...timeLineData]);
    }

    setNewPost((prev) => {
      let newState = {
        ...prev,
        content: "",
      };
      return newState;
    });

    setSelectedImg(null);

    handleClose();
  };

  const handlePickImage = () => {
    inputFileRef?.current?.click();
  };

  const handleReadImage = (e) => {
    if (e?.target && e?.target?.files[0])
      setSelectedImg(URL.createObjectURL(e.target.files[0]));
  };

  const [timeLineData, setTimeLineData] = useState([
    {
      id: 1,
      created_by: {
        first_name: "Jatin",
        last_name: "Ahuja",
        designation: "Software Developer",
        organisation: "Igesia inc.",
        photo_url:
          "https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/drive%2FKQ1gHZ9qhdSrfJFqK4sWa5pfFK13%2F1619012043493-1590003994116-jkTTzjk7mng0kdbW9P80SRfEinc2.png?alt=media&token=421c657f-97de-4b74-b8e0-fb2f719147b6",
      },
      created_at: 1623316266150,
      content: `Thanks to all the participants of #BadRoadBuddies who shared great travel stories with us! There are a few more winners for the contest, so kindly check your DMs and learn more about how to claim your prizes! A huge congratulations to all of you too! 🎉 \n https://timesofindia.indiatimes.com/india/nha-chief-vaccine-demand-supply-gap-reduced-from-111-to-6-51/articleshow/82957236.cms`,
      type: 0, // 0 - post, 1 - blog
      url: "https://timesofindia.indiatimes.com/india/nha-chief-vaccine-demand-supply-gap-reduced-from-111-to-6-51/articleshow/82957236.cms",
      url_data: {
        image:
          "https://static.toiimg.com/thumb/msid-82957935,width-1070,height-580,imgsize-160666,resizemode-75,overlay-toi_sw,pt-32,y_pad-40/photo.jpg",
        title:
          "NHA chief: Vaccine demand-supply gap reduced from 11:1 to 6.5:1 | India News - Times of India",
        description:
          "India News: The demand and supply mismatch for Covid-19 vaccines remains high at 6.5:1, but has improved from 11:1 a week ago, reducing the waiting time on Co-WIN",
        source: "The Times of India",
      },
      comments: [],
    },
    {
      id: 2,
      created_by: {
        first_name: "Natasha",
        last_name: "",
        designation: "Presidential Investment Office of Turkey",
        organisation: "US Country Director",
        photo_url:
          "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822",
      },
      created_at: 1623300256150,
      content: `Many countries strive to improve access to healthcare and improved value to patients. Using innovation and technology to drive universal healthcare in low- and middle-income countries make a lot of sense, but can it be done in a cost-effective way? COVID-19 had such a negative impact on the economy of our countries and we are struggling to provide enough trained healthcare workers into the field. Is there a space to discuss how we can learn from places like China (or others) a step-by-step approach on how technology is used to make access to healthcare more relevant and possible in their countries? Is it possible to improve access to primary healthcare by using digital platforms, artificial intelligence and where should one start if you want to do this? I am interested in setting up a think-tank to look at these issues with a focus on cost-effective, value-based healthcare that is implementable in LMIC’s.`,
      type: 0, // 0 - post, 1 - blog
      comments: [],
    },
    {
      id: 3,
      created_by: {
        first_name: "Arani",
        last_name: "Chaudhuri",
        designation: "CEO",
        organisation: "Igesia inc.",
        photo_url:
          "https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/avatars%2Fusers%2FI8cgqMjuHfN1iVrUPJ2bznuglfx1?alt=media&token=3f524195-3070-4ce0-9b1f-8bd301231bdb",
      },
      created_at: 1623215256150,
      content: `https://www.theverge.com/2021/3/15/22332539/microsoft-teams-down-outage-connectivity-issues`,
      type: 1, // 0 - post, 1 - blog
      url: "https://www.theverge.com/2021/3/15/22332539/microsoft-teams-down-outage-connectivity-issues",
      url_data: {
        image:
          "https://cdn.vox-cdn.com/thumbor/YKSy6Da2U2YTo7fNiqe5PRUSMD0=/0x189:2640x1571/fit-in/1200x630/cdn.vox-cdn.com/uploads/chorus_asset/file/19344713/microsoftteams.jpg",
        title:
          "Microsoft Teams, Exchange and more are still recovering from a Monday outage - The Verge",
        description: "The issues started at around 3:30PM ET.",
        source: "The Verge",
      },
      comments: [],
    },
  ]);

  const [addComment, setAddComment] = useState();

  const [newComment, setNewComment] = useState({
    first_name: "Guest",
    last_name: "User",
    content: "",
    designation: "Developer",
    created_at: Date.now(),
    organisation: "Igesia inc.",
    photo_url:
      "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822",
  });

  const [newPost, setNewPost] = useState({
    id: timeLineData.length + 1,
    created_by: {
      first_name: "Guest",
      last_name: "User",
      designation: "Developer",
      organisation: "Igesia inc.",
      photo_url:
        "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822",
    },
    created_at: Date.now(),
    type: 0,
    content: "",
  });

  const submitPostHandler = (item) => {
    let data = timeLineData.map((timeline) => {
      if (timeline.id === item.id) {
        return {
          ...timeline,
          comments: [...timeline.comments, newComment],
        };
      } else {
        return { ...timeline };
      }
    });
    setTimeLineData(data);
    setAddComment("");
    setNewComment((prev) => {
      let newState = {
        ...prev,
        content: "",
      };
      return newState;
    });
  }

  return (
    <div className="container-fluid px-0 bg-graphite full-screen">
      {/* <Header /> */}

      {/* Timeline cover starts */}
      <TimelineCover />
      {/* Timeline cover ends */}

      {/* Call to action starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row mb-3 mb-md-4">
          {/* Left column */}
          <div className="col-md-4">
            {/* About starts */}
            <AboutCard />
            {/* About ends */}

            {/* Members starts */}
            <MembersCard />
            {/* Members ends */}
          </div>
          <div className="col-md-8">
            <Spotlight />
          </div>
        </div>

        <MySpace />
      </div>
      {/* Call to action ends */}

      {/* Courses start */}
      <Courses />
      {/* Courses end */}

      {/* Consultations start */}
      <Consultations />
      {/* Consultations end */}

      {/* Events start */}
      <Events />
      {/* Events end */}

      {/* Projects start */}
      <Projects />
      {/* Projects end */}

      {/* Blog start */}
      <Blogs />
      {/* Projects end */}

      {/* Main content starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          {/* Left column */}
          <div className="col-md-4">
            {/* Custom content starts */}
            <Card className={`shadow-sm ${TimelineStyle.custom_content_card}`}>
              <Card.Body className="p-3">
                <p className="text-white font-weight-bold">Custom content</p>
                <div className="d-flex justify-content-center">
                  <Image src={CustomContentImg} />
                </div>
              </Card.Body>
            </Card>
            {/* Custom content ends */}
          </div>

          {/* Right column */}
          <div className="col-md-8">
            {/* Write post starts */}
            <Card
              className={`shadow-sm  mb-2 ${TimelineStyle.write_post_card}`}
            >
              <Card.Body className="d-flex align-items-center p-3">
                <Image
                  src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822"
                  roundedCircle
                  height="50"
                  className="mr-3"
                />

                <a
                  title="Click to Add a post"
                  onClick={handleShow}
                  style={{ cursor: "pointer", textDecoration: "none" }}
                >
                  <p className="text-white lead mb-0">What's on your mind?</p>
                </a>
              </Card.Body>
            </Card>
            {/* Write post ends */}

            {/* Filter timeline data starts */}
            <Card className={`mb-3 mb-md-4 ${TimelineStyle.filter_card}`}>
              <Card.Header className="p-0">
                <Tabs
                  activeKey={filter}
                  onSelect={(k) => {
                    setFilter(parseInt(k));
                  }}
                  id="filters"
                  variant="pills"
                  className={`p-0 m-0`}
                >
                  <Tab
                    eventKey={100}
                    title="All"
                    tabClassName={TimelineStyle.filter_tabs}
                  />
                  <Tab
                    eventKey={0}
                    title="Posts"
                    tabClassName={TimelineStyle.filter_tabs}
                  />
                  <Tab
                    eventKey={1}
                    title="Blogs"
                    tabClassName={TimelineStyle.filter_tabs}
                  />
                  <Tab
                    eventKey={2}
                    title="Podcasts"
                    tabClassName={TimelineStyle.filter_tabs}
                  />
                  <Tab
                    eventKey={3}
                    title="Videos"
                    tabClassName={TimelineStyle.filter_tabs}
                  />
                </Tabs>
              </Card.Header>
            </Card>
            {/* Filter timeline data ends */}

            {/* Post data starts */}
            {timeLineData.map(
              (item, key) =>
                (item.type === filter || filter == 100) && (
                  <Card
                    key={key}
                    className={`shadow-sm mb-3 mb-md-4 ${TimelineStyle.post_card}`}
                  >
                    <Card.Header className="d-flex">
                      <div className="mr-2">
                        <Image
                          src={item?.created_by?.photo_url}
                          roundedCircle
                          height="50"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-end">
                          <p className="m-0 font-weight-bold mr-2">
                            {item?.created_by?.first_name}{" "}
                            {item?.created_by?.last_name}
                          </p>
                          <p className="m-0 small">
                            {/* {moment(item.created_at).format("LLL")} */}
                            {timeFromNow(item.created_at)}
                          </p>
                        </div>
                        <p className="mb-0">
                          {item?.created_by?.designation},{" "}
                          {item?.created_by?.organisation}
                        </p>
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </div>
                    </Card.Header>
                    <Card.Body className="py-2">
                      {item.content && (
                        <p>
                          <ReadMore full_text={item.content} max_length="250" />
                        </p>
                      )}
                      {item.image_url && (
                        <Image src={item.image_url} className="img-fluid" />
                      )}
                      {item.url_data && (
                        <div
                          className="d-flex align-items-start p-2 rounded-lg"
                          style={{ background: "#232626" }}
                        >
                          <div className="mr-2">
                            <Image
                              src={item?.url_data?.image}
                              rounded
                              height="100"
                            />
                          </div>
                          <div className="flex-grow-1">
                            {item?.url_data?.title && (
                              <p className="font-weight-bold mb-0">
                                {item?.url_data?.title}
                              </p>
                            )}
                            {item?.url_data?.description && (
                              <p className="text-muted mb-0">
                                {item?.url_data?.description}
                              </p>
                            )}
                            {item?.url_data?.source && (
                              <p className="font-weight-bold small mb-0">
                                {item?.url_data?.source}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </Card.Body>
                    <Card.Footer className="row m-0 p-0">
                      <div
                        className="col-6 text-center border border-dark p-2"
                        onClick={() => {
                          if (!item?.isLiked) {
                            let data = timeLineData.map((timeline) => {
                              if (timeline.id === item.id) {
                                return { ...timeline, isLiked: true };
                              } else {
                                return { ...timeline };
                              }
                            });
                            setTimeLineData(data);
                          } else {
                            console.log("called1");
                            let data = timeLineData.map((timeline) => {
                              if (timeline.id === item.id) {
                                return { ...timeline, isLiked: false };
                              } else {
                                return { ...timeline };
                              }
                            });
                            setTimeLineData(data);
                          }
                        }}
                      >
                        <div
                          className={
                            item?.isLiked ? "text-primary" : "text-light"
                          }
                        >
                          <FontAwesomeIcon icon={faThumbsUp} />
                          <span className="ml-1">Like</span>
                        </div>
                      </div>
                      <div className="col-6 text-center border border-dark p-2">
                        <div onClick={() => setAddComment(item)}>
                          <FontAwesomeIcon icon={faComments} />
                          <span className="ml-1">Comment</span>
                        </div>
                      </div>
                    </Card.Footer>

                    {item?.comments?.length > 0 && (
                      <>
                        {item?.comments?.map((comment) => (
                          <div className="p-2 mx-2 row">
                            <div className="mr-2">
                              <Image
                                src={comment?.photo_url}
                                roundedCircle
                                height="50"
                              />
                            </div>
                            <div className="flex-grow-1 p-2 rounded" style={{backgroundColor: "#605d5d99"}}>
                              <div className="d-flex align-items-end">
                                <p className="m-0 font-weight-bold mr-2">
                                  {comment?.first_name}
                                </p>
                              </div>
                              <p className="mb-0">{comment.content}, </p>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {addComment?.id === item.id && (
                      <div className="p-2 d-flex bd-highlight">
                        <Form
                          className="p-2 w-100 bd-highlight"
                          onSubmit={(e) => {
                            e.preventDefault();
                            submitPostHandler(item);
                          }}
                        >
                          <Form.Group className="" controlId="comment">
                            <Form.Control
                              style={{backgroundColor: "#605d5d99"}}
                              type="text"
                              name="content"
                              className="rounded-pill text-white border-0"
                              width="100%"
                              placeholder="Add your comment"
                              value={newComment?.content}
                              onChange={(e) => {
                                setNewComment((prev) => {
                                  let newState = {
                                    ...prev,
                                    content: e.target.value,
                                  };
                                  return newState;
                                });
                              }}
                            />
                          </Form.Group>
                        </Form>
                        <Button
                          className="p-0 flex-shrink-1 bd-highlight mb-2"
                          variant="transparent"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            submitPostHandler(item);
                          }}
                        >
                          <FontAwesomeIcon className="text-primary mt-0"icon={faPaperPlane} />
                        </Button>
                      </div>
                    )}
                  </Card>
                )
            )}

            {/* Post data ends */}
          </div>
        </div>
      </div>
      {/* Main content ends */}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className={TimelineStyle.write_post_modal}
        id="post-modal"
      >
        <Modal.Header
          className={`shadow-sm border-bottom border-dark d-flex align-items-center ${TimelineStyle.write_post_modal_header}`}
        >
          <div className="flex-grow-1">
            <p
              className="text-white h5 text-center mb-0 font-weight-bold"
              style={{ color: "#E3E6EA" }}
            >
              Create Post
            </p>
          </div>
          <a
            onClick={handleClose}
            className={`${TimelineStyle.write_post_modal_close_btn}`}
          >
            ×
          </a>
        </Modal.Header>
        <Modal.Body className={`p-0 ${TimelineStyle.write_post_modal_body}`}>
          <div className="d-flex align-items-center mb-2 p-2 px-3">
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822"
              roundedCircle
              height="30"
              className="mr-2"
            />
            <p className="text-white font-weight-bold mb-0">Guest User</p>
          </div>

          <div className={`p-3 ${TimelineStyle.write_post_modal_form}`}>
            <Form>
              <Form.Group controlId="content">
                <Form.Control
                  ref={postRef}
                  as="textarea"
                  type="text"
                  rows="4"
                  name="content"
                  className={`text-white border-0 ${TimelineStyle.write_post_card_text_area}`}
                  placeholder="What's on your mind?"
                  value={newPost?.content}
                  onChange={(e) => {
                    setNewPost((prev) => {
                      let newState = {
                        ...prev,
                        content: e.target.value,
                      };
                      return newState;
                    });
                  }}
                />
              </Form.Group>
              {selectedImg && (
                <div
                  className="px-3 border border-dark"
                  style={{ borderRadius: "0.75rem", position: "relative" }}
                >
                  <Image src={selectedImg} className="img-fluid" />
                  <a
                    onClick={() => setSelectedImg(null)}
                    className={TimelineStyle.write_post_modal_remove_img_btn}
                  >
                    ×
                  </a>
                </div>
              )}
            </Form>
          </div>

          <div className="p-3">
            <div className="d-flex border border-dark rounded-lg p-2 mb-2">
              <div className="flex-grow-1">
                <p className="text-white mb-0" style={{ fontWeight: 600 }}>
                  Add to Your Post
                </p>
              </div>
              <div>
                <Form.File
                  ref={inputFileRef}
                  accept="image/*"
                  onChange={handleReadImage}
                  style={{ height: 0, width: 0 }}
                />
                <a
                  onClick={handlePickImage}
                  title="Add image"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faImage}
                    className="text-success"
                  />
                </a>
              </div>
            </div>
            <Button
              type="submit"
              onClick={handlePostSubmit}
              className="small w-100"
              style={{ fontWeight: 600 }}
            >
              Post
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
