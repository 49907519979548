import React, { memo } from "react";
import {
  faBars,
  faCalendarAlt,
  faClipboardList,
  faTasks,
  faTrash,
  faVideo,
  faFile,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SubModule(props) {
  // CONSTANTS
  const {
    idx,
    data,
    selectedSubModuleData,
    handleOpenSubModule,
    handleShowEditSubModuleModal,
    handleRemoveSubModule,
  } = props;

  // STATES
  // FUNCTIONS
  const icon =
    data.type === "schedule"
      ? faCalendarAlt
      : data.type === "assignment"
      ? faClipboardList
      : data.type === "quiz"
      ? faTasks
      : data.type === "lecture"
      ? faVideo
      : faFile;

  return (
    <div
      className={
        selectedSubModuleData?.sub_module_id === data?.id
          ? "d-flex align-items-center rounded mb-2 border border-white"
          : "d-flex align-items-center rounded mb-2"
      }
    >
      <div className="py-2 pl-3 pr-2">
        <FontAwesomeIcon icon={faBars} className="pointer small" />
      </div>
      <div
        className="flex-grow-1 p-2"
        onClick={() => handleOpenSubModule(data)}
        style={{ cursor: "pointer" }}
      >
        <p className="mb-0">
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {data.title}
        </p>
      </div>
      <div className="py-2 pr-3 pl-2">
        <a
          onClick={() => handleShowEditSubModuleModal(data)}
          className="mr-2 pointer small"
          title="Edit item"
        >
          <FontAwesomeIcon icon={faEdit} className="text-light" />
        </a>
        <a
          onClick={() => handleRemoveSubModule(data)}
          className="pointer"
          title="Remove item"
        >
          <FontAwesomeIcon icon={faTrash} className="text-danger" />
        </a>
      </div>
    </div>
  );
}

export default memo(SubModule);
