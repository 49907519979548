import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClipboardList,
  faTasks,
  faVideo,
  faFile,
  faTimes,
  faThLarge,
  faRedo,
  faSortAlphaDownAlt,
  faSortAlphaUpAlt,
  faFilter,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  ADMIN_GET_MODULES,
  ADMIN_PROGRESS_REPORT_GET,
  API_BASE_URL,
} from "../../../../config";
import { auth } from "../../../../firebase";
import ReactExport from "react-export-excel";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";

function Report(props) {
  // CONSTANTS
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const { currentEvent } = props;

  // STATES
  const [showReportDetails, setShowReportDetails] = useState(false);
  const [fetchingReportList, setFetchingReportList] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [filter, setFilter] = useState("firstNameAsc");
  const [selectedReport, setSelectedReport] = useState(null);
  const [fetchingModules, setFetchingModules] = useState(false);
  const [modules, setModules] = useState([]);
  const [progressListFilter, setProgressListFilter] = useState("");

  // FUNCTIONS
  const SubModuleIcon = ({ type, className }) => {
    const icon =
      type === "schedule"
        ? faCalendarAlt
        : type === "assignment"
        ? faClipboardList
        : type === "quiz"
        ? faTasks
        : type === "lecture"
        ? faVideo
        : faFile;
    return <FontAwesomeIcon icon={icon} className={className} />;
  };

  const getItemDetails = (parent_id, id) => {
    const moduleData = modules?.filter((e) => e.id === parent_id)[0];
    const subModuleData = moduleData?.sub_modules?.filter(
      (e) => e.id === id
    )[0];

    return (
      <span>
        <SubModuleIcon type={subModuleData?.type} /> {subModuleData?.title}
      </span>
    );
  };

  const handleFetchModules = async () => {
    setFetchingModules(true);
    try {
      const response = await axios.post(`${API_BASE_URL}${ADMIN_GET_MODULES}`, {
        event_id: currentEvent?.id,
        user_id: auth?.currentUser?.uid,
      });

      if (response.status === 200) {
        const { data } = response.data;

        setModules(data);
        setFetchingModules(false);
      } else {
        setFetchingModules(false);
      }
    } catch (error) {
      console.log("[Error] Fetching modules: ", error);
      setFetchingModules(false);
    }
  };

  const handleOpenReport = (report) => {
    setShowReportDetails(true);
    setSelectedReport(report);
    console.log("report: ", report);
    console.log("modules: ", modules);
  };

  const handleCloseReport = () => {
    setShowReportDetails(false);
    setSelectedReport(null);
  };

  const handleFetchReportList = async (cbFetching, cbList, type) => {
    try {
      cbFetching(true);
      const response = await axios.get(
        `${API_BASE_URL}${ADMIN_PROGRESS_REPORT_GET}?user_id=${auth?.currentUser?.uid}&product_id=${currentEvent?.id}`
      );

      if (response.status === 200) {
        cbFetching(false);
        cbList(response?.data?.data);
      } else {
        cbFetching(false);
      }
    } catch (error) {
      console.log(`[Error] Fetching report list: `, error.message);
      cbFetching(false);
    }
  };

  const handleRefresh = () => {
    // Fetch: Report list
    handleFetchReportList(setFetchingReportList, setReportList);
  };

  const getItemDetailsOnly = (parent_id, id) => {
    const moduleData = modules?.filter((e) => e.id === parent_id)[0];
    const subModuleData = moduleData?.sub_modules?.filter(
      (e) => e.id === id
    )[0];

    return subModuleData;
  };

  useEffect(() => {
    // Fetch: Report list
    handleFetchReportList(setFetchingReportList, setReportList);

    // Fetch: Modules list
    handleFetchModules();
  }, [currentEvent]);

  return (
    <React.Fragment>
      <div className="p-3" style={{ height: "100vh", overflowY: "auto" }}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <h4 className="text-white">
              {currentEvent?.type === 1 ? "Class Report" : "Report"}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className={showReportDetails ? "col-md-6" : "col-md-12"}>
            <div className="text-white border border-secondary">
              <div className="d-flex align-items-end justify-content-between px-3 py-2">
                <div className="d-flex flex-column justify-content-start">
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <span>
                        {filter === "" ? (
                          <span>
                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                            Order by
                          </span>
                        ) : filter === "firstNameDesc" ? (
                          <span>
                            <FontAwesomeIcon
                              icon={faSortAlphaDownAlt}
                              className="mr-2"
                            />
                            First name (Z - A)
                          </span>
                        ) : filter === "firstNameAsc" ? (
                          <span>
                            <FontAwesomeIcon
                              icon={faSortAlphaUpAlt}
                              className="mr-2"
                            />
                            First name (A - Z)
                          </span>
                        ) : filter === "lastNameDesc" ? (
                          <span>
                            <FontAwesomeIcon
                              icon={faSortAlphaDownAlt}
                              className="mr-2"
                            />
                            Last name (Z - A)
                          </span>
                        ) : (
                          <span>
                            <FontAwesomeIcon
                              icon={faSortAlphaUpAlt}
                              className="mr-2"
                            />
                            Last name (A - Z)
                          </span>
                        )}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        style={
                          filter === "firstNameAsc"
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("firstNameAsc")}
                      >
                        <FontAwesomeIcon
                          icon={faSortAlphaUpAlt}
                          className="mr-2"
                        />
                        First name (A - Z)
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={
                          filter === "firstNameDesc"
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("firstNameDesc")}
                      >
                        <FontAwesomeIcon
                          icon={faSortAlphaDownAlt}
                          className="mr-2"
                        />
                        First name (Z - A)
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={
                          filter === "lastNameDesc"
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("lastNameDesc")}
                      >
                        <FontAwesomeIcon
                          icon={faSortAlphaDownAlt}
                          className="mr-2"
                        />
                        Last name (A - Z)
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={
                          filter === "lastNameAsc"
                            ? { backgroundColor: "rgba(51,51,51,0.25)" }
                            : {}
                        }
                        onClick={() => setFilter("lastNameAsc")}
                      >
                        <FontAwesomeIcon
                          icon={faSortAlphaUpAlt}
                          className="mr-2"
                        />
                        Last name (Z - A)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex align-items-end">
                  {fetchingReportList ? (
                    <div className="mr-3">
                      <p className="mb-0">Fetching...</p>
                    </div>
                  ) : (
                    <ExcelFile
                      filename={`Report-${Date.now()}`}
                      element={
                        <button className="mr-2 btn btn-outline-light btn-sm">
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            className="mr-2"
                          />
                          Report
                        </button>
                      }
                    >
                      <ExcelSheet data={reportList} name="Report">
                        <ExcelColumn
                          label="Name"
                          value={(participant) =>
                            participant.firstName + " " + participant.lastName
                          }
                        />
                        <ExcelColumn
                          label="Email"
                          value={(participant) => participant.email}
                        />
                        <ExcelColumn
                          label="Total items"
                          value={(participant) => participant.total_items}
                        />
                        <ExcelColumn
                          label="Completed items"
                          value={(participant) => participant.completed_items}
                        />
                        <ExcelColumn
                          label="Progress %"
                          value={(participant) =>
                            participant.progress_percentage
                          }
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                  <Button
                    variant="light"
                    size="sm"
                    onClick={handleRefresh}
                    disabled={fetchingReportList}
                    // className="mr-3"
                  >
                    {fetchingReportList ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faRedo} />
                    )}{" "}
                    Refresh
                  </Button>
                  <div className="d-flex flex-column justify-content-end"></div>
                </div>
              </div>

              {fetchingReportList && (
                <p className="text-center border-top p-3 mb-0">
                  <Spinner animation="border" size="sm" /> Kindly wait as we
                  generate your report. Please do not navigate away from this
                  page.
                </p>
              )}

              <table class="table table-borderless text-white">
                <thead className="border-top border-secondary">
                  <tr className="">
                    <th className="py-1 font-weight-bold small">#</th>
                    <th className="py-1 font-weight-bold small">Name</th>
                    <th className="py-1 font-weight-bold small">Email</th>
                    <th className="py-1 font-weight-bold small">Progress %</th>
                    <th className="py-1 font-weight-bold small">Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {reportList
                    ?.sort((a, b) => {
                      if (filter === "") {
                        return true;
                      } else if (filter === "firstNameAsc") {
                        return a.firstName.localeCompare(b.firstName);
                      } else if (filter === "firstNameDesc") {
                        return b.firstName.localeCompare(a.firstName);
                      } else if (filter === "lastNameAsc") {
                        return a.lastName.localeCompare(b.lastName);
                      } else if (filter === "lastNameDesc") {
                        return b.lastName.localeCompare(a.lastName);
                      } else {
                        return false;
                      }
                    })
                    ?.map((item, key) => (
                      <tr
                        className="border-top border-secondary align-items-center pointer"
                        key={key}
                        onClick={() => handleOpenReport(item)}
                        style={
                          selectedReport?.id === item.id
                            ? {
                                fontSize: "90%",
                                backgroundColor: "rgba(200, 200, 200, 0.15)",
                              }
                            : { fontSize: "90%" }
                        }
                      >
                        <td>{key + 1}</td>
                        <td>
                          {item?.firstName} {item?.lastName}
                        </td>
                        <td>{item?.email}</td>
                        {/* <td
                        className={
                          parseInt(item?.user_role) === 1
                            ? "font-weight-bold"
                            : ""
                        }
                      >
                        {parseInt(item?.user_role) === 1
                          ? "Moderator"
                          : "Participant"}
                      </td> */}
                        <td>{item?.progress_percentage}%</td>
                        <td>
                          {item?.completed_items} / {item?.total_items}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {showReportDetails && (
            <div className="col-md-6 p-0 m-0 h-100">
              <div className="border border-secondary text-white mr-3">
                <div className="p-3 d-flex align-items-center border-bottom border-secondary">
                  <h5 className="flex-grow-1 mb-0">
                    {selectedReport?.firstName} {selectedReport?.lastName}
                  </h5>
                  <a
                    className="text-white pointer p-2"
                    onClick={handleCloseReport}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </a>
                </div>
                <div className="p-3">
                  <div className="my-3">
                    <p className="text-muted small mb-0">Name</p>
                    <p className="small mb-0">
                      {selectedReport?.firstName} {selectedReport?.lastName}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-muted small mb-0">Email</p>
                    <p className="small mb-0">{selectedReport?.email}</p>
                  </div>
                  {/* <div className="mb-3">
                    <p className="text-muted small mb-0">User role</p>
                    <p className="small mb-0">
                      {parseInt(selectedReport?.user_role) === 1
                        ? "Moderator"
                        : "Participant"}
                    </p>
                  </div> */}
                  <div className="mb-3">
                    <p className="text-muted small mb-0">Total items</p>
                    <p className="small mb-0 text-uppercase">
                      {selectedReport?.total_items}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-muted small mb-0">Completed items</p>
                    <p className="small mb-0 text-uppercase">
                      {selectedReport?.completed_items}
                    </p>
                  </div>
                  <div className="mb-3">
                    <p className="text-muted small mb-0">Progress %</p>
                    <p className="small mb-0 text-uppercase">
                      {selectedReport?.progress_percentage}%
                    </p>
                  </div>
                </div>

                <div className="border-top border-secondary">
                  <div className="d-flex align-items-center p-2">
                    <p className="text-white mb-0 flex-grow-1">Progress list</p>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-basic"
                        size="sm"
                      >
                        <span>
                          {progressListFilter === "" ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFilter}
                                className="mr-2"
                              />
                              All
                            </span>
                          ) : progressListFilter === "done" ? (
                            <span>✅ Done</span>
                          ) : (
                            <span>Not done</span>
                          )}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={
                            progressListFilter === ""
                              ? { backgroundColor: "rgba(51,51,51,0.25)" }
                              : {}
                          }
                          onClick={() => setProgressListFilter("")}
                        >
                          <FontAwesomeIcon icon={faThLarge} className="mr-2" />
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={
                            progressListFilter === "done"
                              ? { backgroundColor: "rgba(51,51,51,0.25)" }
                              : {}
                          }
                          onClick={() => setProgressListFilter("done")}
                        >
                          ✅ Done
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={
                            progressListFilter === "not"
                              ? { backgroundColor: "rgba(51,51,51,0.25)" }
                              : {}
                          }
                          onClick={() => setProgressListFilter("not")}
                        >
                          Not done
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <table class="table table-borderless text-white">
                    <thead className="border-top border-secondary">
                      <tr className="">
                        <th className="py-1 font-weight-bold small">#</th>
                        <th className="py-1 font-weight-bold small">Name</th>
                        <th className="py-1 font-weight-bold small">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedReport?.progress
                        ?.filter((e) => {
                          if (progressListFilter === "") {
                            return true;
                          } else if (progressListFilter === "done") {
                            return e.progress_status;
                          } else {
                            return !e.progress_status;
                          }
                        })
                        ?.map((item, key) => (
                          <tr
                            className="border-top border-secondary align-items-center pointer"
                            key={key}
                          >
                            <td>{key + 1}</td>
                            <td>{getItemDetails(item?.parent_id, item?.id)}</td>
                            <td>
                              {item?.progress_status ? "✅ Done" : "Not done"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  currentEvent: state.currentEvent,
});

export default connect(mapStateToProps)(Report);
