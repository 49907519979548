import React, { memo, useMemo, useEffect, useState } from "react";
import {
  faCalendarAlt,
  faClipboardList,
  faTasks,
  faVideo,
  faFile,
  faAngleDown,
  faAngleUp,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { Accordion, Card, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import { auth } from "../../../../firebase";
import { API_BASE_URL, USER_SUB_MODULE_PROGRESS } from "../../../../config";

function Module(props) {
  // CONSTANTS
  const {
    module_data,
    idx,
    accordion,
    is_public,
    id,
    module_id,
    sub_module_type,
    sub_module_id,
    progress,
  } = props;
  const history = useHistory();

  // STATES
  const [accordionKey, setAccordionKey] = useState("0");

  // FUNCTIONS
  const handleOpenSubModule = (data) => {
    if (id && module_id && sub_module_id && sub_module_type) {
      history.push(
        `/event/${id}/module/${data?.parent_id}/${data?.type}/${data?.id}`
      );
    } else {
      history.push(`./module/${data?.parent_id}/${data?.type}/${data?.id}`);
    }
  };

  const handleSetProgress = async (data) => {
    const progressDetails = {
      ancestor_id: data?.ancestor_id,
      parent_id: data?.parent_id,
      child_id: data?.id,
      user_id: auth?.currentUser?.uid,
    };

    let additionalDetails = {};

    if (data.type === "schedule" || data.type === "file") {
      additionalDetails = { state: "complete" };
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}${USER_SUB_MODULE_PROGRESS}`,
        { ...progressDetails, ...additionalDetails }
      );

      if (response.status === 200) {
        const { data } = response.data;
        if (data?.status) {
          toast.dark(
            <div>
              {data?.state === "complete" ? (
                <p className="mb-0">🎊 Completed 🎊</p>
              ) : (
                <p className="mb-0">✅ Marked done</p>
              )}
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: false,
              autoClose: 3000,
            }
          );
        }
      }
    } catch (error) {
      console.log(`[Error] Setting progress: ${error.message}`);
    }
  };

  const SubModuleName = ({ idx, sub_module_data, accordion }) => {
    const subModuleProgress = useMemo(
      () =>
        progress?.filter(
          (e) =>
            e.id === sub_module_data?.id &&
            e.parent_id === sub_module_data?.parent_id
        )[0],
      [progress]
    );

    const icon =
      sub_module_data?.type === "schedule"
        ? faCalendarAlt
        : sub_module_data?.type === "assignment"
        ? faClipboardList
        : sub_module_data?.type === "quiz"
        ? faTasks
        : sub_module_data?.type === "lecture"
        ? faVideo
        : sub_module_data?.type === "form"
        ? faAddressCard
        : faFile;

    const content_type =
      sub_module_data?.type === "schedule"
        ? "Live Session"
        : sub_module_data?.type === "assignment"
        ? "assignment"
        : sub_module_data?.type === "quiz"
        ? "quiz"
        : sub_module_data?.type === "lecture"
        ? "lecture"
        : sub_module_data?.type === "form"
        ? "form"
        : "document";

    if (accordion) {
      return (
        <div
          key={idx}
          className="rounded-0 py-2 px-3 pointer"
          title={`${content_type?.toUpperCase()}: ${sub_module_data?.title}`}
          style={
            sub_module_data?.parent_id === module_id &&
            sub_module_data?.id === sub_module_id
              ? { backgroundColor: "rgba(51,51,51,0.25)" }
              : {}
          }
        >
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center custom-checkmark">
              {!is_public && (
                <Form.Check
                  id={`${sub_module_data?.id}`}
                  name={`${sub_module_data?.id}`}
                  label={""}
                  type="checkbox"
                  size="sm"
                  onChange={() => handleSetProgress(sub_module_data)}
                  checked={
                    subModuleProgress?.progress_status ||
                    subModuleProgress?.progress_state === "complete"
                  }
                />
              )}
              <div
                onClick={() => {
                  if (is_public) {
                    return false;
                  } else if (auth?.currentUser) {
                    handleOpenSubModule(sub_module_data);
                  } else {
                    return false;
                  }
                }}
                className="flex-grow-1 mb-0"
              >
                {/* For clickable area next to mark as complete button */}
              </div>
              <FontAwesomeIcon
                icon={icon}
                className="mr-2 pl-2"
                style={{ width: "20px" }}
              />{" "}
            </div>
            <div className="flex-grow-1">
              <p
                className="mb-0 small"
                onClick={() => {
                  if (is_public) {
                    return false;
                  } else if (auth?.currentUser) {
                    handleOpenSubModule(sub_module_data);
                  } else {
                    return false;
                  }
                }}
              >
                {sub_module_data?.title}
              </p>
            </div>
            <div
              className="small"
              onClick={() => {
                if (is_public) {
                  return false;
                } else if (auth?.currentUser) {
                  handleOpenSubModule(sub_module_data);
                } else {
                  return false;
                }
              }}
            >
              <p className="m-0 text-uppercase text-muted ml-1 small">
                {content_type}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-between align-items-center border rounded p-2 px-3 mb-2 pointer"
          onClick={() => {
            if (is_public) {
              return false;
            } else if (auth?.currentUser) {
              handleOpenSubModule(sub_module_data);
            } else {
              return false;
            }
          }}
          title={`${content_type?.toUpperCase()}: ${sub_module_data?.title}`}
        >
          <div>
            <p className="mb-0">{sub_module_data?.title}</p>
            <div className="small d-flex align-items-center">
              <FontAwesomeIcon icon={icon} className="mr-3" />
              <p className="m-0 text-uppercase ml-1">{sub_module_data?.type}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (module_id) {
      setAccordionKey(module_data?.id === module_id ? "0" : `${idx}`);
    }
  }, [module_id, sub_module_id]);

  if (accordion) {
    return (
      <Accordion
        defaultActiveKey={module_data?.id === module_id ? "0" : `${idx}`}
        onSelect={(e) => setAccordionKey(e)}
      >
        <Card className="border-0 rounded-0">
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="p-0 m-0 border-bottom-0"
          >
            <div className="d-flex align-items-center px-3 py-2">
              <div className="flex-grow-1" style={{ cursor: "pointer" }}>
                <p className="mb-0 font-weight-bold">{module_data?.name}</p>
                <p className="mb-0 small text-muted">
                  {module_data?.sub_modules?.length} items
                </p>
              </div>
              <FontAwesomeIcon
                icon={accordionKey === "0" ? faAngleUp : faAngleDown}
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-0 m-0">
              <div>
                {module_data?.sub_modules?.length > 0 ? (
                  module_data?.sub_modules
                    ?.sort((a, b) => a.position - b.position)
                    ?.map((item, key) => (
                      <SubModuleName
                        accordion={accordion}
                        idx={key}
                        key={key}
                        sub_module_data={item}
                      />
                    ))
                ) : (
                  <div className=" rounded p-2 px-3 mb-2">
                    <p className="mb-0 text-center text-muted">
                      No items added.
                    </p>
                  </div>
                )}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  } else {
    return (
      <div className="mb-3">
        <p className="mb-2 font-weight-bold">{module_data?.name}</p>

        <div>
          {module_data?.sub_modules?.length > 0 ? (
            module_data?.sub_modules
              ?.sort((a, b) => a.position - b.position)
              ?.map((item, key) => (
                <SubModuleName idx={key} key={key} sub_module_data={item} />
              ))
          ) : (
            <div className=" rounded p-2 px-3 mb-2">
              <p className="mb-0 text-center text-muted">No items added.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default memo(Module);
