import React, { useEffect, useState, memo } from "react";
import {
  faPlusCircle,
  faEye,
  faUnlink,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import { auth, firestore } from "../../../../../firebase";
import { ADMIN_GET_APP_LIST, API_BASE_URL } from "../../../../../config";
import { postData } from "../../../../../utils";
import AssignmentCreate from "./Assignment/Create";
import ListLoader from "./ListLoader";

function Assignment(props) {
  // CONSTANTS
  const {
    eventId,
    currentEvent,
    module_data,
    handleAddSubModule,
    handleRemoveSubModule,
    handleCloseAssignmentView,
  } = props;

  // STATES
  const [key, setKey] = useState("select");
  const [assignments, setAssignments] = useState([]);
  const [fetchingAssignments, setFetchingAssignments] = useState(true);

  // FUNCTIONS
  // Function: Fetch Assignments
  const handleFetchAssignments = async () => {
    try {
      const response = await postData(`${API_BASE_URL}${ADMIN_GET_APP_LIST}`, {
        event_id: eventId,
        user_id: auth.currentUser.uid,
        types: ["assignment"],
      });

      if (response.status === 200) {
        const { data } = response;

        setAssignments(data.assignmentList);
        setFetchingAssignments(false);
      } else {
        setFetchingAssignments(false);
      }
    } catch (error) {
      console.log("[Error] Fetching assignments: ", error);
      setFetchingAssignments(false);
    }
  };

  useEffect(() => {
    let unsubscribe_assignments = firestore
      .collection(`/events/${eventId}/assignments`)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Assignments
          handleFetchAssignments();
        });
      });

    return () => {
      unsubscribe_assignments();
    };
  }, []);

  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <div className="d-flex align-items-center">
        <a
          onClick={handleCloseAssignmentView}
          style={{ cursor: "pointer" }}
          className="text-white p-3 m-0"
          title="Close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <div className="p-3">
          <h4 className="text-white mb-2">Select an assignment or add new</h4>
          <p className="text-uppercase text-light small m-0">
            <strong>MODULE</strong> {module_data?.name}
          </p>
        </div>
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="w-100"
      >
        <Tab
          tabClassName={
            key === "select"
              ? "border-top-0 border-left-0 border-right-0 border-bottom border-primary w-50 bg-transparent text-primary font-weight-bold"
              : "border-top-0 border-left-0 border-right-0 border-bottom border-secondary w-50 bg-transparent text-secondary font-weight-bold"
          }
          eventKey="select"
          title="Select from existing"
        >
          <div className="p-3">
            {fetchingAssignments && <ListLoader />}
            <div className="mb-3">
              {assignments?.map((assignment, key) => (
                <div
                  key={key}
                  className="d-flex justify-content-between align-items-center border rounded p-2 px-3 mb-2"
                >
                  <div>
                    <p className="mb-0">
                      {/* {key + 1}.   */}
                      {currentEvent && currentEvent?.slug && (
                        <a
                          href={`${window.location.origin}/event/${currentEvent?.slug}/assignment/${assignment.id}`}
                          target="_blank"
                          className="text-secondary mr-2 pointer"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </a>
                      )}
                      {assignment.title}
                    </p>
                  </div>
                  <div>
                    {module_data?.sub_modules?.filter(
                      (item) => item.content_id === assignment.content_id
                    )?.length > 0 ? (
                      <div
                        className="pointer"
                        onClick={() =>
                          handleRemoveSubModule(
                            module_data?.sub_modules?.filter(
                              (item) =>
                                item.content_id === assignment.content_id
                            )[0]
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faUnlink}
                          className="my-0 mr-2 pointer text-danger"
                        />
                        <small>REMOVE</small>
                      </div>
                    ) : (
                      <div
                        className="pointer"
                        onClick={() =>
                          handleAddSubModule({
                            reference_id: assignment.id,
                            content_id: assignment.content_id,
                            title: assignment.title,
                            type: "assignment",
                            user_id: auth.currentUser.uid,
                            order_index: module_data?.sub_modules?.length
                              ? module_data?.sub_modules?.length + 1
                              : 1,
                            event_id: eventId,
                            module_id: module_data?.id,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="my-0 mr-2 pointer text-primary"
                        />
                        <small>ADD</small>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <button
                className="btn btn-primary btn-sm"
                onClick={() => setKey("create")}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add new Assignment
              </button>
            </div>
          </div>
        </Tab>
        <Tab
          tabClassName={
            key === "create"
              ? "border-top-0 border-left-0 border-right-0 border-bottom border-primary w-50 bg-transparent text-primary font-weight-bold"
              : "border-top-0 border-left-0 border-right-0 border-bottom border-secondary w-50 bg-transparent text-secondary font-weight-bold"
          }
          eventKey="create"
          title="Create"
        >
          <div className="p-3">
            <AssignmentCreate module_id={module_data?.id} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

export default memo(connect(mapStateToProps)(Assignment));
