import React, { useState, useMemo, useEffect } from "react";
import { Card, Button, Spinner, InputGroup } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faTimes,
  faComment,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  API_BASE_URL,
  DRIVE_UPLOAD,
  USER_ASSIGNMENT_SUBMIT,
  USER_SUB_MODULE_PROGRESS_UPDATE,
  ASSIGNMENT_RESPONSE_GET,
} from "../../../../config/index";
import axios from "axios";
import { toast } from "react-toastify";
import { auth } from "../../../../firebase";

const Assignment = (props) => {
  // CONSTANTS
  const {
    darkTheme,
    currentEvent,
    fetchingDetails,
    current_progress,
    resource_details,
    sub_module_details,
    module_id,
    sub_module_id,
  } = props;
  const user = auth.currentUser;
  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];
  const pdf_type = ["application/pdf"];
  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  // STATES
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [responses, setResponses] = useState([]);
  const [authorized, setAuthorized] = useState([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/gif",

    "application/pdf",

    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ]);
  const [savingProgress, setSavingProgress] = useState(false);
  const [loading, setLoading] = useState(true);

  // FUNCTIONS
  const handleAnswers = (e, key) => {
    let prev = [...answers];
    prev[key] = e.target.value;
    setAnswers(prev);
  };

  const addFile = (event, key) => {
    let prev = [...files];
    let file = event.target.files[0];

    if (event.target.files[0]) {
      prev[key] = file;
      setFiles(prev);
    }
  };

  const removeFile = (key) => {
    let prev = [...files];
    prev.splice(key, 1);
    prev.splice(key, 0, "");
    setFiles(prev);
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const uploadFile = async (file) => {
    let requestData = {};

    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", user.uid);

    // if (folder_id) {
    //     fileData.append("folder_id", folder_id);
    // }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        // this.setState({
        //     percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        // });
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };

  const handleGenerateAnswers = () => {
    let allImagesUploadedPromises = files.map((file, key) => uploadFile(file));

    let answersAssignment = [];

    Promise.all(allImagesUploadedPromises).then(async (values) => {
      console.log("values: ", values);

      let links = [...values];
      resource_details?.questions.map((question, key) =>
        answersAssignment.push({
          attachment: links[key] ? links[key] : "",
          answer: answers[key] ? answers[key] : "",
          questionId: question.questionId,
        })
      );
    });

    return answersAssignment;
  };

  const taskResponse = () => {
    try {
      let allImagesUploadedPromises = files.map((file, key) =>
        uploadFile(file)
      );

      Promise.all(allImagesUploadedPromises).then(async (values) => {
        console.log("values: ", values);

        let links = [...values];
        let answersAssignment = [];
        resource_details?.questions.map((question, key) =>
          answersAssignment.push({
            attachment: links[key] ? links[key] : "",
            answer: answers[key] ? answers[key] : "",
            questionId: question.questionId,
          })
        );

        axios
          .post(`${API_BASE_URL}${USER_ASSIGNMENT_SUBMIT}`, {
            user_id: user?.uid,
            workspace_id: currentEvent?.workspace_id,
            room_id: currentEvent?.room_id,
            assignment_id: sub_module_details?.content_id,
            answers: answersAssignment,
            child_id: sub_module_id,
            parent_id: module_id,
            ancestor_id: sub_module_details?.ancestor_id,
          })
          .then(() => {
            toast.dark("🎊 Submitted successfully 🎊", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            getResponses();
            setIsSubmitting(false);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setIsSubmitting(false);
          });
      });
    } catch {
      //setIsSubmitting(false);
    } finally {
      // setIsSubmitting(false);
    }
  };

  const getResponses = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${ASSIGNMENT_RESPONSE_GET}?content_id=${sub_module_details?.content_id}&user_id=${auth?.currentUser?.uid}`
      );

      if (response.status === 200) {
        const { data } = response.data;

        setResponses({ ...data });
        setLoading(false);
      }
    } catch (error) {
      console.log("[Error] Fetching Assignment details: ", error);
      setLoading(false);
    }
  };

  const handleUpdateProgress = async () => {
    const progressDetails = {
      child_id: sub_module_id,
      parent_id: module_id,
      ancestor_id: sub_module_details?.ancestor_id,
      progress: await handleGenerateAnswers(),
      user_id: auth?.currentUser?.uid,
    };

    try {
      setSavingProgress(true);
      const response = await axios.post(
        `${API_BASE_URL}${USER_SUB_MODULE_PROGRESS_UPDATE}`,
        progressDetails
      );

      if (response.status === 200) {
        const { data } = response.data;
        // if (
        //   data?.progress?.played >= 0.98 &&
        //   data?.state === "complete" &&
        //   data?.status
        // ) {
        //   toast.dark(
        //     <div>
        //       <p className="mb-0">🎊 Completed 🎊</p>
        //     </div>,
        //     {
        //       position: toast.POSITION.TOP_CENTER,
        //       hideProgressBar: false,
        //       autoClose: 3000,
        //     }
        //   );
        // }

        console.log("Progress updated: ", data);
        setSavingProgress(false);
      }
    } catch (error) {
      console.log(`[Error] Setting progress: ${error.message}`);
      setSavingProgress(false);
    }
  };

  useEffect(() => {
    getResponses();

    console.log("current_progress: ", current_progress);
  }, []);

  useEffect(() => {
    if (resource_details?.questions.length > 1)
      setTotalPoints(
        resource_details?.questions?.reduce(
          (a, b) => parseInt(a.weight) + parseInt(b.weight)
        )
      );

    if (resource_details?.questions.length === 1) {
      setTotalPoints(parseInt(resource_details?.questions[0]?.weight));
    }
  }, [resource_details]);

  // FUNCTIONAL COMPONENTS
  const ShowResponse = ({ item, index, question, isGraded, weight }) => {
    return (
      <div className="mb-3">
        <div className="d-flex">
          <p className="font-weight-bold flex-grow-1 mb-0">
            Q{index + 1}. {question}
          </p>
          {isGraded && <span className="small">({weight} points)</span>}
        </div>
        {item.answer ? (
          <p className="mt-0 mb-0">
            <span className="font-weight-bold">A{index + 1}.</span>{" "}
            {item?.answer}{" "}
          </p>
        ) : (
          ""
        )}
        {item.attachment ? (
          <a className="mt-0 mb-0" href={item.attachment} target="_blank">
            Attachment
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };

  if (fetchingDetails || loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Card
      className={
        darkTheme ? "shadow-sm rounded bg-dark" : "shadow-sm rounded bg-white"
      }
    >
      <Card.Header className="d-flex align-items-start">
        <div className="flex-grow-1">
          {/* <h4 className="font-weight-bold mb-0">
                  {resource_details?.assignmentSubject}
                </h4> */}
          <p className="small mb-0">
            {moment(
              convertSecondstoMilliSeconds(
                resource_details?.createdAt._seconds,
                resource_details?.createdAt._nanoseconds
              )
            ).format("LLL")}
          </p>
          {resource_details?.isGraded && (
            <p className="small mb-0">
              <b>Total grade points: </b>
              {totalPoints}
            </p>
          )}
        </div>
      </Card.Header>
      <Card.Body className="p-3">
        <>
          {!responses?.answers?.length && (
            <>
              {resource_details?.questions?.map((que, key) => (
                <div className="mb-3">
                  <p
                    className={
                      que.description
                        ? "font-weight-bold mb-0"
                        : "font-weight-bold mb-1"
                    }
                  >
                    Que: {que.question}
                  </p>
                  {que.description && (
                    <p className="mt-0 mb-1">Desc: {que.description}</p>
                  )}
                  {resource_details?.isGraded && (
                    <p className="small">({que.weight} points)</p>
                  )}

                  {resource_details?.deadline < Date.now() ? null : (
                    <InputGroup className="mb-1">
                      <TextareaAutosize
                        type="text"
                        className="w-100 p-2 rounded"
                        style={{
                          borderColor: "#CDCDCD",
                          minWidth: "300px",
                        }}
                        name="answers[]"
                        value={answers[key]}
                        id={key}
                        onChange={(e) => handleAnswers(e, key)}
                        minRows={3}
                        maxRows={20}
                        placeholder="Your response"
                      />
                    </InputGroup>
                  )}
                  {files[key] && (
                    <div>
                      <div className="d-flex rounded bg-light mt-2">
                        <div
                          className="py-2 px-3"
                          style={{ backgroundColor: "#FEF2E4" }}
                        >
                          {!authorized.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              className="text-danger"
                            />
                          )}
                          {image_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faImage}
                              className="text-secondary"
                            />
                          )}
                          {word_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFileWord}
                              className="text-primary"
                            />
                          )}
                          {pdf_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              className="text-danger"
                            />
                          )}
                          {powerpoint_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFilePowerpoint}
                              className="text-danger"
                            />
                          )}
                          {excel_type.includes(files[key].type) && (
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              className="text-success"
                            />
                          )}
                        </div>
                        <div className="p-2 flex-grow-1">
                          <small className="font-weight-bold text-secondary">
                            {files[key].name}
                          </small>
                        </div>
                        <div className="p-2 flex-grow-1 d-flex justify-content-end align-items-center">
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => removeFile(key)}
                            className="text-dark"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {resource_details?.deadline < Date.now() ? null : files[
                      key
                    ] && files[key].name ? null : (
                    <InputGroup className="mb-1">
                      <div class="file-input">
                        <input
                          type="file"
                          name={key}
                          onChange={(e) => {
                            addFile(e, key);
                          }}
                        />
                        {/* <span class="btn btn-sm btn-primary">Attach</span>
                                                                    <span
                                                                        class="label ml-2"
                                                                        className="text-dark"
                                                                        data-js-label
                                                                    >
                                                                        {files[key]
                                                                            ? " " + files[key].name
                                                                            : " No file selected"}
                                                                    </span> */}
                      </div>
                    </InputGroup>
                  )}
                </div>
              ))}

              <Button
                disabled={
                  resource_details?.deadline < Date.now() ||
                  isSubmitting ||
                  savingProgress
                }
                variant="primary"
                size="sm"
                onClick={() => {
                  setIsSubmitting(true);
                  taskResponse();
                }}
                className="mr-2"
              >
                {isSubmitting && (
                  <Spinner
                    animation="border"
                    size="sm"
                    color="white"
                    className="mr-1"
                  />
                )}
                Submit
              </Button>

              {/* <Button
                    onClick={handleUpdateProgress}
                    variant="secondary"
                    size="sm"
                    disabled={
                      resource_details?.deadline < Date.now() ||
                      isSubmitting ||
                      savingProgress
                    }
                  >
                    {savingProgress ? (
                      <span>Saving...</span>
                    ) : (
                      <span>Save Draft</span>
                    )}
                  </Button> */}
            </>
          )}
          {!(
            (resource_details?.questions?.length ===
              resource_details?.response?.length &&
              resource_details?.questions?.length ===
                resource_details?.links?.length) ||
            resource_details?.deadline > Date.now()
          ) && (
            <p className="small mt-2 mb-0 pb-0">
              <span className="font-weight-bold">Note: </span>Please complete
              your Assignment to unlock submit.
            </p>
          )}
        </>
        {responses?.answers?.length > 0 ? (
          <>
            {responses.answers.map((item, index) => (
              <>
                <ShowResponse
                  item={item}
                  index={index}
                  isGraded={resource_details?.isGraded}
                  question={resource_details?.questions[index]?.question}
                  weight={resource_details?.questions[index]?.weight}
                />
              </>
            ))}
          </>
        ) : null}
        {/* {responses?.isScorePublished ? (
                      <p className="mt-2 mb-1">
                        Total grade points scored: {"totalScore"} out of{" "}
                        {totalPoints}
                        <span className="text-success ml-2">
                          <FontAwesomeIcon icon={faCheckDouble} /> Graded at{" "}
                          {moment(responses?.publishTimestamp).format("LLL")}
                        </span>
                      </p>
                    ) : null} */}
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 small">
          Deadline by <b>{moment(resource_details?.deadline).format("LLL")}</b>
        </p>
      </Card.Footer>
    </Card>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(Assignment);
