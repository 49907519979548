import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import * as CourseStyle from "../course.module.css";

export default function Chat({ classes }) {
  return (
    <div
      className={`d-flex flex-column h-100 justify-content-between ${classes} ${CourseStyle.dark_bg}`}
      // style={{ backgroundColor: "#17191A" }}
    >
      <p className="my-2 font-weight-bold text-muted ml-3">Chat</p>
      <div className="text-center">
        <img
          alt=""
          src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
          width="50%"
        />
        <p className="text-muted small mt-3">Chat will be enabled soon</p>
      </div>
      <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
        <div>Chat is currently disabled</div>
        <div>
          <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
        </div>
      </div>
    </div>
  );
}
