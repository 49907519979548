import React from "react";
import { Container, Row, Col, Card, Table, ProgressBar } from "react-bootstrap";
import Avatar from "react-avatar";
import { memo } from "react";

const CourseStats = () => {
  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <Card.Body>
                <div className="responsive-tbl">
                  <div className="item">
                    <Row>
                      <Col>
                        <h4>
                          Course: Innovation Management: Strategy and Execution
                        </h4>
                      </Col>
                    </Row>
                    <div className="table-responsive product-list">
                      <Table borderless className="text-white">
                        <thead>
                          <tr>
                            <th> #</th>
                            <th>{"Number of Students"}</th>
                            <th colSpan="4">{"% of course Completed"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <ProgressBar
                                now={100}
                                label={`${100}% Completed`}
                                variant="success"
                                className="progresscheck"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td style={{ verticalAlign: "middle" }}>20</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <ProgressBar
                                now={90}
                                label={`${90}% Completed`}
                                variant="warning"
                                className="progresscheck"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td style={{ verticalAlign: "middle" }}>50</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <ProgressBar
                                now={70}
                                label={`${70}% Completed`}
                                variant="warning"
                                className="progresscheck"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td style={{ verticalAlign: "middle" }}>150</td>
                            <td style={{ verticalAlign: "middle" }}>
                              <ProgressBar
                                now={50}
                                label={`${50}% Completed`}
                                variant="danger"
                                className="progresscheck"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(CourseStats);
