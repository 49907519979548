import React, { memo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { postData } from "../../../../../utils";
import { ADMIN_ADD_SUB_MODULE, API_BASE_URL } from "../../../../../config";
import Assignment from "./Assignment";
import Quiz from "./Quiz";
import Schedule from "./Schedule";
import AsyncResource from "./AsyncResource";
import Form from "./Form/Form";

toast.configure();

function SubModuleAdd(props) {
  const {
    module_data,
    handleRemoveSubModule,
    showAddScheduleModal,
    setShowAddScheduleModule,
    showAddAssignmentModal,
    showAddForm,
    setShowAddAssignmentModule,
    setShowAddForm,
    setShowAddSubModuleSection,
    showAddQuizModal,
    setShowAddQuizModule,
    showAddResource,
    setShowAddResource,
    newSubModuleDetails,
  } = props;

  // STATES

  // FUNCTIONS
  // Function: Add Sub-module to a Module
  const handleAddSubModule = async (data) => {
    try {
      const response = await postData(
        `${API_BASE_URL}${ADMIN_ADD_SUB_MODULE}`,
        data
      );

      if (response.status === 200) {
        toast.success("Item successfully added.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("[Error] Adding Item: ", error);
      toast.warning("Error adding Item.", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const handleCloseAssignmentView = () => {
    setShowAddAssignmentModule(false);
    setShowAddSubModuleSection(false);
  };

  const handleCloseFormView = () => {
    setShowAddForm(false);
    setShowAddSubModuleSection(false);
  };

  const handleCloseQuizView = () => {
    setShowAddQuizModule(false);
    setShowAddSubModuleSection(false);
  };

  const handleCloseScheduleView = () => {
    setShowAddScheduleModule(false);
    setShowAddSubModuleSection(false);
  };

  return (
    <React.Fragment>
      {/* Section start: Choose Assignment */}
      {showAddAssignmentModal && (
        <Assignment
          module_data={module_data}
          handleAddSubModule={handleAddSubModule}
          handleRemoveSubModule={handleRemoveSubModule}
          handleCloseAssignmentView={handleCloseAssignmentView}
        />
      )}
      {/* Section end: Choose Assignment */}

      {/* Section start: Choose Quiz */}
      {showAddQuizModal && (
        <Quiz
          module_data={module_data}
          handleAddSubModule={handleAddSubModule}
          handleRemoveSubModule={handleRemoveSubModule}
          handleCloseQuizView={handleCloseQuizView}
        />
      )}
      {/* Section end: Choose Quiz */}

      {/* Section start: Choose Schedule */}
      {showAddScheduleModal && (
        <Schedule
          module_data={module_data}
          handleAddSubModule={handleAddSubModule}
          handleRemoveSubModule={handleRemoveSubModule}
          handleCloseScheduleView={handleCloseScheduleView}
        />
      )}
      {/* Section end: Choose Schedule */}

      {/* Section start: Add Async Content */}
      {showAddResource && (
        <AsyncResource
          module_data={module_data}
          setShowAddResource={setShowAddResource}
          newSubModuleDetails={newSubModuleDetails}
          setShowAddSubModuleSection={setShowAddSubModuleSection}
          handleAddSubModule={handleAddSubModule}
          handleRemoveSubModule={handleRemoveSubModule}
        />
      )}

      {showAddForm && (
        <Form
          module_data={module_data}
          handleAddSubModule={handleAddSubModule}
          handleRemoveSubModule={handleRemoveSubModule}
          handleCloseFormView={handleCloseFormView}
        />
      )}
      {/* Section end: Add Async Content */}
    </React.Fragment>
  );
}

export default memo(SubModuleAdd);
