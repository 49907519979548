import React, { useRef, useState, useEffect } from "react";
import {
  Card
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";

import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import Chat from "./Chat";
import { SAMPLE_PDF } from "../../data";
import CourseRedux from "../CourseRedux";

function Dashboard({ currentLanguage, changeLanguage, course }) {

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  return (
    <>
      <CourseRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <h2 className="font-weight-bold text-white">Notifications</h2>

                  <div className="mt-3 mt-md-4">
                    <div
                      className="mb-3 mb-md-5"
                    >
                      {course?.notificationList?.map((item) => {
                        return (
                          <Card className="bg-dark text-white shadow-sm mb-1">
                            <Card.Body className="p-0">
                              <a
                                className="d-flex align-items-center text-white p-2"
                                style={{ cursor: "pointer" }}
                                href={SAMPLE_PDF}
                                target="_blank"
                              >
                                <div className="bg-warning p-2 rounded">
                                  <FontAwesomeIcon icon={faFile} size="lg" />
                                </div>
                                <div className="ml-2">
                                  <p className="font-weight-bold mb-0">
                                    {item.message}
                                  </p>
                                </div>
                              </a>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({ 
    currentLanguage: state.currentLanguage, 
    course: state.course
  }),
  { changeLanguage }
)(Dashboard);
