import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EventNavbar from "../NavBar";
import EventLeftbar from "../LeftBar";
import { changeLanguage, setGraphId, setProducts } from "../../../../store/actions/actions";
import "@culturehq/add-to-calendar/dist/styles.css";
import firebase from "firebase";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import "./apps.css";
import { useDocument } from "react-firebase-hooks/firestore";


function SixPathTemplate(props) {

    const [path, setPath] = useState(0)
    const [pathName, setPathName] = useState("PAth one");
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);

    const [headDoc, headDocLoading, headDocError] = useDocument(
        firebase
            .firestore()
            .doc(
                `events/${props?.eventId}/bbos/${props.graph.id}/six_path/${path.toString()}`
            )
    );

    useEffect(() => {
        if (headDoc?.data()) {
            setHeaders(headDoc?.data().head);
        }
    }, [headDoc]);

    useEffect(() => {
        firebase
            .firestore()
            .collection(
                `events/${props?.eventId}/bbos/${props.graph.id}/six_path/${path.toString()}/rows`
            )
            .onSnapshot((snapshot) => {
                let arr = [];
                snapshot.forEach((item) => {
                    arr.push({ ...item.data(), id: item.id })
                })
                setRows(arr)
            });
    }, [path, pathName]);


    return (
        <div className="p-1">
        <div className="container" style={{overflow: 'hidden'}}>
            <div className="d-flex align-items-center">
                
                <div className="blockquote mb-0">
                    <p>{pathName && pathName}</p>
                </div>
            </div>
            
            <table class="table table-striped">
                <thead className="thead-dark">
                    <tr>
                        {headers?.map((item) => (
                            <th scope="col" key={item.id}>
                                {item.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((rowData) => {
                        return (
                            <tr>
                                {headers?.map((headData, index) => {
                                    return (
                                        <td>
                                            {rowData[headData.id]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>           
        </div>
        </div>
    );
}








function ThreeTierList(props) {
    const [allGraphs, setAllGraphs] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        firebase.firestore().collection(`events/${props?.currentEvent?.id}/bbos`).onSnapshot((snapshot) => {
            const tempGraphs = [];
            snapshot.forEach((doc) => {
                //if (!doc.data().reset && (doc.data().datasets && doc.data().datasets.length)) {
                tempGraphs.push({ ...doc.data(), id: doc.id })
                //}

            })
            setAllGraphs(tempGraphs)
        })

    }, []);

    let history = useHistory();
    return (
        <div className="h-100 montserrat-font">
            <EventLeftbar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <EventNavbar />
                <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
                    <EventLeftbar />
                    <div
                        className="col-sm h-100 px-5 py-5 "
                        style={{
                            overflow: "scroll",
                            backgroundSize: "cover",
                            backgroundColor: "#EFEFEF",
                            // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/boa-stage-background.jpg)`
                        }}
                    >
                        {/* Networking */}

                        <div className="container py-3">
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="shadow-sm p-3 rounded bg-white">
                                            <h3>
                                            {props.currentEvent?.bbos_names?.six_path ?? "Six Path"} Templates Gallery
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row">
                                        {allGraphs && allGraphs.map((graph, key) => (
                                            <>
                                            {graph.six_path == true &&
                                            <div 
                                                onClick={() => {
                                                    props.setGraphId(graph.id)
                                                    history.push(`/event/${id}/as_is/view/${graph.id}`)
                                                }} 
                                                className="col-md-4 mb-4 col-12" 
                                                key={key}
                                                style={{overflow: 'hidden'}}
                                            >
                                                <div className="card event-card border-0">

                                                    {graph.six_path && <SixPathTemplate eventId={props?.currentEvent?.id} graph={graph} />}

                                                    <div className="card-body h-100 bg-white">
                                                        <div className=" mb-3 align-content-center">

                                                            {graph.title && (
                                                                <h4 className="text-s-6 text-dark">
                                                                    {graph.title}
                                                                </h4>
                                                            )}
                                                            {graph.createdAt && (
                                                                <p className=" text-dark">
                                                                    {moment(graph.createdAt).format("LLL")}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            </>
                                            // <div
                                            //     onClick={() => {
                                            //         props.setGraphId(graph.id)
                                            //         history.push(`/event/bbos/as_is/view/${graph.id}`)
                                            //     }}
                                            //     className="shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 pointer"
                                            //     key={key}
                                            // >
                                            //     <div className="d-flex ">
                                            //         <div
                                            //             className="pointer"
                                            //         >
                                            //             <small className="text-theme-lighter smaller">
                                            //                 {graph?.createdAt && moment(graph.createdAt).format("LLL")}
                                            //             </small>
                                            //             <p className="m-0 font-weight-bold text-theme small">
                                            //                 {graph.title && <div>{graph.title}</div>}
                                            //             </p>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        ))}
                                    </div>

                                </div>

                            </React.Fragment>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        workspaceId: state.workspaceId,
        roomId: state.roomId,
        currentEvent: state.currentEvent,
    }),
    { changeLanguage, setGraphId, setProducts }
)(ThreeTierList);
