import React, { useState, useEffect, useRef } from "react";
import { Card, Image, Button, Dropdown, Nav, Spinner } from "react-bootstrap";
import moment from "moment";
import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { languageText } from "./language.json";
import { ticketTiers } from "./ticketTiers.json";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import * as EventStyle from "./event.module.css";
import ReadMore from "../ReadMore";
import { loadState, saveState } from "../../services";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  yupToFormErrors,
  getIn,
} from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function EventAuthentication({ currentLanguage }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { id, email_address } = useParams();
  const ticket_available = false;

  const [loggingSpinner, setLoggingSpinner] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState(0);
  const [ticketSelected, setTicketSelected] = useState(false);
  const [route, setRoute] = useState("");
  const [currentEvent, setCurrentEvent] = useState({});
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email_address: "",
    password_value: "",
  });
  const current_user = firebase.auth().currentUser;
  const [currentUserIsPart, setCurrentUserIsPart] = useState(false);
  const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("isDemo", "==", true)
      .where("slug", "==", `${id}`)
  );

  useEffect(() => {
    if (eventSnapshot) {
      setCurrentEvent({
        ...eventSnapshot?.docs[0]?.data(),
        id: eventSnapshot?.docs[0]?.id,
      });
    }
  }, [eventSnapshot, loadingEvent]);

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();

    if (ticket_available) {
      // if tickets are available only
      loadState("ticket_type") &&
        setSelectedTicketType(parseInt(loadState("ticket_type")));
      loadState("ticket_selected") &&
        setTicketSelected(loadState("ticket_selected"));
    }
  }, []);

  useEffect(() => {
    if (pathname.split("/")[3] && pathname.split("/")[3] === "register") {
      setRoute("register");
    }
    if (pathname.split("/")[3] && pathname.split("/")[3] === "login") {
      setRoute("login");
    }
  }, [pathname]);

  useEffect(async () => {
    if (current_user && !loadingEvent && currentEvent?.id != null) {
      const querySnapshot = await firebase
        .firestore()
        .collection(`/events/${currentEvent?.id}/users`)
        .where("email", "==", current_user.email)
        .get();

      if (querySnapshot?.docs?.length > 0 && querySnapshot?.docs[0]?.data()) {
        setCurrentUserIsPart(true);

        if (currentEvent.custom_form_required) {
          const { custom_form_filled } = querySnapshot?.docs[0]?.data();

          if (!custom_form_filled || custom_form_filled == null) {
            history.push("./fill-details");
          }
        }
      }
    }
  }, [current_user, currentEvent, loadingEvent]);

  const handleLogin = (resetForm, email, password) => {
    setLoggingSpinner(true)
      firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success("Password Reset Sent", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        history.push(`/event/${id}/login`)
      })
      .catch((err) => {
        console.error(err);
       
      });    
  }
  const handleTicketType = () => {
    saveState("ticket_type", selectedTicketType);
    saveState("ticket_selected", true);
    setTicketSelected(true);
  };

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const RegisterVaildationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, "Please add valid name")
      .required("First name is required"),
    lastName: Yup.string()
      .min(1, "Please add valid name")
      .required("Last name is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Required"),
    email: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Password must be atleast 6 characters long")
      .required("Required"),
  });

  const LoginVaildationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
  });

  const handleRouteChange = (e) => {
    if (pathname.split("/").length == 5) {
      history.push(`../${e}`);
    }

    if (pathname.split("/").length == 4) {
      history.push(`${e}`);
    }
  };

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  }

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Top navigation start */}
      <div className="container pt-3 pt-md-4 d-flex align-items-center justify-content-between">
        <div onClick={() => history.push(`/`)}>
          <Image
            className={`rounded ${EventStyle.event_cover_logo}`}
            src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
          />
        </div>
        <Dropdown className="mr-3 small">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "EN" : "FR"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className="small"
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {languageText[`${currentLanguage}`].english}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-md-8 col-12 mx-auto text-center">
            <h2 className="font-weight-bold">
              {currentEvent?.event_details?.EN?.event_name}
            </h2>
            <p className=" mb-1">
              {moment(convertSecondstoMilliSeconds(currentEvent?.event_details?.start_date?.seconds)).format(
                "DD MMMM YYYY"
              )}
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-8 col-12 mx-auto bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              style={{ position: "sticky", top: 60 }}
            >
              {!!current_user ? (
                currentUserIsPart ? (
                  <Card.Body className="p-3">
                    <p className="text-center h5 mb-3">
                      Already logged in as <b>{current_user?.displayName}</b>
                    </p>
                    <div className="d-flex flex-column align-items-center">
                      <img
                        width="100"
                        height="100"
                        className="img-fluid rounded-circle shadow-sm"
                        src={current_user?.photoURL}
                      />
                      <p className="align-items-center">
                        Not{" "}
                        <b>
                          {current_user?.displayName} ({current_user?.email})
                        </b>
                        ?{" "}
                        <a
                          className="btn btn-sm btn-primary"
                          onClick={async () => {
                            await firebase.auth().signOut();

                            toast.dark("Logged out", {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                            });
                          }}
                        >
                          Logout
                        </a>
                      </p>
                      <div className="w-50">
                        <Button
                          className="text-uppercase w-100 rounded-sm"
                          onClick={() => history.push("./dashboard")}
                        >
                          <small>Visit Reception</small>
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                ) : (
                  <Card.Body className="p-3">
                    <p className="text-center h5 mb-3">
                      Already logged in as <b>{current_user?.displayName}</b>
                    </p>
                    <div className="d-flex flex-column align-items-center">
                      <img
                        width="100"
                        height="100"
                        className="img-fluid rounded-circle shadow-sm"
                        src={current_user?.photoURL}
                      />
                      <p className="align-items-center">
                        Not{" "}
                        <b>
                          {current_user?.displayName} ({current_user?.email})
                        </b>
                        ?{" "}
                        <a
                          className="btn btn-sm btn-primary"
                          onClick={async () => {
                            await firebase.auth().signOut();

                            toast.dark("Logged out", {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                            });
                          }}
                        >
                          Logout
                        </a>
                      </p>
                      <div className="w-50">
                        <Button
                          className="text-uppercase w-100 rounded-sm"
                          onClick={() =>
                            history.push("./complete-registration")
                          }
                        >
                          <small>Register</small>
                        </Button>
                        <p className="text-center text-danger small mt-2 mb-1">
                          <b>Notice</b>: Looks like you are not registered for
                          this event
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                )
              ) : route === "register" && ticket_available ? (
                !ticketSelected && (
                  <Card.Body className="p-3">
                    <h4>Register</h4>
                    <div>
                      {ticketTiers[`${currentLanguage}`].map((tier, id) => (
                        <div className="border rounded py-2 mb-2 form-check">
                          <div className="d-flex justify-content-between">
                            <div className="pl-2">
                              <input
                                className="form-check-input"
                                checked={selectedTicketType == tier.id}
                                disabled={!tier.enabled}
                                value={tier.id}
                                onChange={(e) => {
                                  setSelectedTicketType(e.target.value);
                                }}
                                type="radio"
                                name="flexRadioDefault"
                                id={`flexRadioDefault${id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexRadioDefault${id}`}
                              >
                                <h5
                                  className="mb-0 text-s-8"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  {tier.tierName}
                                </h5>
                                {tier.limitedTickets && (
                                  <p className="small mb-0 text-danger font-weight-bold">
                                    {languageText[`${currentLanguage}`].only}{" "}
                                    {tier.ticketsLeft}{" "}
                                    {languageText[`${currentLanguage}`].left}
                                  </p>
                                )}
                                <p className="small mb-0">
                                  <ReadMore
                                    full_text={tier.tierDescription}
                                    max_length={80}
                                  />
                                </p>
                                {tier.taxExclusive && (
                                  <p className="text-secondary mb-0 mt-1 small">
                                    *{tier.taxPercentage}%{" "}
                                    {
                                      languageText[`${currentLanguage}`]
                                        .taxExcluded
                                    }
                                  </p>
                                )}
                              </label>
                            </div>
                            <div className="text-right text-s-11 font-weight-bold pr-2">
                              {tier.priceLabel}
                              {tier.taxExclusive && "*"}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Button
                      className="w-100 rounded-lg"
                      onClick={handleTicketType}
                    >
                      <small className="font-weight-bold">Register</small>
                    </Button>
                    <small>Above mentioned fees is per participant</small>
                  </Card.Body>
                )
              ) : (
                <Card.Body>
                  <p className="text-center h5 mb-3">
                    Forgot Password
                  </p>
                  <div className="mb-3">
                      <div>
                        <Formik
                          validationSchema={LoginVaildationSchema}
                          initialValues={{
                            email: email_address ?? "",
                          }}
                          onSubmit={(values, { resetForm }) => {
                            handleLogin(
                              resetForm,
                              values.email,
                            );
                          }}
                        >
                          {({ errors, touched, isValidating, values }) => (
                            <Form>
                              <div className="">
                                <div className="">
                                  <div className="form-label-group  mt-3">
                                    <label htmlFor="email" className="mb-1">
                                      Email address
                                    </label>
                                    <Field
                                      name="email"
                                      id="email"
                                      type="email"
                                      placeholder="Email"
                                      className="form-control"
                                    ></Field>
                                    {errors.email && touched.email ? (
                                      <div className="text-danger ml-2">
                                        {errors.email}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3">
                                <div className="d-flex">
                                  <button
                                    type="submit"
                                    className="btn btn-primary flex-grow-1"
                                    disabled={loggingSpinner}
                                  >
                                    {loggingSpinner && (
                                      <Spinner animation="border" size="sm" />
                                    )}{" "}
                                    Forgot Password
                                  </button>
                                </div>
                              </div>
                              <div className="mt-3 d-flex">
                                <p className="text-center small mt-2 mb-0 font-weight-bold">
                                  Go Back to{" "}
                                  <a
                                    className="text-primary"
                                    onClick={() =>
                                      handleRouteChange("login")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    Login
                                  </a>
                                </p>
                              </div>
                              {/* <div className="mt-3 d-flex">
                                <p className="text-center small mt-2 mb-0 font-weight-bold">
                                  Don't have an account?{" "}
                                  <a
                                    className="text-primary"
                                    onClick={() =>
                                      handleRouteChange("register")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    Register here
                                  </a>
                                </p>
                              </div> */}
                            </Form>
                          )}
                        </Formik>
                      </div>
                  
                  </div>
                </Card.Body>
              )}
            </Card>
          </div>
        </div>
      </div>
      {/* Event cover ends */}
    </div>
  );
}

export default connect(
  (state) => ({ currentLanguage: state.currentLanguage,}),
  { changeLanguage }
)(EventAuthentication);