import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Card,
  Image,
  Button,
  Dropdown,
  Form,
  Nav,
} from "react-bootstrap";

import { changeLanguage } from "../../store/actions/actions";
import { connect } from "react-redux";
import { languageText } from "../Event/language.json";
import { ticketTiers } from "../Event/ticketTiers.json";
import { useHistory, useLocation } from "react-router-dom";

import * as ProjectStyle from "./project.module.css";
import ReadMore from "../ReadMore";
import { loadState, saveState } from "../../services";
import { projectList, projectTimeline } from "../data";
import ProjectRedux from "./ProjectRedux";

function EventAuthentication({ currentLanguage, changeLanguage, project }) {
  const [selectedTicketType, setSelectedTicketType] = useState(0);
  const [ticketSelected, setTicketSelected] = useState(false);
  const [route, setRoute] = useState("");

  const { pathname } = useLocation();
  const history = useHistory();

  const pageRef = useRef(null);

  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (pathname.split("/")[3] && pathname.split("/")[3] === "register") {
      setRoute("register");
    }
    if (pathname.split("/")[3] && pathname.split("/")[3] === "login") {
      setRoute("login");
    }
  }, [pathname]);

  const handleRegister = () => {
    if (
      pathname.split("/")[2] !== "" &&
      pathname.split("/")[3] === "register"
    ) {
      history.push(`dashboard`);
    }
  };

  const handleLogin = () => {
    if (pathname.split("/")[2] !== "" && pathname.split("/")[3] === "login") {
      history.push(`login`);
    }
  };

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      <ProjectRedux />
      {/* Top navigation start */}
      <Navbar
        className="container-fluid py-3 py-md-3 bg-graphite shadow-sm"
        variant="dark"
        // sticky="top"
      >
        <div className="container d-flex align-items-center justify-content-between px-3">
          <div className="text-white">
            <Image
              className={`rounded ${ProjectStyle.project_cover_logo}`}
              src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
            />
          </div>
          <Dropdown className="mr-3 small text-white">
            <Dropdown.Toggle
              as="div"
              id="dropdown-basic"
              style={{ cursor: "pointer" }}
            >
              {currentLanguage === "EN" ? "EN" : "FR"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "EN"}
                onClick={() => changeLanguage("EN")}
              >
                {/* {languageText[`${currentLanguage}`].english} */}
                English
              </Dropdown.Item>
              <Dropdown.Item
                className="small"
                active={currentLanguage === "FR"}
                onClick={() => changeLanguage("FR")}
              >
                {/* {languageText[`${currentLanguage}`].french} */}
                French
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      {/* Top navigation end */}

      {/* Event cover starts */}
      <div className="container pt-3 pt-md-4">
        <div className="row">
          <div className="col-7">
            <h2 className="font-weight-bold text-white">{project?.name}</h2>
            <p className="text-white ">{project?.date}</p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-7">
            <Card className={`shadow-sm ${ProjectStyle.project_cover_card}`}>
              <div
                className={`rounded-top ${ProjectStyle.project_cover_img1}`}
                style={{
                  backgroundImage: `url(${project?.image})`,
                }}
              />
            </Card>

            <div className="pt-3 pt-md-4">
              <p className="text-white">{project?.description}</p>
            </div>
          </div>

          <div className="col-5 bg-transparent w-100">
            <Card
              className="rounded-lg bg-white shadow"
              //   style={{ position: "sticky", top: 60 }}
            >
              <Card.Body>
                <p className="text-center h5 mb-3">
                  {route === "register" ? "Register" : "Log in"} for an igesia
                  account
                  <br />
                  to join
                </p>
                <div className="mb-3">
                  <Nav
                    variant="pills"
                    onSelect={(e) => {
                      history.push(e);
                    }}
                    activeKey={route}
                    className="w-100 border rounded-pill m-0"
                  >
                    <Nav.Item className="w-50">
                      <Nav.Link
                        className="py-1 text-center rounded-pill"
                        eventKey="register"
                      >
                        Register
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="w-50">
                      <Nav.Link
                        className="py-1 text-center rounded-pill"
                        eventKey="login"
                      >
                        Log in
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  {route === "register" ? (
                    <Form className="mt-3" onSubmit={(e) => e.preventDefault()}>
                      <Form.Group>
                        <Form.Label className="small">First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first name"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="small">Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last name"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="small">
                          Phone number (optional)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="small">Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="small">Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="I agree to igesia's Terms & Conditions and Privacy Policy"
                          className="small"
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        onClick={handleRegister}
                      >
                        Register
                      </Button>

                      <p className="text-center small mt-2 mb-0 font-weight-bold">
                        Already have an account?{" "}
                        <a
                          className="text-primary"
                          onClick={() => {
                            history.push("login");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Log in here
                        </a>
                      </p>
                    </Form>
                  ) : (
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="small">Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Form.Group controlId="formGroupPassword">
                        <Form.Label className="small">Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className="border rounded-lg px-2"
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        onClick={() => {
                          history.push("dashboard");
                          // handleLogin
                        }}
                      >
                        Log in
                      </Button>

                      <p className="text-center small mt-2 mb-0 font-weight-bold">
                        Don't have an account?{" "}
                        <a
                          className="text-primary"
                          onClick={() => {
                            history.push("register");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Register here
                        </a>
                      </p>
                    </Form>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        
      </div>
      {/* Event cover ends */}
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    project: state.project,
  }),
  { changeLanguage }
)(EventAuthentication);
