import React, { memo } from "react";
import moment from "moment";

function Quiz(props) {
  // CONSTANTS
  const { resource_details, sub_module_details } = props;

  // STATES
  // FUNCTIONS

  return (
    <div>
      <p>1. isGraded: {resource_details?.isGraded ? "True" : "False"}</p>
      <p>2. createdBy: {resource_details?.createdBy}</p>
      <p>
        3. all:{" "}
        {resource_details?.all ? "Assigned to all" : "Not assigned to all"}
      </p>
      <p>4. assignedTo: {JSON.stringify(resource_details?.assignedTo)}</p>
      <p>5. timestamp: {moment(resource_details?.timestamp).format("LLLL")}</p>
      <p>6. deadline: {moment(resource_details?.deadline).format("LLLL")}</p>
      <p>7. quizName: {resource_details?.quizName}</p>
      <p>8. quizQuestions: </p>
      <div className="ml-3">
        {Object.values(resource_details?.quizQuestions)
          ?.sort((a, b) => a.sequenceNo - b.sequenceNo)
          ?.map((item, idx) => (
            <div key={idx}>
              <p>
                Q{item?.sequenceNo}.) {item?.question} ({item?.weight} marks -{" "}
                {item?.type === 1 ? "Multiple choice" : "Single choice"})
              </p>
              <div className="ml-3">
                {Object.entries(item?.options)
                  ?.sort((a, b) => a[1].sequenceNo - b[1].sequenceNo)
                  ?.map((val) => (
                    <p key={val[0]}>
                      {val[1]?.sequenceNo}. {val[1]?.optionValue} (
                      {val[1]?.correctAnswer ? "Correct" : "Incorrect"})
                    </p>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default memo(Quiz);
