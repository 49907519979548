import React, { useState, useMemo, useEffect } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { Card, Button, Spinner, InputGroup } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextareaAutosize from "react-textarea-autosize";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faTimes,
  faComment,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router";
import LeftBar from "../LeftBar";
import NavBar from "../NavBar";
import EventChat from "../EventChat";
import { connect } from "react-redux";
import {
  API_BASE_URL,
  DRIVE_UPLOAD,
  ASSIGNMENT_SUBMIT,
} from "../../../../config/index";
import axios from "axios";
import { toast } from "react-toastify";

const Assignment = ({ darkTheme, currentEvent }) => {
  const { assignment_id } = useParams();
  const user = firebase.auth().currentUser;

  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const [showChat, setShowChat] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [assignmentSnapshot, assignmentDataLoading, assignmentDataError] =
    useDocument(firebase.firestore().doc(`assignments/${assignment_id}`));

  const assignmentData = useMemo(() => {
    if (assignmentDataLoading) return assignmentSnapshot?.data();
    else return {};
  }, [assignmentSnapshot, assignmentDataLoading]);

  const [files, setFiles] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  const [responses, setResponses] = useState([]);

  const [authorized, setAuthorized] = useState([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/gif",

    "application/pdf",

    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ]);

  const handleAnswers = (e, key) => {
    let prev = [...answers];
    prev[key] = e.target.value;
    setAnswers(prev);
  };

  const addFile = (event, key) => {
    let prev = [...files];
    let file = event.target.files[0];

    if (event.target.files[0]) {
      prev[key] = file;
      setFiles(prev);
    }
  };

  const removeFile = (key) => {
    let prev = [...files];
    prev.splice(key, 1);
    prev.splice(key, 0, "");
    setFiles(prev);
  };

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  const uploadFile = async (file) => {
    let requestData = {};

    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", user.uid);

    // if (folder_id) {
    //     fileData.append("folder_id", folder_id);
    // }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        // this.setState({
        //     percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        // });
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };

  const taskResponse = () => {
    try {
      let allImagesUploadedPromises = files.map((file, key) =>
        uploadFile(file)
      );
      //this.setState({ submit: true });

      Promise.all(allImagesUploadedPromises).then(async (values) => {
        console.log("values: ", values);

        let links = [...values];
        let answersAssignment = [];
        assignmentSnapshot?.data()?.questions.map((question, key) =>
          answersAssignment.push({
            attachment: links[key] ? links[key] : "",
            answer: answers[key] ? answers[key] : "",
            questionId: question.questionId,
          })
        );
        let responseData = {
          userId: user?.uid,
          responseTime: Date.now(),
          answers: answersAssignment,
        };

        axios
          .post(`${API_BASE_URL}${ASSIGNMENT_SUBMIT}`, {
            user_id: user?.uid,
            workspace_id: currentEvent?.workspace_id,
            room_id: currentEvent?.room_id,
            assignment_id: assignment_id,
            answers: answersAssignment,
          })
          .then(() => {
            toast.success("Successfully submitted", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            getResponses();
            setIsSubmitting(false);
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setIsSubmitting(false);
          });
      });
    } catch {
      //setIsSubmitting(false);
    } finally {
      // setIsSubmitting(false);
    }
  };

  const getResponses = () => {
    let docRef = firebase
      .firestore()
      .collection(`/assignments/${assignment_id}/responses`)
      .doc(`${user.uid}`);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setResponses(doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    getResponses();
  }, []);

  useEffect(() => {
    if (assignmentSnapshot?.data()?.questions.length > 1)
      setTotalPoints(
        assignmentSnapshot
          ?.data()
          ?.questions?.reduce((a, b) => parseInt(a.weight) + parseInt(b.weight))
      );

    if (assignmentSnapshot?.data()?.questions.length === 1) {
      setTotalPoints(
        parseInt(assignmentSnapshot?.data()?.questions[0]?.weight)
      );
    }
  }, [assignmentSnapshot]);

  const ShowResponse = ({ item, index, question, isGraded, weight }) => {
    return (
      <div className="mb-3">
        <div className="d-flex">
          <p className="font-weight-bold flex-grow-1 mb-0">
            Q{index + 1}. {question}
          </p>
          {isGraded && <span className="small">({weight} points)</span>}
        </div>
        {item.answer ? (
          <p className="mt-0 mb-0">
            <span className="font-weight-bold">A{index + 1}.</span>{" "}
            {item?.answer}{" "}
          </p>
        ) : (
          ""
        )}
        {item.attachment ? (
          <a className="mt-0 mb-0" href={item.attachment} target="_blank">
            Attachment
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };

  if (assignmentDataLoading) {
    return <Spinner animation="border" className="text-light" />;
  }

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            {assignmentSnapshot?.data()?.createdBy !== user.uid ? (
              <div>
                <Card
                  className={
                    darkTheme
                      ? "shadow-sm rounded bg-dark"
                      : "shadow-sm rounded bg-white"
                  }
                >
                  <Card.Header className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <h4 className="font-weight-bold mb-0">
                        {assignmentSnapshot?.data()?.assignmentSubject}
                      </h4>
                      <p className="small mb-0">
                        {moment(
                          convertSecondstoMilliSeconds(
                            assignmentSnapshot?.data()?.createdAt.seconds,
                            assignmentSnapshot?.data()?.createdAt.nanoseconds
                          )
                        ).format("LLL")}
                      </p>
                      {assignmentSnapshot?.data()?.isGraded && (
                        <p className="small mb-0">
                          <b>Total grade points: </b>
                          {totalPoints}
                        </p>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="p-3">
                    <>
                      {/* <p>{JSON.stringify(responses)}</p> */}
                      {!responses?.answers?.length && (
                        <>
                          {assignmentSnapshot
                            ?.data()
                            ?.questions?.map((que, key) => (
                              <div className="mb-3">
                                <p
                                  className={
                                    que.description
                                      ? "font-weight-bold mb-0"
                                      : "font-weight-bold mb-1"
                                  }
                                >
                                  Que: {que.question}
                                </p>
                                {que.description && (
                                  <p className="mt-0 mb-1">
                                    Desc: {que.description}
                                  </p>
                                )}
                                {assignmentSnapshot?.data()?.isGraded && (
                                  <p className="small">({que.weight} points)</p>
                                )}

                                {assignmentSnapshot?.data()?.deadline <
                                Date.now() ? null : (
                                  <InputGroup className="mb-1">
                                    <TextareaAutosize
                                      type="text"
                                      className="w-100 p-2 rounded"
                                      style={{
                                        borderColor: "#CDCDCD",
                                        minWidth: "300px",
                                      }}
                                      name="answers[]"
                                      value={answers[key]}
                                      id={key}
                                      onChange={(e) => handleAnswers(e, key)}
                                      minRows={3}
                                      maxRows={20}
                                      placeholder="Your response"
                                    />
                                  </InputGroup>
                                )}
                                {files[key] && (
                                  <div>
                                    <div className="d-flex rounded bg-light mt-2">
                                      <div
                                        className="py-2 px-3"
                                        style={{ backgroundColor: "#FEF2E4" }}
                                      >
                                        {!authorized.includes(
                                          files[key].type
                                        ) && (
                                          <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                            className="text-danger"
                                          />
                                        )}
                                        {image_type.includes(
                                          files[key].type
                                        ) && (
                                          <FontAwesomeIcon
                                            icon={faImage}
                                            className="text-secondary"
                                          />
                                        )}
                                        {word_type.includes(
                                          files[key].type
                                        ) && (
                                          <FontAwesomeIcon
                                            icon={faFileWord}
                                            className="text-primary"
                                          />
                                        )}
                                        {pdf_type.includes(files[key].type) && (
                                          <FontAwesomeIcon
                                            icon={faFilePdf}
                                            className="text-danger"
                                          />
                                        )}
                                        {powerpoint_type.includes(
                                          files[key].type
                                        ) && (
                                          <FontAwesomeIcon
                                            icon={faFilePowerpoint}
                                            className="text-danger"
                                          />
                                        )}
                                        {excel_type.includes(
                                          files[key].type
                                        ) && (
                                          <FontAwesomeIcon
                                            icon={faFileExcel}
                                            className="text-success"
                                          />
                                        )}
                                      </div>
                                      <div className="p-2 flex-grow-1">
                                        <small className="font-weight-bold text-secondary">
                                          {files[key].name}
                                        </small>
                                      </div>
                                      <div className="p-2 flex-grow-1 d-flex justify-content-end align-items-center">
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          onClick={() => removeFile(key)}
                                          className="text-dark"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {assignmentSnapshot?.data()?.deadline <
                                Date.now() ? null : files[key] &&
                                  files[key].name ? null : (
                                  <InputGroup className="mb-1">
                                    <div class="file-input">
                                      <input
                                        type="file"
                                        name={key}
                                        onChange={(e) => {
                                          addFile(e, key);
                                        }}
                                      />
                                      {/* <span class="btn btn-sm btn-primary">Attach</span>
                                                                    <span
                                                                        class="label ml-2"
                                                                        className="text-dark"
                                                                        data-js-label
                                                                    >
                                                                        {files[key]
                                                                            ? " " + files[key].name
                                                                            : " No file selected"}
                                                                    </span> */}
                                    </div>
                                  </InputGroup>
                                )}
                              </div>
                            ))}

                          <Button
                            disabled={
                              assignmentSnapshot?.data()?.deadline <
                                Date.now() || isSubmitting
                            }
                            variant="primary"
                            size="sm"
                            onClick={() => {
                              setIsSubmitting(true);
                              taskResponse();
                            }}
                          >
                            {isSubmitting && (
                              <Spinner
                                animation="border"
                                size="sm"
                                color="white"
                                className="mr-1"
                              />
                            )}
                            Submit
                          </Button>
                        </>
                      )}
                      {!(
                        (assignmentSnapshot?.data()?.questions?.length ===
                          assignmentSnapshot?.data()?.response?.length &&
                          assignmentSnapshot?.data()?.questions?.length ===
                            assignmentSnapshot?.data()?.links?.length) ||
                        assignmentSnapshot?.data()?.deadline > Date.now()
                      ) && (
                        <p className="small mt-2 mb-0 pb-0">
                          <span className="font-weight-bold">Note: </span>Please
                          complete your Assignment to unlock submit.
                        </p>
                      )}
                    </>
                    {responses?.answers?.length > 0 ? (
                      <>
                        {responses.answers.map((item, index) => (
                          <>
                            <ShowResponse
                              item={item}
                              index={index}
                              isGraded={assignmentSnapshot?.data()?.isGraded}
                              question={
                                assignmentSnapshot?.data()?.questions[index]
                                  ?.question
                              }
                              weight={
                                assignmentSnapshot?.data()?.questions[index]
                                  ?.weight
                              }
                            />
                          </>
                        ))}
                      </>
                    ) : null}
                    {/* {responses?.isScorePublished ? (
                      <p className="mt-2 mb-1">
                        Total grade points scored: {"totalScore"} out of{" "}
                        {totalPoints}
                        <span className="text-success ml-2">
                          <FontAwesomeIcon icon={faCheckDouble} /> Graded at{" "}
                          {moment(responses?.publishTimestamp).format("LLL")}
                        </span>
                      </p>
                    ) : null} */}
                  </Card.Body>
                  <Card.Footer>
                    <p className="mb-0 small">
                      Deadline by{" "}
                      <b>
                        {moment(assignmentSnapshot?.data()?.deadline).format(
                          "LLL"
                        )}
                      </b>
                    </p>
                  </Card.Footer>
                </Card>
              </div>
            ) : null}
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  null
)(Assignment);
