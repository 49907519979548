import React, { useEffect, useState } from "react";
import { Button, Form, Card, Image, Modal } from "react-bootstrap";
import { Formik, Field, Form as FormComponent } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD, SUPER_ADMIN_EMAIL } from "../../../config/index";

import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { connect } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SketchPicker } from "react-color";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as ButtonM } from '@material-ui/core';

toast.configure();

const Settings = (props) => {
  const currentUser = firebase.auth().currentUser;
  const { eventId, currentEvent } = props;
  // console.log("CU ML ==> ",currentUser.email)

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc(eventId)
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addEventManager, setAddEventManager] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogZoom, setOpenDialogZoom] = useState(false);
  const [correctApiKeys, setCorrectApiKeys] = useState(false);
  const [currentItemToRemove, setCurrentItemToRemove] = useState(null);

  const chatOn = eventSnapshot?.data().chat_enabled ?? false;
  let event_manager = eventSnapshot?.data()?.event_manager ?? [];
  const top_branding_image = eventSnapshot?.data()?.top_branding_image ?? null;
  const created_by = eventSnapshot?.data()?.created_by ?? null;
  const stage_bg_color = eventSnapshot?.data()?.stage_bg_color ?? null;

  const [stageBgColor, setStageBgColor] = useState(stage_bg_color ?? "#000");
  const [zoomDetails, setZoomDetails] = useState({
    zoom_api_key: "",
    zoom_secret_key: "",
    zoom_verification_token: "",
    zoom_user_id: ""
  });
  const [isZoomAssociated, setIsZoomAssociated] = useState(false);

  // console.log("event manager ML ==> ",event_manager)
  // console.log("Current User ML ==> ",currentUser.email)
  let isSuperAdmin = currentUser.email === SUPER_ADMIN_EMAIL
  // console.log("isSuperAdmin ML ==> ",isSuperAdmin)
  event_manager = !isSuperAdmin ? event_manager.filter(item => item !== SUPER_ADMIN_EMAIL) : event_manager
  // console.log("event manager After ML ==> ",event_manager)


  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", created_by ?? null);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const ConfirmationDialog = ({ open, onClose, onConfirm,type }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{type === "zoom" ? "Remove Zoom Integration Confirmation" : "Remove Event Manager Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {type === "zoom" 
            ? `Are you sure you want to remove Zoom Integration?`
            : `Are you sure you want to remove "${currentItemToRemove}" as the Event Manager?`
            }
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonM onClick={onClose} color="primary">
            No
          </ButtonM>
          <ButtonM onClick={onConfirm} color="secondary">
            Yes
          </ButtonM>
        </DialogActions>
      </Dialog>
    );
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentItemToRemove(null); // Reset the current item
  };

  const handleCloseDialogZoom = () => {
    setOpenDialogZoom(false);
  };

  useEffect(() => {
    if (!loadingEvent) {
      firebase.firestore().collection("events").doc(eventId).update({
        chat_enabled: chatOn,
      });
    }
  }, [loadingEvent]);

  const eventDetails = eventSnapshot?.data() ?? {};

  const eventManagerValidationSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
  });

  const removeEventManager = (item) => {

    console.log("removeEventManager ML Item ==> ",item)
    // setOpenDialog(false);

    firebase
      .firestore()
      .collection("events")
      .doc(eventId)
      .update({
        event_manager: firebase.firestore.FieldValue.arrayRemove(`${item}`),
      })
      .then(() => {
        setAddEventManager(false);
        setOpenDialog(false);
        setCurrentItemToRemove(null);

        toast.success("Event Manager Removed.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });

      });
  };

  const resetZoomDetails = () => {
    setZoomDetails({
      zoom_api_key: "",
      zoom_secret_key: "",
      zoom_verification_token: "",
      zoom_user_id:""
    });
  };

  const removeZoomIntegration = async () => {
    console.log("Remove Zoom")
    console.log("currentUser.uid ==> ",currentUser.uid)

    const REMOVE_ZOOM_INTEGRATION = `${API_BASE_URL}/integration/zoom/remove-account-associate`;

    try {
      const response = await axios.post(REMOVE_ZOOM_INTEGRATION, {user_id:currentUser.uid});

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setOpenDialogZoom(false)
        setIsZoomAssociated(false);
        resetZoomDetails();
      }
    } catch (error) {
      console.error("ERROR: Remove Zoom account association - ", error);
      toast.warning("Error Removing Zoom account.", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }

  }

  const handleZoomIntegration = async (e) => {
    e.preventDefault();
    const { zoom_api_key, zoom_secret_key, zoom_verification_token, zoom_user_id } =
      zoomDetails;

    if (
      zoom_api_key === "" ||
      zoom_secret_key === "" ||
      zoom_verification_token === "" ||
      zoom_user_id === ""
    ) {
      toast.warning("Please fill all mandatory details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }

    const ZOOM_INTEGRATION = `${API_BASE_URL}/integration/zoom/account-associate`;

    const dataToSend = {
      user_id: currentUser.uid,
      ...zoomDetails,
    };

    try {
      const response = await axios.post(ZOOM_INTEGRATION, dataToSend);

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setIsZoomAssociated(true);
        resetZoomDetails();
      }
      
    } catch (error) {
      console.error("ERROR: Zoom account association - ", error);
    
      if (error?.response && error?.response?.status === 400) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        toast.error("Error integrating zoom account.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }

      resetZoomDetails();
      
    }
  };

  const handleFormFieldChange = (e) => {
    setZoomDetails((prev) => {
      let newState = { ...prev };

      newState[e.target.name] = e.target.value;

      return newState;
    });
  };

  const zoomLinkingStatus = async (user_id) => {
    const ZOOM_INTEGRATION_STATUS = `https://dev.igesia.com/integration/zoom/account/association-status`;

    if (user_id) {
      try {
        let res = await axios.post(ZOOM_INTEGRATION_STATUS, {
          user_id: user_id,
        });
        if (res.status === 200) return res.data.data["is_associated"];
      } catch (error) {
        console.error("ERROR: Zoom integration status - ", error);
      }
    }
  };

  const checkZoomApiKeys = async() => {

    const username = zoomDetails.zoom_api_key;
    const password = zoomDetails.zoom_secret_key;
    const basicAuth = 'Basic ' + Buffer.from(username + ':' + password).toString('base64');

    try {
      const response = await axios.post(`https://zoom.us/oauth/token?grant_type=account_credentials&account_id=${zoomDetails.zoom_user_id}`, {}, {
        headers: {
          'Authorization': basicAuth
        }
      });
      console.log('Response ML:', response.data);

      if(response.status === 200){
        setCorrectApiKeys(true)
      }else{
        toast.warning("Invalid Api Keys.", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error ML:', error);
      return null;
    }
  }

  useEffect(async () => {
    if (eventDetails?.api_type != "prod") {
      const is_associated = await zoomLinkingStatus(currentUser?.uid);
      setIsZoomAssociated(is_associated);
    }
  }, [eventDetails]);

  return (
    <div className="container-fluid vh-100 overflow-auto">
      <div className="h-100 my-3 text-light" style={{ overflow: "scroll" }}>
        <div className="h-100 mt-3" style={{ overflow: "auto" }}>
          {/* Section: Administrator starts */}
          <Card
            style={{ width: "100%" }}
            className="shadow-sm bg-dark shadow-sm mb-3"
          >
            <Card.Header>
              <div className="d-flex justify-content-between">
                <h5 className="mb-0">Administrator</h5>
                <React.Fragment>
                  {addEventManager ? (
                    <p>
                      <b>New</b>
                    </p>
                  ) : (
                    <Button size="sm" onClick={() => setAddEventManager(true)}>
                      New
                    </Button>
                  )}
                </React.Fragment>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                {addEventManager && (
                  <Formik
                    validationSchema={eventManagerValidationSchema}
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={(values, { resetForm }) => {

                      const emailAlreadyExists = event_manager.some(index => index === values.email);
                      const isSuperAdminEmail = values.email === SUPER_ADMIN_EMAIL;

                      if(emailAlreadyExists){
                        toast.error("Event Manager Already Exists.", {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                        });
                      }

                      if(isSuperAdminEmail && (currentUser.email !== SUPER_ADMIN_EMAIL)){
                        toast.error("Unauthorized to Assign Super Admin as Event Manager", {
                          position: toast.POSITION.TOP_CENTER,
                          hideProgressBar: true,
                          autoClose: 3000,
                        });
                      }

                      if((!emailAlreadyExists && !isSuperAdminEmail)){
                        firebase
                        .firestore()
                        .collection("events")
                        .doc(eventId)
                        .update({
                          event_manager:
                            firebase.firestore.FieldValue.arrayUnion(
                              `${values.email}`
                            ),
                        })
                        .then(() => {
                          setAddEventManager(false);
                          toast.success("Event Manager Added.", {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                          });
                        });
                      }
                    }}
                  >
                    {({
                      errors,
                      touched,
                      isValidating,
                      setFieldValue,
                      values,
                    }) => (
                      <FormComponent>
                        <div className="form-label-group">
                          <label htmlFor="email" className="mb-1">
                            Email address
                          </label>
                          <Field
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Email"
                            className="form-control"
                            value={values.email}
                            onChange={(e) => {
                              const value = e.target.value || "";
                              setFieldValue("email", value.toLowerCase());
                            }}
                          ></Field>
                          {errors.email && touched.email ? (
                            <div className="text-danger small ml-2">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="mt-2">
                          <Button className="mr-2" size="sm" type="submit">
                            Add
                          </Button>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => setAddEventManager(false)}
                          >
                            Discard
                          </Button>
                        </div>
                      </FormComponent>
                    )}
                  </Formik>
                )}
              </div>
              {event_manager.map((item, key) => (
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <p className="text-white mb-0">{`${key + 1}. ${item}`}</p>
                  {(item !== currentUser.email) ?
                  <>
                    <Button
                      onClick={() => {
                        setCurrentItemToRemove(item);
                        setOpenDialog(true);
                      }}
                      size="sm"
                      className="btn-sm btn-danger"
                      style={{cursor:"pointer"}}
                    >
                      <FontAwesomeIcon size="sm" icon={faTrash} /> Remove
                    </Button>
                    <ConfirmationDialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      onConfirm={() => removeEventManager(currentItemToRemove)}
                    />
                  </>
                  :
                  <Button
                    disabled
                    size="sm"
                    className="btn-sm btn-danger"
                    style={{backgroundColor:"#999999",cursor:"not-allowed"}}
                  >
                    <FontAwesomeIcon size="sm" icon={faTrash} /> Remove
                  </Button>
                  }
                </div>
              ))}
            </Card.Body>
          </Card>
          {/* Section: Administrator ends */}

          {/* Section: Zoom linking starts */}
          {eventSnapshot?.data()?.api_type === "prod"
            ? null
            : currentEvent?.created_by === currentUser?.uid &&
              created_by === currentUser?.uid && (
                <Card
                  style={{ width: "100%" }}
                  className="shadow-sm bg-dark shadow-sm mb-3"
                >
                  <Card.Header>
                    <h5 className="mb-0">Zoom Integration</h5>
                  </Card.Header>
                  <Card.Body>
                    {isZoomAssociated ? (
                      <p className="mb-0">
                        Zoom successfully integrated using{" "}
                        <b>{currentUser?.email}</b>.
                      </p>
                    ) : (
                      <div className="">
                        <h5 className="mb-0">
                          Fill in these details to link your Zoom account.
                        </h5>
                        <p className="small text-muted">
                          Marked fields (<span className="text-danger">*</span>)
                          are mandatory.
                        </p>

                        <Form onSubmit={handleZoomIntegration}>
                          <Form.Group>
                            <Form.Label>
                              API Key (Client ID) <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              name="zoom_api_key"
                              placeholder="Enter value"
                              onChange={handleFormFieldChange}
                              value={zoomDetails?.zoom_api_key}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              API Secret (Client Secret) <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              name="zoom_secret_key"
                              placeholder="Enter value"
                              onChange={handleFormFieldChange}
                              value={zoomDetails?.zoom_secret_key}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              API User ID  <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              name="zoom_user_id"
                              placeholder="Enter value"
                              onChange={handleFormFieldChange}
                              value={zoomDetails?.zoom_user_id}
                            />
                          </Form.Group>

                          <Form.Group>
                            <Form.Label>
                              JWT Token (Secret Token) <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              name="zoom_verification_token"
                              placeholder="Enter value"
                              onChange={handleFormFieldChange}
                              value={zoomDetails?.zoom_verification_token}
                            />
                          </Form.Group>

                          <div className="d-flex">
                              <Button
                                size="sm"
                                className="text-uppercase mr-2"
                                type="submit"
                              >
                                Submit
                              </Button>
                            <Button
                              size="sm"
                              className="text-uppercase shadow-sm"
                              variant="light"
                              onClick={() => resetZoomDetails()}
                            >
                              Reset form
                            </Button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Card.Body>
                  {isZoomAssociated && 
                    <Card.Footer>
                      <Button
                          onClick={() => setOpenDialogZoom(true)}
                          size="sm"
                          className="btn-sm btn-danger"
                          style={{cursor:"pointer"}}
                        >
                          <FontAwesomeIcon size="sm" icon={faTrash} /> Remove
                        </Button>
                        <ConfirmationDialog
                          open={openDialogZoom}
                          onClose={handleCloseDialogZoom}
                          onConfirm={() => removeZoomIntegration()}
                          type={"zoom"}
                        />
                    </Card.Footer>
                  }
                </Card>
              )}
          {/* Section: Zoom linking ends */}

          {/* Section: Branding starts */}
          <Card
            style={{ width: "100%" }}
            className="shadow-sm bg-dark shadow-sm mb-3"
          >
            <Card.Header>
              <h5 className="mb-0">Branding</h5>
            </Card.Header>
            <Card.Body>
              {/* Top Branding Image */}
              <div className="mb-3">
                <Form>
                  <Form.Group>
                    <Form.Label className="text-uppercase">
                      Top Branding Image
                    </Form.Label>
                    {!!top_branding_image && (
                      <div className="mb-2">
                        <p className="mb-1 text-uppercase small">
                          <b>Preview</b>
                        </p>
                        <Image className="img-fluid" src={top_branding_image} />
                      </div>
                    )}
                    <Form.File
                      id="top-branding-image"
                      name="top-branding-image"
                      accept="image/*"
                      onChange={async (event) => {
                        if (!!event.target.files[0]) {
                          const new_top_branding_image = event.target.files[0]
                            ? await uploadFile(event.target.files[0])
                            : null;

                          try {
                            const docRef = firebase
                              .firestore()
                              .collection(`events`)
                              .doc(`${eventId}`);

                            await docRef.update({
                              top_branding_image: new_top_branding_image,
                            });

                            toast.success(
                              "Top branding image successfully changed",
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              }
                            );
                          } catch (e) {
                            console.error(e, "error in saving to db");
                            toast.error("Error updating top branding image", {
                              position: toast.POSITION.TOP_CENTER,
                              hideProgressBar: true,
                              autoClose: 3000,
                            });
                          }
                        } else {
                          toast.info("Please select some image to update", {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>

              {/* Stage background color */}
              <div className="">
                <p className="text-uppercase">Stage Background Color</p>
                <div className="d-flex">
                  <div className="d-flex align-items-center p-1 bg-white rounded">
                    <a
                      onClick={handleShow}
                      style={{
                        height: 40,
                        width: 80,
                        backgroundColor: stage_bg_color
                          ? stage_bg_color
                          : "#ffffff",
                        cursor: "pointer",
                      }}
                      className="shadow-sm border rounded"
                      title="Click to change color"
                    ></a>
                    <a
                      className="text-uppercase mb-0 text-dark px-2"
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      onClick={handleShow}
                    >
                      {stage_bg_color ? stage_bg_color : "#ffffff"}
                    </a>
                  </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Stage Background Color</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
                    <SketchPicker
                      className="w-100 mb-3"
                      color={stageBgColor ?? "#ffffff"}
                      onChangeComplete={(color) => {
                        setStageBgColor(color.hex);
                      }}
                    />
                    <Button
                      className="text-uppercase w-100"
                      onClick={() => {
                        firebase
                          .firestore()
                          .collection("events")
                          .doc(eventId)
                          .update({
                            stage_bg_color: stageBgColor,
                          })
                          .then(() => {
                            toast.success(
                              "Stage background color successfully changed",
                              {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              }
                            );
                            handleClose();
                          })
                          .catch((err) =>
                            console.err("Error changing stage bg color: ", err)
                          );
                      }}
                      size="sm"
                    >
                      Save Changes
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
            </Card.Body>
          </Card>
          {/* Section: Branding ends */}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});
export default connect(mapStateToProps)(Settings);
