import React, { useMemo, memo } from "react";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Card, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { useHistory } from "react-router-dom";

function UpcomingSchedule(props) {
  const {
    eventId,
    eventSlug,
    startsAt,
    darkTheme,
    currentLanguage,
    currentEvent,
  } = props;
  const user = firebase.auth().currentUser;
  const history = useHistory();

  // Agenda data
  const [scheduleSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventId}/schedule`)
      .where("ends_at", ">", startsAt)
      .orderBy("ends_at", "asc")
  );

  const [currentUserDoc, currentUserLoading, currentUserError] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${eventId}/users`)
      .doc(`${user.uid}`)
  );
  const currentUserCategory = currentUserDoc?.data()?.category ?? "";
  const currentUserRole = currentUserDoc?.data()?.user_role ?? 0;

  const agenda = useMemo(() => {
    return scheduleSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [scheduleSnapshot]);

  const agendaUpcoming = useMemo(() => {
    return agenda?.sort((a, b) => a.starts_at - b.starts_at);
    //   ?.filter((item) => item.ends_at > startsAt);
  }, [agenda]);

  //   Speakers data
  const [speakerSnapshot, loadingSnapshot, errorSnapshot] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${eventId}/speakers`)
      .orderBy("order_index", "asc")
  );

  const allSpeakers = useMemo(() => {
    return speakerSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        ...fac.data(),
      };
    });
  }, [speakerSnapshot]);

  const showAgenda = (agenda, role, category) => {
    let show = false;

    if (!agenda?.assignedTo || !agenda?.assignedToAll) {
      show = true;
    }

    if (agenda.assignedToAll || role === 1) {
      show = true;
    } else if (
      !agenda?.assignedTo ||
      !agenda?.assignedToAll ||
      !agenda.assignedToCategoryMultiple
    ) {
      show = true;
    } else {
      if (agenda.assignedToCategoryMultiple) {
        if (agenda.assignedToCategory.includes(category)) {
          show = true;
        } else show = false;
      } else {
        if (agenda.assignedToCategory === category) show = true;
        else show = false;
      }
    }
    return show;
  };

  return (
    <div className="mb-3">
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        agendaUpcoming.length > 0 && (
          <React.Fragment>
            <h5 className="font-weight-bold">Upcoming Session</h5>
            <React.Fragment>
              {agendaUpcoming?.map((item, key) => {
                const scheduleLangData = {
                  ...item[currentLanguage],
                  id: item.id,
                };
                if (key === 0)
                  return (
                    <>
                      {
                        // showAgenda(item, currentUserRole, currentUserCategory)
                        // true
                        // && (
                        <div className="">
                          <Card
                            className={
                              darkTheme
                                ? item?.ends_at < startsAt
                                  ? agendaUpcoming &&
                                    agendaUpcoming[0]?.id === item.id
                                    ? `bg-dark shadow-sm border border-primary`
                                    : `bg-dark shadow-sm border border-dark`
                                  : agendaUpcoming &&
                                    agendaUpcoming[0]?.id === item.id
                                  ? `bg-dark shadow-sm border border-primary`
                                  : `bg-dark shadow-sm border border-dark`
                                : item?.ends_at < startsAt
                                ? agendaUpcoming &&
                                  agendaUpcoming[0]?.id === item.id
                                  ? `bg-light shadow-sm border border-primary`
                                  : `bg-light shadow-sm border border-light`
                                : agendaUpcoming &&
                                  agendaUpcoming[0]?.id === item.id
                                ? `bg-white shadow-sm border border-primary`
                                : `bg-white shadow-sm border border-light`
                            }
                          >
                            <Card.Header
                              className={
                                darkTheme
                                  ? item?.ends_at < startsAt
                                    ? "border-0 bg-dark mb-0 pb-0"
                                    : "border-0 bg-dark mb-0 pb-0"
                                  : item?.ends_at < startsAt
                                  ? "border-0 bg-light mb-0 pb-0"
                                  : "border-0 bg-white mb-0 pb-0"
                              }
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="small mb-0">
                                  {moment(item?.starts_at).format(
                                    "MMMM Do HH:mm"
                                  )}{" "}
                                  - {moment(item?.ends_at).format("HH:mm")} ·{" "}
                                </p>
                                <div>
                                  <AddToCalendar
                                    style={{ zIndex: 10 }}
                                    event={{
                                      name: item["EN"].title,
                                      details: item?.stage
                                        ? `${item["EN"].description}  <p><a href=${item.stage.stage_link}>${item.stage.stage_link}</a></p>`
                                        : `${item["EN"].description}`,
                                      startsAt: item.starts_at,
                                      endsAt: item.ends_at,
                                      location:
                                        currentUserRole >= 1
                                          ? item.start_url ?? item.meeting_url
                                          : item.meeting_url,
                                    }}
                                  >
                                    Add to calendar
                                  </AddToCalendar>
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body
                              onClick={() =>
                                history.push(
                                  item.meeting_type >= 1
                                    ? `/event/${eventSlug}/agenda/${item.id}`
                                    : `/event/${eventSlug}/agenda/${item.id}/live`
                                )
                              }
                              style={{ cursor: "pointer" }}
                              className={
                                item?.ends_at < startsAt
                                  ? `mt-0 pt-0 text-muted`
                                  : `mt-0 pt-0`
                              }
                            >
                              <h4 className="text-s-10 mb-1">
                                {scheduleLangData.title}
                              </h4>
                              <p className="small">
                                {scheduleLangData.description.replace(
                                  /(<([^>]+)>)/gi,
                                  "\t"
                                ).length > 250
                                  ? parse(
                                      scheduleLangData.description
                                        .replace(/(<([^>]+)>)/gi, "\t")
                                        .substring(0, 250) +
                                        "..." +
                                        "<a href=''>Read more</a>"
                                    )
                                  : parse(scheduleLangData.description)}
                              </p>
                              <div className="row">
                                {item?.speakers?.map((speaker, key) => {
                                  const facultyData = allSpeakers?.filter(
                                    (item) => item.uid === speaker
                                  )[0];

                                  return (
                                    <div
                                      key={key}
                                      className="col-md-6 d-flex align-items-center justify-content-center"
                                      title={facultyData?.first_name}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      // onClick={() => {
                                      //   setShowProfile(true);
                                      //   setSelectedProfile(facultyData);
                                      // }}
                                    >
                                      <img
                                        className="rounded-circle"
                                        height="50"
                                        width="50"
                                        src={facultyData?.img_src}
                                        title={facultyData?.first_name}
                                        alt=""
                                      />
                                      <div className="flex-grow-1 ml-2">
                                        <h6 className="mb-1">
                                          {facultyData?.first_name}{" "}
                                          {facultyData?.last_name}
                                        </h6>
                                        <p className="small mb-0">
                                          {facultyData?.designation}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                        // )
                      }
                    </>
                  );
                else return null;
              })}
            </React.Fragment>
          </React.Fragment>
        )
      )}
    </div>
  );
}

const mapStatesToProps = (state) => ({
  currentLanguage: state.currentLanguage,
  currentEvent: state.currentEvent,
  darkTheme: state.darkTheme,
});

export default connect(mapStatesToProps)(memo(UpcomingSchedule));
