import { useState } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Index";
import { GithubPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../config";
import axios from "axios";
import firebase from "firebase";
import { toast } from "react-toastify";

const AddOrganisation = (props) => {
  const [orgName, setOrgName] = useState("");
  const [slug, setSlug] = useState("");
  const [aboutOrg, setAboutOrg] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [saving, setSaving] = useState(false);

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", "1gHTSDwzWRPW7KAUeTcscDFxs7z2");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const saveOrgToDBHandler = async (e) => {
    e.preventDefault();
    const orgData = {
      organisation_name: orgName,
      organisation_about: aboutOrg,
      slug,
      avatar: avatar ? await uploadFile(avatar) : null,
      cover_image: coverImage ? await uploadFile(coverImage) : null,
    };

    try {
      setSaving(true);
      await firebase
        .firestore()
        .collection(`/organizations/`)
        .doc()
        .set({
          ...orgData,
          owner_id: "1gHTSDwzWRPW7KAUeTcscDFxs7z2",
          timestamp: Date.now(),
        });
      toast.success("You have successfully added organization", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in saving to db");
      toast.error("Error in updatingevent details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="d-flex vh-100" style={{ background: "#232626" }}>
      <Sidebar />
      <div className="overflow-auto max-vh-100 p-4 w-100" id="style-4">
        <Card className="bg-dark text-light">
          <Card.Header className="border-0 bg-dark text-center">
            <h5 className="mb-0">Add Organisation</h5>
          </Card.Header>
          <Card.Body>
            <Form className="theme-form">
              <Row>
                <Col>
                  <Form.Group className="">
                    <label>Organisation Name</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="orgName"
                      placeholder="Organisation Name"
                      value={orgName}
                      onChange={(e) => {
                        setOrgName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  {/* <label className="text-primary small m-0">
                    {orgName.length ? (
                      <> @{orgName.replace(/ /g, "").toLowerCase()} </>
                    ) : null}
                  </label> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="">
                    <label>Slug</label>
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="slug"
                      placeholder="Slug"
                      value={orgName}
                      onChange={(e) => {
                        setSlug(e.target.value);
                      }}
                    />
                  </Form.Group>
                  {/* <label className="text-primary small m-0">
                    {orgName.length ? (
                      <> @{orgName.replace(/ /g, "").toLowerCase()} </>
                    ) : null}
                  </label> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label>About</label>
                    <Form.Control
                      className="form-control"
                      as="textarea"
                      name="about"
                      placeholder="About your organisation"
                      onChange={(e) => {
                        setAboutOrg(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label htmlFor="avatar">Avatar</label>
                    <Form.Control
                      type="file"
                      name="avatar"
                      id="avatar"
                      onChange={(event) => {
                        setAvatar(event.target.files[0]);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label htmlFor="cover">Cover image</label>
                    <Form.Control
                      type="file"
                      name="cover"
                      id="cover"
                      onChange={(event) => {
                        setCoverImage(event.target.files[0]);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col md="3">
                  <Form.Group>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          The primary accent color. Used in buttons and navbar.
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <label ref={ref} {...triggerHandler}>
                          Accent color #1{" "}
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            color="white"
                          />
                        </label>
                      )}
                    </OverlayTrigger>

                    <GithubPicker className="bg-dark" triangle="hide" />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          The secondary accent color. Used in secondary buttons.
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <label ref={ref} {...triggerHandler}>
                          Accent color #2{" "}
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            color="white"
                          />
                        </label>
                      )}
                    </OverlayTrigger>

                    <GithubPicker className="bg-dark" triangle="hide" />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          The primary background color. Used as backdrop color
                          for workspace.
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <label ref={ref} {...triggerHandler}>
                          Background color #1{" "}
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            color="white"
                          />
                        </label>
                      )}
                    </OverlayTrigger>

                    <GithubPicker className="bg-dark" triangle="hide" />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          The secondary background color. Used in cards
                          background.
                        </Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <label ref={ref} {...triggerHandler}>
                          Background color #2{" "}
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            color="white"
                          />
                        </label>
                      )}
                    </OverlayTrigger>

                    <GithubPicker className="bg-dark" triangle="hide" />
                  </Form.Group>
                </Col>
              </Row> */}
              <Row className="text-center my-3 mt-5">
                <Col>
                  <Form.Group className="mb-0">
                    <Button
                      variant="primary"
                      size="sm"
                      className="mr-3"
                      onClick={saveOrgToDBHandler}
                    >
                      Add
                    </Button>
                    <Button variant="danger" size="sm">
                      Cancel
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {/* <Card className="bg-dark text-light my-4">
          <Card.Header className="border-0 bg-dark text-center">
            <h5 className="mb-0">Settings</h5>
          </Card.Header>
          <Card.Body>
            <Form className="theme-form">
              <Row>
                <Col md="12">
                  <label>Timeline Settings</label>
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Allow participants to post"
                    id="allowpost"
                  />{" "}
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Allow participants to comment"
                    id="allowcomment"
                  />{" "}
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Admin only chat"
                    id="adminchat"
                  />{" "}
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Allow networking"
                    id="allownetworking"
                  />{" "}
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <label>Room Settings</label>
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Allow guest users"
                    id="allowpost"
                  />{" "}
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Allow co-admins"
                    id="allowcomment"
                  />{" "}
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Admin only chat"
                    id="adminchat"
                  />{" "}
                </Col>
                <Col md="3">
                  <Form.Check
                    type="switch"
                    label="Allow networking"
                    id="allownetworking"
                  />{" "}
                </Col>
              </Row>
              <Row className="text-center my-3 mt-5">
                <Col>
                  <Form.Group className="mb-0">
                    <Button variant="primary" size="sm" className="mr-3">
                      Add
                    </Button>
                    <Button variant="danger" size="sm">
                      Cancel
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card> */}
      </div>
    </div>
  );
};
export default AddOrganisation;
