import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Spinner,
  FormCheck,
  Modal,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEdit,
  faTrash,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { API_BASE_URL, EVENT_PARTICIPANTS } from "../../../config/index";

import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../utils";
import ReactExport from "react-export-excel";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";

toast.configure();

function RegistrationSettings(props) {
  // CONSTANTS
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc(props.eventId)
  );
  const newRegistrationEnabled = eventSnapshot?.data()?.is_new_registration;
  const custom_form_title = eventSnapshot?.data()?.custom_form_title ?? null;
  const custom_form_required =
    eventSnapshot?.data()?.custom_form_required ?? false;
  const custom_form = eventSnapshot?.data()?.custom_form ?? null;

  // STATES
  const [formLive, setFormLive] = useState(false);
  const [formUpdating, setFormUpdating] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [customFormFields, setCustomFormFields] = useState({});
  const [selectValues, setSelectValues] = useState([]);
  const [allCustomFormFields, setAllCustomFormFields] = useState([]);
  const [updateField, setUpdateField] = useState(false);
  const [updateFieldKey, setUpdateFieldKey] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showModalAnotherField, setShowModalAnotherField] = useState(false);
  const [participantList, setParticipantList] = useState([]);
  const [fetchingParticipants, setFetchingParticipants] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  // FUNCTIONS
  useEffect(() => {
    custom_form_title != "" && setFormTitle(custom_form_title);
    !!custom_form && setAllCustomFormFields(custom_form);
    custom_form_required && setFormLive(true);
  }, [custom_form_title, formUpdating]);

  const handleCustomFormSubmit = (event) => {
    event.preventDefault();

    if (formTitle === "" || formTitle === null) {
      alert("Please enter form title");
      return;
    }
    if (allCustomFormFields?.length === 0) {
      alert("Please add at least one field to form");
      return;
    }

    setSubmittingForm(true);
    firebase
      .firestore()
      .collection(`events`)
      .doc(`${props.eventId}`)
      .update({
        custom_form_title: formTitle,
        custom_form_required: true,
        custom_form: allCustomFormFields,
      })
      .then(() => {
        setCustomFormFields({});
        setFieldType("");
        setSelectValues([]);
        setUpdateField(false);
        setUpdateFieldKey(null);
        setFormUpdating(false);

        toast.success("Custom form successfully submitted", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((e) => {
        console.error(e, "error in saving to db");
        toast.error("Error submitting custom form", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .then(() => {
        setSubmittingForm(false);
      });
  };

  const handleFetchParticipantsList = async () => {
    if (props.eventId) {
      const response = await postData(`${API_BASE_URL}${EVENT_PARTICIPANTS}`, {
        event_id: props.eventId,
      });

      if (response.status === 200) {
        const { data } = response;
        const participant_list = data.participantList?.map((item) => {
          return {
            ...item,
            displayName: item.firstName + " " + item.lastName,
          };
        });

        setParticipantList(participant_list);
        setFetchingParticipants(false);
      }
    }
  };

  useEffect(() => {
    handleFetchParticipantsList();
  }, [props.eventId]);

  useEffect(() => {
    if (participantList?.length > 0) {
      const columns = [
        "#",
        "Name",
        "Email",
        ...allCustomFormFields
          ?.sort((a, b) => a.id - b.id)
          ?.map((formField) => formField.label),
      ];

      const newList = participantList?.map((participant, idx) => {
        let newData = [
          idx + 1,
          participant["displayName"],
          participant["email"],
        ];

        allCustomFormFields
          ?.sort((a, b) => a.id - b.id)
          ?.map((formField) => {
            newData.push(participant[`${formField.field_name}`]);
            return null;
          });

        return newData;
      });

      setColumns(columns);
      setData(newList);
      setFetchingData(false);
    }
  }, [participantList]);

  return (
    <div
      className="container-fluid"
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <div className="py-3 text-light">
        <Card
          style={{ width: "100%" }}
          className="shadow-sm bg-dark shadow-sm mb-3 text-white"
        >
          <Card.Header>
            <Card.Title className="mb-0">Registration settings</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon icon={faUserPlus} className="mx-4 btn-hero" />
                <div className="d-flex flex-column">
                  <span className="small font-weight-bold text-uppercase">
                    Disable New Registration
                  </span>
                  <span>
                    <FormCheck
                      type="switch"
                      id="newRegistration"
                      label={newRegistrationEnabled ? "On" : "Off"}
                      //disabled={true}
                      checked={newRegistrationEnabled}
                      onChange={(e) =>
                        firebase
                          .firestore()
                          .collection("events")
                          .doc(props.eventId)
                          .update({
                            is_new_registration: e.target.checked,
                          })
                      }
                    ></FormCheck>
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon
                  icon={faAddressCard}
                  className="mx-4 btn-hero"
                />
                <div className="d-flex flex-column">
                  <span className="small font-weight-bold text-uppercase">
                    Invite only
                  </span>
                  <span>
                    <FormCheck
                      type="switch"
                      id="inviteOnly"
                      label={eventSnapshot?.data()?.invite_only ? "On" : "Off"}
                      //disabled={true}
                      checked={
                        eventSnapshot?.data()?.invite_only ? true : false
                      }
                      onChange={(e) =>
                        firebase
                          .firestore()
                          .collection("events")
                          .doc(props.eventId)
                          .update({
                            invite_only: e.target.checked,
                          })
                      }
                    ></FormCheck>
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card
          style={{ width: "100%" }}
          className="shadow-sm bg-dark shadow-sm mb-3"
        >
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">Custom registration fields</h5>
              {formLive ? (
                <div>
                  <Button
                    size="sm"
                    className="text-uppercase mr-2"
                    variant="secondary"
                    onClick={() => {
                      setFormUpdating((prev) => !prev);
                    }}
                  >
                    {formUpdating ? "Cancel Updating" : "Update Form"}
                  </Button>

                  {formUpdating && (
                    <Button
                      size="sm"
                      className="text-uppercase"
                      onClick={() => setShowModalAnotherField(true)}
                    >
                      Add Field
                    </Button>
                  )}
                </div>
              ) : (
                <Button
                  size="sm"
                  className="text-uppercase"
                  onClick={() => setShowModalAnotherField(true)}
                >
                  Add Field
                </Button>
              )}
            </div>
            {formLive ? (
              formUpdating && (
                <p className="text-uppercase small mb-0">Edit form</p>
              )
            ) : (
              <p className="text-uppercase small mb-0">Create form</p>
            )}
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleCustomFormSubmit}>
              {formLive ? (
                formUpdating ? (
                  <Form.Group>
                    <Form.Label>Form Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="form_title"
                      placeholder="Enter form title"
                      onChange={(e) => setFormTitle(e.target.value)}
                      value={formTitle}
                    />
                    <Form.Text className="text-muted">
                      Title will be displayed on top of this form.
                    </Form.Text>
                  </Form.Group>
                ) : (
                  <div className="mb-3">
                    <p className="text-uppercase text-muted small mb-0">
                      <b>Form Title</b>
                    </p>
                    <p className="h5">{formTitle}</p>
                  </div>
                )
              ) : (
                <Form.Group>
                  <Form.Label>Form Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="form_title"
                    placeholder="Enter form title"
                    onChange={(e) => setFormTitle(e.target.value)}
                    value={formTitle}
                  />
                  <Form.Text className="text-muted">
                    Title will be displayed on top of this form.
                  </Form.Text>
                </Form.Group>
              )}
              <div>
                {allCustomFormFields.length > 0 ? (
                  <div>
                    {allCustomFormFields.map((field, idx) => (
                      <div className="border rounded p-3 mb-3" key={idx}>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <p className="text-uppercase small mb-1">
                              <b className="mr-2">
                                {idx + 1}. {field.type}
                              </b>
                              <small>
                                (
                                {field.required ? "Mandatory" : "Not mandatory"}
                                )
                              </small>
                            </p>
                          </div>

                          {formLive ? (
                            formUpdating && (
                              <div className="d-flex">
                                <a
                                  className="mr-2 pointer"
                                  onClick={() => {
                                    setUpdateField(true);
                                    setUpdateFieldKey(idx);
                                    setShowModalAnotherField(true);
                                    setCustomFormFields({ ...field });
                                    setFieldType(field.type);
                                    if (field.type === "select") {
                                      setSelectValues(field.values);
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon size="sm" icon={faEdit} />
                                </a>
                                <a
                                  className="text-danger pointer"
                                  onClick={() => {
                                    setAllCustomFormFields((prev) => {
                                      const oldState = [...prev];
                                      const newState = oldState.filter(
                                        (item) => item.id != idx + 1
                                      );
                                      return newState;
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon size="sm" icon={faTrash} />
                                </a>
                              </div>
                            )
                          ) : (
                            <div className="d-flex">
                              <a
                                className="mr-2 pointer"
                                onClick={() => {
                                  setUpdateField(true);
                                  setUpdateFieldKey(idx);
                                  setShowModalAnotherField(true);
                                  setCustomFormFields({ ...field });
                                  setFieldType(field.type);
                                  if (field.type === "select") {
                                    setSelectValues(field.values);
                                  }
                                }}
                              >
                                <FontAwesomeIcon size="sm" icon={faEdit} />
                              </a>
                              <a
                                className="text-danger pointer"
                                onClick={() => {
                                  setAllCustomFormFields((prev) => {
                                    const oldState = [...prev];
                                    const newState = oldState.filter(
                                      (item) => item.id != idx + 1
                                    );
                                    return newState;
                                  });
                                }}
                              >
                                <FontAwesomeIcon size="sm" icon={faTrash} />
                              </a>
                            </div>
                          )}
                        </div>

                        <p className="mb-0 small text-uppercase text-muted">
                          <b>Field label</b>
                        </p>
                        <p
                          className={field.type === "select" ? "mb-1" : "mb-0"}
                        >
                          {field.label}
                        </p>

                        {field.type === "select" && (
                          <React.Fragment>
                            <p className="mb-0 small text-uppercase text-muted">
                              <b>Values</b>
                            </p>
                            <p className="mb-0">{field.values.join(", ")}</p>
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="">No field added yet</p>
                  </div>
                )}
              </div>

              <Modal
                show={showModalAnotherField}
                onHide={() => {
                  setCustomFormFields({});
                  setFieldType("");
                  setSelectValues([]);

                  setShowModalAnotherField(false);
                }}
              >
                <Modal.Header closeButton>
                  <div>
                    <h4 className="mb-0">
                      {updateField ? customFormFields?.label : "Add Field"}
                    </h4>
                    {updateField && (
                      <p className="text-muted text-uppercase mb-0">
                        Update Field
                      </p>
                    )}
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <Form.Label className="text-uppercase small text-muted">
                      <b>Field Type</b>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => setFieldType(e.target.value)}
                      value={fieldType}
                    >
                      <option value="">Select field type</option>
                      <option value="text">Text</option>
                      <option value="select">Select</option>
                      <option value="number">Number</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="text-uppercase small text-muted">
                      <b>Field Label</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="label"
                      placeholder="Enter field label"
                      value={customFormFields?.label}
                      onChange={(e) => {
                        setCustomFormFields((prev) => {
                          return {
                            ...prev,
                            [e.target.name]: e.target.value,
                            field_name: e.target.value
                              .toLowerCase()
                              .replace(/[^a-zA-Z0-9]/g, "_"),
                          };
                        });
                      }}
                    />
                    <Form.Text className="text-muted">
                      Label will be displayed as name of this form field.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      type="hidden"
                      name="field_name"
                      value={customFormFields?.field_name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="text-uppercase small text-muted mb-0">
                      <b>Mandatory Field</b>
                    </Form.Label>
                    <Form.Text className="text-muted mb-2">
                      (If this field is <b>mandatory</b> to be filled by user or
                      not)
                    </Form.Text>
                    <FormCheck
                      type="switch"
                      id="field-required"
                      label={customFormFields?.required ? "Yes" : "No"}
                      checked={customFormFields?.required}
                      onChange={(e) =>
                        setCustomFormFields((prev) => {
                          return {
                            ...prev,
                            required: e.target.checked,
                          };
                        })
                      }
                    ></FormCheck>
                  </Form.Group>

                  {fieldType === "select" && (
                    <React.Fragment>
                      <Form.Group>
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label className="text-uppercase small text-muted mb-0">
                            <b>Values for select</b>
                          </Form.Label>
                          <Button
                            size="sm"
                            onClick={() =>
                              setSelectValues((prev) => [...prev, ""])
                            }
                            className="text-uppercase"
                          >
                            Add Value
                          </Button>
                        </div>
                        {selectValues.map((item, key) => {
                          return (
                            <div>
                              <div className="d-flex align-items-center justify-content-between mb-1">
                                <Form.Label className="small text-muted mb-0">
                                  Value {key + 1}
                                </Form.Label>
                                <a
                                  title="Remove"
                                  onClick={() => {
                                    setSelectValues((prev) => {
                                      const oldState = [...prev];
                                      const newState = [
                                        ...oldState.slice(0, key),
                                        ...oldState.slice(key + 1),
                                      ];
                                      return newState;
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimes} size="sm" />
                                </a>
                              </div>
                              <Form.Control
                                type="text"
                                name={`values-${key}`}
                                value={selectValues[key]}
                                onChange={(e) => {
                                  setSelectValues((prev) => {
                                    const oldValues = [...prev];
                                    oldValues[key] = e.target.value;
                                    return oldValues;
                                  });
                                }}
                              />
                            </div>
                          );
                        })}
                      </Form.Group>
                    </React.Fragment>
                  )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start">
                  {updateField ? (
                    <Button
                      size="sm"
                      variant="primary"
                      className="text-uppercase mr-2"
                      onClick={() => {
                        setAllCustomFormFields((prev) => {
                          const oldState = [...prev];

                          if (fieldType === "select") {
                            if (selectValues.length > 0) {
                              return [
                                ...oldState.slice(0, updateFieldKey),
                                {
                                  ...customFormFields,
                                  type: fieldType,
                                  id: prev.length + 1,
                                  values: selectValues,
                                },
                                ...oldState.slice(updateFieldKey + 1),
                              ];
                            } else {
                              toast.error("Please add some values", {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                            }
                          } else
                            return [
                              ...oldState.slice(0, updateFieldKey),
                              {
                                ...customFormFields,
                                type: fieldType,
                                id: prev.length + 1,
                              },
                              ...oldState.slice(updateFieldKey + 1),
                            ];
                        });
                        setShowModalAnotherField(false);

                        setCustomFormFields({});
                        setFieldType("");
                        setSelectValues([]);
                        setUpdateField(false);
                        setUpdateFieldKey(null);
                      }}
                    >
                      Save Updates
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="success"
                      className="text-uppercase mr-2"
                      onClick={() => {
                        setAllCustomFormFields((prev) => {
                          if (fieldType === "select") {
                            if (selectValues.length > 0) {
                              return [
                                ...prev,
                                {
                                  ...customFormFields,
                                  type: fieldType,
                                  id: prev.length + 1,
                                  values: selectValues,
                                },
                              ];
                            } else {
                              toast.error("Please add some values", {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                            }
                          } else
                            return [
                              ...prev,
                              {
                                ...customFormFields,
                                type: fieldType,
                                id: prev.length + 1,
                              },
                            ];
                        });
                        setShowModalAnotherField(false);

                        setCustomFormFields({});
                        setFieldType("");
                        setSelectValues([]);
                      }}
                    >
                      Save Changes
                    </Button>
                  )}

                  <Button
                    size="sm"
                    variant="none"
                    className="text-uppercase"
                    onClick={() => {
                      setCustomFormFields({});
                      setFieldType("");
                      setSelectValues([]);
                      setUpdateField(false);
                      setUpdateFieldKey(null);

                      setShowModalAnotherField(false);
                    }}
                  >
                    Discard changes
                  </Button>
                </Modal.Footer>
              </Modal>

              {formLive ? (
                formUpdating && (
                  <Button
                    size="sm"
                    className="text-uppercase"
                    type="submit"
                    disabled={submittingForm}
                  >
                    {submittingForm && (
                      <span className="mr-2">
                        <Spinner animation="border" size="sm" />
                      </span>
                    )}
                    Update Form
                  </Button>
                )
              ) : (
                <Button
                  size="sm"
                  className="text-uppercase"
                  type="submit"
                  disabled={submittingForm}
                >
                  {submittingForm && (
                    <span className="mr-2">
                      <Spinner animation="border" size="sm" />
                    </span>
                  )}
                  Submit Form
                </Button>
              )}
            </Form>
          </Card.Body>
        </Card>

        <Card className="shadow-sm bg-dark shadow-sm mb-3">
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="mb-0">Registrations</h4>
              {fetchingData ? (
                <p>Loading...</p>
              ) : (
                <ExcelFile
                  filename={`Registrations-${Date.now()}`}
                  element={
                    <button className="mr-2 btn btn-outline-light btn-sm">
                      <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                      Registrations
                    </button>
                  }
                >
                  <ExcelSheet
                    dataSet={[{ columns, data }]}
                    name="Registrations"
                  />
                </ExcelFile>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            {fetchingParticipants && (
              <p>
                <Spinner animation="border" size="sm" /> Fetching data...
              </p>
            )}

            <Table className="text-white border" size="sm">
              <thead>
                <tr>
                  {/* <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  {allCustomFormFields?.map((formField) => (
                    <th key={formField.id}>{formField.label}</th>
                  ))} */}
                  {columns?.map((e) => (
                    <th key={e}>{e}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* {participantList?.length > 0 &&
                  participantList?.map((participant, idx) => (
                    <tr key={participant.id}>
                      <td>{idx + 1}</td>
                      <td>{participant["displayName"]}</td>
                      <td>{participant["email"]}</td>

                      {allCustomFormFields?.map((formField) => (
                        <td key={formField.id}>
                          {participant[`${formField.field_name}`]}
                        </td>
                      ))}
                    </tr>
                  ))} */}
                {data?.length > 0 &&
                  data?.map((fields, idx) => (
                    <tr key={idx}>
                      {fields?.map((e, key) => (
                        <td key={key}>{e ?? " - "}</td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
});

export default connect(mapStateToProps)(RegistrationSettings);
