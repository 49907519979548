import React, { useState, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import Courses from "./Components/Course/Courses";
import Course from "./Components/Course/Course";
import CourseAuthentication from "./Components/Course/CourseAuthentication";
import CourseDashboard from "./Components/Course/Dashboard/Dashboard";
import CourseFillDetails from "./Components/Course/CourseFillDetails";
import CoursePeople from "./Components/Course/Dashboard/People";
import CourseInstructors from "./Components/Course/Dashboard/Instructors";
import CourseNotification from "./Components/Course/Dashboard/Notification";
import CourseModule from "./Components/Course/Dashboard/Module";
import CourseModules from "./Components/Course/Dashboard/Modules";
import CourseRooms from "./Components/Course/Dashboard/Rooms";
import CourseClass from "./Components/Course/Dashboard/Class";
import ThanksPage from "./Components/Course/Dashboard/ThanksPage";

import Consultations from "./Components/Consultation/Consultations";
import Consultation from "./Components/Consultation/Consultation";
import ConsultationAuthentication from "./Components/Consultation/ConsultationAuthentication";

import Events from "./Components/Event/Events";
import Event from "./Components/Event/Event";
import FillDetailsForm from "./Components/Event/FillDetailsForm";
import EventAuthentication from "./Components/Event/EventAuthentication";
import EventDashboard from "./Components/Event/Dashboard/Dashboard";
import EventSpeaker from "./Components/Event/Dashboard/Speaker";
import EventAgenda from "./Components/Event/Dashboard/Agenda";
import EventBooth from "./Components/Event/Dashboard/Booth";
import EventModules from "./Components/Event/Dashboard/Modules/Modules";
import Profile from "./Components/Event/Dashboard/Profile";
import ForgotPasswordEvent from "./Components/Event/ForgotPassword";
import EventAssignments from "./Components/Event/Dashboard/Assignment/Assignments";
import EventForms from "./Components/Event/Dashboard/Form/index";
import EventForm from "./Components/Event/Dashboard/Form/IgesiaForm";
import EventAssignment from "./Components/Event/Dashboard/Assignment/Assignment";
import Eventquizzes from "./Components/Event/Dashboard/Quiz/Quizzes";
import EventQuiz from "./Components/Event/Dashboard/Quiz/Quiz";
import EventPolls from "./Components/Event/Dashboard/Poll/Polls";
import EventPoll from "./Components/Event/Dashboard/Poll/Poll";
import EventNotes from "./Components/Apps/EventNotes";

import Project from "./Components/Project/Project";
import Projects from "./Components/Project/Projects";
import ProjectDashboard from "./Components/Project/Dashboard/Dashboard";
import ProjectMember from "./Components/Project/Dashboard/Members";
import ProjectDocuments from "./Components/Project/Dashboard/Documents";
import ProjectApps from "./Components/Project/Dashboard/Applications";
import ProjectAppPage from "./Components/Project/Dashboard/AppPage";
import ProjectAuthentication from "./Components/Project/ProjectAuthentication";

import ProjectManager from "./Components/admin/ProjectManager/ProjectManager";

import Timeline from "./Components/Timeline/Timeline";
import MarketingHome from "./Components/Timeline/Home";
import AdminDashboard from "./Components/admin/Index";
import AdminIntegrations from "./Components/admin/Integrations";
import AdminIntegrationsZoom from "./Components/admin/IntegrationsZoom";
import AdminAnalytics from "./Components/admin/Index2";
import AdminNewAnalytics from "./Components/admin/Index3";
//import PwC from "./Components/admin/PwC";
import EventManager from "./Components/admin/EventManager/EventManager";
import ClinicOfficeManager from "./Components/admin/ClinicManager/ClinicOfficeManager";
import AddEventManager from "./Components/admin/EventManager/NeweventManager";
import ConsultationDashboard from "./Components/Consultation/Dashboard/Dashboard";
import ConsultationTeam from "./Components/Consultation/Dashboard/Team";
import ConsultationService from "./Components/Consultation/Dashboard/Service";
import AddBlogManager from "./Components/admin/BlogManager/NewBlog";
import BlogPage from "./Components/Blogs/Blog";
import Blogs from "./Components/Blogs/Blogs";
import BlogManager from "./Components/admin/BlogManager/BlogManager";
import AddProjectManager from "./Components/admin/ProjectManager/NewProject";

// Static Pages
import CustomerStories from "./Components/admin/Stories";
import GettingStarted from "./Components/admin/GettingStarted";

import TimelineMembers from "./Components/Timeline/Members";

import NoMatch from "./Components/NoMatch";

import "./App.css";
import "./dashboard.css";
import "./index.css";
import "./styles.scss";

import OfficeManager from "./Components/admin/OfficeManager/OfficeManager";
import AddOrganisation from "./Components/admin/AddOrganisation";
import CourseManager from "./Components/admin/CourseManager/CourseManager";

import NotesApp from "./Components/Apps/Notes";
import WhiteBoard from "./Components/Apps/WhiteBoard";
import Folder from "./Components/Apps/Folder";
import ViewFolder from "./Components/Apps/Folder/index";
import SelectConsultant from "./Components/Consultation/SelectConsultant";

import Calendar from "./Components/Consultation/Dashboard/Calendar";
import BookAppointment from "./Components/Consultation/BookAppointment";
import NewOffice from "./Components/admin/OfficeManager/NewOffice";
import NewClinic from "./Components/admin/ClinicManager/NewClinic";
import NewCourse from "./Components/admin/CourseManager/NewCourse";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import CustomPage from "./Components/Event/Dashboard/CustomPage";
import EventCustomForm from "./Components/Event/EventCustomForm";
import PeopleNetwork from "./Components/Event/Dashboard/Networking";
import ViewSession from "./Components/Event/Dashboard/ViewSession";
import ViewLiveSession from "./Components/Event/Dashboard/Lecture";

/*BBOS APPS */
import BBOSApps from "./Components/Event/Dashboard/Apps";
import ProjectList from "./Components/Event/Dashboard/apps/ProjectList";
import AsIs from "./Components/Event/Dashboard/apps/As-is";
import AsIsList from "./Components/Event/Dashboard/apps/AsIsList";
import BuyerExpList from "./Components/Event/Dashboard/apps/BuyerExpList";
import ThreeTierList from "./Components/Event/Dashboard/apps/ThreeTierList";
import SixPathList from "./Components/Event/Dashboard/apps/SixPathList";
import ErrcList from "./Components/Event/Dashboard/apps/ErrcList";
import ToBeList from "./Components/Event/Dashboard/apps/ToBeList";

// BBOA APPS
import ProjectListBBOA from "./Components/Event/Dashboard/BBOA/ProjectList";
import BboaProject from "./Components/Event/Dashboard/BBOA/Project";

import { setCurrentEvent, setMyEvents } from "./store/actions/actions";
import CategoryPage from "./Components/Event/Dashboard/CategoryPage";

import Pricing from "./Components/Timeline/Pricing";
import YourPlan from "./Components/Timeline/YourPlan";
import PaymentSuccess from "./Components/Timeline/PaymentSuccess";
import PaymentFailed from "./Components/Timeline/PaymentFailed";

import BoothDescription from "./Components/Event/Dashboard/BoothDescription";
import ManageBooth from "./Components/Event/Dashboard/ManageBooth";
import PreviewBooth from "./Components/Event/Dashboard/PreviewBooth";

import Rooms from "./Components/Rooms/Rooms";

import AdminLogin from "./Components/admin/AdminLogin";
import AdminRegister from "./Components/admin/AdminRegister";
import AdminProfile from "./Components/admin/Profile";
import { Spinner } from "react-bootstrap";
import Terms from "./Components/Timeline/Terms";
import Privacy from "./Components/Timeline/Privacy";
import { setAccessCountryCode } from "./services";
import PublicProfile from "./Components/Profile";
import SubModulePage from "./Components/Event/Dashboard/Modules";
import ExportBBOAMatrix from "./Components/ExportBBOAMatrix";
import DigitalDownloadsCreate from "./Components/admin/DigitalDownloads/Create";
import DigitalDownloadsAdmin from "./Components/admin/DigitalDownloads";
import DigitalDownloadsUser from "./Components/DigitalDownloads";

function App(props) {
  const { currentEvent, setCurrentEvent, setMyEvents } = props;

  const user = firebase.auth().currentUser;
  const [eventSnapshot, loadingEvent, errorEvent] = useCollection(
    firebase
      .firestore()
      .collection(`${window?.location?.pathname?.split("/")[1]}s/`)
      .where("isDemo", "==", true)
      .where("slug", "==", `${window?.location?.pathname?.split("/")[2]}`)
  );

  const [eventsSnapshot, loadingEvents, errorEvents] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("created_by", "==", user?.uid ?? "")
  );
  const [eventsSnapshot2, loadingEvents2, errorEvents2] = useCollection(
    firebase
      .firestore()
      .collection("events")
      .where("event_manager", "array-contains", user?.email ?? "")
  );

  const events = useMemo(() => {
    if (user) {
      let eventArray1 = eventsSnapshot?.docs?.map((e) => {
        return { id: e.id, ...e.data() };
      });
      let eventArray2 = eventsSnapshot2?.docs?.map((e) => {
        return { id: e.id, ...e.data() };
      });
      if (eventArray1 && eventArray2) {
        return [...eventArray1, ...eventArray2];
      }
    }
  }, [eventsSnapshot, eventsSnapshot2]);

  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  function PrivateRouteEvent({ component: Component, authenticated, ...rest }) {
    const currentEventData = useMemo(() => {
      return {
        ...eventSnapshot?.docs[0]?.data(),
        id: eventSnapshot?.docs[0]?.id,
      };
    }, [eventSnapshot]);

    !loadingEvent && currentEvent == null && setCurrentEvent(currentEventData);

    if (loadingEvent) return <Spinner animation="border" size="sm" />;
    else
      return (
        <Route
          {...rest}
          render={(props) =>
            authenticated === true ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: `./`, // `/${window?.location?.pathname?.split("/")[1]}/${window?.location?.pathname?.split("/")[2]}`,
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      );
  }

  function PrivateRouteAdmin({ component: Component, authenticated, ...rest }) {
    if (events?.length > 0) {
      setMyEvents(events);
    }

    // if(authenticated && ) {}
    // if(authenticated === true){
    //   return  <Component {...props} />
    // }
    return (
      <Route
        {...rest}
        render={(props) =>
          authenticated === true ? (
            <>
              {/*
               {(events?.created_by === user?.uid) || (events?.event_manager?.includes(user?.email)) ? */}
              <Component {...props} />
              {/* //     :
            //     <Redirect
            //       to={{
            //         pathname: `/admin`,
            //         state: { from: props.location },
            //       }}
            //     />
            //   }*/}
            </>
          ) : (
            <Redirect
              to={{
                pathname: `/admin/login`, // `/${window?.location?.pathname?.split("/")[1]}/${window?.location?.pathname?.split("/")[2]}`,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  function PrivateRouteAdminEvent({
    component: Component,
    authenticated,
    ...rest
  }) {
    const [eventDocAdmin, loadingEventAdmin, errorEventAdmin] = useDocument(
      firebase
        .firestore()
        .collection(`events`)
        .doc(`${window?.location?.pathname?.split("/")[3]}`)
    );

    !loadingEventAdmin &&
      currentEvent?.id != window?.location?.pathname?.split("/")[3] &&
      setCurrentEvent({
        ...eventDocAdmin?.data(),
        id: window?.location?.pathname?.split("/")[3],
      });

    const access_granted = user
      ? eventDocAdmin?.data()?.created_by === user.uid ||
        eventDocAdmin?.data()?.event_manager?.includes(user.email)
      : false;

    if (loadingEventAdmin) return <Spinner animation="border" size="sm" />;
    else
      return (
        // <Route {...rest} render={(props) => <Component {...props} />} />
        <Route
          {...rest}
          render={(props) =>
            authenticated === true && access_granted ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: `/admin`,
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      );
  }

  useEffect(() => {
    setAccessCountryCode();

    firebase.auth().onAuthStateChanged((usr) => {
      if (usr) {
        setAuthenticated(true);
        console.log("Setting authenticated");
        // console.log("not user")
        setIsLoading(false);
        // firebase
        //   .firestore()
        //   .collection(`${window?.location?.pathname?.split("/")[1]}s/${eventSnapshot?.docs[0]?.id}/users`)
        //   .doc(`${user?.id}`)
        //   .get()
        //   .then((doc) => {
        //     if (doc.exists) {
        //       console.log(doc.data(), "Document found")
        //       setAuthenticated(true)
        //       // if(!doc.data().is_details_filled){
        //       //   window.location.href = `/${window?.location?.pathname?.split("/")[1]}/${window?.location?.pathname?.split("/")[2]}/fill-details`
        //       // }

        //       setIsLoading(false);
        //     }
        //   })
      } else {
        setAuthenticated(false);
        console.log("Setting authenticated5");
        // console.log("not user")
        setIsLoading(false);
      }
    }); //eventSnapshot, loadingEvent,
  }, [setAuthenticated]);

  return (
    <>
      {!isLoading ? (
        <Router>
          <Switch>
            <Route exact path="/" component={MarketingHome} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/timeline" component={Timeline} />
            <Route exact path="/admin/login" component={AdminLogin} />
            <Route exact path="/admin/register" component={AdminRegister} />
            <PrivateRouteAdmin
              exact
              path="/admin"
              component={AdminDashboard}
              authenticated={authenticated}
            />
            <Route
              exact
              path="/public-profile/:user_id"
              component={PublicProfile}
            />
            {/* <PrivateRouteAdmin
              exact
              path="/pwcadmin"
              component={PwC}
              authenticated={authenticated}
            /> */}
            <PrivateRouteAdmin
              exact
              path="/admin/workspaceanalytics"
              component={AdminAnalytics}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/integrations"
              component={AdminIntegrations}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/integrations/zoom"
              component={AdminIntegrationsZoom}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/analytics"
              component={AdminNewAnalytics}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/profile"
              component={AdminProfile}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/customerstories"
              component={CustomerStories}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/gettingstarted"
              component={GettingStarted}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/add/office"
              component={NewOffice}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/add/clinic"
              component={NewClinic}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/add/course"
              component={NewCourse}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/course/add"
              component={() => <AddEventManager isCourse={true} />}
              authenticated={authenticated}
            />

            {/* [Admin] Create: Digital downloads */}
            <PrivateRouteAdmin
              exact
              path="/admin/downloads/add"
              component={DigitalDownloadsCreate}
              authenticated={authenticated}
            />
            {/* [Admin] Manage: Digital download (single) */}
            <PrivateRouteAdmin
              exact
              path="/admin/downloads/:download_id"
              component={DigitalDownloadsAdmin}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/downloads/:download_id/:page"
              component={DigitalDownloadsAdmin}
              authenticated={authenticated}
            />
            {/* [User] Landing page: Digital download (single) */}
            <PrivateRouteAdmin
              exact
              path="/downloads/:download_id"
              component={DigitalDownloadsUser}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/add/blog"
              component={AddBlogManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/add/project"
              component={AddProjectManager}
              authenticated={authenticated}
            />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/my-plan" component={YourPlan} />
            <Route
              exact
              path="/payment-successful"
              component={PaymentSuccess}
            />
            <Route exact path="/payment-failed" component={PaymentFailed} />

            {/* Event manager routes */}
            <PrivateRouteAdminEvent
              exact
              path="/admin/eventmanager/:eventid"
              component={EventManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdminEvent
              exact
              path="/admin/eventmanager/:eventid/:page"
              component={EventManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdminEvent
              exact
              path="/admin/event/:eventid"
              component={EventManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdminEvent
              exact
              path="/admin/event"
              component={EventManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/add/event"
              component={() => <AddEventManager isCourse={false} />}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/event/add"
              component={() => <AddEventManager isCourse={false} />}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/blogmanager/:id?"
              component={BlogManager}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/clinicmanager"
              component={ClinicOfficeManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/clinicmanager/:clinicid"
              component={ClinicOfficeManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/officemanager/:officeid"
              component={OfficeManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/officemanager"
              component={OfficeManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/coursemanager/:courseid"
              component={CourseManager}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/coursemanager"
              component={CourseManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/projectmanager/:projectid"
              component={ProjectManager}
              authenticated={authenticated}
            />
            <PrivateRouteAdmin
              exact
              path="/admin/projectmanager"
              component={ProjectManager}
              authenticated={authenticated}
            />

            <PrivateRouteAdmin
              exact
              path="/admin/addorganisation"
              component={AddOrganisation}
              authenticated={authenticated}
            />

            <Route exact path="/admin/all/:type" component={AdminDashboard} />
            <Route exact path="/admin/:type/:slug" component={AdminDashboard} />

            <Route exact path="/consultations" component={Consultations} />
            <Route exact path="/consultation/:id" component={Consultation} />
            <Route
              exact
              path="/consultation/:id/login"
              component={ConsultationAuthentication}
            />
            <Route
              exact
              path="/consultation/:id/register"
              component={ConsultationAuthentication}
            />

            <Route exact path="/course" component={Courses} />
            <Route exact path="/courses" component={Courses} />
            <Route exact path="/course/:id" component={Course} />
            <Route
              exact
              path="/course/:id/dashboard"
              component={CourseDashboard}
            />
            <Route
              exact
              path="/course/:id/fill-details"
              component={CourseFillDetails}
            />
            <Route
              exact
              path="/course/:id/login"
              component={CourseAuthentication}
            />
            <Route
              exact
              path="/course/:id/notifications"
              component={CourseNotification}
            />
            <Route exact path="/course/:id/people" component={CoursePeople} />
            <Route
              exact
              path="/course/:id/participants"
              component={CoursePeople}
            />
            <Route
              exact
              path="/course/:id/instructors"
              component={CourseInstructors}
            />
            <Route exact path="/course/:id/module" component={CourseModules} />
            <Route
              exact
              path="/course/:id/module/:module_id/:sub_module_id"
              component={CourseModule}
            />
            <Route
              exact
              path="/course/:id/register"
              component={CourseAuthentication}
            />
            <Route exact path="/course/:id/rooms" component={CourseRooms} />
            <Route exact path="/course/:id/rooms" component={CourseRooms} />
            <Route exact path="/course/:id/class/end" component={ThanksPage} />
            <Route
              exact
              path="/course/:id/class/:class_type"
              component={CourseClass}
            />

            <Route exact path="/event" component={Events} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/event/:id" component={Event} />
            <Route
              exact
              path="/event/:id/register"
              component={EventAuthentication}
            />
            <Route
              exact
              path="/event/:id/register/:email_address"
              component={EventAuthentication}
            />
            <Route
              exact
              path="/event/:id/forgot-password"
              component={ForgotPasswordEvent}
            />
            <Route
              exact
              path="/event/:id/complete-registration"
              component={FillDetailsForm}
            />
            <Route
              exact
              path="/event/:id/login"
              component={EventAuthentication}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/agenda/:session_id/live"
              component={ViewLiveSession}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/agenda/:session_id"
              component={ViewSession}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/networking"
              component={PeopleNetwork}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/notes"
              component={EventNotes}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/fill-details"
              component={EventCustomForm}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/profile"
              component={Profile}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/dashboard"
              component={
                currentEvent && currentEvent?.type === 1
                  ? EventModules
                  : EventDashboard
              }
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/modules"
              component={EventModules}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/module/:module_id/:sub_module_type/:sub_module_id"
              component={SubModulePage}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/forms"
              component={EventForms}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/forms/:form_id"
              component={EventForm}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/assignments"
              component={EventAssignments}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/assignment/:assignment_id"
              component={EventAssignment}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/quizzes"
              component={Eventquizzes}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/quiz/:quiz_id"
              component={EventQuiz}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/polls"
              component={EventPolls}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/poll/:poll_id"
              component={EventPoll}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/speakers"
              component={EventSpeaker}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/agenda"
              component={EventAgenda}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/booths"
              component={EventBooth}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/booth/:booth_id"
              component={BoothDescription}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/managebooth"
              component={ManageBooth}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/previewbooth/:boothslug"
              component={PreviewBooth}
              authenticated={authenticated}
            />

            {/* <PrivateRouteEvent
              exact
              path="/event/:id/stage"
              component={EventStage}
              authenticated={authenticated}
            /> */}
            <PrivateRouteEvent
              exact
              path="/event/:id/page/:page_slug"
              component={CustomPage}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/group/:category_id"
              component={CategoryPage}
              authenticated={authenticated}
            />

            {/*-------------------BBOS Apps Routes----------------*/}
            <PrivateRouteEvent
              exact
              path="/event/:id/apps"
              component={BBOSApps}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/projects"
              component={ProjectList}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/as_is/view/:appId?"
              component={AsIs}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/as-is"
              component={AsIsList}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/buyer-exp-audit/"
              component={BuyerExpList}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/three-tiers/"
              component={ThreeTierList}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/six-path/"
              component={SixPathList}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/errc-grid/"
              component={ErrcList}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/to-be/"
              component={ToBeList}
              authenticated={authenticated}
            />

            {/*-------------------BBOA Apps Routes----------------*/}
            <Route
              exact
              path="/export/bboa/:product_id/:project_id"
              component={ExportBBOAMatrix}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/bboa"
              component={ProjectListBBOA}
              authenticated={authenticated}
            />
            <PrivateRouteEvent
              exact
              path="/event/:id/bboa/project/:appId?"
              component={BboaProject}
              authenticated={authenticated}
            />

            <Route exact path="/project/:id/apps" component={ProjectApps} />
            <Route exact path="/project/:id/apps/folder" component={Folder} />
            <Route
              exact
              path="/project/:id/apps/folder/:folderId"
              component={ViewFolder}
            />
            <Route
              exact
              path="/project/:id/apps/:app_type"
              component={ProjectAppPage}
            />

            <Route
              exact
              path="/project/:id/dashboard"
              component={ProjectDashboard}
            />
            <Route
              exact
              path="/project/:id/login"
              component={ProjectAuthentication}
            />
            <Route exact path="/project/:id/notes" component={NotesApp} />
            <Route
              exact
              path="/project/:id/whiteboard"
              component={WhiteBoard}
            />
            <Route exact path="/project/:id/folder" component={Folder} />
            <Route
              exact
              path="/project/:id/members"
              component={ProjectMember}
            />
            <Route
              exact
              path="/project/:id/document"
              component={ProjectDocuments}
            />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/project/:id" component={Project} />
            <Route
              exact
              path="/project/:id/register"
              component={ProjectAuthentication}
            />

            <Route
              exact
              path="/consultation/:id/dashboard"
              component={ConsultationDashboard}
            />
            <Route
              exact
              path="/consultation/:id/provider"
              component={ConsultationTeam}
            />
            <Route
              exact
              path="/consultation/:id/services"
              component={ConsultationService}
            />
            <Route
              exact
              path="/consultation/:id/calendar"
              component={Calendar}
            />

            <Route
              exact
              path="/consultation/:id/doctors"
              component={SelectConsultant}
            />
            <Route
              exact
              path="/consultation/:id/bookappointment"
              component={BookAppointment}
            />

            <Route exact path="/blog/:id" component={BlogPage} />
            <Route exact path="/blogs" component={Blogs} />

            <Route exact path="/members" component={TimelineMembers} />

            <Route exact path="/rooms" component={Rooms} />

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      ) : null}
    </>
  );
}

//export default App;

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentEvent: state.currentEvent,
  }),
  { setCurrentEvent, setMyEvents }
)(App);
