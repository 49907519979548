import React, { useState, useEffect, useRef } from "react";
import { Card, Image, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import {
  faTimes,
  faEye,
  faGraduationCap,
  faUser,
  faPodcast,
  faSearch,
  faUserPlus,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import TimelineCover from "./TimelineCover";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default function Blogs() {
  const history = useHistory();
  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const [peoples, setPeoples] = useState([
    {
      id: 1,
      name: "Lim Yong Kang",
      email: "lim@gmail.com",
      country: "United States",
      img: "https://file.hstatic.net/200000067792/article/1._andrew_lim_5a0b893416ae475891523ac60a5a91f9.png",
      specialties:
        "Innovation, Artificial Intelligence, Services Operations Management, Technology Strategy",
      certification: 14,
      followers: 1251,
      podcasts: 43,
      qualifications: "Phd, MS, Btech",
      following: false,
    },
    {
      id: 2,
      name: "S Hayat",
      email: "shayat@gmail.com",
      country: "United States",
      img: "https://i.pinimg.com/originals/31/54/20/3154204284fb98ef1a893fb02e89dc9c.png",
      specialties:
        "Innovation, Artificial Intelligence, Services Operations Management, Technology Strategy",
      certification: 10,
      followers: 1051,
      podcasts: 23,
      qualifications: "Phd, MS, Btech",
      following: true,
    },
    {
      id: 3,
      name: "Siddhesh Pawar",
      email: "siddesh@gmail.com",
      country: "United States",
      img: "https://t3.ftcdn.net/jpg/02/95/21/58/360_F_295215888_nNSFXTYYPi1UNvKALPD90T5oDS631ife.jpg",
      specialties:
        "Innovation, Artificial Intelligence, Services Operations Management, Technology Strategy",
      certification: 11,
      followers: 1951,
      podcasts: 21,
      qualifications: "Phd, MS, Btech",
      following: false,
    },
    {
      id: 4,
      name: "Richard Moon",
      email: "richard@gmail.com",
      country: "United States",
      img: "https://img2.pngio.com/man-professional-haircut-picturepng-professional-men-png-321_290.jpg",
      specialties:
        "Innovation, Artificial Intelligence, Services Operations Management, Technology Strategy",
      certification: 16,
      followers: 1361,
      podcasts: 15,
      qualifications: "Phd, MS, Btech",
      following: false,
    },
    {
      id: 5,
      name: "Sathvik Saya",
      email: "sathvik@gmail.com",
      country: "United States",
      img: "https://t4.ftcdn.net/jpg/01/56/19/15/360_F_156191504_F8KusEJnAdRbyztflKKtQnnU43GIyWv4.jpg",
      specialties:
        "Innovation, Artificial Intelligence, Services Operations Management, Technology Strategy",
      certification: 11,
      followers: 963,
      podcasts: 9,
      qualifications: "Phd, MS, Btech",
      following: false,
    },
  ]);

  const handleFollow = (id) => {
    const newList = peoples.map((item) => {
      if (item.id == id) {
        if (item.following) {
          toast.dark(`Unfollowed ${item.name}`, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            autoClose: 3000,
          });
        } else {
          toast.info(`Following ${item.name}`, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            autoClose: 3000,
          });
        }
        return {
          ...item,
          following: !item.following,
        };
      } else {
        return item;
      }
    });

    setPeoples(newList);
  };

  const MemberListElement = ({ item }) => {
    return (
      <Card className="bg-dark text-white shadow-sm mb-2">
        <Card.Body>
          <div className="d-flex align-items-center">
            <div
              onClick={() => {
                setSelectedProfile(item);
                setShowProfile(true);
              }}
              className="img-fluid rounded-circle shadow-sm border"
              style={{
                height: 50,
                width: 50,
                backgroundImage: `url(${item.img})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
              title={item.name}
            />

            <div
              onClick={() => {
                setSelectedProfile(item);
                setShowProfile(true);
              }}
              className="ml-2 flex-grow-1"
              style={{ cursor: "pointer" }}
            >
              <p className="mb-0">{item.name}</p>
              <span className="small">{item.email}</span>
            </div>

            <div className="ml-2">
              {item.following ? (
                <Button
                  variant="outline-success"
                  size="sm"
                  className="rounded-pill"
                  title="Already following"
                  onClick={() => handleFollow(item.id)}
                >
                  <span>
                    <FontAwesomeIcon icon={faUserCheck} /> Following
                  </span>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="sm"
                  className="rounded-pill"
                  title="Follow now"
                  onClick={() => handleFollow(item.id)}
                >
                  <span>
                    <FontAwesomeIcon icon={faUserPlus} /> Follow
                  </span>
                </Button>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="container-fluid px-0 bg-graphite full-screen" ref={pageRef}>
      {/* Timeline cover starts */}
      <TimelineCover />
      {/* Timeline cover ends */}

      <div className="container text-white py-3 py-md-4">
        <h2 className="font-weight-bold">Members</h2>
        <p className="text-white font-weight-bold mb-0">
          {peoples.length > 0
            ? peoples.length > 1
              ? `${peoples.length} members`
              : `${peoples.length} member`
            : `No member found`}
        </p>

        <InputGroup className="my-2 outerPill">
          <div className="d-flex align-items-center rounded-lg flex-grow-1  bg-dark">
            <div className="py-2 pr-2 pl-3">
              <FontAwesomeIcon icon={faSearch} size="sm" color="#aeaeae" />
            </div>
            <div className="flex-grow-1">
              <Form.Control
                type="text"
                id="participantsSearch"
                className="border-0 px-0 bg-dark text-white rounded"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  const reg = _.escapeRegExp(searchTerm);
                  // console.log(reg,"Reg")
                  const regex = new RegExp(reg, "gi");
                  setRegex(regex);
                }}
                placeholder={"Search"}
                value={searchTerm}
                style={{ margin: 0 }}
              />
            </div>
            {searchTerm.length > 0 && (
              <div className="py-2 pl-2 pr-3" onClick={() => setSearchTerm("")}>
                <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
              </div>
            )}
          </div>
        </InputGroup>

        {searchTerm && searchTerm.length > 0 ? (
          <>
            {peoples
              .filter((item) => {
                return item.name?.match(regex);
              })
              .map((item) => {
                return <MemberListElement item={item} />;
              })}
          </>
        ) : (
          <>
            {peoples.map((item) => {
              return <MemberListElement item={item} />;
            })}
          </>
        )}

        <Modal
          centered={true}
          show={showProfile}
          onHide={() => setShowProfile(false)}
          animation={true}
        >
          <Modal.Body className="bg-graphite text-white">
            <div
              title="Close"
              className="pointer d-flex justify-content-end mb-0"
              onClick={() => setShowProfile(false)}
            >
              <FontAwesomeIcon className="text-white" icon={faTimes} />
            </div>
            <div className="d-flex flex-column align-items-center text-center">
              <div
                className="img-fluid rounded-circle shadow-sm"
                style={{
                  height: 120,
                  width: 120,
                  backgroundImage: `url(${selectedProfile?.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                title={selectedProfile?.name}
              />
              <p className="font-weight-bold lead">{selectedProfile?.name}</p>
              <div className="w-100 d-flex align-items-center justify-content-around">
                <p className="border-right border-dark w-100 text-lowercase">{`@${
                  selectedProfile?.name?.split(" ")[0]
                }`}</p>
                <p className="w-100">{`${selectedProfile?.email}`}</p>
              </div>
              <p>{selectedProfile?.country}</p>
              <div className="row w-100">
                <div className="col-sm">
                  <FontAwesomeIcon icon={faGraduationCap} />
                  <p>{selectedProfile?.certification}</p>
                </div>
                <div className="col-sm">
                  <FontAwesomeIcon icon={faUser} />
                  <p>{selectedProfile?.followers}</p>
                </div>
                <div className="col-sm">
                  <FontAwesomeIcon icon={faPodcast} />
                  <p>{selectedProfile?.podcasts}</p>
                </div>
              </div>
              <hr />
              <div>
                <p className="lead font-weight-bold">Specialities</p>
                <p>{selectedProfile?.specialties.replaceAll(",", " •")}</p>
              </div>
              <hr />
              <div>
                <p className="lead font-weight-bold">Qualifications</p>
                <p>{selectedProfile?.qualifications.replaceAll(",", " •")}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
