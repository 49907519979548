import moment from "moment";
import * as htmlToImage from "html-to-image";

export const image_type = [
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/gif",
];
export const pdf_type = ["application/pdf"];
export const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const timeFromNow = (timestamp) => {
  if (moment(Date.now()).format("l") === moment(timestamp).format("l"))
    return `Today ${moment(timestamp).format("h:mm A")}`;
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
    return `Yesterday ${moment(timestamp).format("h:mm A")}`;
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return moment(timestamp).format("dddd");
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
    return moment(timestamp).format("DD MMM");
  else return moment(timestamp).format("MMM YY");
};

export const sendEmail = async (data = {}) => {
  // Default options are marked with *
  const response = await fetch("https://api.igesia.com/core/email", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "ACCESS-KEY": "582780d3-d591-4c81-9779-c5dff7715f41",
      "SECRET-KEY": "86B2D2BDF36FE38F28B8C7CC3DAC5",
      // 'Content:-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
};
export async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const removeTags = (str, placeholder = "") => {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, placeholder);
};

export const handleExportJpeg = (id, title) => {
  htmlToImage
    .toJpeg(document.getElementById(`${id}`), { quality: 0.95 })
    .then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = `${title}.jpeg`;
      link.href = dataUrl;
      link.click();
    });
};

export const handleExportPng = (id, title) => {
  htmlToImage
    .toPng(document.getElementById(`${id}`), { quality: 0.95 })
    .then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = `${title}.png`;
      link.href = dataUrl;
      link.click();
    });
};
