import React from "react";
import { Badge, Button, Card, Modal } from "react-bootstrap";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import { connect } from "react-redux";
import { useHistory } from "react-router";

function LiveSession(props) {
  // CONSTANTS
  const { darkTheme, id, resource_details, sub_module_details } = props;
  const history = useHistory();

  // STATES

  // FUNCTIONS

  return (
    <div>
      {/* {Object.entries(resource_details)?.map((e) => (
        <p>{JSON.stringify(e)}</p>
      ))} */}
      {/* Section: Session details */}
      <React.Fragment>
        {resource_details?.starts_at && (
          <Card
            // className="border-muted w-100 mb-3"
            className={
              darkTheme
                ? "bg-dark shadow-sm border border-dark w-100 mb-3"
                : "bg-white shadow-sm border border-light w-100 mb-3"
            }
          >
            <Card.Header
              style={{
                backgroundColor: "rgba(231, 244, 255, 1)",
              }}
              className={
                darkTheme
                  ? "mb-0 font-weight-bold text-dark"
                  : "mb-0 font-weight-bold"
              }
            >
              <h6 className="mb-0 font-weight-bold d-flex align-items-start">
                <small>
                  {resource_details?.is_ended ? (
                    <Badge variant="secondary">Ended</Badge>
                  ) : moment(resource_details?.starts_at).isSame(
                      Date.now(),
                      "day"
                    ) ? (
                    <Badge variant="danger">Live</Badge>
                  ) : moment(resource_details?.starts_at).isAfter(
                      Date.now(),
                      "day"
                    ) ? (
                    <Badge variant="primary">Starting soon</Badge>
                  ) : (
                    <Badge variant="secondary">Ended</Badge>
                  )}
                </small>
              </h6>
            </Card.Header>
            <Card.Body>
              <h4 className="mb-1">
                <b>{resource_details?.EN?.title}</b>
              </h4>
              <p className="text-secondary font-weight-bold">
                {/* {moment(resource_details?.starts_at).format("Do MMM")},{" "} */}
                {moment(resource_details?.starts_at).format("MMMM Do HH:mm")} -{" "}
                {moment(resource_details?.ends_at).format("HH:mm")}
              </p>

              {resource_details?.EN?.description && (
                <div>
                  <p className="mb-0">
                    <b>Description</b>
                  </p>
                  {HTMLReactParser(resource_details?.EN?.description)}
                </div>
              )}

              {resource_details?.is_ended ? (
                // Session: Ended
                resource_details?.recording_link ? (
                  // Recording: Available
                  <div>
                    <a
                      size="sm"
                      className="btn btn-primary rounded-sm px-3 px-md-5"
                      href={`/event/${id}/agenda/${resource_details?.id}/live`}
                      target="_blank"
                    >
                      <b>Watch Session Recording</b>
                    </a>
                  </div>
                ) : (
                  // Recording: Not Available
                  <div>
                    <p className="mb-1 text-danger">
                      <b>Session Ended</b>
                    </p>
                    <Button
                      size="sm"
                      className="rounded-sm px-3 px-md-5"
                      variant="secondary"
                      disabled={true}
                    >
                      <b>Watch Session Recording</b>
                    </Button>
                    <p className="text-dark small my-2">
                      <b>Note: </b>Recording of the session will be uploaded
                      soon
                    </p>
                  </div>
                )
              ) : moment(resource_details?.starts_at).isSame(
                  Date.now(),
                  "day"
                ) ? (
                // Session: Today
                <div>
                  <Button
                    size="sm"
                    className="rounded-sm px-3 px-md-5"
                    href={
                      resource_details?.meeting_type === 1
                        ? false //currentUserRole >= 1
                          ? `${resource_details?.start_url}`
                          : resource_details?.live_streaming &&
                            resource_details?.streaming_link
                          ? `/event/${id}/agenda/${sub_module_details?.content_id}/live`
                          : `${resource_details?.meeting_url}`
                        : `/event/${id}/agenda/${sub_module_details?.content_id}/live`
                    }
                    target={
                      true //resource_details?.meeting_type === 1 && !resource_details?.live_streaming
                        ? "_blank"
                        : ""
                    }
                  >
                    {resource_details?.meeting_type === 1 ? (
                      <b>Join Session</b>
                    ) : (
                      <b>Join Live Session</b>
                    )}
                  </Button>
                  {resource_details?.meeting_type === 1 &&
                    !resource_details?.live_streaming && (
                      <p className="mt-2 mb-0">
                        or, you can click on{" "}
                        <a
                          href={
                            false //currentUserRole >= 1
                              ? resource_details?.start_url
                              : resource_details?.meeting_url
                          }
                          target="_blank"
                        >
                          {false //currentUserRole >= 1
                            ? resource_details?.start_url
                            : resource_details?.meeting_url}
                        </a>
                      </p>
                    )}
                </div>
              ) : moment(resource_details?.starts_at).isAfter(
                  Date.now(),
                  "day"
                ) ? (
                // Session: Day after Today
                <div>
                  <p className="mb-1 text-primary">
                    <b>Starting soon</b>
                  </p>
                  <Button
                    size="sm"
                    className="rounded-sm px-3 px-md-5"
                    variant="secondary"
                    title="Starting soon"
                    disabled={true}
                    href={`/event/${id}/agenda/${resource_details?.id}`}
                    target="_blank"
                  >
                    <b>Join Live Session</b>
                  </Button>
                </div>
              ) : null}
            </Card.Body>
          </Card>
        )}
      </React.Fragment>

      {/* Section: Speakers details */}
      <React.Fragment>
        {resource_details?.speakers && resource_details?.speakers?.length > 0 && (
          <Card
            // className="border-muted w-100 mb-3"
            className={
              darkTheme
                ? "bg-dark shadow-sm border border-dark w-100 mb-3"
                : "bg-white shadow-sm border border-light w-100 mb-3"
            }
          >
            <Card.Header
              style={{
                backgroundColor: "rgba(231, 244, 255, 1)",
              }}
            >
              <h6
                className={
                  darkTheme
                    ? "mb-0 font-weight-bold text-dark"
                    : "mb-0 font-weight-bold"
                }
              >
                Speakers
              </h6>
            </Card.Header>
            {/* <Card.Body className="row">
              {resource_details?.speakers?.map((speaker, key) => {
                const facultyData = allSpeakers?.filter(
                  (item) => item.userId === speaker
                )[0];

                return (
                  <div
                    key={key}
                    className="col-md-6 d-flex align-items-start p-2 mb-2"
                    title={facultyData?.first_name}
                    style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //   handleShow();
                    //   setSelectedFaculty(facultyData);
                    // }}
                  >
                    <img
                      className="rounded-circle"
                      height="90"
                      width="90"
                      src={facultyData?.img_src}
                      title={facultyData?.first_name}
                      alt=""
                    />
                    <div className="flex-grow-1 ml-2">
                      <h6>
                        {facultyData?.first_name} {facultyData?.last_name}
                      </h6>
                      <p className="small">
                        {facultyData?.bio
                          ? HTMLReactParser(facultyData?.bio)
                          : ""}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Card.Body> */}
          </Card>
        )}
        {/* <Modal
          size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          // centered
          show={show}
          onHide={() => {
            handleClose();
            setSelectedFaculty(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {selectedFaculty?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ whiteSpace: "pre-line" }}>
            <div className="d-flex flex-column justify-content-between align-items-center">
              <img
                src={selectedFaculty?.img_src}
                width="150"
                height="150"
                className="mr-4 rounded-circle"
                alt=""
              ></img>
              {selectedFaculty?.description.split("\n").map((para, i) => {
                return <p key={i}>{para}</p>;
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                handleClose();
                setSelectedFaculty(null);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
      </React.Fragment>
    </div>
  );
}

const mapStateToProps = (state) => ({
  darkTheme: state.darkTheme,
});

export default connect(mapStateToProps)(LiveSession);
