import React, { useState, useEffect, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Button, Dropdown, Card, Modal, Image } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import firebase from "firebase";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  faPlusCircle,
  faMinusCircle,
  faEdit,
  faTimes,
  faCheckSquare,
  faGripVertical,
  faTrash,
  faUndo,
  faEnvelope,
  faLink,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  API_BASE_URL,
  BBOA_DELETE_FACTOR,
  DRIVE_UPLOAD,
} from "../../../../config/index";
import { auth } from "../../../../firebase";
import { sendEmail } from "../../../../utils";

toast.configure();

function Quadrants({
  quadName,
  type,
  setNewValue,
  typeArray,
  setNewFactorValue,
  newFactorValue,
  newValue,
  addItems,
  showAddField,
  setShowAddField,
  editField,
  editItems,
  editValue,
  setEditValue,
  setEditField,
  onRevertBackToDragDrop,
  deleteFactorHandler,
}) {
  return (
    <div
      key={type}
      className={
        type == "main"
          ? "col-md-12 border border-secondary p-0 m-0"
          : "col-md-6 border border-secondary p-0 m-0"
      }
    >
      <div
        className={
          type == "main"
            ? "bg-secondary p-2 text-white "
            : "bg-primary p-2 text-white "
        }
      >
        <div className="d-flex bd-highlight">
          <div
            className="p-2 w-100 bd-highlight text-center"
            style={{ textAlign: "center" }}
          >
            {quadName?.toUpperCase()}
          </div>
          <span className="p-2 flex-shrink-1 bd-highlight">
            <FontAwesomeIcon
              icon={
                showAddField.key == type && showAddField.show
                  ? faMinusCircle
                  : faPlusCircle
              }
              className=""
              onClick={(e) => {
                if (showAddField.key !== type && !showAddField.show) {
                  setShowAddField({
                    key: type,
                    show: true,
                  });
                } else {
                  setNewValue("");
                  setShowAddField({
                    key: "",
                    show: false,
                  });
                }
              }}
            />
          </span>
        </div>
      </div>

      <Droppable droppableId={type}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={"droppable-col"}
            >
              <div className="px-3 pt-3">
                {showAddField.key == type && showAddField.show && (
                  <Form onSubmit={(e) => addItems(e, type)}>
                    <Form.Group className="d-flex bd-highlight">
                      <Form.Control
                        className="p-2 flex-grow-1 bd-highlight"
                        type="text"
                        value={newValue}
                        name="newValue"
                        placeholder="Add new Value"
                        onChange={(e) => setNewValue(e.target.value)}
                      />
                      <Dropdown className=" p-1 mt-0 bd-highlight small">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          {newFactorValue ? newFactorValue.name : "High"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setNewFactorValue({
                                value: 2,
                                name: "High",
                              })
                            }
                          >
                            High
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setNewFactorValue({
                                value: 1,
                                name: "Medium",
                              })
                            }
                          >
                            Medium
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setNewFactorValue({
                                value: 0,
                                name: "Low",
                              })
                            }
                          >
                            Low
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="bd-highlight">
                        <Button type="submit" className="bd-highlight  p-2">
                          <FontAwesomeIcon
                            className=" small"
                            icon={faCheckSquare}
                          />
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                )}
              </div>
              <div className="px-3 pt-3"></div>
              <div className="p-2">
                {typeArray?.map((data, index) => {
                  return (
                    <>
                      <Draggable
                        key={`${type}-` + data.id}
                        index={index}
                        draggableId={`${type}-` + data.id}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              className={`item border shadow-sm mb-1 rounded ${
                                snapshot.isDragging && "dragging shadow"
                              } d-flex align-items-center `}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="p-2 d-flex align-items-center border-right">
                                <FontAwesomeIcon
                                  icon={faGripVertical}
                                  className="text-muted"
                                />
                              </div>
                              <span className="flex-grow-1 p-2">
                                {" "}
                                {data.name ? data.name : " "}
                              </span>
                              <div className="pr-2">
                                {data.name && type !== "main" ? (
                                  <FontAwesomeIcon
                                    onClick={(e) => {
                                      onRevertBackToDragDrop(
                                        e,
                                        data,
                                        type,
                                        index
                                      );
                                    }}
                                    className="text-muted small"
                                    icon={faMinusCircle}
                                    style={{ cursor: "default" }}
                                    title="Remove"
                                  />
                                ) : (
                                  " "
                                )}
                              </div>
                              {data?.isNew && (
                                <div className="pr-2">
                                  <FontAwesomeIcon
                                    onClick={(e) => {
                                      deleteFactorHandler(e, data, type, index);
                                    }}
                                    className="text-muted small"
                                    icon={faTrash}
                                    style={{ cursor: "default" }}
                                    title="Delete"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      </Draggable>
                    </>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
}

function ErrcGrid(props) {
  const { darkTheme, currentEvent, graphId, factors, products, title } = props;

  // const [title, setTitle] = useState("");
  // const [titleValue, setTitleValue] = useState("");
  const [showAddField, setShowAddField] = useState({ key: "", show: false });
  const [editField, setEditField] = useState({ id: "", show: false });
  const [editValue, setEditValue] = useState("");
  const [newValue, setNewValue] = useState("");
  const [eliminate, setEliminate] = useState([]);
  const [raise, setRaise] = useState([]);
  const [reduce, setReduce] = useState([]);
  const [create, setCreate] = useState([]);
  const [main, setMain] = useState([]);
  const [newFactorValue, setNewFactorValue] = useState({
    value: 2,
    name: "High",
  });
  const [showMatrixFormModal, setShowMatrixFormModal] = useState(false);
  const [matrixData, setMatrixData] = useState(null);
  const [sending, setSending] = useState(false);
  const [showProTips, setShowProTips] = useState(true);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [subject, setSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const sender = '"igesia Inc." <noreply@igesia.co>';

  const [eventDetailsDoc, eventDetailsLoading, eventDetailsError] = useDocument(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa`)
      .doc(`${graphId}`)
  );

  const populateMatrixDetails = () => {
    if (!eventDetailsLoading) {
      let newData = { ...matrixData };

      if (eventDetailsDoc?.data()?.industry)
        newData["industry"] = eventDetailsDoc?.data()?.industry;
      if (eventDetailsDoc?.data()?.standardOfferings)
        newData["standardOfferings"] =
          eventDetailsDoc?.data()?.standardOfferings;
      if (eventDetailsDoc?.data()?.logoImage)
        newData["logoImage"] = eventDetailsDoc?.data()?.logoImage;
      if (eventDetailsDoc?.data()?.tagline)
        newData["tagline"] = eventDetailsDoc?.data()?.tagline;
      if (eventDetailsDoc?.data()?.valueProposition)
        newData["valueProposition"] = eventDetailsDoc?.data()?.valueProposition;
      if (eventDetailsDoc?.data()?.market)
        newData["market"] = eventDetailsDoc?.data()?.market;
      if (eventDetailsDoc?.data()?.nonCustomersReached)
        newData["nonCustomersReached"] =
          eventDetailsDoc?.data()?.nonCustomersReached;

      setMatrixData(newData);
    }
  };

  useEffect(() => {
    populateMatrixDetails();
  }, [eventDetailsDoc]);

  const addStandardOfferingHandler = () => {
    setMatrixData((prev) => {
      let newValues = { ...prev };
      let newStandardOfferings = newValues?.standardOfferings
        ? [...newValues?.standardOfferings, ""]
        : [""];

      newValues["standardOfferings"] = newStandardOfferings;
      return newValues;
    });
  };

  const changeFormFieldHandler = (e) => {
    setMatrixData((prev) => {
      let newValues = { ...prev };

      if (e.target.name?.indexOf(".") >= 0) {
        newValues[e.target.name.split(".")[0]][e.target.name.split(".")[1]] =
          e.target.value;
      } else newValues[e.target.name] = e.target.value;
      return newValues;
    });
  };

  const changeFormFieldHandler2 = (e) => {
    setMatrixData((prev) => {
      let newValues = { ...prev };
      let newMarket = newValues["market"] ?? {};

      newMarket[e.target.name] = e.target.value;

      return { ...newValues, market: newMarket };
    });
  };

  const changeFormFieldHandler3 = (e) => {
    setMatrixData((prev) => {
      let newValues = { ...prev };
      let newNonCustomersReached = newValues["nonCustomersReached"] ?? {};

      newNonCustomersReached[e.target.name] = e.target.value;

      return { ...newValues, nonCustomersReached: newNonCustomersReached };
    });
  };

  const changeStandardOfferingHandler = (e, index) => {
    setMatrixData((prev) => {
      let newValues = { ...prev };
      let newStandardOfferings = [
        ...newValues?.standardOfferings?.slice(0, index),
        e.target.value,
        ...newValues?.standardOfferings?.slice(
          index + 1,
          newValues?.standardOfferings?.length
        ),
      ];

      newValues["standardOfferings"] = newStandardOfferings;
      return newValues;
    });
  };

  const deleteStandardOfferingHandler = (index) => {
    if (index >= 0) {
      setMatrixData((prev) => {
        let newValues = { ...prev };
        let newStandardOfferings = [
          ...newValues?.standardOfferings?.slice(0, index),
          ...newValues?.standardOfferings?.slice(
            index + 1,
            newValues?.standardOfferings?.length
          ),
        ];

        newValues["standardOfferings"] = newStandardOfferings;
        return newValues;
      });
    } else {
      return;
    }
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", firebase.auth().currentUser.uid ?? null);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const logoImageUpdateHandler = async (event) => {
    if (!!event.target.files[0]) {
      const new_logo_image = event.target.files[0]
        ? await uploadFile(event.target.files[0])
        : null;

      try {
        const docRef = firebase
          .firestore()
          .collection(`events/${currentEvent?.id}/bboa`)
          .doc(`${graphId}`);

        await docRef.update({
          logoImage: new_logo_image,
        });

        toast.success("Logo image successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } catch (e) {
        console.error("Error updating logo image", e);
        toast.error("Error updating logo image", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } else {
      toast.info("Please select some image to update", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const matrixFormSubmitHandler = (e) => {
    e.preventDefault();
    setSending(true);

    const docRef = firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa`)
      .doc(`${graphId}`);

    docRef
      .update(matrixData, { merge: true })
      .then(() => {})
      .catch((e) => {
        console.error("Error updating blue ocean matrix details", e);
        toast.error("Error updating blue ocean matrix details", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSending(false);
      })
      .finally(() => {
        toast.success("Blue Ocean Matrix details successfully updated", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setSending(false);
        setShowMatrixFormModal(false);
      });
  };

  const db = firebase
    .firestore()
    .collection(`events/${currentEvent?.id}/bboa/${graphId}/errc_grid`);

  const [errcCollection, errcCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/${graphId}/errc_grid`)
  );

  const getErrcHandler = () => {
    if (graphId) {
      //console.log(graphId)
      errcCollection?.docs?.map((snapshot) => {
        //console.log(snapshot.data(), "my value");

        // console.log(
        //   snapshot.data().main,
        //   snapshot.data().eliminate,
        //   snapshot.data().reduce,
        //   snapshot.data().raise,
        //   snapshot.data().create
        // );
        // setTitle("Errc Grid");

        const filteredMain = factors?.filter((item1) => {
          return snapshot.data()?.main?.includes(item1.id);
        });
        //console.log(filteredMain);
        setMain(filteredMain);

        const filteredEliminate = factors?.filter((item1) => {
          return snapshot.data()?.eliminate?.includes(item1.id);
        });
        //console.log(filteredEliminate);
        setEliminate(filteredEliminate);

        const filteredReduce = factors?.filter((item1) => {
          return snapshot.data()?.reduce?.includes(item1.id);
        });
        //console.log(filteredReduce);
        setReduce(filteredReduce);

        const filteredRaise = factors?.filter((item1) => {
          return snapshot.data()?.raise?.includes(item1.id);
        });
        //console.log(filteredRaise);
        setRaise(filteredRaise);

        const filteredCreate = factors?.filter((item1) => {
          return snapshot.data()?.create?.includes(item1.id);
        });
        //console.log(filteredCreate);
        setCreate(filteredCreate);
      });
    }
  };

  const deleteFactorHandler = async (e, data, type, index) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_BASE_URL}${BBOA_DELETE_FACTOR}`,
        {
          product_id: currentEvent?.id,
          product_type: currentEvent?.type,
          project_id: graphId,
          factor_id: data?.id,
        }
      );

      if (response.status === 200) {
        toast.dark("Factor deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        toast.dark("Error deleting factors", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(`[Error] Deleting factors: ${error.message}`);
    }
  };

  useEffect(() => {
    if (!errcCollectionLoading) {
      getErrcHandler();
    }
  }, [errcCollection, factors]);

  const addItems = (e, key) => {
    e.preventDefault();
    //console.log(newValue, key, newFactorValue);

    if (graphId) {
      let data = {
        defaultValue: newFactorValue.value,
        name: newValue,
        positionToBe: factors.length,
        isNew: true,
      };
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
        .add(data)
        .then((doc) => {
          if (key == "eliminate" && graphId) {
            let itemCopy = [...eliminate, { ...data, id: doc.id }];
            //console.log(itemCopy);
            updateEliminate(itemCopy);
          } else if (key == "raise" && graphId) {
            let itemCopy = [...raise, { ...data, id: doc.id }];
            updateRaise(itemCopy);
          } else if (key == "reduce" && graphId) {
            let itemCopy = [...reduce, { ...data, id: doc.id }];
            updateReduce(itemCopy);
          } else if (key == "create" && graphId) {
            let itemCopy = [...create, { ...data, id: doc.id }];
            updateCreate(itemCopy);
          } else if (key == "main" && graphId) {
            let itemCopy = [...main, { ...data, id: doc.id }];
            updateMain(itemCopy);
          }
        });
    }

    // let data = {defaultValue:1, uuid: v4(), name: newValue };
    // if (key == "eliminate" && graphId) {
    //   let itemCopy = [...eliminate, data];
    //   console.log(itemCopy)
    //   updateEliminate(itemCopy);
    // } else if (key == "raise" && graphId) {
    //   let itemCopy = [...raise, data];
    //   updateRaise(itemCopy);
    // } else if (key == "reduce" && graphId) {
    //   let itemCopy = [...reduce, data];
    //   updateReduce(itemCopy);
    // } else if (key == "create" && graphId) {
    //   let itemCopy = [...create, data];
    //   updateCreate(itemCopy);
    // } else if (key == "main" && graphId) {
    //   let itemCopy = [...main, data];
    //   updateMain(itemCopy);
    // }
    // setNewValue("");
    // setShowAddField({ key: "", show: false });
  };

  const onRevertBackToDragDrop = (e, data, quadrant, index) => {
    e.preventDefault();

    //console.log(data, quadrant, index, "my data from revert back")
    if (quadrant == "eliminate") {
      let itemCopy = eliminate[index];
      eliminate.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateEliminate(eliminate);
    } else if (quadrant == "raise") {
      let itemCopy = raise[index];
      raise.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateRaise(raise);
    } else if (quadrant == "reduce") {
      let itemCopy = reduce[index];
      reduce.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateReduce(reduce);
    } else if (quadrant == "create") {
      let itemCopy = create[index];
      create.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateCreate(create);
    }
    updateMain(main);
  };

  const editItems = (e, id, key) => {
    e.preventDefault();
    //console.log(e, id, key);

    if (id) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/${graphId}/factors`)
        .doc(id)
        .set(
          {
            name: editValue,
          },
          {
            merge: true,
          }
        );
    }
    if (key == "main") {
      let objIndex = main.findIndex((obj) => obj.id == id);
      main[objIndex].name = editValue;
      setMain(main);
      //updateEliminate(eliminate);
    }
    if (key == "eliminate") {
      let objIndex = eliminate.findIndex((obj) => obj.id == id);
      eliminate[objIndex].name = editValue;
      setEliminate(eliminate);
      //updateEliminate(eliminate);
    }
    if (key == "raise") {
      let objIndex = raise.findIndex((obj) => obj.id == id);
      raise[objIndex].name = editValue;
      setRaise(raise);
      // updateRaise(raise);
    }
    if (key == "reduce") {
      let objIndex = reduce.findIndex((obj) => obj.id == id);
      reduce[objIndex].name = editValue;
      setReduce(reduce);
      // updateReduce(reduce);
    }
    if (key == "create") {
      let objIndex = create.findIndex((obj) => obj.id == id);
      create[objIndex].name = editValue;
      setCreate(create);
      // updateCreate(create);
    }

    setEditValue("");
    setEditField({ id: "", show: false });
  };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }
    if (destination) {
      if (source.droppableId == "main") {
        let itemCopy = main[source.index];
        main.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "eliminate") {
        let itemCopy = eliminate[source.index];
        eliminate.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "raise") {
        let itemCopy = raise[source.index];
        raise.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "reduce") {
        let itemCopy = reduce[source.index];
        reduce.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "create") {
        let itemCopy = create[source.index];
        create.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
    }
  };

  const updateEliminate = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "eliminate update");
    if (result) {
      db.doc("0").set(
        {
          eliminate: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };

  const updateReduce = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "reduce update");
    if (result) {
      db.doc("0").set(
        {
          reduce: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };

  const updateRaise = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "raise update");
    if (result) {
      db.doc("0").set(
        {
          raise: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };
  const updateCreate = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "create update");
    if (result) {
      db.doc("0").set(
        {
          create: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };
  const updateMain = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "main update");
    if (result) {
      db.doc("0").set(
        {
          main: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };

  const handleCopyTextToClipboard = () => {
    const body = document.querySelector("body");
    const area = document.createElement("textarea");
    body.appendChild(area);

    area.value = `${window.location.origin}/export/bboa/${currentEvent?.slug}/${graphId}`;
    area.select();
    document.execCommand("copy");

    body.removeChild(area);
    toast.dark("Link copied!", {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };

  const handleEmailToAdmin = () => {
    setShowSendEmailModal(true);
    setMessageBody((prev) => {
      return (
        prev +
        `<p>Hello,</p><p>Please find the shared link of Blue Ocean Matrix below, <br/>BBOA Link: <a href="${window.location.origin}/export/bboa/${currentEvent?.slug}/${graphId}" target="_blank">${window.location.origin}/export/bboa/${currentEvent?.slug}/${graphId}</a></p>`
      );
    });
  };

  const discardEmail = () => {
    setSubject("");
    setMessageBody("");
    setShowSendEmailModal(false);
  };

  const isThereAnyContent = useMemo(
    () => subject != "" || messageBody != "",
    [subject, messageBody]
  );

  const submitEmail = async () => {
    if (isThereAnyContent) {
      setSendingEmail(true);
      try {
        const response = await sendEmail({
          to: currentEvent?.event_manager?.join(","),
          subject: subject,
          content: messageBody,
          from: sender,
          replyTo: `${auth?.currentUser?.email}`,
        });
        if (response.status === 200) {
          setSendingEmail(false);
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else if (response.status !== 200) {
          setSendingEmail(false);
          toast.error("There was an error in sending email", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } catch (error) {
        setSendingEmail(false);
        console.log("Email error: ", error);
      } finally {
        discardEmail();
      }
    } else {
      toast.error("One or more field(s) are empty", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Card
        className={
          darkTheme
            ? "bg-dark shadow-sm border border-dark mb-3"
            : "bg-white shadow-sm border border-light mb-3"
        }
      >
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h5 className="mb-0 text-uppercase">8. Blue Ocean Matrix</h5>
            <div className="d-flex">
              <Dropdown className="pointer no-dropdown-caret mr-2">
                <Dropdown.Toggle
                  as="div"
                  id="dropdown-basic"
                  className="btn btn-secondary btn-sm text-uppercase"
                >
                  Share Matrix
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      className="text-decoration-none text-dark small"
                      onClick={handleCopyTextToClipboard}
                    >
                      <FontAwesomeIcon icon={faLink} className="mr-2" />
                      Copy Sharable Link
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link
                      onClick={handleEmailToAdmin}
                      className="text-decoration-none text-dark small"
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      Email to Admin
                    </Link>
                  </Dropdown.Item>
                  {/* <Dropdown.Item>
                    <Link
                      className="text-decoration-none text-dark small"
                      to={`${window.location.origin}/export/bboa/${currentEvent?.slug}/${graphId}`}
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="mr-2"
                      />
                      Open in New tab
                    </Link>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                className="text-uppercase"
                size="sm"
                onClick={() => {
                  setShowMatrixFormModal(true);
                }}
              >
                Update details
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-end mb-3">
            <div className="d-flex flex-column">
              {/* Logo */}
              {matrixData?.logoImage ? (
                <div className="mb-2">
                  <Image
                    src={matrixData?.logoImage}
                    width="100"
                    className="img-fluid shadow-sm p-1 bg-white rounded"
                  />
                </div>
              ) : (
                <p className="text-uppercase lead">Logo</p>
              )}
              {/* Tagline */}
              <p className="mb-0 font-weight-bold" style={{ color: "#2f2a60" }}>
                {matrixData?.tagline ? matrixData?.tagline : "TAGLINE"}
              </p>
            </div>
            <div className="d-flex align-items-end flex-column">
              <p className="mb-2" style={{ color: "#2f2a60" }}>
                <b>VALUE PROPOSITION</b>
              </p>
              <p className="lead text-info mb-0">
                {matrixData?.valueProposition
                  ? matrixData?.valueProposition
                  : "________________"}
              </p>
            </div>
          </div>

          <div
            className="mb-3 rounded p-2"
            style={{ backgroundColor: "#ff5050ff" }}
          >
            <p className="text-white mb-0">
              <b className="mr-2">INDUSTRY [Red Ocean]:</b>
              <span>
                {matrixData?.industry
                  ? matrixData?.industry
                  : "________________"}
              </span>
            </p>

            <p className="text-white mb-0">
              <b className="mr-2">Standard offerings:</b>
              <span>
                {products
                  ? products?.map((item) => item.name)?.join(", ")
                  : "________________"}
              </span>
            </p>
          </div>

          <div className="mb-3">
            <div className="row justify-content-between mb-3">
              <div className="col" style={{ color: "#2f2a60" }}>
                <p className="lead font-weight-bold mb-0">MARKET POTENTIAL</p>
                <p className="font-weight-light mb-0">
                  {matrixData?.market?.potential ?? "COUNTRY / WORLD"}
                </p>
              </div>
              <div className="col d-flex align-items-center justify-content-center">
                <div className="border rounded w-100 p-2">
                  <p className="mb-0 font-weight-bold small text-danger">
                    Market Size: {matrixData?.market?.size ?? "____________"}
                  </p>
                  <p className="mb-0 font-weight-bold small text-info">
                    Created Market:{" "}
                    {matrixData?.market?.sizeCreated ?? "____________"}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ color: "#2f2a60" }}>
              <p className="mb-1">
                <span className="mr-2">Market size in 5 years</span>
                <b>
                  {matrixData?.market?.sizeIn5Years ?? "____________ Clients"}
                </b>
              </p>
              <p className="mb-1">
                <span className="mr-2">Market share in 5 years</span>
                <b>{matrixData?.market?.shareIn5Years ?? "____________"} %</b>
              </p>
              <p className="mb-1">
                <span className="mr-2">Revenues in 5 years</span>
                <b>{matrixData?.market?.revenueIn5Years ?? "____________"}</b>
              </p>
            </div>
          </div>

          <div className="mb-3">
            <div className="mb-2" style={{ color: "#2f2a60" }}>
              <p className="lead font-weight-bold mb-0">
                NONCUSTOMERS REACHED BY THE OFFERING
              </p>
            </div>
            <div style={{ color: "#2f2a60" }}>
              <ul className="pl-3">
                <li className="mb-1">
                  <b className="mr-2">To-Be:</b>
                  <span>
                    {matrixData?.nonCustomersReached?.toBe ??
                      "________________________"}
                  </span>
                </li>
                <li className="mb-1">
                  <b className="mr-2">Anti:</b>
                  <span>
                    {matrixData?.nonCustomersReached?.anti ??
                      "________________________"}
                  </span>
                </li>
                <li className="mb-1">
                  <b className="mr-2">Unexplored:</b>
                  <span>
                    {matrixData?.nonCustomersReached?.unexplored ??
                      "________________________"}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="row">
              <Quadrants
                quadName="Reduced"
                type={"reduce"}
                typeArray={reduce}
                setNewValue={setNewValue}
                addItems={addItems}
                showAddField={showAddField}
                setShowAddField={setShowAddField}
                editField={editField}
                editItems={editItems}
                editValue={editValue}
                setEditValue={setEditValue}
                setEditField={setEditField}
                setNewFactorValue={setNewFactorValue}
                newFactorValue={newFactorValue}
                onRevertBackToDragDrop={onRevertBackToDragDrop}
                deleteFactorHandler={deleteFactorHandler}
              />
              <Quadrants
                quadName="Eliminated"
                type={"eliminate"}
                typeArray={eliminate}
                setNewValue={setNewValue}
                addItems={addItems}
                showAddField={showAddField}
                setShowAddField={setShowAddField}
                editField={editField}
                editItems={editItems}
                editValue={editValue}
                setEditValue={setEditValue}
                setEditField={setEditField}
                setNewFactorValue={setNewFactorValue}
                newFactorValue={newFactorValue}
                onRevertBackToDragDrop={onRevertBackToDragDrop}
                deleteFactorHandler={deleteFactorHandler}
              />
              <Quadrants
                quadName="Raised"
                type={"raise"}
                typeArray={raise}
                setNewValue={setNewValue}
                addItems={addItems}
                showAddField={showAddField}
                setShowAddField={setShowAddField}
                editField={editField}
                editItems={editItems}
                editValue={editValue}
                setEditValue={setEditValue}
                setEditField={setEditField}
                setNewFactorValue={setNewFactorValue}
                newFactorValue={newFactorValue}
                onRevertBackToDragDrop={onRevertBackToDragDrop}
                deleteFactorHandler={deleteFactorHandler}
              />
              <Quadrants
                quadName="Created"
                type={"create"}
                typeArray={create}
                setNewValue={setNewValue}
                addItems={addItems}
                showAddField={showAddField}
                setShowAddField={setShowAddField}
                editField={editField}
                editItems={editItems}
                editValue={editValue}
                setEditValue={setEditValue}
                setEditField={setEditField}
                setNewFactorValue={setNewFactorValue}
                newFactorValue={newFactorValue}
                onRevertBackToDragDrop={onRevertBackToDragDrop}
                deleteFactorHandler={deleteFactorHandler}
              />
              <Quadrants
                quadName="Main"
                type={"main"}
                typeArray={main}
                setNewValue={setNewValue}
                addItems={addItems}
                showAddField={showAddField}
                setShowAddField={setShowAddField}
                editField={editField}
                editItems={editItems}
                editValue={editValue}
                setEditValue={setEditValue}
                setEditField={setEditField}
                setNewFactorValue={setNewFactorValue}
                newFactorValue={newFactorValue}
                onRevertBackToDragDrop={onRevertBackToDragDrop}
                deleteFactorHandler={deleteFactorHandler}
              />
            </div>
          </DragDropContext>
        </Card.Body>
        <Card.Footer>
          <div className="d-flex align-items-center">
            <p className="mb-0 text-uppercase small">
              <b>Pro tips</b>
            </p>
            <p
              style={{ cursor: "pointer" }}
              className="mb-0 text-uppercase small text-muted ml-2"
              onClick={() => setShowProTips((prev) => !prev)}
              title={
                showProTips
                  ? "Click to hide pro tips"
                  : "Click to show pro tips"
              }
            >
              <b>({showProTips ? "Hide" : "Show"})</b>
            </p>
          </div>
          <ol
            className={showProTips ? "px-3 mb-1 pb-1" : "px-3 mb-1 pb-1 d-none"}
          >
            <li>
              <small>Drag factors from bottom to a cell in Errc Grid.</small>
            </li>
            <li>
              <small>
                Add new factors by clicking on <b>+</b> plus button.
              </small>
            </li>
            <li>
              <small>
                Click on <b>-</b> minus button to remove a factor from a cell.
              </small>
            </li>
          </ol>
        </Card.Footer>
      </Card>

      {/* Modal: Email to Admin */}
      <Modal
        show={showSendEmailModal}
        onHide={() => setShowSendEmailModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h5 className="mb-0">Email to Admin</h5>
        </Modal.Header>
        <Modal.Body>
          <p
            className="mb-1 p-2 border-bottom"
            style={{ borderColor: "#515151" }}
          >
            <span className="small text-muted mr-2">From</span>{" "}
            {auth?.currentUser?.displayName} ({auth?.currentUser?.email})
          </p>

          <Form.Group className="mb-2">
            <Form.Label className="small text-muted font-weight-bold">
              Subject
            </Form.Label>
            <Form.Control
              disabled={sendingEmail}
              size="sm"
              type="text"
              placeholder="Subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="small text-muted font-weight-bold">
              Message body
            </Form.Label>
            <CKEditor
              disabled={sendingEmail}
              id="editor"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "bold",
                  "italic",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "undo",
                  "redo",
                ],
              }}
              data={messageBody ?? ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMessageBody(data);
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            />
          </Form.Group>
          {/* <div>{JSON.stringify(auth?.currentUser)}</div> */}
          <div className="d-flex flex-row-reverse">
            <Button
              disabled={sendingEmail}
              variant="secondary"
              size="sm"
              onClick={discardEmail}
            >
              Discard
            </Button>
            <Button
              disabled={sendingEmail}
              className="mr-2"
              size="sm"
              onClick={submitEmail}
            >
              {sendingEmail ? "Sending..." : "Send"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal: Blue ocean matrix */}
      <Modal
        show={showMatrixFormModal}
        onHide={() => setShowMatrixFormModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="mb-0">Update details</h5>
            <p className="small text-muted text-uppercase mb-0">
              Blue Ocean Matrix
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={matrixFormSubmitHandler}>
            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Logo Image
              </Form.Label>
              {!!matrixData?.logoImage && (
                <div className="d-flex">
                  <div className="mb-2 border rounded p-2">
                    <p className="mb-1 text-muted text-uppercase small">
                      <b>Preview</b>
                    </p>
                    <Image
                      src={matrixData?.logoImage}
                      width="100"
                      className="img-fluid shadow-sm p-1 bg-white rounded"
                    />
                  </div>
                </div>
              )}
              <Form.File
                id="logoImage"
                name="logoImage"
                accept="image/*"
                onChange={logoImageUpdateHandler}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Tagline
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter tagline"
                value={matrixData?.tagline}
                name="tagline"
                onChange={changeFormFieldHandler}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Value Proposition
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter value proposition"
                value={matrixData?.valueProposition}
                name="valueProposition"
                onChange={changeFormFieldHandler}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                INDUSTRY PERIMETER
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Enter INDUSTRY [Red Ocean]"
                value={matrixData?.industry}
                name="industry"
                onChange={changeFormFieldHandler}
              />
            </Form.Group>

            {/* <Form.Group>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Form.Label className="small text-muted font-weight-bold text-uppercase mb-0">
                  Standard Offerings
                </Form.Label>
                <Button
                  className="text-uppercase"
                  size="sm"
                  onClick={addStandardOfferingHandler}
                >
                  Add
                </Button>
              </div>
              {matrixData?.standardOfferings?.length > 0 ? (
                matrixData?.standardOfferings?.map((item, idx) => (
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <Form.Control
                      key={idx}
                      type="text"
                      placeholder="Enter standard offering"
                      value={item}
                      className="flex-grow-1"
                      onChange={(e) => changeStandardOfferingHandler(e, idx)}
                    />
                    <a
                      onClick={() => deleteStandardOfferingHandler(idx)}
                      className="p-2 pointer"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </div>
                ))
              ) : (
                <p className="text-center mb-0">
                  <i>No standard offerings added yet.</i>
                </p>
              )}
            </Form.Group> */}

            <h6 className="text-muted text-uppercase font-weight-bold mt-4">
              Market Details
            </h6>
            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Potential
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter market potential"
                value={matrixData?.market?.potential}
                name="potential"
                onChange={changeFormFieldHandler2}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Market size
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter market size"
                value={matrixData?.market?.size}
                name="size"
                onChange={changeFormFieldHandler2}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Created market
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter created market"
                value={matrixData?.market?.sizeCreated}
                name="sizeCreated"
                onChange={changeFormFieldHandler2}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Size in 5 years
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter market size in 5 years"
                value={matrixData?.market?.sizeIn5Years}
                name="sizeIn5Years"
                onChange={changeFormFieldHandler2}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Share in 5 years <span className="">(value in %)</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter market share in 5 years"
                value={matrixData?.market?.shareIn5Years}
                name="shareIn5Years"
                onChange={changeFormFieldHandler2}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Revenues in 5 years
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter revenues in 5 years"
                value={matrixData?.market?.revenueIn5Years}
                name="revenueIn5Years"
                onChange={changeFormFieldHandler2}
              />
            </Form.Group>

            <h6 className="text-muted text-uppercase font-weight-bold mt-4">
              NONCUSTOMERS REACHED BY THE OFFERING
            </h6>
            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                To-Be
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter non-customers to-be"
                value={matrixData?.nonCustomersReached?.toBe}
                name="toBe"
                onChange={changeFormFieldHandler3}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Anti
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter non-customers anti"
                value={matrixData?.nonCustomersReached?.anti}
                name="anti"
                onChange={changeFormFieldHandler3}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="small text-muted font-weight-bold text-uppercase">
                Unexplored
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter non-customers unexplored"
                value={matrixData?.nonCustomersReached?.unexplored}
                name="unexplored"
                onChange={changeFormFieldHandler3}
              />
            </Form.Group>

            <div className="d-flex">
              <Button
                size="sm"
                className="mr-2 text-uppercase"
                type="submit"
                disabled={sending}
              >
                {sending ? "Updating..." : "Update"}
              </Button>
              <Button
                size="sm"
                className="shadow-sm text-uppercase"
                variant="light"
                disabled={sending}
                onClick={() => {
                  populateMatrixDetails();
                  setShowMatrixFormModal(false);
                }}
              >
                Discard
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    graphId: state.graphId,
    factors: state.factors,
    products: state.products,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  null
)(ErrcGrid);
