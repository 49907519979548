import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import firebase from "firebase";

import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const Documents = () => {
  const state = useSelector((state) => state);

  const [formDetails, setFormDetails] = useState({});

  const [isUploading, setIsUploading] = useState("");
  const [sendingDoc, setSendingDoc] = useState(false);
  // const [modalShow, setModalShow] = useState(false);
  // const [docToShow, setDocToShow] = useState(null);

  const [allDocuments, setAllDocuments] = useState([]);

  const fetchAllDocuments = () => {
    firebase
      .firestore()
      .collection("clinics")
      .doc(state.clinicId)
      .collection("clinicDocuments")
      .orderBy("createdAt", "asc")
      .onSnapshot((doc) => {
        let tempDocuments = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempDocuments.push({ ...item.data(), id: item.id });
          });
        }
        setAllDocuments([...tempDocuments]);
      });
  };

  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);

    fileData.append("user_id", "1gHTSDwzWRPW7KAUeTcscDFxs7z2");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        setIsUploading(
          Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
        );
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
        return "";
      }
    } else {
      return "";
    }
  };

  const handleDelete = (id) => {
    firebase
      .firestore()
      .collection("clinics")
      .doc(state.clinicId)
      .collection("clinicDocuments")
      .doc(id)
      .delete()
      .then(() => {
        alert("Document deleted successfully!");
      });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (isUploading) return;
    let file = e.target.files[0];
    let fileUrl = await uploadFile(file);
    formDetails["url"] = fileUrl;
    formDetails["type"] = file.type;
    setFormDetails({ ...formDetails });
  };

  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formDetails?.title) {
      setError("Please enter a title!");
      return;
    }
    if (!formDetails?.url) {
      setError("Please select a file!");
      return;
    }
    setError(null);
    setSendingDoc(true);
    firebase
      .firestore()
      .collection("clinics")
      .doc(state.clinicId)
      .collection("clinicDocuments")
      .add({
        ...formDetails,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: Date.now(),
      })
      .then(() => {
        setIsUploading("");
        setFormDetails({
          title: "",
          url: "",
        });
      })
      .then(() => {
        setSendingDoc(false);

        alert("Document added successfully!");
      });
  };

  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <div className="p-3 text-light">
      <h5>Documents Management</h5>
      {/* <div className="row">
                  <div className="col-md-6">
                    <p className="small text-secondary">
                      Create profiles for your managers here. Once created,
                      you’ll need to invite your manager. They will then have
                      the option of accepting or editing your suggested profile.
                    </p>
                  </div>
                </div> */}
      {allDocuments.length ? (
        allDocuments.map((doc, idx) => (
          <Card className="bg-dark text-white p-2 font-14 my-2">
            <div className="row mx-0">
              <div className="col-2 font-weight-bold">{idx + 1}.</div>
              <div className="col-4 px-0 ">
                <div className="font-12 text-muted">
                  {moment.utc(doc.timestamp).local().format("LLL")}
                </div>
                <div>{doc.title}</div>
              </div>

              <div className="col-2 font-weight-bold">
                <a
                  href={doc.url}
                  target="_blank"
                  className="btn btn-primary btn-sm"
                >
                  View
                </a>
              </div>
              <div className="col-2">{doc.type}</div>
              <div className="col-2 font-weight-bold">
                <span
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDelete(doc.id)}
                >
                  Delete
                </span>
              </div>
            </div>
          </Card>
        ))
      ) : (
        <div className="text-center my-4 font-13">No documents found!</div>
      )}

      <div>
        <Card className="bg-dark text-light">
          <CardHeader title="Add a document:" />

          <CardContent>
            <div className="mb-2 text-light">
              <TextField
                value={formDetails.title}
                className="w-100 text-light"
                label="Title"
                onChange={(e) => {
                  formDetails["title"] = e.target.value;
                  setFormDetails({ ...formDetails });
                }}
              />
            </div>
            <div className="my-2">
              <input
                type="file"
                className="form-control-file"
                onChange={handleUpload}
                // accept="image/png, image/gif, image/jpeg"
              />
            </div>
            {isUploading === 0 || isUploading ? (
              <div className="progress my-2">
                <div
                  className="progress-bar bg-primary progress-bar-striped "
                  role="progressbar"
                  style={{ width: `${isUploading}%` }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {isUploading}%
                </div>
              </div>
            ) : null}
            {error && <div className="font-14 text-danger">{error}</div>}
            <div className="text-right">
              <span className="btn btn-sm btn-primary" onClick={handleSubmit}>
                Submit{" "}
                {sendingDoc && (
                  <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                )}
              </span>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Documents;
