import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap";
import "flatpickr/dist/themes/material_green.css";

import firebase from "firebase";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
toast.configure();

const Category = (props) => {
  const { show, editValuesCategory } = props;
  const [saving, setSaving] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const saveTextDataToStateHandler = (e) => {
    setCategoryData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if (editValuesCategory?.id) {
      setCategoryData({ ...editValuesCategory?.data() })
    }
  }, [editValuesCategory]);

  const addScheduleToDBHandler = async (category) => {
    try {
      let size = await firebase
        .firestore()
        .collection(`/events/${props.eventId}/categories`)
        .get();

      size = size.size ?? 0;

      let docref = firebase
        .firestore()
        .collection(`/events/${props.eventId}/categories`)
        .doc();

      await docref.set({
        ...category,
        order_index: +size + 1,
      });

      toast.success("Category added successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setCategoryData({});
    } catch (e) {
      console.error(e);
      toast.error("Error adding category", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };

  const editScheduleToDBHandler = async (category) => {
    try {
      let docref = firebase
        .firestore()
        .collection(`/events/${props.eventId}/categories`)
        .doc(`${editValuesCategory?.id}`);

      await docref.update({
        ...category,
      }, { merge: true });

      toast.success("Category updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      setCategoryData({});
    } catch (e) {
      console.error(e);
      toast.error("Error updating category", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New Category details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                name="title"
                value={categoryData.title}
                onChange={(e) => saveTextDataToStateHandler(e)}
              />
            </Form.Group>
          </div>
          {editValuesCategory?.id ?
            <Button
              variant="primary"
              className="w-100"
              onClick={() => editScheduleToDBHandler(categoryData)}
              disabled={saving}
            >
              Edit
              {saving ? <Spinner /> : null}
            </Button>
            :
            <Button
              variant="primary"
              className="w-100"
              onClick={() => addScheduleToDBHandler(categoryData)}
              disabled={saving}
            >
              Save
              {saving ? <Spinner /> : null}
            </Button>
          }
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default connect((state) => ({
  eventId: state.eventId,
}))(Category);
