import React, { useEffect, useState, useMemo } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import EventNavbar from "../NavBar";
import EventLeftbar from "../LeftBar";
import { setGraphId, setProducts } from "../../../../store/actions/actions";
import "@culturehq/add-to-calendar/dist/styles.css";
import firebase from "firebase";
import EventChat from "../EventChat";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { Button, Modal, Form } from "react-bootstrap";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./apps.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faShare,
  faTrash,
  faEdit,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

function ProjectList(props) {
  const user = firebase.auth().currentUser;
  const { id } = useParams();
  const { darkTheme, currentEvent, setProducts, setGraphId } = props;

  const [allGraphs, setAllGraphs] = useState(null);
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [industry, setIndustry] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAttendeeCategory, setSelectedAttendeeCategory] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const [editProjectDetails, setEditProjectDetails] = useState(null);
  const [editProjectName, setEditProjectName] = useState(false);

  useEffect(() => {
    setProducts([]);
  }, []);

  const [usersSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/users`)
      .where("uid", "!=", user.uid)
  );

  const allUsers = useMemo(() => {
    return usersSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        label: fac?.data()?.firstName,
        value: fac?.data()?.firstName,
        ...fac.data(),
      };
    });
  }, [usersSnapshot]);

  const [currentUserDoc, currentUserLoading, currentUserError] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/users`)
      .doc(`${user.uid}`)
  );
  const currentUserCategory = currentUserDoc?.data()?.category ?? "";
  const currentUserRole = currentUserDoc?.data()?.user_role ?? "";

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa`)
      .onSnapshot((snapshot) => {
        const tempGraphs = [];
        snapshot.forEach((doc) => {
          //if (!doc.data().reset && (doc.data().datasets && doc.data().datasets.length)) {
          tempGraphs.push({ ...doc.data(), id: doc.id });
          //}
        });
        setAllGraphs(tempGraphs);
      });
  }, []);

  const addProjectHandler = (e) => {
    e.preventDefault();

    if (projectName && industry) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .add({
          title: projectName,
          createdAt: Date.now(),
          createdBy: user.uid,
          errc_created: false,
          to_be_created: false,
          buyerExpAudit_created: false,
          six_path: false,
          three_tiers: false,
          industry: industry ?? "",
          assignedToCategory: selectedAttendeeCategory ?? "",
        })
        .then((doc) => {
          setGraphId(doc.id);
          firebase
            .firestore()
            .collection(`events/${currentEvent?.id}/bboa/${doc.id}/factors`)
            .add({
              defaultValue: 1,
              name: "Price",
              description:
                "Price remains always shown at first in a strategy canvas",
              position: 0,
            });
          history.push(`/event/${currentEvent?.slug}/bboa/project/${doc.id}`);
          setProducts([]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warning("Please add mandatory fields", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const deleteProjectHandler = () => {
    if (selectedProject) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${selectedProject.id}`)
        .delete()
        .then(() => {
          setShowDeleteModal(false);
          toast.success("Project deleted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const handleSelectedShare = (graphId) => {
    if (selectedUsers.length > 0) {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${graphId}`)
        .update(
          {
            assignedTo: firebase.firestore.FieldValue.arrayUnion(
              ...selectedUsers
            ),
            assignedToAll: false,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }

    if (selectedAttendeeCategory != "") {
      firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${graphId}`)
        .update({
          assignedToCategory: selectedAttendeeCategory,
          assignedToAll: false,
        })
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedAttendeeCategory("");
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }
  };

  let history = useHistory();

  useEffect(() => {
    if (!!selectedProject) {
      const thisProject = allGraphs?.filter(
        (item) => item.id === selectedProject.id
      );

      if (thisProject[0]) {
        setSelectedProject(thisProject[0]);
      }
    }
  }, [allGraphs]);

  const revokeSharingAccess = (project_id) => {
    const projectRef = firebase
      .firestore()
      .collection(`events/${currentEvent?.id}/bboa/`)
      .doc(`${project_id}`);

    projectRef
      .update({
        assignedToCategory: "",
      })
      .then(() => {
        setShowRevokeModal(false);
        toast.dark("Access revoked", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((error) => console.error("Revoke sharing access: ", error));
  };

  const shareToAllHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${project_id}`);

      projectRef
        .set(
          {
            assignedToAll: true,
            assignedToCategory: "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared to all successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const editProjectNameHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          title: projectName,
          industry: industry ?? "",
        })
        .then(() => {
          setEditProjectName(false);
          setProjectName("");
          setIndustry("");

          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const revokeAccessFromAll = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${currentEvent?.id}/bboa/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          assignedToAll: false,
          assignedToCategory: "",
        })
        .then(() => {
          toast.success("Revoked Access successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const UserDetails = ({ uid, idx, project_id }) => {
    const userDetails = allUsers?.filter((item) => item.uid === uid);
    return (
      <div className="d-flex align-items-center mb-1" key={idx}>
        <div className="mr-2">
          <Button
            size="sm"
            variant="danger"
            onClick={() => revokeSharingAccess(uid, project_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="flex-grow-1">
          <p className="mb-0">
            {idx + 1}. {userDetails[0]?.label} ({userDetails[0]?.email})
          </p>
        </div>
      </div>
    );
  };

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  const Template = ({ is_created, title }) => {
    return (
      <div className="d-flex align-items-center">
        {is_created ? (
          <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon className="text-muted" icon={faCircle} />
        )}
        <p className="small text-uppercase ml-2 mb-0">{title}</p>
      </div>
    );
  };

  return (
    <div className="h-100 montserrat-font">
      <EventLeftbar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <EventNavbar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <EventLeftbar />
          <div
            className="col-md-7 h-100 p-md-5 p-3"
            data-theme={darkTheme ? "dark" : "light"}
            style={{
              overflow: "scroll",
            }}
          >
            {/* Networking */}

            <React.Fragment>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div
                    className={
                      darkTheme
                        ? "shadow-sm p-3 rounded bg-dark"
                        : "shadow-sm p-3 rounded bg-white"
                    }
                  >
                    <h3>BBOA Projects</h3>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  size="sm"
                  className="mb-2"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Add
                </Button>

                {allGraphs &&
                  allGraphs?.map((graph, key) => (
                    <>
                      {(graph.createdBy === user.uid ||
                        currentUserRole === 1 ||
                        graph.assignedToAll ||
                        graph.assignedToCategory === currentUserCategory) && (
                        <div
                          className={
                            darkTheme
                              ? "shadow-sm rounded-lg px-2 pb-2 card mb-2 border-1 bg-dark"
                              : "shadow-sm rounded-lg px-2 pb-2 card mb-2 border-1 bg-white"
                          }
                          // className="shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 "
                          key={key}
                        >
                          <div className="d-flex mb-2">
                            <div
                              className="pointer flex-grow-1"
                              onClick={() => {
                                setGraphId(graph.id);
                                history.push(
                                  `/event/${id}/bboa/project/${graph.id}`
                                );
                              }}
                            >
                              <small className="text-theme-lighter smaller">
                                {graph?.createdAt &&
                                  moment(graph.createdAt).format("LLL")}
                              </small>
                              <p className="m-0 font-weight-bold text-theme small">
                                {graph.title && <div>{graph.title}</div>}
                              </p>
                            </div>

                            {(graph.createdBy != user.uid ||
                              // graph.assignedTo.includes(user.uid) ||
                              graph.assignedToCategory ===
                                currentUserCategory) && (
                              <div className="p-2">
                                <>
                                  {allUsers?.map((item) => (
                                    <>
                                      {item.uid === graph.createdBy && (
                                        <p className=" small">{`Created By ${item.firstName}`}</p>
                                      )}
                                    </>
                                  ))}
                                </>
                              </div>
                            )}

                            {graph.createdBy === user.uid && (
                              <>
                                <div className="pt-2 mr-2">
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setEditProjectName(true);
                                      setEditProjectDetails(graph);
                                      setProjectName(graph?.title);
                                      setIndustry(graph?.industry);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                                    Edit
                                  </Button>
                                </div>
                                <div className="pt-2 mr-2">
                                  <Button
                                    size="sm"
                                    onClick={() => {
                                      setShowShare(true);
                                      setSelectedProject(graph);
                                    }}
                                    className="text-uppercase font-weight-bold"
                                  >
                                    <FontAwesomeIcon icon={faShare} size="sm" />{" "}
                                    <small>Share</small>
                                  </Button>
                                  <Modal
                                    centered
                                    show={showShare}
                                    onHide={() => {
                                      setShowShare(false);
                                      setSelectedUsers([]);
                                    }}
                                  >
                                    <Modal.Header
                                      closeButton
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <div className="d-flex flex-column">
                                        <p className="h4 mb-0">
                                          Share your project
                                        </p>
                                        {/* {selectedProject?.assignedTo?.length >
                                      0 ? (
                                        <p className="mb-0">
                                          Shared with{" "}
                                          {selectedProject?.assignedTo?.length}{" "}
                                          member
                                          {selectedProject?.assignedTo?.length >
                                          1
                                            ? "s"
                                            : ""}
                                        </p>
                                      ) : (
                                        <p className="mb-0">Not yet shared</p>
                                      )} */}
                                        {selectedProject?.assignedToCategory ||
                                        selectedProject?.assignedToAll ? (
                                          <p className="mb-0">
                                            Shared with{" "}
                                            {selectedProject?.assignedToAll ? (
                                              <b>All</b>
                                            ) : (
                                              <b>
                                                {getAttendeeCategoryName(
                                                  selectedProject?.assignedToCategory
                                                )}
                                              </b>
                                            )}{" "}
                                            attendee(s)
                                          </p>
                                        ) : (
                                          <p className="mb-0">Not yet shared</p>
                                        )}
                                      </div>
                                    </Modal.Header>
                                    <Modal.Body className="text-dark">
                                      {/* {selectedProject?.assignedTo?.length >
                                      0 && (
                                      <div className="mb-3">
                                        <p className="mb-2 text-uppercase small">
                                          Already shared with:
                                        </p>
                                        {selectedProject?.assignedTo?.map(
                                          (item, idx) => (
                                            <UserDetails
                                              uid={item}
                                              idx={idx}
                                              project_id={graph.id}
                                            />
                                          )
                                        )}
                                      </div>
                                    )} */}

                                      <p className="mb-1">
                                        Select Group to share this project
                                      </p>
                                      <Select
                                        placeholder="Participant Group"
                                        options={attendeeCategoryList?.map(
                                          (item) => {
                                            return {
                                              label: `${item.name}`,
                                              value: `${item.id}`,
                                            };
                                          }
                                        )}
                                        onChange={(e) =>
                                          setSelectedAttendeeCategory(e.value)
                                        }
                                        className="w-100 mb-2 text-dark"
                                      ></Select>

                                      {/* <Select
                                      isMulti
                                      placeholder="Select people"
                                      className="w-100 mb-2"
                                      options={allUsers?.filter(
                                        (item) =>
                                          !selectedProject?.assignedTo?.includes(
                                            item.uid
                                          )
                                      )}
                                      onChange={(e) =>
                                        setSelectedUsers(
                                          e?.map((item) => item.uid)
                                        )
                                      }
                                    /> */}

                                      <Button
                                        className="w-100 mb-2"
                                        onClick={() =>
                                          handleSelectedShare(graph.id)
                                        }
                                        disabled={
                                          selectedProject?.assignedToCategory ===
                                          selectedAttendeeCategory
                                        }
                                      >
                                        Share
                                      </Button>
                                      {selectedProject?.assignedToCategory && (
                                        <Button
                                          variant="none"
                                          className="w-100 text-danger shadow-sm border-danger"
                                          onClick={(e) => {
                                            setShowRevokeModal(true);
                                          }}
                                        >
                                          Revoke Access from{" "}
                                          <b>
                                            {" "}
                                            {getAttendeeCategoryName(
                                              selectedProject?.assignedToCategory
                                            )}
                                          </b>
                                        </Button>
                                      )}
                                      {selectedProject?.assignedToCategory && (
                                        <p className="small mb-0 text-muted">
                                          "{selectedProject?.title}" is already
                                          being shared with{" "}
                                          <b>
                                            {getAttendeeCategoryName(
                                              selectedProject?.assignedToCategory
                                            )}
                                          </b>
                                        </p>
                                      )}

                                      <p className="my-2 text-center">OR</p>

                                      {!selectedProject?.assignedToAll && (
                                        <Button
                                          className="w-100 mb-2"
                                          onClick={() =>
                                            shareToAllHandler(graph.id)
                                          }
                                        >
                                          Share with Everyone
                                        </Button>
                                      )}
                                      {selectedProject?.assignedToAll && (
                                        <Button
                                          variant="none"
                                          className="w-100 text-danger shadow-sm border-danger"
                                          onClick={(e) => {
                                            revokeAccessFromAll(graph.id);
                                          }}
                                        >
                                          Revoke Access from <b>all</b>
                                        </Button>
                                      )}

                                      <Modal
                                        centered
                                        show={showRevokeModal}
                                        onHide={() => setShowRevokeModal(false)}
                                      >
                                        <Modal.Header
                                          className={
                                            darkTheme
                                              ? "bg-dark border-secondary pb-1"
                                              : "bg-white border-light pb-1"
                                          }
                                          closeButton
                                        >
                                          <Modal.Title>
                                            Revoke Access
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                          className={
                                            darkTheme
                                              ? "bg-dark border-secondary pb-1"
                                              : "bg-white border-light pb-1"
                                          }
                                        >
                                          Are you sure you want to Revoke
                                          Access?
                                        </Modal.Body>
                                        <Modal.Footer
                                          className={
                                            darkTheme
                                              ? "bg-dark border-secondary pb-1"
                                              : "bg-white border-light pb-1"
                                          }
                                        >
                                          <Button
                                            variant="secondary"
                                            onClick={() =>
                                              setShowRevokeModal(false)
                                            }
                                          >
                                            Close
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={(e) =>
                                              revokeSharingAccess(
                                                selectedProject.id
                                              )
                                            }
                                          >
                                            Revoke Access
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                              </>
                            )}
                            {graph.createdBy === user.uid && (
                              <>
                                <div className="pt-2">
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setSelectedProject(graph);
                                    }}
                                    className="text-uppercase font-weight-bold"
                                  >
                                    <FontAwesomeIcon icon={faTrash} size="sm" />{" "}
                                    <small>Delete</small>
                                  </Button>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            className="pointer"
                            onClick={() => {
                              setGraphId(graph.id);
                              history.push(
                                `/event/${id}/bboa/project/${graph.id}`
                              );
                            }}
                          >
                            <Template
                              is_created={true}
                              title="Visual Awakening"
                            />

                            <Template
                              is_created={graph.three_tiers}
                              title="Noncustomers"
                            />

                            <Template
                              is_created={graph.buyerExpAudit_created}
                              title="Exploration"
                            />

                            <Template
                              is_created={graph.six_path}
                              title="Six Path"
                            />

                            <Template
                              is_created={graph.errc_created}
                              title="Blue ocean matrix"
                            />

                            <Template
                              is_created={graph.to_be_created}
                              title="To-Be Strategy"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>

              {/* Modal: Add project */}
              <Modal
                centered
                show={show}
                onHide={() => {
                  setShow(false);
                  setEditProjectDetails(null);
                  setProjectName("");
                  setIndustry("");
                }}
              >
                <Modal.Header
                  className={
                    darkTheme ? "bg-dark border-secondary" : "bg-white"
                  }
                  closeButton
                >
                  <div>
                    <Modal.Title>Add</Modal.Title>
                    <p className="mb-0 small text-muted">
                      Marked fields (<span className="text-danger">*</span>) are
                      mandatory.
                    </p>
                  </div>
                </Modal.Header>
                <Modal.Body
                  className={
                    darkTheme
                      ? "bg-dark border-secondary"
                      : "bg-white border-light"
                  }
                >
                  <Form onSubmit={addProjectHandler}>
                    <Form.Group className="mb-3">
                      <Form.Label className="small text-muted font-weight-bold text-uppercase">
                        Team Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Team name"
                        autoComplete="off"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="small text-muted font-weight-bold text-uppercase">
                        Industry perimeter{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Industry perimeter"
                        autoComplete="off"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="small text-muted font-weight-bold text-uppercase">
                        Select Group to share this project
                      </Form.Label>
                      <Select
                        placeholder="Participant Group"
                        options={attendeeCategoryList?.map((item) => {
                          return {
                            label: `${item.name}`,
                            value: `${item.id}`,
                          };
                        })}
                        onChange={(e) => setSelectedAttendeeCategory(e.value)}
                        className="w-100 mb-2 text-dark"
                      ></Select>
                    </Form.Group>

                    <div className="d-flex">
                      <Button
                        size="sm"
                        variant="primary"
                        type="submit"
                        className="text-uppercase mr-2"
                      >
                        Submit
                      </Button>
                      <Button
                        size="sm"
                        variant="light"
                        className="text-uppercase shadow-sm"
                        onClick={() => {
                          setShow(false);
                          setEditProjectDetails(null);
                          setProjectName("");
                          setIndustry("");
                        }}
                      >
                        Discard
                      </Button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>

              {/* Modal: Edit project */}
              <Modal
                centered
                show={editProjectName}
                onHide={() => {
                  setEditProjectName(false);
                  setEditProjectDetails(null);
                  setProjectName("");
                  setIndustry("");
                }}
              >
                <Modal.Header
                  closeButton
                  className={
                    darkTheme
                      ? "bg-dark border-secondary pb-1"
                      : "bg-white border-light pb-1"
                  }
                >
                  <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body
                  className={
                    darkTheme
                      ? "bg-dark border-secondary"
                      : "bg-white border-light"
                  }
                >
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="small text-muted font-weight-bold text-uppercase">
                        Team Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Team name"
                        autoComplete="off"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="small text-muted font-weight-bold text-uppercase">
                        Industry perimeter
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Industry perimeter"
                        autoComplete="off"
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      />
                    </Form.Group>
                  </Form>

                  <div className="d-flex">
                    <Button
                      size="sm"
                      className="text-uppercase mr-2"
                      variant="primary"
                      onClick={() =>
                        editProjectNameHandler(editProjectDetails?.id)
                      }
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      className="text-uppercase shadow-sm"
                      variant="light"
                      onClick={() => {
                        setEditProjectName(false);
                        setEditProjectDetails(null);
                        setProjectName("");
                        setIndustry("");
                      }}
                    >
                      Discard
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>

              {/* Modal: Delete project */}
              <Modal
                centered
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header
                  data-theme={darkTheme ? "dark" : "light"}
                  className={darkTheme ? "border-dark" : ""}
                  closeButton
                >
                  <Modal.Title>Delete Project</Modal.Title>
                </Modal.Header>
                <Modal.Body data-theme={darkTheme ? "dark" : "light"}>
                  Are you sure you want to delete this project?
                </Modal.Body>
                <Modal.Footer
                  data-theme={darkTheme ? "dark" : "light"}
                  className={darkTheme ? "border-dark" : ""}
                >
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      setShowDeleteModal(false);
                      setSelectedProject(null);
                    }}
                    className="text-uppercase"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => deleteProjectHandler()}
                    className="text-uppercase"
                  >
                    <FontAwesomeIcon icon={faTrash} /> <span>Delete</span>
                  </Button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block  "
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
          {/* Chat Mobile view */}
          {showChat && (
            <div
              className="shadow-lg border border-muted"
              style={{
                height: "55vh",
                position: "fixed",
                bottom: "17vh",
                left: 10,
              }}
            >
              <EventChat event_id={currentEvent?.id} />
            </div>
          )}
          <div
            style={{
              position: "fixed",
              height: 45,
              width: 45,
              bottom: "10.5vh",
              left: 5,
            }}
            className="bg-primary rounded-circle d-block d-md-none"
          >
            <a
              className="d-flex align-items-center justify-content-center"
              onClick={() => setShowChat((prev) => !prev)}
              style={{
                height: 45,
                width: 45,
              }}
            >
              <FontAwesomeIcon
                className="text-white"
                icon={showChat ? faTimes : faComment}
                style={{ fontSize: 20 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    workspaceId: state.workspaceId,
    roomId: state.roomId,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  { setGraphId, setProducts }
)(ProjectList);
