import React, { useEffect, useState, useMemo } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Spinner,
  FormCheck,
  Badge,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faComments,
  faStore,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import EventManagerNavbar from "./EventManagerNavbar";
import EventNavbar from "./Navbar";
import EventManagerLeftbar from "./EventManagerLeftbar";

import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import EventDetails from "./EventDetails";
import BriefStats from "./BriefStats";
import { connect } from "react-redux";

const OverviewCourseManager = (props) => {
  const [attendeesSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("/courses/hdCKh1GocgVH6v2zLaGn/users")
      .orderBy("first_name")
  );

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("courses").doc(props.courseId)
  );

  const chatOn = eventSnapshot?.data()?.chat_enabled ?? false;

  const peopleEnabled = eventSnapshot?.data()?.people_enabled ?? false;
  useEffect(() => {
    if (!loadingEvent) {
      firebase.firestore().collection("courses").doc(props.courseId).update({
        chat_enabled: chatOn,
      });
    }
  }, [loadingEvent]);

  const courseData = useMemo(() => {
    return {
      ...eventSnapshot?.data(),
    };
  }, [eventSnapshot]);

  //   useEffect(() => {
  //     if (!loadingEvent && eventSnapshot.data().chat_enabled) {
  //       setChatOn(true);
  //     } else {
  //       setChatOn(false);
  //     }
  //   }, [loadingEvent]);
  const options = [
    { value: "development", label: "Development" },
    { value: "business", label: "Business" },
    { value: "finandacc", label: "Finance & Accounting" },
    { value: "itandsoft", label: "IT & Software" },
    { value: "design", label: "Design" },
    { value: "marketing", label: "Marketing" },
  ];

  const getCategoryHandler = (value) => {
    let label = "";
    let index = 0;
    options.forEach((option, i) => {
      if (option.value === value) {
        label = option.label;
        index = i;
      }
    });
    return {
      index,
      label,
    };
  };
  const [ingEventDetails, setingEventDetails] = useState(false);
  if (loadingEvent) {
    return <></>;
  }

  return (
    <>
      {!ingEventDetails ? (
        <div
          className="container-fluid h-100 py-4 text-light"
          style={{ overflow: "hidden" }}
        >
          <div className="col-md-12 h-100 mt-3" style={{ overflow: "auto" }}>
            <h4>Welcome to igesia</h4>
            <h5 className="mb-4">Your upcoming courses</h5>
            <div className="mt-3">
              <Card
                style={{ width: "100%" }}
                className="shadow-sm bg-dark shadow-sm"
              >
                <Card.Body>
                  <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                    <div className="d-flex">
                      <img
                        alt="thumbnail"
                        width="110px"
                        src={courseData?.course_details?.course_image}
                        className="img-fluid rounded d-block d-md-inline mr-3"
                      ></img>
                      <div className="d-flex flex-column align-items-start">
                        <p className="mb-1">
                          {" "}
                          {courseData?.course_details?.EN?.course_title}
                        </p>
                        {courseData?.course_details &&
                        courseData?.course_details?.course_category ? (
                          <Badge
                            title={
                              getCategoryHandler(
                                courseData?.course_details?.course_category
                              )["label"]
                            }
                            variant="secondary"
                            className="user-select-none mb-3"
                          >
                            {
                              getCategoryHandler(
                                courseData?.course_details?.course_category
                              )["label"]
                            }
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <Button
                        className="btn-sm btn-primary mr-2"
                        onClick={() => setingEventDetails(true)}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faVideo}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          EVENT STATUS
                        </span>
                        <span>Live</span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          DATE
                        </span>
                        {/* <span>May 11 7:30 AM {"to"} May 11 6:00 PM CET</span> */}
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faTicketAlt}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          TICKET PRICE
                        </span>
                        <span>Free, €1250</span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          ATTENDEE
                        </span>
                        <span>
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            attendeesSnapshot.docs &&
                            attendeesSnapshot.docs.length
                          )}{" "}
                          registrations
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faComments}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          CHAT
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch"
                            label={chatOn ? "On" : "Off"}
                            checked={chatOn}
                            onChange={(e) =>
                              firebase
                                .firestore()
                                .collection("courses")
                                .doc("hdCKh1GocgVH6v2zLaGn")
                                .update({
                                  chat_enabled: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faUsers}
                        className="mx-4 text-black-333 btn-hero"
                      />
                      <div className="d-flex flex-column">
                        <span className="text-secondary small font-weight-bold">
                          PEOPLE NETWORKING
                        </span>
                        <span>
                          <FormCheck
                            type="switch"
                            id="custom-switch-people"
                            label={peopleEnabled ? "On" : "Off"}
                            checked={peopleEnabled}
                            onChange={(e) =>
                              firebase
                                .firestore()
                                .collection("courses")
                                .doc("hdCKh1GocgVH6v2zLaGn")
                                .update({
                                  people_enabled: e.target.checked,
                                })
                            }
                          ></FormCheck>
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <BriefStats />
          </div>
        </div>
      ) : (
        <div className="mt-3 ml-3">
          <Button onClick={() => setingEventDetails(false)} className="btn-sm">
            Back to event manager
          </Button>
          <EventDetails />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  courseId: state.courseId,
  courseDetails: state.courseDetails,
});
export default connect(mapStateToProps)(OverviewCourseManager);
