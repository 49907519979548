import React from "react";

import Template1 from "./Template1";
import Template2 from "./Template2";

export default function ProfileTemplate(props) {
  const components = {
    Template1: Template1,
    Template2: Template2,
  };

  const Template = components[props?.userDetails?.template ?? "Template1"];

  return <Template {...props} />;
}
