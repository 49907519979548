import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { Form, Dropdown, Button, Spinner } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import produce from "immer";
import { connect } from "react-redux";
import { useDocument } from "react-firebase-hooks/firestore";
import DatePicker from "react-date-picker";
import moment from "moment";
toast.configure();

const EventDetails = (props) => {
  const [eventDetailsSnapshot, loading, error] = useDocument(
    firebase.firestore().doc(`/events/${props.eventId}/`)
  );

  const { currentLanguage } = props;

  const { eventid } = props;
  const history = useHistory();
  const userDetails = firebase.auth().currentUser;
  const [eventDetails, setEventDetails] = useState({});

  useEffect(() => {
    setEventDetails(() => {
      if (
        !loading &&
        eventDetailsSnapshot.exists &&
        eventDetailsSnapshot.data().event_details
      ) {
        setEventDetails((prev) => {
          const newObj = { ...eventDetailsSnapshot.data().event_details };
          return newObj;
        });
      }
    });
  }, [loading]);

  const [firebaseID, setFirebaseID] = useState(null);

  const [fileSizeError, setFileSizeError] = useState(false);

  const [saving, setSaving] = useState(false);

  const saveTextDataToStateHandler = (e, lang) => {
    setEventDetails((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [e.target.name]: e.target.value,
        };
      });
      return updatedState;
    });
  };

  const saveHTMLDataToStateHandler = (name, data, lang) => {
    setEventDetails((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [name]: data,
        };
      });
      return updatedState;
    });
  };

  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        userDetails?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const saveEventDataToDBHandler = async (e, eventData) => {
    e.preventDefault();
    const feature_image_url = eventData.featured_image
      ? await uploadFile(eventData.featured_image)
      : null;

    const event_data = {
      ...eventData,
      featured_image: feature_image_url,
    };
    try {
      setSaving(true);
      await firebase
        .firestore()
        .collection(`/events/`)
        .doc(props.eventId)
        .update({
          event_details: {
            ...event_data,
          },
        });
      toast.success("You have successfully updated event details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in saving to db");
      toast.error("Error in updatingevent details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };
  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const featured_image_url = eventDetails?.featured_image
    ? getWhetherURLOrLocalFile(eventDetails?.featured_image)
    : null;

  const convertSecondstoMilliSeconds = (seconds) => {
    return Math.floor(seconds * 1000);
  };

  const [startDateCalendar, setStartDateCalendar] = useState(false);
  const [endDateCalendar, setEndDateCalendar] = useState(false);

  if (loading) return <></>;

  return (
    <>
      <div className="row w-100 h-100 text-light my-3">
        <div
          className="col-md-7 text-light"
          // style={{ maxHeight: "85vh", overflow: "auto" }}
        >
          <div className="text-light">
            <h5 className="small">PREVIEW</h5>

            {eventDetails && eventDetails?.featured_image && (
              <img
                src={featured_image_url}
                className="w-100 mb-3 rounded-12"
                alt="..."
              />
            )}

            {eventDetails && eventDetails[currentLanguage]?.event_name && (
              <h4 className="mb-3 text-s-2-bold text-white">
                {eventDetails[currentLanguage]?.event_name
                  ? eventDetails[currentLanguage]?.event_name
                  : "Event name"}
              </h4>
            )}

            {eventDetails &&
              eventDetails[currentLanguage]?.small_description && (
                <p className="text-white">
                  {parse(eventDetails[currentLanguage]?.small_description)}
                </p>
              )}

            <div className="text-white rounded-12 mb-3">
              {eventDetails && eventDetails[currentLanguage]?.description && (
                <p className="small">
                  {parse(eventDetails[currentLanguage]?.description)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-5 text-light p-2 pb-0 bg-dark rounded"
          // style={{ maxHeight: "85vh", overflow: "auto" }}
        >
          <Form
            className="text-light"
            onSubmit={(e) => saveEventDataToDBHandler(e, eventDetails)}
          >
            <Form.Group className>
              <Form.Label>
                Name
                <p className="small text-secondary mb-0">
                  Company name/ Brand name
                </p>
              </Form.Label>
              <Form.Control
                maxLength="200"
                type="text"
                className="mb-3"
                placeholder="Name"
                value={eventDetails?.EN?.event_name}
                name="event_name"
                onChange={(event) => {
                  saveTextDataToStateHandler(event, "EN");
                }}
              />
            </Form.Group>
            {/*<Form.Group className>
              <Form.Label>
                Nom de l'événement
                <p className="small text-secondary mb-0">
                  Nom de l'entreprise / nom de marque
                </p>
              </Form.Label>
              <Form.Control
                maxLength="200"
                type="text"
                className="mb-3"
                placeholder="Nom de l'événement"
                value={eventDetails?.FR?.event_name}
                name="event_name"
                onChange={(event) => {
                  saveTextDataToStateHandler(event, "FR");
                }}
              />
            </Form.Group>*/}
            <Form.Group>
              <Form.Label>
                Small Description
                <p className="small text-secondary mb-0">
                  Recommdended: Min. 50 words (Max. 400 characters)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3"
                data={eventDetails?.EN?.small_description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  saveHTMLDataToStateHandler("small_description", data, "EN");
                }}
                style={{ height: 200 }}
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>
                Petite description
                <p className="small text-secondary mb-0">
                  Recommandé: Min. 50 mots (max.400 caractères)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3"
                data={eventDetails?.FR?.small_description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  saveHTMLDataToStateHandler("small_description", data, "FR");
                }}
                style={{ height: 400 }}
              />
            </Form.Group>*/}
            <Form.Group>
              <Form.Label>
                Description
                <p className="small text-secondary mb-0">
                  Recommdended: Min. 100 words (No Limit)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3"
                data={eventDetails?.EN?.description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  saveHTMLDataToStateHandler("description", data, "EN");
                }}
                style={{ height: 400 }}
              />
            </Form.Group>

            {/* <Form.Group className>
              <Form.Label>Admin Email</Form.Label>
              <Form.Control
                maxLength="100"
                type="text"
                className="mb-3"
                placeholder="Admin Email"
                value={eventDetails?.admin_email}
                name="admin_email"
                onChange={(event) => {
                  setEventDetails((prevDetails) => {
                    return {
                      ...prevDetails,
                      admin_email: event.target.value,
                    };
                  });
                }}
              />
            </Form.Group> */}
            {/*<Form.Group>
              <Form.Label>
                La description
                <p className="small text-secondary mb-0">
                  Recommandé: Min. 100 mots (sans limite)
                </p>
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                className="mb-3"
                data={eventDetails?.FR?.description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  saveHTMLDataToStateHandler("description", data, "FR");
                }}
                style={{ height: 400 }}
              />
            </Form.Group>*/}
            <Form.Group className="col-md-12">
              <Form.Label>
                Featured Image
                <p className="small text-secondary mb-0">
                  Recommdended: 1200px X 628px
                </p>
              </Form.Label>
              <Form.Control
                type="file"
                className="mb-3"
                accept="image/*"
                placeholder="Featured Image"
                name="featured_image"
                onChange={(event) => {
                  setEventDetails((prevDetails) => {
                    return {
                      ...prevDetails,
                      featured_image: event.target.files[0],
                    };
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-md-12 ">
              <Form.Label>Start Date</Form.Label>
              <br />
              {eventDetails?.start_date && !startDateCalendar ? (
                <a
                  className="border border-secondary rounded p-2 text-white pointer"
                  onClick={() => setStartDateCalendar(true)}
                >
                  {moment(
                    convertSecondstoMilliSeconds(
                      eventDetails?.start_date?.seconds
                    )
                  ).format("MMMM DD, YYYY")}
                </a>
              ) : (
                <DatePicker
                  calendarClassName="mb-3 d-block bg-secondary"
                  className="bg-white w-100"
                  isOpen={startDateCalendar}
                  onCalendarOpen={() => setStartDateCalendar(true)}
                  onCalendarClose={() => setStartDateCalendar(false)}
                  format="dd-M-y"
                  placeholder="Select Event Date"
                  value={
                    eventDetails?.start_date?.seconds
                      ? new Date(
                          convertSecondstoMilliSeconds(
                            eventDetails?.start_date?.seconds
                          )
                        )
                      : eventDetails?.start_date
                  }
                  onChange={(date) => {
                    setEventDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        start_date: date,
                      };
                    });
                  }}
                />
              )}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>End Date</Form.Label>
              <br />
              {eventDetails?.end_date && !endDateCalendar ? (
                <a
                  className="border border-secondary rounded p-2 text-white pointer"
                  onClick={() => setEndDateCalendar(true)}
                >
                  {moment(
                    convertSecondstoMilliSeconds(
                      eventDetails?.end_date?.seconds
                    )
                  ).format("MMMM DD, YYYY")}
                </a>
              ) : (
                <DatePicker
                  calendarClassName="mb-3 d-block bg-secondary"
                  className="bg-white w-100"
                  isOpen={endDateCalendar}
                  onCalendarOpen={() => setEndDateCalendar(true)}
                  onCalendarClose={() => setEndDateCalendar(false)}
                  format="dd-M-y"
                  placeholder="Select Event Ending Date"
                  value={
                    eventDetails?.end_date?.seconds
                      ? new Date(
                          convertSecondstoMilliSeconds(
                            eventDetails?.end_date?.seconds
                          )
                        )
                      : eventDetails?.end_date
                  }
                  onChange={(date) => {
                    setEventDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        end_date: date,
                      };
                    });
                  }}
                />
              )}
            </Form.Group>
            <div className="">
              <Button className="form-control" variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  eventId: state.eventId,
}))(EventDetails);
