import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import Avatar from "react-avatar";
import firebase from "firebase";
import { useHistory } from "react-router-dom";

import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import * as ProjectStyle from "../project.module.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

function NavBar(props) {
  const userDetails = firebase.auth().currentUser;

  const history = useHistory();

  const { changeLanguage, currentLanguage } = props;

  return (
    <div
      className="row px-3 d-flex justify-content-between bg-graphite text-white"
      style={{ height: "7vh" }}
    >
      <div className="d-flex align-items-center">
        <Image
          className={`rounded ${ProjectStyle.project_cover_logo}`}
          src={process.env.PUBLIC_URL + "/assets/Igesia-Logo.svg"}
        />
      </div>
      <div className="d-flex align-items-center">
        <Dropdown className="mr-3">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "Language" : "Langue"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {`English`}
            </Dropdown.Item>
            <Dropdown.Item
              active={currentLanguage === "FR"}
              onClick={() => changeLanguage("FR")}
            >
              {`French`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="mr-4 pointer">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Avatar name={`Ak`} round={true} size="25" textSizeRatio={1.75} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={async () => {
                history.push("/event/blueoceanawards/updateprofile");
              }}
            >
              {`Profile`}
            </Dropdown.Item>
            {/* {snapshot?.docs && snapshot?.docs.length ? (
              <Dropdown.Item
                onClick={() => {
                  history.push("/event/blueoceanawards/managebooth");
                }}
              >
                {`Manage Booth`}
              </Dropdown.Item>
            ) : null} */}
            <Dropdown.Item
              onClick={async () => {
                await firebase.auth().signOut();
                window.location.reload();
                toast.success("Sign Out", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }}
            >
              {`Sign Out`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  { changeLanguage }
)(NavBar);
