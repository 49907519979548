import React from "react";
import "./GridViewBook.css";
import BookLogo from "../BookLogo/BookLogo";
import Swal from "sweetalert2";
import firebase from "firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faEdit,
  faEllipsisH,
  faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const GridViewBook = ({
  Notebook,
  setSelectedNotebook,
  setNotebookEdit,
  currentUser,
}) => {
  const DeleteNoteBook = (id) => {
    Swal.fire({
      title: "Delete Notebook?",
      showDenyButton: true,
      confirmButtonText: `Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        firebase
          .firestore()
          .collection("notesApp")
          .doc(currentUser.uid)
          .collection("notes")
          .where("notebook", "==", id)
          .get()
          .then((doc) => {
            if (!doc.empty) {
              doc.docs.forEach((note) => {
                firebase
                  .firestore()
                  .collection("notesApp")
                  .doc(currentUser.uid)
                  .collection("notes")
                  .doc(note.id)
                  .delete();
              });
            }
          })
          .then(() => {
            firebase
              .firestore()
              .collection("notesApp")
              .doc(currentUser.uid)
              .collection("notebooks")
              .doc(id)
              .delete();
          });
      } else if (result.isDenied) {
      }
    });
  };

  const HandleArchiveNoteBook = (ArchiveNotebook) => {
    firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notebooks")
      .doc(ArchiveNotebook.id)
      .update({
        is_archived: !ArchiveNotebook.is_archived,
      });
  };

  const HandlePinNotebook = (PinNotebook) => {
    firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notebooks")
      .doc(PinNotebook.id)
      .update({
        is_pinned: !PinNotebook.is_pinned,
        pinned_at: Date.now(),
      });
  };

  return (
    <div className="GridViewBook">
      <span
        className={`GridViewBook-pin ${
          Notebook.is_pinned ? "text-yellow" : "text-white"
        }`}
        onClick={() => HandlePinNotebook(Notebook)}
      >
        <FontAwesomeIcon icon={faThumbtack} />
      </span>
      <span
        className="GridViewBook-action px-1"
        id="NotebookAction"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {" "}
        <FontAwesomeIcon icon={faEllipsisH} className="font-14 mt-2" />
      </span>
      <div
        className="dropdown-menu  dropdown-menu-right"
        style={{ filter: "invert(0.85)" }}
        aria-labelledby="NotebookAction"
      >
        <span
          className="dropdown-item cp font-13"
          onClick={() => setNotebookEdit(Notebook)}
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit
        </span>
        <span
          className="dropdown-item cp font-13"
          onClick={() => HandleArchiveNoteBook(Notebook)}
        >
          <FontAwesomeIcon icon={faArchive} className="mr-2" />
          {Notebook.is_archived ? "Unarchive" : "Archive"}
        </span>
        <span
          className="dropdown-item cp font-13"
          onClick={() => DeleteNoteBook(Notebook.id)}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          Delete
        </span>
      </div>

      <div
        className="cp"
        style={{ display: "contents" }}
        onClick={() => setSelectedNotebook(Notebook)}
      >
        <BookLogo bgColor={Notebook.bg_color} />
        <span className="GridViewBook-Text">
          {Notebook.name.length > 15
            ? Notebook.name.slice(0, 15) + "..."
            : Notebook.name}
        </span>
      </div>
    </div>
  );
};

export default GridViewBook;
