import React, { useRef, useState, useEffect } from "react";
import {
    Card,
    Button,
    Modal,
    Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faFile } from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import Select from 'react-select'

function Documents({ project }) {

    const [addDocumentModal, setAddDocumentModal] = useState(false);

    const pageRef = useRef(null);
    useEffect(() => {
        pageRef && pageRef.current.scrollIntoView();
    }, []);
    const notifications = [
        {
            message: "Instructor shared a Document LectureNote101.pdf",
        },
        {
            message: "Instructor shared a Document LectureNote54.ppt",
        },
        {
            message: "Instructor shared a Live Session",
        },
        {
            message: "Instructor updated the course content",
        },
    ];

    const members = [
        { value: 'all', label: 'All' },
        { value: 'lim', label: 'Lim Yong kang' },
        { value: 'hayat', label: 'S Hayat' },
        { value: 'pawar', label: 'Siddhesh Pawar' },
        { value: 'richard', label: 'Richard Moon' },
        { value: 'sathvik', label: 'Sathvik Saya' }
      ]

    return (
        <>
            <div className=" bg-graphite " ref={pageRef}>
                <div className="">
                    <h2 className="font-weight-bold text-white">Documents</h2>
                    <div className="mt-3 mt-md-4">
                        {/* Upcoming Live Sessions */}
                        <Button
                        className="btn-sm btn-primary my-2"
                        onClick={() => setAddDocumentModal(true)}
                      >
                        Add
                      </Button>
                        <div
                            className="mb-3 mb-md-5"
                        >
                            {notifications.map((item) => {
                                return (
                                    <Card className="bg-dark text-white shadow-sm mb-1">
                                        <Card.Body className="p-0">
                                            <a
                                                className="d-flex align-items-center text-white p-2"
                                                style={{ cursor: "pointer" }}
                                                href={
                                                    "http://www.africau.edu/images/default/sample.pdf"
                                                }
                                                target="_blank"
                                            >
                                                <div className="bg-warning p-2 rounded">
                                                    <FontAwesomeIcon icon={faFile} size="lg" />
                                                </div>
                                                <div className="ml-2">
                                                    <p className="font-weight-bold mb-0">
                                                        {item.message}
                                                    </p>
                                                </div>
                                            </a>
                                        </Card.Body>
                                    </Card>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
<><Modal 
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addDocumentModal}
        onHide={() => {
            setAddDocumentModal(false)
          
        }}>
            <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"Creating Document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
              <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                  />
              </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder="Description"
                name="stage_description"
                // value={stageDetails.stage_description}
                // onChange={(e) => saveTextDataToStateHandler(e)}
              />
            </Form.Group>
            
            <Form.Group>
              <Form.Label>Document type</Form.Label>
              <Form.Control
                as="select"
                placeholder="Type"
                name="stage_type"
                // value={stageDetails.stage_type}
                // onChange={(e) => {
                //   saveTextDataToStateHandler(e);
                // }}
                // onKeyUp={(e) => validationHandler(e, stageDetails)}
                // onBlur={(e) => validationHandler(e, stageDetails)}
              >
                <option selected disabled>
                Type
                </option>
                <option value="poll">Poll</option>
                <option value="quiz">Quiz</option>
                <option value="assignment">Assignment</option>
              </Form.Control>
              
            </Form.Group>
            <Form.Group>
                <Form.Label>Assigned To</Form.Label>
                <Select
                    isMulti
                    name="participants"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Participants"
                    options={members}
                />
            </Form.Group>
           
            <Button>Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
        </>
    );
}

export default connect(
    (state) => ({
        currentLanguage: state.currentLanguage,
        project: state.project
    }),
    { changeLanguage }
)(Documents);
