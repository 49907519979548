import React, { useRef, useState, useEffect } from "react";
import { Card, InputGroup, Form, Modal, Button } from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faPodcast,
  faTimes,
  faUser,
  faSearch,
  faUserCheck,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";

import LeftBar from "./LeftBar";
import NavBar from "./NavBar";
import Avatar from "react-avatar";
import Chat from "./Chat";
import CourseRedux from "../CourseRedux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function People({ currentLanguage, changeLanguage, course }) {
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const pageRef = useRef(null);
  useEffect(() => {
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  const [peoples, setPeoples] = useState(course?.peopleList ? [...course?.peopleList] : []);
  useEffect(() => {
    course?.peopleList && setPeoples([...course?.peopleList])
  }, [course])

  const handleFollow = (id) => {
    const newList = peoples.map((item) => {
      if (item.id == id) {
        if (item.following) {
          toast.dark(`Unfollowed ${item.name}`, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            autoClose: 3000,
          });
        } else {
          toast.info(`Following ${item.name}`, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            autoClose: 3000,
          });
        }
        return {
          ...item,
          following: !item.following,
        };
      } else {
        return item;
      }
    });

    setPeoples(newList);
  };

  const MemberListElement = ({ item }) => {
    return (
      <Card className="bg-dark text-white shadow-sm mb-2">
        <Card.Body>
          <div className="d-flex align-items-center">
            <div
              onClick={() => {
                setSelectedProfile(item);
                setShowProfile(true);
              }}
              className="img-fluid rounded-circle shadow-sm border"
              style={{
                height: 50,
                width: 50,
                backgroundImage: `url(${item.avatar})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                cursor: "pointer",
              }}
              title={item.name}
            />

            <div
              className="ml-2 flex-grow-1"
              onClick={() => {
                setSelectedProfile(item);
                setShowProfile(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <p className="mb-0">{item.name}</p>
              <span className="small">{item.email}</span>
            </div>

            <div className="ml-2">
              {item.following ? (
                <Button
                  variant="outline-success"
                  size="sm"
                  className="rounded-pill"
                  title="Already following"
                  onClick={() => handleFollow(item.id)}
                >
                  <span>
                    <FontAwesomeIcon icon={faUserCheck} /> Following
                  </span>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="sm"
                  className="rounded-pill"
                  title="Follow now"
                  onClick={() => handleFollow(item.id)}
                >
                  <span>
                    <FontAwesomeIcon icon={faUserPlus} /> Follow
                  </span>
                </Button>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <CourseRedux />
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className=" bg-graphite ">
                <div className="">
                  <h2 className="font-weight-bold text-white">Participants</h2>

                  <div className="mt-3 mt-md-4 ">
                    <div className="">
                      <InputGroup className="my-2 outerPill">
                        <div className="d-flex align-items-center rounded-lg flex-grow-1  bg-dark">
                          <div className="py-2 pr-2 pl-3">
                            <FontAwesomeIcon
                              icon={faSearch}
                              size="sm"
                              color="#aeaeae"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <Form.Control
                              type="text"
                              id="participantsSearch"
                              className="border-0 px-0 bg-dark text-white rounded"
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                                const reg = _.escapeRegExp(searchTerm);
                                const regex = new RegExp(reg, "gi");
                                setRegex(regex);
                              }}
                              placeholder={"Search"}
                              value={searchTerm}
                              style={{ margin: 0 }}
                            />
                          </div>
                          {searchTerm.length > 0 && (
                            <div
                              className="py-2 pl-2 pr-3"
                              onClick={() => setSearchTerm("")}
                            >
                              <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                            </div>
                          )}
                        </div>
                      </InputGroup>

                      <div className="mb-3 mb-md-5">
                        {searchTerm && searchTerm.length > 0 ? (
                          <>
                            {peoples
                              ?.filter((item) => {
                                return item.name?.match(regex);
                              })
                              .map((item) => {
                                return <MemberListElement item={item} />;
                              })}
                          </>
                        ) : (
                          <>
                            {peoples?.map((item) => {
                              return <MemberListElement item={item} />;
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              centered={true}
              show={showProfile}
              onHide={() => setShowProfile(false)}
              animation={true}
            >
              <Modal.Body className="bg-graphite text-white">
                <div
                  title="Close"
                  className="pointer d-flex justify-content-end mb-0"
                  onClick={() => setShowProfile(false)}
                >
                  <FontAwesomeIcon className="text-white" icon={faTimes} />
                </div>
                <div className="d-flex flex-column align-items-center text-center">
                  <div
                    className="img-fluid rounded-circle shadow-sm"
                    style={{
                      height: 120,
                      width: 120,
                      backgroundImage: `url(${selectedProfile?.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    title={selectedProfile?.name}
                  />
                  <p className="font-weight-bold lead">
                    {selectedProfile?.name}
                  </p>
                  <div className="w-100 d-flex align-items-center justify-content-around">
                    <p className="border-right border-dark w-100 text-lowercase">{`@${
                      selectedProfile?.name?.split(" ")[0]
                    }`}</p>
                    <p className="w-100">{`${selectedProfile?.email}`}</p>
                  </div>
                  <p>{selectedProfile?.country}</p>
                  <div className="row w-100">
                    <div className="col-sm">
                      <FontAwesomeIcon icon={faGraduationCap} />
                      <p>{selectedProfile?.certification}</p>
                    </div>
                    <div className="col-sm">
                      <FontAwesomeIcon icon={faUser} />
                      <p>{selectedProfile?.followers}</p>
                    </div>
                    <div className="col-sm">
                      <FontAwesomeIcon icon={faPodcast} />
                      <p>{selectedProfile?.podcasts}</p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p className="lead font-weight-bold">Specialities</p>
                    <p>{selectedProfile?.specialties.replaceAll(",", " •")}</p>
                  </div>
                  <hr />
                  <div>
                    <p className="lead font-weight-bold">Qualifications</p>
                    <p>
                      {selectedProfile?.qualifications.replaceAll(",", " •")}
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/* Chat module start */}
            <div
              className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
              style={{ overflow: "scroll" }}
            >
              <Chat />
            </div>
            {/* Chat module end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    course: state.course,
  }),
  { changeLanguage }
)(People);
