import React, { useRef, useState, useEffect } from "react";
import { changeLanguage, setShowNoteApp } from "../../store/actions/actions";
import { connect } from "react-redux";

import LeftBar from "../Event/Dashboard/LeftBar";
import NavBar from "../Event/Dashboard/NavBar";
import EventChat from "../Event/Dashboard/EventChat";

import NoteApp from "./NotesApp/NotesApp";

function EventNotes({ setShowNoteApp, darkTheme, currentEvent }) {
  const pageRef = useRef(null);
  useEffect(() => {
    setShowNoteApp(true);
    pageRef && pageRef.current.scrollIntoView();
  }, []);

  return (
    <>
      <div className="h-100" ref={pageRef}>
        <LeftBar mobile />
        <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
          <NavBar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <LeftBar />
            <div
              className="col-md-7 h-100 p-md-5 p-3 bg-graphite"
              style={{ overflow: "scroll" }}
            >
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div
                    className={
                      darkTheme
                        ? "shadow-sm p-3 rounded bg-dark"
                        : "shadow-sm p-3 rounded bg-white"
                    }
                  >
                    <h3>Notes</h3>
                  </div>
                </div>
              </div>

              <div className="mt-3 mt-md-4 text-dark">
                <div className="mb-3 mb-md-5">
                  <NoteApp />
                </div>
              </div>
            </div>
            {/* Chat module */}
            <div
              className="col-md-4 h-100 px-0 d-none d-md-block"
              style={{ overflow: "scroll" }}
            >
              <EventChat
                event_id={currentEvent?.id}
                tabs={
                  currentEvent?.people_enabled
                    ? [
                        {
                          name: "Chat",
                          type: "chat",
                          chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                        },
                        {
                          name: "People",
                          type: "people",
                        },
                      ]
                    : [
                        {
                          name: "Chat",
                          type: "chat",
                          chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                        },
                      ]
                }
              />
            </div>
            {/* Chat module ends */}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  { changeLanguage, setShowNoteApp }
)(EventNotes);
