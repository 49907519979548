import React from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Avatar from "react-avatar";
import { memo } from "react";

const TopUsers = () => {
  return (
    <>
      <Container className="dark-only text-white" fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <Card.Body>
                <div className="best-seller-table responsive-tbl">
                  <div className="item">
                    <div className="table-responsive product-list">
                      <Table borderless className="text-white">
                        <thead>
                          <tr>
                            <th className="f-22">#</th>
                            <th>{"Name"}</th>
                            <th>{"Status"}</th>
                            <th>{"Ticket Sold"}</th>
                            <th>{"Attendees"}</th>
                            <th>{"Networking Request"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>1</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Blue Ocean Award: 7th edition
                            </td>
                            <td style={{ verticalAlign: "middle" }}>223</td>
                            <td style={{ verticalAlign: "middle" }}>232</td>
                            <td style={{ verticalAlign: "middle" }}>13</td>
                            <td style={{ verticalAlign: "middle" }}>20</td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>Random</td>
                            <td style={{ verticalAlign: "middle" }}>210</td>
                            <td style={{ verticalAlign: "middle" }}>4</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>452</td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Blue Ocean
                            </td>
                            <td style={{ verticalAlign: "middle" }}>13</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>3</td>
                            <td style={{ verticalAlign: "middle" }}>358</td>
                          </tr>
                          <tr>
                            <td style={{ verticalAlign: "middle" }}>4</td>
                            <td style={{ verticalAlign: "middle" }}>
                              Red Ocean
                            </td>
                            <td style={{ verticalAlign: "middle" }}>18</td>
                            <td style={{ verticalAlign: "middle" }}>5</td>
                            <td style={{ verticalAlign: "middle" }}>2</td>
                            <td style={{ verticalAlign: "middle" }}>202</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(TopUsers);
