import React, { useEffect, useState } from "react";
import { Col, Container, Row, Nav, Card } from "react-bootstrap";
// import "./igesia-styles.scss";
// import "./event-console.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBook,
  faCalendarAlt,
  faChartPie,
  faClone,
  faComment,
  faComments,
  faFile,
  faHome,
  faKey,
  faLanguage,
  faLock,
  faPaperPlane,
  faPoll,
  faQuestionCircle,
  faReply,
  faStickyNote,
  faStore,
  faTicketAlt,
  faUser,
  faUserGraduate,
  faUsers,
  faUsersCog,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { connect } from "react-redux";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import AskQuestion from "./EventChat/AskQuestion";
import People from "./Networking/People";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EventChat(props) {
  const { currentLanguage, tabs, event_id, darkTheme } = props;

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc(`${event_id}`)
  );
  
  const chatOn = eventSnapshot?.data()?.chat_enabled ?? false;
  const peopleEnabled = eventSnapshot?.data()?.people_enabled ?? false;
  const boothChatEnabled = eventSnapshot?.data()?.booth_chat_enabled ?? false;

  const [currentTab, setCurrentTab] = useState(0);

  const TabView = ({ tabs, currentTab, setCurrentTab, count }) => {
    return (
      <div className="d-flex">
        {tabs.map((tab, key) => {
          if (tab.type !== "people") {
            if (tab.type === "boothchat") {
              return (
                <a
                  key={key}
                  className="col p-2 p-md-3 bg-white d-flex align-items-center justify-content-center"
                  onClick={() => {
                    if (boothChatEnabled) {
                      setCurrentTab(key);
                    }
                  }}
                  style={
                    currentTab === key
                      ? {
                          borderBottom: "2px solid blue",
                          cursor: boothChatEnabled ? "pointer" : "no-drop",
                        }
                      : {
                          borderBottom: "2px solid white",
                          cursor: boothChatEnabled ? "pointer" : "no-drop",
                        }
                  }
                >
                  <p className="text-center m-0 font-weight-bold small">
                    <span
                      className={
                        currentTab === key ? "text-dark" : "text-muted"
                      }
                    >
                      {tab.name} {count && `(${count})`}
                    </span>
                  </p>
                </a>
              );
            } else {
              return (
                <a
                  key={key}
                  className="col p-2 p-md-3 bg-white d-flex align-items-center justify-content-center"
                  onClick={() => setCurrentTab(key)}
                  style={
                    currentTab === key
                      ? { borderBottom: "2px solid blue", cursor: "pointer" }
                      : { borderBottom: "2px solid white", cursor: "pointer" }
                  }
                >
                  <p className="text-center m-0 font-weight-bold small">
                    <span
                      className={
                        currentTab === key ? "text-dark" : "text-muted"
                      }
                    >
                      {tab.name} {count && `(${count})`}
                    </span>
                  </p>
                </a>
              );
            }
          } else {
            return (
              <a
                key={key}
                className="col p-2 p-md-3 bg-white d-flex align-items-center justify-content-center"
                onClick={() => {
                  if (peopleEnabled) {
                    setCurrentTab(key);
                  } else {
                    toast("Networking is disabled", {
                      position: toast.POSITION.TOP_CENTER,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                }}
                style={
                  currentTab === key
                    ? {
                        borderBottom: "2px solid blue",
                        cursor: peopleEnabled ? "pointer" : "no-drop",
                      }
                    : {
                        borderBottom: "2px solid white",
                        cursor: peopleEnabled ? "pointer" : "no-drop",
                      }
                }
              >
                <p className="text-center m-0 font-weight-bold small">
                  <span
                    className={currentTab === key ? "text-dark" : "text-muted"}
                  >
                    {tab.name} {count && `(${count})`}
                  </span>
                </p>
              </a>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      { tabs ? (
        <>{
          tabs.map((tab, key) => {
            if (tab.type === "chat" && currentTab === key)
              return (
                <div
                  key={key}
                  className="col-md-12 h-100 d-flex px-0 flex-column justify-content-between"
                  style={{ overflow: "scroll" }}
                >
                   <AskQuestion
                    chatroute={
                      chatOn ? 
                      tab.chatroute
                        ? tab.chatroute
                        : `/events/${event_id}/questionsAsked/`
                        : null
                    }
                    headerComponent={
                      <TabView
                        tabs={tabs}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    }
                  /> 
                </div>
              );
            if (tab.type === "people" && currentTab === key)
              return (
                <People
                  headerComponent={
                    <TabView
                      tabs={tabs}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  }
                  event_id={event_id}
                />
              );
            if (tab.type === "boothchat" && currentTab === key)
              return (
                <AskQuestion
                  chatroute={tab.chatroute}
                  headerComponent={
                    <TabView
                      tabs={tabs}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />
                  }
                />
              );
          })
        }</>
      ) : (
        <div
          className="col-md-12 h-100 px-0 d-flex flex-column justify-content-between"
          style={{ overflow: "scroll" }}
        >
          <AskQuestion chatroute={`/events/${event_id}/questionsAsked/`} />
        </div>
      )}
    </div>
  );

}
export default connect((state) => ({ currentLanguage: state.currentLanguage, darkTheme: state.darkTheme}))(
  EventChat
);
