import React, { useState, useEffect, useRef } from "react";
import HTMLReactParser from "html-react-parser";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

import {
  API_BASE_URL,
  USER_SUB_MODULE_PROGRESS_UPDATE,
} from "../../../../config";
import { auth } from "../../../../firebase";

const Duration = ({ className, seconds }) => {
  function format(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad(date.getUTCSeconds());
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
  }

  function pad(string) {
    return ("0" + string).slice(-2);
  }

  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
};

export default function RecordedLecture(props) {
  // CONSTANTS
  const {
    fetching_progress,
    current_progress,
    module_id,
    resource_details,
    sub_module_details,
    sub_module_id,
  } = props;

  // REFERENCES
  const playerRef = useRef(null);

  // STATES
  const [initialProgress, setInitialProgress] = useState(0);
  const [progress, setProgress] = useState(null);
  const [progressState, setProgressState] = useState("start");
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [loop, setLoop] = useState(false);
  const [light, setLight] = useState(false);
  const [pip, setPip] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(0.7);

  // FUNCTIONS
  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleEnded = () => {
    setPlaying(loop);
    setProgressState("complete");
    handleUpdateProgress();
  };

  const handlePlayAgain = () => {
    if (playerRef.current) playerRef.current.seekTo(0);
    setPlaying(true);
    setProgressState("start");
  };

  const handlePlayPause = () => {
    setPlaying((prev) => !prev);
    handleUpdateProgress();
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleToggleMute = () => {
    setMuted((prev) => !prev);
  };

  const handleStop = () => {
    if (playerRef.current) playerRef.current.seekTo(0);
    setPlaying(false);
  };

  const handleEnablePIP = () => {
    setPip(true);
  };

  const handleDisablePIP = () => {
    setPip(false);
  };

  const handleSetPlaybackRate = (speed) => {
    setPlaybackRate(parseFloat(speed));
  };

  const handleOnPlaybackRateChange = (speed) => {
    setPlaybackRate(parseFloat(speed));
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value).toFixed(2));
  };

  const handleToggleLoop = () => {
    if (!loop) {
      handlePlayAgain();
    }
    setLoop((prev) => !prev);
  };

  const handleClickFullscreen = () => {
    // function code to be added
  };

  const handleSeek = (seek) => {
    if (playerRef.current) playerRef.current.seekTo(seek);
  };

  const handleSeekChange = (seek) => {
    if (playerRef.current) {
      playerRef.current.seekTo(progress.playedSeconds + seek);
      handleUpdateProgress();
    }
  };

  const handleUpdateProgress = async () => {
    const progressDetails = {
      child_id: sub_module_id,
      parent_id: module_id,
      ancestor_id: sub_module_details?.ancestor_id,
      progress,
      user_id: auth?.currentUser?.uid,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}${USER_SUB_MODULE_PROGRESS_UPDATE}`,
        progressDetails
      );

      if (response.status === 200) {
        const { data } = response.data;
        if (
          data?.progress?.played >= 0.98 &&
          data?.state === "complete" &&
          data?.status
        ) {
          toast.dark(
            <div>
              <p className="mb-0">🎊 Completed 🎊</p>
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: false,
              autoClose: 3000,
            }
          );
        }
      }
    } catch (error) {
      console.log(`[Error] Setting progress: ${error.message}`);
    }
  };

  useEffect(() => {
    if (current_progress || !fetching_progress) {
      if (duration) {
        if (current_progress?.progress?.played) {
          handleSeek(current_progress?.progress?.playedSeconds);
          setInitialProgress(current_progress?.progress?.played * duration);
          setProgressState(
            current_progress?.progress?.played >= 0.98
              ? "complete"
              : "in_progress"
          );
        } else {
          setProgressState("start");
        }
      }
    }
  }, [fetching_progress, duration]);

  useEffect(() => {
    if (playing && duration) {
      // If video progressed by 7 seconds
      if (
        parseFloat(initialProgress + 7).toFixed(2) <
        parseFloat(progress.playedSeconds).toFixed(2)
      ) {
        handleUpdateProgress();
        setInitialProgress(progress.playedSeconds);
      }
    }
  }, [progress]);

  return (
    <div>
      <div className="embed-responsive embed-responsive-16by9">
        <ReactPlayer
          controls
          stopOnUnmount
          width="100%"
          height="auto"
          playing={playing}
          light={light}
          loop={loop}
          muted={muted}
          pip={pip}
          playbackRate={playbackRate}
          volume={volume}
          onDuration={handleDuration}
          onEnded={handleEnded}
          onProgress={setProgress}
          // onReady={(e) => console.log("onReady: ", e)}
          // onStart={(e) => console.log("onStart: ", e)}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnablePIP={handleEnablePIP}
          onDisablePIP={handleDisablePIP}
          onPlaybackRateChange={handleOnPlaybackRateChange}
          onSeek={handleUpdateProgress}
          // onBuffer={(e) => console.log("onBuffer: ", e)}
          config={{
            file: {
              attributes: {
                noDownload: true,
                controlsList: "nodownload noremoteplayback",
              },
            },
          }}
          url={resource_details?.file_url}
          onContextMenu={(e) => e.preventDefault()}
          ref={playerRef}
        />
      </div>

      <div className="mt-2">
        <Button
          disabled={progressState != "complete"}
          onClick={handlePlayAgain}
        >
          <span>
            <FontAwesomeIcon icon={faRedo} className="mr-2" />
            Watch again
          </span>
        </Button>
      </div>
      {/* Video Player Controls */}
      {/* <div className="mt-2">
        <p className="mb-0">Played</p>
        <ProgressBar
          className="w-100 mb-2"
          animated={progress?.played != 1}
          striped={progress?.played != 1}
          now={parseFloat(progress?.played * 100).toFixed(2)}
          label={`${parseFloat(progress?.played * 100).toFixed(2)}%`}
        />

        <p className="mb-0">Loaded</p>
        <ProgressBar
          className="w-100"
          now={parseFloat(progress?.loaded * 100).toFixed(2)}
          label={`${parseFloat(progress?.loaded * 100).toFixed(2)}%`}
        />
      </div> */}

      {/* <div className="d-flex mt-2">
        <p className="mb-0">Duration: </p>
        <Duration seconds={duration} />
      </div>

      <div className="d-flex mt-2">
        <p className="mb-0">Elapsed: </p>
        <Duration seconds={duration * progress?.played} />
      </div>

      <div className="d-flex mt-2">
        <p className="mb-0">Remaining: </p>
        <Duration seconds={duration * (1 - progress?.played)} />
      </div> */}

      {/* <div className="d-flex mt-2">
        <Button disabled={playing} onClick={handlePlayPause} className="mr-2">
          Play
        </Button>

        <Button disabled={!playing} onClick={handlePlayPause} className="mr-2">
          Pause
        </Button>

        <Button onClick={handleStop} className="mr-2">
          Stop
        </Button>

        <Button
          disabled={progressState != "complete"}
          onClick={handlePlayAgain}
        >
          <span>
            <FontAwesomeIcon icon={faRedo} className="mr-2" />
            Watch again
          </span>
        </Button>
      </div> */}

      {/* <div className="d-flex mt-2">
        <Button className="mr-2" onClick={() => handleSeekChange(-10)}>
          -10 sec
        </Button>

        <Button className="mr-2" onClick={() => handleSeekChange(10)}>
          +10 sec
        </Button>

        <Button className="mr-2" onClick={handleToggleMute}>
          {muted ? "Unmute" : "Mute"}
        </Button>
      </div> */}

      {/* {ReactPlayer.canEnablePIP(resource_details?.file_url) && (
        <div className="d-flex mt-2">
          <Button disabled={pip} className="mr-2" onClick={handleEnablePIP}>
            Enable PIP
          </Button>

          <Button disabled={!pip} className="mr-2" onClick={handleDisablePIP}>
            Disable PIP
          </Button>
        </div>
      )} */}

      {/* <div className="d-flex mt-2">
        {[0.5, 0.75, 1, 1.25, 1.5, 2]?.map((e, key) => (
          <Button
            key={key}
            variant={playbackRate === e ? "primary" : "outline-primary"}
            className="mr-2"
            onClick={() => handleSetPlaybackRate(e)}
          >
            {e}x
          </Button>
        ))}
      </div> */}

      {/* <div className="d-flex mt-2">
        <Form.Group>
          <Form.Label>Volume ({parseInt(volume * 100)})</Form.Label>
          <Form.Control
            type="range"
            min={0}
            max={1}
            value={volume}
            step="any"
            onChange={handleVolumeChange}
          />
        </Form.Group>
      </div> */}

      {/* <div className="d-flex mt-2">
        <Button onClick={handleToggleLoop} className="mr-2">
          Looping: Current state "{loop ? "ON" : "OFF"}"
        </Button>

        <Button onClick={handleClickFullscreen}>Fullscreen</Button>
      </div> */}

      {sub_module_details?.description && (
        <div>{HTMLReactParser(sub_module_details?.description)}</div>
      )}
    </div>
  );
}
