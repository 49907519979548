import React, { useRef, useState, useEffect } from "react";
import {
    Navbar,
    Tabs,
    Tab,
    Image,
    Card,
    Button,
    Dropdown,
} from "react-bootstrap";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../store/actions/actions";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";

import * as ConsultationStyle from "../consultation.module.css";
import { languageText } from "../../Event/language.json";
import HTMLReactParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faTooth, faXRay, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import Gallery from "react-grid-gallery";
import { images } from "../images.json";
import LeftBar from "./LeftBar";
import NavBar from "./NavBar";

function Consultation({ currentLanguage, changeLanguage }) {
    const { hash, pathname } = useLocation();
    const history = useHistory();
    const [clinicDoc, loadingClinicDoc, errorClinicDoc] = useDocument(
        firebase.firestore().collection("clinics").doc("EKWsp2FDV2aWp1F6P963")
    );

    return (

        <div className="h-100">
            <LeftBar mobile />
            <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
                <NavBar />
                <div className="row" style={{ height: "95vh", overflow: "hidden" }}>
                    <LeftBar />
                    <div className="col-md-7 h-100 p-5 bg-graphite" style={{ overflow: "scroll" }}>
                        <div className="container-fluid px-0 bg-graphite" >
                            <div className="col-md-12">
                               {/* Services start */}
                                <div className="container mb-5" id="#services">
                                    <h5 className="text-white mb-3 font-weight-bold">
                                        {/* {languageText[`${currentLanguage}`].speakers} */}
                                        Services
                                    </h5>
                                    <div className="d-flex text-white mb-3 bg-dark p-3 rounded shadow-sm">
                                        <div className="mr-3 " style={{ width: "80px" }} >
                                            <FontAwesomeIcon style={{ width: "80px" }} icon={faTooth} size="2x" className="mr-3 mt-1" />
                                        </div>
                                        <div>
                                            <p className="mb-0 font-weight-bold">Dentistry</p>
                                            <p className="mb-0">
                                                A professional with 39 years in Education, Administration, Health Care Delivery & adept at leading programs & initiative involving multi-functional & multi-cultural teams with an unwavering passion for delivering service with excellence
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex text-white mb-3 bg-dark p-3 rounded shadow-sm">
                                        <div className="mr-3 " style={{ width: "80px" }} >
                                            <FontAwesomeIcon style={{ width: "80px" }} icon={faXRay} size="2x" className="mr-3 mt-1" />
                                        </div>
                                        <div>
                                            <p className="mb-0 font-weight-bold">CT Scan &amp; X-Ray</p>
                                            <p className="mb-0">
                                                A professional with 39 years in Education, Administration, Health Care Delivery & adept at leading programs & initiative involving multi-functional & multi-cultural teams with an unwavering passion for delivering service with excellence
                                            </p>
                                        </div>

                                    </div>
                                    <div className="d-flex text-white mb-3 bg-dark p-3 rounded shadow-sm">
                                        <div className="mr-3 " style={{ width: "80px" }} >
                                            <FontAwesomeIcon style={{ width: "80px" }} icon={faBrain} size="2x" className="mr-3 mt-1" />
                                        </div>
                                        <div>
                                            <p className="mb-0 font-weight-bold">Neurological Treatment</p>
                                            <p className="mb-0">
                                                A professional with 39 years in Education, Administration, Health Care Delivery & adept at leading programs & initiative involving multi-functional & multi-cultural teams with an unwavering passion for delivering service with excellence
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Services end */}

                                {/* Publication start */}
                                <div className="container mb-5" id="#services">
                                    <h5 className="text-white mb-3 font-weight-bold">
                                        {/* {languageText[`${currentLanguage}`].speakers} */}
                                        Publications
                                    </h5>
                                    <div className="d-flex" style={{ overflow: "scroll" }}>
                                        <a target="_blank" href="https://www.amazon.com/gp/product/B093FR41BY/">
                                            <img src="https://m.media-amazon.com/images/I/41tviNvueKS._SY346_.jpg" height="200" className="rounded shadow-sm mr-3" />
                                        </a>
                                        <a target="_blank" href="https://www.amazon.com/gp/product/B00I0GI6SY/">
                                            <img src="https://m.media-amazon.com/images/I/41yhuaVxgsL.jpg" height="200" className="rounded shadow-sm mr-3" />
                                        </a>
                                        <a target="_blank" href="https://www.amazon.com/gp/product/B00391L88K/">
                                            <img src="https://m.media-amazon.com/images/I/419kD27SjWL.jpg" height="200" className="rounded shadow-sm mr-3" />
                                        </a>
                                        <a target="_blank" href="https://www.amazon.com/gp/product/B001U5VJY0/">
                                            <img src="https://m.media-amazon.com/images/I/41if3XHoH-L.jpg" height="200" className="rounded shadow-sm mr-3" />
                                        </a>
                                        <a target="_blank" href="https://www.amazon.com/gp/product/B01DJDHNCC/">
                                            <img src="https://m.media-amazon.com/images/I/51MT1Zw599L.jpg" height="200" className="rounded shadow-sm mr-3" />
                                        </a>
                                    </div>
                                </div>
                                {/* Publication end */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 h-100 px-0 d-flex flex-column justify-content-between"
                        style={{ overflow: "scroll" }} >
                        <div className="d-flex flex-column h-100 justify-content-between bg-dark">
                            <p className="my-2 font-weight-bold text-muted ml-3">
                                Chat
                            </p>
                            <div className="text-center">
                                <img
                                    alt=""
                                    src={`${process.env.PUBLIC_URL}/assets/chat.svg`}
                                    width="50%"
                                />
                                <p className="text-muted small mt-3">
                                    Chat will be enabled soon
                                </p>
                            </div>
                            <div className="border-top px-3 pt-2 pb-4 small d-flex justify-content-between text-muted">
                                <div>
                                    Chat is currently disabled
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPaperPlane} className="text-muted" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    (state) => ({ currentLanguage: state.currentLanguage }),
    { changeLanguage }
)(Consultation);
