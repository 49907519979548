import React, { useEffect, useState, memo } from "react";
import {
  faPlusCircle,
  faEye,
  faUnlink,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Modal, Tab, Tabs } from "react-bootstrap";

import { auth, firestore } from "../../../../../../firebase";
import { API_BASE_URL } from "../../../../../../config";
import ListLoader from "../ListLoader";
import axios from "axios";

function Form(props) {
  // CONSTANTS
  const {
    eventId,
    currentEvent,
    module_data,
    handleAddSubModule,
    handleRemoveSubModule,
    handleCloseFormView,
  } = props;

  // STATES
  const [key, setKey] = useState("select");
  const [list, setList] = useState([]);
  const [fetchingList, setFetchingList] = useState(true);
  const [showAddNewFormModal, setShowAddNewFormModal] = useState(false);

  // FUNCTIONS
  // Function: Fetch Forms
  const handleFetchForms = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/form?event_id=${eventId}&user_id=${auth?.currentUser?.uid}`
      );

      if (response.status === 200) {
        const { data } = response;

        setList(data?.data?.list);
        setFetchingList(false);
      } else {
        setFetchingList(false);
      }
    } catch (error) {
      console.log("[Error] Fetching list: ", error);
      setFetchingList(false);
    }
  };

  useEffect(() => {
    let unsubscribe_list = firestore
      .collection(`/events/${eventId}/forms`)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(() => {
          // Fetch: Forms
          handleFetchForms();
        });
      });

    return () => {
      unsubscribe_list();
    };
  }, []);

  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <div className="d-flex align-items-center">
        <a
          onClick={handleCloseFormView}
          style={{ cursor: "pointer" }}
          className="text-white p-3 m-0"
          title="Close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <div className="p-3">
          <h4 className="text-white mb-2">Select a Form or add new</h4>
          <p className="text-uppercase text-light small m-0">
            <strong>MODULE</strong> {module_data?.name}
          </p>
        </div>
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="w-100"
      >
        <Tab
          tabClassName={
            key === "select"
              ? "border-top-0 border-left-0 border-right-0 border-bottom border-primary w-50 bg-transparent text-primary font-weight-bold"
              : "border-top-0 border-left-0 border-right-0 border-bottom border-secondary w-50 bg-transparent text-secondary font-weight-bold"
          }
          eventKey="select"
          title="Select from existing"
        >
          <div className="p-3">
            {fetchingList && <ListLoader />}
            <div className="mb-3">
              {list?.map((form, key) => (
                <div
                  key={key}
                  className="d-flex justify-content-between align-items-center border rounded p-2 px-3 mb-2"
                >
                  <div>
                    <p className="mb-0">
                      {/* {key + 1}.   */}
                      {currentEvent && currentEvent?.slug && (
                        <a
                          href={`${window.location.origin}/event/${currentEvent?.slug}/forms/${form.id}`}
                          target="_blank"
                          className="text-secondary mr-2 pointer"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </a>
                      )}
                      {form.form_title}
                    </p>
                  </div>
                  <div>
                    {module_data?.sub_modules?.filter(
                      (item) => item.content_id === form.id
                    )?.length > 0 ? (
                      <div
                        className="pointer"
                        onClick={() =>
                          handleRemoveSubModule(
                            module_data?.sub_modules?.filter(
                              (item) => item.content_id === form.id
                            )[0]
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faUnlink}
                          className="my-0 mr-2 pointer text-danger"
                        />
                        <small>REMOVE</small>
                      </div>
                    ) : (
                      <div
                        className="pointer"
                        onClick={() =>
                          handleAddSubModule({
                            reference_id: form.id,
                            content_id: form.id,
                            title: form.form_title,
                            type: "form",
                            user_id: auth.currentUser.uid,
                            order_index: module_data?.sub_modules?.length
                              ? module_data?.sub_modules?.length + 1
                              : 1,
                            event_id: eventId,
                            module_id: module_data?.id,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          className="my-0 mr-2 pointer text-primary"
                        />
                        <small>ADD</small>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <a
                className="btn btn-primary btn-sm"
                onClick={() => setShowAddNewFormModal(true)}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add new Form
              </a>
            </div>
          </div>
        </Tab>
        <Tab
          tabClassName={
            key === "create"
              ? "border-top-0 border-left-0 border-right-0 border-bottom border-primary w-50 bg-transparent text-primary font-weight-bold"
              : "border-top-0 border-left-0 border-right-0 border-bottom border-secondary w-50 bg-transparent text-secondary font-weight-bold"
          }
          eventKey="create"
          title="Create"
        >
          <div className="p-3">
            <h4>Create a new form</h4>
            <p>
              You will be redirected to a new tab. Once you have successfully
              created the form, please come back to this page to add it to the
              module.
            </p>
            <a
              className="btn btn-primary btn-sm"
              href={`${window.location.origin}/admin/eventmanager/${eventId}/forms?open=create`}
              target="_blank"
            >
              Continue
            </a>
          </div>
        </Tab>
      </Tabs>

      {/* Modal: Add new form */}
      <Modal
        show={showAddNewFormModal}
        onHide={() => setShowAddNewFormModal(false)}
      >
        <Modal.Header closeButton>
          <h4 className="mb-0">Create a new form</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            You will be redirected to a new tab. Once you have successfully
            created the form, please come back to this page to add it to the
            module.
          </p>
          <a
            className="btn btn-primary btn-sm"
            href={`${window.location.origin}/admin/eventmanager/${eventId}/forms?open=create`}
            target="_blank"
          >
            Continue
          </a>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventId: state.eventId,
  currentEvent: state.currentEvent,
});

export default memo(connect(mapStateToProps)(Form));
