import { Button } from "react-bootstrap";
import { faEnvelope, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import EmailAttendeeModal from "./EmailUserModal";

const SpeakersList = (props) => {
  const { speaker, index } = props;
  return (
    <div className="col-md-6 mb-3 col-12" key={index}>
      <div className="icon-box bg-dark text-light shadow-sm rounded w-100 h-100 px-4 py-3">
        <div className="d-flex align-items-center">
          <div className="mr-2 small">{index + 1}</div>
          <div>
            <Avatar
              name={speaker.first_name + speaker.last_name}
              src={speaker.img_src}
              round={true}
              size="40"
              textSizeRatio={1.75}
              className="mr-3"
            />
          </div>
          <div className="flex-grow-1">
            <h3 className="text-s-10 mb-0 text-capitalize">
              {speaker.first_name + " " + speaker.last_name}
            </h3>
            <p className="small mb-0">{speaker.designation}</p>
            <p className="small font-weight-bold mb-0">
              {speaker.organisation}
            </p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center mt-3">
          <div className="d-flex w-100 ">
            {!speaker.is_published && speaker.email_address ? (
              <Button
                variant="outline"
                className="btn-sm btn-outline-light mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  props.sendReminder(speaker.email_address);
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} size="sm" />{" "}
                <span>Send Reminder</span>{" "}
              </Button>
            ) : null}
            {speaker.email_address ? (
              <EmailAttendeeModal
                first_name={speaker.first_name}
                last_name={speaker.last_name}
                email_address={speaker.email_address}
              />
            ) : null}
            <Button
              onClick={() => props.speakerOnClickHandler(speaker)}
              variant="outline"
              className="btn-sm btn-outline-primary mr-2"
            >
              {" "}
              <FontAwesomeIcon icon={faPen} size="sm" />{" "}
              <span>Edit Profile</span>
            </Button>
            {speaker.is_published ? (
              <div>
                <Button
                  variant="outline"
                  className="btn-sm btn-outline-warning mr-2"
                  onClick={() => props.unPublishSpeakerHandler(speaker.id)}
                >
                  Unpublish
                </Button>
              </div>
            ) : (
              <Button
                variant="outline"
                className="btn-sm btn-outline-warning mr-2"
                onClick={() => props.publishSpeakerHandler(speaker.id)}
              >
                Publish
              </Button>
            )}
            <Button
              variant="outline"
              className="btn-sm btn-outline-danger mr-2"
              onClick={() => {
                props.setSelectedUID(speaker.id);
                props.setDeleteWarningModal(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} size="sm" /> <span>Delete</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SpeakersList;
