import React, { useEffect } from "react";
import { setProject } from "../../store/actions/actions";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { projectList } from "../data";

function ProjectRedux({
  setProject,
  currentProject,
}) {
  const { id } = useParams();

  useEffect(() => {
    const thisProject = projectList.filter(
      (project) => project.slug === id
    );
    thisProject && setProject(thisProject[0]);
  }, [id]);
  return null;
}

export default connect(
  (state) => ({
    currentProject: state.project,
  }),
  { setProject }
)(ProjectRedux);
