import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { lineChart1, lineChart2, lineChart3 } from "./Data/charts-data";
import Chart from "react-apexcharts";
import { memo } from "react";
import Mdata from "./Data/MonthlyData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const Revenue = (props) => {
  const [data, setData] = useState(props.date);
  useEffect(() => {
    setData(props.date);
    lineChart1.series = Mdata[`${data}`]?.graph1 ?? lineChart1.series;
    lineChart2.series = Mdata[`${data}`]?.graph2 ?? lineChart2.series;
    lineChart3.series = Mdata[`${data}`]?.graph3 ?? lineChart3.series;
  }, [props]);
  return (
    <div>
      <h5 className="text-white ml-3 font-weight-bold mt-3">Revenue</h5>
      <Row
        className="dark-only container-fluid my-3 p-0 mx-auto"
        style={{
          border: "1px solid rgba(255, 255, 255, .3)",
          background: "rgb(58, 59, 61)",
          borderRadius: "10px",
          width: "97%",
        }}
      >
        <Col md={3} className="p-2 pt-4 pl-3">
          <div className="">
            <h6 className="font-weight-bold">Total Revenue</h6>
            <h4 className="font-weight-bold">{10.5}K</h4>
          </div>
          <div className="d-flex mt-4">
            <h6 className="font-weight-bold">Growth</h6>
            <span className="small pl-2 pt-1"> Previous period</span>
          </div>
          <div className="d-flex">
            <h5 className="font-weight-bold">{22.5}%</h5>
            <span className="pl-2" style={{ color: "#3bb143" }}>
              <FontAwesomeIcon icon={faArrowUp} />
            </span>
          </div>
        </Col>
        <Col md={9} className="p-3 ">
          <div className="d-sm-flex">
            <Button
              variant="primary"
              className="graphbuttons graph-active p-0 px-2"
              size="sm"
            >
              All
            </Button>
            <Button variant="primary" className="graphbuttons" size="sm">
              Courses{" "}
            </Button>
            <Button variant="primary" className="graphbuttons" size="sm">
              Events
            </Button>
            <Button variant="primary" className="graphbuttons" size="sm">
              Virtual Office
            </Button>
            <Button variant="primary" className="graphbuttons" size="sm">
              Projects
            </Button>
          </div>
          <Chart
            options={lineChart2.options}
            series={lineChart2.series}
            height="170"
            type="area"
          />
        </Col>
        {/*
        <Col xl="4" md="12" className="box-col-12">
          <Card className="o-hidden">
            <div className="chart-widget-top">
              <Row className="card-body">
                <Col xs="8">
                  <h6 className="f-w-600 font-success">Event participants</h6>
                  {/* <span className="num">
                  <span className="counter">{"30"}</span>
                  {"%"}
                  <i className="icon-angle-up f-12 ml-1"></i>
              //  </span>
                </Col>
                <Col xs="4" className="text-right">
                  <h4 className="num total-value">
                    <span className="counter">{Mdata[`${data}`].graphval2}</span>
                  </h4>
                </Col>
              </Row>
              <div>
                <div id="chart-widget2">
                  <Chart
                    className="flot-chart-placeholder"
                    options={lineChart2.options}
                    series={lineChart2.series}
                    height="170"
                    type="area"
                  />
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col xl="4" md="12" className="box-col-12">
          <Card className="o-hidden">
            <div className="chart-widget-top">
              <Row className="card-body">
                <Col xs="8">
                  <h6 className="f-w-600 font-success">Consultancy users</h6>
                  {/* <span className="num">
                  <span className="counter">{"68"}</span>
                  {"%"}
                  <i className="icon-angle-up f-12 ml-1"></i>
              //  </span>
                </Col>
                <Col xs="4" className="text-right">
                  <h4 className="num total-value">
                    <span className="counter">{Mdata[`${data}`].graphval3}</span>
                  </h4>
                </Col>
              </Row>
              <div>
                <div id="chart-widget3">
                  <Chart
                    className="flot-chart-placeholder"
                    options={lineChart3.options}
                    series={lineChart3.series}
                    height="170"
                    type="area"
                  />
                </div>
              </div>
            </div>
          </Card>
        </Col>
        */}
      </Row>
    </div>
  );
};
export default memo(Revenue);
