import React, { lazy, Suspense } from "react";
import Sidebar from "./Sidebar/Index";
import "react-datepicker/dist/react-datepicker.css";
import "./style/Calendar.css";
import { useParams } from "react-router";
import Home from "./Home_old";
import NavBar from "./Navbar";
import { Spinner } from "react-bootstrap";
import SelectedDashboard from "./SelectedDashboard";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
const ViewAll = lazy(() => import("./ViewAll"));

const AdminDashboard = (props) => {
  const params = useParams();
  const user = firebase.auth().currentUser;
  if (!user) {
    return <Redirect to="/admin/login" />;
  }
  return (
    <>
      <NavBar />
      <div
        className="d-flex"
        style={{ background: "#232626", height: "calc(100vh - 56px)" }}
      >
        <Sidebar />
        {params?.type?.trim()?.length && !params?.slug?.trim()?.length ? (
          <Suspense
            fallback={<Spinner animation="border" size="sm" color="white" />}
          >
            <ViewAll {...params} />
          </Suspense>
        ) : params?.slug?.trim()?.length ? (
          <Suspense
            fallback={<Spinner animation="border" size="sm" color="white" />}
          >
            <SelectedDashboard {...params} />
          </Suspense>
        ) : (
          <Home />
        )}
      </div>
    </>
  );
};
export default AdminDashboard;
