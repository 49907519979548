import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

export default function DigitalDownloadsUser() {
  // CONSTANTS
  const { download_id } = useParams();

  return (
    <Container>
      <h3 className="text-white">DigitalDownloadsUser</h3>
      <p className="text-white">{download_id}</p>
    </Container>
  );
}
