import React, { useEffect, useState, useMemo } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { connect } from "react-redux";
import { setGraphId, setProducts } from "../../../../store/actions/actions";
import "@culturehq/add-to-calendar/dist/styles.css";
import firebase from "firebase";
import moment from "moment";
import { useHistory, useParams, useLocation } from "react-router";
import { Button, Card, Modal, Form, FormCheck } from "react-bootstrap";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faComment,
  faShare,
  faTrash,
  faEdit,
  faAddressCard,
  faUserPlus,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function ProjectList(props) {
  const user = firebase.auth().currentUser;
  const { eventid } = useParams();
  const location = useLocation();

  console.log("Set Graph ID ML ===> ",setGraphId)

  const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
    firebase.firestore().collection("events").doc(eventid)
  );

  console.log("EventID ML ===> ",eventid)

  const [allGraphs, setAllGraphs] = useState(null);
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAttendeeCategory, setSelectedAttendeeCategory] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [editProjectName, setEditProjectName] = useState(false);

  const bbosAppsEnabled = eventSnapshot?.data()?.is_bbos_apps ?? true;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  console.log("ALL Graphs ML ===> ",allGraphs)

  useEffect(() => {
    props.setProducts([]);
  }, []);

  useEffect(() => {
    console.log(location);
  }, [location]);

  const [usersSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${props?.currentEvent?.id}/users`)
      .where("uid", "!=", user.uid)
  );

  const allUsers = useMemo(() => {
    return usersSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        label: fac?.data()?.firstName,
        value: fac?.data()?.firstName,
        ...fac.data(),
      };
    });
  }, [usersSnapshot]);

  const [currentUserDoc, currentUserLoading, currentUserError] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${props?.currentEvent?.id}/users`)
      .doc(`${user.uid}`)
  );
  const currentUserCategory = currentUserDoc?.data()?.category ?? "";
  const currentUserRole = currentUserDoc?.data()?.user_role ?? "";

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  console.log("Current Event ML ===> ",props.currentEvent)

  useEffect(() => {
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos`)
      .onSnapshot((snapshot) => {
        const tempGraphs = [];
        snapshot.forEach((doc) => {
          //if (!doc.data().reset && (doc.data().datasets && doc.data().datasets.length)) {
          tempGraphs.push({ ...doc.data(), id: doc.id });
          //}
        });
        setAllGraphs(tempGraphs);
      });
  }, []);

  const addProjectHandler = () => {
    if (projectName) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .add({
          title: projectName,
          createdAt: Date.now(),
          createdBy: user.uid,
          assignedToCategory: "",
          errc_created: false,
          to_be_created: false,
          buyerExpAudit_created: false,
          six_path: false,
          three_tiers: false,
        })
        .then((doc) => {
          props.setGraphId(doc.id);
          firebase
            .firestore()
            .collection(
              `events/${props?.currentEvent?.id}/bbos/${doc.id}/factors`
            )
            .add({
              defaultValue: 1,
              name: "Price",
              position: 0,
            });
          history.push(`./${eventid}#bbos?project=${doc.id}`);
          props.setProducts([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteProjectHandler = () => {
    if (selectedProject) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${selectedProject.id}`)
        .delete()
        .then(() => {
          setShowDeleteModal(false);
          toast.success("Project deleted", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const calculatePercent = (item) => {
    let count = 0;
    for (let key in item) {
      if (typeof item[key] === "boolean" && item[key] === true) {
        count++;
      }
    }
    return Math.floor((count / 5) * 100);
  };

  const handleSelectedShare = (graphId) => {
    if (selectedUsers.length > 0) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${graphId}`)
        .update(
          {
            assignedTo: firebase.firestore.FieldValue.arrayUnion(
              ...selectedUsers
            ),
            assignedToAll: false,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }

    if (selectedAttendeeCategory != "") {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${graphId}`)
        .update({
          assignedToCategory: selectedAttendeeCategory,
          assignedToAll: false,
        })
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedAttendeeCategory("");
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }
  };

  let history = useHistory();

  useEffect(() => {
    if (!!selectedProject) {
      const thisProject = allGraphs?.filter(
        (item) => item.id === selectedProject.id
      );

      if (thisProject[0]) {
        setSelectedProject(thisProject[0]);
      }
    }
  }, [allGraphs]);

  const revokeSharingAccess = (project_id) => {
    const projectRef = firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos/`)
      .doc(`${project_id}`);

    projectRef
      .update({
        assignedToCategory: "",
      })
      .then(() => {
        setShowRevokeModal(false);
        toast.dark("Access revoked", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((error) => console.error("Revoke sharing access: ", error));
  };

  const shareToAllHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .set(
          {
            assignedToAll: true,
            assignedToCategory: "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared to all successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const editProjectNameHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          title: projectName,
        })
        .then(() => {
          setEditProjectName(false);
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const revokeAccessFromAll = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          assignedToAll: false,
          assignedToCategory: "",
        })
        .then(() => {
          toast.success("Revoked Access successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const UserDetails = ({ uid, idx, project_id }) => {
    const userDetails = allUsers?.filter((item) => item.uid === uid);
    return (
      <div className="d-flex align-items-center mb-1" key={idx}>
        <div className="mr-2">
          <Button
            size="sm"
            variant="danger"
            onClick={() => revokeSharingAccess(uid, project_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="flex-grow-1">
          <p className="mb-0">
            {idx + 1}. {userDetails[0]?.label} ({userDetails[0]?.email})
          </p>
        </div>
      </div>
    );
  };

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      <Card
        style={{ width: "100%" }}
        className="shadow-sm bg-dark shadow-sm my-3 text-white"
      >
        <Card.Header>
          <Card.Title className="mb-0">BBOS settings</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="d-flex align-items-center mb-3 col-md-4">
              <FontAwesomeIcon icon={faTh} className="mx-4  btn-hero" />
              <div className="d-flex flex-column">
                <span className="small font-weight-bold text-uppercase">
                  BBOS Apps enabled
                </span>
                <span>
                  <FormCheck
                    type="switch"
                    id="bbosAppsEnabled"
                    label={bbosAppsEnabled ? "On" : "Off"}
                    disabled={true}
                    checked={bbosAppsEnabled}
                    onChange={(e) =>
                      firebase
                        .firestore()
                        .collection("events")
                        .doc(props.eventId)
                        .update({
                          is_bbos_apps: e.target.checked,
                        })
                    }
                  ></FormCheck>
                </span>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <div className="my-3 d-flex justify-content-between">
        <h5 className="text-white">
          {props.currentEvent?.bbos_names?.title ?? "BBOS"} Projects
        </h5>
        <Button size="sm" className="text-uppercase" onClick={handleShow}>
          Add Project
        </Button>
      </div>

      <React.Fragment>
        <div>
          {allGraphs &&
            allGraphs?.map((graph, key) => (
              <>
                {(graph.createdBy === user.uid ||
                  currentUserRole === 1 ||
                  graph.assignedToAll ||
                  graph.assignedToCategory === currentUserCategory) && (
                  <div
                    className="shadow-sm rounded-lg px-2 pb-2 card mb-1 border-1 bg-dark"
                    key={key}
                  >
                    <div className="d-flex">
                      <div
                        className="pointer flex-grow-1"
                        onClick={() => {
                          props.setGraphId(graph.id);
                          history.push(`./${eventid}#bbos?project=${graph.id}`);
                        }}
                      >
                        <small className="text-light smaller">
                          {graph?.createdAt &&
                            moment(graph.createdAt).format("LLL")}
                        </small>
                        <p className="m-0 font-weight-bold text-white small">
                          {graph.title && <div>{graph.title}</div>}
                        </p>
                      </div>

                      {(graph.createdBy != user.uid ||
                        // graph.assignedTo.includes(user.uid) ||
                        graph.assignedToCategory === currentUserCategory) && (
                        <div className="p-2">
                          <>
                            {allUsers?.map((item) => (
                              <>
                                {item.uid === graph.createdBy && (
                                  <p className="text-white small">{`Created By ${item.firstName}`}</p>
                                )}
                              </>
                            ))}
                          </>
                        </div>
                      )}

                      {graph.createdBy === user.uid && (
                        <>
                          <div className="pt-2 mr-2">
                            <Button
                              size="sm"
                              onClick={() => {
                                setEditProjectName(true);
                                setProjectName(graph.title);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} size="sm" />{" "}
                              Project Name
                            </Button>
                          </div>
                          <div className="pt-2 mr-2">
                            <Button
                              size="sm"
                              onClick={() => {
                                setShowShare(true);
                                setSelectedProject(graph);
                              }}
                              className="text-uppercase font-weight-bold"
                            >
                              <FontAwesomeIcon icon={faShare} size="sm" />{" "}
                              <small>Share</small>
                            </Button>
                            <Modal
                              show={showShare}
                              onHide={() => {
                                setShowShare(false);
                                setSelectedUsers([]);
                              }}
                            >
                              <Modal.Header closeButton className="pb-1">
                                <div className="d-flex flex-column">
                                  <p className="h4 mb-0">Share your project</p>
                                  {/* {selectedProject?.assignedTo?.length >
                                      0 ? (
                                        <p className="mb-0">
                                          Shared with{" "}
                                          {selectedProject?.assignedTo?.length}{" "}
                                          member
                                          {selectedProject?.assignedTo?.length >
                                          1
                                            ? "s"
                                            : ""}
                                        </p>
                                      ) : (
                                        <p className="mb-0">Not yet shared</p>
                                      )} */}
                                  {selectedProject?.assignedToCategory ||
                                  selectedProject?.assignedToAll ? (
                                    <p className="mb-0">
                                      Shared with{" "}
                                      {selectedProject?.assignedToAll ? (
                                        <b>All</b>
                                      ) : (
                                        <b>
                                          {getAttendeeCategoryName(
                                            selectedProject?.assignedToCategory
                                          )}
                                        </b>
                                      )}{" "}
                                      attendee(s)
                                    </p>
                                  ) : (
                                    <p className="mb-0">Not yet shared</p>
                                  )}
                                </div>
                              </Modal.Header>
                              <Modal.Body className="text-dark">
                                {/* {selectedProject?.assignedTo?.length >
                                      0 && (
                                      <div className="mb-3">
                                        <p className="mb-2 text-uppercase small">
                                          Already shared with:
                                        </p>
                                        {selectedProject?.assignedTo?.map(
                                          (item, idx) => (
                                            <UserDetails
                                              uid={item}
                                              idx={idx}
                                              project_id={graph.id}
                                            />
                                          )
                                        )}
                                      </div>
                                    )} */}

                                <p className="mb-1">
                                  Select category to share your project
                                </p>
                                <Select
                                  placeholder="Attendee Category"
                                  options={attendeeCategoryList?.map((item) => {
                                    return {
                                      label: `${item.name}`,
                                      value: `${item.id}`,
                                    };
                                  })}
                                  onChange={(e) =>
                                    setSelectedAttendeeCategory(e.value)
                                  }
                                  className="w-100 mb-2 text-dark"
                                ></Select>

                                {/* <Select
                                      isMulti
                                      placeholder="Select people"
                                      className="w-100 mb-2"
                                      options={allUsers?.filter(
                                        (item) =>
                                          !selectedProject?.assignedTo?.includes(
                                            item.uid
                                          )
                                      )}
                                      onChange={(e) =>
                                        setSelectedUsers(
                                          e?.map((item) => item.uid)
                                        )
                                      }
                                    /> */}

                                <Button
                                  className="w-100 mb-2"
                                  onClick={() => handleSelectedShare(graph.id)}
                                  disabled={
                                    selectedProject?.assignedToCategory ===
                                    selectedAttendeeCategory
                                  }
                                >
                                  Share
                                </Button>
                                {selectedProject?.assignedToCategory && (
                                  <Button
                                    variant="none"
                                    className="w-100 text-danger shadow-sm border-danger"
                                    onClick={(e) => {
                                      setShowRevokeModal(true);
                                    }}
                                  >
                                    Revoke Access from{" "}
                                    <b>
                                      {" "}
                                      {getAttendeeCategoryName(
                                        selectedProject?.assignedToCategory
                                      )}
                                    </b>
                                  </Button>
                                )}
                                {selectedProject?.assignedToCategory && (
                                  <p className="small mb-0 text-muted">
                                    "{selectedProject?.title}" is already being
                                    shared with{" "}
                                    <b>
                                      {getAttendeeCategoryName(
                                        selectedProject?.assignedToCategory
                                      )}
                                    </b>
                                  </p>
                                )}

                                <p className="my-2 text-center">OR</p>

                                {!selectedProject?.assignedToAll && (
                                  <Button
                                    className="w-100 mb-2"
                                    onClick={() => shareToAllHandler(graph.id)}
                                  >
                                    Share with Everyone
                                  </Button>
                                )}
                                {selectedProject?.assignedToAll && (
                                  <Button
                                    variant="none"
                                    className="w-100 text-danger shadow-sm border-danger"
                                    onClick={(e) => {
                                      revokeAccessFromAll(graph.id);
                                    }}
                                  >
                                    Revoke Access from <b>all</b>
                                  </Button>
                                )}

                                <Modal
                                  show={showRevokeModal}
                                  onHide={() => setShowRevokeModal(false)}
                                >
                                  <Modal.Header className="pb-1" closeButton>
                                    <Modal.Title>Revoke Access</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body className="pb-1">
                                    Are you sure you want to Revoke Access?
                                  </Modal.Body>
                                  <Modal.Footer className="pb-1">
                                    <Button
                                      variant="secondary"
                                      onClick={() => setShowRevokeModal(false)}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={(e) =>
                                        revokeSharingAccess(selectedProject.id)
                                      }
                                    >
                                      Revoke Access
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </>
                      )}
                      {graph.createdBy === user.uid && (
                        <>
                          <div className="pt-2">
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() => {
                                setShowDeleteModal(true);
                                setSelectedProject(graph);
                              }}
                              className="text-uppercase font-weight-bold"
                            >
                              <FontAwesomeIcon icon={faTrash} size="sm" />{" "}
                              <small>Delete</small>
                            </Button>
                          </div>
                          <>
                            <Modal
                              centered
                              show={showDeleteModal}
                              onHide={() => setShowDeleteModal(false)}
                            >
                              <Modal.Header
                                data-theme="dark"
                                className="border-dark"
                                closeButton
                              >
                                <Modal.Title>Delete Project</Modal.Title>
                              </Modal.Header>
                              <Modal.Body data-theme="dark">
                                Are you sure you want to delete this project?
                              </Modal.Body>
                              <Modal.Footer
                                data-theme="dark"
                                className="border-dark"
                              >
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  onClick={() => {
                                    setShowDeleteModal(false);
                                    setSelectedProject(null);
                                  }}
                                  className="text-uppercase"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() => deleteProjectHandler()}
                                  className="text-uppercase"
                                >
                                  <FontAwesomeIcon icon={faTrash} />{" "}
                                  <span>Delete</span>
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            {/*------------Edit Project Name Modal Start----------------*/}
                            <Modal
                              show={editProjectName}
                              onHide={() => setEditProjectName(false)}
                            >
                              <Modal.Header closeButton className="pb-1">
                                <Modal.Title>Edit Project Name</Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pb-1">
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Edit Project Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Project Name"
                                      autoComplete="off"
                                      value={projectName}
                                      onChange={(e) =>
                                        setProjectName(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer className="pb-1">
                                <Button
                                  variant="secondary"
                                  onClick={() => setEditProjectName(false)}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    editProjectNameHandler(graph.id)
                                  }
                                >
                                  Update
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            {/*------------Edit Project Name Modal Ends----------------*/}
                          </>
                        </>
                      )}
                    </div>

                    <div
                      className="pointer p-3"
                      onClick={() => {
                        props.setGraphId(graph.id);
                        history.push(`./${eventid}#bbos?project=${graph.id}`);
                      }}
                    >
                      <ProgressBar
                        height={2}
                        filledBackground="green"
                        percent={calculatePercent(graph)}
                      >
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished ? "accomplished" : null
                              }`}
                            >
                              {/* {index + 1} */}
                            </div>
                          )}
                        </Step>
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished
                                  ? graph.buyerExpAudit_created
                                    ? "accomplished"
                                    : "accomplished_warn"
                                  : null
                              }`}
                            >
                              {accomplished &&
                                !graph.buyerExpAudit_created &&
                                "!"}
                            </div>
                          )}
                        </Step>
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished
                                  ? graph.three_tiers
                                    ? "accomplished"
                                    : "accomplished_warn"
                                  : null
                              }`}
                            >
                              {accomplished && !graph.three_tiers && "!"}
                            </div>
                          )}
                        </Step>
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished
                                  ? graph.six_path
                                    ? "accomplished"
                                    : "accomplished_warn"
                                  : null
                              }`}
                            >
                              {accomplished && !graph.six_path && "!"}
                            </div>
                          )}
                        </Step>
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished
                                  ? graph.errc_created
                                    ? "accomplished"
                                    : "accomplished_warn"
                                  : null
                              }`}
                            >
                              {accomplished && !graph.errc_created && "!"}
                            </div>
                          )}
                        </Step>
                        <Step>
                          {({ accomplished, index }) => (
                            <div
                              className={`indexedStep ${
                                accomplished
                                  ? graph.to_be_created
                                    ? "accomplished"
                                    : "accomplished_warn"
                                  : null
                              }`}
                            >
                              {accomplished && !graph.to_be_created && "!"}
                            </div>
                          )}
                        </Step>
                      </ProgressBar>
                    </div>
                    <div
                      className="row px-3 pointer text-white"
                      onClick={() => {
                        props.setGraphId(graph.id);
                        history.push(`./${eventid}#bbos?project=${graph.id}`);
                      }}
                    >
                      <p className="small mx-3">
                        {props.currentEvent?.bbos_names?.as_is ??
                          "As Is Strategy"}
                      </p>
                      <p className="small mx-3">
                        {props.currentEvent?.bbos_names?.buyer_experience ??
                          "Buyer Experience Audit"}
                      </p>
                      <p className="small mx-3">
                        {props.currentEvent?.bbos_names?.three_tier ??
                          "Three Tiers of Non-Customers"}
                      </p>
                      <p className="small mx-3">
                        {props.currentEvent?.bbos_names?.six_path ?? "Six Path"}
                      </p>
                      <p className="small mx-3">
                        {props.currentEvent?.bbos_names?.errc ?? "Errc Grid"}
                      </p>
                      <p className="small mx-3">
                        {props.currentEvent?.bbos_names?.to_be ??
                          "To Be Strategy"}
                      </p>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header className="pb-1" closeButton>
            <Modal.Title>Add Project</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-1">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Project Name"
                  autoComplete="off"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="pb-1">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => addProjectHandler()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </div>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    workspaceId: state.workspaceId,
    roomId: state.roomId,
    currentEvent: state.currentEvent,
  }),
  { setGraphId, setProducts }
)(ProjectList);
