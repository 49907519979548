import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

const Tickets = (props) => {
  const [showAddTicketModal, setShowAddTickerModal] = useState(false);

  return (
    <>
      <div
        className="container-fluid h-100 py-4"
        style={{ overflow: "hidden" }}
      >
        <div className="col-md-12 h-100 mt-3" style={{ overflow: "scroll" }}>
          <h5>Ticket Manager</h5>
          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    size="sm"
                  >
                    All events
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      Blue Ocean Awards: 7th Edition
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex">
                <Button
                  className="btn-sm btn-secondary disabled mr-2"
                  disabled
                  onClick={() => setShowAddTickerModal(true)}
                >
                  Add ticket
                </Button>
              </div>
            </div>
            <div className="mt-3 small">
              <Table borderless responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Ticket name</th>
                    <th>Visibility</th>
                    <th>Staus</th>
                    <th>Price</th>
                    <th>Count</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Visitor</td>
                    <td>Public</td>
                    <td>Open</td>
                    <td>Free</td>
                    <td>Unlimited</td>
                    <td>
                      <Button disabled className="btn-sm btn-light mr-2">
                        Edit
                      </Button>
                      <Button disabled className="btn-sm btn-danger">
                        Remove
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>VIP Pack</td>
                    <td>Public</td>
                    <td>Open</td>
                    <td>€1,250</td>
                    <td>10</td>
                    <td>
                      <Button disabled className="btn-sm btn-light mr-2">
                        Edit
                      </Button>
                      <Button disabled className="btn-sm btn-danger">
                        Remove
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* modal for add ticket */}

      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAddTicketModal}
        onHide={() => setShowAddTickerModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create a Ticket
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Ticket name</Form.Label>
              <Form.Control type="text" placeholder="VIP Ticket" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={2} placeholder="Description" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Create ticket
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Tickets;
