import React, { useState, useEffect } from "react";
import EventLeftbar from "../LeftBar";
import EventNavbar from "../NavBar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setErrcId } from "../../../../store/actions/actions";
import { Form, Button, Dropdown, Card } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import { v4 } from "uuid";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  faPlusCircle,
  faMinusCircle,
  faEdit,
  faTimesCircle,
  faCheckSquare,
  faGripVertical,
  faTrash,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

function Quadrants({
  type,
  setNewValue,
  typeArray,
  setNewFactorValue,
  newFactorValue,
  newValue,
  addItems,
  showAddField,
  setShowAddField,
  editField,
  editItems,
  editValue,
  setEditValue,
  setEditField,
  onRevertBackToDragDrop,
  deleteFactorHandler,
}) {
  return (
    <div
      key={type}
      className={
        type == "main"
          ? "col-md-12 border border-secondary p-0 m-0"
          : "col-md-6 border border-secondary p-0 m-0"
      }
    >
      <div
        className={
          type == "main"
            ? "bg-secondary p-2 text-white "
            : "bg-primary p-2 text-white "
        }
      >
        <div className="d-flex bd-highlight">
          <div
            className="p-2 w-100 bd-highlight text-center"
            style={{ textAlign: "center" }}
          >
            {type.toUpperCase()}
          </div>
          <span className="p-2 flex-shrink-1 bd-highlight">
            <FontAwesomeIcon
              icon={
                showAddField.key == type && showAddField.show
                  ? faMinusCircle
                  : faPlusCircle
              }
              className=""
              onClick={(e) => {
                if (showAddField.key !== type && !showAddField.show) {
                  setShowAddField({
                    key: type,
                    show: true,
                  });
                } else {
                  setNewValue("");
                  setShowAddField({
                    key: "",
                    show: false,
                  });
                }
              }}
            />
          </span>
        </div>
      </div>

      <Droppable droppableId={type}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={"droppable-col"}
            >
              <div className="px-3 pt-3">
                {showAddField.key == type && showAddField.show && (
                  <Form onSubmit={(e) => addItems(e, type)}>
                    <Form.Group className="d-flex bd-highlight">
                      <Form.Control
                        className="p-2 flex-grow-1 bd-highlight"
                        type="text"
                        value={newValue}
                        name="newValue"
                        placeholder="Add new Value"
                        onChange={(e) => setNewValue(e.target.value)}
                      />
                      <Dropdown className=" p-1 mt-0 bd-highlight small">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          {newFactorValue ? newFactorValue.name : "High"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setNewFactorValue({
                                value: 2,
                                name: "High",
                              })
                            }
                          >
                            High
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setNewFactorValue({
                                value: 1,
                                name: "Medium",
                              })
                            }
                          >
                            Medium
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setNewFactorValue({
                                value: 0,
                                name: "Low",
                              })
                            }
                          >
                            Low
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="bd-highlight">
                        <Button type="submit" className="bd-highlight  p-2">
                          <FontAwesomeIcon
                            className=" small"
                            icon={faCheckSquare}
                          />
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                )}
              </div>
              <div className="px-3 pt-3"></div>
              <div className="p-2">
                {typeArray?.map((data, index) => {
                  return (
                    <>
                      <Draggable
                        key={`${type}-` + data.id}
                        index={index}
                        draggableId={`${type}-` + data.id}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              className={`item border shadow-sm mb-1 rounded ${snapshot.isDragging && "dragging shadow"
                                } d-flex align-items-center `}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="p-2 d-flex align-items-center border-right">
                                <FontAwesomeIcon
                                  icon={faGripVertical}
                                  className="text-muted"
                                />
                              </div>
                              <span className="flex-grow-1 p-2">
                                {" "}
                                {data.name ? data.name : " "}
                              </span>
                              <div className="pr-2">
                                {data.name && type !== "main" ? (
                                  <FontAwesomeIcon
                                    onClick={(e) => {
                                      onRevertBackToDragDrop(
                                        e,
                                        data,
                                        type,
                                        index
                                      );
                                    }}
                                    className="text-muted small"
                                    icon={faMinusCircle}
                                    style={{ cursor: "default" }}
                                    title="Remove"
                                  />
                                ) : (
                                  " "
                                )}
                              </div>
                              {data?.isNew && <div className="pr-2">
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    deleteFactorHandler(
                                      e,
                                      data,
                                      type,
                                      index
                                    );
                                  }}
                                  className="text-muted small"
                                  icon={faTrash}
                                  style={{ cursor: "default" }}
                                  title="Delete"
                                />
                              </div>}
                            </div>
                          );
                        }}
                      </Draggable>
                    </>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
}

function ErrcGrid(props) {
  const [title, setTitle] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [showAddField, setShowAddField] = useState({ key: "", show: false });
  const [editField, setEditField] = useState({ id: "", show: false });
  const [editValue, setEditValue] = useState("");
  const [newValue, setNewValue] = useState("");
  const [eliminate, setEliminate] = useState([]);
  const [raise, setRaise] = useState([]);
  const [reduce, setReduce] = useState([]);
  const [create, setCreate] = useState([]);
  const [main, setMain] = useState([]);
  const [newFactorValue, setNewFactorValue] = useState({
    value: 2,
    name: "High",
  });
  const { darkTheme } = props;

  const db = firebase
    .firestore()
    .collection(
      `events/${props?.currentEvent?.id}/bbos/${props.graphId}/errc_grid`
    );

  const [errcCollection, errcCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/errc_grid`
      )
  );

  const getErrcHandler = () => {
    if (props.graphId) {
      //console.log(props.graphId)
      errcCollection?.docs?.map((snapshot) => {
        //console.log(snapshot.data(), "my value");

        // console.log(
        //   snapshot.data().main,
        //   snapshot.data().eliminate,
        //   snapshot.data().reduce,
        //   snapshot.data().raise,
        //   snapshot.data().create
        // );
        setTitle("Errc Grid");

        const filteredMain = props.factors?.filter((item1) => {
          return snapshot.data()?.main.includes(item1.id);
        });
        //console.log(filteredMain);
        setMain(filteredMain);

        const filteredEliminate = props.factors?.filter((item1) => {
          return snapshot.data()?.eliminate.includes(item1.id);
        });
        //console.log(filteredEliminate);
        setEliminate(filteredEliminate);

        const filteredReduce = props.factors?.filter((item1) => {
          return snapshot.data()?.reduce.includes(item1.id);
        });
        //console.log(filteredReduce);
        setReduce(filteredReduce);

        const filteredRaise = props.factors?.filter((item1) => {
          return snapshot.data()?.raise.includes(item1.id);
        });
        //console.log(filteredRaise);
        setRaise(filteredRaise);

        const filteredCreate = props.factors?.filter((item1) => {
          return snapshot.data()?.create.includes(item1.id);
        });
        //console.log(filteredCreate);
        setCreate(filteredCreate);
      });
    }
  };


  const deleteFactorHandler = (e, data, type, index) => {
    e.preventDefault();
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
      )
      .doc(`${data?.id}`)
      .delete()

  }

  useEffect(() => {
    if (!errcCollectionLoading) {
      getErrcHandler();
    }
  }, [errcCollection, props.factors]);

  const addItems = (e, key) => {
    e.preventDefault();
    //console.log(newValue, key, newFactorValue);

    if (props.graphId) {
      let data = {
        defaultValue: newFactorValue.value,
        name: newValue,
        position: props.factors.length,
        isNew: true,
      };
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .add(data)
        .then((doc) => {
          if (key == "eliminate" && props.graphId) {
            let itemCopy = [...eliminate, { ...data, id: doc.id }];
            //console.log(itemCopy);
            updateEliminate(itemCopy);
          } else if (key == "raise" && props.graphId) {
            let itemCopy = [...raise, { ...data, id: doc.id }];
            updateRaise(itemCopy);
          } else if (key == "reduce" && props.graphId) {
            let itemCopy = [...reduce, { ...data, id: doc.id }];
            updateReduce(itemCopy);
          } else if (key == "create" && props.graphId) {
            let itemCopy = [...create, { ...data, id: doc.id }];
            updateCreate(itemCopy);
          } else if (key == "main" && props.graphId) {
            let itemCopy = [...main, { ...data, id: doc.id }];
            updateMain(itemCopy);
          }
        });
    }

    // let data = {defaultValue:1, uuid: v4(), name: newValue };
    // if (key == "eliminate" && props.graphId) {
    //   let itemCopy = [...eliminate, data];
    //   console.log(itemCopy)
    //   updateEliminate(itemCopy);
    // } else if (key == "raise" && props.graphId) {
    //   let itemCopy = [...raise, data];
    //   updateRaise(itemCopy);
    // } else if (key == "reduce" && props.graphId) {
    //   let itemCopy = [...reduce, data];
    //   updateReduce(itemCopy);
    // } else if (key == "create" && props.graphId) {
    //   let itemCopy = [...create, data];
    //   updateCreate(itemCopy);
    // } else if (key == "main" && props.graphId) {
    //   let itemCopy = [...main, data];
    //   updateMain(itemCopy);
    // }
    // setNewValue("");
    // setShowAddField({ key: "", show: false });
  };

  const onRevertBackToDragDrop = (e, data, quadrant, index) => {
    e.preventDefault();

    //console.log(data, quadrant, index, "my data from revert back")
    if (quadrant == "eliminate") {
      let itemCopy = eliminate[index];
      eliminate.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateEliminate(eliminate);
    } else if (quadrant == "raise") {
      let itemCopy = raise[index];
      raise.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateRaise(raise);
    } else if (quadrant == "reduce") {
      let itemCopy = reduce[index];
      reduce.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateReduce(reduce);
    } else if (quadrant == "create") {
      let itemCopy = create[index];
      create.splice(index, 1);
      main.splice(index, 0, itemCopy);
      updateCreate(create);
    }
    updateMain(main);
  };

  const editItems = (e, id, key) => {
    e.preventDefault();
    //console.log(e, id, key);

    if (id) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .doc(id)
        .set(
          {
            name: editValue,
          },
          {
            merge: true,
          }
        );
    }
    if (key == "main") {
      let objIndex = main.findIndex((obj) => obj.id == id);
      main[objIndex].name = editValue;
      setMain(main);
      //updateEliminate(eliminate);
    }
    if (key == "eliminate") {
      let objIndex = eliminate.findIndex((obj) => obj.id == id);
      eliminate[objIndex].name = editValue;
      setEliminate(eliminate);
      //updateEliminate(eliminate);
    }
    if (key == "raise") {
      let objIndex = raise.findIndex((obj) => obj.id == id);
      raise[objIndex].name = editValue;
      setRaise(raise);
      // updateRaise(raise);
    }
    if (key == "reduce") {
      let objIndex = reduce.findIndex((obj) => obj.id == id);
      reduce[objIndex].name = editValue;
      setReduce(reduce);
      // updateReduce(reduce);
    }
    if (key == "create") {
      let objIndex = create.findIndex((obj) => obj.id == id);
      create[objIndex].name = editValue;
      setCreate(create);
      // updateCreate(create);
    }

    setEditValue("");
    setEditField({ id: "", show: false });
  };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }
    if (destination) {
      if (source.droppableId == "main") {
        let itemCopy = main[source.index];
        main.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "eliminate") {
        let itemCopy = eliminate[source.index];
        eliminate.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "raise") {
        let itemCopy = raise[source.index];
        raise.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "reduce") {
        let itemCopy = reduce[source.index];
        reduce.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
      if (source.droppableId == "create") {
        let itemCopy = create[source.index];
        create.splice(source.index, 1);
        if (destination.droppableId == "eliminate") {
          eliminate.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "raise") {
          raise.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "reduce") {
          reduce.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "create") {
          create.splice(destination.index, 0, itemCopy);
        } else if (destination.droppableId == "main") {
          main.splice(destination.index, 0, itemCopy);
        }
        updateEliminate(eliminate);
        updateRaise(raise);
        updateReduce(reduce);
        updateCreate(create);
        updateMain(main);
      }
    }
  };

  const updateEliminate = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "eliminate update");
    if (result) {
      db.doc("0").set(
        {
          eliminate: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };

  const updateReduce = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "reduce update");
    if (result) {
      db.doc("0").set(
        {
          reduce: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };

  const updateRaise = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "raise update");
    if (result) {
      db.doc("0").set(
        {
          raise: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };
  const updateCreate = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "create update");
    if (result) {
      db.doc("0").set(
        {
          create: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };
  const updateMain = (itemCopy) => {
    let result = itemCopy.map((x) => x.id);
    //console.log(result, itemCopy, "main update");
    if (result) {
      db.doc("0").set(
        {
          main: result,
        },
        {
          merge: true,
        }
      );
      return;
    }
  };

  return (
    <Card
      className={
        darkTheme
          ? "bg-dark shadow-sm border border-dark mb-3"
          : "bg-white shadow-sm border border-light mb-3"
      }
    >
      <Card.Header>
        <h5 className="mb-0 text-uppercase">{props.currentEvent?.bbos_names?.errc ?? "Errc Grid"}</h5>
      </Card.Header>
      <Card.Body>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="row">
            <Quadrants
              type={"eliminate"}
              typeArray={eliminate}
              setNewValue={setNewValue}
              addItems={addItems}
              showAddField={showAddField}
              setShowAddField={setShowAddField}
              editField={editField}
              editItems={editItems}
              editValue={editValue}
              setEditValue={setEditValue}
              setEditField={setEditField}
              setNewFactorValue={setNewFactorValue}
              newFactorValue={newFactorValue}
              onRevertBackToDragDrop={onRevertBackToDragDrop}
              deleteFactorHandler={deleteFactorHandler}
            />
            <Quadrants
              type={"raise"}
              typeArray={raise}
              setNewValue={setNewValue}
              addItems={addItems}
              showAddField={showAddField}
              setShowAddField={setShowAddField}
              editField={editField}
              editItems={editItems}
              editValue={editValue}
              setEditValue={setEditValue}
              setEditField={setEditField}
              setNewFactorValue={setNewFactorValue}
              newFactorValue={newFactorValue}
              onRevertBackToDragDrop={onRevertBackToDragDrop}
              deleteFactorHandler={deleteFactorHandler}
            />
            <Quadrants
              type={"reduce"}
              typeArray={reduce}
              setNewValue={setNewValue}
              addItems={addItems}
              showAddField={showAddField}
              setShowAddField={setShowAddField}
              editField={editField}
              editItems={editItems}
              editValue={editValue}
              setEditValue={setEditValue}
              setEditField={setEditField}
              setNewFactorValue={setNewFactorValue}
              newFactorValue={newFactorValue}
              onRevertBackToDragDrop={onRevertBackToDragDrop}
              deleteFactorHandler={deleteFactorHandler}
            />
            <Quadrants
              type={"create"}
              typeArray={create}
              setNewValue={setNewValue}
              addItems={addItems}
              showAddField={showAddField}
              setShowAddField={setShowAddField}
              editField={editField}
              editItems={editItems}
              editValue={editValue}
              setEditValue={setEditValue}
              setEditField={setEditField}
              setNewFactorValue={setNewFactorValue}
              newFactorValue={newFactorValue}
              onRevertBackToDragDrop={onRevertBackToDragDrop}
              deleteFactorHandler={deleteFactorHandler}
            />
            <Quadrants
              type={"main"}
              typeArray={main}
              setNewValue={setNewValue}
              addItems={addItems}
              showAddField={showAddField}
              setShowAddField={setShowAddField}
              editField={editField}
              editItems={editItems}
              editValue={editValue}
              setEditValue={setEditValue}
              setEditField={setEditField}
              setNewFactorValue={setNewFactorValue}
              newFactorValue={newFactorValue}
              onRevertBackToDragDrop={onRevertBackToDragDrop}
              deleteFactorHandler={deleteFactorHandler}
            />
          </div>
        </DragDropContext>
      </Card.Body>
      <Card.Footer>
        <p className="mb-0 text-uppercase small">
          <b>Pro tips</b>
        </p>
        <ol className="px-3 mb-1 pb-1">
          <li>
            <small>Drag factors from bottom to a cell in {props.currentEvent?.bbos_names?.errc ?? "Errc Grid"}.</small>
          </li>
          <li>
            <small>
              Add new factors by clicking on <b>+</b> plus button.
            </small>
          </li>
          <li>
            <small>
              Click on <b>-</b> minus button to remove a factor from a cell.
            </small>
          </li>
        </ol>
      </Card.Footer>
    </Card>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    graphId: state.graphId,
    factors: state.factors,
    currentEvent: state.currentEvent,
    darkTheme: state.darkTheme,
  }),
  null
)(ErrcGrid);
