import React from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router";

import * as TimelineStyle from "./timeline.module.css";

export default function Spotlight() {
    const history = useHistory();

    return (
        <Card className={`bg-transparent shadow-sm rounded h-100 border-0`}>
              <Card.Header className="bg-transparent px-0 py-1">
                <p className=" font-weight-bold mb-1">Spotlight</p>
              </Card.Header>
              <Card.Body
                className={`${TimelineStyle.spotlight_card} p-0 d-flex rounded`}
                onClick={() => history.push(`event/blueoceanawards`)}
              >
                <div
                  className="col-6 rounded-left"
                  style={{
                    backgroundImage: `url(https://igesia-assets.s3.ap-south-1.amazonaws.com/all-in-one/BOA.png)`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div className="col-6 p-3 rounded-right">
                  <p className="text-uppercase text-primary mb-1">Featured</p>
                  <p className="font-weight-bold mb-1">
                    Blue Ocean Awards: 7th Edition
                  </p>
                  <p className="font-weight-bold small text-warning mb-1">
                    August 14 7:30 AM to May 11 6:00 PM CET
                  </p>
                  <p className="small">
                    Join our full day journey dedicated to the market creation
                    simultaneously in Paris, Bordeaux, Lyon and Pretoria in
                    South Africa!
                  </p>
                </div>
              </Card.Body>
            </Card>
    )
}
