import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import firebase from "firebase";
import EventManagerNavbar from "./EventManagerNavbar";
import {
  Form,
  Dropdown,
  Button,
  Spinner,
  Badge,
  Accordion,
  Card,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../config/index";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import * as linkify from "linkifyjs";
import EventNavbar from "./Navbar";
import EventLeftbar from "../Sidebar/Index.js";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import produce from "immer";
import { connect } from "react-redux";
import { useDocument } from "react-firebase-hooks/firestore";
import Select from "react-select";

toast.configure();

const CourseDetails = (props) => {
  const [eventDetailsSnapshot, loading, error] = useDocument(
    firebase.firestore().doc(`/courses/${props.courseId}/`)
  );

  const { currentLanguage } = props;

  const { eventid } = props;
  const history = useHistory();
  const userDetails = firebase.auth().currentUser;
  const [courseDetails, setCourseDetails] = useState({});

  const [outcomes, setOutcomes] = useState({ title: "", outcomes_array: [] });
  const [certification, setCertification] = useState({});
  const [benefits, setBenefits] = useState({
    title: "",
    benefits_array: [],
    benefits_desc: "",
  });

  const [extraMaterialKey, setExtraMaterialKey] = useState(0 + "ex");

  const saveTextDataToArrayHandler = (e, type, index, setStateFunc) => {
    setStateFunc((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[type][index] = e.target.value;
      });
      return updatedState;
    });
  };

  const options = [
    { value: "development", label: "Development" },
    { value: "business", label: "Business" },
    { value: "finandacc", label: "Finance & Accounting" },
    { value: "itandsoft", label: "IT & Software" },
    { value: "design", label: "Design" },
    { value: "marketing", label: "Marketing" },
  ];

  useEffect(() => {
    setCourseDetails(() => {
      if (
        !loading &&
        eventDetailsSnapshot.exists &&
        eventDetailsSnapshot.data().course_details
      ) {
        setCourseDetails((prev) => {
          const newObj = { ...eventDetailsSnapshot.data().course_details };
          return newObj;
        });
      }
    });
  }, [loading]);

  const [firebaseID, setFirebaseID] = useState(null);

  const [fileSizeError, setFileSizeError] = useState(false);

  const [saving, setSaving] = useState(false);

  const saveTextDataToStateHandler = (e, lang) => {
    setCourseDetails((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [e.target.name]: e.target.value,
        };
      });
      return updatedState;
    });
  };

  const saveHTMLDataToStateHandler = (name, data, lang) => {
    setCourseDetails((prev) => {
      const updatedState = produce(prev, (draft) => {
        draft[lang] = {
          ...prev[lang],
          [name]: data,
        };
      });
      return updatedState;
    });
  };

  const saveDateToStateHandler = (selectedDate, dateStr, instance) => {
    setCourseDetails((prev) => {
      return { ...prev, [instance.input.name]: selectedDate[0].valueOf() };
    });
  };
  const uploadFile = async (file) => {
    if (file.name) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append(
        "user_id",
        userDetails?.uid ?? "1gHTSDwzWRPW7KAUeTcscDFxs7z2"
      );
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (file) {
        try {
          let res = await axios.post(
            `${API_BASE_URL}${DRIVE_UPLOAD}`,
            fileData,
            config
          );
          return res.data.data["file_url"];
        } catch (error) {
          console.error(error, "error in writing file to db");
        }
      } else return "";
    } else {
      return file;
    }
  };

  const saveEventDataToDBHandler = async (e, eventData) => {
    e.preventDefault();
    const feature_image_url = eventData.course_image
      ? await uploadFile(eventData.course_image)
      : null;

    const event_data = {
      ...eventData,
      course_image: feature_image_url,
      benefits,
      outcomes,
    };
    try {
      setSaving(true);
      await firebase
        .firestore()
        .collection(`/courses/`)
        .doc(props.courseId)
        .update({
          course_details: {
            ...event_data,
          },
        });
      toast.success("You have successfully updated event details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } catch (e) {
      console.error(e, "error in saving to db");
      toast.error("Error in updatingevent details", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };
  const getWhetherURLOrLocalFile = (file) => {
    if (!file.name) {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const featured_image_url = courseDetails?.course_image
    ? getWhetherURLOrLocalFile(courseDetails?.course_image)
    : null;

  const getCategoryHandler = (value) => {
    let label = "";
    let index = 0;
    options.forEach((option, i) => {
      if (option.value === value) {
        label = option.label;
        index = i;
      }
    });
    return {
      index,
      label,
    };
  };

  if (loading) return <></>;

  return (
    <>
      <div className="row w-100 h-100 montserrat-font p-3">
        <div
          className="col-md-7 p-3"
          style={{ maxHeight: "85vh", overflow: "auto" }}
        >
          <div className="text-dark">
            {courseDetails && courseDetails[currentLanguage]?.course_title && (
              <h1 className="mb-3 text-s-2-bold text-white">
                {courseDetails[currentLanguage]?.course_title
                  ? courseDetails[currentLanguage]?.course_title
                  : "Event name"}
              </h1>
            )}

            {courseDetails && courseDetails?.course_category ? (
              <Badge
                title={
                  getCategoryHandler(courseDetails?.course_category)["label"]
                }
                variant="secondary"
                className="user-select-none mb-3"
              >
                {getCategoryHandler(courseDetails?.course_category)["label"]}
              </Badge>
            ) : null}

            {courseDetails && courseDetails?.course_image && (
              <img
                src={featured_image_url}
                className="w-100 mb-3 rounded-12"
                alt="..."
              />
            )}

            {courseDetails &&
              courseDetails[currentLanguage]?.course_subtitle && (
                <p className="text-white">
                  {parse(courseDetails[currentLanguage]?.course_subtitle)}
                </p>
              )}

            <div className="p-3 shadow-sm text-light rounded-12 mb-3">
              {courseDetails && courseDetails[currentLanguage]?.description && (
                <p className="mb-3">
                  {parse(courseDetails[currentLanguage]?.description)}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-5"
          // style={{ maxHeight: "85vh" }}
        >
          <div className="shadow-sm p-2 text-light mb-3">
            <h5 className="mb-0">Enter Course Details</h5>
          </div>
          <Form
            className="text-light"
            onSubmit={(e) => saveEventDataToDBHandler(e, courseDetails)}
          >
            <Form.Group className="text-light">
              <Form.Label>
                Course Title
                <p className="small text-secondary mb-0">
                  It's ok if you can't think of a good title now. You can change
                  it later.
                </p>
              </Form.Label>
              <Form.Control
                maxLength="200"
                type="text"
                className="mb-3"
                placeholder="Name"
                value={courseDetails?.EN?.course_title}
                name="course_title"
                onChange={(event) => {
                  saveTextDataToStateHandler(event, "EN");
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Choose a category
                <p className="small text-secondary mb-0">
                  If you're not sure about the right category, you can change it
                  later.
                </p>
              </Form.Label>
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "#007bff",
                    primary: "#ffffff",
                    neutral0: "#212529",
                    neutral90: "#ffffff",
                    neutral80: "#ffffff",
                  },
                })}
                options={options}
                name="course_category"
                onChange={(e) => {
                  setCourseDetails((prev) => {
                    const updatedState = { ...prev, course_category: e.value };
                    return updatedState;
                  });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Starts at</Form.Label>
              <Flatpickr
                placeholder="Select date"
                data-enable-time
                value={courseDetails?.start_time}
                name="start_time"
                onChange={(selectedDate, dateStr, instance) =>
                  saveDateToStateHandler(selectedDate, dateStr, instance)
                }
                options={{ minDate: "today", minuteIncrement: 1 }}
                className="form-control bg-dark text-light"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Course subtitle
                <p className="small text-secondary mb-0">
                  Recommdended: Min. 50 words (Max. 120 characters)
                </p>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="course_subtitle"
                placeholder="Insert your course subtitle."
                onChange={(event) => {
                  saveTextDataToStateHandler(event, "EN");
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-0">
              <Form.Label>Outcomes</Form.Label>
            </Form.Group>
            <Form.Control
              type="text"
              name="outcomes_title"
              placeholder="Insert your course subtitle."
              onChange={(event) => {
                setOutcomes((prev) => {
                  const updatedState = {
                    ...prev,
                    title: event.target.value,
                    // ...prev["outcomes"],
                  };
                  return updatedState;
                });
              }}
            ></Form.Control>
            <Accordion
              activeKey={extraMaterialKey}
              onSelect={setExtraMaterialKey}
              flush
              className="col-md-12"
            >
              {outcomes?.outcomes_array?.map((pre, idx) => {
                return (
                  <Card key={idx} className="text-dark">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={idx + "ex"}
                      style={{ cursor: "pointer" }}
                      className={`text-white`}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <span className="font-weight-bold text-uppercase small text-dark">
                            Outcomes #{idx + 1}
                          </span>
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={
                              extraMaterialKey === idx + "ex"
                                ? faAngleUp
                                : faAngleDown
                            }
                            className="mr-4 text-dark"
                          />
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                              setOutcomes((prev) => {
                                const updatedState = produce(prev, (draft) => {
                                  draft.outcomes_array.splice(idx, 1);
                                });
                                return updatedState;
                              });
                            }}
                          >
                            X
                          </Button>
                        </div>
                        <div>
                          <span className="small"></span>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={idx + "ex"}>
                      <Card.Body className={`bg-graphite text-white`}>
                        <Form.Label>Outcome</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Outcome"
                          name="outcomes_array"
                          // value={courseDetails.outcomes[idx].link}
                          onChange={(e) =>
                            saveTextDataToArrayHandler(
                              e,
                              "outcomes_array",
                              idx,
                              setOutcomes
                            )
                          }
                          className="mb-2"
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
            <div className="text-center my-2 w-100">
              <Button
                variant="outline-light"
                size="sm"
                onClick={() => {
                  setOutcomes((prev) => {
                    const updatedState = produce(prev, (draft) => {
                      draft = {
                        ...prev,
                        outcomes_array: draft.outcomes_array.push(""),
                      };
                    });
                    return updatedState;
                  });
                }}
              >
                Add Outcome
              </Button>
            </div>

            {/* benefits */}
            <Form.Group className="mb-0">
              <Form.Label>Benefits</Form.Label>
            </Form.Group>
            <Form.Control
              type="text"
              name="outcomes_title"
              placeholder="Benefits"
              onChange={(event) => {
                setBenefits((prev) => {
                  const updatedState = {
                    ...prev,
                    title: event.target.value,
                  };
                  return updatedState;
                });
              }}
            ></Form.Control>
            <Form.Group className="mb-0">
              <Form.Label>Benefits Description</Form.Label>
            </Form.Group>
            <Form.Control
              type="text"
              name="outcomes_title"
              placeholder="Benefits Description"
              onChange={(event) => {
                setBenefits((prev) => {
                  const updatedState = {
                    ...prev,
                    benefits_desc: event.target.value,
                    // ...prev["outcomes"],
                  };
                  return updatedState;
                });
              }}
            ></Form.Control>
            <Accordion
              activeKey={extraMaterialKey}
              onSelect={setExtraMaterialKey}
              flush
              className="col-md-12"
            >
              {benefits?.benefits_array?.map((pre, idx) => {
                return (
                  <Card key={idx} className="text-dark">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={idx + "ex"}
                      style={{ cursor: "pointer" }}
                      className={`text-white`}
                    >
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <span className="font-weight-bold text-uppercase small text-dark">
                            Benefits #{idx + 1}
                          </span>
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={
                              extraMaterialKey === idx + "ex"
                                ? faAngleUp
                                : faAngleDown
                            }
                            className="mr-4 text-dark"
                          />
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                              setBenefits((prev) => {
                                const updatedState = produce(prev, (draft) => {
                                  draft.benefits_array.splice(idx, 1);
                                });
                                return updatedState;
                              });
                            }}
                          >
                            X
                          </Button>
                        </div>
                        <div>
                          <span className="small"></span>
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={idx + "ex"}>
                      <Card.Body className={`bg-graphite text-white`}>
                        <Form.Label>Benefit</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Benefit"
                          name="outcomes"
                          // value={courseDetails.outcomes[idx].link}
                          onChange={(e) =>
                            saveTextDataToArrayHandler(
                              e,
                              "benefits_array",
                              idx,
                              setBenefits
                            )
                          }
                          className="mb-2"
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
            <div className="text-center my-2 w-100">
              <Button
                variant="outline-light"
                size="sm"
                onClick={() => {
                  setBenefits((prev) => {
                    const updatedState = produce(prev, (draft) => {
                      draft = {
                        ...prev,
                        benefits_array: draft.benefits_array.push(""),
                      };
                    });
                    return updatedState;
                  });
                }}
              >
                Add Benefits
              </Button>
            </div>

            <Form.Label>
              Description
              <p className="small text-secondary mb-0">
                Recommdended: Min. 100 words (No Limit)
              </p>
            </Form.Label>
            <CKEditor
              id="editor"
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "heading",
                  "bold",
                  "italic",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "undo",
                  "redo",
                ],
              }}
              className="mb-3"
              data={courseDetails?.EN?.description ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                saveHTMLDataToStateHandler("description", data, "EN");
              }}
              style={{ height: 400 }}
            />
            <Form.Group className="col-md-12">
              <Form.Label>
                Course image
                <p className="small text-secondary mb-0">
                  Recommdended: 1200px X 628px
                </p>
              </Form.Label>
              <Form.Control
                type="file"
                className="mb-3"
                accept="image/*"
                placeholder="Course image"
                name="course_image"
                onChange={(event) => {
                  setCourseDetails((prevDetails) => {
                    return {
                      ...prevDetails,
                      course_image: event.target.files[0],
                    };
                  });
                }}
              />
            </Form.Group>
            <div className="pb-5">
              <Button className="form-control" variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  courseId: state.courseId,
}))(CourseDetails);
