import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { connect } from "react-redux";

const Pricing = (props) => {
    const currentUser = firebase.auth().currentUser;
    const { eventId, currentEvent } = props;
    const [eventSnapshot, loadingEvent, errorEvent] = useDocument(
        firebase.firestore().collection("events").doc(eventId)
    );


    return (
        <div className="container-fluid vh-100 overflow-auto" id="pricing">
            <div className="h-100 my-3 text-light" style={{ overflow: "scroll" }}>
                <div className="h-100" style={{ overflow: "auto" }}>
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Pricing</h5>
                            <p>Set your pricing here. Keep the box blank in case your product is available for free.</p>
                            <form className="form-inline">
                                <div className="form-group mb-2">
                                    <select className="form-control">
                                        <option>INR</option>
                                        <option>USD</option>
                                        <option>EUR</option>
                                    </select>
                                </div>
                                <div class="form-group mx-sm-3 mb-2">
                                    <label for="price" className="sr-only"></label>
                                    <input type="text" className="form-control" id="price" placeholder="0" />
                                </div>
                                <button type="submit" className="btn btn-primary mb-2">Save Price</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    eventId: state.eventId,
    currentEvent: state.currentEvent,
});
export default connect(mapStateToProps)(Pricing);