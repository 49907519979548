import React, { useState, useEffect, useMemo } from "react";
import EventLeftbar from "../LeftBar";
import EventNavbar from "../NavBar";
import Highcharts from "highcharts/highstock";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Dropdown,
  Modal,
  ButtonGroup,
  Card,
} from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HC_more from "highcharts/highcharts-more";
import firebase from "firebase";
import { TwitterPicker } from "react-color";
import moment from "moment";
import Tier from "./Tier";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import {
  faTimes,
  faComment,
  faTrash,
  faArrowLeft,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  setFactors,
  setProducts,
  setGraphId,
  setAsIsFactors,
} from "../../../../store/actions/actions";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import ErrcGrid from "./ErrcGrid";
import ToBe from "./To-be";
import Table from "./Table";
import SixPath from "./Sixpath";
import EventChat from "../EventChat";
import { useHistory, useParams } from "react-router";

HC_more(Highcharts);

require("highcharts/modules/draggable-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

var yourLabels = ["Low", "Medium", "High"];

function ASIS(props) {
  const { id } = useParams();
  let history = useHistory();
  const currentUser = firebase.auth().currentUser;
  const [title, setTitle] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [showErrc, setShowErrc] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("red");
  const [newFactorName, setNewFactorName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAttendeeCategory, setSelectedAttendeeCategory] = useState("");
  const [newFactorValue, setNewFactorValue] = useState({
    value: 0,
    name: "Low",
  });
  const [newProductName, setNewProductName] = useState("");
  const [errcCreated, setErrcCreated] = useState(false);
  const [toBeCreated, setToBeCreated] = useState(false);
  const [buyerExpAuditCreated, setBuyerExpAuditCreated] = useState(false);
  const [threeTier, setThreeTier] = useState(false);
  const [sixPath, setSixPath] = useState(false);
  const [toBe, setToBe] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const [showShare, setShowShare] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const [showFactorForm, setShowFactorForm] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);

  const [currentCords, setCurrentCords] = useState([]);
  const [showTierUpdateModal, setShowTierUpdateModal] = useState(false);
  const [selectedCords, setSelectedCords] = useState(null);
  const [showColorPickerForTier, setShowColorPickerForTier] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToCategory, setAssignedToCategory] = useState("");
  const [assignedToAll, setAssignedToAll] = useState(false);

  const { darkTheme } = props;

  const [coordinateValue, coodinateValueLoading, coodinateValueError] =
    useCollection(
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/three_tiers/`
        )
    );

  const coordinateArray = coordinateValue?.docs?.map((item) => {
    return { ...item.data(), id: item.id };
  });

  useEffect(() => {
    if (coordinateValue) {
      let data = coordinateArray;
      setCurrentCords(data);
    }
  }, [JSON.stringify(coordinateValue)]);

  useEffect(() => {
    if (props.match.params.appId) {
      props.setGraphId(props.match.params.appId);
    }
  }, []);

  const [usersSnapshot, userLoading, userError] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${props?.currentEvent?.id}/users`)
      .where("uid", "!=", currentUser.uid)
  );

  const allUsers = useMemo(() => {
    return usersSnapshot?.docs?.map((fac) => {
      return {
        uid: fac.id,
        label: fac?.data()?.firstName,
        value: fac?.data()?.firstName,
        ...fac.data(),
      };
    });
  }, [usersSnapshot]);

  const [currentUserDoc, currentUserLoading, currentUserError] = useDocument(
    firebase
      .firestore()
      .collection(`/events/${props?.currentEvent?.id}/users`)
      .doc(`${currentUser.uid}`)
  );

  const [
    attendeeCategorySnapshot,
    attendeeCategoryLoading,
    attendeeCategoryError,
  ] = useCollection(
    firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/attendee_category`)
      .orderBy("timestamp", "asc")
  );

  const attendeeCategoryList = useMemo(() => {
    return attendeeCategorySnapshot?.docs?.map((e) => {
      return {
        ...e.data(),
        id: e.id,
      };
    });
  }, [attendeeCategorySnapshot]);

  useEffect(() => {
    if (props.graphId) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`)
        .doc(props.graphId)
        .onSnapshot((snapshot) => {
          if (snapshot?.data()?.title) {
            setTitle(snapshot?.data()?.title);
            setErrcCreated(snapshot?.data()?.errc_created);
            setToBe(snapshot?.data()?.to_be_created);
            setBuyerExpAuditCreated(snapshot?.data()?.buyerExpAudit_created);
            setThreeTier(snapshot?.data()?.three_tiers);
            setSixPath(snapshot?.data()?.six_path);
            setCreatedAt(snapshot?.data()?.createdAt);
            setCreatedBy(snapshot?.data()?.createdBy);
            setAssignedTo(snapshot?.data()?.assignedTo);
            setAssignedToAll(snapshot?.data()?.assignedToAll);
            setAssignedToCategory(snapshot?.data()?.assignedToCategory);
          }
        });
    }
  }, [props]);

  const [factorsCollection, factorsCollectionLoading, error] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
      )
  );

  const factorsArray = factorsCollection?.docs
    ?.sort((a, b) => a.position - b.position)
    ?.map((item) => {
      return { ...item.data(), id: item.id };
    });

  useEffect(() => {
    if (factorsArray) {
      let data = factorsArray;
      let data2 = factorsArray.filter((f) => !f.isNew);
      props.setFactors([...data]);
      props.setAsIsFactors([...data2]);
    }
  }, [factorsCollection]);

  const [productCollection, productCollectionLoading, err] = useCollection(
    firebase
      .firestore()
      .collection(
        `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is`
      )
  );

  const getYDataHandler = async () => {
    try {
      if (productCollection?.docs?.length) {
        // setLoading(true)
        const collectionData = await Promise.all(
          productCollection?.docs?.map(async (item) => {
            let docRef = firebase
              .firestore()
              .collection(
                `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is/${item.id}/values`
              );

            const data = await Promise.all(
              props.asisFactors?.map(async (factor) => {
                const doc = await docRef.doc(factor.id).get();
                if (doc.exists) {
                  return doc.data().value;
                } else {
                  return factor.defaultValue;
                }
              })
            );
            if (data.length === props.asisFactors.length) {
              return {
                ...item.data(),
                id: item.id,
                data: data,
                dragDrop: { dragMaxY: 2, dragMinY: 0, draggableY: true },
              };
            }
          })
        );
        props.setProducts(collectionData);
      }
    } catch (e) {
      console.error(e, "Something went wrong");
    } finally {
      // setLoading(false)
    }
  };

  // useEffect(() => {
  //   setArray((prev) => {
  //     const newState = prev.map((product) => ({name: product.name}))
  //   })
  // },[props.products])

  useEffect(() => {
    if (!productCollectionLoading) {
      getYDataHandler();
    }
  }, [productCollectionLoading, productCollection]);

  // useEffect(() => {
  //   if (props.products.length && props.factors.length) {

  //   }
  // })
  // console.log(props.factors, props.series)
  const [dynamicOption, setDynamicOption] = useState({
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      // renderTo: "container",
      // animation: false,
    },
    xAxis: [],
    series: [],
  });

  const addNewFactor = () => {
    // console.log(newFactorName, newFactorValue);
    if (newFactorName && newFactorValue) {
      let data = {
        defaultValue: newFactorValue.value,
        name: newFactorName,
        position: props.factors.length,
      };
      if (props.graphId) {
        firebase
          .firestore()
          .collection(
            `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
          )
          .add(data)
          .then((doc) => {
            if (errcCreated) {
              firebase
                .firestore()
                .collection(
                  `events/${props?.currentEvent?.id}/bbos/${props.graphId}/errc_grid`
                )
                .doc("0")
                .set(
                  {
                    main: firebase.firestore.FieldValue.arrayUnion(doc.id),
                  },
                  {
                    merge: true,
                  }
                );
            }
          });
        props.factors.push(data);
        props.asisFactors.push(data);
        props.products.map((product) => {
          product.data.push(newFactorValue.value);
        });
        setNewFactorName("");
        setNewFactorValue({ value: 0, name: "Low" });
        setShowFactorForm(false);
        alert("Factor Added Successfully.");
      }
    }
  };

  useEffect(() => {
    setDynamicOption((prev) => {
      const newState = {
        ...prev,
        xAxis: props.asisFactors.map((x) => x.name),
        series: props.products,
      };
      return newState;
    });
  }, [JSON.stringify(props.products), JSON.stringify(props.asisFactors)]);

  let options = {
    chart: {
      type: /*lineTension ? "spline" :*/ "",
      renderTo: "container",
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: dynamicOption.xAxis,
    },

    yAxis: {
      min: 0,
      max: 2,
      labels: {
        formatter: function () {
          return yourLabels[this.value];
        },
      },
      title: {
        text: "Offering Level",
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.series.name;
      },
    },
    plotOptions: {
      series: {
        point: {
          events: {
            drag: function (e) {
              // console.log(e)
            },
            drop: function (e) {
              // console.log(
              //   e.target,
              //   e.target.category,
              //   e.target.y,
              //   e.target.series.userOptions.id,
              //   factorsArray
              // );
              let key = e.target.series.userOptions.id;
              let value = factorsArray.filter(
                (x) => x.name === e.target.category
              );
              // console.log(value, key, props.graphId);
              let document = firebase
                .firestore()
                .collection(
                  `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is/${key}/values`
                )
                .doc(value[0].id);
              if (document && document.exists) {
                document.update({
                  value: e.target.y,
                });
              } else {
                document.set({
                  value: e.target.y,
                });
              }
            },
          },
        },
        stickyTracking: false,
      },
      column: {
        stacking: "normal",
      },
      line: {
        cursor: "ns-resize",
      },
    },
    series: dynamicOption.series,
  };

  function handleOnDragEnd({ destination, source }) {
    //console.log(destination, source);
    let sourceIndex = props.asisFactors[source.index];
    let destinationIndex = props.asisFactors[destination.index];
    // console.log(sourceIndex.id, sourceIndex.position, "source");
    // console.log(destinationIndex.id, destinationIndex.position, "destination");

    if (destination) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .doc(sourceIndex.id)
        .set(
          {
            position: destinationIndex.position,
          },
          {
            merge: true,
          }
        );
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .doc(destinationIndex.id)
        .set(
          {
            position: sourceIndex.position,
          },
          {
            merge: true,
          }
        );
    }

    const item = Array.from(
      props.products?.map((x, key) => {
        const itemData = x;
        const [reorderedData] = itemData.data.splice(source.index, 1);
        itemData.data.splice(destination.index, 0, reorderedData);
        return itemData;
      })
    );
  }

  const handleSelectedShare = (graphId) => {
    if (selectedUsers.length > 0) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${graphId}`)
        .update(
          {
            assignedTo: firebase.firestore.FieldValue.arrayUnion(
              ...selectedUsers
            ),
            assignedToAll: false,
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
        })
        .catch((error) => console.log(error));
    }

    if (selectedAttendeeCategory != "") {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${graphId}`)
        .update({
          assignedToCategory: selectedAttendeeCategory,
          assignedToAll: false,
        })
        .then(() => {
          toast.success("Shared successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setShowShare(false);
          setSelectedAttendeeCategory("");
          setSelectedUsers([]);
        })
        .catch((error) => console.log(error));
    }
  };

  const revokeAccessFromAll = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .update({
          assignedToAll: false,
          assignedToCategory: "",
        })
        .then(() => {
          toast.success("Revoked Access successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const addNewProduct = () => {
    if (newProductName && selectedColor) {
      let data = {
        name: newProductName,
        color: selectedColor,
      };
      if (props.graphId) {
        firebase
          .firestore()
          .collection(
            `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is`
          )
          .add(data);
        setNewProductName("");
      }
    }
  };

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "white" : "white",
    display: "flex",
    padding: grid,
    overflow: "auto",
  });

  const titleHandler = () => {
    if (newTitle) {
      firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .add({
          title: newTitle,
          errc_created: false,
          to_be_created: false,
          buyerExpAudit_created: false,
          six_path: false,
        })
        .then((doc) => {
          props.setGraphId(doc.id);
          setTitle(newTitle);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const createErrcGrid = () => {
    if (props.graphId) {
      let idArray = props.factors.map((x) => x.id);
      let docRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`);
      docRef
        .doc(props.graphId)
        .update({
          errc_created: true,
        })
        .then(() => {
          firebase
            .firestore()
            .collection(
              `events/${props?.currentEvent?.id}/bbos/${props.graphId}/errc_grid`
            )
            .doc("0")
            .set({
              main: idArray.length ? idArray : [],
              eliminate: [],
              raise: [],
              reduce: [],
              create: [],
            });
        });
    }
  };

  const createThreeTier = () => {
    if (props.graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`);
      docRef
        .doc(props.graphId)
        .update({
          three_tiers: true,
        })
        .then(() => {
          // firebase
          //   .firestore()
          //   .collection(
          //     `events/WeMOfCPQp0QLivd1bo4K/bbos/${props.graphId}/three_tiers`
          //   )
          //   .doc("0")
          //   .set({
          //     coordinates: []
          //   });
        });
    }
  };

  const createBuyerExpAudit = () => {
    let heads = [
      {
        name: "Purchase",
      },
      {
        name: "Delivery",
      },
      {
        name: "Use",
      },
      {
        name: "Maintenance",
      },
      {
        name: "Disposal",
      },
      {
        name: "Renewal",
      },
    ];
    let rows = [
      {
        id: 0,
        name: "Productivity",
      },
      {
        id: 1,
        name: "Simplicity",
      },
      {
        id: 2,
        name: "Convenience",
      },
      {
        id: 3,
        name: "Risk",
      },
      {
        id: 4,
        name: "Social/ Family Acceptance",
      },
      {
        id: 5,
        name: "Sustainability",
      },
    ];
    if (props.graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`);
      docRef
        .doc(props.graphId)
        .update({
          buyerExpAudit_created: true,
        })
        .then(() => {
          firebase
            .firestore()
            .collection(
              `events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit`
            )
            .doc("0")
            .set({
              rows: rows,
            });
        });

      let docRef2 = firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/buyer_exp_audit/0/columns`
        );
      let user = firebase.auth().currentUser;
      heads.map((head, index) => {
        docRef2.doc(`${index + 1}`).set({
          name: head.name,
          created_at: new Date(),
          created_by: {
            name: user.displayName,
            email: user.email,
            id: user.uid,
          },
        });
      });
    }
  };
  const createSixPath = () => {
    let paths = [
      {
        name: "Path one",
        head: [
          {
            id: 0,
            name: "What are the alternative industries they choose?",
          },
          {
            id: 1,
            name: "Why do they trade across to them?",
          },
          {
            id: 2,
            name: "What are the negative aspects of this alternative? How can you alleviate them?",
          },
        ],
      },
      {
        name: "Path two",
        head: [
          {
            id: 0,
            name: "What are the strategic groups in your industry?",
          },
          {
            id: 1,
            name: "Why do buyers trade up for a higher strategic group?",
          },
          {
            id: 2,
            name: "Why do they trade down for a lower strategic group?",
          },
          {
            id: 3,
            name: "What are the negative aspects of this alternative? How can you alleviate them?",
          },
        ],
      },
      {
        name: "Path three",
        head: [
          {
            id: 0,
            name: "Purchaser Focus Insights",
          },
          {
            id: 1,
            name: "User Focus Insights",
          },
          {
            id: 2,
            name: "Influencer Focus Insights",
          },
          {
            id: 3,
            name: "What are the negative aspects of this alternative buyer group? How can you alleviate them?",
          },
        ],
      },
      {
        name: "Path four",
        head: [
          {
            id: 0,
            name: "What happens before your customers use of our product?",
          },
          {
            id: 1,
            name: "What happens during (around) the use of your product?",
          },
          {
            id: 2,
            name: "What happens after the use of your current product?",
          },
        ],
      },
      {
        name: "Path five",
        head: [
          {
            id: 0,
            name: "If you compete on emotional appeal, what elements can you strip out to make it functional?",
          },
          {
            id: 1,
            name: "If you compete on functionality, what elements can be added to make it emotional?",
          },
          {
            id: 2,
            name: "What are the negative aspects of this alternative? How can you alleviate them?",
          },
        ],
      },
      {
        name: "Path six",
        head: [
          {
            id: 0,
            name: "What trends have a high probability of impacting your industry, are irreversible, and evolving in a clear trajectory?",
          },
          {
            id: 1,
            name: "How will these trends impact your industry?",
          },
          {
            id: 2,
            name: "Given this, how can you open up unprecedented customer utility?",
          },
        ],
      },
    ];
    if (props.graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`);
      docRef
        .doc(props.graphId)
        .update({
          six_path: true,
        })
        .then(() => {
          paths.map((item, index) => {
            firebase
              .firestore()
              .collection(
                `events/${props?.currentEvent?.id}/bbos/${props.graphId}/six_path`
              )
              .doc(`${index}`)
              .set({
                head: item.head,
                name: item.name,
              });
          });
        });
    }
  };

  const addValueHandlerForTier = () => {
    //console.log(selectedCords)
    if (selectedCords) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/three_tiers`
        )
        .doc(selectedCords.id)
        .update({
          value: selectedCords.value,
          color: selectedCords.color,
        })
        .then(() => {
          setSelectedCords(null);
          setShowTierUpdateModal(false);
        });
    }
  };
  const deleteTierHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/three_tiers`
        )
        .doc(item.id)
        .delete();
    }
  };

  const createTobe = () => {
    if (props.graphId) {
      let docRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos`);
      docRef
        .doc(props.graphId)
        .update({
          to_be_created: true,
        })
        .then(() => {
          setToBe(true);
        });
    }
  };

  const deleteProductHandler = (item) => {
    if (item) {
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/as_is`
        )
        .doc(item.id)
        .delete()
        .then(() => {
          if (props.products.length == 1) {
            props.setProducts([]);
          }
          //
        });
    }
  };

  const deleteFactorHandler = (item) => {
    var index = _.findIndex(props.asisFactors, { id: item.id });

    if (item) {
      let val = props.products.map((item, key) => {
        let product = item;
        product.data.splice(index, 1);
        return product;
      });
      props.setProducts(val);
      firebase
        .firestore()
        .collection(
          `events/${props?.currentEvent?.id}/bbos/${props.graphId}/factors`
        )
        .doc(item.id)
        .delete();
    }
  };

  const revokeSharingAccess = (project_id) => {
    const projectRef = firebase
      .firestore()
      .collection(`events/${props?.currentEvent?.id}/bbos/`)
      .doc(`${project_id}`);

    projectRef
      .update({
        assignedToCategory: "",
      })
      .then(() => {
        toast.dark("Access revoked", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((error) => console.error("Revoke sharing access: ", error));
  };

  const UserDetails = ({ uid, idx, project_id }) => {
    const userDetails = allUsers?.filter((item) => item.uid === uid);
    return (
      <div className="d-flex align-items-center mb-1" key={idx}>
        <div className="mr-2">
          <Button
            size="sm"
            variant="danger"
            onClick={() => revokeSharingAccess(uid, project_id)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className="flex-grow-1">
          <p className="mb-0">
            {idx + 1}. {userDetails[0]?.label} ({userDetails[0]?.email})
          </p>
        </div>
      </div>
    );
  };

  const getAttendeeCategoryName = (id) => {
    return attendeeCategoryList?.find((item) => item.id === id)?.name;
  };

  const shareToAllHandler = (project_id) => {
    if (project_id) {
      const projectRef = firebase
        .firestore()
        .collection(`events/${props?.currentEvent?.id}/bbos/`)
        .doc(`${project_id}`);

      projectRef
        .set(
          {
            assignedToAll: true,
            assignedToCategory: "",
          },
          {
            merge: true,
          }
        )
        .then(() => {
          toast.success("Shared to all successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <>
      <div className="h-100 montserrat-font">
        <EventLeftbar mobile />
        <div className="container-fluid h-100" style={{ overflow: "scroll" }}>
          <EventNavbar />
          <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
            <EventLeftbar />
            <div
              className="h-100 col-md-7 p-3"
              style={{ overflow: "scroll" }}
              data-theme={darkTheme ? "dark" : "light"}
            >
              <React.Fragment>
                <Card
                  className={
                    darkTheme
                      ? "bg-dark shadow-sm border border-dark mb-3"
                      : "bg-white shadow-sm border border-light mb-3"
                  }
                >
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div class="row">
                        <Button
                          className=" ml-3"
                          variant="transparent"
                          onClick={() => history.push(`/event/${id}/projects`)}
                        >
                          <FontAwesomeIcon
                            className={darkTheme ? "text-light" : "text-dark"}
                            icon={faArrowLeft}
                          />
                        </Button>
                        <h5 className="mb-0 p-2 text-uppercase">{title}</h5>
                      </div>
                      <p className="ml-5 small">
                        {moment(createdAt).format("LLL")}
                      </p>
                    </div>
                    <div>
                      {(createdBy !== currentUser.uid ||
                        assignedToAll ||
                        assignedTo?.includes(currentUser.uid)) && (
                        <div className="p-2">
                          <>
                            {allUsers?.map((item) => (
                              <>
                                {item.uid === createdBy && (
                                  <p className=" small">{`Created By ${item.firstName}`}</p>
                                )}
                              </>
                            ))}
                          </>
                        </div>
                      )}
                      {createdBy === currentUser.uid && (
                        <div className="p-2">
                          <Button
                            size="sm"
                            onClick={() => {
                              setShowShare(true);
                              //setSelectedProject(graph);
                            }}
                            className="text-uppercase font-weight-bold"
                          >
                            <FontAwesomeIcon icon={faShare} size="sm" />{" "}
                            <small>Share</small>
                          </Button>

                          <Modal
                            show={showShare}
                            onHide={() => {
                              setShowShare(false);
                              setSelectedUsers([]);
                            }}
                          >
                            <Modal.Header
                              closeButton
                              className={
                                darkTheme
                                  ? "bg-dark border-secondary pb-1"
                                  : "bg-white border-light pb-1"
                              }
                            >
                              <div className="d-flex flex-column">
                                <p className="h4 mb-0">Share your project</p>
                                {/* {assignedTo?.length > 0 ? (
                                  <p className="mb-0">
                                    Shared with {assignedTo?.length} member
                                    {assignedTo?.length > 1 ? "s" : ""}
                                  </p>
                                ) : (
                                  <p className="mb-0">Not yet shared</p>
                                )} */}
                                {assignedToCategory || assignedToAll ? (
                                  <p className="mb-0">
                                    Shared with{" "}
                                    {assignedToAll ? (
                                      <b>All</b>
                                    ) : (
                                      <b>
                                        {getAttendeeCategoryName(
                                          assignedToCategory
                                        )}
                                      </b>
                                    )}{" "}
                                    attendee(s)
                                  </p>
                                ) : (
                                  <p className="mb-0">Not yet shared</p>
                                )}
                              </div>
                            </Modal.Header>
                            <Modal.Body className="text-dark">
                              {assignedTo?.length > 0 && (
                                <div className="mb-3">
                                  <p className="mb-2 text-uppercase small">
                                    Already shared with:
                                  </p>
                                  {assignedTo?.map((item, idx) => (
                                    <UserDetails
                                      uid={item}
                                      idx={idx}
                                      project_id={props.graphId}
                                    />
                                  ))}
                                </div>
                              )}

                              <p className="mb-1">
                                Select category to share your project
                              </p>
                              {/* <Select
                                isMulti
                                placeholder="Select people"
                                className="w-100 mb-2"
                                options={allUsers?.filter(
                                  (item) => !assignedTo?.includes(item.uid)
                                )}
                                onChange={(e) =>
                                  setSelectedUsers(e?.map((item) => item.uid))
                                }
                              /> */}

                              <Select
                                placeholder="Attendee Category"
                                options={attendeeCategoryList?.map((item) => {
                                  return {
                                    label: `${item.name}`,
                                    value: `${item.id}`,
                                  };
                                })}
                                onChange={(e) =>
                                  setSelectedAttendeeCategory(e.value)
                                }
                                className="w-100 mb-2 text-dark"
                              ></Select>

                              <Button
                                className="w-100 mb-2"
                                onClick={() =>
                                  handleSelectedShare(props.graphId)
                                }
                              >
                                Share
                              </Button>
                              {assignedToCategory && (
                                <>
                                  <Button
                                    variant="none"
                                    className="w-100 text-danger shadow-sm mb-2 border-danger"
                                    onClick={(e) => {
                                      setShowRevokeModal(true);
                                    }}
                                  >
                                    Revoke Access from{" "}
                                    <b>
                                      {" "}
                                      {getAttendeeCategoryName(
                                        assignedToCategory
                                      )}
                                    </b>
                                  </Button>
                                  <Modal
                                    show={showRevokeModal}
                                    onHide={() => setShowRevokeModal(false)}
                                  >
                                    <Modal.Header
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                      closeButton
                                    >
                                      <Modal.Title>Revoke Access</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      Are you sure you want to Revoke Access?
                                    </Modal.Body>
                                    <Modal.Footer
                                      className={
                                        darkTheme
                                          ? "bg-dark border-secondary pb-1"
                                          : "bg-white border-light pb-1"
                                      }
                                    >
                                      <Button
                                        variant="secondary"
                                        onClick={() =>
                                          setShowRevokeModal(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={(e) =>
                                          revokeSharingAccess(props.graphId)
                                        }
                                      >
                                        Revoke Access
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </>
                              )}
                              {assignedToCategory && (
                                <p className="small mb-0 text-muted">
                                  "{title}" is already being shared with{" "}
                                  <b>
                                    {getAttendeeCategoryName(
                                      assignedToCategory
                                    )}
                                  </b>
                                </p>
                              )}

                              <p className="my-2 text-center">OR</p>

                              {!assignedToAll && (
                                <Button
                                  className="w-100 mb-2"
                                  onClick={() =>
                                    shareToAllHandler(props.graphId)
                                  }
                                >
                                  Share with Everyone
                                </Button>
                              )}
                              {assignedToAll && (
                                <Button
                                  variant="none"
                                  className="w-100 text-danger shadow-sm border-danger"
                                  onClick={(e) => {
                                    revokeAccessFromAll(props.graphId);
                                  }}
                                >
                                  Revoke Access from <b>all</b>
                                </Button>
                              )}
                            </Modal.Body>
                          </Modal>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </React.Fragment>
              <React.Fragment>
                <Card
                  className={
                    darkTheme
                      ? "bg-dark shadow-sm border border-dark mb-3"
                      : "bg-white shadow-sm border border-light mb-3"
                  }
                >
                  <Card.Header>
                    <h5 className="mb-0 text-uppercase">Project Settings</h5>
                  </Card.Header>
                  <Card.Body className="p-1">
                    <div>
                      {!title && (
                        <div className="card montserrat-font m-2">
                          <div className="p-3">
                            <Form>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Add Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Title"
                                  value={newTitle}
                                  onChange={(e) => {
                                    setNewTitle(e.target.value);
                                  }}
                                />
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="submit"
                                onClick={(e) => {
                                  e.preventDefault();
                                  //console.log(newTitle);
                                  titleHandler();
                                }}
                              >
                                Submit
                              </Button>
                            </Form>
                          </div>
                        </div>
                      )}
                      <Card
                        className={
                          darkTheme
                            ? "bg-dark shadow-sm border border-dark montserrat-font m-2"
                            : "bg-white shadow-sm border border-light montserrat-font m-2"
                        }
                      >
                        <Card.Header>
                          {!showProductForm && (
                            <Button
                              size="sm"
                              className="text-uppercase"
                              onClick={() => setShowProductForm(true)}
                            >
                              Add Product
                            </Button>
                          )}
                          {showProductForm && (
                            <Form className="mb-0">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Product name</Form.Label>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="mr-1"
                                    onClick={() =>
                                      showColorPicker
                                        ? setShowColorPicker(false)
                                        : setShowColorPicker(true)
                                    }
                                    style={{
                                      height: 35,
                                      width: 35,
                                      borderRadius: 4,
                                      backgroundColor: selectedColor,
                                    }}
                                  ></div>
                                  {showColorPicker ? (
                                    <TwitterPicker
                                      onChangeComplete={(color) => {
                                        setShowColorPicker(false);
                                        setSelectedColor(color.hex);
                                      }}
                                      colors={[
                                        "#FF6900",
                                        "#FCB900",
                                        "#7BDCB5",
                                        "#00D084",
                                        "#1adef0",
                                        "#0693E3",
                                        "#ff26d7",
                                        "#EB144C",
                                        "#F78DA7",
                                        "#7500eb",
                                      ]}
                                    />
                                  ) : null}
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter name of product"
                                    value={newProductName}
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setNewProductName(e.target.value)
                                    }
                                  />
                                </div>
                              </Form.Group>
                              <div className="d-flex">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className="mr-2 text-uppercase"
                                  type="submit"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    addNewProduct();
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  className="text-uppercase"
                                  onClick={() => setShowProductForm(false)}
                                >
                                  Discard
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Card.Header>
                        <Card.Body>
                          {!props?.products?.length && (
                            <div className="d-flex align-items-center justify-content-center">
                              <p className="mb-0">
                                Click on <b>Add Product</b> above to add a
                                Product.
                              </p>
                            </div>
                          )}
                          {props.products?.map((item, key) => (
                            <div
                              className="d-flex align-items-center mb-1"
                              key={key}
                            >
                              <div
                                className="mr-2"
                                style={{
                                  height: 20,
                                  width: 20,
                                  borderRadius: 4,
                                  backgroundColor: item.color,
                                }}
                              ></div>
                              <p className="mb-0 flex-grow-1">{item.name}</p>
                              <div>
                                <Button
                                  onClick={() => {
                                    deleteProductHandler(item);
                                  }}
                                  size="sm"
                                  variant="danger"
                                >
                                  <div>
                                    <FontAwesomeIcon size="sm" icon={faTrash} />{" "}
                                    Remove
                                  </div>
                                </Button>
                              </div>
                            </div>
                          ))}
                        </Card.Body>
                      </Card>
                      <Card
                        className={
                          darkTheme
                            ? "bg-dark shadow-sm border border-dark montserrat-font m-2"
                            : "bg-white shadow-sm border border-light montserrat-font m-2"
                        }
                      >
                        <Card.Header>
                          {!showFactorForm && (
                            <Button
                              size="sm"
                              className="text-uppercase"
                              onClick={() => setShowFactorForm(true)}
                            >
                              Add Factor
                            </Button>
                          )}
                          {showFactorForm && (
                            <Form className="mb-0">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Factor name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter name of factor"
                                  autoComplete="off"
                                  value={newFactorName}
                                  onChange={(e) => {
                                    setNewFactorName(e.target.value);
                                  }}
                                />
                                <div className="d-flex py-2">
                                  <Form.Check
                                    type="radio"
                                    label="High"
                                    className="mr-3"
                                    value="names"
                                    name="view"
                                    onClick={(e) =>
                                      setNewFactorValue({
                                        value: 2,
                                        name: "High",
                                      })
                                    }
                                  />

                                  <Form.Check
                                    type="radio"
                                    label="Medium"
                                    className="mr-3"
                                    value="cross"
                                    name="view"
                                    onClick={(e) =>
                                      setNewFactorValue({
                                        value: 1,
                                        name: "Medium",
                                      })
                                    }
                                  />

                                  <Form.Check
                                    type="radio"
                                    label="Low"
                                    value="notes"
                                    checked={
                                      newFactorValue.value == 0 ? true : false
                                    }
                                    name="view"
                                    onClick={(e) =>
                                      setNewFactorValue({
                                        value: 0,
                                        name: "Low",
                                      })
                                    }
                                  />
                                </div>

                                <div className="d-flex">
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    size="sm"
                                    className="mr-2 text-uppercase"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addNewFactor();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="secondary"
                                    className="text-uppercase"
                                    onClick={() => setShowFactorForm(false)}
                                  >
                                    Discard
                                  </Button>
                                </div>
                              </Form.Group>
                            </Form>
                          )}
                        </Card.Header>
                        <Card.Body>
                          {props.asisFactors
                            ?.sort((a, b) => a.position - b.position)
                            ?.map((item, key) => (
                              <>
                                <div className="d-flex align-items-center mb-1">
                                  <div className="mr-2 flex-grow-1">
                                    {key + 1}. {item.name}
                                  </div>
                                  <div>
                                    <Button
                                      onClick={() => {
                                        deleteFactorHandler(item);
                                      }}
                                      size="sm"
                                      variant="danger"
                                    >
                                      <div>
                                        <FontAwesomeIcon
                                          size="sm"
                                          icon={faTrash}
                                        />{" "}
                                        Remove
                                      </div>
                                    </Button>
                                  </div>
                                </div>
                              </>
                            ))}
                        </Card.Body>
                      </Card>
                    </div>
                  </Card.Body>
                </Card>
              </React.Fragment>

              <React.Fragment>
                <Card
                  className={
                    darkTheme
                      ? "bg-dark shadow-sm border border-dark mb-3"
                      : "bg-white shadow-sm border border-light mb-3"
                  }
                >
                  <Card.Header>
                    <h5 className="mb-0 text-uppercase">
                      {props.currentEvent?.bbos_names?.as_is ??
                        "As Is Strategy"}{" "}
                      Canvas
                    </h5>
                  </Card.Header>
                  <Card.Body className="p-1">
                    <div className="">
                      <HighchartsReact
                        constructorType={"chart"}
                        highcharts={Highcharts}
                        options={options}
                      />
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                          >
                            <div className="row-reverse"></div>
                            {props?.asisFactors
                              ?.sort((a, b) => a.position - b.position)
                              ?.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={
                                        index == 0
                                          ? "btn btn-light sm"
                                          : "btn btn-light sm"
                                      }
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // style={getItemStyle(
                                      //   snapshot.isDragging,
                                      //   provided.draggableProps.style
                                      // )}
                                    >
                                      {item.name}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Card.Body>
                  <Card.Footer>
                    <p className="mb-0 text-uppercase small">
                      <b>Pro tips</b>
                    </p>
                    <ol className="px-3 mb-1 pb-1">
                      <li>
                        <small>
                          Drag factors to change their position on x-axis.
                        </small>
                      </li>
                      <li>
                        <small>
                          Drag points on y-axis to change their value.
                        </small>
                      </li>
                      <li>
                        <small>
                          To add more <b>Products</b> and <b>Factors</b> go to
                          project settings.
                        </small>
                      </li>
                      <li>
                        <small>
                          To hide or show <b>Product</b> graph click on the
                          Legend below.
                        </small>
                      </li>
                    </ol>
                  </Card.Footer>
                </Card>
              </React.Fragment>

              <div>
                {buyerExpAuditCreated && <Table />}
                {!buyerExpAuditCreated && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createBuyerExpAudit()}
                  >
                    Create{" "}
                    {props.currentEvent?.bbos_names?.buyer_experience ??
                      "Buyer Experience Audit"}
                  </Button>
                )}

                <br />

                {threeTier && <Tier />}
                {!threeTier && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createThreeTier()}
                  >
                    Create{" "}
                    {props.currentEvent?.bbos_names?.three_tier ??
                      "Three Tiers of Non-Customers"}
                  </Button>
                )}

                <br />

                {sixPath && <SixPath />}
                {!sixPath && (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createSixPath()}
                  >
                    Create Six-Path{" "}
                  </Button>
                )}

                <br />

                {errcCreated ? (
                  <>
                    <ErrcGrid />
                  </>
                ) : (
                  <Button
                    className="w-100 mb-2"
                    onClick={() => createErrcGrid()}
                  >
                    Create {props.currentEvent?.bbos_names?.errc ?? "Errc Grid"}
                  </Button>
                )}

                <br />

                {toBe && <ToBe />}
                {!toBe && (
                  <Button className="w-100 mb-2" onClick={() => createTobe()}>
                    Create{" "}
                    {props.currentEvent?.bbos_names?.to_be ?? "To Be Strategy"}{" "}
                    Canvas
                  </Button>
                )}
              </div>

              <br />
            </div>
            <div
              className="col-md-4 h-100 px-0 d-none d-md-block  "
              style={{ overflow: "scroll" }}
            >
              <EventChat
                event_id={props?.currentEvent?.id}
                tabs={
                  props?.currentEvent?.people_enabled
                    ? [
                        {
                          name: "Chat",
                          type: "chat",
                          chatroute: `/events/${props?.currentEvent?.id}/questionsAsked/`,
                        },
                        {
                          name: "People",
                          type: "people",
                        },
                      ]
                    : [
                        {
                          name: "Chat",
                          type: "chat",
                          chatroute: `/events/${props?.currentEvent?.id}/questionsAsked/`,
                        },
                      ]
                }
              />
            </div>
            {/* Chat Mobile view */}
            {showChat && (
              <div
                className="shadow-lg border border-muted"
                style={{
                  height: "55vh",
                  position: "fixed",
                  bottom: "17vh",
                  left: 10,
                }}
              >
                <EventChat event_id={props?.currentEvent?.id} />
              </div>
            )}
            <div
              style={{
                position: "fixed",
                height: 45,
                width: 45,
                bottom: "10.5vh",
                left: 5,
              }}
              className="bg-primary rounded-circle d-block d-md-none"
            >
              <a
                className="d-flex align-items-center justify-content-center"
                onClick={() => setShowChat((prev) => !prev)}
                style={{
                  height: 45,
                  width: 45,
                }}
              >
                <FontAwesomeIcon
                  className="text-white"
                  icon={showChat ? faTimes : faComment}
                  style={{ fontSize: 20 }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({
    currentLanguage: state.currentLanguage,
    workspaceId: state.workspaceId,
    currentEvent: state.currentEvent,
    roomId: state.roomId,
    graphId: state.graphId,
    factors: state.factors,
    products: state.products,
    series: state.series,
    asisFactors: state.asisFactors,
    darkTheme: state.darkTheme,
  }),
  {
    setFactors,
    setProducts,
    setGraphId,
    setAsIsFactors,
  }
)(ASIS);
