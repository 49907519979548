import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Button,
  Form,
  Card,
  Dropdown,
  Table,
  Modal,
  Spinner,
  Alert,
  InputGroup,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faCalendarAlt,
  faTicketAlt,
  faClipboardList,
  faEnvelope,
  faPaperPlane,
  faTrash,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import EventManagerNavbar from "./EventManagerNavbar";
import EventNavbar from "./Navbar";
import EventManagerLeftbar from "./EventManagerLeftbar";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sendEmail } from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { languageText as languageTextToast } from "./language.json";
import { connect } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import ReactExport from "react-export-excel";

const Attendees = (props) => {
  const [attendeesSnapshot, loading, error] = useCollection(
    firebase
      .firestore()
      .collection(`/courses/${props.courseId}/users`)
      .orderBy("first_name")
  );
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [selectedUID, setSelectedUID] = useState(null);
  const [selectedDisplayName, setSelectedDisplayName] = useState("");
  const [typedDisplayName, setTypedDisplayName] = useState("");

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [boothSnapshot, loadingBooth, errorBooth] = useCollection(
    firebase.firestore().collection(`/courses/${props.courseId}/booths`)
  );

  let attendeeDataArray = attendeesSnapshot?.docs.map((doc) => ({
    ...doc.data(),
    display_name: doc.data().first_name + " " + doc.data().last_name,
    id: doc.id,
  }));

  let boothDataArray = boothSnapshot?.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  // const attendeeKeys = _.keyBy(attendeeDataArray, "email_address");

  // const boothKeys = _.keyBy(boothDataArray, (o) => {
  //   return o.email_address_of_owner[0];
  // });

  // attendeeDataArray = _.values(_.merge(attendeeKeys, boothKeys));
  // console.log(attendeeDataArray, "attendeedataarray");

  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");
  const allAttendeesEmailArray = !loading
    ? attendeesSnapshot.docs.map((a) => a.data().email_address)
    : [];
  const [emailAllAttendee, setEmailAllAttendee] = useState(false);

  const [currentFilterCriteria, setCurrentFilterCriteria] = useState(null);
  const [currentSortCriteria, setCurrentSortCriteria] = useState(null);

  let filteredDataArray =
    currentFilterCriteria !== null
      ? attendeeDataArray.filter(
          (attendee) => attendee.user_role === currentFilterCriteria
        )
      : attendeeDataArray;

  filteredDataArray = filteredDataArray?.map((item) => {
    return {
      ...item,
      first_name: item.first_name.toLowerCase(),
      last_name: item.last_name.toLowerCase(),
    };
  });

  filteredDataArray =
    currentSortCriteria !== null
      ? _.orderBy(
          filteredDataArray,
          currentSortCriteria.value.split("-")[0],
          currentSortCriteria.sortOrder
        )
      : filteredDataArray;

  const undefinedHandler = (value) => {
    if (value) {
      return value;
    } else {
      return "N/A";
    }
  };
  const { currentLanguage } = props;
  const userRoleHandler = (number) => {
    if (number) {
      return "VIP Ticket";
    } else {
      return "Visitor Ticker";
    }
  };

  const filterOptions = [
    { value: null, label: "All" },
    { value: 0, label: "Visitor" },
    { value: 1, label: "VIP" },
    // { value: 2, label: "Speaker/Booth owner" },
  ];

  const sortOptions = [
    { value: "first_name-asc", label: "First name: A-Z ", sortOrder: "asc" },
    { value: "first_name-desc", label: "First name: Z-A", sortOrder: "desc" },
    { value: "last_name-asc", label: "Last name: A-Z", sortOrder: "asc" },
    { value: "last_name-desc", label: "Last name: Z-A", sortOrder: "desc" },
    {
      value: "timestamp-asc",
      label: "Joining time: Oldest-Latest",
      sortOrder: "asc",
    },
    {
      value: "timestamp-desc",
      label: "Joining time: Latest-Oldest",
      sortOrder: "desc",
    },
  ];

  const deleteUserHandler = (uid) => {
    firebase
      .firestore()
      .collection(`/courses/${props.courseId}/users`)
      .doc(uid)
      .delete();
  };

  if (loading) return <Spinner size="sm" animation="border" />;
  if (error) return <h5>Something went wrong!</h5>;

  function EmailAttendeeModal(props) {
    const [show, setShow] = useState(false);

    const [subject, setSubject] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const sender = '"igesia Inc." <noreply@igesia.co>';

    const [discardShow, setDiscardShow] = useState(false);

    const isThereAnyContent = useMemo(
      () => subject != "" || messageBody != "",
      [subject, messageBody]
    );

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const discardEmail = () => {
      setSubject("");
      setMessageBody("");
      setDiscardShow(false);
      handleClose();
    };

    const { first_name, last_name, email_address, sendEmail } = props;

    const submitEmail = async () => {
      if (isThereAnyContent) {
        try {
          const response = await sendEmail({
            to: email_address,
            subject: subject,
            content: messageBody,
            from: sender,
            replyTo: "direction@blue-awards.com",
          });
          if (response.status === 200) {
            toast.success("Email sent successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else if (response.status !== 200) {
            toast.error("There was an error in sending email", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        } catch (error) {
          console.log("Email error: ", error);
        } finally {
          handleClose();
        }
      } else {
        toast.error("One or more field(s) are empty", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    };

    return (
      <>
        <Button
          variant="outline"
          className="btn-sm btn-outline-light"
          onClick={handleShow}
        >
          <span>Send email</span> <FontAwesomeIcon icon={faEnvelope} />
        </Button>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title className="h6 font-weight-bold">
              Email Attendee
            </Modal.Title>
          </Modal.Header>
          <p
            className="mb-1 p-2 border-bottom"
            style={{ borderColor: "#515151" }}
          >
            <span className="small text-muted mr-2">To</span> {first_name}{" "}
            {last_name} ({email_address})
          </p>
          <Modal.Body>
            <Form.Group className="mb-1">
              <Form.Label className="small text-muted font-weight-bold">
                Subject
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </Form.Group>

            <Form.Group className="my-1">
              <Form.Label className="small text-muted font-weight-bold">
                Message body
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                data={messageBody ?? ""}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMessageBody(data);
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </Form.Group>

            <Alert show={discardShow} variant="danger">
              <Alert.Heading className="h6">Discard</Alert.Heading>
              <p className="small">Are you sure you want to discard?</p>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  onClick={discardEmail}
                  variant="outline-danger"
                  className="mr-2"
                >
                  Yes, discard
                </Button>
                <Button
                  size="sm"
                  onClick={() => setDiscardShow(false)}
                  variant="outline-secondary"
                >
                  No, cancel discard
                </Button>
              </div>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            {isThereAnyContent ? (
              <Button
                variant="secondary"
                onClick={() => setDiscardShow(true)}
                disabled={discardShow}
              >
                <span>
                  Discard{" "}
                  <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
                </span>
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                <span>Close</span>
              </Button>
            )}
            <Button variant="primary" onClick={submitEmail}>
              <span>
                Send{" "}
                <FontAwesomeIcon
                  size="sm"
                  icon={faPaperPlane}
                  className="ml-1"
                />
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  function EmailAllAttendeeModal(props) {
    const [subject, setSubject] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const sender = '"igesia Inc." <noreply@igesia.co>';

    const [discardShow, setDiscardShow] = useState(false);

    const isThereAnyContent = useMemo(
      () => subject !== "" || messageBody !== "",
      [subject, messageBody]
    );

    const handleClose = () => props.setShow(false);
    const handleShow = () => props.setShow(true);

    const discardEmail = () => {
      setSubject("");
      setMessageBody("");
      setDiscardShow(false);
      handleClose();
    };

    const { first_name, last_name, email_address, sendEmail } = props;

    const submitEmail = async () => {
      if (isThereAnyContent) {
        try {
          let response = [];
          if (Array.isArray(email_address)) {
            response = await Promise.all(
              email_address.map(async (email) => {
                return await sendEmail({
                  to: email,
                  subject: subject,
                  content: messageBody,
                  from: sender,
                  replyTo: "direction@blue-awards.com",
                });
              })
            );
          }
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } catch (error) {
          console.log("Email error: ", error);
          toast.error("There was an error in sending email", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } finally {
          handleClose();
        }
      } else {
        toast.error("One or more field(s) are empty", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    };

    return (
      <>
        <Modal
          show={props.show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title className="h6 font-weight-bold">
              Email Attendees
            </Modal.Title>
          </Modal.Header>
          <p
            className="mb-1 p-2 border-bottom"
            style={{ borderColor: "#515151" }}
          >
            <span className="small text-muted mr-2">To</span> all attendees
          </p>
          <Modal.Body>
            <Form.Group className="mb-1">
              <Form.Label className="small text-muted font-weight-bold">
                Subject
              </Form.Label>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </Form.Group>

            <Form.Group className="my-1">
              <Form.Label className="small text-muted font-weight-bold">
                Message body
              </Form.Label>
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "undo",
                    "redo",
                  ],
                }}
                data={messageBody ?? ""}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setMessageBody(data);
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
            </Form.Group>

            <Alert show={discardShow} variant="danger">
              <Alert.Heading className="h6">Discard</Alert.Heading>
              <p className="small">Are you sure you want to discard?</p>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  onClick={discardEmail}
                  variant="outline-danger"
                  className="mr-2"
                >
                  Yes, discard
                </Button>
                <Button
                  size="sm"
                  onClick={() => setDiscardShow(false)}
                  variant="outline-secondary"
                >
                  No, cancel discard
                </Button>
              </div>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            {isThereAnyContent ? (
              <Button
                variant="secondary"
                onClick={() => setDiscardShow(true)}
                disabled={discardShow}
              >
                <span>
                  Discard{" "}
                  <FontAwesomeIcon size="sm" icon={faTrash} className="ml-1" />
                </span>
              </Button>
            ) : (
              <Button variant="secondary" onClick={handleClose}>
                <span>Close</span>
              </Button>
            )}
            <Button variant="primary" onClick={submitEmail}>
              <span>
                Send{" "}
                <FontAwesomeIcon
                  size="sm"
                  icon={faPaperPlane}
                  className="ml-1"
                />
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const ticketTypeHandler = (type) => {
    if (type) {
      return "VIP";
    } else {
      return "Visitor";
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchTerm(e.target.value);
    const reg = _.escapeRegExp(searchTerm);
    // console.log(reg,"Reg")
    const regex = new RegExp(reg, "gi");
    setRegex(regex);
    //this.setState({ regex: regex })
  };

  return (
    <>
      <div className="container-fluid py-4">
        <div className="mt-3 overflow-auto h-100">
          <div className="col-md-12 h-100">
            <div className="row">
              <div className="col-md-12 mb-3 text-light">
                <div>
                  <h5>Attendees</h5>
                  <div className="row mb-2 justify-content-between">
                    <div className="col-md-6">
                      <div className="d-flex align-items-center rounded border w-100 search-bar">
                        <div className="py-2 pr-2 pl-3 bg-dark">
                          <FontAwesomeIcon
                            icon={faSearch}
                            size="sm"
                            color="#aeaeae"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <Form.Control
                            type="text"
                            id="participantsSearch"
                            className="border-0 px-0 rounded bg-dark"
                            onChange={handleSearch}
                            placeholder={"Search by name, email"}
                            value={searchTerm}
                            style={{ margin: 0 }}
                          />
                        </div>
                        {searchTerm.length > 0 && (
                          <div
                            className="py-2 pl-2 pr-3 bg-dark"
                            onClick={() => setSearchTerm("")}
                          >
                            <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex col-md-5">
                      <Select
                        placeholder="Ticket type"
                        options={filterOptions}
                        onChange={(e) => setCurrentFilterCriteria(e.value)}
                        className="w-100 mx-3"
                      ></Select>
                      <Select
                        placeholder="Sort"
                        options={sortOptions}
                        onChange={(e) => setCurrentSortCriteria(e)}
                        className="w-100 mx-3"
                      ></Select>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div></div>

                    <div className="d-flex">
                      <Button
                        onClick={() => {
                          setEmailAllAttendee(true);
                        }}
                        className="btn-sm btn-primary mr-2"
                      >
                        Email Attendees
                      </Button>
                      <Button className="btn-sm btn-secondary disabled mr-2">
                        Register Offline
                      </Button>
                      <ExcelFile
                        element={
                          <button className="mr-2 btn btn-outline-dark btn-sm">
                            Export
                          </button>
                        }
                      >
                        <ExcelSheet data={filteredDataArray} name="Attendees">
                          <ExcelColumn
                            label="Name"
                            value={(participant) =>
                              participant.first_name +
                              " " +
                              participant.last_name
                            }
                          />
                          <ExcelColumn
                            label="Email"
                            value={(participant) => participant.email_address}
                          />
                          <ExcelColumn
                            label="Phone"
                            value={(participant) =>
                              participant.phone_number?.number
                            }
                          />
                          <ExcelColumn
                            label="Ticket type"
                            value={(participant) =>
                              ticketTypeHandler(participant.user_role)
                            }
                          />
                          <ExcelColumn
                            label="Registration time"
                            value={(participant) =>
                              moment(participant.timestamp.toDate()).format(
                                "lll"
                              )
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              {searchTerm && searchTerm.trim().length ? (
                <>
                  {filteredDataArray
                    .filter((item) => {
                      return (
                        item?.display_name?.match(regex) ||
                        item?.email_address?.match(regex) ||
                        item?.phone_number?.number?.match(regex)
                      );
                    })
                    .map((attendee, index) => {
                      return (
                        <div className="col-md-4 mb-4 col-12" key={index}>
                          <div className="icon-box bg-dark rounded shadow-sm text-light w-100 h-100 px-4 py-3">
                            <div className="d-flex align-items-center">
                              <div className="mr-2 small">{index + 1}</div>
                              <div>
                                <Avatar
                                  name={
                                    attendee.first_name +
                                    " " +
                                    attendee.last_name
                                  }
                                  round={true}
                                  size="40"
                                  textSizeRatio={1.75}
                                  className="mr-3"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h3 className="text-s-10 mb-0 text-capitalize">
                                  {attendee.first_name +
                                    " " +
                                    attendee.last_name}
                                </h3>
                                <p className="small mb-0">
                                  {attendee.email_address}
                                </p>
                                <p className="small mb-0">
                                  {attendee.phone_number?.number}
                                </p>
                                <p className="small mb-0">
                                  {ticketTypeHandler(attendee.user_role)}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <EmailAttendeeModal
                                email_address={attendee.email_address}
                                phone_number={attendee.phone_number?.number}
                                first_name={attendee.first_name}
                                last_name={attendee.last_name}
                                sendEmail={sendEmail}
                              />
                              <Button
                                variant="outline"
                                className="btn-sm btn-outline-danger ml-1"
                                onClick={() => {
                                  setSelectedDisplayName(
                                    attendee.first_name + attendee.last_name
                                  );
                                  setSelectedUID(attendee.id);
                                  setDeleteWarningModal(true);
                                }}
                              >
                                <span>Delete</span>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {filteredDataArray &&
                    filteredDataArray.map((attendee, index) => {
                      return (
                        <div className="col-md-4 mb-4 col-12" key={index}>
                          <div className="icon-box bg-dark text-light rounded shadow-sm w-100 h-100 px-4 py-3">
                            <div className="d-flex align-items-center">
                              <div className="mr-2 small">{index + 1}</div>
                              <div>
                                <Avatar
                                  name={
                                    attendee.first_name +
                                    " " +
                                    attendee.last_name
                                  }
                                  round={true}
                                  size="40"
                                  textSizeRatio={1.75}
                                  className="mr-3"
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h3 className="text-s-10 mb-0 text-capitalize">
                                  {attendee.first_name +
                                    " " +
                                    attendee.last_name}
                                </h3>
                                <p className="small mb-0">
                                  {attendee.email_address}
                                </p>
                                <p className="small mb-0">
                                  {attendee.phone_number?.number}
                                </p>
                                <p className="small mb-0">
                                  {ticketTypeHandler(attendee.user_role)}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <EmailAttendeeModal
                                email_address={attendee.email_address}
                                phone_number={attendee.phone_number?.number}
                                first_name={attendee.first_name}
                                last_name={attendee.last_name}
                                sendEmail={sendEmail}
                              />
                              <Button
                                variant="outline"
                                className="btn-sm btn-outline-danger ml-1"
                                onClick={() => {
                                  setSelectedDisplayName(
                                    attendee.first_name + attendee.last_name
                                  );
                                  setSelectedUID(attendee.id);
                                  setDeleteWarningModal(true);
                                }}
                              >
                                <span>Delete</span>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <EmailAllAttendeeModal
        email_address={allAttendeesEmailArray}
        sendEmail={sendEmail}
        show={emailAllAttendee}
        setShow={(e) => setEmailAllAttendee(e)}
      />

      <Modal
        show={deleteWarningModal}
        onHide={() => {
          setSelectedUID("");
          setTypedDisplayName("");
          setDeleteWarningModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>Confirm deletion</Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete? This action cannot be reversed</p>
          <div className="d-flex justify-content-end flex-column">
            {/* <p>
              Confirm that you want to delete the attendee by typing its name:
              <span className="font-weight-bold">{selectedDisplayName}</span>
            </p>
            <Form.Control
              type="text"
              placeholder={`${selectedDisplayName}`}
              onChange={(e) => {
                setTypedDisplayName(e.target.value);
              }}
            /> */}
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                className="btn-sm mr-3"
                onClick={() => {
                  setSelectedUID("");
                  setTypedDisplayName("");
                  setDeleteWarningModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="btn-sm"
                onClick={() => {
                  // if (selectedDisplayName === typedDisplayName) {
                  deleteUserHandler(selectedUID);
                  setDeleteWarningModal(false);
                  setSelectedUID("");
                  setTypedDisplayName("");
                  toast.success("Attendee deleted", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  // } else {
                  //   toast.error("Confirm deletion by typing attendee's name", {
                  //     position: toast.POSITION.TOP_CENTER,
                  //     hideProgressBar: true,
                  //     autoClose: 3000,
                  //   });
                  // }
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  courseId: state.courseId,
}))(Attendees);
