import React, { useState, useMemo, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faComment } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import "@culturehq/add-to-calendar/dist/styles.css";
import { changeLanguage } from "../../../../store/actions/actions";
import { connect } from "react-redux";
import LeftBar from "../LeftBar";
import NavBar from "../NavBar";
import EventChat from "../EventChat";
import { useHistory } from "react-router";
import { Button, Card } from "react-bootstrap";
import moment from "moment";

function IgesiaForms({ darkTheme, currentEvent }) {
  const history = useHistory();
  const user = firebase.auth().currentUser;
  const [showChat, setShowChat] = useState(false);

  const [formsSnapshot, formsLoading, formsError] = useCollection(
    firebase
      .firestore()
      .collection(`/events/${currentEvent?.id}/forms`)
      .where("published", "==", true)
    // .orderBy("created_at", "asc")
  );

  const formsData = useMemo(() => {
    return formsSnapshot?.docs?.map((e) => {
      return { id: e.id, ...e.data() };
    });
  }, [formsSnapshot]);

  const convertSecondstoMilliSeconds = (seconds, nanoseconds = 0) => {
    return Math.floor(seconds * 1000 + nanoseconds / 1000000);
  };

  return (
    <div className="h-100">
      <LeftBar mobile />
      <div className="container-fluid h-100" style={{ overflow: "hidden" }}>
        <NavBar />
        <div className="row" style={{ height: "93vh", overflow: "hidden" }}>
          <LeftBar />
          <div
            className="col-md-7 h-100 p-md-5 p-3 "
            data-theme={darkTheme ? "dark" : "light"}
            style={{ overflow: "scroll" }}
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={
                    darkTheme
                      ? "shadow-sm p-3 rounded bg-dark"
                      : "shadow-sm p-3 rounded bg-white"
                  }
                >
                  <h3>Forms</h3>
                </div>
              </div>
            </div>
            {formsData?.length > 0 ? (
              formsData?.map((formData, index) => (
                <Card
                  key={index}
                  className={
                    darkTheme ? "bg-dark mb-2 pointer" : "bg-white mb-2 pointer"
                  }
                  onClick={() => {
                    history.push(
                      `/event/${currentEvent?.slug}/forms/${formData?.id}`
                    );
                  }}
                >
                  <Card.Body>
                    <div className="d-flex mb-0">
                      <p className="flex-grow-1 mb-0 small">
                        {moment(
                          convertSecondstoMilliSeconds(
                            formData?.created_at?.seconds,
                            formData?.created_at?.nanoseconds
                          )
                        ).format("LLL")}
                      </p>

                      {formData?.form_required ? (
                        <p className="mb-0 small text-danger">*Mandatory</p>
                      ) : null}
                    </div>
                    <p className="h4 mb-2">{formData?.form_title}</p>

                    <p className="small text-muted mb-0">
                      <b>No. of questions:</b> {formData?.form_fields?.length}
                    </p>
                  </Card.Body>
                  <Card.Footer className="border-top">
                    <p className="mb-0 small">
                      <b>Deadline by </b>
                      {moment(formData?.ends_at).format("LLL")}
                    </p>
                  </Card.Footer>
                </Card>
              ))
            ) : (
              <div className="w-100 text-center">
                <p>No forms available yet!</p>
              </div>
            )}
          </div>
          <div
            className="col-md-4 h-100 px-0 d-none d-md-block"
            style={{ overflow: "scroll" }}
          >
            <EventChat
              event_id={currentEvent?.id}
              tabs={
                currentEvent?.people_enabled
                  ? [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                      {
                        name: "People",
                        type: "people",
                      },
                    ]
                  : [
                      {
                        name: "Chat",
                        type: "chat",
                        chatroute: `/events/${currentEvent?.id}/questionsAsked/`,
                      },
                    ]
              }
            />
          </div>
        </div>
      </div>
      {/* Chat Mobile view */}
      {showChat && (
        <div
          className="shadow-lg border border-muted"
          style={{
            height: "55vh",
            position: "fixed",
            bottom: "17vh",
            left: 10,
          }}
        >
          <EventChat event_id={currentEvent?.id} />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: 45,
          width: 45,
          bottom: "10.5vh",
          left: 5,
        }}
        className="bg-primary rounded-circle d-block d-md-none"
      >
        <a
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShowChat((prev) => !prev)}
          style={{
            height: 45,
            width: 45,
          }}
        >
          <FontAwesomeIcon
            className="text-white"
            icon={showChat ? faTimes : faComment}
            style={{ fontSize: 20 }}
          />
        </a>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    darkTheme: state.darkTheme,
    currentEvent: state.currentEvent,
  }),
  { changeLanguage }
)(IgesiaForms);
