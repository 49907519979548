import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import {
    API_BASE_URL,
    USER_EXISTS,
    STRIPE_CREATE_SESSION,
    STRIPE_RETRIEVE_SESSION,
} from "../../config/index";
import { postData, sendEmail } from "../../utils/index";
import firebase from "firebase";
import {
    useHistory,
    useLocation,
    withRouter,
    useParams,
} from "react-router-dom";
import { Navbar, Spinner, Dropdown } from "react-bootstrap";
import axios from "axios";
import { values } from "lodash";
import { loadState, saveState } from "../../services/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faEye,
    faEyeSlash,
    faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

function AdminLogin(props) {
    const userDetails = firebase.auth().currentUser;

    const loginFormRef = useRef(null);

    const [email, setEmail] = useState(
        window.localStorage.getItem("values")
            ? JSON.parse(window.localStorage.getItem("values")).email
            : ""
    );
    const [pass, setPass] = useState("");
    const [forgotPassword, setForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [writingUserData, setWritingUserData] = useState(false);
    const [loggingSpinner, setLoggingSpinner] = useState(false);

    const updateStateHandler = (type, value) => {
        if (type === "email") {
            setEmail(value);
        } else if (type === "pass") {
            setPass(value);
        }
    };


    const history = useHistory();

    const resetPasswordHandler = (e) => {
        e.preventDefault();
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                console.log("Reset Email Sent");
                setForgotPassword(false);
                toast.success("Password Reset Sent", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000,
                });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const signinWithEmail = async (event) => {
        event.preventDefault();
        try {
            setLoggingSpinner(true);
            postData(`${API_BASE_URL}${USER_EXISTS}`, { ["email"]: email }).then(
                async (response) => {
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(email, pass)
                        .then((signedInUser) => {
                            toast.success("Logged in successfully", {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                            });
                        })
                        .catch((error) => {
                            console.log("$$$, " + error)
                            toast.error(error.message, {
                                position: toast.POSITION.TOP_CENTER,
                                hideProgressBar: true,
                                autoClose: 3000,
                            });
                        });
                }
            );
        } catch (e) {
            toast.error("User not Found", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
            });
            setTimeout(() => setLoggingSpinner(false), 1000);
        } finally {
            setTimeout(() => setLoggingSpinner(false), 1000);
        }
    };

    const [language, setLanguage] = useState("EN");

    useEffect(() => {
        if (loadState("language")) {
            setLanguage(loadState("language"));
        } else {
            saveState("language", "EN");
        }
    }, [loadState("language")]);

    const languageText = {
        EN: {
            emailAddress: "Email address",
            password: "Password",
            required: "Required",
            login: "Login",
            notRegistered: "Not registered? Register",
            logInToGetStarted: "Log in to get started",
            forgotPassword: "Forgot Password",
            english: "English",
            french: "French",
        },

        FR: {
            emailAddress: "Adresse e-mail",
            password: "Mot de passe",
            required: "Obligatoire",
            login: "Connexion",
            notRegistered: "Non enregistré? S'inscrire",
            logInToGetStarted: "Connectez-vous pour commencer",
            forgotPassword: "Mot de passe oublié",
            english: "Anglais",
            french: "Français",
        },
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
        saveState("language", lang);
        props.callBack && props.callBack(lang);
    };

    if (!!userDetails && !writingUserData) {
        history.push("/admin");
    }

    return (
        <>
            <div className="container-fluid bg-white montserrat-font" style={{}}>
                <div className="container">
                    <Navbar collapseOnSelect expand="lg" variant="light">
                        <Navbar.Brand
                            className="mx-auto"
                            href="#home"
                            href="/"
                            target="_blank"
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/igesia-logo.png`}
                                height="70"
                                alt="igesia-Logo"
                            />
                        </Navbar.Brand>
                        <Dropdown className="">
                            <Dropdown.Toggle
                                as="div"
                                id="dropdown-basic"
                                style={{ cursor: "pointer" }}
                            >
                                {language === "EN" ? "Language" : "Langue"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    active={language === "EN"}
                                    onClick={() => changeLanguage("EN")}
                                >
                                    {languageText[`${language}`].english}
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                    active={language === "FR"}
                                    onClick={() => changeLanguage("FR")}
                                >
                                    {languageText[`${language}`].french}
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar>
                </div>
            </div>

            <div
                className="container-fluid montserrat-font pt-3 pb-5"
                style={{ backgroundColor: "#EFEFEF" }}
            >
                <div className="container pb-4">
                    <div className="row my-5">
                        <div className="col-md-6 offset-md-3">
                            <div className="text-dark text-center p-2 mb-5 ">
                                <h3 className="mb-3">
                                    {languageText[`${language}`].logInToGetStarted}
                                </h3>
                                <p className="mb-0 text-muted">
                                    <a href="/admin/register">
                                        {languageText[`${language}`].notRegistered}
                                    </a>
                                </p>
                            </div>
                            <div className="text-dark">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/igesia-cover.jpg`}
                                    className="w-100 mb-3 rounded-12"
                                    alt="..."
                                />
                            </div>

                            {!forgotPassword ? (
                                <h5>{languageText[`${language}`].login}</h5>
                            ) : (
                                <h5>{languageText[`${language}`].forgotPassword}</h5>
                            )}

                            <form ref={loginFormRef}>
                                {/* <div className="form-label-group mt-3">
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        id="inputEmail"
                                        aria-describedby="emailHelp"
                                        placeholder="Emailsssss"
                                        value={email}
                                        onChange={(e) => updateStateHandler('email', e.target.value)}
                                    />
                                    <label htmlFor="email" className="mb-1">Endereço de e-mail</label>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            className=" form-group small font-weight-bold"
                                            controlId="fname"
                                        >
                                            <label htmlFor="email" className="mb-1 form-label">
                                                {languageText[`${language}`].emailAddress} (
                                                {languageText[`${language}`].required})
                                            </label>
                                            <input
                                                name="email"
                                                id="inputEmail"
                                                type="email"
                                                value={email}
                                                onChange={(e) =>
                                                    updateStateHandler("email", e.target.value)
                                                }
                                                // placeholder="First name"
                                                className="form-control"
                                            ></input>
                                        </div>
                                    </div>
                                </div>

                                {!forgotPassword ? (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="password" className="mb-1 form-label">
                                                    {languageText[`${language}`].password} (
                                                    {languageText[`${language}`].required})
                                                </label>
                                                <div
                                                    className="input-group small font-weight-bold"
                                                    controlId="fname"
                                                >
                                                    <input
                                                        name="password"
                                                        id="pass"
                                                        type={showPassword ? "text" : "password"}
                                                        id="inputPassword"
                                                        value={pass}
                                                        onChange={(e) =>
                                                            updateStateHandler("pass", e.target.value)
                                                        }
                                                        // placeholder="First name"
                                                        className="form-control"
                                                    ></input>
                                                    <div
                                                        className="input-group-append"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <span
                                                            className="input-group-text"
                                                            onClick={() => setShowPassword((prev) => !prev)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={showPassword ? faEyeSlash : faEye}
                                                                title={showPassword ? "Hide" : "Show"}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group text-left">
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setForgotPassword(true);
                                                }}
                                            >
                                                {languageText[`${language}`].forgotPassword}?
                                            </a>
                                        </div>

                                        <div className="d-flex">
                                            <button
                                                className="btn btn-primary flex-grow-1"
                                                type="submit"
                                                onClick={signinWithEmail}
                                                disabled={loggingSpinner}
                                            >
                                                {languageText[`${language}`].login}{" "}
                                                {loggingSpinner ? (
                                                    <Spinner animation="border" size="sm" color="white" />
                                                ) : null}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-primary flex-grow-1 mt-3"
                                            type="submit"
                                            onClick={(e) => resetPasswordHandler(e)}
                                        >
                                            {languageText[`${language}`].forgotPassword}
                                        </button>
                                    </div>
                                )}

                                <div className="mt-3 d-flex">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary px-3 d-block flex-grow-1"
                                        onClick={() => {
                                            history.push("/admin/register");
                                        }}
                                    >
                                        <span>{languageText[`${language}`].notRegistered}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </>
    );
}

export default AdminLogin;