import React, { useState, useEffect, useMemo } from "react";
import { Form, Button, Modal, Spinner, Card, FormCheck } from "react-bootstrap";
import Select from "react-select";
import "flatpickr/dist/themes/material_green.css";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import firebase from "firebase";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import SinglePage from "../../Event/Dashboard/Templates/SinglePage";
import MultiplePages from "../../Event/Dashboard/Templates/MultiplePages";
import FontAwesomeExports from "../../Event/Dashboard/FontAwesomeExports";
import HTMLReactParser from "html-react-parser";
import { useHistory } from "react-router";
import { DRIVE_UPLOAD, API_BASE_URL } from "../../../config";
import { firestore, auth } from "../../../firebase";
import axios from "axios";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

toast.configure();

const Pages = (props) => {
  // CONSTANTS
  const history = useHistory();

  // STATES
  const [featuredImage, setFeaturedImage] = useState();
  const [featuredImageTab, setFeaturedImageTab] = useState();

  const [pageSnapshot, loading, error] = useCollection(
    firestore.collection(`/events/${props.eventId}/pages`)
  );

  const customPageList = useMemo(() => {
    return pageSnapshot?.docs
      ?.sort((a, b) => a?.data()?.order_index - b?.data()?.order_index)
      ?.map((e) => {
        return {
          ...e.data(),
          id: e.id,
        };
      });
  }, [pageSnapshot]);

  const [addCustomPageModal, setAddCustomPageModal] = useState(false);
  const [pageDetails, setPageDetails] = useState({});
  const [content, setContent] = useState([{}]);

  const [selectedCustomPage, setSelectedCustomPage] = useState(null);
  const [tabName, setTabName] = useState("");
  //   const [selectedTabContentList, setSelectedTabContentList] = useState([]);
  const [showAddNewTabModal, setShowAddNewTabModal] = useState(false);
  const [reorderPageModal, setReorderPageModal] = useState(false);

  const [showEditTabContent, setShowEditTabContent] = useState(false);
  const [editTabData, setEditTabData] = useState(null);
  const [editTabContent, setEditTabContent] = useState(null);
  const [tabContent, setTabContent] = useState({});
  const [loadingUpdateTabContent, setLoadingUpdateTabContent] = useState(false);

  const [showEditPageContent, setShowEditPageContent] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const [loadingUpdatePageContent, setLoadingUpdatePageContent] =
    useState(false);

  const options = [
    { id: 0, value: "single", label: "Single" },
    { id: 1, value: "multiple", label: "Multiple" },
  ];

  const pageIcon = [
    { id: 0, value: "faUniversity", label: "University" },
    { id: 1, value: "faOrganistation", label: "Organisation" },
  ];

  const getTypeValueHandler = () => {
    let data = options.filter((item) => item.id === pageDetails.type);
    return data;
  };

  const getIconHandler = () => {
    let data = pageIcon.filter((item) => item.value === pageDetails.icon);
    return data;
  };

  const handleAddCustomPage = async (e) => {
    e.preventDefault();
    if (pageDetails) {
      const size = (
        await firestore.collection(`/events/${props.eventId}/pages/`).get()
      ).docs.length;

      firestore
        .collection(`/events/${props.eventId}/pages/`)
        .add({
          content: parseInt(pageDetails.type) === 0 ? "" : [],
          icon: pageDetails.icon ?? "",
          slug: pageDetails.slug ?? "",
          name: pageDetails.name ?? "",
          type: parseInt(pageDetails.type) ?? 0,
          order_index: size,
          disabled: true,
          show: false,
        })
        .then(() => {
          setAddCustomPageModal(false);
          setPageDetails({});
          toast.success("Page added", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .catch((err) => {
          toast.error("Error in adding page. ", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const getCustomPageTypeName = (type) => {
    switch (type) {
      case 0:
        return "Single page";

      case 1:
        return "Multi-tab";

      default:
        return null;
    }
  };

  const handleDeleteCustomPage = (page_id, type = 0) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      if (type === 0) {
        firestore
          .collection(`/events/${props.eventId}/pages`)
          .doc(`${page_id}`)
          .delete()
          .then(() => {
            toast.success("Page deleted", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .catch((err) => {
            console.error("Error deleting custom page: ", err);
            toast.error("Fail to delete page", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
      if (type === 1) {
        firestore
          .collection(`/events/${props.eventId}/pages`)
          .doc(`${page_id}`)
          .delete()
          .then(() => {
            toast.success("Page deleted", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .catch((err) => {
            console.error("Error deleting custom page: ", err);
            toast.error("Fail to delete page", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
    }
  };

  const handleAddNewTab = (event, page_id) => {
    event.preventDefault();

    if (page_id) {
      firestore
        .collection(`events/${props.eventId}/pages/${page_id}/content`)
        .orderBy("order_index", "desc")
        .limit(1)
        .get()
        .then((snapshots) => {
          let last_index = 0;

          if (snapshots.docs?.length === 0) {
            // do nothing
          } else {
            last_index = snapshots.docs[0]?.data()?.order_index;
          }

          firestore
            .collection(`events/${props.eventId}/pages/${page_id}/content`)
            .add({
              tab_name: tabName,
              order_index: last_index + 1,
              content: `<h3>${tabName}</h3>`,
            })
            .then((docRef) => {
              const tabContentId = docRef.id;
              firestore
                .collection(`events/${props.eventId}/pages`)
                .doc(`${page_id}`)
                .update({
                  content: firebase.firestore.FieldValue.arrayUnion({
                    content_id: tabContentId,
                    order_index: last_index + 1,
                    tab_name: tabName,
                  }),
                })
                .then(() => {
                  setTabName("");
                  setShowAddNewTabModal(false);
                  setSelectedCustomPage(null);

                  firestore
                    .collection(`events/${props.eventId}/pages`)
                    .doc(`${page_id}`)
                    .get()
                    .then((doc) => {
                      if (doc.exists) setSelectedCustomPage(doc.data());
                    });
                });
            })
            .catch((err) => {
              console.error("Error adding tab", err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //   useEffect(async () => {
  //     if (selectedCustomPage) {
  //       const tempList = [];
  //       firebase
  //         .firestore()
  //         .collection(
  //           `events/${props.eventId}/pages/${selectedCustomPage?.id}/content`
  //         )
  //         .get()
  //         .then((querySnapshot) => {
  //           querySnapshot.forEach((doc) =>
  //             tempList.push({ id: doc.id, ...doc.data() })
  //           );
  //         });

  //       setSelectedTabContentList(tempList);
  //     }
  //   }, [selectedCustomPage]);

  const handleDeleteTab = (page_id, tab_id) => {
    const newTabContentList = selectedCustomPage?.content.filter(
      (item) => item.content_id != tab_id
    );

    firestore
      .collection(`events/${props.eventId}/pages/${page_id}/content`)
      .doc(`${tab_id}`)
      .delete()
      .then(() => {
        firestore
          .collection(`events/${props.eventId}/pages`)
          .doc(`${page_id}`)
          .update({
            content: newTabContentList ?? [],
          })
          .then(() => {
            toast.success("Tab deleted", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      })
      .catch((err) => {
        console.error("Error deleting tab: ", err);
      });
  };

  useEffect(() => {
    if (editTabData) {
      firestore
        .collection(
          `events/${props.eventId}/pages/${selectedCustomPage?.id}/content`
        )
        .doc(`${editTabData?.content_id}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setEditTabContent({ id: doc.id, ...doc.data() });
            setTabContent((prev) => {
              let newState = { ...prev };
              newState["content"] = doc.data().content;
              newState["featured_image"] = doc.data().featured_image;
              return newState;
            });
          }
        });
    }
  }, [editTabData]);

  const handleUpdateTabContent = async (event) => {
    event.preventDefault();
    setLoadingUpdateTabContent(true);

    const newContent = selectedCustomPage?.content?.map((item) => {
      if (item.content_id === editTabData?.content_id) {
        return {
          ...item,
          tab_name: tabContent?.tab_name,
        };
      } else {
        return item;
      }
    });

    let featured_image = tabContent?.featured_image;

    if (featuredImageTab) {
      featured_image = await uploadFile(featuredImageTab);
    }

    firestore
      .collection(`events/${props.eventId}/pages`)
      .doc(`${selectedCustomPage?.id}`)
      .update({
        content: newContent,
      })
      .then(() => {
        firestore
          .collection(
            `events/${props.eventId}/pages/${selectedCustomPage?.id}/content`
          )
          .doc(`${editTabContent?.id}`)
          .update({
            content: tabContent?.content,
            tab_name: tabContent?.tab_name,
            featured_image: featured_image ?? "",
          });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingUpdateTabContent(false);
        setShowEditTabContent(false);
        setEditTabData(null);
        setSelectedCustomPage(null);
        setFeaturedImageTab();
      });
  };

  const handleUpdatePageContent = async (event) => {
    event.preventDefault();
    setLoadingUpdatePageContent(true);

    let featured_image = pageContent?.featured_image;

    if (featuredImage) {
      featured_image = await uploadFile(featuredImage);
    }

    firestore
      .collection(`events/${props.eventId}/pages`)
      .doc(`${selectedCustomPage?.id}`)
      .update({
        content: pageContent?.content,
        name: pageContent?.page_name,
        featured_image: featured_image ?? "",
      })
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingUpdatePageContent(false);
        setShowEditPageContent(false);
        setSelectedCustomPage(null);
        setFeaturedImage();
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return;
    }

    let sourceIndex = customPageList[result.source.index];
    let destinationIndex = customPageList[result.destination.index];
    if (result.destination) {
      firestore
        .collection(`events/${props.eventId}/pages`)
        .doc(sourceIndex.id)
        .set(
          {
            order_index: destinationIndex.order_index,
          },
          {
            merge: true,
          }
        );
      firestore
        .collection(`events/${props.eventId}/pages`)
        .doc(destinationIndex.id)
        .set(
          {
            order_index: sourceIndex.order_index,
          },
          {
            merge: true,
          }
        );
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightgrey" : "lightgrey",
    padding: grid,
    width: "100%",
  });

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "darkgrey" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, `${file.name}-${Date.now()}`);
    fileData.append("user_id", auth.currentUser.uid);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        return res.data.data["file_url"];
      } catch (error) {
        console.error(error, "error in writing file to db");
      }
    } else return "";
  };

  return (
    <div className="container-fluid vh-100 overflow-auto">
      <div className="shadow-sm my-3 mb-3 d-flex bd-highlight">
        <h5 className="mb-0 text-white p-2 flex-grow-1 bd-highlight">Pages</h5>
        <Button
          size="sm"
          className="text-uppercase p-2 bd-highlight mr-2"
          onClick={() => setReorderPageModal(true)}
        >
          Reorder Pages
        </Button>
        <Button
          size="sm"
          className="text-uppercase p-2 bd-highlight"
          onClick={() => setAddCustomPageModal(true)}
        >
          Add Custom Page
        </Button>
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center text-white">
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        customPageList?.length === 0 && (
          <p className="text-white">No pages added yet</p>
        )
      )}

      {customPageList?.map((customPage, key) => (
        <Card key={key} className="text-white bg-dark mb-3">
          <Card.Header className="bg-dark border-dark d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-center pr-2">
              <FontAwesomeExports
                className="mb-0"
                icon={customPage.icon}
                size={"2x"}
              />
            </div>
            <div className="flex-grow-1">
              <h5 className="mb-0">{customPage.name}</h5>
              <p className="mb-0 text-uppercase small">
                Type: {getCustomPageTypeName(customPage.type)}
              </p>
            </div>
            <div>
              {parseInt(customPage?.type) === 1 && (
                <Button
                  size="sm"
                  className="text-uppercase mr-2"
                  variant="primary"
                  onClick={() => {
                    setShowAddNewTabModal(true);
                    setSelectedCustomPage(customPage);
                  }}
                >
                  Add New Tab
                </Button>
              )}

              <Button
                size="sm"
                className="text-uppercase mr-2"
                onClick={() =>
                  window.open(
                    `/event/${props.currentEvent.slug}/page/${customPage.slug}`,
                    "_blank"
                  )
                }
              >
                View Page
              </Button>

              <Button
                size="sm"
                className="text-uppercase"
                variant="danger"
                onClick={() =>
                  handleDeleteCustomPage(
                    customPage.id,
                    parseInt(customPage.type)
                  )
                }
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="bg-dark border-dark">
            <div className="row">
              <div className="col-md-10">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    {parseInt(customPage.type) === 0 && (
                      <h6 className="text-muted text-uppercase">
                        Page Content
                      </h6>
                    )}
                    {parseInt(customPage.type) === 1 && (
                      <h6 className="text-muted text-uppercase">Tabs</h6>
                    )}
                  </div>
                  {parseInt(customPage.type) === 0 && (
                    <div className="mb-2">
                      <Button
                        size="sm"
                        variant="secondary"
                        className="text-uppercase mr-2"
                        onClick={() => {
                          setShowEditPageContent(true);
                          setPageContent((prev) => {
                            let newState = { ...prev };
                            newState["page_name"] = customPage.name;
                            newState["content"] = customPage.content;
                            newState["featured_image"] =
                              customPage?.featured_image ?? "";
                            return newState;
                          });
                          setSelectedCustomPage(customPage);
                        }}
                      >
                        <span>
                          <FontAwesomeIcon icon={faEdit} /> Edit Content
                        </span>
                      </Button>
                    </div>
                  )}
                </div>

                {parseInt(customPage.type) === 0 && (
                  <React.Fragment>
                    {customPage?.featured_image && (
                      <figure>
                        <Zoom>
                          <img
                            src={customPage?.featured_image}
                            className="img-fluid w-100 rounded-sm"
                          />
                        </Zoom>
                      </figure>
                    )}

                    {customPage.content.length > 0 &&
                      HTMLReactParser(
                        customPage.content.length > 500
                          ? customPage.content.substring(0, 500) + "..."
                          : customPage.content
                      )}
                  </React.Fragment>
                )}

                {customPage.type === 1 &&
                  customPage.content?.map((item, idx) => (
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-grow-1">
                        <p className="mb-0">
                          {idx + 1}. {item.tab_name}
                        </p>
                      </div>
                      <div>
                        <Button
                          size="sm"
                          variant="secondary"
                          className="text-uppercase mr-2"
                          onClick={() => {
                            setShowEditTabContent(true);
                            setEditTabData(item);
                            setTabContent((prev) => {
                              let newState = { ...prev };
                              newState["tab_name"] = item.tab_name;
                              return newState;
                            });
                            setSelectedCustomPage(customPage);
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faEdit} /> Edit Content
                          </span>
                        </Button>
                        <Button
                          size="sm"
                          variant="danger"
                          className="text-uppercase"
                          onClick={() =>
                            handleDeleteTab(customPage?.id, item.content_id)
                          }
                        >
                          <span>
                            <FontAwesomeIcon icon={faTimes} /> Delete
                          </span>
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-md-2">
                <div className="d-flex align-items-center mb-3">
                  <div className="d-flex flex-column">
                    <span className="text-secondary small font-weight-bold text-uppercase">
                      Show on Landing Page
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id={"show-landing-page" + customPage.slug}
                        label={customPage.show_on_landing_page ? "On" : "Off"}
                        checked={customPage.show_on_landing_page ? true : false}
                        onChange={(e) =>
                          firestore
                            .collection(`/events/${props.eventId}/pages`)
                            .doc(customPage.id)
                            .update({
                              show_on_landing_page: e.target.checked,
                            })
                        }
                      ></FormCheck>
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <div className="d-flex flex-column">
                    <span className="text-secondary small font-weight-bold text-uppercase">
                      Show
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id={"show-" + customPage.slug}
                        label={customPage.show ? "On" : "Off"}
                        checked={customPage.show}
                        onChange={(e) =>
                          firestore
                            .collection(`/events/${props.eventId}/pages`)
                            .doc(customPage.id)
                            .update({
                              show: e.target.checked,
                            })
                        }
                      ></FormCheck>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="d-flex flex-column">
                    <span className="text-secondary small font-weight-bold text-uppercase">
                      Disabled
                    </span>
                    <span>
                      <FormCheck
                        type="switch"
                        id={"disable-" + customPage.slug}
                        label={customPage.disabled ? "On" : "Off"}
                        checked={customPage.disabled}
                        onChange={(e) =>
                          firestore
                            .collection(`/events/${props.eventId}/pages`)
                            .doc(customPage.id)
                            .update({
                              disabled: e.target.checked,
                            })
                        }
                      ></FormCheck>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      ))}

      {/*-------------------------Reorder Custom Page Modal Start-------------------------- */}
      <Modal
        centered
        show={reorderPageModal}
        onHide={() => setReorderPageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reorder Custom Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {customPageList?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Body>
      </Modal>
      {/*-------------------------Reorder Custom Page Modal Endss-------------------------- */}

      {/*-------------------------Add Custom Page Modal Start-------------------------- */}

      <Modal
        centered
        show={addCustomPageModal}
        onHide={() => setAddCustomPageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddCustomPage}>
            <Form.Group>
              <Form.Label className="text-dark">Name</Form.Label>
              <Form.Control
                type="text"
                className=""
                placeholder={`Enter custom page name`}
                value={pageDetails?.name}
                name="page_name"
                onChange={(e) => {
                  setPageDetails((prev) => {
                    const newState = {
                      ...prev,
                      name: e.target.value,
                    };
                    return newState;
                  });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-dark">Slug</Form.Label>
              <Form.Control
                type="text"
                className=""
                placeholder={`Enter custom page slug`}
                value={pageDetails?.slug}
                name="page_slug"
                onChange={(e) => {
                  setPageDetails((prev) => {
                    const newState = {
                      ...prev,
                      slug: e.target.value.toLowerCase(),
                    };
                    return newState;
                  });
                }}
              />
            </Form.Group>

            <Form.Group className>
              <Form.Label className="text-dark">Icon</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                name="page-icon"
                placeholder="Select custom page icon"
                options={pageIcon}
                defaultValue={pageIcon[0]}
                value={getIconHandler()}
                onChange={(e) => {
                  setPageDetails((prev) => {
                    const newState = {
                      ...prev,
                      icon: e.value,
                    };
                    return newState;
                  });
                }}
              />
            </Form.Group>
            <Form.Group className>
              <Form.Label className="text-dark">Type</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={options[0]}
                value={getTypeValueHandler()}
                name="select-type"
                placeholder="Select custom page type"
                options={options}
                onChange={(e) => {
                  setPageDetails((prev) => {
                    const newState = {
                      ...prev,
                      type: e.id,
                    };
                    return newState;
                  });
                }}
              />
            </Form.Group>
            <Button className="form-control" variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/*-------------------------Add Custom Page Modal End-------------------------- */}

      <Modal
        show={showAddNewTabModal}
        onHide={() => {
          setTabName("");
          setShowAddNewTabModal(false);
          setSelectedCustomPage(null);
        }}
        centered
      >
        <Modal.Header className="bg-dark text-white border-dark" closeButton>
          <Modal.Title>Add New Tab</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white border-dark">
          <div>
            <Form
              onSubmit={(event) =>
                handleAddNewTab(event, selectedCustomPage?.id)
              }
            >
              <Form.Group>
                <Form.Label className="flex-grow-1">Tab name</Form.Label>
                <Form.Control
                  type="text"
                  name="tab_name"
                  required={true}
                  value={tabName}
                  onChange={(e) => {
                    setTabName(e.target.value);
                  }}
                />
              </Form.Group>

              <Button type="submit" size="sm" className="text-uppercase">
                Save
              </Button>
              <Button
                variant="none"
                size="sm"
                className="text-uppercase text-white"
                onClick={() => {
                  setTabName("");
                  setShowAddNewTabModal(false);
                  setSelectedCustomPage(null);
                }}
              >
                Discard
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditTabContent}
        onHide={() => {
          setShowEditTabContent(false);
          setEditTabData(null);
          setSelectedCustomPage(null);
        }}
        centered
      >
        <Modal.Header className="bg-dark text-white border-dark" closeButton>
          <Modal.Title>Update Tab Content</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white border-dark">
          <div>
            <Form onSubmit={handleUpdateTabContent}>
              <Form.Group>
                <Form.Label className="flex-grow-1">Tab name</Form.Label>
                <Form.Control
                  type="text"
                  name="tab_name"
                  placeholder="Enter tab name"
                  required={true}
                  value={tabContent?.tab_name}
                  onChange={(e) => {
                    setTabContent((prev) => {
                      let newState = { ...prev };
                      newState["tab_name"] = e.target.value;
                      return newState;
                    });
                  }}
                />
              </Form.Group>

              <Form.File className="mb-2">
                <Form.File.Label>Featured image</Form.File.Label>
                {tabContent?.featured_image && (
                  <div className="mb-2">
                    <figure>
                      <Zoom>
                        <img
                          src={tabContent?.featured_image}
                          className="img-fluid w-100 rounded-sm"
                          style={{ height: 120 }}
                        />
                      </Zoom>
                    </figure>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() =>
                        setTabContent((prev) => {
                          let newState = { ...prev };
                          delete newState["featured_image"];

                          return newState;
                        })
                      }
                    >
                      Remove Image
                    </Button>
                  </div>
                )}
                <Form.File.Input
                  accept="image/*"
                  onChange={(e) => setFeaturedImageTab(e.target.files[0])}
                />
              </Form.File>

              <Form.Group className>
                <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "bold",
                      "italic",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  className="mb-3"
                  required={true}
                  data={tabContent?.content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTabContent((prev) => {
                      let newState = { ...prev };
                      newState["content"] = data;
                      return newState;
                    });
                  }}
                  style={{ height: 200 }}
                />
              </Form.Group>

              <Button
                type="submit"
                size="sm"
                className="text-uppercase mr-2 px-3"
                disabled={loadingUpdateTabContent}
              >
                {loadingUpdateTabContent && (
                  <Spinner animation="border" size="sm" />
                )}{" "}
                Save
              </Button>
              <Button
                variant="none"
                size="sm"
                className="text-uppercase text-white"
                onClick={() => {
                  setShowEditTabContent(false);
                  setEditTabData(null);
                  setSelectedCustomPage(null);
                }}
              >
                Discard
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditPageContent}
        onHide={() => {
          setShowEditPageContent(false);
          setSelectedCustomPage(null);
        }}
        centered
      >
        <Modal.Header className="bg-dark text-white border-dark" closeButton>
          <Modal.Title>Update Page Content</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white border-dark">
          <div>
            <Form onSubmit={handleUpdatePageContent}>
              <Form.Group>
                <Form.Label className="flex-grow-1">Page name</Form.Label>
                <Form.Control
                  type="text"
                  name="page_name"
                  placeholder="Enter page name"
                  required={true}
                  value={pageContent?.page_name}
                  onChange={(e) => {
                    setPageContent((prev) => {
                      let newState = { ...prev };
                      newState["page_name"] = e.target.value;
                      return newState;
                    });
                  }}
                />
              </Form.Group>

              <Form.File className="mb-2">
                <Form.File.Label>Featured image</Form.File.Label>
                {pageContent?.featured_image && (
                  <div className="mb-2">
                    <figure>
                      <Zoom>
                        <img
                          src={pageContent?.featured_image}
                          className="img-fluid w-100 rounded-sm"
                          style={{ height: 120 }}
                        />
                      </Zoom>
                    </figure>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() =>
                        setPageContent((prev) => {
                          let newState = { ...prev };
                          delete newState["featured_image"];

                          return newState;
                        })
                      }
                    >
                      Remove Image
                    </Button>
                  </div>
                )}
                <Form.File.Input
                  accept="image/*"
                  onChange={(e) => setFeaturedImage(e.target.files[0])}
                />
              </Form.File>

              <Form.Group className>
                <CKEditor
                  id="editor"
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "bold",
                      "italic",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  className="mb-3"
                  required={true}
                  data={pageContent?.content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPageContent((prev) => {
                      let newState = { ...prev };
                      newState["content"] = data;
                      return newState;
                    });
                  }}
                  style={{ height: 200 }}
                />
              </Form.Group>

              <Button
                type="submit"
                size="sm"
                className="text-uppercase mr-2 px-3"
                disabled={loadingUpdatePageContent}
              >
                {loadingUpdatePageContent && (
                  <Spinner animation="border" size="sm" />
                )}{" "}
                Save
              </Button>
              <Button
                variant="none"
                size="sm"
                className="text-uppercase text-white"
                onClick={() => {
                  setShowEditPageContent(false);
                  setSelectedCustomPage(null);
                }}
              >
                Discard
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect((state) => ({
  currentLanguage: state.currentLanguage,
  darkTheme: state.darkTheme,
  currentEvent: state.currentEvent,
  eventId: state.eventId,
}))(Pages);
