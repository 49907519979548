import React from "react";
import { Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import firebase from "firebase";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import * as CourseStyle from "../course.module.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

function ClassNavbar({
  changeLanguage,
  currentLanguage,
  class_type,
  class_details,
}) {
  const userDetails = firebase.auth().currentUser;

  const history = useHistory();

  return (
    <div
      className={`row px-3 d-flex justify-content-between align-items-center text-white ${CourseStyle.dark_bg}`}
      style={{ height: "7vh" }}
    >
      <div className="d-flex align-items-center">
        <a href={`${window.location.origin}`} target="_blank" className="mr-2">
          <img
            alt="igesia logo"
            src={`${process.env.PUBLIC_URL}/assets/Igesia-Logo.svg`}
            height="50"
          />
        </a>
        <h6 className="font-weight-bold mb-0 mr-2">{class_details?.name}</h6>
        <h6
          className="font-weight-bold mb-0 pl-2"
          style={{ borderLeft: "2px solid" }}
        >
          {class_type === "live" ? "Live" : "Archive"}
        </h6>
      </div>
      <div className="d-flex">
        <Dropdown className="mr-3">
          <Dropdown.Toggle
            as="div"
            id="dropdown-basic"
            style={{ cursor: "pointer" }}
          >
            {currentLanguage === "EN" ? "Language" : "Langue"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={currentLanguage === "EN"}
              onClick={() => changeLanguage("EN")}
            >
              {`English`}
            </Dropdown.Item>
            <Dropdown.Item
              active={currentLanguage === "FR"}
              onClick={() => changeLanguage("FR")}
            >
              {`French`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="mr-4 pointer">
          <Dropdown.Toggle as="div" id="dropdown-basic">
            <Avatar name={`Ak`} round={true} size="25" textSizeRatio={1.75} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={async () => {
                history.push("/event/blueoceanawards/updateprofile");
              }}
            >
              {`Profile`}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => {
                await firebase.auth().signOut();
                window.location.reload();
                toast.success("Sign Out", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }}
            >
              {`Sign Out`}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
export default connect(
  (state) => ({ currentLanguage: state.currentLanguage }),
  null
)(ClassNavbar);
